import { StaticDataHolderActionTypes } from '../types';
import { SELECT_DATA_FRAGMENT, UN_SELECT_DATA_FRAGMENT, ADD_DATA_FRAGMENT, DELETE_DATA_FRAGMENT, UPDATE_DATA_FRAGMENT, RE_ORDER_DATA_FRAGMENTS, DataFragmentState, IDataFragment, UPDATE_DATA_FRAGMENTS_DATA, SYNCHRONIZE_DATA_FRAGMENTS_DATA, CLEAR_DATA_FRAGMENTS_DELTA, ADD_DATA_FRAGMENTS } from './types';
import { reOrderList } from '../../../helpers/utilities';
import { ADD_STATIC_DATA_HOLDER, UN_SELECT_STATIC_DATA_HOLDER, SELECT_STATIC_DATA_HOLDER, UPDATE_STATIC_DATA_HOLDERS_DATA } from '../types';
import { addEntity, deleteEntity, updateEntries, synchronizeEntries, clearDelta, updateEntity } from '../../normalized-model';

export const initialState: DataFragmentState = {
    byId: {},
    allEntries: [],
    filteredEntries: [],
    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),

    byStaticDataHolder: {},
    selected: [],

    reOrderedTopLevelDataFragments: {},
    reOrderedChildDataFragments: {},
}

export function dataFragmentsReducer(state = initialState, action: StaticDataHolderActionTypes): DataFragmentState {
    let newState: DataFragmentState;

    switch (action.type) {

        // STATIC_DATA_HOLDER ACTIONS

        case ADD_STATIC_DATA_HOLDER:
            return {
                ...state,
                byStaticDataHolder: {
                    ...state.byStaticDataHolder,
                    [action.payload.id]: [],
                }
            }

        case SELECT_STATIC_DATA_HOLDER:
        case UN_SELECT_STATIC_DATA_HOLDER:
            return {
                ...state,
                selected: [],
            }

        case UPDATE_STATIC_DATA_HOLDERS_DATA:
            newState = {
                ...state,
                byStaticDataHolder: {
                    ...state.byStaticDataHolder,
                }
            }

            for (const staticDataHolder of action.data) {
                newState.byStaticDataHolder[staticDataHolder.id] = staticDataHolder.children ? staticDataHolder.children.slice() : [];
            }

            return newState;

        // DATA_FRAGMENT ACTIONS

        case SELECT_DATA_FRAGMENT:
            return {
                ...state,
                selected: state.selected.slice(0, action.index + 1).concat([action.id]),
            }

        case UN_SELECT_DATA_FRAGMENT:
            return {
                ...state,
                selected: state.selected.slice(0, action.index),
            }

        case RE_ORDER_DATA_FRAGMENTS:
            let reOrderedList: Array<string> = [];

            if (action.parentId in state.byStaticDataHolder) {
                reOrderedList = reOrderList(state.byStaticDataHolder[action.parentId], action.sourceIndex, action.destinationIndex);
                // This is a top level dataFragment
                return {
                    ...state,
                    byStaticDataHolder: {
                        ...state.byStaticDataHolder,
                        [action.parentId]: reOrderedList,
                    },
                    reOrderedTopLevelDataFragments: {
                        ...state.reOrderedTopLevelDataFragments,
                        [action.parentId]: reOrderedList,
                    },
                }

            } else {
                reOrderedList = reOrderList(state.byId[action.parentId].children, action.sourceIndex, action.destinationIndex);

                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [action.parentId]: {
                            ...state.byId[action.parentId],
                            children: reOrderedList,
                        }
                    },
                    reOrderedChildDataFragments: {
                        ...state.reOrderedChildDataFragments,
                        [action.parentId]: reOrderedList,
                    },
                }
            }

        case ADD_DATA_FRAGMENT:
            state = addEntity<DataFragmentState, IDataFragment>(state, action.payload);

            if (action.parentId in state.byStaticDataHolder) {
                // This is a top level dataFragment
                return {
                    ...state,
                    byStaticDataHolder: {
                        ...state.byStaticDataHolder,
                        [action.parentId]: state.byStaticDataHolder[action.parentId].concat([action.payload.id]),
                    },
                    reOrderedTopLevelDataFragments: {
                        ...state.reOrderedTopLevelDataFragments,
                        [action.parentId]: state.byStaticDataHolder[action.parentId].concat([action.payload.id]),
                    },
                };
            } else {
                // This is the child of another dataFragment
                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [action.parentId]: {
                            ...state.byId[action.parentId],
                            children: state.byId[action.parentId].children.concat([action.payload.id]),
                        }
                    },
                    reOrderedChildDataFragments: {
                        ...state.reOrderedChildDataFragments,
                        [action.parentId]: state.byId[action.parentId].children.concat([action.payload.id]),
                    },
                };
            }

        case ADD_DATA_FRAGMENTS:
            let newStateForAddDataFragments = {
                ...state,
            };

            for (const entity of action.payload) {
                newStateForAddDataFragments = addEntity<DataFragmentState, IDataFragment>(newStateForAddDataFragments, entity.data);

                if (entity.parentId in state.byStaticDataHolder) {
                    // This is a top level dataFragment
                    newStateForAddDataFragments = {
                        ...newStateForAddDataFragments,
                        byStaticDataHolder: {
                            ...newStateForAddDataFragments.byStaticDataHolder,
                            [entity.parentId]: state.byStaticDataHolder[entity.parentId].concat([entity.data.id]),
                        },
                        reOrderedTopLevelDataFragments: {
                            ...newStateForAddDataFragments.reOrderedTopLevelDataFragments,
                            [entity.parentId]: state.byStaticDataHolder[entity.parentId].concat([entity.data.id]),
                        },
                    };
                } else {
                    // This is the child of another dataFragment
                    newStateForAddDataFragments = {
                        ...newStateForAddDataFragments,
                        byId: {
                            ...newStateForAddDataFragments.byId,
                            [entity.parentId]: {
                                ...newStateForAddDataFragments.byId[entity.parentId],
                                children: newStateForAddDataFragments.byId[entity.parentId].children.concat([entity.data.id]),
                            }
                        },
                        reOrderedChildDataFragments: {
                            ...newStateForAddDataFragments.reOrderedChildDataFragments,
                            [entity.parentId]: newStateForAddDataFragments.byId[entity.parentId].children.concat([entity.data.id]),
                        },
                    };
                }
            }

            return newStateForAddDataFragments;

        case DELETE_DATA_FRAGMENT:
            const newSelectedList = state.selected.slice(0, state.selected.indexOf(action.id) + 1);
            state = deleteEntity<DataFragmentState, IDataFragment>(state, action.id, action.currentTime);

            if (action.parentId in state.byStaticDataHolder) {
                // This is a top level dataFragment
                return {
                    ...state,
                    byStaticDataHolder: {
                        ...state.byStaticDataHolder,
                        [action.parentId]: state.byStaticDataHolder[action.parentId].filter(entry => entry !== action.id),
                    },
                    reOrderedTopLevelDataFragments: {
                        ...state.reOrderedTopLevelDataFragments,
                        [action.parentId]: state.byStaticDataHolder[action.parentId].filter(entry => entry !== action.id),
                    },
                    selected: newSelectedList,
                }
            } else {
                /// This is the child of another dataFragment
                return {
                    ...state,
                    byId: {
                        ...state.byId,
                        [action.parentId]: {
                            ...state.byId[action.parentId],
                            children: state.byId[action.parentId].children.filter(entry => entry !== action.id),
                        },
                    },
                    reOrderedChildDataFragments: {
                        ...state.reOrderedChildDataFragments,
                        [action.parentId]: state.byId[action.parentId].children.filter(entry => entry !== action.id),
                    },
                    selected: newSelectedList,
                }
            }

        case UPDATE_DATA_FRAGMENT:
            return updateEntity<DataFragmentState, IDataFragment>(state, action.payload, action.currentTime);

        case UPDATE_DATA_FRAGMENTS_DATA:
            return updateEntries<DataFragmentState, IDataFragment>(state, action.data);

        case SYNCHRONIZE_DATA_FRAGMENTS_DATA:
            state = synchronizeEntries<DataFragmentState, IDataFragment>(state, action.data);

            state.byStaticDataHolder = {
                ...state.byStaticDataHolder,
            };

            for (const staticDataId in action.reOrderedTopLevelDataFragments) {
                state.byStaticDataHolder[staticDataId] = action.reOrderedTopLevelDataFragments[staticDataId];
            }

            for (const dataFragmentId in action.reOrderedChildDataFragments) {
                state.byId[dataFragmentId] = {
                    ...state.byId[dataFragmentId],
                    children: action.reOrderedChildDataFragments[dataFragmentId],
                };
            }

            return state;

        case CLEAR_DATA_FRAGMENTS_DELTA:
            newState = clearDelta<DataFragmentState, IDataFragment>(state);
            newState.reOrderedTopLevelDataFragments = {};
            newState.reOrderedChildDataFragments = {};
            return newState;


        default:
            return state
    }
}