import moment from "moment";
import uuid from "uuid";
import { addFullPieces } from "../../shared/store/flowchart/pieces/actions";
import { AllPieceTypes, IAddToListPiece, IAddToTablePiece, IStyleTableSectionPiece, IVariablePiece, PieceType } from "../../shared/store/flowchart/pieces/types";
import store from "../../shared/store/main";
import { IWidget } from "../../shared/store/widgets/types";
import { setIsolatedWidgetRichTextPiece } from "../../shared/store/widgets/actions";
import { ISetVariablePiece } from "../../shared/store/flowchart/pieces/types";
import { ApplicationState } from "../../shared/store/types";
import { VariableType } from "../../shared/store/flowchart/variables/types";

export function addCell(widget: IWidget) {
    const flowchartHolderElement = document.getElementById('flowchart-holder');
    const now = moment().format();

    const applicationState: ApplicationState = store.getState();

    if (!flowchartHolderElement) {
        throw new Error('This element needs to exist');
    }

    const variablesInWidget = widget.richTextVariables?.map(variableId => {
        return applicationState.flowchart.variables.byId[variableId];
    });

    const setPieceId = uuid.v4();
    const stylePieceId = uuid.v4();
    const variablePieceId = uuid.v4();
    const addCellPieceId = uuid.v4();

    const cellVariable = variablesInWidget?.find(variable => variable.type === VariableType.STYLED_TABLE_CELL);
    const rowVariable = variablesInWidget?.find(variable => variable.type === VariableType.STYLED_TABLE_ROW);

    const setPiece: ISetVariablePiece = {
        id: setPieceId,
        type: PieceType.SET_VARIABLE,
        createdTime: now,
        lastUpdatedTime: now,
        variableToSet: cellVariable?.id,
        nextPiece: stylePieceId,
    };

    const stylePiece: IStyleTableSectionPiece = {
        id: stylePieceId,
        type: PieceType.STYLE_TABLE_SECTION,
        isBold: undefined,
        isItalic: undefined,
        fontColor: undefined,
        backgroundColor: undefined,
        fontSize: undefined,
        variablePiece: cellVariable?.id,
        createdTime: now,
        lastUpdatedTime: now,
        nextPiece: addCellPieceId,
    };

    const variablePiece: IVariablePiece = {
        id: variablePieceId,
        type: PieceType.VARIABLE,
        variable: cellVariable?.id,
        createdTime: now,
        lastUpdatedTime: now,
    }

    const addCellPiece: IAddToListPiece = {
        id: addCellPieceId,
        type: PieceType.ADD_TO_LIST,
        dataToSet: variablePiece.id,
        listVariable: rowVariable?.id,
        createdTime: now,
        lastUpdatedTime: now,
    };

    const allPiecesToAdd: Array<AllPieceTypes> = [setPiece, stylePiece, variablePiece, addCellPiece];

    store.dispatch(addFullPieces(allPiecesToAdd));
    store.dispatch(setIsolatedWidgetRichTextPiece({
        piece: setPieceId,
        position: {
            x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
            y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
        },
    }, widget.id));
}

export function addRow(widget: IWidget) {
    const flowchartHolderElement = document.getElementById('flowchart-holder');
    const now = moment().format();

    const applicationState = store.getState();

    if (!flowchartHolderElement) {
        throw new Error('This element needs to exist');
    }

    const variablesInWidget = widget.richTextVariables?.map(variableId => {
        return applicationState.flowchart.variables.byId[variableId];
    });

    const addRowPieceId = uuid.v4();
    const setPieceId = uuid.v4();
    const variablePieceId = uuid.v4();

    const rowVariable = variablesInWidget?.find(variable => variable.type === VariableType.STYLED_TABLE_ROW);
    const tableVariable = variablesInWidget?.find(variable => variable.type === VariableType.STYLED_TABLE_DATA);

    const variablePiece: IVariablePiece = {
        id: variablePieceId,
        type: PieceType.VARIABLE,
        variable: rowVariable?.id,
        createdTime: now,
        lastUpdatedTime: now,
    }

    const addRowPiece: IAddToTablePiece = {
        id: addRowPieceId,
        type: PieceType.ADD_TO_TABLE,
        listVariable: tableVariable?.id,
        dataToSet: variablePieceId,
        createdTime: now,
        lastUpdatedTime: now,
        nextPiece: setPieceId,
    };

    const setPiece: ISetVariablePiece = {
        id: setPieceId,
        type: PieceType.SET_VARIABLE,
        createdTime: now,
        lastUpdatedTime: now,
        variableToSet: rowVariable?.id,
    };

    const allPiecesToAdd: Array<AllPieceTypes> = [variablePiece, addRowPiece, setPiece];

    store.dispatch(addFullPieces(allPiecesToAdd));
    store.dispatch(setIsolatedWidgetRichTextPiece({
        piece: addRowPieceId,
        position: {
            x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
            y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
        },
    }, widget.id));
}