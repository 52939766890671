import { VariableState, VariableActionTypes } from './variables/types';
import { PieceState, PieceActionTypes } from './pieces/types';
import { TableCell, VariableValueType } from '../../helpers/common-types';
import { CustomFieldValueType } from '../custom-fields/types';

export const UPDATE_FLOWCHART_DATA = 'UPDATE_FLOWCHART_DATA';

export interface FlowchartState {
    pieces: PieceState,
    variables: VariableState,
}

export interface FlowchartProcessState {

    lastComputedPiece: string | undefined,
    executionStack: Array<string>,  // IDs of any separate execution stacks (e.g. for loop pieces) will be added here. When a piece without a nextPiece is encountered, this stack will be used to figure out which piece to execute next
    variables: {
        [variableId: string]: VariableValueType,
    },
    forIterationCounts: {
        [forPieceId: string]: number,
    },
    forLoopValues?: {
        [forPieceId: string]: string[] | string[][] | TableCell[] | TableCell[][],
    },
    displayingQuestionPieceId: string | undefined,
    displayingShowPieceId: string | undefined,
    displayingGroupPieceId: string | undefined,
    displayingTransferPieceId: string | undefined,
    displayingContinuePieceId: string | undefined,
    displayingAddWorkflowPieceId: string | undefined,
    createdWorkflowId: string | undefined,
    displayingFinsalLoanProcessPieceId: string | undefined,
}

export interface DefaultFlowchartProcessState extends FlowchartProcessState {

    customFields: {
        [customFieldId: string]: CustomFieldValueType,
    },
}

interface IUpdateFlowchartDataAction {
    type: typeof UPDATE_FLOWCHART_DATA,
    data: FlowchartState,
}

export type FlowchartActionTypes = VariableActionTypes | PieceActionTypes | IUpdateFlowchartDataAction;