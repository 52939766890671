import React from 'react';
import { VariableState } from '../../../shared/store/flowchart/variables/types';
import { PieceType, PieceState, FlowchartPieceActions } from '../../../shared/store/flowchart/pieces/types';
import ReturnPiece from '../../../components/flowchart/pieces/ReturnPiece';
import StartPiece from '../../../components/flowchart/pieces/StartPiece';
import { getComponent } from './index';
import { Position } from '../../../shared/helpers/common-types';
import { isUUID } from '../../../shared/helpers/utilities';
import { ApplicationState } from '../../../shared/store/types';
import { IReportType } from '../../../shared/store/reports/types/types';
import { PiecePositionState } from '../../../shared/helpers/common-types';
import { ArithmeticOperatorsPieces, BooleanOperatorsPieces, ConstantPieces, ControlPieces, CustomFieldPieces, DateOperatorsPieces, ListOperatorsPieces, VariablePieces } from './piece-categories';

export function getComponentForReportFields(reportType: IReportType, applicationState: ApplicationState, piecesState: PieceState, variablesState: VariableState, variableIds: Array<string>, isEditable = true, flowchartPieceActions: FlowchartPieceActions, isolatePiece: (pieceState: PiecePositionState) => void, removeIsolatedPiece: (pieceId: string) => void, registerVariable: (variableId: string) => void, pieceId: string, detachPiece?: () => void, initialPosition?: Position): JSX.Element {
    const piece = piecesState.byId[pieceId];

    const getInnerComponentTemp = getComponentForReportFields.bind({}, reportType, applicationState, piecesState, variablesState);
    const getInnerComponentTemp2 = getInnerComponentTemp.bind({}, variableIds, isEditable, flowchartPieceActions, isolatePiece);
    const getInnerComponent = getInnerComponentTemp2.bind({}, removeIsolatedPiece, registerVariable);

    switch (piece.type) {

        case PieceType.START:
            const startNextPiece = piece.nextPiece ? getInnerComponent(piece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
            const startInitialPosition = reportType.startPiece ? reportType.startPiece.position : undefined;
            return <StartPiece pieceId={pieceId} nextPiece={startNextPiece} isDragDisabled={!isEditable} initialPosition={startInitialPosition} removeIsolatedPiece={removeIsolatedPiece} />

        case PieceType.RETURN:
            const returnVariablePiece = piece.returnValue && isUUID(piece.returnValue) ? getInnerComponent(piece.returnValue, flowchartPieceActions.setReturnVariable.bind({}, pieceId, undefined)) : undefined;
            const returnVariableText = piece.returnValue;

            return <ReturnPiece
                pieceId={pieceId}
                returnVariablePiece={returnVariablePiece}
                returnVariableText={returnVariableText}
                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        default:
            return getComponent(pieceId, piecesState, variablesState, variableIds, getInnerComponent, isEditable, flowchartPieceActions, isolatePiece, removeIsolatedPiece, registerVariable, detachPiece, initialPosition);
    }
}

export const piecesByCategory = {
    'Control': {
        color: '#14b1ab',
        pieces: [
            ControlPieces.For,
            ControlPieces.Return,
            ControlPieces.Split,
            ControlPieces.Structure,
            ControlPieces.StaticData,
            ControlPieces.GetAffiliation,
            ControlPieces.Status,
            ControlPieces.Translate,
            ControlPieces.Format,
            ControlPieces.GetEntities,
        ],
    },
    'Constants': {
        color: '#efaa4b',
        pieces: [
            ConstantPieces.Today,
            ConstantPieces.Now,
            ConstantPieces.True,
            ConstantPieces.TrueHexagonal,
            ConstantPieces.False,
            ConstantPieces.FalseHexagonal,
            ConstantPieces.LoggedInUser,
            ConstantPieces.FinancialYearMonths,
        ],
    },
    'Arithmetic Operators': {
        color: '#efaa4b',
        pieces: [
            ArithmeticOperatorsPieces.Subtract,
            ArithmeticOperatorsPieces.Add,
            ArithmeticOperatorsPieces.Multiply,
            ArithmeticOperatorsPieces.Divide,
            ArithmeticOperatorsPieces.Exponent,
            ArithmeticOperatorsPieces.Sequence,
        ],
    },
    'Boolean Operators': {
        color: '#efaa4b',
        pieces: [
            BooleanOperatorsPieces.LesserThan,
            BooleanOperatorsPieces.LesserThanOrEqualTo,
            BooleanOperatorsPieces.GreaterThan,
            BooleanOperatorsPieces.GreaterThanOrEqualTo,
            BooleanOperatorsPieces.EqualTo,
            BooleanOperatorsPieces.NotEqualTo,
            BooleanOperatorsPieces.In,
            BooleanOperatorsPieces.NotIn,
            BooleanOperatorsPieces.And,
            BooleanOperatorsPieces.Or,
            BooleanOperatorsPieces.Not,
            BooleanOperatorsPieces.VariableToBoolean,
            BooleanOperatorsPieces.BooleanToVariable,
            BooleanOperatorsPieces.IsDefined,
            BooleanOperatorsPieces.IsNotDefined
        ],
    },
    'List Operators': {
        color: '#efaa4b',
        pieces: [
            ListOperatorsPieces.PickFirstElement,
            ListOperatorsPieces.PickFirstNElements,
            ListOperatorsPieces.PickLastElement,
            ListOperatorsPieces.PickLastNElements,
            ListOperatorsPieces.PickNthElement,
            ListOperatorsPieces.SplitBySeparator,
            ListOperatorsPieces.AddToList,
            ListOperatorsPieces.RemoveFromList,
            ListOperatorsPieces.AddToTable,
            ListOperatorsPieces.Length,
        ],
    },
    'Date Operators': {
        color: '#efaa4b',
        pieces: [
            DateOperatorsPieces.AddMonths,
            DateOperatorsPieces.AddYears,
            DateOperatorsPieces.SubtractMonths,
            DateOperatorsPieces.SubtractYears,
            DateOperatorsPieces.GetDate,
            DateOperatorsPieces.GetDay,
            DateOperatorsPieces.GetMonth,
            DateOperatorsPieces.GetReadableMonth,
            DateOperatorsPieces.GetYear,
            DateOperatorsPieces.GetTimeDifference,
        ],
    },
    'Variables': {
        color: '#8891c8',
        pieces: [
            VariablePieces.Variable,
            VariablePieces.SetVariable,
            VariablePieces.StyleTableVariable,
        ],
    },
    'Custom Fields': {
        color: '#d289c0',
        pieces: [
            CustomFieldPieces.Get,
        ],
    },
}