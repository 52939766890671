import { IDBPDatabase, IDBPObjectStore, IDBPTransaction } from "idb";
import { ApplicationState } from "./types";

// Add filters for reports
async function migrateFiveToSix(snapshotStore: IDBPObjectStore<any, string[], "currentSnapshot">) {
    const keys = await snapshotStore.getAllKeys()

    for (const userId of keys) {
        const snapshot: ApplicationState = await snapshotStore.get(userId);
        snapshot.reports.filters = {
            projects: [],
            types: [],
            users: [],
            createdDateRange: [],
            lastUpdatedDateRange: [],
            generatedDateRange: [],
            archived: false,
            unsynced: false,
        };

        await snapshotStore.put(snapshot, userId);
    }
}

// Add last worked on filters for workflows
async function migrateEightToNine(snapshotStore: IDBPObjectStore<any, string[], "currentSnapshot">) {
    const keys = await snapshotStore.getAllKeys()

    for (const userId of keys) {
        const snapshot: ApplicationState = await snapshotStore.get(userId);
        snapshot.workflows.filters = {
            ...snapshot.workflows.filters,
            lastWorkedOn: {
                startTime: undefined,
                endTime: undefined,
            }
        };



        await snapshotStore.put(snapshot, userId);
    }
}

// Add default schedules state
async function migrateNineToTen(snapshotStore: IDBPObjectStore<any, string[], "currentSnapshot">) {
    const keys = await snapshotStore.getAllKeys();

    for (const userId of keys) {
        const snapshot = await snapshotStore.get(userId);
        snapshot.reports.schedules = {
            byId: {},
            allEntries: [],
            filteredEntries: [],
            selected: undefined,
            createdIds: new Set(),
            updatedIds: new Set(),
            deletedIds: new Set(),
        };
        await snapshotStore.put(snapshot, userId);
    }
}

export async function migrateDatabase(db: IDBPDatabase<any>, oldVersion: number, newVersion: number | null, transaction: IDBPTransaction<any>) {
    const snapshotStore = transaction.objectStore('currentSnapshot');

    if (oldVersion <= 5 && newVersion && newVersion >= 6) {
        await migrateFiveToSix(snapshotStore);
    }

    if (oldVersion <= 8 && newVersion && newVersion >= 9) {
        await migrateEightToNine(snapshotStore);
    }

    if (oldVersion <= 9 && newVersion && newVersion >= 10) {
        await migrateNineToTen(snapshotStore);
    }
}