import { Component } from 'react';
import styles from './List.module.scss';

import { connect } from 'react-redux';

import WorkflowsTable from './WorkflowsTable';

import { ApplicationState } from '../../../shared/store/types';
import { Permissions } from '../../../shared/store/permissions/types';

type OwnProps = {

};

const mapStateToProps = (state: ApplicationState) => {

    let isWritable = true;
    let isReadable = true;

    const isOnline = state.myData.isOnline

    const granularPermissions = new Set(Object.keys(state.permissions.myPermissions.workflows));
    const areAllTypesHandledByGranularPermissions = state.workflows.types.allEntries.every(typeId => granularPermissions.has(typeId));

    if (areAllTypesHandledByGranularPermissions && Object.keys(state.permissions.myPermissions.workflows).length > 0) {
        isWritable = Object.keys(state.permissions.myPermissions.workflows).some(workflowTypeId => {
            return state.permissions.myPermissions.workflows[workflowTypeId] === Permissions.WRITE;
        });

        isReadable = isWritable || Object.keys(state.permissions.myPermissions.workflows).some(workflowTypeId => {
            return state.permissions.myPermissions.workflows[workflowTypeId] === Permissions.READ;
        });
    }

    return {
        filtersExpanded: false,
        read: isReadable,
        write: isWritable,
        isOnline,
        isSettled: (state.myData.isLoaded || state.myData.isPartiallyLoaded) && !state.myData.isPushingData,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = StateProps & OwnProps;

class ConnectedWorkflows extends Component<Props> {

    render() {

        if (!this.props.isSettled) {
            return <section>
            </section>
        }

        return (
            <section>
                <div className={this.props.filtersExpanded ? styles.tableArea : styles.expandedTableArea}>
                    <WorkflowsTable isReadOnly={this.props.isOnline || !this.props.write} />
                </div>
            </section>
        );
    }

};

const Workflows = connect(mapStateToProps)(ConnectedWorkflows);

export default Workflows;