import React, { Component, FocusEvent, ChangeEvent } from 'react';
import styles from './Translations.module.scss';
import { Redirect } from "react-router-dom";

import { updateTranslation, updateMultipleTranslations, setLanguageSearchTerm, setShowUntranslated } from '../../shared/store/internationalization/translations/actions';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../shared/store/types';
import { Permissions } from '../../shared/store/permissions/types';
import { getTranslateableWords, translatePhrase } from '../../shared/helpers/translation';
import Toggle from '../../widgets/form/Toggle';
import Papa from 'papaparse';
import InputText from '../../widgets/form/InputText';
import Button from '../../widgets/button/CommonButton';

type OwnProps = {
    languageId: string,
};

type OwnState = {
    index: number,
    allTranslatablePairs: {
        masterSetWithoutDuplicates: Set<string[]>;
        duplicateTracker: string[][];
    } | undefined,
    originalPairs: string[][],
    translatablePairs: string[][],
    duplicatePairs: string[][]
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    let canEditLanguage = state.permissions.myPermissions.general.Languages === Permissions.WRITE;
    let canViewLanguage = canEditLanguage || state.permissions.myPermissions.general.Languages === Permissions.READ;

    if (ownProps.languageId in state.permissions.myPermissions.languages) {
        canEditLanguage = state.permissions.myPermissions.languages[ownProps.languageId] === Permissions.WRITE;
        canViewLanguage = canEditLanguage || state.permissions.myPermissions.languages[ownProps.languageId] === Permissions.READ;
    }

    return {
        isReadable: canViewLanguage,
        isWritable: canEditLanguage,
        applicationState: state,
        translations: state.internationalization.translations.byLanguage[ownProps.languageId] || {},
        language: state.internationalization.languages.byId[ownProps.languageId],
        searchTerm: state.internationalization.translations.searchTerm,
        showUntranslated: state.internationalization.translations.showUntranslated
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateTranslation: (originalWord: string, translatedWord: string, languageId: string) => dispatch(updateTranslation(originalWord, translatedWord, languageId)),
        updateMultipleTranslations: (translations: Array<{ originalWord: string, translatedWord: string }>, languageId: string) => dispatch(updateMultipleTranslations(translations, languageId)),
        setLanguageSearchTerm: (searchTerm: string) => dispatch(setLanguageSearchTerm(searchTerm)),
        setShowUntranslated: (flag: boolean) => dispatch(setShowUntranslated(flag)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

class ConnectedLocations extends Component<Props, OwnState> {
    state: OwnState = {
        index: 10,
        allTranslatablePairs: undefined,
        originalPairs: [],
        translatablePairs: [],
        duplicatePairs: []
    }

    changeTranslation = (originalWord: string, e: FocusEvent<HTMLInputElement>) => {
        const translation = e.currentTarget.value;

        this.props.updateTranslation(originalWord, translation, this.props.languageId);
    }

    importTranslations = (importData: Array<Array<string>>) => {

        const dataWithoutHeader = importData.slice(1);
        let translations: Array<{ originalWord: string, translatedWord: string }> = [];

        for (const rowData of dataWithoutHeader) {
            if (rowData.length >= 2) {
                const originalPhrase = rowData[0];
                const translation = rowData[1];

                if (!!translation) {
                    translations.push({
                        originalWord: originalPhrase,
                        translatedWord: translation,
                    });
                }
            }
        }

        this.props.updateMultipleTranslations(translations, this.props.languageId);
    }

    handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        const file = !!e.target.files ? e.target.files[0] : undefined;

        if (!!file) {
            Papa.parse(file, {
                complete: (results) => {
                    const csvData = results.data as Array<Array<string>>;
                    this.importTranslations(csvData);
                }
            });
        }
    }

    componentDidMount(): void {
        const aggregatedData = getTranslateableWords(this.props.applicationState);
        let originalPairs = Array.from(aggregatedData.masterSetWithoutDuplicates),
            translatablePairs = originalPairs,
            duplicatePairs = Array.from(aggregatedData.duplicateTracker);

        this.setState({
            allTranslatablePairs: aggregatedData,
            originalPairs,
            translatablePairs,
            duplicatePairs
        });

        setTimeout(() => {
            if (this.props.searchTerm) {
                this.searchLanguages();
            }
        }, 100);
    }

    loadMore = () => {
        this.setState(prev => {
            return {
                index: prev.index + 10
            }
        });
    }

    setSearchTerm = (term: string) => {
        this.props.setLanguageSearchTerm(term);

        setTimeout(() => {
            this.searchLanguages();
        }, 100);
    }

    toggleWordDisplay = () => {
        this.props.setShowUntranslated(!this.props.showUntranslated);
    }

    searchLanguages = () => {
        if (this.props.searchTerm && this.props.searchTerm.toString().trim().length > 0) {
            this.setState({
                translatablePairs: this.state.originalPairs.filter(pair => {
                    return pair[0].toLowerCase().includes(this.props.searchTerm ? this.props.searchTerm.toString().toLowerCase().trim() : '') ||
                        pair[1].toLowerCase().includes(this.props.searchTerm ? this.props.searchTerm.toString().toLowerCase().trim() : '')
                })
            });
        } else {
            this.setState({
                translatablePairs: this.state.originalPairs
            });
        }
    }

    // componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<OwnState>): void {
    //     if (prevState.index !== this.state.index) {
    //         this.setState({
    //             translatablePairs: this.state.originalPairs.slice(0, this.state.index)
    //         });
    //     }
    // }

    render() {
        if (!this.props.isReadable) {
            return <Redirect to="/dashboard" />;
        }

        return <div>
            <input type="file" accept=".csv, text/csv" id="import-file-input" className={styles.hiddenFile} onChange={this.handleFileUpload} />

            <div className={styles.container}>
                <div className={styles.searchInputHolder}>
                    <InputText type="search" default={this.props.searchTerm} placeholder={translatePhrase('Search within the list below')} onChange={this.setSearchTerm} />
                </div>
                <div className={styles.translationOptions}>
                    <section className={styles.toggleFilter}>
                        <div className={styles.text}>Show Untranslated</div>
                        <Toggle toggleId="showing-all-words" isOn={!this.props.showUntranslated} onToggle={this.toggleWordDisplay} />
                        <div className={styles.text}>Show All</div>
                    </section>
                </div>
            </div>

            <section className={styles.translationMasterCard}>
                <div className={styles.header}>
                    <section className={styles.headerCell}>Original phrase</section>
                    <section className={styles.headerCell}>Translated phrase</section>
                </div>
                <div className={styles.body}>
                    {!this.props.showUntranslated && <React.Fragment>
                        {this.state.translatablePairs.slice(0, this.state.index)
                            .map((translatablePair, key) => {
                                const originalPhrase = translatablePair[0];
                                let additionalPairs: Array<string> = [];

                                this.state.duplicatePairs.forEach(pair => {
                                    if (pair[0].includes(originalPhrase)) {
                                        additionalPairs.push(pair[1])
                                    }
                                });

                                const data = Array.from(new Set([...additionalPairs]));

                                return <div key={key} className={styles.translationRow}>
                                    <section className={styles.originalCell}>
                                        <section className={styles.originalPhrase}>{originalPhrase}</section>
                                        {data.map((pair, key) => <section key={key} className={styles.context}> {pair} </section>)}
                                    </section>
                                    <section className={styles.translatedCell}>
                                        <input type="text" key={this.props.searchTerm + ' ' + this.state.translatablePairs} disabled={!this.props.isWritable} className={styles.translationInput} defaultValue={this.props.translations[originalPhrase]} onBlur={this.changeTranslation.bind(this, originalPhrase)} placeholder="Enter translation here" />
                                    </section>
                                </div>
                            })}
                    </React.Fragment>}

                    {this.props.showUntranslated && <React.Fragment>
                        {this.state.translatablePairs.filter((pair) => {
                            return !this.props.translations[pair[0]];
                        }).slice(0, this.state.index)
                            .map((translatablePair, key) => {
                                const originalPhrase = translatablePair[0];
                                let additionalPairs: Array<string> = [];

                                this.state.duplicatePairs.forEach(pair => {
                                    if (pair[0].includes(originalPhrase)) {
                                        additionalPairs.push(pair[1])
                                    }
                                });

                                const data = Array.from(new Set([...additionalPairs]));

                                return <div key={key} className={styles.translationRow}>
                                    <section className={styles.originalCell}>
                                        <section className={styles.originalPhrase}>{originalPhrase}</section>
                                        {data.map((pair, key) => <section key={key} className={styles.context}> {pair} </section>)}
                                    </section>
                                    <section className={styles.translatedCell}>
                                        <input type="text" key={this.props.searchTerm + ' ' + this.state.translatablePairs} disabled={!this.props.isWritable} className={styles.translationInput} defaultValue={this.props.translations[originalPhrase]} onBlur={this.changeTranslation.bind(this, originalPhrase)} placeholder="Enter translation here" />
                                    </section>
                                </div>
                            })}
                    </React.Fragment>}

                    {!this.props.searchTerm && <div className={styles.moreButton}>
                        <Button onClick={this.loadMore} text={translatePhrase('More')} isRounded isBlock />
                    </div>}
                </div>
            </section>
        </div>

    }
}

const Locations = connect(mapStateToProps, mapDispatchToProps)(ConnectedLocations);

export default Locations;