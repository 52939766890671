import { createSelector } from 'reselect';
import { filterReports } from '../../../shared/helpers/filters';
import store from '../../../shared/store/main';
import { ApplicationState } from '../../../shared/store/types';;

const getReportState = (state: ApplicationState) => state.reports.byId;
const getSearchTerm = (state: ApplicationState) => state.reports.searchTerm;
const getCurrentPageNumber = (state: ApplicationState) => state.reports.currentPageNumber;
const getPageSize = (state: ApplicationState) => state.reports.pageSize;
const getFilters = (state: ApplicationState) => state.reports.filters;

export const getFilteredReports = createSelector(
    [getReportState, getCurrentPageNumber, getPageSize, getSearchTerm, getFilters],
    (reportState, currentPageNumber, pageSize, searchTerm, filters) => {
        const state = store.getState();

        const filteredReports = filterReports(state);
        return filteredReports;

    }
)