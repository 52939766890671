import React, { ChangeEvent, Component, MouseEvent } from 'react';
import styles from './ReportBug.module.scss';
import onClickOutside from "react-onclickoutside";

import { ReactComponent as BugIcon } from '../../common/assets/bug.svg';

import Button from '../../widgets/button/CommonButton';
import { translatePhrase } from '../../shared/helpers/translation';
import InputText from '../../widgets/form/InputText';
import axios from 'axios';
import { BASE_URL } from '../../shared/store/url';
import { IAppError } from '../../shared/store/errors/types';
import { ApplicationState } from '../../shared/store/types';
import { Dispatch } from 'redux';
import { clearIndeterminateMessage, setIndeterminateMessage } from '../../shared/store/my-data/actions';
import { connect } from 'react-redux';
import { collectDataToPush } from '../../shared/helpers/synchronize';
import store from '../../shared/store/main';
import { getTimeoutPromise } from '../../shared/helpers/utilities';

import { ReactComponent as CloseIcon } from '../../assets/action-icons/cancel.svg';

type OwnProps = {
    isMaintainanceModeOn?: boolean,
    checkForMaintenanceMode: () => Promise<void>
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        errorState: state.errors,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setIndeterminateMessage: (message: string) => dispatch(setIndeterminateMessage(message)),
        clearIndeterminateMessage: () => dispatch(clearIndeterminateMessage()),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

type State = {
    showingForm: boolean;
    title: string;
    description: string;
    fileName: string;
    formKey: number;
}

class ConnectedtedReportBug extends Component<Props, State> {
    state = {
        showingForm: false,
        title: '',
        description: '',
        fileName: '',
        formKey: 0,
    }

    handleClickOutside = (event: MouseEvent) => {
        this.setState({
            showingForm: false,
            title: '',
            description: '',
        });
    }

    changeTitle = (changedValue: string) => {
        this.setState({
            title: changedValue,
        });
    }

    changeDescription = (changedValue: string) => {
        this.setState({
            description: changedValue,
        });
    }

    toggleForm = async () => {
        await this.props.checkForMaintenanceMode();
        if (this.props.isMaintainanceModeOn) {
            return;
        }
        this.setState(prevState => {
            return {
                showingForm: !prevState.showingForm,
            };
        });
    }

    startAttachmentUpload = () => {

        const fileElement = document.getElementById('report-bug-file');
        if (!!fileElement) {
            fileElement.click();
        }
    }

    getErrors = () => {
        const localErrorEntries: Array<IAppError> = !localStorage.getItem('errors') ? [] : JSON.parse(localStorage.getItem('errors') || '[]');
        const lastTenErrorIds = this.props.errorState.allEntries.slice(Math.max(this.props.errorState.allEntries.length - 10)).filter(errorId => !localErrorEntries.find(errorEntry => errorEntry.id === errorId));

        return lastTenErrorIds.map(errorId => this.props.errorState.byId[errorId]).concat(localErrorEntries).sort((a, b) => {
            if (a.createdTime < b.createdTime) {
                return 1;
            } else if (a.createdTime > b.createdTime) {
                return -1;
            } else {
                return 0;
            }
        });
    }

    storeFile = () => {

        const fileInput = document.getElementById('report-bug-file') as HTMLInputElement;

        if (fileInput.files && fileInput.files.length > 0) {
            this.setState({
                fileName: fileInput.files[0].name,
            });
        }

    }

    sendReport = async () => {

        this.props.setIndeterminateMessage('Sending bug report');

        await getTimeoutPromise(1000);

        const fileInput = document.getElementById('report-bug-file') as HTMLInputElement;

        let fileUrl = '';

        if (fileInput.files && fileInput.files.length > 0) {
            const fileToUpload = fileInput.files[0];
            var formData = new FormData();
            formData.append('file', fileToUpload);

            const uploadUrl = BASE_URL + '/file-upload/';

            const fileUploadResponse = await axios.post(uploadUrl, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                }
            });

            if (fileUploadResponse.data && typeof fileUploadResponse.data === 'string') {
                fileUrl = BASE_URL + '/file-upload/' + fileUploadResponse.data;
            }
        }

        const heapLimit: number = (window as any).performance.memory.jsHeapSizeLimit;
        const heapSize: number = (window as any).performance.memory.totalJSHeapSize;
        const usedSize: number = (window as any).performance.memory.usedJSHeapSize;
        const deviceMemory: number = (window as any).navigator.deviceMemory;

        const errors = this.getErrors();
        const dataDelta = collectDataToPush(store.getState());

        const dataToSend = {
            title: this.state.title,
            description: this.state.description,
            delta: dataDelta.data,
            fileUrl: '',
            syncLogs: errors,

            heapLimit,
            heapSize,
            usedSize,
            deviceMemory,
        };

        if (fileUrl) {
            dataToSend.fileUrl = fileUrl;
        }

        await axios.post(BASE_URL + '/report-bug/', dataToSend, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });

        this.props.clearIndeterminateMessage();

        this.clearFile();

        this.setState({
            showingForm: false,
            title: '',
            description: '',
        });
    }

    clearFile = () => {
        const fileInput = document.getElementById('report-bug-file') as HTMLInputElement;
        fileInput.value = '';

        this.setState({
            fileName: '',
        });
    }

    render() {
        return <section className={this.state.showingForm ? styles.showingFormContainer : styles.bugReportContainer}>
            {this.state.showingForm && <section key={this.state.formKey} className={styles.reportForm}>

                <div className={styles.inputHolder}>
                    <label> {translatePhrase('Title')}: </label>
                    <InputText type="text" onChange={this.changeTitle} />
                </div>

                <div className={styles.inputHolder}>
                    <label> {translatePhrase('Description')}: </label>
                    <InputText type="textarea" onChange={this.changeDescription} />
                </div>

                <div className={styles.inputHolder}>
                    <label> {translatePhrase('Screenshot')}: </label>
                    <input type="file" id="report-bug-file" onChange={this.storeFile} />

                    {!this.state.fileName && <Button type="primary" text="Attach" isBlock isRounded size="small" padding="0 10px" onClick={this.startAttachmentUpload} />}
                    {this.state.fileName && <Button type="tertiary" icon={<CloseIcon />} text={this.state.fileName} isBlock isRounded size="small" padding="0 10px" onClick={this.clearFile} />}
                </div>

                <div className={styles.submitButton}>
                    <Button type="primary" isRounded isBlock title="Send" text="Send" onClick={this.sendReport} />
                </div>

            </section >}
            <div className={`${styles.buttonHolder} ${this.props.isMaintainanceModeOn && styles.isDisabled}`}>
                <Button isDisabled={this.props.isMaintainanceModeOn} title={this.state.showingForm ? translatePhrase('Close') : translatePhrase('Report a bug')} size="small" icon={this.state.showingForm ? <CloseIcon /> : <BugIcon />} type={this.state.showingForm ? 'primary' : 'secondary'} isBlock={false} isRounded={true} onClick={this.toggleForm} />
                <p className={styles.reportBugText} onClick={this.toggleForm} title={translatePhrase('Report a bug')}>{translatePhrase('Report a bug')}</p>
            </div>
        </section >
    }
}

const ReportBug = connect(mapStateToProps, mapDispatchToProps)(ConnectedtedReportBug);


export default ReportBug;