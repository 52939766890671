import React, { Component } from 'react';
import styles from './ReportFilter.module.scss';

import { ReactComponent as SmartFilterIcon } from '../../../assets/new-custom-icons/dashboard/smartfilter.svg';
import { ReactComponent as CancelIcon } from '../../../common/assets/close.svg';
import { ReactComponent as CheckIcon } from '../../../assets/new-custom-icons/profile/check-mark.svg';
import { ReactComponent as FilterIcon } from '../../../common/assets/filter.svg';
import { ReactComponent as PlusIcon } from '../../../assets/new-custom-icons/dashboard/plus.svg';

import { translatePhrase } from '../../../shared/helpers/translation';

import { ApplicationState } from '../../../shared/store/types';

import { filterReportTable } from '../../../shared/store/reports/actions';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import InputText from '../../../widgets/form/InputText';
import MultiSelectInputText from '../../../widgets/form/MultiSelectInput';
import moment from 'moment';
import Button from '../../../widgets/button/CommonButton';
import DateInput from '../../../widgets/form/DateInput';
import { getReadableDataForCustomField } from '../../../shared/store/custom-fields';

import onClickOutside from "react-onclickoutside";
import { ReportSmartFilters, SmartFilter } from '../../../shared/helpers/smart-filters';
import { ReportFilters } from '../../../shared/store/reports/types';
import LoaderModal from '../../../widgets/loader/LoaderModal';
import { getVisibleUserIdsSelector } from '../../../helpers/selectors';

type OwnProps = {
    closeFilter: () => void,
    tags: Array<string>,
    reportFilters: ReportFilters,
    getSmartFilter?: (smartFilter: string) => void,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    const allAllowedProjects = state.structure.projects.allEntries;

    let allAllowedLocations: Array<string> = []

    allAllowedProjects.forEach(projectId => {
        const projectData = state.structure.projects.byId[projectId];
        const leafLevelIndex = projectData.children.map(levelId => state.structure.levels.byId[levelId]).filter(level => !level.archived).length - 1;

        let locations = state.structure.locations.byProject[projectId].map(locationId => state.structure.locations.byId[locationId]).filter(location => !!location);

        for (let i = 0; i < leafLevelIndex; i += 1) {
            let newLocations: Array<string> = [];

            locations.forEach(location => {
                if (location.children && location.children.length > 0) {
                    newLocations = newLocations.concat(location.children);
                }
            });

            locations = newLocations.map(locationId => state.structure.locations.byId[locationId]).filter(location => !!location);
        }
        allAllowedLocations = allAllowedLocations.concat(locations.map(location => location.id));
    });

    const visibleUserIds = getVisibleUserIdsSelector(state);

    return {
        projectsData: state.structure.projects,
        levelsData: state.structure.levels,
        rolesData: state.structure.roles,
        usersData: state.users,
        locationsData: state.structure.locations,
        reportsData: state.reports,
        roleCustomFieldsData: state.structure.roles.customFields,
        roleCustomFieldOptionsData: state.structure.roles.customFieldOptions,
        allAllowedLocations,
        visibleUserIds,
        myId: state.myData.id,

        filters: state.reports.filters,
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        filterReportTable: (projects: Array<string>, types: Array<string>, users: Array<string>, createdDateRange: Array<string>, lastUpdatedDateRange: Array<string>, generatedDateRange: Array<string>, unsynced: boolean, archived: boolean) => dispatch(filterReportTable(projects, types, users, createdDateRange, lastUpdatedDateRange, generatedDateRange, archived, unsynced)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;


type OwnState = {
    projects: Array<string>,
    types: Array<string>,
    users: Array<string>,
    toastLoader: JSX.Element | undefined,

    createdDateRange: Array<string>,
    lastUpdatedDateRange: Array<string>,
    generatedDateRange: Array<string>,

    archived: boolean,
    unsynced: boolean,

    clearKey: number,
    appliedSmartFilter: string,
};

class ConnectedReportModify extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            projects: props.filters.projects,
            types: props.filters.types,
            users: props.filters.users,
            createdDateRange: props.filters.createdDateRange,
            lastUpdatedDateRange: props.filters.lastUpdatedDateRange,
            generatedDateRange: props.filters.generatedDateRange,
            archived: props.filters.archived,
            unsynced: props.filters.unsynced,
            appliedSmartFilter: '',
            clearKey: 0,
            toastLoader: undefined,
        };
    }

    changeProjects = (projects: Array<string>) => {
        this.setState({
            projects,
            types: [],
        });
    }

    changeTypes = (types: Array<string>) => {
        this.setState({
            types,
        });
    }

    changeUsers = (users: Array<string>) => {
        this.setState({
            users,
        });
    }

    changeArchived = (archived: boolean) => {

        this.setState({
            archived,
        });

        setTimeout(() => {
            this.submitFilters();
        }, 100);
    }

    changeUnsynced = (unsynced: boolean) => {

        this.setState({
            unsynced,
        });

        setTimeout(() => {
            this.submitFilters();
        }, 100);
    }

    changeGeneratedFromDate = (value: string) => {
        this.setState(prevState => {
            const newDateRange = [value];
            if (prevState.generatedDateRange.length > 1) {
                newDateRange.push(prevState.generatedDateRange[1]);
            } else {
                newDateRange.push(value);
            }

            return {
                generatedDateRange: newDateRange,
            };
        });
    }

    changeGeneratedToDate = (value: string) => {
        this.setState(prevState => {
            const newDateRange = [];
            if (prevState.generatedDateRange.length > 0) {
                newDateRange.push(prevState.generatedDateRange[0]);
            } else {
                newDateRange.push(value);
            }

            newDateRange.push(value);

            return {
                generatedDateRange: newDateRange,
            };
        });
    }

    submitFilters = () => {
        let prevFilters = {
            projects: this.state.projects,
            types: this.state.types,
            users: this.state.users,
            createdDateRange: this.state.createdDateRange,
            lastUpdatedDateRange: this.state.lastUpdatedDateRange,
            generatedDateRange: this.state.generatedDateRange,
            unsynced: this.state.unsynced,
            archived: this.state.archived,
        }

        if (JSON.stringify(prevFilters) !== JSON.stringify(this.props.filters)) {
            this.showToastLoader(translatePhrase("Please wait"));

            setTimeout(() => {
                this.props.filterReportTable(
                    this.state.projects,
                    this.state.types,
                    this.state.users,
                    this.state.createdDateRange,
                    this.state.lastUpdatedDateRange,
                    this.state.generatedDateRange,
                    this.state.unsynced,
                    this.state.archived,
                );

                if (JSON.stringify(prevFilters) === JSON.stringify(this.props.filters)) {
                    this.setState((prevState) => {
                        return {
                            toastLoader: undefined,
                            clearKey: prevState.clearKey + 1
                        }
                    });
                }
            }, 500);
        }
    }

    showToastLoader = (text: string) => {
        this.setState({
            toastLoader: <LoaderModal loaderText={[text]} isIndeterminate />
        });
    }

    clearFilters = () => {
        this.props.filterReportTable([], [], [], [], [], [], false, false);
        if (this.props.getSmartFilter) {
            this.props.getSmartFilter("");
        }
        this.setState(prevState => {
            return {
                projects: [],
                types: [],
                users: [],
                createdDateRange: [],
                lastUpdatedDateRange: [],
                generatedDateRange: [],
                archived: false,
                unsynced: false,

                clearKey: prevState.clearKey + 1,
            }
        });
        setTimeout(() => {
            this.submitFilters();
        }, 100);
    }

    handleClickOutside = (event: MouseEvent) => {
        this.props.closeFilter();
    }

    selectSmartFilter = (filter: SmartFilter) => {
        if (filter.name === this.state.appliedSmartFilter) {
            this.removeSmartFilter();
            return;
        }
        this.setState({ appliedSmartFilter: filter.name });
        this.changeGeneratedFromDate(filter.dateRange.from);
        this.changeGeneratedToDate(filter.dateRange.to);
        setTimeout(() => {
            if (this.props.getSmartFilter) {
                this.props.getSmartFilter(filter.name);
            }
        }, 200);
        setTimeout(() => {
            this.submitFilters();
        }, 100);
    }

    checkIfSmartFilterIsApplied = () => {
        if (this.props.reportFilters.generatedDateRange) {
            console.log(this.props.reportFilters.generatedDateRange)
            let lastWorkedOnFromDate = moment(this.props.reportFilters.generatedDateRange[0]).format("YYYY-MM-DD");
            let lastWorkedOnToDate = moment(this.props.reportFilters.generatedDateRange[1]).format("YYYY-MM-DD");

            for (const filter of ReportSmartFilters) {
                if (lastWorkedOnFromDate === filter.dateRange.from && lastWorkedOnToDate === filter.dateRange.to) {
                    this.setState({ appliedSmartFilter: filter.name });
                }
            }
        };
    };

    removeSmartFilter = () => {
        if (this.state.appliedSmartFilter) {
            this.clearFilters();
            this.setState({ appliedSmartFilter: "" });
            if (this.props.getSmartFilter) {
                this.props.getSmartFilter("");
            }
        }
    }

    componentDidMount() {
        this.checkIfSmartFilterIsApplied();
    }

    render() {

        const projectsList = this.props.projectsData.allEntries.map(projectId => {
            return {
                name: this.props.projectsData.byId[projectId].name,
                value: projectId,
            };
        });

        const typesList = this.props.reportsData.types.allEntries
            .filter(typeId => this.state.projects.length === 0 || this.state.projects.includes(this.props.reportsData.types.byId[typeId].project))
            .map(typeId => {
                return {
                    name: this.props.reportsData.types.byId[typeId].name,
                    value: typeId,
                };
            });

        const allAllowedProjects: Set<string> = new Set();

        for (const projectId of this.state.projects) {
            allAllowedProjects.add(projectId);
        }

        for (const typeId of this.state.types) {
            const type = this.props.reportsData.types.byId[typeId];
            allAllowedProjects.add(type.project);
        }

        let reportType = this.state.types.length === 1 ? this.props.reportsData.types.byId[this.state.types[0]] : undefined;
        let customFieldsMarkup: Array<JSX.Element> | undefined;

        const usersList = this.props.visibleUserIds
            .map(userId => {
                const user = this.props.usersData.byId[userId];
                const nameField = this.props.usersData.customFields.byId[this.props.usersData.nameFieldId];
                const name = getReadableDataForCustomField(user.customFields[nameField.id], nameField, userId, 'user');

                return {
                    name,
                    value: userId,
                };
            });

        const smartFilters = ReportSmartFilters.map(filter => {
            return (
                <div key={filter.name}
                    title={translatePhrase(filter.name)}
                    className={styles.filterTag + " " + (this.state.appliedSmartFilter === filter.name ? styles.active : "")}
                    onClick={() => this.selectSmartFilter(filter)}>
                    <div className={styles.filterTerm}>{translatePhrase(filter.name)}</div>
                    {this.state.appliedSmartFilter !== filter.name &&
                        <div className={styles.plusIcon}>
                            <PlusIcon />
                        </div>
                    }
                    {this.state.appliedSmartFilter === filter.name &&
                        <div className={styles.closeIcon}>
                            <CancelIcon />
                        </div>
                    }
                </div>
            )
        });

        return (
            <section className={styles.commonModalHolder + ' ' + styles.filterHolder}>
                <div className={styles.filterCloseButton} onClick={this.props.closeFilter}>
                    <Button title={translatePhrase("Close")} icon={<CancelIcon />} size={'small'} isRounded />
                </div>

                <section className={styles.addOrModifyListCard}>

                    <header>
                        <h2> <FilterIcon /> {translatePhrase('Filter')}</h2>
                        <Button onClick={this.clearFilters} type={'tertiary'} text={translatePhrase('Clear Filter')} isRounded={true} size={'small'} padding={'0px 10px'} />
                    </header>
                    <div className={styles.smartAndAdvancedFilterContainer}>
                        <div className={styles.smartFilter}>
                            <h4> <SmartFilterIcon />  {translatePhrase('Quick Filters')} </h4>
                            {smartFilters}
                        </div>

                        <div className={styles.container}>
                            <div key={this.state.clearKey} className={styles.allInputsHolder}>
                                <header className={styles.groupInputHeading}>
                                    <h4> {translatePhrase("Structure")} </h4>
                                </header>
                                <section className={styles.groupInputs}>
                                    <div className={styles.inputSegment} onMouseLeave={this.submitFilters}>
                                        <MultiSelectInputText
                                            options={projectsList}
                                            onChange={this.changeProjects}
                                            default={this.state.projects}
                                            placeholder={translatePhrase('Projects')}
                                            isAutoFocus={true}
                                        />
                                    </div>
                                    <div className={styles.inputSegment} onMouseLeave={this.submitFilters}>
                                        <MultiSelectInputText
                                            options={typesList}
                                            onChange={this.changeTypes}
                                            default={this.state.types}
                                            placeholder={translatePhrase('Types')}
                                            isAutoFocus={true}
                                        />
                                    </div>
                                    <div className={styles.inputSegment} onMouseLeave={this.submitFilters}>
                                        <MultiSelectInputText
                                            options={usersList}
                                            onChange={this.changeUsers}
                                            default={this.state.users}
                                            placeholder={translatePhrase('Users')}
                                            isAutoFocus={true}
                                        />
                                    </div>
                                </section>

                                {customFieldsMarkup && <React.Fragment>
                                    <header className={styles.groupInputHeading}>
                                        <h4> {translatePhrase("Type Fields")}  </h4>
                                    </header>
                                    <section className={styles.groupInputs}>
                                        {customFieldsMarkup}
                                    </section>
                                </React.Fragment>}

                                <header className={styles.groupInputHeading}>
                                    <h4> {translatePhrase("Dates")} </h4>
                                </header>
                                <section className={styles.groupInputs}>
                                    <div onMouseLeave={this.submitFilters} className={styles.inputSegment} title={translatePhrase('Generated Date') + ' (' + translatePhrase('From') + ')'}>
                                        <DateInput placeholder={translatePhrase('Generated Date') + ' (' + translatePhrase('From') + ')'}
                                            default={this.state.generatedDateRange.length > 0 && moment(this.state.generatedDateRange[0], 'YYYY-MM-DD', true).isValid() ? new Date(this.state.generatedDateRange[0]) : undefined}
                                            onChange={(e) => this.changeGeneratedFromDate(e)} />
                                    </div>
                                    <div onMouseLeave={this.submitFilters} className={styles.inputSegment} title={translatePhrase('Generated Date') + ' (' + translatePhrase('To') + ')'}>
                                        <DateInput placeholder={translatePhrase('Generated Date') + ' (' + translatePhrase('To') + ')'}
                                            default={this.state.generatedDateRange.length > 1 && moment(this.state.generatedDateRange[1], 'YYYY-MM-DD', true).isValid() ? new Date(this.state.generatedDateRange[1]) : undefined}
                                            onChange={(e) => this.changeGeneratedToDate(e)} />
                                    </div>
                                </section>

                                <header className={styles.groupInputHeading}>
                                    <h4> {translatePhrase("Misc")} </h4>
                                </header>
                                <section className={styles.groupInputs}>
                                    <div className={styles.inputSegment}>
                                        <InputText isBooleanField={true} placeholder={translatePhrase('Unsynced')} onChange={value => this.changeUnsynced(value === 'Yes')}
                                            defaultBooleanValue={this.props.filters.unsynced ? true : false} />
                                    </div>

                                    <div className={styles.inputSegment}>
                                        <InputText isBooleanField={true} placeholder={translatePhrase('Archived')} onChange={value => this.changeArchived(value === 'Yes')}
                                            defaultBooleanValue={this.props.filters.archived ? true : false} />
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
                {this.state.toastLoader}
            </section>
        );
    }
}

const EnhancedReportModify = onClickOutside(ConnectedReportModify, {
    excludeScrollbar: true
});

const ReportModify = connect(mapStateToProps, mapDispatchToProps)(EnhancedReportModify);

export default ReportModify;
