import axios from "axios";
import moment from "moment";
import { getWorkflowPieceValue } from "../../store/flowchart/helpers/workflow";
import { IFinsalLoanProcessPiece } from "../../store/flowchart/pieces/types";
import { FinsalCredentials } from "../../store/organization/types";
import { ApplicationState } from "../../store/types";
import { BASE_URL } from "../../store/url";
import { WorkflowProcessState } from "../../store/workflows/types";

export async function makeLoanProcessCall(piece: IFinsalLoanProcessPiece, workflowId: string, processState: WorkflowProcessState, applicationState: ApplicationState) {

    if (!navigator.onLine) {
        return 'No internet connection';
    }

    const getPieceValueShortHand = getWorkflowPieceValue.bind({}, applicationState, processState, workflowId);

    const finsalPremium = piece.premium ? getPieceValueShortHand(piece.premium) : undefined;
    const finsalMember = piece.member ? getPieceValueShortHand(piece.member) : undefined;

    const finsalMemberFirstName = piece.memberFirstName ? getPieceValueShortHand(piece.memberFirstName) : undefined;
    const finsalMemberLastName = piece.memberLastName ? getPieceValueShortHand(piece.memberLastName) : undefined;
    const finsalMemberEmail = piece.memberEmail ? getPieceValueShortHand(piece.memberEmail) : undefined;
    const finsalMemberPhone = piece.memberPhone ? getPieceValueShortHand(piece.memberPhone) : undefined;
    const finsalMemberPan = piece.memberPan ? getPieceValueShortHand(piece.memberPan) : undefined;
    const finsalMemberState = piece.memberState ? getPieceValueShortHand(piece.memberState) : undefined;
    const finsalMemberCity = piece.memberCity ? getPieceValueShortHand(piece.memberCity) : undefined;


    const finsalMemberAddressLine1 = piece.memberAddressLine1 ? getPieceValueShortHand(piece.memberAddressLine1) : undefined;
    const finsalMemberAddressLine2 = piece.memberAddressLine2 ? getPieceValueShortHand(piece.memberAddressLine2) : undefined;
    const finsalMemberPinCode = piece.memberPinCode ? getPieceValueShortHand(piece.memberPinCode) : undefined;
    const finsalMemberDOB = piece.memberDob ? getPieceValueShortHand(piece.memberDob) : undefined;
    const finsalMemberGender = piece.memberGender ? getPieceValueShortHand(piece.memberGender) : undefined;
    const finsalMemberFatherName = piece.memberFatherName ? getPieceValueShortHand(piece.memberFatherName) : undefined;
    const finsalMemberMotherName = piece.memberMotherName ? getPieceValueShortHand(piece.memberMotherName) : undefined;
    const finsalMemberAnnualIncome = piece.memberAnnualIncome ? getPieceValueShortHand(piece.memberAnnualIncome) : undefined;
    const finsalMemberMaritalStatus = piece.memberMaritalStatus ? getPieceValueShortHand(piece.memberMaritalStatus) : undefined;

    const finsallIsApplyingForLoan = piece.applyForLoan ? !!getPieceValueShortHand(piece.applyForLoan) : false;

    const finsalUserEmail = piece.userEmail ? getPieceValueShortHand(piece.userEmail) : undefined;
    const finsalUserPhone = piece.userPhone ? getPieceValueShortHand(piece.userPhone) : undefined;



    if (!(finsalPremium && (typeof finsalPremium === 'string' || typeof finsalPremium === 'number') &&
        finsalMember && typeof finsalMember === 'string' &&
        finsalMemberFirstName && typeof finsalMemberFirstName === 'string' &&
        finsalMemberLastName && typeof finsalMemberLastName === 'string' &&
        finsalMemberEmail && typeof finsalMemberEmail === 'string' &&
        finsalMemberPhone && typeof finsalMemberPhone === 'string' &&
        finsalMemberPan && typeof finsalMemberPan === 'string' &&
        finsalUserEmail && typeof finsalUserEmail === 'string' &&
        finsalUserPhone && typeof finsalUserPhone === 'string')) {

        throw new Error('Finsal piece does not have enough data');
    }

    // Set the default Finsal credentials here
    let credentials: FinsalCredentials = {
        clientId: '2040',
        clientKey: '65f5be985016d93312694eafdc29aace86d4eaee11fa1027c5e056d1ff39c852',
        roles: 'executive',
        loggedInUniqueIdentifierId: '21',
        loggedInUserId: '5876',
        externalEntityNameId: '4',
        externalEntityTypeId: '5',
        policyName: 'APL Loan',

        authUsername: '5876',
        authToken: '94j5rJeCCGuakrmpdFKP/Qwpp/1tpaIO+sWwz2VnLf0=',
    };


    let finsalUrl = BASE_URL + '/finsall-staging/FinsAllServer/api';

    // Overwrite with org-specific finsal credentials here
    if (piece.mode === 'prod') {
        if (finsallIsApplyingForLoan) {
            if (!!applicationState.organization.prodFinsalCredentials) {
                credentials = applicationState.organization.prodFinsalCredentials;
            }
        } else {
            if (!!applicationState.organization.prodFinsalNoLoanCredentials) {
                credentials = applicationState.organization.prodFinsalNoLoanCredentials;
            }
        }
        finsalUrl = BASE_URL + '/finsall/FinsAllServer/api';
    } else {
        if (finsallIsApplyingForLoan) {
            if (!!applicationState.organization.uatFinsalCredentials) {
                credentials = applicationState.organization.uatFinsalCredentials;
            }
        } else {
            if (!!applicationState.organization.uatFinsalNoLoanCredentials) {
                credentials = applicationState.organization.uatFinsalNoLoanCredentials;
            }
        }
    }

    const fiveMinutesFromNow = String(moment().add(5, 'minutes').toDate().getTime());

    const request: any = {
        "serviceName": "BankSelectorService",
        "serviceMethod": "saveOrUpdateBankSelector",
        "clientId": credentials.clientId,
        "clientKey": credentials.clientKey,
        "version": "1.0.0",
        "roles": credentials.roles,
        "loggedInUniqueIdentifierId": credentials.loggedInUniqueIdentifierId,
        "loggedInUserId": credentials.loggedInUserId,

        "user": {
            "mobileNo": finsalMemberPhone.slice(-10),
            "emailId": finsalMemberEmail,
            "firstName": finsalMemberFirstName,
            "lastName": finsalMemberLastName,
            "state": finsalMemberState,
            "city": finsalMemberCity,
            "pan": finsalMemberPan,
        },
        "external_entity_name": {
            "externalEntityNameId": credentials.externalEntityNameId,
        },
        "external_entity_type": {
            "externalEntityTypeId": credentials.externalEntityTypeId,
        },
        "otherPremium": String(finsalPremium),
        "thirdPartyPremium": "0",
        "policyName": "APL Loan",
        "policyRefNumber": finsalMember + (finsallIsApplyingForLoan ? '-loan' : '-noloan'),
        "policyExpiryDate": fiveMinutesFromNow,
        "policyRenewalDate": fiveMinutesFromNow,
        "executiveMobileNo": finsalUserPhone.slice(-10),
        "executiveEmailId": finsalUserEmail,
        "referenceField1": finsalMember,
        "referenceField2": workflowId,
    };

    if (typeof finsalMemberAddressLine1 === 'string') {
        request["user"]["addressLine1"] = finsalMemberAddressLine1;
    }

    if (typeof finsalMemberAddressLine2 === 'string') {
        request["user"]["addressLine2"] = finsalMemberAddressLine2;
    }

    if (typeof finsalMemberPinCode === 'string') {
        request["user"]["pinCode"] = finsalMemberPinCode;
    }

    if (typeof finsalMemberDOB === 'string') {
        let dateValue = finsalMemberDOB;

        if (moment(finsalMemberDOB, 'YYYY-MM-DDTHH:mm:ss', true).isValid() ||
            moment(finsalMemberDOB, 'YYYY-MM-DD', true).isValid()) {
            dateValue = moment(finsalMemberDOB).toDate().getTime().toString();
        }

        request["user"]["dob"] = dateValue;
    }

    if (typeof finsalMemberGender === 'string') {
        request["user"]["gender"] = finsalMemberGender;
    }

    if (typeof finsalMemberFatherName === 'string') {
        request["user"]["fatherName"] = finsalMemberFatherName;
    }

    if (typeof finsalMemberMotherName === 'string') {
        request["user"]["motherName"] = finsalMemberMotherName;
    }

    if (typeof finsalMemberAnnualIncome === 'string' || typeof finsalMemberAnnualIncome === 'number') {
        request["user"]["annualIncome"] = String(finsalMemberAnnualIncome);
    }

    if (typeof finsalMemberMaritalStatus === 'string') {
        request["user"]["maritalStatus"] = finsalMemberMaritalStatus;
    }



    try {
        const response = await axios.post(finsalUrl, request, {
            headers: {
                'authentication-username': credentials.authUsername,
                'authentication-token': credentials.authToken,
            }
        });

        if (response.data.errorMessage) {
            return response.data.errorMessage as string;
        }

        return response.data.redirectionLink as string;
    } catch (e) {
        console.error(e);
        return 'Error when processing loan process API';
    }
}

export async function checkPaymentStatus(piece: IFinsalLoanProcessPiece, finsalMember: string, workflowId: string, applicationState: ApplicationState, processState: WorkflowProcessState) {

    const finsallIsApplyingForLoan = piece.applyForLoan ? !!getWorkflowPieceValue(applicationState, processState, workflowId, piece.applyForLoan) : false;

    // Set the default Finsal credentials here
    let credentials: FinsalCredentials = {
        clientId: '2040',
        clientKey: '65f5be985016d93312694eafdc29aace86d4eaee11fa1027c5e056d1ff39c852',
        roles: 'executive',
        loggedInUniqueIdentifierId: '21',
        loggedInUserId: '5876',
        externalEntityNameId: '4',
        externalEntityTypeId: '5',
        policyName: 'APL Loan',

        authUsername: '5876',
        authToken: '94j5rJeCCGuakrmpdFKP/Qwpp/1tpaIO+sWwz2VnLf0=',
    };


    let finsalUrl = BASE_URL + '/finsall-staging/FinsAllServer/api';

    if (piece.mode === 'prod') {
        if (finsallIsApplyingForLoan) {
            if (!!applicationState.organization.prodFinsalCredentials) {
                credentials = applicationState.organization.prodFinsalCredentials;
            }
        } else {
            if (!!applicationState.organization.prodFinsalNoLoanCredentials) {
                credentials = applicationState.organization.prodFinsalNoLoanCredentials;
            }
        }
        finsalUrl = BASE_URL + '/finsall/FinsAllServer/api';
    } else {
        if (finsallIsApplyingForLoan) {
            if (!!applicationState.organization.uatFinsalCredentials) {
                credentials = applicationState.organization.uatFinsalCredentials;
            }
        } else {
            if (!!applicationState.organization.uatFinsalNoLoanCredentials) {
                credentials = applicationState.organization.uatFinsalNoLoanCredentials;
            }
        }
    }

    const request = {
        "serviceName": "PaymentService",
        "serviceMethod": "paymentStatus",
        "policyRefNumber": finsalMember + (finsallIsApplyingForLoan ? '-loan' : '-noloan'),

        "clientId": credentials.clientId,
        "clientKey": credentials.clientKey,
        "version": "1.0.0",
        "roles": credentials.roles,
        "loggedInUniqueIdentifierId": credentials.loggedInUniqueIdentifierId,
        "loggedInUserId": credentials.loggedInUserId,
    };

    const response = await axios.post(finsalUrl, request, {
        headers: {
            'authentication-username': credentials.authUsername,
            'authentication-token': credentials.authToken,
        }
    });

    if (response.data.errorMessage) {
        throw new Error(response.data.errorMessage as string);
    }

    return response.data.successMessage as string;
}