import React, { Component } from 'react';
import { CardType } from '../../widgets/card/Card';
import CardsList from '../../widgets/card/CardsList';
import ModifyForm from '../../widgets/card/ModifyForm';
import InputText from '../../widgets/form/InputText';
import { translatePhrase } from '../../shared/helpers/translation';
import EnhancedInputText from '../../widgets/form/InputText';

export interface OwnProps {
    selectedId?: string,
    heading: string,
    isReadOnly?: boolean,

    onSelectCard: (id: string) => void,
    onUnSelectCard: () => void,
    onImport?: () => void,
    onExport?: () => void
}

export interface StateProps {
    read: boolean,
    write: boolean,
    restrictStructureChanges: boolean,

    cardsList: Array<CardType>,
    selectedCard: CardType | undefined,
}

export interface DispatchProps {
    reOrderCards: (sourceIndex: number, destinationIndex: number) => void,
    addCard: (payload: { name: string, translatedName: string }) => void,
    deleteCard: (id: string) => void,
    updateCard: (payload: { id: string, name: string, translatedName: string }) => void,
}

type Props = OwnProps & StateProps & DispatchProps;

export interface OwnState {
    isShowingAddForm: boolean,
    isShowingModifyForm: boolean,
    modifyingCardName: string,
    modifyingCardTranslatedName: string,
};

class LanguagesVertical<TProps extends Props, TState extends OwnState> extends Component<TProps, TState> {

    handleSelectCard = (id: string) => {
        this.props.onSelectCard(id);
    }

    toggleModifyForm = () => {
        let toggledState = !this.state.isShowingAddForm;

        if (this.state.isShowingModifyForm) {
            this.setState({
                isShowingModifyForm: false,
                isShowingAddForm: false
            });
        } else {
            this.setState({
                isShowingAddForm: toggledState
            });
        }
    }

    editSelectedCard = () => {

        if (!this.props.selectedCard) {
            throw new Error('Cannot edit card since nothing is selected');
        }

        this.setState({
            isShowingModifyForm: true,
            modifyingCardName: this.props.selectedCard.name,
            modifyingCardTranslatedName: this.props.selectedCard.details ? this.props.selectedCard.details : '',
        });
    }

    updateCardName = (value: string) => {
        this.setState({
            modifyingCardName: value
        });
    }

    updateCardTranslatedName = (value: string) => {
        this.setState({
            modifyingCardTranslatedName: value
        });
    }

    addCard = () => {

        this.props.addCard({
            name: this.state.modifyingCardName,
            translatedName: this.state.modifyingCardTranslatedName ? this.state.modifyingCardTranslatedName : '',
        });

        this.setState({
            modifyingCardName: '',
            modifyingCardTranslatedName: '',
            isShowingAddForm: false
        });
    }

    updateCard = () => {

        if (!this.props.selectedCard) {
            return;
        }

        this.props.updateCard({
            id: this.props.selectedCard.id,
            name: this.state.modifyingCardName,
            translatedName: this.state.modifyingCardTranslatedName,
        });

        this.setState({
            isShowingModifyForm: false,
            modifyingCardName: '',
            modifyingCardTranslatedName: '',
            isShowingAddForm: false
        });
    }

    validateCard = () => {
        if (!this.state.modifyingCardName) {
            return translatePhrase('Enter a valid name');
        }

        return true;
    }

    render() {

        const modifyForm = <ModifyForm isNew={!this.state.isShowingModifyForm} submitForm={this.state.isShowingModifyForm ? this.updateCard : this.addCard} cancelForm={this.toggleModifyForm} validateForm={this.validateCard}>
            <EnhancedInputText placeholder="Name" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardName} default={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.name : ''} key={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.id : 0} />
            <EnhancedInputText placeholder="Translated Name" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardTranslatedName} default={this.state.isShowingModifyForm && this.props.selectedCard && this.props.selectedCard.details ? this.props.selectedCard.details : ''} key={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.id : 0} />
        </ModifyForm>;

        return <CardsList
            heading={this.props.heading}
            cards={this.props.cardsList}
            selectedCard={this.props.selectedCard}
            onSelectCard={this.handleSelectCard}
            onUnselectCard={this.props.onUnSelectCard}
            onDeleteCard={this.props.deleteCard}
            onEditCard={this.editSelectedCard}
            onReorderCards={this.props.reOrderCards}
            modifyForm={modifyForm}
            isShowingAddForm={this.state.isShowingAddForm}
            isShowingEditForm={this.state.isShowingModifyForm}
            onAddCard={this.toggleModifyForm}
            isDeleteRestricted={this.props.restrictStructureChanges}
            isReadOnly={this.props.isReadOnly || !this.props.write}
            onImport={this.props.onImport}
            onExport={this.props.onExport}
            isSearchable
        />
    }
}

export default LanguagesVertical;