import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setToastMessage } from '../shared/store/my-data/actions';
import { Dispatch } from 'redux';
import { ApplicationState } from '../shared/store/types';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { addAppError } from '../shared/store/errors/actions';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {

    return {
        message: state.myData.errorMessage,
        myId: state.myData.id,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setToastMessage: (message: string) => dispatch(setToastMessage(message)),
        addError: (message: string, context: string, location: string, userId: string) => dispatch(addAppError(message, context, location, userId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps;

type OwnState = {
    hasError: boolean,
};

export class ConnectedErrorBoundary extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            hasError: false,
        };
    }
  
    componentDidCatch(error: Error, errorInfo: any) {
        this.props.setToastMessage(error.message);
        this.props.addError(error.message, 'Desktop App error boundary', window.location.href, this.props.myId);
        this.setState({
            hasError: true,
        });
    }
  
    render() {
        if (this.state.hasError) {
            return <div></div>;
        }

        return this.props.children; 
    }
}

const ErrorBoundary = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectedErrorBoundary));

export default ErrorBoundary;