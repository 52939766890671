import React, { Component } from 'react';
import styles from './ConstantPiece.module.scss';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem, { Option } from '../drop-down/ListItem';
import FlowchartPiece, { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../shared/store/types';
import { setStaticDataHolder, setDataFragments } from '../../../shared/store/flowchart/pieces/actions';
import { PieceType } from '../../../shared/store/flowchart/pieces/types';
import { FlowchartContext, FlowchartInfoForPiece, PieceHighlightColour } from '../../../contexts/flowchart-context';
import DropDownSearchBox, { getFilteredOptionsBySearch } from '../drop-down/DropDownSearchBox';

type OwnProps = {
    pieceId: string,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    const staticDataPiece = state.flowchart.pieces.byId[ownProps.pieceId];

    if (staticDataPiece.type !== PieceType.STATIC_DATA) {
        throw new Error('This piece must be a static data piece');
    }

    return {
        staticData: state.staticInfo,
        staticDataPiece,
    }
}



const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
    return {
        setStaticDataHolder: (staticDataId: string | undefined) => dispatch(setStaticDataHolder(ownProps.pieceId, staticDataId)),
        setDataFragments: (dataFragments: Array<string> | undefined) => dispatch(setDataFragments(ownProps.pieceId, dataFragments)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type OwnState = {
    searchTerm: string,
};

type Props = OwnProps & StateProps & DispatchProps & FlowchartPieceProps;

class ConnectedStaticDataPiece extends Component<Props, OwnState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            searchTerm: "",
        }
    }

    selectStaticDataHolder = (selected: string) => {
        this.props.setStaticDataHolder(selected);
    }

    selectDataFragment = (selected: string) => {
        const existingFragments = this.props.staticDataPiece.selectedFragments ? this.props.staticDataPiece.selectedFragments.slice() : [];
        existingFragments.push(selected);
        this.props.setDataFragments(existingFragments);
    }

    goBack = () => {
        if (Array.isArray(this.props.staticDataPiece.selectedFragments) && this.props.staticDataPiece.selectedFragments.length > 0) {
            const newSelectedFragments = this.props.staticDataPiece.selectedFragments.slice();
            newSelectedFragments.pop();
            this.props.setDataFragments(newSelectedFragments.length > 0 ? newSelectedFragments : undefined);
        } else if (!!this.props.staticDataPiece.staticDataHolder) {
            this.props.setStaticDataHolder(undefined);
        }
    }

    updateValidity = (isValid: boolean, flowchartContext: FlowchartInfoForPiece) => {
        if (!isValid) {
            const invalidPieceInfo = flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId);

            if (!invalidPieceInfo && flowchartContext.setInvalidPiece) {
                flowchartContext.setInvalidPiece(this.props.pieceId, flowchartContext.parentSplitPieceIds);
            }
        }

        if (isValid && flowchartContext.removeInvalidPiece && flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId)) {
            flowchartContext.removeInvalidPiece(this.props.pieceId);
        }
    }

    searchForStaticData = (searchTerm: string) => {
        this.setState({ searchTerm: searchTerm })
    }

    render() {

        return <FlowchartContext.Consumer>
            {
                (flowchartContext) => {
                    let currentStaticDataHeading = '';
                    let completeStaticDataHeading = '';
                    let listEntries: Array<JSX.Element>;

                    let isValid = true;

                    const highlightColor = flowchartContext.highlights && flowchartContext.highlights[this.props.pieceId];
                    let highlightClass = styles.noHighlight;

                    switch (highlightColor) {
                        case PieceHighlightColour.GREEN:
                            highlightClass = styles.addedHighlight;
                            break;
                        case PieceHighlightColour.YELLOW:
                            highlightClass = styles.updatedHighlight;
                            break;
                        case PieceHighlightColour.PURPLE:
                            highlightClass = styles.movedHighlight;
                            break;
                        case PieceHighlightColour.RED:
                            highlightClass = styles.deletedHighlight;
                            break;
                    }

                    const staticDataHolderId = this.props.staticDataPiece.staticDataHolder;
                    const selectedFragmentIds = this.props.staticDataPiece.selectedFragments;

                    if (staticDataHolderId) {
                        const staticDataHolder = this.props.staticData.byId[staticDataHolderId];

                        if (!staticDataHolder || staticDataHolder.archived) {
                            isValid = false;
                        }
                    }

                    if (isValid && selectedFragmentIds) {
                        const selectedFragments = selectedFragmentIds.map(selectedFragmentId => this.props.staticData.fragments.byId[selectedFragmentId]);
                        isValid = selectedFragments.every(fragment => !fragment.archived);
                    }

                    if (flowchartContext.highlightIncompletePieces) {
                        const isIncomplete = !staticDataHolderId || !(staticDataHolderId in this.props.staticData.byId);
                        isValid = isValid && !isIncomplete;
                    }

                    this.updateValidity(isValid, flowchartContext);

                    if (this.props.staticDataPiece.staticDataHolder && this.props.staticDataPiece.staticDataHolder in this.props.staticData.byId && Array.isArray(this.props.staticDataPiece.selectedFragments) && this.props.staticDataPiece.selectedFragments.length > 0) {
                        if (!this.props.staticDataPiece.staticDataHolder) {
                            throw new Error('You cannot have fragments without a static data holder');
                        }

                        const staticDataHolderName = this.props.staticData.byId[this.props.staticDataPiece.staticDataHolder].name;

                        const allFragmentEntries = this.props.staticDataPiece.selectedFragments;
                        const allFragmentNames = allFragmentEntries.map(fragmentId => this.props.staticData.fragments.byId[fragmentId].name);

                        const lastFragmentEntryId = allFragmentEntries[allFragmentEntries.length - 1];
                        const lastFragmentEntry = this.props.staticData.fragments.byId[lastFragmentEntryId];

                        currentStaticDataHeading = lastFragmentEntry.name;
                        completeStaticDataHeading = `${staticDataHolderName} > ${allFragmentNames.join(' > ')}`;

                        const filteredEntries: Option[] = lastFragmentEntry.children.map(fragmentId => {
                            return {
                                name: this.props.staticData.fragments.byId[fragmentId].name,
                                value: fragmentId,
                            }
                        });

                        listEntries = getFilteredOptionsBySearch(filteredEntries, this.state.searchTerm).map(option => {
                            return <ListItem key={option.value} theme="aqua" value={option.value} name={option.name} onClick={this.selectDataFragment} />
                        });
                    } else if (this.props.staticDataPiece.staticDataHolder && this.props.staticDataPiece.staticDataHolder in this.props.staticData.byId && this.props.staticDataPiece.staticDataHolder) {
                        const staticDataHolderName = this.props.staticData.byId[this.props.staticDataPiece.staticDataHolder].name;

                        currentStaticDataHeading = staticDataHolderName;
                        completeStaticDataHeading = staticDataHolderName;

                        const filteredEntries: Option[] = this.props.staticData.byId[this.props.staticDataPiece.staticDataHolder].children.map(fragmentId => {
                            return {
                                name: this.props.staticData.fragments.byId[fragmentId].name,
                                value: fragmentId,
                            }
                        });

                        listEntries = getFilteredOptionsBySearch(filteredEntries, this.state.searchTerm).map(option => {
                            return <ListItem key={option.value} theme={isValid ? "aqua" : "red"} value={option.value} name={option.name} onClick={this.selectDataFragment} isExpandable />
                        });
                    } else {
                        const filteredEntries: Option[] = this.props.staticData.allEntries.map(staticDataHolderId => {
                            return {
                                name: this.props.staticData.byId[staticDataHolderId].name,
                                value: staticDataHolderId,
                            }
                        });

                        listEntries = getFilteredOptionsBySearch(filteredEntries, this.state.searchTerm).map(option => {
                            return <ListItem key={option.value} theme={isValid ? "aqua" : "red"} value={option.value} name={option.name} onClick={this.selectStaticDataHolder} isExpandable />
                        });
                    }

                    return (<FlowchartPiece {...this.props}>
                        <div className={highlightClass}>
                            <SelectBox isRounded selectionPromptText={completeStaticDataHeading || 'Select static data'} dismissDropDownAfterSelection={false} theme={isValid ? "aqua" : "red"}>
                                <DropDownList heading={currentStaticDataHeading} goBack={this.goBack} dismissAfterSelection={false} theme={isValid ? "aqua" : "red"}>
                                    <DropDownSearchBox
                                        handleSearchInputChange={this.searchForStaticData}
                                        placeholder={"Search by name"}
                                        searchTerm={this.state.searchTerm}
                                    />
                                    {listEntries}
                                </DropDownList>
                            </SelectBox>
                        </div>
                    </FlowchartPiece>);
                }
            }
        </FlowchartContext.Consumer>
    }
}

const StaticDataPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedStaticDataPiece);

export default StaticDataPiece;