import React, { FC } from 'react';
import StepPiece from './step-piece/StepPiece'
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';
import { VariableType } from '../../../shared/store/flowchart/variables/types';

import ShowPieceEssentials from './ShowPieceEssentials';


type ShowPieceProps = {
    selectedType?: 'User' | 'Member' | 'Group' | 'Workflow' | 'Data fragment' | 'Text' | 'Table',
    variableType?: VariableType,
    selectedFields?: Array<string>,
    selectedEntityType?: string,
    widgetTitle?: string,
    startingDisplayType?: 'table' | 'bar' | 'line' | 'donut',
    xAxis?: string,
    yAxis?: string,
    yAxisAggregation?: 'sum' | 'average',
    nextPiece?: JSX.Element,
    variablePiece?: JSX.Element,
    textToShow?: string,
    isRichText?: boolean,
    isHiddenPiece?: JSX.Element,
}

type Props = ShowPieceProps & FlowchartPieceProps;

const ShowPiece: FC<Props> = (props) => {
    return <StepPiece theme="aqua" {...props}>
        <ShowPieceEssentials pieceName="show" {...props} />
    </StepPiece>
};

export default ShowPiece;