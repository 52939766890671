import React, { Component } from 'react';
import styles from './Group.module.scss';

import { WorkflowProcessState, IWorkflow } from '../../../shared/store/workflows/types';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/types';
import { getWorkflowPieceValue } from '../../../shared/store/flowchart/helpers/workflow';
import { getWorkflowQuestionValidationValue } from '../../../shared/store/flowchart/helpers/question';

import Question, { QuestionProps } from './Question';
import Choose, { ChoosePieceProps } from './Choose';
import { PieceType } from '../../../shared/store/flowchart/pieces/types';
import { getReadableDataForCustomField } from '../../../shared/store/custom-fields';
import { CustomFieldValueType } from '../../../shared/store/custom-fields/types';
import { isUUID } from '../../../shared/helpers/utilities';
import Show from './Show';
import { translatePhrase } from '../../../shared/helpers/translation';

type OwnProps = {
    workflowId: string,
    groupPieceId: string,
    userInputs: {
        [customFieldId: string]: CustomFieldValueType,
    },
    listUserInputs?: {
        [listId: string]: {
            [customFieldId: string]: CustomFieldValueType
        }
    },
    inputExpansions: {
        [customFieldId: string]: boolean,
    },
    errorMessages: {
        [questionId: string]: string,
    },
    userInputsForChoice?: {
        [questionId: string]: string | Array<string>,
    },
    errorMessagesForChoice?: {
        [questionId: string]: string,
    },

    overWrittenVariable?: string,
    overWrittenValue?: string,

    answerKey: number,

    expandInput: (customFieldId: string) => void,
    updateUserInput: (customFieldId: string, value: CustomFieldValueType) => void,
    updateUserInputForChoice?: (questionId: string, value: string | Array<string> | undefined) => void,
    validateAnswer: (questionId: string, answer: CustomFieldValueType, processState: WorkflowProcessState) => Promise<string>,
    validateChoice?: (questionId: string, answer: string | Array<string>, processState: WorkflowProcessState) => Promise<string>,
};

const mapStateToProps = (state: ApplicationState) => {

    return {
        applicationState: state,
        myId: state.myData.id,
        workflowData: state.workflows,
        membersData: state.members,
        groupsData: state.groups,
        piecesData: state.flowchart.pieces,
        variablesData: state.flowchart.variables,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
    sectionOpenStatus: {
        [sectionId: string]: boolean,
    }
}

type DisplayData = {
    type: 'question',
    pieceId: string,
    data: QuestionProps,
} | {
    type: 'choose',
    pieceId: string,
    data: ChoosePieceProps,
} | {
    type: 'show',
    pieceId: string,
    data: undefined,
} | {
    type: 'section',
    pieceId: string,
    data: Array<DisplayData>,
};

class ConnectedGroup extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            sectionOpenStatus: {},
        }
    }

    getAllGroupedPieces = (groupPieceId: string) => {
        const groupedPieces: Array<{
            id: string,
            type: 'question' | 'choose' | 'show' | 'section',
        }> = [];

        const groupPiece = this.props.piecesData.byId[groupPieceId];

        if (groupPiece.type !== PieceType.GROUP && groupPiece.type !== PieceType.GROUP_FOR_LIST && groupPiece.type !== PieceType.SECTION) {
            throw new Error('The id must be a group ID');
        }

        if (!groupPiece.innerPiece) {
            throw new Error('The group piece must have an inner piece');
        }

        let pieceIdToConsider = groupPiece.innerPiece;

        do {
            const pieceToConsider = this.props.piecesData.byId[pieceIdToConsider];

            if (pieceToConsider.type === PieceType.GROUPED_QUESTION) {
                groupedPieces.push({
                    id: pieceIdToConsider,
                    type: 'question',
                });
                pieceIdToConsider = pieceToConsider.nextPiece || '';
            } else if (pieceToConsider.type === PieceType.GROUPED_CHOOSE) {
                groupedPieces.push({
                    id: pieceIdToConsider,
                    type: 'choose',
                });
                pieceIdToConsider = pieceToConsider.nextPiece || '';
            } else if (pieceToConsider.type === PieceType.GROUPED_SHOW) {
                groupedPieces.push({
                    id: pieceIdToConsider,
                    type: 'show',
                });
                pieceIdToConsider = pieceToConsider.nextPiece || '';
            } else if (pieceToConsider.type === PieceType.SECTION) {
                groupedPieces.push({
                    id: pieceIdToConsider,
                    type: 'section',
                });
                pieceIdToConsider = pieceToConsider.nextPiece || '';
            } else {
                throw new Error('This piece can only be a grouped question, a grouped show, or a section');
            }

        } while (pieceIdToConsider);

        return groupedPieces;
    }

    toggleSection = (sectionId: string) => {
        this.setState(prevState => {
            return {
                sectionOpenStatus: {
                    ...prevState.sectionOpenStatus,
                    [sectionId]: typeof prevState.sectionOpenStatus[sectionId] === 'undefined' ? false : !prevState.sectionOpenStatus[sectionId],
                },
            };
        });
    }

    getChoiceValues = (choosePieceId: string) => {
        const workflow = this.props.workflowData.byId[this.props.workflowId];
        const questionPiece = this.props.applicationState.flowchart.pieces.byId[choosePieceId];

        if (questionPiece.type !== PieceType.CHOOSE && questionPiece.type !== PieceType.GROUPED_CHOOSE) {
            throw new Error('The ID should point to a piece of the question type');
        }

        if (!questionPiece.choiceVariable) {
            throw new Error('The question must point to a valid choice variable');
        }

        if (!questionPiece.variablePiece) {
            throw new Error('The question must point to a valid choice list variable');
        }


        let processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: workflow.history[workflow.historyIndex].customFields,
            lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
            executionStack: workflow.history[workflow.historyIndex].executionStack,
            forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
            variables: workflow.history[workflow.historyIndex].variables,
            displayingQuestionPieceId: workflow.history[workflow.historyIndex].displayingQuestionPieceId,
            displayingShowPieceId: workflow.history[workflow.historyIndex].displayingShowPieceId,
            displayingGroupPieceId: workflow.history[workflow.historyIndex].displayingGroupPieceId,
            displayingTransferPieceId: workflow.history[workflow.historyIndex].displayingTransferPieceId,
            displayingContinuePieceId: workflow.history[workflow.historyIndex].displayingContinuePieceId,
            displayingAddWorkflowPieceId: workflow.history[workflow.historyIndex].displayingAddWorkflowPieceId,
            createdWorkflowId: workflow.history[workflow.historyIndex].createdWorkflowId,
        }));

        if (this.props.overWrittenVariable) {
            processState.variables[this.props.overWrittenVariable] = this.props.overWrittenValue;
        }

        for (let questionId in this.props.userInputsForChoice) {
            if (this.props.userInputsForChoice.hasOwnProperty(questionId)) {
                const choosePiece = this.props.piecesData.byId[questionId];

                if (choosePiece.type !== PieceType.CHOOSE && choosePiece.type !== PieceType.GROUPED_CHOOSE) {
                    throw new Error('The piece must be a choose piece');
                }

                if (typeof choosePiece.choiceVariable === 'undefined') {
                    throw new Error('The piece must point to a choice variable');
                }

                processState.variables[choosePiece.choiceVariable] = this.props.userInputsForChoice[questionId];
            }
        }

        let choicesValue = getWorkflowPieceValue(this.props.applicationState, JSON.parse(JSON.stringify(processState)), this.props.workflowId, questionPiece.variablePiece);

        if (typeof choicesValue === 'undefined') {
            choicesValue = [];
        }

        if (!Array.isArray(choicesValue)) {
            throw new Error('The choices list must point to an array of values')
        }

        if (Array.isArray(choicesValue)) {

            if (choicesValue.length > 0 && Array.isArray(choicesValue[0])) {
                // Cannot be a multidimensional array
                throw new Error('The value cannot be a multi-dimensional array')
            }

            choicesValue = choicesValue as Array<string>;
        }

        return choicesValue;
    }

    getDisplayDataForCollection = (collectionId: string): Array<DisplayData> => {

        const workflow = this.props.workflowData.byId[this.props.workflowId];
        const workflowProcessState = this.getWorkflowProcessState(workflow);

        const groupPiece = this.props.piecesData.byId[collectionId];
        const piecesInGroup = this.getAllGroupedPieces(collectionId);

        if (groupPiece.type !== PieceType.GROUP && groupPiece.type !== PieceType.GROUP_FOR_LIST && groupPiece.type !== PieceType.SECTION) {
            throw new Error('The piece must be a group or section type');
        };

        const piecesData: Array<DisplayData> = [];

        for (const groupedPiece of piecesInGroup) {
            if (groupedPiece.type === 'question') {
                const questionPiece = this.props.piecesData.byId[groupedPiece.id];

                if (questionPiece.type !== PieceType.QUESTION && questionPiece.type !== PieceType.GROUPED_QUESTION) {
                    throw new Error('The piece must be a question type');
                }

                if (!questionPiece.customFieldId) {
                    throw new Error('The question must have a valid custom field');
                }

                const allAnswers = typeof this.props.listUserInputs === 'undefined' ? { ...this.props.userInputs } : { ...this.props.listUserInputs };

                let disabledWorkflowProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(workflowProcessState));

                const isDisabled = questionPiece.isDisabledPiece ? !!getWorkflowQuestionValidationValue(this.props.applicationState, disabledWorkflowProcessState, workflow.id, groupedPiece.id, this.props.userInputs[groupedPiece.id], allAnswers, questionPiece.isDisabledPiece) : undefined;

                let requiredWorkflowProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(workflowProcessState));

                const isRequired = questionPiece.isRequiredPiece ? !!getWorkflowQuestionValidationValue(this.props.applicationState, requiredWorkflowProcessState, workflow.id, groupedPiece.id, this.props.userInputs[groupedPiece.id], allAnswers, questionPiece.isRequiredPiece) : undefined;

                let hiddenWorkflowProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(workflowProcessState));

                const isHidden = questionPiece.isHiddenPiece ? !!getWorkflowQuestionValidationValue(this.props.applicationState, hiddenWorkflowProcessState, workflow.id, groupedPiece.id, this.props.userInputs[groupedPiece.id], allAnswers, questionPiece.isHiddenPiece) : undefined;

                if (isHidden) {
                    continue;
                }

                const questionData: QuestionProps = {
                    workflowId: this.props.workflowId,
                    questionId: groupedPiece.id,
                    userInput: this.props.userInputs[questionPiece.customFieldId],
                    isExpanded: this.props.inputExpansions[questionPiece.customFieldId],
                    onExpandToggle: this.props.expandInput.bind(this, questionPiece.customFieldId),
                    errorMessage: this.props.errorMessages[groupedPiece.id],
                    validateAnswer: this.props.validateAnswer,
                    onInputChange: this.props.updateUserInput.bind(this, questionPiece.customFieldId),
                    overWrittenVariable: this.props.overWrittenVariable,
                    overWrittenValue: this.props.overWrittenValue,
                    isDisabled,
                    isRequired,
                };

                piecesData.push({
                    type: 'question',
                    pieceId: groupedPiece.id,
                    data: questionData
                })
            } else if (groupedPiece.type === 'choose') {
                const choosePiece = this.props.piecesData.byId[groupedPiece.id];

                if (choosePiece.type !== PieceType.CHOOSE && choosePiece.type !== PieceType.GROUPED_CHOOSE) {
                    throw new Error('The piece must be a question type');
                }

                if (!this.props.userInputsForChoice || !this.props.errorMessagesForChoice || !this.props.updateUserInputForChoice || !this.props.validateChoice) {
                    throw new Error('These properties must be defined for a choice type')
                }

                const allAnswers = typeof this.props.listUserInputs === 'undefined' ? { ...this.props.userInputs } : { ...this.props.listUserInputs };

                let disabledWorkflowProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(workflowProcessState));

                const isDisabled = choosePiece.isDisabledPiece ? !!getWorkflowQuestionValidationValue(this.props.applicationState, disabledWorkflowProcessState, workflow.id, groupedPiece.id, this.props.userInputs[groupedPiece.id], allAnswers, choosePiece.isDisabledPiece) : undefined;

                let requiredWorkflowProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(workflowProcessState));

                const isRequired = choosePiece.isRequiredPiece ? !!getWorkflowQuestionValidationValue(this.props.applicationState, requiredWorkflowProcessState, workflow.id, groupedPiece.id, this.props.userInputs[groupedPiece.id], allAnswers, choosePiece.isRequiredPiece) : undefined;

                let hiddenWorkflowProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(workflowProcessState));

                const isHidden = choosePiece.isHiddenPiece ? !!getWorkflowQuestionValidationValue(this.props.applicationState, hiddenWorkflowProcessState, workflow.id, groupedPiece.id, this.props.userInputs[groupedPiece.id], allAnswers, choosePiece.isHiddenPiece) : undefined;

                if (isHidden) {
                    continue;
                }

                const allowedChoices = this.getChoiceValues(groupedPiece.id);

                const choooseData: ChoosePieceProps = {
                    key: JSON.stringify(allowedChoices),
                    workflowId: this.props.workflowId,
                    questionId: groupedPiece.id,
                    userInput: this.props.userInputsForChoice[groupedPiece.id],
                    errorMessage: this.props.errorMessagesForChoice[groupedPiece.id],
                    validateAnswer: this.props.validateChoice,
                    onInputChange: this.props.updateUserInputForChoice.bind(this, groupedPiece.id),
                    overWrittenVariable: this.props.overWrittenVariable,
                    overWrittenValue: this.props.overWrittenValue,
                    choiceInputs: this.props.userInputsForChoice,
                    isDisabled,
                    isRequired,
                };

                piecesData.push({
                    type: 'choose',
                    pieceId: groupedPiece.id,
                    data: choooseData
                })
            } else if (groupedPiece.type === 'show') {
                const showPiece = this.props.piecesData.byId[groupedPiece.id];

                if (showPiece.type !== PieceType.SHOW && showPiece.type !== PieceType.GROUPED_SHOW) {
                    throw new Error('The piece must be a show type');
                }

                const allAnswers = typeof this.props.listUserInputs === 'undefined' ? { ...this.props.userInputs } : { ...this.props.listUserInputs };

                const hiddenWorkflowProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(workflowProcessState));
                const isHidden = showPiece.isHiddenPiece ? !!getWorkflowQuestionValidationValue(this.props.applicationState, hiddenWorkflowProcessState, workflow.id, groupedPiece.id, this.props.userInputs[groupedPiece.id], allAnswers, showPiece.isHiddenPiece) : undefined;

                if (isHidden) {
                    continue;
                };

                piecesData.push({
                    type: 'show',
                    pieceId: groupedPiece.id,
                    data: undefined,
                })
            } else if (groupedPiece.type === 'section') {
                const sectionData = this.getDisplayDataForCollection(groupedPiece.id);
                piecesData.push({
                    type: 'section',
                    pieceId: groupedPiece.id,
                    data: sectionData,
                })
            } else {
                throw new Error('The piece should be one of these types');
            }
        };

        return piecesData;
    }

    getWorkflowProcessState = (workflow: IWorkflow) => {
        const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: workflow.history[workflow.historyIndex].customFields,
            lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
            executionStack: workflow.history[workflow.historyIndex].executionStack,
            forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
            variables: workflow.history[workflow.historyIndex].variables,
            displayingQuestionPieceId: workflow.history[workflow.historyIndex].displayingQuestionPieceId,
            displayingShowPieceId: workflow.history[workflow.historyIndex].displayingShowPieceId,
            displayingGroupPieceId: workflow.history[workflow.historyIndex].displayingGroupPieceId,
            displayingTransferPieceId: workflow.history[workflow.historyIndex].displayingTransferPieceId,
            displayingContinuePieceId: workflow.history[workflow.historyIndex].displayingContinuePieceId,
            displayingAddWorkflowPieceId: workflow.history[workflow.historyIndex].displayingAddWorkflowPieceId,
            createdWorkflowId: workflow.history[workflow.historyIndex].createdWorkflowId,
        }));

        if (this.props.overWrittenVariable) {
            processState.variables[this.props.overWrittenVariable] = this.props.overWrittenValue;
        }

        return processState;
    }

    render() {

        const piecesData = this.getDisplayDataForCollection(this.props.groupPieceId);

        const piecesMarkup = piecesData.map((pieceData, index) => {
            if (pieceData.type === 'question') {
                return <section className={styles.questionSectionHolder}> <Question key={pieceData.pieceId} {...pieceData.data} /> </section>;
            } else if (pieceData.type === 'choose') {
                return <section className={styles.QuestionSectionHolder}> <Choose {...pieceData.data} /> </section>;
            } else if (pieceData.type === 'show') {
                const showPiece = this.props.piecesData.byId[pieceData.pieceId];

                if (showPiece.type !== PieceType.SHOW && showPiece.type !== PieceType.GROUPED_SHOW) {
                    throw new Error('This piece must be a show piece');
                };

                let showDataMarkup = <Show userInputs={this.props.userInputs} listUserInputs={this.props.listUserInputs} userInputsForChoice={this.props.userInputsForChoice} workflowId={this.props.workflowId} showPieceId={showPiece.id} />
                return showDataMarkup;

            } else if (pieceData.type === 'section') {
                const sectionPiece = this.props.piecesData.byId[pieceData.pieceId];
                const workflow = this.props.workflowData.byId[this.props.workflowId];
                const headingWorkflowProcessState = this.getWorkflowProcessState(workflow);

                if (sectionPiece.type !== PieceType.SECTION) {
                    throw new Error('The piece must be a section piece');
                }

                const sectionHeading = sectionPiece.heading && isUUID(sectionPiece.heading) ? getWorkflowPieceValue(this.props.applicationState, headingWorkflowProcessState, this.props.workflowId, sectionPiece.heading) : sectionPiece.heading;
                const noOfColumns = sectionPiece.columns && !isNaN(Number(sectionPiece.columns)) ? Number(sectionPiece.columns) : 1;
                let gridColumnStyle = (new Array(noOfColumns)).fill('calc(' + (100 / noOfColumns) + '% - 20px)').join(' ');

                const sectionMarkup = <div>
                    <h3 className={styles.sectionHeading}>
                        <span>{typeof sectionHeading === 'string' ? translatePhrase(sectionHeading) : ''}</span>
                    </h3>
                    <section key={pieceData.pieceId} className={styles.sectionHolder}>
                        {(!!this.state.sectionOpenStatus[pieceData.pieceId] || typeof this.state.sectionOpenStatus[pieceData.pieceId] === 'undefined') && <div className={styles.sectionContent} style={{ gridTemplateColumns: gridColumnStyle }}>
                            {pieceData.data.map(sectionPieceData => {
                                if (sectionPieceData.type === 'question') {
                                    return <Question key={sectionPieceData.pieceId} {...sectionPieceData.data} isSectioned />;
                                } else if (sectionPieceData.type === 'choose') {
                                    return <Choose {...sectionPieceData.data} isSectioned />;
                                } else if (sectionPieceData.type === 'show') {
                                    const showPiece = this.props.piecesData.byId[sectionPieceData.pieceId];
                                    if (showPiece.type !== PieceType.SHOW && showPiece.type !== PieceType.GROUPED_SHOW) {
                                        throw new Error('This piece must be a show piece');
                                    }
                                    let showDataMarkup = <Show userInputs={this.props.userInputs} listUserInputs={this.props.listUserInputs} userInputsForChoice={this.props.userInputsForChoice} workflowId={this.props.workflowId} showPieceId={showPiece.id} />
                                    return showDataMarkup;
                                } else {
                                    throw new Error('Unknown grouped piece');
                                }
                            })}
                        </div>}
                    </section>
                </div>

                return sectionMarkup;
            } else {
                throw new Error('Unknown kind of grouped piece');
            }
        });

        let memberNameMarkup;
        let memberSubTitleMarkup;

        if (this.props.overWrittenValue) {
            const member = this.props.membersData.byId[this.props.overWrittenValue];
            const memberType = this.props.membersData.types.byId[member.type];
            const nameField = this.props.membersData.types.customFields.byId[memberType.nameFieldId];
            memberNameMarkup = <div className={styles.memberName}>{getReadableDataForCustomField(member.customFields[nameField.id], nameField, member.id, 'member')}</div>;

            const subTitleField = this.props.membersData.types.customFields.byId[memberType.subTitleFieldId];
            memberSubTitleMarkup = <div className={styles.memberSubTitle}>{getReadableDataForCustomField(member.customFields[subTitleField.id], subTitleField, member.id, 'member')}</div>;
        }

        return <div>
            {memberNameMarkup}
            {memberSubTitleMarkup}
            {piecesMarkup}
        </div>
    }
}

const Group = connect(mapStateToProps, mapDispatchToProps)(ConnectedGroup);

export default Group;