import React, { Component, MouseEvent } from 'react';
import styles from './UserPerformanceDetails.module.scss';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../../shared/store/types';
import { translatePhrase } from '../../../../shared/helpers/translation';
import { ReactComponent as MetricsIcon } from '../../../../common/assets/user-metrics.svg';
import { ReactComponent as CloseIcon } from '../../../../common/assets/close.svg';
import TimingChart from './TimingChart';
import BasicUserDetails from './BasicUserDetails';
import moment from 'moment';
import axios from 'axios';
import EventCountDetails, { EventCounts } from './EventCountDetails';
import SyncSummary from './SyncSummary';
import InputText from '../../../../widgets/form/InputText';
import Button from '../../../../widgets/button/CommonButton';
import { BASE_URL } from '../../../../shared/store/url';
import { SyncRecord } from '../../../../shared/helpers/synchronize/types';
import LoaderModal from '../../../../widgets/loader/LoaderModal';
import { NudgeType } from '../../../../shared/store/my-data/types';

interface OwnProps {
    userIds: Array<string>;
    hidePerformanceDetails: () => void,
}

interface Option {
    name: string;
    value: string;
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    return {
        structureData: state.structure,
        workflowsData: state.workflows,
        isOnline: state.myData.isOnline,
        isTopBarExpanded: state.myData.isTopBarExpanded,
        selectedNudge: state.myData.selectedNudgeId,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = StateProps & OwnProps;

enum Tab {
    WORKFLOWS,
    EVENTS,
    SYNCS,
}

interface OwnState {
    tab: Tab,
    eventCounts: EventCounts,
    syncRecords: Array<SyncRecord>,
    isShowingFilter: boolean,
    isFetchingEventDetails: boolean,
    isFetchingSyncDetails: boolean,
    userStartIndex: number,
    userEndIndex: number,

    isLoading: boolean,
    filters: {
        noOfDays: string,
        workflowType: string,
    },
}

class ConnectedUserPerformanceDetails extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            tab: props.isOnline ? Tab.EVENTS : Tab.WORKFLOWS,
            eventCounts: {
                login: 0,
                search: 0,
                filter: 0,
            },
            syncRecords: [],
            isShowingFilter: false,
            isFetchingEventDetails: false,
            isFetchingSyncDetails: false,

            isLoading: false,

            filters: {
                noOfDays: '7',
                workflowType: '',
            },
            userStartIndex: 0,
            userEndIndex: 1
        };

        if (props.isOnline) {
            setTimeout(() => this.getEventDetails());
        }
    }

    setWorkflowsTab = () => {
        this.setState({
            tab: Tab.WORKFLOWS,
            isLoading: true,
        });

        window.setTimeout(() => {
            this.setState({
                isLoading: false,
            })
        }, 1000);
    }

    getEventDetails = async () => {
        this.setState({
            isFetchingEventDetails: true,
        });

        const eventCountsData = await axios.post<EventCounts>(BASE_URL + '/event-details/', {
            userIds: this.props.userIds,
            noOfDays: Number(this.state.filters.noOfDays),
        }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });

        this.setState({
            eventCounts: eventCountsData.data,
            isFetchingEventDetails: false,
        });

    }

    setEventsTab = () => {
        this.setState({
            tab: Tab.EVENTS,
        });
        this.getEventDetails();
    }

    getSyncDetails = async () => {
        this.setState({
            isFetchingSyncDetails: true,
        });

        const syncData = await axios.post<Array<SyncRecord>>(BASE_URL + '/sync-details/', {
            userIds: this.props.userIds,
            noOfDays: Number(this.state.filters.noOfDays),
        }, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });

        this.setState({
            syncRecords: syncData.data,
            isFetchingSyncDetails: false,
        });
    }

    setSyncsTab = () => {
        this.setState({
            tab: Tab.SYNCS,
        });
        this.getSyncDetails();
    }

    handleClickOutside = (event: MouseEvent) => {
        this.props.hidePerformanceDetails();
    }

    getLocationName = (locationId: string) => {
        const location = this.props.structureData.locations.byId[locationId];
        let parentName = '';

        if (location.parent) {
            if (location.parent in this.props.structureData.locations.byId) {
                parentName = this.props.structureData.locations.byId[location.parent].name;
            } else if (location.parent in this.props.structureData.projects.byId) {
                parentName = this.props.structureData.projects.byId[location.parent].name;
            }
        }

        return `${translatePhrase(location.name)} (${translatePhrase(parentName)})`;
    }

    toggleFilterShow = () => {
        this.setState(prevState => {
            return {
                isShowingFilter: !prevState.isShowingFilter,
            };
        });
    }

    clearFilters = () => {
        const noOfDays = this.state.filters.noOfDays;
        const workflowType = this.state.filters.workflowType;

        this.setState({
            filters: {
                noOfDays,
                workflowType,
            },
            isShowingFilter: false,
        });
    }

    updateNoOfDays = (noOfDays: string) => {
        this.setState({
            isLoading: true,
            isShowingFilter: false,
            isFetchingEventDetails: true,
            isFetchingSyncDetails: true,
        });

        window.setTimeout(() => {
            this.setState(prevState => {
                return {
                    filters: {
                        ...prevState.filters,
                        noOfDays,
                    },
                }
            })

            window.setTimeout(() => {
                this.setState({
                    isLoading: false,
                })
            }, 500);
        }, 1000);
    }

    updateWorkflowType = (workflowType: string) => {
        this.setState({
            isLoading: true,
            isShowingFilter: false,
            isFetchingEventDetails: true,
            isFetchingSyncDetails: true,
        });

        window.setTimeout(() => {
            this.setState(prevState => {
                return {
                    filters: {
                        ...prevState.filters,
                        workflowType,
                    },
                }
            })

            window.setTimeout(() => {
                this.setState({
                    isLoading: false,
                })
            }, 500);
        }, 1000);

        window.setTimeout(() => {
            this.setState({
                isLoading: false,
            })
        }, 1000);
    }

    render() {
        const lastFilteredCounts: Array<number> = [];

        for (let i = 0; i < Number(this.state.filters.noOfDays); i += 1) {
            lastFilteredCounts.push(i);
        }

        const lastFilteredDays = lastFilteredCounts.map(daysAgo => moment().subtract(daysAgo, 'days').format('YYYY-MM-DD'));

        const filterOptions: Array<Option> = [{
            name: '7 days',
            value: '7',
        }, {
            name: '30 days',
            value: '30',
        }, {
            name: '90 days',
            value: '90',
        }];

        const workflowTypeOptions: Array<Option> = this.props.workflowsData.types.allEntries.map(workflowTypeId => {
            const workflowType = this.props.workflowsData.types.byId[workflowTypeId];
            return {
                name: workflowType.name,
                value: workflowType.id,
            };
        });

        workflowTypeOptions.unshift({
            name: 'All Workflows',
            value: '',
        });

        const isHighlighted = this.props.isTopBarExpanded && this.props.selectedNudge === NudgeType.USERS_METRICS_WORKFLOW_TIMING;

        return <>
            {this.state.isLoading && <LoaderModal
                loaderText={["Calculating user metrics"]}
                isIndeterminate
            />}
            <section className={styles.commonModalHolder + ' ' + styles.filterHolder}>
                <div className={styles.filterCloseButton}>
                    <Button title={translatePhrase("Close")} onClick={this.props.hidePerformanceDetails}
                        icon={<CloseIcon />} size={'small'} isRounded />
                </div>

                <section className={styles.addOrModifyListCard}>
                    <header>
                        <h2> <MetricsIcon /> {translatePhrase('User Metrics')} ({this.state.userStartIndex + 1}/{this.props.userIds.length}) </h2>
                    </header>

                    <div className={styles.container}>
                        {this.props.userIds.slice(this.state.userStartIndex, this.state.userEndIndex).map(userId => <BasicUserDetails key={userId} userId={userId} />)}

                        {this.props.userIds.length > 1 && <div className={styles.userCarouselActionButtons}>
                            <Button isRounded size={'small'} type={'secondary'} text={translatePhrase('Previous')} isDisabled={this.state.userStartIndex === 0} padding={'0 15px'}
                                onClick={() => { this.setState({ userStartIndex: this.state.userStartIndex - 1, userEndIndex: this.state.userEndIndex - 1 }) }} />

                            <Button isRounded size={'small'} type={'secondary'} text={translatePhrase('View All')} padding={'0 15px'}
                                onClick={() => { this.setState({ userStartIndex: 0, userEndIndex: this.props.userIds.length - 1 }) }} />

                            {this.state.userEndIndex === this.props.userIds.length - 1 && this.state.userStartIndex === 0 && <Button isRounded size={'small'} type={'secondary'} text={translatePhrase('View One by One')} padding={'0 15px'}
                                onClick={() => { this.setState({ userStartIndex: 0, userEndIndex: 1 }) }} />}

                            <Button isRounded size={'small'} type={'secondary'} text={translatePhrase('Next')} isDisabled={this.state.userStartIndex === this.props.userIds.length - 1} padding={'0 15px'}
                                onClick={() => { this.setState({ userStartIndex: this.state.userStartIndex + 1, userEndIndex: this.state.userEndIndex + 1 }) }} />
                        </div>}

                        <div className={styles.filterHolder}>
                            <div className={styles.input}>
                                <InputText default={this.state.filters.noOfDays} options={filterOptions} onChange={this.updateNoOfDays} />
                            </div>

                            <div className={styles.input}>
                                <InputText default={this.state.filters.workflowType} options={workflowTypeOptions} onChange={this.updateWorkflowType} />
                            </div>
                        </div>

                        <section className={styles.tabs}>
                            {!this.props.isOnline && <section className={(this.state.tab === Tab.WORKFLOWS ? styles.activeTab : styles.tab) + ' ' + (isHighlighted ? styles.highlight : '')} onClick={this.setWorkflowsTab}>{translatePhrase('Workflows')}</section>}
                            <section className={this.state.tab === Tab.EVENTS ? styles.activeTab : styles.tab} onClick={this.setEventsTab}>{translatePhrase('Events')}</section>
                            <section className={this.state.tab === Tab.SYNCS ? styles.activeTab : styles.tab} onClick={this.setSyncsTab}>{translatePhrase('Push events')}</section>
                        </section>

                        <div className={styles.tabDetails}>
                            {this.state.tab === Tab.WORKFLOWS && !this.props.isOnline && <div className={styles.timingTable}>
                                <div className={styles.tableHeader}>
                                    <label> Date </label>
                                    <label> <span> 12AM </span> <span> 6AM </span> <span> 12PM </span> <span> 6PM </span> <span> 12AM </span> </label>
                                    <label> Total Time </label>
                                </div>
                                {lastFilteredDays.map(day => <TimingChart
                                    key={day}
                                    userIds={this.props.userIds}
                                    date={day}
                                    selectedWorkflowType={this.state.filters.workflowType}
                                />)}
                            </div>}

                            {this.state.tab === Tab.EVENTS && <div>
                                {this.state.isFetchingEventDetails && <div className={styles.spinnerContainer}>
                                    <div className="sk-chase">
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                                    </div>
                                </div>}
                                {!this.state.isFetchingEventDetails && <EventCountDetails eventCounts={this.state.eventCounts} userCount={this.props.userIds.length} />}
                            </div>}

                            {this.state.tab === Tab.SYNCS && <div>
                                {this.state.isFetchingSyncDetails && <div className={styles.spinnerContainer}>
                                    <div className="sk-chase">
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                                        <div className="sk-chase-dot"></div>
                                    </div>
                                </div>}
                                {!this.state.isFetchingSyncDetails && this.state.syncRecords.map((syncRecord, index) => <SyncSummary key={index} syncRecord={syncRecord} />)}
                            </div>}
                        </div>

                    </div>
                </section>
            </section>
        </>
    }
}

const UserPerformanceDetails = connect(mapStateToProps)(ConnectedUserPerformanceDetails);


export default UserPerformanceDetails;