import { createSelector } from "reselect";
import { getVisibleUserIds, getVisibleMemberIds, getVisibleGroupIds, getVisibleWorkflowIds } from "../shared/helpers/visible-entities";
import store from "../shared/store/main";
import { ApplicationState } from "../shared/store/types";

const getUserState = (state: ApplicationState) => state.users.byId;
const getUserArchivedFilter = (state: ApplicationState) => state.users.filters.archived;

export const getVisibleUserIdsSelector = createSelector(
    [getUserState, getUserArchivedFilter],
    (userState, userArchivedFilter) => {
        const state = store.getState();
        return getVisibleUserIds(state);
    }
)

const getMemberState = (state: ApplicationState) => state.members.byId;
const getMemberArchivedFilter = (state: ApplicationState) => state.members.filters.archived;

export const getVisibleMemberIdsSelector = createSelector(
    [getMemberState, getMemberArchivedFilter],
    (memberState, memberArchivedFilter) => {
        const state = store.getState();
        return getVisibleMemberIds(state);
    }
)

const getGroupState = (state: ApplicationState) => state.groups.byId;
const getGroupArchivedFilter = (state: ApplicationState) => state.groups.filters.archived;

export const getVisibleGroupIdsSelector = createSelector(
    [getGroupState, getGroupArchivedFilter],
    (groupState, groupArchivedFilter) => {
        const state = store.getState();
        return getVisibleGroupIds(state);
    }
)

const getWorkflowState = (state: ApplicationState) => state.workflows.byId;
const getWorkflowsFilter = (state: ApplicationState) => state.workflows.filters;

export const getVisibleWorkflowIdsSelector = createSelector(
    [getWorkflowState, getWorkflowsFilter, getVisibleUserIdsSelector, getVisibleMemberIdsSelector, getVisibleGroupIdsSelector],
    (userState, workflowFilters, visibleUserIds, visibleMemberIds, visibleGroupIds) => {
        const state = store.getState();
        return getVisibleWorkflowIds(state, visibleUserIds, visibleMemberIds, visibleGroupIds);
    }
)