import React, { Component } from 'react';
import styles from './EventCountDetails.module.scss';
import { translatePhrase } from '../../../../shared/helpers/translation';

export interface EventCounts {
    login: number;
    search: number;
    filter: number;
}

interface OwnProps {
    eventCounts: EventCounts;
    userCount: number;
}

type Props = OwnProps;

interface OwnState {
}

class EventCountDetails extends Component<Props, OwnState> {

    render() {
        return <section className={styles.eventCountDetails}>
            <div className={styles.label}>{translatePhrase('Login')}</div>
            <div className={styles.value}>{translatePhrase('Total')}: {this.props.eventCounts.login} {this.props.userCount > 1 && <span>({translatePhrase('Avg per user')}: <span className={styles.highlight}>{(this.props.eventCounts.login / this.props.userCount).toFixed(2)}</span>)</span>}</div>

            <div className={styles.label}>{translatePhrase('Search')}</div>
            <div className={styles.value}>{translatePhrase('Total')}: {this.props.eventCounts.search} {this.props.userCount > 1 && <span>({translatePhrase('Avg per user')}: <span className={styles.highlight}>{(this.props.eventCounts.search / this.props.userCount).toFixed(2)}</span>)</span>}</div>

            <div className={styles.label}>{translatePhrase('Filter')}</div>
            <div className={styles.value}>{translatePhrase('Total')}: {this.props.eventCounts.filter} {this.props.userCount > 1 && <span>({translatePhrase('Avg per user')}: <span className={styles.highlight}>{(this.props.eventCounts.filter / this.props.userCount).toFixed(2)}</span>)</span>}</div>
        </section>
    }
}


export default EventCountDetails;