import { FETCH_LOCAL_DATA, FETCH_LOCAL_DATA_REQUEST, RESET_SESSION, ApplicationState, FetchLocalDataAction, FetchLocalDataRequestAction, ResetSessionAction, SaveFilesToIDB, SAVE_FILE_TO_IDB, DeleteFilesInIDB, DELETE_FILE_IN_IDB, SAVE_DATA_TO_SNAPSHOT_DURING_SYSTEM_UPDATE, SaveDataToSnapshotDuringSystemUpdate } from './types';

export function fetchLocalDataRequest(): FetchLocalDataRequestAction {
    return {
        type: FETCH_LOCAL_DATA_REQUEST,
    }
}

export function fetchLocalData(state: ApplicationState): FetchLocalDataAction {
    return {
        type: FETCH_LOCAL_DATA,
        data: state,
    }
}

export function resetSession(): ResetSessionAction {
    return {
        type: RESET_SESSION,
    }
};

export function saveFilesToIDB(id: string, file: File, commit = true): SaveFilesToIDB {
    return {
        type: SAVE_FILE_TO_IDB,
        id,
        file,
        commit,
    };
};

export function deleteFilesInIDB(id: string, commit = true): DeleteFilesInIDB {
    return {
        type: DELETE_FILE_IN_IDB,
        id,
        commit,
    };
};

export function saveDataToSnapshotDuringSystemUpdate(): SaveDataToSnapshotDuringSystemUpdate {
    return {
        type: SAVE_DATA_TO_SNAPSHOT_DURING_SYSTEM_UPDATE,
    };
}