import { IAppError } from "../errors/types";
import { NormalizedModel, Synchronizable } from "../normalized-model";

export const GENERATE_SYNC_LOG = "GENERATE_SYNC_LOG";
export const ADD_SYNC_LOG = "ADD_SYNC_LOG";
export const UPDATE_SYNC_LOG = "UPDATE_SYNC_LOG";
export const UPDATE_SYNC_LOG_DATA = "UPDATE_SYNC_LOG_DATA";
export const FAILED_SYNC_LOG = "FAILED_SYNC_LOG";

export const SYNCHRONIZE_SYNC_LOG_DATA = "SYNCHRONIZE_SYNC_LOG_DATA";
export const CLEAR_SYNC_LOG_DELTA = "CLEAR_SYNC_LOG_DELTA";

export const SET_SYNC_PROCESS_MESSAGE = "SET_SYNC_PROCESS_MESSAGE";
export const CLEAR_SYNC_PROCESS_MESSAGE = "CLEAR_SYNC_PROCESS_MESSAGE";

export interface SyncLogState extends NormalizedModel<SyncMetaLog> {
    currentSyncLogId: string;

    syncProcessMessage: string | undefined;
    readableSyncMessage: string | undefined;
}

export interface DeviceSpecs {
    model: string;
    platform: string;
    osVersion: string;
    manufacturer: string;
    isVirtual: boolean;
    deviceMemory: string;
    jsHeapSizeLimit: string;
    totalJSHeapSize: string;
    usedJSHeapSize: string;
    storageQuota: string;
    storageUsage: string;
}


export interface SyncMetaLog extends Synchronizable {
    id: string;
    syncLogId: string;
    organization: string;
    userId: string;
    timestamp: {
        browserRequestTime: number;
        serverRequestRecievedTime: number;
        processComputationTime: {
            startTime: number;
            endTime: number;
        };
        dataDownloadTime: {
            startTime: number;
            endTime: number;
        };
    };
    platform: "Mobile" | "Desktop";
    ip: string;
    internetSpeed: string; // megabytes
    pushDataSize: number; //bytes
    pullDataSize: number; //bytes
    errors: IAppError[];
    deviceSpecs: DeviceSpecs | undefined,
    status: boolean,
    syncProcessMessage: string,
    failedSyncErrorMessage: string
}

export enum SyncMessage {
    SyncStarted = "Starting to sync your data",
    SendingDataToServer = "Sending your data to the server",
    DataSaved = "Your data has been saved",
    ReceivingDataFromServer = "Receiving new data",
    ReCalculatingData = "Updating your data",
    SyncComplete = "Sync complete",
    SyncFailed = "Sync failed"
};

export interface GenerateSynclog {
    type: typeof GENERATE_SYNC_LOG,
    id: string
}

export interface AddSyncLog {
    type: typeof ADD_SYNC_LOG,
    payload: SyncMetaLog
};


export interface UpdateSyncLog {
    type: typeof UPDATE_SYNC_LOG,
    payload: SyncMetaLog
};

export interface SetSyncProcessMessage {
    message: SyncMessage;
    type: typeof SET_SYNC_PROCESS_MESSAGE
}

export interface ClearSyncProcessMessage {
    type: typeof CLEAR_SYNC_PROCESS_MESSAGE
}

export interface UpdateSyncLogDataData {
    type: typeof UPDATE_SYNC_LOG_DATA,
    data: Array<SyncMetaLog>,
}

export interface FailedSyncLog {
    type: typeof FAILED_SYNC_LOG,
    message: string
}

export interface SynchronizeSyncLogData {
    type: typeof SYNCHRONIZE_SYNC_LOG_DATA,
    data: Array<SyncMetaLog>,
}

export interface ClearSyncLogDelta {
    type: typeof CLEAR_SYNC_LOG_DELTA,
}


export type SyncLogActionTypes = AddSyncLog | UpdateSyncLog | GenerateSynclog | SetSyncProcessMessage | ClearSyncProcessMessage | UpdateSyncLogDataData | FailedSyncLog | SynchronizeSyncLogData | ClearSyncLogDelta;