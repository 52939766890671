import React, { ChangeEvent, Component } from 'react';
import styles from './step-piece/StepPiece.module.scss';
import Input from '../Input';
import StepPiece from './step-piece/StepPiece'

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';
import { Option } from '../drop-down/ListItem';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setVariableForList, setPieceForList } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import { valuePieceSlotTarget } from './utilities';
import { FlowchartContext, FlowchartInfoForPiece } from '../../../contexts/flowchart-context';


type AddToListPieceProps = {
    nextPiece?: JSX.Element,
    variables: Array<Option>,
    listVariableId?: string,
    listVariableName?: string,

    variablePiece?: JSX.Element,
    dataToAdd?: string,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
        setPieceForList: (targetPieceId: string, draggedPieceId: string) => dispatch(setPieceForList(targetPieceId, draggedPieceId)),
        setVariableForList: (pieceId: string, value: string) => dispatch(setVariableForList(pieceId, value)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = AddToListPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type AddToListPieceState = {
    isHoveringOverVariablePiece: boolean,
    searchText: string,
}

class ConnectedAddToListPiece extends Component<Props, AddToListPieceState> {

    state = {
        isHoveringOverVariablePiece: false,
        searchText: '',
    };

    handleHoverOverVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: false,
        });
    };

    handleSetValueUpdate = (value: string) => {
        this.props.setPieceForList(this.props.pieceId, value);
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && this.state.isHoveringOverVariablePiece) {

            this.props.setPieceForList(this.props.pieceId, this.props.lastDraggedPiece.id);
            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverVariablePiece: false,
            });
        }
    }

    searchForVariable = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            searchText: e.target.value,
        });
    }

    updateValidity = (isValid: boolean, flowchartContext: FlowchartInfoForPiece) => {
        if (!isValid) {
            const invalidPieceInfo = flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId);

            if (!invalidPieceInfo && flowchartContext.setInvalidPiece) {
                flowchartContext.setInvalidPiece(this.props.pieceId, flowchartContext.parentSplitPieceIds);
            }
        }

        if (isValid && flowchartContext.removeInvalidPiece && flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId)) {
            flowchartContext.removeInvalidPiece(this.props.pieceId);
        }
    }

    render() {
        return <FlowchartContext.Consumer>
            {
                flowchartContext => {

                    let isValid = !this.props.listVariableId || flowchartContext.variables.includes(this.props.listVariableId);
                                        
                    if (flowchartContext.highlightIncompletePieces) {
                        const isIncomplete = !this.props.listVariableId;
                        isValid = isValid && !isIncomplete;
                    }

                    this.updateValidity(isValid, flowchartContext);

                    const variableSelectBox = <SelectBox theme={isValid ? "indigo" : "red"} selectionPromptText={this.props.listVariableName}>
                        <DropDownList theme={isValid ? "indigo" : "red"} dismissAfterSelection={false}>
                            <div className={styles.nameContainer}>
                                <input className={styles.nameInput} onChange={this.searchForVariable} value={this.state.searchText} type="text" placeholder="Search by name" />
                            </div>
                            {this.props.variables.filter(variable => variable.name.toLocaleLowerCase().includes(this.state.searchText.toLocaleLowerCase())).map((variable, index) => <ListItem name={variable.name} value={variable.value} key={index} theme={isValid ? "indigo" : "red"} onClick={this.props.setVariableForList.bind(this, this.props.pieceId)} />)}
                        </DropDownList>
                    </SelectBox>;
                
                    return (
                        <StepPiece theme="indigo" {...this.props}>
                            <div className={styles.text}>add</div>
                            {this.props.variablePiece ? this.props.variablePiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverVariablePiece && !!this.props.targetPiece} onMouseOver={this.handleHoverOverVariablePiece} onMouseOut={this.handleHoverOutOfVariablePiece} defaultText={this.props.dataToAdd} onChange={this.handleSetValueUpdate} />}
                            <div className={styles.text}>to</div>
                            {variableSelectBox}
                        </StepPiece>
                    )
                }
            }
        </FlowchartContext.Consumer>
        
    }
}

const AddToListPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedAddToListPiece)

export default AddToListPiece;