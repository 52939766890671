import { Component } from "react";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

interface OwnProps {
    locationChain: string;
    locationName: string;
}

class TableLocationList extends Component<OwnProps> {

    render() {
        return (
            <div className="locationHierarchy">
                <Tippy className="my-tippy" content={<span> {this.props.locationChain} </span>}>
                    <span>{this.props.locationName}</span>
                </Tippy>
            </div>
        );
    }
}

export default TableLocationList;