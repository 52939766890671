import React from 'react';
import { VariableState } from '../../../shared/store/flowchart/variables/types';
import { PieceType, PieceState, FlowchartPieceActions } from '../../../shared/store/flowchart/pieces/types';
import ReturnPiece from '../../../components/flowchart/pieces/ReturnPiece';
import ReturnRichTextPiece from '../../../components/flowchart/pieces/ReturnRichTextPiece';
import StartPiece from '../../../components/flowchart/pieces/StartPiece';
import StatusPiece from '../pieces/StatusPiece';
import { getComponent } from './index';
import { PiecePositionState, Position } from '../../../shared/helpers/common-types';
import { isUUID } from '../../../shared/helpers/utilities';
import { IWidget } from '../../../shared/store/widgets/types';
import CustomFieldPiece from '../pieces/CustomFieldPiece';
import { ApplicationState } from '../../../shared/store/types';
import { ArithmeticOperatorsPieces, BooleanOperatorsPieces, ConstantPieces, ControlPieces, CustomFieldPieces, DateOperatorsPieces, ListOperatorsPieces, VariablePieces } from './piece-categories';

export function getComponentForWidgetFields(widget: IWidget, applicationState: ApplicationState, piecesState: PieceState, variablesState: VariableState, variableIds: Array<string>, isEditable = true, flowchartPieceActions: FlowchartPieceActions, isolatePiece: (pieceState: PiecePositionState) => void, removeIsolatedPiece: (pieceId: string) => void, registerVariable: (variableId: string) => void, pieceId: string, detachPiece?: () => void, initialPosition?: Position): JSX.Element {
    const piece = piecesState.byId[pieceId];

    const getInnerComponentTemp = getComponentForWidgetFields.bind({}, widget, applicationState, piecesState, variablesState);
    const getInnerComponentTemp2 = getInnerComponentTemp.bind({}, variableIds, isEditable, flowchartPieceActions, isolatePiece);
    const getInnerComponent = getInnerComponentTemp2.bind({}, removeIsolatedPiece, registerVariable);

    switch (piece.type) {

        case PieceType.START:
            const startNextPiece = piece.nextPiece ? getInnerComponent(piece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
            const startInitialPosition = widget.startPiece ? widget.startPiece.position : undefined;
            return <StartPiece pieceId={pieceId} nextPiece={startNextPiece} isDragDisabled={!isEditable} initialPosition={startInitialPosition} removeIsolatedPiece={removeIsolatedPiece} />

        case PieceType.RETURN:
            const returnVariablePiece = piece.returnValue && isUUID(piece.returnValue) ? getInnerComponent(piece.returnValue, flowchartPieceActions.setReturnVariable.bind({}, pieceId, undefined)) : undefined;
            const returnVariableText = piece.returnValue;

            return <ReturnPiece
                pieceId={pieceId}
                returnVariablePiece={returnVariablePiece}
                returnVariableText={returnVariableText}
                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.RETURN_RICH_TEXT:
            const returnRichTextVariableText = piece.returnValue ? piece.returnValue : '';

            return <ReturnRichTextPiece
                pieceId={pieceId}
                textToShow={returnRichTextVariableText}
                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.STATUS:

            if (widget.type !== 'Workflow') {
                return <div></div>;
            }

            const workflowType = widget.typeId ? applicationState.workflows.types.byId[widget.typeId] : undefined;

            return <StatusPiece
                pieceId={pieceId}

                statusIds={workflowType ? workflowType.statuses : []}
                selectedStatusId={piece.statusId}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.CUSTOM_FIELD:
            let widgetType: 'User' | 'Member' | 'Group' | 'Workflow' = 'Workflow';
            let isShowingMemberPiece = false;
            let customFieldIds: Array<string> = [];

            switch (widget.type) {
                case 'User':
                    widgetType = 'User';
                    const userTypeId = widget.typeId ? widget.typeId : '';
                    customFieldIds = userTypeId ? applicationState.users.customFields.allFields.concat(applicationState.structure.roles.byId[userTypeId].customFields) : [];
                    break;
                case 'Member':
                    widgetType = 'Member';
                    customFieldIds = widget.typeId ? applicationState.members.types.byId[widget.typeId].customFields : [];
                    break;
                case 'Group':
                    widgetType = 'Group';
                    customFieldIds = widget.typeId ? applicationState.groups.types.byId[widget.typeId].customFields : [];
                    break;
                case 'Workflow':
                    widgetType = 'Workflow';
                    customFieldIds = widget.typeId ? applicationState.workflows.types.byId[widget.typeId].customFields : [];

                    const workflowType = widget.typeId ? applicationState.workflows.types.byId[widget.typeId] : undefined;
                    const customField = piece.customField ? applicationState.workflows.types.customFields.byId[piece.customField] : undefined;

                    isShowingMemberPiece = workflowType && customField && !piece.customFieldOption ? workflowType.affiliation === 'group' && customField.affiliation === 'member' : false;
                    break;
                default:
                    throw new Error('Unknown widget entity type');
            }

            const customFieldMemberVariable = piece.memberVariablePiece ? getInnerComponent(piece.memberVariablePiece, flowchartPieceActions.setMemberVariable.bind({}, pieceId, undefined)) : undefined;

            return <CustomFieldPiece
                pieceId={pieceId}

                customFieldIds={customFieldIds}
                selectedCustomFieldId={piece.customField}
                selectedCustomFieldOptionId={piece.customFieldOption}
                type={widgetType}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}

                isShowingMemberVariablePiece={isShowingMemberPiece}
                memberVariablePiece={customFieldMemberVariable}
            />

        default:
            return getComponent(pieceId, piecesState, variablesState, variableIds, getInnerComponent, isEditable, flowchartPieceActions, isolatePiece, removeIsolatedPiece, registerVariable, detachPiece, initialPosition);
    }
}

export const piecesByCategory = {
    'Control': {
        color: '#14b1ab',
        pieces: [
            ControlPieces.For,
            ControlPieces.Return,
            ControlPieces.Split,
            ControlPieces.Structure,
            ControlPieces.StaticData,
            ControlPieces.GetAffiliation,
            ControlPieces.Status,
            ControlPieces.Translate,
            ControlPieces.Format,
            ControlPieces.GetEntities,
        ],
    },
    'Constants': {
        color: '#efaa4b',
        pieces: [
            ConstantPieces.Today,
            ConstantPieces.Now,
            ConstantPieces.True,
            ConstantPieces.TrueHexagonal,
            ConstantPieces.False,
            ConstantPieces.FalseHexagonal,
            ConstantPieces.LoggedInUser,
            ConstantPieces.FinancialYearMonths,
        ],
    },
    'Arithmetic Operators': {
        color: '#efaa4b',
        pieces: [
            ArithmeticOperatorsPieces.Subtract,
            ArithmeticOperatorsPieces.Add,
            ArithmeticOperatorsPieces.Multiply,
            ArithmeticOperatorsPieces.Divide,
            ArithmeticOperatorsPieces.Exponent,
            ArithmeticOperatorsPieces.Sequence,
        ],
    },
    'Boolean Operators': {
        color: '#efaa4b',
        pieces: [
            BooleanOperatorsPieces.LesserThan,
            BooleanOperatorsPieces.LesserThanOrEqualTo,
            BooleanOperatorsPieces.GreaterThan,
            BooleanOperatorsPieces.GreaterThanOrEqualTo,
            BooleanOperatorsPieces.EqualTo,
            BooleanOperatorsPieces.NotEqualTo,
            BooleanOperatorsPieces.In,
            BooleanOperatorsPieces.NotIn,
            BooleanOperatorsPieces.And,
            BooleanOperatorsPieces.Or,
            BooleanOperatorsPieces.Not,
            BooleanOperatorsPieces.VariableToBoolean,
            BooleanOperatorsPieces.BooleanToVariable,
            BooleanOperatorsPieces.IsDefined,
            BooleanOperatorsPieces.IsNotDefined
        ],
    },
    'List Operators': {
        color: '#efaa4b',
        pieces: [
            ListOperatorsPieces.PickFirstElement,
            ListOperatorsPieces.PickFirstNElements,
            ListOperatorsPieces.PickLastElement,
            ListOperatorsPieces.PickLastNElements,
            ListOperatorsPieces.PickNthElement,
            ListOperatorsPieces.SplitBySeparator,
            ListOperatorsPieces.AddToList,
            ListOperatorsPieces.RemoveFromList,
            ListOperatorsPieces.AddToTable,
            ListOperatorsPieces.Length,
        ],
    },
    'Date Operators': {
        color: '#efaa4b',
        pieces: [
            DateOperatorsPieces.AddMonths,
            DateOperatorsPieces.AddYears,
            DateOperatorsPieces.SubtractMonths,
            DateOperatorsPieces.SubtractYears,
            DateOperatorsPieces.GetDate,
            DateOperatorsPieces.GetDay,
            DateOperatorsPieces.GetMonth,
            DateOperatorsPieces.GetReadableMonth,
            DateOperatorsPieces.GetYear,
            DateOperatorsPieces.GetTimeDifference,
        ],
    },
    'Variables': {
        color: '#8891c8',
        pieces: [
            VariablePieces.Variable,
            VariablePieces.SetVariable,
            VariablePieces.StyleTableVariable,
        ],
    },
    'Custom Fields': {
        color: '#d289c0',
        pieces: [
            CustomFieldPieces.CustomField,
            CustomFieldPieces.Get,
        ],
    },
}

export const piecesByCategoryForRichText = {
    'Control': {
        color: '#14b1ab',
        pieces: [
            ControlPieces.For,
            ControlPieces.Return,
            ControlPieces.ReturnRichText,
            ControlPieces.Split,
            ControlPieces.Structure,
            ControlPieces.Status,
            ControlPieces.StaticData,
            ControlPieces.Translate,
            ControlPieces.Format,
            ControlPieces.GetEntities,
        ],
    },
    'Constants': {
        color: '#efaa4b',
        pieces: [
            ConstantPieces.Today,
            ConstantPieces.Now,
            ConstantPieces.True,
            ConstantPieces.TrueHexagonal,
            ConstantPieces.False,
            ConstantPieces.FalseHexagonal,
            ConstantPieces.LoggedInUser,
            ConstantPieces.FinancialYearMonths,
        ],
    },
    'Arithmetic Operators': {
        color: '#efaa4b',
        pieces: [
            ArithmeticOperatorsPieces.Subtract,
            ArithmeticOperatorsPieces.Add,
            ArithmeticOperatorsPieces.Multiply,
            ArithmeticOperatorsPieces.Divide,
            ArithmeticOperatorsPieces.Exponent,
            ArithmeticOperatorsPieces.Sequence
        ],
    },
    'Boolean Operators': {
        color: '#efaa4b',
        pieces: [
            BooleanOperatorsPieces.LesserThan,
            BooleanOperatorsPieces.LesserThanOrEqualTo,
            BooleanOperatorsPieces.GreaterThan,
            BooleanOperatorsPieces.GreaterThanOrEqualTo,
            BooleanOperatorsPieces.EqualTo,
            BooleanOperatorsPieces.NotEqualTo,
            BooleanOperatorsPieces.In,
            BooleanOperatorsPieces.NotIn,
            BooleanOperatorsPieces.And,
            BooleanOperatorsPieces.Or,
            BooleanOperatorsPieces.Not,
            BooleanOperatorsPieces.VariableToBoolean,
            BooleanOperatorsPieces.BooleanToVariable,
            BooleanOperatorsPieces.IsDefined,
            BooleanOperatorsPieces.IsNotDefined
        ],
    },
    'List Operators': {
        color: '#efaa4b',
        pieces: [
            ListOperatorsPieces.PickFirstElement,
            ListOperatorsPieces.PickFirstNElements,
            ListOperatorsPieces.PickLastElement,
            ListOperatorsPieces.PickLastNElements,
            ListOperatorsPieces.PickNthElement,
            ListOperatorsPieces.SplitBySeparator,
            ListOperatorsPieces.AddToList,
            ListOperatorsPieces.RemoveFromList,
            ListOperatorsPieces.AddToTable,
            ListOperatorsPieces.Length,
        ],
    },
    'Date Operators': {
        color: '#efaa4b',
        pieces: [
            DateOperatorsPieces.AddMonths,
            DateOperatorsPieces.AddYears,
            DateOperatorsPieces.SubtractMonths,
            DateOperatorsPieces.SubtractYears,
            DateOperatorsPieces.GetDate,
            DateOperatorsPieces.GetDay,
            DateOperatorsPieces.GetMonth,
            DateOperatorsPieces.GetReadableMonth,
            DateOperatorsPieces.GetYear,
            DateOperatorsPieces.GetTimeDifference,
        ],
    },
    'Variables': {
        color: '#8891c8',
        pieces: [
            VariablePieces.Variable,
            VariablePieces.SetVariable,
            VariablePieces.StyleTableVariable,
        ],
    },
    'Custom Fields': {
        color: '#d289c0',
        pieces: [
            CustomFieldPieces.CustomField,
            CustomFieldPieces.Get,
        ],
    },
}