import React, { useState } from 'react';
import styles from './AggregatedWidget.module.scss';
import { CSVLink } from 'react-csv';
import { setToastMessage } from '../../shared/store/my-data/actions';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { ApplicationState } from '../../shared/store/types';

import { ReactComponent as ExportIcon } from '../../common/assets/export.svg';
import { ReactComponent as ShareIcon } from '../../common/assets/share.svg';
import { ReactComponent as MoreIcon } from '../../assets/new-custom-icons/common/more.svg';
import { ReactComponent as CloseIcon } from '../../assets/action-icons/cancel.svg';
import { ReactComponent as RefreshIcon } from '../../assets/new-custom-icons/new-revision/refresh.svg';

import { ReactComponent as UsersIcon } from '../../assets/new-custom-icons/dashboard/user.svg';
import { ReactComponent as MembersIcon } from '../../assets/new-custom-icons/dashboard/member.svg';
import { ReactComponent as GroupsIcon } from '../../assets/new-custom-icons/dashboard/group.svg';
import { ReactComponent as WorkflowIcon } from '../../assets/new-custom-icons/dashboard/workflow.svg';

import { translatePhrase } from '../../shared/helpers/translation';
import { copyStringToClipboard, isUUID } from '../../shared/helpers/utilities';
import { WidgetData } from '../../shared/store/widgets/types';
import Button from '../../widgets/button/CommonButton';
import { getExportData } from '../../shared/helpers/show-data';
import store from '../../shared/store/main';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';

export type OwnProps = {
    widgetId: string,
    optionsMarkup: JSX.Element,

    setIsPopulatingExport: (isPopulatingExport: boolean) => void,
    updateWidgetCache: () => void,
    setToastLoaderForCopy: () => void,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    let cachedWidgetData: WidgetData | undefined;

    if (ownProps.widgetId && ownProps.widgetId in state.widgets.cachedWidgetData) {
        cachedWidgetData = state.widgets.cachedWidgetData[ownProps.widgetId];
    }

    return {
        widget: state.widgets.byId[ownProps.widgetId],
        isPartial: state.widgets.partiallyCachedWidgetIds && state.widgets.partiallyCachedWidgetIds.includes(ownProps.widgetId),
        cachedWidgetData,
        myId: state.myData.id,
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setToastMessage: (message: string) => dispatch(setToastMessage(message)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;

const ConnectedAggregatedWidget: React.FC<Props> = (props) => {

    const [showMoreOptions, setMoreOptionsFlag] = useState<boolean>(false);
    const [csvData, setCSVData] = useState<Array<Array<string>>>([]);

    const getTypeIcon = (type: string) => {
        if (type === 'User') {
            return <UsersIcon />
        }
        if (type === 'Member') {
            return <MembersIcon />
        }
        if (type === 'Group') {
            return <GroupsIcon />
        }
        if (type === 'Workflow') {
            return <WorkflowIcon />
        }
        return null;
    }

    const populateExportData = () => {

        props.setIsPopulatingExport(true);

        setTimeout(() => {
            const applicationState = store.getState();

            const exportData = getExportData(
                applicationState,
                showData.entityIds,
                props.widget.type,
                props.widget.customFields,
                undefined,
                undefined,
                props.widget.systemFields,
                props.widget.exportingSystemFields,
                props.widget.exportingCustomFields,
            );

            setCSVData(exportData);

            const fileData = Papa.unparse(exportData);
            const fileBlob = new Blob([fileData], { type: 'application/csv' });

            saveAs(fileBlob, `${props.widget.name} Export.csv`);
            props.setIsPopulatingExport(false);
        })
    };


    const getShareLink = () => {
        let hostname = window.location.host;

        if (hostname.startsWith('www')) {
            hostname = hostname.substring(4);
        }

        const shareUrl = `https://backend.${hostname}/public-widget/?id=${props.widgetId}`;

        // props.setToastMessage(translatePhrase('The share URL has been copied to clipboard'));
        props.setToastLoaderForCopy();
        copyStringToClipboard(shareUrl);
    }

    let showData: WidgetData = {
        headings: [],
        entries: [],

        chartLabels: [],
        secondChartLabels: [],
        chartData: [],

        groupedChartData: [],

        csvData: [],
        entityIds: [],

        xAxisFieldName: '',
        yAxisFieldName: '',
    };

    if (props.cachedWidgetData) {
        showData = {
            ...props.cachedWidgetData
        };
    }


    let aggregationValue: number = 0;

    const dataToAggregate = showData.entries.map(tableRow => tableRow.entries[2]);
    let numericValues: Array<number>;

    switch (props.widget.aggregation) {
        case 'count':
            aggregationValue = dataToAggregate.length;
            break;
        case 'sum':
            numericValues = dataToAggregate.map(value => Number(value));
            aggregationValue = numericValues.reduce((sum, num) => {
                if (!isNaN(num)) {
                    return sum + num;
                } else {
                    return sum;
                }
            });
            break;
        case 'average':
            numericValues = dataToAggregate.map(value => Number(value));
            aggregationValue = numericValues.reduce((sum, num) => {
                if (!isNaN(num)) {
                    return sum + num;
                } else {
                    return sum;
                }
            });
            if (numericValues.length > 0) {
                aggregationValue /= numericValues.length;
            } else {
                aggregationValue = 0;
            }
            break;

        default:
            throw new Error('Incorrect aggregation');
    }

    const canShowShareLink = !isUUID(props.myId);

    const header = <header className={styles.showHeader}>

        <h5 className={styles.showHeading + ' ' + (showMoreOptions ? styles.hide : '')}>
            {getTypeIcon(props.widget.type)}

            <div>
                <span title={translatePhrase(props.widget.name)}> {translatePhrase(props.widget.name)} </span>
            </div>
        </h5>

        {!showMoreOptions && <Button title={translatePhrase('More')} icon={<MoreIcon />} onClick={() => setMoreOptionsFlag(true)}
            isRounded={true} type={'secondary'} size={'small'} />}


        <div className={styles.moreOptionsHolder + ' ' + (showMoreOptions ? styles.active : '')}>
            <ul className={styles.moreOptions}>
                {props.optionsMarkup}
                {canShowShareLink &&
                    <Button size={'small'} isRounded={true} title={translatePhrase('Share')}
                        onClick={getShareLink} icon={<ShareIcon />} type={'secondary'} />}

                <Button size={'small'} isRounded={true} type={'secondary'}
                    icon={<ExportIcon />} title={translatePhrase('Prepare Export')} onClick={populateExportData} />

                {/* {csvData.length > 0 && <li title={translatePhrase('Export')} className={styles.linkHolder}>
                    <CSVLink className={styles.link} data={csvData} filename={`${props.widget.name} export.csv`}>
                        <Button size={'small'} isRounded={true} type={'secondary'} icon={<ExportIcon />} />
                    </CSVLink>
                </li>} */}

                <Button size={'small'} isRounded={true} type={'secondary'} icon={<RefreshIcon />}
                    onClick={props.updateWidgetCache} title={translatePhrase('Refresh')} />

                <Button size={'small'} isRounded={true} type={'primary'} icon={<CloseIcon />}
                    title={translatePhrase('Close')} onClick={() => { setMoreOptionsFlag(false) }} />
            </ul>
        </div>

    </header>;

    return <section className={styles.showDataContainer}>
        {header}
        <div className={styles.dataContainer}>
            <div className={styles.numberHolder}>
                <div className={styles.content}>
                    <p> {aggregationValue} </p>
                </div>
            </div>
        </div>
        {props.isPartial && <div className={styles.warningHolder}>{translatePhrase('Partial data. Reload widget to fetch all data')}</div>}

    </section>
}

const AggregatedWidget = connect(mapStateToProps, mapDispatchToProps)(ConnectedAggregatedWidget);

export default AggregatedWidget;
