import { PiecePositionState, TableCell, TableHeading, TableRow } from "../../helpers/common-types";
import { NormalizedModel, Synchronizable } from '../normalized-model';

export const ADD_WIDGET = 'ADD_WIDGET';
export const UPDATE_WIDGET = 'UPDATE_WIDGET';
export const DELETE_WIDGET = 'DELETE_WIDGET';
export const REMOVE_WIDGETS = 'REMOVE_WIDGETS';

export const CLEAR_WIDGET_CACHE = 'CLEAR_WIDGET_CACHE';
export const MARK_PARTIAL_WIDGET_CACHE = 'MARK_PARTIAL_WIDGET_CACHE';
export const UNMARK_PARTIAL_WIDGET_CACHE = 'UNMARK_PARTIAL_WIDGET_CACHE';
export const UPDATE_WIDGET_CACHE = 'UPDATE_WIDGET_CACHE';
export const UPDATE_WIDGET_MESSAGE_CACHE = 'UPDATE_WIDGET_MESSAGE_CACHE';
export const UPDATE_WIDGET_TABLE_CACHE = 'UPDATE_WIDGET_TABLE_CACHE';

export const UPDATE_WIDGET_DATA = 'UPDATE_WIDGET_DATA';

export const UPDATE_WIDGETS_DATA = 'UPDATE_WIDGETS_DATA';
export const SYNCHRONIZE_WIDGETS_DATA = 'SYNCHRONIZE_WIDGETS_DATA';
export const CLEAR_WIDGETS_DELTA = 'CLEAR_WIDGETS_DELTA';

export const UPDATE_WIDGET_START_PIECE = 'UPDATE_WIDGET_START_PIECE';
export const SET_ISOLATED_WIDGET_PIECE = 'SET_ISOLATED_WIDGET_PIECE';
export const REMOVE_ISOLATED_WIDGET_PIECE = 'REMOVE_ISOLATED_WIDGET_PIECE';
export const REGISTER_WIDGET_VARIABLE = 'REGISTER_WIDGET_VARIABLE';

export const UPDATE_WIDGET_RICH_TEXT_START_PIECE = 'UPDATE_WIDGET_RICH_TEXT_START_PIECE';
export const SET_ISOLATED_WIDGET_RICH_TEXT_PIECE = 'SET_ISOLATED_WIDGET_RICH_TEXT_PIECE';
export const REMOVE_ISOLATED_WIDGET_RICH_TEXT_PIECE = 'REMOVE_ISOLATED_WIDGET_RICH_TEXT_PIECE';
export const REGISTER_WIDGET_RICH_TEXT_VARIABLE = 'REGISTER_WIDGET_RICH_TEXT_VARIABLE';

export const SET_SELECTED_WIDGET_FILTER_OPTIONS = 'SET_SELECTED_WIDGET_FILTER_OPTIONS';


export interface INewWidgetData {
    name: string,

    type: string,
    typeId?: string,
    systemFields?: Array<string>,
    customFields: Array<string>,
    exportingSystemFields?: Array<string>,
    exportingCustomFields?: Array<string>,
    creatingUser: string,

    roles: Array<string>,

    seedEntityVariable: string,

    seedUsersVariable?: string,
    seedMembersVariable?: string,
    seedGroupsVariable?: string,
    seedWorkflowsVariable?: string,

    displayType: 'table' | 'bar' | 'line' | 'donut' | 'message' | 'formatted-table',
    aggregation: 'none' | 'count' | 'sum' | 'average',
}

export interface IUpdateableWidgetData extends INewWidgetData {
    id: string,
}

export interface IWidget extends IUpdateableWidgetData, Synchronizable {
    archived?: boolean,
    createdTime: string,
    startPiece?: PiecePositionState,
    variables: Array<string>,
    isolatedPieces: Array<PiecePositionState>,
    richTextStartPiece?: PiecePositionState,
    richTextVariables?: Array<string>,
    richTextIsolatedPieces?: Array<PiecePositionState>,
}

export interface WidgetData {
    headings: Array<TableHeading>;
    entries: Array<TableRow>;

    chartLabels: Array<string>;
    secondChartLabels: Array<string>;
    chartData: Array<number>;
    groupedChartData: Array<Array<number>>;

    csvData: Array<Array<string>>;

    xAxisFieldName: string;
    yAxisFieldName: string;

    entityIds: Array<string>;
}

export interface WidgetDataSlice {
    widgetData: WidgetData;
    lastEntityId?: string;
}

export interface WidgetState extends NormalizedModel<IWidget> {
    byRole: {
        [roleId: string]: Array<string>,
    };
    cachedWidgetData: {
        [widgetId: string]: WidgetData,
    };
    cachedWidgetMessages: {
        [widgetId: string]: string,
    };
    cachedWidgetTableData: {
        [widgetId: string]: Array<Array<TableCell>>,
    };
    partiallyCachedWidgetIds?: Array<string>;
    selectedWidgetFilterOptions?: Array<IWidgetFilterState>
}

export interface IWidgetFilterState {
    widgetId: string
    selectedLevel: string,
    selectedLocations: Array<string>,
    selectedStatuses: Array<string>,
    selectedDropdownCustomField: {
        [customFieldId: string]: string
    },
    selectedDateCustomField: {
        [customFieldId: string]: {
            from: string,
            to: string,
        }
    },
    selectedSystemDateField: {
        [fieldName: string]: {
            from: string,
            to: string,
        }
    },
};

export interface SetSelectedWidgetFilterOptions {
    type: typeof SET_SELECTED_WIDGET_FILTER_OPTIONS,
    payload: IWidgetFilterState
}

export interface AddWidgetAction {
    type: typeof ADD_WIDGET,
    payload: IWidget,
}

export interface UpdateWidgetAction {
    type: typeof UPDATE_WIDGET,
    payload: IUpdateableWidgetData,
}

export interface DeleteWidgetAction {
    type: typeof DELETE_WIDGET,
    id: string,
}

export interface RemoveWidgetsAction {
    type: typeof REMOVE_WIDGETS,
    ids: Array<string>,
}

export interface ClearWidgetCacheAction {
    type: typeof CLEAR_WIDGET_CACHE;
    widgetId?: string,
}

export interface MarkPartialWidgetCacheAction {
    type: typeof MARK_PARTIAL_WIDGET_CACHE;
    widgetId: string;
}

export interface UnMarkPartialWidgetCacheAction {
    type: typeof UNMARK_PARTIAL_WIDGET_CACHE;
    widgetId?: string;
}

export interface UpdateWidgetCacheAction {
    type: typeof UPDATE_WIDGET_CACHE;
    widgetId: string;
    widgetData: WidgetData;
}

export interface UpdateWidgetMessageCacheAction {
    type: typeof UPDATE_WIDGET_MESSAGE_CACHE;
    widgetId: string;
    message: string;
}

export interface UpdateWidgetTableCacheAction {
    type: typeof UPDATE_WIDGET_TABLE_CACHE;
    widgetId: string;
    tableData: Array<Array<TableCell>>;
}

export interface IUpdateWidgetData {
    type: typeof UPDATE_WIDGET_DATA,
    data: WidgetState,
}


export interface UpdateWidgetStartPieceAction {
    type: typeof UPDATE_WIDGET_START_PIECE,
    payload: PiecePositionState,
    widgetId: string,
}

export interface SetIsolatedWidgetPieceAction {
    type: typeof SET_ISOLATED_WIDGET_PIECE,
    payload: PiecePositionState,
    widgetId: string,
}

export interface RemoveIsolatedWidgetPieceAction {
    type: typeof REMOVE_ISOLATED_WIDGET_PIECE,
    pieceId: string,
    widgetId: string,
}

export interface RegisterWidgetVariableAction {
    type: typeof REGISTER_WIDGET_VARIABLE,
    variableId: string,
    widgetId: string,
}


export interface UpdateWidgetRichTextStartPieceAction {
    type: typeof UPDATE_WIDGET_RICH_TEXT_START_PIECE,
    payload: PiecePositionState,
    widgetId: string,
}

export interface SetIsolatedWidgetRichTextPieceAction {
    type: typeof SET_ISOLATED_WIDGET_RICH_TEXT_PIECE,
    payload: PiecePositionState,
    widgetId: string,
}

export interface RemoveIsolatedWidgetRichTextPieceAction {
    type: typeof REMOVE_ISOLATED_WIDGET_RICH_TEXT_PIECE,
    pieceId: string,
    widgetId: string,
}

export interface RegisterWidgetRichTextVariableAction {
    type: typeof REGISTER_WIDGET_RICH_TEXT_VARIABLE,
    variableId: string,
    widgetId: string,
}


export interface UpdateWidgetsData {
    type: typeof UPDATE_WIDGETS_DATA,
    data: Array<IWidget>,
}

export interface SynchronizeWidgetsData {
    type: typeof SYNCHRONIZE_WIDGETS_DATA,
    data: Array<IWidget>,
    myWidgetIds: Array<string>,
}

export interface ClearWidgetsDelta {
    type: typeof CLEAR_WIDGETS_DELTA,
}

export type WidgetActionTypes = AddWidgetAction | UpdateWidgetAction | DeleteWidgetAction | RemoveWidgetsAction | ClearWidgetCacheAction | MarkPartialWidgetCacheAction | UnMarkPartialWidgetCacheAction | UpdateWidgetCacheAction | UpdateWidgetMessageCacheAction | UpdateWidgetTableCacheAction | IUpdateWidgetData | UpdateWidgetStartPieceAction | SetIsolatedWidgetPieceAction | RemoveIsolatedWidgetPieceAction | RegisterWidgetVariableAction | UpdateWidgetRichTextStartPieceAction | SetIsolatedWidgetRichTextPieceAction | RemoveIsolatedWidgetRichTextPieceAction | RegisterWidgetRichTextVariableAction | UpdateWidgetsData | SynchronizeWidgetsData | ClearWidgetsDelta | SetSelectedWidgetFilterOptions;