import { takeEvery, put, all } from 'redux-saga/effects'
import { ADD_REPORT_TYPE, AddReportTypeAction } from './types';
import { addVariable } from '../../flowchart/variables/actions';
import { VariableType } from '../../flowchart/variables/types';
import uuid from 'uuid';
import { addPiece } from '../../flowchart/pieces/actions';
import { PieceType } from '../../flowchart/pieces/types';
import { updateReportTypeStartPiece } from './actions';
import { setDefaultReportTypePermission } from '../../permissions/actions';

function* createSeedFlowchartAndRegisterReport(action: AddReportTypeAction) {
    const startPieceId = uuid.v4();

    yield all([
        put(addVariable({
            id: action.payload.seedUserVariable,
            name: 'Report User',
            type: VariableType.USER,
        })),

        put(addVariable({
            id: action.payload.seedStartDateVariable,
            name: 'Start date',
            type: VariableType.DATE,
        })),
        put(addVariable({
            id: action.payload.seedEndDateVariable,
            name: 'End date',
            type: VariableType.DATE,
        })),

        put(addPiece(startPieceId, PieceType.START)),
        put(updateReportTypeStartPiece({
            piece: startPieceId,
            position: {
                x: 0,
                y: 0,
            }
        }, action.payload.id)),
        put(setDefaultReportTypePermission(action.payload.id)),
    ]);
}

export function* watchReportTypeCreationRequest() {
    yield takeEvery(ADD_REPORT_TYPE, createSeedFlowchartAndRegisterReport);
}