import React, { Component } from 'react';
import styles from './Workflow.module.scss';
import { RouteComponentProps } from 'react-router';
import { Redirect } from "react-router-dom";

import { connect } from 'react-redux';
import { withRouter } from "react-router";

import { ApplicationState } from '../../shared/store/types';
import WorkflowData from './process/WorkflowData';
import { getReadableDataForCustomField } from '../../shared/store/custom-fields';
import { translatePhrase } from '../../shared/helpers/translation';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {

    return {
        isReadable: true,
        isLoaded: state.myData.isLoaded,
        membersData: state.members,
        groupsData: state.groups,
        workflowData: state.workflows,
    }
}

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps & RouteComponentProps<{id: string}>;

class ConnectedWorkflow extends Component<Props> {    

    getHeading = (workflowId: string|undefined) => {
        if (!workflowId) {
            throw new Error('Cannot get the heading of a workflow that does not exist');
        }

        const workflow = this.props.workflowData.byId[workflowId];
        const workflowType = this.props.workflowData.types.byId[workflow.type];

        if (workflowType.affiliation === 'member') {
            const member = this.props.membersData.byId[workflow.affiliatedEntity];
            if (member) {
                const memberType = this.props.membersData.types.byId[member.type];
                let memberName = member.customFields[memberType.nameFieldId];;
    
                const nameField = this.props.membersData.types.customFields.byId[memberType.nameFieldId];
    
                memberName = getReadableDataForCustomField(memberName, nameField, member.id, 'member');
    
                return translatePhrase(workflowType.name) + ' for ' + memberName;
            } else {
                return translatePhrase(workflowType.name) + ' for -';
            }
        } else if (workflowType.affiliation === 'group') {
            const group = this.props.groupsData.byId[workflow.affiliatedEntity];

            if (group) {
                const groupType = this.props.groupsData.types.byId[group.type];
                let groupName = group.customFields[groupType.nameFieldId];
    
                const nameField = this.props.groupsData.types.customFields.byId[groupType.nameFieldId];
    
                groupName = getReadableDataForCustomField(groupName, nameField, group.id, 'group');
    
                return translatePhrase(workflowType.name) + ' for - ' + groupName;
            } else {
                return translatePhrase(workflowType.name) + ' for - ';
            }
        } else if (workflowType.affiliation === 'none') {
            return translatePhrase(workflowType.name);
        }

        
    }

    goToWorkflows = () => {
        this.props.history.push('/workflows/list')
    }
        
    render() {

        if (!this.props.isLoaded) {
            return <div></div>;
        }

        if (!this.props.match) {
            return <div></div>;
        }

        const workflowId = this.props.match.params.id;
        const workflow = this.props.workflowData.byId[workflowId];

        if (!workflow) {
            return <div></div>
        }

        if (!this.props.isReadable) {
            return <Redirect to="/dashboard" />;
        }

        const heading = this.getHeading(workflowId);

        return <WorkflowData workflowId={workflowId} openByDefault goToWorkflows={this.goToWorkflows} />;
        
    }
}

const Workflow = withRouter(connect(mapStateToProps)(ConnectedWorkflow) as any);

export default Workflow;