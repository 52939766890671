import React, { Component, ChangeEvent } from 'react';
import styles from './QuestionPiece.module.scss';
import Input from '../Input';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import { Option } from '../drop-down/ListItem';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setNextPiece, setQuestionData, setVariablePiece, setMemberVariable, setQuestionChoiceVariable } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import { valuePieceSlotTarget } from './utilities';
import { addVariable } from '../../../shared/store/flowchart/variables/actions';
import { IUpdateableVariableData } from '../../../shared/store/flowchart/variables/types';
import VariableModify from './VariableModify';


type ChoosePieceEssentialsProps = {
    choiceListVariablePiece?: JSX.Element,
    listType: 'Project' | 'Level' | 'Role' | 'Location' | 'User' | 'Member' | 'Group' | 'Workflow' | 'Data fragment' | 'Text',
    questionVariablePiece?: JSX.Element,
    questionText?: string,

    selectedChoiceVariable?: string,
    selectedChoiceListVariable?: string,
    variables: Array<Option>,
    nextPiece?: JSX.Element,

    pieceName: string,

    registerVariable?: (variableId: string) => void,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,
        variablesData: state.flowchart.variables,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string | undefined) => dispatch(setTargetPiece(pieceId)),
        setNextPiece: (targetPieceId: string, draggedPieceId: string) => dispatch(setNextPiece(targetPieceId, draggedPieceId)),
        setQuestionData: (targetPieceId: string, draggedPieceId: string) => dispatch(setQuestionData(targetPieceId, draggedPieceId)),
        setMemberVariable: (targetPieceId: string, draggedPieceId: string) => dispatch(setMemberVariable(targetPieceId, draggedPieceId)),
        setQuestionChoiceList: (pieceId: string, variableId: string) => dispatch(setVariablePiece(pieceId, variableId)),
        setQuestionChoiceVariable: (pieceId: string, variableId: string) => dispatch(setQuestionChoiceVariable(pieceId, variableId)),
        addVariable: (variableData: IUpdateableVariableData) => dispatch(addVariable(variableData)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = ChoosePieceEssentialsProps & StateProps & DispatchProps & FlowchartPieceProps;

type ChoosePieceEssentialsState = {
    isHoveringOverQuestionPiece: boolean,
    isHoveringOverVariablePiece: boolean,
    isAddingVariable: boolean,
    searchText: string,
}

class ConnectedChoosePieceEssentials extends Component<Props, ChoosePieceEssentialsState>  {

    state = {
        isHoveringOverQuestionPiece: false,
        isHoveringOverVariablePiece: false,
        isAddingVariable: false,
        searchText: '',
    };

    handleHoverOverVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: false,
        });
    };

    handleHoverOverQuestionPiece = () => {
        this.setState({
            isHoveringOverQuestionPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfQuestionPiece = () => {
        this.setState({
            isHoveringOverQuestionPiece: false,
        });
    };

    handleQuestionValueUpdate = (value: string) => {
        this.props.setQuestionData(this.props.pieceId, value);
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && (this.state.isHoveringOverQuestionPiece || this.state.isHoveringOverVariablePiece)) {
            if (this.state.isHoveringOverQuestionPiece) {
                this.props.setQuestionData(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverVariablePiece) {
                this.props.setQuestionChoiceList(this.props.pieceId, this.props.lastDraggedPiece.id);
            }

            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverQuestionPiece: false,
                isHoveringOverVariablePiece: false,
            });
        }
    }

    submitVariableData = (variableData: IUpdateableVariableData) => {
        this.props.addVariable(variableData);
        this.props.registerVariable && this.props.registerVariable(variableData.id);
        this.setState({
            isAddingVariable: false,
        });
    }

    hideVariableForm = () => {
        this.setState({
            isAddingVariable: false,
        });
    }

    showVariableForm = () => {
        this.setState({
            isAddingVariable: true,
        });
    }

    searchForVariable = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            searchText: e.target.value,
        });
    }

    render() {

        const pieceFooter = this.state.isAddingVariable ? <VariableModify submit={this.submitVariableData} cancel={this.hideVariableForm} /> : <section className={styles.addPrompt + ' ignore-options-onclickoutside'} onClick={this.showVariableForm}>+ Add Variable</section>;

        const variableName = this.props.selectedChoiceVariable && this.props.selectedChoiceVariable in this.props.variablesData.byId ? this.props.variablesData.byId[this.props.selectedChoiceVariable].name : undefined;

        const choiceVariableSelectBox = <SelectBox theme="indigo" selectionPromptText={variableName}>
            <DropDownList footer={pieceFooter} theme="indigo" dismissAfterSelection={true}>
                <div className={styles.searchBoxHolder + ' ignore-options-onclickoutside'}>
                    <input className={styles.searchBox} onChange={this.searchForVariable} value={this.state.searchText} type="text" placeholder="Search by name" />
                </div>
                {this.props.variables.filter(variable => variable.name.toLocaleLowerCase().includes(this.state.searchText.toLocaleLowerCase()))
                    .map((variable, index) => {
                        return <ListItem name={variable.name} value={variable.value} key={index} theme="indigo" onClick={this.props.setQuestionChoiceVariable.bind(this, this.props.pieceId)} />
                    })}
            </DropDownList>
        </SelectBox>;

        return (
            <div className={styles.visibleItems}>
                <div className={styles.text}>{this.props.pieceName} from </div>
                {this.props.choiceListVariablePiece ? this.props.choiceListVariablePiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverVariablePiece && !!this.props.targetPiece} placeholderText="Enter the choice list here" minSize={24} onMouseOver={this.handleHoverOverVariablePiece} onMouseOut={this.handleHoverOutOfVariablePiece} />}
                <div className={styles.text}>which is a {this.props.listType} list stored in</div>
                {choiceVariableSelectBox}
                <div className={styles.text}>with question</div>
                {this.props.questionVariablePiece ? this.props.questionVariablePiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverQuestionPiece && !!this.props.targetPiece} defaultText={this.props.questionText} placeholderText="Enter the question here" minSize={24} onMouseOver={this.handleHoverOverQuestionPiece} onMouseOut={this.handleHoverOutOfQuestionPiece} onChange={this.handleQuestionValueUpdate} />}
            </div>
        )

    }
}

const ChoosePieceEssentials = connect(mapStateToProps, mapDispatchToProps)(ConnectedChoosePieceEssentials);

export default ChoosePieceEssentials;