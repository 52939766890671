import React, { Component } from 'react';
import styles from './Configuration.module.scss';
import { Redirect } from "react-router-dom";

import ModifyForm from '../../../widgets/card/ModifyForm';
import Card, { CardType } from '../../../widgets/card/Card';

import { translatePhrase } from '../../../shared/helpers/translation';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { Permissions } from '../../../shared/store/permissions/types';

import { ApplicationState } from '../../../shared/store/types';
import UserCustomFieldVertical from './UserCustomFieldVertical';
import uuid from 'uuid';
import RoleCustomFieldVertical from '../../structure/hierarchy/RoleCustomFieldVertical';
import { selectRole, unSelectRole, addRoleCustomField } from '../../../shared/store/structure/role/actions';
import RolesList from '../../structure/hierarchy/RolesList';

import { ReactComponent as CancelIcon } from '../../../common/assets/close.svg';
import { INewCustomFieldData, FieldType } from '../../../shared/store/custom-fields/types';
import EnhancedInputText from '../../../widgets/form/InputText';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canEditConfiguration = state.permissions.myPermissions.general.UserConfiguration === Permissions.WRITE;
    const canViewConfiguration = canEditConfiguration || state.permissions.myPermissions.general.UserConfiguration === Permissions.READ;

    const selectedRoleId = state.structure.roles.selected;
    const roleCustomFieldIds = selectedRoleId ? state.structure.roles.byId[selectedRoleId].customFields : [];

    return {
        isReadable: canViewConfiguration,
        isWritable: canEditConfiguration,
        customFieldIds: state.users.customFields.allFields,

        roleCustomFieldsData: state.structure.roles.customFields,
        userCustomFieldsData: state.users.customFields,

        selectedRole: state.structure.roles.selected,
        roleCustomFieldIds,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        addRoleCustomField: (payload: INewCustomFieldData, roleId: string) => dispatch(addRoleCustomField(payload, roleId)),

        selectRole: (id: string) => dispatch(selectRole(id)),
        unSelectRole: () => dispatch(unSelectRole()),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
    isShowingAddForm: boolean;
    modifyingVerticalName: string;
    modifyingVerticalType: string;
    modifyingVerticalIsComputed: string;
    modifyingVerticalIsInTable: string;
};

class ConnectedConfiguration extends Component<Props, OwnState> {

    state: OwnState = {
        isShowingAddForm: false,
        modifyingVerticalName: '',
        modifyingVerticalType: '',
        modifyingVerticalIsComputed: 'No',
        modifyingVerticalIsInTable: 'No',
    };

    toggleCustomFieldAddForm = () => {
        this.setState((prevState) => {
            return {
                isShowingAddForm: !prevState.isShowingAddForm,
            };
        });
    }

    updateFieldName = (value: string) => {
        this.setState({
            modifyingVerticalName: value,
        });
    }

    updateFieldType = (value: string) => {
        this.setState({
            modifyingVerticalType: value,
        });
    }

    updateFieldIsComputed = (value: string) => {
        this.setState({
            modifyingVerticalIsComputed: value,
        });
    }

    updateFieldIsInTable = (value: string) => {
        this.setState({
            modifyingVerticalIsInTable: value,
        });
    }


    addCustomField = () => {
        if (this.validateVerticalForm() !== true) {
            return;
        }

        const fieldType = this.state.modifyingVerticalType as keyof typeof FieldType;

        if (this.props.selectedRole) {
            this.props.addRoleCustomField({
                name: this.state.modifyingVerticalName,
                type: FieldType[fieldType],
                isComputed: this.state.modifyingVerticalIsComputed === 'Yes',
                isEditable: true,
                isDeletable: true,
                isInTable: this.state.modifyingVerticalIsInTable === 'Yes',
                seedEntityVariable: uuid.v4(),
            }, this.props.selectedRole);

            this.setState({
                isShowingAddForm: false,
                modifyingVerticalName: '',
                modifyingVerticalType: '',
                modifyingVerticalIsComputed: 'No',
                modifyingVerticalIsInTable: 'No',
            });
        }
    }

    validateVerticalForm = () => {
        const fieldTypes = Object.keys(FieldType);
        const binaryTypes = ['Yes', 'No'];

        if (!this.state.modifyingVerticalName) {
            return 'Enter a valid name';
        }

        const rolesCustomFieldNames: Array<string> = this.props.roleCustomFieldIds.map(id => {
            return this.props.roleCustomFieldsData.byId[id].name.toLocaleLowerCase();
        });

        if (rolesCustomFieldNames.includes(this.state.modifyingVerticalName.toLocaleLowerCase())) {
            return 'A custom field with this name already exists';
        }

        if (!fieldTypes.includes(this.state.modifyingVerticalType)) {
            return 'Enter a valid type';
        }

        if (!binaryTypes.includes(this.state.modifyingVerticalIsInTable)) {
            return 'Enter a valid in-table field'
        }

        return true;
    }

    render() {
        if (!this.props.isReadable) {
            return <Redirect to="/dashboard" />;
        }

        const typeOptions = Object.keys(FieldType).map(name => {
            return {
                name: name.split('_').join(' '),
                value: name,
            }
        });

        const addFieldForm =
            <div className={styles.modifyFormCard}>
                <div>
                    <header>
                        <div className={styles.heading}>
                            {translatePhrase('Add Role Custom Field')}
                        </div>
                        <button className={styles.cancelCustomFieldButton} title={translatePhrase('Cancel')} onClick={this.toggleCustomFieldAddForm}> <CancelIcon /> </button>
                    </header>
                </div>
                <div className={styles.container}>
                    <ModifyForm hideCancel isNew={true} submitForm={this.addCustomField} cancelForm={this.toggleCustomFieldAddForm} validateForm={this.validateVerticalForm}>
                        <EnhancedInputText placeholder="Name" onEnterPress={this.addCustomField} onChange={this.updateFieldName} />
                        <EnhancedInputText placeholder="Type" onEnterPress={this.addCustomField} onChange={this.updateFieldType} options={typeOptions} />
                        <EnhancedInputText isBooleanField placeholder="Is Computed" onEnterPress={this.addCustomField} onChange={this.updateFieldIsComputed} defaultBooleanValue={false} />
                        <EnhancedInputText isBooleanField placeholder="Searchable / Show in Table" onEnterPress={this.addCustomField} onChange={this.updateFieldIsInTable} defaultBooleanValue={false} />
                    </ModifyForm>
                </div>
            </div>;

        const defaultCard: CardType = {
            id: 'none',
            name: 'Default - All Roles',
        };

        const userCustomFieldNames: Array<string> = this.props.customFieldIds.map(id => {
            return this.props.userCustomFieldsData.byId[id].name.toLocaleLowerCase();
        });

        const rolesCustomFieldNames: Array<string> = this.props.roleCustomFieldIds.map(id => {
            return this.props.roleCustomFieldsData.byId[id].name.toLocaleLowerCase();
        });

        return (<div className={styles.configurationHolder}>
            <div className={styles.allRoles} onClick={this.props.unSelectRole}>
                <div className={styles.defaultCardHolder}>
                    <Card
                        card={defaultCard}
                        isPotentiallyDraggable={false}
                        isDragDisabled
                        isActive={!this.props.selectedRole}
                        isReadonly
                        isShowingAddForm={this.state.isShowingAddForm}
                        isShowingIndex
                        index={-1}
                        onSelectCard={this.props.unSelectRole}
                        onUnselectCard={this.props.unSelectRole}
                        onEditCard={() => { }}
                        onDeleteCard={() => { }}
                    ></Card>
                </div>
                <RolesList heading="Roles" isSearchable onSelectCard={this.props.selectRole} onUnSelectCard={this.props.unSelectRole} selectedId={this.props.selectedRole} isReadOnly isShowingProjectName />
            </div>

            <div className={styles.cardsTree}>
                {this.props.isWritable && this.props.selectedRole && <section className={styles.newVerticalHolder + ' ignore-react-onclickoutside'}>
                    {this.state.isShowingAddForm ? addFieldForm : <section onClick={this.toggleCustomFieldAddForm} className={styles.newCustomFieldPrompt}>+ {translatePhrase('Add Role Custom Field')}</section>}
                </section>}
                {!this.props.selectedRole && this.props.customFieldIds.map(id => {
                    const customField = this.props.userCustomFieldsData.byId[id];
                    const remainingCustomFieldNames = userCustomFieldNames.filter(fieldName => fieldName.toLocaleLowerCase() !== customField.name.toLocaleLowerCase());
                    return <UserCustomFieldVertical existingCardNames={remainingCustomFieldNames} customFieldId={id} key={id} />
                })}

                {this.props.selectedRole && this.props.roleCustomFieldIds.map(id => {
                    const customField = this.props.roleCustomFieldsData.byId[id];
                    const remainingCustomFieldNames = rolesCustomFieldNames.filter(fieldName => fieldName.toLocaleLowerCase() !== customField.name.toLocaleLowerCase());
                    return <RoleCustomFieldVertical existingCardNames={remainingCustomFieldNames} roleId={this.props.selectedRole || ''} customFieldId={id} key={id} isReadOnly={!this.props.isWritable} />
                })}
            </div>

        </div>);

    }

}

const Configuration = connect(mapStateToProps, mapDispatchToProps)(ConnectedConfiguration);

export default Configuration;