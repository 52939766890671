import React, { Component, ChangeEvent } from 'react';
import styles from './step-piece/StepPiece.module.scss';
import Input from '../Input';
import StepPiece from './step-piece/StepPiece'

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';
import { Option } from '../drop-down/ListItem';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setVariablePiece, styleTableSection } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import { FlowchartContext, FlowchartInfoForPiece } from '../../../contexts/flowchart-context';
import DropDownSearchBox, { getFilteredOptionsBySearch } from '../drop-down/DropDownSearchBox';

const OPTIONS: Option[] = [
    {
        name: "Yes",
        value: "yes",
    },
    {
        name: "No",
        value: "no",
    },
];

type StyleTableSectionPieceProps = {
    nextPiece?: JSX.Element,
    variables: Array<Option>,
    settingVariableId?: string,
    isBold: boolean | undefined,
    isItalic: boolean | undefined,
    backgroundColor: string | undefined,
    fontColor: string | undefined,
    fontSize: number | undefined,

    registerVariable?: (variableId: string) => void,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        variablesData: state.flowchart.variables,
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,
        variableData: state.flowchart.variables,
    }
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: StyleTableSectionPieceProps & FlowchartPieceProps) => {

    return {
        setTargetPiece: (pieceId: string | undefined) => dispatch(setTargetPiece(pieceId)),
        setVariablePiece: (pieceId: string, variablePiece: string | undefined) => dispatch(setVariablePiece(pieceId, variablePiece)),
        styleTableSection: (
            pieceId: string,
            isBold: boolean | undefined,
            isItalic: boolean | undefined,
            backgroundColor: string | undefined,
            fontColor: string | undefined,
            fontSize: number | undefined,
        ) => dispatch(styleTableSection(
            pieceId,
            isBold,
            isItalic,
            backgroundColor,
            fontColor,
            fontSize,
        )),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = StyleTableSectionPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type FormatTableSectionPieceState = {
    searchText: string,
    boldSearchText: string,
    italicSearchText: string,
}

class ConnectedFormatTableSectionPiece extends Component<Props, FormatTableSectionPieceState> {

    state = {
        searchText: '',
        boldSearchText: "",
        italicSearchText: "",
    };

    searchForVariable = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            searchText: e.target.value,
        });
    }

    updateValidity = (isValid: boolean, flowchartContext: FlowchartInfoForPiece) => {
        if (!isValid) {
            const invalidPieceInfo = flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId);

            if (!invalidPieceInfo && flowchartContext.setInvalidPiece) {
                flowchartContext.setInvalidPiece(this.props.pieceId, flowchartContext.parentSplitPieceIds);
            }
        }

        if (isValid && flowchartContext.removeInvalidPiece && flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId)) {
            flowchartContext.removeInvalidPiece(this.props.pieceId);
        }
    }

    setBoldValue = (boldValue: string) => {
        let isBold: boolean | undefined;

        switch (boldValue) {
            case 'yes':
                isBold = true;
                break;
            case 'no':
                isBold = false;
                break;
            default:
                break;
        }

        this.props.styleTableSection(
            this.props.pieceId,
            isBold,
            this.props.isItalic,
            this.props.backgroundColor,
            this.props.fontColor,
            this.props.fontSize,
        );
    }

    setItalicValue = (italicValue: string) => {
        let isItalic: boolean | undefined;

        switch (italicValue) {
            case 'yes':
                isItalic = true;
                break;
            case 'no':
                isItalic = false;
                break;
            default:
                break;
        }

        this.props.styleTableSection(
            this.props.pieceId,
            this.props.isBold,
            isItalic,
            this.props.backgroundColor,
            this.props.fontColor,
            this.props.fontSize,
        );
    }

    setBackgroundColor = (backgroundColor: string) => {
        this.props.styleTableSection(
            this.props.pieceId,
            this.props.isBold,
            this.props.isItalic,
            backgroundColor,
            this.props.fontColor,
            this.props.fontSize,
        );
    }

    setFontColor = (fontColor: string) => {
        this.props.styleTableSection(
            this.props.pieceId,
            this.props.isBold,
            this.props.isItalic,
            this.props.backgroundColor,
            fontColor,
            this.props.fontSize,
        );
    }

    setFontSize = (fontSize: string) => {
        if (!isNaN(Number(fontSize))) {
            this.props.styleTableSection(
                this.props.pieceId,
                this.props.isBold,
                this.props.isItalic,
                this.props.backgroundColor,
                this.props.fontColor,
                Number(fontSize),
            );
        }
    }

    searchForBold = (searchTerm: string) => {
        this.setState({ boldSearchText: searchTerm });
    }

    searchForItalic = (searchTerm: string) => {
        this.setState({ italicSearchText: searchTerm });
    }

    render() {

        return <FlowchartContext.Consumer>
            {
                (flowchartContext) => {

                    const settingVariableName = this.props.settingVariableId && this.props.settingVariableId in this.props.variableData.byId ? this.props.variableData.byId[this.props.settingVariableId].name : undefined;

                    const variableSelectBox = <SelectBox theme="indigo" selectionPromptText={settingVariableName}>
                        <DropDownList theme="indigo" dismissAfterSelection={true}>
                            <div className={styles.searchBoxHolder + ' ignore-options-onclickoutside'}>
                                <input className={styles.searchBox} onChange={this.searchForVariable} value={this.state.searchText} type="text" placeholder="Search by name" />
                            </div>
                            {this.props.variables.filter(variable => variable.name.toLocaleLowerCase().includes(this.state.searchText.toLocaleLowerCase()))
                                .map((variable, index) => {
                                    const variableData = this.props.variablesData.byId[variable.value];
                                    return <ListItem name={variable.name} detail={variableData.type} value={variable.value} key={index} theme="indigo" onClick={this.props.setVariablePiece.bind(this, this.props.pieceId)} />
                                })}
                        </DropDownList>
                    </SelectBox>;

                    let isValid = !this.props.settingVariableId || !!this.props.variables.find(option => option.value === this.props.settingVariableId);

                    if (flowchartContext.highlightIncompletePieces) {
                        const isIncomplete = !this.props.settingVariableId;
                        isValid = isValid && !isIncomplete;
                    }

                    this.updateValidity(isValid, flowchartContext);

                    const boldSelectBox = <SelectBox theme="indigo" selectionPromptText={this.props.isBold ? 'Yes' : 'No'}>
                        <DropDownList theme="indigo" dismissAfterSelection={false}>
                            <DropDownSearchBox
                                handleSearchInputChange={this.searchForBold}
                                placeholder={"Search by name"}
                                searchTerm={this.state.boldSearchText}
                            />
                            {getFilteredOptionsBySearch(OPTIONS, this.state.boldSearchText).map((option) => {
                                return <ListItem name={option.name} value={option.value} theme="indigo" onClick={this.setBoldValue} />
                            })}
                        </DropDownList>
                    </SelectBox>;

                    const italicSelectBox = <SelectBox theme="indigo" selectionPromptText={this.props.isItalic ? 'Yes' : 'No'}>
                        <DropDownList theme="indigo" dismissAfterSelection={false}>
                            <DropDownSearchBox
                                handleSearchInputChange={this.searchForItalic}
                                placeholder={"Search by name"}
                                searchTerm={this.state.italicSearchText}
                            />
                            {getFilteredOptionsBySearch(OPTIONS, this.state.italicSearchText).map((option) => {
                                return <ListItem name={option.name} value={option.value} theme="indigo" onClick={this.setItalicValue} />
                            })}
                        </DropDownList>
                    </SelectBox>;

                    return (
                        <StepPiece theme={isValid ? "indigo" : "red"} {...this.props}>
                            <div className={styles.text}>style</div>
                            {variableSelectBox}
                            <div className={styles.text}> Bold: </div>
                            {boldSelectBox}
                            <div className={styles.text}> Italic: </div>
                            {italicSelectBox}
                            <div className={styles.text}> Background color: </div>
                            <Input placeholderText="Hex code" minSize={8} onChange={this.setBackgroundColor} defaultText={this.props.backgroundColor} />
                            <div className={styles.text}> Font color: </div>
                            <Input placeholderText="Hex code" minSize={8} onChange={this.setFontColor} defaultText={this.props.fontColor} />
                            <div className={styles.text}> Font size: </div>
                            <Input placeholderText="No. of pixels" type="number" minSize={10} onChange={this.setFontSize} defaultText={this.props.fontSize ? String(this.props.fontSize) : ''} />
                        </StepPiece>
                    )
                }
            }
        </FlowchartContext.Consumer>

    }
}

const StyleTableSectionPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedFormatTableSectionPiece)

export default StyleTableSectionPiece;