import styles from './FlowchartWindow.module.scss';
import React, { useEffect, useState } from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { translatePhrase } from '../../shared/helpers/translation';
import { NudgeType } from '../../shared/store/my-data/types';
import Button from '../../widgets/button/CommonButton';
import { ReactComponent as ExpandIcon } from '../../assets/action-icons/expand.svg';
import { ReactComponent as CollapseIcon } from '../../assets/action-icons/collapse.svg';
import { ReactComponent as ToggleIcon } from '../../assets/new-custom-icons/common/more.svg';
import { ReactComponent as CloseIcon } from '../../common/assets/cancel.svg';
import { IWorkflowType } from '../../shared/store/workflows/types/types';
import { IReportType } from '../../shared/store/reports/types/types';
import { CustomField } from '../../shared/store/custom-fields/types';
import Tippy from '@tippyjs/react';

type OwnProps = {
    selectedNudge: NudgeType | undefined,
    flowchartType?: IWorkflowType | IReportType | CustomField,
    toggleFlowChartOptions?: () => void,
    goBack: () => void,
    toggleExpandFlowChart: () => void,
    isExpanded: boolean,
    unSelectPiece?: () => void,
    isFlowChartMoreOptionShown?: boolean,
    selectComparison?: () => void,
};

type Props = OwnProps & RouteComponentProps<{ id: string, version?: string }>;

const FlowchartWindow: React.FC<Props> = (props) => {

    const [isMoreOptionShown, setMoreOption] = useState<boolean>(false);

    const toggleExpandFlowchart = () => {
        props.toggleExpandFlowChart();
    };

    const toggleFlowchartOptions = () => {
        setMoreOption(!isMoreOptionShown);
        props.toggleFlowChartOptions && props.toggleFlowChartOptions();
    };

    return (
        <div className={`${styles.flowchartOuterWindow} ${props.isExpanded && styles.expandedFlowChart}`}>
            <div className={styles.buttonHolder}>
                <div className={styles.backButton}>
                    <Button size={'small'} isRounded text={translatePhrase('Back to Configuration')} type={'secondary'} padding={"5px 10px"} isHighlighted={NudgeType.FLOWCHART_BACK_TO_CONFIGURATION === props.selectedNudge}
                        onClick={() => props.goBack && props.goBack()}
                    />
                </div>
                <div className={styles.tabButtons}>
                    {props.flowchartType && props.match.params && props.match.params.version && <div>
                        <NavLink onClick={() => props.toggleFlowChartOptions && props.isFlowChartMoreOptionShown && props.toggleFlowChartOptions()} to={`/workflows/flowchart/${props.flowchartType.id}/beta`} className={styles.Tab + " " + (NudgeType.FLOWCHART_BETA === props.selectedNudge ? styles.nudgeActive : "")} activeClassName={styles.active}> {translatePhrase('Test')} </NavLink>
                        <NavLink onClick={() => props.toggleFlowChartOptions && props.isFlowChartMoreOptionShown && props.toggleFlowChartOptions()} to={`/workflows/flowchart/${props.flowchartType.id}/live`} className={styles.Tab + " " + (NudgeType.FLOWCHART_LIVE === props.selectedNudge ? styles.nudgeActive : "")} activeClassName={styles.active}> {translatePhrase('Published')} </NavLink>
                        {props.selectComparison &&

                            <Tippy className="my-tippy" content={<span>Diff between Test and Published flowcharts</span>}>
                                <section className={styles.comparison} onClick={props.selectComparison}>C</section>
                            </Tippy>}
                    </div>}
                </div>
                <div className={styles.actionButtons}>
                    {props.toggleFlowChartOptions && !props.isFlowChartMoreOptionShown && <Button size={'small'} onClick={() => toggleFlowchartOptions()} isRounded type={'secondary'} color={'primary'} title={translatePhrase('More')} icon={<ToggleIcon />} />}
                    {props.toggleFlowChartOptions && props.isFlowChartMoreOptionShown && <Button size={'small'} onClick={() => toggleFlowchartOptions()} isRounded type={'primary'} color={'primary'} title={translatePhrase('Close')} icon={<CloseIcon />} />}
                    <Button size={'small'} onClick={() => toggleExpandFlowchart()} isRounded type={'secondary'} color={'primary'} title={props.isExpanded ? translatePhrase('Exit fullscreen') : translatePhrase('Fullscreen')} icon={props.isExpanded ? <CollapseIcon /> : <ExpandIcon />} isHighlighted={NudgeType.FLOWCHART_EXPAND === props.selectedNudge} />
                </div>
            </div>
            <div className={styles.flowchartInnerWindow}>{props.children}</div>
        </div>
    )
}

export default withRouter(FlowchartWindow);