import { WorkflowTypeState, SELECT_WORKFLOW_TYPE, UN_SELECT_WORKFLOW_TYPE, RE_ORDER_WORKFLOW_TYPES, ADD_WORKFLOW_TYPE, UPDATE_WORKFLOW_TYPE, DELETE_WORKFLOW_TYPE, SELECT_WORKFLOW_TYPE_CUSTOM_FIELD, UN_SELECT_WORKFLOW_TYPE_CUSTOM_FIELD, ADD_WORKFLOW_TYPE_CUSTOM_FIELD, DELETE_WORKFLOW_TYPE_CUSTOM_FIELD, UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD, SELECT_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION, UN_SELECT_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION, RE_ORDER_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION, ADD_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION, DELETE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION, UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION, UPDATE_WORKFLOW_TYPE_START_PIECE, SET_ISOLATED_WORKFLOW_TYPE_PIECE, UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD_START_PIECE, SET_ISOLATED_WORKFLOW_TYPE_CUSTOM_FIELD_PIECE, REMOVE_ISOLATED_WORKFLOW_TYPE_PIECE, REMOVE_ISOLATED_WORKFLOW_TYPE_CUSTOM_FIELD_PIECE, REGISTER_WORKFLOW_TYPE_VARIABLE, REGISTER_WORKFLOW_TYPE_CUSTOM_FIELD_VARIABLE, ADD_WORKFLOW_TO_WORKFLOW_TYPE, REMOVE_WORKFLOW_FROM_WORKFLOW_TYPE, IWorkflowType, ADD_STATUS_TO_WORKFLOW_TYPE, REMOVE_STATUS_FROM_WORKFLOW_TYPE, UPDATE_WORKFLOW_TYPES_DATA, UPDATE_WORKFLOW_TYPE_CUSTOM_FIELDS_DATA, UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTIONS_DATA, SYNCHRONIZE_WORKFLOW_TYPES_DATA, SYNCHRONIZE_WORKFLOW_TYPE_CUSTOM_FIELDS_DATA, SYNCHRONIZE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTIONS_DATA, CLEAR_WORKFLOW_TYPES_DELTA, INSTANTIATE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION, INSTANTIATE_WORKFLOW_TYPE_CUSTOM_FIELD, INSTANTIATE_WORKFLOW_TYPE, UPDATE_WORKFLOW_TYPE_RICH_TEXT, SET_WORKFLOW_TYPE_SEARCH_STRING, REMOVE_BETA_ISOLATED_WORKFLOW_TYPE_PIECE, SET_BETA_ISOLATED_WORKFLOW_TYPE_PIECE, UPDATE_WORKFLOW_TYPE_BETA_START_PIECE, PUBLISH_FLOWCHART_TO_LIVE, REMOVE_ALL_ISOLATED_WORKFLOW_TYPE_PIECES, REMOVE_ALL_BETA_ISOLATED_WORKFLOW_TYPE_PIECES, BULK_ADD_WORKFLOWS_TO_WORKFLOW_TYPE, BULK_REMOVE_WORKFLOWS_FROM_WORKFLOW_TYPE, UPDATE_PUBLISH_PIECE_MAPPING } from './types';
import { selectCustomField, unSelectCustomField, addCustomField, deleteCustomField, updateCustomField, selectCustomFieldOption, unSelectCustomFieldOption, reOrderCustomFieldOptions, addCustomFieldOption, deleteCustomFieldOption, updateCustomFieldOption, updateStartPieceForCustomField, setIsolatedPieceForCustomField, removeIsolatedPieceForCustomField, registerVariableForCustomField, updateCustomFields, updateCustomFieldOptions, synchronizeCustomFields, synchronizeCustomFieldOptions, clearDeltaForCustomFields } from '../../custom-fields';
import { WorkflowActionTypes } from '../types';
import { mergeReverseLinkArrays, reOrderList } from '../../../helpers/utilities';
import { statusesReducer, initialState as workflowStatusInitialState } from './statuses/reducer';
import { addEntity, deleteEntity, updateEntity, updateEntries, synchronizeEntries, clearDelta, synchronizeCustomFieldReverseLinks, synchronizeCustomFieldOptionReverseLinks } from '../../normalized-model';
import { RE_ORDER_STATUSES, SYNCHRONIZE_WORKFLOW_STATUSES_DATA } from './statuses/types';

export const initialState: WorkflowTypeState = {
    byId: {},
    allEntries: [],
    filteredEntries: [],
    selected: undefined,
    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),

    searchString: undefined,

    statuses: workflowStatusInitialState,
    customFields: {
        byId: {},
        allFields: []
    },
    customFieldOptions: {
        byId: {},
        allOptions: []
    },
    selectedField: undefined,
    selectedOption: undefined,
    createdCustomFieldIds: new Set(),
    updatedCustomFieldIds: new Set(),
    deletedCustomFieldIds: new Set(),
    createdCustomFieldOptionIds: new Set(),
    updatedCustomFieldOptionIds: new Set(),
    deletedCustomFieldOptionIds: new Set(),


    areWorkflowTypesReordered: false,

    reOrderedStatuses: {},
    reOrderedCustomFields: {},
    reOrderedCustomFieldOptions: {},
};

export function workflowTypesReducer(state = initialState, action: WorkflowActionTypes): WorkflowTypeState {
    state = {
        ...state,
        statuses: statusesReducer(state.statuses, action),
    }

    let newState: WorkflowTypeState;

    switch (action.type) {

        // Workflow type actions

        case SELECT_WORKFLOW_TYPE:
            return {
                ...state,
                selected: action.id,
            }

        case UN_SELECT_WORKFLOW_TYPE:
            return {
                ...state,
                selected: undefined,
            }

        case RE_ORDER_WORKFLOW_TYPES:
            return {
                ...state,
                allEntries: reOrderList(state.allEntries, action.sourceIndex, action.destinationIndex),
                areWorkflowTypesReordered: true,
            };

        case ADD_WORKFLOW_TYPE:
            return addEntity<WorkflowTypeState, IWorkflowType>(state, action.payload);

        case INSTANTIATE_WORKFLOW_TYPE:
            state = addEntity<WorkflowTypeState, IWorkflowType>(state, action.payload);

            state.reOrderedStatuses = {
                ...state.reOrderedStatuses,
                [action.payload.id]: action.payload.statuses,
            };

            return state;

        case DELETE_WORKFLOW_TYPE:
            return deleteEntity<WorkflowTypeState, IWorkflowType>(state, action.id, action.currentTime);

        case UPDATE_WORKFLOW_TYPE:
            return updateEntity<WorkflowTypeState, IWorkflowType>(state, action.payload, action.currentTime);

        case UPDATE_WORKFLOW_TYPE_RICH_TEXT:
            const selectedWorkflowType = state.byId[action.workflowTypeId];
            const updatedWorkflowTypeWithRichText = {
                ...selectedWorkflowType,
                richTextDescription: action.richText,
            };

            return updateEntity<WorkflowTypeState, IWorkflowType>(state, updatedWorkflowTypeWithRichText, action.currentTime);

        case SET_WORKFLOW_TYPE_SEARCH_STRING:
            return {
                ...state,
                searchString: action.searchString,
            };

        case ADD_WORKFLOW_TO_WORKFLOW_TYPE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowTypeId]: {
                        ...state.byId[action.workflowTypeId],
                        workflows: state.byId[action.workflowTypeId].workflows.concat([action.workflowId]),
                    },
                },
            }

        case REMOVE_WORKFLOW_FROM_WORKFLOW_TYPE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowTypeId]: {
                        ...state.byId[action.workflowTypeId],
                        workflows: state.byId[action.workflowTypeId].workflows.filter(workflowId => workflowId !== action.workflowId),
                    },
                },
            }

        case BULK_ADD_WORKFLOWS_TO_WORKFLOW_TYPE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowTypeId]: {
                        ...state.byId[action.workflowTypeId],
                        workflows: state.byId[action.workflowTypeId].workflows.concat(action.workflowIds),
                    },
                },
            }

        case BULK_REMOVE_WORKFLOWS_FROM_WORKFLOW_TYPE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowTypeId]: {
                        ...state.byId[action.workflowTypeId],
                        workflows: state.byId[action.workflowTypeId].workflows.filter(workflowId => !action.workflowIds.includes(workflowId)),
                    },
                },
            }

        case ADD_STATUS_TO_WORKFLOW_TYPE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowTypeId]: {
                        ...state.byId[action.workflowTypeId],
                        statuses: state.byId[action.workflowTypeId].statuses.concat(action.statusId),
                    },
                },
                reOrderedStatuses: {
                    ...state.reOrderedStatuses,
                    [action.workflowTypeId]: state.byId[action.workflowTypeId].statuses.concat(action.statusId),
                },
            }

        case REMOVE_STATUS_FROM_WORKFLOW_TYPE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowTypeId]: {
                        ...state.byId[action.workflowTypeId],
                        statuses: state.byId[action.workflowTypeId].statuses.filter(workflowId => workflowId !== action.statusId),
                    },
                },
                reOrderedStatuses: {
                    ...state.reOrderedStatuses,
                    [action.workflowTypeId]: state.byId[action.workflowTypeId].statuses.filter(workflowId => workflowId !== action.statusId),
                }
            }

        case UPDATE_WORKFLOW_TYPE_START_PIECE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowTypeId]: {
                        ...state.byId[action.workflowTypeId],
                        startPiece: action.payload,
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.workflowTypeId]),
            }

        case SET_ISOLATED_WORKFLOW_TYPE_PIECE:
            const newIsolatedPieces = state.byId[action.workflowTypeId].isolatedPieces.slice(0);
            const isolatedPieceIndex = newIsolatedPieces.findIndex(isolatedPieceData => isolatedPieceData.piece === action.payload.piece);

            if (isolatedPieceIndex < 0) {
                newIsolatedPieces.push(action.payload);
            } else {
                newIsolatedPieces[isolatedPieceIndex] = action.payload;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowTypeId]: {
                        ...state.byId[action.workflowTypeId],
                        isolatedPieces: newIsolatedPieces,
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.workflowTypeId]),
            }

        case REMOVE_ISOLATED_WORKFLOW_TYPE_PIECE:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowTypeId]: {
                        ...state.byId[action.workflowTypeId],
                        isolatedPieces: state.byId[action.workflowTypeId].isolatedPieces.filter(pieceData => pieceData.piece !== action.pieceId),
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.workflowTypeId]),
            }

        case REMOVE_ALL_ISOLATED_WORKFLOW_TYPE_PIECES:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowTypeId]: {
                        ...state.byId[action.workflowTypeId],
                        isolatedPieces: [],
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.workflowTypeId]),
            }

        case UPDATE_WORKFLOW_TYPE_BETA_START_PIECE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowTypeId]: {
                        ...state.byId[action.workflowTypeId],
                        betaStartPiece: action.payload,
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.workflowTypeId]),
            }

        case SET_BETA_ISOLATED_WORKFLOW_TYPE_PIECE:
            const newBetaIsolatedPieces = state.byId[action.workflowTypeId].betaIsolatedPieces.slice(0);
            const betaIsolatedPieceIndex = newBetaIsolatedPieces.findIndex(isolatedPieceData => isolatedPieceData.piece === action.payload.piece);

            if (betaIsolatedPieceIndex < 0) {
                newBetaIsolatedPieces.push(action.payload);
            } else {
                newBetaIsolatedPieces[betaIsolatedPieceIndex] = action.payload;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowTypeId]: {
                        ...state.byId[action.workflowTypeId],
                        betaIsolatedPieces: newBetaIsolatedPieces,
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.workflowTypeId]),
            }

        case REMOVE_BETA_ISOLATED_WORKFLOW_TYPE_PIECE:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowTypeId]: {
                        ...state.byId[action.workflowTypeId],
                        betaIsolatedPieces: state.byId[action.workflowTypeId].betaIsolatedPieces?.filter(pieceData => pieceData.piece !== action.pieceId),
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.workflowTypeId]),
            }

        case REMOVE_ALL_BETA_ISOLATED_WORKFLOW_TYPE_PIECES:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowTypeId]: {
                        ...state.byId[action.workflowTypeId],
                        betaIsolatedPieces: [],
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.workflowTypeId]),
            }

        case PUBLISH_FLOWCHART_TO_LIVE:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowTypeId]: {
                        ...state.byId[action.workflowTypeId],
                        startPiece: state.byId[action.workflowTypeId].betaStartPiece,
                        previousVersions: state.byId[action.workflowTypeId].previousVersions.concat({
                            ...state.byId[action.workflowTypeId].startPiece,
                            deprecationTime: action.currentTime,
                        }),
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.workflowTypeId]),
            }

        case UPDATE_PUBLISH_PIECE_MAPPING:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowTypeId]: {
                        ...state.byId[action.workflowTypeId],
                        publishPieceMapping: action.publishPieceMapping,
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.workflowTypeId]),
            }

        case REGISTER_WORKFLOW_TYPE_VARIABLE:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowTypeId]: {
                        ...state.byId[action.workflowTypeId],
                        variables: state.byId[action.workflowTypeId].variables.concat([action.variableId]),
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.workflowTypeId]),
            }

        case SELECT_WORKFLOW_TYPE_CUSTOM_FIELD:
            return selectCustomField<WorkflowTypeState>(state, action.id);

        case UN_SELECT_WORKFLOW_TYPE_CUSTOM_FIELD:
            return unSelectCustomField<WorkflowTypeState>(state);

        case ADD_WORKFLOW_TYPE_CUSTOM_FIELD:
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowTypeId]: {
                        ...state.byId[action.workflowTypeId],
                        customFields: state.byId[action.workflowTypeId].customFields.concat([action.payload.id]),
                    }
                },
                reOrderedCustomFields: {
                    ...state.reOrderedCustomFields,
                    [action.workflowTypeId]: state.byId[action.workflowTypeId].customFields.concat([action.payload.id]),
                },
            }
            return addCustomField<WorkflowTypeState>(newState, action.payload, action.workflowTypeId, action.currentTime);


        case INSTANTIATE_WORKFLOW_TYPE_CUSTOM_FIELD:
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowTypeId]: {
                        ...state.byId[action.workflowTypeId],
                        customFields: state.byId[action.workflowTypeId].customFields.concat([action.payload.id]),
                    }
                },
                reOrderedCustomFields: {
                    ...state.reOrderedCustomFields,
                    [action.workflowTypeId]: state.byId[action.workflowTypeId].customFields.concat([action.payload.id]),
                },
            }
            return addCustomField<WorkflowTypeState>(newState, action.payload, action.workflowTypeId, action.currentTime);

        case DELETE_WORKFLOW_TYPE_CUSTOM_FIELD:
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                    [action.workflowTypeId]: {
                        ...state.byId[action.workflowTypeId],
                        customFields: state.byId[action.workflowTypeId].customFields.filter(customFieldId => customFieldId !== action.id),
                    }
                },
                reOrderedCustomFields: {
                    ...state.reOrderedCustomFields,
                    [action.workflowTypeId]: state.byId[action.workflowTypeId].customFields.filter(customFieldId => customFieldId !== action.id),
                },
            }
            return deleteCustomField<WorkflowTypeState>(newState, action.id, action.currentTime);

        case UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD:
            return updateCustomField<WorkflowTypeState>(state, action.payload, action.currentTime);


        case UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD_START_PIECE:
            return updateStartPieceForCustomField<WorkflowTypeState>(state, action.customFieldId, action.payload, action.currentTime);

        case SET_ISOLATED_WORKFLOW_TYPE_CUSTOM_FIELD_PIECE:
            return setIsolatedPieceForCustomField<WorkflowTypeState>(state, action.customFieldId, action.payload, action.currentTime);

        case REMOVE_ISOLATED_WORKFLOW_TYPE_CUSTOM_FIELD_PIECE:
            return removeIsolatedPieceForCustomField<WorkflowTypeState>(state, action.customFieldId, action.pieceId, action.currentTime);

        case REGISTER_WORKFLOW_TYPE_CUSTOM_FIELD_VARIABLE:
            return registerVariableForCustomField<WorkflowTypeState>(state, action.customFieldId, action.variableId, action.currentTime);


        case SELECT_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION:
            return selectCustomFieldOption<WorkflowTypeState>(state, action.id);

        case UN_SELECT_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION:
            return unSelectCustomFieldOption<WorkflowTypeState>(state);

        case RE_ORDER_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION:
            return reOrderCustomFieldOptions<WorkflowTypeState>(state, action.sourceIndex, action.destinationIndex, action.parentId);

        case ADD_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION:
            return addCustomFieldOption<WorkflowTypeState>(state, action.payload, action.parentId, action.currentTime);

        case INSTANTIATE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION:
            return addCustomFieldOption<WorkflowTypeState>(state, action.payload, action.parentId, action.currentTime);

        case DELETE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION:
            return deleteCustomFieldOption<WorkflowTypeState>(state, action.id, action.parentId, action.currentTime);

        case UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTION:
            return updateCustomFieldOption<WorkflowTypeState>(state, action.payload, action.currentTime);

        case UPDATE_WORKFLOW_TYPES_DATA:
            return updateEntries<WorkflowTypeState, IWorkflowType>(state, action.data);

        case UPDATE_WORKFLOW_TYPE_CUSTOM_FIELDS_DATA:
            return updateCustomFields<WorkflowTypeState>(state, action.data);

        case UPDATE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTIONS_DATA:
            return updateCustomFieldOptions<WorkflowTypeState>(state, action.data);

        case SYNCHRONIZE_WORKFLOW_TYPES_DATA:
            state = synchronizeEntries<WorkflowTypeState, IWorkflowType>(state, action.data);
            if (action.reOrder.length > 0) {
                state.allEntries = mergeReverseLinkArrays(action.reOrder, state.allEntries);
            }
            return state;

        case SYNCHRONIZE_WORKFLOW_STATUSES_DATA:
            state = {
                ...state,
                byId: {
                    ...state.byId,
                }
            };

            state.reOrderedStatuses = {
                ...state.reOrderedStatuses,
            };

            for (const workflowTypeId in action.reOrderedStatuses) {
                let newStatusIds = action.reOrderedStatuses[workflowTypeId];

                if (workflowTypeId in state.reOrderedStatuses) {
                    newStatusIds = mergeReverseLinkArrays(newStatusIds, state.reOrderedStatuses[workflowTypeId]);
                    state.reOrderedStatuses[workflowTypeId] = newStatusIds;
                }

                const workflowType = state.byId[workflowTypeId];

                if (JSON.stringify(workflowType.statuses) !== JSON.stringify(newStatusIds)) {
                    state.byId[workflowTypeId] = {
                        ...state.byId[workflowTypeId],
                        statuses: newStatusIds,
                    };
                }
            }

            return state;

        case SYNCHRONIZE_WORKFLOW_TYPE_CUSTOM_FIELDS_DATA:
            newState = synchronizeCustomFields<WorkflowTypeState>(state, action.data);
            newState = synchronizeCustomFieldReverseLinks<WorkflowTypeState>(newState, action.data);
            return newState;

        case SYNCHRONIZE_WORKFLOW_TYPE_CUSTOM_FIELD_OPTIONS_DATA:
            newState = synchronizeCustomFieldOptions<WorkflowTypeState>(state, action.data);
            newState = synchronizeCustomFieldOptionReverseLinks<WorkflowTypeState>(newState, action.reOrderedCustomFieldOptions);
            return newState;

        case CLEAR_WORKFLOW_TYPES_DELTA:
            newState = clearDelta<WorkflowTypeState, IWorkflowType>(state);
            newState.areWorkflowTypesReordered = false;
            newState.reOrderedStatuses = {};
            newState.reOrderedCustomFields = {};
            newState = clearDeltaForCustomFields<WorkflowTypeState>(newState);
            return newState;

        case RE_ORDER_STATUSES:
            const reOrderedList = reOrderList(state.byId[action.parentId].statuses, action.sourceIndex, action.destinationIndex);

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.parentId]: {
                        ...state.byId[action.parentId],
                        statuses: reOrderedList,
                    }
                },
                reOrderedStatuses: {
                    ...state.reOrderedStatuses,
                    [action.parentId]: reOrderedList,
                }
            }

        default:
            return state;
    }
}