import { ApplicationState } from '../store/types';
import { isUUID } from './utilities';
import { getAllLocationsVisibleToUser, getAllLocationsUnderUser } from './locations';
import { Permissions } from '../store/permissions/types';

export function getVisibleUserIds(state: ApplicationState, ignoreFilters?: boolean, userId?: string, filteredLocationIds?: Array<string>) {
    let visibleUsers: Array<string> = [];

    if (!userId) {
        userId = state.myData.id;
    }

    const masterUserIdList = !ignoreFilters && state.users.filters.archived ? Object.keys(state.users.byId).filter(userId => state.users.byId[userId].archived) : state.users.allEntries;

    if (isUUID(userId)) {
        let visibleLocationIds = new Set(getAllLocationsUnderUser(userId, state));

        if (typeof filteredLocationIds !== 'undefined') {
            const filteredSet = new Set(filteredLocationIds);
            const tempLocationIds = new Set<string>();
            for (const locationId of visibleLocationIds) {
                if (filteredSet.has(locationId)) {
                    tempLocationIds.add(locationId);
                }
            }
            visibleLocationIds = tempLocationIds;
        }

        visibleUsers = masterUserIdList.filter(userId => {
            const user = state.users.byId[userId];
            return user.locations.some(locationId => visibleLocationIds.has(locationId));
        })
    } else {
        if (typeof filteredLocationIds !== 'undefined') {
            const filteredSet = new Set(filteredLocationIds);

            visibleUsers = masterUserIdList.filter(userId => {
                const user = state.users.byId[userId];
                return user.locations.some(locationId => filteredSet.has(locationId));
            })
        } else {
            visibleUsers = masterUserIdList;
        }
    }

    return visibleUsers;
}

export function getVisibleMemberIds(state: ApplicationState, ignoreFilters?: boolean, userId?: string, filteredLocationIds?: Array<string>) {
    let visibleMembers: Array<string> = [];

    if (!userId) {
        userId = state.myData.id;
    }

    const masterMemberIdList = !ignoreFilters && state.members.filters.archived ? Object.keys(state.members.byId).filter(memberId => state.members.byId[memberId].archived) : state.members.allEntries;

    if (isUUID(userId)) {
        let visibleLocationIds = new Set(getAllLocationsVisibleToUser(userId, state));

        if (typeof filteredLocationIds !== 'undefined') {
            const filteredSet = new Set(filteredLocationIds);
            const tempLocationIds = new Set<string>();
            for (const locationId of visibleLocationIds) {
                if (filteredSet.has(locationId)) {
                    tempLocationIds.add(locationId);
                }
            }
            visibleLocationIds = tempLocationIds;
        }

        visibleMembers = masterMemberIdList.filter(memberId => {
            const member = state.members.byId[memberId];
            return visibleLocationIds.has(member.location);
        });
    } else {
        if (typeof filteredLocationIds !== 'undefined') {
            const filteredSet = new Set(filteredLocationIds);

            visibleMembers = masterMemberIdList.filter(memberId => {
                const member = state.members.byId[memberId];
                return filteredSet.has(member.location);
            });
        } else {
            visibleMembers = masterMemberIdList;
        }
    }

    visibleMembers = visibleMembers.filter(memberId => {
        const member = state.members.byId[memberId];
        if (member.type in state.permissions.myPermissions.members) {
            return state.permissions.myPermissions.members[member.type] !== Permissions.NONE;
        } else {
            return true;
        }
    });

    return visibleMembers;
}

export function getVisibleGroupIds(state: ApplicationState, ignoreFilters?: boolean, userId?: string, filteredLocationIds?: Array<string>) {
    let visibleGroups: Array<string> = [];

    if (!userId) {
        userId = state.myData.id;
    }

    const masterGroupIdList = !ignoreFilters && state.groups.filters.archived ? Object.keys(state.groups.byId).filter(groupId => state.groups.byId[groupId].archived) : state.groups.allEntries;

    if (isUUID(userId)) {
        let visibleLocationIds = new Set(getAllLocationsVisibleToUser(userId, state));

        if (typeof filteredLocationIds !== 'undefined') {
            const filteredSet = new Set(filteredLocationIds);
            const tempLocationIds = new Set<string>();
            for (const locationId of visibleLocationIds) {
                if (filteredSet.has(locationId)) {
                    tempLocationIds.add(locationId);
                }
            }
            visibleLocationIds = tempLocationIds;
        }

        visibleGroups = masterGroupIdList.filter(groupId => {
            const group = state.groups.byId[groupId];
            return visibleLocationIds.has(group.location);
        });
    } else {
        if (typeof filteredLocationIds !== 'undefined') {
            const filteredSet = new Set(filteredLocationIds);

            visibleGroups = masterGroupIdList.filter(groupId => {
                const group = state.groups.byId[groupId];
                return filteredSet.has(group.location);
            });
        } else {
            visibleGroups = masterGroupIdList;
        }
    }

    visibleGroups = visibleGroups.filter(groupId => {
        const group = state.groups.byId[groupId];
        if (group.type in state.permissions.myPermissions.groups) {
            return state.permissions.myPermissions.groups[group.type] !== Permissions.NONE;
        } else {
            return true;
        }
    });

    return visibleGroups;
}

export function getVisibleWorkflowIds(state: ApplicationState, visibleUserIds?: Array<string>, visibleMemberIds?: Array<string>, visibleGroupIds?: Array<string>, ignoreFilters?: boolean, userId?: string, filteredLocationIds?: Array<string>) {

    if (!userId) {
        userId = state.myData.id;
    }

    let visibleUsers = visibleUserIds ? visibleUserIds : getVisibleUserIds(state, ignoreFilters, userId, filteredLocationIds),
        visibleMembers = visibleMemberIds ? visibleMemberIds : getVisibleMemberIds(state, ignoreFilters, userId, filteredLocationIds),
        visibleGroups = visibleGroupIds ? visibleGroupIds : getVisibleGroupIds(state, ignoreFilters, userId, filteredLocationIds),
        visibleWorkflows: Array<string> = [];

    type WorkflowIdsHolder = {
        [workflowTypeId: string]: Array<string>,
    };

    function getWorkflowIdsFromWorkflowIdsHolder(workflowIdsHolder: WorkflowIdsHolder, workflowsSet: Set<string>) {

        for (const workflowTypeId in workflowIdsHolder) {
            if (workflowTypeId in state.permissions.myPermissions.workflows) {
                if (state.permissions.myPermissions.workflows[workflowTypeId] === Permissions.NONE) {
                    continue;
                }
            }

            workflowIdsHolder[workflowTypeId].forEach(workflowId => workflowId in state.workflows.byId && workflowsSet.add(workflowId));
        }

    }

    const masterWorkflowIdList = !ignoreFilters && state.workflows.filters.archived ? Object.keys(state.workflows.byId).filter(workflowId => state.workflows.byId[workflowId].archived) : state.workflows.allEntries;

    let initialWorkflowIds: Array<string> = [];

    if (ignoreFilters || state.workflows.filters.dues.includes('completed') || state.workflows.filters.archived) {
        initialWorkflowIds = masterWorkflowIdList;
    } else {
        initialWorkflowIds = state.workflows.activeWorkflowEntries;
    }

    if (isUUID(userId)) {
        const allowedWorkflows = new Set<string>();

        const loggedInUser = state.users.byId[userId];

        getWorkflowIdsFromWorkflowIdsHolder(loggedInUser.workflows, allowedWorkflows);


        visibleUsers.forEach(userId => getWorkflowIdsFromWorkflowIdsHolder(state.users.byId[userId].workflows, allowedWorkflows));
        visibleMembers.forEach(userId => getWorkflowIdsFromWorkflowIdsHolder(state.members.byId[userId].workflows, allowedWorkflows));
        visibleGroups.forEach(userId => getWorkflowIdsFromWorkflowIdsHolder(state.groups.byId[userId].workflows, allowedWorkflows));

        const allAffiliations = new Set(visibleMembers.concat(visibleGroups));
        const visibleUsersIdsSet = new Set(visibleUserIds);

        initialWorkflowIds.forEach(workflowId => {
            const workflow = state.workflows.byId[workflowId];

            if (!workflow || !workflow.trackingUsers) {
                return;
            }

            if (!userId) {
                userId = state.myData.id;
            }

            if (workflow.trackingUsers.includes(userId)) {
                allowedWorkflows.add(workflowId);
            } else if (visibleUsersIdsSet.has(workflow.user)) {
                allowedWorkflows.add(workflowId);
            } else if (workflow.affiliatedEntity && allAffiliations.has(workflow.affiliatedEntity)) {
                allowedWorkflows.add(workflowId);
            }
        });

        visibleWorkflows = initialWorkflowIds.filter(workflowId => allowedWorkflows.has(workflowId));
    } else {
        visibleWorkflows = initialWorkflowIds;
    }

    return visibleWorkflows;
}

export function getVisibleReportIds(state: ApplicationState, ignoreFilters?: boolean, userId?: string) {
    let visibleReportIds: Array<string> = [];

    if (!userId) {
        userId = state.myData.id;
    }

    const masterReportIdList = !ignoreFilters && state.reports.filters.archived ? Object.keys(state.reports.byId).filter(reportId => state.reports.byId[reportId].archived) : state.reports.allEntries;

    if (isUUID(userId)) {

        const userIds = new Set(getVisibleUserIds(state, ignoreFilters, userId));
        userIds.add(userId);

        visibleReportIds = masterReportIdList.filter(reportId => {
            const report = state.reports.byId[reportId];
            return userIds.has(report.user);
        });
    } else {
        visibleReportIds = masterReportIdList;
    }

    visibleReportIds = visibleReportIds.filter(reportId => {
        const report = state.reports.byId[reportId];
        if (report.type in state.permissions.myPermissions.reports) {
            return state.permissions.myPermissions.reports[report.type] !== Permissions.NONE;
        } else {
            return true;
        }
    });

    return visibleReportIds;
}