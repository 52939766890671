import React, { Component } from 'react';
import styles from './ShowData.module.scss';
import Table, { TableRow } from '../../../widgets/table/Table';
import { CSVLink } from 'react-csv';
import ChartistGraph from 'react-chartist';
import MultiSelectInputText from '../../../widgets/form/MultiSelectInput';
import {
    IBarChartOptions,
    ILineChartOptions,
    IPieChartOptions,
} from 'chartist';
import * as Chartist from 'chartist';
import 'chartist-plugin-tooltip';
import 'chartist-plugin-axistitle';
import { setToastMessage } from '../../../shared/store/my-data/actions';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { getReadableDataForCustomField, } from '../../../shared/store/custom-fields';
import { CustomFieldValueType, CustomField, WorkflowTypeCustomField } from '../../../shared/store/custom-fields/types';

import { ApplicationState } from '../../../shared/store/types';
import { IWorkflow } from '../../../shared/store/workflows/types';
import { IUser } from '../../../shared/store/users/types';
import { IMember } from '../../../shared/store/members/types';
import { IGroup } from '../../../shared/store/groups/types';

import { ReactComponent as LineChartIcon } from '../../../common/assets/line-chart.svg';
import { ReactComponent as BarChartIcon } from '../../../common/assets/bar-chart-fill.svg';
import { ReactComponent as DonutChartIcon } from '../../../common/assets/doughnut-chart.svg';
import { ReactComponent as TableIcon } from '../../../common/assets/table.svg';
import { ReactComponent as ExportIcon } from '../../../common/assets/export.svg';
import { ReactComponent as ShareIcon } from '../../../common/assets/share.svg';
import { ReactComponent as MoreIcon } from '../../../assets/new-custom-icons/common/more.svg';
import { ReactComponent as FilterIcon } from '../../../common/assets/filter.svg';
import { ReactComponent as CloseIcon } from '../../../common/assets/close.svg';
import { ReactComponent as SearchIcon } from '../../../common/assets/search.svg';
import { ReactComponent as UsersIcon } from '../../../assets/new-custom-icons/dashboard/user.svg';
import { ReactComponent as MembersIcon } from '../../../assets/new-custom-icons/dashboard/member.svg';
import { ReactComponent as GroupsIcon } from '../../../assets/new-custom-icons/dashboard/group.svg';
import { ReactComponent as WorkflowIcon } from '../../../assets/new-custom-icons/dashboard/workflow.svg';
import { ReactComponent as RefreshIcon } from '../../../assets/new-custom-icons/new-revision/refresh.svg';
import { ReactComponent as RightArrow } from '../../../common/assets/right-arrow.svg';

import { translatePhrase } from '../../../shared/helpers/translation';
import { copyStringToClipboard, isUUID } from '../../../shared/helpers/utilities';
import draftToHtml from 'draftjs-to-html';
import ReactHtmlParser from 'react-html-parser';
import { getAllLocationsInTree, getAncestorChainOfLocation } from '../../../shared/helpers/locations';
import InputText, { OptionInput } from '../../../widgets/form/InputText';
import { getChartData, getExportData, getShowData } from '../../../shared/helpers/show-data';
import moment from 'moment';
import { IWidgetFilterState, WidgetData } from '../../../shared/store/widgets/types';
import Button from '../../../widgets/button/CommonButton';

import { CircularProgressbar } from 'react-circular-progressbar';
import DateInput from '../../../widgets/form/DateInput';

import ScrollContainer from 'react-indiana-drag-scroll';
import { NudgeType } from '../../../shared/store/my-data/types';
import { Option } from '../../flowchart/drop-down/ListItem';
import { RouteComponentProps, withRouter } from 'react-router';
import { setSelectedWidgetFilterOptions } from '../../../shared/store/widgets/actions';

export type ShowTableProps = {
    entityIds: Array<string>,
    type: string,
    typeId?: string,
    widgetId?: string,
    systemFields?: Array<string>,
    customFields: Array<string>,
    exportingSystemFields?: Array<string>,
    exportingCustomFields?: Array<string>,

    aggregation?: 'none' | 'count' | 'sum' | 'average',
    roles?: Array<string>,
    xAxis?: string,
    yAxis?: string,
    yAxisAggregation?: 'sum' | 'average',
    startingDisplayType?: 'table' | 'bar' | 'line' | 'donut' | 'message' | 'formatted-table',

    header?: string,
    optionsMarkup?: JSX.Element,

    textToShow?: string,

    updateWidgetCache?: () => void,
    setIsPopulatingExport: (isPopulatingExport: boolean) => void,
    setToastLoaderForCopy?: () => void,
};


const mapStateToProps = (state: ApplicationState, ownProps: ShowTableProps) => {

    let cachedWidgetData: WidgetData | undefined;

    if (ownProps.widgetId && ownProps.widgetId in state.widgets.cachedWidgetData) {
        cachedWidgetData = state.widgets.cachedWidgetData[ownProps.widgetId];
    }

    return {
        projectsData: state.structure.projects,
        levelsData: state.structure.levels,
        rolesData: state.structure.roles,
        locationsData: state.structure.locations,
        usersData: state.users,
        membersData: state.members,
        groupsData: state.groups,
        workflowData: state.workflows,

        selectedNudge: state.myData.selectedNudgeId,
        cachedWidgetData,
        isPartial: ownProps.widgetId && state.widgets.partiallyCachedWidgetIds && state.widgets.partiallyCachedWidgetIds.includes(ownProps.widgetId),

        applicationState: state,
        myId: state.myData.id,
        selectedWidgetFilterOptions: state.widgets.selectedWidgetFilterOptions
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setToastMessage: (message: string) => dispatch(setToastMessage(message)),
        setSelectedWidgetFilterOptions: (selectedFilterOptions: IWidgetFilterState) => dispatch(setSelectedWidgetFilterOptions(selectedFilterOptions))
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = ShowTableProps & StateProps & DispatchProps;

type OwnState = {
    dataType: 'table' | 'bar' | 'line' | 'donut' | 'message' | 'formatted-table',
    filters: {
        [customFieldId: string]: Array<string>,
    },
    pageNumber: number,
    showMoreOptions: boolean,

    tableSearchTerm: string,

    // Filter Params
    showFilter: boolean,
    showSearchBar: boolean,
    selectedLevel: string,
    selectedLocations: Array<string>,
    selectedStatuses: Array<string>,
    selectedDropdownCustomField: {
        [customFieldId: string]: string
    },
    selectedDateCustomField: {
        [customFieldId: string]: {
            from: string,
            to: string,
        }
    },
    selectedSystemDateField: {
        [fieldName: string]: {
            from: string,
            to: string,
        }
    },

    csvData: Array<Array<string>>,
}

class ConnectedShowTable extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            dataType: props.startingDisplayType || 'table',
            filters: {},
            pageNumber: 1,
            showMoreOptions: false,
            tableSearchTerm: '',

            // Filter Params
            showFilter: false,

            selectedLevel: '',
            selectedLocations: [],
            selectedStatuses: [],
            selectedDropdownCustomField: {},
            selectedDateCustomField: {},
            selectedSystemDateField: {},

            showSearchBar: false,

            csvData: [],
        }
    }


    getTypeIcon(type: string) {
        if (type === 'User') {
            return <UsersIcon />
        }
        if (type === 'Member') {
            return <MembersIcon />
        }
        if (type === 'Group') {
            return <GroupsIcon />
        }
        if (type === 'Workflow') {
            return <WorkflowIcon />
        }
        return null;
    }

    getCommonCustomFieldValue = (entity: IUser | IMember | IGroup, type: 'user' | 'member' | 'group', customField: CustomField) => {
        let customFieldValue = entity.customFields[customField.id];

        return getReadableDataForCustomField(customFieldValue, customField, entity.id, type);
    }

    getWorkflowCustomFieldValue = (workflow: IWorkflow, customField: WorkflowTypeCustomField) => {
        let customFieldValue = workflow.history[workflow.historyIndex].customFields[customField.id] as CustomFieldValueType;

        return getReadableDataForCustomField(customFieldValue, customField, workflow.id, 'workflow');
    }

    getShareLink = () => {
        let hostname = window.location.host;

        if (hostname.startsWith('www')) {
            hostname = hostname.substring(4);
        }

        const shareUrl = `https://backend.${hostname}/public-widget/?id=${this.props.widgetId}`;

        this.props.setToastLoaderForCopy ? this.props.setToastLoaderForCopy() : this.props.setToastMessage(translatePhrase('The share URL has been copied to clipboard'));

        copyStringToClipboard(shareUrl);
    }

    selectLevel = (levelId: string) => {
        this.setState({
            selectedLevel: levelId,
            selectedLocations: []
        });
    }

    selectDropdownCustomField = (customFieldId: string, value: string) => {
        let selectedDropdownCustomField = this.state.selectedDropdownCustomField;
        selectedDropdownCustomField[customFieldId] = value;

        this.setState({
            selectedDropdownCustomField,
        });
    }

    selectDateRangeForCustomField = (customFieldId: string, from: string, to: string) => {
        let selectedDateCustomField = this.state.selectedDateCustomField;

        selectedDateCustomField[customFieldId] = {
            from: from,
            to: to,
        };

        this.setState({
            selectedDateCustomField,
        });
    }

    selectDateRangeForSystemField = (type: string, from: string, to: string) => {
        let selectedSystemDateField = this.state.selectedSystemDateField;

        selectedSystemDateField[type] = {
            from: from,
            to: to,
        };

        this.setState({
            selectedSystemDateField,
        });
    }

    changeLocationFieldOptions = (selectedLocations: Array<string>) => {
        this.setState({
            selectedLocations
        });
    }

    changeStatusFieldOptions = (selectedStatuses: Array<string>) => {
        this.setState({
            selectedStatuses
        });
    }

    setFilterOptions() {
        if (this.props.widgetId) {
            let selectedFilterOptions: IWidgetFilterState = {
                widgetId: this.props.widgetId,
                selectedLevel: this.state.selectedLevel,
                selectedLocations: this.state.selectedLocations,
                selectedStatuses: this.state.selectedStatuses,
                selectedDropdownCustomField: this.state.selectedDropdownCustomField,
                selectedDateCustomField: this.state.selectedDateCustomField,
                selectedSystemDateField: this.state.selectedSystemDateField
            }

            this.props.setSelectedWidgetFilterOptions(selectedFilterOptions);
        }

    }

    componentDidMount() {
        if (this.props.selectedWidgetFilterOptions && this.props.selectedWidgetFilterOptions.length > 0 && this.props.widgetId) {
            this.props.selectedWidgetFilterOptions.map((filterData: IWidgetFilterState) => {
                if (filterData.widgetId === this.props.widgetId) {
                    this.setState({
                        selectedLevel: filterData.selectedLevel,
                        selectedLocations: filterData.selectedLocations,
                        selectedStatuses: filterData.selectedStatuses,
                        selectedDropdownCustomField: filterData.selectedDropdownCustomField,
                        selectedDateCustomField: filterData.selectedDateCustomField,
                        selectedSystemDateField: filterData.selectedSystemDateField
                    })
                }
            })
        }
    }
    componentDidUpdate(prevProps: Props, prevState: OwnState) {
        if (this.state !== prevState) {
            this.setFilterOptions();
            console.log(this.state.selectedDateCustomField)
            console.log(this.state.selectedSystemDateField)
        }
    }

    changeDisplay(dataType: string) {
        switch (dataType) {
            case 'table':
                this.setState({
                    dataType: 'table',
                    tableSearchTerm: '',
                    filters: {},
                });
                break;
            case 'bar':
                this.setState({
                    dataType: 'bar',
                    tableSearchTerm: '',
                    filters: {},
                });
                break;
            case 'line':
                this.setState({
                    dataType: 'line',
                    tableSearchTerm: '',
                    filters: {},
                });
                break;
            case 'donut':
                this.setState({
                    dataType: 'donut',
                    tableSearchTerm: '',
                    filters: {},
                });
                break;
        }
    }

    goToPage = (pageNumber: number) => {
        this.setState({
            pageNumber,
        });
    }

    getMarkupForRichText = (value: string) => {
        let stringifiedHtml: string;
        try {
            stringifiedHtml = draftToHtml(JSON.parse(value));
        } catch (e) {
            stringifiedHtml = '<p>Incorrect HTML</p>';
        }

        return ReactHtmlParser(stringifiedHtml);
    }

    getReadableProject = (projectId: string) => {
        if (!(projectId in this.props.applicationState.structure.projects.byId)) {
            return undefined;
        }

        return this.props.applicationState.structure.projects.byId[projectId].name;
    }

    getReadableLevel = (levelId: string) => {
        if (!(levelId in this.props.applicationState.structure.levels.byId)) {
            return undefined;
        }

        return this.props.applicationState.structure.levels.byId[levelId].name;
    }

    getReadableRole = (roleId: string) => {
        if (!(roleId in this.props.applicationState.structure.roles.byId)) {
            return undefined;
        }

        return this.props.applicationState.structure.roles.byId[roleId].name;
    }

    getReadableLocation = (locationId: string) => {
        const locationChain = getAncestorChainOfLocation(locationId).slice().reverse().concat([locationId]);
        return locationChain.map(locationId => {
            if (!(locationId in this.props.applicationState.structure.locations.byId)) {
                return undefined;
            }

            return this.props.applicationState.structure.locations.byId[locationId].name
        }).filter(location => !!location).join(' > ');
    }

    setTableSearchTerm = (searchTerm: string) => {
        this.setState({
            tableSearchTerm: searchTerm,
        });
    }

    filterEntity = (entityId: string) => {
        const effectiveLocations = Array.from(new Set(this.state.selectedLocations.map(locationId => getAllLocationsInTree(locationId)).flat()));

        switch (this.props.type) {
            case 'User':
                const user = this.props.usersData.byId[entityId];
                return user.locations.some(locationId => effectiveLocations.includes(locationId));
            case 'Member':
                const member = this.props.membersData.byId[entityId];
                return effectiveLocations.includes(member.location);
            case 'Group':
                const group = this.props.groupsData.byId[entityId];
                return effectiveLocations.includes(group.location);
            case 'Workflow':
                const workflow = this.props.workflowData.byId[entityId];
                if (workflow.affiliatedEntity) {
                    let affiliatedEntity: IMember | IGroup;

                    if (workflow.affiliatedEntity in this.props.membersData.byId) {
                        affiliatedEntity = this.props.membersData.byId[workflow.affiliatedEntity];
                        return effectiveLocations.includes(affiliatedEntity.location);
                    } else if (workflow.affiliatedEntity in this.props.groupsData.byId) {
                        affiliatedEntity = this.props.groupsData.byId[workflow.affiliatedEntity];
                        return effectiveLocations.includes(affiliatedEntity.location);
                    } else {
                        return true;
                    }
                } else {
                    const assignedUser = this.props.usersData.byId[workflow.user];
                    return assignedUser.locations.some(locationId => effectiveLocations.includes(locationId));
                }
            default:
                return true;
        }
    }

    filterEntitiesByStatus = (filteredEntities: Array<string>) => {
        if (this.props.type !== 'Workflow' || this.state.selectedStatuses.length === 0) {
            return filteredEntities;
        }

        return filteredEntities.filter((entityId) => {
            const workflow = this.props.workflowData.byId[entityId];
            return this.state.selectedStatuses.includes(workflow.status);
        });
    }

    filterEntitiesBySelectedDropdown = (filteredEntities: Array<string>) => {
        return filteredEntities.filter((entityId) => {
            switch (this.props.type) {
                case 'User':
                    const user = this.props.usersData.byId[entityId];
                    let checkedUserFields = [];

                    for (let field in this.state.selectedDropdownCustomField) {
                        if (this.state.selectedDropdownCustomField[field] === '-' && user.customFields[field] === undefined) {
                            checkedUserFields.push(true);
                        } else if (Array.isArray(user.customFields[field])) {
                            let customArrayField: any = [];
                            customArrayField = user.customFields[field];
                            checkedUserFields.push(customArrayField.includes(this.state.selectedDropdownCustomField[field]));
                        } else {
                            checkedUserFields.push(user.customFields[field] === this.state.selectedDropdownCustomField[field]);
                        }
                    }

                    return new Set(checkedUserFields).size === 1 && checkedUserFields[0] === true;
                case 'Member':
                    const member = this.props.membersData.byId[entityId];
                    let checkedMemberFields = [];

                    for (let field in this.state.selectedDropdownCustomField) {

                        if (this.state.selectedDropdownCustomField[field] === '-' && member.customFields[field] === undefined) {
                            checkedMemberFields.push(true);
                        } else if (Array.isArray(member.customFields[field])) {
                            let customArrayField: any = [];
                            customArrayField = member.customFields[field];

                            checkedMemberFields.push(customArrayField.includes(this.state.selectedDropdownCustomField[field]));
                        } else {
                            checkedMemberFields.push(member.customFields[field] === this.state.selectedDropdownCustomField[field]);
                        }

                    }

                    return new Set(checkedMemberFields).size === 1 && checkedMemberFields[0] === true;
                case 'Group':
                    const group = this.props.groupsData.byId[entityId];
                    let checkedGroupFields = [];

                    for (let field in this.state.selectedDropdownCustomField) {
                        if (this.state.selectedDropdownCustomField[field] === '-' && group.customFields[field] === undefined) {
                            checkedGroupFields.push(true);
                        } else if (Array.isArray(group.customFields[field])) {
                            let customArrayField: any = [];
                            customArrayField = group.customFields[field];

                            checkedGroupFields.push(customArrayField.includes(this.state.selectedDropdownCustomField[field]));
                        } else {
                            checkedGroupFields.push(group.customFields[field] === this.state.selectedDropdownCustomField[field]);
                        }
                    }

                    return new Set(checkedGroupFields).size === 1 && checkedGroupFields[0] === true;

                case 'Workflow':
                    const workflow = this.props.workflowData.byId[entityId];

                    let checkedWorkflowFields: Array<boolean> = [];

                    if (workflow.history[workflow.historyIndex].customFields) {
                        for (let field in this.state.selectedDropdownCustomField) {
                            if (this.state.selectedDropdownCustomField[field] === '-' && workflow.history[workflow.historyIndex].customFields[field] === undefined) {
                                checkedWorkflowFields.push(true);
                            } else if (Array.isArray(workflow.history[workflow.historyIndex].customFields[field])) {
                                let customArrayField: any = [];
                                customArrayField = workflow.history[workflow.historyIndex].customFields[field];

                                checkedWorkflowFields.push(customArrayField.includes(this.state.selectedDropdownCustomField[field]));
                            } else {
                                checkedWorkflowFields.push(workflow.history[workflow.historyIndex].customFields[field] === this.state.selectedDropdownCustomField[field]);
                            }
                        }
                    } else {
                        checkedWorkflowFields.push(false);
                    }

                    return new Set(checkedWorkflowFields).size === 1 && checkedWorkflowFields[0] === true;
                default: return true;
            }
        });
    }

    filterEntitiesBySelectedDateRange = (filteredEntities: Array<string>) => {
        return filteredEntities.filter((entityId) => {
            switch (this.props.type) {
                case 'User':
                    const user = this.props.usersData.byId[entityId];
                    let checkedUserFields: Array<boolean> = [];

                    for (let field in this.state.selectedDateCustomField) {
                        let startDate = moment(this.state.selectedDateCustomField[field].from ? this.state.selectedDateCustomField[field].from : new Date()).format();
                        let endDate = moment(this.state.selectedDateCustomField[field].to ? this.state.selectedDateCustomField[field].to : new Date()).format();

                        if (user.customFields[field] !== undefined && user.customFields[field] !== '' && user.customFields[field] !== null) {
                            let temp: any = user.customFields[field];
                            let currentDate: any = moment(new Date(temp)).format();
                            if (currentDate >= startDate && currentDate <= endDate) {
                                checkedUserFields.push(true);
                            } else {
                                checkedUserFields.push(false);
                            }
                        }
                    }

                    return new Set(checkedUserFields).size === 1 && checkedUserFields[0] === true;
                case 'Member':
                    const member = this.props.membersData.byId[entityId];
                    let checkedMemberFields: Array<boolean> = [];

                    for (let field in this.state.selectedDateCustomField) {
                        let startDate = moment(this.state.selectedDateCustomField[field].from ? this.state.selectedDateCustomField[field].from : new Date()).format();
                        let endDate = moment(this.state.selectedDateCustomField[field].to ? this.state.selectedDateCustomField[field].to : new Date()).format();

                        if (member.customFields[field] !== undefined && member.customFields[field] !== '' && member.customFields[field] !== null) {
                            let temp: any = member.customFields[field];
                            let currentDate: any = moment(new Date(temp)).format();
                            if (currentDate >= startDate && currentDate <= endDate) {
                                checkedMemberFields.push(true);
                            } else {
                                checkedMemberFields.push(false);
                            }
                        }
                    }

                    return new Set(checkedMemberFields).size === 1 && checkedMemberFields[0] === true;
                case 'Group':
                    const group = this.props.groupsData.byId[entityId];
                    let checkedGroupFields = [];

                    for (let field in this.state.selectedDateCustomField) {
                        let startDate = moment(this.state.selectedDateCustomField[field].from ? this.state.selectedDateCustomField[field].from : new Date()).format();
                        let endDate = moment(this.state.selectedDateCustomField[field].to ? this.state.selectedDateCustomField[field].to : new Date()).format();

                        if (group.customFields[field] !== undefined && group.customFields[field] !== '' && group.customFields[field] !== null) {
                            let temp: any = group.customFields[field];
                            let currentDate: any = moment(new Date(temp)).format();
                            if (currentDate >= startDate && currentDate <= endDate) {
                                checkedGroupFields.push(true);
                            } else {
                                checkedGroupFields.push(false);
                            }
                        }
                    }

                    return new Set(checkedGroupFields).size === 1 && checkedGroupFields[0] === true;

                case 'Workflow':
                    const workflow = this.props.workflowData.byId[entityId];
                    let checkedWorkflowFields: Array<boolean> = [];

                    if (workflow.history[workflow.historyIndex].customFields) {
                        for (let field in this.state.selectedDateCustomField) {
                            let startDate = moment(this.state.selectedDateCustomField[field].from ? this.state.selectedDateCustomField[field].from : new Date()).format();
                            let endDate = moment(this.state.selectedDateCustomField[field].to ? this.state.selectedDateCustomField[field].to : new Date()).format();

                            if (workflow.history[workflow.historyIndex].customFields[field] !== undefined && workflow.history[workflow.historyIndex].customFields[field] !== '' && workflow.history[workflow.historyIndex].customFields[field] !== null) {
                                let temp: any = workflow.history[workflow.historyIndex].customFields[field];
                                let currentDate: any = moment(new Date(temp)).format();
                                if (currentDate >= startDate && currentDate <= endDate) {
                                    checkedWorkflowFields.push(true);
                                } else {
                                    checkedWorkflowFields.push(false);
                                }
                            }
                        }
                    } else {
                        checkedWorkflowFields.push(false);
                    }

                    return new Set(checkedWorkflowFields).size === 1 && checkedWorkflowFields[0] === true;
                default: return true;
            }
        });
    }

    filterEntitiesBySystemDateRange = (filteredEntities: Array<string>) => {
        return filteredEntities.filter((entityId) => {
            switch (this.props.type) {
                case 'User':
                    const user = this.props.usersData.byId[entityId];

                    if (user) {
                        let createdStartDate = this.state.selectedSystemDateField['created_date'] ? moment(this.state.selectedSystemDateField['created_date'].from ? this.state.selectedSystemDateField['created_date'].from : new Date()).format() : null;
                        let createdEndDate = this.state.selectedSystemDateField['created_date'] ? moment(this.state.selectedSystemDateField['created_date'].to ? this.state.selectedSystemDateField['created_date'].to : new Date()).format() : null;

                        let updatedStartDate = this.state.selectedSystemDateField['updated_date'] ? moment(this.state.selectedSystemDateField['updated_date'].from ? this.state.selectedSystemDateField['updated_date'].from : new Date()).format() : null;
                        let updatedEndDate = this.state.selectedSystemDateField['updated_date'] ? moment(this.state.selectedSystemDateField['updated_date'].to ? this.state.selectedSystemDateField['updated_date'].to : new Date()).format() : null;

                        let currentCreatedDate = moment(new Date(user.createdTime)).format();
                        let currentUpdatedDate = moment(new Date(user.lastUpdatedTime)).format();

                        if ((createdStartDate && createdEndDate && (currentCreatedDate >= createdStartDate && currentCreatedDate <= createdEndDate)) ||
                            (updatedStartDate && updatedEndDate && (currentUpdatedDate >= updatedStartDate && currentUpdatedDate <= updatedEndDate))) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    return true;

                case 'Member':
                    const member = this.props.membersData.byId[entityId];

                    if (member) {
                        let createdStartDate = this.state.selectedSystemDateField['created_date'] ? moment(this.state.selectedSystemDateField['created_date'].from ? this.state.selectedSystemDateField['created_date'].from : new Date()).format() : null;
                        let createdEndDate = this.state.selectedSystemDateField['created_date'] ? moment(this.state.selectedSystemDateField['created_date'].to ? this.state.selectedSystemDateField['created_date'].to : new Date()).format() : null;

                        let updatedStartDate = this.state.selectedSystemDateField['updated_date'] ? moment(this.state.selectedSystemDateField['updated_date'].from ? this.state.selectedSystemDateField['updated_date'].from : new Date()).format() : null;
                        let updatedEndDate = this.state.selectedSystemDateField['updated_date'] ? moment(this.state.selectedSystemDateField['updated_date'].to ? this.state.selectedSystemDateField['updated_date'].to : new Date()).format() : null;

                        let currentCreatedDate = moment(new Date(member.createdTime)).format();
                        let currentUpdatedDate = moment(new Date(member.lastUpdatedTime)).format();

                        if ((createdStartDate && createdEndDate && (currentCreatedDate >= createdStartDate && currentCreatedDate <= createdEndDate)) ||
                            (updatedStartDate && updatedEndDate && (currentUpdatedDate >= updatedStartDate && currentUpdatedDate <= updatedEndDate))) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    return true;

                case 'Group':
                    const group = this.props.groupsData.byId[entityId];

                    if (group) {
                        let createdStartDate = this.state.selectedSystemDateField['created_date'] ? moment(this.state.selectedSystemDateField['created_date'].from ? this.state.selectedSystemDateField['created_date'].from : new Date()).format() : null;
                        let createdEndDate = this.state.selectedSystemDateField['created_date'] ? moment(this.state.selectedSystemDateField['created_date'].to ? this.state.selectedSystemDateField['created_date'].to : new Date()).format() : null;

                        let updatedStartDate = this.state.selectedSystemDateField['updated_date'] ? moment(this.state.selectedSystemDateField['updated_date'].from ? this.state.selectedSystemDateField['updated_date'].from : new Date()).format() : null;
                        let updatedEndDate = this.state.selectedSystemDateField['updated_date'] ? moment(this.state.selectedSystemDateField['updated_date'].to ? this.state.selectedSystemDateField['updated_date'].to : new Date()).format() : null;

                        let currentCreatedDate = moment(new Date(group.createdTime)).format();
                        let currentUpdatedDate = moment(new Date(group.lastUpdatedTime)).format();

                        if ((createdStartDate && createdEndDate && (currentCreatedDate >= createdStartDate && currentCreatedDate <= createdEndDate)) ||
                            (updatedStartDate && updatedEndDate && (currentUpdatedDate >= updatedStartDate && currentUpdatedDate <= updatedEndDate))) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    return true;

                case 'Workflow':
                    const workflow = this.props.workflowData.byId[entityId];

                    if (workflow) {
                        let createdStartDate = this.state.selectedSystemDateField['created_date'] ? moment(this.state.selectedSystemDateField['created_date'].from ? this.state.selectedSystemDateField['created_date'].from : new Date()).format() : null;
                        let createdEndDate = this.state.selectedSystemDateField['created_date'] ? moment(this.state.selectedSystemDateField['created_date'].to ? this.state.selectedSystemDateField['created_date'].to : new Date()).format() : null;

                        let updatedStartDate = this.state.selectedSystemDateField['updated_date'] ? moment(this.state.selectedSystemDateField['updated_date'].from ? this.state.selectedSystemDateField['updated_date'].from : new Date()).format() : null;
                        let updatedEndDate = this.state.selectedSystemDateField['updated_date'] ? moment(this.state.selectedSystemDateField['updated_date'].to ? this.state.selectedSystemDateField['updated_date'].to : new Date()).format() : null;

                        let dueStartDate = this.state.selectedSystemDateField['due_date'] ? moment(this.state.selectedSystemDateField['due_date'].from ? this.state.selectedSystemDateField['due_date'].from : new Date()).format() : null;
                        let dueEndDate = this.state.selectedSystemDateField['due_date'] ? moment(this.state.selectedSystemDateField['due_date'].to ? this.state.selectedSystemDateField['due_date'].to : new Date()).format() : null;

                        let workedOnStartDate = this.state.selectedSystemDateField['worked_on_date'] ? moment(this.state.selectedSystemDateField['worked_on_date'].from ? this.state.selectedSystemDateField['worked_on_date'].from : new Date()).format() : null;
                        let workedOnEndDate = this.state.selectedSystemDateField['worked_on_date'] ? moment(this.state.selectedSystemDateField['worked_on_date'].to ? this.state.selectedSystemDateField['worked_on_date'].to : new Date()).format() : null;

                        let currentCreatedDate = moment(new Date(workflow.createdTime)).format();
                        let currentUpdatedDate = moment(new Date(workflow.lastUpdatedTime)).format();
                        let currentDueDate = workflow.dueDate ? moment(new Date(workflow.dueDate)).format() : moment().format();


                        const workflowProcessState = workflow.historyIndex >= workflow.history.length ? workflow.history[workflow.history.length - 1] : workflow.history[workflow.historyIndex];
                        let currentWorkedOnDate = moment(new Date(workflow.lastUpdatedTime)).format();
                        if (workflowProcessState) {
                            const executionTime = workflowProcessState.executionTime;
                            currentWorkedOnDate = moment(executionTime).format();
                        }
                        if (
                            (createdStartDate && createdEndDate && (currentCreatedDate >= createdStartDate && currentCreatedDate <= createdEndDate)) ||
                            (updatedStartDate && updatedEndDate && (currentUpdatedDate >= updatedStartDate && currentUpdatedDate <= updatedEndDate)) ||
                            (workedOnStartDate && workedOnEndDate && (currentWorkedOnDate >= workedOnStartDate && currentWorkedOnDate <= workedOnEndDate)) ||
                            (dueStartDate && dueEndDate && (currentDueDate >= dueStartDate && currentDueDate <= dueEndDate))
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    return true;

                default: return true;
            }
        });
    }

    filterOutCachedEntities = (widgetData: WidgetData, filteredEntities: Array<string>) => {
        const filteredWidgetData: WidgetData = {
            headings: widgetData.headings.slice(),
            entries: [],
            chartLabels: widgetData.chartLabels,
            secondChartLabels: widgetData.secondChartLabels,
            chartData: widgetData.chartData,
            groupedChartData: widgetData.groupedChartData,

            csvData: widgetData.csvData.length > 0 ? [widgetData.csvData[0]] : [],

            xAxisFieldName: widgetData.xAxisFieldName,
            yAxisFieldName: widgetData.yAxisFieldName,

            entityIds: [],
        };

        const filteredEntitySet: Set<string> = new Set(filteredEntities);

        for (let i = 0; i < widgetData.entityIds.length; i += 1) {
            const entityId = widgetData.entityIds[i];

            if (filteredEntitySet.has(entityId)) {
                filteredWidgetData.entries.push(widgetData.entries[i]);
                filteredWidgetData.csvData.push(widgetData.csvData[i + 1]);
                filteredWidgetData.entityIds.push(entityId);
            }
        }

        return filteredWidgetData;
    }

    getFilteredEntityIds = () => {
        const entityIds = this.props.cachedWidgetData ? this.props.cachedWidgetData.entityIds : this.props.entityIds;

        const filteredEntities = this.state.selectedLocations.length > 0 ? entityIds.filter(this.filterEntity) : entityIds;

        let dropDownFilteredEntities = filteredEntities;

        if (Object.keys(this.state.selectedDropdownCustomField).length !== 0) {
            dropDownFilteredEntities = this.filterEntitiesBySelectedDropdown(filteredEntities);
        }

        let statusFilteredEntities = dropDownFilteredEntities;

        if (this.props.type === 'Workflow' && this.state.selectedStatuses.length > 0) {
            statusFilteredEntities = this.filterEntitiesByStatus(dropDownFilteredEntities)
        }

        let dateFilteredEntities = statusFilteredEntities;

        if (Object.keys(this.state.selectedDateCustomField).length !== 0) {
            dateFilteredEntities = this.filterEntitiesBySelectedDateRange(statusFilteredEntities);
        }

        let systemDateFilteredEntities = dateFilteredEntities;

        if (Object.keys(this.state.selectedSystemDateField).length !== 0) {
            systemDateFilteredEntities = this.filterEntitiesBySystemDateRange(dateFilteredEntities);
        }

        return systemDateFilteredEntities;
    }

    populateExportData = () => {
        const filteredEntityIds = this.getFilteredEntityIds();

        this.props.setIsPopulatingExport(true);

        setTimeout(() => {
            const exportData = getExportData(
                this.props.applicationState,
                filteredEntityIds,
                this.props.type,
                this.props.customFields,
                this.props.xAxis,
                this.props.yAxis,
                this.props.systemFields,
                this.props.exportingSystemFields,
                this.props.exportingCustomFields,
            );

            this.setState({
                csvData: exportData,
            });

            this.props.setIsPopulatingExport(false);
        }, 1000);

    }

    getSelectedLevelName = () => {
        let selectedLevelName = undefined;
        this.props.applicationState.structure.levels.allEntries.forEach((id) => {
            if (this.state.selectedLevel === id) {
                selectedLevelName = this.props.applicationState.structure.levels.byId[id].name + ' (' + this.props.projectsData.byId[this.props.applicationState.structure.levels.byId[id].project].name + ') '
            }
        });
        return selectedLevelName;
    }

    getFilteredLocations = () => {

        const filteredLocations = this.props.locationsData.allEntries
            .filter(locationId => {
                const location = this.props.locationsData.byId[locationId];

                const locationChain = getAncestorChainOfLocation(location.id).reverse().concat([location.id]);

                const highestLocation = this.props.locationsData.byId[locationChain[0]];
                const projectId = highestLocation.parent ? highestLocation.parent : '';

                const project = this.props.projectsData.byId[projectId];

                if (!project) {
                    return false;
                }

                const levelId = project.children[locationChain.length - 1];

                return this.state.selectedLevel === levelId;
            })
            .map(locationId => {
                const location = this.props.locationsData.byId[locationId];
                let parentName = '';

                if (location.parent) {
                    if (location.parent in this.props.locationsData.byId) {
                        let currentLocation = location;
                        let projectName = '';

                        while (currentLocation.parent && currentLocation.parent in this.props.locationsData.byId) {
                            currentLocation = this.props.locationsData.byId[currentLocation.parent];
                        }

                        if (currentLocation.parent && currentLocation.parent in this.props.projectsData.byId) {
                            projectName = this.props.projectsData.byId[currentLocation.parent].name;
                        }

                        parentName = translatePhrase(this.props.locationsData.byId[location.parent].name) + ' - ' + translatePhrase(projectName);
                    } else if (location.parent in this.props.projectsData.byId) {
                        parentName = translatePhrase(this.props.projectsData.byId[location.parent].name);
                    }
                }
                return {
                    name: `${translatePhrase(location.name)} (${parentName})`,
                    value: locationId,
                };
            });
        return filteredLocations;
    }

    removeLevelTag = () => {
        this.setState({
            selectedLevel: "",
            selectedLocations: [],
        })
    }

    removeLocationTag = (locationId: string) => {
        const newSelectedLocation = this.state.selectedLocations.filter(selectedLocationId => {
            return selectedLocationId !== locationId;
        });
        this.setState({ selectedLocations: newSelectedLocation });
    }


    removeStatusTag = (statusId: string) => {
        const newSelectedStatuses = this.state.selectedStatuses.filter(status => {
            return status !== statusId;
        })

        this.setState({ selectedStatuses: newSelectedStatuses });
    }

    removeCustomFieldTag = (fieldDetailsId: string) => {
        const newSelectedDropdownCustomField = this.state.selectedDropdownCustomField;
        delete newSelectedDropdownCustomField[fieldDetailsId];

        this.setState({ selectedDropdownCustomField: newSelectedDropdownCustomField })
    }

    removeDateCustomFieldTag = (fieldDetailsId: string) => {
        const newSelectedDateCustomField = this.state.selectedDateCustomField;
        delete newSelectedDateCustomField[fieldDetailsId];

        this.setState({ selectedDateCustomField: newSelectedDateCustomField });
    }

    removeSelectDateRangeForSystemField = (type: "due_date" | "created_date" | "updated_date" | "worked_on_date") => {
        const newSelectedSystemDateField = this.state.selectedSystemDateField;
        delete newSelectedSystemDateField[type];
        this.setState({ selectedSystemDateField: newSelectedSystemDateField });
    }

    checkIsFilterApplied = () => {
        if (this.state.selectedLevel) {
            return true;
        }

        if (this.state.selectedLocations.length > 0) {
            return true;
        }

        if (this.state.selectedStatuses.length > 0) {
            return true;
        }

        if (Object.keys(this.state.selectedDropdownCustomField).length > 0) {
            return true;
        }

        if (Object.keys(this.state.selectedDateCustomField).length > 0) {
            return true;
        }

        if (Object.keys(this.state.selectedSystemDateField).length > 0) {
            return true;
        }

        return false;
    }

    toggleFilter = () => {
        this.setState({ showFilter: !this.state.showFilter });
    }

    render() {

        let populatedSelectCustomFields: Array<{
            fieldDetails: CustomField,
            choices: Array<OptionInput>
        }> = [];

        let populatedDateCustomFields: Array<CustomField> = [];

        if (this.props.aggregation !== 'none') {
            console.log('Local aggregation');
        }

        if (this.props.exportingCustomFields && !(this.props.aggregation && this.props.aggregation !== 'none')) {
            this.props.exportingCustomFields.forEach((field) => {
                if (this.props.type === 'Member') {
                    let fieldDetails = this.props.applicationState.members.types.customFields.byId[field];

                    if (fieldDetails.type === 'SINGLE_SELECT' || fieldDetails.type === 'MULTI_SELECT') {
                        let choices = fieldDetails.choices.map(choice => {
                            return {
                                name: this.props.applicationState.members.types.customFieldOptions.byId[choice].name,
                                value: this.props.applicationState.members.types.customFieldOptions.byId[choice].id
                            }
                        });

                        choices.push({
                            name: translatePhrase('Not Applicable') + ' ( - )',
                            value: '-'
                        });

                        populatedSelectCustomFields.push({
                            fieldDetails,
                            choices
                        });
                    }

                    if (fieldDetails.type === 'DATE') {
                        populatedDateCustomFields.push(fieldDetails);
                    }
                }
                if (this.props.type === 'User') {
                    let fieldDetails = this.props.applicationState.users.customFields.byId[field];

                    if (fieldDetails && fieldDetails.type) {
                        if (fieldDetails.type === 'SINGLE_SELECT' || fieldDetails.type === 'MULTI_SELECT') {
                            let choices = fieldDetails.choices.map(choice => {
                                return {
                                    name: this.props.applicationState.users.customFieldOptions.byId[choice].name,
                                    value: this.props.applicationState.users.customFieldOptions.byId[choice].id
                                }
                            });

                            choices.push({
                                name: 'None',
                                value: '-'
                            });

                            populatedSelectCustomFields.push({
                                fieldDetails,
                                choices
                            });
                        }

                        if (fieldDetails.type === 'DATE') {
                            populatedDateCustomFields.push(fieldDetails);
                        }
                    }

                }
                if (this.props.type === 'Group') {
                    let fieldDetails = this.props.applicationState.groups.types.customFields.byId[field];

                    if (fieldDetails.type === 'SINGLE_SELECT' || fieldDetails.type === 'MULTI_SELECT') {
                        let choices = fieldDetails.choices.map(choice => {
                            return {
                                name: this.props.applicationState.groups.types.customFieldOptions.byId[choice].name,
                                value: this.props.applicationState.groups.types.customFieldOptions.byId[choice].id
                            }
                        });

                        choices.push({
                            name: 'None',
                            value: '-'
                        });

                        populatedSelectCustomFields.push({
                            fieldDetails,
                            choices
                        });
                    }

                    if (fieldDetails.type === 'DATE') {
                        populatedDateCustomFields.push(fieldDetails);
                    }
                }

                if (this.props.type === 'Workflow') {
                    let fieldDetails: CustomField = this.props.applicationState.workflows.types.customFields.byId[field];

                    if (!fieldDetails) {
                        if (field in this.props.applicationState.members.types.customFields.byId) {
                            fieldDetails = this.props.applicationState.members.types.customFields.byId[field];
                        }

                        if (field in this.props.applicationState.groups.types.customFields.byId) {
                            fieldDetails = this.props.applicationState.groups.types.customFields.byId[field];
                        }
                    }

                    if (fieldDetails.type === 'SINGLE_SELECT' || fieldDetails.type === 'MULTI_SELECT') {
                        let choices = fieldDetails.choices.map(choice => {
                            return {
                                name: this.props.applicationState.workflows.types.customFieldOptions.byId[choice].name,
                                value: this.props.applicationState.workflows.types.customFieldOptions.byId[choice].id
                            }
                        });

                        choices.push({
                            name: 'None',
                            value: '-'
                        });

                        populatedSelectCustomFields.push({
                            fieldDetails,
                            choices
                        });
                    }

                    if (fieldDetails.type === 'DATE') {
                        populatedDateCustomFields.push(fieldDetails);
                    }
                }
            });
        }

        const entityIds = this.props.cachedWidgetData ? this.props.cachedWidgetData.entityIds : this.props.entityIds;

        let systemDateFilteredEntities = this.getFilteredEntityIds();;

        let showData: WidgetData;

        if (this.props.widgetId) {
            showData = {
                headings: [],
                entries: [],

                chartLabels: [],
                secondChartLabels: [],
                chartData: [],
                groupedChartData: [],

                csvData: [],

                entityIds: [],

                xAxisFieldName: '',
                yAxisFieldName: '',
            };

            if (this.props.cachedWidgetData) {

                if (systemDateFilteredEntities.length !== entityIds.length) {
                    showData = this.filterOutCachedEntities(this.props.cachedWidgetData, systemDateFilteredEntities);
                    const filteredChartCounts = getChartData(
                        this.props.applicationState,
                        systemDateFilteredEntities,
                        this.props.type,
                        this.props.customFields,
                        this.props.xAxis,
                        this.props.yAxis,
                        this.props.yAxisAggregation,
                        this.props.aggregation,
                        this.props.typeId,
                        this.props.systemFields,
                    );

                    showData.chartLabels = filteredChartCounts.chartLabels;
                    showData.secondChartLabels = filteredChartCounts.secondChartLabels;
                    showData.chartData = filteredChartCounts.chartData;
                    showData.groupedChartData = filteredChartCounts.groupedChartData;
                } else {
                    showData = this.props.cachedWidgetData;
                }
            }
        } else {
            showData = getShowData(
                this.props.applicationState,
                systemDateFilteredEntities,
                this.props.type,
                this.props.customFields,
                this.props.xAxis,
                this.props.yAxis,
                this.props.yAxisAggregation,
                this.props.aggregation,
                this.props.typeId,
                this.props.systemFields,
            );
        }

        const chartistData = {
            labels: showData.chartLabels,
            series: [showData.chartData],
        };

        if (showData.groupedChartData && showData.groupedChartData.length > 0 && this.props.aggregation === 'none') {
            chartistData.series = showData.groupedChartData;
        }

        const seriesData = [];

        for (let i = 0; i < showData.chartLabels.length; i += 1) {
            const label = showData.chartLabels[i];
            const value = showData.chartData[i];

            seriesData.push({
                name: label,
                value,
            })
        }

        const donutData = {
            labels: showData.chartLabels,
            series: seriesData,
        }

        const maxDonutValue = Math.max.apply(Math, donutData.series.map(function (o) { return o.value; }));
        const maxChartValue = Math.max.apply(Math, chartistData.series[0].map(function (o) { return o; }));

        const pathColors: Array<string> = ['#00549a', '#007199', '#00879a', '#009996',
            '#009a84', '#00996d', '#009a53', '#00993e',
            '#009927', '#00990d', '#019700', '#01e990',
            '#369900', '#4b9a01', '#6a9801', '#cacc00',
            '#949900', '#998e01', '#9ac700', '#995600',
            '#994900', '#983700'];

        const groupedBarColors: Array<string> = [
            '#42ace5', '#b4f793', '#9647e1',
            '#f5d47d', '#ffc3ff', '#626262',
            '#00533a', '#a82ed8', '#e26a45',
            '#8b002e',
        ];

        const donutChartOptions: IPieChartOptions = {
            donut: true,
            donutWidth: 40,
            height: 280,
            startAngle: 240,
            showLabel: false,
            plugins: [
                Chartist.plugins.tooltip({
                    appendToBody: true,
                    seriesName: true,
                })
            ],

        };

        let chartCellWidth = showData.chartLabels.length * 150;

        if (chartistData.series.length > 1) {
            const extraLength = chartistData.series.length * 15;
            chartCellWidth = showData.chartLabels.length * (150 + extraLength);
        }

        const lineChartOptions: ILineChartOptions = {
            height: 280,
            width: `${chartCellWidth}px`,
            low: 0,
            high: !isNaN(maxChartValue) ? maxChartValue + 20 : undefined,
            plugins: [
                Chartist.plugins.tooltip(),
            ],
            axisX: {
                showGrid: false,
            },
        };

        const barChartOptions: IBarChartOptions = {
            height: 280,
            width: `${chartCellWidth}px`,
            low: 0,
            high: !isNaN(maxChartValue) ? maxChartValue + 20 : undefined,
            plugins: [
                Chartist.plugins.tooltip(),
            ],
            axisX: {
                showGrid: false,
            },

        };

        if (this.props.xAxis && this.props.yAxis) {
            barChartOptions.chartPadding = {
                bottom: 30,
                left: 20,
            };

            barChartOptions.plugins = [
                Chartist.plugins.tooltip(),
                Chartist.plugins.ctAxisTitle({
                    axisX: {
                        axisTitle: showData.xAxisFieldName,
                        axisClass: 'ct-axis-title',
                        offset: {
                            x: 0,
                            y: 50
                        },
                        textAnchor: 'middle'
                    },
                    axisY: {
                        axisTitle: showData.yAxisFieldName,
                        axisClass: 'ct-axis-title',
                        offset: {
                            x: 0,
                            y: 0
                        },
                        textAnchor: 'middle',
                        flipTitle: false
                    }
                }),
            ];


            lineChartOptions.chartPadding = {
                bottom: 30,
                left: 20,
            };

            lineChartOptions.plugins = [
                Chartist.plugins.tooltip(),
                Chartist.plugins.ctAxisTitle({
                    axisX: {
                        axisTitle: showData.xAxisFieldName,
                        axisClass: 'ct-axis-title',
                        offset: {
                            x: 0,
                            y: 50
                        },
                        textAnchor: 'middle'
                    },
                    axisY: {
                        axisTitle: showData.yAxisFieldName,
                        axisClass: 'ct-axis-title',
                        offset: {
                            x: 0,
                            y: 0
                        },
                        textAnchor: 'middle',
                        flipTitle: false
                    }
                }),
            ];
        } else if (this.props.xAxis) {
            barChartOptions.chartPadding = {
                bottom: 30,
            };

            barChartOptions.plugins = [
                Chartist.plugins.tooltip(),
                Chartist.plugins.ctAxisTitle({
                    axisX: {
                        axisTitle: showData.xAxisFieldName,
                        axisClass: 'ct-axis-title',
                        offset: {
                            x: 0,
                            y: 50
                        },
                        textAnchor: 'middle'
                    },
                }),
            ];


            lineChartOptions.chartPadding = {
                bottom: 30,
            };

            lineChartOptions.plugins = [
                Chartist.plugins.tooltip(),
                Chartist.plugins.ctAxisTitle({
                    axisX: {
                        axisTitle: showData.xAxisFieldName,
                        axisClass: 'ct-axis-title',
                        offset: {
                            x: 0,
                            y: 50
                        },
                        textAnchor: 'middle'
                    },
                }),
            ];
        }

        let fields = this.props.systemFields ? this.props.systemFields : [];
        fields = fields.concat(this.props.customFields);

        const isGlobalAggregated = this.props.aggregation && this.props.aggregation !== 'none' && fields.length === 1;
        let aggregationValue: number = 0;

        if (isGlobalAggregated) {
            const dataToAggregate = showData.entries.map(tableRow => tableRow.entries[2]);
            let numericValues: Array<number>;

            switch (this.props.aggregation) {
                case 'count':
                    aggregationValue = dataToAggregate.length;
                    break;
                case 'sum':
                    numericValues = dataToAggregate.map(value => Number(value));
                    aggregationValue = numericValues.reduce((sum, num) => {
                        if (!isNaN(num)) {
                            return sum + num;
                        } else {
                            return sum;
                        }
                    });
                    break;
                case 'average':
                    numericValues = dataToAggregate.map(value => Number(value));
                    aggregationValue = numericValues.reduce((sum, num) => {
                        if (!isNaN(num)) {
                            return sum + num;
                        } else {
                            return sum;
                        }
                    });
                    if (numericValues.length > 0) {
                        aggregationValue /= numericValues.length;
                    } else {
                        aggregationValue = 0;
                    }
                    break;

                default:
                    throw new Error('Incorrect aggregation');
            }
        }

        const canShowShareLink = this.props.widgetId && !isUUID(this.props.myId);

        let pages: Array<JSX.Element> = [], totalPages = Math.ceil(showData.entries.length / 5);
        let showingTableRows: Array<TableRow> = [];
        let tableEntriesCount: number = showData.entries.length;

        if (this.state.dataType === 'table') {

            let tableEntries: Array<TableRow> = [];

            if (this.state.tableSearchTerm) {
                tableEntries = showData.entries.filter(tableRow => {
                    return !this.state.tableSearchTerm || tableRow.entries.find(entry => String(entry).toLocaleLowerCase().includes(this.state.tableSearchTerm.toLocaleLowerCase()));
                });
            } else {
                tableEntries = showData.entries;
            }

            totalPages = Math.ceil(tableEntries.length / 5);
            let startIndexForPages: number, endIndexForPages: number;

            if (totalPages < 5) {
                startIndexForPages = 1;
                endIndexForPages = totalPages;
            } else if (this.state.pageNumber < 5) {
                startIndexForPages = 1;
                endIndexForPages = 5
            } else if (this.state.pageNumber > (totalPages - 5)) {
                startIndexForPages = totalPages - 4;
                endIndexForPages = totalPages;
            } else {
                startIndexForPages = this.state.pageNumber - 2;
                endIndexForPages = this.state.pageNumber + 2;
            }

            for (let i = startIndexForPages; i <= endIndexForPages; i += 1) {
                pages.push(<section key={i} className={this.state.pageNumber === i ? styles.activePageLink : styles.pageLink} onClick={e => this.goToPage(i)} title={i ? i.toString() : ''}>{i}</section>);
            }

            tableEntriesCount = tableEntries.length;

            const startIndex = (this.state.pageNumber - 1) * 5;
            const endIndex = startIndex + 5 > tableEntries.length ? tableEntries.length : startIndex + 5;
            showingTableRows = tableEntries.slice(startIndex, endIndex);
        }

        const header = <header className={styles.showHeader}>

            <h5 className={styles.showHeading + ' ' + (this.state.showMoreOptions ? styles.hide : '')}>
                {this.getTypeIcon(this.props.type)}
                <div className={styles.heading}>
                    <div title={translatePhrase(this.props.header ? this.props.header : 'None')}> {!isGlobalAggregated && translatePhrase(this.props.header ? this.props.header : '')} ({entityIds.length}) </div>
                    <span> {translatePhrase(this.props.type)} </span>
                </div>
            </h5>

            <div className={styles.actionButtonsContainer + ' ' + (this.state.showMoreOptions ? styles.hide : '')}>
                {this.state.dataType === 'table' && !this.props.textToShow &&
                    <Button
                        isHighlighted={this.props.selectedNudge === NudgeType.DASHBOARD_SEARCH_WIDGET}
                        title={!this.state.showSearchBar ? translatePhrase('Search') : translatePhrase('Close')}
                        icon={!this.state.showSearchBar ? <SearchIcon /> : <CloseIcon />}
                        onClick={() => this.setState({ showSearchBar: !this.state.showSearchBar, tableSearchTerm: '' })}
                        isRounded={true} type={this.state.showSearchBar ? 'primary' : 'secondary'}
                        size={'small'} />}

                {!isGlobalAggregated && !this.props.textToShow &&
                    <Button
                        isHighlighted={this.props.selectedNudge === NudgeType.DASHBOARD_FILTER_WIDGET}
                        title={!this.state.showFilter ? translatePhrase('Filter') : translatePhrase('Close')}
                        icon={!this.state.showFilter ? <FilterIcon /> : <CloseIcon />}
                        onClick={() => this.toggleFilter()}
                        isRounded={true} type={this.state.showFilter ? 'primary' : 'secondary'}
                        size={'small'} />}

                <Button
                    isHighlighted={this.props.selectedNudge === NudgeType.DASHBOARD_SHOW_MORE_WIDGET_OPTIONS}
                    title={translatePhrase('More')}
                    icon={<MoreIcon />}
                    onClick={() => this.setState({ showMoreOptions: true })}
                    isRounded={true}
                    type={'secondary'}
                    size={'small'} />
            </div>


            <div className={styles.moreOptionsHolder + ' ' + (this.state.showMoreOptions ? styles.active : '')}>
                <ul className={styles.moreOptions}>
                    {this.props.optionsMarkup}
                    {canShowShareLink && <Button isRounded type={"secondary"} size={"small"} icon={<ShareIcon />}
                        title={translatePhrase('Share')} onClick={this.getShareLink} />}
                    {this.state.csvData.length === 0 && <Button
                        isRounded type={"secondary"} size={"small"} icon={<ExportIcon />}
                        onClick={this.populateExportData} title={translatePhrase('Prepare Export')} />}
                    {this.state.csvData.length > 0 && <li title={translatePhrase('Export')}>
                        <CSVLink className={styles.link} data={this.state.csvData} filename={`Data export.csv`}>
                            <Button isRounded type={"secondary"} size={"small"} icon={<ExportIcon />} />
                        </CSVLink>
                    </li>}
                    {this.props.updateWidgetCache && <Button title={translatePhrase('Refresh')}
                        isRounded type={"secondary"} size={"small"} icon={<RefreshIcon />}
                        onClick={this.props.updateWidgetCache} />}
                    <Button title={translatePhrase('Close')}
                        isRounded type={"primary"} size={"small"} icon={<CloseIcon />}
                        onClick={() => { this.setState({ showMoreOptions: false }) }} />
                </ul>
            </div>

        </header>;

        const canShowLine = (!!this.props.xAxis || this.props.customFields.concat(this.props.systemFields ? this.props.systemFields : []).length < 2) && !isGlobalAggregated && !this.state.showSearchBar;
        const canShowBar = (!!this.props.xAxis || this.props.customFields.concat(this.props.systemFields ? this.props.systemFields : []).length < 3) && !isGlobalAggregated && !this.state.showSearchBar;

        const tabSegments = <section className={styles.widgetHeaderSegment}>
            {canShowBar && <div className={this.props.selectedNudge === NudgeType.DASHBOARD_SWITCH_WIDGET_VIEW ? styles.highlightedDataFormats : styles.dataFormats}>
                {canShowLine && <section title={translatePhrase('Line Chart')} className={styles.format + ' ' + (this.state.dataType === 'line' ? styles.activeFormat : '')} onClick={this.changeDisplay.bind(this, 'line')}>
                    <LineChartIcon />
                    <span> Line </span>
                </section>}
                <section title={translatePhrase('Bar Chart')} className={styles.format + ' ' + (this.state.dataType === 'bar' ? styles.activeFormat : '')} onClick={this.changeDisplay.bind(this, 'bar')}>
                    <BarChartIcon />
                    <span> Bar </span>
                </section>
                {!this.props.yAxis && canShowLine && <section title={translatePhrase('Donut Chart')} className={styles.format + ' ' + (this.state.dataType === 'donut' ? styles.activeFormat : '')} onClick={this.changeDisplay.bind(this, 'donut')}>
                    <DonutChartIcon />
                    <span> Donut </span>
                </section>}
                <section title={translatePhrase('Table')} className={styles.format + ' ' + (this.state.dataType === 'table' ? styles.activeFormat : '')} onClick={this.changeDisplay.bind(this, 'table')}>
                    <TableIcon />
                    <span> Table </span>
                </section>
            </div>}

            {this.state.dataType === 'table' && this.state.showSearchBar && <div className={styles.searchBar}>
                <InputText type="search" placeholder={translatePhrase('Search within the list below')} onChange={this.setTableSearchTerm} />
            </div>}

        </section>;

        const filterLevels = this.props.applicationState.structure.levels.allEntries.map((id) => {
            return {
                id: id,
                name: this.props.applicationState.structure.levels.byId[id].name + ' (' + this.props.projectsData.byId[this.props.applicationState.structure.levels.byId[id].project].name + ') ',
                value: this.props.applicationState.structure.levels.byId[id].id
            }
        });

        let filterStatuses: Array<Option> = [];

        const filterLocations = this.getFilteredLocations();

        if (this.props.type === 'Workflow' && this.props.typeId) {
            const workflowType = this.props.workflowData.types.byId[this.props.typeId];

            filterStatuses = workflowType.statuses.map(statusId => {
                const status = this.props.workflowData.types.statuses.byId[statusId];

                return {
                    name: status.name,
                    value: statusId,
                };
            });
        }

        const isFiltetTags = this.checkIsFilterApplied();

        return <section className={styles.showDataContainer + ' ' + (isGlobalAggregated ? styles.restrictedWidth : '')}>
            {header}

            {isFiltetTags && !this.state.showFilter &&
                <section className={styles.tagsHolder}>
                    {
                        this.state.selectedLevel &&
                        <div className={styles.tag}>
                            <span>
                                {this.props.applicationState.structure.levels.byId[this.state.selectedLevel].name}
                                ({this.props.projectsData.byId[this.props.applicationState.structure.levels.byId[this.state.selectedLevel].project].name})
                            </span>
                            <Button
                                title={translatePhrase('Close')}
                                onClick={() => this.removeLevelTag()}
                                icon={<CloseIcon />}
                                size={'small'}
                                isRounded />
                        </div>
                    }
                    {this.state.selectedLocations.length > 0 && this.state.selectedLevel &&
                        this.state.selectedLocations.map((location, index) => {
                            return (
                                <div className={styles.tag} key={index}>
                                    <span>
                                        {this.props.locationsData.byId[location].name}
                                    </span>
                                    <Button
                                        title={translatePhrase('Close')}
                                        isRounded
                                        size={'small'}
                                        icon={<CloseIcon />}
                                        onClick={() => this.removeLocationTag(location)} />
                                </div>
                            )

                        })
                    }
                    {this.state.selectedStatuses &&
                        this.state.selectedStatuses.map((statusId, index) => {
                            return (
                                <div className={styles.tag} key={index}>
                                    <span>
                                        {this.props.workflowData.types.statuses.byId[statusId].name}
                                    </span>
                                    <Button
                                        title={translatePhrase('Close')}
                                        isRounded
                                        size={'small'}
                                        icon={<CloseIcon />}
                                        onClick={() => this.removeStatusTag(statusId)} />
                                </div>
                            )

                        })
                    }
                    {
                        Object.keys(this.state.selectedDropdownCustomField).length > 0 &&
                        populatedSelectCustomFields.map((field) => {
                            if (this.state.selectedDropdownCustomField[field.fieldDetails.id]) {
                                const choiceName = field.choices.find((choice) => {
                                    return choice.value === this.state.selectedDropdownCustomField[field.fieldDetails.id];
                                })?.name;

                                return (
                                    <div className={styles.tag}>
                                        <span>
                                            {choiceName}
                                        </span>
                                        <Button
                                            title={translatePhrase('Close')}
                                            isRounded
                                            size={'small'}
                                            icon={<CloseIcon />}
                                            onClick={() => this.removeCustomFieldTag(field.fieldDetails.id)} />
                                    </div>
                                );
                            } else {
                                return undefined;
                            }
                        })
                    }
                    {
                        Object.keys(this.state.selectedDateCustomField).length > 0 &&
                        populatedDateCustomFields.map((field, index) => {
                            let start = '',
                                end = '';

                            if (this.state.selectedDateCustomField[field.id] && this.state.selectedDateCustomField[field.id].from) {
                                start = moment(this.state.selectedDateCustomField[field.id].from).format('DD MMM YYYY');
                            }

                            if (this.state.selectedDateCustomField[field.id] && this.state.selectedDateCustomField[field.id].to) {
                                end = moment(this.state.selectedDateCustomField[field.id].to).format('DD MMM YYYY');
                            }


                            if (field.name && (start || end)) {
                                return <div className={styles.tag} key={index}>
                                    <span>{field.name + ', '}</span>
                                    <strong> {(start ? start : translatePhrase('Today'))} </strong>
                                    <span>{' ' + translatePhrase('to') + ' '}</span>
                                    <strong> {(end ? end : translatePhrase('Today'))} </strong>
                                    <Button title={translatePhrase('Close')} isRounded size={'small'} icon={<CloseIcon />} onClick={() => this.removeDateCustomFieldTag(field.id)} />
                                </div>
                            } else {
                                return undefined;
                            }
                        })
                    }
                    {
                        Object.keys(this.state.selectedSystemDateField).length > 0 &&
                        this.state.selectedSystemDateField['created_date'] &&
                        <div className={styles.tag}>
                            <span>
                                {translatePhrase('Created')},
                                <strong> {' ' + this.state.selectedSystemDateField['created_date'].from ? moment(this.state.selectedSystemDateField['created_date'].from).format('DD MMM YYYY') : translatePhrase('Today')} </strong>
                                {' ' + translatePhrase('to') + ' '}
                                <strong> {this.state.selectedSystemDateField['created_date'].to ? moment(this.state.selectedSystemDateField['created_date'].to).format('DD MMM YYYY') : translatePhrase('Today')} </strong>
                            </span>
                            <Button
                                title={translatePhrase('Close')}
                                isRounded
                                size={'small'}
                                icon={<CloseIcon />}
                                onClick={() => this.removeSelectDateRangeForSystemField("created_date")} />
                        </div>
                    }

                    {
                        Object.keys(this.state.selectedSystemDateField).length > 0 &&
                        this.state.selectedSystemDateField['updated_date'] &&
                        <div className={styles.tag}>
                            <span>
                                {translatePhrase('Updated')},
                                <strong> {' ' + this.state.selectedSystemDateField['updated_date'].from ? moment(this.state.selectedSystemDateField['updated_date'].from).format('DD MMM YYYY') : translatePhrase('Today')} </strong>
                                {' ' + translatePhrase('to') + ' '}
                                <strong> {this.state.selectedSystemDateField['updated_date'].to ? moment(this.state.selectedSystemDateField['updated_date'].to).format('DD MMM YYYY') : translatePhrase('Today')} </strong>
                            </span>
                            <Button
                                title={translatePhrase('Close')}
                                isRounded
                                size={'small'}
                                icon={<CloseIcon />}
                                onClick={() => this.removeSelectDateRangeForSystemField("updated_date")} />
                        </div>
                    }

                    {
                        Object.keys(this.state.selectedSystemDateField).length > 0 &&
                        this.state.selectedSystemDateField['due_date'] &&
                        <div className={styles.tag}>
                            <span>
                                {translatePhrase('Due')},
                                <strong> {' ' + this.state.selectedSystemDateField['due_date'].from ? moment(this.state.selectedSystemDateField['due_date'].from).format('DD MMM YYYY') : translatePhrase('Today')} </strong>
                                {' ' + translatePhrase('to') + ' '}
                                <strong> {this.state.selectedSystemDateField['due_date'].to ? moment(this.state.selectedSystemDateField['due_date'].to).format('DD MMM YYYY') : translatePhrase('Today')} </strong>
                            </span>
                            <Button
                                title={translatePhrase('Close')}
                                isRounded
                                size={'small'}
                                icon={<CloseIcon />}
                                onClick={() => this.removeSelectDateRangeForSystemField("due_date")} />
                        </div>
                    }
                    {
                        Object.keys(this.state.selectedSystemDateField).length > 0 &&
                        this.state.selectedSystemDateField['worked_on_date'] &&
                        <div className={styles.tag}>
                            <span>
                                {translatePhrase('Worked on')},
                                <strong> {' ' + this.state.selectedSystemDateField['worked_on_date'].from ? moment(this.state.selectedSystemDateField['worked_on_date'].from).format('DD MMM YYYY') : translatePhrase('Today')} </strong>
                                {' ' + translatePhrase('to') + ' '}
                                <strong> {this.state.selectedSystemDateField['worked_on_date'].to ? moment(this.state.selectedSystemDateField['worked_on_date'].to).format('DD MMM YYYY') : translatePhrase('Today')} </strong>
                            </span>
                            <Button
                                title={translatePhrase('Close')}
                                isRounded
                                size={'small'}
                                icon={<CloseIcon />}
                                onClick={() => this.removeSelectDateRangeForSystemField("worked_on_date")} />
                        </div>
                    }
                    {
                        <div className={styles.tag + " " + styles.countTag}>
                            <span>
                                {`Count: ${tableEntriesCount}`}
                            </span>
                        </div>
                    }
                </section>
            }

            {tabSegments}
            {this.state.showFilter &&
                <div className={styles.filterAndTagsHolder}>

                    {<div className={styles.filterHolder}>
                        <div className={styles.inputSegmentContainer}>
                            <div className={styles.inputSegment}>
                                <InputText
                                    placeholder={translatePhrase('Levels')}
                                    onChange={this.selectLevel}
                                    options={filterLevels}
                                    default={this.getSelectedLevelName()}
                                    isAutoFocus={true}
                                    key={this.state.selectedLevel} />
                            </div>

                            <div className={styles.inputSegment}>
                                <MultiSelectInputText
                                    options={filterLocations}
                                    onChange={(e) => this.changeLocationFieldOptions(e)}
                                    placeholder={translatePhrase('Locations')}
                                    isAutoFocus={true}
                                    default={this.state.selectedLocations}
                                    key={this.state.selectedLocations.length}
                                />
                            </div>

                            {filterStatuses.length > 0 && <div className={styles.inputSegment}>
                                <MultiSelectInputText
                                    options={filterStatuses}
                                    onChange={(e) => this.changeStatusFieldOptions(e)}
                                    placeholder={translatePhrase('Statuses')}
                                    isAutoFocus={true}
                                    default={this.state.selectedStatuses}
                                    key={this.state.selectedStatuses.length}
                                />
                            </div>}

                            {populatedSelectCustomFields.map((field) => {

                                const selectedChoice = this.state.selectedDropdownCustomField[field.fieldDetails.id];
                                const defaultChoiceName = field.choices.find(choice => selectedChoice === choice.value);

                                return <div className={styles.inputSegment} key={field.fieldDetails.id}>
                                    <InputText
                                        placeholder={field.fieldDetails.name}
                                        onChange={(value) => this.selectDropdownCustomField(field.fieldDetails.id, value)}
                                        default={defaultChoiceName?.name}
                                        options={field.choices}
                                        isAutoFocus={true}
                                        key={Object.keys(this.state.selectedDropdownCustomField).length} />
                                </div>
                            })}

                            {populatedDateCustomFields.map((field) => {
                                return <section className={styles.dateInputHolder} key={field.id} title={translatePhrase(field.name) + ' (' + translatePhrase('From') + ')'}>
                                    <div className={styles.inputSegment}>
                                        <DateInput
                                            placeholder={(translatePhrase(field.name) + ' (' + translatePhrase('From') + ')')}
                                            default={this.state.selectedDateCustomField[field.id] ? this.state.selectedDateCustomField[field.id].from ? new Date(this.state.selectedDateCustomField[field.id].from) : undefined : undefined}
                                            onChange={value => this.selectDateRangeForCustomField(field.id, value ? value.toString() : '', this.state.selectedDateCustomField[field.id] ? this.state.selectedDateCustomField[field.id].to : '')}
                                            key={this.state.selectedDateCustomField?.[field.id]?.from} />
                                    </div>
                                    <div className={styles.inputSegment} title={translatePhrase(field.name) + ' (' + translatePhrase('To') + ')'}>
                                        <DateInput
                                            placeholder={(translatePhrase(field.name) + ' (' + translatePhrase('To') + ')')}
                                            default={this.state.selectedDateCustomField[field.id] ? this.state.selectedDateCustomField[field.id].to ? new Date(this.state.selectedDateCustomField[field.id].to) : undefined : undefined}
                                            onChange={value => this.selectDateRangeForCustomField(field.id, this.state.selectedDateCustomField[field.id] ? this.state.selectedDateCustomField[field.id].from : '', value ? value.toString() : '')}
                                            key={this.state.selectedDateCustomField?.[field.id]?.to} />
                                    </div>
                                </section>
                            })

                            }

                            {this.props.type === 'Workflow' && <section className={styles.dateInputHolder}>
                                <div className={styles.inputSegment} title={translatePhrase('Due Date') + ' (' + translatePhrase('From') + ')'}>
                                    <DateInput
                                        placeholder={(translatePhrase('Due Date') + ' (' + translatePhrase('From') + ')')}
                                        default={this.state.selectedSystemDateField['due_date'] ? this.state.selectedSystemDateField['due_date'].from ? new Date(this.state.selectedSystemDateField['due_date'].from) : undefined : undefined}
                                        onChange={value => this.selectDateRangeForSystemField('due_date', value ? value.toString() : '', this.state.selectedSystemDateField['due_date'] ? this.state.selectedSystemDateField['due_date'].to : '')}
                                        key={this.state.selectedSystemDateField.hasOwnProperty("due_date") ? 1 : 0} />
                                </div>
                                <div className={styles.inputSegment} title={translatePhrase('Due Date') + ' (' + translatePhrase('To') + ')'}>
                                    <DateInput
                                        placeholder={(translatePhrase('Due Date') + ' (' + translatePhrase('To') + ')')}
                                        default={this.state.selectedSystemDateField['due_date'] ? this.state.selectedSystemDateField['due_date'].to ? new Date(this.state.selectedSystemDateField['due_date'].to) : undefined : undefined}
                                        onChange={value => this.selectDateRangeForSystemField('due_date', this.state.selectedSystemDateField['due_date'] ? this.state.selectedSystemDateField['due_date'].from : '', value ? value.toString() : '')}
                                        key={this.state.selectedSystemDateField.hasOwnProperty("due_date") ? 1 : 0} />
                                </div>
                            </section>}

                            {this.props.type !== 'User' && <section className={styles.dateInputHolder}>
                                <div className={styles.inputSegment} title={translatePhrase('Created Date') + ' (' + translatePhrase('From') + ')'}>
                                    <DateInput
                                        placeholder={(translatePhrase('Created Date') + ' (' + translatePhrase('From') + ')')}
                                        default={this.state.selectedSystemDateField['created_date'] ? this.state.selectedSystemDateField['created_date'].from ? new Date(this.state.selectedSystemDateField['created_date'].from) : undefined : undefined}
                                        onChange={value => this.selectDateRangeForSystemField('created_date', value ? value.toString() : '', this.state.selectedSystemDateField['created_date'] ? this.state.selectedSystemDateField['created_date'].to : '')}
                                        key={this.state.selectedSystemDateField.hasOwnProperty("created_date") ? 1 : 0} />
                                </div>
                                <div className={styles.inputSegment} title={translatePhrase('Created Date') + ' (' + translatePhrase('To') + ')'}>
                                    <DateInput
                                        placeholder={(translatePhrase('Created Date') + ' (' + translatePhrase('To') + ')')}
                                        default={this.state.selectedSystemDateField['created_date'] ? this.state.selectedSystemDateField['created_date'].to ? new Date(this.state.selectedSystemDateField['created_date'].to) : undefined : undefined}
                                        onChange={value => this.selectDateRangeForSystemField('created_date', this.state.selectedSystemDateField['created_date'] ? this.state.selectedSystemDateField['created_date'].from : '', value ? value.toString() : '')}
                                        key={this.state.selectedSystemDateField.hasOwnProperty("created_date") ? 1 : 0} />
                                </div>
                            </section>}

                            {this.props.type !== 'User' && <section className={styles.dateInputHolder}>
                                <div className={styles.inputSegment} title={translatePhrase('Last Updated') + ' (' + translatePhrase('From') + ')'}>
                                    <DateInput
                                        placeholder={(translatePhrase('Last Updated') + ' (' + translatePhrase('From') + ')')}
                                        default={this.state.selectedSystemDateField['updated_date'] ? this.state.selectedSystemDateField['updated_date'].from ? new Date(this.state.selectedSystemDateField['updated_date'].from) : undefined : undefined}
                                        onChange={value => this.selectDateRangeForSystemField('updated_date', value ? value.toString() : '', this.state.selectedSystemDateField['updated_date'] ? this.state.selectedSystemDateField['updated_date'].to : '')}
                                        key={this.state.selectedSystemDateField.hasOwnProperty("updated_date") ? 1 : 0} />
                                </div>
                                <div className={styles.inputSegment} title={translatePhrase('Last Updated') + ' (' + translatePhrase('To') + ')'}>
                                    <DateInput
                                        placeholder={(translatePhrase('Last Updated') + ' (' + translatePhrase('To') + ')')}
                                        default={this.state.selectedSystemDateField['updated_date'] ? this.state.selectedSystemDateField['updated_date'].to ? new Date(this.state.selectedSystemDateField['updated_date'].to) : undefined : undefined}
                                        onChange={value => this.selectDateRangeForSystemField('updated_date', this.state.selectedSystemDateField['updated_date'] ? this.state.selectedSystemDateField['updated_date'].from : '', value ? value.toString() : '')}
                                        key={this.state.selectedSystemDateField.hasOwnProperty("updated_date") ? 1 : 0} />
                                </div>
                            </section>}


                            {this.props.type === 'Workflow' && <section className={styles.dateInputHolder}>
                                <div className={styles.inputSegment} title={translatePhrase('Last Worked On') + ' (' + translatePhrase('From') + ')'}>
                                    <DateInput placeholder={(translatePhrase('Last Worked On') + ' (' + translatePhrase('From') + ')')}
                                        default={this.state.selectedSystemDateField['worked_on_date'] ? this.state.selectedSystemDateField['worked_on_date'].from ? new Date(this.state.selectedSystemDateField['worked_on_date'].from) : undefined : undefined}
                                        onChange={value => this.selectDateRangeForSystemField('worked_on_date', value ? value.toString() : '', this.state.selectedSystemDateField['worked_on_date'] ? this.state.selectedSystemDateField['worked_on_date'].to : '')} />
                                </div>
                                <div className={styles.inputSegment} title={translatePhrase('Last Worked On') + ' (' + translatePhrase('To') + ')'}>
                                    <DateInput placeholder={(translatePhrase('Last Worked On') + ' (' + translatePhrase('To') + ')')}
                                        default={this.state.selectedSystemDateField['worked_on_date'] ? this.state.selectedSystemDateField['worked_on_date'].to ? new Date(this.state.selectedSystemDateField['worked_on_date'].to) : undefined : undefined}
                                        onChange={value => this.selectDateRangeForSystemField('worked_on_date', this.state.selectedSystemDateField['worked_on_date'] ? this.state.selectedSystemDateField['worked_on_date'].from : '', value ? value.toString() : '')} />
                                </div>
                            </section>}
                        </div>
                    </div>}
                </div>
            }


            <div className={styles.dataContainer + " " + (this.state.showFilter ? styles.blur : "")}>
                {isGlobalAggregated && <div className={styles.numberHolder}>
                    <span className={styles.icon}> {this.getTypeIcon(this.props.type)} </span>
                    <div className={styles.content}>
                        <p> {aggregationValue} </p>
                        <h4 className={styles.aggregationHeader} title={this.props.header ? translatePhrase(this.props.header) : ''}> {this.props.header ? translatePhrase(this.props.header) : ''} </h4>
                    </div>
                </div>}
                {!isGlobalAggregated && this.state.dataType !== 'message' && showData.chartData.length === 0 && showData.entries.length === 0 && <div className={styles.noDataContainer}>{translatePhrase('No data available')}</div>}
                {!isGlobalAggregated && this.state.dataType !== 'message' && (showData.chartData.length > 0 || showData.entries.length > 0) && <div className={styles.dataContent}>
                    {this.state.dataType === 'table' && <section className={styles.normalTable}>
                        <section className={styles.tableHolder}>
                            <Table
                                headings={showData.headings}
                                entries={showingTableRows}
                                isReadOnly
                                areActionsHidden
                            />

                            <div className={styles.pageActions}>
                                {pages.length > 1 && <div className={styles.pageSize}>
                                    <input type="text" onBlur={(event) => this.goToPage(Number(event.currentTarget.value))} key={this.state.pageNumber} defaultValue={this.state.pageNumber} />
                                    <div> {translatePhrase('of')} {totalPages} </div>
                                    {this.state.pageNumber > 1 && <button className={styles.prevPage} onClick={() => this.goToPage(this.state.pageNumber - 1)}> <RightArrow /> </button>}
                                    {this.state.pageNumber <= totalPages && <button className={styles.nextPage} onClick={() => this.goToPage(this.state.pageNumber + 1)}> <RightArrow /> </button>}
                                </div>}
                            </div>
                        </section>
                    </section>}
                    {this.state.dataType === 'bar' && <ScrollContainer hideScrollbars={false} className={styles.chartContainer + ' ' + styles.draggable + ' ' + (showData.chartLabels.length > 3 && showData.chartData.length > 3 ? styles.showShadow : '')}>
                        <ChartistGraph className={chartistData.series.length > 1 ? 'grouped-bar' : 'normal-bar'} data={chartistData} options={barChartOptions} type="Bar" />
                    </ScrollContainer>}

                    {this.state.dataType === 'bar' && chartistData.series.length > 1 && <ul className={styles.chartLegendsForBar}>
                        {showData.secondChartLabels?.map((label, index) => {
                            return <li>
                                <div className={styles.indicator} style={{ backgroundColor: groupedBarColors[index % groupedBarColors.length] }}></div>
                                <label title={label}>
                                    {label}
                                </label>
                            </li>
                        })}
                    </ul>}

                    {this.state.dataType === 'line' && <ScrollContainer hideScrollbars={false} className={styles.chartContainer + ' ' + styles.draggable + ' ' + (showData.chartLabels.length > 3 && showData.chartData.length > 3 ? styles.showShadow : '')}>
                        <ChartistGraph data={chartistData} options={lineChartOptions} type="Line" />
                    </ScrollContainer>}

                    {this.state.dataType === 'donut' && <section className={styles.chartContainer + ' ' + styles.donut}>
                        <ChartistGraph data={donutData} options={donutChartOptions} type="Pie" />

                        <ul className={styles.chartLegends}>
                            {donutData.labels.map((label, index) => {
                                return <li>
                                    <CircularProgressbar className={styles.progress}
                                        value={50}
                                        strokeWidth={18}
                                        styles={{
                                            path: {
                                                stroke: pathColors[index % pathColors.length],
                                                strokeLinecap: 'round',
                                            },
                                            trail: {
                                                stroke: '#e6e6e6',
                                                strokeLinecap: 'round',
                                            }
                                        }
                                        }
                                    />
                                    <label>
                                        {label} <span> {donutData.series[index].value} </span>
                                    </label>
                                </li>
                            })}
                        </ul>
                    </section>}
                </div>}
                {this.state.dataType === 'message' && this.props.textToShow && <div className={styles.dataContent}>
                    <section>{this.getMarkupForRichText(this.props.textToShow)}</section>
                </div>}
            </div>

            {this.props.isPartial && <div className={styles.warningHolder}>{translatePhrase('Partial data. Reload widget to fetch all data')}</div>}
        </section>

    }
}

const ShowTable = connect(mapStateToProps, mapDispatchToProps)(ConnectedShowTable);

export default ShowTable;
