import React, { Component } from 'react';
import styles from './BasicUserDetails.module.scss';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../../shared/store/types';
import { getReadableDataForCustomField } from '../../../../shared/store/custom-fields';
import { translatePhrase } from '../../../../shared/helpers/translation';

interface OwnProps {
    userId: string;
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    const userData = state.users.byId[ownProps.userId];

    const nameField = state.users.customFields.byId[state.users.nameFieldId];
    const name = getReadableDataForCustomField(userData.customFields[nameField.id], nameField, userData.id, 'user');

    return {
        userData,
        name,
        structureData: state.structure,
        workflowsData: state.workflows,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = StateProps & DispatchProps & OwnProps;

interface OwnState {
}

class ConnectedBasicUserDetails extends Component<Props, OwnState> {

    getLocationName = (locationId: string) => {
        const location = this.props.structureData.locations.byId[locationId];
        let parentName = '';

        if (location.parent) {
            if (location.parent in this.props.structureData.locations.byId) {
                parentName = this.props.structureData.locations.byId[location.parent].name;
            } else if (location.parent in this.props.structureData.projects.byId) {
                parentName = this.props.structureData.projects.byId[location.parent].name;
            }
        }

        return `${translatePhrase(location.name)} (${translatePhrase(parentName)})`;
    }

    render() {
        return <section className={styles.basicUserDetails}>
            <div className={styles.label}>{translatePhrase('Name')}</div>
            <div className={styles.value}>{this.props.name}</div>

            <div className={styles.label}>{this.props.userData.projects.length === 1 ? translatePhrase('Project') : translatePhrase('Projects')}</div>
            <div className={styles.value}>
                {this.props.userData.projects
                    .filter(projectId => projectId in this.props.structureData.projects.byId)
                    .map(projectId => <div>{translatePhrase(this.props.structureData.projects.byId[projectId].name)}</div>)}
            </div>

            <div className={styles.label}>{this.props.userData.levels.length === 1 ? translatePhrase('Level') : translatePhrase('Levels')}</div>
            <div className={styles.value}>
                {this.props.userData.levels
                    .filter(levelId => levelId in this.props.structureData.levels.byId)
                    .map(levelId => <div>{translatePhrase(this.props.structureData.levels.byId[levelId].name)}</div>)}
            </div>

            <div className={styles.label}>{this.props.userData.roles.length === 1 ? translatePhrase('Role') : translatePhrase('Roles')}</div>
            <div className={styles.value}>
                {this.props.userData.roles
                    .filter(roleId => roleId in this.props.structureData.roles.byId)
                    .map(roleId => <div>{translatePhrase(this.props.structureData.roles.byId[roleId].name)}</div>)}
            </div>

            <div className={styles.label}>{this.props.userData.locations.length === 1 ? translatePhrase('Location') : translatePhrase('Locations')}</div>
            <div className={styles.value}>
                {this.props.userData.locations
                    .filter(locationId => locationId in this.props.structureData.locations.byId)
                    .map(locationId => <div>{this.getLocationName(locationId)}</div>)}
            </div>
        </section>
    }
}

const BasicUserDetails = connect(mapStateToProps, mapDispatchToProps)(ConnectedBasicUserDetails);


export default BasicUserDetails;