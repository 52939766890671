import React, { ChangeEvent, Component } from 'react';
import styles from './Languages.module.scss';
import { Redirect } from "react-router-dom";

import { selectLanguage, unSelectLanguage } from '../../shared/store/internationalization/languages/action';

import Translations from './Translations';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../shared/store/types';
import { Permissions } from '../../shared/store/permissions/types';

import { ReactComponent as LanguageIcon } from '../../common/assets/translate.svg';

import LanguagesList from './LanguagesList';
import { getTranslateableWords, translatePhrase } from '../../shared/helpers/translation';
import PageHeader from '../page-header/PageHeader';
import { updateMultipleTranslations, updateTranslation } from '../../shared/store/internationalization/translations/actions';
import Papa from 'papaparse';
import LoaderModal from '../../widgets/loader/LoaderModal';
import { saveAs } from 'file-saver';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const selectedLanguageId = !state.internationalization.languages.selected ? '' : state.internationalization.languages.selected;
    const canEditLanguages = state.permissions.myPermissions.general.LanguagesConfiguration === Permissions.WRITE;
    const canViewLanguages = canEditLanguages || state.permissions.myPermissions.general.LanguagesConfiguration === Permissions.READ;

    return {
        isReadable: canViewLanguages,
        selectedLanguage: selectedLanguageId,
        applicationState: state,
        translations: state.internationalization.translations.byLanguage[selectedLanguageId] || {},
        language: state.internationalization.languages.byId[selectedLanguageId],
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        selectLanguage: (id: string) => dispatch(selectLanguage(id)),
        unSelectLanguage: () => dispatch(unSelectLanguage()),
        updateTranslation: (originalWord: string, translatedWord: string, languageId: string) => dispatch(updateTranslation(originalWord, translatedWord, languageId)),
        updateMultipleTranslations: (translations: Array<{ originalWord: string, translatedWord: string }>, languageId: string) => dispatch(updateMultipleTranslations(translations, languageId))
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
    successLoader: boolean,
    successMessage: string,
    showLoader: boolean
    loaderText: Array<string>
}

class ConnectedLocations extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            successLoader: false,
            successMessage: '',
            loaderText: [],
            showLoader: false
        }
    }

    importTranslations = (importData: Array<Array<string>>) => {

        const dataWithoutHeader = importData.slice(1);
        let translations: Array<{ originalWord: string, translatedWord: string }> = [];

        for (const rowData of dataWithoutHeader) {
            if (rowData.length >= 2) {
                const originalPhrase = rowData[0];
                const translation = rowData[1];

                if (!!translation) {
                    translations.push({
                        originalWord: originalPhrase,
                        translatedWord: translation,
                    });
                }
            }
        }

        this.props.updateMultipleTranslations(translations, this.props.selectedLanguage);
    }


    handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        const file = !!e.target.files ? e.target.files[0] : undefined;

        if (!!file) {
            Papa.parse(file, {
                complete: (results) => {
                    const csvData = results.data as Array<Array<string>>;
                    this.importTranslations(csvData);
                }
            });
        }
    }

    getLanguageExportData = () => {

        const aggregatedData = getTranslateableWords(this.props.applicationState),
            translatables = Array.from(aggregatedData.masterSetWithoutDuplicates),
            duplicatePairs = Array.from(aggregatedData.duplicateTracker),
            csvData: Array<Array<string>> = [['Original phrase', 'Translated phrase', 'Context']];


        for (const translatablePair of translatables) {
            const translatable = translatablePair[0];
            let additionalPairs: Array<string> = [];

            duplicatePairs.forEach(pair => {
                if (pair[0].includes(translatable)) {
                    additionalPairs.push(pair[1]);
                }
            });

            const data = Array.from(new Set([...additionalPairs]));

            csvData.push([translatable, this.props.translations[translatable], data.join(',')]);
        }

        if (!this.props.isReadable) {
            return <Redirect to="/dashboard" />;
        }

        const csvString = Papa.unparse(csvData);
        const fileBlob = new Blob([csvString], { type: "text/csv" });
        saveAs(fileBlob, `${this.props.language?.name} Export.csv`);

        this.setState({
            showLoader: false,
            loaderText: [],
            successLoader: true,
            successMessage: translatePhrase('Export ready'),
        });

        window.setTimeout(() => {
            this.setState({
                successLoader: false,
                successMessage: '',
            });
        }, 3000);
    };


    exportData = () => {
        this.setState({
            showLoader: true,
            loaderText: [translatePhrase('Preparing export') + '...'],
        });
        setTimeout(this.getLanguageExportData, 200)
    }

    render() {

        return (<section className={styles.FocusSpace}>
            {this.state.successLoader && <LoaderModal loaderText={[this.state.successMessage]} isSuccess={true} />}
            {this.state.showLoader && <LoaderModal loaderText={this.state.loaderText} isSuccess={false} isIndeterminate />}

            <header className={styles.pageHeader}>
                <h2 className={styles.heading} id="page-header"> <LanguageIcon />  {translatePhrase('Languages')} </h2>
                <PageHeader />
            </header>
            <div className={styles.innerFocus}>
                <div className={styles.languagesHolder}>
                    <div className={styles.allLanguages} onClick={this.props.unSelectLanguage}>
                        <LanguagesList
                            heading="Languages"
                            onSelectCard={this.props.selectLanguage}
                            onUnSelectCard={() => { }}
                            selectedId={this.props.selectedLanguage}
                            onExport={this.exportData}
                            onImport={this.props.language?.name ? () => this.handleFileUpload : undefined}
                        />
                    </div>
                    {this.props.selectedLanguage &&
                        <div className={styles.translationsArea + " ignore-react-onclickoutside"}>
                            <Translations key={this.props.selectedLanguage} languageId={this.props.selectedLanguage} />
                        </div>
                    }
                </div>
            </div>
        </section>);
    }
}

const Locations = connect(mapStateToProps, mapDispatchToProps)(ConnectedLocations);

export default Locations;