import React, { Component } from 'react';
import draftToHtml from 'draftjs-to-html';
import ReactHtmlParser from 'react-html-parser';
import { isWebUri } from 'valid-url';

import { connect } from 'react-redux';
import styles from './Show.module.scss';

import Papa from 'papaparse';
import { PieceType } from '../../../shared/store/flowchart/pieces/types';
import ShowTable, { ShowTableProps } from './ShowTable';
import { WorkflowProcessState } from '../../../shared/store/workflows/types';
import { ApplicationState } from '../../../shared/store/types';
import { convertFileToBlobAndDataURL, getFormatedFileSize, getOnlineFileSize, updateLinkToCurrentOrigin } from '../../../shared/helpers/file-utilities';
import { isUUID } from '../../../shared/helpers/utilities';
import { VariableType } from '../../../shared/store/flowchart/variables/types';
import { getWorkflowPieceValue } from '../../../shared/store/flowchart/helpers/workflow';
import { getPieceValueType } from '../../../shared/store/flowchart/helpers';
import Table from '../../../widgets/table/Table';
import { TableHeading, TableRow } from '../../../widgets/table/Table';
import { translatePhrase } from '../../../shared/helpers/translation';

import Button from '../../../widgets/button/CommonButton';
import { ReactComponent as ReadIcon } from '../../../common/assets/search-book.svg';
import { ReactComponent as ExportIcon } from '../../../common/assets/export.svg';
import { ReactComponent as TableIcon } from '../../../common/assets/table.svg';
import { CustomFieldValueType, FieldType } from '../../../shared/store/custom-fields/types';
import { getWorkflowQuestionValidationValue } from '../../../shared/store/flowchart/helpers/question';
import { DBSavedFiles, TableCell } from '../../../shared/helpers/common-types';
import { getStoredFileInDB } from '../../../shared/store/file-operations';
import { saveAs } from 'file-saver';
import TextDecorator from './TextDecorator';
import { ReactComponent as DownloadIcon } from '../../../common/assets/download-alt.svg';
import { ReactComponent as PdfIcon } from '../../../common/assets/bxs-file-pdf.svg';
import { ReactComponent as CssIcon } from '../../../common/assets/bxs-file-css.svg';
import FileDetails from './FileDetails';

type OwnProps = {
    workflowId: string;
    showPieceId: string;
    userInputsForChoice?: {
        [questionId: string]: string | Array<string>,
    };

    userInputs: {
        [customFieldId: string]: CustomFieldValueType,
    },
    listUserInputs?: {
        [listId: string]: {
            [customFieldId: string]: CustomFieldValueType
        }
    },
};

const mapStateToProps = (state: ApplicationState) => {

    return {
        applicationState: state,
        workflowData: state.workflows,
        piecesData: state.flowchart.pieces,
    }
}

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;

type OwnState = {
    token: string,
    showDataMarkup: JSX.Element | undefined,
}

class ConnectedShow extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            token: localStorage.getItem('token') || '',
            showDataMarkup: undefined,
        }
    }

    getShowDataFromPieceId = (showPieceId: string, workflowProcessState: WorkflowProcessState): ShowTableProps | string | Array<string> => {
        const showPiece = this.props.piecesData.byId[showPieceId];

        if (showPiece.type !== PieceType.SHOW && showPiece.type !== PieceType.GROUPED_SHOW) {
            throw new Error('The piece must be a show piece');
        }

        if (!showPiece.variableToShow) {
            throw new Error('There is no variable to show');
        }

        const variableData: {
            [variableId: string]: string | Array<string>,
        } = {};


        if (this.props.userInputsForChoice) {
            for (const choosePieceId in this.props.userInputsForChoice) {
                const choosePiece = this.props.piecesData.byId[choosePieceId];

                if (choosePiece.type !== PieceType.CHOOSE && choosePiece.type !== PieceType.GROUPED_CHOOSE) {
                    throw new Error('This piece must be a choose piece');
                }

                if (!choosePiece.choiceVariable) {
                    throw new Error('The choose piece must have a variable selected');
                }

                variableData[choosePiece.choiceVariable] = this.props.userInputsForChoice[choosePieceId];
            }
        }

        const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: workflowProcessState.customFields,
            lastComputedPiece: workflowProcessState.displayingShowPieceId,
            executionStack: workflowProcessState.executionStack,
            forIterationCounts: workflowProcessState.forIterationCounts,
            variables: {
                ...workflowProcessState.variables,
                ...variableData,
            },
            displayingQuestionPieceId: workflowProcessState.displayingQuestionPieceId,
            displayingShowPieceId: workflowProcessState.displayingShowPieceId,
            displayingGroupPieceId: workflowProcessState.displayingGroupPieceId,
            displayingTransferPieceId: workflowProcessState.displayingTransferPieceId,
            displayingContinuePieceId: workflowProcessState.displayingContinuePieceId,
            displayingAddWorkflowPieceId: workflowProcessState.displayingAddWorkflowPieceId,
            createdWorkflowId: workflowProcessState.createdWorkflowId,
        }));

        if (!isUUID(showPiece.variableToShow)) {
            return showPiece.variableToShow;
        }


        const allAnswers = {
            ...this.props.listUserInputs,
            ...this.props.userInputs,
        }

        let variableValue = getWorkflowQuestionValidationValue(this.props.applicationState, processState, this.props.workflowId, this.props.showPieceId, undefined, allAnswers, showPiece.variableToShow);
        const showVariableType = getPieceValueType(showPiece.variableToShow, this.props.applicationState.flowchart.pieces, this.props.applicationState.flowchart.variables);

        let selectedTypeForShow: 'User' | 'Member' | 'Group' | 'Workflow' | 'Text' = 'Text';

        if (typeof showVariableType === 'undefined' || showVariableType === VariableType.TEXT) {
            if (typeof variableValue === 'string') {
                return translatePhrase(String(variableValue));
            }
            return String(variableValue);
        } else if (showVariableType === VariableType.TEXT_LIST) {

            if (typeof variableValue === 'undefined') {
                return '';
            }

            if (!Array.isArray(variableValue)) {
                throw new Error('Variable value must be a string');
            }

            const stringValues = variableValue as Array<string>;

            const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
                customFields: workflowProcessState.customFields,
                lastComputedPiece: workflowProcessState.displayingShowPieceId,
                executionStack: workflowProcessState.executionStack,
                forIterationCounts: workflowProcessState.forIterationCounts,
                variables: {
                    ...workflowProcessState.variables,
                    ...variableData,
                },
                displayingQuestionPieceId: workflowProcessState.displayingQuestionPieceId,
                displayingShowPieceId: workflowProcessState.displayingShowPieceId,
                displayingGroupPieceId: workflowProcessState.displayingGroupPieceId,
                displayingTransferPieceId: workflowProcessState.displayingTransferPieceId,
                displayingContinuePieceId: workflowProcessState.displayingContinuePieceId,
                displayingAddWorkflowPieceId: workflowProcessState.displayingAddWorkflowPieceId,
                createdWorkflowId: workflowProcessState.createdWorkflowId,
            }));

            let widgetTitle = showPiece.widgetTitle ? showPiece.widgetTitle : 'Text list';

            if (typeof widgetTitle === 'string' && isUUID(widgetTitle)) {
                const pieceValue = getWorkflowPieceValue(this.props.applicationState, processState, this.props.workflowId, widgetTitle);

                if (typeof pieceValue === 'string') {
                    widgetTitle = pieceValue;
                }
            }

            return [widgetTitle, ...stringValues];
        } else if (showVariableType === VariableType.USER || showVariableType === VariableType.USERS_LIST) {
            selectedTypeForShow = 'User';
        } else if (showVariableType === VariableType.MEMBER || showVariableType === VariableType.MEMBERS_LIST) {
            selectedTypeForShow = 'Member';
        } else if (showVariableType === VariableType.GROUP || showVariableType === VariableType.GROUPS_LIST) {
            selectedTypeForShow = 'Group';
        } else if (showVariableType === VariableType.WORKFLOW || showVariableType === VariableType.WORKFLOWS_LIST) {
            selectedTypeForShow = 'Workflow';
        } else if (showVariableType === VariableType.DATA_FRAGMENT) {
            if (typeof variableValue === 'undefined') {
                return '';
            }

            if (typeof variableValue !== 'string') {
                throw new Error('Variable value must be a string');
            }

            if (variableValue in this.props.applicationState.staticInfo.fragments.byId) {
                return this.props.applicationState.staticInfo.fragments.byId[variableValue].name;
            }
        } else if (showVariableType === VariableType.DATA_FRAGMENTS_LIST) {
            if (typeof variableValue === 'undefined') {
                return '';
            }

            if (!Array.isArray(variableValue)) {
                throw new Error('Variable value must be a string');
            }

            const stringValues = variableValue as Array<string>;

            const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
                customFields: workflowProcessState.customFields,
                lastComputedPiece: workflowProcessState.displayingShowPieceId,
                executionStack: workflowProcessState.executionStack,
                forIterationCounts: workflowProcessState.forIterationCounts,
                variables: {
                    ...workflowProcessState.variables,
                    ...variableData,
                },
                displayingQuestionPieceId: workflowProcessState.displayingQuestionPieceId,
                displayingShowPieceId: workflowProcessState.displayingShowPieceId,
                displayingGroupPieceId: workflowProcessState.displayingGroupPieceId,
                displayingTransferPieceId: workflowProcessState.displayingTransferPieceId,
                displayingContinuePieceId: workflowProcessState.displayingContinuePieceId,
                displayingAddWorkflowPieceId: workflowProcessState.displayingAddWorkflowPieceId,
                createdWorkflowId: workflowProcessState.createdWorkflowId,
            }));

            let widgetTitle = showPiece.widgetTitle ? showPiece.widgetTitle : 'Data fragments';

            if (typeof widgetTitle === 'string' && isUUID(widgetTitle)) {
                const pieceValue = getWorkflowPieceValue(this.props.applicationState, processState, this.props.workflowId, widgetTitle);

                if (typeof pieceValue === 'string') {
                    widgetTitle = pieceValue;
                }
            }

            return [widgetTitle, ...stringValues.map(value => this.props.applicationState.staticInfo.fragments.byId[value].name)];
        } else {
            return String(variableValue);
        }

        if (!Array.isArray(variableValue) && typeof variableValue !== 'string') {
            throw new Error('The variable value must be an iterable');
        }

        if (Array.isArray(variableValue)) {

            if (variableValue.length > 0 && Array.isArray(variableValue[0])) {
                // Cannot be a multidimensional array
                throw new Error('The value cannot be a multi-dimensional array')
            }

            variableValue = variableValue as Array<string>;
        }

        const showTableData: ShowTableProps = {
            entityIds: typeof variableValue === 'string' ? [variableValue] : variableValue,
            type: selectedTypeForShow,
            typeId: showPiece.entityType,
            customFields: showPiece.customFieldIds || [],
            xAxis: showPiece.xAxis,
            yAxis: showPiece.yAxis,
            yAxisAggregation: showPiece.yAxisAggregation,
            startingDisplayType: showPiece.startingDisplayType,
            header: showPiece.widgetTitle,
            setIsPopulatingExport: () => { },
        };

        return showTableData;
    }

    getShowMarkupForLink = async (link: string) => {
        if (!isWebUri(link) && !link.includes("https://") && !link.includes("http://")) {
            return undefined;
        };

        try {

            if (link.startsWith('https://youtube.com') || link.startsWith('https://www.youtube.com')) {
                try {
                    const youtubeUrl = new URL(link);

                    let embedLink = '';

                    if (link.includes('embed')) {
                        embedLink = link;
                    } else if (youtubeUrl.searchParams.get('v')) {
                        embedLink = 'https://www.youtube.com/embed/' + youtubeUrl.searchParams.get('v');
                    }

                    return <div className={styles.showImageBorder}>
                        <section className={styles.youtubeVideoHolder}>
                            <iframe
                                width="100%"
                                height="315"
                                style={{ borderRadius: "12px" }}
                                src={embedLink}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen>

                            </iframe>
                        </section>

                    </div>
                } catch {
                    return <div>Could not load youtube video</div>
                }
            }

            if (link.startsWith('https://finsall.co.in')) {
                return <div className={styles.showImageBorder}>
                    <section className={styles.finsalHolder}>
                        <iframe
                            src={link}
                            style={{
                                width: '90%',
                                height: '70vh',
                                display: 'block',
                            }}
                        >
                        </iframe>
                    </section>
                </div>
            };

            if (isWebUri(link) && !link.includes('diceflow.in')) {
                return <a className={styles.downloadLink} href={link} rel="noopener noreferrer" target="_blank">{link}</a>
            }

            link = "https://" + link.split("https://")[1];
            const originalLink = link;

            if (link.includes('diceflow.in')) {
                link = updateLinkToCurrentOrigin(link) + '?token=' + this.state.token;
            }

            let fileName = 'Link';

            if (link.includes('diceflow.in')) {
                fileName = decodeURI(originalLink).split('/')[5];
            };

            const fileSizeInBytes = await getOnlineFileSize(originalLink);
            const fileSize = fileSizeInBytes ? getFormatedFileSize(fileSizeInBytes) : "";

            const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
            const audioExtensions = ['.mp3', '.aac', '.m4a', '.wav', '.x-m4a'];
            const videoExtensions = ['.mp4', '.mov'];
            const downloadableExtensions = ['.pdf', '.css'];

            for (const extension of imageExtensions) {
                if (originalLink.endsWith(extension)) {
                    return (
                        <div className={styles.showImageBorder}>
                            <img src={link} className={styles.showImage} alt="Show piece link" />
                            <FileDetails
                                fileName={fileName}
                                extension={extension.slice(1)}
                                fileLink={link}
                                fileSize={fileSize}
                            />
                        </div>
                    );
                }
            }

            for (const extension of audioExtensions) {
                if (originalLink.endsWith(extension)) {
                    return (
                        <div className={styles.showAudioContainer}>
                            <audio controls><source src={link} /></audio>
                            <FileDetails
                                fileName={fileName}
                                extension={extension.slice(1)}
                                fileLink={link}
                                fileSize={fileSize}
                            />
                        </div>
                    );
                }
            }

            for (const extension of videoExtensions) {
                if (originalLink.endsWith(extension)) {
                    return (
                        <div className={styles.showVideoContainer}>
                            <video controls style={{ borderRadius: "12px" }} className={styles.showVideo}><source src={link} /></video>
                            <FileDetails
                                fileName={fileName}
                                extension={extension.slice(1)}
                                fileLink={link}
                                fileSize={fileSize}
                            />
                        </div>
                    );
                }
            }

            for (const extension of downloadableExtensions) {
                if (originalLink.endsWith(extension)) {
                    return (
                        <div className={styles.showPdfContainer}>
                            <div className={styles.pdfName}>
                                <div className={styles.pdfIconHolder}>
                                    {extension === ".pdf" ?
                                        <PdfIcon width={"16"} height={"21"} fill='#9A8CA0' />
                                        :
                                        <CssIcon width={"16"} height={"21"} fill='#9A8CA0' />

                                    }
                                </div>
                                <div className={styles.fileName}>{fileName}</div>
                            </div>
                            <div className={styles.fileDetails}>
                                <span>PDF</span>
                                <span className={styles.dot}></span>
                                <span>{fileSize}</span>
                                <a className={styles.downloadButton} href={link} target='_blank' rel="noopener noreferrer">
                                    <DownloadIcon />
                                    <div>{translatePhrase('Download')}</div>
                                </a>
                            </div>
                        </div>
                    );
                }
            }
        } catch (error) {
            return <a className={styles.downloadLink} href={link} rel="noopener noreferrer" target="_blank">{link}</a>
        }
    }

    getShowMarkupForRichText = (value: string) => {
        let stringifiedHtml: string;
        try {
            stringifiedHtml = draftToHtml(JSON.parse(value));
        } catch (e) {
            stringifiedHtml = '<p>Incorrect HTML</p>';
        }

        return ReactHtmlParser(stringifiedHtml);
    }

    exportData = (exportData: Array<Array<string>>, title?: string) => {
        const csvString = Papa.unparse(exportData);
        const fileBlob = new Blob([csvString], { type: 'text/csv' });

        saveAs(fileBlob, `${title ? title : 'Table'} Export.csv`);
    }

    downloadLocalFile = (value: DBSavedFiles) => {
        if (value?.file) {
            saveAs(value.file, value.file.name);
        }
    }

    async getShowMarkupForOfflineLink(link: string) {
        const localSavedFile = await getStoredFileInDB(link);

        if (localSavedFile && localSavedFile.file) {
            const file = localSavedFile.file;
            const fileName = file.name;
            const fileSize = getFormatedFileSize(file.size);
            const blobFile = await convertFileToBlobAndDataURL(localSavedFile.file);
            const dataURL = blobFile.dataURL;

            const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
            const audioExtensions = ['.mp3', '.aac', '.m4a', '.wav', '.x-m4a'];
            const videoExtensions = ['.mp4', '.mov'];

            let finalLink = dataURL;

            for (const extension of imageExtensions) {
                if (fileName.endsWith(extension)) {
                    return (
                        <div className={styles.showImageBorder}>
                            <img src={finalLink} className={styles.showImage} alt={fileName} />
                            <FileDetails
                                fileName={fileName}
                                extension={extension.slice(1)}
                                downloadLocalFile={this.downloadLocalFile}
                                localSavedFile={localSavedFile}
                                fileSize={fileSize}
                            />
                        </div>
                    );
                }
            }

            for (const extension of audioExtensions) {
                if (fileName.endsWith(extension)) {
                    return (
                        <div className={styles.showAudioContainer}>
                            <audio controls><source src={finalLink} /></audio>
                            <FileDetails
                                fileName={fileName}
                                extension={extension.slice(1)}
                                downloadLocalFile={this.downloadLocalFile}
                                localSavedFile={localSavedFile}
                                fileSize={fileSize}
                            />
                        </div>
                    );
                }
            }

            for (const extension of videoExtensions) {
                if (fileName.endsWith(extension)) {
                    return (
                        <div className={styles.showVideoContainer}>
                            <video controls className={styles.showVideo}><source src={finalLink} /></video>
                            <FileDetails
                                fileName={fileName}
                                extension={extension.slice(1)}
                                downloadLocalFile={this.downloadLocalFile}
                                localSavedFile={localSavedFile}
                                fileSize={fileSize}
                            />
                        </div>
                    );
                }
            }

            if (fileName.endsWith(".pdf")) {
                return (
                    <div className={styles.showPdfContainer}>
                        <div className={styles.pdfName}>
                            <div className={styles.pdfIconHolder}>
                                <PdfIcon width={"16"} height={"21"} fill='#9A8CA0' />
                            </div>
                            <div className={styles.fileName}>{fileName}</div>
                        </div>
                        <div className={styles.fileDetails}>
                            <span>PDF</span>
                            <span className={styles.dot}></span>
                            <span>{fileSize}</span>
                            <div className={styles.downloadButton} onClick={() => this.downloadLocalFile(localSavedFile)}>
                                <DownloadIcon />
                                <div>{translatePhrase('Download')}</div>
                            </div>
                        </div>
                    </div>
                )
            }

            return <div onClick={() => this.downloadLocalFile(localSavedFile)}>{fileName}</div>;

        } else return <span></span>
    };

    async getFileMarkUp(customFieldValue: string) {
        if (isWebUri(customFieldValue) || customFieldValue.startsWith('http')) {
            return this.getShowMarkupForLink(customFieldValue);
        } else return await this.getShowMarkupForOfflineLink(customFieldValue);
    };


    calculateShowDataMarkup = async () => {
        const workflow = this.props.workflowData.byId[this.props.workflowId];

        if (!workflow) {
            return <div></div>
        }

        const workflowProcessState = workflow.history[workflow.historyIndex];
        const showPiece = this.props.piecesData.byId[this.props.showPieceId];


        if (showPiece.type !== PieceType.SHOW && showPiece.type !== PieceType.GROUPED_SHOW) {
            throw new Error('This piece must be a show piece');
        }

        let showDataMarkup: JSX.Element | undefined;

        if (showPiece.isRichText) {
            showDataMarkup = <div className={styles.richtext}>{this.getShowMarkupForRichText(showPiece.variableToShow || '')}</div>;
        } else {
            const showData = this.getShowDataFromPieceId(this.props.showPieceId, workflowProcessState);
            let showDataType: FieldType | undefined;

            let displayPiece = this.props.piecesData.byId[this.props.showPieceId];

            if (displayPiece.type === PieceType.SHOW || displayPiece.type === PieceType.GROUPED_SHOW) {
                if (displayPiece.variableToShow) {
                    let piece: any = this.props.piecesData.byId[displayPiece.variableToShow];
                    if (piece && piece.customField) {
                        showDataType = this.props.workflowData.types.customFields.byId[piece.customField].type;
                    }
                }
            };

            const dataType = showPiece.variableToShow ? isUUID(showPiece.variableToShow) ? getPieceValueType(showPiece.variableToShow, this.props.piecesData, this.props.applicationState.flowchart.variables, this.props.applicationState) : VariableType.TEXT : undefined;

            if (showPiece.variableToShow && (dataType === VariableType.TABLE_DATA || dataType === VariableType.STYLED_TABLE_DATA)) {
                const showDataTitle = showPiece.widgetTitle && isUUID(showPiece.widgetTitle) ? getWorkflowPieceValue(this.props.applicationState, workflowProcessState, this.props.workflowId, showPiece.widgetTitle) as string : showPiece.widgetTitle;
                const showData = getWorkflowPieceValue(this.props.applicationState, workflowProcessState, this.props.workflowId, showPiece.variableToShow);

                if (Array.isArray(showData)) {
                    const rowsData = showData.slice() as Array<Array<string | TableCell>>;

                    const entries: Array<TableRow> = rowsData.map((rowDatum, index) => {
                        return {
                            id: String(index),
                            entries: rowDatum,
                        }
                    });

                    const allData = rowsData.map(row => row.map(cell => typeof cell === 'object' ? cell.value : cell));

                    const header = <header className={styles.showHeader}>

                        <h5 className={styles.showHeading}>
                            <TableIcon />
                            <div className={styles.heading}>
                                <div title={showDataTitle}>
                                    <TextDecorator text={showDataTitle || ''} />
                                </div>
                            </div>
                        </h5>

                        <div className={styles.actionButtonsContainer}>
                            <Button title={translatePhrase('Export')} icon={<ExportIcon />}
                                onClick={() => this.exportData(allData, typeof showDataTitle === 'string' ? showDataTitle : undefined)}
                                isRounded={true} type={'secondary'}
                                size={'small'} />
                        </div>

                    </header>;

                    showDataMarkup = <section className={styles.showDataContainerForTable}>
                        {header}
                        <div className={styles.dataContainer}>
                            <section className={styles.normalTable}>
                                <section className={styles.tableHolder}>
                                    <Table
                                        headings={[]}
                                        entries={entries}
                                        isReadOnly
                                        isEquidistant
                                        areActionsHidden
                                    />
                                </section>
                            </section>
                        </div>
                    </section>
                }
            } else if (typeof showData === 'string') {
                if (showDataType === FieldType.FILE || (isWebUri(showData) || showData.startsWith('https://') || showData.startsWith('http://'))) {
                    showDataMarkup = await this.getFileMarkUp(showData);
                } else showDataMarkup = <div> <TextDecorator text={translatePhrase(showData)} /></div>
            } else if (Array.isArray(showData)) {
                const headings: Array<TableHeading> = [{
                    name: translatePhrase('Sl. no'),
                    isSortable: false,
                    width: 100,
                }, {
                    name: translatePhrase(showData[0]),
                    isSortable: false,
                    width: 300,
                }];

                const entries: Array<TableRow> = showData.slice(1).map((dataFragmentName, index) => {
                    return {
                        id: dataFragmentName,
                        entries: [
                            index + 1,
                            translatePhrase(dataFragmentName),
                        ],
                    };
                });

                showDataMarkup = <Table
                    headings={headings}
                    entries={entries}
                    isReadOnly
                    areActionsHidden
                />
            } else {
                // This is a show widget of some kind
                showDataMarkup = <ShowTable key={this.props.showPieceId} {...showData} />
            };
        }

        this.setState({
            showDataMarkup,
        });
    }

    componentDidMount() {
        this.calculateShowDataMarkup();
    }

    componentDidUpdate(prevProps: Readonly<Props>) {
        if (this.props.workflowId !== prevProps.workflowId) {
            this.calculateShowDataMarkup();
        }

        if (this.props.showPieceId !== prevProps.showPieceId) {
            this.calculateShowDataMarkup();
        }

        if (JSON.stringify(this.props.userInputs) !== JSON.stringify(prevProps.userInputs)) {
            this.calculateShowDataMarkup();
        }

        if (JSON.stringify(this.props.listUserInputs) !== JSON.stringify(prevProps.listUserInputs)) {
            this.calculateShowDataMarkup();
        }

        if (JSON.stringify(this.props.userInputsForChoice) !== JSON.stringify(prevProps.userInputsForChoice)) {
            this.calculateShowDataMarkup();
        }
    }

    render() {
        return <div className={styles.showDataContainer}> <div className={styles.iconHolder}> <ReadIcon /> </div> {this.state.showDataMarkup}</div>;
    }
}

const Show = connect(mapStateToProps)(ConnectedShow);

export default Show;