import React, { Component, ChangeEvent } from 'react';
import styles from '../Users.module.scss'
import UserModify from './UserModify';
import UserFilter from './UserFilter';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../shared/store/types';

import { addUserRequest, updateUserRequest, deleteUser, searchUserTable, goToPageUserTable, setPageSizeUserTable, sortUserTable, unArchiveUser, setIsShowingAddFormForUsers, setIsShowingCSVFormForUsers, setIsShowingFilterFormForUsers, setIsShowingModifyFormForUsers, recomputeAllUsers, setIsShowingMetricsForUsers, setisShowingUserSyncMetaLogExportForm } from '../../../shared/store/users/actions';
import { IUpdateableUserData } from '../../../shared/store/users/types';
import TableWithMeta from '../../../widgets/table/TableWithMeta';
import { getReadableDataForCustomField } from '../../../shared/store/custom-fields';
import { translatePhrase } from '../../../shared/helpers/translation';
import { Permissions } from '../../../shared/store/permissions/types';
import UserPerformanceDetails from './performance-details/UserPerformanceDetails';
import { TableHeading, TableRow } from '../../../widgets/table/Table';
import { getFilteredUsers } from './selectors';
import UserCSV from './UserCSV';
import { FieldType } from '../../../shared/store/custom-fields/types';
import { NudgeType } from '../../../shared/store/my-data/types';
import LoaderModal from '../../../widgets/loader/LoaderModal';
import { setInfoMessage, clearInfoMessage } from '../../../shared/store/my-data/actions';
import { getAncestorChainOfLocation } from '../../../shared/helpers/locations';
import { isUUID } from '../../../shared/helpers/utilities';
import { clearTempFiles, commitTempFiles } from '../../../shared/store/file-operations';

import TableLocationList from './TableLocationList';
import { BASE_URL } from '../../../shared/store/url';
import UserSyncLogExportCSV from './UserSyncLogExportCSV';

const mapStateToProps = (state: ApplicationState) => {

    const currentPageNumber = state.users.currentPageNumber,
        pageSize = state.users.pageSize,
        userSearchTerm = state.users.searchTerm,
        filters = state.users.filters;

    let filteredUsers = getFilteredUsers(state);

    const tags: Array<string> = [];

    if (filters.projects.length > 0) {
        tags.push('Projects: ' + filters.projects.map(projectId => state.structure.projects.byId[projectId].name).join(', '));
    }

    if (filters.levels.length > 0) {
        tags.push('Levels: ' + filters.levels.map(levelId => state.structure.levels.byId[levelId].name).join(', ') + (filters.areLowerLevelsIncluded ? ' and below' : ''));
    }

    if (filters.roles.length > 0) {
        tags.push('Roles: ' + filters.roles.map(roleId => state.structure.roles.byId[roleId].name).join(', '));
    }

    if (filters.locations.length > 0) {
        tags.push('Locations: ' + filters.locations.map(locationId => state.structure.locations.byId[locationId].name).join(', ') + (filters.areLowerLevelsIncluded ? ' and below' : ''));
    }

    if (filters.unsynced) {
        tags.push('Unsynced');
    }

    if (filters.archived) {
        tags.push('Archived');
    }

    if (filters.isOnline) {
        tags.push('Is Online');
    }

    if (filters.isBetaTester) {
        tags.push('Is Tester');
    }

    for (const customFieldId in filters.customFields) {
        const customField = state.structure.roles.customFields.byId[customFieldId];
        const selectedOptions = filters.customFields[customFieldId].map(optionId => state.structure.roles.customFieldOptions.byId[optionId]);

        tags.push(`${customField.name}: ${selectedOptions.map(option => option.name).join(', ')}`);
    }

    if (tags.length > 0) {
        tags.push('Count: ' + filteredUsers.length);
    }

    const token = localStorage.getItem('token')

    return {
        usersList: filteredUsers.slice((currentPageNumber - 1) * pageSize, currentPageNumber * pageSize),
        totalNoOfUsers: state.users.allEntries.length,
        pageSize: pageSize,
        pageNumber: currentPageNumber,
        totalPages: Math.ceil(filteredUsers.length / pageSize),
        tags,
        token,
        searchTerm: userSearchTerm,
        userFilters: filters,
        userSort: state.users.sort,

        customFieldsData: state.users.customFields,
        customFieldOptionsData: state.users.customFieldOptions,

        roleCustomFieldsData: state.structure.roles.customFields,
        roleCustomFieldOptionsData: state.structure.roles.customFieldOptions,

        projectsData: state.structure.projects,
        levelsData: state.structure.levels,
        rolesData: state.structure.roles,
        locationsData: state.structure.locations,

        usersData: state.users,

        areFiltersExpanded: false,

        myPermissions: state.permissions.myPermissions,
        myId: state.myData.id,

        isShowingAddForm: !!state.users.isShowingAddForm,
        isShowingModifyForm: !!state.users.isShowingModifyForm,
        isShowingFilterForm: !!state.users.isShowingFilterForm,
        isShowingUserMetrics: !!state.users.isShowingMetrics,
        isShowingCSVForm: !!state.users.isShowingCSVForm,
        isShowingUserSyncMetaLogExportForm: !!state.users.isShowingUserSyncMetaLogExportForm,

        applicationState: state,
        organizationPlan: state.organization.plan,
        selectedNudge: state.myData.selectedNudgeId,
        isSuperUser: !isUUID(state.myData.id),
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        searchTable: (searchTerm: string) => dispatch(searchUserTable(searchTerm)),
        goToPage: (page: number) => dispatch(goToPageUserTable(page)),
        changePageSize: (size: number) => dispatch(setPageSizeUserTable(size)),
        sort: (column: string, order: 'ASC' | 'DESC') => dispatch(sortUserTable(column, order)),

        setInfoMessage: (message: string) => dispatch(setInfoMessage(message)),
        clearInfoMessage: () => dispatch(clearInfoMessage()),

        addUser: (payload: IUpdateableUserData) => dispatch(addUserRequest(payload)),
        deleteUser: (id: string) => dispatch(deleteUser(id)),
        unArchiveUser: (id: string) => dispatch(unArchiveUser(id)),
        recomputeAllUsers: () => dispatch(recomputeAllUsers()),
        updateUser: (payload: IUpdateableUserData) => dispatch(updateUserRequest(payload)),

        setIsShowingAddForm: (showValue: boolean) => dispatch(setIsShowingAddFormForUsers(showValue)),
        setIsShowingModifyForm: (showValue: boolean) => dispatch(setIsShowingModifyFormForUsers(showValue)),
        setIsShowingFilterForm: (showValue: boolean) => dispatch(setIsShowingFilterFormForUsers(showValue)),
        setIsShowingMetricsForUsers: (showValue: boolean) => dispatch(setIsShowingMetricsForUsers(showValue)),
        setIsShowingCSVForm: (showValue: boolean) => dispatch(setIsShowingCSVFormForUsers(showValue)),
        setisShowingUserSyncMetaLogExportForm: (showValue: boolean) => dispatch(setisShowingUserSyncMetaLogExportForm(showValue)),
    };
}

type OwnProps = {
    isReadOnly: boolean,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
    isShowingPerformanceDetailsLoader: boolean,
    userSelectedForPerformance: string | undefined,
    token: string,
}

class ConnectedUsersTable extends Component<Props, OwnState> {

    state: OwnState = {
        isShowingPerformanceDetailsLoader: false,
        userSelectedForPerformance: undefined,
        token: localStorage.getItem('token') || '',
    }

    static defaultProps = {
        isReadOnly: false,
    }

    addBlur() {
        document.querySelector('#page-header')?.classList.add('blur');
    }

    removeBlur() {
        document.querySelector('#page-header')?.classList.remove('blur');
    }

    showAddForm = () => {
        this.addBlur();
        this.props.setIsShowingAddForm(true);
    }

    showFilterForm = () => {
        this.addBlur();
        this.props.setIsShowingFilterForm(true);
    }

    showModifyForm = () => {
        this.addBlur();
        this.props.setIsShowingModifyForm(true);
    }

    showPerformanceDetails = () => {
        this.addBlur();
        this.setState({
            isShowingPerformanceDetailsLoader: true,
            userSelectedForPerformance: undefined,
        });

        window.setTimeout(() => {

            this.props.setIsShowingMetricsForUsers(true);

            this.setState({
                isShowingPerformanceDetailsLoader: false,
            });
        }, 1500);
    }


    showExportSyncMetaLog = () => {
        if (this.props.isShowingUserSyncMetaLogExportForm) {
            this.removeBlur();
            this.props.setisShowingUserSyncMetaLogExportForm(false);
        } else {
            this.addBlur();
            this.props.setisShowingUserSyncMetaLogExportForm(true);
        }
    }

    showPerformanceDetailsForUser = (userId: string) => {
        this.setState({
            isShowingPerformanceDetailsLoader: true,
            userSelectedForPerformance: userId,
        });

        window.setTimeout(() => {

            this.props.setIsShowingMetricsForUsers(true);

            this.setState({
                isShowingPerformanceDetailsLoader: false,
            });
        }, 1500);
    }

    hidePerformanceDetails = () => {
        this.removeBlur();

        this.props.setIsShowingMetricsForUsers(false);

        this.setState({
            userSelectedForPerformance: undefined,
        });
    }

    hideUserForm = async () => {
        await clearTempFiles();
        this.removeBlur();
        this.props.setIsShowingAddForm(false);
        this.props.setIsShowingModifyForm(false);
    }

    hideFilterForm = () => {
        this.removeBlur();
        this.props.setIsShowingFilterForm(false);
    }

    addUser = async (userData: IUpdateableUserData) => {
        await commitTempFiles();
        this.props.addUser(userData);
        this.removeBlur();
        this.props.setIsShowingAddForm(false);
    }

    updateUser = async (memberData: IUpdateableUserData) => {
        await commitTempFiles();
        this.props.updateUser(memberData);
        this.removeBlur();
        this.props.setIsShowingModifyForm(false);
    }

    deleteUser = (id: string) => {
        this.props.deleteUser(id);
        if (this.props.pageNumber !== 1 && this.props.usersList.length === 0) {
            this.props.goToPage(this.props.pageNumber - 1);
        }
    }

    unArchiveUser = (id: string) => {
        this.props.unArchiveUser(id);
        if (this.props.pageNumber !== 1 && this.props.usersList.length === 0) {
            this.props.goToPage(this.props.pageNumber - 1);
        }
    }

    handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        this.props.searchTable(e.currentTarget.value);
    }

    handleSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        this.props.changePageSize(Number(e.currentTarget.value));
    }

    handleSort = (column: string) => {
        const isNewColumn = this.props.userSort.column !== column;
        const sortOrder = !isNewColumn && this.props.userSort.order === 'ASC' ? 'DESC' : 'ASC';

        this.props.sort(column, sortOrder);
    }

    handleCSVForm = () => {
        if (this.props.isShowingCSVForm) {
            this.removeBlur();
            this.props.setIsShowingCSVForm(false);
        } else {
            this.addBlur();
            this.props.setIsShowingCSVForm(true);
        }
    }

    recomputeUserComputedFields = () => {
        this.props.setInfoMessage('Recomputing user data');

        setTimeout(() => {
            this.props.recomputeAllUsers();
            this.props.clearInfoMessage();
        }, 500);
    }

    render() {

        const userIds = this.props.usersList.map(user => user.id);

        let MAX_NO_OF_USERS = 10;

        if (this.props.organizationPlan === 'unlimited') {
            MAX_NO_OF_USERS = Infinity;
        }

        const nameField = this.props.usersData.customFields.byId[this.props.usersData.nameFieldId];
        const subTitleField = this.props.usersData.customFields.byId[this.props.usersData.subTitleFieldId];

        if (!nameField || !subTitleField) {
            return <div></div>;
        }

        const headings: Array<TableHeading> = [{
            name: 'Sl. no',
            isSortable: false,
            isSticky: true,
            width: 90,
        }, {
            name: nameField.name,
            isSortable: false,
            isSticky: true,
            width: 120,
        }, {
            name: 'Subtitle',
            isSortable: false,
            isSticky: false,
            width: 120,
        }, {
            name: 'Project',
            isSortable: false,
            width: 120,
        }, {
            name: 'Level',
            isSortable: false,
            width: 120,
        }, {
            name: 'Location',
            isSortable: false,
            width: 150,
        }, {
            name: 'Role',
            isSortable: false,
            width: 120,
        }, {
            name: 'Phone',
            isSortable: false,
            width: 130,
        }];

        this.props.customFieldsData.allFields.forEach(customFieldId => {

            if (this.props.usersData.nameFieldId === customFieldId || this.props.usersData.subTitleFieldId === customFieldId) {
                return;
            }

            const customField = this.props.customFieldsData.byId[customFieldId];
            const width = customField.type === FieldType.TEXT ? 200 : 150;

            if (customField.isInTable) {
                headings.push({
                    name: customField.name,
                    isSortable: false,
                    width: width,
                });
            }

        });

        if (this.props.userFilters.roles.length === 1) {
            const role = this.props.rolesData.byId[this.props.userFilters.roles[0]];

            role.customFields.forEach(customFieldId => {
                const customField = this.props.roleCustomFieldsData.byId[customFieldId];
                const width = customField.type === FieldType.TEXT ? 200 : 150;

                if (customField.isInTable) {
                    headings.push({
                        name: customField.name,
                        isSortable: false,
                        width: width,
                    });
                }
            });
        }

        const entries: Array<TableRow> = this.props.usersList.map<TableRow>((user, index) => {
            let userName = user.customFields[this.props.usersData.nameFieldId];

            userName = getReadableDataForCustomField(userName, this.props.customFieldsData.byId[this.props.usersData.nameFieldId], user.id, 'user');

            let isBetaTester;
            let isOnline;

            if (user.isBetaTester) {
                isBetaTester = <span className={styles.betaTag}> {translatePhrase("Tester")} </span>;
            };

            if (user.isOnline) {
                isOnline = <span className={styles.betaTag + ' ' + styles.onlineTag}> {translatePhrase("Online")} </span>;
            };

            let userNameElement = <div className={styles.userNameElement}>
                {userName} {isOnline} {isBetaTester}
            </div>

            let userSubTitle = user.customFields[this.props.usersData.subTitleFieldId];
            userSubTitle = getReadableDataForCustomField(userSubTitle, subTitleField, user.id, 'user');

            const validLocationIds = user.locations.filter(locationId => locationId in this.props.locationsData.byId);

            const locationChain = validLocationIds.map(locationId => {
                const parentLocationIds = getAncestorChainOfLocation(locationId);
                parentLocationIds.reverse();
                const locationChain = parentLocationIds
                    .filter(locationId => locationId in this.props.locationsData.byId)
                    .concat([locationId])
                    .map(locationId => this.props.locationsData.byId[locationId].name)
                    .join(' > ');

                return locationChain;
            }).join(', ')

            const locationName = validLocationIds.map(locationId => translatePhrase(this.props.locationsData.byId[locationId].name)).join(', ');
            const locationMarkup = <TableLocationList
                locationChain={locationChain}
                locationName={locationName} />

            const cells = [
                (this.props.pageNumber - 1) * this.props.pageSize + index + 1, // Sl. no
                userNameElement, // Name
                userSubTitle, // Details
                user.projects.length ? user.projects.filter(projectId => projectId in this.props.projectsData.byId).map(projectId => translatePhrase(this.props.projectsData.byId[projectId].name)).join(', ') : '-', // Project
                user.levels.length ? user.levels.filter(levelId => levelId in this.props.levelsData.byId).map(levelId => translatePhrase(this.props.levelsData.byId[levelId].name)).join(', ') : '-', // Level
                user.locations.length ? locationMarkup : '-', // Location
                user.roles.length ? user.roles.filter(roleId => roleId in this.props.rolesData.byId).map(roleId => translatePhrase(this.props.rolesData.byId[roleId].name)).join(', ') : '-', // Role
                user.phone.countryCode + ' ' + user.phone.number, // Phone,
            ];

            this.props.customFieldsData.allFields.forEach(customFieldId => {

                if (this.props.usersData.nameFieldId === customFieldId || this.props.usersData.subTitleFieldId === customFieldId) {
                    return;
                }

                const customField = this.props.customFieldsData.byId[customFieldId];

                if (!customField.isInTable) {
                    return;
                }

                let customFieldValue = user.customFields[customFieldId];

                let cellValue = getReadableDataForCustomField(customFieldValue, customField, user.id, 'user');

                if (customField.type === FieldType.FILE && cellValue.startsWith('http')) {
                    cells.push(<a className={styles.fileLink} target="_blank" rel="noreferrer noopener" href={cellValue + '?token=' + this.state.token}>Link</a>);
                } else {
                    cells.push(cellValue);
                }

            });

            if (this.props.userFilters.roles.length === 1) {
                const role = this.props.rolesData.byId[this.props.userFilters.roles[0]];
                role.customFields.forEach(customFieldId => {
                    const customField = this.props.roleCustomFieldsData.byId[customFieldId];

                    if (!customField.isInTable) {
                        return;
                    }

                    let customFieldValue = user.customFields[customFieldId];

                    let cellValue = getReadableDataForCustomField(customFieldValue, customField, user.id, 'role');

                    cells.push(cellValue);
                });
            }

            const userEditForm = <UserModify userId={user.id} submit={this.updateUser} cancel={this.hideUserForm} isReadOnly={this.props.isReadOnly} />;

            let isDeleteAllowed = true;

            if (user.archived && this.props.myId !== 'SuperUser') {
                isDeleteAllowed = false;
            } else if (this.props.myPermissions.general.Users !== Permissions.WRITE) {
                isDeleteAllowed = false;
            }

            return {
                id: user.id,
                entries: cells,
                editForm: userEditForm,
                isDeleted: user.archived,
                isDeleteAllowed: isDeleteAllowed,
                shareText: `Name: ${userName}\nDescription: ${userSubTitle}`,
            }
        });

        return (
            <div>
                {this.state.isShowingPerformanceDetailsLoader && <LoaderModal
                    loaderText={["Calculating user metrics"]}
                    isIndeterminate
                />}
                <TableWithMeta
                    entityType="User"
                    headings={headings}
                    entries={entries}

                    sortedColumn={this.props.userSort.column}
                    sortType={this.props.userSort.order}
                    isReadOnly={this.props.isReadOnly}
                    areFiltersExpanded={this.props.areFiltersExpanded}
                    tags={this.props.tags}

                    totalPages={this.props.totalPages}
                    totalNoOfEntries={this.props.totalNoOfUsers}
                    pageNumber={this.props.pageNumber}
                    searchTerm={this.props.searchTerm}
                    placeHolderForSearch="Search within the list below"
                    isAddAllowed={this.props.totalNoOfUsers < MAX_NO_OF_USERS}
                    isLimitReached={this.props.totalNoOfUsers >= MAX_NO_OF_USERS}
                    isShowingAddForm={this.props.isShowingAddForm}
                    isShowingModifyForm={this.props.isShowingModifyForm}
                    addForm={<UserModify submit={this.addUser} cancel={this.hideUserForm} />}

                    isShowingFilterForm={this.props.isShowingFilterForm}
                    showFilterForm={this.showFilterForm}
                    filterForm={
                        <UserFilter
                            tags={this.props.tags}
                            closeFilter={this.hideFilterForm} />}

                    onSort={this.handleSort}
                    onDelete={this.deleteUser}
                    onUnArchive={this.unArchiveUser}
                    showAddForm={this.showAddForm}
                    showModifyForm={this.showModifyForm}
                    reindexTable={this.props.isSuperUser ? this.recomputeUserComputedFields : undefined}
                    showPerformanceDetails={this.showPerformanceDetails}
                    showPerformanceDetailsForUser={this.showPerformanceDetailsForUser}
                    isShowingPerformanceDetails={this.props.isShowingUserMetrics}
                    showExportSyncMetaLog={this.showExportSyncMetaLog}

                    search={this.props.searchTable}
                    changePageSize={this.props.changePageSize}
                    goToPage={this.props.goToPage}
                    sort={this.props.sort}
                    handleCSVForm={this.handleCSVForm}
                    isShowingCSVForm={this.props.isShowingCSVForm}
                    isShowingUserSyncMetaLogExportForm={this.props.isShowingUserSyncMetaLogExportForm}

                    isAddHighlighted={this.props.selectedNudge === NudgeType.USERS_ADD_USER}
                    isFilterHighlighted={this.props.selectedNudge === NudgeType.USERS_FILTER}
                    isSearchHighlighted={this.props.selectedNudge === NudgeType.USERS_SEARCH}
                    isImportExportHighlighted={this.props.selectedNudge === NudgeType.USERS_IMPORT_EXPORT}
                    isShowMoreHighlighted={this.props.selectedNudge === NudgeType.USERS_LIST_SHOW_MORE}
                />
                {this.props.isShowingUserMetrics && <UserPerformanceDetails
                    userIds={this.state.userSelectedForPerformance ? [this.state.userSelectedForPerformance] : this.props.usersList.map(userData => userData.id)}
                    hidePerformanceDetails={this.hidePerformanceDetails}
                />}
                {this.props.isShowingCSVForm && <UserCSV closeCSV={this.handleCSVForm} isLimitReached={this.props.totalNoOfUsers >= MAX_NO_OF_USERS} />}

                {this.props.isShowingUserSyncMetaLogExportForm && <UserSyncLogExportCSV closeCSV={this.showExportSyncMetaLog} userIds={userIds} />}
            </div>
        );
    }
}

const UsersTable = connect(mapStateToProps, mapDispatchToProps)(ConnectedUsersTable);

export default UsersTable;