import React from 'react';
import styles from './Reports.module.scss';
import { Switch, Route, Redirect } from "react-router-dom";

import { ApplicationState } from '../../shared/store/types';

import { Permissions } from '../../shared/store/permissions/types';

import Configuration from './configuration/Configuration';
import List from './list/List';

import { ReactComponent as ReportsIcon } from '../../common/assets/report.svg';

import { connect } from 'react-redux';
import ReportFlowchart from './configuration/ReportFlowchart';
import Header from '../../widgets/header/Header';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canViewReportConfiguration =
      state.permissions.myPermissions.general.ReportsConfiguration === Permissions.WRITE ||
      state.permissions.myPermissions.general.ReportsConfiguration === Permissions.READ;

    return {
        canGoToConfiguration: canViewReportConfiguration,
        canGoToList: true,
        hasReports: state.reports.allEntries.length > 0,
        isPresentable: state.myData.isLoaded || state.myData.isPartiallyLoaded,
        isFlowchartExpanded: state.myData.isFlowchartExpanded
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;

const ConnectedReports: React.FC<Props> = (props) => {
    const { isPresentable, canGoToConfiguration, canGoToList, hasReports } = props;
    if (!isPresentable) {
        return <div></div>;
    }

    if (!(canGoToConfiguration || canGoToList)) {
        return <Redirect to="/dashboard" />;
    }


    const tabs = [
        {
            tabName: "List",
            link: "/reports/list",
            hasAccess: canGoToList,
        },
        {
            tabName: "Configuration",
            link: "/reports/configuration",
            hasAccess: canGoToConfiguration,
        }
    ];

    return (
        <section className={styles.FocusSpace} >

            <Header 
                pageName={"reports"}
                tabs={tabs}
                icon={<ReportsIcon />}
            />

            {isPresentable && <div className={styles.innerFocus}>
                <Switch>
                    <Route path="/reports/configuration" render={() => <Configuration />} />
                    <Route path="/reports/list" render={() => <List />} />
                    <Route path={"/reports/flowchart/:id/"} component={ReportFlowchart} />
                    <Route render={() => <Redirect to={canGoToList && (hasReports || !canGoToConfiguration) ? '/reports/list' : '/reports/configuration'} />} />
                </Switch>
            </div>}
        </section >
    );
};

const Reports = connect(mapStateToProps)(ConnectedReports);

export default Reports;