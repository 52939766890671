import React, { Component } from 'react';
import styles from './UserModify.module.scss';

import InputText from '../../../widgets/form/InputText';
import eyeIcon from '../../../assets/eye.svg';
import chevronIcon from '../../../assets/chevron-arrow-down.svg';
import { ReactComponent as CancelIcon } from '../../../common/assets/close.svg';
import { ReactComponent as PlusIcon } from '../../../assets/new-custom-icons/dashboard/plus.svg';
import { ReactComponent as CheckIcon } from '../../../assets/new-custom-icons/profile/check-mark.svg';

import { translatePhrase } from '../../../shared/helpers/translation';
import { FieldType } from '../../../shared/store/custom-fields/types';

import { ApplicationState } from '../../../shared/store/types';
import { IUpdateableUserData } from '../../../shared/store/users/types';


import { connect } from 'react-redux';
import uuid from 'uuid';
import axios from 'axios';
import { isUUID } from '../../../shared/helpers/utilities';
import { Dispatch } from 'redux';
import { setToastMessage } from '../../../shared/store/my-data/actions';
import CustomFieldInput from '../../../widgets/form/CustomField';
import ProfileModify from './ProfileModify';
import { getLocationsAtLevel } from '../../../shared/helpers/locations';
import LoaderModal from '../../../widgets/loader/LoaderModal';
import Button from '../../../widgets/button/CommonButton';
import { BASE_URL } from '../../../shared/store/url';
import DateInput from '../../../widgets/form/DateInput';

type OwnProps = {
    userId?: string,
    isReadOnly?: boolean,

    submit: (userData: IUpdateableUserData) => void,
    cancel: () => void,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    const allAllowedProjects = state.structure.projects.allEntries,
        allAllowedLevels = state.structure.levels.allEntries;

    let allAllowedLocations: Array<string> = Object.keys(state.structure.locations.byId);

    return {
        applicationState: state,
        user: ownProps.userId ? state.users.byId[ownProps.userId] : undefined,
        myId: state.myData.id,
        loggedInUser: isUUID(state.myData.id) ? state.users.byId[state.myData.id] : undefined,
        usersData: state.users,
        projectsData: state.structure.projects,
        levelsData: state.structure.levels,
        rolesData: state.structure.roles,
        locationsData: state.structure.locations,
        workflowsData: state.workflows,
        customFieldsData: state.users.customFields,
        customFieldOptionsData: state.users.customFieldOptions,
        roleCustomFieldsData: state.structure.roles.customFields,
        roleCustomFieldOptionsData: state.structure.roles.customFieldOptions,
        languagesData: state.internationalization.languages,
        languages: state.internationalization.languages.allEntries.map(languageId => {
            const language = state.internationalization.languages.byId[languageId];

            let label: string;

            if (language.translatedName) {
                label = `${language.name} (${language.translatedName})`;
            } else {
                label = language.name;
            }

            return {
                name: label,
                value: languageId,
            }
        }).concat([{ name: 'English', value: '0' }]),

        allowedData: {
            projects: allAllowedProjects,
            levels: allAllowedLevels,
            locations: allAllowedLocations,
        },
        plan: state.organization.plan,
        locallyCreatedUserCount: state.users.createdIds.size,
        defaultLanguageForOrg: state.organization.language,
        orgCode: state.organization.code,
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setToastMessage: (message: string) => dispatch(setToastMessage(message)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;


type OwnState = {
    userData: IUpdateableUserData,
    submitTimer: number | undefined,
    locationKey: number,
    errorMessage: string,
    isUpsertAllowed: boolean,
    upsertError: string,
    newProfiles: number,
    duplicatePhoneTimer: number | undefined,
};

class ConnectedUserModify extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        let userData: IUpdateableUserData;
        let upsertError = '';
        if (!props.user) {
            // This is a new user
            userData = {
                id: uuid.v4(),
                projects: props.loggedInUser && props.loggedInUser.projects.length === 1 ? props.loggedInUser.projects : [],
                levels: [],
                roles: [],
                locations: [],
                isAppUser: true,
                isOnline: false,
                isBetaTester: false,
                phone: {
                    countryCode: '+91',
                    number: '',
                },
                language: '',
                password: '',
                customFields: {}
            };

            if (this.props.plan !== 'unlimited') {
                upsertError = 'Checking if user limit is reached';
            }
        } else {
            userData = {
                id: props.user.id,
                projects: props.user.projects,
                levels: props.user.levels,
                roles: props.user.roles,
                locations: props.user.locations,
                isAppUser: props.user.isAppUser,
                isOnline: props.user.isOnline,
                isBetaTester: props.user.isBetaTester,
                phone: {
                    countryCode: props.user.phone.countryCode,
                    number: props.user.phone.number,
                },
                language: props.user.language,
                password: props.user.password,
                customFields: JSON.parse(JSON.stringify(props.user.customFields)),
            }
        }

        this.state = {
            userData: userData,
            submitTimer: undefined,
            locationKey: 0,
            errorMessage: '',
            isUpsertAllowed: this.props.plan !== 'unlimited' && !!props.user,
            upsertError,
            newProfiles: props.user ? 0 : 1,
            duplicatePhoneTimer: undefined,
        };
    }

    changeProjects = (projects: Array<string>) => {

        this.setState(prevState => {
            const remainingLevels = prevState.userData.levels.filter(levelId => {
                const level = this.props.levelsData.byId[levelId];
                return projects.includes(level.project);
            });

            const remainingRoles = prevState.userData.roles.filter(roleId => {
                const role = this.props.rolesData.byId[roleId];
                return remainingLevels.includes(role.level);
            });

            const locationsInLevels = remainingLevels.map(levelId => getLocationsAtLevel(levelId)).flat().map(location => location.id);
            const remainingLocations = prevState.userData.locations.filter(locationId => locationsInLevels.includes(locationId));

            let updatedIUpdateableUserData: IUpdateableUserData = {
                ...prevState.userData,
                projects: projects,
                levels: remainingLevels,
                roles: remainingRoles,
                locations: remainingLocations,
            };

            return {
                userData: updatedIUpdateableUserData
            }
        });
    }

    changeLevels = (levels: Array<string>) => {

        this.setState(prevState => {
            const remainingRoles = prevState.userData.roles.filter(roleId => {
                const role = this.props.rolesData.byId[roleId];
                return levels.includes(role.level);
            });

            const locationsInLevels = levels.map(levelId => getLocationsAtLevel(levelId)).flat().map(location => location.id);
            const remainingLocations = prevState.userData.locations.filter(locationId => locationsInLevels.includes(locationId));

            let updatedIUpdateableUserData: IUpdateableUserData = {
                ...prevState.userData,
                levels: levels,
                roles: remainingRoles,
                locations: remainingLocations,
            };

            return {
                userData: updatedIUpdateableUserData
            }
        });
    }

    changeRoles = (roles: Array<string>) => {

        this.setState(prevState => {
            let updatedIUpdateableUserData: IUpdateableUserData = {
                ...prevState.userData,
                roles,
            };

            return {
                userData: updatedIUpdateableUserData
            }
        });
    }

    changeLocations = (locations: Array<string>) => {

        this.setState(prevState => {
            let updatedIUpdateableUserData: IUpdateableUserData = {
                ...prevState.userData,
                locations: locations
            };

            return {
                userData: updatedIUpdateableUserData
            }
        });
    }

    checkAndWarnPhoneNumberCollision = (phoneCountryCode: string, phoneNumber: string) => {
        this.setState(prevState => {
            const anotherUserWithSamePhoneExists = this.props.usersData.allEntries
                .filter(userId => {
                    return userId !== prevState.userData.id && this.props.usersData.byId[userId] && !this.props.usersData.byId[userId].archived
                })
                .some(userId => {
                    const user = this.props.usersData.byId[userId];
                    return user.phone.countryCode === phoneCountryCode && user.phone.number === phoneNumber;
                });

            if (anotherUserWithSamePhoneExists) {

                if (prevState.duplicatePhoneTimer) {
                    window.clearTimeout(prevState.duplicatePhoneTimer);
                }

                const timeout = window.setTimeout(() => {
                    this.setState(newPrevState => {
                        if (newPrevState.errorMessage === 'Another user with this phone exists') {
                            return {
                                errorMessage: '',
                            }
                        }

                        return null;
                    });
                }, 4000);

                return {
                    errorMessage: 'Another user with this phone exists',
                    duplicatePhoneTimer: timeout,
                };
            } else if (prevState.errorMessage === 'Another user with this phone exists') {

                if (prevState.duplicatePhoneTimer) {
                    window.clearTimeout(prevState.duplicatePhoneTimer);
                }

                return {
                    errorMessage: '',
                    duplicatePhoneTimer: undefined,
                }
            } else {
                return null;
            }
        })
    }

    changePhoneCountryCode = (phoneCountryCode: string) => {
        let updatedIUpdateableUserData: IUpdateableUserData = {
            ...this.state.userData,
            phone: {
                ...this.state.userData.phone,
                countryCode: phoneCountryCode
            }
        };

        this.setState({
            userData: updatedIUpdateableUserData
        });

        this.checkAndWarnPhoneNumberCollision(phoneCountryCode, this.state.userData.phone.number);
    }

    changePhoneNumber = (phoneNumber: string) => {
        let updatedIUpdateableUserData: IUpdateableUserData = {
            ...this.state.userData,
            phone: {
                ...this.state.userData.phone,
                number: phoneNumber,
            }
        };

        this.setState({
            userData: updatedIUpdateableUserData
        });

        this.checkAndWarnPhoneNumberCollision(this.state.userData.phone.countryCode, phoneNumber);
    }

    changeLanguage = (language: string) => {
        let updatedIUpdateableUserData: IUpdateableUserData = {
            ...this.state.userData,
            language: language
        };

        this.setState({
            userData: updatedIUpdateableUserData
        });
    }

    changeIsOnline = (isOnline: boolean) => {
        let updatedIUpdateableUserData: IUpdateableUserData = {
            ...this.state.userData,
            isOnline,
        };

        this.setState({
            userData: updatedIUpdateableUserData
        });
    }

    changeIsBetaTester = (isBetaTester: boolean) => {
        let updatedIUpdateableUserData: IUpdateableUserData = {
            ...this.state.userData,
            isBetaTester,
        };

        this.setState({
            userData: updatedIUpdateableUserData
        });
    }

    changePassword = (password: string) => {
        let updatedIUpdateableUserData: IUpdateableUserData = {
            ...this.state.userData,
            password: password
        };

        this.setState({
            userData: updatedIUpdateableUserData
        });
    }

    showErrorMessage = (message: string) => {
        let that = this

        this.setState({
            errorMessage: message
        });

        window.setTimeout(() => {
            that.setState({
                errorMessage: ''
            });
        }, 3000);

    }

    preValidateIUpdateableUserData = () => {

        if (!this.state.userData.projects || this.state.userData.projects.length === 0) {
            return;
        }

        if (!this.state.userData.levels || this.state.userData.levels.length === 0) {
            return;
        }

        if (!this.state.userData.roles || this.state.userData.roles.length === 0) {
            return;
        }

        if (this.state.userData.locations.length === 0) {
            return;
        }

        if (!this.state.userData.phone.number) {
            return;
        }

        const anotherUserWithSamePhoneExists = this.props.usersData.allEntries
            .filter(userId => {
                return userId !== this.state.userData.id && this.props.usersData.byId[userId] && !this.props.usersData.byId[userId].archived
            })
            .some(userId => {
                const user = this.props.usersData.byId[userId];
                return user.phone.countryCode === this.state.userData.phone.countryCode && user.phone.number === this.state.userData.phone.number;
            });

        if (anotherUserWithSamePhoneExists) {
            return;
        }

        if (!this.state.userData.password && !this.props.userId) {
            return;
        }

        for (let i = 0; i < this.props.customFieldsData.allFields.length; i += 1) {
            const fieldId = this.props.customFieldsData.allFields[i];
            const field = this.props.customFieldsData.byId[fieldId];

            if (field.isComputed) {
                continue;
            }

            if (field.type === FieldType.MULTI_SELECT) {
                if (this.state.userData.customFields[fieldId] && !Array.isArray(this.state.userData.customFields[fieldId])) {
                    throw new Error('The value of a multi-select field must be an array');
                }
            } else {
                if (this.state.userData.customFields[fieldId] && Array.isArray(this.state.userData.customFields[fieldId])) {
                    throw new Error('The value of a non multi-select field must not be an array');
                }
            }

            if (field.type === FieldType.PHONE) {
                const phoneNumber = this.state.userData.customFields[fieldId];

                if (!!phoneNumber) {
                    if (typeof phoneNumber !== 'string') {
                        throw new Error('A phone number must be a string');
                    }

                    if (phoneNumber.trim().split(' ').length !== 2) {
                        return;
                    }
                }
            }

            if (field.type === FieldType.LOCATION) {
                const location = this.state.userData.customFields[fieldId];

                if (!!location) {
                    if (typeof location !== 'string') {
                        throw new Error('A phone number must be a string');
                    }

                    if (location.trim().split(' ').length !== 2) {
                        return;
                    }
                }
            }
        };

        return true
    }

    validateIUpdateableUserData = () => {

        if (!this.state.userData.projects || this.state.userData.projects.length === 0) {
            this.showErrorMessage('Select a project');
            return;
        }

        if (!this.state.userData.levels || this.state.userData.levels.length === 0) {
            this.showErrorMessage('Select a level');
            return;
        }

        if (!this.state.userData.roles || this.state.userData.roles.length === 0) {
            this.showErrorMessage('Select a role');
            return;
        }

        if (this.props.user) {
            const userId = this.props.user.id;
            const removedProjectIds = this.props.user.projects.filter(projectId => !this.state.userData.projects.includes(projectId));

            const workflowTypeIdsInRemovedProjects = this.props.workflowsData.types.allEntries.filter(workflowTypeId => {
                const workflowType = this.props.workflowsData.types.byId[workflowTypeId];

                return removedProjectIds.includes(workflowType.project);
            });

            const workflowTypeIds = new Set(workflowTypeIdsInRemovedProjects);

            const hasActiveWorkflowInRemovedProject = this.props.workflowsData.activeWorkflowEntries.some(workflowId => {
                const workflow = this.props.workflowsData.byId[workflowId];

                return workflow && workflow.user === userId && workflowTypeIds.has(workflow.type);
            });

            if (hasActiveWorkflowInRemovedProject) {
                this.showErrorMessage('Cannot remove role when there are active workflows. Please complete or transfer the workflows');
                return;
            }
        }

        for (const levelId of this.state.userData.levels) {
            const level = this.props.levelsData.byId[levelId];

            const locationIds = getLocationsAtLevel(levelId).map(location => location.id);

            const hasLocationAtLevel = this.state.userData.locations.some(locationId => locationIds.includes(locationId));

            if (!hasLocationAtLevel) {
                this.showErrorMessage(`Select at least one location at ${level.name}`);
                return;
            }
        }

        if (!this.state.userData.phone.number) {
            this.showErrorMessage('Enter a phone number');
            return;
        }

        const anotherUserWithSamePhoneExists = this.props.usersData.allEntries
            .filter(userId => {
                return userId !== this.state.userData.id && this.props.usersData.byId[userId] && !this.props.usersData.byId[userId].archived
            })
            .some(userId => {
                const user = this.props.usersData.byId[userId];
                return user.phone.countryCode === this.state.userData.phone.countryCode && user.phone.number === this.state.userData.phone.number;
            });

        if (anotherUserWithSamePhoneExists) {
            this.showErrorMessage('This phone number is already being used by another user');
            return;
        }

        if (!this.state.userData.password && !this.props.userId) {
            this.showErrorMessage('Enter a password');
            return;
        }

        for (let i = 0; i < this.props.customFieldsData.allFields.length; i += 1) {
            const fieldId = this.props.customFieldsData.allFields[i];
            const field = this.props.customFieldsData.byId[fieldId];

            if (field.isComputed) {
                continue;
            }

            if (field.type === FieldType.MULTI_SELECT) {
                if (this.state.userData.customFields[fieldId] && !Array.isArray(this.state.userData.customFields[fieldId])) {
                    throw new Error('The value of a multi-select field must be an array');
                }
            } else {
                if (this.state.userData.customFields[fieldId] && Array.isArray(this.state.userData.customFields[fieldId])) {
                    throw new Error('The value of a non multi-select field must not be an array');
                }
            }

            if (field.type === FieldType.PHONE) {
                const phoneNumber = this.state.userData.customFields[fieldId];

                if (!!phoneNumber) {
                    if (typeof phoneNumber !== 'string') {
                        throw new Error('A phone number must be a string');
                    }

                    if (phoneNumber.trim().split(' ').length !== 2) {
                        this.showErrorMessage('Enter a valid ' + field.name);
                        return;
                    }
                }
            }

            if (field.type === FieldType.LOCATION) {
                const location = this.state.userData.customFields[fieldId];

                if (!!location) {
                    if (typeof location !== 'string') {
                        throw new Error('A phone number must be a string');
                    }

                    if (location.trim().split(' ').length !== 2) {
                        this.showErrorMessage('Enter a valid ' + field.name);
                        return;
                    }
                }
            }
        };

        return true
    }

    submitUserForm = async () => {
        if (this.validateIUpdateableUserData()) {
            this.markForSubmit();

        }
    }

    markForSubmit = () => {
        let that = this;

        const timeout = window.setTimeout(function () {
            that.props.submit(that.state.userData);
        }, 1000);

        this.setState({
            submitTimer: timeout
        });
    }

    changeCustomField = (fieldId: string, value: string | string[] | undefined | boolean) => {
        const field = this.props.customFieldsData.byId[fieldId];

        let updatedIUpdateableUserData: IUpdateableUserData = {
            ...this.state.userData,
            customFields: {
                ...this.state.userData.customFields,
                [fieldId]: field.type === FieldType.NUMBER ? Number(value) : value,
            }
        };

        this.setState({
            userData: updatedIUpdateableUserData
        });
    }

    changeRoleCustomField = (fieldId: string, value: string | string[] | undefined | boolean) => {
        const field = this.props.roleCustomFieldsData.byId[fieldId];

        let updatedIUpdateableUserData: IUpdateableUserData = {
            ...this.state.userData,
            customFields: {
                ...this.state.userData.customFields,
                [fieldId]: field.type === FieldType.NUMBER ? Number(value) : value,
            }
        };

        this.setState({
            userData: updatedIUpdateableUserData
        });
    }

    fetchUserCount = async () => {

        if (this.state.isUpsertAllowed) {
            return;
        }

        if (!window.navigator.onLine) {
            this.setState({
                upsertError: translatePhrase('Users can only be added when online'),
            });
            return;
        }

        const serverUrl = BASE_URL + '/user-count/';

        let responseData: any

        try {
            responseData = await axios.get(serverUrl, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            });
        } catch (e) {
            this.setState({
                upsertError: translatePhrase('Error while fetching user count. Please try again'),
            });
        }

        const serverUserCount = responseData.data as number;  // Coerce the data to be in the expected format
        const totalUserCount = serverUserCount + this.props.locallyCreatedUserCount;

        if (isNaN(Number(totalUserCount))) {
            this.setState({
                upsertError: translatePhrase('Incorrect data format'),
            });
        } else if (this.props.plan !== 'unlimited' && totalUserCount >= 10) {
            this.setState({
                upsertError: translatePhrase('You have reached the maximum limit'),
            });
        } else {
            this.setState({
                isUpsertAllowed: true,
                upsertError: '',
            });
        }

    }

    componentDidMount() {
        this.fetchUserCount();
    }

    addEmptyProfile = () => {
        this.setState(prevState => {
            return {
                newProfiles: prevState.newProfiles + 1,
            };
        })
    }

    removeEmptyProfile = () => {
        this.setState(prevState => {
            return {
                newProfiles: prevState.newProfiles - 1,
            };
        })
    }

    componentWillUnmount(): void {
        this.props.cancel();
    }

    render() {
        const nameFieldId = this.props.usersData.nameFieldId;
        const nameFieldMarkup = <CustomFieldInput
            entityType="user"
            entity={this.state.userData}
            fieldId={nameFieldId}
            customFieldsData={this.props.customFieldsData}
            customFieldOptionsData={this.props.customFieldOptionsData}
            changeField={this.changeCustomField}
        />

        const subtitleFieldId = this.props.usersData.subTitleFieldId;
        const subtitleFieldMarkup = <CustomFieldInput
            entityType="user"
            entity={this.state.userData}
            fieldId={subtitleFieldId}
            customFieldsData={this.props.customFieldsData}
            customFieldOptionsData={this.props.customFieldOptionsData}
            changeField={this.changeCustomField}
        />

        const locationFieldId = this.props.usersData.locationFieldId;
        const locationFieldMarkup = <CustomFieldInput
            entityType="user"
            entity={this.state.userData}
            fieldId={locationFieldId}
            customFieldsData={this.props.customFieldsData}
            customFieldOptionsData={this.props.customFieldOptionsData}
            changeField={this.changeCustomField}
        />

        let defaultLanguage = '0';

        if (this.props.user) {
            defaultLanguage = this.props.user.language;
        } else if (this.props.defaultLanguageForOrg) {
            defaultLanguage = this.props.defaultLanguageForOrg;
        }

        return (
            <section className={styles.commonModalHolder}>
                <section data-user-id={this.props.userId ? this.props.userId : ''} className={this.props.isReadOnly ? styles.viewOnlyUser : styles.addOrModifyListCard}>

                    {this.state.errorMessage && <LoaderModal loaderText={[translatePhrase('Error') + ' - ' + translatePhrase(this.state.errorMessage)]} isError={true} />}

                    <header>
                        <h2>{this.props.user ? this.props.isReadOnly ? translatePhrase('View User') : translatePhrase('Edit User') : translatePhrase('Add User')}</h2>
                        <Button size={'small'} padding={'0px 10px'} icon={<CancelIcon />} onClick={this.props.cancel} text={this.props.isReadOnly ? translatePhrase('Close') : translatePhrase('Cancel')} isRounded={true} />
                    </header>

                    <div className={styles.container}>

                        {this.state.isUpsertAllowed && !this.state.upsertError && <div className={styles.allInputsHolder}>

                            {nameFieldMarkup}

                            {subtitleFieldMarkup}

                            {locationFieldMarkup}

                            <div className={styles.inputSegment + ' ' + styles.phoneInput}>
                                <InputText icon={chevronIcon} placeholder={'Country Code'} default={this.props.user ? this.props.user.phone.countryCode : '+91'} options={['+91', '+1']} isSearchDisabled onChange={this.changePhoneCountryCode} />
                            </div>

                            <div className={styles.inputSegment}>
                                <InputText placeholder="Phone" type="tel" default={this.props.user ? this.props.user.phone.number : ''} onChange={this.changePhoneNumber} />
                            </div>

                            <div className={styles.inputSegment}>
                                <InputText placeholder={this.state.userData.password ? 'Password' : 'Password (hidden)'} icon={eyeIcon} type="password" onChange={this.changePassword} />
                            </div>

                            <div className={styles.inputSegment}>
                                <InputText placeholder="Language" icon={chevronIcon} default={defaultLanguage} onChange={this.changeLanguage} options={this.props.languages} isAutoFocus={true} />
                            </div>

                            <div className={styles.inputSegment}>
                                <InputText isBooleanField={true} placeholder="Is Online?" onChange={value => this.changeIsOnline(value === 'Yes')}
                                    defaultBooleanValue={this.props.user ? this.props.user.isOnline : false} />
                            </div>

                            <div className={styles.inputSegment}>
                                <InputText isBooleanField={true} placeholder="Is Tester?" onChange={value => this.changeIsBetaTester(value === 'Yes')}
                                    defaultBooleanValue={this.props.user ? this.props.user.isBetaTester : false} />
                            </div>

                            {this.props.user && <div className={styles.inputSegment}>
                                <DateInput isDisabled placeholder={translatePhrase('Created time')} default={new Date(this.props.user.createdTime)} onChange={() => { }} />
                            </div>}
                            {this.props.user && <div className={styles.inputSegment}>
                                <DateInput isDisabled placeholder={translatePhrase('Last updated time')} default={new Date(this.props.user.lastUpdatedTime)} onChange={() => { }} />
                            </div>}

                        </div>}

                        {this.state.isUpsertAllowed && !this.state.upsertError && this.state.userData.roles
                            .filter(roleId => roleId in this.props.rolesData.byId)
                            .map((roleId, index) => {
                                return <ProfileModify
                                    key={roleId}
                                    index={index}
                                    roleId={roleId}
                                    userData={this.state.userData}

                                    changeProjects={this.changeProjects}
                                    changeLevels={this.changeLevels}
                                    changeRoles={this.changeRoles}
                                    changeLocations={this.changeLocations}
                                    changeCustomField={this.changeRoleCustomField}
                                />
                            })}
                        {this.state.isUpsertAllowed && !this.state.upsertError && (new Array(this.state.newProfiles)).fill(0).map((value, index) => {
                            return <ProfileModify
                                index={this.state.userData.roles.length + index}
                                userData={this.state.userData}

                                changeProjects={this.changeProjects}
                                changeLevels={this.changeLevels}
                                changeRoles={this.changeRoles}
                                changeLocations={this.changeLocations}
                                changeCustomField={this.changeRoleCustomField}
                                removeEmptyProfile={this.removeEmptyProfile}
                            />
                        })}
                        {this.state.newProfiles === 0 && <section className={styles.addRole} onClick={this.addEmptyProfile}> <span> + </span> Add Role</section>}

                    </div>

                    {!this.props.isReadOnly && this.state.isUpsertAllowed && !this.state.upsertError && <div className={styles.buttonsHolder}>
                        {this.state.submitTimer ?
                            <Button color={'contrast'} isRounded={true} padding={'0px 10px'} icon={<CheckIcon />} text={this.props.user ? translatePhrase('Update User Requested') : translatePhrase('Add User Requested')} /> :
                            <Button isDisabled={!this.preValidateIUpdateableUserData()} color={'contrast'} isRounded={true} padding={'0px 10px'} icon={this.props.user ? <CheckIcon /> : <PlusIcon />} text={this.props.user ? translatePhrase('Update User') : translatePhrase('Add User')} onClick={this.submitUserForm} />}
                    </div>}

                </section>
            </section>
        );
    }
}

const UserModify = connect(mapStateToProps, mapDispatchToProps)(ConnectedUserModify);

export default UserModify;