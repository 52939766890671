import React, { Component, MouseEvent } from 'react';
import styles from './step-piece/StepPiece.module.scss';
import Input from '../Input';
import draftToHtml from 'draftjs-to-html';
import ReactHtmlParser from 'react-html-parser';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem, { Option } from '../drop-down/ListItem';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setVariableForShow, setWidgetTitleForShow, setEntityType, setCustomFieldsForShow, setRichTextForShow, showRichTextEditor, setStartingDisplayForShow, setXAxisForShow, setYAxisForShow, setYAxisAggregationForShow, setHiddenPieceForShow } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import { booleanPieceSlotTarget, valuePieceSlotTarget } from './utilities';
import { FieldType } from '../../../shared/store/custom-fields/types';
import { VariableType } from '../../../shared/store/flowchart/variables/types';
import { FlowchartContext } from '../../../contexts/flowchart-context';
import DropDownSearchBox from '../drop-down/DropDownSearchBox';
import { getFilteredOptionsBySearch } from '../drop-down/DropDownSearchBox';

const TEXT_STYLE_OPTIONS: Option[] = [{ name: "Normal", value: "Normal" }, { name: "Rich Text", value: "Rich Text" }];
export type ShowPieceProps = {
    selectedType?: 'User' | 'Member' | 'Group' | 'Workflow' | 'Data fragment' | 'Text' | 'Table',
    variableType?: VariableType,
    selectedFields?: Array<string>,
    selectedEntityType?: string,
    widgetTitle?: string,
    startingDisplayType?: 'table' | 'bar' | 'line' | 'donut',
    xAxis?: string,
    yAxis?: string,
    yAxisAggregation?: 'sum' | 'average',
    nextPiece?: JSX.Element,
    variablePiece?: JSX.Element,
    textToShow?: string,
    isRichText?: boolean,

    pieceName: string,

    isHiddenPiece?: JSX.Element,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,

        rolesData: state.structure.roles,
        userData: state.users,
        memberTypes: state.members.types,
        groupTypes: state.groups.types,
        workflowTypes: state.workflows.types,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string | undefined) => dispatch(setTargetPiece(pieceId)),
        setVariableForShow: (pieceId: string, value: string) => dispatch(setVariableForShow(pieceId, value)),
        setHiddenPieceForShow: (pieceId: string, value: string) => dispatch(setHiddenPieceForShow(pieceId, value)),
        setWidgetTitleForShow: (pieceId: string, value: string) => dispatch(setWidgetTitleForShow(pieceId, value)),
        setEntityTypeForShow: (pieceId: string, value: string) => dispatch(setEntityType(pieceId, value)),
        setCustomFields: (pieceId: string, value: Array<string>) => dispatch(setCustomFieldsForShow(pieceId, value)),
        setStaringDisplayForShow: (pieceId: string, value: 'table' | 'bar' | 'line' | 'donut') => dispatch(setStartingDisplayForShow(pieceId, value)),
        setXAxisForShow: (pieceId: string, value: string) => dispatch(setXAxisForShow(pieceId, value)),
        setYAxisForShow: (pieceId: string, value: string | undefined) => dispatch(setYAxisForShow(pieceId, value)),
        setYAxisAggregationForShow: (pieceId: string, value: 'sum' | 'average' | undefined) => dispatch(setYAxisAggregationForShow(pieceId, value)),
        setRichText: (pieceId: string, value: boolean) => dispatch(setRichTextForShow(pieceId, value)),
        showRichTextEditor: () => dispatch(showRichTextEditor()),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = ShowPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type OwnState = {
    isHoveringOverVariablePiece: boolean,
    textStylesSearchTerm: string,
    isHoveringOverHiddenPiece: boolean,
}

class ConnectedShowPieceEssentials extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            isHoveringOverVariablePiece: false,
            textStylesSearchTerm: "",
            isHoveringOverHiddenPiece: false
        };
    }

    updateSelectedOptions = (field: string) => {
        let selectedFields = this.props.selectedFields ? this.props.selectedFields.slice(0) : [];

        if (selectedFields.includes(field)) {
            selectedFields = selectedFields.filter(selectedOption => field !== selectedOption);
        } else {
            selectedFields.push(field);
        }

        this.props.setCustomFields(this.props.pieceId, selectedFields);
    }

    updateTextStyle = (style: string) => {
        this.props.setRichText(this.props.pieceId, style === 'Rich Text');
        this.props.setVariableForShow(this.props.pieceId, '');

        if (style === 'Rich Text') {
            this.props.showRichTextEditor();
        }
    }

    updateDisplayType = (type: string) => {
        let displayType: 'table' | 'bar' | 'line' | 'donut' = 'table';

        switch (type) {
            case 'table':
                displayType = 'table';
                break;
            case 'bar':
                displayType = 'bar';
                break;
            case 'line':
                displayType = 'line';
                break;
            case 'donut':
                displayType = 'donut';
                break;
            default:
                displayType = 'table';
        }

        this.props.setStaringDisplayForShow(this.props.pieceId, displayType);
    }

    updateXAxisForShow = (value: string) => {
        this.props.setXAxisForShow(this.props.pieceId, value);
    }

    updateYAxisForShow = (value: string) => {
        this.props.setYAxisForShow(this.props.pieceId, value ? value : undefined);
    }

    updateYAxisAggregationForShow = (value: string) => {
        let aggregation: 'sum' | 'average' | undefined;

        switch (value) {
            case 'sum':
                aggregation = 'sum';
                break;
            case 'average':
                aggregation = 'average';
                break;
            default:
                aggregation = undefined;
        }

        this.props.setYAxisAggregationForShow(this.props.pieceId, aggregation);
    }

    handleHoverOverVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: false,
        });
    };

    handleShowValueUpdate = (value: string) => {
        this.props.setVariableForShow(this.props.pieceId, value);
    }

    handleShowWidgetTitleUpdate = (value: string) => {
        this.props.setWidgetTitleForShow(this.props.pieceId, value);
    }

    handleEditorClick = (e: MouseEvent) => {
        e.stopPropagation();
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && (this.state.isHoveringOverVariablePiece || this.state.isHoveringOverHiddenPiece)) {

            if (this.state.isHoveringOverVariablePiece) {
                this.props.setVariableForShow(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverHiddenPiece) {
                this.props.setHiddenPieceForShow(this.props.pieceId, this.props.lastDraggedPiece.id)
            }
            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverVariablePiece: false,
                isHoveringOverHiddenPiece: false
            });
        }
    }

    getShowMarkupForRichText = (value: string) => {
        let stringifiedHtml: string;
        try {
            stringifiedHtml = draftToHtml(JSON.parse(value));
        } catch (e) {
            stringifiedHtml = '<p>Incorrect HTML</p>';
        }

        return ReactHtmlParser(stringifiedHtml);
    }

    searchForTextStyles = (searchTerm: string) => {
        this.setState({ textStylesSearchTerm: searchTerm });
    };

    handleHoverOverHiddenPiece = () => {
        this.setState({
            isHoveringOverHiddenPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        booleanPieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfHiddenPiece = () => {
        this.setState({
            isHoveringOverHiddenPiece: false,
        });
    };

    render() {
        return <FlowchartContext.Consumer>
            {
                (flowchartContext) => {
                    const entityTypeSelectCallback = this.props.setEntityTypeForShow.bind(this, this.props.pieceId);

                    let entityTypeSelectBox: JSX.Element | undefined;
                    let richTextSelectBox: JSX.Element | undefined;
                    let customFieldsSelectBox: JSX.Element | undefined;
                    let xAxisSelectBox: JSX.Element | undefined;
                    let yAxisSelectBox: JSX.Element | undefined;
                    let entityType: string | undefined;

                    const chartTypeOptions = [{
                        name: 'Table',
                        value: 'table',
                    }, {
                        name: 'Bar',
                        value: 'bar',
                    }, {
                        name: 'Line',
                        value: 'line',
                    }, {
                        name: 'Donut',
                        value: 'donut',
                    }];

                    const chartTypeAggregations = [{
                        name: 'Count (Default)',
                        value: '',
                    }, {
                        name: 'Sum',
                        value: 'sum',
                    }, {
                        name: 'Average',
                        value: 'average',
                    }];

                    let startingDisplayTypeSelectBox = <SelectBox theme="aqua" selectionPromptText={this.props.startingDisplayType ? this.props.startingDisplayType[0].toUpperCase() + this.props.startingDisplayType.substring(1) : 'Select type'}>
                        <DropDownList theme="aqua" dismissAfterSelection={true}>
                            {chartTypeOptions.map(chartTypeOption => <ListItem theme="aqua" name={chartTypeOption.name} key={chartTypeOption.value} value={chartTypeOption.value} onClick={this.updateDisplayType} />)}
                        </DropDownList>
                    </SelectBox>;

                    let aggregationSelectBox = <SelectBox theme="aqua" selectionPromptText={this.props.yAxisAggregation ? this.props.yAxisAggregation[0].toUpperCase() + this.props.yAxisAggregation.substring(1) : 'Count (Default)'}>
                        <DropDownList theme="aqua" dismissAfterSelection={true}>
                            {chartTypeAggregations.map(chartTypeOption => <ListItem theme="aqua" name={chartTypeOption.name} key={chartTypeOption.value} value={chartTypeOption.value} onClick={this.updateYAxisAggregationForShow} />)}
                        </DropDownList>
                    </SelectBox>;

                    let xAxisText = undefined;
                    let yAxisText = undefined;

                    switch (this.props.selectedType) {

                        case 'User':
                            const customFieldsText = this.props.selectedFields && this.props.selectedFields.length > 0 ? this.props.selectedFields
                                .filter(fieldId => {
                                    return this.props.userData.customFields.byId.hasOwnProperty(fieldId) || this.props.rolesData.customFields.byId.hasOwnProperty(fieldId)
                                })
                                .map(fieldId => {
                                    const customField = this.props.userData.customFields.byId.hasOwnProperty(fieldId) ? this.props.userData.customFields.byId[fieldId].name : this.props.rolesData.customFields.byId[fieldId].name;
                                    return customField;
                                }).join(', ') : undefined;

                            if (this.props.xAxis) {
                                if (this.props.xAxis in this.props.userData.customFields.byId) {
                                    xAxisText = this.props.userData.customFields.byId[this.props.xAxis].name;
                                } else if (this.props.xAxis in this.props.rolesData.customFields.byId) {
                                    xAxisText = this.props.rolesData.customFields.byId[this.props.xAxis].name;
                                }
                            }

                            if (this.props.yAxis) {
                                if (this.props.yAxis in this.props.userData.customFields.byId) {
                                    yAxisText = this.props.userData.customFields.byId[this.props.yAxis].name;
                                } else if (this.props.yAxis in this.props.rolesData.customFields.byId) {
                                    yAxisText = this.props.rolesData.customFields.byId[this.props.yAxis].name;
                                }
                            }

                            entityType = this.props.selectedEntityType;

                            customFieldsSelectBox = <SelectBox theme="aqua" selectionPromptText={customFieldsText ? customFieldsText : 'Select fields'}>
                                <DropDownList theme="aqua" dismissAfterSelection={true}>
                                    {this.props.userData.customFields.allFields.map(userFieldId => <ListItem theme="aqua" name={this.props.userData.customFields.byId[userFieldId].name} key={userFieldId} value={userFieldId} onClick={this.updateSelectedOptions} />)}
                                    {entityType ? this.props.rolesData.byId[entityType].customFields.map(roleFieldId => <ListItem theme="aqua" name={this.props.rolesData.customFields.byId[roleFieldId].name} key={roleFieldId} value={roleFieldId} onClick={this.updateSelectedOptions} />) : undefined}
                                </DropDownList>
                            </SelectBox>;

                            xAxisSelectBox = <SelectBox theme="aqua" selectionPromptText={xAxisText ? xAxisText : 'Select x axis'}>
                                <DropDownList theme="aqua" dismissAfterSelection={true}>
                                    {this.props.userData.customFields.allFields.map(userFieldId => <ListItem theme="aqua" name={this.props.userData.customFields.byId[userFieldId].name} key={userFieldId} value={userFieldId} onClick={this.updateXAxisForShow} />)}
                                    {entityType ? this.props.rolesData.byId[entityType].customFields.map(roleFieldId => <ListItem theme="aqua" name={this.props.rolesData.customFields.byId[roleFieldId].name} key={roleFieldId} value={roleFieldId} onClick={this.updateXAxisForShow} />) : undefined}
                                </DropDownList>
                            </SelectBox>;

                            yAxisSelectBox = <SelectBox theme="aqua" selectionPromptText={yAxisText ? yAxisText : 'Select y axis'}>
                                <DropDownList theme="aqua" dismissAfterSelection={true}>
                                    <ListItem theme="aqua" name={'Count (Default)'} key={'0'} value={''} onClick={this.updateYAxisForShow} />

                                    {this.props.userData.customFields.allFields
                                        .filter(userFieldId => this.props.userData.customFields.byId[userFieldId].type === FieldType.NUMBER)
                                        .map(userFieldId => <ListItem theme="aqua" name={this.props.userData.customFields.byId[userFieldId].name} key={userFieldId} value={userFieldId} onClick={this.updateXAxisForShow} />)}

                                    {entityType ? this.props.rolesData.byId[entityType].customFields
                                        .filter(userFieldId => this.props.rolesData.customFields.byId[userFieldId].type === FieldType.NUMBER)
                                        .map(roleFieldId => <ListItem theme="aqua" name={this.props.rolesData.customFields.byId[roleFieldId].name} key={roleFieldId} value={roleFieldId} onClick={this.updateYAxisForShow} />) : undefined}
                                </DropDownList>
                            </SelectBox>;

                            entityType = this.props.selectedEntityType;

                            break;

                        case 'Member':

                            let allowedMemberTypeIds = this.props.memberTypes.allEntries;

                            if (flowchartContext.projects.length > 0) {
                                allowedMemberTypeIds = allowedMemberTypeIds.filter(memberTypeId => {
                                    const memberType = this.props.memberTypes.byId[memberTypeId];
                                    return flowchartContext.projects.includes(memberType.project);
                                });
                            }

                            entityTypeSelectBox = <SelectBox theme="aqua" selectionPromptText={this.props.selectedEntityType && this.props.memberTypes.byId.hasOwnProperty(this.props.selectedEntityType) ? this.props.memberTypes.byId[this.props.selectedEntityType].name : undefined}>
                                <DropDownList theme="aqua" dismissAfterSelection={true}>
                                    {allowedMemberTypeIds.map(memberTypeId => <ListItem theme="aqua" name={this.props.memberTypes.byId[memberTypeId].name} key={memberTypeId} value={memberTypeId} onClick={entityTypeSelectCallback} />)}
                                </DropDownList>
                            </SelectBox>;

                            if (this.props.selectedEntityType && this.props.memberTypes.byId.hasOwnProperty(this.props.selectedEntityType)) {
                                entityType = this.props.selectedEntityType;
                            }

                            if (this.props.xAxis) {
                                if (this.props.xAxis in this.props.memberTypes.customFields.byId) {
                                    xAxisText = this.props.memberTypes.customFields.byId[this.props.xAxis].name;
                                }
                            }

                            if (this.props.yAxis) {
                                if (this.props.yAxis in this.props.memberTypes.customFields.byId) {
                                    yAxisText = this.props.memberTypes.customFields.byId[this.props.yAxis].name;
                                }
                            }

                            if (entityType) {
                                const customFieldsText = this.props.selectedFields && this.props.selectedFields.length > 0 ? this.props.selectedFields
                                    .filter(fieldId => {
                                        return this.props.memberTypes.customFields.byId.hasOwnProperty(fieldId)
                                    })
                                    .map(fieldId => this.props.memberTypes.customFields.byId[fieldId].name).join(', ') : undefined;

                                customFieldsSelectBox = <SelectBox theme="aqua" selectionPromptText={customFieldsText ? customFieldsText : 'Select fields'}>
                                    <DropDownList theme="aqua" dismissAfterSelection={true}>
                                        {this.props.memberTypes.byId[entityType].customFields.map(memberFieldId => <ListItem theme="aqua" name={this.props.memberTypes.customFields.byId[memberFieldId].name} key={memberFieldId} value={memberFieldId} onClick={this.updateSelectedOptions} />)}
                                    </DropDownList>
                                </SelectBox>;

                                xAxisSelectBox = <SelectBox theme="aqua" selectionPromptText={xAxisText ? xAxisText : 'Select x axis'}>
                                    <DropDownList theme="aqua" dismissAfterSelection={true}>
                                        {this.props.memberTypes.byId[entityType].customFields.map(memberFieldId => <ListItem theme="aqua" name={this.props.memberTypes.customFields.byId[memberFieldId].name} key={memberFieldId} value={memberFieldId} onClick={this.updateXAxisForShow} />)}
                                    </DropDownList>
                                </SelectBox>;

                                yAxisSelectBox = <SelectBox theme="aqua" selectionPromptText={yAxisText ? yAxisText : 'Select y axis'}>
                                    <DropDownList theme="aqua" dismissAfterSelection={true}>
                                        {this.props.memberTypes.byId[entityType].customFields
                                            .filter(memberFieldId => this.props.memberTypes.customFields.byId[memberFieldId].type === FieldType.NUMBER)
                                            .map(memberFieldId => <ListItem theme="aqua" name={this.props.memberTypes.customFields.byId[memberFieldId].name} key={memberFieldId} value={memberFieldId} onClick={this.updateYAxisForShow} />)}
                                    </DropDownList>
                                </SelectBox>;
                            }

                            break;

                        case 'Group':

                            let allowedGroupTypeIds = this.props.groupTypes.allEntries;

                            if (flowchartContext.projects.length > 0) {
                                allowedGroupTypeIds = allowedGroupTypeIds.filter(groupTypeId => {
                                    const groupType = this.props.groupTypes.byId[groupTypeId];
                                    return flowchartContext.projects.includes(groupType.project);
                                });
                            }

                            entityTypeSelectBox = <SelectBox theme="aqua" selectionPromptText={this.props.selectedEntityType && this.props.groupTypes.byId.hasOwnProperty(this.props.selectedEntityType) ? this.props.groupTypes.byId[this.props.selectedEntityType].name : undefined}>
                                <DropDownList theme="aqua" dismissAfterSelection={true}>
                                    {allowedGroupTypeIds.map(groupTypeId => <ListItem theme="aqua" name={this.props.groupTypes.byId[groupTypeId].name} key={groupTypeId} value={groupTypeId} onClick={entityTypeSelectCallback} />)}
                                </DropDownList>
                            </SelectBox>;

                            if (this.props.selectedEntityType && this.props.groupTypes.byId.hasOwnProperty(this.props.selectedEntityType)) {
                                entityType = this.props.selectedEntityType;
                            }

                            if (this.props.xAxis) {
                                if (this.props.xAxis in this.props.groupTypes.customFields.byId) {
                                    xAxisText = this.props.groupTypes.customFields.byId[this.props.xAxis].name;
                                }
                            }

                            if (this.props.yAxis) {
                                if (this.props.yAxis in this.props.groupTypes.customFields.byId) {
                                    yAxisText = this.props.groupTypes.customFields.byId[this.props.yAxis].name;
                                }
                            }

                            if (entityType) {
                                const customFieldsText = this.props.selectedFields && this.props.selectedFields.length > 0 ? this.props.selectedFields
                                    .filter(fieldId => {
                                        return this.props.groupTypes.customFields.byId.hasOwnProperty(fieldId)
                                    })
                                    .map(fieldId => this.props.groupTypes.customFields.byId[fieldId].name).join(', ') : undefined;

                                customFieldsSelectBox = <SelectBox theme="aqua" selectionPromptText={customFieldsText ? customFieldsText : 'Select fields'}>
                                    <DropDownList theme="aqua" dismissAfterSelection={true}>
                                        {this.props.groupTypes.byId[entityType].customFields.map(groupFieldId => <ListItem theme="aqua" name={this.props.groupTypes.customFields.byId[groupFieldId].name} key={groupFieldId} value={groupFieldId} onClick={this.updateSelectedOptions} />)}
                                    </DropDownList>
                                </SelectBox>;

                                xAxisSelectBox = <SelectBox theme="aqua" selectionPromptText={xAxisText ? xAxisText : 'Select x axis'}>
                                    <DropDownList theme="aqua" dismissAfterSelection={true}>
                                        {this.props.groupTypes.byId[entityType].customFields.map(groupFieldId => <ListItem theme="aqua" name={this.props.groupTypes.customFields.byId[groupFieldId].name} key={groupFieldId} value={groupFieldId} onClick={this.updateXAxisForShow} />)}
                                    </DropDownList>
                                </SelectBox>;

                                yAxisSelectBox = <SelectBox theme="aqua" selectionPromptText={yAxisText ? yAxisText : 'Select y axis'}>
                                    <DropDownList theme="aqua" dismissAfterSelection={true}>
                                        {this.props.groupTypes.byId[entityType].customFields
                                            .filter(groupFieldId => this.props.groupTypes.customFields.byId[groupFieldId].type === FieldType.NUMBER)
                                            .map(groupFieldId => <ListItem theme="aqua" name={this.props.groupTypes.customFields.byId[groupFieldId].name} key={groupFieldId} value={groupFieldId} onClick={this.updateYAxisForShow} />)}
                                    </DropDownList>
                                </SelectBox>;
                            }
                            break;

                        case 'Workflow':

                            let allowedWorkflowTypeIds = this.props.workflowTypes.allEntries;

                            if (flowchartContext.projects.length > 0) {
                                allowedWorkflowTypeIds = allowedWorkflowTypeIds.filter(workflowTypeId => {
                                    const workflowType = this.props.workflowTypes.byId[workflowTypeId];
                                    return flowchartContext.projects.includes(workflowType.project);
                                });
                            }

                            entityTypeSelectBox = <SelectBox theme="aqua" selectionPromptText={this.props.selectedEntityType && this.props.workflowTypes.byId.hasOwnProperty(this.props.selectedEntityType) ? this.props.workflowTypes.byId[this.props.selectedEntityType].name : undefined}>
                                <DropDownList theme="aqua" dismissAfterSelection={true}>
                                    {allowedWorkflowTypeIds.map(workflowTypeId => <ListItem theme="aqua" name={this.props.workflowTypes.byId[workflowTypeId].name} key={workflowTypeId} value={workflowTypeId} onClick={entityTypeSelectCallback} />)}
                                </DropDownList>
                            </SelectBox>;

                            if (this.props.selectedEntityType && this.props.workflowTypes.byId.hasOwnProperty(this.props.selectedEntityType)) {
                                entityType = this.props.selectedEntityType;
                            }

                            if (this.props.xAxis) {
                                if (this.props.xAxis in this.props.workflowTypes.customFields.byId) {
                                    xAxisText = this.props.workflowTypes.customFields.byId[this.props.xAxis].name;
                                }
                            }

                            if (this.props.yAxis) {
                                if (this.props.yAxis in this.props.workflowTypes.customFields.byId) {
                                    yAxisText = this.props.workflowTypes.customFields.byId[this.props.yAxis].name;
                                }
                            }

                            if (entityType) {
                                const customFieldsText = this.props.selectedFields && this.props.selectedFields.length > 0 ? this.props.selectedFields
                                    .filter(fieldId => {
                                        return this.props.workflowTypes.customFields.byId.hasOwnProperty(fieldId)
                                    })
                                    .map(fieldId => this.props.workflowTypes.customFields.byId[fieldId].name).join(', ') : undefined;

                                const workflowType = this.props.workflowTypes.byId[entityType];
                                let allowedFields: Array<string> = [];

                                if (workflowType.affiliation === 'member' || workflowType.affiliation === 'none') {
                                    allowedFields = workflowType.customFields;
                                } else {
                                    allowedFields = workflowType.customFields.filter(fieldId => this.props.workflowTypes.customFields.byId[fieldId].affiliation === 'group');
                                }

                                customFieldsSelectBox = <SelectBox theme="aqua" selectionPromptText={customFieldsText ? customFieldsText : 'Select fields'}>
                                    <DropDownList theme="aqua" dismissAfterSelection={true}>
                                        {allowedFields.map(workflowFieldId => <ListItem theme="aqua" name={this.props.workflowTypes.customFields.byId[workflowFieldId].name} key={workflowFieldId} value={workflowFieldId} onClick={this.updateSelectedOptions} />)}
                                    </DropDownList>
                                </SelectBox>;

                                xAxisSelectBox = <SelectBox theme="aqua" selectionPromptText={xAxisText ? xAxisText : 'Select x axis'}>
                                    <DropDownList theme="aqua" dismissAfterSelection={true}>
                                        {this.props.workflowTypes.byId[entityType].customFields.map(workflowFieldId => <ListItem theme="aqua" name={this.props.workflowTypes.customFields.byId[workflowFieldId].name} key={workflowFieldId} value={workflowFieldId} onClick={this.updateXAxisForShow} />)}
                                    </DropDownList>
                                </SelectBox>;

                                yAxisSelectBox = <SelectBox theme="aqua" selectionPromptText={yAxisText ? yAxisText : 'Select y axis'}>
                                    <DropDownList theme="aqua" dismissAfterSelection={true}>
                                        {this.props.workflowTypes.byId[entityType].customFields
                                            .filter(workflowFieldId => this.props.workflowTypes.customFields.byId[workflowFieldId].type === FieldType.NUMBER)
                                            .map(workflowFieldId => <ListItem theme="aqua" name={this.props.workflowTypes.customFields.byId[workflowFieldId].name} key={workflowFieldId} value={workflowFieldId} onClick={this.updateYAxisForShow} />)}
                                    </DropDownList>
                                </SelectBox>;
                            }
                            break;

                        default:
                            entityTypeSelectBox = undefined;
                    }

                    let valueToShow: JSX.Element;

                    if (this.props.isRichText && typeof this.props.textToShow !== 'undefined') {
                        valueToShow = <div className={styles.editorWrapper} onClick={() => !flowchartContext.isReadonly && this.props.showRichTextEditor()}>
                            {this.getShowMarkupForRichText(this.props.textToShow)}
                        </div>;
                    } else {
                        valueToShow = this.props.variablePiece ? this.props.variablePiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverVariablePiece && !!this.props.targetPiece} onMouseOver={this.handleHoverOverVariablePiece} onMouseOut={this.handleHoverOutOfVariablePiece} onChange={this.handleShowValueUpdate} defaultText={this.props.textToShow} />;
                    }

                    if (!this.props.selectedType || this.props.selectedType === 'Text') {
                        richTextSelectBox = <SelectBox theme="aqua" selectionPromptText={this.props.isRichText ? 'Rich Text' : 'Normal'}>
                            <DropDownList theme="aqua" dismissAfterSelection={false}>
                                <DropDownSearchBox
                                    handleSearchInputChange={this.searchForTextStyles}
                                    placeholder={"Search by name"}
                                    searchTerm={this.state.textStylesSearchTerm}
                                />
                                {getFilteredOptionsBySearch(TEXT_STYLE_OPTIONS, this.state.textStylesSearchTerm).map((item) => {
                                    return <ListItem theme="aqua" name={item.name} value={item.value} onClick={this.updateTextStyle} />
                                })}
                            </DropDownList>
                        </SelectBox>;
                    }

                    const showAxes = this.props.startingDisplayType === 'bar' || this.props.startingDisplayType === 'line';

                    const isListType = this.props.selectedType && this.props.selectedType !== 'Text' && this.props.selectedType !== 'Table';
                    const isTextList = this.props.variableType === VariableType.TEXT_LIST;

                    return (
                        <div className={styles.visibleItems}>
                            <div className={styles.text}>{this.props.pieceName}</div>
                            {valueToShow}
                            <div className={styles.text}>which is a {this.props.selectedType} {this.props.selectedType !== 'Table' && 'list of type'}</div>
                            {entityTypeSelectBox}
                            {richTextSelectBox}

                            {this.props.selectedType && (isListType || isTextList || this.props.selectedType === 'Table') && <div className={styles.text}>with title</div>}
                            {this.props.selectedType && (isListType || isTextList || this.props.selectedType === 'Table') && <Input canReceiveDrag={false} onChange={this.handleShowWidgetTitleUpdate} defaultText={this.props.widgetTitle} />}

                            {this.props.selectedType && isListType && startingDisplayTypeSelectBox && <div className={styles.text}>with display type</div>}
                            {this.props.selectedType && isListType && startingDisplayTypeSelectBox}

                            {showAxes && <div className={styles.text}>with x axis</div>}
                            {showAxes && xAxisSelectBox}

                            {this.props.xAxis && <div className={styles.text}>with aggregation</div>}
                            {this.props.xAxis && aggregationSelectBox}

                            {this.props.yAxisAggregation && <div className={styles.text}>for y axis</div>}
                            {this.props.yAxisAggregation && yAxisSelectBox}

                            {!showAxes && this.props.startingDisplayType && customFieldsSelectBox && <div className={styles.text}>with fields</div>}
                            {!showAxes && this.props.startingDisplayType && customFieldsSelectBox}

                            <div className={styles.text}>Is Hidden?</div>
                            {this.props.isHiddenPiece ? this.props.isHiddenPiece : <div className={(this.state.isHoveringOverHiddenPiece && this.props.isDragging && this.props.targetPiece ? styles.booleanIndicatorHovering : styles.booleanIndicator) + ' attachment-target'} onMouseOver={this.handleHoverOverHiddenPiece} onMouseOut={this.handleHoverOutOfHiddenPiece}></div>}

                        </div>
                    )
                }
            }
        </FlowchartContext.Consumer>

    }
}

const ShowPieceEssentials = connect(mapStateToProps, mapDispatchToProps)(ConnectedShowPieceEssentials);

export default ShowPieceEssentials;