import { Component } from 'react';
import uuid from 'uuid';
import moment from 'moment';
import styles from './OaaSApp.module.scss';
import { ApplicationState } from '../shared/store/types';
import { connect } from 'react-redux';
import 'tippy.js/dist/tippy.css';
import Tippy from '@tippyjs/react';
import { withRouter, RouteComponentProps } from "react-router";

import Sidebar from './sidebar/Sidebar';
import { Dispatch } from 'redux';

import { fetchLocalData } from '../shared/store/actions'
import { clearInfoMessage, clearToastMessage, initiateFetchAppData, resetNetworkCall, resumePartialDataFetch, setShouldUpdateApplication, setToastMessage, startDataPush, updatePermissions } from '../shared/store/my-data/actions';

import AppRoutes from './AppRoutes';
import { clearCurrentSnapshot, getCurrentSnapshot, getDICEflowDB, storeCurrentSnapshot } from '../shared/store/database';
import store from '../shared/store/main';

import { ReactComponent as CopyIcon } from '../common/assets/copy.svg';
import { ReactComponent as SearchPhraseIcon } from '../common/assets/translate-search.svg';
import { WidgetData } from '../shared/store/widgets/types';
import { markPartialWidgetCache, updateWidgetCache, updateWidgetMessageCache, updateWidgetTableCache } from '../shared/store/widgets/actions';
import LoaderModal from '../widgets/loader/LoaderModal';
import { getTranslateableWords, translatePhrase } from '../shared/helpers/translation';
import { getShowDataForWidget, getValueForMessageWidget } from '../shared/helpers/widgets';
import { getVisibleGroupIds, getVisibleMemberIds, getVisibleUserIds, getVisibleWorkflowIds } from '../shared/helpers/visible-entities';
import { TableCell } from '../shared/helpers/common-types';
import { VERSION_NUMBER } from '../shared/helpers/version';
import { copyStringToClipboard, isUUID } from '../shared/helpers/utilities';
import InputText from '../widgets/form/InputText';
import { Permissions } from '../shared/store/permissions/types';
import React from 'react';
import Button from '../widgets/button/CommonButton';
import { setCurrentRoute } from '../shared/store/state-save/actions';
import FlowchartComparison from './FlowchartComparison';
import { clearSyncProcessMessage } from '../shared/store/sync-logs/actions';
import { DeviceSpecs, SyncMessage } from '../shared/store/sync-logs/types';

type OwnProps = {};

type OwnState = {
    code: string,
    timeoutCallBack: number | undefined,
    loader: JSX.Element | undefined,
    shouldShowUpdateToast: boolean,
    showLanguageShortCut: boolean,
    searchInput: string | undefined,
    translatablePairs: string[][],
    duplicatePairs: string[][],
    isSessionExpired: boolean
}

export function isPageExcluded() {
    const exclusionsForSingleTab = ['/file-download'];
    const path = window.location.pathname;
    return exclusionsForSingleTab.some(link => path.startsWith(link));
}

const mapStateToProps = (state: ApplicationState, props: OwnProps & RouteComponentProps) => {
    const cachedWidgetIds = Object.keys(state.widgets.cachedWidgetData);
    const cachedMessageIds = Object.keys(state.widgets.cachedWidgetMessages);
    const isEntityListPage = props.location.pathname === '/members/list' || props.location.pathname === '/groups/list' ||
        props.location.pathname === '/workflows/list' || props.location.pathname === '/users/list' ||
        props.location.pathname === '/reports/list' || props.location.pathname === '/dashboard' ||
        props.location.pathname === '/languages';

    const returningData = {
        organization: state.organization,
        infoMessage: state.myData.infoMessage,
        indeterminateMessage: state.myData.indeterminateMessage,
        errorMessage: state.myData.errorMessage,
        myId: state.myData.id || 'SuperUser',
        isFrozen: state.myData.isFrozen,
        isLoaded: state.myData.isLoaded,
        isPushingData: state.myData.isPushingData,
        isPartiallyLoaded: state.myData.isPartiallyLoaded,
        isNetworkCallInterrupted: state.myData.isNetworkCallInterrupted,

        isSettled: state.myData.isLoaded && !state.myData.isPushingData && !state.myData.isFetchingOlderData,
        isFetchingForDataUpdate: !!state.myData.isFetchingForDataUpdate,
        fetchingDataForUpdateMessage: state.myData.fetchingDataForUpdateMessage,

        isFetchingOlderData: state.myData.isFetchingOlderData,
        isLoggingOut: !!state.myData.isLoggingOut,

        userIdsMarkedForIndex: state.users.markedForIndex,
        memberIdsMarkedForIndex: state.members.markedForIndex,
        groupIdsMarkedForIndex: state.groups.markedForIndex,
        workflowIdsMarkedForIndex: state.workflows.markedForIndex,

        shouldUpdateApplication: state.myData.shouldUpdateApplication,
        latestVersionNumber: state.myData.latestVersionNumber,

        comparisonWorkflowTypeId: state.flowchart.pieces.comparison?.workflowTypeId,

        areWidgetsCached: cachedWidgetIds.length > 0 || cachedMessageIds.length > 0,
        isFlowchartExpanded: state.myData.isFlowchartExpanded,
        applicationState: state,
        isLanguagesReadable: state.permissions.myPermissions.general.LanguagesConfiguration !== Permissions.NONE,
        isEntityListPage,
        savedStates: state.savedStates,
        syncProcessMessage: state.synclogState.syncProcessMessage
    }

    return returningData;
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        fetchAppData: (snapshot: ApplicationState) => dispatch(fetchLocalData(snapshot)),
        fetchAppDataFromServer: () => dispatch(initiateFetchAppData()),
        startSync: () => dispatch(startDataPush()),
        resumePartialDataFetch: () => dispatch(resumePartialDataFetch()),
        clearToastMessage: () => dispatch(clearToastMessage()),
        updateMyPermissions: () => dispatch(updatePermissions()),

        updateWidgetCache: (id: string, widgetData: WidgetData) => dispatch(updateWidgetCache(id, widgetData)),
        markPartialWidgetCache: (id: string) => dispatch(markPartialWidgetCache(id)),
        updateWidgetMessageCache: (id: string, message: string) => dispatch(updateWidgetMessageCache(id, message)),
        updateWidgetTableCache: (id: string, tableData: Array<Array<TableCell>>) => dispatch(updateWidgetTableCache(id, tableData)),

        setShouldUpdateApplication: (shouldUpdateApplication: boolean) => dispatch(setShouldUpdateApplication(shouldUpdateApplication)),
        setToastMessage: (message: string, persistMessage: boolean) => dispatch(setToastMessage(message, persistMessage)),

        clearInfoMessage: () => dispatch(clearInfoMessage()),

        setCurrentRoute: (location: string) => dispatch(setCurrentRoute(location)),

        resetNetworkCall: () => dispatch(resetNetworkCall()),
        clearSyncProcessMessage: () => dispatch(clearSyncProcessMessage()),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps;

function hexToRgbA(hex: string) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    return r + "," + g + "," + b;
}

class ConnectedOaaSApp extends Component<Props, OwnState> {
    channel = new BroadcastChannel('diceflow-data');

    state: OwnState = {
        code: '0',
        timeoutCallBack: undefined,
        loader: undefined,
        shouldShowUpdateToast: false,
        showLanguageShortCut: false,
        searchInput: undefined,
        translatablePairs: [],
        duplicatePairs: [],
        isSessionExpired: false
    }

    static getDerivedStateFromProps(props: Readonly<Props>, state: Readonly<OwnState>) {
        if (state.code && props.organization.code !== state.code) {

            document.documentElement.style.setProperty('--primary-color', props.organization.primary_color);

            if (props.organization.primary_color) {
                document.documentElement.style.setProperty('--primary-color-rgb', hexToRgbA(props.organization.primary_color));
            } else {
                document.documentElement.style.setProperty('--primary-color-rgb', '128, 63, 151');
            }

            document.documentElement.style.setProperty('--contrast-color', props.organization.contrast_color);
            document.documentElement.style.setProperty('--contrast-color-rgb', hexToRgbA(props.organization.contrast_color));
            document.documentElement.style.setProperty('--primary-gradient', props.organization.primary_gradient);

            return {
                code: props.organization.code
            }
        } else {
            return null;
        }
    }

    logUserOutAfterTimeout = () => {
        const lastActionTime = localStorage.getItem('lastAction');

        if (lastActionTime === null) {
            return;
        }

        const difference = Math.abs(moment().diff(moment(lastActionTime), 'minutes'));

        if (difference >= this.props.organization.sessionTimeoutInMinutes) {
            this.setState({ isSessionExpired: true });
            console.log('Session Expired')
        }
    }

    logout = () => {
        storeCurrentSnapshot(store.getState(), true);

        localStorage.removeItem('token');
        localStorage.removeItem('myId');
        window.location.assign('/login/' + this.props.organization.code);
    }

    startCountDownForLogoutWhenIdle = () => {

        const startCountDownForLogout = () => {
            if (this.state.timeoutCallBack) {
                window.clearTimeout(this.state.timeoutCallBack);
            }

            const timeoutCallback = window.setTimeout(
                this.logUserOutAfterTimeout,
                this.props.organization.sessionTimeoutInMinutes * 60 * 1000
            );
            localStorage.setItem('lastAction', moment().format());

            this.setState({
                timeoutCallBack: timeoutCallback,
            });
        }

        if ((window as any).requestIdleCallback) {
            (window as any).requestIdleCallback(startCountDownForLogout);
        } else {
            startCountDownForLogout();
        }
    }

    fetchAppData = async () => {
        const myId = window.localStorage.getItem('myId');

        if (!myId) {
            this.props.fetchAppDataFromServer();
            return;
        }

        if (localStorage.getItem('clearData')) {
            localStorage.removeItem('clearData');
            await clearCurrentSnapshot(myId);
        }

        const snapshot: ApplicationState = await getCurrentSnapshot(myId);

        if (!!snapshot && !snapshot.myData.isFrozen && snapshot.myData.isLoaded) {

            if (snapshot.myData.id === 'SuperUser') {
                let orgAdminIds: any = {};

                if (!localStorage.getItem('orgAdminIds')) {
                    localStorage.setItem('orgAdminIds', JSON.stringify(orgAdminIds));
                }

                const orgAdminData = localStorage.getItem('orgAdminIds');

                if (orgAdminData) {
                    orgAdminIds = JSON.parse(orgAdminData);
                    orgAdminIds[snapshot.organization.code] = myId;
                    localStorage.setItem('orgAdminIds', JSON.stringify(orgAdminIds));
                }
            }

            this.props.fetchAppData(snapshot);
            this.props.updateMyPermissions();
        } else {
            this.props.fetchAppDataFromServer();
        }
    }

    checkForOnlyTab = () => {
        const tabId = uuid.v4();
        const tabTime = moment().toISOString();
        const channelMessage = tabId + ' ' + tabTime;

        if (!this.channel) {
            this.channel = new BroadcastChannel('diceflow-data');
        }

        this.channel.postMessage(channelMessage);

        this.channel.addEventListener('message', event => {
            const message: string = event.data;
            const messageComponents = message.split(' ');

            if (messageComponents.length === 2) {
                const receivedTabId = messageComponents[0];
                const messageTime = messageComponents[1];

                if (tabId !== receivedTabId) {
                    if (messageTime > tabTime) {
                        this.channel.postMessage(channelMessage);
                    } else {
                        window.alert('A window/tab for diceflow.in is already open');
                        window.location.assign('/');
                    }
                }
            }
        });
    }

    getDeviceSpecs = async (): Promise<DeviceSpecs> => {
        function bytesToGB(bytes: number) {
            return (bytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
        }

        const userAgent = navigator.userAgent;
        const deviceMemory = (navigator as any).deviceMemory ? `${(navigator as any).deviceMemory} GB` : 'Unknown';

        const memoryInfo = 'memory' in performance ? performance.memory : null;
        const osVersionMatch = navigator.userAgent.match(/\((.*?)\)/);
        const osVersion = osVersionMatch ? osVersionMatch[1] : '';

        const specs: DeviceSpecs = {
            model: 'Web',  // Model detection is unreliable in browsers
            platform: navigator.platform || 'web',
            osVersion,
            manufacturer: navigator.vendor || 'Unknown',
            isVirtual: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent),
            deviceMemory: deviceMemory,
            jsHeapSizeLimit: memoryInfo ? bytesToGB((memoryInfo as any).jsHeapSizeLimit) : 'N/A',
            totalJSHeapSize: memoryInfo ? bytesToGB((memoryInfo as any).totalJSHeapSize) : 'N/A',
            usedJSHeapSize: memoryInfo ? bytesToGB((memoryInfo as any).usedJSHeapSize) : 'N/A',
            storageQuota: '',
            storageUsage: '',
        };

        if ('storage' in navigator && 'estimate' in navigator.storage) {
            const estimate = await navigator.storage.estimate();
            specs.storageQuota = bytesToGB(estimate.quota as any) || '';
            specs.storageUsage = bytesToGB(estimate.usage as any) || '';
        }

        return specs;
    };

    async componentDidMount() {
        const isFileDownloadPath = window.location.pathname.startsWith('/file-download/');

        if (window.location.hostname !== 'localhost' && !window.localStorage.getItem('token') && !isFileDownloadPath) {
            window.location.assign('/login/');
        }

        const isExcluded = isPageExcluded();

        if (isExcluded) {
            return;
        }

        this.checkForOnlyTab();

        setTimeout(() => {
            this.fetchAppData();
            this.startCountDownForLogoutWhenIdle();
        }, 1000);

        document.addEventListener('mousemove', this.startCountDownForLogoutWhenIdle);
        document.addEventListener('keydown', this.startCountDownForLogoutWhenIdle);
        document.addEventListener('keypress', this.startCountDownForLogoutWhenIdle);
        document.addEventListener("keydown", this.onKeyDown);

        const deviceSpecs = await this.getDeviceSpecs();
        if (deviceSpecs) {
            localStorage.setItem('deviceSpecs', JSON.stringify(deviceSpecs));
        }
    }

    componentWillUnmount() {
        this.channel.close();
    }

    updateAllWidgetCaches = () => {
        console.log('Start widget caching');

        const state = store.getState();
        let widgetIdsInRoles: Array<string> = [];

        const myRoles = state.users.byId.hasOwnProperty(state.myData.id) ? state.users.byId[state.myData.id].roles : [];

        for (const roleId of myRoles) {
            const widgetIdsInRole = roleId && roleId in state.widgets.byRole ? state.widgets.byRole[roleId] : [];
            widgetIdsInRoles = widgetIdsInRoles.concat(widgetIdsInRole);
        }

        const newWidgetIds = Array.from(new Set(widgetIdsInRoles.filter(widgetId => !state.myData.widgets.myWidgets.includes(widgetId))));
        const myWidgetIds = Array.from(new Set(state.myData.widgets.myWidgets));

        const allWidgetIds = isUUID(state.myData.id) ? newWidgetIds.concat(myWidgetIds).filter(widgetId => !state.widgets.byId[widgetId].archived) : state.widgets.allEntries;

        const visibleUserIds = getVisibleUserIds(state, true);
        const visibleMemberIds = getVisibleMemberIds(state, true);
        const visibleGroupIds = getVisibleGroupIds(state, true);
        const visibleWorkflowIds = getVisibleWorkflowIds(state, visibleUserIds, visibleMemberIds, visibleGroupIds, true);

        for (const widgetId of allWidgetIds) {
            const widget = state.widgets.byId[widgetId];

            if (widget.displayType === 'message') {
                const message = getValueForMessageWidget(widgetId, visibleUserIds, visibleMemberIds, visibleGroupIds, visibleWorkflowIds, state);

                if (message) {
                    this.props.updateWidgetMessageCache(widgetId, message);
                }
            } else if (widget.displayType === 'formatted-table') {
                // Do not calculate table by default
                continue;
                // const tableData = getValueForTableWidget(widgetId, visibleUserIds, visibleMemberIds, visibleGroupIds, visibleWorkflowIds, state);

                // if (tableData) {
                //     this.props.updateWidgetTableCache(widgetId, tableData);
                // }
            } else {
                let runningSlice = getShowDataForWidget(widgetId, state, visibleUserIds, visibleMemberIds, visibleGroupIds, visibleWorkflowIds);
                const widgetData = runningSlice.widgetData;

                while (runningSlice.lastEntityId) {
                    runningSlice = getShowDataForWidget(widgetId, state, visibleUserIds, visibleMemberIds, visibleGroupIds, visibleWorkflowIds, runningSlice.lastEntityId);

                    widgetData.entries = widgetData.entries.concat(runningSlice.widgetData.entries);
                    widgetData.entityIds = widgetData.entityIds.concat(runningSlice.widgetData.entityIds);

                    for (let i = 0; i < runningSlice.widgetData.chartLabels.length; i += 1) {
                        const chartLabel = runningSlice.widgetData.chartLabels[i];
                        const chartData = runningSlice.widgetData.chartData[i];

                        const existingLabelIndex = widgetData.chartLabels.indexOf(chartLabel);

                        if (existingLabelIndex === -1) {
                            widgetData.chartLabels.push(chartLabel);
                            widgetData.chartData.push(chartData);
                        } else {
                            widgetData.chartData[existingLabelIndex] += chartData;
                        }
                    }
                }

                this.props.updateWidgetCache(widgetId, widgetData);
            }
        }

        console.log('End widget caching');
    }

    updateTasksInBackground = async () => {

        if (!this.props.areWidgetsCached) {
            this.updateAllWidgetCaches();
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.isSettled && !prevProps.isSettled) {
            this.updateTasksInBackground();
        }

        if (prevProps.isLoaded !== this.props.isLoaded && this.props.isLoaded) {
            if (localStorage.getItem("shouldAppUpdate") === "true") {
                setTimeout(() => {
                    this.setIsShowingUpdateToast(true);
                    localStorage.setItem("shouldAppUpdate", "false");
                }, 1000);
                setTimeout(() => {
                    this.setIsShowingUpdateToast(false);
                }, 6000);
            } else {
                this.setIsShowingUpdateToast(false);
            }
        }

        if (this.props.isLoaded && this.props.shouldUpdateApplication && this.props.latestVersionNumber) {
            this.props.setToastMessage(translatePhrase('Please wait') + ', ' + translatePhrase('updating application'), false);
            setTimeout(() => {
                localStorage.setItem("shouldAppUpdate", "true");
                window.location.reload();
            }, 2000);
        }

        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.props.setCurrentRoute(this.props.location.pathname)
        }

    }

    restartNetworkCall = () => {
        if (!window.navigator.onLine) {
            window.alert(translatePhrase('Please check your internet'));
            return;
        }

        if (this.props.isPartiallyLoaded) {
            this.props.resumePartialDataFetch();
        } else if (this.props.isPushingData) {
            this.props.startSync();
        } else {
            this.props.fetchAppDataFromServer();
        }
    }

    reloadPage = () => {
        this.props.setShouldUpdateApplication(false);
        window.location.reload();
    }

    cancelReload = () => {
        this.props.setShouldUpdateApplication(false);
    }

    setIsShowingUpdateToast = (shouldShowUpdateToast: boolean) => {
        this.setState({
            shouldShowUpdateToast: shouldShowUpdateToast
        });
    }

    onKeyDown = async (e: KeyboardEvent) => {
        if (e.ctrlKey && e.key === "]") {
            this.setState({
                showLanguageShortCut: true
            });
        }

        if (e.key === "Escape" && this.state.showLanguageShortCut) {
            this.setState({ showLanguageShortCut: false });
        };

        if (e.ctrlKey && e.shiftKey && e.key === 'L') {
            const myId = localStorage.getItem('myId');
            const token = localStorage.getItem('token');
            const orgAdminIds = localStorage.getItem('orgAdminIds');
            const dataToCopy = {
                myId,
                token,
                orgAdminIds
            };
            navigator.clipboard.writeText(JSON.stringify(dataToCopy, null, 2));
        };

        if (e.ctrlKey && e.shiftKey && e.key === 'K') {
            navigator.clipboard.readText().then(dataToPaste => {
                const parsedData = JSON.parse(dataToPaste);
                localStorage.setItem('myId', parsedData.myId);
                localStorage.setItem('token', parsedData.token);
                localStorage.setItem('orgAdminIds', parsedData.orgAdminIds);
                window.location.reload();
            });
        }
    }

    setSearchTerm = (term: string) => {
        this.setState({ searchInput: term })

        let translatablePairs: string[][] = [];

        if (this.state.showLanguageShortCut) {
            const allTranslatablePairs = getTranslateableWords(this.props.applicationState);
            translatablePairs = Array.from(allTranslatablePairs.masterSetWithoutDuplicates);
            const duplicatePairs = Array.from(allTranslatablePairs.duplicateTracker);

            if (this.state.searchInput && this.state.searchInput.toString().trim().length > 0) {
                translatablePairs = translatablePairs.filter(pair => {
                    return pair[0].toLowerCase().includes(this.state.searchInput ? this.state.searchInput.toString().toLowerCase().trim() : '');
                });
            }

            this.setState({
                translatablePairs: translatablePairs.slice(0, 10),
                duplicatePairs: duplicatePairs
            });
        }
    }

    render() {
        const frozenClass = this.props.isFrozen ? styles.frozenApp : '';

        const isExcluded = isPageExcluded();

        return (
            <div className={styles.backgroundColorProvider + ' ' + frozenClass}>

                {this.state.showLanguageShortCut && <React.Fragment>
                    <section className={styles.quickFind}>
                        <InputText default={this.state.searchInput} isAutoFocus onChange={value => this.setSearchTerm(value)} placeholder={translatePhrase('Search Phrases')} />
                        <ul>
                            {this.state.translatablePairs.map((translatablePair, key) => {
                                const originalPhrase = translatablePair[0];

                                let additionalPairs: Array<string> = [];

                                this.state.duplicatePairs.forEach(pair => {
                                    if (pair[0].includes(originalPhrase)) {
                                        additionalPairs.push(pair[1])
                                    }
                                });

                                const data = Array.from(new Set([...additionalPairs]));

                                return <li key={key}>
                                    <label> {originalPhrase}
                                        {data.map(pair => <span> {pair} </span>)}
                                    </label>
                                    <Tippy className="my-tippy" content={<span> {translatePhrase('Tap on icon to copy')} </span>}>
                                        <CopyIcon onClick={() => {
                                            copyStringToClipboard(originalPhrase);
                                            this.setState({ showLanguageShortCut: false });
                                        }} />
                                    </Tippy>
                                </li>
                            })}
                        </ul>
                    </section>
                    <div className={styles.backDrop} onClick={() => this.setState({ showLanguageShortCut: false })}></div>
                </React.Fragment>}

                {this.props.isLanguagesReadable && !this.props.isEntityListPage && <div className={styles.searchPhraseButton}>
                    <Button title={translatePhrase('Search Phrases') + ': CTRL + ] '} onClick={() => this.setState({ showLanguageShortCut: true })} color={'contrast'} isRounded type={this.state.showLanguageShortCut ? 'primary' : 'secondary'} icon={<SearchPhraseIcon />} />
                </div>}

                {!this.props.isFlowchartExpanded && <Sidebar />}
                <AppRoutes />

                {this.props.comparisonWorkflowTypeId && <FlowchartComparison workflowTypeId={this.props.comparisonWorkflowTypeId} />}

                {this.props.isLoggingOut && <LoaderModal
                    loaderText={[`${translatePhrase('Logging out')}...`, `${translatePhrase('Saving your data')}...`]}
                    isIndeterminate
                />}

                {this.state.shouldShowUpdateToast && <LoaderModal
                    loaderText={[translatePhrase('Application updated to') + ': ' + VERSION_NUMBER]}
                    isOutsideClickable
                    isInfo
                    forceShowClose
                    closeModal={this.cancelReload}
                />}

                {this.props.infoMessage && <LoaderModal
                    isInfo
                    loaderText={[this.props.infoMessage]}
                    isIndeterminate
                    isOutsideClickable
                    closeModal={this.props.clearInfoMessage}
                    forceShowClose
                />}

                {this.props.indeterminateMessage && <LoaderModal
                    loaderText={[this.props.indeterminateMessage]}
                    isIndeterminate
                />}

                {this.props.errorMessage && <LoaderModal
                    isError
                    loaderText={[this.props.errorMessage]}
                    isIndeterminate
                />}

                {((!isExcluded && !this.props.isLoaded && !this.props.isPartiallyLoaded) || this.props.isNetworkCallInterrupted) && <LoaderModal
                    loaderText={[(!this.props.isNetworkCallInterrupted ? translatePhrase(this.props.isFetchingForDataUpdate && this.props.fetchingDataForUpdateMessage ? this.props.fetchingDataForUpdateMessage : 'Fetching your data') + '...' : translatePhrase('Network error. Please check internet and try again'))]}
                    isError={this.props.isNetworkCallInterrupted}
                    isIndeterminate
                    shouldAllowRetry={this.props.isNetworkCallInterrupted}
                    retryCallback={this.restartNetworkCall}
                    closeModal={this.props.resetNetworkCall}
                />}
                {this.props.syncProcessMessage === SyncMessage.SyncStarted && !this.props.isNetworkCallInterrupted && <LoaderModal
                    loaderText={[translatePhrase(this.props.syncProcessMessage || 'Syncing') + '...']}
                    isNotAllowedToCloseManually
                    isError={this.props.isNetworkCallInterrupted}
                    isIndeterminate
                    shouldAllowRetry={this.props.isNetworkCallInterrupted}
                    retryCallback={this.restartNetworkCall}
                />}
                {this.props.isPushingData && !this.props.isPartiallyLoaded && !this.props.isNetworkCallInterrupted && <LoaderModal
                    loaderText={[translatePhrase(this.props.syncProcessMessage || 'Syncing') + '...']}
                    isNotAllowedToCloseManually
                    isError={this.props.isNetworkCallInterrupted}
                    isIndeterminate
                    shouldAllowRetry={this.props.isNetworkCallInterrupted}
                    retryCallback={this.restartNetworkCall}
                />}
                {this.props.syncProcessMessage === SyncMessage.SyncComplete && <LoaderModal
                    loaderText={[translatePhrase(this.props.syncProcessMessage || 'Syncing') + '...']}
                    isSuccess
                />}
                {this.props.isFetchingOlderData && !this.props.isPartiallyLoaded && !this.props.isNetworkCallInterrupted && <LoaderModal
                    loaderText={[translatePhrase('Fetching older data') + '...']}
                    isNotAllowedToCloseManually
                    isError={this.props.isNetworkCallInterrupted}
                    isIndeterminate
                    shouldAllowRetry={this.props.isNetworkCallInterrupted}
                    retryCallback={this.restartNetworkCall}
                />}

                {this.state.isSessionExpired && <LoaderModal
                    loaderText={[translatePhrase('Your session has expired. Please log in again to continue.')]}
                    isError
                    shouldAllowRetry
                    closeModal={this.logout.bind(this)}
                />}
            </div>
        );

    }
}

const OaaSApp = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectedOaaSApp) as any);
export default OaaSApp;