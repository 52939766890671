import { Component, ChangeEvent } from 'react';
import styles from './GetEntitiesPiece.module.scss';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import FlowchartPiece, { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setEntity, setEntityType, setVariablePiece } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import { FlowchartContext, FlowchartInfoForPiece, PieceHighlightColour } from '../../../contexts/flowchart-context';
import Input from '../Input';
import { valuePieceSlotTarget } from './utilities';
import DropDownSearchBox from '../drop-down/DropDownSearchBox';


type GetEntitiesPieceProps = {
    nextPiece?: JSX.Element,
    entity: 'LOCATION' | 'USER' | 'MEMBER' | 'GROUP' | 'WORKFLOW' | undefined,
    entityType: string | undefined,
    locationPiece?: JSX.Element,
}

interface EntityOptions {
    name: string;
    value: string;
    entity: "LOCATION" | "USER" | "MEMBER" | "GROUP" | "WORKFLOW" | undefined;
}

const ENTITY_OPTIONS: EntityOptions[] = [
    { name: "Locations", value: "Locations", entity: "LOCATION" },
    { name: "Users", value: "Users", entity: "USER" },
    { name: "Members", value: "Members", entity: "MEMBER" },
    { name: "Groups", value: "Groups", entity: "GROUP" },
    { name: "Workflows", value: "Workflows", entity: "WORKFLOW" },
  ];

const mapStateToProps = (state: ApplicationState) => {

    return {
        projectsData: state.structure.projects,
        levelsData: state.structure.levels,
        rolesData: state.structure.roles,
        memberTypesData: state.members.types,
        groupTypesData: state.groups.types,
        workflowTypesData: state.workflows.types,

        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,
    }
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: GetEntitiesPieceProps & FlowchartPieceProps) => {

    return {
        setTargetPiece: (pieceId: string | undefined) => dispatch(setTargetPiece(pieceId)),
        setEntity: (targetPieceId: string, value: 'LOCATION' | 'USER' | 'MEMBER' | 'GROUP' | 'WORKFLOW' | undefined) => dispatch(setEntity(targetPieceId, value)),
        setEntityType: (pieceId: string, value: string) => dispatch(setEntityType(pieceId, value)),
        setVariablePiece: (pieceId: string, value: string) => dispatch(setVariablePiece(pieceId, value)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = GetEntitiesPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type SetPieceState = {
    isHoveringOverVariablePiece: boolean,
    isAddingVariable: boolean,
    searchText: string,
    entitySearchTerm: string,
}

class ConnectedSetPiece extends Component<Props, SetPieceState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            isHoveringOverVariablePiece: false,
            isAddingVariable: false,
            searchText: '',
            entitySearchTerm: "",
        };
    }

    updateValidity = (isValid: boolean, flowchartContext: FlowchartInfoForPiece) => {
        if (!isValid) {
            const invalidPieceInfo = flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId);

            if (!invalidPieceInfo && flowchartContext.setInvalidPiece) {
                flowchartContext.setInvalidPiece(this.props.pieceId, flowchartContext.parentSplitPieceIds);
            }
        }

        if (isValid && flowchartContext.removeInvalidPiece && flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId)) {
            flowchartContext.removeInvalidPiece(this.props.pieceId);
        }
    }

    handleHoverOverLocationPiece = () => {
        this.setState({
            isHoveringOverVariablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfLocationPiece = () => {
        this.setState({
            isHoveringOverVariablePiece: false,
        });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && this.state.isHoveringOverVariablePiece) {
            if (this.state.isHoveringOverVariablePiece) {
                this.props.setVariablePiece(this.props.pieceId, this.props.lastDraggedPiece.id);
            }

            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverVariablePiece: false,
            });
        }
    }

    searchForGetEntities = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            searchText: e.target.value,
        });
    }
    
    searchForEntities = (searchTerm: string) => {
        this.setState({ entitySearchTerm: searchTerm });
    }

    render() {
        const filteredEntities = ENTITY_OPTIONS.filter(entity => {
            return entity.name.toLocaleLowerCase().includes(this.state.entitySearchTerm.toLocaleLowerCase());
        })
        return <FlowchartContext.Consumer>
            {
                (flowchartContext) => {

                    const entitySelectBox = <SelectBox theme="aqua" selectionPromptText={this.props.entity ? this.props.entity[0] + this.props.entity.substring(1).toLowerCase() + 's' : undefined}>
                        <DropDownList theme="aqua" dismissAfterSelection={false}>
                            <DropDownSearchBox
                                handleSearchInputChange={this.searchForEntities}
                                placeholder={"Search by name"}
                                searchTerm={this.state.entitySearchTerm}
                            />
                            {filteredEntities.map(entity => {
                                return <ListItem name={entity.name} value={entity.value} theme="aqua" onClick={() => this.props.setEntity(this.props.pieceId, entity.entity)} />
                            })}
                        </DropDownList>
                    </SelectBox>;

                    const highlightColor = flowchartContext.highlights && flowchartContext.highlights[this.props.pieceId];
                    let highlightClass = styles.noHighlight;

                    switch (highlightColor) {
                        case PieceHighlightColour.GREEN:
                            highlightClass = styles.addedHighlight;
                            break;
                        case PieceHighlightColour.YELLOW:
                            highlightClass = styles.updatedHighlight;
                            break;
                        case PieceHighlightColour.PURPLE:
                            highlightClass = styles.movedHighlight;
                            break;
                        case PieceHighlightColour.RED:
                            highlightClass = styles.deletedHighlight;
                            break;
                    }

                    let entityTypeText = 'Any';
                    let entityTypeOptions: Array<JSX.Element> = [];

                    const lowerCaseSearchText = this.state.searchText.toLocaleLowerCase().trim();

                    switch (this.props.entity) {
                        case 'LOCATION':
                            entityTypeOptions = this.props.levelsData.allEntries
                                .filter(levelId => {
                                    const level = this.props.levelsData.byId[levelId];
                                    const project = this.props.projectsData.byId[level.project];

                                    const passesSearch = !lowerCaseSearchText || level.name.toLowerCase().includes(lowerCaseSearchText) || project.name.toLowerCase().includes(lowerCaseSearchText);

                                    return passesSearch && flowchartContext.projects.includes(level.project);
                                }).map(levelId => {
                                    const level = this.props.levelsData.byId[levelId];
                                    const project = this.props.projectsData.byId[level.project];
                                    return <ListItem key={levelId} name={level.name} detail={project.name} value={level.id} theme="aqua" onClick={this.props.setEntityType.bind(this, this.props.pieceId)} />
                                });

                            if (this.props.entityType) {
                                const selectedLevel = this.props.levelsData.byId[this.props.entityType];

                                if (selectedLevel) {
                                    entityTypeText = selectedLevel.name;
                                }
                            }

                            break;

                        case 'USER':
                            entityTypeOptions = this.props.rolesData.allEntries
                                .filter(roleId => {
                                    const role = this.props.rolesData.byId[roleId];
                                    const level = this.props.levelsData.byId[role.level];
                                    const project = this.props.projectsData.byId[level.project];

                                    const passesSearch = !lowerCaseSearchText || role.name.toLowerCase().includes(lowerCaseSearchText) || project.name.toLowerCase().includes(lowerCaseSearchText);

                                    return passesSearch && flowchartContext.projects.includes(level.project);
                                }).map(roleId => {
                                    const role = this.props.rolesData.byId[roleId];
                                    const level = this.props.levelsData.byId[role.level];
                                    const project = this.props.projectsData.byId[level.project];
                                    return <ListItem key={roleId} name={role.name} detail={project.name} value={role.id} theme="aqua" onClick={this.props.setEntityType.bind(this, this.props.pieceId)} />
                                });

                            if (this.props.entityType) {
                                const selectedRole = this.props.rolesData.byId[this.props.entityType];

                                if (selectedRole) {
                                    entityTypeText = selectedRole.name;
                                }
                            }

                            break;

                        case 'MEMBER':
                            entityTypeOptions = this.props.memberTypesData.allEntries
                                .filter(memberTypeId => {
                                    const memberType = this.props.memberTypesData.byId[memberTypeId];
                                    const project = this.props.projectsData.byId[memberType.project];

                                    const passesSearch = !lowerCaseSearchText || memberType.name.toLowerCase().includes(lowerCaseSearchText) || project.name.toLowerCase().includes(lowerCaseSearchText);

                                    return passesSearch && flowchartContext.projects.includes(memberType.project);
                                }).map(memberTypeId => {
                                    const memberType = this.props.memberTypesData.byId[memberTypeId];
                                    const project = this.props.projectsData.byId[memberType.project];
                                    return <ListItem key={memberTypeId} name={memberType.name} detail={project.name} value={memberType.id} theme="aqua" onClick={this.props.setEntityType.bind(this, this.props.pieceId)} />
                                });

                            if (this.props.entityType) {
                                const selectedMemberType = this.props.memberTypesData.byId[this.props.entityType];

                                if (selectedMemberType) {
                                    entityTypeText = selectedMemberType.name;
                                }
                            }

                            break;

                        case 'GROUP':
                            entityTypeOptions = this.props.groupTypesData.allEntries
                                .filter(groupTypeId => {
                                    const groupType = this.props.groupTypesData.byId[groupTypeId];
                                    const project = this.props.projectsData.byId[groupType.project];

                                    const passesSearch = !lowerCaseSearchText || groupType.name.toLowerCase().includes(lowerCaseSearchText) || project.name.toLowerCase().includes(lowerCaseSearchText);

                                    return passesSearch && flowchartContext.projects.includes(groupType.project);
                                }).map(groupTypeId => {
                                    const groupType = this.props.groupTypesData.byId[groupTypeId];
                                    const project = this.props.projectsData.byId[groupType.project];
                                    return <ListItem key={groupTypeId} name={groupType.name} detail={project.name} value={groupType.id} theme="aqua" onClick={this.props.setEntityType.bind(this, this.props.pieceId)} />
                                });

                            if (this.props.entityType) {
                                const selectedGroupType = this.props.groupTypesData.byId[this.props.entityType];

                                if (selectedGroupType) {
                                    entityTypeText = selectedGroupType.name;
                                }
                            }

                            break;

                        case 'WORKFLOW':
                            entityTypeOptions = this.props.workflowTypesData.allEntries
                                .filter(workflowTypeId => {
                                    const workflowType = this.props.workflowTypesData.byId[workflowTypeId];
                                    const project = this.props.projectsData.byId[workflowType.project];

                                    const passesSearch = !lowerCaseSearchText || workflowType.name.toLowerCase().includes(lowerCaseSearchText) || project.name.toLowerCase().includes(lowerCaseSearchText);

                                    return passesSearch && flowchartContext.projects.includes(workflowType.project);
                                }).map(workflowTypeId => {
                                    const workflowType = this.props.workflowTypesData.byId[workflowTypeId];
                                    const project = this.props.projectsData.byId[workflowType.project];
                                    return <ListItem key={workflowTypeId} name={workflowType.name} detail={project.name} value={workflowType.id} theme="aqua" onClick={this.props.setEntityType.bind(this, this.props.pieceId)} />
                                });

                            if (this.props.entityType) {
                                const selectedWorkflowType = this.props.workflowTypesData.byId[this.props.entityType];

                                if (selectedWorkflowType) {
                                    entityTypeText = selectedWorkflowType.name;
                                }
                            }

                            break;
                    }

                    const entityTypeSelectBox = <SelectBox theme="aqua" selectionPromptText={entityTypeText}>
                        <DropDownList theme="aqua" dismissAfterSelection={false}>
                            <div className={styles.nameContainer}>
                                <input className={styles.nameInput} onChange={this.searchForGetEntities} value={this.state.searchText} type="text" placeholder="Search" />
                            </div>
                            <ListItem name="Any" value="" theme="aqua" onClick={this.props.setEntityType.bind(this, this.props.pieceId)} />
                            {entityTypeOptions}
                        </DropDownList>
                    </SelectBox>;

                    let isValid = true;

                    if (flowchartContext.highlightIncompletePieces) {
                        const isIncomplete = !this.props.entity;
                        isValid = isValid && !isIncomplete;
                    }

                    this.updateValidity(isValid, flowchartContext);

                    let typeText = 'type';

                    if (this.props.entity === 'LOCATION') {
                        typeText = 'level';
                    } else if (this.props.entity === 'USER') {
                        typeText = 'role';
                    }

                    return (
                        <FlowchartPiece {...this.props}>
                            <div className={isValid ? styles.getEntitiesPieceWrapper : styles.invalidGetEntitiesPieceWrapper + ' ' + highlightClass}>
                                <div className={styles.text}>get</div>
                                {entitySelectBox}
                                {this.props.entity && <div className={styles.text}>of {typeText}</div>}
                                {this.props.entity && entityTypeSelectBox}
                                <div className={styles.text}>in and under location</div>
                                {this.props.locationPiece ? this.props.locationPiece : <Input placeholderText="Location (all by default)" minSize={21}
                                    canReceiveDrag={this.props.isDragging && this.state.isHoveringOverVariablePiece && !!this.props.targetPiece}
                                    onMouseOver={this.handleHoverOverLocationPiece}
                                    onMouseOut={this.handleHoverOutOfLocationPiece}
                                />}
                            </div>
                        </FlowchartPiece>
                    )
                }
            }
        </FlowchartContext.Consumer>

    }
}

const SetPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedSetPiece)

export default SetPiece;