import { NudgeData } from "./index";
import { ApplicationState } from "../../../shared/store/types";
import { NudgeType } from "../../../shared/store/my-data/types";
import { Permissions } from '../../../shared/store/permissions/types';

export function getGroupsListNudges(applicationState: ApplicationState): Array<NudgeData> {

    let nudgesData: Array<NudgeData> = [];

    if (applicationState.groups.isShowingAddForm || applicationState.groups.isShowingModifyForm) {
        nudgesData.push({
            id: NudgeType.GROUPS_MODIFY_GROUP_DETAILS,
            header: applicationState.groups.isShowingAddForm ? 'Add group details' : 'Update group details',
            content: `Enter content for groups add/edit details here`
        });

        return nudgesData;
    }

    if (applicationState.groups.isShowingCSVForm) {
        nudgesData.push({
            id: NudgeType.GROUPS_IMPORT_EXPORT_GROUP_DETAILS,
            header: 'Import/Export details',
            content: `Select a type. Click on "Export Table" and then "Export data" to export a CSV with group details.
            To import data, click on "Download template" to get the CSV template used for import. Fill up these details to import.`,
        });

        return nudgesData;
    }

    let hasGroupTypesToAdd = applicationState.myData.id === 'SuperUser';

    if (!hasGroupTypesToAdd) {
        const allowedGroupTypes = applicationState.groups.types.allEntries
        .filter(typeId => {
            const groupType = applicationState.groups.types.byId[typeId];
            const loggedInUser = applicationState.myData.id ? applicationState.users.byId[applicationState.myData.id] : undefined;
    
            if (loggedInUser) {
                return loggedInUser.projects.includes(groupType.project);
            } else {
                return true;
            }
        });

        for (const groupTypeId of allowedGroupTypes) {
    
            if (applicationState.permissions.myPermissions.groups[groupTypeId] === Permissions.WRITE || typeof applicationState.permissions.myPermissions.groups[groupTypeId] === 'undefined') {
                hasGroupTypesToAdd = true;
            }
        }
    }

    if (hasGroupTypesToAdd) {
        nudgesData = [{
            id: NudgeType.GROUPS_ADD_GROUP,
            header: 'Add group',
            content: 'Click on the add button on the top right to add a new group',
        }];
    }
    
    nudgesData = nudgesData.concat([{
        id: NudgeType.GROUPS_FILTER,
        header: 'Filter groups',
        content: 'Click on the filter button to filter the existing groups list',
    }, {
        id: NudgeType.GROUPS_SEARCH,
        header: 'Search groups',
        content: 'Type into the search bar to search the existing groups list',
    }, {
        id: NudgeType.GROUPS_IMPORT_EXPORT,
        header: 'Import/Export',
        content: 'Click this button to import groups into the app, or export groups in the form of a CSV.',
    }, {
        id: NudgeType.GROUPS_LIST_FIRE_WORKFLOW,
        header: 'Fire workflow for group',
        content: 'Fire a workflow for this group',
    }, {
        id: NudgeType.GROUPS_LIST_SHOW_MORE,
        header: 'Show more group options',
        content: 'Click on this button to show more options for the group',
    }]);

    return nudgesData;
}