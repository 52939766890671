import { FieldType, CustomField } from "../store/custom-fields/types";
import { ApplicationState } from "../store/types";
import { IWorkflow } from "../store/workflows/types";
import { getAllLocationsInTree } from "./locations";
import { getSearchStringsForGroup, getSearchStringsForMember, getSearchStringsForUser, getSearchStringsForWorkflow, doesMemberHaveSearchString } from "./search";
import { isUUID } from "./utilities";
import { getVisibleGroupIds, getVisibleMemberIds, getVisibleUserIds, getVisibleWorkflowIds, getVisibleReportIds } from "./visible-entities";
import moment from 'moment-timezone';

export function filterUsers(state: ApplicationState, userIds?: Array<string>) {
    let visibleUsers = userIds ? userIds : getVisibleUserIds(state);

    if (state.users.filters.unsynced) {
        visibleUsers = visibleUsers.filter(userId => state.users.createdIds.has(userId) || state.users.updatedIds.has(userId));
    }

    const allProcessedUsers = visibleUsers.map(userId => state.users.byId[userId]),
        userSearchTerm = state.users.searchTerm,
        filters = state.users.filters;

    let hasFilters = filters.projects.length > 0 || filters.levels.length > 0 || filters.roles.length > 0 || filters.locations.length > 0 || Object.keys(filters.customFields).length > 0;

    let effectiveLevels: Array<string> = [];
    let effectiveLocations: Array<string> = [];

    if (filters.areLowerLevelsIncluded) {
        effectiveLevels = filters.levels.map(levelId => {
            const level = state.structure.levels.byId[levelId];
            const project = state.structure.projects.byId[level.project];
            const levelIndex = project.children.indexOf(level.id);
            return project.children.slice(levelIndex)
        }).flat();

        effectiveLocations = filters.locations.map(locationId => getAllLocationsInTree(locationId)).flat();
        effectiveLocations = Array.from(new Set(effectiveLocations));
    } else {
        effectiveLevels = filters.levels;
        effectiveLocations = filters.locations;
    }

    let filteredUsers = allProcessedUsers.filter(user => {

        if (filters.isOnline && !user.isOnline) {
            return false;
        }

        if (filters.isBetaTester && !user.isBetaTester) {
            return false;
        }

        if (!hasFilters) {
            return true;
        }

        if (filters.projects.length > 0 && !filters.projects.some(projectId => user.projects.includes(projectId))) {
            return false;
        }

        if (effectiveLevels.length > 0 && !effectiveLevels.some(levelId => user.levels.includes(levelId))) {
            return false;
        }

        if (filters.roles.length > 0 && !filters.roles.some(roleId => user.roles.includes(roleId))) {
            return false;
        }

        if (effectiveLocations.length > 0 && !effectiveLocations.some(locationId => user.locations.includes(locationId))) {
            return false;
        }

        for (const customFieldId in filters.customFields) {
            if (customFieldId in filters.customFields && Array.isArray(filters.customFields[customFieldId])) {

                let customField: CustomField;
                const customFieldValue = user.customFields[customFieldId];

                if (customFieldId in state.users.customFields.byId) {
                    customField = state.users.customFields.byId[customFieldId];
                } else {
                    customField = state.structure.roles.customFields.byId[customFieldId];
                }

                if (customField.type === FieldType.SINGLE_SELECT) {
                    if (typeof customFieldValue !== 'string') {
                        return false;
                    }

                    if (filters.customFields[customFieldId].length > 0 && !filters.customFields[customFieldId].includes(customFieldValue)) {
                        return false;
                    }
                } else if (customField.type === FieldType.MULTI_SELECT) {
                    if (!Array.isArray(customFieldValue)) {
                        return false;
                    }

                    if (filters.customFields[customFieldId].length > 0 && !filters.customFields[customFieldId].some(filteredOption => customFieldValue.includes(filteredOption))) {
                        return false;
                    }
                }

            }
        }

        return true;
    });

    if (userSearchTerm) {
        const collapsedSearchTerm = userSearchTerm.replace(/\s\s+/g, ' ');  // Collapse all white spaces
        filteredUsers = filteredUsers.filter(user => {
            const searchStringsForUser = getSearchStringsForUser(user, state);
            return searchStringsForUser && searchStringsForUser.some(searchString => searchString.replace(/\s\s+/g, ' ').toLocaleLowerCase().includes(collapsedSearchTerm.toLocaleLowerCase()));
        });
    }

    return filteredUsers;
}

export function filterMembers(state: ApplicationState, memberIds?: Array<string>) {
    let visibleMembers = memberIds ? memberIds : getVisibleMemberIds(state);

    if (state.members.filters.unsynced) {
        visibleMembers = visibleMembers.filter(memberId => state.members.createdIds.has(memberId) || state.members.updatedIds.has(memberId));
    }

    const allProcessedMembers = visibleMembers.map(memberId => state.members.byId[memberId]),
        memberSearchTerm = state.members.searchTerm,
        filters = state.members.filters;

    let hasFilters = filters.projects.length > 0 || filters.types.length > 0 || filters.locations.length > 0 || Object.keys(filters.customFields).length > 0 || filters.createdDateRange.length > 0 || filters.lastUpdatedDateRange.length > 0;


    let effectiveLocations: Array<string> = [];

    if (filters.locations.length > 0) {
        effectiveLocations = filters.locations.map(locationId => getAllLocationsInTree(locationId)).flat();
        effectiveLocations = Array.from(new Set(effectiveLocations));
    }

    let filteredMembers = !hasFilters ? allProcessedMembers : allProcessedMembers.filter(member => {
        const memberType = state.members.types.byId[member.type];

        if (filters.projects.length > 0 && !filters.projects.includes(memberType.project)) {
            return false;
        }

        if (filters.types.length > 0 && !filters.types.includes(member.type)) {
            return false;
        }

        if (effectiveLocations.length > 0 && !effectiveLocations.includes(member.location)) {
            return false;
        }


        if (filters.createdDateRange.length > 1) {
            const createdOnStartDate = moment(filters.createdDateRange[0]).startOf('day');
            const createdOnEndDate = moment(filters.createdDateRange[1]).endOf('day');
            const createdDate = moment(member.createdTime);

            if (createdDate && (!createdDate.isSameOrAfter(createdOnStartDate) || !createdDate.isSameOrBefore(createdOnEndDate))) {
                return false;
            }
        }

        if (filters.lastUpdatedDateRange.length > 1) {
            const lastUpdatedOnStartDate = moment(filters.lastUpdatedDateRange[0]).startOf('day');
            const lastUpdatedOnEndDate = moment(filters.lastUpdatedDateRange[1]).endOf('day');
            const lastUpdatedDate = moment(member.lastUpdatedTime);

            if (lastUpdatedDate && (!lastUpdatedDate.isSameOrAfter(lastUpdatedOnStartDate) || !lastUpdatedDate.isSameOrBefore(lastUpdatedOnEndDate))) {
                return false;
            }
        }


        for (const customFieldId in filters.customFields) {
            if (customFieldId in filters.customFields && Array.isArray(filters.customFields[customFieldId])) {

                let customField = state.members.types.customFields.byId[customFieldId];
                const customFieldValue = member.customFields[customFieldId];

                if (customField.type === FieldType.SINGLE_SELECT) {
                    if (typeof customFieldValue !== 'string') {
                        return false;
                    }

                    if (filters.customFields[customFieldId].length > 0 && !filters.customFields[customFieldId].includes(customFieldValue)) {
                        return false;
                    }
                } else if (customField.type === FieldType.MULTI_SELECT) {
                    if (!Array.isArray(customFieldValue)) {
                        return false;
                    }

                    if (filters.customFields[customFieldId].length > 0 && !filters.customFields[customFieldId].some(filteredOption => customFieldValue.includes(filteredOption))) {
                        return false;
                    }
                }

            }
        }

        return true;
    });

    if (memberSearchTerm) {
        const collapsedSearchTerm = memberSearchTerm.replace(/\s\s+/g, ' ').toLocaleLowerCase();  // Collapse all white spaces
        filteredMembers = filteredMembers.filter(member => doesMemberHaveSearchString(member, state, collapsedSearchTerm));
    }

    return filteredMembers;
}

export function filterGroups(state: ApplicationState, groupIds?: Array<string>) {
    let visibleGroups: Array<string> = groupIds ? groupIds : getVisibleGroupIds(state);

    if (state.groups.filters.unsynced) {
        visibleGroups = visibleGroups.filter(userId => state.groups.createdIds.has(userId) || state.groups.updatedIds.has(userId));
    }

    const allProcessedGroups = visibleGroups.map(groupId => state.groups.byId[groupId]),
        groupSearchTerm = state.groups.searchTerm,
        filters = state.groups.filters;

    let hasFilters = filters.projects.length > 0 || filters.types.length > 0 || filters.locations.length > 0 || Object.keys(filters.customFields).length > 0 || filters.createdDateRange.length > 0 || filters.lastUpdatedDateRange.length > 0;

    let filteredGroups = !hasFilters ? allProcessedGroups : allProcessedGroups.filter(group => {
        const groupType = state.groups.types.byId[group.type];

        if (filters.projects.length > 0 && !filters.projects.includes(groupType.project)) {
            return false;
        }

        if (filters.types.length > 0 && !filters.types.includes(group.type)) {
            return false;
        }

        let effectiveLocations = filters.locations.map(locationId => getAllLocationsInTree(locationId)).flat();
        effectiveLocations = Array.from(new Set(effectiveLocations));

        if (effectiveLocations.length > 0 && !effectiveLocations.includes(group.location)) {
            return false;
        }

        if (filters.createdDateRange.length > 1) {
            const createdOnStartDate = moment(filters.createdDateRange[0]).startOf('day');
            const createdOnEndDate = moment(filters.createdDateRange[1]).endOf('day');
            const createdDate = moment(group.createdTime);

            if (createdDate && (!createdDate.isSameOrAfter(createdOnStartDate) || !createdDate.isSameOrBefore(createdOnEndDate))) {
                return false;
            }
        }

        if (filters.lastUpdatedDateRange.length > 1) {
            const lastUpdatedOnStartDate = moment(filters.lastUpdatedDateRange[0]).startOf('day');
            const lastUpdatedOnEndDate = moment(filters.lastUpdatedDateRange[1]).endOf('day');
            const lastUpdatedDate = moment(group.lastUpdatedTime);

            if (lastUpdatedDate && (!lastUpdatedDate.isSameOrAfter(lastUpdatedOnStartDate) || !lastUpdatedDate.isSameOrBefore(lastUpdatedOnEndDate))) {
                return false;
            }
        }

        for (const customFieldId in filters.customFields) {
            if (customFieldId in filters.customFields && Array.isArray(filters.customFields[customFieldId])) {

                let customField = state.groups.types.customFields.byId[customFieldId];
                const customFieldValue = group.customFields[customFieldId];

                if (customField.type === FieldType.SINGLE_SELECT) {
                    if (typeof customFieldValue !== 'string') {
                        return false;
                    }

                    if (filters.customFields[customFieldId].length > 0 && !filters.customFields[customFieldId].includes(customFieldValue)) {
                        return false;
                    }
                } else if (customField.type === FieldType.MULTI_SELECT) {
                    if (!Array.isArray(customFieldValue)) {
                        return false;
                    }

                    if (filters.customFields[customFieldId].length > 0 && !filters.customFields[customFieldId].some(filteredOption => customFieldValue.includes(filteredOption))) {
                        return false;
                    }
                }

            }
        }

        return true;
    });

    if (groupSearchTerm) {
        const collapsedSearchTerm = groupSearchTerm.replace(/\s\s+/g, ' ');  // Collapse all white spaces
        filteredGroups = filteredGroups.filter(group => {
            const searchStringsForGroup = getSearchStringsForGroup(group, state);
            return searchStringsForGroup && searchStringsForGroup.some(searchString => searchString.replace(/\s\s+/g, ' ').toLocaleLowerCase().includes(collapsedSearchTerm.toLocaleLowerCase()));
        });
    }

    return filteredGroups;
}

export function filterWorkflows(state: ApplicationState, workflows?: Array<IWorkflow>, maximumNumberOfFlows?: number) {
    let allProcessedWorkflows: Array<IWorkflow> = [];

    if (!workflows) {
        const allVisibleWorkflowIds = getVisibleWorkflowIds(state);
        allProcessedWorkflows = allVisibleWorkflowIds.map(workflowId => state.workflows.byId[workflowId]);
    } else {
        allProcessedWorkflows = workflows;
    }

    if (state.workflows.filters.unsynced) {
        allProcessedWorkflows = allProcessedWorkflows.filter(workflow => state.workflows.createdIds.has(workflow.id) || state.workflows.updatedIds.has(workflow.id));
    }

    if (state.workflows.filters.archived) {
        allProcessedWorkflows = allProcessedWorkflows.filter(workflow => !!workflow.archived)
    } else {
        allProcessedWorkflows = allProcessedWorkflows.filter(workflow => !workflow.archived)
    }

    const workflowSearchTerm = state.workflows.searchTerm,
        filters = state.workflows.filters;

    let hasFilters = filters.dues.length > 0 || filters.projects.length > 0 || filters.types.length > 0 || filters.users.length > 0 || filters.locations.length > 0 || filters.otherUsers.length > 0 || filters.statuses.length > 0 || filters.affiliations.length > 0 || Object.keys(filters.customFields).length > 0 || filters.createdDateRange.length === 2 || filters.lastUpdatedDateRange.length > 0 || filters.dueDateRange.length === 2 || filters.isOutdated || (filters.lastWorkedOn.startTime && filters.lastWorkedOn.endTime);

    let filteredWorkflows: Array<IWorkflow> = [];

    if (!hasFilters && !workflowSearchTerm) {
        return allProcessedWorkflows;
    };


    workflowsloop:
    for (const workflow of allProcessedWorkflows) {
        const today = moment().tz('Asia/Kolkata').format('YYYY-MM-DD');;
        const workflowType = state.workflows.types.byId[workflow.type];

        if (workflowSearchTerm) {
            const collapsedSearchTerm = workflowSearchTerm.replace(/\s\s+/g, ' ');  // Collapse all white spaces
            const searchStringsForWorkflow = getSearchStringsForWorkflow(workflow, state);
            const hasSearchTerm = searchStringsForWorkflow && searchStringsForWorkflow.some(searchString => searchString.replace(/\s\s+/g, ' ').toLocaleLowerCase().includes(collapsedSearchTerm.toLocaleLowerCase()));

            if (!hasSearchTerm) {
                continue;
            }
        }

        if (hasFilters) {
            if (filters.dues.length > 0 && filters.dues.length < 3) {
                const workflowStatus = state.workflows.types.statuses.byId[workflow.status];

                if (!workflowStatus) {
                    continue;
                }

                const areSomeDueOptionsPassed = filters.dues.some(dueOption => {

                    switch (dueOption) {
                        case 'due':
                            return !workflowStatus.isTerminal && workflow.dueDate && workflow.dueDate >= today;
                        case 'overdue':
                            return !workflowStatus.isTerminal && workflow.dueDate && workflow.dueDate < today;
                        case 'completed':
                            return workflowStatus.isTerminal;
                        default:
                            return false;
                    }
                });

                if (!areSomeDueOptionsPassed) {
                    continue;
                }
            }

            if (filters.users.length > 0) {
                const areSomeFilterOptionsPassed = filters.users.some(userOption => {

                    switch (userOption) {
                        case 'my':
                            return !isUUID(state.myData.id) || workflow.user === state.myData.id || workflow.trackingUsers.includes(state.myData.id);
                        case 'other':
                            if (filters.otherUsers.length > 0 && !filters.otherUsers.includes(workflow.user)) {
                                return false;
                            }

                            return workflow.user !== state.myData.id;
                        default:
                            return false;
                    }
                });

                if (!areSomeFilterOptionsPassed) {
                    continue;
                }
            }

            if (filters.projects.length > 0 && !filters.projects.includes(workflowType.project)) {
                continue;
            }

            if (filters.types.length > 0 && !filters.types.includes(workflow.type)) {
                continue;
            }

            if (filters.statuses.length > 0 && !filters.statuses.includes(workflow.status)) {
                continue;
            }

            if (filters.locations.length > 0) {
                let effectiveLocations = filters.locations.map(locationId => getAllLocationsInTree(locationId)).flat();
                effectiveLocations = Array.from(new Set(effectiveLocations));

                let workflowLocations: Array<string> = [];

                if (workflow.affiliatedEntity) {
                    if (workflow.affiliatedEntity in state.groups.byId) {
                        const group = state.groups.byId[workflow.affiliatedEntity];
                        workflowLocations.push(group.location);
                    } else if (workflow.affiliatedEntity in state.members.byId) {
                        const member = state.members.byId[workflow.affiliatedEntity];
                        workflowLocations.push(member.location);
                    }
                } else {
                    if (workflow.user in state.users.byId) {
                        const user = state.users.byId[workflow.user];
                        for (const locationId of user.locations) {
                            workflowLocations.push(locationId);
                        }
                    };
                };


                if (!workflowLocations.some(workflowLocation => effectiveLocations.includes(workflowLocation))) {
                    continue;
                }
            }

            if (filters.affiliations.length > 0 && !filters.affiliations.includes(workflow.affiliatedEntity)) {
                continue;
            }

            if (filters.createdDateRange.length > 1) {
                const createdOnStartDate = moment(filters.createdDateRange[0]).startOf('day');
                const createdOnEndDate = moment(filters.createdDateRange[1]).endOf('day');
                const createdDate = moment(workflow.createdTime);

                if (createdDate && (!createdDate.isSameOrAfter(createdOnStartDate) || !createdDate.isSameOrBefore(createdOnEndDate))) {
                    continue;
                }
            }

            if (filters.lastUpdatedDateRange.length > 1) {
                const lastUpdatedOnStartDate = moment(filters.lastUpdatedDateRange[0]).startOf('day');
                const lastUpdatedOnEndDate = moment(filters.lastUpdatedDateRange[1]).endOf('day');
                const lastUpdatedDate = moment(workflow.lastUpdatedTime);


                if (lastUpdatedDate && (!lastUpdatedDate.isSameOrAfter(lastUpdatedOnStartDate) || !lastUpdatedDate.isSameOrBefore(lastUpdatedOnEndDate))) {
                    continue;
                }
            }

            if (filters.lastWorkedOn.startTime && filters.lastWorkedOn.endTime) {
                const lastWorkedOnStartDate = moment(filters.lastWorkedOn.startTime).startOf('day');
                const lastWorkedOnEndDate = moment(filters.lastWorkedOn.endTime).endOf('day');
                const workflowLastWorkedOnTime = moment(workflow.lastWorkedOnTime);

                if (workflowLastWorkedOnTime && (!workflowLastWorkedOnTime.isSameOrAfter(lastWorkedOnStartDate) || !workflowLastWorkedOnTime.isSameOrBefore(lastWorkedOnEndDate))) {
                    continue;
                }
            }

            if (filters.dueDateRange.length > 1) {
                const workflowDueDate = moment(workflow.dueDate);
                const filterFromDueDate = moment(filters.dueDateRange[0]).startOf('day');
                const filterToDueDate = moment(filters.dueDateRange[1]).endOf('day');

                if (workflowDueDate && (!workflowDueDate.isSameOrAfter(filterFromDueDate) || !workflowDueDate.isSameOrBefore(filterToDueDate))) {
                    continue;
                }
            }

            if (filters.isOutdated) {
                if (!workflow.startPieceId) {
                    continue;
                }

                const startPiece = workflowType.startPiece;
                const betaStartPiece = workflowType.betaStartPiece;
                const startPieceId = workflow.startPieceId;
                const isOutDatedWorkflow = startPieceId !== startPiece.piece && startPieceId !== betaStartPiece.piece;
                if (!isOutDatedWorkflow) {
                    continue;
                }
            }

            customfieldsloop:
            for (const customFieldId in filters.customFields) {
                if (customFieldId in filters.customFields && Array.isArray(filters.customFields[customFieldId])) {

                    let customField = state.workflows.types.customFields.byId[customFieldId];
                    const workflowProcessState = workflow.historyIndex >= workflow.history.length ? workflow.history[workflow.history.length - 1] : workflow.history[workflow.historyIndex];
                    const customFieldValue = workflowProcessState.customFields[customFieldId];

                    if (customField.type === FieldType.SINGLE_SELECT) {
                        if (typeof customFieldValue !== 'string') {
                            continue workflowsloop;
                        }

                        if (filters.customFields[customFieldId].length > 0 && !filters.customFields[customFieldId].includes(customFieldValue)) {
                            continue workflowsloop;
                        }
                    } else if (customField.type === FieldType.MULTI_SELECT) {
                        if (!Array.isArray(customFieldValue)) {
                            continue workflowsloop;
                        }

                        if (filters.customFields[customFieldId].length > 0 && !filters.customFields[customFieldId].some(filteredOption => customFieldValue.includes(filteredOption))) {
                            continue workflowsloop;
                        }
                    }

                }
            }
        }

        filteredWorkflows.push(workflow);

        if (maximumNumberOfFlows && filteredWorkflows.length >= maximumNumberOfFlows) {
            break;
        }
    };


    return filteredWorkflows;
}

export function filterReports(state: ApplicationState) {
    let visibleReportIds: Array<string> = getVisibleReportIds(state);

    if (state.reports.filters.unsynced) {
        visibleReportIds = visibleReportIds.filter(reportId => state.reports.createdIds.has(reportId) || state.reports.updatedIds.has(reportId));
    }

    const allProcessedReports = visibleReportIds.map(reportId => state.reports.byId[reportId]),
        reportSearchTerm = state.reports.searchTerm,
        filters = state.reports.filters;

    let hasFilters = filters.projects.length > 0 || filters.users.length > 0 || filters.types.length > 0 || filters.createdDateRange.length > 0 || filters.lastUpdatedDateRange.length > 0 || filters.generatedDateRange.length > 0;

    let filteredReports = !hasFilters ? allProcessedReports : allProcessedReports.filter(report => {
        const reportType = state.reports.types.byId[report.type];

        if (filters.projects.length > 0 && !filters.projects.includes(reportType.project)) {
            return false;
        }

        if (filters.users.length > 0 && !filters.users.includes(report.user)) {
            return false;
        }

        if (filters.types.length > 0 && !filters.types.includes(report.type)) {
            return false;
        }

        if (filters.createdDateRange.length > 1) {
            const createdOnStartDate = filters.createdDateRange[0].substring(0, 10);
            const createdOnEndDate = filters.createdDateRange[1].substring(0, 10);
            const createdDate = report.createdTime.substring(0, 10);

            if (createdDate < createdOnStartDate || createdDate > createdOnEndDate) {
                return false;
            }
        }

        if (filters.lastUpdatedDateRange.length > 1) {
            const lastUpdatedOnStartDate = filters.lastUpdatedDateRange[0].substring(0, 10);
            const lastUpdatedOnEndDate = filters.lastUpdatedDateRange[1].substring(0, 10);
            const lastUpdatedDate = report.lastUpdatedTime.substring(0, 10);

            if (lastUpdatedDate < lastUpdatedOnStartDate || lastUpdatedDate > lastUpdatedOnEndDate) {
                return false;
            }
        }

        if (filters.generatedDateRange.length > 1) {
            if (report.generatedTime && (report.generatedTime < filters.generatedDateRange[0] || report.generatedTime > filters.generatedDateRange[1])) {
                return false;
            }
        };

        if (filters.generatedDateRange.length > 1) {
            if (!(report.generatedTime)) {
                return false
            }
        }

        return true;
    });

    if (reportSearchTerm) {
        const collapsedSearchTerm = reportSearchTerm.replace(/\s\s+/g, ' ');  // Collapse all white spaces
        filteredReports = filteredReports.filter(report => {
            const reportType = state.reports.types.byId[report.type];
            const user = state.users.byId[report.user];
            const userName = String(user.customFields[state.users.nameFieldId]);
            const searchStringsForReport: Array<string> = [report.name, reportType.name, userName];

            searchStringsForReport.push(moment(report.startDate).format('DD MMM YYYY'));
            searchStringsForReport.push(moment(report.endDate).format('DD MMM YYYY'));

            searchStringsForReport.push(moment(report.createdTime).format('DD MMM YYYY, hh:mm:ss A'));
            searchStringsForReport.push(moment(report.lastUpdatedTime).format('DD MMM YYYY, hh:mm:ss A'));

            if (report.generatedTime) {
                searchStringsForReport.push(moment(report.generatedTime).format('DD MMM YYYY, hh:mm:ss A'));
            }

            return searchStringsForReport && searchStringsForReport.some(searchString => searchString.replace(/\s\s+/g, ' ').toLocaleLowerCase().includes(collapsedSearchTerm.toLocaleLowerCase()));
        });
    }

    return filteredReports;
}