import React, { Component, ChangeEvent } from 'react';
import styles from './step-piece/StepPiece.module.scss';
import Input from '../Input';
import StepPiece from './step-piece/StepPiece'

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';
import { Option } from '../drop-down/ListItem';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setDataSetValue, setVariableForSet, updateVariablePiece } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import { valuePieceSlotTarget } from './utilities';
import VariableModify from './VariableModify';
import { IUpdateableVariableData } from '../../../shared/store/flowchart/variables/types';
import { NestingData } from '../../../shared/store/flowchart/pieces/types';
import { addVariable } from '../../../shared/store/flowchart/variables/actions';
import { FlowchartContext, FlowchartInfoForPiece } from '../../../contexts/flowchart-context';


type SetPieceProps = {
    nextPiece?: JSX.Element,
    variables: Array<Option>,
    settingVariableId?: string,

    variablePiece?: JSX.Element,
    dataToSet?: string,

    registerVariable?: (variableId: string) => void,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        variablesData: state.flowchart.variables,
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,
        variableData: state.flowchart.variables,
    }
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: SetPieceProps & FlowchartPieceProps) => {

    return {
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
        setDataSetValue: (targetPieceId: string, draggedPieceId: string) => dispatch(setDataSetValue(targetPieceId, draggedPieceId)),
        setVariableForSet: (pieceId: string, value: string) => dispatch(setVariableForSet(pieceId, value)),
        
        updateVariablePiece: (variable: string|undefined, nesting: Array<NestingData>|undefined) => dispatch(updateVariablePiece(ownProps.pieceId, variable, nesting)),
        addVariable: (variableData: IUpdateableVariableData) => dispatch(addVariable(variableData)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = SetPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type SetPieceState = {
    isHoveringOverVariablePiece: boolean,
    isAddingVariable: boolean,
    searchText: string,
}

class ConnectedSetPiece extends Component<Props, SetPieceState> {

    state = {
        isHoveringOverVariablePiece: false,
        isAddingVariable: false,
        searchText: '',
    };

    handleHoverOverVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: false,
        });
    };

    handleSetValueUpdate = (value: string) => {
        this.props.setDataSetValue(this.props.pieceId, value);
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && this.state.isHoveringOverVariablePiece) {

            this.props.setDataSetValue(this.props.pieceId, this.props.lastDraggedPiece.id);
            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverVariablePiece: false,
            });
        }
    }

    searchForVariable = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            searchText: e.target.value,
        });
    }

    submitVariableData = (variableData: IUpdateableVariableData) => {
        this.props.addVariable(variableData);
        this.props.registerVariable && this.props.registerVariable(variableData.id);
        this.setState({
            isAddingVariable: false,
        });
    }

    hideVariableForm = () => {
        this.setState({
            isAddingVariable: false,
        });
    }

    showVariableForm = () => {
        this.setState({
            isAddingVariable: true,
        });
    }

    updateValidity = (isValid: boolean, flowchartContext: FlowchartInfoForPiece) => {
        if (!isValid) {
            const invalidPieceInfo = flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId);

            if (!invalidPieceInfo && flowchartContext.setInvalidPiece) {
                flowchartContext.setInvalidPiece(this.props.pieceId, flowchartContext.parentSplitPieceIds);
            }
        }

        if (isValid && flowchartContext.removeInvalidPiece && flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId)) {
            flowchartContext.removeInvalidPiece(this.props.pieceId);
        }
    }

    render() {

        return <FlowchartContext.Consumer>
            {
                (flowchartContext) => {
                    const pieceFooter = this.state.isAddingVariable ? <VariableModify submit={this.submitVariableData} cancel={this.hideVariableForm} /> : <section className={styles.addPrompt} onClick={this.showVariableForm}>+ Add Variable</section>;

                    const settingVariableName = this.props.settingVariableId && this.props.settingVariableId in this.props.variableData.byId ? this.props.variableData.byId[this.props.settingVariableId].name : undefined;
            
                    const variableSelectBox = <SelectBox theme="indigo" selectionPromptText={settingVariableName}>
                        <DropDownList footer={pieceFooter} theme="indigo" dismissAfterSelection={false}>
                            <div className={styles.searchBoxHolder + ' ignore-options-onclickoutside'}>
                                <input className={styles.searchBox} onChange={this.searchForVariable} value={this.state.searchText} type="text" placeholder="Search by name" />
                            </div>
                            {this.props.variables.filter(variable => variable.name.toLocaleLowerCase().includes(this.state.searchText.toLocaleLowerCase()))
                            .map((variable, index) => {
                                const variableData = this.props.variablesData.byId[variable.value];
                                return <ListItem name={variable.name} detail={variableData.type} value={variable.value} key={index} theme="indigo" onClick={this.props.setVariableForSet.bind(this, this.props.pieceId)} />
                            })}
                        </DropDownList>
                    </SelectBox>;
            
                    let isValid = !this.props.settingVariableId || !!this.props.variables.find(option => option.value === this.props.settingVariableId);
                                
                    if (flowchartContext.highlightIncompletePieces) {
                        const isIncomplete = !this.props.settingVariableId;
                        isValid = isValid && !isIncomplete;
                    }

                    this.updateValidity(isValid, flowchartContext);
                
                    return (
                        <StepPiece theme={isValid ? "indigo" : "red"} {...this.props}>
                            <div className={styles.text}>set</div>
                            {variableSelectBox}
                            <div className={styles.text}>to</div>
                            {this.props.variablePiece ? this.props.variablePiece : <Input minSize={12} placeholderText="Empty value" canReceiveDrag={this.props.isDragging && this.state.isHoveringOverVariablePiece && !!this.props.targetPiece} onMouseOver={this.handleHoverOverVariablePiece} onMouseOut={this.handleHoverOutOfVariablePiece} defaultText={this.props.dataToSet} onChange={this.handleSetValueUpdate} />}
                        </StepPiece>
                    )
                }
            }
        </FlowchartContext.Consumer>
        
    }
}

const SetPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedSetPiece)

export default SetPiece;