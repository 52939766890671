import { Component } from "react";
import onClickOutside from "react-onclickoutside";
import styles from "./RecentSearchesAndFilters.module.scss";
import InputText from '../../widgets/form/InputText';
import { translatePhrase } from '../../shared/helpers/translation';

import { ReactComponent as CloseIcon } from '../../common/assets/close.svg';
import { ReactComponent as TrashIcon } from '../../assets/trash.svg';
import { ReactComponent as SearchIcon } from '../../assets/search.svg';
import { ReactComponent as HistoryIcon } from '../../../src/common/assets/timeline.svg';
import { ReactComponent as PlusIcon } from '../../../src/assets/new-custom-icons/dashboard/plus.svg';

import Button from "../button/CommonButton";
import { SearchAndFilterType } from "./TableWithMeta";

interface OwnProps {
    isSearchHighlighted?: boolean;
    searchTerm?: string;
    recentSearchTerms: string[];
    stringfiedFilterTerms: string[];
    recentFilterTerms: string[][];
    recentFiltersObj: any[];
    filterTags?: string[],
    groupsFilters: any;
    membersFilters: any;
    workflowsFilters: any;
    usersFilters: any;
    reportFilters: any;
    entityType: string;
    isBlur: boolean;
    isRemoveFilterTag: boolean;
    storeRecentSearchAndFilterTerms: (type: "search" | "filter") => void;
    clearRecentSearches: () => void;
    clearRecentFilters: () => void;

    removeRecentSearchTerm: (searchTerm: string) => void;

    handleSearch: (searchTerm: string) => void;
    reindexTable?: () => void;
    applyWorkflowFilter: (selectedFilter: any) => void;
    applyMemberFilter: (selectedFilter: any) => void;
    applyGroupFilter: (selectedFilter: any) => void;
    applyUserFilter: (selectedFilter: any) => void;
    applyReportFIlter: (selectedFilter: any) => void;

}

interface OwnState {
    isOpen: boolean;
    defaultValue: string | undefined;
    inputKey: number;
    selectedIndex: number;
    selectedFilter: any;
}

type Props = OwnProps;

class RecentSearchesAndFilters extends Component<Props, OwnState> {
    constructor(props: Readonly<Props>) {
        super(props);
        this.state = {
            isOpen: false,
            defaultValue: props.searchTerm,
            inputKey: 0,
            selectedIndex: -1,
            selectedFilter: {},
        };
    }

    handleClickOutside = (event: MouseEvent) => {
        this.setState({
            isOpen: false,
        });
        this.props.storeRecentSearchAndFilterTerms("search");
    }

    openRecentSearches = () => {
        if (this.props.recentSearchTerms.length <= 0 && this.props.recentFilterTerms.length <= 0) return;

        this.setState({
            isOpen: true,
        });
    }

    closeRecentSearches = () => {
        this.setState({
            isOpen: false,
        });
        this.props.storeRecentSearchAndFilterTerms("search");
    }

    removeSelectedTerm = (searchTerm: string) => {
        this.props.removeRecentSearchTerm(searchTerm);
    }

    selectSearchTerm = (searchTerm: string) => {
        if (this.props.searchTerm === searchTerm) searchTerm = "";

        this.props.handleSearch(searchTerm);

        this.setState(prevState => {
            return {
                defaultValue: searchTerm,
                inputKey: prevState.inputKey + 1,
                isOpen: false
            }
        });
    }

    selectedFilter = (selectedFilter: any) => {
        this.setState({
            selectedFilter: selectedFilter,
            isOpen: false
        });
        switch (this.props.entityType) {
            case SearchAndFilterType.GROUP:
                this.applyGroupFilter(selectedFilter);
                break;
            case SearchAndFilterType.MEMBER:
                this.applyMemberFilter(selectedFilter);
                break;
            case SearchAndFilterType.WORKFLOW:
                this.applyWorkflowFilter(selectedFilter);
                break;
            case SearchAndFilterType.USER:
                this.applyUserFilter(selectedFilter);
                break;
            case SearchAndFilterType.REPORT:
                this.applyReportFIlter(selectedFilter);
                break;
        }
    }

    applyGroupFilter = (selectedFilter: any) => {
        if (JSON.stringify(selectedFilter) === JSON.stringify(this.props.groupsFilters)) {
            this.props.applyGroupFilter({
                projects: [], types: [], locations: [], customFields: {},
                createdDateRange: [], lastUpdatedDateRange: [], unsynced: false, archived: false
            });
            this.setState({ selectedFilter: {} });
            return;
        }
        this.props.applyGroupFilter(selectedFilter);
    }

    applyMemberFilter = (selectedFilter: any) => {
        if (JSON.stringify(selectedFilter) === JSON.stringify(this.props.membersFilters)) {
            this.props.applyMemberFilter({
                projects: [], types: [], locations: [], customFields: {}, createdDateRange: [],
                lastUpdatedDateRange: [], unsynced: false, archived: false
            });
            this.setState({ selectedFilter: {} });
            return;
        }
        this.props.applyMemberFilter(selectedFilter);
    }

    applyWorkflowFilter = (selectedFilter: any) => {
        if (JSON.stringify(selectedFilter) === JSON.stringify(this.props.workflowsFilters)) {
            this.props.applyWorkflowFilter({
                dues: [], projects: [], types: [], statuses: [], users: [],
                locations: [], otherUsers: [], affiliations: [],
                customFields: {}, createdDateRange: [], lastUpdatedDateRange: [], lastWorkedOn: {}, dueDateRange: [], unsynced: false, archived: false
            });
            this.setState({ selectedFilter: {} });
            return;
        }
        this.props.applyWorkflowFilter(selectedFilter);
    }

    applyUserFilter = (selectedFilter: any) => {
        if (JSON.stringify(selectedFilter) === JSON.stringify(this.props.usersFilters)) {
            this.props.applyUserFilter({
                projects: [], levels: [], roles: [], locations: [], unsynced: false,
                areLowerLevelsIncluded: true, archived: false, isOnline: false, isBetaTester: false, customFields: {}
            });
            this.setState({ selectedFilter: {} });
            return;
        }
        this.props.applyUserFilter(selectedFilter);
    }

    applyReportFIlter = (selectedFilter: any) => {
        if (JSON.stringify(selectedFilter) === JSON.stringify(this.props.reportFilters)) {
            this.props.applyReportFIlter({
                projects: [], types: [], users: [], createdDateRange: [], lastUpdatedDateRange: [],
                generatedDateRange: [], unsynced: false, archived: false
            });
            this.setState({ selectedFilter: {} });
            return;
        }
        this.props.applyReportFIlter(selectedFilter);
    }

    toggleRecentSearchesAndFilters = () => {
        setTimeout(() => {
            this.setState({ isOpen: !this.state.isOpen });
        }, 200);
    }

    componentDidMount() {
        switch (this.props.entityType) {
            case SearchAndFilterType.GROUP:
                if (this.props.groupsFilters) this.setState({ selectedFilter: this.props.groupsFilters });
                break;
            case SearchAndFilterType.MEMBER:
                if (this.props.membersFilters) this.setState({ selectedFilter: this.props.membersFilters });
                break;
            case SearchAndFilterType.WORKFLOW:
                if (this.props.workflowsFilters) this.setState({ selectedFilter: this.props.workflowsFilters });
                break;
            case SearchAndFilterType.USER:
                if (this.props.usersFilters) this.setState({ selectedFilter: this.props.usersFilters });
                break;
            case SearchAndFilterType.REPORT:
                if (this.props.reportFilters) this.setState({ selectedFilter: this.props.reportFilters });
                break;
        }
    }

    componentDidUpdate(prevProps: Props) {
        switch (this.props.entityType) {
            case SearchAndFilterType.GROUP:
                if (!this.isObjectEqual(prevProps.groupsFilters, this.props.groupsFilters)) {
                    this.setState({ selectedFilter: this.props.groupsFilters });
                }
                break;
            case SearchAndFilterType.MEMBER:
                if (!this.isObjectEqual(prevProps.membersFilters, this.props.membersFilters)) {
                    this.setState({ selectedFilter: this.props.membersFilters });
                }
                break;
            case SearchAndFilterType.WORKFLOW:
                if (!this.isObjectEqual(prevProps.workflowsFilters, this.props.workflowsFilters)) {
                    this.setState({ selectedFilter: this.props.workflowsFilters });
                }
                break;
            case SearchAndFilterType.USER:
                if (!this.isObjectEqual(prevProps.usersFilters, this.props.usersFilters)) {
                    this.setState({ selectedFilter: this.props.usersFilters });
                }
                break;
            case SearchAndFilterType.REPORT:
                if (!this.isObjectEqual(prevProps.reportFilters, this.props.reportFilters)) {
                    this.setState({ selectedFilter: this.props.reportFilters });
                }
                break;
        }

        if (this.props.isRemoveFilterTag !== prevProps.isRemoveFilterTag) {
            this.setState({ selectedFilter: undefined });
        }
    }

    isObjectEqual = (objectA: any, objectB: any) => {
        return JSON.stringify(objectA) === JSON.stringify(objectB);
    }

    render() {

        const recentSearchTags = this.props.recentSearchTerms.map(searchTerm => {
            return (
                <div key={searchTerm} title={searchTerm} className={styles.searchTag + " " + (this.props.searchTerm === searchTerm ? styles.active : "")} onClick={() => {
                    this.selectSearchTerm(searchTerm);
                    console.log("called");
                }}>
                    <div className={styles.searchTerm}>{searchTerm}</div>
                    {this.props.searchTerm !== searchTerm &&
                        <div className={styles.searchIcon}>
                            <SearchIcon />
                        </div>}
                    {this.props.searchTerm && this.props.searchTerm === searchTerm &&
                        <div className={styles.closeIcon}>
                            <CloseIcon />
                        </div>}
                </div>
            );
        });

        const recentFilterTags = this.props.stringfiedFilterTerms.map((filter, index) => {
            return (
                <div key={filter}
                    title={filter}
                    className={styles.filterTag + " " + (JSON.stringify(this.state.selectedFilter) === JSON.stringify(this.props.recentFiltersObj[index]) ? styles.active : "")}
                    onClick={() => this.selectedFilter(this.props.recentFiltersObj[index])}>
                    <div className={styles.filterTerm}>{filter}</div>
                    {JSON.stringify(this.state.selectedFilter) !== JSON.stringify(this.props.recentFiltersObj[index]) &&
                        <div className={styles.plusIcon}>
                            <PlusIcon />
                        </div>}
                    {this.props.recentFiltersObj && JSON.stringify(this.state.selectedFilter) === JSON.stringify(this.props.recentFiltersObj[index]) &&
                        <div className={styles.closeIcon}>
                            <CloseIcon />
                        </div>}
                </div>
            )
        })

        return (
            <div className={styles.searchHolder + " " +
                ((this.state.isOpen && (this.props.recentSearchTerms.length > 0 || this.props.stringfiedFilterTerms.length > 0)) ? styles.active : "") + " " +
                (this.props.isBlur ? styles.blur : "")}>
                <div className={styles.searchContainer}>
                    <div className={styles.searchBoxHolder}>
                        <div className={styles.searchBox} >
                            <InputText
                                key={this.state.inputKey}
                                isHighlighted={this.props.isSearchHighlighted}
                                type='search'
                                placeholder={translatePhrase('Search')}
                                default={this.state.defaultValue}
                                onChange={this.props.handleSearch}
                                hideInputPlaceholder={false}
                                focusColor={'pink'}
                                onEnterPress={() => this.props.storeRecentSearchAndFilterTerms("search")} />
                        </div>
                        {
                            this.state.isOpen &&
                            <div className={styles.closeButton}>
                                <Button
                                    title={translatePhrase("Close")}
                                    icon={<CloseIcon />}
                                    onClick={() => this.closeRecentSearches()}
                                    isRounded={true}
                                    size={"small"}
                                    color={"primary"}
                                    type={"primary"} />
                            </div>
                        }
                        {
                            !this.state.isOpen &&
                            (this.props.recentSearchTerms.length > 0 || this.props.stringfiedFilterTerms.length > 0) &&
                            <Button
                                title={translatePhrase("Recent Filters/Searches")}
                                icon={<HistoryIcon />}
                                onClick={() => this.openRecentSearches()}
                                isRounded={true}
                                size={"small"}
                                color={"primary"}
                                type={"primary"}
                                padding={"0px 2px 0px 0px"} />
                        }
                    </div>
                    <div className={styles.recentSearchAndFilterHolder}>
                        {this.props.recentSearchTerms.length > 0 && this.state.isOpen &&
                            <div className={styles.recentSearches}>
                                <div className={styles.heading}>
                                    <h4>{translatePhrase("Recent Searches")}</h4>
                                    <div className={styles.trashIcon} onClick={() => { this.props.clearRecentSearches(); this.closeRecentSearches(); }}>
                                        <TrashIcon />
                                    </div>
                                </div>
                                <div className={styles.tagsHolder}>
                                    {recentSearchTags}
                                </div>
                            </div>}
                        {this.props.stringfiedFilterTerms.length > 0 && this.state.isOpen &&
                            <div className={styles.recentFilters}>
                                <div className={styles.heading}>
                                    <h4>{translatePhrase("Recent Filters")}</h4>
                                    <div className={styles.trashIcon} onClick={() => { this.props.clearRecentFilters(); this.closeRecentSearches() }}>
                                        <TrashIcon />
                                    </div>
                                </div>
                                <div className={styles.tagsHolder}>
                                    {recentFilterTags}
                                </div>
                            </div>}
                    </div>
                </div>
            </div>
        )
    }
};

const EnhancedRecentSearchesAndFiltersModify = onClickOutside(RecentSearchesAndFilters, {
    excludeScrollbar: true
});

export default EnhancedRecentSearchesAndFiltersModify;
