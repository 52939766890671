import React, { Component } from 'react';
import styles from './SyncSummary.module.scss';
import { translatePhrase } from '../../../../shared/helpers/translation';
import moment from 'moment';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../../shared/store/types';
import { connect } from 'react-redux';
import { SyncRecord } from '../../../../shared/helpers/synchronize/types';
interface OwnProps {
    syncRecord: SyncRecord;
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    const userData = state.users;
    const name = state.users.byId[ownProps.syncRecord.user].customFields[state.users.nameFieldId];
    
    return {
        name,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = StateProps & DispatchProps & OwnProps;

interface OwnState {

}

class ConnectedSyncSummary extends Component<Props, OwnState> {
    render() {

        const syncRecordSummary = this.props.syncRecord.summary;

        return <section className={styles.syncSummaryDetails}>
            <div className={styles.label}>{translatePhrase('Name')}</div>
            <div className={styles.value}>{this.props.name}</div>

            <div className={styles.label}>{translatePhrase('Time')}</div>
            <div className={styles.value}>{moment(this.props.syncRecord.syncTime).format('DD MMM YYYY, hh:mm:ss A')}</div>

            <div className={styles.label}>{translatePhrase('Users')}</div>
            <div className={styles.value}>
                {syncRecordSummary.createdUserCount !== 0 && <span className={styles.segment}>{translatePhrase('Created')}: {syncRecordSummary.createdUserCount}</span>}
                {syncRecordSummary.updatedUserCount !== 0 && <span className={styles.segment}>{translatePhrase('Updated')}: {syncRecordSummary.updatedUserCount}</span>}
                {syncRecordSummary.archivedUserCount !== 0 && <span className={styles.segment}>{translatePhrase('Archived')}: {syncRecordSummary.archivedUserCount}</span>}
                {syncRecordSummary.createdUserCount === 0 && syncRecordSummary.updatedUserCount === 0 && syncRecordSummary.archivedUserCount === 0 && '-'}
            </div>

            <div className={styles.label}>{translatePhrase('Members')}</div>
            <div className={styles.value}>
            {syncRecordSummary.createdMemberCount !== 0 && <span className={styles.segment}>{translatePhrase('Created')}: {syncRecordSummary.createdMemberCount}</span>}
                {syncRecordSummary.updatedMemberCount !== 0 && <span className={styles.segment}>{translatePhrase('Updated')}: {syncRecordSummary.updatedMemberCount}</span>}
                {syncRecordSummary.archivedMemberCount !== 0 && <span className={styles.segment}>{translatePhrase('Archived')}: {syncRecordSummary.archivedMemberCount}</span>}
                {syncRecordSummary.createdMemberCount === 0 && syncRecordSummary.updatedMemberCount === 0 && syncRecordSummary.archivedMemberCount === 0 && '-'}
            </div>

            <div className={styles.label}>{translatePhrase('Groups')}</div>
            <div className={styles.value}>
            {syncRecordSummary.createdGroupCount !== 0 && <span className={styles.segment}>{translatePhrase('Created')}: {syncRecordSummary.createdGroupCount}</span>}
                {syncRecordSummary.updatedGroupCount !== 0 && <span className={styles.segment}>{translatePhrase('Updated')}: {syncRecordSummary.updatedGroupCount}</span>}
                {syncRecordSummary.archivedGroupCount !== 0 && <span className={styles.segment}>{translatePhrase('Archived')}: {syncRecordSummary.archivedGroupCount}</span>}
                {syncRecordSummary.createdGroupCount === 0 && syncRecordSummary.updatedGroupCount === 0 && syncRecordSummary.archivedGroupCount === 0 && '-'}
            </div>

            <div className={styles.label}>{translatePhrase('Workflows')}</div>
            <div className={styles.value}>
            {syncRecordSummary.createdWorkflowCount !== 0 && <span className={styles.segment}>{translatePhrase('Created')}: {syncRecordSummary.createdWorkflowCount}</span>}
                {syncRecordSummary.updatedWorkflowCount !== 0 && <span className={styles.segment}>{translatePhrase('Updated')}: {syncRecordSummary.updatedWorkflowCount}</span>}
                {syncRecordSummary.archivedWorkflowCount !== 0 && <span className={styles.segment}>{translatePhrase('Archived')}: {syncRecordSummary.archivedWorkflowCount}</span>}
                {syncRecordSummary.createdWorkflowCount === 0 && syncRecordSummary.updatedWorkflowCount === 0 && syncRecordSummary.archivedWorkflowCount === 0 && '-'}
            </div>
        </section>
    }
}

const SyncSummary = connect(mapStateToProps, mapDispatchToProps)(ConnectedSyncSummary);

export default SyncSummary;