import React, { Component } from 'react';
import styles from './step-piece/StepPiece.module.scss';
import Input from '../Input';
import { OptionInput } from '../../../widgets/form/InputText';
import StepPiece from './step-piece/StepPiece'

import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setVariablePiece, setWorkflowAffiliationVariable } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import { valuePieceSlotTarget } from './utilities';
import { FlowchartContext, FlowchartInfoForPiece } from '../../../contexts/flowchart-context';
import { VariableType } from '../../../shared/store/flowchart/variables/types';


type SetLocationPieceProps = {
    nextPiece?: JSX.Element,

    locationPiece?: JSX.Element,
    affiliationPiece?: JSX.Element,

    locationPieceType?: VariableType,
    affiliationPieceType?: VariableType,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string | undefined) => dispatch(setTargetPiece(pieceId)),
        setVariablePiece: (targetPieceId: string, draggedPieceId: string) => dispatch(setVariablePiece(targetPieceId, draggedPieceId)),
        setWorkflowAffiliationVariable: (targetPieceId: string, value: string) => dispatch(setWorkflowAffiliationVariable(targetPieceId, value)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = SetLocationPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type SetLocationPieceState = {
    isHoveringOverLocationPiece: boolean,
    isHoveringOverAffiliationPiece: boolean,
}

class ConnectedSetLocationPiece extends Component<Props, SetLocationPieceState> {

    state = {
        isHoveringOverLocationPiece: false,
        isHoveringOverAffiliationPiece: false,
    };

    handleHoverOverLocationPiece = () => {
        this.setState({
            isHoveringOverLocationPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfLocationPiece = () => {
        this.setState({
            isHoveringOverLocationPiece: false,
        });
    };

    handleHoverOverAffiliationPiece = () => {
        this.setState({
            isHoveringOverAffiliationPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfAffiliationPiece = () => {
        this.setState({
            isHoveringOverAffiliationPiece: false,
        });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && (this.state.isHoveringOverLocationPiece || this.state.isHoveringOverAffiliationPiece)) {

            if (this.state.isHoveringOverLocationPiece) {
                this.props.setVariablePiece(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverAffiliationPiece) {
                this.props.setWorkflowAffiliationVariable(this.props.pieceId, this.props.lastDraggedPiece.id);
            }

            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverLocationPiece: false,
                isHoveringOverAffiliationPiece: false,
            });
        }
    }

    updateValidity = (isValid: boolean, flowchartContext: FlowchartInfoForPiece) => {
        if (!isValid) {
            const invalidPieceInfo = flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId);

            if (!invalidPieceInfo && flowchartContext.setInvalidPiece) {
                flowchartContext.setInvalidPiece(this.props.pieceId, flowchartContext.parentSplitPieceIds);
            }
        }

        if (isValid && flowchartContext.removeInvalidPiece && flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId)) {
            flowchartContext.removeInvalidPiece(this.props.pieceId);
        }
    }

    render() {

        return <FlowchartContext.Consumer>
            {
                (flowchartContext) => {
                    let isValid = true;

                    if (this.props.locationPieceType) {
                        isValid = isValid && this.props.locationPieceType === VariableType.LOCATION;
                    }

                    if (this.props.affiliationPieceType) {
                        const affiliationTypes = [VariableType.MEMBER, VariableType.MEMBERS_LIST, VariableType.GROUP, VariableType.GROUPS_LIST];
                        isValid = isValid && affiliationTypes.includes(this.props.affiliationPieceType)
                    }

                    if (flowchartContext.highlightIncompletePieces) {
                        const isIncomplete = !this.props.locationPiece || !this.props.affiliationPiece;
                        isValid = isValid && !isIncomplete;
                    }

                    this.updateValidity(isValid, flowchartContext);

                    return (
                        <StepPiece theme={isValid ? "aqua" : "red"} {...this.props}>
                            <div className={styles.text}>set location</div>
                            {this.props.locationPiece ? this.props.locationPiece : <Input minSize={8} placeholderText="Location" canReceiveDrag={this.props.isDragging && this.state.isHoveringOverLocationPiece && !!this.props.targetPiece} onMouseOver={this.handleHoverOverLocationPiece} onMouseOut={this.handleHoverOutOfLocationPiece} />}
                            <div className={styles.text}>for</div>
                            {this.props.affiliationPiece ? this.props.affiliationPiece : <Input minSize={22} placeholderText="Member/Group (list)" canReceiveDrag={this.props.isDragging && this.state.isHoveringOverAffiliationPiece && !!this.props.targetPiece} onMouseOver={this.handleHoverOverAffiliationPiece} onMouseOut={this.handleHoverOutOfAffiliationPiece} />}
                        </StepPiece>
                    )
                }
            }
        </FlowchartContext.Consumer>

    }
}

const SetLocationPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedSetLocationPiece)

export default SetLocationPiece;