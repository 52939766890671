import React, { Component, ChangeEvent } from 'react';
import styles from './step-piece/StepPiece.module.scss';
import StepPiece from './step-piece/StepPiece'

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';
import { Option } from '../drop-down/ListItem';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setVariableForCurrentLocation } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';


type GetCurrentLocationPieceProps = {
    nextPiece?: JSX.Element,
    variables: Array<Option>,
    settingVariableName?: string,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        variablesData: state.flowchart.variables,
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setVariable: (pieceId: string, value: string) => dispatch(setVariableForCurrentLocation(pieceId, value)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = GetCurrentLocationPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type GetCurrentLocationPieceState = {
    searchText: string,
}

class ConnectedGetCurrentLocationPiece extends Component<Props, GetCurrentLocationPieceState> {

    state = {
        searchText: '',
    };

    searchForVariable = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            searchText: e.target.value,
        });
    }

    render() {

        const variableSelectBox = <SelectBox theme="indigo" selectionPromptText={this.props.settingVariableName}>
            <DropDownList theme="indigo">
                <div className={styles.searchBoxHolder + ' ignore-options-onclickoutside'}>
                    <input className={styles.searchBox} onChange={this.searchForVariable} value={this.state.searchText} type="text" placeholder="Search by name" />
                </div>
                {this.props.variables.filter(variable => variable.name.toLocaleLowerCase().includes(this.state.searchText.toLocaleLowerCase()))
                .map((variable, index) => {
                    const variableData = this.props.variablesData.byId[variable.value];
                    return <ListItem name={variable.name} detail={variableData.type} value={variable.value} key={index} theme="indigo" onClick={this.props.setVariable.bind(this, this.props.pieceId)} />
                })}
            </DropDownList>
        </SelectBox>;
    
        return (
            <StepPiece theme="indigo" {...this.props}>
                <div className={styles.text}>get current location and store in</div>
                {variableSelectBox}
            </StepPiece>
        )
        
    }
}

const GetCurrentLocationPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedGetCurrentLocationPiece)

export default GetCurrentLocationPiece;