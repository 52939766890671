import React, { Component } from 'react';
import styles from './ConstantPiece.module.scss';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem, { Option } from '../drop-down/ListItem';
import FlowchartPiece, { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../shared/store/types';
import { setStructureProject, setStructureLevel, setStructureRole } from '../../../shared/store/flowchart/pieces/actions';
import { PieceType } from '../../../shared/store/flowchart/pieces/types';
import { FlowchartContext, PieceHighlightColour } from '../../../contexts/flowchart-context';
import DropDownSearchBox, { getFilteredOptionsBySearch } from '../drop-down/DropDownSearchBox';

type OwnProps = {
    pieceId: string,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    const structurePiece = state.flowchart.pieces.byId[ownProps.pieceId];

    if (structurePiece.type !== PieceType.STRUCTURE) {
        throw new Error('This piece must be a structure piece');
    }

    return {
        projectsData: state.structure.projects,
        levelsData: state.structure.levels,
        rolesData: state.structure.roles,
        structurePiece,
    }
}



const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
    return {
        setStructureProject: (project: string | undefined) => dispatch(setStructureProject(ownProps.pieceId, project)),
        setStructureLevel: (project: string | undefined) => dispatch(setStructureLevel(ownProps.pieceId, project)),
        setStructureRole: (project: string | undefined) => dispatch(setStructureRole(ownProps.pieceId, project)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type OwnState = {
    searchTerm: string,
};

type Props = OwnProps & StateProps & DispatchProps & FlowchartPieceProps;

class ConnectedStructurePiece extends Component<Props, OwnState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            searchTerm: "",
        }
    }

    selectProject = (selected: string) => {
        this.props.setStructureProject(selected);
    }

    selectLevel = (selected: string) => {
        this.props.setStructureLevel(selected);
    }

    selectRole = (selected: string) => {
        this.props.setStructureRole(selected);
    }

    goBack = () => {
        if (!!this.props.structurePiece.role) {
            this.props.setStructureRole(undefined);
        } else if (!!this.props.structurePiece.level) {
            this.props.setStructureLevel(undefined);
        } else if (!!this.props.structurePiece.project) {
            this.props.setStructureProject(undefined);
        }
    }

    searchForStructure = (searchTerm: string) => {
        this.setState({ searchTerm: searchTerm })
    }

    render() {
        return <FlowchartContext.Consumer>
            {
                (flowchartContext) => {
                    let currentStructureHeading = '';
                    let completeStructureHeading = '';
                    let listEntries: Array<JSX.Element>;

                    const highlightColor = flowchartContext.highlights && flowchartContext.highlights[this.props.pieceId];
                    let highlightClass = styles.noHighlight;

                    switch (highlightColor) {
                        case PieceHighlightColour.GREEN:
                            highlightClass = styles.addedHighlight;
                            break;
                        case PieceHighlightColour.YELLOW:
                            highlightClass = styles.updatedHighlight;
                            break;
                        case PieceHighlightColour.PURPLE:
                            highlightClass = styles.movedHighlight;
                            break;
                        case PieceHighlightColour.RED:
                            highlightClass = styles.deletedHighlight;
                            break;
                    }

                    if (this.props.structurePiece.role) {
                        if (!this.props.structurePiece.project || !this.props.structurePiece.level) {
                            throw new Error('You cannot have a role without a project or level');
                        }

                        const projectName = this.props.projectsData.byId[this.props.structurePiece.project].name;
                        const levelName = this.props.levelsData.byId[this.props.structurePiece.level].name;
                        const roleName = this.props.rolesData.byId[this.props.structurePiece.role].name;

                        currentStructureHeading = levelName;
                        completeStructureHeading = `${projectName} > ${levelName} > ${roleName}`;
                        const filteredRoleIds: Option[] = this.props.levelsData.byId[this.props.structurePiece.level].children.map(roleId => {
                            return {
                                name: this.props.rolesData.byId[roleId].name,
                                value: roleId,
                            }
                        })
                        listEntries = getFilteredOptionsBySearch(filteredRoleIds, this.state.searchTerm).map(option => {
                            return <ListItem key={option.value} theme="aqua" value={option.value} name={option.name} onClick={this.selectRole} />
                        });
                    } else if (this.props.structurePiece.level) {
                        if (!this.props.structurePiece.project) {
                            throw new Error('You cannot have a level without a project');
                        }

                        const projectName = this.props.projectsData.byId[this.props.structurePiece.project].name;
                        const levelName = this.props.levelsData.byId[this.props.structurePiece.level].name;

                        currentStructureHeading = levelName;
                        completeStructureHeading = `${projectName} > ${levelName}`;

                        const filteredRoleIds: Option[] = this.props.levelsData.byId[this.props.structurePiece.level].children.map(roleId => {
                            return {
                                name: this.props.rolesData.byId[roleId].name,
                                value: roleId,
                            }
                        });

                        listEntries = getFilteredOptionsBySearch(filteredRoleIds, this.state.searchTerm).map(option => {
                            return <ListItem key={option.value} theme="aqua" value={option.value} name={option.name} onClick={this.selectRole} />
                        });
                    } else if (this.props.structurePiece.project) {
                        const projectName = this.props.projectsData.byId[this.props.structurePiece.project].name;

                        currentStructureHeading = projectName;
                        completeStructureHeading = projectName;

                        const filteredLevelIds: Option[] = this.props.projectsData.byId[this.props.structurePiece.project].children.map(levelId => {
                            return {
                                name: this.props.levelsData.byId[levelId].name,
                                value: levelId,
                            }
                        })
                        listEntries = getFilteredOptionsBySearch(filteredLevelIds, this.state.searchTerm).map(option => {
                            return <ListItem key={option.value} theme="aqua" value={option.value} name={option.name} onClick={this.selectLevel} isExpandable />
                        });
                    } else {
                        const projectEntries = flowchartContext.projects.length === 0 ? this.props.projectsData.allEntries : flowchartContext.projects;
                        const filteredProjectEntries: Option[] = projectEntries.map((projectId) => {
                            return {
                                name: this.props.projectsData.byId[projectId].name,
                                value: projectId,
                            }
                        })
                        listEntries = getFilteredOptionsBySearch(filteredProjectEntries, this.state.searchTerm).map(option => {
                            return <ListItem key={option.value} theme="aqua" value={option.value} name={option.name} onClick={this.selectProject} isExpandable />
                        });
                    }

                    return (<FlowchartPiece {...this.props}>
                        <div className={highlightClass}>
                            <SelectBox isRounded selectionPromptText={completeStructureHeading || 'Select a project'} dismissDropDownAfterSelection={false} theme="aqua">
                                <DropDownList heading={currentStructureHeading} goBack={this.goBack} dismissAfterSelection={false} theme="aqua">
                                    <DropDownSearchBox
                                        handleSearchInputChange={this.searchForStructure}
                                        placeholder={`${completeStructureHeading || 'Search project'} by name`}
                                        searchTerm={this.state.searchTerm}
                                    />
                                    {listEntries}
                                </DropDownList>
                            </SelectBox>
                        </div>
                    </FlowchartPiece>);
                }
            }
        </FlowchartContext.Consumer>
    }
}

const StructurePiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedStructurePiece);

export default StructurePiece;