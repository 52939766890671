import { FC, useState } from "react";
import styles from './FireWorkflowAction.module.scss';

import { ReactComponent as RocketIcon } from '../../common/assets/rocket.svg';
import { ReactComponent as CloseIcon } from '../../common/assets/close.svg';
import { ReactComponent as WorkflowsIcon } from '../../common/assets/flow-data.svg';
import uuid from "uuid";
import moment from "moment";
import { useHistory } from "react-router";
import { Dispatch } from "redux";
import { AffiliationEntity, getAffiliatedWorkflowsList } from "./selectors";
import { connect } from "react-redux";
import { ApplicationState } from "../../shared/store/types";
import { isUUID } from "../../shared/helpers/utilities";
import Button from "../../widgets/button/CommonButton";
import { NudgeType } from "../../shared/store/my-data/types";
import { translatePhrase } from "../../shared/helpers/translation";
import OptionsList from "../../widgets/form/OptionsList";
import { Permissions } from "../../shared/store/permissions/types";
import { IUpdateableWorkflowData } from "../../shared/store/workflows/types";
import { addWorkflow } from "../../shared/store/workflows/actions";
import LoaderModal from "../../widgets/loader/LoaderModal";
import AlertModal from "../../widgets/alert/AlertModal";

type OwnProps = {
    affiliationEntity: AffiliationEntity,
}


const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    const tooltipEntries = getAffiliatedWorkflowsList(ownProps.affiliationEntity, state)
    const isOnline = state.myData.isOnline
    return {
        workflowTypesData: state.workflows.types,
        workflows: state.workflows,
        selectedNudge: state.myData.selectedNudgeId,
        myId: state.myData.id,
        usersData: state.users,
        permissionsData: state.permissions,
        tooltipList: tooltipEntries,
        isOnline
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        addWorkflow: (payload: IUpdateableWorkflowData) => dispatch(addWorkflow(payload)),
    }
}



type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;

const ConnectedFireWorkflowAction: FC<Props> = (props) => {
    const [isShowingFireWorkflowList, setIsShowingFireWorkflowList] = useState(false);
    const [selectedAffiliationEntity, setSelectedAffiliationEntity] = useState<string | undefined>(undefined);
    const [loaderToast, setLoaderToast] = useState<React.ReactNode | undefined>(undefined);
    const history = useHistory();

    const showFireWorkflowOptions = (affiliationId: string) => {
        setSelectedAffiliationEntity(affiliationId);
        setIsShowingFireWorkflowList(true);
    }

    const hideFireWorkflowOptions = () => {
        setSelectedAffiliationEntity(undefined);
        setIsShowingFireWorkflowList(false);
    }

    const fireNewWorkflow = (workflowTypeId: string, affiliationId: string) => {
        const newWorkflowId = uuid.v4();
        const workflowType = props.workflowTypesData.byId[workflowTypeId];
        const defaultStatusId = workflowType.statuses.filter(statusId => !props.workflowTypesData.statuses.byId[statusId].isTerminal)[0];
        const defaultStatus = props.workflowTypesData.statuses.byId[defaultStatusId];

        function initiateNewWorkflow() {

            let defaultDueDate = moment().add(defaultStatus.dueInDays, 'days').format('YYYY-MM-DD');
            props.addWorkflow({
                id: newWorkflowId,
                type: workflowTypeId,
                status: defaultStatusId,
                dueDate: defaultDueDate,
                affiliatedEntity: affiliationId,
                user: isUUID(props.myId) ? props.myId : props.usersData.allEntries[0],
            });

            history.push(`/workflow/${newWorkflowId}/execute`)
        }

        if (!workflowType.areMultipleInstancesAllowed && Array.isArray(workflowType.workflows)) {

            const workflowIdsOfType = props.workflows.activeWorkflowEntries.filter(workflowId => {
                const workflow = props.workflows.byId[workflowId];
                return workflow.type === workflowType.id;
            });

            const otherAffiliationWorkflows = workflowIdsOfType.filter(workflowId => props.workflows.byId[workflowId].affiliatedEntity === affiliationId)

            if (otherAffiliationWorkflows.length === 0) {
                initiateNewWorkflow();
                return;
            }

            for (const workflowOfTypeId of workflowIdsOfType) {
                const workflowOfType = props.workflows.byId[workflowOfTypeId];
                const isExistingFlowExecutable = workflowOfType.user === props.myId || !isUUID(props.myId);

                const triggerWorkflow = (trigger: boolean) => {
                    if (trigger) {
                        if (isExistingFlowExecutable) {
                            history.push(`/workflow/${workflowOfTypeId}/execute`);
                        } else {
                            history.push(`/workflow-data/${workflowOfTypeId}`);
                        }
                    } else setLoaderToast(false);

                    return;
                }

                if (workflowOfType && !workflowOfType.archived && !props.workflows.types.statuses.byId[workflowOfType.status].isTerminal && affiliationId === workflowOfType.affiliatedEntity) {
                    setLoaderToast(<AlertModal
                        alertSentence={(isExistingFlowExecutable) ?
                            translatePhrase("Seems like there is already a workflow existing... Would you like to resume it?") :
                            translatePhrase('Another user already has a workflow of this type open for this member/group. View data?')}
                        confirmApproval={triggerWorkflow} alertHeader={''}
                    />)
                }
            };
            return;
        }

        initiateNewWorkflow()
    }


    const isFireWorkflowAvailable = (affiliationTypeId: string) => {
        let workflowTypes = props.workflowTypesData.allEntries
            .filter(workflowTypeId => {
                const workflowType = props.workflowTypesData.byId[workflowTypeId];
                const nonTerminalStatuses = workflowType.statuses.filter(statusId => !props.workflowTypesData.statuses.byId[statusId].isTerminal);

                if (props.permissionsData.myPermissions.workflows[workflowType.id]) {
                    if (props.permissionsData.myPermissions.workflows[workflowType.id] !== Permissions.WRITE) {
                        return false;
                    }
                }

                if (nonTerminalStatuses.length === 0) {
                    return false;
                }

                if (workflowType.affiliation !== props.affiliationEntity.type) {
                    return false;
                }

                if (workflowType.affiliatedEntity && workflowType.affiliatedEntity !== affiliationTypeId) {
                    return false;
                }

                return true;
            })

        if (workflowTypes.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    const triggerWorkflowOptions = () => {
        if (selectedAffiliationEntity === props.affiliationEntity.entity.id && isShowingFireWorkflowList) {
            return hideFireWorkflowOptions();
        } else if (selectedAffiliationEntity === undefined && isShowingFireWorkflowList === false) {
            return showFireWorkflowOptions(props.affiliationEntity.entity.id);
        }
    }

    return (
        <div className={styles.listHolder} title={props.tooltipList.length === 0 ? translatePhrase('No Workflows to fire') : ''}>
            <Button isHighlighted={props.selectedNudge === NudgeType.MEMBERS_LIST_FIRE_WORKFLOW || props.selectedNudge === NudgeType.GROUPS_LIST_FIRE_WORKFLOW} title={translatePhrase('Fire Workflow')} type={selectedAffiliationEntity === props.affiliationEntity.entity.id && isShowingFireWorkflowList ? 'primary' : 'secondary'} isRounded isDisabled={!isFireWorkflowAvailable(props.affiliationEntity.entity.type) || props.tooltipList.length === 0 || props.isOnline}
                onClick={triggerWorkflowOptions}
                icon={selectedAffiliationEntity === props.affiliationEntity.entity.id && isShowingFireWorkflowList ? <CloseIcon className={styles.fireActionIcon} /> : <RocketIcon className={styles.fireActionIcon} />} />
            {selectedAffiliationEntity === props.affiliationEntity.entity.id && isShowingFireWorkflowList && <div className={styles.workflowDropdown}>
                <div className={styles.triangle}> ▲ </div>

                <header>
                    <WorkflowsIcon /> <h5> Workflows </h5>
                </header>

                <OptionsList
                    isShowing={true}
                    isSearchDisabled={false}
                    options={props.tooltipList}
                    onSelect={(name, workflowId) => fireNewWorkflow(workflowId, props.affiliationEntity.entity.id)}
                    isAutoFocus={true}
                />
            </div>}
            {loaderToast}
        </div>
    )
}

const FireWorkFlowAction = connect(mapStateToProps, mapDispatchToProps)(ConnectedFireWorkflowAction);

export default FireWorkFlowAction
