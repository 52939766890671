import moment from "moment";
import { AddWorkflowPackageAction, ADD_WORKFLOW_PACKAGE, DeletePackageAction, DELETE_PACKAGE, IUpdateableWorkflowPackage, MarketplacePackage, UpdateWorkflowPackageAction, UpdateWorkflowPackageRequestAction, UpdatePackagesData, UPDATE_WORKFLOW_PACKAGE, UPDATE_PACKAGES_DATA, UPDATE_WORKFLOW_PACKAGE_REQUEST, AddDataPackageAction, ADD_DATA_PACKAGE, IUpdateableDataPackage, UpdateDataPackageAction, UpdateDataPackageRequestAction, UPDATE_DATA_PACKAGE, UPDATE_DATA_PACKAGE_REQUEST } from "./types";

export function addWorkflowPackage(payload: IUpdateableWorkflowPackage): AddWorkflowPackageAction {
    const now = moment.utc().format();

    return {
        type: ADD_WORKFLOW_PACKAGE,
        payload: {
            ...payload,
            createdTime: now,
            lastUpdatedTime: now,
        },
        currentTime: now,
    }
}

export function updateWorkflowPackageRequest(payload: IUpdateableWorkflowPackage): UpdateWorkflowPackageRequestAction {
    return {
        type: UPDATE_WORKFLOW_PACKAGE_REQUEST,
        payload,
    }
}

export function updateWorkflowPackage(payload: IUpdateableWorkflowPackage): UpdateWorkflowPackageAction {
    return {
        type: UPDATE_WORKFLOW_PACKAGE,
        payload,
        currentTime: moment.utc().format(),
    }
}

export function addDataPackage(payload: IUpdateableDataPackage): AddDataPackageAction {
    const now = moment.utc().format();

    return {
        type: ADD_DATA_PACKAGE,
        payload: {
            ...payload,
            createdTime: now,
            lastUpdatedTime: now,
        },
        currentTime: now,
    }
}

export function updateDataPackageRequest(payload: IUpdateableDataPackage): UpdateDataPackageRequestAction {
    return {
        type: UPDATE_DATA_PACKAGE_REQUEST,
        payload,
    }
}

export function updateDataPackage(payload: IUpdateableDataPackage): UpdateDataPackageAction {
    return {
        type: UPDATE_DATA_PACKAGE,
        payload,
        currentTime: moment.utc().format(),
    }
}

export function deletePackage(id: string): DeletePackageAction {
    return {
        type: DELETE_PACKAGE,
        id,
        currentTime: moment.utc().format(),
    }
}

export function updatePackagesData(packages: Array<MarketplacePackage>): UpdatePackagesData {
    return {
        type: UPDATE_PACKAGES_DATA,
        data: packages,
    }
}