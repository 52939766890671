import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/types';
import { INewGroupTypeData, IUpdateableGroupTypeData } from '../../../shared/store/groups/types/types';
import { reOrderGroupTypes, addGroupType, updateGroupType, deleteGroupType } from '../../../shared/store/groups/types/actions';

import CardTreeVertical, {
    OwnProps as CardTreeOwnProps,
    StateProps as CardTreeStateProps,
    DispatchProps as CardTreeDispatchProps,
    OwnState as CardTreeOwnState
} from './CardTreeVertical';
import { CardType } from '../../../widgets/card/Card';
import { Permissions } from '../../../shared/store/permissions/types';
import { Option } from '../../flowchart/drop-down/ListItem';
import { clearErrorMessage, setErrorMessage } from '../../../shared/store/my-data/actions';

interface OwnProps extends CardTreeOwnProps {
}

interface StateProps extends CardTreeStateProps {
}

interface DispatchProps extends CardTreeDispatchProps {
    addCard: (payload: INewGroupTypeData) => void,
    updateCard: (payload: IUpdateableGroupTypeData) => void,
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): StateProps => {
    const allGroupTypes = state.groups.types.allEntries.map(groupTypeId => state.groups.types.byId[groupTypeId]);

    const groupTypesList: Array<CardType> = allGroupTypes
        .filter(groupType => {
            return groupType.project in state.structure.projects.byId;
        })
        .map(groupType => {
            const specificPermission = state.permissions.myPermissions.groups[groupType.id];

            return {
                id: groupType.id,
                name: groupType.name,
                details: `${state.structure.projects.byId[groupType.project].name}, ${state.structure.levels.byId[groupType.level].name}`,
                isReadOnly: typeof specificPermission !== 'undefined' && specificPermission === Permissions.READ,
                isRequired: true,
            }
        });

    const selectedCard = ownProps.selectedId ? allGroupTypes.find(project => project.id === ownProps.selectedId) : undefined;

    let customFields: Array<Option> = [];

    if (selectedCard) {
        customFields = selectedCard.customFields
            .filter(fieldId => {
                return !state.groups.types.customFields.byId[fieldId].isComputed;
            })
            .map(fieldId => {
                return {
                    name: state.groups.types.customFields.byId[fieldId].name,
                    value: fieldId,
                };
            });
    }

    return {
        read: true,
        write: true,
        restrictStructureChanges: false,

        projectsData: state.structure.projects,
        levelsData: state.structure.levels,
        membersData: state.members,
        cardsList: groupTypesList,
        selectedCard,
        customFields,
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        reOrderCards: (sourceIndex: number, destinationIndex: number) => dispatch(reOrderGroupTypes(sourceIndex, destinationIndex)),
        addCard: (payload: INewGroupTypeData) => dispatch(addGroupType(payload)),
        deleteCard: (id: string) => dispatch(deleteGroupType(id)),
        updateCard: (payload: IUpdateableGroupTypeData) => dispatch(updateGroupType(payload)),
    };
}

type Props = OwnProps & StateProps & DispatchProps;

interface OwnState extends CardTreeOwnState {
};

class ConnectedGroupTypesList extends CardTreeVertical<Props, OwnState> {

    state: OwnState = {
        isShowingAddForm: false,
        isShowingModifyForm: false,
        modifyingCardName: '',
        modifyingCardProject: '',
        modifyingCardLevel: '',
        modifyingCardIsRequired: 'Yes',
        modifyingCardUniqueField: '',
    }

    static defaultProps = {
        isReadOnly: false,
        showCardCount: true,
        isSearchable: true
    }

}

const GroupTypesList = connect(mapStateToProps, mapDispatchToProps)(ConnectedGroupTypesList);

export default GroupTypesList;