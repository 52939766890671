import React, { FC } from 'react';
import BinaryOperator, { BinaryOperatorProps } from './BinaryOperator';
import { OwnProps as FlowchartPieceProps } from '../FlowchartPiece';


type SplitBySeparatorOperatorProps = Omit<Omit<Omit<BinaryOperatorProps, 'operatorSymbol'>, 'isBoolean'>, 'areOperatorsBoolean'>;

const SplitBySeparatorOperator: FC<SplitBySeparatorOperatorProps & FlowchartPieceProps> = (props) => {

    return (
        <BinaryOperator operatorSymbol="split by" {...props} />
    )
}

export default SplitBySeparatorOperator;