import moment from "moment";

export const dateStringToIsoDate = (fromDateString: string, toDateString: string) => {
    const fromDate = moment(fromDateString).startOf('day').toISOString();
    const toDate = moment(toDateString).endOf('day').toISOString();
    return { fromDate, toDate }
}

export const DateRanges = {
    previousWeekFromDate: moment().day(-7).format("YYYY-MM-DD").toString(),
    previousWeekToDate: moment().day(-1).format("YYYY-MM-DD").toString(),

    currentWeekFromDate: moment().day(0).format("YYYY-MM-DD").toString(),
    currentWeekToDate: moment().day(6).format("YYYY-MM-DD").toString(),

    currentMonthFromDate: moment().startOf('month').format("YYYY-MM-DD").toString(),
    currentMonthToDate: moment().endOf('month').format("YYYY-MM-DD").toString(),

    previousMonthFromDate: moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD").toString(),
    previousMonthToDate: moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD").toString(),
};

export type SmartFilter = {
    name: string,
    type: string,
    dateRange: {
        from: string,
        to: string
    }
}

export const WorkFlowSmartFilters: Array<SmartFilter> = [
    {
        name: 'Due this Week',
        type: 'overdue',
        dateRange: {
            from: DateRanges.currentWeekFromDate,
            to: DateRanges.currentWeekToDate,
        }
    },
    {
        name: 'Due this Month',
        type: 'overdue',
        dateRange: {
            from: DateRanges.currentMonthFromDate,
            to: DateRanges.currentMonthToDate,
        }
    },
    {
        name: 'Completed last week',
        type: 'completed',
        dateRange: {
            from: DateRanges.previousWeekFromDate,
            to: DateRanges.previousWeekToDate,
        }
    },
    {
        name: 'Completed current week',
        type: 'completed',
        dateRange: {
            from: DateRanges.currentWeekFromDate,
            to: DateRanges.currentWeekToDate,
        }
    },
    {
        name: 'Completed last month',
        type: 'completed',
        dateRange: {
            from: DateRanges.previousMonthFromDate,
            to: DateRanges.previousMonthToDate,
        }
    },
    {
        name: 'Completed current month',
        type: 'completed',
        dateRange: {
            from: DateRanges.currentMonthFromDate,
            to: DateRanges.currentMonthToDate,
        }
    }
];

export const ReportSmartFilters: Array<SmartFilter> = [
    {
        name: 'Generated this week',
        type: '',
        dateRange: {
            from: DateRanges.currentWeekFromDate,
            to: DateRanges.currentWeekToDate
        }
    },
    {
        name: 'Generated this month',
        type: '',
        dateRange: {
            from: DateRanges.currentMonthFromDate,
            to: DateRanges.currentMonthToDate
        }
    }
];

export const MemberSmartFilters: Array<SmartFilter> = [
    {
        name: 'Added previous week',
        type: 'completed',
        dateRange: {
            from: DateRanges.previousWeekFromDate,
            to: DateRanges.previousWeekToDate,
        }
    },
    {
        name: 'Added this week',
        type: 'completed',
        dateRange: {
            from: DateRanges.currentWeekFromDate,
            to: DateRanges.currentWeekToDate,
        }
    },
    {
        name: 'Added last month',
        type: 'completed',
        dateRange: {
            from: DateRanges.previousMonthFromDate,
            to: DateRanges.previousMonthToDate,
        }
    },
    {
        name: 'Added current month',
        type: 'completed',
        dateRange: {
            from: DateRanges.currentMonthFromDate,
            to: DateRanges.currentMonthToDate,
        }
    },
];
export const GroupSmartFilters: Array<SmartFilter> = [
    {
        name: 'Added previous week',
        type: 'completed',
        dateRange: {
            from: DateRanges.previousWeekFromDate,
            to: DateRanges.previousWeekToDate,
        }
    },
    {
        name: 'Added this week',
        type: 'completed',
        dateRange: {
            from: DateRanges.currentWeekFromDate,
            to: DateRanges.currentWeekToDate,
        }
    },
    {
        name: 'Added last month',
        type: 'completed',
        dateRange: {
            from: DateRanges.previousMonthFromDate,
            to: DateRanges.previousMonthToDate,
        }
    },
    {
        name: 'Added current month',
        type: 'completed',
        dateRange: {
            from: DateRanges.currentMonthFromDate,
            to: DateRanges.currentMonthToDate,
        }
    },
];