import React, { Component } from 'react';
import styles from '../organization-profile/OrganizationProfile.module.scss';

import { ApplicationState } from '../../shared/store/types';

import { ReactComponent as LogoutIcon } from '../../common/assets/logout.svg';
import { ReactComponent as EditIcon } from '../../common/assets/edit.svg';
import { ReactComponent as CancelIcon } from '../../common/assets/close.svg';
import { ReactComponent as ProfileIcon } from '../../common/assets/user-certification.svg';
import { ReactComponent as MailIcon } from '../../common/assets/email.svg';
import { ReactComponent as PhoneIcon } from '../../assets/new-custom-icons/profile/telephone.svg';
import { ReactComponent as LanguageIcon } from '../../common/assets/translate.svg';
import { ReactComponent as SecurityIcon } from '../../common/assets/security.svg';
import { ReactComponent as CheckIcon } from '../../assets/new-custom-icons/profile/check-mark.svg';
import { ReactComponent as MessageIcon } from '../../assets/new-custom-icons/new-revision/message.svg';
import { ReactComponent as PinIcon } from '../../assets/new-custom-icons/profile/pin.svg';
import { ReactComponent as FlowchartIcon } from '../../assets/new-custom-icons/new-revision/flow-data.svg';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { isUUID } from '../../shared/helpers/utilities';
import { getReadableDataForCustomField } from '../../shared/store/custom-fields';
import { IUpdateableUserData } from '../../shared/store/users/types';
import { updateUser } from '../../shared/store/users/actions';
import { updateSuperUserPassword } from '../../shared/store/organization/actions';
import { translatePhrase } from '../../shared/helpers/translation';
import Button from '../../widgets/button/CommonButton';
import InputText from '../../widgets/form/InputText';
import { storeCurrentSnapshot } from '../../shared/store/database';
import { Link } from 'react-router-dom';
import { setIsLoggingOut } from '../../shared/store/my-data/actions';
import { DefaultFlowchartProcessState } from '../../shared/store/flowchart/types';
import { VariableValueType } from '../../shared/helpers/common-types';
import { getUserComputedFieldValue } from '../../shared/store/flowchart/helpers/custom-fields/user';


type OwnProps = {
    closeSegment: () => void,
};

type EditableProfile = {
    name: string,
    language: string,
    password: string,
}

interface OwnState {
    editingProfile: boolean,
    editingDetails: boolean,
    locationKey: number,
    profileKey: number,
    profile: EditableProfile,
    profileCleanCopy: EditableProfile | undefined,
    isDataSavedLocally: boolean,
};

const mapStateToProps = (state: ApplicationState) => {

    let name = '';
    let language = '';
    let phone = '';
    let email = ''
    let isBetaTester = 'No';

    if (!isUUID(state.myData.id)) {
        name = 'Super User';
        email = state.organization.email ? state.organization.email : '';
    } else {
        const user = state.users.byId[state.myData.id];
        const nameField = state.users.customFields.byId[state.users.nameFieldId];
        phone = user.phone.countryCode + ' ' + user.phone.number;
        name = getReadableDataForCustomField(user.customFields[state.users.nameFieldId], nameField, user.id, 'user');
        language = user.language;

        if (user.isBetaTester) {
            isBetaTester = 'Yes';
        }
    }

    return {
        baseLanguage: 'English',
        applicationState: state,
        otherLanguages: state.internationalization.languages.allEntries.map(languageId => {
            const language = state.internationalization.languages.byId[languageId];
            let label: string;

            if (language.translatedName) {
                label = `${language.name} (${language.translatedName})`;
            } else {
                label = language.name;
            }

            return {
                id: language.id,
                name: label,
            };
        }),
        name,
        isBetaTester,
        phone,
        email,
        language,
        myId: state.myData.id,
        usersData: state.users,
        nameFieldId: state.users.nameFieldId,
        locations: state.users.byId[state.myData.id] && state.users.byId[state.myData.id].locations ? state.users.byId[state.myData.id].locations : []
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        updateSuperUserPassword: (password: string) => dispatch(updateSuperUserPassword(password)),
        updateUser: (userData: IUpdateableUserData) => dispatch(updateUser(userData)),
        setIsLoggingOut: (isLoggingOut: boolean) => dispatch(setIsLoggingOut(isLoggingOut)),
    };
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

class ConnectedOrganizationProfile extends Component<Props, OwnState> {

    state: OwnState = {
        editingProfile: false,
        editingDetails: false,
        locationKey: 0,
        profileKey: 0,
        profile: {
            name: this.props.name,
            language: '',
            password: '',
        },
        isDataSavedLocally: false,
        profileCleanCopy: undefined,
    }



    logout = async () => {
        this.props.closeSegment();
        this.props.setIsLoggingOut(true);

        setTimeout(async () => {
            try {
                await storeCurrentSnapshot(this.props.applicationState, true);
            } catch (e) {
                console.error('Could not save data locally');
            }

            this.setState({
                isDataSavedLocally: true,
            });

            setTimeout(() => {
                this.props.setIsLoggingOut(false);

                window.localStorage.removeItem('lastAction');
                window.localStorage.removeItem('lastSync');
                // window.localStorage.removeItem('token');
                // window.localStorage.removeItem('myId');
                window.location.assign('/login/');
            }, 500);
        }, 1000);
    }

    changeLanguage = (event: string) => {
        let updatedProfile = {
            ...this.state.profile,
            language: event
        };

        this.setState({
            profile: updatedProfile,
        });
    }

    changePassword = (e: string) => {
        let updatedProfile = {
            ...this.state.profile,
            password: e
        };

        this.setState({
            profile: updatedProfile,
        });
    }

    changeName = (e: string) => {
        let updatedProfile = {
            ...this.state.profile,
            name: e
        };

        this.setState({
            profile: updatedProfile,
        });
    }

    editProfile = () => {
        const cleanCopy = JSON.parse(JSON.stringify(this.state.profile));
        const newProfileKey = this.state.profileKey + 1;

        this.setState({
            editingProfile: true,
            profileCleanCopy: cleanCopy,
            profileKey: newProfileKey,
        });
    }

    saveProfile = () => {
        const newProfileKey = this.state.profileKey + 1;

        this.setState({
            editingProfile: false,
            profileCleanCopy: undefined,
            profileKey: newProfileKey,
        });

        if (isUUID(this.props.myId)) {
            const loggedInUser = this.props.usersData.byId[this.props.myId];

            this.props.updateUser({
                ...loggedInUser,
                password: this.state.profile.password,
                language: this.state.profile.language,
                customFields: {
                    ...loggedInUser.customFields,
                    [this.props.nameFieldId]: this.state.profile.name,
                }
            });
        } else {
            if (this.state.profile.password) {
                this.props.updateSuperUserPassword(this.state.profile.password);
            }
        }
    }

    cancelProfile = () => {
        const cleanCopy = this.state.profileCleanCopy,
            newKey = this.state.profileKey + 1;

        if (cleanCopy === undefined) {
            return;
        }

        this.setState({
            editingProfile: false,
            profile: cleanCopy,
            profileKey: newKey,
            profileCleanCopy: undefined
        });
    }

    getUserSubtitle(userId: string) {
        const user = this.props.applicationState.users.byId[userId];
        let userSubtitle: VariableValueType;

        if (!user) {
            return '-';
        }

        const subTitleField = this.props.applicationState.users.customFields.byId[this.props.applicationState.users.subTitleFieldId];

        if (subTitleField && subTitleField.isComputed && typeof subTitleField.startPiece !== 'undefined') {
            const processState: DefaultFlowchartProcessState = {
                displayingContinuePieceId: undefined,
                displayingAddWorkflowPieceId: undefined,
                customFields: { ...user.customFields },
                variables: {
                    [subTitleField.seedEntityVariable]: user.id,
                },
                lastComputedPiece: undefined,
                executionStack: [],
                forIterationCounts: {},
                displayingQuestionPieceId: undefined,
                displayingShowPieceId: undefined,
                displayingGroupPieceId: undefined,
                displayingTransferPieceId: undefined,
                createdWorkflowId: undefined,
                displayingFinsalLoanProcessPieceId: undefined,
            };

            try {
                userSubtitle = getUserComputedFieldValue(this.props.applicationState, processState, subTitleField.startPiece.piece, user.id, subTitleField);
            } catch {
                userSubtitle = '-';
            }
        } else {
            try {
                userSubtitle = user.customFields[this.props.applicationState.users.subTitleFieldId];
            }
            catch {
                userSubtitle = '-';
            }
        }

        if (Array.isArray(userSubtitle)) {

            if (userSubtitle.length > 0 && Array.isArray(userSubtitle[0])) {
                // Cannot be a multidimensional array
                throw new Error('The value cannot be a multi-dimensional array')
            }

            userSubtitle = userSubtitle as Array<string>;
        }

        try {
            userSubtitle = getReadableDataForCustomField(userSubtitle, this.props.applicationState.users.customFields.byId[this.props.applicationState.users.subTitleFieldId], user.id, 'user');
        }

        catch {
            userSubtitle = '-';
        }

        return userSubtitle;
    }

    getUserLocation(id: string) {
        try {
            return this.props.applicationState.structure.locations.byId[id].name;
        } catch {
            return '-';
        }
    }

    goBack = () => {
        window.history.back();
    }

    render() {
        const allLanguages = [{
            value: '0',
            name: 'English',
        }].concat(this.props.otherLanguages.map(language => {
            return {
                name: language.name,
                value: language.id,
            };
        }));

        var defaultLanguageId = '0';
        if (isUUID(this.props.language)) {
            const selectedLanguage = this.props.otherLanguages.find(language => language.id === this.props.language);

            if (selectedLanguage) {
                defaultLanguageId = selectedLanguage.id;
            }
        }

        return (<section className={styles.ProfilePage} key={this.state.profileKey}>
            <header className={styles.segmentHeader}>
                <h2> <ProfileIcon /> {translatePhrase('Profile')} </h2>

                {!this.state.editingProfile &&
                    <Button icon={<EditIcon />} text={'Edit'} isRounded={true}
                        type={'secondary'} size={'small'} padding={'0px 10px'} onClick={() => this.editProfile()} />
                }

                {this.state.editingProfile &&
                    <div>
                        <Button icon={<CheckIcon />} padding={'0px 10px'} text={'Done'} isRounded={true} type={'primary'} color={'contrast'} size={'small'} onClick={() => this.saveProfile()} />

                        <Button icon={<CancelIcon />} padding={'0px 10px'} text={'Cancel'} isRounded={true} type={'secondary'} size={'small'} onClick={() => this.cancelProfile()} />
                    </div>
                }
            </header>

            {!this.state.editingProfile && <section className={styles.profileContainer}>
                <ul className={styles.secondaryDetails}>
                    <li>
                        <div className={styles.icon}>
                            <ProfileIcon />
                        </div>
                        <div className={styles.data}>
                            <label> {translatePhrase('Name')} </label>
                            <span> {this.state.profile.name} </span>
                        </div>
                    </li>

                    <li>
                        <div className={styles.icon}>
                            <FlowchartIcon />
                        </div>
                        <div className={styles.data}>
                            <label> {translatePhrase('Is Tester?')} </label>
                            <span> {this.props.isBetaTester} </span>
                        </div>

                    </li>

                    <li>
                        <div className={styles.icon}>
                            <MessageIcon />
                        </div>
                        <div className={styles.data}>
                            <label> {translatePhrase('Subtitle')} </label>
                            <span> {this.getUserSubtitle(this.props.applicationState.myData.id)} </span>
                        </div>

                    </li>

                    {this.props.locations.length > 0 && <li>
                        <div className={styles.icon}>
                            <PinIcon />
                        </div>
                        <div className={styles.data}
                            title={this.props.locations.map(location => translatePhrase(this.getUserLocation(location))).toString()}>
                            <label> {translatePhrase('Locations')} </label>
                            {this.props.locations.map(location => {
                                return <span> {translatePhrase(this.getUserLocation(location))} </span>
                            }).slice(0, 2)}
                            {this.props.locations.length > 2 && <span>...</span>}
                        </div>
                    </li>}

                    {isUUID(this.props.myId) && <li>
                        <div className={styles.icon}>
                            <PhoneIcon />
                        </div>
                        <div className={styles.data}>
                            <label> {translatePhrase('Phone')} </label>
                            <span> {this.props.phone} </span>
                        </div>
                    </li>}

                    {!isUUID(this.props.myId) && <li>
                        <div className={styles.icon}>
                            <MailIcon />
                        </div>
                        <div className={styles.data}>
                            <label> {translatePhrase('Email')} </label>
                            <span> {this.props.email} </span>
                        </div>
                    </li>}

                    {isUUID(this.props.myId) && <li>
                        <div className={styles.icon}>
                            <LanguageIcon />
                        </div>
                        <div className={styles.data}>
                            <label> {translatePhrase('Language')} </label>
                            {(this.props.language === 'English' || this.props.language === '') && <span> English </span>}
                            {this.props.language !== 'English' && <span>
                                {allLanguages.find(language => language.value === this.props.language)?.name}
                            </span>}
                        </div>
                    </li>}

                    <li>
                        <div className={styles.icon}>
                            <SecurityIcon />
                        </div>
                        <div className={styles.data}>
                            <label> {translatePhrase('Password')} </label>
                            <span> **************** </span>
                        </div>
                    </li>
                </ul>
            </section>}

            {
                this.state.editingProfile && <section className={styles.editProfileForm}>
                    <div className={styles.inputHolder}>
                        <label> {translatePhrase('Name')} </label>
                        <InputText isDisabled={!isUUID(this.props.myId)} default={this.state.profile.name} onChange={(e) => this.changeName(e)} />
                    </div>

                    <div className={styles.inputHolder}>
                        <label> {translatePhrase('Is Beta Tester')} </label>
                        <InputText isDisabled={true} default={this.props.isBetaTester} onChange={() => { }} />
                    </div>

                    {isUUID(this.props.myId) && <div className={styles.inputHolder}>
                        <label> {translatePhrase('Phone')} </label>
                        <InputText isDisabled={true} default={this.props.phone} onChange={() => { }} />
                    </div>}

                    {!isUUID(this.props.myId) && <div className={styles.inputHolder}>
                        <label> {translatePhrase('Email')} </label>
                        <InputText isDisabled={true} default={this.props.email} onChange={() => { }} />
                    </div>}

                    {isUUID(this.props.myId) && <div className={styles.inputHolder}>
                        <label> {translatePhrase('Language')} </label>
                        <InputText options={allLanguages} default={defaultLanguageId} onChange={(e) => this.changeLanguage(e)} />
                    </div>}

                    <div className={styles.inputHolder}>
                        <label> {translatePhrase('New Password')} </label>
                        <InputText default={''} onChange={(e) => { this.changePassword(e) }} />
                    </div>

                </section>
            }

            <Link to="/api-docs"><section className={styles.apiLink}>API Documentation</section></Link>

            {
                !this.state.editingProfile && <div className={styles.logout}>
                    <Button icon={<LogoutIcon />} text={'Logout'} isBlock={false} isRounded={true} type={'secondary'} padding={'0px 10px'} onClick={() => this.logout()} dataSelector="Logout Button" />
                </div>
            }
        </section >);
    }
}

const OrganizationProfile = connect(mapStateToProps, mapDispatchToProps)(ConnectedOrganizationProfile);

export default OrganizationProfile;