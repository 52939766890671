import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/types';
import { deleteLevelCustomField, updateLevelCustomField, selectLevelCustomFieldOption, unSelectLevelCustomFieldOption, reOrderLevelCustomFieldOption, addLevelCustomFieldOption, deleteLevelCustomFieldOption, updateLevelCustomFieldOption } from '../../../shared/store/structure/level/actions';

import CustomFieldVertical, { 
    OwnProps as CustomFieldVerticalOwnProps, 
    StateProps as CustomFieldVerticalStateProps, 
    DispatchProps as CustomFieldVerticalDispatchProps, 
    OwnState as CustomFieldVerticalOwnState 
} from '../../../helpers/CustomFieldVertical';
import { Permissions } from '../../../shared/store/permissions/types';
import { translatePhrase } from '../../../shared/helpers/translation';

interface OwnProps extends CustomFieldVerticalOwnProps {
    levelId: string,
}

interface StateProps extends CustomFieldVerticalStateProps {
}

interface DispatchProps extends CustomFieldVerticalDispatchProps {
}

interface OwnState extends CustomFieldVerticalOwnState {
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) : StateProps => {
    const canEditLevelConfiguration = state.permissions.myPermissions.general.Hierarchy === Permissions.WRITE;
    const canViewLevelConfiguration = state.permissions.myPermissions.general.Hierarchy === Permissions.READ || canEditLevelConfiguration;

    const customFieldData = state.structure.levels.customFields.byId[ownProps.customFieldId];

    return {
        read: canViewLevelConfiguration,
        write: canEditLevelConfiguration,
        restrictStructureChanges: false,
        customFieldData: customFieldData,
        cardsList: customFieldData.choices.map(choiceId => {
            return {
                id: choiceId,
                name: translatePhrase(state.structure.levels.customFieldOptions.byId[choiceId].name),
            }
        }),
        selectedCard: state.structure.levels.selectedOption && customFieldData.choices.includes(state.structure.levels.selectedOption) ? {
            id: state.structure.levels.selectedOption,
            name: state.structure.levels.customFieldOptions.byId[state.structure.levels.selectedOption].name,
        } : undefined
    };
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) : DispatchProps => {
    return {
        onSelectCard: (id) => dispatch(selectLevelCustomFieldOption(id)),
        onUnSelectCard: () => dispatch(unSelectLevelCustomFieldOption()),
        reOrderCards: (sourceIndex, destinationIndex) => dispatch(reOrderLevelCustomFieldOption(sourceIndex, destinationIndex, ownProps.customFieldId)),
        addCard: (payload) => dispatch(addLevelCustomFieldOption(payload, ownProps.customFieldId)),
        deleteCard: (id) => dispatch(deleteLevelCustomFieldOption(id, ownProps.customFieldId)),
        updateCard: (payload) => dispatch(updateLevelCustomFieldOption(payload)),

        deleteVertical: (id) => dispatch(deleteLevelCustomField(id, ownProps.levelId)),
        updateVertical: (payload) => dispatch(updateLevelCustomField(payload)),
    }
}

type Props = OwnProps & StateProps & DispatchProps;

class ConnectedLevelCustomFieldVertical extends CustomFieldVertical<Props, OwnState> {

    state: OwnState = {
        isShowingAddForm: false,
        isShowingModifyForm: false,
        isShowingModifyVerticalForm: false,

        modifyingCardName: this.props.selectedCard ? this.props.selectedCard.name : '',
        modifyingVerticalName: this.props.customFieldData ? this.props.customFieldData.name : '',
        modifyingVerticalType: this.props.customFieldData ? this.props.customFieldData.type : '',
        modifyingVerticalIsComputed: this.props.customFieldData ? this.props.customFieldData.isComputed ? 'Yes' : 'No' : 'No',
        modifyingVerticalIsInTable: this.props.customFieldData ? this.props.customFieldData.isInTable ? 'Yes' : 'No' : 'No',

        isUpdating: false,
    };

    static defaultProps = {
        isReadOnly: false,
    }

}

const LevelCustomFieldVertical = connect(mapStateToProps, mapDispatchToProps)(ConnectedLevelCustomFieldVertical);

export default LevelCustomFieldVertical;