import React, { ChangeEvent, Component } from 'react';
import styles from './Configuration.module.scss';
import { Link, Redirect } from "react-router-dom";

import { Permissions } from '../../../shared/store/permissions/types';

import { selectReportType, unSelectReportType } from '../../../shared/store/reports/types/actions';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ReactComponent as DuplicateIcon } from '../../../assets/new-custom-icons/common/duplicate.svg';
import { ReactComponent as ExportIcon } from '../../../assets/new-custom-icons/common/export.svg';
import { ReactComponent as ImportIcon } from '../../../assets/new-custom-icons/common/import.svg';
import { ReactComponent as CancelIcon } from '../../../common/assets/close.svg';

import ReportTypesList from './ReportTypesList';

import { ApplicationState } from '../../../shared/store/types';
import { duplicateReportType, ExportedReportType, ExportReportTypeData } from '../../../shared/helpers/duplicate';
import { translatePhrase } from '../../../shared/helpers/translation';
import { isUUID, validateEmail } from '../../../shared/helpers/utilities';
import { setToastMessage } from '../../../shared/store/my-data/actions';
import ModifyForm from '../../../widgets/card/ModifyForm';
import { importReportTypeFromExportFile } from '../../../shared/helpers/duplicate/import';
import { NormalizedModel } from '../../../shared/store/normalized-model';
import { AllPieceTypes } from '../../../shared/store/flowchart/pieces/types';
import { IVariable } from '../../../shared/store/flowchart/variables/types';
import { duplicatePiece } from '../../../shared/store/flowchart/helpers/pieces';
import EnhancedInputText from '../../../widgets/form/InputText';
import Button from '../../../widgets/button/CommonButton';
import { addReportSchedule } from '../../../shared/store/reports/schedules/actions';
import { INewReportScheduleData, ReportScheduleFrequency } from '../../../shared/store/reports/schedules/types';
import { getReadableDataForCustomField } from '../../../shared/store/custom-fields';
import ReportSchedule from './ReportSchedule';
import moment, { Moment } from 'moment';
import { saveAs } from 'file-saver';


type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canEditConfiguration = state.permissions.myPermissions.general.ReportsConfiguration === Permissions.WRITE;
    const canViewConfiguration = canEditConfiguration || state.permissions.myPermissions.general.ReportsConfiguration === Permissions.READ;
    const selectedReportTypeId = state.reports.types.selected;

    return {
        isReadable: canViewConfiguration,
        isWritable: canEditConfiguration,
        selectedReportTypeId,
        projectsData: state.structure.projects,
        usersData: state.users,
        pieceState: state.flowchart.pieces,
        variableState: state.flowchart.variables,
        reportTypesData: state.reports.types,
        reportSchedulesData: state.reports.schedules,

        isSuperUser: !isUUID(state.myData.id),
        orgEmail: state.organization.email,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        selectReportType: (id: string) => dispatch(selectReportType(id)),
        unSelectReportType: () => dispatch(unSelectReportType()),

        addReportSchedule: (payload: INewReportScheduleData) => dispatch(addReportSchedule(payload)),

        setToastMessage: (message: string) => dispatch(setToastMessage(message)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
    isShowingAddForm: boolean,
    modifyingVerticalName: string,
    modifyingVerticalUser: string,
    modifyingVerticalFrequency: string,
    modifyingVerticalDayOfMonth: number,
    shouldSendEmails: boolean,
    modifyingVerticalEmails: string,

    isLoading: boolean,
    importingReportData: ExportReportTypeData | undefined,
    importName: string,
    importProject: string,
    isAllReportTypes: boolean,

}

class ConnectedLocations extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            isShowingAddForm: false,
            modifyingVerticalName: '',
            modifyingVerticalUser: '',
            modifyingVerticalFrequency: '',
            modifyingVerticalDayOfMonth: 1,
            shouldSendEmails: true,
            modifyingVerticalEmails: props.isSuperUser && props.orgEmail ? props.orgEmail : '',

            isLoading: false,
            importingReportData: undefined,
            importName: '',
            importProject: '',
            isAllReportTypes: true,
        };
    }

    toggleReportScheduleAddForm = () => {
        this.setState((prevState) => {
            return {
                isShowingAddForm: !prevState.isShowingAddForm,
                modifyingVerticalName: '',
                modifyingVerticalUser: '',
                modifyingVerticalFrequency: '',
                modifyingVerticalDayOfMonth: 1,
                shouldSendEmails: true,
                modifyingVerticalEmails: '',
            };
        });
    }

    updateScheduleName = (value: string) => {
        this.setState({
            modifyingVerticalName: value,
        });
    }

    updateScheduleUser = (value: string) => {
        this.setState({
            modifyingVerticalUser: value,
        });
    }

    updateScheduleFrequency = (value: string) => {
        this.setState({
            modifyingVerticalFrequency: value,
        });
    }

    updateFieldDayOfMonth = (value: string) => {
        this.setState({
            modifyingVerticalDayOfMonth: isNaN(Number(value)) ? 1 : Number(value),
        });
    }

    updateShouldSendEmails = (value: string) => {
        this.setState(prevState => {
            const shouldSendEmails = value === 'Yes';

            return {
                shouldSendEmails,
                modifyingVerticalEmails: shouldSendEmails ? prevState.modifyingVerticalEmails : '',
            }
        });
    }

    updateScheduleEmails = (value: string) => {
        this.setState({
            modifyingVerticalEmails: value,
        });
    }

    addReportSchedule = () => {
        const frequencyType = this.state.modifyingVerticalFrequency as keyof typeof ReportScheduleFrequency;

        if (!this.props.selectedReportTypeId) {
            return;
        }

        if (this.validateVerticalForm() !== true) {
            return;
        }

        this.props.addReportSchedule({
            name: this.state.modifyingVerticalName,
            type: this.props.selectedReportTypeId,
            user: this.state.modifyingVerticalUser,
            emails: this.state.modifyingVerticalEmails,
            frequency: ReportScheduleFrequency[frequencyType],
            dayOfMonth: this.state.modifyingVerticalDayOfMonth || undefined,
        });

        this.setState({
            isShowingAddForm: false,
            modifyingVerticalName: '',
            modifyingVerticalUser: '',
            modifyingVerticalEmails: '',
            modifyingVerticalFrequency: '',
            modifyingVerticalDayOfMonth: 1,
        });
    }

    validateVerticalForm = () => {
        const frequencyTypes = Object.keys(ReportScheduleFrequency);

        if (!this.state.modifyingVerticalName) {
            return 'Enter a valid name';
        }

        if (!this.props.selectedReportTypeId) {
            return 'Select a report type';
        }

        const reportScheduleNames: Array<string> = this.props.reportSchedulesData.allEntries.map(id => {
            return this.props.reportSchedulesData.byId[id].name.toLocaleLowerCase();
        });

        if (reportScheduleNames.includes(this.state.modifyingVerticalName.toLocaleLowerCase())) {
            return 'A report schedule with this name already exists'
        }

        if (!this.state.modifyingVerticalUser) {
            return 'Enter a valid user';
        }

        if (!frequencyTypes.includes(this.state.modifyingVerticalFrequency)) {
            return 'Enter a valid frequency'
        }

        const dayOfMonth = this.state.modifyingVerticalDayOfMonth;

        if (dayOfMonth < 1 || dayOfMonth > 31) {
            return 'Enter a valid day of month (between 1 and 31)';
        }

        if (this.state.shouldSendEmails) {
            if (!this.state.modifyingVerticalEmails) {
                return 'Enter at least one email address';
            }

            const emails = this.state.modifyingVerticalEmails.split(',').map(email => email.trim());
            const invalidEmails = emails.filter(email => !validateEmail(email));

            if (invalidEmails.length > 0) {
                const errorMessage = `Invalid email${invalidEmails.length > 1 ? 's' : ''}: ` + invalidEmails.join(',');
                return errorMessage;
            }

        }

        return true;
    }

    validateImportForm = () => {

        if (!this.state.importingReportData) {
            return 'No data to import';
        }

        if (!this.state.importName) {
            return 'Enter a valid name';
        }

        if (!this.state.importProject) {
            return 'Enter a valid project';
        }

        const existingNamesForReportTypes = this.props.reportTypesData.allEntries.map(reportTypeId => {
            const reportType = this.props.reportTypesData.byId[reportTypeId];
            return reportType.name;
        });

        if (existingNamesForReportTypes.includes(this.state.importName)) {
            return 'Enter a unique name for the importing report type';
        }

        return true;
    }

    updateImportName = (value: string) => {
        this.setState({
            importName: value,
        });
    }

    updateImportProject = (value: string) => {
        this.setState({
            importProject: value,
        });
    }

    hideImportForm = () => {
        this.setState({
            importingReportData: undefined,
        });
    }

    prepareExport = (reportTypeId?: string) => {
        setTimeout(() => {
            this.setState({
                isLoading: true,
            });

            this.startExport(reportTypeId);
        }, 1000);
    }

    onDuplicate = () => {
        this.props.selectedReportTypeId &&
            duplicateReportType(this.props.selectedReportTypeId);
    }

    onExport = () => {
        this.prepareExport(this.props.selectedReportTypeId)
    }

    onImport = () => {
        return this.handleFileUpload
    }

    showFlowchart = () => {
        this.setState({
            isAllReportTypes: false,
        })
    }

    hideFlowchart = () => {
        this.setState({
            isAllReportTypes: true,
        })
    }

    startExport = (reportTypeId: string | undefined) => {
        if (!reportTypeId) {
            return;
        }

        const reportType = this.props.reportTypesData.byId[reportTypeId];

        const exportedReportType: ExportedReportType = {
            id: reportType.id,
            name: reportType.name,

            startPiece: undefined,
            variables: reportType.variables,

            seedStartDateVariable: reportType.seedStartDateVariable,
            seedEndDateVariable: reportType.seedEndDateVariable,
            seedUserVariable: reportType.seedUserVariable,
            seedUsersVariable: reportType.seedUsersVariable,
            seedMembersVariable: reportType.seedMembersVariable,
            seedGroupsVariable: reportType.seedGroupsVariable,
            seedWorkflowsVariable: reportType.seedWorkflowsVariable,
        };

        if (reportType.startPiece) {
            exportedReportType.startPiece = {
                piece: reportType.startPiece.piece,
                position: reportType.startPiece.position,
            };
        }

        const pieceState: NormalizedModel<AllPieceTypes> = {
            byId: {},
            allEntries: [],
            filteredEntries: [],

            createdIds: new Set(),
            updatedIds: new Set(),
            deletedIds: new Set(),
        };

        const variableState: NormalizedModel<IVariable> = {
            byId: {},
            allEntries: [],
            filteredEntries: [],

            createdIds: new Set(),
            updatedIds: new Set(),
            deletedIds: new Set(),
        }

        const addPiece = (pieceData: AllPieceTypes) => {
            pieceState.byId[pieceData.id] = pieceData;

            if (!pieceState.allEntries.includes(pieceData.id)) {
                pieceState.allEntries.push(pieceData.id);
            }
        }

        const addVariable = (variable: IVariable) => {
            variableState.byId[variable.id] = variable;

            if (!variableState.allEntries.includes(variable.id)) {
                variableState.allEntries.push(variable.id);
            }
        }

        if (reportType.startPiece && exportedReportType.startPiece) {
            const newId = duplicatePiece(this.props.pieceState, addPiece, undefined, reportType.startPiece.piece);
            exportedReportType.startPiece.piece = newId;
        }

        for (const variableId of reportType.variables) {
            const variable = this.props.variableState.byId[variableId];
            if (variable) {
                addVariable(variable);
            }
        }

        const exportInfo: ExportReportTypeData = {
            reportType: exportedReportType,
            pieceState: pieceState,
            variableState: variableState,
        };

        const exportData = JSON.stringify(exportInfo, null, 2);

        const fileBlob = new Blob([exportData.split('\n').join('\r\n')], { type: 'application/json' });

        saveAs(fileBlob, `${exportedReportType.name} Export.json`);

        this.setState({
            isLoading: false,
        });
    }

    importReportType = () => {
        this.setState({
            isLoading: true,
        });

        setTimeout(() => {
            if (this.state.importingReportData) {
                try {
                    importReportTypeFromExportFile(
                        this.state.importingReportData,
                        this.state.importName,
                        this.state.importProject,
                    );
                } catch (e) {
                    this.props.setToastMessage('Report type import failed. Please check the file again');
                    console.error(e);
                }

                this.setState({
                    importingReportData: undefined,

                    isLoading: false,

                    importName: '',
                    importProject: '',
                });

            } else {
                this.setState({
                    isLoading: false,
                });
            }
        }, 1000);
    }

    handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        const file = !!e.target.files ? e.target.files[0] : undefined;

        if (!!file) {
            var reader = new FileReader();

            reader.onload = (evt: any) => {
                const importedData = evt.target.result;
                try {
                    const fileData: ExportReportTypeData = JSON.parse(importedData);

                    this.setState({
                        importingReportData: fileData,

                        importName: fileData.reportType.name,
                    });
                } catch (e) {
                    this.props.setToastMessage('Report type import failed. Please check the file again');
                    console.error(e);
                }
            }

            reader.readAsText(file, "UTF-8");
        }
    }

    render() {
        if (!this.props.isReadable) {
            return <Redirect to="/dashboard" />;
        }

        const projectsList = this.props.projectsData.allEntries.map(projectId => {
            return {
                name: this.props.projectsData.byId[projectId].name,
                value: projectId,
            }
        });

        projectsList.unshift({
            name: 'All projects',
            value: '',
        });

        const importReportTypeForm =
            <div className={styles.modifyFormCard}>
                <div>
                    <header>
                        <div className={styles.heading}>
                            {translatePhrase('Import Report Type')}
                        </div>
                        <button className={styles.cancelCustomFieldButton} title={translatePhrase('Cancel')} onClick={this.hideImportForm}> <CancelIcon /> </button>
                    </header>
                </div>
                <div className={styles.container}>
                    <ModifyForm hideCancel isNew={true} submitForm={this.importReportType} cancelForm={this.hideImportForm} validateForm={this.validateImportForm}>
                        <EnhancedInputText placeholder="Name" onEnterPress={this.importReportType} onChange={this.updateImportName} default={this.state.importingReportData && this.state.importingReportData.reportType.name} />
                        <EnhancedInputText placeholder="Project" onEnterPress={this.importReportType} onChange={this.updateImportProject} options={projectsList} />
                    </ModifyForm>
                </div>
            </div>;

        const frequencyOptions = Object.keys(ReportScheduleFrequency).map(name => {
            const uppercaseName = name.split('_').join(' ');
            const normalisedName = uppercaseName[0] + uppercaseName.substring(1).toLocaleLowerCase();

            return {
                name: normalisedName,
                value: name,
            }
        });

        const reportType = this.props.selectedReportTypeId ? this.props.reportTypesData.byId[this.props.selectedReportTypeId] : undefined;

        let usersList: Array<{
            name: string;
            value: string;
        }> = [];

        if (reportType) {
            usersList = this.props.usersData.allEntries
                .filter(userId => {
                    const userData = this.props.usersData.byId[userId];

                    return userData.projects.includes(reportType.project);
                })
                .map(userId => {
                    const user = this.props.usersData.byId[userId];
                    let userName = user.customFields[this.props.usersData.nameFieldId];

                    const nameField = this.props.usersData.customFields.byId[this.props.usersData.nameFieldId];

                    userName = getReadableDataForCustomField(userName, nameField, userId, 'user');

                    return {
                        name: userName,
                        value: userId,
                    };
                });
        }

        const resetTimeValuesForDate = (date: Moment) => {
            return date.hours(0).minutes(0).seconds(0).milliseconds(0);
        }


        let frequencyHelperMessage = '';

        let reportStartDate = resetTimeValuesForDate(moment());
        let reportEndDate = resetTimeValuesForDate(moment());
        let reportGenerationDate = resetTimeValuesForDate(moment());

        const dayOfMonth = this.state.modifyingVerticalDayOfMonth || 1;
        let lastDayOfMonth = dayOfMonth;
        const formattedDayOfMonth = moment().month(0).date(dayOfMonth).format('Do');

        switch (this.state.modifyingVerticalFrequency) {
            case ReportScheduleFrequency.MONTHLY:
                reportGenerationDate = resetTimeValuesForDate(moment()).add(1, 'month');

                lastDayOfMonth = reportGenerationDate.endOf('month').date();

                reportGenerationDate = reportGenerationDate.date(dayOfMonth < lastDayOfMonth ? dayOfMonth : lastDayOfMonth);

                reportStartDate = moment(reportGenerationDate).subtract(1, 'month').date(1);
                reportEndDate = moment(reportStartDate).add(1, 'month').subtract(1, 'day');

                frequencyHelperMessage = `Runs on ${formattedDayOfMonth} of every month for the previous month. Next report: ${reportStartDate.format('Do MMM')} - ${reportEndDate.format('Do MMM')} generated on ${reportGenerationDate.format('Do MMM')}`;
                break;
            case ReportScheduleFrequency.QUARTERLY:
                reportGenerationDate = resetTimeValuesForDate(moment()).add(1, 'month');

                // The month is a 0-based index, so the first month of every quarter is divisible by 3
                // The loop below keeps adding a month until the month is divisible by 3
                while (reportGenerationDate.month() % 3) {
                    reportGenerationDate.add(1, 'month');
                }

                lastDayOfMonth = reportGenerationDate.endOf('month').date();

                reportGenerationDate = reportGenerationDate.date(dayOfMonth < lastDayOfMonth ? dayOfMonth : lastDayOfMonth);

                reportStartDate = moment(reportGenerationDate).subtract(3, 'months').date(1);
                reportEndDate = moment(reportStartDate).add(3, 'months').subtract(1, 'day');

                frequencyHelperMessage = `Report will be generated on the ${formattedDayOfMonth} of Jan, Apr, Jul, Oct. Next report: ${reportStartDate.format('Do MMM')} - ${reportEndDate.format('Do MMM')} generated on ${reportGenerationDate.format('Do MMM')}`;
                break;
            default:
                break;
        }

        const addFieldForm =
            <div className={styles.modifyFormCard}>
                <div>
                    <header>
                        <div className={styles.heading}>
                            {translatePhrase('Add Report Schedule')}
                        </div>
                        <button className={styles.cancelCustomFieldButton} title={translatePhrase('Cancel')} onClick={this.toggleReportScheduleAddForm}> <CancelIcon /> </button>
                    </header>
                </div>
                <div className={styles.container}>
                    <ModifyForm hideCancel isNew={true} submitForm={this.addReportSchedule} cancelForm={this.toggleReportScheduleAddForm} validateForm={this.validateVerticalForm}>
                        <EnhancedInputText placeholder="Name" onEnterPress={this.addReportSchedule} onChange={this.updateScheduleName} />
                        <EnhancedInputText placeholder="User" onEnterPress={this.addReportSchedule} onChange={this.updateScheduleUser} options={usersList} />
                        <EnhancedInputText placeholder="Frequency" onEnterPress={this.addReportSchedule} onChange={this.updateScheduleFrequency} options={frequencyOptions} />
                        <EnhancedInputText placeholder="Day of month" type="number" onEnterPress={this.addReportSchedule} onChange={this.updateFieldDayOfMonth} default={String(this.state.modifyingVerticalDayOfMonth)} />
                        {frequencyHelperMessage && <section className={styles.frequencyHelperMessage}>{frequencyHelperMessage}</section>}
                        <EnhancedInputText isBooleanField placeholder="Send emails" onEnterPress={this.addReportSchedule} onChange={this.updateShouldSendEmails} defaultBooleanValue={true} />
                        {this.state.shouldSendEmails && <EnhancedInputText placeholder="Emails" onEnterPress={this.addReportSchedule} onChange={this.updateScheduleEmails} />}
                    </ModifyForm>
                </div>
            </div>;

        const scheduleIdsForReportType = this.props.reportSchedulesData.allEntries.filter(scheduleId => {
            const reportSchedule = this.props.reportSchedulesData.byId[scheduleId];
            return reportSchedule.type === this.props.selectedReportTypeId;
        })

        const reportScheduleNames = scheduleIdsForReportType.filter(scheduleId => this.props.reportSchedulesData.byId[scheduleId].name);

        const schedules = scheduleIdsForReportType.map((id) => {
            const reportSchedule = this.props.reportSchedulesData.byId[id];
            const remainingScheduleNames = reportScheduleNames.filter(name => name.toLowerCase() !== reportSchedule.name.toLowerCase());

            return <ReportSchedule
                scheduleId={id}
                isReadOnly={!this.props.isWritable}
                remainingScheduleNames={remainingScheduleNames}
            />
        });

        return (<div className={styles.innerFocusContainer}>
            <div className={styles.importExportButtonHolder + ' ignore-top-level-onclickoutside ignore-react-onclickoutside'}>
                {this.props.selectedReportTypeId && <button onClick={() => this.props.selectedReportTypeId && duplicateReportType(this.props.selectedReportTypeId)}> <DuplicateIcon /> {translatePhrase('Duplicate')} </button>}
                {this.props.selectedReportTypeId && <button onClick={() => this.prepareExport(this.props.selectedReportTypeId)}> <ExportIcon /> {translatePhrase('Export')} </button>}

                <input type="file" accept=".json, application/json" id="import-file-input" className={styles.hiddenFile} onChange={this.handleFileUpload} />
                {this.props.isSuperUser && <label htmlFor="import-file-input"> <ImportIcon /> {translatePhrase('Import')} </label>}
            </div>

            {this.props.isWritable && <section className={styles.newVerticalHolder + ' ignore-react-onclickoutside'}>
                {this.state.importingReportData && importReportTypeForm}
            </section>}

            <div className={styles.reportsHolder}>

                {this.state.isAllReportTypes && <div className={styles.allReportTypes}>
                    <ReportTypesList isSearchable showCardCount onDuplicate={this.onDuplicate} onExport={this.onExport} onImport={this.onImport} showFlowchart={this.showFlowchart} unSelectReportType={this.props.unSelectReportType} />
                </div>}

                {this.props.selectedReportTypeId && this.state.isAllReportTypes &&
                    <Link to={`/reports/flowchart/${this.props.selectedReportTypeId}/`}>
                        <div className={styles.goToFlowchart + ' ignore-react-onclickoutside'}>
                            <Button size={'medium'} isRounded text={translatePhrase('Go to Flowchart')} padding={'0px 15px'} type={'primary'} color={'contrast'} />
                        </div>
                    </Link>
                }

                {this.state.isAllReportTypes && this.props.selectedReportTypeId && <div className={styles.cardsTree + ' ignore-react-onclickoutside'}>
                    <header className={styles.treeHeader}>
                        {translatePhrase('Schedules')}
                    </header>
                    <div className={styles.cardsTreeHolder}>
                        {this.props.isWritable && <section className={styles.newVerticalHolder + ' ignore-react-onclickoutside'}>
                            {this.state.isShowingAddForm ? addFieldForm : <section onClick={this.toggleReportScheduleAddForm} className={styles.newCustomFieldPrompt}> + {translatePhrase('Add Schedule')}</section>}
                        </section>}
                        {schedules}
                    </div>

                </div>}
            </div>
        </div>);
    }
}

const Locations = connect(mapStateToProps, mapDispatchToProps)(ConnectedLocations);

export default Locations;