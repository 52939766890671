import React, { Component } from 'react';
import { CardType } from '../../../widgets/card/Card';
import CardsList from '../../../widgets/card/CardsList';
import ModifyForm from '../../../widgets/card/ModifyForm';
import { LevelState } from '../../../shared/store/structure/level/types';
import { INewGroupTypeData, IUpdateableGroupTypeData } from '../../../shared/store/groups/types/types';
import uuid from 'uuid';
import { ProjectState } from '../../../shared/store/structure/project/types';
import { Option } from '../../flowchart/drop-down/ListItem';
import EnhancedInputText from '../../../widgets/form/InputText';
import { MemberState } from '../../../shared/store/members/types';

export interface OwnProps {
    selectedId?: string;
    heading: string;
    isReadOnly?: boolean;
    showCardCount?: boolean;
    isSearchable?: boolean;

    onSelectCard: (id: string) => void;
    onUnSelectCard: () => void;
    onDuplicate: () => void;
}

export interface StateProps {
    read: boolean,
    write: boolean,
    restrictStructureChanges: boolean,

    projectsData: ProjectState,
    levelsData: LevelState,
    membersData: MemberState,

    customFields: Array<Option>,

    cardsList: Array<CardType>,
    selectedCard?: IUpdateableGroupTypeData,
}

export interface DispatchProps {
    reOrderCards: (sourceIndex: number, destinationIndex: number) => void,
    addCard: (payload: INewGroupTypeData) => void,
    deleteCard: (id: string) => void,
    updateCard: (payload: IUpdateableGroupTypeData) => void,
}

type Props = OwnProps & StateProps & DispatchProps;

export interface OwnState {
    isShowingAddForm: boolean,
    isShowingModifyForm: boolean,
    modifyingCardName: string,
    modifyingCardProject: string,
    modifyingCardLevel: string,
    modifyingCardIsRequired: string,
    modifyingCardUniqueField: string,
};

class CardTreeLevel<TProps extends Props, TState extends OwnState> extends Component<TProps, TState> {

    handleSelectCard = (id: string) => {
        this.props.onSelectCard(id);
    }

    toggleModifyForm = () => {
        let toggledState = !this.state.isShowingAddForm;

        if (this.state.isShowingModifyForm) {
            if (this.props.selectedCard) {
                this.setState({
                    isShowingModifyForm: false,
                    isShowingAddForm: false,
                    modifyingCardName: this.props.selectedCard.name,
                    modifyingCardProject: this.props.selectedCard.project,
                    modifyingCardLevel: this.props.selectedCard.level,
                    modifyingCardIsRequired: this.props.selectedCard.isRequired ? 'Yes' : 'No',
                    modifyingCardUniqueField: this.props.selectedCard.uniqueFieldId,
                });
            }
        } else {
            this.setState({
                isShowingAddForm: toggledState,
                modifyingCardName: '',
                modifyingCardProject: '',
                modifyingCardLevel: '',
                modifyingCardIsRequired: 'Yes',
                modifyingCardUniqueField: '',
            });
        }
    }

    editSelectedCard = () => {

        if (!this.props.selectedCard) {
            throw new Error('Cannot edit card since nothing is selected');
        }

        this.setState({
            isShowingAddForm: false,
            isShowingModifyForm: true,
            modifyingCardName: this.props.selectedCard.name,
            modifyingCardProject: this.props.selectedCard.project,
            modifyingCardLevel: this.props.selectedCard.level,
            modifyingCardIsRequired: this.props.selectedCard.isRequired ? 'Yes' : 'No',
        });
    }

    updateCardName = (value: string) => {
        this.setState({
            modifyingCardName: value
        });
    }

    updateCardProject = (value: string) => {
        this.setState({
            modifyingCardProject: value,
            modifyingCardLevel: '',
        });
    }

    updateCardLevel = (value: string) => {
        this.setState({
            modifyingCardLevel: value
        });
    }

    updateCardIsRequired = (value: string) => {
        this.setState({
            modifyingCardIsRequired: value,
        });
    }

    updateCardUniqueFields = (value: string) => {
        this.setState({
            modifyingCardUniqueField: value,
        });
    }

    getLevelsOfProject = () => {

        if (!this.state.modifyingCardProject) {
            return [];
        }

        const allLevelsInProject = this.props.projectsData.byId[this.state.modifyingCardProject].children;

        const allowedLevelsData = allLevelsInProject.map(levelId => {
            return {
                name: this.props.levelsData.byId[levelId].name,
                value: levelId,
            };
        });

        return allowedLevelsData;
    }

    addCard = () => {

        const validationResult = this.validateCard();

        if (typeof validationResult === 'string') {
            return;
        }

        const nameFieldId = uuid.v4();

        this.props.addCard({
            name: this.state.modifyingCardName,
            project: this.state.modifyingCardProject,
            level: this.state.modifyingCardLevel,
            isRequired: this.state.modifyingCardIsRequired === 'Yes',
            uniqueFieldId: nameFieldId,
            nameFieldId: nameFieldId,
            subTitleFieldId: uuid.v4(),
        });

        this.setState({
            modifyingCardName: '',
            modifyingCardIsRequired: '',
            isShowingAddForm: false
        });
    }

    updateCard = () => {
        const validationResult = this.validateCard();

        if (typeof validationResult === 'string') {
            return;
        }

        if (!this.props.selectedCard) {
            return;
        }

        this.props.updateCard({
            id: this.props.selectedCard.id,
            name: this.state.modifyingCardName,
            project: this.state.modifyingCardProject,
            level: this.state.modifyingCardLevel,
            isRequired: this.state.modifyingCardIsRequired === 'Yes',
            uniqueFieldId: this.state.modifyingCardUniqueField,
            nameFieldId: this.props.selectedCard.nameFieldId,
            subTitleFieldId: this.props.selectedCard.subTitleFieldId,
        });

        this.setState({
            isShowingModifyForm: false,
            modifyingCardName: '',
            modifyingCardProject: '',
            modifyingCardIsRequired: '',
            isShowingAddForm: false
        });
    }

    validateCard = () => {
        if (!this.state.modifyingCardName) {
            return 'Enter a valid name';
        }

        if (!this.state.modifyingCardProject) {
            return 'Enter a valid project';
        }

        if (!this.state.modifyingCardLevel) {
            return 'Enter a valid level';
        }

        if (!this.state.modifyingCardName) {
            return 'Select if the field is required';
        }

        // Validation required only during updates
        if (this.props.selectedCard) {

            // If you are updating a group type as required, all existing members need to have a group of this group type
            if (!this.props.selectedCard.isRequired && this.state.modifyingCardIsRequired === 'Yes') {
                const groupTypeId = this.props.selectedCard.id;

                for (const memberId of this.props.membersData.allEntries) {
                    const member = this.props.membersData.byId[memberId];
                    const groupsOfType = member.groups[groupTypeId];

                    if (!groupsOfType || groupsOfType.length === 0) {
                        return 'There are members without this group type';
                    }
                }
            }
        }

        return true;
    }

    render() {

        const projectsList = this.props.projectsData.allEntries.map(projectId => {
            return {
                name: this.props.projectsData.byId[projectId].name,
                value: projectId,
            }
        });

        const levelsList = this.getLevelsOfProject();

        const modifyForm = <ModifyForm hideCancel isNew={!this.state.isShowingModifyForm} submitForm={this.state.isShowingModifyForm ? this.updateCard : this.addCard} cancelForm={this.toggleModifyForm} validateForm={this.validateCard}>
            <EnhancedInputText placeholder="Name" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardName} default={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.name : ''} key={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.id : 0} />
            <EnhancedInputText isBooleanField placeholder="Is Required" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardIsRequired} defaultBooleanValue={this.state.isShowingModifyForm && this.props.selectedCard && this.props.selectedCard.isRequired ? true : false} />
            {this.state.isShowingModifyForm && <EnhancedInputText placeholder="Unique field" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardUniqueFields} default={this.state.isShowingModifyForm && this.props.selectedCard && this.props.selectedCard.uniqueFieldId ? this.props.customFields.find(option => option.value === this.props.selectedCard?.uniqueFieldId)?.name : ''} options={this.props.customFields} />}
            <EnhancedInputText placeholder="Project" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardProject} default={this.state.isShowingModifyForm && this.props.selectedCard && this.props.selectedCard.project ? this.props.projectsData.byId[this.props.selectedCard.project].name : ''} options={projectsList} />
            <EnhancedInputText key={this.state.modifyingCardProject} placeholder="Level" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardLevel} default={this.state.isShowingModifyForm && this.props.selectedCard && this.props.selectedCard.level && levelsList.map(level => level.value).includes(this.props.selectedCard.level) ? this.props.levelsData.byId[this.props.selectedCard.level].name : ''} options={levelsList} />
        </ModifyForm>;

        return <CardsList
            heading={this.props.heading}
            cards={this.props.cardsList}
            selectedCard={this.props.selectedCard}
            onSelectCard={this.handleSelectCard}
            onUnselectCard={this.props.onUnSelectCard}
            onDeleteCard={this.props.deleteCard}
            onEditCard={this.editSelectedCard}
            onReorderCards={this.props.reOrderCards}
            modifyForm={modifyForm}
            isShowingAddForm={this.state.isShowingAddForm}
            isShowingEditForm={this.state.isShowingModifyForm}
            onAddCard={this.toggleModifyForm}
            isDeleteRestricted={this.props.restrictStructureChanges}
            isReadOnly={this.props.isReadOnly || !this.props.write}
            isSearchable={!!this.props.isSearchable}
            showCardCount={!!this.props.showCardCount}
            onDuplicate={this.props.onDuplicate}
        />
    }
}

export default CardTreeLevel;