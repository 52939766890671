import React, { Component } from 'react';
import styles from './TooltipList.module.scss';
import { translatePhrase } from '../../shared/helpers/translation';
import { ReactComponent as SearchIcon } from '../../assets/new-custom-icons/dashboard/search.svg';

type OwnProps = {
    isShowingSearch: boolean,
    listElements: Array<JSX.Element>,
    headerText?: string,
    placeholderText?: string,
    closeTooltip: () => void,
    headerIcon?: JSX.Element,

    search?: (searchString: string) => void,
};

class TooltipList extends Component<OwnProps>  {
    static defaultProps = {
        isShowingSearch: false,
        listElements: [],
    }

    componentDidMount() {
        if (this.props.search) {
            this.props.search('');
        };
    }

    render() {
        return (
            <div className={styles.tooltipHolder}>
                { this.props.headerText && <header>                    
                    { this.props.headerIcon }
                    <h5> { this.props.headerText } </h5>
                </header> }

                {this.props.isShowingSearch && <div className={styles.searchInputHolder}>
                    <input type="text" className={styles.searchBox} onChange={e => this.props.search && this.props.search(e.currentTarget.value)} placeholder={ this.props.placeholderText ? this.props.placeholderText : translatePhrase('Search the list') }/>
                    <SearchIcon />
                </div>}

                <section className={styles.listItems}>
                    {this.props.listElements
                    .map(child => {
                        return <section className={styles.listItem}>{child}</section>
                    })}
                </section>
            </div>
        );
    }
}

export default TooltipList;