import Button from '../../../widgets/button/CommonButton';
import styles from './PackageSnapshot.module.scss';
import { ReactComponent as MembersIcon } from '../../../common/assets/user-favorite-alt.svg';
import { ReactComponent as GroupsIcon } from '../../../common/assets/group.svg';
import { ReactComponent as WorkflowsIcon } from '../../../common/assets/flow-data.svg';
import { ReactComponent as DataIcon } from '../../../common/assets/data-base.svg';

import { Link } from 'react-router-dom';
import { ImageCarousel } from '../image-carousel/ImageCarousel';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../shared/store/types';
import { MarketplacePackage, PackageType } from '../../../shared/store/package/types';
import DecrementingCounter from '../../../widgets/table/DecrementingCounter';
import { useState } from 'react';
import { deletePackage } from '../../../shared/store/package/actions';

interface OwnProps {
    package: MarketplacePackage,
}

function getIconFromType(type: PackageType) {
    switch (type) {
        case 'Member':
            return <MembersIcon />
        case 'Group':
            return <GroupsIcon />
        case 'Workflow':
            return <WorkflowsIcon />
        case 'Data':
            return <DataIcon />
        default:
            return <WorkflowsIcon />
    }
}

export function PackageSnapshot(props: OwnProps) {
    const [deleteTimer, setDeleteTimer] = useState<number|undefined>();
    const myUserId = useSelector((state: ApplicationState) => state.myData.id);
    const myOrganizationId = useSelector((state: ApplicationState) => (state.organization as any).id);
    const dispatch = useDispatch();

    const isMyPackage = props.package.organization === myOrganizationId && props.package.author === myUserId;

    let featuresElement: JSX.Element;

    switch (props.package.type) {
        case 'Member':
        case 'Group':
            featuresElement = <ul className={styles.features}>
                <li> {props.package.customFieldsCount} Custom Fields </li>
            </ul>
            break;
        case 'Workflow':
            featuresElement = <ul className={styles.features}>
                <li> {props.package.customFieldsCount} Custom Fields </li>
                <li> Flowchart </li>
                {props.package.hasAffiliation && <li> Has Affiliation </li>}
            </ul>
            break;
        case 'Data':
            featuresElement = <ul className={styles.features}>
                <li> {props.package.levelsCount} Levels </li>
            </ul>
            break;
    }
    
    function deleteSelectedPackage() {
        dispatch(deletePackage(props.package.id));
        setDeleteTimer(undefined);
    }
    
    function markForDelete() {
        const timeout = window.setTimeout(deleteSelectedPackage, 5000);
        setDeleteTimer(timeout);
    }
    
    function cancelDelete() {
        clearTimeout(deleteTimer);
        setDeleteTimer(undefined);
    }
    
    return <li key={props.package.id} className={styles.productCard}>
        <section className={styles.imageCarouselHolder}>
            <label className={styles.type}> {getIconFromType(props.package.type)} {props.package.type} </label>
            {props.package.imageLinks.length > 0 ? <img className={styles.snapshotPreview} src={props.package.imageLinks[0]} /> : undefined}
        </section>
        <div className={styles.info}>
            <h4> {props.package.name} </h4>
            <h5> {props.package.type} </h5>
            <p>{props.package.description}</p>
            {featuresElement}
        </div>
        
        <div className={styles.buttonsHolder}>
            <Link to={`/marketplace/package-details/${props.package.type.toLocaleLowerCase()}/${props.package.id}`}> <Button text={'View Details'} type={'tertiary'} /> </Link>
            {isMyPackage && typeof deleteTimer === 'undefined' && <Button isRounded text={'Delete'} type={'secondary'} size={'medium'} color={'contrast'} padding={'0px 25px'} onClick={markForDelete} />}
            {isMyPackage && typeof deleteTimer !== 'undefined' && <Button isRounded size={'medium'} type={'secondary'} padding={'0px 25px'} onClick={cancelDelete} functionalText={<DecrementingCounter additionalText={'Undo before'} remaining={5} />} />}
        </div>
    </li>
}