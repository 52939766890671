import React, { Component, createRef, RefObject, KeyboardEvent, FocusEvent, ChangeEvent, FormEvent, MouseEvent } from 'react';
import onClickOutside from 'react-onclickoutside';
import { ReactComponent as DropdownIcon } from '../../assets/new-custom-icons/common/dropdown.svg';
import styles from './InputText.module.scss';
import toggleStyles from './Toggle.module.scss';
import OptionsList from './OptionsList';
import { translatePhrase } from '../../shared/helpers/translation';
import Button from '../button/CommonButton';

export type Option = {
    id: number,
    name: string,
    description?: string,
    value: string,
}

export type OptionInput = {
    name: string,
    description?: string,
    value: string
}

type OwnProps = {
    default?: string,
    options?: Array<OptionInput | string>,
    placeholder?: string,
    icon?: string,
    type: string,
    isDisabled: boolean,
    isSearchDisabled: boolean,
    isBooleanField?: boolean,
    defaultBooleanValue?: boolean
    isAutoFocus?: boolean,
    isHighlighted?: boolean,
    isRequired?: boolean,
    hideInputPlaceholder?: boolean,
    focusColor?: string,
    errorMessage?: string,

    min?: number,
    max?: number,

    onChange: (changedValue: string) => void,
    onEnterPress?: () => void,
    onFocus?: () => void,
}

type OwnState = {
    isShowingOptions: boolean,
    inputValue: string,
    isFocussed: boolean,
    isTextShowing: boolean,
    lastSentInput: string | undefined,
    defaultBooleanValue?: boolean,

    options?: Array<Option>,
}

class InputText extends Component<OwnProps, OwnState> {
    input: RefObject<HTMLInputElement> = createRef();
    textarea: RefObject<HTMLTextAreaElement> = createRef();
    optionsListElement: RefObject<HTMLInputElement> = createRef();

    constructor(props: Readonly<OwnProps>) {
        super(props);

        this.state = {
            inputValue: this.props.default || '',
            isFocussed: false,
            isShowingOptions: false,
            lastSentInput: undefined,
            isTextShowing: false,
            defaultBooleanValue: this.props.defaultBooleanValue || false,
        };

        // If there are options provided for the input...
        if (this.props.options) {

            // ...Mold them to the proper format...
            const options = this.props.options.map((option, index) => {
                return {
                    id: index,
                    name: typeof option === 'string' ? option : option.name,
                    description: typeof option === 'string' ? undefined : option.description,
                    value: typeof option === 'string' ? option : option.value,
                };
            });

            // ...then add them to the state
            this.state = {
                ...this.state,
                options: options
            };
        }
    }

    componentDidUpdate(prevProps: OwnProps) {
        // If there are options provided for the input...
        if (this.props.options && prevProps.options && (this.props.options.length !== prevProps.options.length || JSON.stringify(this.props.options) !== JSON.stringify(prevProps.options))) {

            // ...Mold them to the proper format...
            const options = this.props.options.map((option, index) => {
                return {
                    id: index,
                    name: typeof option === 'string' ? option : option.name,
                    description: typeof option === 'string' ? undefined : option.description,
                    value: typeof option === 'string' ? option : option.value,
                };
            });

            this.setState({
                options: options,
            });
        };

        if (prevProps.default !== this.props.default) {
            this.setState({
                inputValue: this.props.default || ''
            })
        }
    }

    componentDidMount() {
        setTimeout(() => {
            if (this.props.isAutoFocus) {
                this.input.current?.focus();
                this.textarea.current?.focus();
            }
        }, 500);
    }

    static defaultProps = {
        isDisabled: false,
        isSearchDisabled: false,
        type: 'text',
    }

    handleClickOutside = (event: MouseEvent) => {
        if (this.state.isShowingOptions) {
            this.setState({
                isShowingOptions: false,
            });
        }
    }

    // Used to toggle the show/hide text for password types
    toggleText = () => {
        this.setState((prevState, props) => {
            return { isTextShowing: !prevState.isTextShowing }
        });
    }

    handleKeyPress = (e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        enum keyCodes { ENTER_KEY = 13, UP_KEY = 38, DOWN_KEY = 40 };

        if (this.props.type === 'number') {
            const invalidNumberKeys = ["+", "e", "E"];

            if (invalidNumberKeys.includes(e.key)) {
                e.preventDefault();
                return;
            };

            // If the "Arrow Up" key is pressed, increment the input number
            if (e.keyCode === keyCodes.UP_KEY || e.keyCode === keyCodes.DOWN_KEY) {
                const inputValue = (String(isNaN(parseInt(this.state.inputValue)) ? 0 : parseInt(this.state.inputValue) + (e.keyCode === keyCodes.UP_KEY ? 1 : -1)));

                if (this.props.onChange && this.state.lastSentInput !== inputValue) {
                    this.props.onChange(inputValue);

                    this.setState({
                        lastSentInput: inputValue
                    });
                }
                this.setState({ inputValue });
            }
        }

        // If the "Enter" key is pressed, select the highlighted option
        if (e.keyCode === keyCodes.ENTER_KEY) {
            if (this.props.onEnterPress) {
                this.props.onEnterPress();
            }
        }
    }

    // HACK - When a workflow fills in this value, goes forward, goes back, and 
    // removes the contents of this field, the change event isn't fired. This hack 
    // listens to the input element for the empty value, and does the 
    handleInput = (e: FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let inputValue = (e.target as HTMLInputElement).value;

        if (inputValue !== '') {
            return;
        }

        this.changeInput(inputValue);

    }

    changeInput = (inputValue: string) => {

        if (this.props.onChange && this.state.lastSentInput !== inputValue) {
            this.props.onChange(inputValue);

            this.setState({
                lastSentInput: inputValue
            });
        }

        this.setState({
            inputValue,
        });

    }

    handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        let inputValue = e.target.value;
        this.changeInput(inputValue);
    }

    onFocus = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (!this.state.isFocussed) {
            this.setState({
                isFocussed: true,
            });
        }
        if (this.props.onFocus) {
            this.props.onFocus();
        }
    }

    toggleInput = () => {
        this.setState({
            defaultBooleanValue: !this.state.defaultBooleanValue
        });
        setTimeout(() => {
            this.props.onChange(this.state.defaultBooleanValue ? 'Yes' : 'No');
        }, 10);
    }

    onBlur = (e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const that = this;
        window.setTimeout(function () {
            that.setState((state, props) => ({
                // Check if the input is focussed (the input may have gained focus between the blur and the delay)
                isFocussed: document.activeElement === that.input.current,
            }));
        }, 100);
    }

    onSelect = (name: string, value: string) => {
        this.setState({
            inputValue: name,
            isFocussed: false,
            isShowingOptions: false,
        });

        if (this.input && this.input.current) {
            this.input.current.value = name;
            this.input.current.blur();
        }

        if (this.textarea && this.textarea.current) {
            this.textarea.current.value = name;
            this.textarea.current.blur();
        }

        this.props.onChange && this.props.onChange(value);
    }

    toggleOptions = () => {
        if (this.props.options && this.props.options.length > 0) {
            if (this.props.isDisabled) {
                return;
            }
            const newIsShowingOptions = !this.state.isShowingOptions;
            this.setState({
                isShowingOptions: newIsShowingOptions,
            });

            setTimeout(() => {
                if (newIsShowingOptions) {
                    this.optionsListElement.current?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center',
                    });
                }
            }, 400);
        }
    };



    render() {
        let defaultValue = this.state.inputValue;

        if (this.state.options && this.state.options.length > 0) {
            const option = this.state.options.find(option => option.value === this.state.inputValue);
            if (!!option) {
                defaultValue = option.name;
            }
        }

        let inputHolderClass = this.props.type === 'textarea' ? styles.textAreaHolder : styles.inputHolder;

        if (this.props.isDisabled) {
            inputHolderClass += ` ${styles.disableInput}`;
        }

        if (this.state.isFocussed || this.state.isShowingOptions) {
            inputHolderClass += ` ${styles.active}`;
        }

        if (this.props.errorMessage) {
            inputHolderClass += ` ${styles.erroneous}`;
        }

        if (this.props.isHighlighted) {
            inputHolderClass += ` ${styles.isHighlighted}`;
        }

        if (this.props.isRequired) {
            inputHolderClass += ` ${styles.isRequired}`;
        }

        return (
            <section data-selector={"form-input-holder-" + (this.props.placeholder ? this.props.placeholder.toLowerCase() : '')}>
                <div className={inputHolderClass} title={(this.props.placeholder ? this.props.placeholder : '') + (this.props.default ? ' : ' + this.props.default : '')}>

                    {(this.props.hideInputPlaceholder && this.props.default && this.props.default.length > 0) ?
                        ''
                        :
                        this.props.placeholder && !this.props.hideInputPlaceholder && <div onClick={() => this.toggleOptions()}
                            className={styles.placeholder + ' ' + (this.state.isShowingOptions || this.state.isFocussed ? styles.shrink : '')}>
                            {translatePhrase(this.props.placeholder)} </div>}

                    {!this.props.isBooleanField && !this.props.options && this.props.type !== 'textarea' && <input
                        className={this.state.inputValue || this.props.default ? styles.active : ''}
                        type={this.props.type ? (this.props.type === 'password' && this.state.isTextShowing ? 'text' : this.props.type) : 'text'}
                        autoComplete="off"
                        defaultValue={this.props.default}
                        onChange={this.handleChange}
                        onInput={this.handleInput}
                        onKeyDown={this.handleKeyPress}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        readOnly={this.props.isSearchDisabled}
                        disabled={this.props.isDisabled}
                        ref={this.input}
                        min={this.props.min}
                        max={this.props.max}
                    />}

                    {!this.props.isBooleanField && !this.props.options && this.props.type === 'textarea' && <textarea
                        className={this.state.inputValue || this.props.default ? styles.active : ''}
                        autoComplete="off"
                        defaultValue={this.props.default}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyPress}
                        onFocus={this.onFocus}
                        onBlur={this.onBlur}
                        readOnly={this.props.isSearchDisabled}
                        disabled={this.props.isDisabled}
                        ref={this.textarea}
                    ></textarea>}

                    {this.props.options && <div onClick={() => this.toggleOptions()} className={this.props.isDisabled ? styles.disabledSelectedOptionText : styles.selectedOptionText}>{defaultValue}</div>}

                    {!this.props.isDisabled && this.state.options && <Button dataSelector="form-toggle-options" onClick={() => this.toggleOptions()} isSelected={this.state.isShowingOptions} icon={<DropdownIcon />} size={'small'} isBlock={false} isRounded={true} />}

                    {this.props.isBooleanField &&
                        <section className={styles.booleanInputHolder + ' ' + (this.state.defaultBooleanValue ? toggleStyles.on : toggleStyles.off)} onClick={this.toggleInput}>
                            <div className={toggleStyles.slider}></div>
                        </section>
                    }
                </div>

                <section ref={this.optionsListElement} style={{ display: this.state.isShowingOptions ? 'block' : 'none' }}>
                    {this.state.options && !this.props.isDisabled &&
                        <OptionsList
                            isShowing={this.state.isShowingOptions}
                            isSearchDisabled={this.props.isSearchDisabled}
                            options={this.state.options}
                            onSelect={this.onSelect}
                            isAutoFocus={this.props.isAutoFocus} />
                    }
                </section>
            </section>
        );
    }
}

const EnhancedInputText = onClickOutside(InputText, {
    excludeScrollbar: true
});

export default EnhancedInputText;