import React, { Component } from 'react';
import StepPiece from './step-piece/StepPiece'
import styles from './step-piece/StepPiece.module.scss';

import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';
import Input from '../Input';
import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import { Option } from '../drop-down/ListItem';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setUpdateDueDateValue, setTargetPiece, setWorkflow } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import { valuePieceSlotTarget } from './utilities';
import { FlowchartContext, FlowchartInfoForPiece } from '../../../contexts/flowchart-context';
import DropDownSearchBox from '../drop-down/DropDownSearchBox';
import { getFilteredOptionsBySearch } from '../drop-down/DropDownSearchBox';


type UpdateDueDatePieceProps = {
    nextPiece?: JSX.Element,
    workflowVariableId?: string,
    variables: Array<Option>,
    dueDatePiece?: JSX.Element,
    dueDateText?: string,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,
        variablesData: state.flowchart.variables,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
        setWorkflow: (pieceId: string, workflowId: string) => dispatch(setWorkflow(pieceId, workflowId)),
        setUpdateDueDateValue: (pieceId: string, value: string) => dispatch(setUpdateDueDateValue(pieceId, value)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = UpdateDueDatePieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type UpdateDueDateState = {
    isHoveringOverVariable: boolean,
    workflowSearchTerm: string,
}

class ConnectedUpdateDueDatePiece extends Component<Props, UpdateDueDateState> {
    
    constructor(props: Props) {
        super(props);
        this.state = {
            isHoveringOverVariable: false,
            workflowSearchTerm: "",
        };
    } 

    handleHoverOverVariable = () => {
        this.setState({
            isHoveringOverVariable: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }
        
        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfVariablePiece = () => {
        this.setState({
            isHoveringOverVariable: false,
        });
    };

    handleLeftVariableValueUpdate = (value: string) => {
        this.props.setUpdateDueDateValue(this.props.pieceId, value);
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && this.state.isHoveringOverVariable) {

            this.props.setUpdateDueDateValue(this.props.pieceId, this.props.lastDraggedPiece.id);
            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverVariable: false,
            });
        }
    }

    updateValidity = (isValid: boolean, flowchartContext: FlowchartInfoForPiece) => {
        if (!isValid) {
            const invalidPieceInfo = flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId);

            if (!invalidPieceInfo && flowchartContext.setInvalidPiece) {
                flowchartContext.setInvalidPiece(this.props.pieceId, flowchartContext.parentSplitPieceIds);
            }
        }

        if (isValid && flowchartContext.removeInvalidPiece && flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId)) {
            flowchartContext.removeInvalidPiece(this.props.pieceId);
        }
    }

    searchForWorkflows = (searchTerm: string) => {
        this.setState({ workflowSearchTerm: searchTerm });
    }

    render() {
        const { workflowSearchTerm } = this.state;
        return <FlowchartContext.Consumer>
            {
                flowchartContext => {
                    const variableName = this.props.workflowVariableId && this.props.workflowVariableId in this.props.variablesData.byId ? this.props.variablesData.byId[this.props.workflowVariableId].name : undefined;

                    const isValid = !this.props.workflowVariableId || !!this.props.variables.find(option => option.value === this.props.workflowVariableId);
                    this.updateValidity(isValid, flowchartContext);

                    const filteredWorkflows = getFilteredOptionsBySearch(this.props.variables, workflowSearchTerm);

                    const variableSelectBox = <SelectBox theme="indigo" selectionPromptText={variableName ? variableName : 'Current workflow'}>
                        <DropDownList theme="indigo" dismissAfterSelection={false}>
                             <DropDownSearchBox
                                handleSearchInputChange={this.searchForWorkflows}
                                placeholder={"Search by name"}
                                searchTerm={workflowSearchTerm}
                                />
                            {filteredWorkflows.map((variable, index) => <ListItem name={variable.name} value={variable.value} key={index} theme="indigo" onClick={this.props.setWorkflow.bind(this, this.props.pieceId)} />)}
                        </DropDownList>
                    </SelectBox>;
                
                    return (
                        <StepPiece theme={isValid ? "aqua" : "red"} {...this.props}>
                            <div className={styles.text}>Update due date of</div>
                            {variableSelectBox}
                            <div className={styles.text}>to</div>
                            {this.props.dueDatePiece ? this.props.dueDatePiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverVariable && !!this.props.targetPiece} defaultText={this.props.dueDateText} onMouseOver={this.handleHoverOverVariable} onMouseOut={this.handleHoverOutOfVariablePiece} onChange={this.handleLeftVariableValueUpdate} />}
                        </StepPiece>
                    )
                }
            }
        </FlowchartContext.Consumer>
    }
}

const UpdateDueDatePiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedUpdateDueDatePiece);

export default UpdateDueDatePiece;