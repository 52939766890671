import { UPDATE_TRANSLATION, UPDATE_MULTIPLE_TRANSLATIONS, SET_SEARCH_TERM, SET_SHOW_UNTRANSLATED } from './types';

export function updateTranslation(originalWord: string, translatedWord: string, language: string) {
    return {
        type: UPDATE_TRANSLATION,
        originalWord,
        translatedWord,
        language,
    };
}

export function updateMultipleTranslations(translations: Array<{originalWord: string, translatedWord: string}>, language: string) {
    return {
        type: UPDATE_MULTIPLE_TRANSLATIONS,
        translations,
        language,
    };
}

export function setLanguageSearchTerm(searchTerm: string) {
    return {
        type: SET_SEARCH_TERM,
        searchTerm
    }
}

export function setShowUntranslated(flag: boolean) {
    return {
        type: SET_SHOW_UNTRANSLATED,
        showUntranslated: flag
    }
}