import React, { Component } from 'react';
import styles from './List.module.scss';

import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/types';
import { Permissions } from '../../../shared/store/permissions/types';

import UsersTable from './UsersTable';
import UserCSV from './UserCSV';
import { IUpdateableUserData } from '../../../shared/store/users/types';
import { Dispatch } from 'redux';
import { addUser } from '../../../shared/store/users/actions';
import { isUUID } from '../../../shared/helpers/utilities';
import { translatePhrase } from '../../../shared/helpers/translation';

type OwnProps = {
};

const mapStateToProps = (state: ApplicationState) => {
    const canEditUsers = state.permissions.myPermissions.general.Users === Permissions.WRITE;
    const canViewUsers = canEditUsers || state.permissions.myPermissions.general.Users === Permissions.READ;
    
    return {
        filtersExpanded: false,
        read: canViewUsers,
        write: canEditUsers,
        structureData: state.structure,
        userData: state.users,
        languageData: state.internationalization.languages,
        isSuperUser: !isUUID(state.myData.id),
        isSettled: (state.myData.isLoaded || state.myData.isPartiallyLoaded) && !state.myData.isPushingData,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        addUser: (payload: IUpdateableUserData) => dispatch(addUser(payload)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = StateProps & DispatchProps & OwnProps;

type OwnState = {
}

class ConnectedUsers extends Component<Props, OwnState> {
    render() {

        if (!this.props.isSettled) {
            return <section className={styles.innerSection}>
            </section>
        }
        
        return (
            <section className={styles.innerSection}>
                <div className={this.props.filtersExpanded ? styles.tableArea : styles.expandedTableArea}>
                    <UsersTable isReadOnly={!this.props.write} />
                </div>
            </section>
        );
    }
};

const Users = connect(mapStateToProps, mapDispatchToProps)(ConnectedUsers);

export default Users;