import React, { Component } from 'react';
import draftToHtml from 'draftjs-to-html';
import ReactHtmlParser from 'react-html-parser';
import StepPiece from './step-piece/StepPiece';
import styles from './step-piece/StepPiece.module.scss';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, showRichTextEditor } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';


type ReturnRichTextPieceProps = {
    textToShow: string,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
        showRichTextEditor: () => dispatch(showRichTextEditor()),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = ReturnRichTextPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type ReturnRichTextPieceState = {
}

class ConnectedReturnRichTextPiece extends Component<Props, ReturnRichTextPieceState> {

    getShowMarkupForRichText = (value: string) => {
        let stringifiedHtml: string;
        try {
            stringifiedHtml = draftToHtml(JSON.parse(value));
        } catch (e) {
            stringifiedHtml = '<p>Incorrect HTML</p>';
        }

        return ReactHtmlParser(stringifiedHtml);
    }

    render() {

        return (
            <StepPiece theme="aqua" {...this.props} hasLowerLock={false}>
                <div className={styles.text}>Return</div>
                <div className={styles.editorWrapperSmall} onClick={this.props.showRichTextEditor}>
                    {this.props.textToShow ? this.getShowMarkupForRichText(this.props.textToShow) : <div>Click here to enter rich text data</div>}
                </div>
            </StepPiece>
        )
    }
}

const ReturnRichTextPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedReturnRichTextPiece);

export default ReturnRichTextPiece;