import React, { Component } from 'react';
import styles from './ContainerPiece.module.scss';

import FlowchartPiece, { OwnProps as FlowchartPieceProps } from '../FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setNextPiece, setInnerPiece, togglePieceExpansion, toggleAllPiecesExpansion } from '../../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../../shared/store/types';
import { translatePhrase } from '../../../../shared/helpers/translation';
import { PieceType } from '../../../../shared/store/flowchart/pieces/types';
import { setErrorMessage, clearErrorMessage } from '../../../../shared/store/my-data/actions';
import { getAllExpandablePiecesInPiece } from '../../../../shared/store/flowchart/helpers/pieces';

import ExpandIcon from '../../../../assets/flowchart/expand.svg';
import ExpandAllIcon from '../../../../assets/flowchart/expand-all.svg';
import CollapseIcon from '../../../../assets/flowchart/collapse.svg';
import CollapseAllIcon from '../../../../assets/flowchart/collapse-all.svg';
import { FlowchartContext, PieceHighlightColour } from '../../../../contexts/flowchart-context';

type ContainerPieceEssentialsProps = {
    nextPiece?: JSX.Element,

    upperArmContent?: JSX.Element,
    lowerArmContent?: JSX.Element,
    theme?: 'indigo' | 'pink' | 'aqua' | 'camo' | 'red',

    handleHoverOverInnerPiece: () => void,
    handleHoverOverNextPiece: () => void,
    isCollapsible?: boolean
}

const mapStateToProps = (state: ApplicationState, ownProps: FlowchartPieceProps) => {

    const pieceData = state.flowchart.pieces;
    const isPieceExpanded = state.flowchart.pieces.isPieceExpanded;

    return {
        pieceData,
        isPieceExpanded,
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,
        comparisionWorkflowTypeId: state.flowchart.pieces.comparison.workflowTypeId
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string | undefined) => dispatch(setTargetPiece(pieceId)),
        setNextPiece: (targetPieceId: string, draggedPieceId: string) => dispatch(setNextPiece(targetPieceId, draggedPieceId)),
        setInnerPiece: (targetPieceId: string, draggedPieceId: string) => dispatch(setInnerPiece(targetPieceId, draggedPieceId)),
        setErrorMessage: (message: string) => dispatch(setErrorMessage(message, true)),
        clearErrorMessage: () => dispatch(clearErrorMessage()),
        togglePieceExpansion: (pieceId: string, isExpanded: boolean) => dispatch(togglePieceExpansion(pieceId, isExpanded)),
        toggleAllPiecesExpansion: (pieceId: Array<string>, isExpanded: boolean) => dispatch(toggleAllPiecesExpansion(pieceId, isExpanded)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = ContainerPieceEssentialsProps & StateProps & DispatchProps & FlowchartPieceProps;

type ContainerPieceEssentialsState = {
    isHoveringOverNextPiece: boolean,
    isHoveringOverInnerPiece: boolean,

    isExpanded: boolean
}

class ConnectedContainerPieceEssentials extends Component<Props, ContainerPieceEssentialsState>  {

    state = {
        isHoveringOverNextPiece: false,
        isHoveringOverInnerPiece: false,
        isExpanded: false
    };

    handleHoverOverNextPiece = () => {
        this.setState({
            isHoveringOverNextPiece: true,
        });

        this.props.handleHoverOverNextPiece();
    };

    handleHoverOutOfNextPiece = () => {
        this.setState({
            isHoveringOverNextPiece: false,
        });
    };

    handleHoverOverInnerPiece = () => {
        this.setState({
            isHoveringOverInnerPiece: true,
        });

        this.props.handleHoverOverInnerPiece();
    };

    handleHoverOutOfInnerPiece = () => {
        this.setState({
            isHoveringOverInnerPiece: false,
        });
    };

    componentDidMount() {
        if (!this.props.isPieceExpanded[this.props.pieceId]) {
            this.toggleExpansion();
        };
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.comparisionWorkflowTypeId !== prevProps.comparisionWorkflowTypeId && !this.props.comparisionWorkflowTypeId) {
            const pieceType = this.props.pieceData.byId[this.props.pieceId].type;
            if (pieceType === PieceType.GROUP) {
                setTimeout(() => {
                    this.props.togglePieceExpansion(this.props.pieceId, true);
                }, 500)
            }
        }

        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && (this.state.isHoveringOverNextPiece || this.state.isHoveringOverInnerPiece)) {
            if (this.state.isHoveringOverNextPiece) {

                if (this.props.nextPiece) {
                    const currentPiece = this.props.pieceData.byId[this.props.pieceId];

                    let followingPiece = this.props.lastDraggedPiece;

                    while ('nextPiece' in followingPiece && !!followingPiece.nextPiece) {
                        followingPiece = this.props.pieceData.byId[followingPiece.nextPiece];
                    }

                    if ('nextPiece' in currentPiece && currentPiece.nextPiece) {
                        if (followingPiece.type === PieceType.END || followingPiece.type === PieceType.RETURN ||
                            followingPiece.type === PieceType.SUCCESS || followingPiece.type === PieceType.ERROR) {
                            this.props.setErrorMessage(translatePhrase('Please remove the last piece and continue'));
                            setTimeout(() => {
                                this.props.clearErrorMessage();
                            }, 4000);

                            this.setState({
                                isHoveringOverNextPiece: false,
                            });
                            return;
                        }

                        this.props.setNextPiece(followingPiece.id, currentPiece.nextPiece);
                    }
                }

                this.props.setNextPiece(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverInnerPiece) {

                if (this.props.children) {
                    const currentPiece = this.props.pieceData.byId[this.props.pieceId];

                    let followingPiece = this.props.lastDraggedPiece;

                    while ('nextPiece' in followingPiece && !!followingPiece.nextPiece) {
                        followingPiece = this.props.pieceData.byId[followingPiece.nextPiece];
                    }

                    if ('innerPiece' in currentPiece && currentPiece.innerPiece) {
                        if (followingPiece.type === PieceType.END || followingPiece.type === PieceType.RETURN ||
                            followingPiece.type === PieceType.SUCCESS || followingPiece.type === PieceType.ERROR) {
                            this.props.setErrorMessage(translatePhrase('Please remove the last piece and continue'));
                            setTimeout(() => {
                                this.props.clearErrorMessage();
                            }, 4000);

                            this.setState({
                                isHoveringOverInnerPiece: false,
                            });
                            return;
                        }

                        this.props.setNextPiece(followingPiece.id, currentPiece.innerPiece);
                    }
                }

                this.props.setInnerPiece(this.props.pieceId, this.props.lastDraggedPiece.id);
            }

            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverNextPiece: false,
                isHoveringOverInnerPiece: false,
            });
        }
    };

    toggleExpansion = () => {
        const isExpanded = this.props.isPieceExpanded[this.props.pieceId];
        this.props.togglePieceExpansion(this.props.pieceId, !isExpanded);
    };

    toggleExpansionAll = () => {
        const isExpanded = this.props.isPieceExpanded[this.props.pieceId];
        const piece = this.props.pieceData.byId[this.props.pieceId];

        if (!('innerPiece' in piece)) {
            return;
        }

        const innerPieceId = piece.innerPiece;

        if (typeof innerPieceId === 'undefined') {
            return;
        }

        const expandablePieces = [this.props.pieceId].concat(getAllExpandablePiecesInPiece(innerPieceId, this.props.pieceData));
        this.props.toggleAllPiecesExpansion(expandablePieces, !isExpanded);
    };


    render() {
        let themeClass: string;
        const currentPiece = this.props.pieceData.byId[this.props.pieceId];
        const nextPieceId = 'nextPiece' in currentPiece ? currentPiece.nextPiece : undefined;

        switch (this.props.theme) {
            case 'indigo':
                themeClass = styles.indigoContainerPiece;
                break;
            case 'pink':
                themeClass = styles.pinkContainerPiece;
                break;
            case 'aqua':
                themeClass = styles.aquaContainerPiece;
                break;
            case 'camo':
                themeClass = styles.camoContainerPiece;
                break;
            case 'red':
                themeClass = styles.redContainerPiece;
                break;
            default:
                themeClass = styles.indigoContainerPiece;
                break;
        };

        return <FlowchartContext.Consumer>
            {
                (flowchartContext) => {
                    const highlightColor = flowchartContext.highlights && flowchartContext.highlights[this.props.pieceId];
                    let highlightClass = styles.noHighlight;

                    switch (highlightColor) {
                        case PieceHighlightColour.GREEN:
                            highlightClass = styles.addedHighlight;
                            break;
                        case PieceHighlightColour.YELLOW:
                            highlightClass = styles.updatedHighlight;
                            break;
                        case PieceHighlightColour.PURPLE:
                            highlightClass = styles.movedHighlight;
                            break;
                        case PieceHighlightColour.RED:
                            highlightClass = styles.deletedHighlight;
                            break;
                    }

                    let middleHighlightClass = styles.middleNoHighlight;

                    switch (highlightColor) {
                        case PieceHighlightColour.GREEN:
                            middleHighlightClass = styles.middleAddedHighlight;
                            break;
                        case PieceHighlightColour.YELLOW:
                            middleHighlightClass = styles.middleUpdatedHighlight;
                            break;
                        case PieceHighlightColour.PURPLE:
                            middleHighlightClass = styles.middleMovedHighlight;
                            break;
                        case PieceHighlightColour.RED:
                            middleHighlightClass = styles.middleDeletedHighlight;
                            break;
                    }

                    let lowerHighlightClass = styles.lowerNoHighlight;

                    switch (highlightColor) {
                        case PieceHighlightColour.GREEN:
                            lowerHighlightClass = styles.lowerAddedHighlight;
                            break;
                        case PieceHighlightColour.YELLOW:
                            lowerHighlightClass = styles.lowerUpdatedHighlight;
                            break;
                        case PieceHighlightColour.PURPLE:
                            lowerHighlightClass = styles.lowerMovedHighlight;
                            break;
                        case PieceHighlightColour.RED:
                            lowerHighlightClass = styles.lowerDeletedHighlight;
                            break;
                    }

                    const isCurrentPieceExpanded = this.props.isPieceExpanded[this.props.pieceId];

                    const isExpanded = isCurrentPieceExpanded || flowchartContext.isValidating || !this.props.isCollapsible;

                    const nextPiece = <div className={this.state.isHoveringOverNextPiece && this.props.isDragging && this.props.targetPiece ? styles.nextPieceHovering : styles.nextPieceHoverable} onMouseOver={this.handleHoverOverNextPiece} onMouseOut={this.handleHoverOutOfNextPiece}></div>
                    const innerPiece = <div className={this.state.isHoveringOverInnerPiece && this.props.isDragging && this.props.targetPiece ? styles.innerPieceHovering : styles.innerPieceHoverable} onMouseOver={this.handleHoverOverInnerPiece} onMouseOut={this.handleHoverOutOfInnerPiece}></div>

                    return <FlowchartPiece {...this.props}>
                        <div className={themeClass + ' ' + (!isExpanded ? highlightClass : '') + ' children-clickable'}>
                            <section className={styles.upperArm + (isExpanded ? ' ' + highlightClass : '')}>
                                <div className={styles.upperArmUpperPieceLock}></div>
                                <div className={isExpanded ? styles.spaceBetweenLocks : styles.spaceBetweenCollapsedLocks}></div>
                                {isExpanded && <div className={styles.upperArmLowerPieceLock}></div>}

                                <div className={styles.extendableUpperArm}></div>

                                <div className={styles.upperArmContent}>
                                    {this.props.upperArmContent}
                                </div>
                                {isExpanded ? innerPiece : nextPiece}

                                {this.props.isCollapsible && <div className={styles.actionButtonWrapper}>
                                    <div data-expand-toggle="true" className={styles.expandButton} onClick={this.toggleExpansion}>
                                        <span><img src={!isCurrentPieceExpanded ? ExpandIcon : CollapseIcon} alt="icon" />{!isCurrentPieceExpanded ? 'Expand' : 'Collapse'}</span></div>
                                    <div data-expand-toggle="true" className={styles.expandButton} onClick={this.toggleExpansionAll}>
                                        <span><img src={!isCurrentPieceExpanded ? ExpandAllIcon : CollapseAllIcon} alt="icon" />{!isCurrentPieceExpanded ? 'Expand All' : 'Collapse All'}</span>
                                    </div>
                                </div>}

                            </section>
                            {isExpanded && <div className={styles.upperArmOverflowBoundary}>
                                <div className={styles.upperArmOverflow + (isExpanded ? ' ' + middleHighlightClass : '')}></div>
                            </div>}

                            {isExpanded && <div className={styles.innards}>
                                <section className={styles.leftArm}>
                                    <div className={styles.overflowBoundary}>
                                        <div className={styles.overflow + (isExpanded ? ' ' + highlightClass : '')}></div>
                                    </div>
                                </section>
                                <section className={styles.innerContent}>{this.props.children}</section>
                            </div>}
                            <section className={(isExpanded ? styles.lowerArm : styles.lowerCollapsedArm) + (isExpanded ? ' ' + lowerHighlightClass : '')}>
                                {isExpanded && <div className={styles.lowerArmUpperPieceLock}></div>}
                                {isExpanded && <div className={styles.spaceBetweenLocks}></div>}
                                <div className={styles.lowerArmLowerPieceLock}></div>

                                {isExpanded && <div className={styles.extendableLowerArm}></div>}

                                {isExpanded && <div className={styles.lowerArmContent}>
                                    {this.props.lowerArmContent}
                                </div>}

                                {nextPiece}
                            </section>
                        </div>
                        <div key={nextPieceId} className={styles.nextPiece}>{this.props.nextPiece || undefined}</div>
                    </FlowchartPiece>
                }}
        </FlowchartContext.Consumer>

    }
}

const ContainerPieceEssentials = connect(mapStateToProps, mapDispatchToProps)(ConnectedContainerPieceEssentials);

export default ContainerPieceEssentials;