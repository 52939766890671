import { SELECT_DATA_FRAGMENT, UN_SELECT_DATA_FRAGMENT, RE_ORDER_DATA_FRAGMENTS, ADD_DATA_FRAGMENT_REQUEST, ADD_DATA_FRAGMENT, DELETE_DATA_FRAGMENT, UPDATE_DATA_FRAGMENT, SelectDataFragmentAction, UnSelectDataFragmentAction, ReOrderDataFragmentsAction, AddDataFragmentRequestAction, AddDataFragmentAction, UpdateDataFragmentAction, DeleteDataFragmentAction } from './types';
import { INewDataFragmentData, IUpdateableDataFragmentData } from './types';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';

export function selectDataFragment(id: string, index: number): SelectDataFragmentAction {
    return {
        type: SELECT_DATA_FRAGMENT,
        id,
        index,
    }
}

export function unSelectDataFragment(index: number): UnSelectDataFragmentAction {
    return {
        type: UN_SELECT_DATA_FRAGMENT,
        index,
    }
}

export function reOrderDataFragments(sourceIndex: number, destinationIndex: number, parentId: string): ReOrderDataFragmentsAction {
    return {
        type: RE_ORDER_DATA_FRAGMENTS,
        sourceIndex,
        destinationIndex,
        parentId,
    }
}

export function addDataFragmentRequest(payload: INewDataFragmentData, parentId: string): AddDataFragmentRequestAction {
    return {
        type: ADD_DATA_FRAGMENT_REQUEST,
        payload,
        parentId,
    }
}

export function addDataFragment(payload: INewDataFragmentData, parentId: string): AddDataFragmentAction {
    const now = moment.utc().format();

    return {
        type: ADD_DATA_FRAGMENT,
        payload: {
            id: uuidv4(),
            ...payload,
            parent: parentId,
            children: [],
            createdTime: now,
            lastUpdatedTime: now,
        },
        parentId,
        currentTime: now,
    }
}

export function updateDataFragment(payload: IUpdateableDataFragmentData): UpdateDataFragmentAction {
    return {
        type: UPDATE_DATA_FRAGMENT,
        payload,
        currentTime: moment.utc().format(),
    }
}

export function deleteDataFragment(id: string, parentId: string): DeleteDataFragmentAction {
    return {
        type: DELETE_DATA_FRAGMENT,
        id,
        parentId,
        currentTime: moment.utc().format(),
    }
}