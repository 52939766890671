export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';
export const CLEAR_ORGANIZATION_DELTA = 'CLEAR_ORGANIZATION_DELTA';
export const WIPE_DATA = 'WIPE_DATA';
export const UPDATE_SUPER_USER_PASSWORD = 'UPDATE_SUPER_USER_PASSWORD';

export interface FinsalCredentials {
    clientId: string,
    clientKey: string,
    roles: string,
    loggedInUniqueIdentifierId: string,
    loggedInUserId: string,
    externalEntityNameId: string,
    externalEntityTypeId: string,
    policyName: string,

    authUsername: string,
    authToken: string,
}

export interface OrganizationState {
    name: string;
    code: string;
    plan?: 'free' | 'unlimited';
    category: string;
    categories: Array<string>,
    size: string,
    sizes: Array<string>,
    image: string,
    language: string,
    phoneCountryCode: string,
    phoneNumber: string,
    address: string,
    latitude: string,
    longitude: string,
    primary_color: string,
    primary_gradient: string,
    contrast_color: string,
    sessionTimeoutInMinutes: number,
    completedMemberThreshold?: number,
    completedGroupThreshold?: number,
    completedWorkflowThreshold?: number,
    completedMemberThresholdForSuperUser?: number,
    completedGroupThresholdForSuperUser?: number,
    completedWorkflowThresholdForSuperUser?: number,
    superUserPassword: string,
    email?: string,
    hasUpdated: boolean,
    hasThresholdChanged: boolean,
    isTestOrganization?: boolean,
    fileSizeLimitInMB?: number,

    uatFinsalCredentials?: FinsalCredentials,
    prodFinsalCredentials?: FinsalCredentials,

    uatFinsalNoLoanCredentials?: FinsalCredentials,
    prodFinsalNoLoanCredentials?: FinsalCredentials,
}

interface UpdateOrganizationAction {
    type: typeof UPDATE_ORGANIZATION,
    payload: OrganizationState,
};

export interface UpdateSuperUserPassword {
    type: typeof UPDATE_SUPER_USER_PASSWORD;
    password: string;
}

interface ClearOrganizationDeltaAction {
    type: typeof CLEAR_ORGANIZATION_DELTA,
};

interface WipeDataAction {
    type: typeof WIPE_DATA,
};

export type OrganizationActionTypes = UpdateOrganizationAction | UpdateSuperUserPassword | ClearOrganizationDeltaAction | WipeDataAction;