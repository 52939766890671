import { Permissions, DEFAULT_PERMISSIONS, PermissionSet } from '../store/permissions/types';
import { ApplicationState } from '../store/types';

function getHighestPermission(oldPermission: Permissions, newPermission: Permissions) {
    if (oldPermission === Permissions.WRITE || newPermission === Permissions.WRITE) {
        return Permissions.WRITE;
    } else if (oldPermission === Permissions.READ || newPermission === Permissions.READ) {
        return Permissions.READ;
    } else {
        return Permissions.NONE;
    }
}

export function getHighestPermissionSet(permissions: Array<PermissionSet>) {
    const firstPermissionSet = permissions[0];
    const otherPermissionSets = permissions.slice(1);

    const finalPermissionSet = firstPermissionSet;

    for (const otherPermissionSet of otherPermissionSets) {
        for (const permission in otherPermissionSet.general) {
            const oldPermission = finalPermissionSet.general[permission];
            const newPermission = otherPermissionSet.general[permission];
            finalPermissionSet.general[permission] = getHighestPermission(oldPermission, newPermission);
        }

        for (const permission in otherPermissionSet.members) {
            const oldPermission = finalPermissionSet.members[permission];
            const newPermission = otherPermissionSet.members[permission];
            finalPermissionSet.members[permission] = getHighestPermission(oldPermission, newPermission);
        }

        for (const permission in otherPermissionSet.groups) {
            const oldPermission = finalPermissionSet.groups[permission];
            const newPermission = otherPermissionSet.groups[permission];
            finalPermissionSet.groups[permission] = getHighestPermission(oldPermission, newPermission);
        }

        for (const permission in otherPermissionSet.workflows) {
            const oldPermission = finalPermissionSet.workflows[permission];
            const newPermission = otherPermissionSet.workflows[permission];
            finalPermissionSet.workflows[permission] = getHighestPermission(oldPermission, newPermission);
        }

        for (const permission in otherPermissionSet.reports) {
            const oldPermission = finalPermissionSet.reports[permission];
            const newPermission = otherPermissionSet.reports[permission];
            finalPermissionSet.reports[permission] = getHighestPermission(oldPermission, newPermission);
        }

        for (const permission in otherPermissionSet.languages) {
            const oldPermission = finalPermissionSet.languages[permission];
            const newPermission = otherPermissionSet.languages[permission];
            finalPermissionSet.languages[permission] = getHighestPermission(oldPermission, newPermission);
        }
    }

    return finalPermissionSet;
}

export function getPermissionsForUser(userId: string, state: ApplicationState) {
    const user = state.users.byId[userId];
    const defaultPermissions: PermissionSet = JSON.parse(JSON.stringify(DEFAULT_PERMISSIONS));
    let permissionsForRoles: Array<PermissionSet> = [];
    let permissionsForRole: PermissionSet;

    for (const roleId of user.roles) {
        if (roleId in state.permissions.rolePermissions) {
            permissionsForRole = {
                general: {
                    ...defaultPermissions.general,
                    ...state.permissions.rolePermissions[roleId].general,
                },
                members: {
                    ...defaultPermissions.members,
                    ...state.permissions.rolePermissions[roleId].members,
                },
                groups: {
                    ...defaultPermissions.groups,
                    ...state.permissions.rolePermissions[roleId].groups,
                },
                workflows: {
                    ...defaultPermissions.workflows,
                    ...state.permissions.rolePermissions[roleId].workflows,
                },
                reports: {
                    ...defaultPermissions.reports,
                    ...state.permissions.rolePermissions[roleId].reports,
                },
                staticInfo: {
                    ...defaultPermissions.staticInfo,
                    ...state.permissions.rolePermissions[roleId].staticInfo,
                },
                languages: {
                    ...defaultPermissions.languages,
                    ...state.permissions.rolePermissions[roleId].languages,
                },
                role: defaultPermissions.role
            }
        } else {
            permissionsForRole = JSON.parse(JSON.stringify(defaultPermissions));
        }

        permissionsForRoles.push(permissionsForRole);
    }

    const finalPermissionSet = getHighestPermissionSet(permissionsForRoles);
    return finalPermissionSet;
}