import React, { Component } from 'react';
import { CardType } from '../../../widgets/card/Card';
import CardsList from '../../../widgets/card/CardsList';
import ModifyForm from '../../../widgets/card/ModifyForm';
import uuid from 'uuid';
import { WorkflowTypeState } from '../../../shared/store/workflows/types/types';
import { ProjectState } from '../../../shared/store/structure/project/types';
import { MemberTypeState } from '../../../shared/store/members/types/types';
import { GroupTypeState } from '../../../shared/store/groups/types/types';
import { Option } from '../../flowchart/drop-down/ListItem';
import EnhancedInputText from '../../../widgets/form/InputText';

export type SpecialType = 'add-member' | 'add-group' | 'edit-member' | 'edit-group';

export interface OwnProps {
    selectedId?: string;
    heading: string;
    isReadOnly?: boolean;
    showCardCount?: boolean;
    isSearchable?: boolean;
    isImportHighlighted: boolean;

    onSelectCard: (id: string) => void;
    onUnSelectCard: () => void;
    onDuplicate: () => void;
    onExport: () => void;
    onImport: () => void;
    onGenerate?: () => void;
}

export interface WorkflowCardType extends CardType {
    affiliation: 'member' | 'group' | 'none',
    affiliatedEntity: string,
    isCore: boolean,
    areMultipleInstancesAllowed: boolean,
    subTitleFieldId?: string,
    project: string,
    seedEntityVariable: string,
    seedAffiliationVariable: string,
    startPiece: {
        piece: string,
        position: {
            x: number,
            y: number,
        },
    },
    betaStartPiece: {
        piece: string,
        position: {
            x: number,
            y: number,
        },
    },
};

export interface StateProps {
    read: boolean,
    write: boolean,
    restrictStructureChanges: boolean,

    searchInput: string | undefined,

    projectsData: ProjectState,
    memberTypesData: MemberTypeState,
    groupTypesData: GroupTypeState,
    workflowTypesData: WorkflowTypeState,
    cardsList: Array<WorkflowCardType>,
    selectedCard: WorkflowCardType | undefined,
}

export interface DispatchProps {
    reOrderCards: (sourceIndex: number, destinationIndex: number) => void,
    addCard: (payload: WorkflowCardType) => void,
    deleteCard: (id: string) => void,
    updateCard: (payload: WorkflowCardType) => void,

    setSearchInput: (searchString: string) => void,
}

type Props = OwnProps & StateProps & DispatchProps;

export interface OwnState {
    isShowingAddForm: boolean,
    isShowingModifyForm: boolean,
    modifyingCardName: string,
    modifyingCardAffiliation: 'member' | 'group' | 'none',
    modifyingCardAffiliatedEntity: string,
    modifyingCardSubtitleFieldId: string,
    modifyingCardIsCore: string,
    modifyingCardAreMultipleInstancesAllowed: string,
    modifyingCardProject: string,

    seedAffiliationVariable: string,
    lastSelectedCard: string | undefined,
};

class CardTreeLevel<TProps extends Props, TState extends OwnState> extends Component<TProps, TState> {

    static getDerivedStateFromProps(props: Readonly<Props>, state: Readonly<OwnState>) {
        if ((!props.selectedCard && typeof state.lastSelectedCard !== 'undefined') || (props.selectedCard && props.selectedCard.id !== state.lastSelectedCard)) {

            return {
                modifyingCardName: props.selectedCard ? props.selectedCard.name : '',
                modifyingCardProject: props.selectedCard ? props.selectedCard.project : '',
                modifyingCardIsCore: props.selectedCard ? props.selectedCard.isCore ? 'Yes' : 'No' : 'Yes',
                modifyingCardAreMareMultipleInstancesAllowed: props.selectedCard ? props.selectedCard.areMultipleInstancesAllowed ? 'Yes' : 'No' : 'Yes',
                modifyingCardAffiliation: props.selectedCard ? props.selectedCard.affiliation : 'none',
                modifyingCardAffiliatedEntity: props.selectedCard ? props.selectedCard.affiliatedEntity : '',
                modifyingCardSubtitleFieldId: props.selectedCard ? props.selectedCard.subTitleFieldId : undefined,

                seedAffiliationVariable: props.selectedCard ? props.selectedCard.seedAffiliationVariable : '',
                lastSelectedCard: props.selectedCard ? props.selectedCard.id : undefined,
            };
        }

        return null;
    }

    handleSelectCard = (id: string) => {
        this.props.onSelectCard(id);
    }

    toggleModifyForm = () => {
        const toggledState = !this.state.isShowingAddForm;

        if (this.state.isShowingModifyForm) {
            if (this.props.selectedCard) {
                this.setState({
                    isShowingModifyForm: false,
                    isShowingAddForm: false,
                    modifyingCardProject: this.props.selectedCard.project,
                    modifyingCardAffiliatedEntity: this.props.selectedCard.affiliatedEntity,
                    modifyingCardName: this.props.selectedCard.name,
                    modifyingCardAffiliation: this.props.selectedCard.affiliation,
                });
            }
        } else {
            this.setState({
                isShowingAddForm: toggledState,
                modifyingCardProject: '',
                modifyingCardAffiliatedEntity: '',
                modifyingCardName: '',
                modifyingCardAffiliation: 'none',
            });
        };
    }

    editSelectedCard = () => {

        if (!this.props.selectedCard) {
            throw new Error('Cannot edit card since nothing is selected');
        }

        this.setState({
            isShowingModifyForm: true,
            modifyingCardName: this.props.selectedCard.name,
            modifyingCardAffiliation: this.props.selectedCard.affiliation,
        });
    }

    updateCardName = (value: string) => {
        this.setState({
            modifyingCardName: value
        });
    }

    updateCardAffiliation = (value: string) => {
        let affiliationValue: 'member' | 'group' | 'none';
        switch (value) {
            case 'none': affiliationValue = value;
                break;
            case 'member': affiliationValue = value;
                break;
            case 'group': affiliationValue = value;
                break;
            default:
                throw new Error('The affiliation cannot be of any other type');
        }

        this.setState({
            modifyingCardAffiliation: affiliationValue,
        });
    }

    updateCardAffiliatedEntity = (value: string) => {
        this.setState({
            modifyingCardAffiliatedEntity: value,
        });
    }

    updateCardSubtitleFieldId = (value: string) => {
        this.setState({
            modifyingCardSubtitleFieldId: value,
        });
    }

    updateCardProject = (value: string) => {

        this.setState({
            modifyingCardProject: value,
        });
    }

    updateCardIsCore = (value: string) => {

        this.setState({
            modifyingCardIsCore: value,
        });
    }

    updateCardAreMultipleInstancesAllowed = (value: string) => {

        this.setState({
            modifyingCardAreMultipleInstancesAllowed: value,
        });
    }

    addCard = () => {

        if (typeof this.validateCard() === 'string') {
            return;
        }

        const newId = uuid.v4();

        this.props.addCard({
            id: newId,
            name: this.state.modifyingCardName,
            affiliation: this.state.modifyingCardAffiliation,
            affiliatedEntity: this.state.modifyingCardAffiliatedEntity,
            isCore: this.state.modifyingCardIsCore === 'Yes',
            areMultipleInstancesAllowed: this.state.modifyingCardAreMultipleInstancesAllowed === 'Yes',
            project: this.state.modifyingCardProject,
            seedEntityVariable: uuid.v4(),
            seedAffiliationVariable: uuid.v4(),
            startPiece: {
                piece: uuid.v4(),
                position: {
                    x: 0,
                    y: 0,
                },
            },
            betaStartPiece: {
                piece: uuid.v4(),
                position: {
                    x: 0,
                    y: 0,
                },
            },
        });

        this.setState({
            modifyingCardName: '',
            modifyingCardAffiliation: 'none',
            isShowingAddForm: false
        });
    }

    updateCard = () => {

        if (typeof this.validateCard() === 'string') {
            return;
        }

        if (!this.props.selectedCard) {
            return;
        }
        this.props.updateCard({
            id: this.props.selectedCard.id,
            name: this.state.modifyingCardName,
            affiliation: this.state.modifyingCardAffiliation,
            affiliatedEntity: this.state.modifyingCardAffiliatedEntity,
            subTitleFieldId: this.state.modifyingCardSubtitleFieldId ? this.state.modifyingCardSubtitleFieldId : undefined,
            isCore: this.state.modifyingCardIsCore === 'Yes',
            areMultipleInstancesAllowed: this.state.modifyingCardAreMultipleInstancesAllowed === 'Yes',
            project: this.state.modifyingCardProject,
            seedEntityVariable: this.props.selectedCard.seedEntityVariable,
            seedAffiliationVariable: this.props.selectedCard.seedAffiliationVariable,
            startPiece: this.props.selectedCard.startPiece,
            betaStartPiece: this.props.selectedCard.betaStartPiece,
        });

        this.setState({
            isShowingModifyForm: false,
            modifyingCardName: '',
            modifyingCardAffiliation: 'none',
            isShowingAddForm: false
        });
    }

    validateCard = () => {
        if (!this.state.modifyingCardName) {
            return 'Enter a valid name';
        }

        if (!this.state.modifyingCardProject) {
            return 'Enter a valid project';
        }

        return true;
    }

    render() {
        const affiliationOptions = [{
            name: 'None',
            value: 'none',
        }, {
            name: 'Member',
            value: 'member',
        }, {
            name: 'Group',
            value: 'group',
        }];

        const projectsList = this.props.projectsData.allEntries.map(projectId => {
            return {
                name: this.props.projectsData.byId[projectId].name,
                value: projectId,
            }
        });

        let customFieldsList: Array<Option> = [];

        if (this.props.selectedCard) {
            const workflowType = this.props.workflowTypesData.byId[this.props.selectedCard.id];

            customFieldsList = workflowType.customFields
                .filter(customFieldId => {
                    const customField = this.props.workflowTypesData.customFields.byId[customFieldId];

                    if (workflowType.affiliation === 'group' && customField.affiliation === 'member') {
                        // Do not allow custom fields of member types in workflows of group type
                        return false;
                    }

                    return true;
                })
                .map(customFieldId => {
                    const customField = this.props.workflowTypesData.customFields.byId[customFieldId];

                    return {
                        name: customField.name,
                        value: customFieldId,
                    };
                });

            customFieldsList.unshift({
                name: 'Default',
                value: '',
            });
        }

        let affiliatedEntityTypeOptions: Array<Option> = [];

        if (this.state.modifyingCardAffiliation === 'member') {

            affiliatedEntityTypeOptions = [{
                name: 'All Member Types',
                value: '',
            }];

            affiliatedEntityTypeOptions = affiliatedEntityTypeOptions.concat(
                this.props.memberTypesData.allEntries
                    .filter(memberTypeId => this.props.memberTypesData.byId[memberTypeId].project === this.state.modifyingCardProject)
                    .map(memberTypeId => {
                        const memberType = this.props.memberTypesData.byId[memberTypeId];
                        return {
                            name: memberType.name,
                            value: memberTypeId,
                        };
                    })
            );
        } else if (this.state.modifyingCardAffiliation === 'group') {

            affiliatedEntityTypeOptions = [{
                name: 'All Group Types',
                value: '',
            }];

            affiliatedEntityTypeOptions = affiliatedEntityTypeOptions.concat(
                this.props.groupTypesData.allEntries
                    .filter(groupTypeId => this.props.groupTypesData.byId[groupTypeId].project === this.state.modifyingCardProject)
                    .map(groupTypeId => {
                        const groupType = this.props.groupTypesData.byId[groupTypeId];
                        return {
                            name: groupType.name,
                            value: groupTypeId,
                        };
                    })
            );
        }

        const defaultAffiliatedEntity = affiliatedEntityTypeOptions.find(option => option.value === this.state.modifyingCardAffiliatedEntity);
        let defaultAffiliatedEntityName = defaultAffiliatedEntity ? defaultAffiliatedEntity.name : '';

        const modifyForm = <ModifyForm key={this.state.lastSelectedCard} isNew={!this.state.isShowingModifyForm} submitForm={this.state.isShowingModifyForm ? this.updateCard : this.addCard} cancelForm={this.toggleModifyForm} validateForm={this.validateCard}>
            <EnhancedInputText placeholder="Name" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardName} default={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.name : ''} key={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.id : 0} />
            <EnhancedInputText placeholder="Project" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardProject} options={projectsList} default={this.state.modifyingCardProject in this.props.projectsData.byId ? this.props.projectsData.byId[this.state.modifyingCardProject].name : ''} isDisabled={this.state.isShowingModifyForm} />
            {this.state.modifyingCardProject && <EnhancedInputText placeholder="Affiliation" onEnterPress={this.addCard} onChange={this.updateCardAffiliation} options={affiliationOptions} default={this.state.modifyingCardAffiliation ? this.state.modifyingCardAffiliation[0].toUpperCase() + this.state.modifyingCardAffiliation.substring(1) : ''} isDisabled={this.state.isShowingModifyForm} />}
            {this.state.modifyingCardAffiliation !== 'none' && <EnhancedInputText placeholder="Affiliated entity" onEnterPress={this.addCard} onChange={this.updateCardAffiliatedEntity} options={affiliatedEntityTypeOptions} default={defaultAffiliatedEntityName} />}
            <EnhancedInputText isBooleanField placeholder="Is Core?" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardIsCore} defaultBooleanValue={this.props.selectedCard && !this.props.selectedCard.isCore ? false : true} />
            <EnhancedInputText isBooleanField placeholder="Allow Multiple Instances?" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardAreMultipleInstancesAllowed} defaultBooleanValue={this.props.selectedCard && this.props.selectedCard.areMultipleInstancesAllowed ? true : false} />
            {this.props.selectedCard && <EnhancedInputText placeholder="Subtitle" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardSubtitleFieldId} default={this.props.selectedCard && this.props.selectedCard.subTitleFieldId ? this.props.selectedCard.subTitleFieldId : undefined} options={customFieldsList} />}
        </ModifyForm>;

        return <CardsList
            heading={this.props.heading}
            cards={this.props.cardsList}
            selectedCard={this.props.selectedCard}
            onSelectCard={this.handleSelectCard}
            onUnselectCard={this.props.onUnSelectCard}
            onDeleteCard={this.props.deleteCard}
            onEditCard={this.editSelectedCard}
            onReorderCards={this.props.reOrderCards}
            modifyForm={modifyForm}
            isShowingAddForm={this.state.isShowingAddForm}
            isShowingEditForm={this.state.isShowingModifyForm}
            onAddCard={() => { this.props.onUnSelectCard(); this.toggleModifyForm(); }}
            isDeleteRestricted={this.props.restrictStructureChanges}
            isReadOnly={this.props.isReadOnly || !this.props.write}
            showCardCount={!!this.props.showCardCount}
            isSearchable={!!this.props.isSearchable}
            searchInput={this.props.searchInput}
            setSearchInput={this.props.setSearchInput}
            onDuplicate={this.props.onDuplicate}
            onExport={this.props.onExport}
            onImport={this.props.onImport}
            onGenerateFlowchart={this.props.onGenerate}
            isHighlightedImport={this.props.isImportHighlighted}
        />
    }
}

export default CardTreeLevel;