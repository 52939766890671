import { createSelector } from 'reselect';
import { getVisibleGroupIdsSelector } from '../../../helpers/selectors';
import { filterGroups } from '../../../shared/helpers/filters';
import store from '../../../shared/store/main';
import { ApplicationState } from '../../../shared/store/types';
import { IGroup } from '../../../shared/store/groups/types';
import moment from 'moment';

const getGroupById = (state: ApplicationState) => state.groups.byId;
const getSearchTerm = (state: ApplicationState) => state.groups.searchTerm;
const getCurrentPageNumber = (state: ApplicationState) => state.groups.currentPageNumber;
const getPageSize = (state: ApplicationState) => state.groups.pageSize;
const getFilters = (state: ApplicationState) => state.groups.filters;
const getIsOnline = (state: ApplicationState) => state.myData.isOnline;
const getTotalNoOfGroups = (state: ApplicationState) => state.groups.totalNoOfGroups;
const getProjectsById = (state: ApplicationState) => state.structure.projects.byId;
const getGroupTypes = (state: ApplicationState) => state.groups.types;
const getLocations = (state: ApplicationState) => state.structure.locations;

export const getSortedGroups = createSelector(
    [getGroupById, getFilters, getVisibleGroupIdsSelector],
    (groupById, filters, visibleGroupIds) => {
        const visibleGroups = visibleGroupIds.map(groupId => groupById[groupId]);
        const sortedGroups = visibleGroups.sort((a, b) => {
            const aDate = new Date(a.lastUpdatedTime);
            const bDate = new Date(b.lastUpdatedTime);

            return bDate.getTime() - aDate.getTime();
        });
        return sortedGroups;
    }
)

export const getFilteredGroups = createSelector(
    [getSortedGroups, getGroupById, getCurrentPageNumber, getPageSize, getSearchTerm, getIsOnline, getFilters],
    (sortedGroups, groupById, currentPageNumber, pageSize, searchTerm, isOnline, filters) => {
        const state = store.getState();

        let filteredGroups: Array<IGroup> = [];

        if (isOnline) {
            if (filters.archived) {
                filteredGroups = Object.keys(groupById).map(groupId => groupById[groupId]).filter(group => group.archived);
            } else {
                filteredGroups = state.groups.allEntries.map(groupId => groupById[groupId])
            }
        } else {
            filteredGroups = filterGroups(state, sortedGroups.map(group => group.id));
        }

        return filteredGroups;

    }
)

export const getTotalNoOfMembers = createSelector(
    [getIsOnline, getFilteredGroups, getTotalNoOfGroups],
    (isOnline, filteredGroups, totalMembers) => {
        return !isOnline ? filteredGroups.length : totalMembers;
    }
)

export const getFilterTags = createSelector(
    [getFilters, getTotalNoOfMembers, getFilteredGroups, getProjectsById, getIsOnline, getGroupTypes, getLocations],
    (filters, totalNoOfGroups, filteredGroups, projectsById, isOnline, groupTypes, locations) => {
        const tags: Array<string> = [];

        if (filters.projects.length > 0) {
            tags.push('Projects: ' + filters.projects.map(projectId => projectsById[projectId].name).join(', '));
        }
    
        if (filters.types.length > 0) {
            tags.push('Types: ' + filters.types.map(typeId => groupTypes.byId[typeId].name).join(', '));
        }
    
        if (filters.locations.length > 0) {
            tags.push('Locations: ' + filters.locations.map(locationId => locations.byId[locationId].name).join(', ') + ' and below');
        }
    
        if (filters.createdDateRange.length > 1) {
            tags.push(`Created date range: ${moment(filters.createdDateRange[0]).format('DD MMM YYYY')} - ${moment(filters.createdDateRange[1]).format('DD MMM YYYY')}`);
        }
    
        if (filters.lastUpdatedDateRange.length > 1) {
            tags.push(`Last updated date range: ${moment(filters.lastUpdatedDateRange[0]).format('DD MMM YYYY')} - ${moment(filters.lastUpdatedDateRange[1]).format('DD MMM YYYY')}`);
        }
    
        if (filters.unsynced) {
            tags.push('Unsynced');
        }
    
        if (filters.archived) {
            tags.push('Archived');
        }
    
        if (filters.types.length === 1) {
    
            for (const customFieldId in filters.customFields) {
                const customField = groupTypes.customFields.byId[customFieldId];
                const selectedOptions = filters.customFields[customFieldId].map(optionId => groupTypes.customFieldOptions.byId[optionId]);
    
                tags.push(`${customField.name}: ${selectedOptions.map(option => option.name).join(', ')}`);
            }
        }
    
        if (tags.length > 0) {
            tags.push('Count: ' + totalNoOfGroups);
        }

        return tags;
    }
)