import { takeEvery, put, select } from 'redux-saga/effects'
import { AddLanguageAction, ADD_LANGUAGE } from './languages/types';
import { setDefaultLanguagePermission } from '../permissions/actions';

function* addDefaultPermissions(action: AddLanguageAction) {
    yield put(setDefaultLanguagePermission(action.payload.id));
}

export function* watchLanguageCreationChanges() {
    yield takeEvery(ADD_LANGUAGE, addDefaultPermissions);
}