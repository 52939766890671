import { NudgeData } from "./index";
import { ApplicationState } from "../../../shared/store/types";
import { NudgeType } from "../../../shared/store/my-data/types";

export function getOrganizationNudges(applicationState: ApplicationState): Array<NudgeData> {
    let nudgesData: Array<NudgeData> = [];

    if (applicationState.myData.isEditingOrgProfile) {
        nudgesData.push({
            id: NudgeType.ORGANIZATION_EDIT_DETAILS,
            header: 'Edit organization profile',
            content: `Upload button - Upload your Organisation's logo
                Name - Name of your Organisation
                Category - Category or Sector of your Organisation
                Code - Unique code used to identify your Organisation by our system - Required for additional support
                Language - English is the base language of your application - You can choose from other configured languages for the Superuser
                Address - Email Address of your organisation (Need to fix the Label + Editability - Should this be allowed to be changed?)
                Phone - Country Code and Phone Number of the Superuser
                Location - Lat/Long of the Organisation
                Team Size - Number of employees in the Organisation
                Session Timeout Duration - Number of minutes of inactivity after which user will be logged out
                Threshold Days - At the Organisation level - If set, application will bring down Member/Group/Workflow data for this many days from today - Please note that Role level thresholds will override this setting
                Threshold Days for Super User - If set, application will bring down Member/Group/Workflow data for this many days from today for the Superuser
                Pre-defined Themes - Select a theme for your user at the Organisation level
                Primary Color - Or set primary color of your choice and ...
                Secondary Color -  set secondary color of your choice`,
        });
    }

    return nudgesData;
}