import axios, { AxiosRequestConfig } from 'axios';
import { BASE_URL } from '../store/url';

const httpClient = axios.create();
const FIVE_MINUTES = 5 * 60 * 1000;
httpClient.defaults.timeout = FIVE_MINUTES;

function abortSignal(timeoutMs: number) {
    const abortController = new AbortController();
    const abortIfOffline = () => {
        if (!navigator.onLine) {
            abortController.abort();
            console.warn('Request aborted due to network disconnection.');
        }
    };

    const pollInterval = setInterval(() => {
        abortIfOffline();
    }, 5000);

    setTimeout(() => {
        clearInterval(pollInterval);
        abortController.abort();
    }, timeoutMs || 0);

    return abortController.signal;
}

function getAuthorizationHeader() {
    const token = localStorage.getItem('token');
    return token ? `Bearer ${token}` : '';
}

export function httpRequestClient<T>(
    endpoint: string,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET',
    data: any = null,
    timeoutMs: number = FIVE_MINUTES
) {
    const config: AxiosRequestConfig = {
        url: `${BASE_URL}${endpoint}`,
        method,
        data, // This is where data for POST/PUT requests is passed
        signal: abortSignal(timeoutMs),
        timeout: timeoutMs,
        headers: {
            Authorization: getAuthorizationHeader(),
            'ngsw-bypass': true,
        },
    };

    return httpClient(config).then((response) => response.data);
}