import { StructureActionTypes } from '../types'
import { ADD_LEVEL, DELETE_LEVEL, UPDATE_LEVEL, SELECT_LEVEL, UN_SELECT_LEVEL, LevelState, SELECT_LEVEL_CUSTOM_FIELD, UN_SELECT_LEVEL_CUSTOM_FIELD, ADD_LEVEL_CUSTOM_FIELD, DELETE_LEVEL_CUSTOM_FIELD, UPDATE_LEVEL_CUSTOM_FIELD, SELECT_LEVEL_CUSTOM_FIELD_OPTION, UN_SELECT_LEVEL_CUSTOM_FIELD_OPTION, RE_ORDER_LEVEL_CUSTOM_FIELD_OPTION, ADD_LEVEL_CUSTOM_FIELD_OPTION, DELETE_LEVEL_CUSTOM_FIELD_OPTION, UPDATE_LEVEL_CUSTOM_FIELD_OPTION, UPDATE_LEVEL_CUSTOM_FIELD_START_PIECE, SET_ISOLATED_LEVEL_CUSTOM_FIELD_PIECE, REMOVE_ISOLATED_LEVEL_CUSTOM_FIELD_PIECE, REGISTER_LEVEL_CUSTOM_FIELD_VARIABLE, ADD_ROLE_TO_LEVEL, REMOVE_ROLE_FROM_LEVEL, ILevel, UPDATE_LEVELS_DATA, UPDATE_LEVEL_CUSTOM_FIELDS_DATA, UPDATE_LEVEL_CUSTOM_FIELD_OPTIONS_DATA, SYNCHRONIZE_LEVELS_DATA, CLEAR_LEVELS_DELTA, SYNCHRONIZE_LEVEL_CUSTOM_FIELDS_DATA, SYNCHRONIZE_LEVEL_CUSTOM_FIELD_OPTIONS_DATA, INSTANTIATE_LEVEL, INSTANTIATE_LEVEL_CUSTOM_FIELD, INSTANTIATE_LEVEL_CUSTOM_FIELD_OPTION } from './types';
import { IRole, RE_ORDER_ROLES, SYNCHRONIZE_ROLES_DATA } from '../role/types';
import { UPDATE_STRUCTURE_DATA } from '../types';
import { reOrderList } from '../../../helpers/utilities';
import { UN_SELECT_PROJECT, SELECT_PROJECT } from '../project/types';
import { selectCustomField, unSelectCustomField, addCustomField, deleteCustomField, updateCustomField, selectCustomFieldOption, unSelectCustomFieldOption, reOrderCustomFieldOptions, addCustomFieldOption, deleteCustomFieldOption, updateCustomFieldOption, setIsolatedPieceForCustomField, removeIsolatedPieceForCustomField, updateStartPieceForCustomField, registerVariableForCustomField, updateCustomFields, updateCustomFieldOptions, clearDeltaForCustomFields, synchronizeCustomFields, synchronizeCustomFieldOptions } from '../../custom-fields';
import { addEntity, updateEntity, deleteEntity, updateEntries, synchronizeEntries, clearDelta, synchronizeReverseLinks, synchronizeCustomFieldReverseLinks, synchronizeCustomFieldOptionReverseLinks } from '../../normalized-model';

const initialState: LevelState = {
    byId: {},
    allEntries: [],
    filteredEntries: [],
    selected: undefined,
    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),

    customFields: {
        byId: {},
        allFields: [],
    },
    customFieldOptions: {
        byId: {},
        allOptions: [],
    },
    selectedField: undefined,
    selectedOption: undefined,
    createdCustomFieldIds: new Set(),
    updatedCustomFieldIds: new Set(),
    deletedCustomFieldIds: new Set(),
    createdCustomFieldOptionIds: new Set(),
    updatedCustomFieldOptionIds: new Set(),
    deletedCustomFieldOptionIds: new Set(),

    reOrderedRoles: {},
    reOrderedCustomFields: {},
    reOrderedCustomFieldOptions: {},
}

export function levelsReducer(state = initialState, action: StructureActionTypes): LevelState {
    let newState: LevelState;

    switch (action.type) {

        // PROJECT ACTIONS

        case SELECT_PROJECT:
        case UN_SELECT_PROJECT:
            return {
                ...state,
                selected: undefined,
            }

        // LEVEL ACTIONS

        case SELECT_LEVEL:
            return {
                ...state,
                selected: action.id,
            }

        case UN_SELECT_LEVEL:
            return {
                ...state,
                selected: undefined,
            }

        case ADD_LEVEL:
            return addEntity<LevelState, ILevel>(state, action.payload);

        case INSTANTIATE_LEVEL:
            return addEntity<LevelState, ILevel>(state, action.payload);

        case UPDATE_LEVEL:
            return updateEntity<LevelState, ILevel>(state, action.payload, action.currentTime);

        case DELETE_LEVEL:
            state = deleteEntity<LevelState, ILevel>(state, action.id, action.currentTime);
            return state;

        case ADD_ROLE_TO_LEVEL:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.levelId]: {
                        ...state.byId[action.levelId],
                        children: state.byId[action.levelId].children.concat([action.roleId]),
                    }
                },
                reOrderedRoles: {
                    ...state.reOrderedRoles,
                    [action.levelId]: state.byId[action.levelId].children.concat([action.roleId]),
                },
            }

        case REMOVE_ROLE_FROM_LEVEL:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.levelId]: {
                        ...state.byId[action.levelId],
                        children: state.byId[action.levelId].children.filter(roleId => roleId !== action.roleId),
                    }
                },
                reOrderedRoles: {
                    ...state.reOrderedRoles,
                    [action.levelId]: state.byId[action.levelId].children.filter(roleId => roleId !== action.roleId),
                },
            }

        case SELECT_LEVEL_CUSTOM_FIELD:
            return selectCustomField<LevelState>(state, action.id);

        case UN_SELECT_LEVEL_CUSTOM_FIELD:
            return unSelectCustomField<LevelState>(state);


        case ADD_LEVEL_CUSTOM_FIELD:
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                    [action.levelId]: {
                        ...state.byId[action.levelId],
                        customFields: state.byId[action.levelId].customFields.concat([action.payload.id]),
                    }
                },
                reOrderedCustomFields: {
                    ...state.reOrderedCustomFields,
                    [action.levelId]: state.byId[action.levelId].customFields.concat([action.payload.id]),
                },
            }
            return addCustomField<LevelState>(newState, action.payload, action.levelId, action.currentTime);


        case INSTANTIATE_LEVEL_CUSTOM_FIELD:
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                    [action.levelId]: {
                        ...state.byId[action.levelId],
                        customFields: state.byId[action.levelId].customFields.concat([action.payload.id]),
                    }
                }
            }
            return addCustomField<LevelState>(newState, action.payload, action.levelId, action.currentTime);

        case DELETE_LEVEL_CUSTOM_FIELD:
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                    [action.levelId]: {
                        ...state.byId[action.levelId],
                        customFields: state.byId[action.levelId].customFields.filter(customFieldId => customFieldId !== action.id),
                    }
                },
                reOrderedCustomFields: {
                    ...state.reOrderedCustomFields,
                    [action.levelId]: state.byId[action.levelId].customFields.filter(customFieldId => customFieldId !== action.id),
                },
            }
            return deleteCustomField<LevelState>(newState, action.id, action.currentTime);

        case UPDATE_LEVEL_CUSTOM_FIELD:
            return updateCustomField<LevelState>(state, action.payload, action.currentTime);


        case UPDATE_LEVEL_CUSTOM_FIELD_START_PIECE:
            return updateStartPieceForCustomField<LevelState>(state, action.customFieldId, action.payload, action.currentTime);

        case SET_ISOLATED_LEVEL_CUSTOM_FIELD_PIECE:
            return setIsolatedPieceForCustomField<LevelState>(state, action.customFieldId, action.payload, action.currentTime);

        case REMOVE_ISOLATED_LEVEL_CUSTOM_FIELD_PIECE:
            return removeIsolatedPieceForCustomField<LevelState>(state, action.customFieldId, action.pieceId, action.currentTime);

        case REGISTER_LEVEL_CUSTOM_FIELD_VARIABLE:
            return registerVariableForCustomField<LevelState>(state, action.customFieldId, action.variableId, action.currentTime);


        case SELECT_LEVEL_CUSTOM_FIELD_OPTION:
            return selectCustomFieldOption<LevelState>(state, action.id);

        case UN_SELECT_LEVEL_CUSTOM_FIELD_OPTION:
            return unSelectCustomFieldOption<LevelState>(state);

        case RE_ORDER_LEVEL_CUSTOM_FIELD_OPTION:
            return reOrderCustomFieldOptions<LevelState>(state, action.sourceIndex, action.destinationIndex, action.parentId);

        case ADD_LEVEL_CUSTOM_FIELD_OPTION:
            return addCustomFieldOption<LevelState>(state, action.payload, action.parentId, action.currentTime);

        case INSTANTIATE_LEVEL_CUSTOM_FIELD_OPTION:
            return addCustomFieldOption<LevelState>(state, action.payload, action.parentId, action.currentTime);

        case DELETE_LEVEL_CUSTOM_FIELD_OPTION:
            return deleteCustomFieldOption<LevelState>(state, action.id, action.parentId, action.currentTime);

        case UPDATE_LEVEL_CUSTOM_FIELD_OPTION:
            return updateCustomFieldOption<LevelState>(state, action.payload, action.currentTime);

        case UPDATE_LEVELS_DATA:
            return updateEntries<LevelState, ILevel>(state, action.data);

        case UPDATE_LEVEL_CUSTOM_FIELDS_DATA:
            return updateCustomFields<LevelState>(state, action.data);

        case UPDATE_LEVEL_CUSTOM_FIELD_OPTIONS_DATA:
            return updateCustomFieldOptions<LevelState>(state, action.data);

        case SYNCHRONIZE_LEVELS_DATA:
            return synchronizeEntries<LevelState, ILevel>(state, action.data);

        case SYNCHRONIZE_ROLES_DATA:
            return synchronizeReverseLinks<LevelState, ILevel, IRole>(state, action.data, 'level', 'children');

        case SYNCHRONIZE_LEVEL_CUSTOM_FIELDS_DATA:
            newState = synchronizeCustomFields<LevelState>(state, action.data);
            newState = synchronizeCustomFieldReverseLinks<LevelState>(newState, action.data);
            return newState;

        case SYNCHRONIZE_LEVEL_CUSTOM_FIELD_OPTIONS_DATA:
            newState = synchronizeCustomFieldOptions<LevelState>(state, action.data);
            newState = synchronizeCustomFieldOptionReverseLinks<LevelState>(newState, action.reOrderedCustomFieldOptions);
            return newState;

        case CLEAR_LEVELS_DELTA:
            newState = clearDelta<LevelState, ILevel>(state);
            newState.reOrderedRoles = {};
            newState.reOrderedCustomFields = {};
            newState.reOrderedCustomFieldOptions = {};

            newState = clearDeltaForCustomFields<LevelState>(newState);
            return newState;


        // ROLE ACTIONS

        case RE_ORDER_ROLES:
            const reOrderedList = reOrderList(state.byId[action.parentId].children, action.sourceIndex, action.destinationIndex);

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.parentId]: {
                        ...state.byId[action.parentId],
                        children: reOrderedList,
                    }
                },
                reOrderedRoles: {
                    ...state.reOrderedRoles,
                    [action.parentId]: reOrderedList,
                }
            }

        // Structure actions

        case UPDATE_STRUCTURE_DATA:
            return {
                ...action.data.levels,
                createdIds: state.createdIds,
                updatedIds: state.updatedIds,
                deletedIds: state.deletedIds,
                createdCustomFieldIds: state.createdCustomFieldIds,
                updatedCustomFieldIds: state.updatedCustomFieldIds,
                deletedCustomFieldIds: state.deletedCustomFieldIds,
                createdCustomFieldOptionIds: state.createdCustomFieldOptionIds,
                updatedCustomFieldOptionIds: state.updatedCustomFieldOptionIds,
                deletedCustomFieldOptionIds: state.deletedCustomFieldOptionIds,

            }

        default:
            return state
    }
}