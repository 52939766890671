import { NudgeData } from "./index";
import { ApplicationState } from "../../../shared/store/types";
import { NudgeType } from "../../../shared/store/my-data/types";
import { Permissions } from '../../../shared/store/permissions/types';

export function getMembersListNudges(applicationState: ApplicationState): Array<NudgeData> {

    let nudgesData: Array<NudgeData> = [];

    if (applicationState.members.isShowingAddForm || applicationState.members.isShowingModifyForm) {
        nudgesData.push({
            id: NudgeType.MEMBERS_MODIFY_MEMBER_DETAILS,
            header: applicationState.members.isShowingAddForm ? 'Add member details' : 'Update member details',
            content: `Enter content for members add/edit details here`
        });

        return nudgesData;
    }

    if (applicationState.members.isShowingCSVForm) {
        nudgesData.push({
            id: NudgeType.MEMBERS_IMPORT_EXPORT_MEMBER_DETAILS,
            header: 'Import/Export details',
            content: `Select a type. Click on "Export Table" and then "Export data" to export a CSV with member details.
            To import data, click on "Download template" to get the CSV template used for import. Fill up these details to import.`,
        });

        return nudgesData;
    }

    let hasMemberTypesToAdd = applicationState.myData.id === 'SuperUser';

    if (!hasMemberTypesToAdd) {
        const allowedMemberTypes = applicationState.members.types.allEntries
        .filter(typeId => {
            const memberType = applicationState.members.types.byId[typeId];
            const loggedInUser = applicationState.myData.id ? applicationState.users.byId[applicationState.myData.id] : undefined;
    
            if (loggedInUser) {
                return loggedInUser.projects.includes(memberType.project);
            } else {
                return true;
            }
        });

        for (const memberTypeId of allowedMemberTypes) {
    
            if (applicationState.permissions.myPermissions.members[memberTypeId] === Permissions.WRITE || typeof applicationState.permissions.myPermissions.members[memberTypeId] === 'undefined') {
                hasMemberTypesToAdd = true;
            }
        }
    }

    if (hasMemberTypesToAdd) {
        nudgesData = [{
            id: NudgeType.MEMBERS_ADD_MEMBER,
            header: 'Add member',
            content: 'Click on the add button on the top right to add a new member',
        }];
    }
    
    nudgesData = nudgesData.concat([{
        id: NudgeType.MEMBERS_FILTER,
        header: 'Filter members',
        content: 'Click on the filter button to filter the existing members list',
    }, {
        id: NudgeType.MEMBERS_SEARCH,
        header: 'Search members',
        content: 'Type into the search bar to search the existing members list',
    }, {
        id: NudgeType.MEMBERS_IMPORT_EXPORT,
        header: 'Import/Export',
        content: 'Click this button to import members into the app, or export members in the form of a CSV.',
    }, {
        id: NudgeType.MEMBERS_LIST_FIRE_WORKFLOW,
        header: 'Fire workflow for member',
        content: 'Fire a workflow for this member',
    }, {
        id: NudgeType.MEMBERS_LIST_SHOW_MORE,
        header: 'Show more member options',
        content: 'Click on this button to show more options for the member',
    }]);

    return nudgesData;
}