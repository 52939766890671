import React, { ReactNode } from 'react';
import styles from './Header.module.scss';
import { default as tabStyles } from '../../widgets/tabs/Tabs.module.scss';
import { NavLink } from "react-router-dom";
import Tabs from '../tabs/Tabs';
import { translatePhrase } from '../../shared/helpers/translation';
import LoaderModal from '../loader/LoaderModal';

interface TabsType {
    tabName: string;
    link: string;
    hasAccess: boolean;
    dataSelector?: string;
    isActive?: (match: any, location: any) => boolean;
}

type OwnProps = {
    currentCount?: number,
    totalCount?: number | undefined,
    pageName: string,
    icon: ReactNode,
    tabs?: TabsType[];
};


type Props =  OwnProps;

const Header: React.FC<Props>  = (props) => {
    const { currentCount, totalCount, pageName, icon, tabs } = props;

    return (
      <header className={styles.pageHeader}>
        <h2 className={styles.heading} id="page-header">
          {icon} {translatePhrase(`${pageName}`)}
        </h2>

        {totalCount &&
          currentCount &&
          typeof totalCount !== "undefined" &&
          currentCount !== totalCount && (
            <LoaderModal
              loaderText={[translatePhrase("Fetching your data") + "..."]}
              progress={Number(
                ((currentCount / (totalCount || 0)) * 100).toFixed(2)
              )}
              isNotAllowedToCloseManually
            />
          )}

        <Tabs>
          {tabs && tabs.map((tab) => {
            return tab.hasAccess ? (
              <NavLink
                key={tab.tabName}
                data-selector={tab.dataSelector}
                to={tab.link}
                className={tabStyles.Tab}
                activeClassName={tabStyles.active}
                isActive={tab.isActive}
              >
                {translatePhrase(`${tab.tabName}`)}
              </NavLink>
            ) : undefined;
          })}
        </Tabs>
      </header>
    );
}

export default Header;