import { Component, ChangeEvent } from 'react';
import styles from './Flowchart.module.scss';
import { Link, Redirect, RouteComponentProps, withRouter } from "react-router-dom";

import { Permissions } from '../../../shared/store/permissions/types';
import { ReactComponent as ChevronDownIcon } from '../../../assets/chevron-arrow-down.svg';
import { ReactComponent as ExternalIcon } from '../../../common/assets/help.svg';

import { setNextPiece, setInnerPiece, setConditionForIfPiece, setConditionPiece, setConditionNextPiece, setLoopVariable, setIterableVariable, setOperand, setLeftOperand, setRightOperand, setQuestionData, setMemberVariable, setDataStoreValue, setDataSetValue, setDataCopyVariable, setReturnValue, addPiece, addFullPiece, deletePiece, setVariableForShow, setVariableForCustomField, setQuestionRequiredPiece, setQuestionDisabledPiece, setQuestionDefaultPiece, setQuestionImage, setLocationPiece, setPieceForList, setVariablePiece, setHeading, setWorkflowAffiliationVariable, setDate, setMonth, setYear, setMessage, setUpdateDueDateValue, copyPiece, setEntity, setEntityType, setError, setQuestionHiddenPiece, setHiddenPieceForShow } from '../../../shared/store/flowchart/pieces/actions';
import { FlowchartPieceActions, PieceType, AllPieceTypes } from '../../../shared/store/flowchart/pieces/types';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import uuid from 'uuid';

import { ApplicationState } from '../../../shared/store/types';
import { CategoryValue } from '../../flowchart/helpers/index';
import { piecesByCategory as customFieldPiecesByCategory, getComponentForReportFields } from '../../flowchart/helpers/report';
import { setIsolatedReportTypePiece, removeIsolatedReportTypePiece, registerReportTypeVariable, updateReportTypeStartPiece, selectReportType } from '../../../shared/store/reports/types/actions';
import { setIsFlowchartExpanded, setToastMessage } from '../../../shared/store/my-data/actions';
import { duplicatePiece, pastePiece } from '../../../shared/store/flowchart/helpers/pieces';
import { FlowchartContext, FlowchartInfoForPiece, InvalidPieceDetails } from '../../../contexts/flowchart-context';
import { PiecePositionState } from '../../../shared/helpers/common-types';

import Button from '../../../widgets/button/CommonButton';
import { translatePhrase } from '../../../shared/helpers/translation';
import { NudgeType } from '../../../shared/store/my-data/types';
import FlowchartWindow from '../../flowchart-window/FlowchartWindow';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canEditConfiguration = state.permissions.myPermissions.general.ReportsConfiguration === Permissions.WRITE;
    const canViewConfiguration = canEditConfiguration || state.permissions.myPermissions.general.ReportsConfiguration === Permissions.READ;

    return {
        isPartiallyLoaded: state.myData.isPartiallyLoaded,
        isLoaded: state.myData.isLoaded,
        isReadable: canViewConfiguration,
        isWritable: canEditConfiguration,
        isDragging: state.flowchart.pieces.isDragging,
        piecesData: state.flowchart.pieces,
        variablesData: state.flowchart.variables,
        applicationState: state,

        reportType: state.reports.types,
        isFlowchartExpanded: state.myData.isFlowchartExpanded,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece,
        selectedNudge: state.myData.selectedNudgeId,
        lastCopiedPiece: state.flowchart.pieces.lastCopiedPiece,
        lastCopiedPieceFlowchartContext: state.flowchart.pieces.lastCopiedPieceFlowchartContext,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    var emptyMethod = () => { };

    const flowchartPieceActions: FlowchartPieceActions = {
        setNextPiece: (pieceId, value) => dispatch(setNextPiece(pieceId, value)),
        setInnerPiece: (pieceId, value) => dispatch(setInnerPiece(pieceId, value)),
        setConditionForIfPiece: (pieceId, index, value) => dispatch(setConditionForIfPiece(pieceId, index, value)),
        setConditionPiece: (pieceId, value) => dispatch(setConditionPiece(pieceId, value)),
        setConditionNextPiece: (pieceId, index, value) => dispatch(setConditionNextPiece(pieceId, index, value)),
        setLoopVariable: (pieceId, value) => dispatch(setLoopVariable(pieceId, value)),
        setIterableVariable: (pieceId, value) => dispatch(setIterableVariable(pieceId, value)),
        setOperand: (pieceId, value) => dispatch(setOperand(pieceId, value)),
        setLeftOperand: (pieceId, value) => dispatch(setLeftOperand(pieceId, value)),
        setRightOperand: (pieceId, value) => dispatch(setRightOperand(pieceId, value)),
        setQuestionData: (pieceId, value) => dispatch(setQuestionData(pieceId, value)),
        setMemberVariable: (pieceId, value) => dispatch(setMemberVariable(pieceId, value)),
        setRequiredPiece: (pieceId, value) => dispatch(setQuestionRequiredPiece(pieceId, value)),
        setDisabledPiece: (pieceId, value) => dispatch(setQuestionDisabledPiece(pieceId, value)),
        setHiddenPiece: (pieceId, value) => dispatch(setQuestionHiddenPiece(pieceId, value)),
        setDefaultPiece: (pieceId, value) => dispatch(setQuestionDefaultPiece(pieceId, value)),
        setImage: (pieceId, value) => dispatch(setQuestionImage(pieceId, value)),
        setDataStoreValue: (pieceId, value) => dispatch(setDataStoreValue(pieceId, value)),
        setDataSetValue: (pieceId, value) => dispatch(setDataSetValue(pieceId, value)),
        setDataCopyVariable: (pieceId, value) => dispatch(setDataCopyVariable(pieceId, value)),
        setDataForList: (pieceId, value) => dispatch(setPieceForList(pieceId, value)),
        setReturnVariable: (pieceId, value) => dispatch(setReturnValue(pieceId, value)),
        setLocationPiece: (pieceId, value) => dispatch(setLocationPiece(pieceId, value)),
        setVariableForShow: (pieceId, value) => dispatch(setVariableForShow(pieceId, value)),
        setHiddenPieceForShow: (pieceId, value) => dispatch(setHiddenPieceForShow(pieceId, value)),
        setVariableForCustomField: (pieceId, value) => dispatch(setVariableForCustomField(pieceId, value)),
        setVariablePiece: (pieceId, value) => dispatch(setVariablePiece(pieceId, value)),
        setAffiliationVariablePiece: (pieceId, value) => dispatch(setWorkflowAffiliationVariable(pieceId, value)),
        setHeadingPiece: (pieceId, value) => dispatch(setHeading(pieceId, value)),
        setMessage: (pieceId, value) => dispatch(setMessage(pieceId, value)),

        setDatePiece: (pieceId, value) => dispatch(setDate(pieceId, value)),
        setMonthPiece: (pieceId, value) => dispatch(setMonth(pieceId, value)),
        setYearPiece: (pieceId, value) => dispatch(setYear(pieceId, value)),
        setDueDatePiece: (pieceId, value) => dispatch(setUpdateDueDateValue(pieceId, value)),

        setEntity: (pieceId, value) => dispatch(setEntity(pieceId, value)),
        setEntityType: (pieceId, value) => dispatch(setEntityType(pieceId, value)),

        setErrorValue: (pieceId: string, value: string | undefined) => dispatch(setError(pieceId, value)),

        setStartPieceData: (reportId: string, payload: PiecePositionState) => dispatch(updateReportTypeStartPiece(payload, reportId)),

        setFinsalPremium: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberFirstName: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberLastName: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberEmail: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberPhone: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberPan: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberState: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberCity: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberAddressLine1: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberAddressLine2: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberPinCode: (pieceId: string, value: string | undefined) => emptyMethod(),

        setFinsalUserEmail: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalUserPhone: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMember: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberDOB: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberGender: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberFatherName: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberMotherName: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberAnnualIncome: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberMaritalStatus: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalApplyForLoan: (pieceId: string, value: string | undefined) => emptyMethod(),
    };

    return {
        flowchartPieceActions,
        setIsolatedReportPiece: (reportId: string, payload: PiecePositionState) => dispatch(setIsolatedReportTypePiece(payload, reportId)),
        removeIsolatedReportPiece: (reportId: string, pieceId: string) => dispatch(removeIsolatedReportTypePiece(pieceId, reportId)),
        registerReportVariable: (reportId: string, variableId: string) => dispatch(registerReportTypeVariable(variableId, reportId)),

        toggleFlowChartExpansion: (flag: boolean) => dispatch(setIsFlowchartExpanded(flag)),
        addPiece: (pieceId: string, pieceType: PieceType) => dispatch(addPiece(pieceId, pieceType)),
        addFullPiece: (pieceData: AllPieceTypes) => dispatch(addFullPiece(pieceData)),
        deletePiece: (pieceId: string) => dispatch(deletePiece(pieceId)),
        copyPiece: (pieceId: string, flowchartContext: string) => dispatch(copyPiece(pieceId, flowchartContext)),
        setToastMessage: (message: string) => dispatch(setToastMessage(message)),
        selectReportType: (id: string) => dispatch(selectReportType(id)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps<{ id: string, version: string }>;

type OwnState = {
    isWaitingForDrag: boolean,
    isShowingValidations: boolean,

    expandedCategory: string | undefined,
    searchTerm: string,
    invalidPieces: Array<InvalidPieceDetails>,
    isFlowchartExpanded: boolean,
    isFlowchartOptions: boolean,
}

class ConnectedReportFlowchart extends Component<Props, OwnState> {

    state = {
        isWaitingForDrag: false,
        isShowingValidations: false,
        expandedCategory: undefined,
        searchTerm: '',
        invalidPieces: new Array<InvalidPieceDetails>(),
        isFlowchartExpanded: false,
        isFlowchartOptions: false,
    };

    deletePiece = (pieceId: string) => {
        const reportTypeTypeId = this.props.match.params.id;
        const reportType = this.props.reportType.byId[reportTypeTypeId];

        const pieceFound = !!reportType.isolatedPieces.find(isolatedPiece => isolatedPiece.piece === pieceId);

        if (!pieceFound) {
            // You can only delete isolated pieces
            return;
        }

        this.props.removeIsolatedReportPiece(reportType.id, pieceId);
        this.props.deletePiece(pieceId);
    }

    duplicatePiece = (pieceId: string, attachedPiece: boolean = false) => {
        const reportTypeTypeId = this.props.match.params.id;
        const reportType = this.props.reportType.byId[reportTypeTypeId];

        const pieceToCopy = this.props.piecesData.byId[pieceId];
        const flowchartHolderElement = document.getElementById('flowchart-holder');

        if (pieceToCopy.type === PieceType.START) {
            return '';
        }

        if (!flowchartHolderElement) {
            throw new Error('This element needs to exist');
        }

        const newId = duplicatePiece(this.props.piecesData, this.props.addFullPiece, undefined, pieceId)

        if (!attachedPiece) {
            this.props.setIsolatedReportPiece(reportType.id, {
                piece: newId,
                position: {
                    x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                    y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                },
            });
        }

        return newId;
    }

    pastePiece = (pieceId: string, attachedPiece: boolean = false) => {

        if (this.props.lastCopiedPieceFlowchartContext === this.props.match.params.id) {
            this.duplicatePiece(pieceId, attachedPiece);
            return;
        }

        const reportTypeTypeId = this.props.match.params.id;
        const reportType = this.props.reportType.byId[reportTypeTypeId];

        const pieceToPaste = this.props.piecesData.byId[pieceId];
        const flowchartHolderElement = document.getElementById('flowchart-holder');

        if (pieceToPaste.type === PieceType.START) {
            return '';
        }

        if (!flowchartHolderElement) {
            throw new Error('This element needs to exist');
        }

        const newId = pastePiece(this.props.piecesData, this.props.addFullPiece, pieceId)

        if (!attachedPiece) {
            this.props.setIsolatedReportPiece(reportType.id, {
                piece: newId,
                position: {
                    x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                    y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                },
            });
        }

        return newId;
    }

    handleKeyPress = (e: KeyboardEvent) => {
        // Do not apply any shortcuts when you are typing something into an input element
        if (window.document.activeElement && window.document.activeElement.tagName === 'INPUT') {
            return;
        }

        switch (e.key) {
            case 'Backspace':
            case 'Delete':
                this.props.lastDraggedPiece && this.deletePiece(this.props.lastDraggedPiece);
                return;
            case 'D':
            case 'd':
                this.props.lastDraggedPiece && this.duplicatePiece(this.props.lastDraggedPiece);
                return;
            case 'C':
            case 'c':
                if (this.props.lastDraggedPiece && (e.ctrlKey || e.metaKey)) {
                    this.props.copyPiece(this.props.lastDraggedPiece, this.props.match.params.id);
                    this.props.setToastMessage('The piece has been copied');
                }
                return;
            case 'V':
            case 'v':
                (e.ctrlKey || e.metaKey) && this.props.lastCopiedPiece && this.pastePiece(this.props.lastCopiedPiece);
                return;
        }
    }

    handleKeyLift = (e: KeyboardEvent) => {
        // Do not apply any shortcuts when you are typing something into an input element
        if (window.document.activeElement && (window.document.activeElement.tagName === 'INPUT' || window.document.activeElement.classList.contains('public-DraftEditor-content'))) {
            return;
        }

        switch (e.key) {
            case '/':
                document.getElementById('piece-search')?.focus();
                return;
        }
    }

    componentWillMount() {
        document.addEventListener('keydown', this.handleKeyPress);
        document.addEventListener('keyup', this.handleKeyLift);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
        document.removeEventListener('keyup', this.handleKeyLift);

        if (this.props.isFlowchartExpanded) {
            this.props.toggleFlowChartExpansion(false);
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        };

        // The drag event interferes with the click event. Put the change in flowchart state as the last thing in the event queue so that the click event is fired before pointer events are removed from the flowchart
        window.setTimeout(() => {
            this.setState({
                isWaitingForDrag: this.props.isDragging,
            });
        }, 500);

    }

    expandCategory = (category: string) => {
        this.setState(prevState => {
            return {
                expandedCategory: prevState.expandedCategory === category ? undefined : category,
            }
        });
    }

    addPiece = (pieceType: PieceType) => {
        const reportTypeTypeId = this.props.match.params.id;
        const reportType = this.props.reportType.byId[reportTypeTypeId];

        const newId = uuid.v4();
        const flowchartHolderElement = document.getElementById('flowchart-holder');

        if (!flowchartHolderElement) {
            throw new Error('This element needs to exist');
        }

        this.props.addPiece(newId, pieceType);

        this.props.setIsolatedReportPiece(reportType.id, {
            piece: newId,
            position: {
                x: flowchartHolderElement.scrollLeft + (0.7 * window.innerWidth) / 2,
                y: flowchartHolderElement.scrollTop + 250,
            },
        });


    }

    updateSearchTerm = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            searchTerm: e.target.value,
        });
    }

    toggleValidationVisibility = () => {
        this.setState(prevState => {
            return {
                isShowingValidations: !prevState.isShowingValidations,
            };
        });
    }

    setInvalidPiece = (pieceId: string, parentSplitPieces: Array<string>) => {

        this.setState(prevState => {
            if (prevState.invalidPieces.find(pieceInfo => pieceInfo.pieceId === pieceId)) {
                return null;
            }

            const newInvalidPieces = prevState.invalidPieces.concat({
                pieceId,
                parentSplitPieces,
            });

            return {
                invalidPieces: newInvalidPieces,
            };
        });
    }

    removeInvalidPiece = (pieceId: string) => {
        this.setState(prevState => {
            if (!prevState.invalidPieces.find(pieceInfo => pieceInfo.pieceId === pieceId)) {
                return null;
            }

            const newInvalidPieces = prevState.invalidPieces.filter(piece => piece.pieceId !== pieceId);
            return {
                invalidPieces: newInvalidPieces,
            };
        });
    }

    goToInvalidPiece = async (pieceInfo: InvalidPieceDetails) => {
        const pieceElement = document.querySelector(`[data-piece-id="${pieceInfo.pieceId}"]`);
        pieceElement?.scrollIntoView();
    }


    toggleExpandFlowchart = () => {
        this.props.toggleFlowChartExpansion(!this.props.isFlowchartExpanded);
    };

    toggleFlowchartOptions = () => {
        this.setState({ isFlowchartOptions: !this.state.isFlowchartOptions, isShowingValidations: false });
    }

    render() {

        if (!this.props.isReadable) {
            return <Redirect to="/dashboard" />;
        }

        if (!this.props.isLoaded && !this.props.isPartiallyLoaded) {
            return <div></div>;
        }

        const reportTypeTypeId = this.props.match.params.id;
        const reportType = this.props.reportType.byId[reportTypeTypeId];

        const startPiece = reportType.startPiece ? getComponentForReportFields(reportType, this.props.applicationState, this.props.piecesData, this.props.variablesData, reportType.variables, this.props.isWritable, this.props.flowchartPieceActions, this.props.setIsolatedReportPiece.bind(this, reportType.id), this.props.removeIsolatedReportPiece.bind(this, reportType.id), this.props.registerReportVariable.bind(this, reportType.id), reportType.startPiece.piece, undefined) : undefined;

        let isolatedPieces: Array<JSX.Element | undefined> = [];

        isolatedPieces = reportType.isolatedPieces.map(isolatedPieceData => {
            const isolatedPiece = isolatedPieceData.piece ? getComponentForReportFields(reportType, this.props.applicationState, this.props.piecesData, this.props.variablesData, reportType.variables, this.props.isWritable, this.props.flowchartPieceActions, this.props.setIsolatedReportPiece.bind(this, reportType.id), this.props.removeIsolatedReportPiece.bind(this, reportType.id), this.props.registerReportVariable.bind(this, reportType.id), isolatedPieceData.piece, undefined, isolatedPieceData.position) : undefined;
            return isolatedPiece;
        });

        const piecesByCategory = customFieldPiecesByCategory;

        const flowchartHolderElement = document.getElementById('flowchart-holder');
        const flowchartInfoForPiece: FlowchartInfoForPiece = {
            flowchartHolderElement,
            projects: reportType.project ? [reportType.project] : this.props.applicationState.structure.projects.allEntries,
            variables: reportType.variables.slice(),
            parentSplitPieceIds: [],
            parentIfPieceIndices: [],
            invalidPieces: this.state.invalidPieces,
            isValidating: this.state.isShowingValidations,
            isReadonly: !this.props.isWritable,
            highlightIncompletePieces: true,
            setInvalidPiece: this.setInvalidPiece,
            removeInvalidPiece: this.removeInvalidPiece,
            searchTerm: this.state.searchTerm,
        }

        const invalidPieces = this.state.invalidPieces.filter(pieceInfo => pieceInfo.pieceId in this.props.piecesData.byId);
        const allPieces = Object.keys(piecesByCategory).map(category => (piecesByCategory as any)[category].pieces).flat();
        const pieceNameMapping: {
            [type: string]: string,
        } = {};

        allPieces.forEach(piece => {
            pieceNameMapping[piece.type] = piece.name;
        });

        return (
            <FlowchartWindow
                flowchartType={reportType}
                selectedNudge={this.props.selectedNudge}
                toggleExpandFlowChart={this.toggleExpandFlowchart}
                toggleFlowChartOptions={this.toggleFlowchartOptions}
                isFlowChartMoreOptionShown={this.state.isFlowchartOptions}
                goBack={() => { this.props.selectReportType(reportType.id); this.props.history.push('/reports/configuration') }}
                isExpanded={this.props.isFlowchartExpanded}>

                <div className={`${this.state.isWaitingForDrag ? styles.waitingForDragFlowchartHolder : styles.normalFlowchartHolder} ${this.state.isFlowchartExpanded && styles.expandedFlowchart} ${styles.flowchartWithPiceContainer}`}>
                    <section className={styles.piecesCollection}>
                        <section className={styles.pieceOptionWrapper}>
                            <section className={styles.searchSection}>
                                <input type="text"
                                    id="piece-search"
                                    placeholder="Search for pieces"
                                    className={styles.searchInput + " " + (NudgeType.FLOWCHART_SEARCH_PIECES === this.props.selectedNudge ? styles.nudgeActive : "")}
                                    value={this.state.searchTerm}
                                    onChange={this.updateSearchTerm} />
                            </section>
                        </section>
                        {Object.keys(piecesByCategory).map(category => {
                            let piecesInCategory: Array<JSX.Element | undefined> = [];
                            if (category in piecesByCategory) {
                                piecesInCategory = (piecesByCategory as { [key: string]: CategoryValue })[category].pieces.map(piece => (!!this.state.searchTerm && !piece.name.toLocaleLowerCase().includes(this.state.searchTerm.toLocaleLowerCase())) ? undefined :
                                    <section className={styles.pieceEntry} key={piece.name} onClick={() => this.addPiece(piece.type)}>
                                        <div className={styles.pieceEntryName}>{piece.name}</div>
                                        {piece.tooltip && <a target='_blank' rel="noreferrer" href={piece.tooltip}>
                                            <span className={styles.infoIcon}> <ExternalIcon /> </span></a>
                                        }
                                    </section>).filter(pieceElement => typeof pieceElement !== 'undefined');
                            }
                            return piecesInCategory.length > 0 && <section key={category}>
                                <section className={styles.categoryHeading} onClick={() => this.expandCategory(category)}>
                                    <div className={styles.categoryIndicator} style={{ background: (piecesByCategory as { [key: string]: CategoryValue })[category].color }}></div>
                                    <div className={styles.categoryName}>{category}</div>
                                    <div className={this.state.expandedCategory === category ? styles.expandedCategoryChevron : styles.categoryChevron}><ChevronDownIcon /></div>
                                </section>
                                {(!!this.state.searchTerm || this.state.expandedCategory === category) && <div>
                                    {piecesInCategory}
                                </div>}
                            </section>
                        })}
                    </section>
                    <div id="flowchart-holder" className={`${styles.flowchartContainer} ${!this.props.isWritable && ' react-drag-disabled'}`}>
                        <FlowchartContext.Provider value={flowchartInfoForPiece}>
                            <h4>{reportType.name}</h4>
                            {startPiece}
                            {isolatedPieces.map((isolatedPiece, i) => <div className={styles.pieceHolder} key={i}>{isolatedPiece}</div>)}
                            {this.props.isWritable && this.state.isFlowchartOptions && <div className={styles.rightSideFixedElements}>
                                <section className={styles.validations}>
                                    <section className={styles.validationHeading}>
                                        <h5>Validation</h5>
                                        <div className={styles.toggle} onClick={this.toggleValidationVisibility}>{this.state.isShowingValidations ? 'Hide' : 'Show'}</div>
                                    </section>
                                    {this.state.isShowingValidations && <section className={styles.validation}>
                                        <div className={styles.name}>Validating now</div>
                                        <div className={styles.value}>{invalidPieces.length > 0 ? 'Click on a piece below to view the piece' : 'There are no invalid pieces in the flowchart'}</div>
                                    </section>}
                                    {this.state.isShowingValidations && invalidPieces.map(pieceInfo => {
                                        return <section className={styles.validation} onClick={e => this.goToInvalidPiece(pieceInfo)}>
                                            <div className={styles.name}>{pieceNameMapping[this.props.piecesData.byId[pieceInfo.pieceId].type]}</div>
                                            <div className={styles.value}>{pieceInfo.parentSplitPieces.map(parentId => `#${parentId.substring(0, 5)}`).join(', ')}</div>
                                        </section>
                                    })}
                                </section>
                            </div>}
                        </FlowchartContext.Provider>
                    </div>
                </div>

            </FlowchartWindow>
        );
    }
}

const ReportFlowchart = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectedReportFlowchart) as any);

export default ReportFlowchart;