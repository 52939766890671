import React, { Component, ChangeEvent } from 'react';
import styles from './Transfer.module.scss';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/types';

import { transferWorkflow, updateProcessState } from '../../../shared/store/workflows/actions';

import { PieceType } from '../../../shared/store/flowchart/pieces/types';
import { getReadableDataForCustomField } from '../../../shared/store/custom-fields';
import { getAllLocationsVisibleToUser } from '../../../shared/helpers/locations';
import { translatePhrase } from '../../../shared/helpers/translation';
import { setToastMessage } from '../../../shared/store/my-data/actions';
import { withRouter, RouteComponentProps } from 'react-router';
import { WorkflowProcessState } from '../../../shared/store/workflows/types';
import { RadioButton } from './RadioButton';
import Button from '../../../widgets/button/CommonButton';

import { ReactComponent as UserIcon } from '../../../common/assets/user-certification.svg';
import { ReactComponent as StructureIcon } from '../../../common/assets/data-structured.svg';
import { ReactComponent as MapIcon } from '../../../assets/map-pin.svg';
import LoaderModal from '../../../widgets/loader/LoaderModal';

type OwnProps = {
    workflowId: string,
    transferId: string,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    const transferPiece = state.flowchart.pieces.byId[ownProps.transferId];

    if (transferPiece.type !== PieceType.TRANSFER_WORKFLOW) {
        throw new Error('The ID should point to a piece of the transfer type');
    }

    if (!transferPiece.roles) {
        throw new Error('The transfer must be attached to one or more roles');
    }

    const roles = transferPiece.roles;
    const currentOwnerLocationHierarchy: Array<string> = getAllLocationsVisibleToUser(state.workflows.byId[ownProps.workflowId].user, state);

    let users = state.users.allEntries
    .filter(userId => {
        const user = state.users.byId[userId];
        return userId !== state.myData.id && roles.some(role => user.roles.includes(role)) && currentOwnerLocationHierarchy.some(hierarchy => user.locations.includes(hierarchy));
    })
    .map(userId => {
        return {
            value: userId,
            roles: state.users.byId[userId].roles.filter(roleId => roleId in state.structure.roles.byId).map(roleId => translatePhrase(state.structure.roles.byId[roleId].name)).join(', '),
            locations: state.users.byId[userId].locations.filter(locationId => locationId in state.structure.locations.byId).map(locationId => translatePhrase(state.structure.locations.byId[locationId].name)).join(', '),
        };
    });

    if (roles.includes('previous')) {
        state.workflows.byId[ownProps.workflowId].trackingUsers.forEach(userId => {
            const userData = {
                value: userId,
                roles: state.users.byId[userId].roles.filter(roleId => roleId in state.structure.roles.byId).map(roleId => translatePhrase(state.structure.roles.byId[roleId].name)).join(', '),
                locations: state.users.byId[userId].locations.map(locationId => state.structure.locations.byId[locationId].name).join(', '),
            };

            !users.some(user => user.value === userId) && users.push(userData);
        })
    }

    return {
        userChoices: users,
        usersData: state.users,
        workflowData: state.workflows,

        applicationState: state,
    }
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
    return {
        transferWorkflow: (userId: string) => dispatch(transferWorkflow(ownProps.workflowId, userId)),
        setToastMessage: (message: string) => dispatch(setToastMessage(message)),
        updateWorkflowProcessState: (processState: WorkflowProcessState, workflowId: string, userId: string) => dispatch(updateProcessState(processState, workflowId, userId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps;

type OwnState = {
    userInput: string,
    errorMessage: string,
    isTransferred: boolean,
    toastModal: JSX.Element | undefined
}

class ConnectedTransfer extends Component<Props, OwnState> {
    state: OwnState = {
        userInput: '',
        errorMessage: '',
        isTransferred: false,
        toastModal: undefined
    };

    static getDerivedStateFromProps(props: Readonly<Props>, state: Readonly<OwnState>) {
        if (!state.userInput && props.userChoices.length === 1) {
            return {
                userInput: props.userChoices[0].value,
            };
        }

        return null;
    }


    showToastModal = (text: string, isSuccess: boolean) => {
        this.setState({
            toastModal: <LoaderModal loaderText={[text]} isSuccess={isSuccess} isError={!isSuccess} />
        });

        setTimeout(() => {
            this.setState({
                toastModal: undefined
            })
        }, 4000);
    }

    selectChoice = (value: string) => {
        this.setState({
            userInput: value,
        });
    }

    showErrorMessage = (message: string) => {
        this.showToastModal(message, false);

        this.setState({
            errorMessage: message,
        });

        window.setTimeout(() => {
            this.setState({
                errorMessage: '',
            });
        }, 4000);
    }

    handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            userInput: event.currentTarget.value,
        });
    }

    getWorkflowProcessState = () => {
        const workflow = this.props.workflowData.byId[this.props.workflowId];
        const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: workflow.history[workflow.historyIndex].customFields,
            lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
            executionStack: workflow.history[workflow.historyIndex].executionStack,
            forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
            variables: workflow.history[workflow.historyIndex].variables,
            displayingQuestionPieceId: workflow.history[workflow.historyIndex].displayingQuestionPieceId,
            displayingShowPieceId: workflow.history[workflow.historyIndex].displayingShowPieceId,
            displayingGroupPieceId: workflow.history[workflow.historyIndex].displayingGroupPieceId,
            displayingTransferPieceId: workflow.history[workflow.historyIndex].displayingTransferPieceId,
            displayingContinuePieceId: workflow.history[workflow.historyIndex].displayingContinuePieceId,
            displayingAddWorkflowPieceId: workflow.history[workflow.historyIndex].displayingAddWorkflowPieceId,
            createdWorkflowId: workflow.history[workflow.historyIndex].createdWorkflowId,
        }));

        return processState;
    }

    transferWorkflow = () => {
        if (!this.state.userInput) {
            return;
        }

        this.setState({
            isTransferred: true,
        });

        const user = this.props.usersData.byId[this.state.userInput];
        const roles = user.roles.filter(roleId => roleId in this.props.applicationState.structure.roles.byId).map(roleId => this.props.applicationState.structure.roles.byId[roleId]);
        let userName = user.customFields[this.props.usersData.nameFieldId];
        const locationNames = user.locations
        .filter(locationId => locationId in this.props.applicationState.structure.locations.byId)
        .map(locationId => {
            const locationName = this.props.applicationState.structure.locations.byId[locationId].name;
            return translatePhrase(locationName);
        })

        const nameField = this.props.usersData.customFields.byId[this.props.usersData.nameFieldId];

        userName = getReadableDataForCustomField(userName, nameField, user.id, 'user');

        this.props.transferWorkflow(this.state.userInput);
        this.props.setToastMessage(translatePhrase(`${translatePhrase('This workflow has been transferred to')} ${userName} (${translatePhrase('Roles')}: ${roles.filter(role => !!role).map(role => role.name)}, ${translatePhrase('Locations')}: ${locationNames.join(', ')})`));        
        
        const processState = this.getWorkflowProcessState();
        processState.lastComputedPiece = processState.displayingTransferPieceId;
        processState.displayingTransferPieceId = undefined;

        const workflow = this.props.workflowData.byId[this.props.workflowId];

        this.props.updateWorkflowProcessState(processState, this.props.workflowId, workflow.user);
    }

    submitUser = () => {
        if (!this.state.userInput) {
            this.showErrorMessage('Please select a user');
            return;
        }

        this.transferWorkflow();

        this.props.history.push('/workflow-data/' + this.props.workflowId);
    }

    render() {

        const usersMarkup = <section className={styles.choicesList}>
            <div className={styles.options}>
                {this.props.userChoices.map(userChoice => {
                    const user = this.props.usersData.byId[userChoice.value];
                    let userName = user.customFields[this.props.usersData.nameFieldId];

                    const nameField = this.props.usersData.customFields.byId[this.props.usersData.nameFieldId];

                    userName = getReadableDataForCustomField(userName, nameField, user.id, 'user');

                    return (<section key={userChoice.value} className={this.state.userInput === userChoice.value ? styles.activeChoice : styles.choice} onClick={() => this.selectChoice(userChoice.value)}>                        
                        <div className={styles.choiceMainText}> <RadioButton isActive={this.state.userInput === userChoice.value} /> <span> {userName} </span> </div>
                        <div className={styles.choiceSubText}><StructureIcon /> {userChoice.roles} <span className={styles.separator}> | </span> <MapIcon /> {userChoice.locations}</div>
                    </section>)
                })}
            </div>
        </section>;

        return <div key={this.props.transferId} className={styles.FocusSpace}>
            <h3 className={styles.sectionHeading}>
                {translatePhrase('Select the user to transfer')}
            </h3>

            <div className={styles.question + ' ' + (this.state.errorMessage ? styles.errorQuestion : '')}>
                <section className={styles.questionText}>
                    <div className={styles.iconHolder}>
                        <UserIcon />
                    </div>
                    <span className={styles.questionWords}>
                        {translatePhrase('Select User')}                        
                    </span>
                </section>

                <div className={styles.questionDetails}>
                    {usersMarkup}
                </div>

                {this.state.errorMessage && <span className={styles.errorText}>*{translatePhrase(this.state.errorMessage)}</span>}
            </div>            

            <div className={styles.transferBox}>
                <Button text={translatePhrase('Transfer')} padding={'0px 20px'} isRounded color={'contrast'}
                onClick={() => this.submitUser()} />
            </div>

            { this.state.toastModal }
        </div>
    }

}

const Transfer = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectedTransfer));

export default Transfer;