import React, { ChangeEvent, Component } from 'react';
import styles from './step-piece/StepPiece.module.scss';
import Input from '../Input';
import StepPiece from './step-piece/StepPiece'
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setDataStoreValue, setMemberVariable, setCustomField, setVariableForCustomField, setEntityType } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import { valuePieceSlotTarget } from './utilities';
import { FlowchartContext, FlowchartInfoForPiece } from '../../../contexts/flowchart-context';


type StorePieceProps = {
    selectedType?: string,
    selectedEntityType?: string,

    nextPiece?: JSX.Element,
    variablePiece?: JSX.Element,
    dataVariablePiece?: JSX.Element,
    textToStore?: string,
    memberVariable?: JSX.Element,

    storingCustomField?: string,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,

        projectsData: state.structure.projects,
        levelsData: state.structure.levels,
        rolesData: state.structure.roles,
        userData: state.users,
        memberTypes: state.members.types,
        groupTypes: state.groups.types,
        workflowTypes: state.workflows.types,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string | undefined) => dispatch(setTargetPiece(pieceId)),
        setDataStoreValue: (targetPieceId: string, draggedPieceId: string) => dispatch(setDataStoreValue(targetPieceId, draggedPieceId)),
        setMemberVariable: (targetPieceId: string, draggedPieceId: string) => dispatch(setMemberVariable(targetPieceId, draggedPieceId)),
        setCustomField: (pieceId: string, value: string) => dispatch(setCustomField(pieceId, value)),
        setVariableForCustomField: (pieceId: string, value: string) => dispatch(setVariableForCustomField(pieceId, value)),
        setEntityType: (pieceId: string, value: string) => dispatch(setEntityType(pieceId, value)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = StorePieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type StorePieceState = {
    isHoveringOverMemberPiece: boolean,
    isHoveringOverVariablePiece: boolean,
    isHoveringOverDataVariablePiece: boolean,
    entityTypeSearchText: string,
    fieldSearchText: string,
}

class ConnectedStorePiece extends Component<Props, StorePieceState> {

    state = {
        isHoveringOverMemberPiece: false,
        isHoveringOverVariablePiece: false,
        isHoveringOverDataVariablePiece: false,
        entityTypeSearchText: '',
        fieldSearchText: '',
    };

    searchForEntityType = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            entityTypeSearchText: e.target.value,
        });
    }

    searchForCustomField = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            fieldSearchText: e.target.value,
        });
    }

    handleHoverOverMemberPiece = () => {
        this.setState({
            isHoveringOverMemberPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfMemberPiece = () => {
        this.setState({
            isHoveringOverMemberPiece: false,
        });
    };

    handleHoverOverVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: false,
        });
    };

    handleSetValueUpdate = (value: string) => {
        this.props.setDataStoreValue(this.props.pieceId, value);
    }

    handleHoverOverDataVariablePiece = () => {
        this.setState({
            isHoveringOverDataVariablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfDataVariablePiece = () => {
        this.setState({
            isHoveringOverDataVariablePiece: false,
        });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && (this.state.isHoveringOverMemberPiece || this.state.isHoveringOverVariablePiece || this.state.isHoveringOverDataVariablePiece)) {

            if (this.state.isHoveringOverMemberPiece) {
                this.props.setMemberVariable(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverVariablePiece) {
                this.props.setVariableForCustomField(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverDataVariablePiece) {
                this.props.setDataStoreValue(this.props.pieceId, this.props.lastDraggedPiece.id);
            }

            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverMemberPiece: false,
                isHoveringOverVariablePiece: false,
                isHoveringOverDataVariablePiece: false,
            });
        }
    }

    updateValidity = (isValid: boolean, flowchartContext: FlowchartInfoForPiece) => {
        if (!isValid) {
            const invalidPieceInfo = flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId);

            if (!invalidPieceInfo && flowchartContext.setInvalidPiece) {
                flowchartContext.setInvalidPiece(this.props.pieceId, flowchartContext.parentSplitPieceIds);
            }
        }

        if (isValid && flowchartContext.removeInvalidPiece && flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId)) {
            flowchartContext.removeInvalidPiece(this.props.pieceId);
        }
    }

    render() {

        return <FlowchartContext.Consumer>
            {
                (flowchartContext) => {
                    const entityTypeSelectCallback = this.props.setEntityType.bind(this, this.props.pieceId);

                    let entityTypeSelectBox: JSX.Element | undefined;
                    let customFieldsSelectBox: JSX.Element | undefined;
                    let entityType: string | undefined;

                    let isValid = true;

                    const entityTypeSearchElement = <div className={styles.nameContainer + ' ignore-options-onclickoutside'}>
                        <input className={styles.nameInput} onChange={this.searchForEntityType} value={this.state.entityTypeSearchText} type="text" placeholder="Search entity type" />
                    </div>;

                    const fieldSearchElement = <div className={styles.nameContainer + ' ignore-options-onclickoutside'}>
                        <input className={styles.nameInput} onChange={this.searchForCustomField} value={this.state.fieldSearchText} type="text" placeholder="Search custom field" />
                    </div>;

                    switch (this.props.selectedType) {

                        case 'Location':
                            entityType = this.props.selectedEntityType;

                            let allowedLevelIds = this.props.levelsData.allEntries
                                .filter(levelId => {
                                    const level = this.props.levelsData.byId[levelId];
                                    return level.name.toLocaleLowerCase().includes(this.state.entityTypeSearchText.toLocaleLowerCase())
                                });

                            if (flowchartContext.projects.length > 0) {
                                allowedLevelIds = allowedLevelIds.filter(levelId => {
                                    const level = this.props.levelsData.byId[levelId];
                                    return flowchartContext.projects.includes(level.project);
                                });
                            }

                            if (entityType) {
                                isValid = allowedLevelIds.includes(entityType);
                            }

                            entityTypeSelectBox = <SelectBox theme="pink" selectionPromptText={entityType && this.props.levelsData.byId.hasOwnProperty(entityType) ? this.props.levelsData.byId[entityType].name : undefined}>
                                <DropDownList theme="pink" dismissAfterSelection={true}>
                                    {entityTypeSearchElement}
                                    {allowedLevelIds.map(levelId => {
                                        const levelData = this.props.levelsData.byId[levelId];

                                        return <ListItem theme="pink" name={levelData.name + ' (' + this.props.projectsData.byId[levelData.project].name + ')'} key={levelId} value={levelId} onClick={entityTypeSelectCallback} />
                                    })}
                                </DropDownList>
                            </SelectBox>;


                            if (entityType && this.props.levelsData.byId.hasOwnProperty(entityType)) {

                                const level = this.props.levelsData.byId[entityType];

                                const validStoringCustomFieldIds = level.customFields
                                    .filter(customFieldId => {
                                        const customField = this.props.levelsData.customFields.byId[customFieldId];
                                        return customField.name.toLocaleLowerCase().includes(this.state.fieldSearchText.toLocaleLowerCase())
                                    })
                                    .filter(levelFieldId => {
                                        const levelField = this.props.levelsData.customFields.byId[levelFieldId];
                                        return !levelField.isComputed;
                                    });

                                if (isValid && this.props.storingCustomField) {
                                    isValid = validStoringCustomFieldIds.includes(this.props.storingCustomField);
                                }

                                customFieldsSelectBox = <SelectBox theme="pink" selectionPromptText={this.props.storingCustomField ? this.props.levelsData.customFields.byId[this.props.storingCustomField].name : 'Select fields'}>
                                    <DropDownList theme="pink" dismissAfterSelection={true}>
                                        {fieldSearchElement}
                                        {validStoringCustomFieldIds.map(levelFieldId => <ListItem theme="pink" name={this.props.levelsData.customFields.byId[levelFieldId].name} key={levelFieldId} value={levelFieldId} onClick={this.props.setCustomField.bind(this, this.props.pieceId)} />)}
                                    </DropDownList>
                                </SelectBox>;
                            }

                            break;

                        case 'User':
                            entityType = this.props.selectedEntityType;

                            let allowedRoleIds = this.props.rolesData.allEntries;

                            if (flowchartContext.projects.length > 0) {
                                allowedRoleIds = allowedRoleIds.filter(roleId => {
                                    const role = this.props.rolesData.byId[roleId];
                                    const level = this.props.levelsData.byId[role.level];

                                    return flowchartContext.projects.includes(level.project);
                                });
                            }

                            entityTypeSelectBox = <SelectBox theme="pink" selectionPromptText={entityType && this.props.rolesData.byId.hasOwnProperty(entityType) ? this.props.rolesData.byId[entityType].name : 'Any role'}>
                                <DropDownList theme="pink" dismissAfterSelection={true}>
                                    {entityTypeSearchElement}
                                    {'any role'.toLocaleLowerCase().includes(this.state.entityTypeSearchText.toLocaleLowerCase()) && <ListItem theme="pink" name="Any role" key="0" value="" onClick={entityTypeSelectCallback} />}
                                    {allowedRoleIds
                                        .filter(roleId => {
                                            const role = this.props.rolesData.byId[roleId];
                                            return role.name.toLocaleLowerCase().includes(this.state.entityTypeSearchText.toLocaleLowerCase())
                                        })
                                        .map(roleId => <ListItem theme="pink" name={this.props.rolesData.byId[roleId].name} key={roleId} value={roleId} onClick={entityTypeSelectCallback} />)}
                                </DropDownList>
                            </SelectBox>;

                            if (entityType) {
                                isValid = allowedRoleIds.includes(entityType);
                            }

                            const userFieldIds = this.props.userData.customFields.allFields
                                .filter(userFieldId => {
                                    const userField = this.props.userData.customFields.byId[userFieldId];
                                    return !userField.isComputed;
                                });

                            let roleFieldIds: Array<string> = [];

                            if (entityType) {
                                const role = this.props.rolesData.byId[entityType];

                                roleFieldIds = role.customFields
                                    .filter(roleFieldId => {
                                        const roleField = this.props.rolesData.customFields.byId[roleFieldId];
                                        return !roleField.isComputed;
                                    });
                            }

                            let customFieldName = 'Select Fields';

                            if (this.props.storingCustomField) {
                                if (this.props.userData.customFields.byId.hasOwnProperty(this.props.storingCustomField)) {
                                    customFieldName = this.props.userData.customFields.byId[this.props.storingCustomField].name;
                                } else if (this.props.rolesData.customFields.byId.hasOwnProperty(this.props.storingCustomField)) {
                                    customFieldName = this.props.rolesData.customFields.byId[this.props.storingCustomField].name;
                                }
                            }

                            customFieldsSelectBox = <SelectBox theme="pink" selectionPromptText={customFieldName}>
                                <DropDownList theme="pink" dismissAfterSelection={true}>
                                    {userFieldIds.map(userFieldId => <ListItem theme="pink" name={this.props.userData.customFields.byId[userFieldId].name} key={userFieldId} value={userFieldId} onClick={this.props.setCustomField.bind(this, this.props.pieceId)} />)}
                                    {roleFieldIds.map(roleFieldId => <ListItem theme="pink" name={this.props.rolesData.customFields.byId[roleFieldId].name} key={roleFieldId} value={roleFieldId} onClick={this.props.setCustomField.bind(this, this.props.pieceId)} />)}
                                </DropDownList>
                            </SelectBox>;

                            break;

                        case 'Member':
                            entityType = this.props.selectedEntityType;

                            let allowedMemberTypeIds = this.props.memberTypes.allEntries
                                .filter(memberTypeId => {
                                    const memberType = this.props.memberTypes.byId[memberTypeId];
                                    return flowchartContext.projects.includes(memberType.project);
                                });

                            if (flowchartContext.projects.length > 0) {
                                allowedMemberTypeIds = allowedMemberTypeIds
                                    .filter(memberTypeId => {
                                        const memberType = this.props.memberTypes.byId[memberTypeId];
                                        return memberType.name.toLocaleLowerCase().includes(this.state.entityTypeSearchText.toLocaleLowerCase())
                                    });
                            }

                            if (entityType) {
                                isValid = allowedMemberTypeIds.includes(entityType);
                            }

                            entityTypeSelectBox = <SelectBox theme="pink" selectionPromptText={entityType && this.props.memberTypes.byId.hasOwnProperty(entityType) ? this.props.memberTypes.byId[entityType].name : undefined}>
                                <DropDownList theme="pink" dismissAfterSelection={true}>
                                    {entityTypeSearchElement}
                                    {allowedMemberTypeIds.map(memberTypeId => <ListItem theme="pink" name={this.props.memberTypes.byId[memberTypeId].name} key={memberTypeId} value={memberTypeId} onClick={entityTypeSelectCallback} />)}
                                </DropDownList>
                            </SelectBox>;


                            if (entityType && this.props.memberTypes.byId.hasOwnProperty(entityType)) {

                                const memberType = this.props.memberTypes.byId[entityType];

                                const validStoringCustomFieldIds = memberType.customFields
                                    .filter(customFieldId => {
                                        const customField = this.props.memberTypes.customFields.byId[customFieldId];
                                        return customField.name.toLocaleLowerCase().includes(this.state.fieldSearchText.toLocaleLowerCase())
                                    })
                                    .filter(memberFieldId => {
                                        const memberField = this.props.memberTypes.customFields.byId[memberFieldId];
                                        return !memberField.isComputed;
                                    });

                                if (isValid && this.props.storingCustomField) {
                                    isValid = validStoringCustomFieldIds.includes(this.props.storingCustomField);
                                }

                                customFieldsSelectBox = <SelectBox theme="pink" selectionPromptText={this.props.storingCustomField && this.props.memberTypes.customFields.byId.hasOwnProperty(this.props.storingCustomField) ? this.props.memberTypes.customFields.byId[this.props.storingCustomField].name : 'Select fields'}>
                                    <DropDownList theme="pink" dismissAfterSelection={true}>
                                        {fieldSearchElement}
                                        {validStoringCustomFieldIds.map(memberFieldId => <ListItem theme="pink" name={this.props.memberTypes.customFields.byId[memberFieldId].name} key={memberFieldId} value={memberFieldId} onClick={this.props.setCustomField.bind(this, this.props.pieceId)} />)}
                                    </DropDownList>
                                </SelectBox>;
                            }

                            break;

                        case 'Group':
                            entityType = this.props.selectedEntityType;

                            let allowedGroupTypeIds = this.props.groupTypes.allEntries
                                .filter(groupTypeId => {
                                    const groupType = this.props.groupTypes.byId[groupTypeId];
                                    return groupType.name.toLocaleLowerCase().includes(this.state.entityTypeSearchText.toLocaleLowerCase())
                                });

                            if (flowchartContext.projects.length > 0) {
                                allowedGroupTypeIds = allowedGroupTypeIds.filter(groupTypeId => {
                                    const groupType = this.props.groupTypes.byId[groupTypeId];
                                    return flowchartContext.projects.includes(groupType.project);
                                });
                            }

                            if (entityType) {
                                isValid = allowedGroupTypeIds.includes(entityType);
                            }

                            entityTypeSelectBox = <SelectBox theme="pink" selectionPromptText={entityType && this.props.groupTypes.byId.hasOwnProperty(entityType) ? this.props.groupTypes.byId[entityType].name : undefined}>
                                <DropDownList theme="pink" dismissAfterSelection={true}>
                                    {entityTypeSearchElement}
                                    {allowedGroupTypeIds.map(groupTypeId => <ListItem theme="pink" name={this.props.groupTypes.byId[groupTypeId].name} key={groupTypeId} value={groupTypeId} onClick={entityTypeSelectCallback} />)}
                                </DropDownList>
                            </SelectBox>;


                            if (entityType && this.props.groupTypes.byId.hasOwnProperty(entityType)) {

                                const groupType = this.props.groupTypes.byId[entityType];

                                const validStoringCustomFieldIds = groupType.customFields
                                    .filter(customFieldId => {
                                        const customField = this.props.groupTypes.customFields.byId[customFieldId];
                                        return customField.name.toLocaleLowerCase().includes(this.state.fieldSearchText.toLocaleLowerCase())
                                    })
                                    .filter(groupFieldId => {
                                        const groupField = this.props.groupTypes.customFields.byId[groupFieldId];
                                        return !groupField.isComputed;
                                    });

                                if (isValid && this.props.storingCustomField) {
                                    isValid = validStoringCustomFieldIds.includes(this.props.storingCustomField);
                                }

                                customFieldsSelectBox = <SelectBox theme="pink" selectionPromptText={this.props.storingCustomField && this.props.groupTypes.customFields.byId.hasOwnProperty(this.props.storingCustomField) ? this.props.groupTypes.customFields.byId[this.props.storingCustomField].name : 'Select fields'}>
                                    <DropDownList theme="pink" dismissAfterSelection={true}>
                                        {fieldSearchElement}
                                        {validStoringCustomFieldIds.map(groupFieldId => <ListItem theme="pink" name={this.props.groupTypes.customFields.byId[groupFieldId].name} key={groupFieldId} value={groupFieldId} onClick={this.props.setCustomField.bind(this, this.props.pieceId)} />)}
                                    </DropDownList>
                                </SelectBox>;
                            }
                            break;

                        case 'Workflow':
                            entityType = this.props.selectedEntityType;

                            let allowedWorkflowTypeIds = this.props.workflowTypes.allEntries
                                .filter(workflowTypeId => {
                                    const workflowType = this.props.workflowTypes.byId[workflowTypeId];
                                    return workflowType.name.toLocaleLowerCase().includes(this.state.entityTypeSearchText.toLocaleLowerCase())
                                });

                            if (flowchartContext.projects.length > 0) {
                                allowedWorkflowTypeIds = allowedWorkflowTypeIds.filter(workflowTypeId => {
                                    const workflowType = this.props.workflowTypes.byId[workflowTypeId];
                                    return flowchartContext.projects.includes(workflowType.project);
                                });
                            }

                            if (entityType) {
                                isValid = allowedWorkflowTypeIds.includes(entityType);
                            }

                            entityTypeSelectBox = <SelectBox theme="pink" selectionPromptText={entityType && this.props.workflowTypes.byId.hasOwnProperty(entityType) ? this.props.workflowTypes.byId[entityType].name : undefined}>
                                <DropDownList theme="pink" dismissAfterSelection={true}>
                                    {entityTypeSearchElement}
                                    {allowedWorkflowTypeIds.map(workflowTypeId => <ListItem theme="pink" name={this.props.workflowTypes.byId[workflowTypeId].name} key={workflowTypeId} value={workflowTypeId} onClick={entityTypeSelectCallback} />)}
                                </DropDownList>
                            </SelectBox>;


                            if (entityType && this.props.workflowTypes.byId.hasOwnProperty(entityType)) {

                                const workflowType = this.props.workflowTypes.byId[entityType];

                                const validStoringCustomFieldIds = workflowType.customFields
                                    .filter(customFieldId => {
                                        const customField = this.props.workflowTypes.customFields.byId[customFieldId];
                                        return customField.name.toLocaleLowerCase().includes(this.state.fieldSearchText.toLocaleLowerCase())
                                    })
                                    .filter(workflowFieldId => {
                                        const workflowField = this.props.workflowTypes.customFields.byId[workflowFieldId];
                                        return !workflowField.isComputed;
                                    });

                                if (isValid && this.props.storingCustomField) {
                                    isValid = validStoringCustomFieldIds.includes(this.props.storingCustomField);
                                }

                                customFieldsSelectBox = <SelectBox theme="pink" selectionPromptText={this.props.storingCustomField && this.props.workflowTypes.customFields.byId.hasOwnProperty(this.props.storingCustomField) ? this.props.workflowTypes.customFields.byId[this.props.storingCustomField].name : 'Select fields'}>
                                    <DropDownList theme="pink" dismissAfterSelection={true}>
                                        {fieldSearchElement}
                                        {validStoringCustomFieldIds.map(workflowFieldId => <ListItem theme="pink" name={this.props.workflowTypes.customFields.byId[workflowFieldId].name} key={workflowFieldId} value={workflowFieldId} onClick={this.props.setCustomField.bind(this, this.props.pieceId)} />)}
                                    </DropDownList>
                                </SelectBox>;
                            }
                            break;

                        default:
                            entityTypeSelectBox = undefined;
                    }

                    const isForSingleMember = this.props.selectedType === 'Workflow' && this.props.selectedEntityType && this.props.storingCustomField && this.props.workflowTypes.byId[this.props.selectedEntityType].affiliation === 'group' && this.props.workflowTypes.customFields.byId[this.props.storingCustomField].affiliation === 'member';

                    if (flowchartContext.highlightIncompletePieces) {
                        let isIncomplete = !this.props.variablePiece || !this.props.selectedType || !this.props.storingCustomField;

                        if (this.props.selectedType !== 'User') {
                            isIncomplete = isIncomplete || !this.props.selectedEntityType;
                        }

                        isValid = isValid && !isIncomplete;
                    }


                    this.updateValidity(isValid, flowchartContext);

                    return (
                        <StepPiece theme={isValid ? "pink" : "red"} {...this.props}>
                            <div className={styles.text}>store</div>
                            {this.props.dataVariablePiece ? this.props.dataVariablePiece : <Input minSize={12} placeholderText="Empty value" canReceiveDrag={this.props.isDragging && this.state.isHoveringOverDataVariablePiece && !!this.props.targetPiece} onMouseOver={this.handleHoverOverDataVariablePiece} onMouseOut={this.handleHoverOutOfDataVariablePiece} defaultText={this.props.textToStore} onChange={this.handleSetValueUpdate} />}
                            <div className={styles.text}>in</div>

                            {this.props.variablePiece ? this.props.variablePiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverVariablePiece && !!this.props.targetPiece} onMouseOver={this.handleHoverOverVariablePiece} onMouseOut={this.handleHoverOutOfVariablePiece} defaultText={this.props.textToStore} onChange={this.handleSetValueUpdate} placeholderText="Entity variable (e.g. the 'Workflow' variable)" minSize={44} />}
                            {this.props.selectedType && <div className={styles.text}>which is a {this.props.selectedType} of type</div>}
                            {this.props.selectedType && entityTypeSelectBox}
                            {customFieldsSelectBox && <div className={styles.text}>in the custom field:</div>}
                            {customFieldsSelectBox}

                            {isForSingleMember && <div className={styles.text}>for</div>}
                            {isForSingleMember && (this.props.memberVariable ? this.props.memberVariable : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverMemberPiece && !!this.props.targetPiece} isDisabled onMouseOver={this.handleHoverOverMemberPiece} onMouseOut={this.handleHoverOutOfMemberPiece} placeholderText="Member variable" minSize={15} />)}
                        </StepPiece>
                    )
                }
            }
        </FlowchartContext.Consumer>

    }
}

const StorePiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedStorePiece);

export default StorePiece;