import { ADD_WIDGET, UPDATE_WIDGET, DELETE_WIDGET, IUpdateableWidgetData, REGISTER_WIDGET_VARIABLE, UPDATE_WIDGET_START_PIECE, SET_ISOLATED_WIDGET_PIECE, REMOVE_ISOLATED_WIDGET_PIECE, AddWidgetAction, UpdateWidgetAction, DeleteWidgetAction, UpdateWidgetStartPieceAction, SetIsolatedWidgetPieceAction, RemoveIsolatedWidgetPieceAction, RegisterWidgetVariableAction, RegisterWidgetRichTextVariableAction, REGISTER_WIDGET_RICH_TEXT_VARIABLE, RemoveIsolatedWidgetRichTextPieceAction, REMOVE_ISOLATED_WIDGET_RICH_TEXT_PIECE, SetIsolatedWidgetRichTextPieceAction, SET_ISOLATED_WIDGET_RICH_TEXT_PIECE, UpdateWidgetRichTextStartPieceAction, UPDATE_WIDGET_RICH_TEXT_START_PIECE, UpdateWidgetCacheAction, WidgetData, UPDATE_WIDGET_CACHE, UpdateWidgetMessageCacheAction, UPDATE_WIDGET_MESSAGE_CACHE, ClearWidgetCacheAction, CLEAR_WIDGET_CACHE, MARK_PARTIAL_WIDGET_CACHE, UNMARK_PARTIAL_WIDGET_CACHE, MarkPartialWidgetCacheAction, UnMarkPartialWidgetCacheAction, UpdateWidgetTableCacheAction, UPDATE_WIDGET_TABLE_CACHE, IWidgetFilterState, SET_SELECTED_WIDGET_FILTER_OPTIONS, SetSelectedWidgetFilterOptions } from './types';
import moment from 'moment';
import { PiecePositionState, TableCell } from '../../helpers/common-types';

export function addWidget(payload: IUpdateableWidgetData): AddWidgetAction {
    const now = moment.utc().format();

    return {
        type: ADD_WIDGET,
        payload: {
            ...payload,
            createdTime: now,
            lastUpdatedTime: now,
            variables: [],
            isolatedPieces: [],
        },
    }
}

export function updateWidget(payload: IUpdateableWidgetData): UpdateWidgetAction {
    return {
        type: UPDATE_WIDGET,
        payload,
    }
}

export function deleteWidget(id: string): DeleteWidgetAction {
    return {
        type: DELETE_WIDGET,
        id,
    }
}

export function clearWidgetCache(widgetId?: string): ClearWidgetCacheAction {
    return {
        type: CLEAR_WIDGET_CACHE,
        widgetId,
    }
}

export function markPartialWidgetCache(widgetId: string): MarkPartialWidgetCacheAction {
    return {
        type: MARK_PARTIAL_WIDGET_CACHE,
        widgetId,
    }
}

export function unMarkPartialWidgetCache(widgetId?: string): UnMarkPartialWidgetCacheAction {
    return {
        type: UNMARK_PARTIAL_WIDGET_CACHE,
        widgetId,
    }
}

export function updateWidgetCache(id: string, widgetData: WidgetData): UpdateWidgetCacheAction {
    return {
        type: UPDATE_WIDGET_CACHE,
        widgetId: id,
        widgetData,
    };
}

export function updateWidgetMessageCache(id: string, message: string): UpdateWidgetMessageCacheAction {
    return {
        type: UPDATE_WIDGET_MESSAGE_CACHE,
        widgetId: id,
        message,
    };
}

export function updateWidgetTableCache(id: string, tableData: Array<Array<TableCell>>): UpdateWidgetTableCacheAction {
    return {
        type: UPDATE_WIDGET_TABLE_CACHE,
        widgetId: id,
        tableData,
    };
}

export function updateWidgetStartPiece(payload: PiecePositionState, widgetId: string): UpdateWidgetStartPieceAction {
    return {
        type: UPDATE_WIDGET_START_PIECE,
        payload,
        widgetId
    }
}

export function setIsolatedWidgetPiece(payload: PiecePositionState, widgetId: string): SetIsolatedWidgetPieceAction {
    return {
        type: SET_ISOLATED_WIDGET_PIECE,
        payload,
        widgetId
    }
}

export function removeIsolatedWidgetPiece(pieceId: string, widgetId: string): RemoveIsolatedWidgetPieceAction {
    return {
        type: REMOVE_ISOLATED_WIDGET_PIECE,
        pieceId,
        widgetId
    }
}

export function registerWidgetVariable(variableId: string, widgetId: string): RegisterWidgetVariableAction {
    return {
        type: REGISTER_WIDGET_VARIABLE,
        variableId,
        widgetId,
    }
}

export function updateWidgetRichTextStartPiece(payload: PiecePositionState, widgetId: string): UpdateWidgetRichTextStartPieceAction {
    return {
        type: UPDATE_WIDGET_RICH_TEXT_START_PIECE,
        payload,
        widgetId
    }
}

export function setIsolatedWidgetRichTextPiece(payload: PiecePositionState, widgetId: string): SetIsolatedWidgetRichTextPieceAction {
    return {
        type: SET_ISOLATED_WIDGET_RICH_TEXT_PIECE,
        payload,
        widgetId
    }
}

export function removeIsolatedWidgetRichTextPiece(pieceId: string, widgetId: string): RemoveIsolatedWidgetRichTextPieceAction {
    return {
        type: REMOVE_ISOLATED_WIDGET_RICH_TEXT_PIECE,
        pieceId,
        widgetId
    }
}

export function registerWidgetRichTextVariable(variableId: string, widgetId: string): RegisterWidgetRichTextVariableAction {
    return {
        type: REGISTER_WIDGET_RICH_TEXT_VARIABLE,
        variableId,
        widgetId,
    }
}

export function setSelectedWidgetFilterOptions(selectedOptions: IWidgetFilterState): SetSelectedWidgetFilterOptions {
    return {
        type: SET_SELECTED_WIDGET_FILTER_OPTIONS,
        payload: selectedOptions
    }
}