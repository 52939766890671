import moment from "moment";
import { getReadableDataForCustomField } from "../store/custom-fields";
import { CustomFieldValueType, FieldType } from "../store/custom-fields/types";
import { IGroup } from "../store/groups/types";
import { IUpdateableMemberData } from "../store/members/types";
import { ApplicationState } from "../store/types";
import { IUpdateableUserData } from "../store/users/types";
import { IWorkflow } from "../store/workflows/types";

export function getSearchStringsForUser(user: IUpdateableUserData, state: ApplicationState) {
    const searchStrings: Array<string> = [];

    const nameField = state.users.customFields.byId[state.users.nameFieldId];
    let nameValue: string | undefined;

    try {
        nameValue = getReadableDataForCustomField(user.customFields[nameField.id], nameField, user.id, 'user', state);
    } catch (e) {
        if (e instanceof Error) {
            console.error('Could not get name of user with ID: ' + user.id);
        }
    }

    if (nameValue) {
        searchStrings.push(nameValue);
    }

    const subTitleField = state.users.customFields.byId[state.users.subTitleFieldId];
    let subTitleValue: string | undefined;

    try {
        subTitleValue = getReadableDataForCustomField(user.customFields[subTitleField.id], subTitleField, user.id, 'user', state);
    } catch (e) {
        if (e instanceof Error) {
            console.error('Could not get subTitle of user with ID: ' + user.id);
        }
    }

    if (subTitleValue) {
        searchStrings.push(subTitleValue);
    }

    const projectNames = user.projects.filter(projectId => projectId in state.structure.projects.byId).map(projectId => state.structure.projects.byId[projectId].name);
    const levelNames = user.levels.filter(levelId => levelId in state.structure.levels.byId).map(levelId => state.structure.levels.byId[levelId].name);
    const roleNames = user.roles.filter(roleId => roleId in state.structure.roles.byId).map(roleId => state.structure.roles.byId[roleId].name);
    const locationNames = user.locations.filter(locationId => locationId in state.structure.locations.byId).map(locationId => state.structure.locations.byId[locationId].name);
    const phoneNumber = '+' + user.phone.countryCode + ' ' + user.phone.number;

    projectNames.forEach(name => searchStrings.push(name));
    levelNames.forEach(name => searchStrings.push(name));
    roleNames.forEach(name => searchStrings.push(name));
    locationNames.forEach(name => searchStrings.push(name));
    searchStrings.push(phoneNumber);

    const roles = user.roles.filter(roleId => roleId in state.structure.roles.byId).map(roleId => state.structure.roles.byId[roleId]);

    for (const role of roles) {
        const roleFields = role.customFields.filter(fieldId => {
            const customField = state.structure.roles.customFields.byId[fieldId];
            return customField.isInTable && (customField.type === FieldType.TEXT || customField.type === FieldType.DATE || customField.type === FieldType.NUMBER || customField.type === FieldType.PHONE || customField.type === FieldType.SINGLE_SELECT || customField.type === FieldType.MULTI_SELECT);
        });

        for (const customFieldId of roleFields) {
            const customField = state.structure.roles.customFields.byId[customFieldId];
            let fieldValue: string | undefined;

            try {
                fieldValue = getReadableDataForCustomField(user.customFields[customFieldId], customField, user.id, 'role', state);
            } catch (e) {
                if (e instanceof Error) {
                    console.error('Could not get ' + customField.name + ' value  of user with ID: ' + user.id);
                }
            }

            if (fieldValue) {
                searchStrings.push(fieldValue);
            }
        }
    }

    return searchStrings;
}

function doesMatchSearch(searchableString: string, targetSearchTerm: string) {
    return searchableString.replace(/\s\s+/g, ' ').toLocaleLowerCase().includes(targetSearchTerm);
}

export function doesMemberHaveSearchString(member: IUpdateableMemberData, state: ApplicationState, searchString: string) {

    const memberType = state.members.types.byId[member.type];

    if (!memberType) {
        console.error('Could not find member type for member with ID: ' + member.id);
        return false;
    }

    const nameField = state.members.types.customFields.byId[memberType.nameFieldId];
    let nameValue: string | undefined;

    try {
        nameValue = getReadableDataForCustomField(member.customFields[nameField.id], nameField, member.id, 'member', state);
    } catch (e) {
        if (e instanceof Error) {
            console.error('Could not get name of member with ID: ' + member.id);
        }
    }

    if (nameValue && doesMatchSearch(nameValue, searchString)) {
        return true;
    }

    const subTitleField = state.members.types.customFields.byId[memberType.subTitleFieldId];
    let subTitleValue: string | undefined;

    try {
        subTitleValue = getReadableDataForCustomField(member.customFields[subTitleField.id], subTitleField, member.id, 'member', state);
    } catch (e) {
        if (e instanceof Error) {
            console.error('Could not get subTitle of member with ID: ' + member.id);
        }
    }

    if (subTitleValue && doesMatchSearch(subTitleValue, searchString)) {
        return true;
    }

    const typeName = memberType.name;

    if (doesMatchSearch(typeName, searchString)) {
        return true;
    }

    const locationName = member.location in state.structure.locations.byId ? state.structure.locations.byId[member.location].name : undefined;

    if (locationName && doesMatchSearch(locationName, searchString)) {
        return true;
    }

    const typeFields = memberType.customFields
        .map(fieldId => state.members.types.customFields.byId[fieldId])
        .filter(customField => {
            return customField.isInTable && customField.id !== memberType.nameFieldId && customField.id !== memberType.subTitleFieldId &&
                (customField.type === FieldType.TEXT || customField.type === FieldType.DATE || customField.type === FieldType.NUMBER || customField.type === FieldType.PHONE || customField.type === FieldType.SINGLE_SELECT || customField.type === FieldType.MULTI_SELECT);
        });

    for (const customField of typeFields) {
        let fieldValue: string | undefined

        try {
            fieldValue = getReadableDataForCustomField(member.customFields[customField.id], customField, member.id, 'member', state);
        } catch (e) {
            if (e instanceof Error) {
                console.error('Could not get ' + customField.name + ' value  of member with ID: ' + member.id);
            }
        }

        if (fieldValue && doesMatchSearch(fieldValue, searchString)) {
            return true;
        }
    }

    return false;
}

export function getSearchStringsForMember(member: IUpdateableMemberData, state: ApplicationState) {
    const searchStrings: Array<string> = [];

    const memberType = state.members.types.byId[member.type];

    if (!memberType) {
        console.error('Could not find member type for member with ID: ' + member.id);
        return searchStrings;
    }

    const typeName = memberType.name;
    const locationName = member.location in state.structure.locations.byId ? state.structure.locations.byId[member.location].name : undefined;

    const nameField = state.members.types.customFields.byId[memberType.nameFieldId];
    let nameValue: string | undefined;

    try {
        nameValue = getReadableDataForCustomField(member.customFields[nameField.id], nameField, member.id, 'member', state);
    } catch (e) {
        if (e instanceof Error) {
            console.error('Could not get name of member with ID: ' + member.id);
        }
    }

    if (nameValue) {
        searchStrings.push(nameValue);
    }

    const subTitleField = state.members.types.customFields.byId[memberType.subTitleFieldId];
    let subTitleValue: string | undefined;

    try {
        subTitleValue = getReadableDataForCustomField(member.customFields[subTitleField.id], subTitleField, member.id, 'member', state);
    } catch (e) {
        if (e instanceof Error) {
            console.error('Could not get subTitle of member with ID: ' + member.id);
        }
    }

    if (subTitleValue) {
        searchStrings.push(subTitleValue);
    }

    searchStrings.push(typeName)

    if (locationName) {
        searchStrings.push(locationName);
    }

    const typeFields = memberType.customFields
        .map(fieldId => state.members.types.customFields.byId[fieldId])
        .filter(customField => {
            return customField.isInTable && customField.id !== memberType.nameFieldId && customField.id !== memberType.subTitleFieldId &&
                (customField.type === FieldType.TEXT || customField.type === FieldType.DATE || customField.type === FieldType.NUMBER || customField.type === FieldType.PHONE || customField.type === FieldType.SINGLE_SELECT || customField.type === FieldType.MULTI_SELECT);
        });

    for (const customField of typeFields) {
        let fieldValue: string | undefined

        try {
            fieldValue = getReadableDataForCustomField(member.customFields[customField.id], customField, member.id, 'member', state);
        } catch (e) {
            if (e instanceof Error) {
                console.error('Could not get ' + customField.name + ' value  of member with ID: ' + member.id);
            }
        }

        if (fieldValue) {
            searchStrings.push(fieldValue);
        }
    }

    return searchStrings;
}

export function getSearchStringsForGroup(group: IGroup, state: ApplicationState) {
    const searchStrings: Array<string> = [];

    const groupType = state.groups.types.byId[group.type];

    if (!groupType) {
        console.error('Could not find group type for group with ID: ' + group.id);
        return searchStrings;
    }

    const typeName = groupType.name;
    const locationName = group.location in state.structure.locations.byId ? state.structure.locations.byId[group.location].name : undefined;

    const nameField = state.groups.types.customFields.byId[groupType.nameFieldId];
    let nameValue: string | undefined;

    try {
        nameValue = getReadableDataForCustomField(group.customFields[nameField.id], nameField, group.id, 'group', state);
    } catch (e) {
        if (e instanceof Error) {
            console.error('Could not get name of group with ID: ' + group.id);
        }
    }

    if (nameValue) {
        searchStrings.push(nameValue);
    }

    const subTitleField = state.groups.types.customFields.byId[groupType.subTitleFieldId];
    let subTitleValue: string | undefined;

    try {
        subTitleValue = getReadableDataForCustomField(group.customFields[subTitleField.id], subTitleField, group.id, 'group', state);
    } catch (e) {
        if (e instanceof Error) {
            console.error('Could not get subTitle of group with ID: ' + group.id);
        }
    }

    if (subTitleValue) {
        searchStrings.push(subTitleValue);
    }

    searchStrings.push(typeName)

    if (locationName) {
        searchStrings.push(locationName);
    }

    const typeFields = groupType.customFields
        .map(fieldId => state.groups.types.customFields.byId[fieldId])
        .filter(customField => {
            return customField.isInTable && customField.id !== groupType.nameFieldId && customField.id !== groupType.subTitleFieldId &&
                (customField.type === FieldType.TEXT || customField.type === FieldType.DATE || customField.type === FieldType.NUMBER || customField.type === FieldType.PHONE || customField.type === FieldType.SINGLE_SELECT || customField.type === FieldType.MULTI_SELECT);
        });

    for (const customField of typeFields) {
        let fieldValue: string | undefined;

        try {
            fieldValue = getReadableDataForCustomField(group.customFields[customField.id], customField, group.id, 'group', state);
        } catch (e) {
            if (e instanceof Error) {
                console.error('Could not get ' + customField.name + ' value  of group with ID: ' + group.id);
            }
        }

        if (fieldValue) {
            searchStrings.push(fieldValue);
        }
    }

    return searchStrings;
}

export function getSearchStringsForWorkflow(workflow: IWorkflow, state: ApplicationState) {
    const searchStrings: Array<string> = [];

    const workflowType = state.workflows.types.byId[workflow.type];

    if (!workflowType) {
        console.error('Could not find workflow type for workflow with ID: ' + workflow.id);
        throw new Error('Could not find workflow type for workflow with ID: ' + workflow.id);
    }

    const statusName = workflow.status in state.workflows.types.statuses.byId ? state.workflows.types.statuses.byId[workflow.status].name : undefined;

    const typeName = workflowType.name;
    searchStrings.push(typeName);

    if (statusName) {
        searchStrings.push(statusName);
    }

    let readableDueDate: string | undefined

    try {
        readableDueDate = workflow.dueDate ? moment(workflow.dueDate).format('DD MMM YYYY').toLocaleLowerCase() : undefined;
    } catch (e) {
        console.error('Could not read due date of workflow with ID: ' + workflow.id);
    }

    if (readableDueDate) {
        searchStrings.push(readableDueDate);
    }

    let readableLastWorkedOn: string | undefined
    const workflowProcessState = workflow.historyIndex >= workflow.history.length ? workflow.history[workflow.history.length - 1] : workflow.history[workflow.historyIndex];

    try {
        readableLastWorkedOn = workflowProcessState.executionTime ? moment(workflowProcessState.executionTime).format('DD MMM YYYY, hh:mm:ss A').toLocaleLowerCase() : undefined;
    } catch (e) {
        console.error('Could not read last worked on date of workflow with ID: ' + workflow.id);
    }

    if (readableLastWorkedOn) {
        searchStrings.push(readableLastWorkedOn);
    }

    let affiliationName: string | undefined;

    const user = workflow.user in state.users.byId ? state.users.byId[workflow.user] : undefined;

    if (user) {
        const userNameField = state.users.customFields.byId[state.users.nameFieldId];
        let userName: string | undefined;

        try {
            userName = getReadableDataForCustomField(user.customFields[userNameField.id], userNameField, user.id, 'user', state);
        } catch (e) {
            if (e instanceof Error) {
                console.error('Could not get username of workflow with ID: ' + workflow.id);
            }
        }

        if (userName) {
            searchStrings.push(userName);
        }
    }

    let locationName: string | undefined;

    let subTitle: string | undefined;

    if (workflowType.subTitleFieldId) {
        const subtitleField = state.workflows.types.customFields.byId[workflowType.subTitleFieldId];

        try {
            subTitle = getReadableDataForCustomField(workflowProcessState.customFields[subtitleField.id] as CustomFieldValueType, subtitleField, workflow.id, 'workflow', state);
        } catch (e) {
            if (e instanceof Error) {
                console.error('Could not get ' + subtitleField.name + ' value  of workflow with ID: ' + workflow.id);
            }
        }

        if (subTitle) {
            searchStrings.push(subTitle);
        }
    }

    if (workflowType.affiliation === 'member') {
        const member = state.members.byId[workflow.affiliatedEntity];

        if (member) {
            const locationName = member.location in state.structure.locations.byId ? state.structure.locations.byId[member.location].name : undefined;

            if (locationName) {
                searchStrings.push(locationName);
            }

            const memberType = member.type in state.members.types.byId ? state.members.types.byId[member.type] : undefined;

            if (memberType) {
                const nameField = state.members.types.customFields.byId[memberType.nameFieldId];

                try {
                    affiliationName = getReadableDataForCustomField(member.customFields[nameField.id], nameField, member.id, 'member', state);
                } catch (e) {
                    console.error('Could not get member name of workflow with ID: ' + workflow.id);
                }

                if (affiliationName) {
                    searchStrings.push(affiliationName);
                }
            }
        }
    } else if (workflowType.affiliation === 'group') {
        const group = state.groups.byId[workflow.affiliatedEntity];

        if (group) {
            const locationName = group.location in state.structure.locations.byId ? state.structure.locations.byId[group.location].name : undefined;

            if (locationName) {
                searchStrings.push(locationName);
            }

            const groupType = group.type in state.groups.types.byId ? state.groups.types.byId[group.type] : undefined;

            if (groupType) {
                const nameField = state.groups.types.customFields.byId[groupType.nameFieldId];

                try {
                    affiliationName = getReadableDataForCustomField(group.customFields[nameField.id], nameField, group.id, 'group', state);
                } catch (e) {
                    console.error('Could not get group name of workflow with ID: ' + workflow.id);
                }

                if (affiliationName) {
                    searchStrings.push(affiliationName);
                }
            }
        }
    } else if (workflowType.affiliation === 'none') {
        if (user) {
            locationName = user.locations.map(locationId => locationId in state.structure.locations.byId ? state.structure.locations.byId[locationId].name : '').join(', ');
            searchStrings.push(locationName);
        }

    }

    const typeFields = workflowType.customFields
        .map(fieldId => state.workflows.types.customFields.byId[fieldId])
        .filter(customField => {
            return customField.isInTable && customField.id !== workflowType.subTitleFieldId && (workflowType.affiliation !== 'group' || customField.affiliation === 'group') &&
                (customField.type === FieldType.TEXT || customField.type === FieldType.DATE || customField.type === FieldType.NUMBER || customField.type === FieldType.PHONE || customField.type === FieldType.SINGLE_SELECT || customField.type === FieldType.MULTI_SELECT);
        });

    for (const customField of typeFields) {
        let fieldValue: string | undefined;

        try {
            fieldValue = getReadableDataForCustomField(workflowProcessState.customFields[customField.id] as CustomFieldValueType, customField, workflow.id, 'workflow', state);
        } catch (e) {
            if (e instanceof Error) {
                console.error('Could not get ' + customField.name + ' value  of workflow with ID: ' + workflow.id);
            }
        }

        if (fieldValue) {
            searchStrings.push(fieldValue);
        }
    }

    return searchStrings;
}