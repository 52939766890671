import React, { Component } from 'react';
import styles from './ListItem.module.scss';

import { ReactComponent as DeleteIcon } from '../../../assets/trash.svg';
import { ReactComponent as EditIcon } from '../../../assets/edit.svg';
import { ReactComponent as ChevronDownIcon } from '../../../assets/chevron-arrow-down.svg';

export type Option = {
    name: string,
    value: string
}


type ListItemProps = {
    theme?: 'indigo'|'aqua'|'pink'|'camo'|'orange'|'red',
    
    name: string,
    value?: string,
    detail?: string,

    isEditable?: boolean,
    isDeletable?: boolean,
    isExpandable?: boolean,

    onClick?: (value: string, detail?: string) => void,
}

type ListItemState = {

}

class ListItem extends Component<ListItemProps, ListItemState> {
    state = {
    };

    handleClick = () => {
        if (this.props.onClick) {
            if (typeof this.props.value !== 'undefined') {
                this.props.onClick(this.props.value, this.props.detail);
            } else {
                this.props.onClick(this.props.name, this.props.detail);
            }
        }
    }

    render() {
        let themeClass;
    
        switch(this.props.theme) {
            case 'indigo':
                themeClass = styles.indigoThemedEntry;
                break;
            case 'aqua':
                themeClass = styles.aquaThemedEntry;
                break;
            case 'pink':
                themeClass = styles.pinkThemedEntry;
                break;
            case 'camo':
                themeClass = styles.camoThemedEntry;
                break;
            case 'orange':
                themeClass = styles.orangeThemedEntry;
                break;
            case 'red':
                themeClass = styles.redThemedEntry;
                break;
            default:
                themeClass = styles.indigoThemedEntry;
                break;
        }

        return (
            <section className={themeClass} onClick={this.handleClick}>
                <div className={styles.name}><span title={this.props.name}>{this.props.name}</span></div>
                <div className={styles.detail}><span title={this.props.detail}>{this.props.detail}</span></div>
                <div className={styles.actions}>
                    {this.props.isEditable && <EditIcon />}
                    {this.props.isDeletable && <DeleteIcon />}
                    {this.props.isExpandable && <ChevronDownIcon />}
                </div>
            </section>
        )
    }
}

export default ListItem;