import React, { Component, ChangeEvent } from 'react';
import styles from './OrganizationProfile.module.scss';

import { ApplicationState } from '../../shared/store/types';
import { updateOrgProfile, wipeData } from '../../shared/store/organization/actions';
import { OrganizationState } from '../../shared/store/organization/types';
import { Permissions } from '../../shared/store/permissions/types';

import { ReactComponent as OrganizationIcon } from '../../common/assets/organisation.svg';
import { ReactComponent as EditIcon } from '../../common/assets/edit.svg';
import { ReactComponent as CheckIcon } from '../../assets/new-custom-icons/profile/check-mark.svg';
import { ReactComponent as CancelIcon } from '../../common/assets/close.svg';
import { ReactComponent as PinIcon } from '../../assets/new-custom-icons/profile/pin.svg';
import { ReactComponent as DBIcon } from '../../common/assets/db.svg';
import { ReactComponent as PinAddressIcon } from '../../common/assets/address.svg';
import { ReactComponent as LanguageIcon } from '../../common/assets/translate.svg';
import { ReactComponent as PhoneIcon } from '../../assets/new-custom-icons/profile/telephone.svg';
import { ReactComponent as GroupIcon } from '../../common/assets/group.svg';
import { ReactComponent as TimerIcon } from '../../common/assets/timer.svg';
import { ReactComponent as ContrastIcon } from '../../common/assets/contrast.svg';
import LogoImage from '../../common/images/sample-logo.png';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { translatePhrase } from '../../shared/helpers/translation';
import Button from '../../widgets/button/CommonButton';
import InputText from '../../widgets/form/InputText';
import { setEditingOrgState, setIsEditingOrgProfile } from '../../shared/store/my-data/actions';

const FILE_SIZE_LIMIT = [{
    name: '1 MB',
    value: '1'
}, {
    name: '5 MB',
    value: '5'
}, {
    name: '10 MB',
    value: '10'
}, {
    name: '20 MB',
    value: '20'
}, {
    name: '40 MB',
    value: '40'
}];

type OwnProps = {};
interface OwnState {
    locationKey: number,
    organizationKey: number,
    isGettingLocation: boolean
};

const mapStateToProps = (state: ApplicationState) => {
    const canWriteOrganizationProfile = state.permissions.myPermissions.general.OrganizationProfile === Permissions.WRITE;
    const canReadOrganizationProfile = canWriteOrganizationProfile || state.permissions.myPermissions.general.OrganizationProfile === Permissions.READ;

    return {
        baseLanguage: 'English',
        otherLanguages: state.internationalization.languages.allEntries.map(languageId => {
            const language = state.internationalization.languages.byId[languageId];
            let label: string;

            if (language.translatedName) {
                label = `${language.name} (${language.translatedName})`;
            } else {
                label = language.name;
            }

            return {
                id: language.id,
                name: label,
            };
        }),

        organization: state.organization,

        isEditingOrgProfile: !!state.myData.isEditingOrgProfile,
        editingOrgState: state.myData.editingOrgState,

        read: canReadOrganizationProfile,
        write: canWriteOrganizationProfile,
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;

function mapDispatchToProps(dispatch: Dispatch) {
    return {
        updateOrgData: (orgData: OrganizationState) => dispatch(updateOrgProfile(orgData)),
        setIsEditingOrgProfile: (isEditing: boolean) => dispatch(setIsEditingOrgProfile(isEditing)),
        setEditingOrgState: (orgState: OrganizationState) => dispatch(setEditingOrgState(orgState)),
        wipeData: () => dispatch(wipeData()),
    };
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

function hexToRgbA(hex: string) {
    var r = parseInt(hex.slice(1, 3), 16),
        g = parseInt(hex.slice(3, 5), 16),
        b = parseInt(hex.slice(5, 7), 16);

    return r + "," + g + "," + b;
}

const ColorThemes: Array<{ primary: string, contrast: string }> = [{
    primary: '#803f97',
    contrast: '#00ae8d'
}, {
    primary: '#00ae8d',
    contrast: '#803f97'
}, {
    primary: '#0066cc',
    contrast: '#cc0047'
}, {
    primary: '#cc0047',
    contrast: '#0066cc'
}, {
    primary: '#800000',
    contrast: '#ff8d00'
}, {
    primary: '#0066cc',
    contrast: '#ffbc00'
}, {
    primary: '#ffbc00',
    contrast: '#0066cc'
}, {
    primary: '#800000',
    contrast: '#1a8080',
}, {
    primary: '#1a8080',
    contrast: '#800000',
}];

class ConnectedOrganizationProfile extends Component<Props, OwnState> {

    state: OwnState = {
        locationKey: 0,
        organizationKey: 0,
        isGettingLocation: false
    }

    changePrimaryColor = (color: string) => {
        document.documentElement.style.setProperty('--primary-color', color);
        document.documentElement.style.setProperty('--primary-color-rgb', hexToRgbA(color));


        if (!this.props.editingOrgState) {
            return;
        }

        let updatedOrgData = {
            ...this.props.editingOrgState,
            primary_color: color,
            primary_gradient: ''
        };

        this.props.setEditingOrgState(updatedOrgData);
    }

    getLocation = () => {
        this.setState({ isGettingLocation: true });

        setTimeout(() => {
            const geolocation = navigator.geolocation;

            geolocation.getCurrentPosition((position) => {
                if (!this.props.editingOrgState) {
                    return;
                }

                let updatedOrganization = {
                    ...this.props.editingOrgState,
                    latitude: String(position.coords.latitude),
                    longitude: String(position.coords.longitude)
                };

                this.props.setEditingOrgState(updatedOrganization);

                this.setState({
                    isGettingLocation: false
                });
            });
        }, 1000);
    }

    changeName = (name: string) => {
        if (!this.props.editingOrgState) {
            return;
        }

        let updatedOrganization = {
            ...this.props.editingOrgState,
            name: name
        };

        this.props.setEditingOrgState(updatedOrganization);
    }

    changeCategory = (e: string) => {
        if (!this.props.editingOrgState) {
            return;
        }

        let updatedOrganization = {
            ...this.props.editingOrgState,
            category: e
        };

        this.props.setEditingOrgState(updatedOrganization);
    }

    changeLanguage = (e: string) => {
        if (!this.props.editingOrgState) {
            return;
        }

        let updatedOrganization = {
            ...this.props.editingOrgState,
            language: e
        };

        this.props.setEditingOrgState(updatedOrganization);
    }

    changeSize = (event: string) => {
        if (!this.props.editingOrgState) {
            return;
        }

        let updatedOrganization = {
            ...this.props.editingOrgState,
            size: event
        };

        this.props.setEditingOrgState(updatedOrganization);
    }

    changePhoneCountryCode = (event: string) => {
        if (!this.props.editingOrgState) {
            return;
        }

        let updatedOrganization = {
            ...this.props.editingOrgState,
            phoneCountryCode: event,
        };

        this.props.setEditingOrgState(updatedOrganization);
    }

    changePhoneNumber = (event: string) => {
        if (!this.props.editingOrgState) {
            return;
        }

        let updatedOrganization = {
            ...this.props.editingOrgState,
            phoneNumber: event,
        };

        this.props.setEditingOrgState(updatedOrganization);
    }

    changeTimeoutDuration = (event: number | string) => {
        if (!this.props.editingOrgState) {
            return;
        }

        let updatedOrganization = {
            ...this.props.editingOrgState,
            sessionTimeoutInMinutes: typeof event === 'string' ? parseInt(event) : event,
        };

        this.props.setEditingOrgState(updatedOrganization);
    }

    changeCompletedThreshold = (event: any) => {
        if (!this.props.editingOrgState) {
            return;
        }

        let updatedOrganization = {
            ...this.props.editingOrgState,
            completedThreshold: !event || event.trim() === '0' ? undefined : Number(event),
        };

        this.props.setEditingOrgState(updatedOrganization);
    }

    changeCompletedMemberThreshold = (event: any) => {
        if (!this.props.editingOrgState) {
            return;
        }

        let updatedOrganization = {
            ...this.props.editingOrgState,
            completedMemberThreshold: !event || event.trim() === '0' ? undefined : Number(event),
        };

        this.props.setEditingOrgState(updatedOrganization);
    }

    changeCompletedGroupThreshold = (event: any) => {
        if (!this.props.editingOrgState) {
            return;
        }

        let updatedOrganization = {
            ...this.props.editingOrgState,
            completedGroupThreshold: !event || event.trim() === '0' ? undefined : Number(event),
        };

        this.props.setEditingOrgState(updatedOrganization);
    }

    changeCompletedWorkflowThreshold = (event: any) => {
        if (!this.props.editingOrgState) {
            return;
        }

        let updatedOrganization = {
            ...this.props.editingOrgState,
            completedWorkflowThreshold: !event || event.trim() === '0' ? undefined : Number(event),
        };

        this.props.setEditingOrgState(updatedOrganization);
    }

    changeCompletedThresholdForSuperUser = (event: any) => {
        if (!this.props.editingOrgState) {
            return;
        }

        let updatedOrganization = {
            ...this.props.editingOrgState,
            completedThresholdForSuperUser: !event || event.trim() === '0' ? undefined : Number(event),
        };

        this.props.setEditingOrgState(updatedOrganization);
    }

    changeCompletedMemberThresholdForSuperUser = (event: any) => {
        if (!this.props.editingOrgState) {
            return;
        }

        let updatedOrganization = {
            ...this.props.editingOrgState,
            completedMemberThresholdForSuperUser: !event || event.trim() === '0' ? undefined : Number(event),
        };

        this.props.setEditingOrgState(updatedOrganization);
    }

    changeCompletedGroupThresholdForSuperUser = (event: any) => {
        if (!this.props.editingOrgState) {
            return;
        }

        let updatedOrganization = {
            ...this.props.editingOrgState,
            completedGroupThresholdForSuperUser: !event || event.trim() === '0' ? undefined : Number(event),
        };

        this.props.setEditingOrgState(updatedOrganization);
    }

    changeCompletedWorkflowThresholdForSuperUser = (event: any) => {
        if (!this.props.editingOrgState) {
            return;
        }

        let updatedOrganization = {
            ...this.props.editingOrgState,
            completedWorkflowThresholdForSuperUser: !event || event.trim() === '0' ? undefined : Number(event),
        };

        this.props.setEditingOrgState(updatedOrganization);
    }

    changeOrgFileSizeLimit = (limit: string) => {
        if (!this.props.editingOrgState) {
            return;
        }
        
        let updatedOrganization = {
            ...this.props.editingOrgState,
            fileSizeLimitInMB: Number(limit),
        };

        this.props.setEditingOrgState(updatedOrganization);
    }

    changeAddress = (event: any) => {
        if (!this.props.editingOrgState) {
            return;
        }

        let updatedOrganization = {
            ...this.props.editingOrgState,
            address: event
        };

        this.props.setEditingOrgState(updatedOrganization);
    }

    changeLatitude = (event: string) => {
        if (!this.props.editingOrgState) {
            return;
        }

        let updatedOrganization = {
            ...this.props.editingOrgState,
            latitude: event,
        };

        this.props.setEditingOrgState(updatedOrganization);
    }

    changeLongitude = (event: string) => {
        if (!this.props.editingOrgState) {
            return;
        }

        let updatedOrganization = {
            ...this.props.editingOrgState,
            longitude: event,
        };

        this.props.setEditingOrgState(updatedOrganization);
    }

    changeContrastColor = (color: string) => {
        if (!this.props.editingOrgState) {
            return;
        }

        document.documentElement.style.setProperty('--contrast-color', color);
        document.documentElement.style.setProperty('--contrast-color-rgb', hexToRgbA(color));

        let updatedOrganization = {
            ...this.props.editingOrgState,
            contrast_color: color
        };

        this.props.setEditingOrgState(updatedOrganization);
    }

    editOrgProfile = () => {
        const cleanCopy = JSON.parse(JSON.stringify(this.props.organization));

        this.props.setEditingOrgState(cleanCopy);
        this.props.setIsEditingOrgProfile(true);
    }

    saveOrgProfile = () => {
        if (!this.props.editingOrgState) {
            return;
        }

        this.props.setIsEditingOrgProfile(false);

        this.props.updateOrgData(this.props.editingOrgState);
    }

    cancelOrgProfile = () => {
        const newKey = this.state.organizationKey + 1;

        document.documentElement.style.setProperty('--primary-color', this.props.organization.primary_color);
        document.documentElement.style.setProperty('--primary-color-rgb', hexToRgbA(this.props.organization.primary_color));
        document.documentElement.style.setProperty('--primary-gradient', this.props.organization.primary_gradient);
        document.documentElement.style.setProperty('--contrast-color', this.props.organization.contrast_color);
        document.documentElement.style.setProperty('--contrast-color-rgb', hexToRgbA(this.props.organization.contrast_color));

        this.props.setIsEditingOrgProfile(false);

        this.setState({
            organizationKey: newKey,
        });
    }

    uploadImage = (e: ChangeEvent<HTMLInputElement>) => {
        this.readURL(e.target);
    }

    readURL(input: HTMLInputElement) {

        if (input.files && input.files[0]) {
            let reader = new FileReader();

            reader.onload = (e: ProgressEvent) => {
                if (!this.props.editingOrgState) {
                    return;
                }

                const base64EncodedImage = (e.target as any).result;
                let updatedOrganization = {
                    ...this.props.editingOrgState,
                    image: base64EncodedImage
                };

                this.props.setEditingOrgState(updatedOrganization);
            };

            reader.readAsDataURL(input.files[0]);
        }
    }

    wipeOrgData = () => {
        this.props.wipeData();
    }

    render() {
        const allColors = [{
            color: '#ff0000',
            lighterColor: '#ff4d4d'
        }, {
            color: '#00ff00',
            lighterColor: '#4dff4d'
        }, {
            color: '#0000ff',
            lighterColor: '#4d4dff'
        }];

        const allLanguages = [{
            value: '0',
            name: 'English',
        }]
            .concat(this.props.otherLanguages.map((language) => {
                return {
                    value: language.id,
                    name: language.name
                }
            }))

        const allCategories = [
            {
                name: 'Health',
                value: 'Health',
            },
            {
                name: 'Agriculture',
                value: 'Agriculture',
            },
            {
                name: 'Education',
                value: 'Education',
            },
            {
                name: 'Financial Inclusion',
                value: 'Financial Inclusion',
            },
            {
                name: 'WASH',
                value: 'WASH',
            },
            {
                name: 'Gender Based Violence',
                value: 'Gender Based Violence',
            },
            {
                name: 'Social Protection',
                value: 'Social Protection',
            },
        ].concat(this.props.organization.categories.map((category) => {
            return {
                name: category,
                value: category
            }
        }));

        const allSizes = [
            {
                name: '1 to 5',
                value: '1 to 5',
            },
            {
                name: '6 to 10',
                value: '6 to 10',
            },
            {
                name: '11 to 50',
                value: '11 to 50',
            },
            {
                name: '50+',
                value: '50+',
            },
        ];

        return (
            <section className={styles.ProfilePage} key={this.state.organizationKey}>
                <header className={styles.segmentHeader}>
                    <h2> <OrganizationIcon /> {translatePhrase('Organization Profile')} </h2>

                    {this.props.write && !this.props.isEditingOrgProfile &&
                        <div>
                            <Button icon={<EditIcon />} text={'Edit'} isRounded={true} type={'secondary'} size={'small'} padding={'0px 10px'} onClick={() => this.editOrgProfile()} />
                        </div>
                    }

                    {this.props.isEditingOrgProfile &&
                        <div>
                            <Button icon={<CheckIcon />} padding={'0px 10px'} text={'Done'} isRounded={true} type={'primary'} color={'contrast'} size={'small'} onClick={() => this.saveOrgProfile()} />

                            <Button icon={<CancelIcon />} padding={'0px 10px'} text={'Cancel'} isRounded={true} type={'secondary'} size={'small'} onClick={() => this.cancelOrgProfile()} />
                        </div>
                    }
                </header>

                {!this.props.isEditingOrgProfile && <section className={styles.profileContainer}>
                    <div className={styles.plan}>
                        <label> Current Plan: </label>
                        <span> Free Plan </span>
                    </div>

                    <div className={styles.orgDetails}>
                        {this.props.organization.image && <img src={this.props.organization.image} alt="org profile image" />}
                        {!this.props.organization.image && <img src={LogoImage} alt="org profile image" />}
                        <label> <span> {this.props.organization.name} </span> </label>
                        <label> Category: <span> {this.props.organization.category} </span> </label>
                        <label> Code: <span> {this.props.organization.code} </span> </label>
                    </div>

                    <ul className={styles.secondaryDetails}>
                        <li>
                            <div className={styles.icon}>
                                <LanguageIcon />
                            </div>
                            <div className={styles.data}>
                                <label> Current Language </label>
                                {this.props.organization.language === 'English' && <span> English </span>}
                                {this.props.organization.language !== 'English' && <span>
                                    {allLanguages.find(language => language.value === this.props.organization.language)?.name}
                                </span>}
                            </div>
                        </li>

                        <li>
                            <div className={styles.icon}>
                                <PinAddressIcon />
                            </div>
                            <div className={styles.data}>
                                <label> Address </label>
                                <span> {this.props.organization.address} </span>
                            </div>
                        </li>

                        <li>
                            <div className={styles.icon}>
                                <PhoneIcon />
                            </div>
                            <div className={styles.data}>
                                <label> Phone </label>
                                <span> {this.props.organization.phoneCountryCode} {this.props.organization.phoneNumber} </span>
                            </div>
                        </li>

                        <li>
                            <div className={styles.icon}>
                                <PinIcon />
                            </div>
                            <div className={styles.data}>
                                <label> Location </label>
                                <span> {String(this.props.organization.latitude) || ''}, {String(this.props.organization.longitude) || ''} </span>
                            </div>
                        </li>

                        <li>
                            <div className={styles.icon}>
                                <GroupIcon />
                            </div>
                            <div className={styles.data}>
                                <label> Team Size </label>
                                <span> {this.props.organization.size} </span>
                            </div>
                        </li>

                        <li>
                            <div className={styles.icon}>
                                <TimerIcon />
                            </div>
                            <div className={styles.data}>
                                <label> Session timeout (in min.) </label>
                                <span> {String(this.props.organization.sessionTimeoutInMinutes)} </span>
                            </div>
                        </li>

                        <li className={styles.cardInputHolder}>
                            <div className={styles.icon}>
                                <TimerIcon />
                            </div>
                            <div className={styles.data}>
                                <label> Member Threshold Days for entire org </label>
                                <span> {typeof this.props.organization.completedMemberThreshold === 'number' ? String(this.props.organization.completedMemberThreshold) : undefined} </span>
                            </div>
                        </li>

                        <li className={styles.cardInputHolder}>
                            <div className={styles.icon}>
                                <TimerIcon />
                            </div>
                            <div className={styles.data}>
                                <label> Group Threshold Days for entire org </label>
                                <span> {typeof this.props.organization.completedGroupThreshold === 'number' ? String(this.props.organization.completedGroupThreshold) : undefined} </span>
                            </div>
                        </li>

                        <li className={styles.cardInputHolder}>
                            <div className={styles.icon}>
                                <TimerIcon />
                            </div>
                            <div className={styles.data}>
                                <label> Workflow Threshold Days for entire org </label>
                                <span> {typeof this.props.organization.completedWorkflowThreshold === 'number' ? String(this.props.organization.completedWorkflowThreshold) : undefined} </span>
                            </div>
                        </li>

                        <li className={styles.cardInputHolder}>
                            <div className={styles.icon}>
                                <TimerIcon />
                            </div>
                            <div className={styles.data}>
                                <label> Member Threshold Days for Super User </label>
                                <span> {typeof this.props.organization.completedMemberThresholdForSuperUser === 'number' ? String(this.props.organization.completedMemberThresholdForSuperUser) : undefined} </span>
                            </div>
                        </li>

                        <li className={styles.cardInputHolder}>
                            <div className={styles.icon}>
                                <TimerIcon />
                            </div>
                            <div className={styles.data}>
                                <label> Group Threshold Days for Super User </label>
                                <span> {typeof this.props.organization.completedGroupThresholdForSuperUser === 'number' ? String(this.props.organization.completedGroupThresholdForSuperUser) : undefined} </span>
                            </div>
                        </li>

                        <li className={styles.cardInputHolder}>
                            <div className={styles.icon}>
                                <TimerIcon />
                            </div>
                            <div className={styles.data}>
                                <label> Workflow Threshold Days for Super User </label>
                                <span> {typeof this.props.organization.completedWorkflowThresholdForSuperUser === 'number' ? String(this.props.organization.completedWorkflowThresholdForSuperUser) : undefined} </span>
                            </div>
                        </li>

                        <li className={styles.cardInputHolder}>
                            <div className={styles.icon}>
                                <DBIcon />
                            </div>
                            <div className={styles.data}>
                                <label> Max File Size </label>
                                <span> {this.props.organization.fileSizeLimitInMB} MB </span>
                            </div>
                        </li>

                        <li>
                            <div className={styles.icon + ' ' + styles.pallete + ' ' + styles.primary} style={{ backgroundColor: this.props.organization.primary_color }}>
                                <ContrastIcon />
                            </div>
                            <div className={styles.data}>
                                <label> Primary Color </label>
                                <span> {this.props.organization.primary_color} </span>
                            </div>
                        </li>

                        <li>
                            <div className={styles.icon + ' ' + styles.pallete + ' ' + styles.secondary} style={{ backgroundColor: this.props.organization.contrast_color }}>
                                <ContrastIcon />
                            </div>
                            <div className={styles.data}>
                                <label> Contrast Color </label>
                                <span> {this.props.organization.contrast_color} </span>
                            </div>
                        </li>
                    </ul>
                </section>}

                {this.props.isEditingOrgProfile && this.props.editingOrgState && <section className={styles.editProfileForm}>
                    <div className={styles.profileImageInput}>
                        <input type="file" id="org-profile-img" onChange={this.uploadImage} />
                        {!this.props.editingOrgState.image && <img src={LogoImage} alt="org profile image" />}
                        {this.props.editingOrgState.image && <img src={this.props.editingOrgState.image} alt="" id="org-profile-image" />}
                        <label htmlFor="org-profile-img"> <Button text={'Upload'} type={'secondary'} isRounded={true} size={'small'} padding={'0px 10px'} /> </label>
                    </div>

                    <div className={styles.inputHolder}>
                        <label> {translatePhrase('Name')}: </label>
                        <InputText default={this.props.editingOrgState.name} onChange={(e) => this.changeName(e)} />
                    </div>

                    <div className={styles.inputHolder}>
                        <label> {translatePhrase('Category')}: </label>
                        <InputText default={this.props.editingOrgState.category} options={allCategories} onChange={(e) => this.changeCategory(e)} />
                    </div>

                    <div className={styles.inputHolder}>
                        <label> {translatePhrase('Code')}: </label>
                        <InputText isDisabled={true} default={this.props.editingOrgState.code} onChange={() => { }} />
                    </div>

                    <div className={styles.inputHolder}>
                        <label> {translatePhrase('Language')}: </label>
                        <InputText default={this.props.editingOrgState.language} options={allLanguages} onChange={(e) => this.changeLanguage(e)} />
                    </div>

                    <div className={styles.inputHolder}>
                        <label> {translatePhrase('Address')}: </label>
                        <InputText default={this.props.editingOrgState.address} onChange={(e) => this.changeAddress(e)} />
                    </div>

                    <div className={styles.phoneInput}>
                        <div className={styles.inputHolder}>
                            <label> {translatePhrase('Phone')}: </label>
                            <InputText
                                options={[{
                                    name: '+91',
                                    value: '+91'
                                }, {
                                    name: '+1',
                                    value: '+1'
                                }]}
                                default={this.props.editingOrgState.phoneCountryCode} onChange={(e) => this.changePhoneCountryCode(e)} />
                        </div>

                        <div className={styles.inputHolder}>
                            <InputText default={this.props.editingOrgState.phoneNumber} onChange={(e) => this.changePhoneNumber(e)} />
                        </div>
                    </div>

                    <div className={styles.locationInput}>
                        {!this.state.isGettingLocation && <div>
                            <div className={styles.inputHolder}>
                                <label> {translatePhrase('Location')}: </label>
                                <InputText default={this.props.editingOrgState.latitude} onChange={(e) => this.changeLatitude(e)} />
                            </div>
                            <div className={styles.inputHolder}>
                                <InputText default={this.props.editingOrgState.longitude} onChange={(e) => this.changeLongitude(e)} />
                            </div>
                        </div>}
                        {this.state.isGettingLocation && <div>
                            <p> Getting your location... </p>
                        </div>}
                        <Button icon={<PinIcon />} onClick={() => this.getLocation()} isRounded={true} type={'primary'} isBlock={false} />
                    </div>

                    <div className={styles.inputHolder}>
                        <label> {translatePhrase('Team Size')}: </label>
                        <InputText default={this.props.editingOrgState.size} options={allSizes} onChange={(e) => this.changeSize(e)} />
                    </div>

                    <div className={styles.inputHolder}>
                        <label> {translatePhrase('Session timeout (in min.)')}: </label>
                        <InputText type={'tel'} min={0} default={String(this.props.editingOrgState.sessionTimeoutInMinutes)} onChange={(e) => this.changeTimeoutDuration(e)} />
                    </div>

                    <div className={styles.inputHolder}>
                        <label> {translatePhrase('Member Threshold Days for entire org')}: </label>
                        <InputText type={'tel'} min={1} default={typeof this.props.editingOrgState.completedMemberThreshold === 'number' ? String(this.props.editingOrgState.completedMemberThreshold) : undefined} onChange={(e) => this.changeCompletedMemberThreshold(e)} />
                    </div>

                    <div className={styles.inputHolder}>
                        <label> {translatePhrase('Group Threshold Days for entire org')}: </label>
                        <InputText type={'tel'} min={1} default={typeof this.props.editingOrgState.completedGroupThreshold === 'number' ? String(this.props.editingOrgState.completedGroupThreshold) : undefined} onChange={(e) => this.changeCompletedGroupThreshold(e)} />
                    </div>

                    <div className={styles.inputHolder}>
                        <label> {translatePhrase('Workflow Threshold Days for entire org')}: </label>
                        <InputText type={'tel'} min={1} default={typeof this.props.editingOrgState.completedWorkflowThreshold === 'number' ? String(this.props.editingOrgState.completedWorkflowThreshold) : undefined} onChange={(e) => this.changeCompletedWorkflowThreshold(e)} />
                    </div>

                    <div className={styles.inputHolder}>
                        <label> {translatePhrase('Member Threshold Days For Super User')}: </label>
                        <InputText type={'tel'} min={1} default={typeof this.props.editingOrgState.completedMemberThresholdForSuperUser === 'number' ? String(this.props.editingOrgState.completedMemberThresholdForSuperUser) : undefined} onChange={(e) => this.changeCompletedMemberThresholdForSuperUser(e)} />
                    </div>

                    <div className={styles.inputHolder}>
                        <label> {translatePhrase('Group Threshold Days For Super User')}: </label>
                        <InputText type={'tel'} min={1} default={typeof this.props.editingOrgState.completedGroupThresholdForSuperUser === 'number' ? String(this.props.editingOrgState.completedGroupThresholdForSuperUser) : undefined} onChange={(e) => this.changeCompletedGroupThresholdForSuperUser(e)} />
                    </div>

                    <div className={styles.inputHolder}>
                        <label> {translatePhrase('Workflow Threshold Days For Super User')}: </label>
                        <InputText type={'tel'} min={1} default={typeof this.props.editingOrgState.completedWorkflowThresholdForSuperUser === 'number' ? String(this.props.editingOrgState.completedWorkflowThresholdForSuperUser) : undefined} onChange={(e) => this.changeCompletedWorkflowThresholdForSuperUser(e)} />
                    </div>

                    <div className={styles.inputHolder}>
                        <label> {translatePhrase('Max File Size')}: </label>
                        <InputText options={FILE_SIZE_LIMIT} default={this.props.organization.fileSizeLimitInMB?.toString()} onChange={(e) => this.changeOrgFileSizeLimit(e)} />
                    </div>


                    <div className={styles.inputHolder}>
                        <label> {translatePhrase('Pre-defined Themes')} ({translatePhrase('Optional')}) </label>

                        <ul className={styles.themeSet}>
                            {ColorThemes.map((theme) => <li
                                onClick={() => {
                                    this.changePrimaryColor(theme.primary);
                                    setTimeout(() => this.changeContrastColor(theme.contrast), 50);
                                }}
                                className={(this.props.editingOrgState && this.props.editingOrgState.primary_color === theme.primary && this.props.editingOrgState.contrast_color === theme.contrast) ? styles.active : ''}>
                                <span style={{ backgroundColor: theme.primary }}></span>
                                <span style={{ backgroundColor: theme.contrast }}></span>
                            </li>)}
                        </ul>
                    </div>

                    <div className={styles.inputHolder + ' ' + styles.colorPalette}>
                        <label> {translatePhrase('Primary Color')}: </label>
                        <InputText key={this.props.editingOrgState.primary_color} default={this.props.editingOrgState.primary_color} onChange={(e) => this.changePrimaryColor(e)} />
                        <div style={{ backgroundColor: this.props.editingOrgState.primary_color }} className={styles.colorSample + ' ' + styles.primary}></div>
                    </div>

                    <div className={styles.inputHolder + ' ' + styles.colorPalette}>
                        <label> {translatePhrase('Contrast Color')}: </label>
                        <InputText key={this.props.editingOrgState.contrast_color} default={this.props.editingOrgState.contrast_color} onChange={(e) => this.changeContrastColor(e)} />
                        <div style={{ backgroundColor: this.props.editingOrgState.contrast_color }} className={styles.colorSample + ' ' + styles.secondary}></div>
                    </div>
                </section>}

            </section>
        );
    }
}

const OrganizationProfile = connect(mapStateToProps, mapDispatchToProps)(ConnectedOrganizationProfile);

export default OrganizationProfile;
