import { openDB, IDBPDatabase } from 'idb';
import { DataToPush } from '../helpers/synchronize/types';
import * as uuid from 'uuid';
import moment from 'moment';
import { ApplicationState } from './types';
import { migrateDatabase } from './database-migrations';

interface PushedDataLog {
    id: string;
    timestamp: string;
    data: DataToPush;
};

export const databaseVersion = 11;

let diceflowDb: IDBPDatabase<ApplicationState>;

export async function getDICEflowDB() {
    if (!diceflowDb) {
        diceflowDb = await openDB('diceflow', databaseVersion, {
            upgrade(db, oldVersion, newVersion, transaction) {
                if (!db.objectStoreNames.contains('currentSnapshot')) {
                    db.createObjectStore('currentSnapshot');
                } else if (oldVersion !== newVersion) {
                    migrateDatabase(db, oldVersion, newVersion, transaction);
                }

                if (!db.objectStoreNames.contains('diceflowFiles')) {
                    db.createObjectStore('diceflowFiles');
                }
            }
        });
    }

    return diceflowDb;
}

export async function logDataPush(data: DataToPush) {
    const dataLog: PushedDataLog = {
        id: uuid.v4(),
        timestamp: moment().format(),
        data,
    };

    const database = await getDICEflowDB();
    database.add('pushedDataLog', dataLog, dataLog.id);
}

export async function getCurrentSnapshot(myId: string) {
    const database = await getDICEflowDB();
    const snapshot = await database.get('currentSnapshot', myId);

    return snapshot;
}

async function storeSnapshot(state: ApplicationState) {
    let myId = state.myData.id;

    if (typeof localStorage === 'undefined') {
        return;
    }

    if (myId === 'SuperUser') {
        const orgAdminData = localStorage.getItem('orgAdminIds')
        if (orgAdminData) {
            const orgAdminIds = JSON.parse(orgAdminData);
            myId = orgAdminIds[state.organization.code];
        }
    }

    if (!myId) {
        return;
    }

    const stateToStore: ApplicationState = {
        ...state,
        myData: {
            ...state.myData,
            isTopBarExpanded: false,
            isLoggingOut: false,
        },
    }

    const database = await getDICEflowDB();
    await database.put('currentSnapshot', stateToStore, myId);
}

let idleCallbackId: number | undefined;


export async function storeCurrentSnapshot(state: ApplicationState, isImmediate?: boolean) {

    if (typeof localStorage === 'undefined') {
        return;
    }

    const requestIdleCallback = (window as any).requestIdleCallback ? (window as any).requestIdleCallback : window.setTimeout;
    const cancelIdleCallback = (window as any).requestIdleCallback ? (window as any).cancelIdleCallback : window.clearTimeout;

    if (isImmediate) {

        if (typeof idleCallbackId !== 'undefined') {
            cancelIdleCallback(idleCallbackId);
            idleCallbackId = undefined;
        }

        await storeSnapshot(state);
    }

    if (typeof idleCallbackId !== 'undefined') {
        console.info('Already scheduled a snapshot store on idle');
        return;
    }


    idleCallbackId = requestIdleCallback(() => {
        idleCallbackId = undefined;
        storeSnapshot(state);
    });
}

export async function clearCurrentSnapshot(myId: string) {
    const database = await getDICEflowDB();
    await database.delete('currentSnapshot', myId);
};
