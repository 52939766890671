import { createContext } from "react";


export interface InvalidPieceDetails {
    pieceId: string;
    parentSplitPieces: Array<string>;
}

export enum PieceHighlightColour {
    GREEN,
    RED,
    YELLOW,
    PURPLE,
}

export interface PieceHighlight {
    [pieceId: string]: PieceHighlightColour,
}

export interface FlowchartInfoForPiece {
    flowchartHolderElement: HTMLElement | null;
    projects: Array<string>;
    variables: Array<string>;
    parentSplitPieceIds: Array<string>;
    parentIfPieceIndices: Array<number>;
    isValidating?: boolean;
    isReadonly?: boolean;
    highlightIncompletePieces?: boolean;
    invalidPieces?: Array<InvalidPieceDetails>;
    highlights?: PieceHighlight;
    setInvalidPiece?: (pieceId: string, parentSplitPieces: Array<string>) => void;
    removeInvalidPiece?: (pieceId: string) => void;
    searchTerm: string;
}

export const FlowchartContext = createContext<FlowchartInfoForPiece>({
    flowchartHolderElement: null,
    projects: [],
    variables: [],
    parentSplitPieceIds: [],
    parentIfPieceIndices: [],
    searchTerm: '',
});