import { NudgeData } from "./index";
import { ApplicationState } from "../../../shared/store/types";
import { NudgeType } from "../../../shared/store/my-data/types";
import { isUUID } from "../../../shared/helpers/utilities";

export function getStructureHierarchyNudges(applicationState: ApplicationState): Array<NudgeData> {

    let nudgesData: Array<NudgeData> = [];

    if (!isUUID(applicationState.myData.id)) {
        nudgesData = nudgesData.concat([{
            id: NudgeType.HIERARCHY_SET_UP_SAMPLE_PROJECT,
            header: 'Setup Sample Project',
            content: `One click to setup a Sample HEALTH project. Please note details of the project created.
                Hierarchy: Country > State > District > Tehsil > Village with a Role at each level
                Users: 3 users of Village level Health Worker, Tehsil level Health Nurse and District level Health Manager. Phone number to be used for login and password is 1234
                Locations added as per Hierarchy mentioned above: India > Karnataka > Bangalore Rural > Devanahalli > Attibele
                Members: 2 Sample Members added
                Workflows: Configured 1 workflow called New Visit Record
                Dashboard: Widget to show Patient Count and Vaccination Status added
                
                How to login and use the Desktop Application: https://support.diceflow.in/desktop-user-manual/logindashboard
                How to login and use the Mobile Application: https://support.diceflow.in/mobile-user-manual/install-mobile-application`,
        }]);
    }


    return nudgesData;
}