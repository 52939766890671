import React, { Component } from 'react';
import CardsList from '../../../widgets/card/CardsList';
import ModifyForm from '../../../widgets/card/ModifyForm';
import { IUpdateableGroupTypeActionData, INewGroupTypeActionData } from '../../../shared/store/groups/types/actions/types';
import { Option } from '../../flowchart/drop-down/ListItem';
import { IWorkflowType } from '../../../shared/store/workflows/types/types';
import { GroupTypeActionCard } from './GroupTypeActions';
import EnhancedInputText from '../../../widgets/form/InputText';

export interface OwnProps {
    selectedId?: string,
    parentId: string,
    heading: string,
    isReadOnly?: boolean,


    onSelectCard: (id: string) => void,
    onUnSelectCard: () => void,
    onGenerate?: () => void,
}

export interface StateProps {
    read: boolean,
    write: boolean,
    restrictStructureChanges: boolean,

    addWorkflowTypes: Array<IWorkflowType>,
    workflowTypes: Array<IWorkflowType>,
    cardsList: Array<GroupTypeActionCard>,
    selectedCard: GroupTypeActionCard | undefined,
    isSelectedCardRestricted: boolean,
}

export interface DispatchProps {
    reOrderCards: (sourceIndex: number, destinationIndex: number) => void,
    addCard: (payload: INewGroupTypeActionData) => void,
    deleteCard: (id: string) => void,
    updateCard: (payload: IUpdateableGroupTypeActionData) => void,
    setToastMessage: (message: string) => void,
}

type Props = OwnProps & StateProps & DispatchProps;

export interface OwnState {
    isShowingAddForm: boolean,
    isShowingModifyForm: boolean,
    modifyingCardName: string,
    modifyingCardIcon: string,
    modifyingCardWorkflowType: string,
};

class CardTreeLevel<TProps extends Props, TState extends OwnState> extends Component<TProps, TState> {

    handleSelectCard = (id: string) => {
        this.props.onSelectCard(id);
    }

    toggleModifyForm = () => {
        let toggledState = !this.state.isShowingAddForm;

        if (this.state.isShowingModifyForm) {
            this.setState({
                isShowingModifyForm: false,
                isShowingAddForm: false
            });
        } else {
            this.setState({
                isShowingAddForm: toggledState
            });
        }
    }

    editSelectedCard = () => {

        if (!this.props.selectedCard) {
            throw new Error('Cannot edit card since nothing is selected');
        }

        this.setState({
            isShowingModifyForm: true,
            modifyingCardName: this.props.selectedCard.name,
            modifyingCardIcon: this.props.selectedCard.icon,
            modifyingCardWorkflowType: this.props.selectedCard.workflowType || '',
        });
    }

    updateCardName = (value: string) => {
        this.setState({
            modifyingCardName: value
        });
    }

    updateCardIcon = (value: string) => {
        this.setState({
            modifyingCardIcon: value
        });
    }

    updateCardWorkflowType = (value: string) => {
        this.setState({
            modifyingCardWorkflowType: value
        });
    }

    addCard = () => {

        if (this.validateCard() !== true) {
            return;
        }

        if (this.state.modifyingCardWorkflowType) {
            this.props.setToastMessage('Please ensure that this workflow type has WRITE permissions');
        }

        this.props.addCard({
            name: this.state.modifyingCardName,
            icon: this.state.modifyingCardIcon,
            workflowType: this.state.modifyingCardWorkflowType ? this.state.modifyingCardWorkflowType : undefined,
        });

        this.setState({
            modifyingCardName: '',
            modifyingCardIcon: '',
            modifyingCardWorkflowType: '',
            isShowingAddForm: false
        });
    }

    updateCard = () => {

        if (!this.props.selectedCard) {
            return;
        }

        if (this.state.modifyingCardWorkflowType && this.props.selectedCard.workflowType !== this.state.modifyingCardWorkflowType) {
            this.props.setToastMessage('Please ensure that this workflow type has WRITE permissions');
        }

        this.props.updateCard({
            id: this.props.selectedCard.id,
            name: this.state.modifyingCardName,
            icon: this.state.modifyingCardIcon,
            workflowType: this.state.modifyingCardWorkflowType ? this.state.modifyingCardWorkflowType : undefined,
        });

        this.setState({
            isShowingModifyForm: false,
            modifyingCardName: '',
            modifyingCardIcon: '',
            modifyingCardWorkflowType: '',
            isShowingAddForm: false
        });
    }

    validateCard = () => {
        if (!this.state.modifyingCardName) {
            return 'Enter a valid name';
        }

        if (!this.state.modifyingCardIcon) {
            return 'Enter a valid icon';
        }

        return true;
    }

    render() {
        const icons: Array<Option> = [{
            name: 'Bar Chart',
            value: 'bar-chart',
        }, {
            name: 'Cancel',
            value: 'cancel',
        }, {
            name: 'Donut Chart',
            value: 'donut-chart',
        }, {
            name: 'Export',
            value: 'export',
        }, {
            name: 'Filter',
            value: 'filter',
        }, {
            name: 'Line Chart',
            value: 'line-chart',
        }, {
            name: 'Map Pin',
            value: 'map-pin',
        }, {
            name: 'Plus',
            value: 'plus',
        }, {
            name: 'Pencil',
            value: 'pencil',
        }, {
            name: 'Pencil',
            value: 'pencil',
        }, {
            name: 'Search',
            value: 'search',
        }, {
            name: 'Sort',
            value: 'sort',
        }, {
            name: 'Star',
            value: 'star',
        }, {
            name: 'Table',
            value: 'table-grid',
        }, {
            name: 'Trash',
            value: 'trash',
        }];

        const selectedIcon = this.props.selectedCard ? icons.find(action => this.props.selectedCard ? action.value === this.props.selectedCard.icon : undefined) : undefined;
        const selectedIconName = selectedIcon ? selectedIcon.name : undefined;

        let eligibleWorkflowTypes: Array<Option>;

        if (this.props.selectedId && this.props.selectedId === this.props.cardsList[0].id) {
            const defaultOption = {
                name: 'Default',
                value: '',
            };

            eligibleWorkflowTypes = this.props.addWorkflowTypes.map(workflowType => {
                return {
                    name: workflowType.name,
                    value: workflowType.id,
                };
            });

            eligibleWorkflowTypes = [defaultOption].concat(eligibleWorkflowTypes);
        } else {

            eligibleWorkflowTypes = this.props.workflowTypes.map(workflowType => {
                return {
                    name: workflowType.name,
                    value: workflowType.id,
                };
            });

            if (this.props.selectedId && (this.props.selectedId === this.props.cardsList[1].id || this.props.selectedId === this.props.cardsList[2].id)) {
                const defaultOption = {
                    name: 'Default',
                    value: '',
                };

                eligibleWorkflowTypes = [defaultOption].concat(eligibleWorkflowTypes);
            }

        }

        const selectedWorkflowType = this.props.selectedCard && this.props.selectedCard.workflowType ? eligibleWorkflowTypes.find(workflowType => this.props.selectedCard ? workflowType.value === this.props.selectedCard.workflowType : undefined) : undefined;
        let selectedWorkflowTypeName = selectedWorkflowType ? selectedWorkflowType.name : undefined;

        if (!selectedWorkflowTypeName && this.props.selectedId) {
            if (this.props.selectedId === this.props.cardsList[0].id || this.props.selectedId === this.props.cardsList[1].id || this.props.selectedId === this.props.cardsList[2].id) {
                selectedWorkflowTypeName = 'Default';
            }
        }

        const modifyForm = <ModifyForm hideCancel key={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.id : 0} isNew={!this.state.isShowingModifyForm} submitForm={this.state.isShowingModifyForm ? this.updateCard : this.addCard} cancelForm={this.toggleModifyForm} validateForm={this.validateCard}>
            <EnhancedInputText placeholder="Name" isDisabled={this.state.isShowingModifyForm && this.props.isSelectedCardRestricted} onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardName} default={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.name : ''} />
            <EnhancedInputText placeholder="Icon" isDisabled={this.state.isShowingModifyForm && this.props.isSelectedCardRestricted} onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardIcon} default={selectedIconName} options={icons} />
            <EnhancedInputText placeholder="Workflow Type" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardWorkflowType} default={selectedWorkflowTypeName} options={eligibleWorkflowTypes} />
        </ModifyForm>;

        return <CardsList
            heading={this.props.heading}
            cards={this.props.cardsList}
            selectedCard={this.props.selectedCard}
            onSelectCard={this.handleSelectCard}
            onUnselectCard={this.props.onUnSelectCard}
            onDeleteCard={this.props.deleteCard}
            onEditCard={this.editSelectedCard}
            onReorderCards={this.props.reOrderCards}
            modifyForm={modifyForm}
            isShowingAddForm={this.state.isShowingAddForm}
            isShowingEditForm={this.state.isShowingModifyForm}
            onAddCard={this.toggleModifyForm}
            isAddRestricted={true}
            isDeleteRestricted={this.props.restrictStructureChanges}
            isReadOnly={this.props.isReadOnly || !this.props.write}
            isCollapsible={true}
            showCardCount={true}
            onGenerateFlowchart={this.props.onGenerate}
        />
    }
}

export default CardTreeLevel;