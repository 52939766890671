import React, { Component } from 'react';
import styles from './MemberModify.module.scss';

import InputText from '../../../widgets/form/InputText';
import chevronIcon from '../../../assets/chevron-arrow-down.svg';
import { ReactComponent as CancelIcon } from '../../../common/assets/close.svg';
import { ReactComponent as PlusIcon } from '../../../assets/new-custom-icons/dashboard/plus.svg';
import { ReactComponent as CheckIcon } from '../../../assets/new-custom-icons/profile/check-mark.svg';

import { translatePhrase } from '../../../shared/helpers/translation';
import { FieldType } from '../../../shared/store/custom-fields/types';

import { ApplicationState } from '../../../shared/store/types';
import { IUpdateableMemberData } from '../../../shared/store/members/types';

import { connect } from 'react-redux';
import uuid from 'uuid';
import moment from 'moment';
import axios from 'axios';
import { ILocation } from '../../../shared/store/structure/location/types';
import { getAllLeafLocationsOfUser, getAncestorChainOfLocation } from '../../../shared/helpers/locations';
import { Permissions } from '../../../shared/store/permissions/types';
import { IWorkflowType } from '../../../shared/store/workflows/types/types';
import { IMemberTypeAction } from '../../../shared/store/members/types/actions/types';
import { Dispatch } from 'redux';
import { IUpdateableWorkflowData } from '../../../shared/store/workflows/types';
import { addWorkflow } from '../../../shared/store/workflows/actions';
import { withRouter, RouteComponentProps } from 'react-router';
import { isUUID } from '../../../shared/helpers/utilities';
import MultiSelectInputText from '../../../widgets/form/MultiSelectInput';
import CustomFieldInput from '../../../widgets/form/CustomField';
import Button from '../../../widgets/button/CommonButton';
import { BASE_URL } from '../../../shared/store/url';
import { getReadableDataForCustomField } from '../../../shared/store/custom-fields';
import DateInput from '../../../widgets/form/DateInput';
import { clearErrorMessage, setErrorMessage } from '../../../shared/store/my-data/actions';

type OwnProps = {
    memberId?: string,
    isReadOnly?: boolean,

    submit: (memberData: IUpdateableMemberData) => void,
    cancel: () => void,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    const allAllowedLocations = getAllLeafLocationsOfUser(state.myData.id);

    return {
        myId: state.myData.id,
        myPermissions: state.permissions.myPermissions,
        projectsData: state.structure.projects,
        usersData: state.users,
        member: ownProps.memberId ? state.members.byId[ownProps.memberId] : undefined,
        membersData: state.members,
        memberTypesData: state.members.types,
        groupData: state.groups,

        locationsData: state.structure.locations,

        customFieldsData: state.members.types.customFields,
        customFieldOptionsData: state.members.types.customFieldOptions,

        groupsData: state.groups,
        groupTypesData: state.groups.types,
        workflowsData: state.workflows,
        workflowTypesData: state.workflows.types,

        allowedData: {
            locations: allAllowedLocations,
        },

        applicationState: state,
        orgCode: state.organization.code,
    }
};

type StateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        addWorkflow: (workflowData: IUpdateableWorkflowData) => dispatch(addWorkflow(workflowData)),
        setErrorMessage: (message: string) => dispatch(setErrorMessage(message, true)),
        clearErrorMessage: () => dispatch(clearErrorMessage()),
    };
};

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps;

type OwnState = {
    memberData: IUpdateableMemberData,
    submitTimer: number | undefined,
    locationKey: number,
};

class ConnectedMemberModify extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        let memberData: IUpdateableMemberData;
        if (!props.member) {
            // This is a new member
            memberData = {
                id: uuid.v4(),
                type: '',
                location: '',
                groups: {},
                customFields: {},
            };
        } else {
            memberData = {
                id: props.member.id,
                type: props.member.type,
                location: props.member.location,
                groups: JSON.parse(JSON.stringify(props.member.groups)),
                customFields: JSON.parse(JSON.stringify(props.member.customFields)),
            }
        }

        this.props.clearErrorMessage();

        this.state = {
            memberData: memberData,
            submitTimer: undefined,
            locationKey: 0,
        };
    }

    changeType = (type: string) => {
        let updatedIUpdateableMemberData: IUpdateableMemberData = {
            ...this.state.memberData,
            type: type
        };

        this.setState({
            memberData: updatedIUpdateableMemberData
        });
    }

    changeLocation = (location: string) => {
        let updatedIUpdateableMemberData: IUpdateableMemberData = {
            ...this.state.memberData,
            location: location
        };

        this.setState({
            memberData: updatedIUpdateableMemberData
        });
    }

    changeGroup = (typeId: string, groupIds: Array<string> | undefined) => {
        let updatedIUpdateableMemberData: IUpdateableMemberData = {
            ...this.state.memberData,
            groups: {
                ...this.state.memberData.groups,
                [typeId]: groupIds || [],
            }
        };

        this.setState({
            memberData: updatedIUpdateableMemberData
        });
    }

    showErrorMessage = (message: string) => {
        let that = this;

        this.props.setErrorMessage(message);

        window.setTimeout(() => {
            that.props.clearErrorMessage();
        }, 3000);

    }

    preValidateIUpdateableMemberData = () => {
        if (!this.state.memberData.type) {
            return;
        }

        if (!this.state.memberData.location) {
            return;
        }

        const memberType = this.props.memberTypesData.byId[this.state.memberData.type];

        const groupTypeIdsInProject = this.props.groupTypesData.allEntries.filter(typeId => {
            const groupType = this.props.groupTypesData.byId[typeId];
            return groupType.project === memberType.project;
        })

        for (const typeId of groupTypeIdsInProject) {
            const groupType = this.props.groupTypesData.byId[typeId];


            if (memberType.project === groupType.project && groupType.isRequired) {
                if (!this.state.memberData.groups.hasOwnProperty(typeId)) {
                    return;
                }
            }
        }

        for (let i = 0; i < this.props.memberTypesData.byId[this.state.memberData.type].customFields.length; i += 1) {
            const fieldId = this.props.memberTypesData.byId[this.state.memberData.type].customFields[i];
            const field = this.props.customFieldsData.byId[fieldId];

            if (field.isComputed) {
                continue;
            }

            if (field.type === FieldType.MULTI_SELECT) {
                if (this.state.memberData.customFields[fieldId] && !Array.isArray(this.state.memberData.customFields[fieldId])) {
                    throw new Error('The value of a multi-select field must be an array');
                }
            } else {
                if (this.state.memberData.customFields[fieldId] && Array.isArray(this.state.memberData.customFields[fieldId])) {
                    throw new Error('The value of a non multi-select field must not be an array');
                }
            }

            if (field.type === FieldType.PHONE) {
                const phoneNumber = this.state.memberData.customFields[fieldId];

                if (!!phoneNumber) {
                    if (typeof phoneNumber !== 'string') {
                        throw new Error('A phone number must be a string');
                    }
                }
            }

            if (field.type === FieldType.LOCATION) {
                const location = this.state.memberData.customFields[fieldId];

                if (!!location) {
                    if (typeof location !== 'string') {
                        throw new Error('A phone number must be a string');
                    }

                    if (location.trim().split(' ').length !== 2) {
                        return;
                    }
                }
            }
        };

        return true;
    }

    validateIUpdateableMemberData = () => {

        if (!this.state.memberData.type) {
            this.showErrorMessage(translatePhrase('Enter valid:') + ' ' + translatePhrase('Type'));
            return;
        }

        if (!this.state.memberData.location) {
            this.showErrorMessage(translatePhrase('Enter valid:') + ' ' + translatePhrase('Location'));
            return;
        }

        if (this.state.memberData.location !== this.props.member?.location) {

            let hasGroupsInDifferentLocation = false;

            for (const groupTypeId in this.state.memberData.groups) {
                const groupsInDifferentLocation = this.state.memberData.groups[groupTypeId]?.filter(groupId => {
                    const group = this.props.groupData.byId[groupId];
                    return !group || group.location !== this.state.memberData.location;
                });

                if (!!groupsInDifferentLocation && groupsInDifferentLocation.length > 0) {
                    hasGroupsInDifferentLocation = true;
                    break;
                }
            }

            if (hasGroupsInDifferentLocation) {
                this.showErrorMessage('Cannot change location with member/group links');
                return;
            }
        }

        for (const typeId of this.props.groupTypesData.allEntries) {
            const groupType = this.props.groupTypesData.byId[typeId];
            const memberType = this.props.memberTypesData.byId[this.state.memberData.type];

            if (memberType.project === groupType.project && groupType.isRequired) {
                if (!this.state.memberData.groups.hasOwnProperty(typeId)) {
                    this.showErrorMessage(translatePhrase('Enter valid:') + ' ' + translatePhrase(groupType.name));
                    return;
                }

                if (this.state.memberData.groups[typeId].length === 0) {
                    this.showErrorMessage(translatePhrase('Enter valid:') + ' ' + translatePhrase(groupType.name));
                    return;
                }
            }
        }

        const memberType = this.props.memberTypesData.byId[this.state.memberData.type];

        const nameField = this.props.customFieldsData.byId[memberType.nameFieldId];

        if (!this.state.memberData.customFields[nameField.id]) {
            this.showErrorMessage(translatePhrase('Enter valid:') + ' ' + translatePhrase('Name'));
            return;
        }

        for (let i = 0; i < memberType.customFields.length; i += 1) {
            const fieldId = memberType.customFields[i];
            const field = this.props.customFieldsData.byId[fieldId];

            if (field.isComputed) {
                continue;
            }

            if (field.type === FieldType.MULTI_SELECT) {
                if (this.state.memberData.customFields[fieldId] && !Array.isArray(this.state.memberData.customFields[fieldId])) {
                    throw new Error('The value of a multi-select field must be an array');
                }
            } else {
                if (this.state.memberData.customFields[fieldId] && Array.isArray(this.state.memberData.customFields[fieldId])) {
                    throw new Error('The value of a non multi-select field must not be an array');
                }
            }

            if (field.type === FieldType.PHONE) {
                const phoneNumber = this.state.memberData.customFields[fieldId];

                if (!!phoneNumber) {
                    if (typeof phoneNumber !== 'string') {
                        throw new Error('A phone number must be a string');
                    }
                }
            }

            if (field.type === FieldType.LOCATION) {
                const location = this.state.memberData.customFields[fieldId];

                if (!!location) {
                    if (typeof location !== 'string') {
                        throw new Error('A phone number must be a string');
                    }

                    if (location.trim().split(' ').length !== 2) {
                        this.showErrorMessage(translatePhrase('Enter valid:') + ' ' + translatePhrase(field.name));
                        return;
                    }
                }
            }
        };

        return true;
    }

    submitMemberForm = async () => {
        if (this.validateIUpdateableMemberData()) {
            this.markForSubmit();
        }
    }

    markForSubmit = () => {
        let that = this;

        const timeout = window.setTimeout(function () {
            that.props.submit(that.state.memberData);
        }, 1000);

        this.setState({
            submitTimer: timeout
        });
    }

    changeCustomField = (fieldId: string, value: string | string[] | undefined | boolean) => {
        const field = this.props.customFieldsData.byId[fieldId];

        let updatedIUpdateableMemberData: IUpdateableMemberData = {
            ...this.state.memberData,
            customFields: {
                ...this.state.memberData.customFields,
                [fieldId]: field.type === FieldType.NUMBER ? Number(value) : value,
            }
        };

        this.setState({
            memberData: updatedIUpdateableMemberData
        });
    }

    fireWorkflow = (workflowType: IWorkflowType) => {
        const newWorkflowStatuses = workflowType.statuses.map(statusId => this.props.workflowTypesData.statuses.byId[statusId]).filter(workflowStatus => !workflowStatus.isTerminal);

        if (newWorkflowStatuses.length === 0) {
            throw new Error('A workflow of this type should have at least one non terminal status');
        }

        const dueInDays = newWorkflowStatuses[0].dueInDays || 7;

        const newWorkflowDueDate = moment().add(dueInDays, 'days').format('YYYY-MM-DD');

        const addWorkflowAffiliationValue = workflowType.affiliation === 'member' && this.props.memberId ? this.props.memberId : '';

        // The workflow can be added
        const addedWorkflowId = uuid.v4();

        this.props.addWorkflow({
            id: addedWorkflowId,
            type: workflowType.id,
            status: newWorkflowStatuses[0].id,
            dueDate: newWorkflowDueDate,
            affiliatedEntity: addWorkflowAffiliationValue,
            user: isUUID(this.props.myId) ? this.props.myId : this.props.usersData.allEntries[0],
        });

        this.props.history.push(`/workflow/${addedWorkflowId}/execute`);
    }

    getGroupFieldFromGroupTypeId = (typeId: string, locationChain: Array<string>) => {
        const groupType = this.props.groupTypesData.byId[typeId];

        const groupsList = this.props.groupsData.allEntries
            .filter(groupId => {

                const group = this.props.groupsData.byId[groupId];

                if (!group) {
                    return false;
                }

                if (group.type !== groupType.id) {
                    return false;
                }

                if (!locationChain.includes(group.location)) {
                    return false;
                }

                return true;
            })
            .map(groupId => {
                const group = this.props.groupData.byId[groupId];
                const groupType = this.props.groupData.types.byId[group.type];

                const nameField = this.props.groupData.types.customFields.byId[groupType.nameFieldId];
                const subTitleField = this.props.groupData.types.customFields.byId[groupType.subTitleFieldId];

                const groupName = getReadableDataForCustomField(group.customFields[groupType.nameFieldId], nameField, groupId, 'group', this.props.applicationState);
                const groupSubtitle = getReadableDataForCustomField(group.customFields[groupType.subTitleFieldId], subTitleField, groupId, 'group', this.props.applicationState);

                return {
                    name: `${groupName} (${groupSubtitle})`,
                    value: groupId,
                }
            });

        let validSelectedGroups: Array<string> = [];

        if (this.state.memberData.groups[typeId]) {
            validSelectedGroups = this.state.memberData.groups[typeId].filter(groupId => {
                const group = this.props.groupsData.byId[groupId];

                if (!group) {
                    return false;
                }

                if (!locationChain.includes(group.location)) {
                    return false;
                }

                return true;
            });
        }

        return <div key={typeId} className={styles.inputSegment}>
            <MultiSelectInputText
                isRequired={groupType.isRequired}
                options={groupsList}
                default={validSelectedGroups}
                onChange={this.changeGroup.bind(this, typeId)}
                placeholder={groupType.name}
                isAutoFocus={true}
            />
        </div>
    }

    componentWillUnmount(): void {
        this.props.cancel();
    }

    render() {

        let optionalGroupFields: Array<JSX.Element> = [];
        let requiredGroupFields: Array<JSX.Element> = [];

        if (this.state.memberData.type && this.state.memberData.location) {
            const memberType = this.props.memberTypesData.byId[this.state.memberData.type];
            const locationChain: Array<string> = [this.state.memberData.location];
            let parentId = this.props.locationsData.byId[this.state.memberData.location].parent || '';

            while (parentId in this.props.locationsData.byId) {
                locationChain.push(parentId);
                parentId = this.props.locationsData.byId[parentId].parent || '';
            }

            const requiredGroupTypeIds = this.props.groupTypesData.allEntries
                .filter(typeId => {
                    const groupType = this.props.groupTypesData.byId[typeId];

                    if (memberType.project !== groupType.project) {
                        return false;
                    }

                    return groupType.isRequired;
                });

            const optionalGroupTypeIds = this.props.groupTypesData.allEntries
                .filter(typeId => {
                    const groupType = this.props.groupTypesData.byId[typeId];

                    if (memberType.project !== groupType.project) {
                        return false;
                    }

                    return !groupType.isRequired;
                });

            requiredGroupFields = requiredGroupTypeIds.map(typeId => {
                return this.getGroupFieldFromGroupTypeId(typeId, locationChain)
            });

            optionalGroupFields = optionalGroupTypeIds.map(typeId => {
                return this.getGroupFieldFromGroupTypeId(typeId, locationChain)
            });
        }

        let customFields: Array<JSX.Element | undefined> = [];

        let nameField: JSX.Element | undefined;
        let subtitleField: JSX.Element | undefined;
        let lastSeenField: JSX.Element | undefined;

        if (this.state.memberData.type) {
            const memberType = this.props.memberTypesData.byId[this.state.memberData.type];

            customFields = this.props.memberTypesData.byId[this.state.memberData.type].customFields
                .filter(fieldId => {
                    return fieldId !== memberType.nameFieldId && fieldId !== memberType.subTitleFieldId && fieldId !== memberType.locationFieldId;
                })
                .map(fieldId => {
                    return <CustomFieldInput
                        entityType="member"
                        entity={this.state.memberData}
                        fieldId={fieldId}
                        customFieldsData={this.props.customFieldsData}
                        customFieldOptionsData={this.props.customFieldOptionsData}
                        changeField={this.changeCustomField}
                    />
                });

            nameField = <CustomFieldInput
                isRequired
                entityType="member"
                entity={this.state.memberData}
                fieldId={memberType.nameFieldId}
                customFieldsData={this.props.customFieldsData}
                customFieldOptionsData={this.props.customFieldOptionsData}
                changeField={this.changeCustomField}
            />

            subtitleField = <CustomFieldInput
                entityType="member"
                entity={this.state.memberData}
                fieldId={memberType.subTitleFieldId}
                customFieldsData={this.props.customFieldsData}
                customFieldOptionsData={this.props.customFieldOptionsData}
                changeField={this.changeCustomField}
            />

            lastSeenField = <CustomFieldInput
                entityType="member"
                entity={this.state.memberData}
                fieldId={memberType.locationFieldId}
                customFieldsData={this.props.customFieldsData}
                customFieldOptionsData={this.props.customFieldOptionsData}
                changeField={this.changeCustomField}
            />
        }

        const typesList = this.props.memberTypesData.allEntries
            .filter(typeId => {
                const memberType = this.props.memberTypesData.byId[typeId];
                const loggedInUser = this.props.myId ? this.props.usersData.byId[this.props.myId] : undefined;

                if (loggedInUser) {
                    return loggedInUser.projects.includes(memberType.project);
                } else {
                    return true;
                }
            })
            .filter(typeId => {
                return !this.props.myPermissions.members[typeId] || this.props.myPermissions.members[typeId] === Permissions.WRITE;
            })
            .map(typeId => {
                return {
                    name: this.props.memberTypesData.byId[typeId].name,
                    value: typeId,
                };
            });

        let locationsList = this.props.allowedData.locations
            .map(locationId => {
                let parentName = ''
                const location = this.props.locationsData.byId[locationId];

                if (location.parent) {
                    parentName = location.parent in this.props.locationsData.byId ? translatePhrase(this.props.locationsData.byId[location.parent].name) : translatePhrase(this.props.projectsData.byId[location.parent].name);
                }

                const parentLocationIds = getAncestorChainOfLocation(locationId);
                parentLocationIds.reverse();
                const locationChain = parentLocationIds
                    .filter(locationId => locationId in this.props.locationsData.byId)
                    .concat([locationId])
                    .map(locationId => this.props.locationsData.byId[locationId].name)
                    .join(' > ');

                return {
                    name: `${translatePhrase(location.name)} (${parentName})`,
                    description: locationChain,
                    value: locationId,
                };
            });

        if (this.state.memberData.type) {
            const memberType = this.props.memberTypesData.byId[this.state.memberData.type];
            const allLocationsInProject = this.props.locationsData.byProject[memberType.project];

            locationsList = locationsList.filter(location => {
                let topLocation: ILocation = this.props.locationsData.byId[location.value];

                while (topLocation.parent && topLocation.parent in this.props.locationsData.byId) {
                    topLocation = this.props.locationsData.byId[topLocation.parent];
                }

                return allLocationsInProject.includes(topLocation.id);
            });
        }

        const memberType = this.state.memberData.type ? this.props.memberTypesData.byId[this.state.memberData.type] : undefined;
        let workflowType: IWorkflowType | undefined;
        let isHandledByWorkflow = false;
        let hasPermissionsToCreateWorkflow = true;
        let isBlockedByOtherWorkflowInstance = false;

        if (memberType) {
            let action: IMemberTypeAction;
            if (this.props.memberId) {
                // This is an edit member form
                action = this.props.memberTypesData.actions.byId[memberType.actions[1]];
            } else {
                // This is an add member form
                action = this.props.memberTypesData.actions.byId[memberType.actions[0]];
            }

            isHandledByWorkflow = !!action.workflowType;

            if (action.workflowType) {
                workflowType = this.props.workflowTypesData.byId[action.workflowType];

                if (!workflowType.areMultipleInstancesAllowed && Array.isArray(workflowType.workflows)) {
                    for (const workflowOfTypeId of workflowType.workflows) {
                        if (workflowOfTypeId in this.props.workflowsData.byId) {
                            const workflowOfType = this.props.workflowsData.byId[workflowOfTypeId];
                            let isAffiliatedWithAnotherEntity = false;

                            if (workflowType.affiliation === 'none') {
                                isAffiliatedWithAnotherEntity = workflowOfType.user === this.props.myId;
                            } else if (workflowType.affiliation === 'member' && this.props.memberId) {
                                isAffiliatedWithAnotherEntity = workflowOfType.affiliatedEntity === this.props.memberId;
                            }

                            if (workflowOfType && !workflowOfType.archived && isAffiliatedWithAnotherEntity && !this.props.workflowTypesData.statuses.byId[workflowOfType.status].isTerminal) {
                                isBlockedByOtherWorkflowInstance = true;
                                break;
                            }
                        }
                    }
                }

                if (this.props.myPermissions.members[memberType.id] && this.props.myPermissions.members[memberType.id] !== Permissions.WRITE) {
                    hasPermissionsToCreateWorkflow = false;
                } else if (this.props.myPermissions.workflows[action.workflowType]) {
                    hasPermissionsToCreateWorkflow = this.props.myPermissions.workflows[action.workflowType] === Permissions.WRITE;
                }
            }
        }

        return (
            <section className={styles.commonModalHolder}>
                <section data-selector="member-upsert-form" data-member-id={this.props.memberId ? this.props.memberId : ''} className={this.props.isReadOnly ? styles.viewOnlyMember : styles.addOrModifyListCard}>
                    <header>
                        <h2>
                            {this.props.member ? this.props.isReadOnly ?
                                translatePhrase('View Member') : translatePhrase('Edit Member') : translatePhrase('Add Member')}
                        </h2>
                        <Button dataSelector="cancel-upsert-member" padding={'0px 10px'} size={'small'} isRounded onClick={this.props.cancel} icon={<CancelIcon />} text={this.props.isReadOnly ? translatePhrase('Close') : translatePhrase('Cancel')} />
                    </header>

                    <div className={styles.container}>
                        <div className={styles.allInputsHolder}>
                            <div className={styles.inputSegment}>
                                <InputText isRequired isDisabled={!!this.props.memberId && isHandledByWorkflow} placeholder={translatePhrase('Type')} icon={chevronIcon} default={this.props.member ? this.props.memberTypesData.byId[this.props.member.type].name : ''} options={typesList} onChange={this.changeType} isAutoFocus={true} />
                            </div>
                            {(this.props.isReadOnly || !isHandledByWorkflow) && this.state.memberData.type && <div className={styles.inputSegment} key={this.state.memberData.type}>
                                <InputText isRequired isDisabled={isUUID(this.props.myId) && !!this.props.memberId} placeholder={translatePhrase('Location')} icon={chevronIcon} default={this.props.member && this.props.member.location ? translatePhrase(this.props.locationsData.byId[this.props.member.location].name) : ''} options={locationsList} onChange={this.changeLocation} isAutoFocus={true} />
                            </div>}

                            {(this.props.isReadOnly || !isHandledByWorkflow) && nameField}
                            {(this.props.isReadOnly || !isHandledByWorkflow) && requiredGroupFields}

                            {(this.props.isReadOnly || !isHandledByWorkflow) && subtitleField}
                            {(this.props.isReadOnly || !isHandledByWorkflow) && lastSeenField}

                            {this.props.member && <div className={styles.inputSegment}>
                                <DateInput isDisabled placeholder={translatePhrase('Created time')} default={new Date(this.props.member.createdTime)} onChange={() => { }} />
                            </div>}
                            {this.props.member && <div className={styles.inputSegment}>
                                <DateInput isDisabled placeholder={translatePhrase('Last updated time')} default={new Date(this.props.member.lastUpdatedTime)} onChange={() => { }} />
                            </div>}

                            {(this.props.isReadOnly || !isHandledByWorkflow) && optionalGroupFields}
                            {(this.props.isReadOnly || !isHandledByWorkflow) && customFields}

                        </div>

                        {isHandledByWorkflow ?
                            hasPermissionsToCreateWorkflow ?
                                !isBlockedByOtherWorkflowInstance ?
                                    <section className={styles.message} >{`${translatePhrase('This is handled by the workflow type:')} ${workflowType ? translatePhrase(workflowType.name) : ''}. ${translatePhrase('Please start the workflow by clicking the button below')}`}</section>
                                    :
                                    <section className={styles.message} >{`${translatePhrase('This is handled by the workflow type:')} ${workflowType ? translatePhrase(workflowType.name) : ''}. ${translatePhrase('There is already another worklflow of the same type in progress')}`}</section>
                                :
                                <section className={styles.message} >{`${translatePhrase('This is handled by the workflow type:')}. ${translatePhrase('You do not have permissions to start a workflow of this type')}`}</section>
                            :
                            undefined
                        }

                    </div>

                    {isHandledByWorkflow && hasPermissionsToCreateWorkflow && !isBlockedByOtherWorkflowInstance &&
                        <div className={styles.buttonsHolder}>
                            <Button dataSelector="fire-workflow" isRounded={true} onClick={() => workflowType && this.fireWorkflow(workflowType)} text={translatePhrase('Fire Workflow')} color={'contrast'} padding={'0px 10px'} />
                        </div>}

                    {!isHandledByWorkflow && !this.props.isReadOnly && <div className={styles.buttonsHolder}>
                        {this.state.submitTimer ?

                            <Button padding={'0px 10px'} color={'contrast'} isRounded={true} icon={<CheckIcon />} text={this.props.member ? translatePhrase('Updated Member') : translatePhrase('Added Member')} /> :
                            <Button dataSelector="upsert-member" isDisabled={!this.preValidateIUpdateableMemberData()} padding={'0px 10px'} color={'contrast'} isRounded={true} onClick={this.submitMemberForm} icon={this.props.member ? <CheckIcon /> : <PlusIcon />} text={this.props.member ? translatePhrase('Update Member') : translatePhrase('Add Member')} />}
                    </div>}

                </section>

            </section>
        );
    }
}

const MemberModify = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectedMemberModify));

export default MemberModify;