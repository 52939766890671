import { Component, createRef, RefObject } from 'react';
import styles from './Dashboard.module.scss';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { deleteWidget, clearWidgetCache, updateWidgetCache, updateWidgetMessageCache, unMarkPartialWidgetCache, updateWidgetTableCache } from '../../shared/store/widgets/actions';

import { ApplicationState } from '../../shared/store/types';

import ModifyWidget from './ModifyWidget';

import { ReactComponent as DashboardIcon } from '../../common/assets/dashboard-line.svg';
import { ReactComponent as PlusIcon } from '../../assets/new-custom-icons/dashboard/plus.svg';
import { ReactComponent as LeftIcon } from '../../assets/chevron-arrow-down.svg';
import { ReactComponent as RefreshIcon } from '../../common/assets/refresh.svg';

import Widget from './Widget';
import { translatePhrase } from '../../shared/helpers/translation';
import { isUUID } from '../../shared/helpers/utilities';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import LoaderModal from '../../widgets/loader/LoaderModal';

import store from '../../shared/store/main';
import { WidgetData } from '../../shared/store/widgets/types';

import Button from '../../widgets/button/CommonButton';
import { getShowDataForWidget, getValueForMessageWidget, getValueForTableWidget } from '../../shared/helpers/widgets';
import { getVisibleUserIds, getVisibleMemberIds, getVisibleGroupIds, getVisibleWorkflowIds } from '../../shared/helpers/visible-entities';

import Tabs from '../../widgets/tabs/Tabs';
import { NudgeType } from '../../shared/store/my-data/types';
import { TableCell } from '../../shared/helpers/common-types';
import { Permissions } from '../../shared/store/permissions/types';
import { clearIndeterminateMessage, clearInfoMessage, reloadDashboardWidgets as completeDashboardReload, setIndeterminateMessage, setInfoMessage } from '../../shared/store/my-data/actions';


type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    let widgetIdsInRoles: Array<string> = [];
    const isOnline = state.myData.isOnline;
    const myRoles = state.users.byId.hasOwnProperty(state.myData.id) ? state.users.byId[state.myData.id].roles : [];
    const canEditDashboard = state.permissions.myPermissions.general.DashboardConfiguration === Permissions.WRITE;

    for (const roleId of myRoles) {
        const widgetIdsInRole = roleId && roleId in state.widgets.byRole ? state.widgets.byRole[roleId] : [];
        widgetIdsInRoles = widgetIdsInRoles.concat(widgetIdsInRole);
    }

    const newWidgetIds = Array.from(new Set(widgetIdsInRoles.filter(widgetId => !state.myData.widgets.myWidgets.includes(widgetId))));
    const myWidgetIds = Array.from(new Set(state.myData.widgets.myWidgets));

    const allWidgetIds = isUUID(state.myData.id) ? newWidgetIds.concat(myWidgetIds).filter(widgetId => widgetId in state.widgets.byId && !state.widgets.byId[widgetId].archived) : state.widgets.allEntries;

    const sortedWidgetIds = allWidgetIds
        .filter(widgetId => state.widgets.byId[widgetId])
        .sort((widgetIdA, widgetIdB) => {
            const widgetA = state.widgets.byId[widgetIdA];
            const widgetB = state.widgets.byId[widgetIdB];

            if (widgetA.displayType === 'message' && widgetB.displayType !== 'message') {
                return -1;
            } else if (widgetA.displayType !== 'message' && widgetB.displayType === 'message') {
                return 1;
            } else {
                return 0;
            }
        });

    const nonAggregratedWidgetIds = sortedWidgetIds.filter(widgetId => {

        const widget = state.widgets.byId[widgetId];
        let fields = widget.systemFields ? widget.systemFields : [];
        fields = fields.concat(widget.customFields);

        const isGlobalAggregation = widget.aggregation !== 'none' && fields.length === 1;

        return !widget.archived && !isGlobalAggregation;
    });

    const formattedTableWidgetIds = nonAggregratedWidgetIds.filter(widgetId => {
        const widget = state.widgets.byId[widgetId];
        return !widget.archived && widget.displayType === 'formatted-table'
    });

    const otherNonAggregatedWidgetIds = nonAggregratedWidgetIds.filter(widgetId => {
        const widget = state.widgets.byId[widgetId];
        return !widget.archived && widget.displayType !== 'formatted-table';
    });

    const aggregratedWidgetIds = sortedWidgetIds.filter(widgetId => {
        const widget = state.widgets.byId[widgetId];
        let fields = widget.systemFields ? widget.systemFields : [];
        fields = fields.concat(widget.customFields);

        const isGlobalAggregation = widget.aggregation !== 'none' && fields.length === 1;

        return !widget.archived && isGlobalAggregation;
    });

    const cachedWidgetIds = Object.keys(state.widgets.cachedWidgetData).filter(widgetId => allWidgetIds.includes(widgetId));
    const cachedMessageIds = Object.keys(state.widgets.cachedWidgetMessages).filter(widgetId => allWidgetIds.includes(widgetId));
    const cachedTableIds = Object.keys(state.widgets.cachedWidgetTableData).filter(widgetId => allWidgetIds.includes(widgetId));

    let totalEntitiesCount = 0;

    const noOfMemberIdsInState = Object.keys(state.members.byId).length;
    const noOfGroupIdsInState = Object.keys(state.groups.byId).length;
    const noOfWorkflowIdsInState = Object.keys(state.workflows.byId).length;

    if (typeof state.myData.totalNoOfMembers !== 'undefined') {
        totalEntitiesCount += state.myData.totalNoOfMembers >= noOfMemberIdsInState ? state.myData.totalNoOfMembers : noOfWorkflowIdsInState;
    }

    if (typeof state.myData.totalNoOfGroups !== 'undefined') {
        totalEntitiesCount += state.myData.totalNoOfGroups >= noOfGroupIdsInState ? state.myData.totalNoOfGroups : noOfWorkflowIdsInState;
    }

    if (typeof state.myData.totalNoOfWorkflows !== 'undefined') {
        totalEntitiesCount += state.myData.totalNoOfWorkflows >= noOfWorkflowIdsInState ? state.myData.totalNoOfWorkflows : noOfWorkflowIdsInState;
    }

    const currentEntitiesCount = Object.keys(state.members.byId).length + Object.keys(state.groups.byId).length + Object.keys(state.workflows.byId).length;

    return {
        selectedNudge: state.myData.selectedNudgeId,
        cachedWidgetCount: cachedWidgetIds.length + cachedMessageIds.length + cachedTableIds.length,
        applicationState: state,
        widgetsData: state.widgets,
        myWidgetsData: state.myData.widgets,
        myId: state.myData.id,
        isFetchingInactiveData: state.myData.isFetchingOlderData,
        isLoaded: state.myData.isLoaded,
        isPartiallyLoaded: state.myData.isPartiallyLoaded,
        isSettled: state.myData.isLoaded && !state.myData.isPushingData,
        isTopBarExpanded: state.myData.isTopBarExpanded,
        isOnline,
        canEditDashboard,

        shouldReloadDashboard: !!state.myData.shouldReloadDashboard,

        currentEntitiesCount,
        totalEntitiesCount: totalEntitiesCount,

        aggregratedWidgetIds,
        formattedTableWidgetIds,
        otherNonAggregatedWidgetIds,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        deleteWidget: (id: string) => dispatch(deleteWidget(id)),
        clearWidgetCache: (widgetId?: string) => dispatch(clearWidgetCache(widgetId)),
        updateWidgetCache: (id: string, widgetData: WidgetData) => dispatch(updateWidgetCache(id, widgetData)),
        updateWidgetMessageCache: (id: string, message: string) => dispatch(updateWidgetMessageCache(id, message)),
        updateWidgetTableCache: (id: string, tableData: Array<Array<TableCell>>) => dispatch(updateWidgetTableCache(id, tableData)),
        unMarkPartialWidgetCache: (id?: string) => dispatch(unMarkPartialWidgetCache(id)),
        completeDashboardReload: () => dispatch(completeDashboardReload()),

        setInfoMessage: (message: string, persistMessage?: boolean) => dispatch(setInfoMessage(message, persistMessage)),
        clearInfoMessage: () => dispatch(clearInfoMessage()),

        setIndeterminateMessage: (message: string) => dispatch(setIndeterminateMessage(message)),
        clearIndeterminateMessage: () => dispatch(clearIndeterminateMessage()),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
    otherNonAggregratedWidgets: Array<JSX.Element>,
    aggregatedWidgets: Array<JSX.Element>,
    formattedTableWidgets: Array<JSX.Element>,
    widgetsLoaded: boolean,
    showModifyWidget: boolean,
    selectedWidgetToModify: string,
    isPopulatingExport: boolean,
    isFinishedPopulatingExport: boolean,
    toastLoader: JSX.Element | undefined,
}

class ConnectedDashboard extends Component<Props, OwnState> {
    elementRef: RefObject<HTMLElement>;

    constructor(props: Props) {
        super(props);
        this.elementRef = createRef();
        this.state = {
            otherNonAggregratedWidgets: [],
            aggregatedWidgets: [],
            formattedTableWidgets: [],
            widgetsLoaded: false,
            showModifyWidget: false,
            selectedWidgetToModify: '',
            isPopulatingExport: false,
            isFinishedPopulatingExport: false,
            toastLoader: undefined
        };
    }

    showToastLoader = (text: string, isSuccess: boolean) => {
        this.setState({
            toastLoader: <LoaderModal loaderText={[text]} isSuccess={isSuccess} isError={!isSuccess} />
        });

        setTimeout(() => {
            this.setState({
                toastLoader: undefined
            });
        }, 4000);
    }

    modifyWidget = (id?: string) => {
        if (id) {
            this.setState({ selectedWidgetToModify: id, showModifyWidget: true });
        } else {
            this.setState({ showModifyWidget: true });
        }
    }

    closeWidget = () => {
        this.setState({ selectedWidgetToModify: '', showModifyWidget: false });
    }

    updateWidgetElements() {
        let otherNonAggregratedWidgets = this.props.otherNonAggregatedWidgetIds
            .filter(widgetId => widgetId in this.props.widgetsData.cachedWidgetData || widgetId in this.props.widgetsData.cachedWidgetMessages)
            .map(widgetId => this.getWidgetMarkup(widgetId, !this.props.canEditDashboard));

        let aggregatedWidgets = this.props.aggregratedWidgetIds
            .filter(widgetId => widgetId in this.props.widgetsData.cachedWidgetData || widgetId in this.props.widgetsData.cachedWidgetMessages)
            .map(widgetId => this.getWidgetMarkup(widgetId, !this.props.canEditDashboard));

        let formattedTableWidgets = this.props.formattedTableWidgetIds
            .map(widgetId => this.getWidgetMarkup(widgetId, !this.props.canEditDashboard));

        this.setState({
            otherNonAggregratedWidgets,
            aggregatedWidgets,
            formattedTableWidgets,
            widgetsLoaded: true,
        });
    }

    setIsPopulatingExport = (isPopulatingExport: boolean) => {
        const isFinishedPopulatingExport = this.state.isPopulatingExport && !isPopulatingExport;

        if (isFinishedPopulatingExport) {
            this.setState({
                isPopulatingExport,
                isFinishedPopulatingExport,
            });
        } else {
            this.setState({
                isPopulatingExport,
            })
        }
    }

    closeFinishedPopulatingExportModal = () => {
        this.setState({
            isFinishedPopulatingExport: false,
        })
    }

    updateWidgetCache = (widgetId: string, visibleUserIds: Array<string>, visibleMemberIds: Array<string>, visibleGroupIds: Array<string>, visibleWorkflowIds: Array<string>) => {
        const state = store.getState();
        const widget = state.widgets.byId[widgetId];

        if (widget.displayType === 'message') {
            const message = getValueForMessageWidget(widgetId, visibleUserIds, visibleMemberIds, visibleGroupIds, visibleWorkflowIds, state);

            if (message) {
                this.props.updateWidgetMessageCache(widgetId, message);
            }
        } else if (widget.displayType === 'formatted-table') {
            const tableData = getValueForTableWidget(widgetId, visibleUserIds, visibleMemberIds, visibleGroupIds, visibleWorkflowIds, state);

            if (tableData) {
                this.props.updateWidgetTableCache(widgetId, tableData);
            }
        } else {
            let runningSlice = getShowDataForWidget(widgetId, state, visibleUserIds, visibleMemberIds, visibleGroupIds, visibleWorkflowIds);
            const widgetData = runningSlice.widgetData;

            while (runningSlice.lastEntityId) {
                runningSlice = getShowDataForWidget(widgetId, state, visibleUserIds, visibleMemberIds, visibleGroupIds, visibleWorkflowIds, runningSlice.lastEntityId);

                widgetData.entries = widgetData.entries.concat(runningSlice.widgetData.entries);
                widgetData.entityIds = widgetData.entityIds.concat(runningSlice.widgetData.entityIds);

                for (let i = 0; i < runningSlice.widgetData.chartLabels.length; i += 1) {
                    const chartLabel = runningSlice.widgetData.chartLabels[i];
                    const chartData = runningSlice.widgetData.chartData[i];

                    const existingLabelIndex = widgetData.chartLabels.indexOf(chartLabel);

                    if (existingLabelIndex === -1) {
                        widgetData.chartLabels.push(chartLabel);
                        widgetData.chartData.push(chartData);
                    } else {
                        widgetData.chartData[existingLabelIndex] += chartData;
                    }
                }
            }

            this.props.updateWidgetCache(widgetId, widgetData);
        }
    }

    updateSingleWidgetCache = async (widgetId: string) => {
        this.props.setIndeterminateMessage(translatePhrase('Loading widgets') + '...');

        setTimeout(() => {
            const state = store.getState();
            const visibleUserIds = getVisibleUserIds(state, true);
            const visibleMemberIds = getVisibleMemberIds(state, true);
            const visibleGroupIds = getVisibleGroupIds(state, true);
            const visibleWorkflowIds = getVisibleWorkflowIds(state, visibleUserIds, visibleMemberIds, visibleGroupIds, true);

            this.updateWidgetCache(widgetId, visibleUserIds, visibleMemberIds, visibleGroupIds, visibleWorkflowIds);
            this.props.unMarkPartialWidgetCache(widgetId);
            this.props.clearIndeterminateMessage();
        }, 1000);
    }

    updateAllWidgetCaches = async () => {
        this.props.clearWidgetCache();

        this.props.setIndeterminateMessage(translatePhrase('Loading widgets') + '...');

        const state = store.getState();
        let widgetIdsInRoles: Array<string> = [];

        const myRoles = state.users.byId.hasOwnProperty(state.myData.id) ? state.users.byId[state.myData.id].roles : [];

        for (const roleId of myRoles) {
            const widgetIdsInRole = roleId && roleId in state.widgets.byRole ? state.widgets.byRole[roleId] : [];
            widgetIdsInRoles = widgetIdsInRoles.concat(widgetIdsInRole);
        }

        const newWidgetIds = Array.from(new Set(widgetIdsInRoles.filter(widgetId => !state.myData.widgets.myWidgets.includes(widgetId))));
        const myWidgetIds = Array.from(new Set(state.myData.widgets.myWidgets));

        const allWidgetIds = isUUID(this.props.myId) ? newWidgetIds.concat(myWidgetIds).filter(widgetId => widgetId in state.widgets.byId && !state.widgets.byId[widgetId].archived) : this.props.widgetsData.allEntries;

        setTimeout(() => {
            const visibleUserIds = getVisibleUserIds(state, true);
            const visibleMemberIds = getVisibleMemberIds(state, true);
            const visibleGroupIds = getVisibleGroupIds(state, true);
            const visibleWorkflowIds = getVisibleWorkflowIds(state, visibleUserIds, visibleMemberIds, visibleGroupIds, true);

            for (const widgetId of allWidgetIds) {
                this.updateWidgetCache(widgetId, visibleUserIds, visibleMemberIds, visibleGroupIds, visibleWorkflowIds);
            }

            this.props.unMarkPartialWidgetCache();

            this.props.clearIndeterminateMessage();

            this.props.completeDashboardReload();
        }, 1000);
    }

    componentDidMount() {
        this.updateWidgetElements();

        if (this.props.shouldReloadDashboard) {
            this.props.setInfoMessage(translatePhrase('Reload all widgets'));

            setTimeout(() => {
                this.props.clearInfoMessage();
            }, 4000);
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.isSettled && prevProps.isSettled !== this.props.isSettled) {
            this.updateWidgetElements();

            if (this.props.shouldReloadDashboard) {
                this.props.setInfoMessage(translatePhrase('Reload all widgets'));

                setTimeout(() => {
                    this.props.clearInfoMessage();
                }, 4000);
            }
        }

        if (this.props.cachedWidgetCount !== prevProps.cachedWidgetCount) {
            this.updateWidgetElements();
        }

        if (
            (this.props.aggregratedWidgetIds.length !== prevProps.aggregratedWidgetIds.length) ||
            (this.props.formattedTableWidgetIds.length !== prevProps.formattedTableWidgetIds.length) ||
            (this.props.otherNonAggregatedWidgetIds.length !== prevProps.otherNonAggregatedWidgetIds.length)
        ) {
            this.updateWidgetElements();
        }

        if (!this.props.isFetchingInactiveData && prevProps.isFetchingInactiveData) {
            this.updateAllWidgetCaches();
        }
    }

    getWidgetMarkup(widgetId: string, isReadOnly: boolean) {

        return <div className={styles.showDataHolder} key={widgetId}>
            <Widget
                isReadOnly={isReadOnly}
                widgetId={widgetId}
                deleteWidget={this.props.deleteWidget}
                modifyWidget={this.modifyWidget}
                setIsPopulatingExport={this.setIsPopulatingExport}
                updateWidgetCache={this.updateSingleWidgetCache.bind(this, widgetId)}
                setToastLoader={(text, flag) => this.showToastLoader(text, flag)}
                isDashboardWidget={true}
            />
        </div>
    }

    render() {

        const responsive = {
            desktop: {
                breakpoint: { max: 3000, min: 1023 },
                items: 4,
            }
        };

        let RefCarousel: any = null;

        return (<section ref={this.elementRef} className={styles.FocusSpace + ' ' + (this.state.showModifyWidget ? styles.noScroll : '')}>

            {this.state.toastLoader}

            {this.state.showModifyWidget && <ModifyWidget modifyingWidgetId={this.state.selectedWidgetToModify} closeWidgetModal={this.closeWidget} />}

            {this.state.isPopulatingExport && <LoaderModal
                loaderText={[(translatePhrase('Populating export data') + '...')]}
            />}

            {this.state.isFinishedPopulatingExport && <LoaderModal
                loaderText={[(translatePhrase('Export ready'))]}
                isSuccess
                isOutsideClickable
                closeModal={this.closeFinishedPopulatingExportModal}
            />}

            <header className={styles.pageHeader}>
                <h2 className={styles.heading}> <DashboardIcon />  {translatePhrase('Dashboard')} </h2>

                {(!this.props.isLoaded || this.props.isFetchingInactiveData) && this.props.currentEntitiesCount !== this.props.totalEntitiesCount && <LoaderModal
                    loaderText={[translatePhrase('Fetching your data') + '...']}
                    progress={Number(((this.props.currentEntitiesCount / this.props.totalEntitiesCount) * 100).toFixed(2))}
                    isNotAllowedToCloseManually
                />}

                <Tabs></Tabs>
            </header>
            <section className={styles.widgets + ' ' + (this.state.showModifyWidget ? styles.blurrify : '')}>

                {!this.props.isOnline && <div className={styles.pageButtons}>

                    <div className={styles.reloadButtonHolder + ' ' + (this.props.isTopBarExpanded ? styles.moveRight : '')}>
                        <Button dataSelector="dashboard-reload-all-widgets" isHighlighted={this.props.selectedNudge === NudgeType.DASHBOARD_RELOAD_ALL || this.props.shouldReloadDashboard} title={translatePhrase('Reload all widgets')} icon={<RefreshIcon />}
                            size={'large'} isBlock={false} isRounded={true} type={'secondary'} onClick={() => this.updateAllWidgetCaches()} />
                    </div>

                    {(!isUUID(this.props.myId) || this.props.canEditDashboard) &&
                        <Button dataSelector="dashboard-add-new-widget" isHighlighted={this.props.selectedNudge === NudgeType.DASHBOARD_ADD_NEW} icon={<PlusIcon />} onClick={() => this.modifyWidget()}
                            text={translatePhrase('Add Widget')} padding={'0px 15px'} isBlock={false}
                            isRounded={true} color={'contrast'} size={'small'} />
                    }
                </div>}

                {this.props.isOnline && <div className={styles.onlineMessage}>
                    <span>Dashboard is not available when online</span>
                </div>}

                {!this.props.isOnline && <div className={styles.widgetsDataContainer}>
                    {this.state.aggregatedWidgets.length > 0 && <div className={styles.carouselContainer}>
                        <Carousel
                            key={this.props.isTopBarExpanded ? 1 : 0}
                            ref={(el) => (RefCarousel = el)}
                            showDots={false}
                            responsive={responsive}
                            sliderClass={styles.reactCarousel}
                            arrows={false}
                            infinite={true}>
                            {this.state.aggregatedWidgets}
                        </Carousel>
                    </div>}

                    {this.state.aggregatedWidgets.length > 4 && <div className={styles.actionButtons}>
                        <Button icon={<LeftIcon />}
                            isRounded={true}
                            size={'small'}
                            onClick={() => {
                                RefCarousel.previous()
                            }} />

                        <Button
                            icon={<LeftIcon />}
                            size={'small'}
                            isRounded={true}
                            onClick={() => {
                                RefCarousel.next()
                            }} />
                    </div>}

                    <div className={styles.widgetsContainer}>
                        {this.state.formattedTableWidgets}
                        {this.state.otherNonAggregratedWidgets}
                    </div>
                </div>}
            </section>

        </section>);
    }
}

const Dashboard = connect(mapStateToProps, mapDispatchToProps)(ConnectedDashboard);

export default Dashboard;