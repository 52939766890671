import { ChangeEvent, FC, useState } from 'react';
import styles from './Configuration.module.scss';
import { Link, Redirect } from "react-router-dom";

import ModifyForm from '../../../widgets/card/ModifyForm';
import { Permissions } from '../../../shared/store/permissions/types';

import { translatePhrase } from '../../../shared/helpers/translation';

import { selectWorkflowType, unSelectWorkflowType, addWorkflowTypeCustomField, addWorkflowTypeCustomFieldOption } from '../../../shared/store/workflows/types/actions';
import { addStatus, selectStatus, unSelectStatus } from '../../../shared/store/workflows/types/statuses/actions';
import { ReactComponent as DuplicateIcon } from '../../../assets/new-custom-icons/common/duplicate.svg';
import { ReactComponent as CancelIcon } from '../../../common/assets/close.svg';
import { ReactComponent as EditIcon } from '../../../assets/new-custom-icons/common/edit.svg';
import { ReactComponent as ExportIcon } from '../../../assets/new-custom-icons/common/export.svg';
import { ReactComponent as ImportIcon } from '../../../assets/new-custom-icons/common/import.svg';
import { ReactComponent as FlowchartIcon } from '../../../assets/new-custom-icons/new-revision/flow-data.svg';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { saveAs } from 'file-saver';

import WorkflowTypeCustomFieldVertical from './WorkflowTypeCustomFieldVertical';
import WorkflowTypesList from './WorkflowTypesList';
import StatusesList from './WorkflowTypeStatuses';

import { ApplicationState } from '../../../shared/store/types';
import uuid from 'uuid';
import { duplicateWorkflowType, exportCustomFields, ExportedGroupType, ExportedMemberType, ExportedWorkflowType, exportWorkflowTypeCustomFields, ExportWorkflowTypeData } from '../../../shared/helpers/duplicate';
import draftToHtml from 'draftjs-to-html';
import ReactHtmlParser from 'react-html-parser';
import { addFullPiece, setNextPiece, showRichTextEditor } from '../../../shared/store/flowchart/pieces/actions';
import LoaderModal from '../../../widgets/loader/LoaderModal';
import { AllPieceTypes, IAnswerPiece, ICustomFieldPiece, IEndPiece, IErrorPiece, IGreaterThanPiece, IGroupedQuestionPiece, IGroupPiece, IHexagonalTruePiece, ILengthPiece, ILesserThanPiece, ISplitPiece, IStartPiece, ITodayPiece, PieceType } from '../../../shared/store/flowchart/pieces/types';
import { NormalizedModel } from '../../../shared/store/normalized-model';
import { duplicatePiece } from '../../../shared/store/flowchart/helpers/pieces';
import { IVariable, VariableType } from '../../../shared/store/flowchart/variables/types';
import { isUUID } from '../../../shared/helpers/utilities';
import { importWorkflowTypeFromExportFile } from '../../../shared/helpers/duplicate/import';
import { setIsTopBarExpanded, setToastMessage } from '../../../shared/store/my-data/actions';
import moment from 'moment';
import { INewStatusData, IUpdateableStatusData } from '../../../shared/store/workflows/types/statuses/types';
import { INewWorkflowTypeCustomFieldData, INewFieldChoiceData, FieldType } from '../../../shared/store/custom-fields/types';
import InputText from '../../../widgets/form/InputText';
import MultiSelectInput from '../../../widgets/form/MultiSelectInput';
import Button from '../../../widgets/button/CommonButton';
import ConfirmModal from '../../../widgets/loader/ConfirmModal';
import { NudgeType } from '../../../shared/store/my-data/types';
import { IStaticDataHolder } from '../../../shared/store/static-info/types';
import { IDataFragment } from '../../../shared/store/static-info/data-fragment/types';
import { useHistory } from 'react-router';

type OwnProps = {};

type Option = {
    name: string,
    value: string
}

const mapStateToProps = (state: ApplicationState) => {
    const canEditConfiguration = state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.WRITE;
    const canViewConfiguration = canEditConfiguration || state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.READ;
    const selectedWorkflowTypeId = state.workflows.types.selected;
    const selectedWorkflowStatusId = state.workflows.types.statuses.selected;
    const selectedWorkflowType = selectedWorkflowTypeId ? state.workflows.types.byId[selectedWorkflowTypeId] : undefined;
    const customFieldIds = selectedWorkflowTypeId ? state.workflows.types.byId[selectedWorkflowTypeId].customFields : [];

    return {
        selectedWorkflowTypeId,
        canHaveGroupAffiliations: !!selectedWorkflowType && selectedWorkflowType.affiliation === 'group',
        isReadable: canViewConfiguration,
        isWritable: canEditConfiguration,
        customFieldIds,
        selectedStatus: selectedWorkflowStatusId,
        projectsData: state.structure.projects,
        levelsData: state.structure.levels,
        rolesData: state.structure.roles,
        memberTypesData: state.members.types,
        groupTypesData: state.groups.types,
        workflowTypesData: state.workflows.types,
        variableState: state.flowchart.variables,
        pieceState: state.flowchart.pieces,

        staticDataHoldersData: state.staticInfo,

        isSuperUser: !isUUID(state.myData.id),
        selectedNudge: state.myData.selectedNudgeId,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        selectWorkflowType: (id: string) => dispatch(selectWorkflowType(id)),
        unSelectWorkflowType: () => dispatch(unSelectWorkflowType()),

        selectWorkflowStatus: (id: string) => dispatch(selectStatus(id)),
        unSelectWorkflowStatus: () => dispatch(unSelectStatus()),

        showRichTextEditor: () => dispatch(showRichTextEditor()),
        setToastMessage: (message: string) => dispatch(setToastMessage(message)),

        addWorkflowTypeCustomField: (payload: INewWorkflowTypeCustomFieldData, workflowTypeId: string) => dispatch(addWorkflowTypeCustomField(payload, workflowTypeId)),
        addWorkflowTypeCustomFieldOption: (payload: INewFieldChoiceData, customFieldId: string) => dispatch(addWorkflowTypeCustomFieldOption(payload, customFieldId)),

        expandTopBar: () => dispatch(setIsTopBarExpanded(true)),

        addFullPiece: (payload: AllPieceTypes) => dispatch(addFullPiece(payload)),
        setNextPiece: (targetPieceId: string, draggedPieceId: string) => dispatch(setNextPiece(targetPieceId, draggedPieceId)),
        addStatus: (payload: INewStatusData, parentId: string) => dispatch(addStatus(payload, parentId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

const ConnectedWorkflowConfiguration: FC<Props> = (props: Props) => {

    const [isShowingAddForm, setIsShowingAddForm] = useState(false);
    const [isShowingDuplicateForm, setIsShowingDuplicateForm] = useState(false);
    const [isShowingLoader, setIsShowingLoader] = useState(false);
    const [duplicatingWorkflowTypeId, setDuplicatingWorkflowTypeId] = useState<string | undefined>(undefined);
    const [modifyingVerticalName, setModifyingVerticalName] = useState('');
    const [modifyingVerticalType, setModifyingVerticalType] = useState('');
    const [modifyingVerticalIsComputed, setModifyingVerticalIsComputed] = useState('No');
    const [modifyingVerticalIsInTable, setModifyingVerticalIsInTable] = useState('No');
    const [modifyingVerticalAffiliation, setModifyingVerticalAffiliation] = useState<'member' | 'group'>('member');

    const [duplicateName, setDuplicateName] = useState('');
    const [duplicateProject, setDuplicateProject] = useState('');
    const [duplicateAffiliatedEntity, setDuplicateAffiliatedEntity] = useState('');
    const [duplicateIsCore, setDuplicateIsCore] = useState('Yes');
    const [duplicateAreMultipleInstancesAllowed, setDuplicateAreMultipleInstancesAllowed] = useState('No');

    const [importingWorkflowData, setImportingWorkflowData] = useState<ExportWorkflowTypeData | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(false);
    const [isShowingBetaExportOption, setIsShowingBetaExportOption] = useState(false);

    const [importName, setImportName] = useState<string>('');
    const [importProject, setImportProject] = useState<string>('');
    const [importLevel, setImportLevel] = useState<string>('');
    const [importAffiliation, setImportAffiliation] = useState<'none' | 'member' | 'group'>('none');
    const [importAffiliatedEntityName, setImportAffiliatedEntityName] = useState<string>('');
    const [importExistingAffiliatedEntityId, setImportExistingAffiliatedEntityId] = useState<string>('');
    const [importIsLinkedToExistingAffiliation, setImportIsLinkedToExistingAffiliation] = useState<string>('Yes');
    const [importStaticDataIds, setImportStaticDataIds] = useState<string[]>([]);
    const [importIsCore, setImportIsCore] = useState<string>('Yes');
    const [importAreMultipleInstancesAllowed, setImportAreMultipleInstancesAllowed] = useState<string>('No');
    const [loaderToast, setLoaderToast] = useState<JSX.Element | undefined>(undefined);
  
    const history = useHistory();

    const toggleCustomFieldAddForm = () => {
        setIsShowingAddForm(prevIsShowingAddForm => !prevIsShowingAddForm);
    }

    const showDuplicateForm = (workflowTypeId: string | undefined) => {
        if (!workflowTypeId) {
            return;
        }

        const duplicateWorkflowType = props.workflowTypesData.byId[workflowTypeId];

        setIsShowingDuplicateForm(true);
        setDuplicatingWorkflowTypeId(workflowTypeId);

        setDuplicateName(duplicateWorkflowType.name);
        setDuplicateProject(duplicateWorkflowType.project);
        setDuplicateAffiliatedEntity(duplicateWorkflowType.affiliatedEntity);

        setDuplicateIsCore(duplicateWorkflowType.isCore ? "Yes" : "No");
        setDuplicateAreMultipleInstancesAllowed(duplicateWorkflowType.areMultipleInstancesAllowed ? "Yes" : "No");
    }

    const prepareBetaExport = (workflowTypeId: string | undefined) => {

        setTimeout(() => {
            setIsLoading(true);
            setIsShowingBetaExportOption(false);
            startExport(workflowTypeId, true);
        }, 1000);
    }

    const cancelBetaExport = () => {
        setIsShowingBetaExportOption(false);
    }

    const prepareExport = (workflowTypeId: string | undefined) => {
        if (!workflowTypeId) {
            return;
        }

        const workflowType = props.workflowTypesData.byId[workflowTypeId];
        const startPiece = props.pieceState.byId[workflowType.startPiece.piece];

        if (startPiece.type !== PieceType.START) {
            return;
        }

        if (!startPiece.nextPiece) {
            setIsShowingBetaExportOption(true);
            return;
        }

        setTimeout(() => {
            setIsLoading(true);
            startExport(workflowTypeId);
        }, 1000);
    }

    const getAncestorChainOfFragment = (fragmentId: string) => {
        const fragmentState = props.staticDataHoldersData.fragments;

        const ancestorFragments: Array<string> = [];
        let upperFragment: IDataFragment = fragmentState.byId[fragmentId];
        let parentId = upperFragment.parent || '';

        while (parentId in fragmentState.byId) {
            ancestorFragments.push(parentId);
            upperFragment = fragmentState.byId[parentId];
            parentId = upperFragment.parent || '';
        }

        return ancestorFragments;
    }

    const getHeaderForStaticData = (selectedStaticDataHolder: IStaticDataHolder) => {

        let longestLine: number = 0;

        for (const fragmentId of props.staticDataHoldersData.fragments.allEntries) {
            const fragment = props.staticDataHoldersData.fragments.byId[fragmentId];

            if (fragment.children.length === 0) {
                const ancestorIds = getAncestorChainOfFragment(fragment.id).reverse();
                ancestorIds.push(fragment.id);

                const isAncestorArchived = ancestorIds.some(ancestorId => props.staticDataHoldersData.fragments.byId[ancestorId].archived);
                const isFragmentInStaticData = selectedStaticDataHolder.children.includes(ancestorIds[0]);

                if (!isAncestorArchived && isFragmentInStaticData) {
                    longestLine = ancestorIds.length;
                }
            }
        }

        const headings: Array<string> = [];

        for (let i = 1; i <= longestLine; i += 1) {
            headings.push(`Data level ${i}`);
        }

        return headings;
    }

    const getStaticInfoData = (selectedStaticDataHolder: IStaticDataHolder) => {
        const staticInfoData: Array<Array<string>> = [];

        for (const fragmentId of props.staticDataHoldersData.fragments.allEntries) {
            const fragment = props.staticDataHoldersData.fragments.byId[fragmentId];

            if (fragment.children.length === 0) {
                const fragmentRow: Array<string> = []
                const ancestorIds = getAncestorChainOfFragment(fragment.id).reverse();
                ancestorIds.push(fragment.id);

                const isAncestorArchived = ancestorIds.some(ancestorId => props.staticDataHoldersData.fragments.byId[ancestorId].archived);
                const isFragmentInStaticData = selectedStaticDataHolder.children.includes(ancestorIds[0]);

                if (isFragmentInStaticData && !isAncestorArchived) {

                    for (const ancestorId of ancestorIds) {
                        const ancestorFragment = props.staticDataHoldersData.fragments.byId[ancestorId];
                        const fragmentName = ancestorFragment.name ? ancestorFragment.name : '-';
                        fragmentRow.push(fragmentName);
                    }

                    staticInfoData.push(fragmentRow);

                }
            }
        };

        return staticInfoData;
    }

    const startExport = (workflowTypeId: string | undefined, isBetaExport = false) => {
        if (!workflowTypeId) {
            return;
        }

        const workflowType = props.workflowTypesData.byId[workflowTypeId];
        const project = props.projectsData.byId[workflowType.project];

        const exportedWorkflowType: ExportedWorkflowType = {
            id: workflowType.id,
            name: workflowType.name,
            affiliation: workflowType.affiliation,
            affiliatedEntity: workflowType.affiliatedEntity,
            isCore: workflowType.isCore,

            seedEntityVariable: workflowType.seedEntityVariable,
            seedAffiliationVariable: workflowType.seedAffiliationVariable,
            areMultipleInstancesAllowed: workflowType.areMultipleInstancesAllowed,

            startPiece: {
                piece: isBetaExport ? workflowType.betaStartPiece.piece : workflowType.startPiece.piece,
                position: workflowType.startPiece.position,
            },
            variables: workflowType.variables,

            levels: project.children.map(levelId => {
                const level = props.levelsData.byId[levelId];

                return {
                    id: levelId,
                    name: level.name,
                    customFields: exportCustomFields(props.levelsData, level.customFields),
                    roles: level.children.map(roleId => {
                        const role = props.rolesData.byId[roleId];

                        return {
                            id: roleId,
                            name: role.name,
                            customFields: exportCustomFields(props.rolesData, role.customFields),
                        };
                    }),
                };
            }),

            statuses: workflowType.statuses.map(statusId => {
                const status = props.workflowTypesData.statuses.byId[statusId];

                return {
                    id: status.id,
                    name: status.name,
                    isTerminal: status.isTerminal,
                    dueInDays: status.dueInDays,
                }
            }),
            customFields: exportWorkflowTypeCustomFields(props.workflowTypesData, workflowType.customFields),
        };

        const pieceState: NormalizedModel<AllPieceTypes> = {
            byId: {},
            allEntries: [],
            filteredEntries: [],

            createdIds: new Set(),
            updatedIds: new Set(),
            deletedIds: new Set(),
        };

        const variableState: NormalizedModel<IVariable> = {
            byId: {},
            allEntries: [],
            filteredEntries: [],

            createdIds: new Set(),
            updatedIds: new Set(),
            deletedIds: new Set(),
        }

        const addPiece = (pieceData: AllPieceTypes) => {
            pieceState.byId[pieceData.id] = pieceData;

            if (!pieceState.allEntries.includes(pieceData.id)) {
                pieceState.allEntries.push(pieceData.id);
            }
        }

        const addVariable = (variable: IVariable) => {
            variableState.byId[variable.id] = variable;

            if (!variableState.allEntries.includes(variable.id)) {
                variableState.allEntries.push(variable.id);
            }
        }

        const newId = duplicatePiece(props.pieceState, addPiece, undefined, isBetaExport ? workflowType.betaStartPiece.piece : workflowType.startPiece.piece);
        exportedWorkflowType.startPiece.piece = newId;

        for (const variableId of workflowType.variables) {
            const variable = props.variableState.byId[variableId];
            if (variable) {
                addVariable(variable);
            }
        }

        const exportInfo: ExportWorkflowTypeData = {
            workflowType: exportedWorkflowType,
            pieceState: pieceState,
            variableState: variableState,
            affiliation: undefined,
        };

        for (const customField of exportedWorkflowType.customFields) {
            if (customField.isComputed && customField.startPiece) {
                const newId = duplicatePiece(props.pieceState, addPiece, undefined, customField.startPiece.piece);
                customField.startPiece.piece = newId;

                for (const variableId of customField.variables) {
                    const variable = props.variableState.byId[variableId];
                    if (variable) {
                        addVariable(variable);
                    }
                }
            }
        }

        if (exportedWorkflowType.affiliatedEntity) {
            if (exportedWorkflowType.affiliation === 'member') {
                const memberType = props.memberTypesData.byId[exportedWorkflowType.affiliatedEntity];

                const exportedActions = memberType.actions.map(actionId => {
                    const action = props.memberTypesData.actions.byId[actionId];

                    return {
                        id: action.id,
                        name: action.name,
                        icon: action.icon,
                        workflowType: action.workflowType,
                    };
                });

                const exportedMemberType: ExportedMemberType = {
                    id: memberType.id,
                    name: memberType.name,
                    nameFieldId: memberType.nameFieldId,
                    subTitleFieldId: memberType.subTitleFieldId,
                    locationFieldId: memberType.locationFieldId,
                    customFields: exportCustomFields(props.memberTypesData, memberType.customFields),
                    actions: exportedActions,
                };

                for (const customField of exportedMemberType.customFields) {
                    if (customField.isComputed && customField.startPiece) {
                        const newId = duplicatePiece(props.pieceState, addPiece, undefined, customField.startPiece.piece);
                        customField.startPiece.piece = newId;

                        for (const variableId of customField.variables) {
                            const variable = props.variableState.byId[variableId];
                            if (variable) {
                                addVariable(variable);
                            }
                        }
                    }
                }

                exportInfo.affiliation = exportedMemberType;

            } else if (exportedWorkflowType.affiliation === 'group') {
                const groupType = props.groupTypesData.byId[exportedWorkflowType.affiliatedEntity];

                const exportedActions = groupType.actions.map(actionId => {
                    const action = props.groupTypesData.actions.byId[actionId];

                    return {
                        id: action.id,
                        name: action.name,
                        icon: action.icon,
                        workflowType: action.workflowType,
                    };
                });

                const exportedGroupType: ExportedGroupType = {
                    id: groupType.id,
                    name: groupType.name,
                    level: groupType.level,
                    isRequired: groupType.isRequired,
                    uniqueFieldId: groupType.uniqueFieldId,
                    nameFieldId: groupType.nameFieldId,
                    subTitleFieldId: groupType.subTitleFieldId,
                    customFields: exportCustomFields(props.groupTypesData, groupType.customFields),
                    actions: exportedActions,
                };

                for (const customField of exportedGroupType.customFields) {
                    if (customField.isComputed && customField.startPiece) {
                        const newId = duplicatePiece(props.pieceState, addPiece, undefined, customField.startPiece.piece);
                        customField.startPiece.piece = newId;

                        for (const variableId of customField.variables) {
                            const variable = props.variableState.byId[variableId];
                            if (variable) {
                                addVariable(variable);
                            }
                        }
                    }
                }

                exportInfo.affiliation = exportedGroupType;
            }
        }

        const staticInfoIds: Set<string> = new Set();

        for (const pieceId of pieceState.allEntries) {
            const piece = pieceState.byId[pieceId];

            if (piece.type === PieceType.STATIC_DATA && piece.staticDataHolder) {
                staticInfoIds.add(piece.staticDataHolder);
            }
        }

        if (staticInfoIds.size > 0) {
            exportInfo.staticData = [];

            for (const staticInfoId of staticInfoIds) {
                const staticInfo = props.staticDataHoldersData.byId[staticInfoId];

                const headers = getHeaderForStaticData(staticInfo);
                const data = getStaticInfoData(staticInfo);

                const exportData = [headers, ...data];

                exportInfo.staticData.push({
                    id: staticInfo.id,
                    name: staticInfo.name,
                    data: exportData,
                });
            }
        }

        const exportData = JSON.stringify(exportInfo, null, 2);

        const fileBlob = new Blob([exportData.split('\n').join('\r\n')], { type: 'application/json' });

        saveAs(fileBlob, `${exportedWorkflowType.name} Export.json`);

        showLoaderToast('Export ready', true);

        setIsLoading(false);
    }

    const hideImportForm = () => {
        setImportingWorkflowData(undefined);

        setImportName('');
        setImportProject('');
        setImportLevel('');
        setImportAffiliatedEntityName('');
        setImportExistingAffiliatedEntityId('');
        setImportIsLinkedToExistingAffiliation('Yes');
        setImportIsCore('Yes');
        setImportAreMultipleInstancesAllowed('No');
    }

    const hideDuplicateForm = () => {
        setIsShowingDuplicateForm(false);
        setDuplicatingWorkflowTypeId(undefined);
    }

    const replicateWorkflowType = () => {
        if (duplicatingWorkflowTypeId) {

            setIsShowingLoader(true);

            setTimeout(() => {
                if (duplicatingWorkflowTypeId) {
                    duplicateWorkflowType(duplicatingWorkflowTypeId, {
                        name: duplicateName,
                        project: duplicateProject,
                        affiliatedEntity: duplicateAffiliatedEntity,
                        isCore: duplicateIsCore === 'Yes',
                        areMultipleInstancesAllowed: duplicateAreMultipleInstancesAllowed === 'Yes',
                    });

                    setIsShowingDuplicateForm(false);
                    setIsShowingLoader(false);
                    setDuplicatingWorkflowTypeId(undefined);
                    setDuplicateName('');
                    setDuplicateProject('');
                    setDuplicateAffiliatedEntity('');
                    setDuplicateIsCore('Yes');
                    setDuplicateAreMultipleInstancesAllowed('No');

                    showLoaderToast("Duplication complete, please check the flowchart to confirm", true);
                }
            }, 200);
        }
    }

    const validateImportForm = () => {

        if (!importingWorkflowData) {
            return 'No data to import';
        }

        if (!importName) {
            return 'Enter a valid name';
        }

        if (!importProject) {
            return 'Enter a valid project';
        }

        const existingNamesForWorkflowTypes = props.workflowTypesData.allEntries.map(workflowTypeId => {
            const workflowType = props.workflowTypesData.byId[workflowTypeId];
            return workflowType.name;
        });

        if (importingWorkflowData.affiliation && importAffiliation !== 'none') {

            if (importIsLinkedToExistingAffiliation === 'Yes') {

                if (!importExistingAffiliatedEntityId) {
                    return 'Select an existing entity from list';
                }

            } else {

                if (!importAffiliatedEntityName) {
                    return 'Select a new entity name';
                }

            }


            if (!importExistingAffiliatedEntityId) {
                if (importingWorkflowData.workflowType.affiliation === 'member') {

                    if (!importAffiliatedEntityName) {
                        return 'Enter a name for the member type';
                    }

                    const existingAffiliationNames = props.memberTypesData.allEntries.map(memberTypeId => {
                        const memberType = props.memberTypesData.byId[memberTypeId];
                        return memberType.name;
                    });

                    if (existingAffiliationNames.includes(importAffiliatedEntityName)) {
                        return 'Enter a unique name for the importing member type';
                    }
                } else if (importingWorkflowData.workflowType.affiliation === 'group') {

                    if (!importLevel) {
                        return 'Select the level at which to create the group type';
                    }

                    if (!importAffiliatedEntityName) {
                        return 'Enter a name for the group type';
                    }

                    const existingAffiliationNames = props.groupTypesData.allEntries.map(groupTypeId => {
                        const groupType = props.groupTypesData.byId[groupTypeId];
                        return groupType.name;
                    });

                    if (existingAffiliationNames.includes(importAffiliatedEntityName)) {
                        return 'Enter a unique name for the importing group type';
                    }
                }
            }

        }

        if (existingNamesForWorkflowTypes.includes(importName)) {
            return 'Enter a unique name for the importing workflow type';
        }

        return true;
    }

    const updateImportName = (value: string) => {
        setImportName(value);
    }

    const updateImportProject = (value: string) => {
        setImportProject(value);
    }

    const updateImportAffiliation = (value: string) => {
        switch (value) {
            case 'none':
            case 'member':
            case 'group':
                setImportAffiliation(value);
        }

        if (importIsLinkedToExistingAffiliation === 'Yes' && !importingWorkflowData?.affiliation) {
            setImportIsLinkedToExistingAffiliation('No');
        }
    }

    const updateImportStaticData = (value: Array<string>) => {
        setImportStaticDataIds(value);
    }

    const updateImportLevel = (value: string) => {
        setImportLevel(value);
    }

    const updateImportIsLinkedToExistingAffiliation = (value: string) => {
        setImportIsLinkedToExistingAffiliation(value);
    }

    const updateImportExistingAffiliatedEntityId = (value: string) => {
        setImportExistingAffiliatedEntityId(value);
    }

    const updateImportAffiliatedEntityName = (value: string) => {
        setImportAffiliatedEntityName(value);
    }

    const updateImportIsCore = (value: string) => {
        setImportIsCore(value);
    }

    const updateImportAreMultipleInstancesAllowed = (value: string) => {
        setImportAreMultipleInstancesAllowed(value);
    }

    const validateDuplicateForm = () => {

        if (!duplicatingWorkflowTypeId) {
            return 'Select a workflow type to duplicate';
        }

        const sourceWorkflowType = props.workflowTypesData.byId[duplicatingWorkflowTypeId];

        if (!duplicateName) {
            return 'Enter a valid name';
        }

        if (!duplicateProject) {
            return 'Enter a valid project';
        }

        if (duplicateName === sourceWorkflowType.name && duplicateProject === sourceWorkflowType.project) {
            return "Duplicates in the same project must have different names";
        }

        return true;
    }

    const updateDuplicateName = (value: string) => {
        setDuplicateName(value);
    }

    const updateDuplicateProject = (value: string) => {
        setDuplicateProject(value);
        setDuplicateAffiliatedEntity("");
    }

    const updateDuplicateAffiliatedEntity = (value: string) => {
        setDuplicateAffiliatedEntity(value);
    }

    const updateDuplicateIsCore = (value: string) => {
        setDuplicateIsCore(value);
    }

    const updateDuplicateAreMultipleInstancesAllowed = (value: string) => {
        setDuplicateAreMultipleInstancesAllowed(value);
    }

    const updateFieldName = (value: string) => {
        setModifyingVerticalName(value);
    }

    const updateFieldType = (value: string) => {
        setModifyingVerticalType(value);
    }

    const updateFieldAffiliation = (value: string) => {
        const affiliationValue: 'member' | 'group' = value as 'member' | 'group';
        setModifyingVerticalAffiliation(affiliationValue);
    }

    const updateFieldIsComputed = (value: string) => {
        setModifyingVerticalIsComputed(value);
    }

    const updateFieldIsInTable = (value: string) => {
        setModifyingVerticalIsInTable(value);
    }

    const addCustomField = () => {
        const fieldType = modifyingVerticalType as keyof typeof FieldType;

        if (!props.selectedWorkflowTypeId) {
            return;
        }

        if (validateVerticalForm() !== true) {
            return;
        }

        props.addWorkflowTypeCustomField({
            name: modifyingVerticalName,
            type: FieldType[fieldType],
            isComputed: modifyingVerticalIsComputed === 'Yes',
            isInTable: modifyingVerticalIsInTable === 'Yes',
            isEditable: true,
            isDeletable: true,
            affiliation: modifyingVerticalAffiliation,

            seedEntityVariable: uuid.v4(),
            seedAffiliationVariable: uuid.v4(),
        }, props.selectedWorkflowTypeId);

        setIsShowingAddForm(false);
        setModifyingVerticalName("");
        setModifyingVerticalType("");
        setModifyingVerticalIsComputed("No");
        setModifyingVerticalIsInTable("No");
    }

    const validateVerticalForm = () => {
        const fieldTypes = Object.keys(FieldType);
        const binaryTypes = ['Yes', 'No'];
        const affiliationTypes = ['member', 'group'];

        if (!modifyingVerticalName) {
            return "Enter a valid name";
        }

        const workflowTypesCustomFieldNames: Array<string> = props.customFieldIds.map(id => {
            return props.workflowTypesData.customFields.byId[id].name.toLocaleLowerCase();
        });

        if (workflowTypesCustomFieldNames.includes(modifyingVerticalName.toLocaleLowerCase())) {
            return 'A custom field with this name already exists';
        }

        if (!fieldTypes.includes(modifyingVerticalType)) {
            return "Enter a valid type";
        }

        if (!binaryTypes.includes(modifyingVerticalIsComputed)) {
            return "Enter a valid computed field";
        }

        if (!binaryTypes.includes(modifyingVerticalIsInTable)) {
            return "Enter a valid in-table field";
        }

        if (!affiliationTypes.includes(modifyingVerticalAffiliation)) {
            return "Enter a valid affiliation";
        }

        return true;
    }

    const showLoaderToast = (text: string, isSuccess: boolean) => {
        setLoaderToast(<LoaderModal loaderText={[text]} isSuccess={isSuccess} isError={!isSuccess} />);

        setTimeout(() => {
            setLoaderToast(undefined);
        }, 4000);
    }

    const getMarkupForRichText = (value: string) => {
        let stringifiedHtml: string;
        try {
            stringifiedHtml = draftToHtml(JSON.parse(value));
        } catch (e) {
            stringifiedHtml = '<p>Incorrect HTML</p>';
        }

        return ReactHtmlParser(stringifiedHtml);
    }

    const updateWorkflowDataForNonMatchingAffiliation = () => {
        const updatedWorkflowData: ExportWorkflowTypeData = JSON.parse(JSON.stringify(importingWorkflowData));

        if (updatedWorkflowData && importAffiliation !== updatedWorkflowData.workflowType.affiliation) {


            if (importAffiliation === 'none') {
                for (const customField of updatedWorkflowData.workflowType.customFields) {
                    customField.affiliation = 'member';
                }

                for (const pieceId of updatedWorkflowData.pieceState.allEntries) {
                    const piece = updatedWorkflowData.pieceState.byId[pieceId];

                    if (piece.type === PieceType.QUESTION || piece.type === PieceType.GROUPED_QUESTION) {
                        piece.memberVariablePiece = undefined;
                    }
                }
            } else if (importAffiliation === 'member') {

                for (const customField of updatedWorkflowData.workflowType.customFields) {
                    customField.affiliation = 'member';
                }

                for (const pieceId of updatedWorkflowData.pieceState.allEntries) {
                    const piece = updatedWorkflowData.pieceState.byId[pieceId];

                    if (piece.type === PieceType.QUESTION || piece.type === PieceType.GROUPED_QUESTION) {
                        piece.memberVariablePiece = undefined;
                    }
                }
                let memberVariableName = 'Member';
                const variableNames = new Set(updatedWorkflowData.workflowType.variables.map(variableId => updatedWorkflowData.variableState.byId[variableId].name));

                if (variableNames.has(memberVariableName)) {
                    let i = 1;

                    while (variableNames.has(memberVariableName)) {
                        memberVariableName = 'Member ' + i;
                        i += 1;
                    }
                }

                if (!updatedWorkflowData.workflowType.seedAffiliationVariable) {

                    const seedVariable: IVariable = {
                        id: uuid.v4(),
                        name: memberVariableName,
                        type: VariableType.MEMBER,
                        createdTime: moment().format(),
                        lastUpdatedTime: moment().format(),
                    };

                    updatedWorkflowData.variableState.byId[seedVariable.id] = seedVariable;
                    updatedWorkflowData.variableState.allEntries.splice(1, 0, seedVariable.id);
                }

                updatedWorkflowData.variableState.byId[updatedWorkflowData.workflowType.seedAffiliationVariable].name = memberVariableName;
                updatedWorkflowData.variableState.byId[updatedWorkflowData.workflowType.seedAffiliationVariable].type = VariableType.MEMBER;
            } else if (importAffiliation === 'group') {

                for (const customField of updatedWorkflowData.workflowType.customFields) {
                    customField.affiliation = 'group';
                }

                let groupVariableName = 'Group';
                const variableNames = new Set(updatedWorkflowData.workflowType.variables.map(variableId => updatedWorkflowData.variableState.byId[variableId].name));

                if (variableNames.has(groupVariableName)) {
                    let i = 1;

                    while (variableNames.has(groupVariableName)) {
                        groupVariableName = 'Group ' + i;
                        i += 1;
                    }
                }

                if (!updatedWorkflowData.workflowType.seedAffiliationVariable) {

                    const seedVariable: IVariable = {
                        id: uuid.v4(),
                        name: groupVariableName,
                        type: VariableType.GROUP,
                        createdTime: moment().format(),
                        lastUpdatedTime: moment().format(),
                    };

                    updatedWorkflowData.variableState.byId[seedVariable.id] = seedVariable;
                    updatedWorkflowData.variableState.allEntries.splice(1, 0, seedVariable.id);
                }

                updatedWorkflowData.variableState.byId[updatedWorkflowData.workflowType.seedAffiliationVariable].name = groupVariableName;
                updatedWorkflowData.variableState.byId[updatedWorkflowData.workflowType.seedAffiliationVariable].type = VariableType.GROUP;
            }
        }

        return updatedWorkflowData;
    }

    const importWorkflowType = () => {
        setIsLoading(true);

        setTimeout(() => {
            if (importingWorkflowData) {
                const updatedWorkflowData = updateWorkflowDataForNonMatchingAffiliation();

                try {
                    importWorkflowTypeFromExportFile(
                        updatedWorkflowData,
                        importName,
                        importProject,
                        importLevel,
                        importAffiliation,
                        importAffiliatedEntityName,
                        importStaticDataIds,
                        importExistingAffiliatedEntityId,
                        importIsCore === 'Yes',
                        importAreMultipleInstancesAllowed === 'Yes',
                    );
                    showLoaderToast('Workflow type import complete, please try the flowchart to confirm', true);
                } catch (e) {
                    showLoaderToast('Workflow type import failed. Please check the file again', false);
                    console.error(e);
                }

                setImportingWorkflowData(undefined);

                setIsLoading(false);

                setImportName('');
                setImportProject('');
                setImportLevel('');
                setImportAffiliation('none');
                setImportAffiliatedEntityName('');
                setImportExistingAffiliatedEntityId('');
                setImportIsLinkedToExistingAffiliation('Yes');
                setImportIsCore('Yes');
                setImportAreMultipleInstancesAllowed('No');

            } else {
                setIsLoading(false);
            }
        }, 1000);
    }

    const handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        const file = !!e.target.files ? e.target.files[0] : undefined;

        if (!!file) {
            var reader = new FileReader();

            reader.onload = (evt: any) => {
                const importedData = evt.target.result;
                try {
                    const fileData: ExportWorkflowTypeData = JSON.parse(importedData);

                    setImportingWorkflowData(fileData);

                    setImportName(fileData.workflowType.name);
                    setImportAffiliation(fileData.workflowType.affiliation);
                    setImportAffiliatedEntityName(fileData.affiliation ? fileData.affiliation.name : '');
                    setImportStaticDataIds([]);
                    setImportIsCore(fileData.workflowType.isCore ? 'Yes' : 'No');
                    setImportAreMultipleInstancesAllowed(fileData.workflowType.areMultipleInstancesAllowed ? 'Yes' : 'No');

                    props.expandTopBar();
                } catch (e) {
                    showLoaderToast('Workflow type import failed. Please check the file again', false);
                    console.error(e);
                }
            }

            reader.readAsText(file, "UTF-8");
        }
    }

    const generateValidationForDate = () => {
        const answerPiece: IAnswerPiece = {
            id: uuid.v4(),
            type: PieceType.ANSWER,
            createdTime: moment().format(),
            lastUpdatedTime: moment().format(),
        };

        props.addFullPiece(answerPiece);


        const todayPiece: ITodayPiece = {
            id: uuid.v4(),
            type: PieceType.TODAY,
            createdTime: moment().format(),
            lastUpdatedTime: moment().format(),
        };

        props.addFullPiece(todayPiece);


        const lesserThanPiece: IGreaterThanPiece = {
            id: uuid.v4(),
            type: PieceType.GREATER_THAN,
            createdTime: moment().format(),
            lastUpdatedTime: moment().format(),
            leftOperand: answerPiece.id,
            rightOperand: todayPiece.id,
        }

        props.addFullPiece(lesserThanPiece);


        const errorPiece: IErrorPiece = {
            id: uuid.v4(),
            type: PieceType.ERROR,
            createdTime: moment().format(),
            lastUpdatedTime: moment().format(),
            error: 'The date cannot be greater than the current date',
        }

        props.addFullPiece(errorPiece);


        const splitPiece: ISplitPiece = {
            id: uuid.v4(),
            type: PieceType.SPLIT,
            createdTime: moment().format(),
            lastUpdatedTime: moment().format(),

            ifPieceData: [{
                conditionPiece: lesserThanPiece.id,
                nextPiece: errorPiece.id,
            }]
        }

        props.addFullPiece(splitPiece);

        return splitPiece.id;
    }

    const generateValidationForText = () => {
        const answerPiece: IAnswerPiece = {
            id: uuid.v4(),
            type: PieceType.ANSWER,
            createdTime: moment().format(),
            lastUpdatedTime: moment().format(),
        };

        props.addFullPiece(answerPiece);


        const lengthPiece: ILengthPiece = {
            id: uuid.v4(),
            type: PieceType.LENGTH,

            operand: answerPiece.id,

            createdTime: moment().format(),
            lastUpdatedTime: moment().format(),
        }

        props.addFullPiece(lengthPiece);


        const lesserThanPiece: ILesserThanPiece = {
            id: uuid.v4(),
            type: PieceType.LESSER_THAN,
            createdTime: moment().format(),
            lastUpdatedTime: moment().format(),
            leftOperand: lengthPiece.id,
            rightOperand: '3',
        }

        props.addFullPiece(lesserThanPiece);


        const errorPiece: IErrorPiece = {
            id: uuid.v4(),
            type: PieceType.ERROR,
            createdTime: moment().format(),
            lastUpdatedTime: moment().format(),
            error: 'The answer must have at least 3 characters',
        }

        props.addFullPiece(errorPiece);


        const splitPiece: ISplitPiece = {
            id: uuid.v4(),
            type: PieceType.SPLIT,
            createdTime: moment().format(),
            lastUpdatedTime: moment().format(),

            ifPieceData: [{
                conditionPiece: lesserThanPiece.id,
                nextPiece: errorPiece.id,
            }]
        }

        props.addFullPiece(splitPiece);

        return splitPiece.id;
    }

    const generateDefaultForSingleSelect = (customFieldId: string, choiceId: string) => {
        const customFieldPiece: ICustomFieldPiece = {
            id: uuid.v4(),
            type: PieceType.CUSTOM_FIELD,
            createdTime: moment().format(),
            lastUpdatedTime: moment().format(),

            customField: customFieldId,
            customFieldOption: choiceId,
        }

        props.addFullPiece(customFieldPiece);

        return customFieldPiece.id;
    }

    const onDuplicate = () => {
        return showDuplicateForm(props.selectedWorkflowTypeId);
    }

    const onExport = () => {
        return prepareExport(props.selectedWorkflowTypeId);
    }

    const onImport = () => {
        return handleFileUpload;
    }

    const generateFlowchartForEmptyWorkflowType = () => {
        if (!props.selectedWorkflowTypeId) {
            return;
        }

        const selectedWorkflowType = props.workflowTypesData.byId[props.selectedWorkflowTypeId];

        let isTextValidationDemonstrated = false;
        let isDateValidationDemonstrated = false;

        const questions = selectedWorkflowType.customFields.map(customFieldId => {
            const customField = props.workflowTypesData.customFields.byId[customFieldId];

            const truePiece: IHexagonalTruePiece = {
                id: uuid.v4(),
                type: PieceType.HEXAGONAL_TRUE,
                createdTime: moment().format(),
                lastUpdatedTime: moment().format(),
            };

            props.addFullPiece(truePiece);

            let innerValidationPieceId: string | undefined;
            let defaultPieceId: string | undefined;

            if (!isDateValidationDemonstrated && customField.type === FieldType.DATE) {
                innerValidationPieceId = generateValidationForDate();
                isDateValidationDemonstrated = true;
            } else if (!isTextValidationDemonstrated && customField.type === FieldType.TEXT) {
                innerValidationPieceId = generateValidationForText();
                isTextValidationDemonstrated = true;
            } else if (customField.type === FieldType.SINGLE_SELECT && customField.choices.length > 0) {
                defaultPieceId = generateDefaultForSingleSelect(customField.id, customField.choices[0]);
            }

            const questionPiece: IGroupedQuestionPiece = {
                id: uuid.v4(),
                type: PieceType.GROUPED_QUESTION,
                createdTime: moment().format(),
                lastUpdatedTime: moment().format(),
                isRequiredPiece: truePiece.id,
                customFieldId: customFieldId,
                innerPiece: innerValidationPieceId,
                default: defaultPieceId,
            };

            return questionPiece;
        });

        for (let i = 0; i < questions.length - 1; i += 1) {
            questions[i].nextPiece = questions[i + 1].id;
        }

        const endPieceId = uuid.v4();

        const groupPiece: IGroupPiece = {
            id: uuid.v4(),
            type: PieceType.GROUP,
            innerPiece: questions[0].id,
            createdTime: moment().format(),
            lastUpdatedTime: moment().format(),
            nextPiece: endPieceId,
        };

        props.addFullPiece(groupPiece);
        props.setNextPiece(selectedWorkflowType.betaStartPiece.piece, groupPiece.id);

        for (const question of questions) {
            props.addFullPiece(question);
        }

        const openStatusIds = selectedWorkflowType.statuses.filter(statusId => {
            const status = props.workflowTypesData.statuses.byId[statusId];
            return !status.isTerminal;
        });

        if (openStatusIds.length === 0) {
            const openStatus: IUpdateableStatusData = {
                id: uuid.v4(),
                name: 'Open',
                dueInDays: 7,
                isTerminal: false,
            };

            props.addStatus(openStatus, props.selectedWorkflowTypeId);
        }

        const closedStatusIds = selectedWorkflowType.statuses.filter(statusId => {
            const status = props.workflowTypesData.statuses.byId[statusId];
            return status.isTerminal;
        });

        let closedStatusId: string;

        if (closedStatusIds.length === 0) {
            closedStatusId = uuid.v4();
            const closedStatus: IUpdateableStatusData = {
                id: closedStatusId,
                name: 'Closed',
                isTerminal: true,
            };

            props.addStatus(closedStatus, props.selectedWorkflowTypeId);
        } else {
            closedStatusId = closedStatusIds[0];
        }

        const endPiece: IEndPiece = {
            id: endPieceId,
            type: PieceType.END,
            status: closedStatusId,
            createdTime: moment().format(),
            lastUpdatedTime: moment().format(),
        };

        props.addFullPiece(endPiece);

        history.push(`/workflows/flowchart/${props.selectedWorkflowTypeId}/beta`);
    }


    if (!props.isReadable) {
        return <Redirect to="/dashboard" />;
    }

    const affiliationOptions = [{
        name: 'Member',
        value: 'member',
    }, {
        name: 'Group',
        value: 'group',
    }];
    const typeOptions = Object.keys(FieldType).map(name => {
        return {
            name: name.split('_').join(' '),
            value: name,
        }
    });

    const addFieldForm =
        <div className={styles.modifyFormCard}>
            <div>
                <header>
                    <div className={styles.heading}>
                        {translatePhrase('Add Workflow Type Custom Field')}
                    </div>
                    <button className={styles.cancelCustomFieldButton} title={translatePhrase('Cancel')} onClick={toggleCustomFieldAddForm}> <CancelIcon /> </button>
                </header>
            </div>
            <div className={styles.container}>
                <ModifyForm hideCancel isNew={true} submitForm={addCustomField} cancelForm={toggleCustomFieldAddForm} validateForm={validateVerticalForm}>
                    <InputText placeholder="Name" onEnterPress={addCustomField} onChange={updateFieldName} />
                    <InputText placeholder="Type" onEnterPress={addCustomField} onChange={updateFieldType} options={typeOptions} />
                    <InputText isBooleanField placeholder="Is Computed" onEnterPress={addCustomField} onChange={updateFieldIsComputed} defaultBooleanValue={false} />
                    <InputText isBooleanField placeholder="Searchable / Show in Table" onEnterPress={addCustomField} onChange={updateFieldIsInTable} defaultBooleanValue={false} />
                    {props.canHaveGroupAffiliations && <InputText placeholder="Affiliation" onEnterPress={addCustomField} onChange={updateFieldAffiliation} options={affiliationOptions} default="Member" />}
                </ModifyForm>
            </div>
        </div>;

    const projectsList = props.projectsData.allEntries.map(projectId => {
        return {
            name: props.projectsData.byId[projectId].name,
            value: projectId,
        }
    });

    const staticInfoList = importingWorkflowData?.staticData ? importingWorkflowData?.staticData.map(staticInfo => {
        return {
            name: staticInfo.name,
            value: staticInfo.id,
        }
    }) : [];

    let levelsList: Array<Option> = [];

    if (importProject) {
        const project = props.projectsData.byId[importProject];
        levelsList = project.children.map(levelId => {
            return {
                name: props.levelsData.byId[levelId].name,
                value: levelId,
            }
        });
    }

    const workflowTypeDuplicationTarget = duplicatingWorkflowTypeId ? props.workflowTypesData.byId[duplicatingWorkflowTypeId] : undefined;

    let affiliatedEntityTypeOptions: Array<Option> = [];

    if (workflowTypeDuplicationTarget) {

        const duplicationTargetProject = duplicateProject ? duplicateProject : workflowTypeDuplicationTarget.project;

        if (workflowTypeDuplicationTarget.affiliation === 'member') {

            affiliatedEntityTypeOptions = [{
                name: 'All Member Types',
                value: '',
            }];

            affiliatedEntityTypeOptions = affiliatedEntityTypeOptions.concat(
                props.memberTypesData.allEntries
                    .filter(memberTypeId => props.memberTypesData.byId[memberTypeId].project === duplicationTargetProject)
                    .map(memberTypeId => {
                        const memberType = props.memberTypesData.byId[memberTypeId];
                        return {
                            name: memberType.name,
                            value: memberTypeId,
                        };
                    })
            );
        } else if (workflowTypeDuplicationTarget.affiliation === 'group') {

            affiliatedEntityTypeOptions = [{
                name: 'All Group Types',
                value: '',
            }];

            affiliatedEntityTypeOptions = affiliatedEntityTypeOptions.concat(
                props.groupTypesData.allEntries
                    .filter(groupTypeId => props.groupTypesData.byId[groupTypeId].project === duplicationTargetProject)
                    .map(groupTypeId => {
                        const groupType = props.groupTypesData.byId[groupTypeId];
                        return {
                            name: groupType.name,
                            value: groupTypeId,
                        };
                    })
            );
        }
    }

    const duplicateWorkflowForm =
        <div className={styles.modifyFormCard}>
            <div>
                <header>
                    <div className={styles.heading}>
                        {translatePhrase('Duplicate Workflow')}
                    </div>
                    <button className={styles.cancelCustomFieldButton} title={translatePhrase('Cancel')} onClick={hideDuplicateForm}> <CancelIcon /> </button>
                </header>
            </div>
            <div className={styles.container}>
                <ModifyForm hideCancel isNew={true} submitForm={replicateWorkflowType} cancelForm={hideDuplicateForm} validateForm={validateDuplicateForm}>
                    <InputText placeholder="Name" onEnterPress={replicateWorkflowType} onChange={updateDuplicateName} default={workflowTypeDuplicationTarget && workflowTypeDuplicationTarget.name} />
                    <InputText placeholder="Project" onEnterPress={replicateWorkflowType} onChange={updateDuplicateProject} default={workflowTypeDuplicationTarget && workflowTypeDuplicationTarget.project} options={projectsList} />
                    {workflowTypeDuplicationTarget && workflowTypeDuplicationTarget.affiliation !== 'none' && <InputText key={duplicateProject} placeholder="Affiliated entity" onEnterPress={replicateWorkflowType} onChange={updateDuplicateAffiliatedEntity} default={workflowTypeDuplicationTarget && workflowTypeDuplicationTarget.project === duplicateProject ? workflowTypeDuplicationTarget.affiliatedEntity : ''} options={affiliatedEntityTypeOptions} />}
                    <InputText isBooleanField placeholder="Is Core?" onEnterPress={replicateWorkflowType} onChange={updateDuplicateIsCore} defaultBooleanValue={workflowTypeDuplicationTarget && workflowTypeDuplicationTarget.isCore} />
                    <InputText isBooleanField placeholder="Allow Multiple Instances?" onEnterPress={replicateWorkflowType} onChange={updateDuplicateAreMultipleInstancesAllowed} defaultBooleanValue={workflowTypeDuplicationTarget && workflowTypeDuplicationTarget.areMultipleInstancesAllowed} />
                </ModifyForm>
            </div>
        </div>;

    if (importingWorkflowData && importProject) {

        const importTargetProject = importProject;

        if (importAffiliation === 'member') {

            affiliatedEntityTypeOptions = affiliatedEntityTypeOptions.concat(
                props.memberTypesData.allEntries
                    .filter(memberTypeId => props.memberTypesData.byId[memberTypeId].project === importTargetProject)
                    .map(memberTypeId => {
                        const memberType = props.memberTypesData.byId[memberTypeId];
                        return {
                            name: memberType.name,
                            value: memberTypeId,
                        };
                    })
            );
        } else if (importAffiliation === 'group') {

            affiliatedEntityTypeOptions = affiliatedEntityTypeOptions.concat(
                props.groupTypesData.allEntries
                    .filter(groupTypeId => props.groupTypesData.byId[groupTypeId].project === importTargetProject)
                    .map(groupTypeId => {
                        const groupType = props.groupTypesData.byId[groupTypeId];
                        return {
                            name: groupType.name,
                            value: groupTypeId,
                        };
                    })
            );
        }
    }

    const importAffiliationOptions = [{
        name: 'None',
        value: 'none',
    }, {
        name: 'Member',
        value: 'member',
    }, {
        name: 'Group',
        value: 'group',
    }];

    const affiliation = importAffiliation ? importAffiliation[0].toLocaleUpperCase() + importAffiliation.substring(1) : undefined;
    const importWorkflowForm =
        <div className={styles.modifyFormCard}>
            <div>
                <header>
                    <div className={styles.heading}>
                        {translatePhrase('Import Workflow')}
                    </div>
                    <button className={styles.cancelCustomFieldButton} title={translatePhrase('Cancel')} onClick={hideImportForm}> <CancelIcon /> </button>
                </header>
            </div>
            <div className={styles.container}>
                <ModifyForm hideCancel isNew={true} submitForm={importWorkflowType} cancelForm={hideImportForm} validateForm={validateImportForm}>
                    <InputText placeholder="Name" onEnterPress={importWorkflowType} onChange={updateImportName} default={importingWorkflowData && importingWorkflowData.workflowType.name} />
                    <InputText placeholder="Project" onEnterPress={importWorkflowType} onChange={updateImportProject} options={projectsList} />
                    <InputText placeholder="Affiliation" onEnterPress={importWorkflowType} onChange={updateImportAffiliation} options={importAffiliationOptions} default={importingWorkflowData?.workflowType.affiliation} />
                    {importAffiliation !== 'none' && importingWorkflowData?.workflowType.affiliatedEntity && <InputText isBooleanField placeholder={`Link to existing ${importAffiliation} type?`} onEnterPress={importWorkflowType} onChange={updateImportIsLinkedToExistingAffiliation} defaultBooleanValue={true} />}
                    {importIsLinkedToExistingAffiliation === 'No' && importAffiliation === 'group' && levelsList.length > 0 && <InputText placeholder="Level" onEnterPress={importWorkflowType} onChange={updateImportLevel} options={levelsList} />}
                    {importIsLinkedToExistingAffiliation === 'Yes' && importAffiliation !== 'none' && importProject && importingWorkflowData?.affiliation && <InputText placeholder={`${affiliation} type`} onEnterPress={importWorkflowType} onChange={updateImportExistingAffiliatedEntityId} options={affiliatedEntityTypeOptions} />}
                    {importIsLinkedToExistingAffiliation === 'No' && importingWorkflowData?.workflowType.affiliatedEntity && <InputText placeholder={`${affiliation} type name`} onEnterPress={importWorkflowType} onChange={updateImportAffiliatedEntityName} default={importingWorkflowData?.affiliation?.name} />}
                    {importingWorkflowData?.staticData && <MultiSelectInput placeholder="Static Data" default={importStaticDataIds} onChange={updateImportStaticData} options={staticInfoList} />}
                    <InputText isBooleanField placeholder="Is Core?" onEnterPress={importWorkflowType} onChange={updateImportIsCore} defaultBooleanValue={importingWorkflowData?.workflowType.isCore} />
                    <InputText isBooleanField placeholder="Allow Multiple Instances?" onEnterPress={importWorkflowType} onChange={updateImportAreMultipleInstancesAllowed} defaultBooleanValue={importingWorkflowData?.workflowType.areMultipleInstancesAllowed} />
                </ModifyForm>
            </div>
        </div>;

    const workflowType = props.selectedWorkflowTypeId ? props.workflowTypesData.byId[props.selectedWorkflowTypeId] : undefined;

    const loadingMessages: Array<string> = [
        'Duplicating workflow type',
        'Recreating Workflow Type flowchart',
        'Overriding copied values',
    ]

    const workflowTypesCustomFieldNames: Array<string> = props.customFieldIds.map(id => {
        return props.workflowTypesData.customFields.byId[id].name.toLocaleLowerCase();
    });

    const startPieceForWorkflow = workflowType ? props.pieceState.byId[workflowType.startPiece.piece] as IStartPiece : undefined;

    return <div className={styles.innerFocusContainer}>

        <div className={styles.importExportButtonHolder + ' ignore-top-level-onclickoutside ignore-react-onclickoutside'}>
            {props.selectedWorkflowTypeId && <button onClick={() => showDuplicateForm(props.selectedWorkflowTypeId)}> <DuplicateIcon /> {translatePhrase('Duplicate')} </button>}
            {props.selectedWorkflowTypeId && <button onClick={() => prepareExport(props.selectedWorkflowTypeId)}> <ExportIcon /> {translatePhrase('Export')} </button>}

            <input type="file" accept=".json, application/json" id="import-file-input" className={styles.hiddenFile} onChange={handleFileUpload} />
            {props.isSuperUser && <label htmlFor="import-file-input"> <ImportIcon /> {translatePhrase('Import')} </label>}

            {props.isWritable && workflowType && workflowType.customFields.length > 0 && !startPieceForWorkflow?.nextPiece && <button onClick={() => generateFlowchartForEmptyWorkflowType()}> <FlowchartIcon /> {translatePhrase('Generate default flowchart')} </button>}
        </div>

        {isLoading && <LoaderModal loaderText={[(translatePhrase(`${importingWorkflowData ? 'Importing' : 'Exporting'} your data`) + '...')]} isIndeterminate />}

        <div className={styles.workflowsHolder}>

            {isShowingLoader && <LoaderModal loaderText={loadingMessages} isIndeterminate />}

            <div className={styles.allWorkflowTypes}>
                <WorkflowTypesList
                    heading="Workflow Types"
                    onSelectCard={props.selectWorkflowType}
                    onUnSelectCard={props.unSelectWorkflowType}
                    selectedId={props.selectedWorkflowTypeId}
                    onDuplicate={onDuplicate}
                    onExport={onExport}
                    onImport={onImport}
                    onGenerate={props.isWritable && workflowType && workflowType.customFields.length > 0 && !startPieceForWorkflow?.nextPiece ? generateFlowchartForEmptyWorkflowType : undefined}
                    isImportHighlighted={props.selectedNudge === NudgeType.WORKFLOWS_CONFIGURATION_IMPORT}
                />
            </div>


            {workflowType &&
                <div className={styles.cardsTree + ' ignore-react-onclickoutside'}>

                    <header className={styles.treeHeader}>
                        {translatePhrase('Description')}
                    </header>
                    <div className={styles.richTextDataHolder}>
                        <Button isRounded type={"secondary"} color={"contrast"} icon={<EditIcon />} size={"small"} onClick={props.showRichTextEditor} />
                        <div className={styles.container}>
                            {workflowType.richTextDescription ? getMarkupForRichText(workflowType.richTextDescription) : <span className={styles.placeholder}> {translatePhrase('Enter description')} </span>}
                        </div>
                    </div>

                    <header className={styles.treeHeader}>
                        {translatePhrase('Statuses')}
                    </header>

                    <div className={styles.cardsTreeHolder}>
                        <StatusesList
                            heading="Statuses"
                            selectedId={props.selectedStatus}
                            parentId={workflowType.id}
                            onSelectCard={props.selectWorkflowStatus}
                            onUnSelectCard={props.unSelectWorkflowStatus}
                        />
                    </div>

                    <header className={styles.treeHeader}>
                        {translatePhrase('Custom Fields')}
                    </header>

                    <div className={styles.cardsTreeHolder}>
                        {props.isWritable && <section className={styles.newVerticalHolder + ' ignore-react-onclickoutside'}>
                            {isShowingAddForm ? addFieldForm : <section onClick={toggleCustomFieldAddForm} className={styles.newCustomFieldPrompt}>+ {translatePhrase('Add Custom Field')}</section>}
                            {isShowingDuplicateForm && duplicateWorkflowForm}
                            {importingWorkflowData && importWorkflowForm}
                        </section>}

                        {props.customFieldIds.map(id => {
                            const workflowTypeCustomField = props.workflowTypesData.customFields.byId[id];
                            const remainingCustomFieldNames = workflowTypesCustomFieldNames.filter(fieldName => fieldName.toLocaleLowerCase() !== workflowTypeCustomField.name.toLocaleLowerCase());

                            return <WorkflowTypeCustomFieldVertical existingNames={remainingCustomFieldNames} workflowTypeId={props.selectedWorkflowTypeId || ''} customFieldId={id} key={id} />
                        })}
                    </div>

                </div>}
            {!workflowType &&
                <div className={styles.cardsTree + ' ignore-react-onclickoutside'}>
                    <div className={styles.cardsTreeHolder}>
                        {props.isWritable && <section className={styles.newVerticalHolder + ' ignore-react-onclickoutside'}>
                            {importingWorkflowData && importWorkflowForm}
                        </section>}
                    </div>
                </div>}
            {props.isReadable && props.selectedWorkflowTypeId && <Link to={`/workflows/flowchart/${props.selectedWorkflowTypeId}/beta`}>
                <div className={styles.goToFlowchart + ' ignore-react-onclickoutside'}>
                    <Button size={'medium'} isRounded text={translatePhrase('Go to Flowchart')} padding={'0px 15px'} type={'primary'} color={'contrast'} />
                </div>
            </Link>}
        </div>

        {props.selectedWorkflowTypeId && isShowingBetaExportOption && <ConfirmModal
            confirmText={translatePhrase('Main flowchart not found. Export with beta flowchart?')}
            confirm={() => prepareBetaExport(props.selectedWorkflowTypeId)}
            cancel={cancelBetaExport}
        />}

        {loaderToast}
    </div>;
}

const WorkflowConfiguration = connect(mapStateToProps, mapDispatchToProps)(ConnectedWorkflowConfiguration);

export default WorkflowConfiguration;