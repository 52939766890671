import React, { Component, CSSProperties } from 'react';
import styles from './Table.module.scss';

import DecrementingCounter from './DecrementingCounter';

import { translatePhrase } from '../../shared/helpers/translation';

import { ReactComponent as CloseIcon } from '../../common/assets/close.svg';
import { ReactComponent as MoreIcon } from '../../assets/new-custom-icons/common/more.svg';
import { ReactComponent as ViewIcon } from '../../common/assets/eye.svg';
import { ReactComponent as EditIcon } from '../../common/assets/edit.svg';
import { ReactComponent as TransferIcon } from '../../common/assets/transfer.svg';
import { ReactComponent as DeleteIcon } from '../../assets/action-icons/archive.svg';
import { ReactComponent as ShareIcon } from '../../common/assets/share.svg';
import { ReactComponent as UndoIcon } from '../../assets/undo.svg';
import { copyStringToClipboard, isUUID } from '../../shared/helpers/utilities';

import { ReactComponent as UserMetricsIcon } from '../../common/assets/user-metrics.svg';
import { ReactComponent as Cancel } from '../../common/assets/close.svg';
import { ReactComponent as DonutChart } from '../../common/assets/doughnut-chart.svg';
import { ReactComponent as Export } from '../../assets/action-icons/export.svg';
import { ReactComponent as Filter } from '../../common/assets/filter.svg';
import { ReactComponent as LineChart } from '../../common/assets/line-chart.svg';
import { ReactComponent as MapPin } from '../../assets/action-icons/map-pin.svg';
import { ReactComponent as Pencil } from '../../assets/action-icons/pencil.svg';
import { ReactComponent as Plus } from '../../common/assets/plus.svg';
import { ReactComponent as Search } from '../../common/assets/search.svg';
import { ReactComponent as Sort } from '../../assets/action-icons/sort.svg';
import { ReactComponent as Star } from '../../assets/action-icons/star.svg';
import { ReactComponent as Cog } from '../../assets/action-icons/cog.svg';
import { ReactComponent as TableGrid } from '../../assets/action-icons/table-grid.svg';
import { ReactComponent as MailIcon } from '../../common/assets/email.svg';

import TooltipList from '../tooltip-list/TooltipList';

import { ApplicationState } from '../../shared/store/types';
import { setToastMessage } from '../../shared/store/my-data/actions';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { IUpdateableWorkflowData } from '../../shared/store/workflows/types';
import { addWorkflow } from '../../shared/store/workflows/actions';
import { connect } from 'react-redux';

import uuid from 'uuid';
import moment from 'moment';
import Button from '../button/CommonButton';
import { TableCell } from '../../shared/helpers/common-types';

export type TableHeading = {
    name: string,
    isSortable: boolean,
    isSticky?: boolean,
    stickyFromRight?: number,
    width: number,
}

export interface TableAction {
    name: string;
    icon: string;
    workflowType: string;
}

export type TableEntry = JSX.Element | number | string | TableCell;

export type TableRow = {
    id: string,
    entries: Array<TableEntry>,
    extraActions?: Array<TableAction>,
    deleteWorkflowTypeId?: string,
    editForm?: JSX.Element,
    viewLink?: string,
    showViewInsteadOfEdit?: boolean,
    isDeleted?: boolean,
    isDeleteAllowed?: boolean,
    isNotifyAllowed?: boolean,
    shareText?: string,
    transferUsersList?: Array<JSX.Element>,
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    return {
        myId: state.myData.id,
        myPermissions: state.permissions.myPermissions,
        usersData: state.users,
        workflowsData: state.workflows,
        workflowTypesData: state.workflows.types,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        addWorkflow: (payload: IUpdateableWorkflowData) => dispatch(addWorkflow(payload)),
        setToastMessage: (message: string) => dispatch(setToastMessage(message)),
    };
}

type OwnProps = {
    headings: Array<TableHeading>,
    entries: Array<TableRow>,

    sortedColumn?: string,
    sortType?: 'ASC' | 'DESC',
    isReadOnly?: boolean,
    areActionsHidden?: boolean,
    areFiltersExpanded?: boolean,
    isShowingModifyForm?: boolean,
    isShowMoreHighlighted?: boolean,

    pushHeaderDown?: boolean,
    isEquidistant?: boolean,
    isFullTable?: boolean,
    shouldOverrideStyles?: boolean,

    notifyActionName?: string,

    isDashboardTableWidget?: boolean,

    transferSearch?: (searchString: string) => void,

    onSort?: (name: string) => void,
    onDelete?: (id: string) => void,
    onUnArchive?: (id: string) => void,
    onNotify?: (id: string) => void,
    showModifyForm?: () => void,
    showPerformanceDetails?: (userId: string) => void,
};

type OwnState = {
    selectedRow: string | undefined,
    deleteTimer: number | undefined,

    isShowingTransferList: boolean,
    isShowingCalendar: boolean,
    selectedEntity: string | undefined,
    showMoreOptions: boolean,
    showSharedText: boolean,
    hasFireWorkflow: boolean,
    editRowElement: JSX.Element | undefined,
    tableHeaderPosition: number,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps;

class ConnectedTable extends Component<Props, OwnState> {
    state: OwnState = {
        selectedRow: undefined,
        deleteTimer: undefined,
        isShowingTransferList: false,
        isShowingCalendar: false,
        selectedEntity: undefined,
        showMoreOptions: false,
        showSharedText: false,
        hasFireWorkflow: false,
        editRowElement: undefined,
        tableHeaderPosition: 0
    };

    static defaultProps = {
        isReadOnly: false,
        areFiltersExpanded: false,
        isShowingModifyForm: false,
    };

    selectRow = (id: string) => {
        this.setState({
            selectedRow: id,
        });
    }

    deleteSelectedRow = () => {
        this.state.selectedRow && this.props.onDelete && this.props.onDelete(this.state.selectedRow);
        this.setState({
            selectedRow: undefined,
            deleteTimer: undefined,
            showMoreOptions: false,
        });
    }

    markForDelete = (entityId: string, deleteWorkflowTypeId?: string) => {
        if (deleteWorkflowTypeId) {
            this.fireWorkflow(deleteWorkflowTypeId, entityId);
        } else {
            let that = this;
            const timeout = window.setTimeout(that.deleteSelectedRow, 5000);
            this.setState({
                deleteTimer: timeout
            });
        }
    }

    cancelDelete = () => {
        clearTimeout(this.state.deleteTimer);
        this.setState({
            deleteTimer: undefined
        });
    }

    unArchiveSelectedRow = () => {
        this.state.selectedRow && this.props.onUnArchive && this.props.onUnArchive(this.state.selectedRow);
        this.setState({
            selectedRow: undefined,
            deleteTimer: undefined,
        });
    }

    notifySelectedRow = () => {
        this.state.selectedRow && this.props.onNotify && this.props.onNotify(this.state.selectedRow);
    }

    markForUnArchive = () => {
        let that = this;
        const timeout = window.setTimeout(that.unArchiveSelectedRow, 5000);
        this.setState({
            deleteTimer: timeout
        });
    }

    cancelUnArchive = () => {
        clearTimeout(this.state.deleteTimer);
        this.setState({
            deleteTimer: undefined
        });
    }

    shareRowDetails = (shareText: string) => {
        copyStringToClipboard(shareText);

        this.setState({
            showSharedText: true,
        });

        setTimeout(() => {
            this.setState({
                showSharedText: false,
            });
        }, 3000);
    }

    fireWorkflow = (workflowTypeId: string, entityId: string) => {

        const newWorkflowId = uuid.v4();
        const workflowType = this.props.workflowTypesData.byId[workflowTypeId];

        if (!workflowType.areMultipleInstancesAllowed && Array.isArray(workflowType.workflows)) {
            for (const workflowOfTypeId of workflowType.workflows) {
                const workflowOfType = this.props.workflowsData.byId[workflowOfTypeId];

                if (!!workflowOfType) {
                    let isAffiliatedWithAnotherEntity = false;

                    if (workflowType.affiliation === 'none') {
                        isAffiliatedWithAnotherEntity = workflowOfType.user === this.props.myId;
                    } else {
                        isAffiliatedWithAnotherEntity = workflowOfType.affiliatedEntity === entityId;
                    }

                    if (workflowOfType && !workflowOfType.archived && isAffiliatedWithAnotherEntity && !this.props.workflowTypesData.statuses.byId[workflowOfType.status].isTerminal) {
                        this.props.setToastMessage(translatePhrase('There is already another worklflow of the same type in progress'));
                        return;
                    }
                }
            }
        }


        const defaultStatusId = workflowType.statuses.filter(statusId => !this.props.workflowTypesData.statuses.byId[statusId].isTerminal)[0];
        const defaultStatus = this.props.workflowTypesData.statuses.byId[defaultStatusId];

        let defaultDueDate = moment().add(defaultStatus.dueInDays, 'days').format('YYYY-MM-DD');
        this.props.addWorkflow({
            id: newWorkflowId,
            type: workflowTypeId,
            status: defaultStatusId,
            dueDate: defaultDueDate,
            affiliatedEntity: entityId,
            user: isUUID(this.props.myId) ? this.props.myId : this.props.usersData.allEntries[0],
        });

        this.props.history.push(`/workflow/${newWorkflowId}/execute`)
    }

    getSVGFromIconName = (action: TableAction, entityId: string) => {
        const fireWorkflowCallback = this.fireWorkflow.bind(this, action.workflowType, entityId);

        switch (action.icon) {
            case 'bar-chart': return <Button size={'small'} icon={<UserMetricsIcon />} type={'secondary'} isRounded onClick={fireWorkflowCallback} />;
            case 'cancel': return <Button size={'small'} icon={<Cancel />} type={'secondary'} isRounded onClick={fireWorkflowCallback} />;
            case 'donut-chart': return <Button size={'small'} icon={<DonutChart />} type={'secondary'} isRounded onClick={fireWorkflowCallback} />;
            case 'export': return <Button size={'small'} icon={<Export />} type={'secondary'} isRounded onClick={fireWorkflowCallback} />;
            case 'filter': return <Button size={'small'} icon={<Filter />} type={'secondary'} isRounded onClick={fireWorkflowCallback} />;
            case 'line-chart': return <Button size={'small'} icon={<LineChart />} type={'secondary'} isRounded onClick={fireWorkflowCallback} />;
            case 'map-pin': return <Button size={'small'} icon={<MapPin />} type={'secondary'} isRounded onClick={fireWorkflowCallback} />;
            case 'pencil': return <Button size={'small'} icon={<Pencil />} type={'secondary'} isRounded onClick={fireWorkflowCallback} />;
            case 'plus': return <Button size={'small'} icon={<Plus />} type={'secondary'} isRounded onClick={fireWorkflowCallback} />;
            case 'search': return <Button size={'small'} icon={<Search />} type={'secondary'} isRounded onClick={fireWorkflowCallback} />;
            case 'sort': return <Button size={'small'} icon={<Sort />} type={'secondary'} isRounded onClick={fireWorkflowCallback} />;
            case 'star': return <Button size={'small'} icon={<Star />} type={'secondary'} isRounded onClick={fireWorkflowCallback} />;
            case 'table-grid': return <Button size={'small'} icon={<TableGrid />} type={'secondary'} isRounded onClick={fireWorkflowCallback} />;
            case 'trash': return <Button size={'small'} icon={<TableGrid />} type={'secondary'} isRounded onClick={fireWorkflowCallback} />;
            default: return undefined;
        }
    }

    showTransferList = (entityId: string) => {
        this.setState({
            isShowingTransferList: true,
            selectedEntity: entityId,
        });
    }

    hideTransferList = () => {
        this.setState({
            isShowingTransferList: false,
            selectedEntity: undefined,
        });
    }

    toggleCalendar = (id: string) => {
        this.setState(prevState => {
            return {
                isShowingCalendar: !prevState.isShowingCalendar,
                selectedEntity: id,
            }
        });
    }

    checkIfHeaderHasAction = () => {
        let flag = false;
        this.props.headings.map((heading) => {
            if (heading.name === 'Action') {
                flag = true;
            }
        });

        return flag;
    }


    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<OwnState>, snapshot?: any): void {
        setTimeout(() => {
            let height: any = document.getElementById('filter-tags')?.clientHeight ? document.getElementById('filter-tags')?.clientHeight : 0;

            if (height !== undefined || height !== null) {
                this.setState({
                    tableHeaderPosition: height ? height : 0
                })
            }
        }, 500);
    }

    showModifyForm = (editRowElement: JSX.Element | undefined) => {
        if (editRowElement) {
            this.setState({ editRowElement: editRowElement });
        }
        if (this.props.showModifyForm) {
            this.props.showModifyForm();
        }
    }

    render() {
        const bodyMarkup = this.props.entries.map((row, index) => {
            const isMarkedForDelete = this.state.selectedRow && this.state.selectedRow === row.id && !!this.state.deleteTimer;

            const cellsMarkup = row.entries.map((entry, columnIndex) => {
                let cellClasses = styles.cell;
                let distanceFromLeft: string | number = 'auto';
                let distanceFromRight: string | number = 'auto';

                let headingForColumn: TableHeading = {
                    name: '',
                    width: 200,
                    isSortable: false,
                    isSticky: false,
                    stickyFromRight: undefined,
                };

                if (this.props.headings[columnIndex]) {
                    headingForColumn = this.props.headings[columnIndex];
                }

                const stickyFromRight = headingForColumn.stickyFromRight;

                if (this.props.isEquidistant) {
                    cellClasses += ' ' + styles.equidistant;
                }

                if (headingForColumn.isSticky) {
                    cellClasses = styles.cell + ' ' + styles.sticky;
                    distanceFromLeft = this.props.headings.slice(0, columnIndex).reduce((accumulatedValue, currentEntry) => accumulatedValue + currentEntry.width, 0);
                } else if (stickyFromRight) {
                    cellClasses = styles.cell + ' ' + styles.sticky;
                    distanceFromRight = stickyFromRight;
                }

                if (columnIndex >= 0) {

                    const cellStyles: CSSProperties = {
                        fontStyle: (entry as TableCell).isItalic ? 'italic' : undefined,
                        fontWeight: (entry as TableCell).isBold ? 'bold' : undefined,
                        color: (entry as TableCell).fontColor ? '#' + (entry as TableCell).fontColor : '#595959',
                        fontSize: (entry as TableCell).fontSize ? (entry as TableCell).fontSize + 'px' : undefined,
                        backgroundColor: (entry as TableCell).backgroundColor ? '#' + (entry as TableCell).backgroundColor : '#ffffff',
                    };

                    return <div
                        key={columnIndex}
                        className={cellClasses}
                        style={cellStyles}
                        data-selector="table-cell">
                        {typeof entry === 'object' && entry.hasOwnProperty('value') ? (entry as TableCell).value : entry}
                    </div>;
                }
            });



            return (
                <section
                    data-selector="table-row" key={row.id}
                    className={isMarkedForDelete ? styles.inactiveRow : this.state.selectedRow === row.id ? styles.selectedRow : styles.row
                        + " " + (this.props.isDashboardTableWidget ? styles.dashboardWidgetCell : "")}
                    style={{ width: (!this.props.pushHeaderDown && this.props.headings.length > 5 ? this.props.headings.length * 10 + 'vw' : '100%') }}
                    onClick={() => {
                        if (this.state.selectedRow !== row.id) {
                            this.setState({ showMoreOptions: false, selectedRow: undefined });
                        }
                    }}>
                    {cellsMarkup}
                    {!this.props.areActionsHidden && <div key="actions" className={styles.cell + ' ' + styles.actions + (this.state.selectedRow && this.state.selectedRow === row.id ? (' ' + styles.selectedAction) : '')}>

                        {this.state.showMoreOptions && this.state.selectedRow === row.id && <div className={styles.triangle}> ▲ </div>}

                        {!this.state.showMoreOptions &&
                            <Button dataSelector="more-button"
                                isHighlighted={this.props.isShowMoreHighlighted}
                                title={translatePhrase('More')}
                                size={'small'}
                                onClick={() => {
                                    setTimeout(() => this.setState({ showMoreOptions: true, selectedRow: row.id }), 100);
                                }}
                                icon={<MoreIcon />}
                                type={'secondary'}
                                isRounded />}

                        {this.state.showMoreOptions && this.state.selectedRow === row.id &&
                            <Button dataSelector="close-button" title={translatePhrase('Close')} size={'small'} onClick={() => {
                                this.setState({ showMoreOptions: false, selectedRow: undefined });
                            }} icon={<CloseIcon />} type={'primary'} isSelected isRounded />
                        }


                        {this.state.showMoreOptions && this.state.selectedRow === row.id &&
                            <div className={styles.moreOptions}>
                                {isMarkedForDelete && <React.Fragment>
                                    <Button dataSelector="cancel-button" title={translatePhrase('Cancel')} size={'small'} onClick={this.cancelDelete} padding={'0px 10px'}
                                        type={'secondary'} isRounded
                                        functionalText={<DecrementingCounter additionalText={translatePhrase('Undo before')} remaining={5} />} />
                                </React.Fragment>}

                                {this.state.showSharedText && row.shareText && <React.Fragment>
                                    <div className={styles.clipboardText}>
                                        <div className={styles.container}> <ShareIcon /> <div className={styles.text}> {row.shareText} </div> </div>
                                        <p> {translatePhrase('The data has been copied to clipboard')} </p>
                                    </div>
                                </React.Fragment>}

                                {!isMarkedForDelete && !this.state.showSharedText && <React.Fragment>
                                    {this.props.showPerformanceDetails &&
                                        <Button dataSelector="performance-button" title={translatePhrase('Performance details')} size={'small'} isRounded icon={<UserMetricsIcon />} type={'secondary'}
                                            onClick={this.props.showPerformanceDetails.bind(this, row.id)} />}

                                    {this.props.showModifyForm && (!this.props.isReadOnly && row.editForm && !row.showViewInsteadOfEdit ?
                                        <Button dataSelector="edit-button" title={translatePhrase('Edit')} size={'small'} isRounded icon={<EditIcon />} type={'secondary'}
                                            onClick={() => this.showModifyForm(row.editForm)} /> :
                                        row.viewLink ? <Link to={row.viewLink}>
                                            <Button dataSelector="view-button" title={translatePhrase('View')} size={'small'} isRounded icon={<ViewIcon />} type={'secondary'}
                                            /> </Link> :
                                            <Button dataSelector="view-button" title={translatePhrase('View')} size={'small'} isRounded icon={<ViewIcon />} type={'secondary'}
                                                onClick={() => this.showModifyForm(row.editForm)} />)}


                                    {row.transferUsersList &&
                                        <Button dataSelector="transfer-button" size={'small'} isRounded
                                            type={this.state.selectedEntity === row.id && this.state.isShowingTransferList ? 'primary' : 'secondary'}
                                            icon={this.state.selectedEntity === row.id && this.state.isShowingTransferList ? <CloseIcon /> : <TransferIcon />}
                                            title={this.state.selectedEntity === row.id && this.state.isShowingTransferList ? 'Close' : 'Transfer'}
                                            onClick={() => {
                                                if (this.state.selectedEntity === row.id && this.state.isShowingTransferList) {
                                                    this.hideTransferList();
                                                } else {
                                                    this.showTransferList(row.id);
                                                }
                                            }} />}

                                    {this.state.selectedEntity === row.id && this.state.isShowingTransferList &&
                                        <div className={styles.usersHolder + ' ' + (index > 20 ? styles.upwardModal : '')}>
                                            <div className={styles.container}>
                                                <TooltipList headerIcon={<TransferIcon />} search={this.props.transferSearch} isShowingSearch closeTooltip={this.hideTransferList} listElements={row.transferUsersList} headerText={translatePhrase('Transfer Workflow')} placeholderText={translatePhrase('Search the user list')} />
                                            </div>
                                        </div>}

                                    {row.extraActions && row.extraActions.map(action => this.getSVGFromIconName(action, row.id))}

                                    {!this.props.isReadOnly && row.isDeleteAllowed && (row.isDeleted ?
                                        <Button dataSelector="undo-button" title={translatePhrase('Undo')} size={'small'} isRounded icon={<UndoIcon />} type={'secondary'}
                                            onClick={this.markForUnArchive} /> :
                                        <Button dataSelector="archive-button" title={translatePhrase('Archive')} size={'small'} isRounded icon={<DeleteIcon />} type={'secondary'} onClick={() => this.markForDelete(row.id, row.deleteWorkflowTypeId)} />
                                    )}

                                    {this.props.onNotify && row.isNotifyAllowed && <Button dataSelector="notify-button" title={this.props.notifyActionName || translatePhrase('Notify')} size={'small'} isRounded icon={<MailIcon />} type={'secondary'}
                                        onClick={this.notifySelectedRow} />}

                                    {row.shareText && <Button dataSelector="share-button" title={translatePhrase('Share')} size={'small'} isRounded icon={<ShareIcon />} type={'secondary'}
                                        onClick={this.shareRowDetails.bind(this, row.shareText)} />}
                                </React.Fragment>}
                            </div>
                        }

                    </div>}
                </section>
            );
        });


        let tableHeadingsMarkup = this.props.headings.map((heading, index) => {
            let sortingClasses = heading.isSortable ? ' ' + styles.sortable : '';

            if (this.props.sortedColumn && this.props.sortType && heading.name === this.props.sortedColumn) {
                sortingClasses += ' ' + (this.props.sortType === 'ASC' ? styles.sortedAsc : styles.sortedDesc);
            }

            let tableCellClasses = styles.cell + ' ' + sortingClasses;
            let distanceFromLeft: string | number = 'auto';
            let distanceFromRight: string | number = 'auto';

            const stickyFromRight = heading.stickyFromRight;

            if (heading.isSticky) {
                tableCellClasses += ' ' + styles.sticky;
                distanceFromLeft = this.props.headings.slice(0, index).reduce((accumulatedValue, currentEntry) => accumulatedValue + currentEntry.width, 0);
            } else if (stickyFromRight) {
                tableCellClasses += ' ' + styles.sticky;
                distanceFromRight = stickyFromRight;
            }

            if (index >= 0) {
                return (<div className={styles.cell} key={index}> {translatePhrase(heading.name)} </div>);
            }
        });

        if (!this.props.areActionsHidden) {
            tableHeadingsMarkup.push(<div key="actions" className={styles.cell + ' ' + styles.actions}> <span className={styles.actionIcon}> <Cog /> </span> </div>);
        }

        return (
            <section className={styles.table + ' ' + (this.props.isShowingModifyForm ? styles.blur : '') + ' ' + (this.props.isFullTable ? styles.fullTable : '') + ' ' + (this.props.shouldOverrideStyles ? styles.styleOverriden : '')}>
                {this.state.editRowElement && this.props.isShowingModifyForm &&
                    <section className={this.props.areFiltersExpanded ? styles.editEntryFormHolder : styles.expandedEditEntryFormHolder}>
                        {this.state.editRowElement}
                    </section>
                }
                <section className={styles.tableBody + ' ' + (this.checkIfHeaderHasAction() ? styles.actionTable : '') + ' ' + (!this.props.areActionsHidden && this.checkIfHeaderHasAction() ? styles.hasMore : '')}>
                    {this.props.headings.length > 0 && <section className={styles.row + ' ' + (this.props.pushHeaderDown ? styles.pushHeaderDown : '')}
                        style={{ top: this.props.pushHeaderDown ? 70 + this.state.tableHeaderPosition + 'px' : 0, width: (!this.props.pushHeaderDown && this.props.headings.length > 5 ? this.props.headings.length * 10 + 'vw' : '100%') }}> {tableHeadingsMarkup} </section>}
                    {bodyMarkup.length > 0 && bodyMarkup}
                </section>
                {bodyMarkup.length === 0 && <p className={styles.noDataMessage}> <span> {translatePhrase('No data available')} </span> </p>}
            </section>
        );
    }
}

const TableWithRoute = connect(mapStateToProps, mapDispatchToProps)(ConnectedTable);
const Table = withRouter(TableWithRoute);

export default Table;