import React from 'react';
import styles from './StaticData.module.scss';
import { Switch, Route, Redirect } from "react-router-dom";
import { ApplicationState } from '../../shared/store/types';

import Configuration from './configuration/Configuration';

import { connect } from 'react-redux';

import { ReactComponent as DataIcon } from '../../common/assets/data-base.svg';
import { Permissions } from '../../shared/store/permissions/types';
import Header from '../../widgets/header/Header';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canGoToConfiguration = state.permissions.myPermissions.general.StaticInfoConfiguration !== Permissions.NONE;

    return {
        canGoToConfiguration,
        isPresentable: state.myData.isLoaded || state.myData.isPartiallyLoaded,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;

const ConnectedStaticData: React.FC<Props> = (props) => {

    if (!(props.canGoToConfiguration)) {
        return <Redirect to="/dashboard" />;
    }

    const tabs = [
        {
            tabName: "Configuration",
            link: "/static-data/configuration",
            hasAccess: props.canGoToConfiguration,
        }
    ];
    return (
        <section className={styles.FocusSpace}>

            <Header
                pageName={"Static Data"}
                tabs={tabs}
                icon={<DataIcon />}
            />

            {props.isPresentable && <div className={styles.innerFocus}>
                <Switch>
                    <Route path="/static-data/configuration" render={() => <Configuration />} />
                    <Route render={() => <Redirect to={'/static-data/configuration'} />} />
                </Switch>
            </div>}

        </section>
    );
};

const StaticData = connect(mapStateToProps)(ConnectedStaticData);

export default StaticData;