import { Component } from 'react';
import Input from '../Input';
import styles from './SplitPiece.module.scss';
import stepStyles from './step-piece/StepPiece.module.scss';
import containerStyles from './container-piece/ContainerPiece.module.scss';
import Draggable, { DraggableEvent, DraggableData } from 'react-draggable';
import { Position } from '../../../shared/helpers/common-types';
import FlowchartPiece, { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { toggleAllPiecesExpansion, togglePieceExpansion, setNextPiece, setTargetPiece, startPieceDrag, stopPieceDrag } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';

import { setConditionPosition, setConditionPieces } from '../../../shared/store/flowchart/pieces/actions';
import { IfData, PieceType } from '../../../shared/store/flowchart/pieces/types';
import { nextPieceTarget, booleanPieceSlotTarget } from './utilities';
import { FlowchartContext, FlowchartInfoForPiece, PieceHighlightColour } from '../../../contexts/flowchart-context';
import store from '../../../shared/store/main';
import { translatePhrase } from '../../../shared/helpers/translation';
import { setErrorMessage, clearErrorMessage } from '../../../shared/store/my-data/actions';
import { getIfPieceId, getAllExpandablePiecesInPiece } from '../../../shared/store/flowchart/helpers/pieces';
import ExpandIcon from '../../../assets/flowchart/expand.svg';
import ExpandAllIcon from '../../../assets/flowchart/expand-all.svg';
import CollapseIcon from '../../../assets/flowchart/collapse.svg';
import CollapseAllIcon from '../../../assets/flowchart/collapse-all.svg';

export type IfPieceData = {
    position: Position,
    conditionPiece?: JSX.Element,
    nextPiece?: JSX.Element,
    ifPieceId: string
}

type SplitPieceProps = {
    pieceId: string,
    initialIfPieces?: Array<Partial<IfPieceData>>,
    nextPiece?: JSX.Element,
}

const mapStateToProps = (state: ApplicationState, ownProps: SplitPieceProps) => {
    const piece = state.flowchart.pieces.byId[ownProps.pieceId];

    if (piece.type !== PieceType.SPLIT) {
        throw new Error('A split can only be created from a split piece type');
    };

    const pieceData = state.flowchart.pieces;
    const isPieceExpanded = state.flowchart.pieces.isPieceExpanded;

    return {
        pieceData,
        isPieceExpanded,
        ifData: piece.ifPieceData,
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        draggedPieceSplitParents: state.flowchart.pieces.draggedPieceSplitParents ? state.flowchart.pieces.draggedPieceSplitParents : [],
        draggedPieceIfIndices: state.flowchart.pieces.draggedPieceIfIndices ? state.flowchart.pieces.draggedPieceIfIndices : [],
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        startPieceDrag: (pieceId: string, draggedPieceSplitParents: Array<string>, draggedPieceIfIndices: Array<number>) => dispatch(startPieceDrag(pieceId, draggedPieceSplitParents, draggedPieceIfIndices)),
        stopPieceDrag: () => dispatch(stopPieceDrag()),
        setConditionPosition: (pieceId: string, ifIndex: number, x: number, y: number) => dispatch(setConditionPosition(pieceId, ifIndex, x, y)),
        setConditionPieces: (pieceId: string, value: Array<IfData>) => dispatch(setConditionPieces(pieceId, value)),
        setTargetPiece: (pieceId: string | undefined) => dispatch(setTargetPiece(pieceId)),
        setNextPiece: (targetPieceId: string, draggedPieceId: string) => dispatch(setNextPiece(targetPieceId, draggedPieceId)),
        setErrorMessage: (message: string) => dispatch(setErrorMessage(message, true)),
        clearErrorMessage: () => dispatch(clearErrorMessage()),
        togglePieceExpansion: (pieceId: string, isExpanded: boolean) => dispatch(togglePieceExpansion(pieceId, isExpanded)),
        toggleAllPiecesExpansion: (pieceId: Array<string>, isExpanded: boolean) => dispatch(toggleAllPiecesExpansion(pieceId, isExpanded)),
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = SplitPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type SplitPieceState = {
    ifPositions: Array<Position>,
    isHoveringOverNextPiece: boolean,
    isHoveringOverConditionPiece: boolean,
    hoveringIfPieceIndex: number | undefined,

    ifKey: number,

    fullIfData: Array<IfData>,
}

const FIRST_IF_PIECE_OFFSET = 200;

class ConnectedSplitPiece extends Component<Props, SplitPieceState> {
    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            ifPositions: this.getCompleteIfPieceData().map(ifPiece => {
                return {
                    x: ifPiece.position.x,
                    y: ifPiece.position.y,
                };
            }),
            isHoveringOverNextPiece: false,
            isHoveringOverConditionPiece: false,
            hoveringIfPieceIndex: undefined,
            ifKey: 1,

            fullIfData: [],
        };
    }

    toggleSplitPieceExpansion = (noOfIfPieces: number) => {
        const isCurrentSplitPieceExpanded = this.props.isPieceExpanded[this.props.pieceId];
        const pieceIdsToExpand: Array<string> = [this.props.pieceId];
        for (let i = 0; i <= noOfIfPieces; i++) {
            pieceIdsToExpand.push(getIfPieceId(this.props.pieceId, i));
        };

        this.props.toggleAllPiecesExpansion(pieceIdsToExpand, !isCurrentSplitPieceExpanded);
    };

    toggleIfPieceExpansion = (index: number) => {
        const ifPieceId = getIfPieceId(this.props.pieceId, index);
        const isIfPieceExpanded = this.props.isPieceExpanded[ifPieceId];
        this.props.togglePieceExpansion(ifPieceId, !isIfPieceExpanded);
    };

    toggleIfPieceExpansionAll = (index: number) => {
        const ifPieceId = getIfPieceId(this.props.pieceId, index);
        const isExpanded = this.props.isPieceExpanded[ifPieceId];

        if (!this.props.ifData) {
            return;
        }

        const ifDatum = this.props.ifData[index];

        if (typeof ifDatum.nextPiece === 'undefined') {
            this.props.togglePieceExpansion(ifPieceId, !isExpanded);
            return;
        }

        const expandableIds: Array<string> = [ifPieceId].concat(getAllExpandablePiecesInPiece(ifDatum.nextPiece, this.props.pieceData));

        this.props.toggleAllPiecesExpansion(expandableIds, !isExpanded);
    };

    toggleExpansionAll = () => {
        const isExpanded = this.props.isPieceExpanded[this.props.pieceId];
        let expandableIds: Array<string> = [this.props.pieceId];

        if (this.props.ifData) {
            for (let i = 0; i < this.props.ifData.length; i += 1) {
                expandableIds.push(getIfPieceId(this.props.pieceId, i));

                const ifDatum = this.props.ifData[i];
                if (ifDatum.nextPiece) {
                    expandableIds = expandableIds.concat(getAllExpandablePiecesInPiece(ifDatum.nextPiece, this.props.pieceData));
                }
            }
        }

        this.props.toggleAllPiecesExpansion(expandableIds, !isExpanded);
    };

    handleHoverOverNextPiece = () => {
        this.setState({
            isHoveringOverNextPiece: true,
            hoveringIfPieceIndex: undefined,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        nextPieceTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOverNextIfPiece = (ifPieceIndex: number) => {
        const isIfPieceExpanded = this.props.isPieceExpanded[getIfPieceId(this.props.pieceId, ifPieceIndex)];

        if (!isIfPieceExpanded) {
            return;
        };

        this.setState({
            isHoveringOverNextPiece: true,
            hoveringIfPieceIndex: ifPieceIndex,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        };

        nextPieceTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfNextPiece = () => {
        this.setState({
            isHoveringOverNextPiece: false,
            hoveringIfPieceIndex: undefined,
        });
    };

    handleHoverOverConditionPiece = (ifPieceIndex: number) => {
        this.setState({
            isHoveringOverConditionPiece: true,
            hoveringIfPieceIndex: ifPieceIndex,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        booleanPieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfConditionPiece = () => {
        this.setState({
            isHoveringOverConditionPiece: false,
            hoveringIfPieceIndex: undefined,
        });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id) {

            if (typeof this.state.hoveringIfPieceIndex === 'undefined') {
                if (this.state.isHoveringOverNextPiece) {

                    if (this.props.nextPiece) {
                        const currentPiece = this.props.pieceData.byId[this.props.pieceId];

                        let followingPiece = this.props.lastDraggedPiece;

                        while ('nextPiece' in followingPiece && !!followingPiece.nextPiece) {
                            followingPiece = this.props.pieceData.byId[followingPiece.nextPiece];
                        }

                        if ('nextPiece' in currentPiece && currentPiece.nextPiece) {
                            if (followingPiece.type === PieceType.END || followingPiece.type === PieceType.RETURN ||
                                followingPiece.type === PieceType.SUCCESS || followingPiece.type === PieceType.ERROR) {
                                this.props.setErrorMessage(translatePhrase('Please remove the last piece and continue'));
                                setTimeout(() => {
                                    this.props.clearErrorMessage();
                                }, 4000);

                                this.setState({
                                    isHoveringOverNextPiece: false,
                                });
                                return;
                            };

                            this.props.setNextPiece(followingPiece.id, currentPiece.nextPiece);
                        }
                    }

                    this.props.setNextPiece(this.props.pieceId, this.props.lastDraggedPiece.id);
                    this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

                    this.setState({
                        isHoveringOverNextPiece: false,
                    });
                }
            } else {
                const newIfData = this.props.ifData || [];

                newIfData[this.state.hoveringIfPieceIndex] = {
                    ...newIfData[this.state.hoveringIfPieceIndex],
                };

                if (this.state.isHoveringOverNextPiece) {
                    const currentPiece = newIfData[this.state.hoveringIfPieceIndex];

                    if (currentPiece.nextPiece) {

                        let followingPiece = this.props.lastDraggedPiece;

                        while ('nextPiece' in followingPiece && !!followingPiece.nextPiece) {
                            followingPiece = this.props.pieceData.byId[followingPiece.nextPiece];
                        }

                        if (currentPiece.nextPiece) {
                            this.props.setNextPiece(followingPiece.id, currentPiece.nextPiece);
                        }
                    }

                    currentPiece.nextPiece = this.props.lastDraggedPiece.id;
                } else if (this.state.isHoveringOverConditionPiece) {
                    newIfData[this.state.hoveringIfPieceIndex].conditionPiece = this.props.lastDraggedPiece.id;
                }

                this.props.setConditionPieces(this.props.pieceId, newIfData);
                this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

                this.setState({
                    isHoveringOverNextPiece: false,
                    isHoveringOverConditionPiece: false,
                    hoveringIfPieceIndex: undefined,
                });

            }

        }
    }

    static getDerivedStateFromProps(props: Readonly<Props>, state: Readonly<SplitPieceState>) {
        if (props.ifData && props.ifData.length !== state.ifPositions.length) {
            return {
                ifPositions: props.ifData ? props.ifData.map((ifDatum, i) => {
                    return {
                        x: ifDatum.position ? ifDatum.position.x : FIRST_IF_PIECE_OFFSET + (i * 300),
                        y: ifDatum.position ? ifDatum.position.y : 50,
                    };
                }) : [],
                ifKey: state.ifKey + 1,
            };
        }

        return null;
    }

    handleDragStart = (e: DraggableEvent, data: DraggableData, ifPieceIndex: number, draggedPieceSplitParents: Array<string>, draggedPieceIfIndices: Array<number>) => {

        const isDragging = store.getState().flowchart.pieces.isDragging;

        if (!isDragging) {
            // Only stop the propagation if no other piece is being dragged yet
            e.stopPropagation();

            const nextPieceId = this.props.ifData ? this.props.ifData[ifPieceIndex].nextPiece : undefined;

            if (nextPieceId) {
                this.props.startPieceDrag(nextPieceId, draggedPieceSplitParents, draggedPieceIfIndices);
            }
        } else {
            // If another piece is already being dragged, cancel this drag event.
            // (The return cancels only this drag event - the parent's drag start handlers will still be called)
            return false;
        }
    }

    getCompleteIfPieceData = () => {
        const noOfIfPieces = this.props.initialIfPieces ? this.props.initialIfPieces.length : 1;
        const ifPieceData: Array<IfPieceData> = [];

        for (let i = 0; i < noOfIfPieces; i += 1) {
            if (this.props.initialIfPieces && this.props.initialIfPieces[i]) {
                const position = this.props.initialIfPieces[i].position;
                ifPieceData.push({
                    ...this.props.initialIfPieces[i],
                    position: {
                        x: position ? position.x : FIRST_IF_PIECE_OFFSET + (i * 300),
                        y: position ? position.y : 50,
                    },
                    ifPieceId: getIfPieceId(this.props.pieceId, i)
                });
            } else {
                ifPieceData.push({
                    conditionPiece: undefined,
                    nextPiece: undefined,
                    position: {
                        x: FIRST_IF_PIECE_OFFSET + (i * 300),
                        y: 50,
                    },
                    ifPieceId: getIfPieceId(this.props.pieceId, i)
                });
            }
        }

        return ifPieceData;
    };

    handleIfPieceDrag = (e: DraggableEvent, data: DraggableData, ifPieceIndex: number) => {
        const newIfPositions = this.state.ifPositions.slice(0);

        newIfPositions[ifPieceIndex] = {
            x: data.x,
            y: data.y,
        };

        this.setState({
            ifPositions: newIfPositions,
        });
    }

    handleIfPieceDragEnd = (ifPieceIndex: number, e: DraggableEvent, data: DraggableData) => {
        // this.props.setConditionPosition(this.props.pieceId, ifPieceIndex, data.x, data.y);

        this.props.stopPieceDrag();

        e.stopPropagation();
    }

    handleChange = (value: string) => {
        if (!isNaN(Number(value))) {
            this.setState((prevState, prevProps) => {
                const newNoOfPieces = Number(value);
                let newIfData = prevProps.ifData || [];
                let fullIfData = prevState.fullIfData.slice();
                let newIfPositions = prevState.ifPositions.slice(0);

                for (let i = 0; i < newIfData.length; i += 1) {
                    fullIfData[i] = {
                        ...newIfData[i],
                    };
                }

                if (newNoOfPieces > newIfData.length) {
                    for (let i = newIfData.length; i < newNoOfPieces; i += 1) {
                        if (i < fullIfData.length) {
                            newIfData.push({
                                conditionPiece: fullIfData[i].conditionPiece,
                                nextPiece: fullIfData[i].nextPiece,
                                position: {
                                    x: FIRST_IF_PIECE_OFFSET + (i * 300),
                                    y: 50,
                                }
                            });
                        } else {
                            const emptyIfSlot: IfData = {
                                conditionPiece: undefined,
                                nextPiece: undefined,
                                position: {
                                    x: FIRST_IF_PIECE_OFFSET + (i * 300),
                                    y: 50,
                                }
                            };

                            newIfData.push(emptyIfSlot);
                            fullIfData.push(emptyIfSlot);
                        }
                    }

                } else if (newNoOfPieces < newIfData.length) {
                    newIfData = newIfData.slice(0, newNoOfPieces);
                }

                prevProps.setConditionPieces(prevProps.pieceId, newIfData);

                return {
                    ifPositions: newIfPositions,
                    fullIfData,
                }

            });
        }
    }

    render() {
        return <FlowchartContext.Consumer>
            {
                (flowchartContext) => {
                    const ifPieces: Array<JSX.Element> = [];
                    const ifPieceData = this.getCompleteIfPieceData();
                    const noOfPieces = Math.min(ifPieceData.length, this.state.ifPositions.length);

                    const isSplitSelected = this.props.draggedPieceSplitParents.includes(this.props.pieceId);
                    const isFinalSplitSelected = this.props.draggedPieceSplitParents[this.props.draggedPieceSplitParents.length - 1] === this.props.pieceId;

                    let selectedIfIndex = -1;
                    if (isSplitSelected) {
                        selectedIfIndex = this.props.draggedPieceIfIndices[this.props.draggedPieceSplitParents.indexOf(this.props.pieceId)];
                    }

                    const highlightColor = flowchartContext.highlights && flowchartContext.highlights[this.props.pieceId];
                    let highlightClass = styles.noHighlight;

                    switch (highlightColor) {
                        case PieceHighlightColour.GREEN:
                            highlightClass = styles.addedHighlight;
                            break;
                        case PieceHighlightColour.YELLOW:
                            highlightClass = styles.updatedHighlight;
                            break;
                        case PieceHighlightColour.PURPLE:
                            highlightClass = styles.movedHighlight;
                            break;
                        case PieceHighlightColour.RED:
                            highlightClass = styles.deletedHighlight;
                            break;
                    }

                    for (let i = 0; i < noOfPieces; i += 1) {
                        const ifPieceDatum = ifPieceData[i];
                        const lineHeight = this.state.ifPositions[i].y - 30;
                        const xOffset = (i * 300) + FIRST_IF_PIECE_OFFSET;
                        const yOffset = (i * 80);

                        const splitPieceFlowchartContext: FlowchartInfoForPiece = {
                            flowchartHolderElement: flowchartContext.flowchartHolderElement,
                            projects: flowchartContext.projects,
                            variables: flowchartContext.variables,
                            parentSplitPieceIds: flowchartContext.parentSplitPieceIds.concat([this.props.pieceId]),
                            parentIfPieceIndices: flowchartContext.parentIfPieceIndices.concat(i),
                            invalidPieces: flowchartContext.invalidPieces,
                            isValidating: flowchartContext.isValidating,
                            isReadonly: flowchartContext.isReadonly,
                            highlightIncompletePieces: flowchartContext.highlightIncompletePieces,
                            highlights: flowchartContext.highlights,
                            setInvalidPiece: flowchartContext.setInvalidPiece,
                            removeInvalidPiece: flowchartContext.removeInvalidPiece,
                            searchTerm: '',
                        };

                        let ifPieceClass = styles.ifPiece;

                        if (selectedIfIndex !== -1) {
                            if (selectedIfIndex === i) {
                                if (isFinalSplitSelected) {
                                    ifPieceClass = styles.highlightedIfPiece;
                                } else {
                                    ifPieceClass = styles.selectedIfPiece;
                                }
                            } else {
                                ifPieceClass = styles.shadowedIfPiece;
                            }
                        };

                        const isIfPieceExpanded = this.props.isPieceExpanded[getIfPieceId(this.props.pieceId, i)] || flowchartContext.isValidating;
                        const isSplitPieceExpanded = this.props.isPieceExpanded[this.props.pieceId] || flowchartContext.isValidating;

                        const ifPiece = <Draggable key={i} cancel={'input'} disabled={this.props.isDragDisabled} onStart={(e, data) => this.handleDragStart(e, data, i, splitPieceFlowchartContext.parentSplitPieceIds, splitPieceFlowchartContext.parentIfPieceIndices)} onDrag={(e, data) => this.handleIfPieceDrag(e, data, i)} defaultPosition={{ x: xOffset, y: 50 + yOffset }} onStop={this.handleIfPieceDragEnd.bind(this, i)}>
                            <div className={ifPieceClass + ' children-clickable'}>
                                <div className={styles.ifLineDrop} style={{ top: -lineHeight - yOffset, height: lineHeight + yOffset }}><div className={styles.ifPieceCircle}></div></div>
                                <section className={stepStyles.aquaStepPiece}>
                                    <div className={stepStyles.filledUpperPieceLock}></div>
                                    <div className={stepStyles.spaceBetweenLocks}></div>

                                    {isIfPieceExpanded && <div className={stepStyles.lowerPieceLock}></div>}
                                    <div className={`${stepStyles.extendableUpperArm} ${!isIfPieceExpanded && stepStyles.isCollapsed}`}></div>

                                    <div className={stepStyles.visibleItems}>
                                        <div className={stepStyles.text}>if</div>
                                        {ifPieceDatum.conditionPiece || <div className={(this.state.isHoveringOverConditionPiece && typeof this.state.hoveringIfPieceIndex !== 'undefined' && this.props.isDragging && this.props.targetPiece ? styles.booleanIndicatorHovering : styles.booleanIndicator) + ' attachment-target'} onMouseOver={this.handleHoverOverConditionPiece.bind(this, i)} onMouseOut={this.handleHoverOutOfConditionPiece}></div>}
                                        <div className={stepStyles.text}>then</div>
                                    </div>

                                    {!flowchartContext.isValidating && <div className={styles.actionButtonWrapper}>
                                        <div data-expand-toggle="true" className={styles.expandButton} onClick={this.toggleIfPieceExpansion.bind(this, i)}>
                                            <span><img src={!isIfPieceExpanded ? ExpandIcon : CollapseIcon} alt="icon" />{!isIfPieceExpanded ? 'Expand' : 'Collapse'}</span>
                                        </div>

                                        <div data-expand-toggle="true" className={styles.expandButton} onClick={this.toggleIfPieceExpansionAll.bind(this, i)}>
                                            <span><img src={!isIfPieceExpanded ? ExpandAllIcon : CollapseAllIcon} alt="icon" />{!isIfPieceExpanded ? 'Expand All' : 'Collapse All'}</span>
                                        </div>

                                    </div>}
                                    <div className={isIfPieceExpanded && this.state.isHoveringOverNextPiece && typeof this.state.hoveringIfPieceIndex !== 'undefined' && this.state.hoveringIfPieceIndex === i && this.props.isDragging && this.props.targetPiece ? styles.nextPieceHovering : styles.nextPieceHoverable} onMouseOver={this.handleHoverOverNextIfPiece.bind(this, i)} onMouseOut={this.handleHoverOutOfNextPiece}></div>
                                </section>
                                {isIfPieceExpanded && <FlowchartContext.Provider value={splitPieceFlowchartContext}>
                                    {ifPieceDatum.nextPiece}
                                </FlowchartContext.Provider>}
                            </div>
                        </Draggable>;
                        ifPieces.push(ifPiece);
                    }

                    const rightMostPosition = Math.max(...this.state.ifPositions.map(ifPosition => ifPosition.x));

                    const horizontalLeftValue = 60;
                    const horizontalWidthValue = rightMostPosition + 5;

                    const isCurrentSplitPieceExpanded = this.props.isPieceExpanded[this.props.pieceId] || flowchartContext.isValidating;
                    const isSplitPieceExpanded = this.props.isPieceExpanded[this.props.pieceId] || flowchartContext.isValidating;

                    const isExpanded = isCurrentSplitPieceExpanded || isSplitPieceExpanded;

                    return (
                        <FlowchartPiece {...this.props}>
                            <div className={highlightClass + ' ' + (isExpanded ? styles.expandedPiece : '')}>
                                <section className={containerStyles.upperArm}>
                                    <div className={containerStyles.upperArmUpperPieceLock}></div>
                                    <div className={isExpanded ? containerStyles.spaceBetweenLocks : containerStyles.spaceBetweenCollapsedLocks}></div>

                                    <div className={containerStyles.extendableUpperArm}></div>

                                    <div className={containerStyles.upperArmContent}>
                                        <div className={containerStyles.text}>Split</div>
                                        <Input type="number" defaultText={this.props.initialIfPieces ? String(this.props.initialIfPieces.length) : '1'} onChange={this.handleChange} />
                                        <div className={containerStyles.text}>#{this.props.pieceId.substring(0, 5)}</div>
                                    </div>

                                    {!flowchartContext.isValidating && <div className={styles.actionButtonWrapper}>
                                        <div data-expand-toggle="true" className={styles.expandButton} onClick={this.toggleSplitPieceExpansion.bind(this, noOfPieces)}>
                                            <span><img src={!isCurrentSplitPieceExpanded ? ExpandIcon : CollapseIcon} alt="icon" />{!isCurrentSplitPieceExpanded ? "Expand" : "Collapse"}</span>
                                        </div>
                                        <div data-expand-toggle="true" className={styles.expandButton} onClick={this.toggleExpansionAll}>
                                            <span><img src={!isSplitPieceExpanded ? ExpandAllIcon : CollapseAllIcon} alt="icon" />{!isSplitPieceExpanded ? 'Expand All' : 'Collapse All'}</span>
                                        </div>
                                    </div>}

                                    {!isExpanded && <div className={(this.state.isHoveringOverNextPiece && this.props.isDragging && typeof this.state.hoveringIfPieceIndex === 'undefined' && this.props.targetPiece ? styles.nextPieceHovering : styles.nextPieceHoverable) + ' attachment-target'} onMouseOver={this.handleHoverOverNextPiece} onMouseOut={this.handleHoverOutOfNextPiece}></div>}

                                </section>

                                {isExpanded && <div className={styles.splitPieceLines}>
                                    <div className={styles.splitLineDrop}><div className={styles.splitPieceCircle}></div></div>
                                    <div className={styles.splitLineHorizontal} style={{ left: horizontalLeftValue, width: horizontalWidthValue }}></div>
                                </div>}

                                {isExpanded && <div className={containerStyles.innards}>
                                    <section className={containerStyles.leftArm}></section>
                                    <section className={containerStyles.innerContent}>
                                        {(isExpanded) && <div className={styles.ifPiecesHolder} key={this.state.ifKey}>{ifPieces}</div>}
                                    </section>
                                </div>}

                                <section className={isExpanded ? containerStyles.lowerArm : containerStyles.lowerCollapsedArm}>
                                    {isExpanded && <div className={containerStyles.lowerArmUpperPieceLock + ' ' + styles.lowerArmUpperPieceLock}></div>}
                                    {isExpanded && <div className={containerStyles.spaceBetweenLocks}></div>}

                                    <div className={containerStyles.lowerArmLowerPieceLock}></div>

                                    {isExpanded && <div className={containerStyles.extendableLowerArm}></div>}

                                    {isExpanded && <div className={containerStyles.lowerArmContent}></div>}
                                    {isExpanded && <div className={(this.state.isHoveringOverNextPiece && this.props.isDragging && typeof this.state.hoveringIfPieceIndex === 'undefined' && this.props.targetPiece ? styles.nextPieceHovering : styles.nextPieceHoverable) + ' attachment-target'} onMouseOver={this.handleHoverOverNextPiece} onMouseOut={this.handleHoverOutOfNextPiece}></div>}
                                </section>

                            </div>
                            <div className={selectedIfIndex === -1 ? containerStyles.nextPiece : containerStyles.shadowedNextPiece}>{this.props.nextPiece || undefined}</div>
                        </FlowchartPiece>
                    )
                }
            }
        </FlowchartContext.Consumer>
    }
}

const SplitPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedSplitPiece);

export default SplitPiece;