import React from 'react';
import styles from './RadioButton.module.scss';

type OwnProps = {
    isActive?: boolean,
    clickHandler?: () => void,
}

export const RadioButton: React.FC<OwnProps> = (props: OwnProps) => {
    return <div className={styles.radio + ' ' + (props.isActive ? styles.active : '')}
    onClick={() => props.clickHandler ? props.clickHandler : console.log("Just clicked a radio button")}></div>
}