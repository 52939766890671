import React, { ChangeEvent, useState } from 'react';
import { Link } from 'react-router-dom';
import styles from './AddPackage.module.scss';
import InputText, { OptionInput } from '../../../widgets/form/InputText';
import Checkbox from '../../../widgets/form/Checkbox';
import Button from '../../../widgets/button/CommonButton';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../shared/store/types';
import { IWorkflowType } from '../../../shared/store/workflows/types/types';
import { ExportedWorkflowType, exportWorkflowTypeCustomFields, ExportWorkflowTypeData, ExportedMemberType, exportCustomFields, ExportedGroupType } from '../../../shared/helpers/duplicate';
import { AllPieceTypes } from '../../../shared/store/flowchart/pieces/types';
import { IVariable } from '../../../shared/store/flowchart/variables/types';
import { NormalizedModel } from '../../../shared/store/normalized-model';
import { duplicatePiece } from '../../../shared/store/flowchart/helpers/pieces';
import uuid from 'uuid';
import { IUpdateableDataPackage, IUpdateableWorkflowPackage } from '../../../shared/store/package/types';
import marketImage from '../../../common/images/market_image.png';
import { addDataPackage, addWorkflowPackage } from '../../../shared/store/package/actions';
import { useHistory } from 'react-router';
import MultiSelectInput from '../../../widgets/form/MultiSelectInput';
import { IStaticDataHolder } from '../../../shared/store/static-info/types';
import { dataURLtoFile } from '../../../shared/helpers/utilities';
import axios from 'axios';
import { BASE_URL } from '../../../shared/store/url';
import LoaderModal from '../../../widgets/loader/LoaderModal';

export const AddPackage: React.FC = () => {
    const [shouldAddAffiliation, setShouldAddAffiliation] = useState(false);
    const [selectedProjectId, setSelectedProjectId] = useState<string | undefined>();
    const [selectedPackageType, setSelectedPackageType] = useState<'Workflow' | 'Data'>('Workflow');
    const [selectedPackageImageData, setSelectedPackageImageData] = useState<string>('');
    const [selectedWorkflowTypeId, setSelectedWorkflowTypeId] = useState<string | undefined>();
    const [selectedStaticDataId, setSelectedStaticDataId] = useState<string | undefined>();
    const [packageCategories, setPackageCategories] = useState<Array<string>>([]);

    const [packageName, setPackageName] = useState('');
    const [packageDescription, setPackageDescription] = useState('');

    const [errorMessage, setErrorMessage] = useState('');

    const dispatch = useDispatch();
    const history = useHistory();

    const projectsData = useSelector((state: ApplicationState) => state.structure.projects);
    const levelsData = useSelector((state: ApplicationState) => state.structure.levels);
    const rolesData = useSelector((state: ApplicationState) => state.structure.roles);

    const memberTypesData = useSelector((state: ApplicationState) => state.members.types);
    const groupTypesData = useSelector((state: ApplicationState) => state.groups.types);
    const workflowTypesData = useSelector((state: ApplicationState) => state.workflows.types);
    const variableState = useSelector((state: ApplicationState) => state.flowchart.variables);
    const pieceState = useSelector((state: ApplicationState) => state.flowchart.pieces);
    const staticDataHoldersData = useSelector((state: ApplicationState) => state.staticInfo);

    const myUserId = useSelector((state: ApplicationState) => state.myData.id);
    const myOrganizationId = useSelector((state: ApplicationState) => (state.organization as any).id);

    function toggleShouldAddAffiliation() {
        setShouldAddAffiliation(shouldAddAffiliation => !shouldAddAffiliation);
    }

    function getWorkflowExportData() {

        if (!selectedWorkflowTypeId) {
            return;
        }

        const workflowType = workflowTypesData.byId[selectedWorkflowTypeId];
        const project = projectsData.byId[workflowType.project];

        const exportedWorkflowType: ExportedWorkflowType = {
            id: workflowType.id,
            name: workflowType.name,
            affiliation: workflowType.affiliation,
            affiliatedEntity: workflowType.affiliatedEntity,
            isCore: workflowType.isCore,

            seedEntityVariable: workflowType.seedEntityVariable,
            seedAffiliationVariable: workflowType.seedAffiliationVariable,
            areMultipleInstancesAllowed: workflowType.areMultipleInstancesAllowed,

            startPiece: {
                piece: workflowType.startPiece.piece,
                position: workflowType.startPiece.position,
            },
            variables: workflowType.variables,

            levels: project.children.map(levelId => {
                const level = levelsData.byId[levelId];

                return {
                    id: levelId,
                    name: level.name,
                    customFields: exportCustomFields(levelsData, level.customFields),
                    roles: level.children.map(roleId => {
                        const role = rolesData.byId[roleId];

                        return {
                            id: roleId,
                            name: role.name,
                            customFields: exportCustomFields(rolesData, role.customFields),
                        };
                    }),
                };
            }),

            statuses: workflowType.statuses.map(statusId => {
                const status = workflowTypesData.statuses.byId[statusId];

                return {
                    id: status.id,
                    name: status.name,
                    isTerminal: status.isTerminal,
                    dueInDays: status.dueInDays,
                }
            }),
            customFields: exportWorkflowTypeCustomFields(workflowTypesData, workflowType.customFields),
        };

        const exportPieceState: NormalizedModel<AllPieceTypes> = {
            byId: {},
            allEntries: [],
            filteredEntries: [],

            createdIds: new Set(),
            updatedIds: new Set(),
            deletedIds: new Set(),
        };

        const exportVariableState: NormalizedModel<IVariable> = {
            byId: {},
            allEntries: [],
            filteredEntries: [],

            createdIds: new Set(),
            updatedIds: new Set(),
            deletedIds: new Set(),
        }

        const addPiece = (pieceData: AllPieceTypes) => {
            exportPieceState.byId[pieceData.id] = pieceData;

            if (!exportPieceState.allEntries.includes(pieceData.id)) {
                exportPieceState.allEntries.push(pieceData.id);
            }
        }

        const addVariable = (variable: IVariable) => {
            exportVariableState.byId[variable.id] = variable;

            if (!exportVariableState.allEntries.includes(variable.id)) {
                exportVariableState.allEntries.push(variable.id);
            }
        }

        const newId = duplicatePiece(pieceState, addPiece, undefined, workflowType.startPiece.piece);
        exportedWorkflowType.startPiece.piece = newId;

        for (const variableId of workflowType.variables) {
            const variable = variableState.byId[variableId];
            if (variable) {
                addVariable(variable);
            }
        }

        const exportInfo: ExportWorkflowTypeData = {
            workflowType: exportedWorkflowType,
            pieceState: exportPieceState,
            variableState: exportVariableState,
            affiliation: undefined,
        };

        for (const customField of exportedWorkflowType.customFields) {
            if (customField.isComputed && customField.startPiece) {
                const newId = duplicatePiece(pieceState, addPiece, undefined, customField.startPiece.piece);
                customField.startPiece.piece = newId;

                for (const variableId of customField.variables) {
                    const variable = variableState.byId[variableId];
                    if (variable) {
                        addVariable(variable);
                    }
                }
            }
        }

        if (shouldAddAffiliation && exportedWorkflowType.affiliatedEntity) {
            if (exportedWorkflowType.affiliation === 'member') {
                const memberType = memberTypesData.byId[exportedWorkflowType.affiliatedEntity];

                const exportedActions = memberType.actions.map(actionId => {
                    const action = memberTypesData.actions.byId[actionId];

                    return {
                        id: action.id,
                        name: action.name,
                        icon: action.icon,
                        workflowType: action.workflowType,
                    };
                });

                const exportedMemberType: ExportedMemberType = {
                    id: memberType.id,
                    name: memberType.name,
                    nameFieldId: memberType.nameFieldId,
                    subTitleFieldId: memberType.subTitleFieldId,
                    locationFieldId: memberType.locationFieldId,
                    customFields: exportCustomFields(memberTypesData, memberType.customFields),
                    actions: exportedActions,
                };

                for (const customField of exportedMemberType.customFields) {
                    if (customField.isComputed && customField.startPiece) {
                        const newId = duplicatePiece(pieceState, addPiece, undefined, customField.startPiece.piece);
                        customField.startPiece.piece = newId;

                        for (const variableId of customField.variables) {
                            const variable = variableState.byId[variableId];
                            if (variable) {
                                addVariable(variable);
                            }
                        }
                    }
                }

                exportInfo.affiliation = exportedMemberType;

            } else if (exportedWorkflowType.affiliation === 'group') {
                const groupType = groupTypesData.byId[exportedWorkflowType.affiliatedEntity];

                const exportedActions = groupType.actions.map(actionId => {
                    const action = groupTypesData.actions.byId[actionId];

                    return {
                        id: action.id,
                        name: action.name,
                        icon: action.icon,
                        workflowType: action.workflowType,
                    };
                });

                const exportedGroupType: ExportedGroupType = {
                    id: groupType.id,
                    name: groupType.name,
                    level: groupType.level,
                    isRequired: groupType.isRequired,
                    uniqueFieldId: groupType.uniqueFieldId,
                    nameFieldId: groupType.nameFieldId,
                    subTitleFieldId: groupType.subTitleFieldId,
                    customFields: exportCustomFields(groupTypesData, groupType.customFields),
                    actions: exportedActions,
                };

                for (const customField of exportedGroupType.customFields) {
                    if (customField.isComputed && customField.startPiece) {
                        const newId = duplicatePiece(pieceState, addPiece, undefined, customField.startPiece.piece);
                        customField.startPiece.piece = newId;

                        for (const variableId of customField.variables) {
                            const variable = variableState.byId[variableId];
                            if (variable) {
                                addVariable(variable);
                            }
                        }
                    }
                }

                exportInfo.affiliation = exportedGroupType;
            }
        }

        return exportInfo;
    }

    function addWorkflowPackageFromData(selectedPackageImageLink: string) {
        const exportData = getWorkflowExportData();

        if (!exportData) {
            return;
        }

        const workflowPackage: IUpdateableWorkflowPackage = {
            id: uuid.v4(),
            type: 'Workflow',
            name: packageName,
            description: packageDescription,
            categories: packageCategories,
            imageLinks: [selectedPackageImageLink],

            organization: myOrganizationId,
            author: myUserId,

            packageData: exportData,
            customFieldsCount: exportData.workflowType.customFields.length,
            hasAffiliation: shouldAddAffiliation,
        }

        dispatch(addWorkflowPackage(workflowPackage));

        history.push('/marketplace/packages');
    }



    function getAncestorChainOfFragment(fragmentId: string) {
        const fragmentState = staticDataHoldersData.fragments;

        const ancestorFragments: Array<string> = [];
        let upperFragment = fragmentState.byId[fragmentId];
        let parentId = upperFragment.parent || '';

        while (parentId in fragmentState.byId) {
            ancestorFragments.push(parentId);
            upperFragment = fragmentState.byId[parentId];
            parentId = upperFragment.parent || '';
        }

        return ancestorFragments;
    }

    function getStaticInfoData() {
        const staticInfoData: Array<Array<string>> = [];

        if (!selectedStaticDataId) {
            return [];
        }

        const selectedStaticDataHolder = staticDataHoldersData.byId[selectedStaticDataId];

        if (!selectedStaticDataHolder) {
            return staticInfoData;
        }

        for (const fragmentId of staticDataHoldersData.fragments.allEntries) {
            const fragment = staticDataHoldersData.fragments.byId[fragmentId];

            if (fragment.children.length === 0) {
                const fragmentRow: Array<string> = []
                const ancestorIds = getAncestorChainOfFragment(fragment.id).reverse();
                ancestorIds.push(fragment.id);

                const isAncestorArchived = ancestorIds.some(ancestorId => staticDataHoldersData.fragments.byId[ancestorId].archived);
                const isFragmentInStaticData = selectedStaticDataHolder.children.includes(ancestorIds[0]);

                if (isFragmentInStaticData && !isAncestorArchived) {

                    for (const ancestorId of ancestorIds) {
                        const ancestorFragment = staticDataHoldersData.fragments.byId[ancestorId];
                        fragmentRow.push(ancestorFragment.name);
                    }

                    staticInfoData.push(fragmentRow);

                }
            }
        };

        return staticInfoData;
    }

    function getImportFileHeader() {

        if (!selectedStaticDataId) {
            return [];
        }

        const selectedStaticDataHolder = staticDataHoldersData.byId[selectedStaticDataId];

        if (!selectedStaticDataHolder) {
            throw new Error('The static data holder must be selected')
        }

        if (selectedStaticDataHolder.children.length === 0) {
            return ['Data level 1', 'Data level 2', '...', 'Enter as many data levels as you would like'];
        }

        let longestLine: number = 0;

        for (const fragmentId of staticDataHoldersData.fragments.allEntries) {
            const fragment = staticDataHoldersData.fragments.byId[fragmentId];

            if (fragment.children.length === 0) {
                const ancestorIds = getAncestorChainOfFragment(fragment.id).reverse();
                ancestorIds.push(fragment.id);

                const isAncestorArchived = ancestorIds.some(ancestorId => staticDataHoldersData.fragments.byId[ancestorId].archived);
                const isFragmentInStaticData = selectedStaticDataHolder.children.includes(ancestorIds[0]);

                if (!isAncestorArchived && isFragmentInStaticData) {
                    longestLine = ancestorIds.length;
                }
            }
        }

        const headings: Array<string> = [];

        for (let i = 1; i <= longestLine; i += 1) {
            headings.push(`Data level ${i}`);
        }

        return headings;
    }

    function getStaticDataExportData() {
        const headers = getImportFileHeader();
        const data = getStaticInfoData();

        const exportData = [headers, ...data];

        return exportData;
    }

    function addStaticPackageFromData(selectedPackageImageLink: string) {

        const exportData = getStaticDataExportData();

        if (!exportData) {
            return;
        }

        let highestLevelsCount = 0;

        for (const row of exportData) {
            if (highestLevelsCount < row.length) {
                highestLevelsCount = row.length;
            }
        }

        const dataPackage: IUpdateableDataPackage = {
            id: uuid.v4(),
            type: 'Data',
            name: packageName,
            description: packageDescription,
            categories: packageCategories,
            imageLinks: [selectedPackageImageLink],

            organization: myOrganizationId,
            author: myUserId,

            packageData: exportData,
            levelsCount: highestLevelsCount,
        }

        dispatch(addDataPackage(dataPackage));

        history.push('/marketplace/packages');
    }

    function showErrorMessage(error: string) {
        setErrorMessage(error);

        window.setTimeout(() => {
            setErrorMessage('');
        }, 4000);
    }

    async function addPackageFromData() {

        if (!packageName) {
            showErrorMessage('Please enter a package name');
            return;
        }

        if (!packageDescription) {
            showErrorMessage('Please enter a package description');
            return;
        }

        if (packageCategories.length === 0) {
            showErrorMessage('Please select at least one category');
            return;
        }

        if (!selectedPackageImageData) {
            showErrorMessage('Please upload an image for use in package');
            return;
        }

        let selectedPackageImageLink: string | undefined;

        try {
            selectedPackageImageLink = await uploadFile();
        } catch (e) {
            if (e instanceof Error) {
                showErrorMessage('The image upload failed. Please try again');
            }
            return;
        }

        if (!selectedPackageImageLink) {
            showErrorMessage('The image upload failed. Please try again');
            return;
        }

        if (selectedWorkflowType) {
            addWorkflowPackageFromData(selectedPackageImageLink);
        } else if (selectedStaticDataType) {
            addStaticPackageFromData(selectedPackageImageLink);
        } else {
            showErrorMessage('Please select either a workflow or data type');
            return;
        }
    }

    const allProjects: Array<OptionInput> = useSelector((state: ApplicationState) => state.structure.projects.allEntries.map(projectId => {
        const project = state.structure.projects.byId[projectId];

        return {
            name: project.name,
            value: projectId,
        };
    }));

    const allWorkflowTypes: Array<IWorkflowType> = useSelector((state: ApplicationState) => state.workflows.types.allEntries.map(workflowTypeId => {
        return state.workflows.types.byId[workflowTypeId];
    }));

    const allStaticDataTypes: Array<IStaticDataHolder> = useSelector((state: ApplicationState) => state.staticInfo.allEntries.map(staticInfoId => {
        return state.staticInfo.byId[staticInfoId];
    }));

    const selectedWorkflowType = allWorkflowTypes.find(workflowType => workflowType.id === selectedWorkflowTypeId);
    const selectedStaticDataType = allStaticDataTypes.find(staticDataType => staticDataType.id === selectedStaticDataId);

    let filteredWorkflowTypes: Array<OptionInput> = [];
    let filteredStaticDataTypes: Array<OptionInput> = [];

    if (selectedProjectId) {
        filteredWorkflowTypes = allWorkflowTypes
            .filter(workflowType => workflowType.project === selectedProjectId)
            .map(workflowType => {
                let fullName = workflowType.name;

                if (workflowType.affiliation === 'member') {
                    if (workflowType.affiliatedEntity) {
                        const memberType = memberTypesData.byId[workflowType.affiliatedEntity];
                        fullName += ` (Affiliated with ${memberType.name})`
                    } else {
                        fullName += ` (Affiliated with a Member)`
                    }
                } else if (workflowType.affiliation === 'group') {
                    if (workflowType.affiliatedEntity) {
                        const groupType = groupTypesData.byId[workflowType.affiliatedEntity];
                        fullName += ` (Affiliated with ${groupType.name})`
                    } else {
                        fullName += ` (Affiliated with a Group)`
                    }
                }

                return {
                    name: fullName,
                    value: workflowType.id,
                };
            })

        filteredStaticDataTypes = allStaticDataTypes
            .map(staticDataType => {
                return {
                    name: staticDataType.name,
                    value: staticDataType.id,
                };
            });
    } else {
        filteredWorkflowTypes = allWorkflowTypes
            .map(workflowType => {
                let fullName = workflowType.name;

                if (workflowType.affiliation === 'member') {
                    if (workflowType.affiliatedEntity) {
                        const memberType = memberTypesData.byId[workflowType.affiliatedEntity];
                        fullName += ` (${memberType.name})`
                    } else {
                        fullName += ` (Member)`
                    }
                } else if (workflowType.affiliation === 'group') {
                    if (workflowType.affiliatedEntity) {
                        const groupType = groupTypesData.byId[workflowType.affiliatedEntity];
                        fullName += ` (${groupType.name})`
                    } else {
                        fullName += ` (Group)`
                    }
                }

                return {
                    name: fullName,
                    value: workflowType.id,
                };
            });

        filteredStaticDataTypes = allStaticDataTypes
            .map(staticDataType => {
                return {
                    name: staticDataType.name,
                    value: staticDataType.id,
                };
            });
    }

    function uploadFile(): Promise<string> {
        return new Promise((resolve, reject) => {
            const rawFileData = selectedPackageImageData;

            const startingIndex = rawFileData.indexOf('/') + 1;
            const endingIndex = rawFileData.indexOf(';');
            const extension = rawFileData.substring(startingIndex, endingIndex);
            const fileToUpload = dataURLtoFile(rawFileData, uuid.v4() + '.' + extension);

            var formData = new FormData();
            formData.append('file', fileToUpload);

            const uploadUrl = BASE_URL + '/file-upload/';
            try {
                axios.post(uploadUrl, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: 'Bearer ' + localStorage.getItem('token'),
                    }
                }).then(fileUploadResponse => {
                    if (fileUploadResponse.data && typeof fileUploadResponse.data === 'string') {
                        resolve(uploadUrl + fileUploadResponse.data);
                    } else {
                        reject();
                    }
                });
            } catch (e) {
                reject(e);
            }
        })
    }

    function handleFileInputChange(event: ChangeEvent<HTMLInputElement>) {
        if (event.target.files && event.target.files[0]) {
            const file: File = event.target.files[0];

            const reader = new FileReader();
            reader.onload = async e => {
                const rawFileData = String(reader.result);
                setSelectedPackageImageData(rawFileData);
            };

            reader.readAsDataURL(file);
        }
    }

    const categories = [
        'Health',
        'Agriculture',
        'Education',
        'Financial Inclusion',
        'Technology',
        'WASH',
        'Gender Based Violence',
        'Social Protection'
    ];

    return <section>

        <header className={styles.pageHeading}>
            <h1> Add your package </h1>
        </header>


        <section className={styles.addPackageForm}>
            <h4> Add {selectedPackageType} from: </h4>
            <div className={styles.formSegment}>
                <InputText placeholder={'Type'} default="Workflow" onChange={value => setSelectedPackageType(value as 'Workflow' | 'Data')} options={['Workflow', 'Data']} />
                <InputText placeholder={'Project'} onChange={value => setSelectedProjectId(value)} options={allProjects} />
                {selectedPackageType === 'Workflow' && <InputText placeholder={'Workflow'} onChange={value => setSelectedWorkflowTypeId(value)} options={filteredWorkflowTypes} />}
                {selectedPackageType === 'Data' && <InputText placeholder={'Static data'} onChange={value => setSelectedStaticDataId(value)} options={filteredStaticDataTypes} />}
            </div>

            <h4> Basic details </h4>
            <div className={styles.formSegment}>
                <InputText placeholder={'Name'} onChange={value => setPackageName(value)} />
                <InputText placeholder={'Description'} onChange={value => setPackageDescription(value)} />
                <MultiSelectInput
                    placeholder="Categories"
                    options={categories}
                    onChange={setPackageCategories}
                />
                <input id="package-image-file" className={styles.input} type="file" accept="image/*" onChange={handleFileInputChange} />
            </div>

            {selectedPackageType === 'Workflow' && selectedWorkflowType?.affiliatedEntity && <h4> Additional: </h4>}
            {selectedPackageType === 'Workflow' && selectedWorkflowType?.affiliatedEntity && <div className={styles.formSegment}>
                <Checkbox
                    name={'Affiliation'}
                    key={shouldAddAffiliation ? '1' : '0'}
                    value={shouldAddAffiliation ? 'true' : 'false'}
                    defaultChecked={shouldAddAffiliation}
                    toggle={toggleShouldAddAffiliation}
                />
            </div>}

            {myUserId === 'SuperUser' && <div className={styles.formButtonsHolder}>
                <Link to="/marketplace"> <Button type={'secondary'} padding={'0px 15px'} text={'Go Back'} isRounded /> </Link>
                <Button isDisabled={!packageName || !packageDescription || (!selectedWorkflowType && !selectedStaticDataType)} color={'contrast'} padding={'0px 15px'} text={'Add Package'} isRounded onClick={addPackageFromData} />
            </div>}
        </section>

        {errorMessage && <LoaderModal loaderText={[errorMessage]} isOutsideClickable isError />}
    </section>
}