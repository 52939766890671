import { FC, useState, useEffect } from 'react';


import styles from './GroupsTable.module.scss';

import GroupModify from './GroupModify';
import GroupFilter from './GroupFilter';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../shared/store/types';

import { addGroup, updateGroupRequest, deleteGroup, searchGroupTable, goToPageGroupTable, setPageSizeGroupTable, sortGroupTable, unArchiveGroup, setIsShowingAddFormForGroups, setIsShowingCSVFormForGroups, setIsShowingFilterFormForGroups, setIsShowingModifyFormForGroups, recomputeAllGroups, bulkDeleteGroups } from '../../../shared/store/groups/actions';
import { IUpdateableGroupData } from '../../../shared/store/groups/types';
import TableWithMeta from '../../../widgets/table/TableWithMeta';
import { getReadableDataForCustomField } from '../../../shared/store/custom-fields';
import { isUUID } from '../../../shared/helpers/utilities';
import { translatePhrase } from '../../../shared/helpers/translation';
import { IUpdateableWorkflowData } from '../../../shared/store/workflows/types';
import { addWorkflow } from '../../../shared/store/workflows/actions';

import { Permissions } from '../../../shared/store/permissions/types';
import { clearIndeterminateMessage, clearInfoMessage, clearToastMessage, setIndeterminateMessage, setInfoMessage, setToastMessage } from '../../../shared/store/my-data/actions';
import { TableHeading, TableRow } from '../../../widgets/table/Table';
import { getFilterTags, getFilteredGroups, getTotalNoOfMembers } from './selectors';

import GroupCSV from './GroupCSV';
import { NudgeType } from '../../../shared/store/my-data/types';
import { FieldType } from '../../../shared/store/custom-fields/types';
import { getAncestorChainOfLocation } from '../../../shared/helpers/locations';
import TableLocationList from '../../users/list/TableLocationList';
import { getActionableIdsAndBulkActionsSelector, getIsPartialFetch } from '../../../selectors/selectors';
import { clearTempFiles, commitTempFiles } from '../../../shared/store/file-operations';
import FireWorkFlowAction from '../../workflow-actions/FireWorkflowAction';

const mapStateToProps = (state: ApplicationState) => {

    const currentPageNumber = state.groups.currentPageNumber,
        pageSize = state.groups.pageSize,
        groupSearchTerm = state.groups.searchTerm

    const isOnline = state.myData.isOnline
    const totalNoOfGroups = getTotalNoOfMembers(state);
    const filteredGroups = getFilteredGroups(state);

    const { bulkActions, actionableIds } = getActionableIdsAndBulkActionsSelector(filteredGroups, state.groups.types)(state);

    return {
        groupsList: !isOnline ? filteredGroups.slice((currentPageNumber - 1) * pageSize, currentPageNumber * pageSize) : filteredGroups,
        actionableGroupIds: actionableIds,
        totalNoOfGroups,
        pageSize: pageSize,
        pageNumber: currentPageNumber,
        totalPages: Math.ceil(totalNoOfGroups / pageSize),
        tags: getFilterTags(state),

        usersData: state.users,
        myPermissions: state.permissions.myPermissions,

        isPartialFetch: getIsPartialFetch(state),

        searchTerm: groupSearchTerm,
        groupFilters: state.groups.filters,
        groupSort: state.groups.sort,

        projectsData: state.structure.projects,

        groupTypesData: state.groups.types,
        customFieldsData: state.groups.types.customFields,
        locationsData: state.structure.locations,
        permissionsData: state.permissions,
        membersData: state.members,
        workflowsData: state.workflows,
        workflowTypesData: state.workflows.types,

        areFiltersExpanded: false,
        bulkActions,

        organizationPlan: state.organization.plan,

        applicationState: state,
        myId: state.myData.id,
        selectedNudge: state.myData.selectedNudgeId,

        isShowingAddForm: !!state.groups.isShowingAddForm,
        isShowingModifyForm: !!state.groups.isShowingModifyForm,
        isShowingFilterForm: !!state.groups.isShowingFilterForm,
        isShowingCSVForm: !!state.groups.isShowingCSVForm,
        isOnline,
        isSuperUser: !isUUID(state.myData.id),
        token: state.myData.token,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        searchTable: (searchTerm: string) => dispatch(searchGroupTable(searchTerm)),
        goToPage: (page: number) => dispatch(goToPageGroupTable(page)),
        changePageSize: (size: number) => dispatch(setPageSizeGroupTable(size)),
        sort: (column: string, order: 'ASC' | 'DESC') => dispatch(sortGroupTable(column, order)),

        setToastMessage: (message: string) => dispatch(setToastMessage(message)),
        clearToastMessage: () => dispatch(clearToastMessage()),

        setInfoMessage: (message: string) => dispatch(setInfoMessage(message)),
        clearInfoMessage: () => dispatch(clearInfoMessage()),

        setIndeterminateMessage: (message: string) => dispatch(setIndeterminateMessage(message)),
        clearIndeterminateMessage: () => dispatch(clearIndeterminateMessage()),

        addGroup: (payload: IUpdateableGroupData) => dispatch(addGroup(payload)),
        deleteGroup: (id: string) => dispatch(deleteGroup(id)),
        bulkDeleteGroups: (ids: Array<string>) => dispatch(bulkDeleteGroups(ids)),
        unArchiveGroup: (id: string) => dispatch(unArchiveGroup(id)),
        recomputeAllGroups: () => dispatch(recomputeAllGroups()),
        updateGroup: (payload: IUpdateableGroupData) => dispatch(updateGroupRequest(payload)),
        addWorkflow: (payload: IUpdateableWorkflowData) => dispatch(addWorkflow(payload)),

        setIsShowingAddForm: (showValue: boolean) => dispatch(setIsShowingAddFormForGroups(showValue)),
        setIsShowingModifyForm: (showValue: boolean) => dispatch(setIsShowingModifyFormForGroups(showValue)),
        setIsShowingFilterForm: (showValue: boolean) => dispatch(setIsShowingFilterFormForGroups(showValue)),
        setIsShowingCSVForm: (showValue: boolean) => dispatch(setIsShowingCSVFormForGroups(showValue)),
    };
}

type OwnProps = {
    isReadOnly: boolean,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;

const ConnectedGroupsTable: FC<Props> = (props) => {
 
    const [selectedSmartFilter, setSelectedSmartFilter] = useState("");
    const [headings, setHeadings] = useState<TableHeading[]>([]);
    const [entries, setEntries] = useState<TableRow[]>([]);
    const [groupTypesToAdd, setGroupTypesToAdd] = useState(false);    

    const addBlur = () => {
        document.querySelector('#page-header')?.classList.add('blur');
        document.querySelector('#import-export-button-holder')?.classList.add('blur');
    }

    const removeBlur = () => {
        document.querySelector('#page-header')?.classList.remove('blur');
        document.querySelector('#import-export-button-holder')?.classList.remove('blur');
    }

    const handleCSVForm = () => {
        if (props.isShowingCSVForm) {
            removeBlur();
            props.setIsShowingCSVForm(false);
        } else {
            addBlur()
            props.setIsShowingCSVForm(true);
        }
    }

    const showAddForm = () => {
        addBlur();
        props.setIsShowingAddForm(true);
    }

    const showFilterForm = () => {
        addBlur();
        props.setIsShowingFilterForm(true);
    }

    const showModifyForm = () => {
        addBlur();
        props.setIsShowingModifyForm(true);
    }

    const hideGroupForm = async () => {
        await clearTempFiles();
        removeBlur();
        props.setIsShowingAddForm(false);
        props.setIsShowingModifyForm(false);
    }

    const hideFilterForm = () => {
        removeBlur();
        props.setIsShowingFilterForm(false);
    }

    const addGroup = async (groupData: IUpdateableGroupData) => {
        await commitTempFiles();
        removeBlur();
        props.addGroup(groupData);
        props.setIsShowingAddForm(false);
    }

    const updateGroup = async (groupData: IUpdateableGroupData) => {
        await commitTempFiles();
        removeBlur();
        props.updateGroup(groupData);
        props.setIsShowingModifyForm(false);
    }

    const deleteGroup = (id: string) => {
        props.deleteGroup(id);
        if (props.pageNumber !== 1 && props.groupsList.length === 0) {
            props.goToPage(props.pageNumber - 1);
        }
    }

    const unArchiveGroup = (id: string) => {
        props.unArchiveGroup(id);
        if (props.pageNumber !== 1 && props.groupsList.length === 0) {
            props.goToPage(props.pageNumber - 1);
        }
    }


    const recomputeGroupComputedFields = () => {
        props.setIndeterminateMessage('Recomputing group data');

        setTimeout(() => {
            props.recomputeAllGroups();
            props.clearIndeterminateMessage();
        }, 500);
    }

    const deleteAllGroups = () => {
        if (props.bulkActions) {
            props.setIndeterminateMessage('Archiving groups...');

            setTimeout(() => {
                props.bulkDeleteGroups(props.actionableGroupIds);
                props.clearIndeterminateMessage();
            }, 500);
        }
    }

    const getSmartFilter = (smartFilter: string) => {
        setSelectedSmartFilter(smartFilter)
    }

    const handleSort = (column: string) => {
        const isNewColumn = props.groupSort.column !== column;
        const sortOrder = !isNewColumn && props.groupSort.order === 'ASC' ? 'DESC' : 'ASC';
        props.sort(column, sortOrder);
    }

    const setTableHeadings = () => {
        const headings: Array<TableHeading> = [{
            name: 'Sl. no',
            isSortable: false,
            isSticky: true,
            width: 150,
        }, {
            name: 'Name',
            isSortable: false,
            isSticky: true,
            width: 120,
        }, {
            name: 'Subtitle',
            isSortable: false,
            isSticky: false,
            width: 160,
        }, {
            name: 'Type',
            isSortable: false,
            width: 100,
        }, {
            name: 'Location',
            isSortable: false,
            width: 150,
        }];

        if (props.groupFilters.types.length === 1 || props.groupTypesData.allEntries.length === 1) {
            let groupType = props.groupTypesData.byId[props.groupTypesData.allEntries[0]];

            if (props.groupFilters.types.length === 1) {
                groupType = props.groupTypesData.byId[props.groupFilters.types[0]];
            }

            groupType.customFields.forEach(customFieldId => {
                const customField = props.customFieldsData.byId[customFieldId];
                const width = customField.type === FieldType.TEXT ? 200 : 150;

                if (customFieldId === groupType.nameFieldId || customFieldId === groupType.subTitleFieldId) {
                    return;
                }

                if (customField.isInTable) {
                    headings.push({
                        name: customField.name,
                        isSortable: false,
                        width: width,
                    });
                }
            });
        }

        headings.push({
            name: 'Action',
            isSortable: false,
            width: 200,
        });

        setHeadings(headings);
    }

    const setTableEntries = () => {
        const entries: Array<TableRow> = props.groupsList
            .map<TableRow>((group, index) => {
                const groupType = props.groupTypesData.byId[group.type];

                const nameField = props.groupTypesData.customFields.byId[groupType.nameFieldId];
                let groupName = group.customFields[groupType.nameFieldId];

                groupName = getReadableDataForCustomField(groupName, nameField, group.id, 'group');

                let groupSubTitle = group.customFields[groupType.subTitleFieldId];
                const subTitleField = props.groupTypesData.customFields.byId[groupType.subTitleFieldId];

                groupSubTitle = getReadableDataForCustomField(groupSubTitle, subTitleField, group.id, 'group');

                const parentLocationIds = getAncestorChainOfLocation(group.location);
                parentLocationIds.reverse();
                const locationChain = parentLocationIds
                    .filter(locationId => locationId in props.locationsData.byId)
                    .concat([group.location])
                    .map(locationId => props.locationsData.byId[locationId].name)
                    .join(' > ');

                const locationName = group.location ? translatePhrase(props.locationsData.byId[group.location].name) : '-'
                const locationMarkup = <TableLocationList
                    locationChain={locationChain}
                    locationName={locationName} />

                const cells: Array<string | number | JSX.Element> = [
                    (props.pageNumber - 1) * props.pageSize + index + 1,
                    groupName,
                    groupSubTitle,
                    translatePhrase(props.groupTypesData.byId[group.type].name),
                    locationMarkup,
                ];

                if (props.groupFilters.types.length === 1 || props.groupTypesData.allEntries.length === 1) {
                    let groupType = props.groupTypesData.byId[props.groupTypesData.allEntries[0]];

                    if (props.groupFilters.types.length === 1) {
                        groupType = props.groupTypesData.byId[props.groupFilters.types[0]];
                    }

                    groupType.customFields.forEach(customFieldId => {
                        const customField = props.customFieldsData.byId[customFieldId];

                        if (!customField.isInTable) {
                            return;
                        }

                        if (customFieldId === groupType.nameFieldId || customFieldId === groupType.subTitleFieldId) {
                            return;
                        }

                        let customFieldValue = group.customFields[customFieldId];

                        let cellValue = getReadableDataForCustomField(customFieldValue, customField, group.id, 'group');

                        if (customField.type === FieldType.FILE && cellValue.startsWith('http')) {
                            cells.push(<a className={styles.fileLink} target="_blank" rel="noreferrer noopener" href={cellValue + '?token=' + props.token}>Link</a>);
                        } else {
                            cells.push(cellValue);
                        }
                    });
                }                

                cells.push(<FireWorkFlowAction affiliationEntity={{ type: 'group', entity: group }} key={index} />);

                let showViewInsteadOfEdit = props.isOnline;
                const editAction = props.groupTypesData.actions.byId[groupType.actions[1]];

                if (group.type in props.myPermissions.groups) {
                    if (props.myPermissions.groups[group.type] === Permissions.READ) {
                        showViewInsteadOfEdit = true;
                    }
                }

                const groupEditForm = <GroupModify groupId={group.id} submit={updateGroup} cancel={hideGroupForm} isReadOnly={showViewInsteadOfEdit || !!editAction.workflowType} />;

                const extraActions = groupType.actions.slice(3)
                    .map(actionId => {
                        const action = props.groupTypesData.actions.byId[actionId];

                        return {
                            name: action.name,
                            icon: action.icon,
                            workflowType: action.workflowType || '',
                        }
                    })
                    .filter(action => {
                        if (!action.workflowType) {
                            return false;
                        }

                        if (action.workflowType in props.myPermissions.workflows && props.myPermissions.workflows[action.workflowType] !== Permissions.WRITE) {
                            return false;
                        }

                        return true;
                    });

                const deleteAction = props.groupTypesData.actions.byId[groupType.actions[2]];
                let isDeleteAllowed = true;

                if (group.archived && props.myId !== 'SuperUser') {
                    isDeleteAllowed = false;
                } else if (props.myPermissions.groups[groupType.id] && props.myPermissions.groups[groupType.id] !== Permissions.WRITE) {
                    isDeleteAllowed = false;
                } else if (!!deleteAction.workflowType && props.myPermissions.workflows[deleteAction.workflowType]) {
                    isDeleteAllowed = props.myPermissions.workflows[deleteAction.workflowType] === Permissions.WRITE;
                }

                return {
                    id: group.id,
                    entries: cells,
                    extraActions,
                    isDeleteAllowed,
                    deleteWorkflowTypeId: isDeleteAllowed ? deleteAction.workflowType : undefined,
                    editForm: groupEditForm,
                    isDeleted: group.archived,
                    shareText: `Name: ${groupName}\nDescription: ${groupSubTitle}`,
                    showViewInsteadOfEdit,
                }
            });
        
        setEntries(entries);
    }

    const updateGroupTypesToAdd = () => {
        let groupTypesToAdd = false;

        const allowedMemberTypes = props.groupTypesData.allEntries
            .filter(typeId => {
                const groupType = props.groupTypesData.byId[typeId];
                const loggedInUser = props.myId ? props.usersData.byId[props.myId] : undefined;

                if (loggedInUser) {
                    return loggedInUser.projects.includes(groupType.project);
                } else {
                    return true;
                }
            })

        for (const groupTypeId of allowedMemberTypes) {

            if (props.myPermissions.groups[groupTypeId] === Permissions.WRITE || typeof props.myPermissions.groups[groupTypeId] === 'undefined') {
                groupTypesToAdd = true;
            }
        }

        setGroupTypesToAdd(groupTypesToAdd);
    }

    useEffect(() => {
        if (props.isOnline) {
            props.goToPage(1);
        }
        setTableHeadings();
        setTableEntries();
        updateGroupTypesToAdd();
    }, []);

    useEffect(() => {
        setTableHeadings();
    }, [props.groupFilters, props.groupTypesData]);

    useEffect(() => {
        setTableEntries();
    }, [props.groupsList]);

    useEffect(() => {
        updateGroupTypesToAdd();
    }, [props.groupTypesData]);


    let MAX_NO_OF_GROUPS = Infinity;

    if (props.organizationPlan === 'unlimited') {
        MAX_NO_OF_GROUPS = Infinity;
    }

    return <section>
            <TableWithMeta
                entityType="Group"
                headings={headings}
                entries={entries}

                sortedColumn={props.groupSort.column}
                sortType={props.groupSort.order}
                isReadOnly={!!props.isReadOnly}
                areFiltersExpanded={props.areFiltersExpanded}
                tags={props.tags}

                totalPages={props.totalPages}
                totalNoOfEntries={props.totalNoOfGroups}
                pageNumber={props.pageNumber}
                searchTerm={props.searchTerm}
                placeHolderForSearch="Search within the list below"
                isAddAllowed={groupTypesToAdd && props.totalNoOfGroups < MAX_NO_OF_GROUPS}
                isShowingAddForm={props.isShowingAddForm}
                isShowingModifyForm={props.isShowingModifyForm}
                addForm={<GroupModify submit={addGroup} cancel={hideGroupForm} />}

                isShowingFilterForm={props.isShowingFilterForm}
                showFilterForm={showFilterForm}
                filterForm={
                    <GroupFilter 
                        tags={props.tags} 
                        closeFilter={hideFilterForm} 
                        groupFilters={props.groupFilters} 
                        getSmartFilter={getSmartFilter} />}

                onSort={handleSort}
                onDelete={deleteGroup}
                onUnArchive={unArchiveGroup}
                showAddForm={showAddForm}
                showModifyForm={showModifyForm}
                reindexTable={props.isSuperUser && !props.isPartialFetch ? recomputeGroupComputedFields : undefined}
                archiveEntities={props.bulkActions && entries.length > 0 ? () => deleteAllGroups() : undefined}

                search={props.searchTable}
                changePageSize={props.changePageSize}
                goToPage={props.goToPage}
                sort={props.sort}
                handleCSVForm={handleCSVForm}
                isShowingCSVForm={props.isShowingCSVForm}

                isAddHighlighted={props.selectedNudge === NudgeType.GROUPS_ADD_GROUP}
                isFilterHighlighted={props.selectedNudge === NudgeType.GROUPS_FILTER}
                isSearchHighlighted={props.selectedNudge === NudgeType.GROUPS_SEARCH}
                isImportExportHighlighted={props.selectedNudge === NudgeType.GROUPS_IMPORT_EXPORT}
                isShowMoreHighlighted={props.selectedNudge === NudgeType.GROUPS_LIST_SHOW_MORE}
                selectedSmartFilter={selectedSmartFilter}
            />

            {props.isShowingCSVForm && <GroupCSV closeCSV={handleCSVForm} />}
        </section>
}

const GroupsTable = connect(mapStateToProps, mapDispatchToProps)(ConnectedGroupsTable);

export default GroupsTable;