import styles from "./FileDetails.module.scss";

import { ReactComponent as DownloadIcon } from '../../../common/assets/download-alt.svg';
import { FC } from "react";
import { DBSavedFiles } from "../../../shared/helpers/common-types";
import { translatePhrase } from "../../../shared/helpers/translation";

interface OwnProps {
    fileName: string;
    extension: string;
    downloadLocalFile?: (value: DBSavedFiles) => void;
    localSavedFile?: DBSavedFiles;
    fileSize?: string;
    fileLink?: string;
}


const FileDetails: FC<OwnProps> = (props: OwnProps) => {
    const { fileName, extension, downloadLocalFile, localSavedFile, fileSize, fileLink } = props;
    return (
        <div className={styles.fileDetailsContainer}>
            <div className={styles.fileName} title={fileName}>{fileName}</div>
            <div className={styles.fileDetails}>
                <span>{extension.toLocaleUpperCase()}</span>
                <span className={styles.dot}></span>
                <span>{fileSize && fileSize}</span>
                {(downloadLocalFile && localSavedFile) &&
                    <div className={styles.downloadButton} onClick={() => downloadLocalFile(localSavedFile)}>
                        <DownloadIcon />
                        <div>{translatePhrase("Download")}</div>
                    </div>
                }
                {fileLink &&
                    <a className={styles.downloadButton} href={fileLink} target='_blank' rel="noopener noreferrer">
                        <DownloadIcon />
                        <div>{translatePhrase("Download")}</div>
                    </a>
                }
                    
            </div>
        </div>
    );
}

export default FileDetails;