import { Component, createRef, KeyboardEvent, RefObject } from 'react';
import { ReactComponent as SearchIcon } from '../../assets/new-custom-icons/dashboard/search.svg';
import styles from './OptionsList.module.scss';
import Option from './Option';
import { Option as OptionType } from './InputText';
import { translatePhrase } from '../../shared/helpers/translation';

type OwnProps = {
    options: Array<OptionType>,
    isShowing: boolean,
    isAutoFocus?: boolean,
    isSearchDisabled?: boolean,
    onSelect: (selectedName: string, selectedValue: string) => void
};

type OwnState = {
    search: string,
    highlightedIndex: number | undefined,
};

class OptionsList extends Component<OwnProps, OwnState> {
    state: OwnState = {
        search: '',
        highlightedIndex: undefined,
    };

    inputRef: RefObject<HTMLInputElement>;
    optionsRef: RefObject<HTMLElement>;
    // availableOptions: Array<OptionType> = this.props.options;

    constructor(props: OwnProps) {
        super(props);
        this.inputRef = createRef();
        this.optionsRef = createRef();
    }

    handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        enum keyCodes { ENTER_KEY = 13, UP_KEY = 38, DOWN_KEY = 40 };

        // If the "Enter" key is pressed, select the highlighted option
        if (e.keyCode === keyCodes.ENTER_KEY) {
            if (this.state.highlightedIndex !== undefined) {

                const availableOptions = this.getAvailableOptions();
                const selectedOption = availableOptions[this.state.highlightedIndex];

                this.props.onSelect(selectedOption.name, selectedOption.value);
            }
        }

        if (e.keyCode === keyCodes.UP_KEY) {

            if (this.state.highlightedIndex === undefined) {

                // If no option is highlighted, highlight the last option...
                this.setState({
                    highlightedIndex: this.props.options.length - 1
                });
            } else {
                const currentOptionIndex = this.state.highlightedIndex;

                // If the highlighted option is the first option, highlight the last option. Otherwise, highlight the previous option
                const availableOptions = this.getAvailableOptions();
                const previousOptionIndex = currentOptionIndex > 0 ? currentOptionIndex - 1 : availableOptions.length - 1;

                this.setState({
                    highlightedIndex: previousOptionIndex
                });
            }

            setTimeout(() => {
                this.scrollToView();
            }, 200);

        }

        if (e.keyCode === keyCodes.DOWN_KEY) {
            if (this.state.highlightedIndex === undefined) {

                this.setState({
                    highlightedIndex: 0
                });

            } else {
                const availableOptions = this.getAvailableOptions();
                const currentOptionIndex = this.state.highlightedIndex;
                const nextOptionIndex = currentOptionIndex < availableOptions.length - 1 ? currentOptionIndex + 1 : 0;
                this.setState({
                    highlightedIndex: nextOptionIndex
                });
            }

            setTimeout(() => {
                this.scrollToView();
            }, 200);

        }

        this.setState({
            search: e.currentTarget.value,
        });

    }

    setSearch = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.currentTarget.value) {
            this.setState({
                search: e.currentTarget.value,
            });
            return;
        }

        this.setState({
            search: ''
        })

    }

    scrollToView = () => {

        if (this.state.highlightedIndex !== undefined) {
            if (this.optionsRef && this.optionsRef.current) {
                const index = this.state.highlightedIndex + 1;
                const option = this.optionsRef.current.children[index];
                if (option !== undefined) option.scrollIntoView({ block: "center" });
            }
        }
    }

    getAvailableOptions = () => {
        return this.state.search ?
            this.props.options.filter(option => option.name.toLocaleLowerCase().indexOf(this.state.search.toLocaleLowerCase().trim()) !== -1)
            :
            this.props.options;
    }

    render() {
        // These are options that contain the search term
        // this.availableOptions = this.state.search ?
        //     this.props.options.filter(option => option.name.toLocaleLowerCase().indexOf(this.state.search.toLocaleLowerCase().trim()) !== -1)
        //     :
        //     this.props.options;

        const availableOptions = this.getAvailableOptions();

        const optionsMarkup = availableOptions.map((option, index) => {
            return <Option
                key={option.id}
                name={translatePhrase(option.name)}
                isHighlighted={index === this.state.highlightedIndex}
                value={option.value}
                search={this.state.search}
                onClick={(name: string, value: string) => this.props.onSelect(name, value)} />;
        });

        const showingOptionElements = optionsMarkup.slice(0);

        // Todo: this code has to be moved to lifecycle method
        setTimeout(() => {
            if (this.props.isAutoFocus) {
                this.inputRef.current?.focus();
            }
        }, 200)

        return <section data-selector="options-list" ref={this.optionsRef} className={this.props.isShowing ? styles.optionsList : styles.hiddenOptionsList}>
            {!this.props.isSearchDisabled && <div className={styles.searchInputHolder}>
                <input ref={this.inputRef} className={styles.searchInput} type="text" placeholder="Search" onKeyDown={this.handleKeyPress} onKeyUp={this.setSearch} />
                <SearchIcon />
            </div>}
            {showingOptionElements}
        </section>;

    }

}

export default OptionsList;