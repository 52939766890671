import React, { Component, MouseEvent, ChangeEvent, useState } from 'react';
import styles from './CardsList.module.scss';
import { ReactComponent as CancelIcon } from '../../common/assets/close.svg';
import { translatePhrase } from '../../shared/helpers/translation';
import { Link } from "react-router-dom";
import { ReactComponent as StructureIcon } from '../../assets/navigation/structure.svg';
import { ReactComponent as ToggleDropdownIcon } from '../../assets/new-custom-icons/common/dropdown.svg';

import CountDown from './CountDown';

import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';

import EnhancedCard, { CardType, EnhancedTopCard } from './Card';
import { ReactComponent as MoreIcon } from "../../assets/new-custom-icons/common/more.svg";
import { ReactComponent as DeleteIcon } from "../../assets/new-custom-icons/dashboard/trash.svg";
import { ReactComponent as EditIcon } from '../../assets/new-custom-icons/common/edit.svg';
import { ReactComponent as UndoIcon } from '../../assets/undo.svg';
import { ReactComponent as CloseIcon } from "../../assets/action-icons/cancel.svg";
import { ReactComponent as ImportIcon } from '../../assets/new-custom-icons/common/import.svg';
import { ReactComponent as PlusIcon } from '../../assets/new-custom-icons/dashboard/plus.svg';


import Button from "../../widgets/button/CommonButton";
import { WidgetSearchTerm } from '../../shared/store/state-save/types';



function onCardDragEnd(reOrderCallback: (sourceIndex: number, destinationIndex: number) => void, result: DropResult) {
    const { destination, source } = result;

    if (!destination) {
        return;
    }

    if (destination.index === source.index) {
        return;
    }

    reOrderCallback(source.index, destination.index);
}


type OwnProps = {
    isSelected: boolean;
    isShowingEditVerticalForm: boolean;
    isShowingAddForm: boolean;
    isPotentiallyDraggable: boolean;
    modifyForm?: JSX.Element;
    modifyVerticalForm?: JSX.Element;
    heading: string;

    isReadOnly: boolean;
    isDeleteRestricted: boolean;
    isAddRestricted: boolean;
    isShowingEditForm: boolean;
    isCollapsible?: boolean;
    isShowMoreHighlighted?: boolean;

    isHighlightedImport?: boolean
    isHighlightedExport?: boolean


    cards?: Array<CardType>;
    selectedCard?: CardType;

    isTopLevel: boolean;
    isShowingIndex: boolean;
    isSearchable?: boolean;
    searchInput?: string;
    setSearchInput?: (searchString: string) => void;
    showCardCount?: boolean;

    subHeader?: string;
    flowChartLink?: string;

    onSelectCard: (id: string) => void;
    onUnselectCard: () => void;
    onReorderCards?: (sourceIndex: number, destinationIndex: number) => void;
    onEditCard: () => void;
    onDeleteCard: (id: string) => void;

    onEditVertical?: () => void;
    onDeleteVertical?: () => void;
    onAddCard?: () => void;
    onDuplicate?: () => void;
    onExport?: () => void;
    onImport?: () => void;
    onLocationExport?: () => void;
    showFlowchart?: () => void;
    onGenerateFlowchart?: () => void;

    searchTerm?: string;
    setSearchTermForComponentsWidget?: (searchTerm: string) => void

};

type OwnState = {
    deleteTimer: number | undefined;
    searchInput: string;
    collapseCard: boolean;
    showMoreOptions: boolean;
};

export default class CardsList extends Component<OwnProps, OwnState> {

    constructor(props: Readonly<OwnProps>) {
        super(props);
        this.state = {
            deleteTimer: undefined,
            searchInput: props.searchInput ? props.searchInput : "",
            collapseCard: false,
            showMoreOptions: false,
        };
    }

    static defaultProps = {
        isSelected: true,
        isShowingEditVerticalForm: false,
        isShowingAddForm: false,
        isPotentiallyDraggable: true,
        isTopLevel: false,
        isShowingIndex: true,

        isReadOnly: false,
        isDeleteRestricted: false,
        isAddRestricted: false,
        isShowingEditForm: false,
    }

    deleteCardList = () => {
        this.props.onDeleteVertical && this.props.onDeleteVertical();
    }

    markForDelete = () => {
        let that = this;
        const timeout = window.setTimeout(that.deleteCardList, 5000);
        this.setState({
            deleteTimer: timeout
        });
    }

    cancelDelete = () => {
        window.clearTimeout(this.state.deleteTimer);
        this.setState({
            deleteTimer: undefined
        });
    }

    componentDidMount = () => {
        if (this.props.isCollapsible) {
            this.setState({
                collapseCard: true
            });
        } else {
            this.setState({
                collapseCard: false
            });
        }
    }

    handleClick = (e: MouseEvent) => {
        e.stopPropagation();
        this.state.deleteTimer && this.cancelDelete();
    }

    handleHeaderClick = (e: MouseEvent) => {
        e.stopPropagation();
        if (this.props.isCollapsible && this.props.cards && this.props.cards.length > 0) {
            this.setState({
                collapseCard: !this.state.collapseCard
            });

            if (this.props.isShowingAddForm) {
                this.props.onAddCard && this.props.onAddCard();
            }
        }
    }

    collapseOpenedCard = () => {
        if (this.props.isCollapsible) {
            setTimeout(() => {
                this.setState({
                    collapseCard: true
                })
            });
        }
    }

    openCollapsedCard = () => {
        if (this.props.isCollapsible) {
            setTimeout(() => {
                this.setState({
                    collapseCard: false
                })
            });
        }
    }

    handleDragEnd = (result: DropResult) => {
        this.props.onReorderCards && onCardDragEnd(this.props.onReorderCards, result)
    }

    changeSearchInput = (e: ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;
        this.setState({
            searchInput: inputValue,
        });
        this.props.setSearchInput && this.props.setSearchInput(inputValue);
        this.props.setSearchTermForComponentsWidget && this.props.setSearchTermForComponentsWidget(inputValue)
    }

    checkIsMultipleActionButton = () => {
        let count = 0;
        if (!this.props.isReadOnly && this.props.modifyVerticalForm) {
            count = count + 1;
        }

        if (!this.props.isDeleteRestricted) {
            count = count + 1;
        }

        return count > 1;
    }

    handleImport = () => {
        const fileInput = document.getElementById('import-file-input') as HTMLInputElement;
        fileInput.value = ''; // Reset the input value
        document.getElementById("import-file-input")?.click();
    }


    render() {
        let cards: Array<JSX.Element> = [];

        if (this.props.cards) {

            if (this.props.isTopLevel) {
                cards = this.props.cards
                    .filter(card => {
                        if (!this.state.searchInput) {
                            return true;
                        }

                        const isSearchInName = card.name.toLocaleLowerCase().includes(this.state.searchInput.toLocaleLowerCase());
                        const isSearchInDetails = card.details && card.details.toLocaleLowerCase().includes(this.state.searchInput.toLocaleLowerCase());

                        return isSearchInName || isSearchInDetails;
                    })
                    .map((card, index) => {
                        return <EnhancedTopCard
                            key={card.id}
                            isReadonly={this.props.isReadOnly || !!card.isReadOnly}
                            isDeleteRestricted={this.props.isDeleteRestricted}
                            isDragDisabled={this.props.isReadOnly || this.props.isDeleteRestricted}
                            index={index}
                            card={card}
                            onSelectCard={() => { this.props.onSelectCard(card.id) }}
                            onUnselectCard={this.props.onUnselectCard}
                            onDeleteCard={() => { this.props.onDeleteCard(card.id) }}
                            onEditCard={this.props.onEditCard}
                            isActive={this.props.selectedCard ? this.props.selectedCard.id === card.id : false}
                            modifyForm={this.props.modifyForm}
                            isShowingIndex={this.props.isShowingIndex}
                            isShowingAddForm={this.props.isShowingAddForm}
                            isShowingEditForm={this.props.isShowingEditForm}
                            isPotentiallyDraggable={this.props.isPotentiallyDraggable}
                            onDuplicate={this.props.onDuplicate}
                            onExport={this.props.onExport}
                            onLocationExport={this.props.onLocationExport}
                            onGenerateFlowchart={this.props.onGenerateFlowchart}
                            isHighlightedExport={this.props.isHighlightedExport}
                            isHighlightedImport={this.props.isHighlightedImport}
                            cancelForm={this.props.onAddCard}
                        />
                    });
            } else {
                cards = this.props.cards
                    .filter(card => {
                        if (!this.state.searchInput) {
                            return true;
                        }

                        const isSearchInName = card.name.toLocaleLowerCase().includes(this.state.searchInput.toLocaleLowerCase());
                        const isSearchInDetails = card.details && card.details.toLocaleLowerCase().includes(this.state.searchInput.toLocaleLowerCase());

                        return isSearchInName || isSearchInDetails;
                    })
                    .map((card, index) => {
                        return <EnhancedCard
                            key={card.id}
                            isReadonly={this.props.isReadOnly || !!card.isReadOnly}
                            isDeleteRestricted={this.props.isDeleteRestricted || !!card.isDeleteRestricted}
                            isDragDisabled={this.props.isReadOnly || this.props.isDeleteRestricted}
                            index={index}
                            card={card}
                            onSelectCard={() => { this.props.onSelectCard(card.id) }}
                            onUnselectCard={this.props.onUnselectCard}
                            onDeleteCard={() => { this.props.onDeleteCard(card.id) }}
                            onEditCard={this.props.onEditCard}
                            isActive={this.props.selectedCard ? this.props.selectedCard.id === card.id : false}
                            modifyForm={this.props.modifyForm}
                            isShowingIndex={this.props.isShowingIndex}
                            isShowingAddForm={this.props.isShowingAddForm}
                            isShowingEditForm={this.props.isShowingEditForm}
                            isPotentiallyDraggable={this.props.isPotentiallyDraggable}
                            onDuplicate={this.props.onDuplicate}
                            onExport={this.props.onExport}
                            onLocationExport={this.props.onLocationExport}
                            onGenerateFlowchart={this.props.onGenerateFlowchart}
                            isHighlightedExport={this.props.isHighlightedExport}
                            isHighlightedImport={this.props.isHighlightedImport}
                            cancelForm={this.props.onAddCard}
                            showFlowchart={this.props.showFlowchart}
                        />
                    });
            }

        }

        const actionButtonMarkUp = <div className={styles.actionButton}>
            {!this.props.isReadOnly && this.props.modifyVerticalForm &&
                <Button
                    title={translatePhrase('Edit')}
                    isDisabled={this.props.isShowingAddForm || this.props.isShowingEditForm}
                    size={'small'}
                    onClick={this.props.onEditVertical}
                    icon={<EditIcon />}
                    type={'secondary'}
                    isRounded={true} />
            }
            {!this.props.isDeleteRestricted &&
                <div className={styles.deleteButton}>
                    <Button
                        title={translatePhrase('Delete')}
                        size={'small'}
                        onClick={this.markForDelete}
                        icon={<DeleteIcon />}
                        type={'secondary'}
                        isRounded={true}
                        isDisabled={this.props.isDeleteRestricted}
                        isDanger={true} />
                </div>
            }
        </div>

        const className = this.props.isShowingEditVerticalForm ? styles.editingCardsList : (this.state.collapseCard ? styles.cardsList + ' ' + styles.collapse : styles.cardsList) + ' ignore-react-onclickoutside ignore-top-level-onclickoutside';
        const cardsListMarkup = this.state.deleteTimer ?
            <div className={styles.undoDelete} onClick={this.cancelDelete}>{translatePhrase('Undo')} <UndoIcon /> <CountDown remaining={5} color={'#ea7a7a'} /></div>
            :
            <div>{this.props.isShowingEditVerticalForm ?
                <div>
                    <header>
                        <div className={styles.heading}>
                            {translatePhrase(this.props.heading)}
                            {this.props.showCardCount && <div>
                                {this.props.cards && <span> {this.props.cards.length} {translatePhrase('defined')} </span>}
                            </div>}
                        </div>
                    </header>
                    <div className={styles.content + ' ' + styles.verticalForm}>
                        {this.props.modifyVerticalForm}
                    </div>
                </div>
                :
                <div>
                    <header>
                        {this.props.isCollapsible && this.props.cards && this.props.cards.length > 0 &&
                            <section className={styles.cardButton + ' ' + styles.dropdown + ' ' + (!this.state.collapseCard ? styles.activeDropdown : '')}
                                onClick={this.handleHeaderClick} title={translatePhrase('Click to expand')}>
                                <ToggleDropdownIcon />
                            </section>
                        }
                        <div className={styles.heading + ' ' + (this.state.showMoreOptions ? styles.width : '')} title={translatePhrase(this.props.heading)} onClick={this.handleHeaderClick}>
                            {translatePhrase(this.props.heading)}
                            {this.props.showCardCount && !this.props.subHeader && <div>
                                {this.props.cards && <span> {this.props.cards.length} {translatePhrase('defined')} </span>}
                            </div>}

                            {this.props.subHeader && !this.props.showCardCount && <div title={this.props.subHeader}>
                                <span> {translatePhrase(this.props.subHeader)} </span>
                            </div>}

                        </div>

                        {this.props.onImport && !this.props.isReadOnly &&
                            <label className={styles.import + ' ' + (this.props.isHighlightedImport ? styles.isHighlighted : '')}
                                onClick={this.props.onImport}
                                htmlFor="import-file-input"> <Button title={translatePhrase('Import')} onClick={this.handleImport} icon={<ImportIcon />} isRounded type={"secondary"} size={"small"} /> </label>}

                        {
                            !this.props.isAddRestricted && !this.state.deleteTimer && !this.props.isShowingEditVerticalForm && !this.props.isReadOnly && this.props.modifyForm &&
                            !this.props.isShowingAddForm && !this.props.isShowingEditForm &&

                            <section className={this.state.showMoreOptions ? styles.hideButton : ''}>
                                <Button onClick={() => { this.props.onAddCard && this.props.onAddCard(); this.openCollapsedCard(); }}
                                    title={translatePhrase('Add')}
                                    icon={<PlusIcon />}
                                    size={"small"}
                                    color={'contrast'}
                                    type={'primary'}
                                    isRounded />
                            </section>
                        }

                        {
                            this.props.flowChartLink &&
                            <section className={styles.cardButton + ' ' + (this.state.showMoreOptions ? styles.hide : '')}>
                                <Link to={this.props.flowChartLink} title={translatePhrase('Configure Workflow')}>
                                    <StructureIcon />
                                </Link>
                            </section>
                        }


                        {(this.props.isShowingAddForm || this.props.isShowingEditForm) &&
                            <Button onClick={() => { this.props.onAddCard && this.props.onAddCard(); this.collapseOpenedCard() }}
                                title={translatePhrase('Cancel')}
                                icon={<CancelIcon />}
                                size={"small"}
                                color={'contrast'}
                                type={'primary'}
                                isRounded />
                        }

                        {!this.state.showMoreOptions && !this.props.isReadOnly && this.props.modifyVerticalForm && this.checkIsMultipleActionButton() &&
                            <Button
                                isHighlighted={this.props.isShowMoreHighlighted}
                                title={translatePhrase('More')}
                                size={'small'}
                                onClick={() => {
                                    setTimeout(() => this.setState({ showMoreOptions: true }), 100);
                                }}
                                icon={<MoreIcon />}
                                type={'secondary'}
                                isRounded={true} />
                        }

                        {!this.state.showMoreOptions && !this.props.isReadOnly && this.props.modifyVerticalForm && !this.checkIsMultipleActionButton() &&
                            actionButtonMarkUp
                        }

                        <div className={styles.moreOptionsHolder + ' ' + (this.state.showMoreOptions ? styles.active : '')}>
                            <ul className={styles.moreOptions}>
                                <div className={styles.actionButtonsColumn}>
                                    {!this.props.isReadOnly && this.props.modifyVerticalForm &&
                                        <Button onClick={this.props.onEditVertical}
                                            title={translatePhrase('Edit')}
                                            isDisabled={this.props.isShowingAddForm || this.props.isShowingEditForm}
                                            icon={<EditIcon />}
                                            size={"small"}
                                            isRounded
                                            color={'primary'}
                                            type={'secondary'} />
                                    }
                                    {!this.props.isDeleteRestricted &&
                                        <Button onClick={this.markForDelete}
                                            title={translatePhrase('Delete')}
                                            icon={<DeleteIcon />}
                                            isDisabled={this.props.isDeleteRestricted}
                                            isRounded
                                            size={"small"}
                                            color={"danger"}
                                            type={'secondary'} />
                                    }
                                    <Button onClick={() => this.setState({ showMoreOptions: false })}
                                        title={translatePhrase('Close')}
                                        icon={<CloseIcon />}
                                        size={"small"}
                                        isRounded
                                        type={'secondary'} />
                                </div>
                            </ul>
                        </div>

                    </header>
                    {this.props.isSearchable && this.props.cards && this.props.cards.length > 0 && <input className={styles.searchBox} type="search" defaultValue={this.state.searchInput} onChange={this.changeSearchInput} placeholder={translatePhrase('Search') + '...'}></input>}
                    {this.props.children
                        ?
                        <div className={styles.content}>
                            <ul className={styles.structureList}>
                                <div>
                                    {this.props.children}
                                </div>
                            </ul>
                        </div>
                        :
                        cards.length > 0 && <div className={styles.content}>
                            <ul className={styles.structureList}>
                                {cards}
                            </ul>
                        </div>
                    }
                </div>}
            </div>

        const cardsListFooter = !this.props.isAddRestricted && !this.state.deleteTimer && !this.props.isShowingEditVerticalForm && !this.props.isReadOnly && this.props.modifyForm && this.props.isShowingAddForm && this.props.isSelected &&
            <footer><div className={styles.modifyFormHolder}>  {this.props.modifyForm} </div></footer>;

        if (!this.props.isPotentiallyDraggable) {
            return <section
                className={className + (this.props.isCollapsible ? ' ' + styles.collapsible : '')}
                onClick={this.handleClick}
            >
                {cardsListMarkup}
                {cardsListFooter}
            </section>;
        } else {
            return <DragDropContext onDragEnd={this.handleDragEnd}>
                <Droppable droppableId={this.props.heading}>
                    {(provided, snapshot) => (
                        <section
                            className={className + (this.props.isCollapsible ? ' ' + styles.collapsible : '')}
                            ref={provided.innerRef}
                            onClick={this.handleClick}
                        >
                            {cardsListMarkup}
                            {provided.placeholder}
                            {cardsListFooter}
                        </section>)
                    }
                </Droppable>
            </DragDropContext>
        }
    }

}