import { NudgeData } from "./index";
import { ApplicationState } from "../../../shared/store/types";
import { NudgeType } from "../../../shared/store/my-data/types";

export function getDashboardNudges(applicationState: ApplicationState): Array<NudgeData> {
    let nudgesData: Array<NudgeData> = [{
        id: NudgeType.DASHBOARD_RELOAD_ALL,
        header: 'Reload all widgets',
        content: 'Click on the reload button near the top right of the screen to refresh all data for all visible widgets',
    }];

    const isSuperUser = applicationState.myData.id === 'SuperUser';

    if (isSuperUser) {
        nudgesData.push({
            id: NudgeType.DASHBOARD_ADD_NEW,
            header: 'Add new widget',
            content: 'Click on the "Add Widget" button which will allow you to add a new widget',
        });
    }

    let widgetIdsInRoles: Array<string> = [];
    const myRoles = applicationState.users.byId.hasOwnProperty(applicationState.myData.id) ? applicationState.users.byId[applicationState.myData.id].roles : [];

    for (const roleId of myRoles) {
        const widgetIdsInRole = roleId && roleId in applicationState.widgets.byRole ? applicationState.widgets.byRole[roleId] : [];
        widgetIdsInRoles = widgetIdsInRoles.concat(widgetIdsInRole);
    }

    const newWidgetIds = Array.from(new Set(widgetIdsInRoles.filter(widgetId => !applicationState.myData.widgets.myWidgets.includes(widgetId))));
    const myWidgetIds = Array.from(new Set(applicationState.myData.widgets.myWidgets));

    const allWidgetIds = newWidgetIds.concat(myWidgetIds).filter(widgetId => !applicationState.widgets.byId[widgetId].archived);

    const hasAggregatedWidgets = allWidgetIds.some(widgetId => applicationState.widgets.byId[widgetId].aggregation !== 'none');
    const hasMessageWidgets = allWidgetIds.some(widgetId => applicationState.widgets.byId[widgetId].displayType === 'message');
    const hasGraphicalWidgets = allWidgetIds.some(widgetId => {
        const widget = applicationState.widgets.byId[widgetId]
        
        return widget.displayType !== 'message' && widget.aggregation === 'none';
    });

    const hasMultiViewWidgets = allWidgetIds.some(widgetId => {
        const widget = applicationState.widgets.byId[widgetId]
        return widget.displayType !== 'message' && widget.displayType !== 'table' && widget.aggregation === 'none';
    });

    const hasTableWidgets = allWidgetIds.some(widgetId => {
        const widget = applicationState.widgets.byId[widgetId]
        
        return widget.displayType === 'table';
    });

    if (hasTableWidgets) {
        nudgesData.push({
            id: NudgeType.DASHBOARD_SEARCH_WIDGET,
            header: 'Search widget',
            content: 'Click on the search button on a table widget that will allow you to search the table data',
        });
    }

    if (hasGraphicalWidgets) {
        nudgesData.push({
            id: NudgeType.DASHBOARD_FILTER_WIDGET,
            header: 'Filter widget',
            content: 'Click on the filter button on a widget that will allow you to filter the data set of the widget',
        });
    }

    if (allWidgetIds.length > 0) {
        nudgesData.push({
            id: NudgeType.DASHBOARD_SHOW_MORE_WIDGET_OPTIONS,
            header: 'Show more widget options',
            content: 'Click on the more button to expand the options available for a widget',
        });
    }

    if (hasMultiViewWidgets) {
        nudgesData.push({
            id: NudgeType.DASHBOARD_SWITCH_WIDGET_VIEW,
            header: 'Switch widget view',
            content: 'Click on any of these options to switch between multiple views for the widget',
        });
    }

    return nudgesData;
}