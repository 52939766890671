import React, { Component } from 'react';
import styles from './GetValuePiece.module.scss';
import Input from '../Input';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import FlowchartPiece, { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setWorkflowType, setVariablePiece } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import { valuePieceSlotTarget } from './utilities';
import { FlowchartContext, FlowchartInfoForPiece, PieceHighlightColour } from '../../../contexts/flowchart-context';
import { VariableType } from '../../../shared/store/flowchart/variables/types';
import DropDownSearchBox from '../drop-down/DropDownSearchBox';


type GetAffiliationPieceProps = {
    workflowType?: string,

    variablePiece?: JSX.Element,
    variableType?: VariableType,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,

        projectsData: state.structure.projects,
        levelsData: state.structure.levels,
        rolesData: state.structure.roles,
        userData: state.users,
        memberTypes: state.members.types,
        groupTypes: state.groups.types,
        workflowTypes: state.workflows.types,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string | undefined) => dispatch(setTargetPiece(pieceId)),
        setWorkflowType: (targetPieceId: string, workflowTypeId: string) => dispatch(setWorkflowType(targetPieceId, workflowTypeId)),
        setVariablePiece: (targetPieceId: string, draggedPieceId: string) => dispatch(setVariablePiece(targetPieceId, draggedPieceId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = GetAffiliationPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type GetPieceState = {
    isHoveringOverVariablePiece: boolean,
    workflowTypeSearchTerm: string,
}

class ConnectedGetAffiliationForWorkflowPiece extends Component<Props, GetPieceState> {

    state = {
        isHoveringOverVariablePiece: false,
        workflowTypeSearchTerm: "",
    };

    handleHoverOverVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: false,
        });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && this.state.isHoveringOverVariablePiece) {

            if (this.state.isHoveringOverVariablePiece) {
                this.props.setVariablePiece(this.props.pieceId, this.props.lastDraggedPiece.id);
            }

            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverVariablePiece: false,
            });
        }
    }

    updateValidity = (isValid: boolean, flowchartContext: FlowchartInfoForPiece) => {
        if (!isValid) {
            const invalidPieceInfo = flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId);

            if (!invalidPieceInfo && flowchartContext.setInvalidPiece) {
                flowchartContext.setInvalidPiece(this.props.pieceId, flowchartContext.parentSplitPieceIds);
            }
        }

        if (isValid && flowchartContext.removeInvalidPiece && flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId)) {
            flowchartContext.removeInvalidPiece(this.props.pieceId);
        }
    }

    searchForWorkflowType = (searchTerm: string) => {
        this.setState({ workflowTypeSearchTerm: searchTerm });
    } 

    render() {

        return <FlowchartContext.Consumer>
            {
                (flowchartContext) => {
                    const workflowTypeSelectCallback = this.props.setWorkflowType.bind(this, this.props.pieceId);
                    const workflowType = this.props.workflowType;

                    let workflowTypeSelectBox: JSX.Element | undefined;

                    let isValid = true;

                    let allowedWorkflowTypeIds = this.props.workflowTypes.allEntries;

                    const highlightColor = flowchartContext.highlights && flowchartContext.highlights[this.props.pieceId];
                    let highlightClass = styles.noHighlight;

                    switch (highlightColor) {
                        case PieceHighlightColour.GREEN:
                            highlightClass = styles.addedHighlight;
                            break;
                        case PieceHighlightColour.YELLOW:
                            highlightClass = styles.updatedHighlight;
                            break;
                        case PieceHighlightColour.PURPLE:
                            highlightClass = styles.movedHighlight;
                            break;
                        case PieceHighlightColour.RED:
                            highlightClass = styles.deletedHighlight;
                            break;
                    }

                    if (flowchartContext.projects.length > 0) {
                        allowedWorkflowTypeIds = allowedWorkflowTypeIds.filter(workflowTypeId => {
                            const workflowType = this.props.workflowTypes.byId[workflowTypeId];
                            return flowchartContext.projects.includes(workflowType.project);
                        });
                    }

                    if (workflowType) {
                        isValid = allowedWorkflowTypeIds.includes(workflowType);

                        if (this.props.variableType) {
                            isValid = isValid && this.props.variableType === VariableType.WORKFLOW;
                        }
                    }

                    const filteredWorkflowTypeNames = allowedWorkflowTypeIds
                    .map(workflowTypeId => {
                        const workflowType = this.props.workflowTypes.byId[workflowTypeId];
                        const project = this.props.projectsData.byId[workflowType.project];

                        return { name: workflowType.name, description: project.name, value: workflowTypeId }
                    })
                    .filter(item => {
                        return item.name.toLocaleLowerCase().includes(this.state.workflowTypeSearchTerm.toLocaleLowerCase());
                    });

                    workflowTypeSelectBox = <SelectBox theme="pink" selectionPromptText={workflowType && this.props.workflowTypes.byId.hasOwnProperty(workflowType) ? this.props.workflowTypes.byId[workflowType].name : undefined}>
                        <DropDownList theme="pink" dismissAfterSelection={false}>
                            <DropDownSearchBox
                                handleSearchInputChange={this.searchForWorkflowType}
                                placeholder={"Search by name"}
                                searchTerm={this.state.workflowTypeSearchTerm}
                            />
                            {filteredWorkflowTypeNames.map(workflowType => {
                                return <ListItem theme="pink" name={workflowType.name} detail={workflowType.description} key={workflowType.value} value={workflowType.value} onClick={workflowTypeSelectCallback} />
                            })}
                        </DropDownList>
                    </SelectBox>;

                    if (flowchartContext.highlightIncompletePieces) {
                        const isIncomplete = !workflowType || !this.props.variablePiece;
                        isValid = isValid && !isIncomplete;
                    }

                    this.updateValidity(isValid, flowchartContext);

                    return (<FlowchartPiece {...this.props}>
                        <div className={(isValid ? styles.getPieceWrapper : styles.invalidGetPieceWrapper) + ' ' + highlightClass}>
                            <div className={styles.text}>get affiliation from </div>
                            {this.props.variablePiece ? this.props.variablePiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverVariablePiece && !!this.props.targetPiece} isDisabled onMouseOver={this.handleHoverOverVariablePiece} onMouseOut={this.handleHoverOutOfVariablePiece} placeholderText="Entity variable (e.g. the 'Workflow' variable)" minSize={44} />}
                            {this.props.variablePiece && <div className={styles.text}>which is a workflow of type </div>}
                            {this.props.variablePiece && workflowTypeSelectBox}
                        </div>
                    </FlowchartPiece>)
                }
            }
        </FlowchartContext.Consumer>

    }
}

const GetAffiliationForWorkflowPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedGetAffiliationForWorkflowPiece);

export default GetAffiliationForWorkflowPiece;