import { Component, createRef, RefObject } from 'react';
import { ReactComponent as CalendarIcon } from "../../common/assets/calendar.svg";
import { ReactComponent as CloseIcon } from "../../common/assets/close.svg";
import { ReactComponent as ArrowRight } from "../../common/assets/keyboard_arrow_right.svg";
import { ReactComponent as ArrowLeft } from "../../common/assets/keyboard_arrow_left.svg";
import styles from './InputText.module.scss';
import { translatePhrase } from '../../shared/helpers/translation';
import Button from '../button/CommonButton';

type OwnProps = {
    default?: Date,
    placeholder?: string,
    isDisabled?: boolean,
    onChange: (changedValue: string) => void,
    expandCalendar?: boolean,
    questionId?: string
};

type OwnState = {
    selectedDay: number | undefined,
    selectedMonth: number | undefined,
    selectedYear: number | undefined,
    selectedSegment: 'DATE' | 'MONTH' | 'YEAR',
    showCalendar: boolean,
};

const MonthsMap = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const today = new Date();
class DateInput extends Component<OwnProps, OwnState> {
    input: RefObject<HTMLInputElement> = createRef();
    segmentRef: RefObject<HTMLDivElement> = createRef();

    constructor(props: Readonly<OwnProps>) {
        super(props);

        this.state = {
            selectedDay: this.props.default ? new Date(this.props.default).getDate() : undefined,
            selectedMonth: this.props.default ? new Date(this.props.default).getMonth() + 1 : undefined,
            selectedYear: this.props.default ? new Date(this.props.default).getFullYear() : undefined,
            selectedSegment: 'YEAR',
            showCalendar: false,
        };
    }

    componentDidMount(): void {
        if (this.props.expandCalendar) {
            this.setState({
                showCalendar: true,
                selectedSegment: 'YEAR'
            });

            this.scrollActiveSegmentIntoView();
        }
    };

    scrollActiveSegmentIntoView = () => {
        setTimeout(() => {
            try {
                let YearPosition: number = document.querySelector('.selected-year-' + this.props.questionId)?.getBoundingClientRect().top || 0;
                let MonthPosition: number = document.querySelector('.selected-month-' + this.props.questionId)?.getBoundingClientRect().top || 0;
                let DatePosition: number = document.querySelector('.selected-date-' + this.props.questionId)?.getBoundingClientRect().top || 0;
                let BasePosition: number = document.querySelector('.segment-' + this.props.questionId)?.getBoundingClientRect().top || 0;

                if (this.state.selectedSegment === 'YEAR') {
                    document.querySelector('.segment-' + this.props.questionId)?.scrollTo({ top: (YearPosition - BasePosition) - 50, behavior: "smooth" });
                };
                if (this.state.selectedSegment === 'MONTH') {
                    document.querySelector('.segment-' + this.props.questionId)?.scrollTo({ top: (MonthPosition - BasePosition) - 50, behavior: "smooth" });
                };
                if (this.state.selectedSegment === 'DATE') {
                    document.querySelector('.segment-' + this.props.questionId)?.scrollTo({ top: (DatePosition - BasePosition) - 50, behavior: "smooth" });
                };

            } catch (e) {
                console.log(e)
            }
        }, 100)
    }

    changeInput = () => {
        if (
            typeof this.state.selectedYear !== 'undefined' &&
            typeof this.state.selectedMonth !== 'undefined' &&
            typeof this.state.selectedDay !== 'undefined'
        ) {
            let defaultDate: string = this.props.default?.getDate() + '-' + this.props.default?.getMonth() + '-' + this.props.default?.getFullYear()
            let updatedDay: string = (this.state.selectedDay) + '-' + (this.state.selectedMonth - 1) + '-' + (this.state.selectedYear)
            if (defaultDate !== updatedDay) {
                console.log('Loader')
                this.props.onChange(this.state.selectedYear + '-' + this.state.selectedMonth.toString().padStart(2, '0') + '-' + this.state.selectedDay.toString().padStart(2, '0'));
            }
            else {
                console.log('No Loader')
                this.props.onChange('');
            }
        }
    }

    switchSegment = (type: 'DATE' | 'MONTH' | 'YEAR') => {
        this.setState({
            selectedSegment: type
        });
        this.scrollActiveSegmentIntoView();
    }

    toggleCalendar = () => {
        if (!this.props.isDisabled) {
            this.setState({ showCalendar: !this.state.showCalendar });
            this.switchSegment('YEAR');
        }
    }

    nextMonth = () => {
        let monthIndex = typeof this.state.selectedMonth === 'number' ? this.state.selectedMonth - 1 : today.getDate()
        if (
            typeof this.state.selectedDay === 'number' &&
            typeof this.state.selectedMonth === 'number' &&
            typeof this.state.selectedYear === 'number'
        ) {
            if ((monthIndex + 1) === 12) {
                if (this.state.selectedYear === today.getFullYear() + 5) {
                    return;
                }
                this.setState({
                    selectedYear: this.state.selectedYear + 1,
                    selectedMonth: 1
                });
                if (!this.props.expandCalendar) {
                    this.props.onChange((this.state.selectedYear + 1) + '-' + (1).toString().padStart(2, '0') + '-' + this.state.selectedDay.toString().padStart(2, '0'));
                }
                return;
            };

            const nextMonth = this.state.selectedMonth + 1;
            this.setState({ selectedMonth: nextMonth });

            if (!this.props.expandCalendar) {
                this.props.onChange(this.state.selectedYear + '-' + (this.state.selectedMonth + 1).toString().padStart(2, '0') + '-' + this.state.selectedDay.toString().padStart(2, '0'));
            }
        }
    };

    prevMonth = () => {
        let monthIndex = typeof this.state.selectedMonth === 'number' ? this.state.selectedMonth - 1 : today.getDate()
        if (
            typeof this.state.selectedDay === 'number' &&
            typeof this.state.selectedMonth === 'number' &&
            typeof this.state.selectedYear === 'number'
        ) {
            if ((monthIndex + 1) === 1) {
                if (this.state.selectedYear === today.getFullYear() - 100) {
                    return;
                }
                this.setState({
                    selectedYear: this.state.selectedYear - 1,
                    selectedMonth: 12
                });
                if (!this.props.expandCalendar) {
                    this.props.onChange((this.state.selectedYear - 1) + '-' + (12).toString().padStart(2, '0') + '-' + this.state.selectedDay.toString().padStart(2, '0'));
                }
            } else {
                if (!this.props.expandCalendar) {
                    this.props.onChange(this.state.selectedYear + '-' + (this.state.selectedMonth - 1).toString().padStart(2, '0') + '-' + this.state.selectedDay.toString().padStart(2, '0'));
                }
                return typeof this.state.selectedMonth === 'number' ? this.setState({ selectedMonth: this.state.selectedMonth - 1 }) : this.setState({ selectedDay: monthIndex - 1 })
            }
        }
    }

    nextYear = () => {
        if (
            typeof this.state.selectedDay === 'number' &&
            typeof this.state.selectedMonth === 'number' &&
            typeof this.state.selectedYear === 'number'
        ) {
            if (!this.props.expandCalendar) {
                this.props.onChange((this.state.selectedYear + 1) + '-' + (this.state.selectedMonth).toString().padStart(2, '0') + '-' + this.state.selectedDay.toString().padStart(2, '0'));
            }

            if (typeof this.state.selectedYear === 'number') {
                if (today.getFullYear() + 5 === this.state.selectedYear) {
                    return;
                }
                this.setState({ selectedYear: this.state.selectedYear + 1 })
            } else this.setState({ selectedDay: today.getFullYear() + 1 })
        }

    }
    prevYear = () => {
        if (
            typeof this.state.selectedDay === 'number' &&
            typeof this.state.selectedMonth === 'number' &&
            typeof this.state.selectedYear === 'number'
        ) {
            if (!this.props.expandCalendar) {
                this.props.onChange((this.state.selectedYear - 1) + '-' + (this.state.selectedMonth).toString().padStart(2, '0') + '-' + this.state.selectedDay.toString().padStart(2, '0'));
            }
            return typeof this.state.selectedYear === 'number' ? this.setState({ selectedYear: this.state.selectedYear - 1 }) : this.setState({ selectedDay: today.getFullYear() - 1 })
        }
    }

    toggleToday = () => {
        this.setState({
            selectedDay: new Date().getDate(),
            selectedMonth: new Date().getMonth() + 1,
            selectedYear: new Date().getFullYear(),
        });
        if (!this.props.expandCalendar) {
            this.props.onChange(new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0'));
        }
    }

    setDefaultDate = () => {
        if (this.props.default) {
            return;
        }
        this.setState({
            selectedDay: new Date().getDate(),
            selectedMonth: new Date().getMonth() + 1,
            selectedYear: new Date().getFullYear(),
        });

        if (!this.props.expandCalendar) {
            this.props.onChange(new Date().getFullYear() + '-' + (new Date().getMonth() + 1).toString().padStart(2, '0') + '-' + new Date().getDate().toString().padStart(2, '0'));
        }
    }

    render() {
        const calendarActionButtons =
            <ul className={styles.modifyDates}>
                <li><button className={styles.prevPage} onClick={this.prevMonth}> <ArrowLeft /><ArrowLeft /> </button>Month<button className={styles.nextPage} onClick={this.nextMonth}><ArrowRight /><ArrowRight /> </button></li>
                <li> <button className={styles.currentDay} onClick={this.toggleToday}>Today</button> </li>
                <li><button className={styles.prevPage} onClick={this.prevYear}><ArrowLeft /><ArrowLeft /><ArrowLeft /> </button>Year<button className={styles.nextPage} onClick={this.nextYear}><ArrowRight /><ArrowRight /><ArrowRight /> </button></li>
            </ul>


        const today = new Date();
        let monthIndex = typeof this.state.selectedMonth === 'number' ? this.state.selectedMonth - 1 : today.getDate(),
            date = new Date(typeof this.state.selectedYear === 'number' ? this.state.selectedYear : today.getFullYear(), monthIndex, 1),
            selectedMonthDays = [],
            yearsMap = [],
            currentYear = new Date().getFullYear();

        while (date.getMonth() === monthIndex) {
            selectedMonthDays.push(date.getDate());
            date.setDate(date.getDate() + 1);
        }

        for (let i = currentYear + 5; i > currentYear - 101; i -= 1) {
            yearsMap.push(i);
        }

        let dateRepresentation: string | undefined;

        if (
            typeof this.state.selectedDay === 'number' &&
            typeof this.state.selectedMonth === 'number' &&
            typeof this.state.selectedYear === 'number'
        ) {
            dateRepresentation = this.state.selectedDay.toString().padStart(2, '0') + '-' + MonthsMap[this.state.selectedMonth - 1] + '-' + this.state.selectedYear
        }

        return (
            <section className={styles.calendarInputHolder} onClick={this.setDefaultDate.bind(this)}>
                <div className={styles.inputHolder + ' ' + (this.props.isDisabled ? styles.disableInput : '') + ' ' + (this.state.showCalendar || this.props.expandCalendar ? styles.calendarActive : '') + ' ' + (this.props.expandCalendar ? styles.showDoneButton : '')}>
                    {this.props.placeholder && <div onClick={() => this.toggleCalendar()} title={translatePhrase(this.props.placeholder)} className={styles.placeholder}>{translatePhrase(this.props.placeholder)} : </div>}

                    <div onClick={() => this.toggleCalendar()} className={styles.selectedOptionText}> {dateRepresentation} </div>

                    {!this.props.expandCalendar && <Button isDisabled={this.props.isDisabled} onClick={() => this.toggleCalendar()} icon={this.state.showCalendar ? <CloseIcon /> : <CalendarIcon />} isSelected={this.state.showCalendar} size={'small'} isRounded />}

                    {this.props.expandCalendar && <div className={styles.doneButton}>
                        <Button type={'secondary'} onClick={this.changeInput} size={'small'} padding={'0px 10px'} text={'Done'} isRounded />
                    </div>}
                </div>

                <section className={styles.calenderWrapper}>
                    {this.state.showCalendar && <div className={styles.calendarUI}>
                        <div className={styles.segments}>
                            <button onClick={() => this.switchSegment('YEAR')}
                                className={this.state.selectedSegment === 'YEAR' ? styles.active : ''}
                            > Year </button>
                            <button onClick={() => this.switchSegment('MONTH')}
                                className={this.state.selectedSegment === 'MONTH' ? styles.active : ''}
                            > Month </button>
                            <button onClick={() => this.switchSegment('DATE')}
                                className={this.state.selectedSegment === 'DATE' ? styles.active : ''}
                            > Date </button>
                        </div>
                        <div ref={this.segmentRef} className={styles.valueSet + ' ' + 'segment-' + this.props.questionId + ' ' + (this.state.selectedSegment === 'DATE' ? styles.dates : '')}>
                            {this.state.selectedSegment === 'DATE' &&
                                selectedMonthDays.map((day) => {
                                    return <span key={day}
                                        onClick={() => {

                                            if (!this.props.expandCalendar && typeof this.state.selectedYear === 'number' && typeof this.state.selectedMonth === 'number') {
                                                this.props.onChange(this.state.selectedYear + '-' + this.state.selectedMonth.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0'));
                                            }

                                            this.setState({ selectedDay: day, showCalendar: this.props.expandCalendar ? true : false });
                                        }}
                                        className={styles.date + ' ' + (this.state.selectedDay === day ? (styles.active + ' selected-date-' + this.props.questionId) : today.getDate() === day ? styles.todayMark : ' ')}> {day} </span>;
                                })
                            }
                            {this.state.selectedSegment === 'MONTH' &&
                                MonthsMap.map((month, index) => {
                                    return <span key={month}
                                        onClick={() => {

                                            if (!this.props.expandCalendar && typeof this.state.selectedYear === 'number' && typeof this.state.selectedDay === 'number') {
                                                this.props.onChange(this.state.selectedYear + '-' + (index + 1).toString().padStart(2, '0') + '-' + this.state.selectedDay.toString().padStart(2, '0'));
                                            }

                                            this.setState({ selectedMonth: index + 1 });
                                        }}
                                        className={styles.month + ' ' + (typeof this.state.selectedMonth === 'number' && this.state.selectedMonth - 1 === index ? (styles.active + ' selected-month-' + this.props.questionId) : today.getMonth() === index ? styles.todayMark : ' ')}
                                    > {month} </span>;
                                })
                            }
                            {this.state.selectedSegment === 'YEAR' &&
                                yearsMap.map((year) => {
                                    return <span key={year}
                                        onClick={() => {
                                            if (!this.props.expandCalendar && typeof this.state.selectedMonth === 'number' && typeof this.state.selectedDay === 'number') {
                                                this.props.onChange(year + '-' + this.state.selectedMonth.toString().padStart(2, '0') + '-' + this.state.selectedDay.toString().padStart(2, '0'));
                                            }

                                            this.setState({ selectedYear: year });
                                        }}
                                        className={styles.year + ' ' + (this.state.selectedYear === year ? (styles.active + ' selected-year-' + this.props.questionId) : today.getFullYear() === year ? styles.todayMark : ' ')}>
                                        {year} </span>;
                                })
                            }
                        </div>
                    </div>}
                    {!this.props.isDisabled && calendarActionButtons}
                </section>
            </section>
        );
    }
}

export default DateInput;