import React, { Component, ChangeEvent, createRef, RefObject } from 'react';
import styles from './PageHeader.module.scss';
import { RouteComponentProps, withRouter } from 'react-router';

import { ApplicationState } from '../../shared/store/types';
import { connect } from 'react-redux';

import { translatePhrase } from '../../shared/helpers/translation';

import { ReactComponent as OrganizationIcon } from '../../common/assets/organisation.svg';
import { ReactComponent as CloseIcon } from '../../common/assets/close.svg';
import { ReactComponent as SearchIcon } from '../../common/assets/search.svg';
import { ReactComponent as HelpIcon } from '../../common/assets/help.svg';

import { isUUID } from '../../shared/helpers/utilities';
import { Dispatch } from 'redux';
import { startDataPush, setToastMessage, clearToastMessage, freezeBackground, unFreezeBackground, setIsTopBarExpanded, initiateFetchAppData } from '../../shared/store/my-data/actions';

import { selectMemberType } from '../../shared/store/members/types/actions';
import { selectGroupType } from '../../shared/store/groups/types/actions';
import { selectWorkflowType } from '../../shared/store/workflows/types/actions';
import { getReadableDataForCustomField } from '../../shared/store/custom-fields';
import { Permissions } from '../../shared/store/permissions/types';
import { selectProject } from '../../shared/store/structure/project/actions';
import { selectLevel } from '../../shared/store/structure/level/actions';
import { selectRole } from '../../shared/store/structure/role/actions';
import { selectStaticDataHolder } from '../../shared/store/static-info/actions';
import { selectReportType } from '../../shared/store/reports/types/actions';
import Button from '../../widgets/button/CommonButton';
import UserProfile from '../user-profile/UserProfile';
import OrganizationProfile from '../organization-profile/OrganizationProfile';
import Nudges from './Nudges';
import { OrganizationState } from '../../shared/store/organization/types';
import { NudgeType } from '../../shared/store/my-data/types';
import { isPageExcluded } from '../OaaSApp';


type OwnState = {
    showSearchBar: boolean,
    searchTerm: string,
    searchedPages: Array<JSX.Element>,
    searchedProjectsHierarchy: Array<JSX.Element>,
    searchedProjectsLocation: Array<JSX.Element>,
    searchedProjectsPermissions: Array<JSX.Element>,
    searchedLevels: Array<JSX.Element>,
    searchedRolesHierarchy: Array<JSX.Element>,
    searchedRolesUserConfig: Array<JSX.Element>,
    searchedUsers: Array<JSX.Element>,
    searchedMemberTypes: Array<JSX.Element>,
    searchedGroupTypes: Array<JSX.Element>,
    searchedWorkflowTypes: Array<JSX.Element>,
    searchedReportTypes: Array<JSX.Element>,
    searchedStaticInfo: Array<JSX.Element>,

    searchedMembers: Array<JSX.Element>,
    searchedGroups: Array<JSX.Element>,

    selectedNudge: number,

    isSyncing: boolean,
    lastSavedText: string,
    hasData: boolean,
    saveTextUpdateIntervalId: number | undefined,

    selectedSegment: 'HELP' | 'ORG_PROFILE' | 'PERSONAL_PROFILE' | '',

    organizationKey: number,
    organization: OrganizationState,
}

const mapStateToProps = (state: ApplicationState) => {

    let isBetaTester = false;
    const selectedWorkflowTypeId = state.workflows.types.selected;

    if (isUUID(state.myData.id)) {
        const user = state.users.byId[state.myData.id];

        if (user) {
            isBetaTester = user.isBetaTester;
        }
    }

    return {
        userId: state.myData.id ? isUUID(state.myData.id) ? state.myData.id : 'Super User' : '',
        isSaving: state.myData.isPushingData,
        lastSyncTime: state.myData.lastSyncTime,

        applicationState: state,
        usersData: state.users,
        orgCode: state.organization.code,
        myId: state.myData.id,
        isBetaTester,
        isTopBarExpanded: state.myData.isTopBarExpanded,

        isProfileReadable: state.permissions.myPermissions.general.OrganizationProfile !== Permissions.NONE,

        isPushingData: state.myData.isPushingData,
        isFetchingData: !state.myData.isLoaded,
        logo: state.organization.image,

        organization: state.organization,
        isEditingOrgProfile: state.myData.isEditingOrgProfile,
        selectedNudge: state.myData.selectedNudgeId,
        selectedWorkflowTypeId,
        isFlowchartExpanded: state.myData.isFlowchartExpanded,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        save: () => dispatch(startDataPush()),
        fetchAppDataFromServer: () => dispatch(initiateFetchAppData()),

        setToastMessage: (message: string, persistMessage: boolean) => dispatch(setToastMessage(message, persistMessage)),
        clearToastMessage: () => dispatch(clearToastMessage()),

        freezeBackground: () => dispatch(freezeBackground()),
        unFreezeBackground: () => dispatch(unFreezeBackground()),

        selectProject: (id: string) => dispatch(selectProject(id)),
        selectLevel: (id: string) => dispatch(selectLevel(id)),
        selectRole: (id: string) => dispatch(selectRole(id)),

        selectMemberType: (id: string) => dispatch(selectMemberType(id)),
        selectGroupType: (id: string) => dispatch(selectGroupType(id)),
        selectWorkflowType: (id: string) => dispatch(selectWorkflowType(id)),
        selectReportType: (id: string) => dispatch(selectReportType(id)),

        selectStaticInfoHolder: (id: string) => dispatch(selectStaticDataHolder(id)),

        toggleTopBarExpansion: (flag: boolean) => dispatch(setIsTopBarExpanded(flag)),
    }
}


type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = StateProps & DispatchProps & RouteComponentProps;

class ConnectedPageHeader extends Component<Props, OwnState> {

    searchInput: RefObject<HTMLInputElement> = createRef();

    constructor(props: Readonly<Props>) {
        super(props);

        const linksWithNudges = ['/dashboard', '/structure/locations', '/users/list', '/members/list', '/groups/list', '/workflows/list', '/workflows/configuration'];
        const isPageWithNudges = linksWithNudges.includes(this.props.location.pathname);

        this.state = {
            showSearchBar: false,
            searchTerm: '',
            searchedPages: [],
            searchedProjectsHierarchy: [],
            searchedProjectsLocation: [],
            searchedProjectsPermissions: [],
            searchedLevels: [],
            searchedRolesHierarchy: [],
            searchedRolesUserConfig: [],
            searchedMemberTypes: [],
            searchedGroupTypes: [],
            searchedWorkflowTypes: [],
            searchedReportTypes: [],
            searchedStaticInfo: [],
            searchedUsers: [],
            searchedMembers: [],
            searchedGroups: [],
            selectedNudge: 0,

            hasData: false,
            isSyncing: false,
            lastSavedText: 'Not Synced',
            saveTextUpdateIntervalId: undefined,

            selectedSegment: this.props.isTopBarExpanded ? isPageWithNudges ? 'HELP' : 'PERSONAL_PROFILE' : '',

            organizationKey: 0,
            organization: {
                name: this.props.organization.name,
                code: this.props.organization.code,
                category: this.props.organization.category,
                categories: this.props.organization.categories,
                size: this.props.organization.size,
                sizes: this.props.organization.sizes,
                image: this.props.organization.image,
                language: this.props.organization.language,
                phoneCountryCode: this.props.organization.phoneCountryCode,
                phoneNumber: this.props.organization.phoneNumber,
                address: this.props.organization.address,
                latitude: this.props.organization.latitude,
                longitude: this.props.organization.longitude,
                primary_color: this.props.organization.primary_color,
                primary_gradient: this.props.organization.primary_gradient,
                contrast_color: this.props.organization.contrast_color,
                sessionTimeoutInMinutes: this.props.organization.sessionTimeoutInMinutes,
                hasUpdated: this.props.organization.hasUpdated,
                hasThresholdChanged: this.props.organization.hasThresholdChanged,
                superUserPassword: this.props.organization.superUserPassword,
            }
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.isTopBarExpanded && !this.state.selectedSegment && !prevProps.isTopBarExpanded) {
            const linksWithNudges = ['/dashboard', '/structure/hierarchy', '/structure/locations', '/users/list', '/members/list', '/groups/list', '/workflows/list', '/workflows/configuration'];
            const isPageWithNudges = linksWithNudges.includes(this.props.location.pathname);

            this.setState({
                selectedSegment: isPageWithNudges ? 'HELP' : 'PERSONAL_PROFILE'
            });
        }

        if (!this.props.isTopBarExpanded && prevProps.isTopBarExpanded && !!this.state.selectedSegment) {
            this.setState({
                selectedSegment: '',
            })
        }
    }

    selectPage = (pageLink: string) => {
        this.clearSearch();
        this.props.history.push(pageLink);
    }

    selectProjectForHierarchy = (projectId: string) => {
        this.props.selectProject(projectId);
        this.clearSearch();
        this.props.history.push('/structure/hierarchy');
    }

    selectProjectForLocations = (projectId: string) => {
        this.props.selectProject(projectId);
        this.clearSearch();
        this.props.history.push('/structure/locations');
    }

    selectLevel = (levelId: string) => {
        const level = this.props.applicationState.structure.levels.byId[levelId];
        this.props.selectProject(level.project);
        this.props.selectLevel(levelId);
        this.clearSearch();
        this.props.history.push('/structure/hierarchy');
    }

    selectRoleHierarchy = (roleId: string) => {
        const role = this.props.applicationState.structure.roles.byId[roleId];
        const level = this.props.applicationState.structure.levels.byId[role.level];
        this.props.selectProject(level.project);
        this.props.selectLevel(level.id);
        this.props.selectRole(role.id);
        this.clearSearch();
        this.props.history.push('/structure/hierarchy');
    }

    selectRoleUserConfig = (roleId: string) => {
        const role = this.props.applicationState.structure.roles.byId[roleId];
        const level = this.props.applicationState.structure.levels.byId[role.level];
        this.props.selectProject(level.project);
        this.props.selectLevel(level.id);
        this.props.selectRole(role.id);
        this.clearSearch();
        this.props.history.push('/users/configuration');
    }

    selectMemberType = (memberTypeId: string) => {
        this.props.selectMemberType(memberTypeId);
        this.clearSearch();
        this.props.history.push('/members/configuration');
    }

    selectGroupType = (groupTypeId: string) => {
        this.props.selectGroupType(groupTypeId);
        this.clearSearch();
        this.props.history.push('/groups/configuration');
    }

    selectWorkflowType = (workflowTypeId: string) => {
        this.props.selectWorkflowType(workflowTypeId);
        this.clearSearch();
        this.props.history.push('/workflows/configuration');
    }

    selectReportType = (reportTypeId: string) => {
        this.props.selectReportType(reportTypeId);
        this.clearSearch();
        this.props.history.push('/reports/configuration');
    }

    selectStaticInfoHolder = (staticInfoHolderId: string) => {
        this.props.selectStaticInfoHolder(staticInfoHolderId);
        this.clearSearch();
        this.props.history.push('/static-data/configuration');
    }

    generateResults = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === '') {
            this.clearSearch();
            return;
        }

        const searchTerm = e.target.value.toLocaleLowerCase().trim();

        const pageLinks = [{
            name: 'Hierarchy',
            link: '/structure/hierarchy',
        }, {
            name: 'Locations',
            link: '/structure/locations',
        }, {
            name: 'Permissions',
            link: '/structure/permissions',
        }, {
            name: 'User List',
            link: '/users/list',
        }, {
            name: 'User Configuration',
            link: '/users/configuration',
        }, {
            name: 'Member List',
            link: '/members/list',
        }, {
            name: 'Member Configuration',
            link: '/members/configuration',
        }, {
            name: 'Group List',
            link: '/groups/list',
        }, {
            name: 'Group Configuration',
            link: '/groups/configuration',
        }, {
            name: 'Workflow List',
            link: '/workflows/list',
        }, {
            name: 'Workflow Configuration',
            link: '/workflows/configuration',
        }, {
            name: 'Reports List',
            link: '/reports/list',
        }, {
            name: 'Reports Configuration',
            link: '/reports/configuration',
        }, {
            name: 'Static Data Configuration',
            link: '/static-data/configuration',
        }];

        const searchedPages: Array<JSX.Element> = pageLinks
            .filter(page => page.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
            .map(page => {
                const startIndex = page.name.toLocaleLowerCase().indexOf(searchTerm);
                const endIndex = startIndex + searchTerm.length;

                return <section key={page.name} className={styles.namedResult} onClick={this.selectPage.bind({}, page.link)}><span>{page.name.substring(0, startIndex)}</span> <em>{page.name.substring(startIndex, endIndex)}</em> <span>{page.name.substring(endIndex)}</span></section>
            });

        const searchedProjects = this.props.applicationState.structure.projects.allEntries
            .map(projectId => this.props.applicationState.structure.projects.byId[projectId])
            .filter(project => project.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));

        const searchedProjectsHierarchy: Array<JSX.Element> = searchedProjects.map(project => {
            const startIndex = project.name.toLocaleLowerCase().indexOf(searchTerm);
            const endIndex = startIndex + searchTerm.length;

            return <section key={project.id} className={styles.namedResult} onClick={this.selectProjectForHierarchy.bind(this, project.id)}><span>{project.name.substring(0, startIndex)}</span> <em>{project.name.substring(startIndex, endIndex)}</em> <span>{project.name.substring(endIndex)}</span></section>
        });


        const searchedProjectsLocation: Array<JSX.Element> = searchedProjects.map(project => {
            const startIndex = project.name.toLocaleLowerCase().indexOf(searchTerm);
            const endIndex = startIndex + searchTerm.length;

            return <section key={project.id} className={styles.namedResult} onClick={this.selectProjectForLocations.bind(this, project.id)}><span>{project.name.substring(0, startIndex)}</span> <em>{project.name.substring(startIndex, endIndex)}</em> <span>{project.name.substring(endIndex)}</span></section>
        });


        const searchedProjectsPermissions: Array<JSX.Element> = searchedProjects.map(project => {
            const startIndex = project.name.toLocaleLowerCase().indexOf(searchTerm);
            const endIndex = startIndex + searchTerm.length;

            return <section key={project.id} className={styles.namedResult} onClick={this.selectProjectForLocations.bind(this, project.id)}><span>{project.name.substring(0, startIndex)}</span> <em>{project.name.substring(startIndex, endIndex)}</em> <span>{project.name.substring(endIndex)}</span></section>
        });

        const searchedLevels = this.props.applicationState.structure.levels.allEntries
            .map(levelId => this.props.applicationState.structure.levels.byId[levelId])
            .filter(level => level.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
            .map(level => {
                const startIndex = level.name.toLocaleLowerCase().indexOf(searchTerm);
                const endIndex = startIndex + searchTerm.length;

                return <section key={level.id} className={styles.namedResult} onClick={this.selectLevel.bind(this, level.id)}><span>{level.name.substring(0, startIndex)}</span> <em>{level.name.substring(startIndex, endIndex)}</em> <span>{level.name.substring(endIndex)}</span></section>
            });

        const searchedRoles = this.props.applicationState.structure.roles.allEntries
            .map(roleId => this.props.applicationState.structure.roles.byId[roleId])
            .filter(role => role.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));

        const searchedRolesHierarchy: Array<JSX.Element> = searchedRoles.map(role => {
            const startIndex = role.name.toLocaleLowerCase().indexOf(searchTerm);
            const endIndex = startIndex + searchTerm.length;

            return <section key={role.id} className={styles.namedResult} onClick={this.selectRoleHierarchy.bind(this, role.id)}><span>{role.name.substring(0, startIndex)}</span> <em>{role.name.substring(startIndex, endIndex)}</em> <span>{role.name.substring(endIndex)}</span></section>
        });

        const searchedRolesUserConfig: Array<JSX.Element> = searchedRoles.map(role => {
            const startIndex = role.name.toLocaleLowerCase().indexOf(searchTerm);
            const endIndex = startIndex + searchTerm.length;

            return <section key={role.id} className={styles.namedResult} onClick={this.selectRoleUserConfig.bind(this, role.id)}><span>{role.name.substring(0, startIndex)}</span> <em>{role.name.substring(startIndex, endIndex)}</em> <span>{role.name.substring(endIndex)}</span></section>
        });

        const searchedMemberTypes: Array<JSX.Element> = this.props.applicationState.members.types.allEntries
            .map(memberTypeId => this.props.applicationState.members.types.byId[memberTypeId])
            .filter(memberType => memberType.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
            .map(memberType => {
                const startIndex = memberType.name.toLocaleLowerCase().indexOf(searchTerm);
                const endIndex = startIndex + searchTerm.length;

                return <section key={memberType.id} className={styles.namedResult} onClick={this.selectMemberType.bind(this, memberType.id)}><span>{memberType.name.substring(0, startIndex)}</span> <em>{memberType.name.substring(startIndex, endIndex)}</em> <span>{memberType.name.substring(endIndex)}</span></section>
            });

        const searchedGroupTypes: Array<JSX.Element> = this.props.applicationState.groups.types.allEntries
            .map(groupTypeId => this.props.applicationState.groups.types.byId[groupTypeId])
            .filter(groupType => groupType.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
            .map(groupType => {
                const startIndex = groupType.name.toLocaleLowerCase().indexOf(searchTerm);
                const endIndex = startIndex + searchTerm.length;

                return <section key={groupType.id} className={styles.namedResult} onClick={this.selectGroupType.bind(this, groupType.id)}><span>{groupType.name.substring(0, startIndex)}</span> <em>{groupType.name.substring(startIndex, endIndex)}</em> <span>{groupType.name.substring(endIndex)}</span></section>
            });

        const searchedWorkflowTypes: Array<JSX.Element> = this.props.applicationState.workflows.types.allEntries
            .map(workflowTypeId => this.props.applicationState.workflows.types.byId[workflowTypeId])
            .filter(workflowType => workflowType.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
            .map(workflowType => {
                const startIndex = workflowType.name.toLocaleLowerCase().indexOf(searchTerm);
                const endIndex = startIndex + searchTerm.length;

                return <section key={workflowType.id} className={styles.namedResult} onClick={this.selectWorkflowType.bind(this, workflowType.id)}><span>{workflowType.name.substring(0, startIndex)}</span> <em>{workflowType.name.substring(startIndex, endIndex)}</em> <span>{workflowType.name.substring(endIndex)}</span></section>
            });

        const searchedReportTypes: Array<JSX.Element> = this.props.applicationState.reports.types.allEntries
            .map(reportTypeId => this.props.applicationState.reports.types.byId[reportTypeId])
            .filter(reportType => reportType.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
            .map(reportType => {
                const startIndex = reportType.name.toLocaleLowerCase().indexOf(searchTerm);
                const endIndex = startIndex + searchTerm.length;

                return <section key={reportType.id} className={styles.namedResult} onClick={this.selectReportType.bind(this, reportType.id)}><span>{reportType.name.substring(0, startIndex)}</span> <em>{reportType.name.substring(startIndex, endIndex)}</em> <span>{reportType.name.substring(endIndex)}</span></section>
            });

        const searchedStaticInfoHolders: Array<JSX.Element> = this.props.applicationState.staticInfo.allEntries
            .map(staticInfoId => this.props.applicationState.staticInfo.byId[staticInfoId])
            .filter(staticInfo => staticInfo.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()))
            .map(staticInfo => {
                const startIndex = staticInfo.name.toLocaleLowerCase().indexOf(searchTerm);
                const endIndex = startIndex + searchTerm.length;

                return <section key={staticInfo.id} className={styles.namedResult} onClick={this.selectStaticInfoHolder.bind(this, staticInfo.id)}><span>{staticInfo.name.substring(0, startIndex)}</span> <em>{staticInfo.name.substring(startIndex, endIndex)}</em> <span>{staticInfo.name.substring(endIndex)}</span></section>
            });

        this.setState({
            searchTerm: e.target.value,
            searchedProjectsHierarchy,
            searchedProjectsLocation,
            searchedLevels,
            searchedRolesHierarchy,
            searchedProjectsPermissions,
            searchedRolesUserConfig,
            searchedPages,
            searchedMemberTypes,
            searchedGroupTypes,
            searchedWorkflowTypes,
            searchedReportTypes,
            searchedStaticInfo: searchedStaticInfoHolders,
        });
    }

    clearSearch = () => {
        this.setState({
            searchTerm: '',
            searchedPages: [],
            searchedProjectsHierarchy: [],
            searchedLevels: [],
            searchedRolesHierarchy: [],
            searchedProjectsLocation: [],
            searchedProjectsPermissions: [],
            searchedRolesUserConfig: [],
            searchedMemberTypes: [],
            searchedGroupTypes: [],
            searchedWorkflowTypes: [],
            searchedReportTypes: [],
            searchedUsers: [],
            searchedMembers: [],
            searchedGroups: [],
            searchedStaticInfo: [],
        });
    }

    displaySearchBarWithFocus = () => {
        this.setState({ showSearchBar: true });

        setTimeout(() => {
            this.searchInput.current?.focus();
        }, 100);
    }

    navigateUser = (url: string) => {

        if (url.includes('http')) {
            window.open(url, '_blank');
        } else {
            this.props.history.push(url);
        }
    }

    closeSegment = () => {
        this.setState({ selectedSegment: '' });
        this.props.toggleTopBarExpansion(false);
    }

    changeSegment(segment_name: 'HELP' | 'ORG_PROFILE' | 'PERSONAL_PROFILE') {
        if (this.state.selectedSegment === segment_name) {
            this.setState({ selectedSegment: '' })
            this.props.toggleTopBarExpansion(false);
        } else {
            this.setState({
                selectedSegment: segment_name,
            });
            this.props.toggleTopBarExpansion(true);
        }
    }

    render() {
        let userName = '';
        let userNameAbbreviation = '';

        const isExcluded = isPageExcluded();

        if (isExcluded) {
            return <div></div>;
        }

        if (isUUID(this.props.userId)) {
            const user = this.props.usersData.byId[this.props.userId];
            let rawUserName = user.customFields[this.props.usersData.nameFieldId];;

            const nameField = this.props.usersData.customFields.byId[this.props.usersData.nameFieldId];

            userName = getReadableDataForCustomField(rawUserName, nameField, this.props.userId, 'user');

            userNameAbbreviation = userName.split(' ').map(word => word.length > 0 ? word[0].toUpperCase() : '').join('');
        } else {
            userName = 'Super User';
            userNameAbbreviation = 'SU';
        }

        const searchedHierarchy = this.state.searchedProjectsHierarchy.concat(this.state.searchedLevels).concat(this.state.searchedRolesHierarchy);

        let searchWindow = (this.state.showSearchBar && <section className={styles.searchBarModal}>

            <div className={styles.modalBackdrop} onClick={() => this.setState({ showSearchBar: false })}></div>

            <div className={styles.SearchBar + ' ' + (this.state.searchTerm ? styles.active : '')}>
                <SearchIcon className={styles.searchIconPlaceholder} />

                <input type="text" ref={this.searchInput} placeholder={translatePhrase('Search for page') + '...'} value={this.state.searchTerm} onChange={this.generateResults} />

                <div className={this.state.searchTerm ? styles.results + ' ' + styles.active : styles.results} onClick={() => this.setState({ showSearchBar: false })}>
                    {this.state.searchedPages && this.state.searchedPages.length > 0 &&
                        <div>
                            <header>Pages</header>
                            {this.state.searchedPages}
                        </div>}
                    {searchedHierarchy && searchedHierarchy.length > 0 &&
                        <div>
                            <header>Hierarchy</header>
                            {searchedHierarchy}
                        </div>}
                    {this.state.searchedProjectsLocation && this.state.searchedProjectsLocation.length > 0 &&
                        <div>
                            <header>Locations</header>
                            {this.state.searchedProjectsLocation}
                        </div>}
                    {this.state.searchedProjectsPermissions && this.state.searchedProjectsPermissions.length > 0 &&
                        <div>
                            <header>Permissions</header>
                            {this.state.searchedProjectsPermissions}
                        </div>}
                    {this.state.searchedRolesUserConfig && this.state.searchedRolesUserConfig.length > 0 &&
                        <div>
                            <header>User configuration</header>
                            {this.state.searchedRolesUserConfig}
                        </div>}
                    {this.state.searchedUsers && this.state.searchedUsers.length > 0 &&
                        <div>
                            <header>Users</header>
                            {this.state.searchedUsers}
                        </div>}
                    {this.state.searchedMembers && this.state.searchedMembers.length > 0 &&
                        <div>
                            <header>Members</header>
                            {this.state.searchedMembers}
                        </div>}
                    {this.state.searchedGroups && this.state.searchedGroups.length > 0 &&
                        <div>
                            <header>Groups</header>
                            {this.state.searchedGroups}
                        </div>}
                    {this.state.searchedMemberTypes && this.state.searchedMemberTypes.length > 0 &&
                        <div>
                            <header>Member Types</header>
                            {this.state.searchedMemberTypes}
                        </div>}
                    {this.state.searchedGroupTypes && this.state.searchedGroupTypes.length > 0 &&
                        <div>
                            <header>Group Types</header>
                            {this.state.searchedGroupTypes}
                        </div>}
                    {this.state.searchedWorkflowTypes && this.state.searchedWorkflowTypes.length > 0 &&
                        <div>
                            <header>Workflow Types</header>
                            {this.state.searchedWorkflowTypes}
                        </div>}
                    {this.state.searchedReportTypes && this.state.searchedReportTypes.length > 0 &&
                        <div>
                            <header>Report Types</header>
                            {this.state.searchedReportTypes}
                        </div>}
                    {this.state.searchedStaticInfo && this.state.searchedStaticInfo.length > 0 &&
                        <div>
                            <header>Static Data</header>
                            {this.state.searchedStaticInfo}
                        </div>}
                </div>
            </div>
        </section>);

        const linksWithNudges = ['/dashboard', '/structure/hierarchy', '/users/list', '/members/list', '/groups/list', '/workflows/list', '/reports/list', '/workflows/configuration', `/workflows/flowchart/${this.props.selectedWorkflowTypeId}/beta`, `/workflows/flowchart/${this.props.selectedWorkflowTypeId}/live`];
        const isPageWithNudges = linksWithNudges.includes(this.props.location.pathname);

        let helpSegment = (this.state.selectedSegment === 'HELP' && <Nudges />);

        let userProfile = (this.state.selectedSegment === 'PERSONAL_PROFILE' && <UserProfile closeSegment={this.closeSegment} />);
        let orgProfile = (this.state.selectedSegment === 'ORG_PROFILE' && <OrganizationProfile />);

        return (
            <section className={`${styles.PageHeader} ${this.props.isTopBarExpanded && styles.expanded} ${this.state.showSearchBar && styles.active} ' ignore-top-level-onclickoutside ignore-react-onclickoutside' ${this.props.isFlowchartExpanded && styles.disabled}`}>
                {searchWindow}

                <div className={styles.ProfileUtilitiesHolder}>
                    <div className={styles.segments}>
                        <div className={styles.buttonHolder + ' ' + (this.state.selectedSegment === 'HELP' ? styles.active : '')}>
                            <span className={styles.background}></span>
                            <Button isHighlighted={this.props.isEditingOrgProfile && this.state.selectedSegment === 'ORG_PROFILE'}
                                isDisabled={(!isPageWithNudges && !this.props.isEditingOrgProfile) || this.props.isFlowchartExpanded} title={this.state.selectedSegment === 'HELP' ? translatePhrase('Close') : translatePhrase('Help')} isSelected={this.state.selectedSegment === 'HELP'}
                                icon={this.state.selectedSegment === 'HELP' ? <CloseIcon /> : <HelpIcon />}
                                type={this.state.selectedSegment === 'HELP' ? 'primary' : 'secondary'}
                                isBlock={false} isRounded={true} onClick={() => this.changeSegment('HELP')} />
                        </div>


                        <div className={styles.buttonHolder + ' ' + (this.state.selectedSegment === 'ORG_PROFILE' ? styles.active : '')}>
                            <span className={styles.background}></span>
                            {this.props.isProfileReadable && <Button isHighlighted={this.props.isEditingOrgProfile && this.props.selectedNudge === NudgeType.ORGANIZATION_EDIT_DETAILS} title={this.state.selectedSegment === 'ORG_PROFILE' ? translatePhrase('Close') : translatePhrase('Org Profile')} isSelected={this.state.selectedSegment === 'ORG_PROFILE'}
                                icon={this.state.selectedSegment === 'ORG_PROFILE' ? <CloseIcon /> : <OrganizationIcon />}
                                type={this.state.selectedSegment === 'ORG_PROFILE' ? 'primary' : 'secondary'} isBlock={false} isRounded={true}
                                onClick={() => this.changeSegment('ORG_PROFILE')}
                                isDisabled={this.props.isFlowchartExpanded} />}
                        </div>

                        <div className={styles.buttonHolder + ' ' + (this.state.selectedSegment === 'PERSONAL_PROFILE' ? styles.active : '')}>
                            <span className={styles.background}></span>
                            <Button title={this.state.selectedSegment === 'PERSONAL_PROFILE' ? translatePhrase('Close') : translatePhrase('Personal Profile')} isSelected={this.state.selectedSegment === 'PERSONAL_PROFILE'}
                                text={this.state.selectedSegment === 'PERSONAL_PROFILE' ? undefined : userNameAbbreviation}
                                icon={this.state.selectedSegment === 'PERSONAL_PROFILE' ? <CloseIcon /> : undefined}
                                type={this.state.selectedSegment === 'PERSONAL_PROFILE' ? 'primary' : 'secondary'}
                                isBlock={false} isRounded={true}
                                onClick={() => this.changeSegment('PERSONAL_PROFILE')}
                                dataSelector="Personal Profile Button"
                                isDisabled={this.props.isFlowchartExpanded}
                            />
                            {this.props.isBetaTester && <span className={styles.betaMarker}>
                                <span> {translatePhrase("Tester")} </span>
                            </span>}
                        </div>

                    </div>
                </div>

                {this.props.isTopBarExpanded && <section className={styles.container}>
                    {helpSegment}
                    {orgProfile}
                    {userProfile}
                </section>}

            </section>
        );
    }
}

const PageHeader = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectedPageHeader));

export default PageHeader;
