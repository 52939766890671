import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Dispatch } from 'redux';
import { setErrorMessage, setInfoMessage } from '../../shared/store/my-data/actions';
import { connect } from 'react-redux';
import { BASE_URL } from '../../shared/store/url';

type OwnProps = {};


const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setInfoMessage: (message: string, persistMessage: boolean) => dispatch(setInfoMessage(message, persistMessage)),
        setErrorMessage: (message: string, persistMessage: boolean) => dispatch(setErrorMessage(message, persistMessage)),
    }
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & DispatchProps & RouteComponentProps<{ link: string }>;

export class ConnectedFileDownload extends Component<Props> {

    componentDidMount() {
        const link = decodeURIComponent(this.props.match.params.link);
        const name = link.split('/')[1] || link;

        const token = localStorage.getItem('token');

        if (token) {
            this.props.setInfoMessage('Started download of ' + name, true);
            window.location.assign(BASE_URL + '/file-upload/' + link + '?token=' + localStorage.getItem('token'));
        } else {
            this.props.setErrorMessage('File access failed. User not logged into DICEflow', true);
            window.location.assign(BASE_URL + '/file-upload/' + link);
        }
    }

    render() {
        return <div></div>
    }
}

const FileDownload = withRouter(connect(null, mapDispatchToProps)(ConnectedFileDownload));

export default FileDownload;