import { GroupTypeState, GroupTypeActionTypes } from './types/types';
import { NormalizedModel, CustomFieldDeltaTrackable } from '../normalized-model';
import { GroupTypeActionActions } from './types/actions/types';
import { SynchronizeWorkflowsData } from '../workflows/types';
import { CustomFieldDataHolder } from '../custom-fields/types';

export const ADD_GROUP = 'ADD_GROUP';
export const UPDATE_GROUP_REQUEST = 'UPDATE_GROUP_REQUEST';
export const UPDATE_GROUP = 'UPDATE_GROUP';

export const BULK_ADD_GROUPS = 'BULK_ADD_GROUPS';
export const BULK_UPDATE_GROUPS_REQUEST = 'BULK_UPDATE_GROUPS_REQUEST';
export const BULK_UPDATE_GROUPS = 'BULK_UPDATE_GROUPS';
export const BULK_DELETE_GROUPS = 'BULK_DELETE_GROUPS';

export const ADD_MEMBER_TO_GROUP = 'ADD_MEMBER_TO_GROUP';
export const REMOVE_MEMBER_FROM_GROUP = 'REMOVE_MEMBER_FROM_GROUP';

export const RECALCULATE_COMPUTED_FIELDS_FOR_GROUP = 'RECALCULATE_COMPUTED_FIELDS_FOR_GROUP';
export const BULK_RECALCULATE_COMPUTED_FIELDS_FOR_GROUP = 'BULK_RECALCULATE_COMPUTED_FIELDS_FOR_GROUP';
export const RECOMPUTE_ALL_GROUPS = 'RECOMPUTE_ALL_GROUPS';

export const BULK_ADD_MEMBERS_TO_GROUPS = 'BULK_ADD_MEMBERS_TO_GROUPS';
export const BULK_REMOVE_MEMBERS_FROM_GROUPS = 'BULK_REMOVE_MEMBERS_FROM_GROUPS';

export const ADD_WORKFLOW_TO_GROUP = 'ADD_WORKFLOW_TO_GROUP';
export const REMOVE_WORKFLOW_FROM_GROUP = 'REMOVE_WORKFLOW_FROM_GROUP';

export const BULK_ADD_WORKFLOWS_TO_GROUPS = 'BULK_ADD_WORKFLOWS_TO_GROUPS';
export const BULK_REMOVE_WORKFLOWS_FROM_GROUPS = 'BULK_REMOVE_WORKFLOWS_FROM_GROUPS';

export const DELETE_GROUP = 'DELETE_GROUP';
export const UN_ARCHIVE_GROUP = 'UN_ARCHIVE_GROUP';

export const UPDATE_GROUP_CUSTOM_FIELD_DATA = 'UPDATE_GROUP_CUSTOM_FIELD_DATA';
export const UPDATE_GROUP_COMPUTED_FIELD_DATA = 'UPDATE_GROUP_COMPUTED_FIELD_DATA';
export const BULK_UPDATE_GROUP_COMPUTED_FIELD_DATA = 'BULK_UPDATE_GROUP_COMPUTED_FIELD_DATA';

export const UPDATE_GROUPS_LOCATION_REQUEST = 'UPDATE_GROUP_LOCATION_REQUEST';
export const UPDATE_GROUPS_LOCATION = 'UPDATE_GROUP_LOCATION';
export const SET_MEMBERS_FOR_GROUP_REQUEST = 'SET_MEMBERS_FOR_GROUP_REQUEST';
export const SET_MEMBERS_FOR_GROUP = 'SET_MEMBERS_FOR_GROUP';


export const SET_IS_SHOWING_ADD_FORM_FOR_GROUPS = 'SET_IS_SHOWING_ADD_FORM_FOR_GROUPS';
export const SET_IS_SHOWING_MODIFY_FORM_FOR_GROUPS = 'SET_IS_SHOWING_MODIFY_FORM_FOR_GROUPS';
export const SET_IS_SHOWING_FILTER_FORM_FOR_GROUPS = 'SET_IS_SHOWING_FILTER_FORM_FOR_GROUPS';
export const SET_IS_SHOWING_CSV_FORM_FOR_GROUPS = 'SET_IS_SHOWING_CSV_FORM_FOR_GROUPS';

export const SEARCH_GROUP_TABLE = 'SEARCH_GROUP_TABLE';
export const FILTER_GROUP_TABLE = 'FILTER_GROUP_TABLE';
export const SET_IS_FILTERING_FOR_GROUP_TABLE = 'SET_IS_FILTERING_FOR_GROUP_TABLE';
export const GO_TO_PAGE_GROUP_TABLE = 'GO_TO_PAGE_GROUP_TABLE';
export const SET_PAGE_SIZE_GROUP_TABLE = 'SET_PAGE_SIZE_GROUP_TABLE';
export const SORT_GROUP_TABLE = 'SORT_GROUP_TABLE';

export const UPDATE_GROUPS_DATA = 'UPDATE_GROUPS_DATA';

export const SYNCHRONIZE_GROUPS_DATA = 'SYNCHRONIZE_GROUPS_DATA';
export const APPEND_GROUPS_DATA = 'APPEND_GROUPS_DATA';
export const CLEAR_GROUPS_DELTA = 'CLEAR_GROUPS_DELTA';

export const UPDATE_GROUP_DATA = 'UPDATE_GROUP_DATA';
export const CLEAR_GROUP_ENTRIES = 'CLEAR_GROUP_ENTRIES';

export const FETCH_GROUPS_FROM_SERVER = 'FETCH_GROUPS_FROM_SERVER';
export const SET_TOTAL_NUMBER_OF_GROUPS_FROM_SERVER = 'SET_TOTAL_NUMBER_OF_GROUPS_FROM_SERVER';

export const SET_GROUP_ID_FOR_CSV_FROM = 'SET_GROUP_ID_FOR_CSV_FROM';
export const CLEAR_GROUP_ID_FOR_CSV_FROM = 'CLEAR_GROUP_ID_FOR_CSV_FROM';

export interface INewGroupData {
    type: string,

    location: string,
    members: Array<string>,
    representatives: Array<string>,

    customFields: CustomFieldDataHolder,
}

export interface IUpdateableGroupData extends INewGroupData {
    id: string,
}

export interface IGroup extends IUpdateableGroupData, CustomFieldDeltaTrackable {
    archived?: boolean,
    createdTime: string,

    workflows: {
        [workflowTypeId: string]: Array<string>,
    },
}

export interface GroupFilters {
    types: Array<string>,
    projects: Array<string>,
    locations: Array<string>,
    customFields: {
        [fieldId: string]: Array<string>,
    },
    createdDateRange: Array<string>,
    lastUpdatedDateRange: Array<string>,
    archived: boolean,
    unsynced: boolean,
};

export interface GroupState extends NormalizedModel<IGroup> {

    types: GroupTypeState,

    isFiltering: boolean,

    noOfGroupsReceivedInLastSync: number,
    totalNoOfGroups: number,

    pageSize: number,
    currentPageNumber: number,
    filters: GroupFilters,
    sort: {
        column: string | undefined,
        order: 'ASC' | 'DESC',
    },
    searchTerm: string | undefined,

    markedForIndex: Array<string>,

    isShowingAddForm?: boolean;
    isShowingModifyForm?: boolean;
    isShowingFilterForm?: boolean;
    isShowingCSVForm?: boolean;
    selectedGroupIdForCSVForm: string | undefined
}

export interface AddGroupAction {
    type: typeof ADD_GROUP,
    payload: IGroup,
    currentTime: string,
}

export interface UpdateGroupRequestAction {
    type: typeof UPDATE_GROUP_REQUEST,
    payload: IUpdateableGroupData,
}

export interface UpdateGroupAction {
    type: typeof UPDATE_GROUP,
    payload: IUpdateableGroupData,
    currentTime: string,
}

export interface BulkAddGroupsAction {
    type: typeof BULK_ADD_GROUPS,
    payload: Array<IGroup>,
    currentTime: string,
}

export interface BulkUpdateGroupsRequestAction {
    type: typeof BULK_UPDATE_GROUPS_REQUEST,
    payload: Array<IUpdateableGroupData>,
}

export interface BulkUpdateGroupsAction {
    type: typeof BULK_UPDATE_GROUPS,
    payload: Array<IUpdateableGroupData>,
    currentTime: string,
}

export interface AddMemberToGroupAction {
    type: typeof ADD_MEMBER_TO_GROUP,
    groupId: string,
    memberId: string,
}

export interface RemoveMemberFromGroupAction {
    type: typeof REMOVE_MEMBER_FROM_GROUP,
    groupId: string,
    memberId: string,
}

export interface ChangeDeltaForGroupComputedFields {
    groupId: string,
    groupChanged: boolean,
    locationChanged: boolean,
    membersChanged: boolean,
    workflowTypesChanged: Array<string>,
}

export interface SetGroupIdForCSVForm {
    type: typeof SET_GROUP_ID_FOR_CSV_FROM,
    id: string | undefined
}

export interface ClearGroupIdForCSVForm {
    type: typeof CLEAR_GROUP_ID_FOR_CSV_FROM,
}

export interface RecalculateComputedFieldsForGroupAction extends ChangeDeltaForGroupComputedFields {
    type: typeof RECALCULATE_COMPUTED_FIELDS_FOR_GROUP,
}

export interface BulkRecalculateComputedFieldsForGroupAction {
    type: typeof BULK_RECALCULATE_COMPUTED_FIELDS_FOR_GROUP,
    payload: Array<ChangeDeltaForGroupComputedFields>
}

export interface GroupMemberLink {
    groupId: string;
    memberId: string;
}

export interface BulkAddMembersToGroupsAction {
    type: typeof BULK_ADD_MEMBERS_TO_GROUPS;
    links: Array<GroupMemberLink>;
}

export interface BulkRemoveMembersFromGroupsAction {
    type: typeof BULK_REMOVE_MEMBERS_FROM_GROUPS;
    links: Array<GroupMemberLink>;
}

export interface AddWorkflowToGroupAction {
    type: typeof ADD_WORKFLOW_TO_GROUP,
    groupId: string,
    workflowTypeId: string,
    workflowId: string,
}

export interface RemoveWorkflowFromGroupAction {
    type: typeof REMOVE_WORKFLOW_FROM_GROUP,
    groupId: string,
    workflowTypeId: string,
    workflowId: string,
}

export interface UpdateGroupCustomFieldDataAction {
    type: typeof UPDATE_GROUP_CUSTOM_FIELD_DATA,
    workflowId: string,
    groupId: string,
    customFieldData: CustomFieldDataHolder,
    currentTime: string,
}

export interface UpdateGroupComputedFieldDataAction {
    type: typeof UPDATE_GROUP_COMPUTED_FIELD_DATA,
    groupId: string,
    customFieldData: CustomFieldDataHolder,
    currentTime: string,
}

export interface ComputedFieldUpdatePayloadForGroup {
    groupId: string;
    customFieldData: CustomFieldDataHolder;
}

export interface BulkUpdateGroupComputedFieldDataAction {
    type: typeof BULK_UPDATE_GROUP_COMPUTED_FIELD_DATA,
    payload: Array<ComputedFieldUpdatePayloadForGroup>,
    currentTime: string,
}

export interface RecomputeAllGroupsAction {
    type: typeof RECOMPUTE_ALL_GROUPS,
}

export interface UpdateGroupsLocationRequestAction {
    type: typeof UPDATE_GROUPS_LOCATION_REQUEST,
    groupIds: Array<string>,
    locationId: string,
    currentTime: string,
}

export interface UpdateGroupsLocationAction {
    type: typeof UPDATE_GROUPS_LOCATION,
    groupIds: Array<string>,
    locationId: string,
    currentTime: string,
}

export interface SetMembersForGroupRequestAction {
    type: typeof SET_MEMBERS_FOR_GROUP_REQUEST,
    memberIds: Array<string>,
    memberTypes: 'representatives' | 'all_members',
    groupId: string,
}

export interface SetMembersForGroupAction {
    type: typeof SET_MEMBERS_FOR_GROUP,
    memberIds: Array<string>,
    memberTypes: 'representatives' | 'all_members',
    groupId: string,
}


export interface SetIsShowingAddFormForGroupsAction {
    type: typeof SET_IS_SHOWING_ADD_FORM_FOR_GROUPS;
    showValue: boolean;
}

export interface SetIsShowingModifyFormForGroupsAction {
    type: typeof SET_IS_SHOWING_MODIFY_FORM_FOR_GROUPS;
    showValue: boolean;
}

export interface SetIsShowingFilterFormForGroupsAction {
    type: typeof SET_IS_SHOWING_FILTER_FORM_FOR_GROUPS;
    showValue: boolean;
}

export interface SetIsShowingCSVFormForGroupsAction {
    type: typeof SET_IS_SHOWING_CSV_FORM_FOR_GROUPS;
    showValue: boolean;
}

export interface GroupWorkflowLink {
    groupId: string;
    workflowId: string;
    workflowTypeId: string;
}

export interface BulkAddWorkflowsToGroupsAction {
    type: typeof BULK_ADD_WORKFLOWS_TO_GROUPS;
    links: Array<GroupWorkflowLink>;
}

export interface BulkRemoveWorkflowsFromGroupsAction {
    type: typeof BULK_REMOVE_WORKFLOWS_FROM_GROUPS;
    links: Array<GroupWorkflowLink>;
}

export interface DeleteGroupAction {
    type: typeof DELETE_GROUP,
    id: string,
    currentTime: string,
}

export interface BulkDeleteGroupsAction {
    type: typeof BULK_DELETE_GROUPS,
    ids: Array<string>,
    currentTime: string,
}

export interface UnArchiveGroupAction {
    type: typeof UN_ARCHIVE_GROUP,
    id: string,
    currentTime: string,
}


export interface SearchGroupTableAction {
    type: typeof SEARCH_GROUP_TABLE,
    searchTerm: string,
}

export interface FilterGroupTableAction {
    type: typeof FILTER_GROUP_TABLE,
    types: Array<string>,
    projects: Array<string>,
    locations: Array<string>,
    customFields: {
        [customFieldId: string]: Array<string>,
    },
    createdDateRange: Array<string>,
    lastUpdatedDateRange: Array<string>,
    archived: boolean,
    unsynced: boolean,
}

export interface SetIsFilteringForGroupTableAction {
    type: typeof SET_IS_FILTERING_FOR_GROUP_TABLE;
    isFiltering: boolean;
}

export interface GoToPageGroupTableAction {
    type: typeof GO_TO_PAGE_GROUP_TABLE,
    pageNumber: number,
}

export interface SetPageSizeGroupTableAction {
    type: typeof SET_PAGE_SIZE_GROUP_TABLE,
    pageSize: number,
}

export interface SortGroupTableAction {
    type: typeof SORT_GROUP_TABLE,
    column: string,
    order: 'ASC' | 'DESC'
}

export interface UpdateGroupsData {
    type: typeof UPDATE_GROUPS_DATA,
    data: Array<IGroup>,
    totalNoOfGroups: number,
}

export interface SynchronizeGroupsData {
    type: typeof SYNCHRONIZE_GROUPS_DATA,
    data: Array<IGroup>,
}

export interface AppendGroupsData {
    type: typeof APPEND_GROUPS_DATA,
    data: Array<IGroup>,
}

export interface ClearGroupsDelta {
    type: typeof CLEAR_GROUPS_DELTA,
}


export interface IUpdateGroupData {
    type: typeof UPDATE_GROUP_DATA,
    data: GroupState,
}

export interface IClearGroupEntriesAction {
    type: typeof CLEAR_GROUP_ENTRIES,
}

export interface IFetchGroupsFromServerAction {
    type: typeof FETCH_GROUPS_FROM_SERVER,
}

export interface ISetTotalNumberOfGroupsFromServerAction {
    type: typeof SET_TOTAL_NUMBER_OF_GROUPS_FROM_SERVER,
    totalNumberOfGroups: number,
}

type GroupActions = AddGroupAction | UpdateGroupRequestAction | UpdateGroupAction | BulkAddGroupsAction | BulkUpdateGroupsRequestAction | BulkUpdateGroupsAction | AddMemberToGroupAction | RemoveMemberFromGroupAction | RecalculateComputedFieldsForGroupAction | BulkRecalculateComputedFieldsForGroupAction | BulkAddMembersToGroupsAction | BulkRemoveMembersFromGroupsAction | AddWorkflowToGroupAction | RemoveWorkflowFromGroupAction | UpdateGroupCustomFieldDataAction | UpdateGroupComputedFieldDataAction | BulkUpdateGroupComputedFieldDataAction | RecomputeAllGroupsAction | UpdateGroupsLocationRequestAction | UpdateGroupsLocationAction | SetMembersForGroupRequestAction | SetMembersForGroupAction | BulkAddWorkflowsToGroupsAction | BulkRemoveWorkflowsFromGroupsAction | DeleteGroupAction | BulkDeleteGroupsAction | UnArchiveGroupAction | SetGroupIdForCSVForm;

type GroupTableActions = SearchGroupTableAction | FilterGroupTableAction | SetIsFilteringForGroupTableAction | GoToPageGroupTableAction | SetPageSizeGroupTableAction | SortGroupTableAction | SetIsShowingAddFormForGroupsAction | SetIsShowingModifyFormForGroupsAction | SetIsShowingFilterFormForGroupsAction | SetIsShowingCSVFormForGroupsAction | IClearGroupEntriesAction | IFetchGroupsFromServerAction | ISetTotalNumberOfGroupsFromServerAction;

export type GroupActionTypes = GroupActions | GroupTypeActionTypes | GroupTypeActionActions | GroupTableActions | UpdateGroupsData | SynchronizeGroupsData | AppendGroupsData | ClearGroupsDelta | IUpdateGroupData | SynchronizeWorkflowsData;