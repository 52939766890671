import React, { Component } from 'react';
import styles from './Configuration.module.scss';
import { Redirect } from "react-router-dom";

import ModifyForm from '../../../widgets/card/ModifyForm';

import { Permissions } from '../../../shared/store/permissions/types';

import { translatePhrase } from '../../../shared/helpers/translation';

import { selectMemberType, unSelectMemberType, addMemberTypeCustomField } from '../../../shared/store/members/types/actions';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import MemberTypeActions from './MemberTypeActions';
import MemberTypeCustomFieldVertical from './MemberTypeCustomFieldVertical';
import MemberTypesList from './MemberTypesList';

import { ReactComponent as TemplateActionIcon } from '../../../assets/new-custom-icons/common/template-for-action.svg';
import { ReactComponent as CancelIcon } from '../../../common/assets/close.svg';

import { ApplicationState } from '../../../shared/store/types';
import uuid from 'uuid';
import { selectMemberTypeAction, unSelectMemberTypeAction, updateMemberTypeAction } from '../../../shared/store/members/types/actions/actions';
import { duplicateMemberType } from '../../../shared/helpers/duplicate';
import { IUpdateableWorkflowTypeData } from '../../../shared/store/workflows/types/types';
import { INewStatusData, IUpdateableStatusData } from '../../../shared/store/workflows/types/statuses/types';
import { addWorkflowType, addWorkflowTypeCustomField, addWorkflowTypeCustomFieldOption, registerWorkflowTypeVariable } from '../../../shared/store/workflows/types/actions';
import { addStatus } from '../../../shared/store/workflows/types/statuses/actions';
import { AllPieceTypes, IGroupPiece, PieceType, IGroupedQuestionPiece, IAddMemberPiece, IStorePiece, IVariablePiece, ICustomFieldPiece, IPickFirstElementPiece, IEndPiece, IGetValuePiece, IHexagonalTruePiece } from '../../../shared/store/flowchart/pieces/types';
import { addFullPiece, setNextPiece } from '../../../shared/store/flowchart/pieces/actions';
import { IUpdateableMemberTypeActionData } from '../../../shared/store/members/types/actions/types';
import moment from 'moment';
import { IUpdateableVariableData, VariableType } from '../../../shared/store/flowchart/variables/types';
import { addVariable } from '../../../shared/store/flowchart/variables/actions';
import { isUUID } from '../../../shared/helpers/utilities';
import LoaderModal from '../../../widgets/loader/LoaderModal';
import { INewCustomFieldData, IUpdateableWorkflowTypeCustomFieldData, INewFieldChoiceData, FieldType } from '../../../shared/store/custom-fields/types';
import EnhancedInputText from '../../../widgets/form/InputText';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canEditConfiguration = state.permissions.myPermissions.general.MembersConfiguration === Permissions.WRITE;
    const canViewConfiguration = canEditConfiguration || state.permissions.myPermissions.general.MembersConfiguration === Permissions.READ;
    const selectedMemberTypeId = state.members.types.selected;
    const customFieldIds = selectedMemberTypeId ? state.members.types.byId[selectedMemberTypeId].customFields : [];

    return {
        selectedMemberType: selectedMemberTypeId ? state.members.types.byId[selectedMemberTypeId] : undefined,
        selectedMemberTypeAction: state.members.types.actions.selected ? state.members.types.actions.byId[state.members.types.actions.selected] : undefined,
        isReadable: canViewConfiguration,
        isWritable: canEditConfiguration,
        customFieldIds,
        memberTypesData: state.members.types,
        workflowTypesData: state.workflows.types,

        isSuperUser: !isUUID(state.myData.id),
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        selectMemberType: (id: string) => dispatch(selectMemberType(id)),
        unSelectMemberType: () => dispatch(unSelectMemberType()),
        selectMemberTypeAction: (id: string) => dispatch(selectMemberTypeAction(id)),
        unSelectMemberTypeAction: () => dispatch(unSelectMemberTypeAction()),
        addMemberTypeCustomField: (payload: INewCustomFieldData, memberTypeId: string) => dispatch(addMemberTypeCustomField(payload, memberTypeId)),
        registerWorkflowTypeVariable: (variableId: string, workflowTypeId: string) => dispatch(registerWorkflowTypeVariable(variableId, workflowTypeId)),

        addWorkflowType: (payload: IUpdateableWorkflowTypeData) => dispatch(addWorkflowType(payload)),
        addWorkflowTypeCustomField: (payload: IUpdateableWorkflowTypeCustomFieldData, workflowTypeId: string) => dispatch(addWorkflowTypeCustomField(payload, workflowTypeId)),
        addWorkflowTypeCustomFieldOption: (payload: INewFieldChoiceData, workflowTypeId: string) => dispatch(addWorkflowTypeCustomFieldOption(payload, workflowTypeId)),
        addFullPiece: (payload: AllPieceTypes) => dispatch(addFullPiece(payload)),
        setNextPiece: (targetPieceId: string, draggedPieceId: string) => dispatch(setNextPiece(targetPieceId, draggedPieceId)),
        addStatus: (payload: INewStatusData, parentId: string) => dispatch(addStatus(payload, parentId)),
        addVariable: (payload: IUpdateableVariableData) => dispatch(addVariable(payload)),

        updateMemberTypeAction: (payload: IUpdateableMemberTypeActionData) => dispatch(updateMemberTypeAction(payload)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
    isShowingAddForm: boolean;
    modifyingVerticalName: string;
    modifyingVerticalType: string;
    modifyingVerticalIsComputed: string;
    modifyingVerticalIsInTable: string;

    loaderMessage: {
        message: string;
        isError: boolean;
    };
};

class ConnectedLocations extends Component<Props, OwnState> {

    state: OwnState = {
        isShowingAddForm: false,
        modifyingVerticalName: '',
        modifyingVerticalType: '',
        modifyingVerticalIsComputed: 'No',
        modifyingVerticalIsInTable: 'No',
        loaderMessage: {
            message: '',
            isError: false
        }
    };

    showErrorMessage = (message: string, isError: boolean) => {
        this.setState({
            loaderMessage: {
                message,
                isError,
            }
        });

        window.setTimeout(() => {
            this.setState({
                loaderMessage: {
                    message: '',
                    isError: false,
                }
            });
        }, 3000);

    }

    toggleCustomFieldAddForm = () => {
        this.setState((prevState) => {
            return {
                isShowingAddForm: !prevState.isShowingAddForm,
            };
        });
    }

    updateFieldName = (value: string) => {
        this.setState({
            modifyingVerticalName: value,
        });
    }

    updateFieldType = (value: string) => {
        this.setState({
            modifyingVerticalType: value,
        });
    }

    updateFieldIsComputed = (value: string) => {
        this.setState({
            modifyingVerticalIsComputed: value,
        });
    }

    updateFieldIsInTable = (value: string) => {
        this.setState({
            modifyingVerticalIsInTable: value,
        });
    }

    addCustomField = () => {
        const fieldType = this.state.modifyingVerticalType as keyof typeof FieldType;

        if (!this.props.selectedMemberType) {
            return;
        }

        if (this.validateVerticalForm() !== true) {
            return;
        }

        this.props.addMemberTypeCustomField({
            name: this.state.modifyingVerticalName,
            type: FieldType[fieldType],
            isComputed: this.state.modifyingVerticalIsComputed === 'Yes',
            isEditable: true,
            isDeletable: true,
            isInTable: this.state.modifyingVerticalIsInTable === 'Yes',
            seedEntityVariable: uuid.v4(),
        }, this.props.selectedMemberType.id);

        this.setState({
            isShowingAddForm: false,
            modifyingVerticalName: '',
            modifyingVerticalType: '',
            modifyingVerticalIsComputed: 'No',
            modifyingVerticalIsInTable: 'No',
        });
    }

    validateVerticalForm = () => {
        const fieldTypes = Object.keys(FieldType);
        const binaryTypes = ['Yes', 'No'];

        if (!this.state.modifyingVerticalName) {
            return 'Enter a valid name';
        }

        const memberTypesCustomFieldNames: Array<string> = this.props.customFieldIds.map(id => {
            return this.props.memberTypesData.customFields.byId[id].name.toLocaleLowerCase();
        });

        if (memberTypesCustomFieldNames.includes(this.state.modifyingVerticalName.toLocaleLowerCase())) {
            return 'A custom field with this name already exists'
        }

        if (!fieldTypes.includes(this.state.modifyingVerticalType)) {
            return 'Enter a valid type';
        }

        if (!binaryTypes.includes(this.state.modifyingVerticalIsInTable)) {
            return 'Enter a valid in-table field'
        }

        return true;
    }

    doesWorkflowTypeWithNameExist = (name: string) => {
        return !!this.props.workflowTypesData.allEntries.find(workflowTypeId => this.props.workflowTypesData.byId[workflowTypeId].name === name)
    }

    onDuplicate = () => {
        this.props.selectedMemberType &&
            duplicateMemberType(
                this.props.selectedMemberType
                    ? this.props.selectedMemberType.id
                    : ""
            );
    }

    generateTemplateForAdd = () => {
        if (!this.props.selectedMemberType) {
            return;
        }

        const addAction = this.props.memberTypesData.actions.byId[this.props.selectedMemberType.actions[0]];

        const managedAddWorkflowTypeId = uuid.v4();
        const startPieceId = uuid.v4();
        const betaStartPieceId = uuid.v4();

        const baseWorkflowTypeName = `Add ${this.props.selectedMemberType.name} member`;
        let workflowTypeName = baseWorkflowTypeName;
        let workflowTypeCount = 1;

        while (this.doesWorkflowTypeWithNameExist(workflowTypeName)) {
            workflowTypeCount += 1;
            workflowTypeName = baseWorkflowTypeName + ' ' + workflowTypeCount;
        }

        // Add action workflow type
        const managedAddWorkflowTypeData: IUpdateableWorkflowTypeData = {
            id: managedAddWorkflowTypeId,
            name: workflowTypeName,
            project: this.props.selectedMemberType.project,
            affiliation: 'none',
            affiliatedEntity: '',
            isCore: true,
            areMultipleInstancesAllowed: false,
            isManaged: true,
            seedAffiliationVariable: uuid.v4(),
            seedEntityVariable: uuid.v4(),
            startPiece: {
                piece: startPieceId,
                position: {
                    x: 0,
                    y: 0,
                },
            },
            betaStartPiece: {
                piece: betaStartPieceId,
                position: {
                    x: 0,
                    y: 0,
                },
            },
        };

        const openStatusId = uuid.v4();
        const managedOpenStatus: IUpdateableStatusData = {
            id: openStatusId,
            name: 'Open',
            isTerminal: false,
            dueInDays: 7,
        };

        const closedStatusId = uuid.v4();
        const managedClosedStatus: IUpdateableStatusData = {
            id: closedStatusId,
            name: 'Closed',
            isTerminal: true,
        };

        this.props.addWorkflowType(managedAddWorkflowTypeData);
        this.props.addStatus(managedOpenStatus, managedAddWorkflowTypeId);
        this.props.addStatus(managedClosedStatus, managedAddWorkflowTypeId);

        this.props.updateMemberTypeAction({
            id: addAction.id,
            name: addAction.name,
            icon: addAction.icon,
            workflowType: managedAddWorkflowTypeId,
        });

        // Add custom fields

        const customFieldIdMap: { [id: string]: string } = {};

        for (const customFieldId of this.props.selectedMemberType.customFields) {
            const customField = this.props.memberTypesData.customFields.byId[customFieldId];

            if (customField.isComputed) {
                continue;
            }

            const newCustomFieldId = uuid.v4();

            customFieldIdMap[customFieldId] = newCustomFieldId;

            const newCustomfieldData: IUpdateableWorkflowTypeCustomFieldData = {
                id: newCustomFieldId,
                name: customField.name,
                type: customField.type,
                isComputed: customField.isComputed,
                isDeletable: customField.isDeletable,
                isEditable: customField.isEditable,
                isInTable: customField.isInTable,
                seedEntityVariable: customField.seedEntityVariable,
                affiliation: 'member',
            };

            this.props.addWorkflowTypeCustomField(newCustomfieldData, managedAddWorkflowTypeId);

            // Add custom field options
            for (const choiceId of customField.choices) {
                const customFIeldOption = this.props.memberTypesData.customFieldOptions.byId[choiceId];

                const newCustomFieldOptionData: INewFieldChoiceData = {
                    name: customFIeldOption.name,
                };

                this.props.addWorkflowTypeCustomFieldOption(newCustomFieldOptionData, newCustomFieldId);
            }
        }

        // Generate flowchart

        const questions = this.props.selectedMemberType.customFields
            .filter(customFieldId => !this.props.memberTypesData.customFields.byId[customFieldId].isComputed)
            .map(customFieldId => {

                const truePiece: IHexagonalTruePiece = {
                    id: uuid.v4(),
                    type: PieceType.HEXAGONAL_TRUE,
                    createdTime: moment().format(),
                    lastUpdatedTime: moment().format(),
                };

                this.props.addFullPiece(truePiece);

                const questionPiece: IGroupedQuestionPiece = {
                    id: uuid.v4(),
                    type: PieceType.GROUPED_QUESTION,
                    createdTime: moment().format(),
                    lastUpdatedTime: moment().format(),
                    isRequiredPiece: truePiece.id,
                    customFieldId: customFieldIdMap[customFieldId],
                };

                return questionPiece;
            });

        for (let i = 0; i < questions.length - 1; i += 1) {
            questions[i].nextPiece = questions[i + 1].id;
        }

        const addMemberPieceId = uuid.v4();

        const groupPiece: IGroupPiece = {
            id: uuid.v4(),
            type: PieceType.GROUP,
            innerPiece: questions[0].id,
            createdTime: moment().format(),
            lastUpdatedTime: moment().format(),
            nextPiece: addMemberPieceId,
        };

        this.props.addFullPiece(groupPiece);
        this.props.setNextPiece(managedAddWorkflowTypeData.startPiece.piece, groupPiece.id);

        for (const question of questions) {
            this.props.addFullPiece(question);
        }

        const memberVariable: IUpdateableVariableData = {
            id: uuid.v4(),
            name: 'New Member',
            type: VariableType.MEMBER,
        };

        this.props.addVariable(memberVariable);
        this.props.registerWorkflowTypeVariable(memberVariable.id, managedAddWorkflowTypeData.id);

        const storePieces = this.props.selectedMemberType.customFields
            .filter(customFieldId => !this.props.memberTypesData.customFields.byId[customFieldId].isComputed)
            .map(customFieldId => {

                if (!this.props.selectedMemberType) {
                    throw new Error('The member type must be defined');
                }

                const memberVariablePiece: IVariablePiece = {
                    id: uuid.v4(),
                    type: PieceType.VARIABLE,
                    variable: memberVariable.id,
                    createdTime: moment().format(),
                    lastUpdatedTime: moment().format(),
                };

                this.props.addFullPiece(memberVariablePiece);

                const customFieldPiece: ICustomFieldPiece = {
                    id: uuid.v4(),
                    type: PieceType.CUSTOM_FIELD,
                    createdTime: moment().format(),
                    lastUpdatedTime: moment().format(),
                    customField: customFieldIdMap[customFieldId],
                };

                this.props.addFullPiece(customFieldPiece);

                const storePiece: IStorePiece = {
                    id: uuid.v4(),
                    type: PieceType.STORE,
                    createdTime: moment().format(),
                    lastUpdatedTime: moment().format(),

                    variablePiece: memberVariablePiece.id,
                    entityType: this.props.selectedMemberType.id,
                    dataToStore: customFieldPiece.id,
                    customFieldId: customFieldId,
                }

                return storePiece;
            });

        for (let i = 0; i < storePieces.length - 1; i += 1) {
            storePieces[i].nextPiece = storePieces[i + 1].id;
        }

        const locationPiece: IVariablePiece = {
            id: uuid.v4(),
            type: PieceType.VARIABLE,
            createdTime: moment().format(),
            lastUpdatedTime: moment().format(),
            variable: managedAddWorkflowTypeData.seedEntityVariable,
            nesting: [{
                value: 'user',
            }, {
                value: 'locations',
            }],
        };

        this.props.addFullPiece(locationPiece);

        const firstOfListPiece: IPickFirstElementPiece = {
            id: uuid.v4(),
            type: PieceType.PICK_FIRST_ELEMENT,
            createdTime: moment().format(),
            lastUpdatedTime: moment().format(),
            operand: locationPiece.id,
        }

        this.props.addFullPiece(firstOfListPiece);

        const addMemberPiece: IAddMemberPiece = {
            id: addMemberPieceId,
            type: PieceType.ADD_MEMBER,
            variable: memberVariable.id,
            entityType: this.props.selectedMemberType.id,
            createdTime: moment().format(),
            lastUpdatedTime: moment().format(),
            nextPiece: storePieces[0].id,
            locationPiece: firstOfListPiece.id,
        };

        this.props.addFullPiece(addMemberPiece);

        const endPiece: IEndPiece = {
            id: uuid.v4(),
            type: PieceType.END,
            status: closedStatusId,
            createdTime: moment().format(),
            lastUpdatedTime: moment().format(),
        };

        this.props.addFullPiece(endPiece);

        storePieces[storePieces.length - 1].nextPiece = endPiece.id;

        for (const storePiece of storePieces) {
            this.props.addFullPiece(storePiece);
        }

        this.showErrorMessage(`The workflow type "${managedAddWorkflowTypeData.name}" has been created`, false);

    }

    generateTemplateForEdit = () => {
        if (!this.props.selectedMemberType) {
            return;
        }

        const editAction = this.props.memberTypesData.actions.byId[this.props.selectedMemberType.actions[1]];

        const managedEditWorkflowTypeId = uuid.v4();
        const startPieceId = uuid.v4();
        const betaStartPieceId = uuid.v4();

        const baseWorkflowTypeName = `Update ${this.props.selectedMemberType.name} member`;
        let workflowTypeName = baseWorkflowTypeName;
        let workflowTypeCount = 1;

        while (this.doesWorkflowTypeWithNameExist(workflowTypeName)) {
            workflowTypeCount += 1;
            workflowTypeName = baseWorkflowTypeName + ' ' + workflowTypeCount;
        }

        // Add action workflow type
        const managedEditWorkflowTypeData: IUpdateableWorkflowTypeData = {
            id: managedEditWorkflowTypeId,
            name: workflowTypeName,
            project: this.props.selectedMemberType.project,
            affiliation: 'member',
            affiliatedEntity: this.props.selectedMemberType.id,
            isCore: true,
            areMultipleInstancesAllowed: false,
            isManaged: true,
            seedAffiliationVariable: uuid.v4(),
            seedEntityVariable: uuid.v4(),
            startPiece: {
                piece: startPieceId,
                position: {
                    x: 0,
                    y: 0,
                },
            },
            betaStartPiece: {
                piece: betaStartPieceId,
                position: {
                    x: 0,
                    y: 0,
                },
            },
        };

        const openStatusId = uuid.v4();
        const managedOpenStatus: IUpdateableStatusData = {
            id: openStatusId,
            name: 'Open',
            isTerminal: false,
            dueInDays: 7,
        };

        const closedStatusId = uuid.v4();
        const managedClosedStatus: IUpdateableStatusData = {
            id: closedStatusId,
            name: 'Closed',
            isTerminal: true,
        };

        this.props.addWorkflowType(managedEditWorkflowTypeData);
        this.props.addStatus(managedOpenStatus, managedEditWorkflowTypeId);
        this.props.addStatus(managedClosedStatus, managedEditWorkflowTypeId);

        this.props.updateMemberTypeAction({
            id: editAction.id,
            name: editAction.name,
            icon: editAction.icon,
            workflowType: managedEditWorkflowTypeId,
        });

        // Add custom fields

        const customFieldIdMap: { [id: string]: string } = {};

        for (const customFieldId of this.props.selectedMemberType.customFields) {
            const customField = this.props.memberTypesData.customFields.byId[customFieldId];

            if (customField.isComputed) {
                continue;
            }

            const newCustomFieldId = uuid.v4();

            customFieldIdMap[customFieldId] = newCustomFieldId;

            const newCustomfieldData: IUpdateableWorkflowTypeCustomFieldData = {
                id: newCustomFieldId,
                name: customField.name,
                type: customField.type,
                isComputed: customField.isComputed,
                isDeletable: customField.isDeletable,
                isEditable: customField.isEditable,
                isInTable: customField.isInTable,
                seedEntityVariable: customField.seedEntityVariable,
                affiliation: 'member',
            };

            this.props.addWorkflowTypeCustomField(newCustomfieldData, managedEditWorkflowTypeId);

            // Add custom field options
            for (const choiceId of customField.choices) {
                const customFIeldOption = this.props.memberTypesData.customFieldOptions.byId[choiceId];

                const newCustomFieldOptionData: INewFieldChoiceData = {
                    name: customFIeldOption.name,
                };

                this.props.addWorkflowTypeCustomFieldOption(newCustomFieldOptionData, newCustomFieldId);
            }
        }

        // Generate flowchart

        const questions = this.props.selectedMemberType.customFields
            .filter(customFieldId => !this.props.memberTypesData.customFields.byId[customFieldId].isComputed)
            .map(customFieldId => {

                const memberVariablePiece: IVariablePiece = {
                    id: uuid.v4(),
                    type: PieceType.VARIABLE,
                    variable: managedEditWorkflowTypeData.seedAffiliationVariable,
                    createdTime: moment().format(),
                    lastUpdatedTime: moment().format(),
                };

                this.props.addFullPiece(memberVariablePiece);

                const getValuePiece: IGetValuePiece = {
                    id: uuid.v4(),
                    type: PieceType.GET_VALUE,
                    variablePiece: memberVariablePiece.id,
                    entityType: this.props.selectedMemberType ? this.props.selectedMemberType.id : undefined,
                    customFieldId: customFieldId,

                    createdTime: moment().format(),
                    lastUpdatedTime: moment().format(),
                };

                this.props.addFullPiece(getValuePiece);

                const truePiece: IHexagonalTruePiece = {
                    id: uuid.v4(),
                    type: PieceType.HEXAGONAL_TRUE,
                    createdTime: moment().format(),
                    lastUpdatedTime: moment().format(),
                };

                this.props.addFullPiece(truePiece);


                const questionPiece: IGroupedQuestionPiece = {
                    id: uuid.v4(),
                    type: PieceType.GROUPED_QUESTION,
                    createdTime: moment().format(),
                    lastUpdatedTime: moment().format(),
                    customFieldId: customFieldIdMap[customFieldId],
                    isRequiredPiece: truePiece.id,
                    default: getValuePiece.id,
                };

                return questionPiece;
            });

        for (let i = 0; i < questions.length - 1; i += 1) {
            questions[i].nextPiece = questions[i + 1].id;
        }

        for (const question of questions) {
            this.props.addFullPiece(question);
        }

        const storePieces = this.props.selectedMemberType.customFields
            .filter(customFieldId => !this.props.memberTypesData.customFields.byId[customFieldId].isComputed)
            .map(customFieldId => {

                if (!this.props.selectedMemberType) {
                    throw new Error('The member type must be defined');
                }

                const memberVariablePiece: IVariablePiece = {
                    id: uuid.v4(),
                    type: PieceType.VARIABLE,
                    variable: managedEditWorkflowTypeData.seedAffiliationVariable,
                    createdTime: moment().format(),
                    lastUpdatedTime: moment().format(),
                };

                this.props.addFullPiece(memberVariablePiece);

                const customFieldPiece: ICustomFieldPiece = {
                    id: uuid.v4(),
                    type: PieceType.CUSTOM_FIELD,
                    createdTime: moment().format(),
                    lastUpdatedTime: moment().format(),
                    customField: customFieldIdMap[customFieldId],
                };

                this.props.addFullPiece(customFieldPiece);

                const storePiece: IStorePiece = {
                    id: uuid.v4(),
                    type: PieceType.STORE,
                    createdTime: moment().format(),
                    lastUpdatedTime: moment().format(),

                    variablePiece: memberVariablePiece.id,
                    entityType: this.props.selectedMemberType.id,
                    dataToStore: customFieldPiece.id,
                    customFieldId: customFieldId,
                }

                return storePiece;
            });

        for (let i = 0; i < storePieces.length - 1; i += 1) {
            storePieces[i].nextPiece = storePieces[i + 1].id;
        }

        const groupPiece: IGroupPiece = {
            id: uuid.v4(),
            type: PieceType.GROUP,
            innerPiece: questions[0].id,
            createdTime: moment().format(),
            lastUpdatedTime: moment().format(),
            nextPiece: storePieces[0].id,
        };

        this.props.addFullPiece(groupPiece);
        this.props.setNextPiece(managedEditWorkflowTypeData.startPiece.piece, groupPiece.id);

        const endPiece: IEndPiece = {
            id: uuid.v4(),
            type: PieceType.END,
            status: closedStatusId,
            createdTime: moment().format(),
            lastUpdatedTime: moment().format(),
        };

        this.props.addFullPiece(endPiece);

        storePieces[storePieces.length - 1].nextPiece = endPiece.id;

        for (const storePiece of storePieces) {
            this.props.addFullPiece(storePiece);
        }

        this.showErrorMessage(`The workflow type "${managedEditWorkflowTypeData.name}" has been created`, false);

    }

    generateTemplateForAction = () => {
        if (!this.props.selectedMemberType || !this.props.selectedMemberTypeAction) {
            return;
        }

        if (this.props.selectedMemberType.actions[0] === this.props.selectedMemberTypeAction.id) {
            this.generateTemplateForAdd();
        } else if (this.props.selectedMemberType.actions[1] === this.props.selectedMemberTypeAction.id) {
            this.generateTemplateForEdit();
        } else {
            this.showErrorMessage('There is no template for this action. Please create a workflow type and link this manually', true);
        }
    }

    render() {
        if (!this.props.isReadable) {
            return <Redirect to="/dashboard" />;
        }

        const typeOptions = Object.keys(FieldType).map(name => {
            return {
                name: name.split('_').join(' '),
                value: name,
            }
        });

        const addFieldForm =
            <div className={styles.modifyFormCard}>
                <div>
                    <header>
                        <div className={styles.heading}>
                            {translatePhrase('Add Member Type Custom Field')}
                        </div>
                        <button className={styles.cancelCustomFieldButton} title={translatePhrase('Cancel')} onClick={this.toggleCustomFieldAddForm}> <CancelIcon /> </button>
                    </header>
                </div>
                <div className={styles.container}>
                    <ModifyForm hideCancel isNew={true} submitForm={this.addCustomField} cancelForm={this.toggleCustomFieldAddForm} validateForm={this.validateVerticalForm}>
                        <EnhancedInputText placeholder="Name" onEnterPress={this.addCustomField} onChange={this.updateFieldName} />
                        <EnhancedInputText placeholder="Type" onEnterPress={this.addCustomField} onChange={this.updateFieldType} options={typeOptions} />
                        <EnhancedInputText isBooleanField placeholder="Is Computed" onEnterPress={this.addCustomField} onChange={this.updateFieldIsComputed} defaultBooleanValue={false} />
                        <EnhancedInputText isBooleanField placeholder="Searchable / Show in Table" onEnterPress={this.addCustomField} onChange={this.updateFieldIsInTable} defaultBooleanValue={false} />
                    </ModifyForm>
                </div>
            </div>;

        let shouldAllowTemplate = false;

        if (this.props.isSuperUser && this.props.selectedMemberType && this.props.selectedMemberTypeAction) {
            if (!this.props.selectedMemberTypeAction.workflowType) {
                if (this.props.selectedMemberType.actions.slice(0, 2).includes(this.props.selectedMemberTypeAction.id)) {
                    shouldAllowTemplate = true;
                }
            }
        }

        const memberTypesCustomFieldNames: Array<string> = this.props.customFieldIds.map(id => {
            return this.props.memberTypesData.customFields.byId[id].name.toLocaleLowerCase();
        });

        const defaultCustomFieldIds = this.props.selectedMemberType ? [
            this.props.selectedMemberType.nameFieldId,
            this.props.selectedMemberType.subTitleFieldId,
            this.props.selectedMemberType.locationFieldId,
        ] : [];

        const otherCustomFieldIds = this.props.customFieldIds.filter(customFieldId => !defaultCustomFieldIds.includes(customFieldId));

        const defaultCustomFields = defaultCustomFieldIds.map((id) => {
            const memberTypeCustomField = this.props.memberTypesData.customFields.byId[id];
            const remainingCustomFieldNames = memberTypesCustomFieldNames.filter(fieldName => fieldName.toLocaleLowerCase() !== memberTypeCustomField.name.toLocaleLowerCase());

            return <MemberTypeCustomFieldVertical
                existingCardNames={remainingCustomFieldNames}
                memberTypeId={this.props.selectedMemberType ? this.props.selectedMemberType.id : ''}
                customFieldId={id} key={id} />
        });

        const otherCustomFields = otherCustomFieldIds.map((id) => {
            const memberTypeCustomField = this.props.memberTypesData.customFields.byId[id];
            const remainingCustomFieldNames = memberTypesCustomFieldNames.filter(fieldName => fieldName.toLocaleLowerCase() !== memberTypeCustomField.name.toLocaleLowerCase());

            return <MemberTypeCustomFieldVertical
                existingCardNames={remainingCustomFieldNames}
                memberTypeId={this.props.selectedMemberType ? this.props.selectedMemberType.id : ''}
                customFieldId={id} key={id} />
        });


        return (<div className={styles.innerFocusContainer}>
            <div className={styles.importExportButtonHolder + ' ignore-top-level-onclickoutside ignore-react-onclickoutside'}>
                {shouldAllowTemplate && this.props.selectedMemberTypeAction && <button onClick={this.generateTemplateForAction}> <TemplateActionIcon /> {translatePhrase('Create workflow for')}: {translatePhrase(this.props.selectedMemberTypeAction.name)} </button>}
            </div>

            <div className={styles.membersHolder}>

                {this.state.loaderMessage.message && this.state.loaderMessage.isError && <LoaderModal loaderText={[translatePhrase(this.state.loaderMessage.message)]} isError={true} />}

                {this.state.loaderMessage.message && !this.state.loaderMessage.isError && <LoaderModal loaderText={[translatePhrase(this.state.loaderMessage.message)]} isSuccess={true} />}


                <div className={styles.allMemberTypes}>
                    <MemberTypesList heading="Member Types" onSelectCard={this.props.selectMemberType} onUnSelectCard={this.props.unSelectMemberType} selectedId={this.props.selectedMemberType ? this.props.selectedMemberType.id : ''} isReadOnly={!this.props.isWritable} onDuplicate={this.onDuplicate} />
                </div>
                {this.props.selectedMemberType &&
                    <div className={styles.cardsTree + ' ignore-react-onclickoutside'}>
                        <header className={styles.treeHeader}>
                            {translatePhrase('Actions')}
                        </header>
                        <div className={styles.cardsTreeHolder}>
                            <MemberTypeActions
                                key={this.props.selectedMemberType.id}
                                heading="Actions"
                                selectedId={this.props.selectedMemberTypeAction ? this.props.selectedMemberTypeAction.id : ''}
                                parentId={this.props.selectedMemberType.id}
                                onSelectCard={this.props.selectMemberTypeAction}
                                onUnSelectCard={this.props.unSelectMemberTypeAction}
                                onGenerate={shouldAllowTemplate && this.props.selectedMemberTypeAction ? this.generateTemplateForAction : undefined}
                            />
                        </div>

                        <header className={styles.treeHeader}>
                            {translatePhrase('Default Fields')}
                        </header>
                        <div className={styles.cardsTreeHolder}>
                            {defaultCustomFields}
                        </div>

                        <header className={styles.treeHeader}>
                            {translatePhrase('Custom Fields')}
                        </header>
                        <div className={styles.cardsTreeHolder}>
                            {this.props.isWritable && <section className={styles.newVerticalHolder + ' ignore-react-onclickoutside'}>
                                {this.state.isShowingAddForm ? addFieldForm : <section onClick={this.toggleCustomFieldAddForm} className={styles.newCustomFieldPrompt}> + {translatePhrase('Add Custom Field')}</section>}
                            </section>}
                            {otherCustomFields}
                        </div>

                    </div>}
            </div>
        </div>);
    }
}

const Locations = connect(mapStateToProps, mapDispatchToProps)(ConnectedLocations);

export default Locations;