import { watchGroupTypeCreationRequest, watchGroupTypeInstantiationRequest, watchGroupTypeCustomFieldChanges, watchGroupCreationChanges, watchGroupDeletionChanges, watchGroupsDeletionChanges, watchGroupTypeActionCreationChanges, watchGroupTypeActionDeletionChanges, watchGroupUnarchiveChanges, watchGroupUpdateChanges, watchGroupMemberUpdateChanges, watchGroupAddToMemberChanges, watchGroupRemoveFromMemberChanges, watchGroupLocationUpdate, watchBulkGroupCustomFieldRecalculation, watchGroupCustomFieldRecalculation, watchGroupCustomFieldUpdateChanges, watchRecomputeAllGroups, watchBulkGroupAddToMemberChanges, watchBulkGroupRemoveFromMemberChanges, watchGroupPagePageChanges, watchGroupQueryChangesForOnline, watchBulkGroupCreationChanges, watchBulkGroupUpdateChanges } from "./groups/sagas";
import { sagaMiddleware } from "./main";
import { watchMemberTypeCreationRequest, watchMemberTypeInstantiationRequest, watchMemberTypeCustomFieldCreation, watchMemberTypeCustomFieldChanges, watchMemberCreationChanges, watchMemberDeletionChanges, watchMembersDeletionChanges, watchBulkMemberCreationChanges, watchMemberTypeActionCreationChanges, watchMemberTypeActionDeletionChanges, watchMemberUnarchiveChanges, watchMemberUpdateChanges, watchBulkMemberUpdateChanges, watchMemberCustomFieldUpdateChanges, watchMemberAddToGroupChanges, watchMemberRemoveFromGroupChanges, watchMemberLocationUpdate, watchMemberCustomFieldRecalculation, watchBulkMemberCustomFieldRecalculation, watchRecomputeAllMembers, watchMemberPagePageChanges, watchMemberQueryChangesForOnline, watchBulkMemberAddToGroupChanges, watchBulkMemberRemoveFromGroupChanges } from "./members/sagas";
import { watchIDUpdate, watchPermissionUpdate, watchChangeSuperUserPassword, watchToastMessage, watchProjectSetup, watchErrorMessage, watchInfoMessage } from "./my-data/sagas";
import { watchReportTypeCreationRequest } from "./reports/types/sagas";
import { watchDataFetchRequest, watchDataWipeRequest, watchChangeDataRequest, watchSaveDataRequest, watchOlderDataFetchRequest, watchResumePartialResponseRequest, watchErrorAddRequest, watchSaveFilesToIDB, watchDeleteFilesInIDB, watchSaveDataToSnapshotDuringSystemUpdate } from "./sagas";
import { watchDataFragmentCreationChanges, watchDataFragmentDeletionChanges, watchStaticDataCreationChanges } from "./static-info/data-fragment/sagas";
import { watchLevelCreationChanges, watchLevelDeletionChanges, watchLevelCustomFieldChanges } from "./structure/level/sagas";
import { watchLocationCreationChanges, watchLocationDeletionChanges, watchLocationUpsertChanges } from "./structure/location/sagas";
import { watchRoleCreationChanges, watchRoleDeletionChanges, watchRoleCustomFieldChanges } from "./structure/role/sagas";
import { watchUserCreationRequestChanges, watchUserCreationChanges, watchUserDeletionChanges, watchUserUnarchiveChanges, watchUserUpdateChanges, watchUserCustomFieldChanges, watchProjectUpdateChanges, watchLevelUpdateChanges, watchRoleUpdateChanges, watchBulkUserCustomFieldRecalculation, watchRecomputeAllUsers, watchUserCustomFieldRecalculation } from "./users/sagas";
import { watchWidgetCreationRequest, watchWidgetUpdateRequest, watchWidgetDeletionRequest } from "./widgets/sagas";
import { watchWorkflowTypeCreation, watchWorkflowTypeInstantiation, watchStatusCreationChanges, watchStatusDeletionChanges, watchWorkflowTypeCustomFieldCreation, watchWorkflowChanges, watchWorkflowCreationChanges, watchWorkflowDeletionChanges, watchWorkflowUnarchiveChanges, watchWorkflowExecutionChanges, watchBulkWorkflowCustomFieldRecalculation, watchRecomputeAllWorkflows, watchWorkflowCustomFieldRecalculation, watchWorkflowPagePageChanges, watchWorkflowQueryChangesForOnline, watchWorkflowTypeFlowchartPublish, watchWorkflowExecutionEnd, watchWorkflowsDeletionChanges } from "./workflows/sagas";
import { watchPackageCreationChanges, watchPackageDeletionChanges } from './package/sagas';
import { watchLanguageCreationChanges } from "./internationalization/sagas";
import { watchAddSyncLog, watchFailedSyncLog } from "./sync-logs/sagas";

export function attachSagasLocally() {
    sagaMiddleware.run(watchDataFetchRequest);
    sagaMiddleware.run(watchDataWipeRequest);
    // sagaMiddleware.run(watchDataPushRequest);
    sagaMiddleware.run(watchChangeDataRequest);
    sagaMiddleware.run(watchSaveDataRequest);
    sagaMiddleware.run(watchOlderDataFetchRequest);
    sagaMiddleware.run(watchResumePartialResponseRequest);
    sagaMiddleware.run(watchErrorAddRequest);

    sagaMiddleware.run(watchReportTypeCreationRequest);

    sagaMiddleware.run(watchWidgetCreationRequest);
    sagaMiddleware.run(watchWidgetUpdateRequest);
    sagaMiddleware.run(watchWidgetDeletionRequest);

    sagaMiddleware.run(watchLevelCreationChanges);
    sagaMiddleware.run(watchLevelDeletionChanges);
    sagaMiddleware.run(watchLevelCustomFieldChanges);

    sagaMiddleware.run(watchRoleCreationChanges);
    sagaMiddleware.run(watchRoleDeletionChanges);
    sagaMiddleware.run(watchRoleCustomFieldChanges);

    sagaMiddleware.run(watchLocationCreationChanges);
    sagaMiddleware.run(watchLocationUpsertChanges);
    sagaMiddleware.run(watchLocationDeletionChanges);

    sagaMiddleware.run(watchUserCreationRequestChanges);
    sagaMiddleware.run(watchUserCreationChanges);
    sagaMiddleware.run(watchUserDeletionChanges);
    sagaMiddleware.run(watchUserUnarchiveChanges);
    sagaMiddleware.run(watchUserUpdateChanges);
    sagaMiddleware.run(watchUserCustomFieldChanges);
    sagaMiddleware.run(watchProjectUpdateChanges);
    sagaMiddleware.run(watchLevelUpdateChanges);
    sagaMiddleware.run(watchRoleUpdateChanges);
    sagaMiddleware.run(watchUserCustomFieldRecalculation);
    sagaMiddleware.run(watchBulkUserCustomFieldRecalculation);
    sagaMiddleware.run(watchRecomputeAllUsers);

    sagaMiddleware.run(watchStaticDataCreationChanges);
    sagaMiddleware.run(watchDataFragmentCreationChanges);
    sagaMiddleware.run(watchDataFragmentDeletionChanges);

    sagaMiddleware.run(watchLanguageCreationChanges);

    sagaMiddleware.run(watchMemberTypeCreationRequest);
    sagaMiddleware.run(watchMemberTypeInstantiationRequest);
    sagaMiddleware.run(watchMemberTypeCustomFieldCreation);
    sagaMiddleware.run(watchMemberTypeCustomFieldChanges);
    sagaMiddleware.run(watchMemberCreationChanges);
    sagaMiddleware.run(watchMemberDeletionChanges);
    sagaMiddleware.run(watchMembersDeletionChanges);
    sagaMiddleware.run(watchBulkMemberCreationChanges);
    sagaMiddleware.run(watchMemberTypeActionCreationChanges);
    sagaMiddleware.run(watchMemberTypeActionDeletionChanges);
    sagaMiddleware.run(watchMemberUnarchiveChanges);
    sagaMiddleware.run(watchMemberUpdateChanges);
    sagaMiddleware.run(watchBulkMemberUpdateChanges);
    sagaMiddleware.run(watchMemberCustomFieldUpdateChanges);
    sagaMiddleware.run(watchMemberAddToGroupChanges);
    sagaMiddleware.run(watchMemberRemoveFromGroupChanges);
    sagaMiddleware.run(watchBulkMemberAddToGroupChanges);
    sagaMiddleware.run(watchBulkMemberRemoveFromGroupChanges);
    sagaMiddleware.run(watchMemberLocationUpdate);
    sagaMiddleware.run(watchMemberCustomFieldRecalculation);
    sagaMiddleware.run(watchBulkMemberCustomFieldRecalculation);
    sagaMiddleware.run(watchRecomputeAllMembers);
    sagaMiddleware.run(watchMemberPagePageChanges);
    sagaMiddleware.run(watchMemberQueryChangesForOnline);

    sagaMiddleware.run(watchGroupTypeCreationRequest);
    sagaMiddleware.run(watchGroupTypeInstantiationRequest);
    sagaMiddleware.run(watchGroupTypeCustomFieldChanges);
    sagaMiddleware.run(watchGroupCreationChanges);
    sagaMiddleware.run(watchGroupDeletionChanges);
    sagaMiddleware.run(watchGroupsDeletionChanges);
    sagaMiddleware.run(watchBulkGroupCreationChanges);
    sagaMiddleware.run(watchBulkGroupUpdateChanges);
    sagaMiddleware.run(watchGroupTypeActionCreationChanges);
    sagaMiddleware.run(watchGroupTypeActionDeletionChanges);
    sagaMiddleware.run(watchGroupUnarchiveChanges);
    sagaMiddleware.run(watchGroupUpdateChanges);
    sagaMiddleware.run(watchGroupCustomFieldUpdateChanges);
    sagaMiddleware.run(watchGroupMemberUpdateChanges);
    sagaMiddleware.run(watchGroupAddToMemberChanges);
    sagaMiddleware.run(watchGroupRemoveFromMemberChanges);
    sagaMiddleware.run(watchBulkGroupAddToMemberChanges);
    sagaMiddleware.run(watchBulkGroupRemoveFromMemberChanges);
    sagaMiddleware.run(watchGroupLocationUpdate);
    sagaMiddleware.run(watchGroupCustomFieldRecalculation);
    sagaMiddleware.run(watchBulkGroupCustomFieldRecalculation);
    sagaMiddleware.run(watchRecomputeAllGroups);
    sagaMiddleware.run(watchGroupPagePageChanges);
    sagaMiddleware.run(watchGroupQueryChangesForOnline);

    sagaMiddleware.run(watchWorkflowTypeCreation);
    sagaMiddleware.run(watchWorkflowTypeInstantiation);
    sagaMiddleware.run(watchStatusCreationChanges);
    sagaMiddleware.run(watchStatusDeletionChanges);
    sagaMiddleware.run(watchWorkflowTypeCustomFieldCreation);
    sagaMiddleware.run(watchWorkflowChanges);
    sagaMiddleware.run(watchWorkflowCreationChanges);
    sagaMiddleware.run(watchWorkflowExecutionChanges);
    sagaMiddleware.run(watchWorkflowExecutionEnd);
    sagaMiddleware.run(watchWorkflowDeletionChanges);
    sagaMiddleware.run(watchWorkflowsDeletionChanges);
    sagaMiddleware.run(watchWorkflowUnarchiveChanges);
    sagaMiddleware.run(watchWorkflowCustomFieldRecalculation);
    sagaMiddleware.run(watchBulkWorkflowCustomFieldRecalculation);
    sagaMiddleware.run(watchRecomputeAllWorkflows);
    sagaMiddleware.run(watchWorkflowPagePageChanges);
    sagaMiddleware.run(watchWorkflowQueryChangesForOnline);
    sagaMiddleware.run(watchWorkflowTypeFlowchartPublish);

    sagaMiddleware.run(watchIDUpdate);
    sagaMiddleware.run(watchPermissionUpdate);
    sagaMiddleware.run(watchChangeSuperUserPassword);
    sagaMiddleware.run(watchToastMessage);
    sagaMiddleware.run(watchInfoMessage);
    sagaMiddleware.run(watchErrorMessage);
    sagaMiddleware.run(watchProjectSetup);

    sagaMiddleware.run(watchPackageCreationChanges);
    sagaMiddleware.run(watchPackageDeletionChanges);

    sagaMiddleware.run(watchSaveFilesToIDB);
    sagaMiddleware.run(watchDeleteFilesInIDB);

    sagaMiddleware.run(watchSaveDataToSnapshotDuringSystemUpdate);

    sagaMiddleware.run(watchAddSyncLog);
    sagaMiddleware.run(watchFailedSyncLog);
}