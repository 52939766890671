import { GroupTypeState, SELECT_GROUP_TYPE, UN_SELECT_GROUP_TYPE, RE_ORDER_GROUP_TYPES, ADD_GROUP_TYPE, UPDATE_GROUP_TYPE, DELETE_GROUP_TYPE, ADD_GROUP_TO_GROUP_TYPE, REMOVE_GROUP_FROM_GROUP_TYPE, SELECT_GROUP_TYPE_CUSTOM_FIELD, UN_SELECT_GROUP_TYPE_CUSTOM_FIELD, ADD_GROUP_TYPE_CUSTOM_FIELD, DELETE_GROUP_TYPE_CUSTOM_FIELD, UPDATE_GROUP_TYPE_CUSTOM_FIELD, SELECT_GROUP_TYPE_CUSTOM_FIELD_OPTION, UN_SELECT_GROUP_TYPE_CUSTOM_FIELD_OPTION, RE_ORDER_GROUP_TYPE_CUSTOM_FIELD_OPTION, ADD_GROUP_TYPE_CUSTOM_FIELD_OPTION, DELETE_GROUP_TYPE_CUSTOM_FIELD_OPTION, UPDATE_GROUP_TYPE_CUSTOM_FIELD_OPTION, UPDATE_GROUP_TYPE_CUSTOM_FIELD_START_PIECE, SET_ISOLATED_GROUP_TYPE_CUSTOM_FIELD_PIECE, REMOVE_ISOLATED_GROUP_TYPE_CUSTOM_FIELD_PIECE, REGISTER_GROUP_TYPE_CUSTOM_FIELD_VARIABLE, UPDATE_GROUP_TYPE_MANAGEMENT, DISCARD_GROUP_TYPE_MANAGEMENT, ADD_GROUP_TYPE_MANAGEMENT_CUSTOM_FIELD_MAP, IGroupType, UPDATE_GROUP_TYPES_DATA, UPDATE_GROUP_TYPE_CUSTOM_FIELDS_DATA, UPDATE_GROUP_TYPE_CUSTOM_FIELD_OPTIONS_DATA, SYNCHRONIZE_GROUP_TYPES_DATA, SYNCHRONIZE_GROUP_TYPE_CUSTOM_FIELDS_DATA, SYNCHRONIZE_GROUP_TYPE_CUSTOM_FIELD_OPTIONS_DATA, CLEAR_GROUP_TYPES_DELTA, INSTANTIATE_GROUP_TYPE, INSTANTIATE_GROUP_TYPE_CUSTOM_FIELD, INSTANTIATE_GROUP_TYPE_CUSTOM_FIELD_OPTION, ADD_ACTION_TO_GROUP_TYPE, REMOVE_ACTION_FROM_GROUP_TYPE, BULK_ADD_GROUPS_TO_GROUP_TYPES, BULK_REMOVE_GROUPS_FROM_GROUP_TYPES } from './types';
import { GroupActionTypes } from '../types';
import { selectCustomField, unSelectCustomField, addCustomField, deleteCustomField, updateCustomField, selectCustomFieldOption, unSelectCustomFieldOption, reOrderCustomFieldOptions, addCustomFieldOption, deleteCustomFieldOption, updateCustomFieldOption, setIsolatedPieceForCustomField, removeIsolatedPieceForCustomField, updateStartPieceForCustomField, registerVariableForCustomField, updateCustomFields, updateCustomFieldOptions, synchronizeCustomFields, synchronizeCustomFieldOptions, clearDeltaForCustomFields } from '../../custom-fields';
import { mergeReverseLinkArrays, reOrderList } from '../../../helpers/utilities';
import { addEntity, deleteEntity, updateEntity, updateEntries, synchronizeEntries, clearDelta, synchronizeCustomFieldReverseLinks, synchronizeCustomFieldOptionReverseLinks } from '../../normalized-model';
import { actionsReducer, initialState as groupTypeActionsInitialState } from './actions/reducer';
import { ADD_GROUP_TYPE_ACTION, DELETE_GROUP_TYPE_ACTION, INSTANTIATE_GROUP_TYPE_ACTION, RE_ORDER_GROUP_TYPE_ACTIONS, SYNCHRONIZE_GROUP_TYPE_ACTIONS_DATA } from './actions/types';

export const initialState: GroupTypeState = {
    byId: {},
    allEntries: [],
    filteredEntries: [],
    selected: undefined,

    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),

    actions: groupTypeActionsInitialState,

    customFields: {
        byId: {},
        allFields: [],
    },
    customFieldOptions: {
        byId: {},
        allOptions: [],
    },
    selectedField: undefined,
    selectedOption: undefined,
    createdCustomFieldIds: new Set(),
    updatedCustomFieldIds: new Set(),
    deletedCustomFieldIds: new Set(),
    createdCustomFieldOptionIds: new Set(),
    updatedCustomFieldOptionIds: new Set(),
    deletedCustomFieldOptionIds: new Set(),

    reOrderedActions: {},
    reOrderedCustomFields: {},
    reOrderedCustomFieldOptions: {},

    areGroupTypesReordered: false,
};

export function groupTypesReducer(state = initialState, action: GroupActionTypes): GroupTypeState {
    state = {
        ...state,
        actions: actionsReducer(state.actions, action),
    };

    let newState: GroupTypeState;
    let groupTypeManagement: undefined | {
        workflowTypeId: string,
        lastQuestionPiece: string,
        customFieldMap: {
            [groupTypeCustomFieldId: string]: string,  // ID of the custom field in the managed workflow
        },
    };

    switch (action.type) {

        // Group type actions

        case SELECT_GROUP_TYPE:
            return {
                ...state,
                selected: action.id,
            }

        case UN_SELECT_GROUP_TYPE:
            return {
                ...state,
                selected: undefined,
            }

        case RE_ORDER_GROUP_TYPES:
            return {
                ...state,
                allEntries: reOrderList(state.allEntries, action.sourceIndex, action.destinationIndex),
                areGroupTypesReordered: true,
            };

        case ADD_GROUP_TYPE:
            return addEntity<GroupTypeState, IGroupType>(state, action.payload);

        case INSTANTIATE_GROUP_TYPE:
            return addEntity<GroupTypeState, IGroupType>(state, action.payload);

        case DELETE_GROUP_TYPE:
            return deleteEntity<GroupTypeState, IGroupType>(state, action.id, action.currentTime);

        case UPDATE_GROUP_TYPE:
            return updateEntity<GroupTypeState, IGroupType>(state, action.payload, action.currentTime);

        case ADD_ACTION_TO_GROUP_TYPE:
            const newActions = state.byId[action.groupTypeId].actions ? state.byId[action.groupTypeId].actions.concat(action.actionId) : [action.actionId];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.groupTypeId]: {
                        ...state.byId[action.groupTypeId],
                        actions: newActions,
                    },
                },
            }

        case REMOVE_ACTION_FROM_GROUP_TYPE:
            const remainingActions = state.byId[action.groupTypeId].actions ? state.byId[action.groupTypeId].actions.filter(groupId => groupId !== action.actionId) : [];

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.groupTypeId]: {
                        ...state.byId[action.groupTypeId],
                        actions: remainingActions,
                    },
                },
            }

        case ADD_GROUP_TO_GROUP_TYPE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.groupTypeId]: {
                        ...state.byId[action.groupTypeId],
                        groups: state.byId[action.groupTypeId].groups.concat(action.groupId),
                    },
                },
            }

        case REMOVE_GROUP_FROM_GROUP_TYPE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.groupTypeId]: {
                        ...state.byId[action.groupTypeId],
                        groups: state.byId[action.groupTypeId].groups.filter(groupId => groupId !== action.groupId),
                    },
                },
            }

        case BULK_ADD_GROUPS_TO_GROUP_TYPES:
            state = {
                ...state,
                byId: {
                    ...state.byId,
                }
            };

            for (const linkData of action.links) {
                state.byId[linkData.groupTypeId] = {
                    ...state.byId[linkData.groupTypeId],
                    groups: state.byId[linkData.groupTypeId].groups.concat(linkData.groupId),
                }
            }

            return state;

        case BULK_REMOVE_GROUPS_FROM_GROUP_TYPES:
            state = {
                ...state,
                byId: {
                    ...state.byId,
                }
            };

            for (const linkData of action.links) {
                state.byId[linkData.groupTypeId] = {
                    ...state.byId[linkData.groupTypeId],
                    groups: state.byId[linkData.groupTypeId].groups.filter(groupId => groupId !== linkData.groupId),
                }
            }

            return state;

        case SELECT_GROUP_TYPE_CUSTOM_FIELD:
            return selectCustomField<GroupTypeState>(state, action.id);

        case UN_SELECT_GROUP_TYPE_CUSTOM_FIELD:
            return unSelectCustomField<GroupTypeState>(state);

        case ADD_GROUP_TYPE_CUSTOM_FIELD:
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                    [action.groupTypeId]: {
                        ...state.byId[action.groupTypeId],
                        customFields: state.byId[action.groupTypeId].customFields.concat([action.payload.id]),
                    }
                },
                reOrderedCustomFields: {
                    ...state.reOrderedCustomFields,
                    [action.groupTypeId]: state.byId[action.groupTypeId].customFields.concat([action.payload.id]),
                },
            }
            return addCustomField<GroupTypeState>(newState, action.payload, action.groupTypeId, action.currentTime);


        case INSTANTIATE_GROUP_TYPE_CUSTOM_FIELD:
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                    [action.groupTypeId]: {
                        ...state.byId[action.groupTypeId],
                        customFields: state.byId[action.groupTypeId].customFields.concat([action.payload.id]),
                    }
                },
                reOrderedCustomFields: {
                    ...state.reOrderedCustomFields,
                    [action.groupTypeId]: state.byId[action.groupTypeId].customFields.concat([action.payload.id]),
                },
            }
            return addCustomField<GroupTypeState>(newState, action.payload, action.groupTypeId, action.currentTime);

        case DELETE_GROUP_TYPE_CUSTOM_FIELD:
            newState = {
                ...state,
                byId: {
                    ...state.byId,
                    [action.groupTypeId]: {
                        ...state.byId[action.groupTypeId],
                        customFields: state.byId[action.groupTypeId].customFields.filter(customFieldId => customFieldId !== action.id),
                    }
                },
                reOrderedCustomFields: {
                    ...state.reOrderedCustomFields,
                    [action.groupTypeId]: state.byId[action.groupTypeId].customFields.filter(customFieldId => customFieldId !== action.id),
                },
            }
            return deleteCustomField<GroupTypeState>(newState, action.id, action.currentTime);

        case UPDATE_GROUP_TYPE_CUSTOM_FIELD:
            return updateCustomField<GroupTypeState>(state, action.payload, action.currentTime);


        case UPDATE_GROUP_TYPE_CUSTOM_FIELD_START_PIECE:
            return updateStartPieceForCustomField<GroupTypeState>(state, action.customFieldId, action.payload, action.currentTime);

        case SET_ISOLATED_GROUP_TYPE_CUSTOM_FIELD_PIECE:
            return setIsolatedPieceForCustomField<GroupTypeState>(state, action.customFieldId, action.payload, action.currentTime);

        case REMOVE_ISOLATED_GROUP_TYPE_CUSTOM_FIELD_PIECE:
            return removeIsolatedPieceForCustomField<GroupTypeState>(state, action.customFieldId, action.pieceId, action.currentTime);

        case REGISTER_GROUP_TYPE_CUSTOM_FIELD_VARIABLE:
            return registerVariableForCustomField<GroupTypeState>(state, action.customFieldId, action.variableId, action.currentTime);


        case SELECT_GROUP_TYPE_CUSTOM_FIELD_OPTION:
            return selectCustomFieldOption<GroupTypeState>(state, action.id);

        case UN_SELECT_GROUP_TYPE_CUSTOM_FIELD_OPTION:
            return unSelectCustomFieldOption<GroupTypeState>(state);

        case RE_ORDER_GROUP_TYPE_CUSTOM_FIELD_OPTION:
            return reOrderCustomFieldOptions<GroupTypeState>(state, action.sourceIndex, action.destinationIndex, action.parentId);

        case ADD_GROUP_TYPE_CUSTOM_FIELD_OPTION:
            return addCustomFieldOption<GroupTypeState>(state, action.payload, action.parentId, action.currentTime);

        case INSTANTIATE_GROUP_TYPE_CUSTOM_FIELD_OPTION:
            return addCustomFieldOption<GroupTypeState>(state, action.payload, action.parentId, action.currentTime);

        case DELETE_GROUP_TYPE_CUSTOM_FIELD_OPTION:
            return deleteCustomFieldOption<GroupTypeState>(state, action.id, action.parentId, action.currentTime);

        case UPDATE_GROUP_TYPE_CUSTOM_FIELD_OPTION:
            return updateCustomFieldOption<GroupTypeState>(state, action.payload, action.currentTime);

        case ADD_GROUP_TYPE_MANAGEMENT_CUSTOM_FIELD_MAP:
            groupTypeManagement = state.byId[action.groupTypeId].management;

            if (typeof groupTypeManagement === 'undefined') {
                return state;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.groupTypeId]: {
                        ...state.byId[action.groupTypeId],
                        management: {
                            ...groupTypeManagement,
                            customFieldMap: {
                                ...groupTypeManagement.customFieldMap,
                                [action.groupTypeCustomFieldId]: action.workflowTypeCustomFieldId,
                            }
                        },
                    },
                },
            }

        case UPDATE_GROUP_TYPE_MANAGEMENT:
            groupTypeManagement = state.byId[action.groupTypeId].management;

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.groupTypeId]: {
                        ...state.byId[action.groupTypeId],
                        management: {
                            ...groupTypeManagement,
                            workflowTypeId: action.workflowTypeId,
                            lastQuestionPiece: action.lastQuestionPieceId,
                            customFieldMap: {
                                ...(groupTypeManagement ? groupTypeManagement.customFieldMap : {})
                            }
                        },
                    },
                },
            }

        case DISCARD_GROUP_TYPE_MANAGEMENT:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.groupTypeId]: {
                        ...state.byId[action.groupTypeId],
                        management: undefined,
                    },
                },
            }

        case UPDATE_GROUP_TYPES_DATA:
            return updateEntries<GroupTypeState, IGroupType>(state, action.data);

        case UPDATE_GROUP_TYPE_CUSTOM_FIELDS_DATA:
            return updateCustomFields<GroupTypeState>(state, action.data);

        case UPDATE_GROUP_TYPE_CUSTOM_FIELD_OPTIONS_DATA:
            return updateCustomFieldOptions<GroupTypeState>(state, action.data);

        case SYNCHRONIZE_GROUP_TYPES_DATA:
            state = synchronizeEntries<GroupTypeState, IGroupType>(state, action.data);
            if (action.reOrder.length > 0) {
                state.allEntries = mergeReverseLinkArrays(action.reOrder, state.allEntries);
            }
            return state;

        case SYNCHRONIZE_GROUP_TYPE_CUSTOM_FIELDS_DATA:
            newState = synchronizeCustomFields<GroupTypeState>(state, action.data);
            newState = synchronizeCustomFieldReverseLinks<GroupTypeState>(newState, action.data);
            return newState;

        case SYNCHRONIZE_GROUP_TYPE_CUSTOM_FIELD_OPTIONS_DATA:
            newState = synchronizeCustomFieldOptions<GroupTypeState>(state, action.data);
            newState = synchronizeCustomFieldOptionReverseLinks<GroupTypeState>(newState, action.reOrderedCustomFieldOptions);
            return newState;

        case CLEAR_GROUP_TYPES_DELTA:
            newState = clearDelta<GroupTypeState, IGroupType>(state);
            newState.areGroupTypesReordered = false;
            newState.reOrderedActions = {};
            newState.reOrderedCustomFields = {};
            newState = clearDeltaForCustomFields<GroupTypeState>(newState);
            return newState;


        // Action ACTIONS

        case ADD_GROUP_TYPE_ACTION:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.parentId]: {
                        ...state.byId[action.parentId],
                        actions: state.byId[action.parentId].actions.concat(action.payload.id),
                    }
                },
                reOrderedActions: {
                    ...state.reOrderedActions,
                    [action.parentId]: state.byId[action.parentId].actions.concat(action.payload.id),
                }
            }

        case INSTANTIATE_GROUP_TYPE_ACTION:
            return {
                ...state,
                reOrderedActions: {
                    ...state.reOrderedActions,
                    [action.parentId]: state.byId[action.parentId].actions.concat(action.payload.id),
                },
            }

        case DELETE_GROUP_TYPE_ACTION:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.parentId]: {
                        ...state.byId[action.parentId],
                        actions: state.byId[action.parentId].actions.filter(actionId => actionId === action.id),
                    }
                },
                reOrderedActions: {
                    ...state.reOrderedActions,
                    [action.parentId]: state.byId[action.parentId].actions.filter(actionId => actionId === action.id),
                }
            }

        case RE_ORDER_GROUP_TYPE_ACTIONS:
            const reOrderedList = reOrderList(state.byId[action.parentId].actions, action.sourceIndex, action.destinationIndex);

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.parentId]: {
                        ...state.byId[action.parentId],
                        actions: reOrderedList,
                    }
                },
                reOrderedActions: {
                    ...state.reOrderedActions,
                    [action.parentId]: reOrderedList,
                }
            }

        default:
            return state;
    }
}