import React, { FC } from 'react';
import styles from './ConstantPiece.module.scss';
import leftArrow from '../../../assets/flowchart/triangle-left.svg';
import rightArrow from '../../../assets/flowchart/triangle-right.svg';
import FlowchartPiece, { OwnProps as FlowchartPieceProps } from './FlowchartPiece';
import { FlowchartContext, PieceHighlightColour } from '../../../contexts/flowchart-context';


export type ConstantPieceProps = {
    constantName: string,
    isBoolean?: boolean,
}

type Props = ConstantPieceProps & FlowchartPieceProps;

const ConstantPiece: FC<Props> = (props) => {

    return <FlowchartContext.Consumer>
        {
            (flowchartContext) => {
                const highlightColor = flowchartContext.highlights && flowchartContext.highlights[props.pieceId];
                let highlightClass = styles.noHighlight;

                switch (highlightColor) {
                    case PieceHighlightColour.GREEN:
                        highlightClass = styles.addedHighlight;
                        break;
                    case PieceHighlightColour.YELLOW:
                        highlightClass = styles.updatedHighlight;
                        break;
                    case PieceHighlightColour.PURPLE:
                        highlightClass = styles.movedHighlight;
                        break;
                    case PieceHighlightColour.RED:
                        highlightClass = styles.deletedHighlight;
                        break;
                }

                return (
                    <FlowchartPiece {...props}>
                        <div className={highlightClass}>
                            <div className={props.isBoolean ? styles.booleanConstantPiece : styles.constantPiece}>
                                <div className={styles.text}>{props.constantName}</div>

                                {props.isBoolean && <div className={styles.booleanLeftPiece}><img className={styles.booleanPieceImage} alt="Left piece of boolean operator" src={leftArrow} /></div>}
                                {props.isBoolean && <div className={styles.booleanRightPiece}><img className={styles.booleanPieceImage} alt="Right piece of boolean operator" src={rightArrow} /></div>}
                            </div>
                        </div>
                    </FlowchartPiece>
                )
            }
        }
    </FlowchartContext.Consumer>
}

export default ConstantPiece;