import React from 'react';
import styles from './TextDecorator.module.scss'
import { decorateText } from '../../../shared/helpers/text-utilities';

interface OwnProps {
    text: string,
    ruleType?: 'bold' | 'italic';
};

const TextDecorator: React.FC<OwnProps> = (props: OwnProps) => {
    const boldTexts = decorateText(props.text, 'bold');
    const italicTexts = decorateText(props.text, 'italic');

    const boldTextElements = boldTexts.map((part, index) => {
        return <span key={index} className={part.isBold ? styles.boldText : ''}>{part.text}</span>
    });

    const italicTextElements = italicTexts.map((part, index) => {
        return <span key={index} className={part.isBold ? styles.boldText : ''}>{part.text}</span>
    });

    switch (props.ruleType) {
        case 'bold':
            return <span>{boldTextElements}</span>
        case 'italic':
            return <span>{italicTextElements}</span>
        default:
            return <span>{boldTextElements}</span>
    }
};

export default TextDecorator;
