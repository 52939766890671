import React, { ChangeEvent, Component } from 'react';
import styles from './Configuration.module.scss';
import { Redirect } from "react-router-dom";

import { Permissions } from '../../../shared/store/permissions/types';

import { translatePhrase } from '../../../shared/helpers/translation';

import { selectStaticDataHolder, unSelectStaticDataHolder } from '../../../shared/store/static-info/actions';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import StaticDataHoldersList from './StaticDataHoldersList';

import { ReactComponent as ExportIcon } from '../../../assets/new-custom-icons/common/export.svg';
import { ReactComponent as ImportIcon } from '../../../assets/new-custom-icons/common/import.svg';

import { ApplicationState } from '../../../shared/store/types';
import { setToastMessage } from '../../../shared/store/my-data/actions';
import { addDataFragment, selectDataFragment, unSelectDataFragment } from '../../../shared/store/static-info/data-fragment/actions';
import DataFragmentsList from './DataFragmentsList';
import { isUUID } from '../../../shared/helpers/utilities';
import Papa from 'papaparse';
import { DataFragmentState, IDataFragment, IUpdateableDataFragmentData } from '../../../shared/store/static-info/data-fragment/types';
import store from '../../../shared/store/main';
import LoaderModal from "../../../widgets/loader/LoaderModal";
import uuid from "uuid";
import { duplicateStaticDataHolder } from '../../../shared/helpers/duplicate';
import { saveAs } from 'file-saver';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canEditConfiguration = state.permissions.myPermissions.general.StaticInfoConfiguration === Permissions.WRITE;
    const canViewConfiguration = canEditConfiguration || state.permissions.myPermissions.general.StaticInfoConfiguration === Permissions.READ;
    const selectedStaticDataHolderId = state.staticInfo.selected;

    return {
        selectedStaticDataHolder: selectedStaticDataHolderId ? state.staticInfo.byId[selectedStaticDataHolderId] : undefined,
        isReadable: canViewConfiguration,
        isWritable: canEditConfiguration,
        staticDataHoldersData: state.staticInfo,
        selectedDataFragments: state.staticInfo.fragments.selected,
        isSuperUser: !isUUID(state.myData.id),
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        selectStaticDataHolder: (id: string) => dispatch(selectStaticDataHolder(id)),
        unSelectStaticDataHolder: () => dispatch(unSelectStaticDataHolder()),

        selectDataFragment: (id: string, index: number) => dispatch(selectDataFragment(id, index)),
        unSelectDataFragment: (index: number) => dispatch(unSelectDataFragment(index)),
        addDataFragment: (newDataFragmentData: IUpdateableDataFragmentData, parentId: string) => dispatch(addDataFragment(newDataFragmentData, parentId)),

        setToastMessage: (message: string) => dispatch(setToastMessage(message)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
    isShowingAddForm: boolean,
    modifyingVerticalName: string,
    modifyingVerticalValue: string,

    exportData: Array<Array<string>>,
    erroneousData: Array<Array<string>>,
    loader: JSX.Element | undefined,
}

class ConnectedLocations extends Component<Props, OwnState> {

    state: OwnState = {
        isShowingAddForm: false,
        modifyingVerticalName: '',
        modifyingVerticalValue: '',
        loader: undefined,

        exportData: [],
        erroneousData: [],
    };

    onSelectDataFragment = (dataFragmentIndex: number, id: string) => {
        this.props.selectDataFragment(id, dataFragmentIndex - 1);
    }

    onUnSelectDataFragment = (dataFragmentIndex: number) => {
        this.props.unSelectDataFragment(dataFragmentIndex - 1);
    }

    importRow = (fragmentData: Array<string>) => {
        const selectedStaticDataHolder = this.props.selectedStaticDataHolder;

        if (!selectedStaticDataHolder) {
            this.showModal('There must be a project selected')
            throw new Error('There must be a project selected');
        }

        let parentDataFragment: IDataFragment | undefined;
        let fragmentStateData = store.getState().staticInfo.fragments;

        // Given a list of sibling IDs and a location, return the data of the location which has the given name within that list of siblings
        function getFragmentFromName(siblingFragmentIds: Array<string>, fragmentName: string, fragmentStateData: DataFragmentState) {
            const currentFragmentId = siblingFragmentIds.find(fragmentId => fragmentId in fragmentStateData.byId && fragmentStateData.byId[fragmentId].name === fragmentName);

            return typeof currentFragmentId !== 'undefined' ? fragmentStateData.byId[currentFragmentId] : undefined;
        }

        for (const fragmentName of fragmentData) {

            let currentDataFragment: IDataFragment | undefined;

            if (typeof parentDataFragment === 'undefined') {
                currentDataFragment = getFragmentFromName(fragmentStateData.byStaticDataHolder[selectedStaticDataHolder.id], fragmentName, fragmentStateData);
            } else {
                currentDataFragment = getFragmentFromName(parentDataFragment.children, fragmentName, fragmentStateData);
            }

            // If the current data fragment does not exist, prepare the new fragment data.
            if (typeof currentDataFragment === 'undefined') {
                const newFragmentId = uuid.v4();
                const newFragmentData: IUpdateableDataFragmentData = {
                    id: newFragmentId,
                    name: fragmentName,
                };

                const parentId = typeof parentDataFragment === 'undefined' ? selectedStaticDataHolder.id : parentDataFragment.id;

                this.props.addDataFragment(newFragmentData, parentId);
                fragmentStateData = store.getState().staticInfo.fragments;
                currentDataFragment = fragmentStateData.byId[newFragmentId];
            }

            parentDataFragment = currentDataFragment;
        }

    }

    importStaticData = (importData: Array<Array<string>>) => {

        const erroneousData: Array<Array<string>> = []

        if (!this.props.selectedStaticDataHolder) {
            throw new Error('There must be a static data selected');
        }

        const errorFileHeading = this.getImportFileHeader().concat(['Errors']);
        const noOfColumns = errorFileHeading.length - 1;
        erroneousData.push(errorFileHeading);

        const dataWithoutHeader = importData.slice(1);

        for (const rowData of dataWithoutHeader) {

            let lastColumnWithData = 0;

            for (let i = 0; i < rowData.length; i += 1) {
                const fragmentName = rowData[i];

                if (fragmentName) {
                    lastColumnWithData = i;
                }
            }

            const filteredRowData = rowData.slice(0, lastColumnWithData + 1);

            if (filteredRowData.length > 1 || (filteredRowData.length === 1 && filteredRowData[0].trim() !== '')) {
                try {
                    this.importRow(filteredRowData);
                } catch (e) {
                    if (e instanceof Error) {
                        const erroneousRow = Array(noOfColumns).fill('').map((entry, index) => {
                            if (index < filteredRowData.length) {
                                return filteredRowData[index];
                            } else {
                                return '';
                            }
                        });
                        erroneousRow.push(e.message);
                        erroneousData.push(erroneousRow);
                    }
                }
            }
        }

        this.setState({
            erroneousData,
        });

    }

    handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        const file = !!e.target.files ? e.target.files[0] : undefined;

        this.showModal(`${translatePhrase('Importing your data')}...`)

        if (!!file) {
            Papa.parse(file, {
                complete: (results) => {
                    const csvData = results.data as Array<Array<string>>;
                    this.importStaticData(csvData);

                    this.showModal(translatePhrase('Import complete'), true);
                }
            });
        }
    }

    showModal = (text: string, isSuccess?: boolean, isIndeterminate?: boolean) => {

        if (isIndeterminate) {
            this.setState({
                loader: <LoaderModal isIndeterminate loaderText={[text]} />
            });
            return;
        }

        this.setState({
            loader: <LoaderModal isError={!isSuccess} loaderText={[text]} isSuccess={isSuccess} />
        });

        setTimeout(() => {
            this.setState({ loader: undefined });
        }, 3000);
    }

    getImportFileHeader = () => {
        if (!this.props.selectedStaticDataHolder) {
            this.showModal("This static date holder muct be selected")
            throw new Error('The static data holder must be selected')
        }

        if (this.props.selectedStaticDataHolder.children.length === 0) {
            return ['Data level 1', 'Data level 2', '...', 'Enter as many data levels as you would like'];
        }

        let longestLine: number = 0;

        for (const fragmentId of this.props.staticDataHoldersData.fragments.allEntries) {
            const fragment = this.props.staticDataHoldersData.fragments.byId[fragmentId];

            if (fragment.children.length === 0) {
                const ancestorIds = this.getAncestorChainOfFragment(fragment.id).reverse();
                ancestorIds.push(fragment.id);

                const isAncestorArchived = ancestorIds.some(ancestorId => this.props.staticDataHoldersData.fragments.byId[ancestorId].archived);
                const isFragmentInStaticData = this.props.selectedStaticDataHolder.children.includes(ancestorIds[0]);

                if (!isAncestorArchived && isFragmentInStaticData) {
                    longestLine = ancestorIds.length;
                }
            }
        }

        const headings: Array<string> = [];

        for (let i = 1; i <= longestLine; i += 1) {
            headings.push(`Data level ${i}`);
        }

        return headings;
    }

    dismissErrorMessage = () => {
        this.setState({
            erroneousData: [],
        });
    }

    getAncestorChainOfFragment = (fragmentId: string) => {
        const fragmentState = this.props.staticDataHoldersData.fragments;

        const ancestorFragments: Array<string> = [];
        let upperFragment: IDataFragment = fragmentState.byId[fragmentId];
        let parentId = upperFragment.parent || '';

        while (parentId in fragmentState.byId) {
            ancestorFragments.push(parentId);
            upperFragment = fragmentState.byId[parentId];
            parentId = upperFragment.parent || '';
        }

        return ancestorFragments;
    }

    getStaticInfoData = () => {
        const staticInfoData: Array<Array<string>> = [];

        if (!this.props.selectedStaticDataHolder) {
            return staticInfoData;
        }

        for (const fragmentId of this.props.staticDataHoldersData.fragments.allEntries) {
            const fragment = this.props.staticDataHoldersData.fragments.byId[fragmentId];

            if (fragment.children.length === 0) {
                const fragmentRow: Array<string> = []
                const ancestorIds = this.getAncestorChainOfFragment(fragment.id).reverse();
                ancestorIds.push(fragment.id);

                const isAncestorArchived = ancestorIds.some(ancestorId => this.props.staticDataHoldersData.fragments.byId[ancestorId].archived);
                const isFragmentInStaticData = this.props.selectedStaticDataHolder.children.includes(ancestorIds[0]);

                if (isFragmentInStaticData && !isAncestorArchived) {

                    for (const ancestorId of ancestorIds) {
                        const ancestorFragment = this.props.staticDataHoldersData.fragments.byId[ancestorId];
                        const fragmentName = ancestorFragment.name ? ancestorFragment.name : '-';
                        fragmentRow.push(fragmentName);
                    }

                    staticInfoData.push(fragmentRow);

                }
            }
        };

        return staticInfoData;
    }

    getStaticDataExportData = () => {
        if (!this.props.selectedStaticDataHolder) {
            return;
        }

        this.showModal(translatePhrase('Preparing export') + '...', false, true);

        const headers = this.getImportFileHeader();
        const data = this.getStaticInfoData();

        const exportData = [headers, ...data];

        const csvString = Papa.unparse(exportData);
        const fileBlob = new Blob([csvString], { type: 'text/csv' });

        saveAs(fileBlob, `${this.props.selectedStaticDataHolder.name} Export.csv`);

        this.showModal(translatePhrase('Export ready'), true);
    }

    onDuplicate = () => {
        return this.props.selectedStaticDataHolder &&
            duplicateStaticDataHolder(
                this.props.selectedStaticDataHolder
                    ? this.props.selectedStaticDataHolder.id
                    : ""
            );
    }

    onExport = () => {
        return this.getStaticDataExportData();
    }

    onImport = () => {
        return this.handleFileUpload;
    }

    render() {
        if (!this.props.isReadable) {
            return <Redirect to="/dashboard" />;
        }

        let dataFragmentsMarkup: Array<JSX.Element> = [];

        if (this.props.selectedStaticDataHolder) {

            const parentIds = [this.props.selectedStaticDataHolder.id].concat(this.props.selectedDataFragments);

            dataFragmentsMarkup = parentIds.map((parentId, index) => {
                return <DataFragmentsList
                    key={parentId}
                    heading={'Data Level ' + (index + 1)}
                    parentId={parentId}
                    isReadOnly={!this.props.isWritable}
                    onSelectCard={this.onSelectDataFragment.bind(this, index)}
                    onUnSelectCard={this.onUnSelectDataFragment.bind(this, index)}
                    selectedId={this.props.selectedDataFragments[index]}
                />;
            });

        }


        return (<div className={styles.innerFocusContainer}>
            {this.state.loader}
            <div className={styles.importExportButtonHolder + ' ignore-top-level-onclickoutside ignore-react-onclickoutside'}>

                <input type="file" accept=".csv, text/csv" id="import-file-input" className={styles.hiddenFile} onChange={this.handleFileUpload} />
                {this.props.isSuperUser && this.props.selectedStaticDataHolder && <label htmlFor="import-file-input" > <ImportIcon /> {translatePhrase('Import')} </label>}

                {this.props.selectedStaticDataHolder && <button onClick={this.getStaticDataExportData}> <ExportIcon /> {translatePhrase(this.props.selectedStaticDataHolder.children.length === 0 ? 'Download template' : 'Export')} </button>}
            </div>

            <div className={styles.staticInfoHolder}>
                <div className={styles.allStaticDataHolders}>
                    <StaticDataHoldersList
                        isSearchable
                        showCardCount
                        heading="Static data"
                        onSelectCard={this.props.selectStaticDataHolder}
                        onUnSelectCard={this.props.unSelectStaticDataHolder}
                        selectedId={this.props.selectedStaticDataHolder ? this.props.selectedStaticDataHolder.id : ''}
                        isReadOnly={!this.props.isWritable}
                        onDuplicate={this.onDuplicate}
                        onExport={this.onExport}
                        onImport={this.onImport} />
                </div>
                {this.props.selectedStaticDataHolder &&
                    <div className={styles.cardsTree + ' ignore-react-onclickoutside'}>
                        {dataFragmentsMarkup}
                    </div>}
            </div>
        </div>);

    }
}

const Locations = connect(mapStateToProps, mapDispatchToProps)(ConnectedLocations);

export default Locations;