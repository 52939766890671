import React, { Component, createRef } from 'react';
import axios from 'axios';
import styles from './Process.module.scss';
import { RouteComponentProps } from 'react-router';
import { Redirect, Link } from "react-router-dom";

import { selectWorkflowType } from '../../../shared/store/workflows/types/actions';
import { clearErrorMessage, clearInfoMessage, setErrorMessage, setInfoMessage, setToastMessage } from '../../../shared/store/my-data/actions';
import { updateStatus, updateDueDate, updateProcessState, addToHistory, addWorkflow, updateWorkflow, navigateBack, navigateForward, addToScreenInputs, updateWorkflowCustomFieldData } from '../../../shared/store/workflows/actions';
import { WorkflowProcessState, IUpdateableWorkflowData, IWorkflowScreenInput, IWorkflow, WorkflowProcessStep } from '../../../shared/store/workflows/types';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router";

import { ApplicationState } from '../../../shared/store/types';
import { startOrResumeWorkflow, getWorkflowPieceValue } from '../../../shared/store/flowchart/helpers/workflow';
import { getWorkflowQuestionValidation, getWorkflowQuestionValidationValue } from '../../../shared/store/flowchart/helpers/question';

import Question from './Question';
import Choose from './Choose';
import Group from './Group';
import WorkflowData from './WorkflowData';
import { PieceType } from '../../../shared/store/flowchart/pieces/types';
import Transfer from './Transfer';
import Continue from './Continue';
import Button from '../../../widgets/button/CommonButton';
import { getReadableDataForCustomField } from '../../../shared/store/custom-fields';
import { VariableType } from '../../../shared/store/flowchart/variables/types';
import { isUUID } from '../../../shared/helpers/utilities';
import { IUpdateableGroupData } from '../../../shared/store/groups/types';
import { updateLocationCustomFieldData } from '../../../shared/store/structure/location/actions';
import { updateUserCustomFieldData } from '../../../shared/store/users/actions';
import { updateMemberCustomFieldData, addMember, updateMembersLocationRequest } from '../../../shared/store/members/actions';
import { updateGroupCustomFieldData, addGroup, setMembersForGroupRequest, updateGroupsLocationRequest } from '../../../shared/store/groups/actions';
import { IUpdateableMemberData } from '../../../shared/store/members/types';
import { getPieceValueType } from '../../../shared/store/flowchart/helpers';
import Show from './Show';
import { translatePhrase } from '../../../shared/helpers/translation';
import { dataURLtoFile } from '../../../shared/helpers/utilities';
import uuid from 'uuid';
import { getAllPiecesInPiece } from '../../../shared/store/flowchart/helpers/pieces';
import Timeline from './Timeline';
import ProgressBar from './ProgressBar';
import moment from 'moment';
import Tabs from '../../../widgets/tabs/Tabs';
import { Permissions } from '../../../shared/store/permissions/types';
import { ReactComponent as ExitIcon } from '../../../common/assets/pause.svg';
import { ReactComponent as BetaIcon } from '../../../common/assets/beta.svg';
import LoaderModal from '../../../widgets/loader/LoaderModal';
import { VariableValueType } from '../../../shared/helpers/common-types';
import { CustomFieldDataHolder, WorkflowTypeCustomFieldDataHolder, CustomFieldValueType, WorkflowTypeCustomField, FieldType } from '../../../shared/store/custom-fields/types';
import { BASE_URL } from '../../../shared/store/url';
import { ReactComponent as ReadIcon } from '../../../common/assets/search-book.svg';
import { ReactComponent as StructureIcon } from '../../../assets/navigation/structure.svg';
import { checkPaymentStatus, makeLoanProcessCall } from '../../../shared/helpers/finsal/loan-process';
import { storeCurrentSnapshot } from '../../../shared/store/database';
import store from '../../../shared/store/main';
import { getStoredFileInDB } from '../../../shared/store/file-operations';
import { getFileNameFromUrl } from '../../../shared/helpers/file-utilities';

type OwnProps = {};

var submitButton: JSX.Element | undefined = undefined;

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps & RouteComponentProps<{ id: string }>) => {

    let workflow: IWorkflow | undefined;
    let workflowProcessState: WorkflowProcessStep | undefined;
    let isBetaTester = false;

    if (ownProps.match) {
        const workflowId = ownProps.match.params.id;
        workflow = state.workflows.byId[workflowId];
        if (workflow) {
            workflowProcessState = workflow.history[workflow.historyIndex];
            const user = state.users.byId[workflow.user];

            if (user) {
                isBetaTester = user.isBetaTester;
            }
        }
    }

    const canEditConfiguration = state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.WRITE;
    const canViewConfiguration = canEditConfiguration || state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.READ;

    return {
        isReadable: true,
        isWritable: true,
        applicationState: state,
        myId: state.myData.id,
        workflow,
        workflowProcessState,
        workflowData: state.workflows,
        membersData: state.members,
        groupsData: state.groups,
        piecesData: state.flowchart.pieces,
        variablesData: state.flowchart.variables,
        isLoaded: state.myData.isLoaded,
        message: state.myData.errorMessage,
        canViewConfiguration,
        isBetaTester,
        orgCode: state.organization.code,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        selectWorkflowType: (id: string) => dispatch(selectWorkflowType(id)),
        updateStatus: (workflowId: string, statusId: string) => dispatch(updateStatus(workflowId, statusId)),
        updateDueDate: (workflowId: string, dueDate: string) => dispatch(updateDueDate(workflowId, dueDate)),
        addToHistory: (processState: WorkflowProcessState, workflowId: string, userId: string) => dispatch(addToHistory(processState, workflowId, userId)),
        addToScreenInputs: (workflowId: string, screenInputs: IWorkflowScreenInput) => dispatch(addToScreenInputs(workflowId, screenInputs)),
        navigateForward: (workflowId: string) => dispatch(navigateForward(workflowId)),
        navigateBack: (workflowId: string) => dispatch(navigateBack(workflowId)),
        addMember: (memberData: IUpdateableMemberData) => dispatch(addMember(memberData)),
        addGroup: (groupData: IUpdateableGroupData) => dispatch(addGroup(groupData)),
        addWorkflow: (workflowData: IUpdateableWorkflowData) => dispatch(addWorkflow(workflowData)),

        setMembersForGroup: (groupId: string, memberTypes: 'representatives' | 'all_members', memberIds: Array<string>) => dispatch(setMembersForGroupRequest(groupId, memberTypes, memberIds)),

        updateMembersLocation: (memberIds: Array<string>, locationId: string) => dispatch(updateMembersLocationRequest(memberIds, locationId)),
        updateGroupsLocation: (groupIds: Array<string>, locationId: string) => dispatch(updateGroupsLocationRequest(groupIds, locationId)),

        updateLocationCustomFieldData: (locationId: string, customFieldData: CustomFieldDataHolder) => dispatch(updateLocationCustomFieldData(locationId, customFieldData)),
        updateUserCustomFieldData: (workflowId: string, userId: string, customFieldData: CustomFieldDataHolder) => dispatch(updateUserCustomFieldData(workflowId, userId, customFieldData)),
        updateMemberCustomFieldData: (workflowId: string, memberId: string, customFieldData: CustomFieldDataHolder) => dispatch(updateMemberCustomFieldData(workflowId, memberId, customFieldData)),
        updateGroupCustomFieldData: (workflowId: string, groupId: string, customFieldData: CustomFieldDataHolder) => dispatch(updateGroupCustomFieldData(workflowId, groupId, customFieldData)),
        updateWorkflowCustomFieldData: (changedWorkflowId: string, workflowId: string, customFieldData: WorkflowTypeCustomFieldDataHolder) => dispatch(updateWorkflowCustomFieldData(changedWorkflowId, workflowId, customFieldData)),
        updateWorkflow: (workflowData: IUpdateableWorkflowData) => dispatch(updateWorkflow(workflowData)),

        setToastMessage: (message: string) => dispatch(setToastMessage(message)),

        setInfoMessage: (message: string, persistMessage?: boolean) => dispatch(setInfoMessage(message, persistMessage)),
        clearInfoMessage: () => dispatch(clearInfoMessage()),

        setErrorMessage: (message: string, persistMessage?: boolean) => dispatch(setErrorMessage(message, persistMessage)),
        clearErrorMessage: () => dispatch(clearErrorMessage()),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps<{ id: string }>;

type OwnState = {
    userInputs: {
        [customFieldId: string]: CustomFieldValueType,
    },
    userInputsForList: {
        [listItem: string]: {
            [customFieldId: string]: CustomFieldValueType,
        }
    },
    expandedInputs: {
        [customFieldId: string]: boolean,
    },
    expandedInputsForList: {
        [listItem: string]: {
            [customFieldId: string]: boolean,
        },
    },
    errorMessages: {
        [questionId: string]: string,
    },
    errorMessagesForList: {
        [listItem: string]: {
            [questionId: string]: string,
        }
    },
    choiceInputs: {
        [questionId: string]: string | Array<string>,
    },
    choiceErrorMessages: {
        [questionId: string]: string,
    },
    answerKey: number,
    errorForWorkflowExecution: string,
    addWorkflowErrorMessage: string,
    expandTimeline: boolean,
    sortedTimeline: boolean,
    toastModal: JSX.Element | undefined,
    isButtonClicked: boolean,

    isFinsalComplete: boolean,
}

class ConnectedWorkflowProcess extends Component<Props, OwnState> {
    finsalIframeRef: React.RefObject<HTMLIFrameElement>;

    constructor(props: Readonly<Props>) {
        super(props);
        this.finsalIframeRef = createRef<HTMLIFrameElement>();

        this.state = {
            userInputs: {},
            userInputsForList: {},
            expandedInputs: {},
            expandedInputsForList: {},
            errorMessages: {},
            errorMessagesForList: {},
            choiceInputs: {},
            choiceErrorMessages: {},
            answerKey: 1,
            errorForWorkflowExecution: '',
            addWorkflowErrorMessage: '',
            expandTimeline: true,
            sortedTimeline: true,
            toastModal: undefined,
            isButtonClicked: false,

            isFinsalComplete: false,
        };
    }

    showToastModal = (text: string, isSuccess: boolean) => {
        let timer = setTimeout(() => {
            if (this.state.toastModal) {
                this.setState({
                    toastModal: undefined
                });
            }
        }, 4000);

        this.setState({
            toastModal: <LoaderModal closeModal={() => {
                this.setState({
                    toastModal: undefined
                });
                clearTimeout(timer);
            }} loaderText={[text]} isOutsideClickable isSuccess={isSuccess} isError={!isSuccess} />
        });
    }

    handleKeyPress = (e: KeyboardEvent) => {
        // Do not apply any shortcuts when you are typing something into an input element
        if (!window.document.activeElement) {
            return;
        }

        const className = window.document.activeElement.getAttribute('class');

        if (!className || !className.toLocaleLowerCase().includes('choice')) {
            return;
        }

        switch (e.key) {
            case 'Enter':
                (window.document.activeElement as HTMLElement).click();
        }
    }

    componentWillMount() {
        document.addEventListener('keydown', this.handleKeyPress);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
    }

    componentDidCatch(error: Error, errorInfo: any) {
        this.reportWorkflowError(error.message);
    }

    updateCustomFieldValue = (workflowId: string, entityId: string, type: VariableType, fieldId: string, value: CustomFieldValueType, memberId?: string) => {
        if (type === VariableType.LOCATION) {
            this.props.updateLocationCustomFieldData(entityId, { [fieldId]: value });
        } else if (type === VariableType.USER) {
            this.props.updateUserCustomFieldData(workflowId, entityId, { [fieldId]: value });
        } else if (type === VariableType.MEMBER) {
            this.props.updateMemberCustomFieldData(workflowId, entityId, { [fieldId]: value });
        } else if (type === VariableType.GROUP) {
            this.props.updateGroupCustomFieldData(workflowId, entityId, { [fieldId]: value });
        } else if (type === VariableType.WORKFLOW) {
            if (memberId) {
                this.props.updateWorkflowCustomFieldData(workflowId, entityId, { [fieldId]: { [memberId]: value } });
            } else {
                this.props.updateWorkflowCustomFieldData(workflowId, entityId, { [fieldId]: value });
            }
        }
    }

    updateLocation = (entityIds: Array<string>, type: VariableType, locationId: string) => {
        if (type === VariableType.MEMBER || type === VariableType.MEMBERS_LIST) {
            this.props.updateMembersLocation(entityIds, locationId);
        } else if (type === VariableType.GROUP || type === VariableType.GROUPS_LIST) {
            this.props.updateGroupsLocation(entityIds, locationId);
        }
    }

    getWorkflowProcessState = (workflowId?: string) => {
        if (!workflowId) {
            workflowId = this.props.match.params.id;
        }

        const workflow = this.props.workflowData.byId[workflowId];
        const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: workflow.history[workflow.historyIndex].customFields,
            lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
            executionStack: workflow.history[workflow.historyIndex].executionStack,
            forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
            variables: workflow.history[workflow.historyIndex].variables,
            displayingQuestionPieceId: workflow.history[workflow.historyIndex].displayingQuestionPieceId,
            displayingShowPieceId: workflow.history[workflow.historyIndex].displayingShowPieceId,
            displayingGroupPieceId: workflow.history[workflow.historyIndex].displayingGroupPieceId,
            displayingTransferPieceId: workflow.history[workflow.historyIndex].displayingTransferPieceId,
            displayingContinuePieceId: workflow.history[workflow.historyIndex].displayingContinuePieceId,
            displayingAddWorkflowPieceId: workflow.history[workflow.historyIndex].displayingAddWorkflowPieceId,
            createdWorkflowId: workflow.history[workflow.historyIndex].createdWorkflowId,
        }));

        return processState;
    }

    doesWorkflowConditionPass = (workflowId: string) => {
        const processState = this.getWorkflowProcessState();

        if (!processState.displayingContinuePieceId) {
            return false;
        }

        const continuePiece = this.props.applicationState.flowchart.pieces.byId[processState.displayingContinuePieceId];

        if (continuePiece.type !== PieceType.CONTINUE) {
            return false;
        }

        if (!continuePiece.condition) {
            return false;
        }

        const isConditionPassing = !!getWorkflowPieceValue(this.props.applicationState, processState, workflowId, continuePiece.condition);

        return isConditionPassing;
    }

    addWorkflowFromPiece = (pieceId: string) => {
        const piece = this.props.piecesData.byId[pieceId];

        if (piece.type !== PieceType.ADD_WORKFLOW) {
            throw new Error('The piece must be an add workflow');
        }

        if (!piece.workflowType) {
            throw new Error('The start workflow piece must have a type');
        }

        if (typeof piece.variable === 'undefined') {
            throw new Error('This piece must point to a variable');
        }

        const newWorkflowType = this.props.workflowData.types.byId[piece.workflowType];

        const newWorkflowStatuses = newWorkflowType.statuses.map(statusId => this.props.workflowData.types.statuses.byId[statusId]).filter(workflowStatus => !workflowStatus.isTerminal);

        if (newWorkflowStatuses.length === 0) {
            this.setState({
                addWorkflowErrorMessage: 'A workflow of this type should have at least one non terminal status'
            });
            return false;
        }

        const workflowId = this.props.match.params.id;

        let addWorkflowAffiliationValue: VariableValueType = '';

        if (newWorkflowType.affiliation !== 'none') {

            if (!piece.affiliationVariable) {
                this.setState({
                    addWorkflowErrorMessage: 'The start workflow piece must have an affiliation variable'
                });
                return false;
            }

            const addWorkflowAffiliationVariablePiece = this.props.piecesData.byId[piece.affiliationVariable];

            const processState = this.getWorkflowProcessState();

            addWorkflowAffiliationValue = getWorkflowPieceValue(this.props.applicationState, processState, workflowId, addWorkflowAffiliationVariablePiece.id);

            if (typeof addWorkflowAffiliationValue !== 'string' || !isUUID(addWorkflowAffiliationValue)) {
                this.setState({
                    addWorkflowErrorMessage: 'This value must be an ID for the affiliated entity'
                });
                return false;
            }

        }

        // Do not add the workflow if an instance for that group already exists
        if (!newWorkflowType.areMultipleInstancesAllowed && Array.isArray(newWorkflowType.workflows)) {
            if (newWorkflowType.affiliation !== 'none') {
                for (let i = 0; i < newWorkflowType.workflows.length; i += 1) {
                    const workflowOfType = this.props.workflowData.byId[newWorkflowType.workflows[i]];

                    if (workflowOfType && !workflowOfType.archived && workflowOfType.affiliatedEntity === addWorkflowAffiliationValue && !this.props.workflowData.types.statuses.byId[workflowOfType.status].isTerminal) {
                        this.setState({
                            addWorkflowErrorMessage: 'A workflow with this affiliation already exists'
                        });
                        return false;
                    }
                }
            } else {
                for (let i = 0; i < newWorkflowType.workflows.length; i += 1) {
                    const workflowOfType = this.props.workflowData.byId[newWorkflowType.workflows[i]];
                    const assignedUserId = this.props.workflowData.byId[workflowId].user;

                    if (workflowOfType && !workflowOfType.archived && workflowOfType.user === assignedUserId && !this.props.workflowData.types.statuses.byId[workflowOfType.status].isTerminal) {
                        this.setState({
                            addWorkflowErrorMessage: 'A workflow with this affiliation already exists'
                        });
                        return false;
                    }
                }
            }
        }

        return true;
    }

    startOrResumeWorkflow = (workflowId?: string) => {

        return new Promise<void>(async (resolve, reject) => {

            if (!workflowId) {
                workflowId = this.props.match.params.id;
            }

            const workflow = this.props.workflowData.byId[workflowId];
            const workflowType = this.props.workflowData.types.byId[workflow.type];

            const allPiecesInWorkflow = getAllPiecesInPiece(this.props.piecesData, workflowType.startPiece.piece);

            const getLocationPiece = allPiecesInWorkflow.find(piece => piece.type === PieceType.GET_CURRENT_LOCATION);

            if (!!getLocationPiece) {
                if (navigator.permissions) {
                    const status = await navigator.permissions.query({ name: 'geolocation' });
                    if (status.state === 'denied') {
                        this.reportWorkflowError('You need location permissions to execute this workflow');
                        reject('You need location permissions to execute this workflow');
                        return;
                    } else if (status.state === 'prompt') {
                        this.reportWorkflowError('Please provide location permissions and try again');
                        reject('Please provide location permissions and try again');
                        navigator.geolocation.getCurrentPosition(() => { });
                        return;
                    }
                }
            }

            const processState = this.getWorkflowProcessState(workflowId);
            let display: string;

            const workflowStatus = this.props.workflowData.types.statuses.byId[workflow.status];
            let workflowAddSuccessful = true;

            if (processState.displayingQuestionPieceId) {
                const questionPiece = this.props.applicationState.flowchart.pieces.byId[processState.displayingQuestionPieceId];

                if (questionPiece.type === PieceType.QUESTION) {
                    display = 'question';
                } else {
                    display = 'choose';
                }

            } else if (processState.displayingShowPieceId) {
                display = 'show';
            } else if (processState.displayingGroupPieceId) {
                display = 'group';
            } else if (processState.displayingTransferPieceId) {
                display = 'transfer';
            } else if (processState.displayingContinuePieceId) {
                display = 'continue';
            } else if (workflowStatus.isTerminal) {
                display = 'end';
            } else if (processState.createdWorkflowId) {
                display = 'switch';
            } else if (processState.displayingAddWorkflowPieceId) {
                display = 'add-workflow';
                workflowAddSuccessful = this.addWorkflowFromPiece(processState.displayingAddWorkflowPieceId);
            } else {
                display = 'start';
            }

            let shouldWorkflowExecute = false;

            if (display === 'start') {
                shouldWorkflowExecute = true;
            } else if (display === 'continue') {
                const doesContinueConditionPass = this.doesWorkflowConditionPass(workflowId);
                shouldWorkflowExecute = doesContinueConditionPass;
                processState.displayingContinuePieceId = undefined;
            } else if (display === 'add-workflow') {
                shouldWorkflowExecute = workflowAddSuccessful;
            } else if (display === 'switch') {
                shouldWorkflowExecute = true;
                processState.createdWorkflowId = undefined;
            }

            if (shouldWorkflowExecute) {
                this.setState({
                    isButtonClicked: true,
                });

                setTimeout(async () => {
                    if (workflowId) {
                        try {
                            await startOrResumeWorkflow(this.props.applicationState, processState, workflowId, this.props.updateStatus, this.props.updateDueDate, this.updateCustomFieldValue, this.updateLocation, this.props.addToHistory, this.props.addMember, this.props.addGroup, this.props.setMembersForGroup, this.props.addWorkflow);
                        } catch (e) {
                            if (e instanceof Error) {
                                console.error(e);
                                this.reportWorkflowError(e.message);
                            }
                        }
                    }

                    this.setState({
                        isButtonClicked: false,
                    });

                    resolve();
                }, 500);
            } else {
                resolve();
            }

        });

    }

    reportWorkflowError = (error: string) => {
        this.props.setToastMessage('Error during workflow execution');
        this.setState({
            errorForWorkflowExecution: error,
        });
    }

    continueAfterDisplay = async () => {

        const workflowId = this.props.match.params.id;
        const workflow = this.props.workflowData.byId[workflowId];

        if (!workflow.history[workflow.historyIndex].displayingShowPieceId) {
            this.reportWorkflowError('This can only be called when a display piece is being shown')
        }

        const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: workflow.history[workflow.historyIndex].customFields,
            lastComputedPiece: workflow.history[workflow.historyIndex].displayingShowPieceId,
            executionStack: workflow.history[workflow.historyIndex].executionStack,
            forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
            variables: workflow.history[workflow.historyIndex].variables,
            displayingQuestionPieceId: undefined,
            displayingShowPieceId: undefined,
            displayingGroupPieceId: undefined,
            displayingTransferPieceId: undefined,
            displayingContinuePieceId: undefined,
            displayingAddWorkflowPieceId: undefined,
            createdWorkflowId: undefined,
            displayingFinsalLoanProcessPieceId: undefined,
        }));

        this.setState({
            isButtonClicked: true,
        });

        setTimeout(() => {
            try {
                startOrResumeWorkflow(this.props.applicationState, processState, workflowId, this.props.updateStatus, this.props.updateDueDate, this.updateCustomFieldValue, this.updateLocation, this.props.addToHistory, this.props.addMember, this.props.addGroup, this.props.setMembersForGroup, this.props.addWorkflow);
            } catch (e) {
                if (e instanceof Error) {
                    this.reportWorkflowError(e.message);
                }
            }

            this.setState({
                isButtonClicked: false,
            });
        }, 500);
    }

    continueAfterFinsal = async () => {

        const workflowId = this.props.match.params.id;
        const workflow = this.props.workflowData.byId[workflowId];

        if (!workflow.history[workflow.historyIndex].displayingFinsalLoanProcessPieceId) {
            this.reportWorkflowError('This can only be called when a finsall loan process is being shown')
        }

        const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: workflow.history[workflow.historyIndex].customFields,
            lastComputedPiece: workflow.history[workflow.historyIndex].displayingFinsalLoanProcessPieceId,
            executionStack: workflow.history[workflow.historyIndex].executionStack,
            forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
            variables: workflow.history[workflow.historyIndex].variables,
            displayingQuestionPieceId: undefined,
            displayingShowPieceId: undefined,
            displayingGroupPieceId: undefined,
            displayingTransferPieceId: undefined,
            displayingContinuePieceId: undefined,
            displayingAddWorkflowPieceId: undefined,
            createdWorkflowId: undefined,
            displayingFinsalLoanProcessPieceId: undefined,
        }));

        this.setState({
            isButtonClicked: true,
            isFinsalComplete: false,
        });

        setTimeout(() => {
            try {
                startOrResumeWorkflow(this.props.applicationState, processState, workflowId, this.props.updateStatus, this.props.updateDueDate, this.updateCustomFieldValue, this.updateLocation, this.props.addToHistory, this.props.addMember, this.props.addGroup, this.props.setMembersForGroup, this.props.addWorkflow);
            } catch (e) {
                if (e instanceof Error) {
                    this.reportWorkflowError(e.message);
                }
            }

            this.setState({
                isButtonClicked: false,
            });
        }, 500);
    }

    switchToNewWorkflow = async (newWorkflowId: string | undefined) => {
        if (!this.props.workflow) {
            return;
        }

        if (newWorkflowId) {
            const newWorkflow = this.props.workflowData.byId[newWorkflowId];

            const newProcessState = newWorkflow.history[newWorkflow.historyIndex];
            const newWorkflowStatus = this.props.workflowData.types.statuses.byId[newWorkflow.status];


            if (!newWorkflowStatus.isTerminal && !newProcessState.lastComputedPiece) {
                await this.startOrResumeWorkflow(newWorkflowId);
            }

            this.startOrResumeWorkflow(this.props.workflow.id);

            this.props.history.push(`/workflow/${newWorkflowId}/execute`);
        }

    }

    exitTransferScreen = () => {
        const workflowId = this.props.match.params.id;
        let workflow = this.props.workflowData.byId[workflowId];

        while (workflow && workflow.triggeringWorkflow) {
            const triggeringWorkflow = this.props.workflowData.byId[workflow.triggeringWorkflow];
            const triggeringWorkflowStatus = this.props.workflowData.types.statuses.byId[triggeringWorkflow.status];

            if (!triggeringWorkflowStatus.isTerminal && (triggeringWorkflow.user === this.props.myId || this.props.myId === 'SuperUser')) {
                this.props.history.push(`/workflow/${triggeringWorkflow.id}/execute`);
                return;
            }

            workflow = this.props.workflowData.byId[workflow.triggeringWorkflow];
        }

        this.props.history.push('/workflows/list')
    }

    getHeading = (workflowId: string | undefined) => {
        if (!workflowId) {
            throw new Error('Cannot get the heading of a workflow that does not exist');
        }

        const workflow = this.props.workflowData.byId[workflowId];
        const workflowTypesData = this.props.applicationState.workflows.types;
        const workflowType = this.props.workflowData.types.byId[workflow.type];

        let workflowAffiliatedName = translatePhrase('Not Applicable');


        if (workflowType.affiliation === 'member') {
            const member = this.props.membersData.byId[workflow.affiliatedEntity];
            const memberType = this.props.membersData.types.byId[member.type];
            let memberName = member.customFields[memberType.nameFieldId];;

            const nameField = this.props.membersData.types.customFields.byId[memberType.nameFieldId];

            memberName = getReadableDataForCustomField(memberName, nameField, member.id, 'member');

            workflowAffiliatedName = translatePhrase(workflowType.name) + ' - ' + memberName;
        } else if (workflowType.affiliation === 'group') {
            const group = this.props.groupsData.byId[workflow.affiliatedEntity];
            const groupType = this.props.groupsData.types.byId[group.type];
            let groupName = group.customFields[groupType.nameFieldId];

            const nameField = this.props.groupsData.types.customFields.byId[groupType.nameFieldId];

            groupName = getReadableDataForCustomField(groupName, nameField, group.id, 'group');

            workflowAffiliatedName = translatePhrase(workflowType.name) + ' - ' + groupName;
        } else if (workflowType.affiliation === 'none') {
            workflowAffiliatedName = translatePhrase(workflowType.name);
        }

        let details = '-';

        if (workflowType.subTitleFieldId) {
            const subTitleField = workflowTypesData.customFields.byId[workflowType.subTitleFieldId];
            const detailsValue = workflow.history[workflow.historyIndex].customFields[workflowType.subTitleFieldId];

            // Only allow fields that are not member-affiliated in group workflows
            if (Array.isArray(detailsValue) || typeof detailsValue !== 'object') {
                details = getReadableDataForCustomField(detailsValue, subTitleField, workflow.id, 'workflow')
            }
        }

        return {
            heading: workflowAffiliatedName,
            subTitle: details
        };


    }

    getNextWorkflowSubtitleDetails = (workflowId: string | undefined) => {

        if (!workflowId) {
            throw new Error('Cannot get the heading of a workflow that does not exist');
        }

        const workflow = this.props.workflowData.byId[workflowId];
        const workflowTypesData = this.props.applicationState.workflows.types;
        const workflowType = this.props.workflowData.types.byId[workflow.type];

        let workflowAffiliatedName = '';

        if (workflowType.affiliation === 'member') {
            const member = this.props.membersData.byId[workflow.affiliatedEntity];
            const memberType = this.props.membersData.types.byId[member.type];
            let memberName = member.customFields[memberType.nameFieldId];;

            const nameField = this.props.membersData.types.customFields.byId[memberType.nameFieldId];

            memberName = getReadableDataForCustomField(memberName, nameField, member.id, 'member');

            workflowAffiliatedName = memberName;
        } else if (workflowType.affiliation === 'group') {
            const group = this.props.groupsData.byId[workflow.affiliatedEntity];
            const groupType = this.props.groupsData.types.byId[group.type];
            let groupName = group.customFields[groupType.nameFieldId];

            const nameField = this.props.groupsData.types.customFields.byId[groupType.nameFieldId];

            groupName = getReadableDataForCustomField(groupName, nameField, group.id, 'group');

            workflowAffiliatedName = groupName;
        }

        let details = workflowAffiliatedName;

        if (workflowType.subTitleFieldId) {
            const subTitleField = workflowTypesData.customFields.byId[workflowType.subTitleFieldId];
            const detailsValue = workflow.history[workflow.historyIndex].customFields[workflowType.subTitleFieldId];

            // Only allow fields that are not member-affiliated in group workflows
            if (Array.isArray(detailsValue) || typeof detailsValue !== 'object') {
                details = getReadableDataForCustomField(detailsValue, subTitleField, workflow.id, 'workflow')
            }
        }

        return details;
    }

    updateUserInput = (customFieldId: string, value: CustomFieldValueType) => {
        this.setState((prevState: Readonly<OwnState>) => {
            return {
                userInputs: {
                    ...prevState.userInputs,
                    [customFieldId]: value,
                },
                answerKey: (window.document.activeElement && window.document.activeElement.tagName === 'INPUT') ? prevState.answerKey : prevState.answerKey + 1,
            };
        });
    }

    toggleInputExpansion = (customFieldId: string) => {
        this.setState((prevState: Readonly<OwnState>) => {
            return {
                expandedInputs: {
                    ...prevState.expandedInputs,
                    [customFieldId]: !prevState.expandedInputs[customFieldId],
                },
            };
        });
    }

    toggleInputExpansionForList = (listId: string, customFieldId: string) => {
        this.setState((prevState: Readonly<OwnState>) => {
            return {
                expandedInputsForList: {
                    ...prevState.expandedInputsForList,
                    [listId]: {
                        ...prevState.expandedInputsForList[listId],
                        [customFieldId]: listId in prevState.expandedInputsForList ? !prevState.expandedInputsForList[listId][customFieldId] : true,
                    },
                },
            };
        });
    }

    updateUserInputForChoice = (questionId: string, value: string | Array<string> | undefined) => {

        this.setState(prevState => {
            if (typeof value === 'undefined') {
                const newChoices = {
                    ...prevState.choiceInputs,
                };

                delete newChoices[questionId];

                return {
                    ...prevState,
                    choiceInputs: newChoices,
                };
            }

            return {
                choiceInputs: {
                    ...prevState.choiceInputs,
                    [questionId]: value,
                },
                answerKey: (window.document.activeElement && window.document.activeElement.tagName === 'INPUT') ? prevState.answerKey : prevState.answerKey + 1,
            };
        });
    }

    updateUserInputForList = (listId: string, customFieldId: string, value: CustomFieldValueType) => {
        this.setState(prevState => {
            return {
                userInputsForList: {
                    ...prevState.userInputsForList,
                    [listId]: {
                        ...prevState.userInputsForList[listId],
                        [customFieldId]: value,
                    },
                },
                answerKey: (window.document.activeElement && window.document.activeElement.tagName === 'INPUT') ? prevState.answerKey : prevState.answerKey + 1,
            };
        })
    }

    getAllQuestionsInGroup = (groupPieceId: string) => {
        const questionIds: Array<string> = [];
        const chooseIds: Array<string> = [];

        const groupPiece = this.props.piecesData.byId[groupPieceId];

        if (groupPiece.type !== PieceType.GROUP && groupPiece.type !== PieceType.GROUP_FOR_LIST && groupPiece.type !== PieceType.SECTION) {
            throw new Error('The id must be a group ID');
        }

        if (!groupPiece.innerPiece) {
            throw new Error('The group piece must have an inner piece');
        }

        let pieceIdToConsider = groupPiece.innerPiece;

        do {
            const pieceToConsider = this.props.piecesData.byId[pieceIdToConsider];

            if (pieceToConsider.type === PieceType.GROUPED_QUESTION) {
                questionIds.push(pieceIdToConsider);
                pieceIdToConsider = pieceToConsider.nextPiece || '';
            } else if (pieceToConsider.type === PieceType.GROUPED_CHOOSE) {
                chooseIds.push(pieceIdToConsider);
                pieceIdToConsider = pieceToConsider.nextPiece || '';
            } else if (pieceToConsider.type === PieceType.SECTION) {
                const questionsInSection = this.getAllQuestionsInGroup(pieceToConsider.id);
                questionIds.push.apply(questionIds, questionsInSection.questionIds);
                chooseIds.push.apply(chooseIds, questionsInSection.chooseIds);
                pieceIdToConsider = pieceToConsider.nextPiece || '';
            } else if (pieceToConsider.type === PieceType.GROUPED_SHOW) {
                pieceIdToConsider = pieceToConsider.nextPiece || '';
            } else {
                throw new Error('This piece can only be a grouped question, a grouped show, or a section');
            }

        } while (pieceIdToConsider);

        return {
            questionIds,
            chooseIds,
        };
    }

    updateWorkflowWithAnswer = (workflowId: string, customField: WorkflowTypeCustomField, questionId: string, processState: WorkflowProcessState, answer: CustomFieldValueType) => {
        const workflow = this.props.workflowData.byId[workflowId];
        const workflowType = this.props.workflowData.types.byId[workflow.type];

        // Store the answer in a custom field
        const isForSingleMember = workflowType.affiliation === 'group' && customField.affiliation === 'member';
        let customFieldData = processState.customFields[customField.id]

        const questionPiece = this.props.piecesData.byId[questionId];

        if (questionPiece.type !== PieceType.QUESTION && questionPiece.type !== PieceType.GROUPED_QUESTION) {
            throw new Error('This is not a question piece');
        }

        if (isForSingleMember) {
            if (!questionPiece.memberVariablePiece) {
                throw new Error('This piece needs to have a member variable along with this custom field');
            }

            const memberId = getWorkflowPieceValue(this.props.applicationState, processState, workflow.id, questionPiece.memberVariablePiece);

            if (typeof memberId !== 'string') {
                throw new Error('The member ID must be a string');
            }

            if (typeof customFieldData === 'undefined') {
                customFieldData = {};
            }

            if (Array.isArray(customFieldData) || typeof customFieldData !== 'object') {
                throw new Error('The custom field data must be an object');
            }

            customFieldData[memberId] = answer;
        } else {
            customFieldData = answer;
        }

        processState.customFields[customField.id] = customFieldData;

        return processState;
    }

    showErrorMessage = (message: string, questionId: string) => {
        this.showToastModal(message, false);

        this.setState(prevState => {
            return {
                errorMessages: {
                    ...prevState.errorMessages,
                    [questionId]: message,
                }
            }
        });

        window.setTimeout(() => {
            this.setState(prevState => {
                return {
                    errorMessages: {
                        ...prevState.errorMessages,
                        [questionId]: '',
                    }
                }
            });
        }, 4000);

        const questionElement = window.document.getElementById(questionId);
        if (!!questionElement) {
            questionElement.scrollIntoView({
                block: 'center',
            });
        }
    }

    showErrorMessageForChoice = (message: string, questionId: string) => {
        this.showToastModal(message, false);

        this.setState(prevState => {
            return {
                choiceErrorMessages: {
                    ...prevState.choiceErrorMessages,
                    [questionId]: message,
                }
            }
        });

        window.setTimeout(() => {
            this.setState(prevState => {
                return {
                    choiceErrorMessages: {
                        ...prevState.choiceErrorMessages,
                        [questionId]: '',
                    }
                }
            });
        }, 5000);

        const questionElement = window.document.getElementById(questionId);
        if (!!questionElement) {
            questionElement.scrollIntoView({
                block: 'center',
            });
        }
    }

    showErrorMessageForList = (listId: string, message: string, questionId: string) => {
        this.showToastModal(message, false);

        this.setState(prevState => {
            return {
                errorMessagesForList: {
                    ...prevState.errorMessagesForList,
                    [listId]: {
                        ...prevState.errorMessagesForList[listId],
                        [questionId]: message,
                    },
                }
            }
        });

        window.setTimeout(() => {
            this.setState(prevState => {
                return {
                    errorMessagesForList: {
                        ...prevState.errorMessagesForList,
                        [listId]: {
                            ...prevState.errorMessagesForList[listId],
                            [questionId]: '',
                        },
                    }
                }
            });
        }, 5000);

        const questionElement = window.document.getElementById(questionId + listId);
        if (!!questionElement) {
            questionElement.scrollIntoView({
                block: 'center',
            });
        }
    }

    getUpdatedFileNamesForAllAnswers = async (questionIds: Array<string>) => {

        let userInputs: {
            [customFieldId: string]: CustomFieldValueType;
        } = JSON.parse(JSON.stringify(this.state.userInputs));

        for (let i = 0; i < questionIds.length; i += 1) {
            const questionId = questionIds[i];
            const questionPiece = this.props.piecesData.byId[questionId];

            if (questionPiece.type !== PieceType.QUESTION && questionPiece.type !== PieceType.GROUPED_QUESTION) {
                throw new Error('The ID should point to a piece of the question type');
            }

            if (!questionPiece.customFieldId) {
                throw new Error('The question must be attached to a valid custom field');
            }

            const customField = this.props.workflowData.types.customFields.byId[questionPiece.customFieldId];
            let answer = userInputs[questionPiece.customFieldId];

            if (customField.type === FieldType.FILE) {
                if (typeof answer === 'string') {
                    if (answer.startsWith('http')) {
                        answer = getFileNameFromUrl(answer);
                    } else {
                        const localSavedFile = await getStoredFileInDB(answer);
                        answer = localSavedFile?.file?.name;
                    }
                }
                userInputs[questionPiece.customFieldId] = answer;
            }
        }

        const allAnswers = {
            ...this.state.userInputsForList,
            ...userInputs,
        }

        return allAnswers;
    }

    validateAnswer = async (questionIds: Array<string>, questionId: string, answer: CustomFieldValueType, processState: WorkflowProcessState) => {

        const questionPiece = this.props.piecesData.byId[questionId];

        if (questionPiece.type !== PieceType.QUESTION && questionPiece.type !== PieceType.GROUPED_QUESTION) {
            throw new Error('The ID should point to a piece of the question type');
        }

        if (!questionPiece.customFieldId) {
            throw new Error('The question must be attached to a valid custom field');
        }

        const customField = this.props.workflowData.types.customFields.byId[questionPiece.customFieldId];

        const workflow = this.props.workflowData.byId[this.props.match.params.id];

        if (customField.type === FieldType.SINGLE_SELECT && typeof answer === 'string' && isUUID(answer)) {
            answer = this.props.workflowData.types.customFieldOptions.byId[answer].name;
        } else if (customField.type === FieldType.MULTI_SELECT && Array.isArray(answer)) {
            answer = answer.map(optionId => isUUID(optionId) ? this.props.workflowData.types.customFieldOptions.byId[optionId].name : optionId);
        } else if (customField.type === FieldType.NUMBER && typeof answer !== 'undefined' && answer !== '') {
            if (isNaN(Number(answer))) {
                return 'The answer must be a valid number';
            }

            const numberString = String(answer).trim();

            if (!/^-?[0-9]*(\.[0-9]+)?$/.test(numberString)) {
                return 'The answer must be a valid number';
            }

            answer = Number(answer);
        } else if (customField.type === FieldType.BOOLEAN) {
            if (answer === 'Yes') {
                answer = true;
            } else if (answer === 'No') {
                answer = false;
            }
        }

        if (customField.type === FieldType.PHONE) {
            if (typeof answer !== 'undefined') {
                if (typeof answer !== 'string') {
                    throw new Error('The answer type must be string')
                }

                if (answer.split(' ').length !== 2) {
                    return 'Invalid phone number';
                }

                const phoneCountryCode = answer.split(' ')[0];

                if (!['+91', '+1'].includes(phoneCountryCode)) {
                    return 'Invalid country code';
                }

            }
        }

        if (customField.type === FieldType.FILE) {
            if (typeof answer === 'string') {
                if (answer.startsWith('http')) {
                    answer = getFileNameFromUrl(answer);
                } else {
                    const localSavedFile = await getStoredFileInDB(answer);
                    answer = localSavedFile?.file?.name;
                }
            }
        }

        const allAnswers = await this.getUpdatedFileNamesForAllAnswers(questionIds);

        let isDisabled = false, isHidden = false;

        if (questionPiece.isDisabledPiece) {
            const disabledProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(processState));
            isDisabled = !!getWorkflowQuestionValidationValue(this.props.applicationState, disabledProcessState, workflow.id, questionId, answer, allAnswers, questionPiece.isDisabledPiece);
        }

        if (questionPiece.isHiddenPiece) {
            const hiddenProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(processState));
            isHidden = !!getWorkflowQuestionValidationValue(this.props.applicationState, hiddenProcessState, workflow.id, questionId, answer, allAnswers, questionPiece.isHiddenPiece);
        }

        if (questionPiece.isRequiredPiece) {
            const requiredProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(processState));
            const isRequired = !!getWorkflowQuestionValidationValue(this.props.applicationState, requiredProcessState, workflow.id, questionId, answer, allAnswers, questionPiece.isRequiredPiece);

            if (isRequired && !isDisabled && !isHidden) {
                if (customField.type === FieldType.BOOLEAN) {
                    if (typeof answer === 'undefined') {
                        return 'This answer is required';
                    }
                } else if (customField.type === FieldType.NUMBER) {
                    if (!answer && answer !== 0) {
                        return 'This answer is required';
                    }
                } else if (!answer || (Array.isArray(answer) && answer.length === 0)) {
                    return 'This answer is required';
                }
            }
        }

        let errorMessage = '';

        if (questionPiece.innerPiece && !isDisabled) {
            const validationProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(processState));
            let validationErrorMessage = getWorkflowQuestionValidation(this.props.applicationState, validationProcessState, questionPiece.innerPiece, workflow.id, questionId, answer, allAnswers);

            if (typeof validationErrorMessage === 'undefined') {
                validationErrorMessage = '';
            }

            if (typeof validationErrorMessage !== 'string') {
                throw new Error('Invalid value for validation');
            }

            errorMessage = validationErrorMessage;
        }


        return errorMessage;
    }

    validateChoice = async (questionIds: Array<string>, chooseId: string, answer: string | Array<string>, processState: WorkflowProcessState) => {
        const questionPiece = this.props.piecesData.byId[chooseId];

        if (questionPiece.type !== PieceType.CHOOSE && questionPiece.type !== PieceType.GROUPED_CHOOSE) {
            throw new Error('The ID should point to a piece of the choose type');
        }

        if (!questionPiece.variablePiece) {
            throw new Error('The choose piece must point to a valid choice list');
        }

        if (!questionPiece.choiceVariable) {
            throw new Error('The choose piece must point to a valid choice variable');
        }

        const choiceListVariableType = getPieceValueType(questionPiece.variablePiece, this.props.piecesData, this.props.variablesData);

        const workflow = this.props.workflowData.byId[this.props.match.params.id];

        switch (choiceListVariableType) {
            case VariableType.PROJECTS_LIST:
            case VariableType.LEVELS_LIST:
            case VariableType.ROLES_LIST:
            case VariableType.LOCATIONS_LIST:
            case VariableType.USERS_LIST:
            case VariableType.MEMBERS_LIST:
            case VariableType.GROUPS_LIST:
            case VariableType.WORKFLOWS_LIST:
            case VariableType.DATA_FRAGMENTS_LIST:
            case VariableType.TEXT_LIST:
                break;
            default:
                throw new Error('Unknown type for list variable');
        }

        const allAnswers = await this.getUpdatedFileNamesForAllAnswers(questionIds);

        let isDisabled = false, isHidden = false;

        if (questionPiece.isDisabledPiece) {
            const disabledProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(processState));
            isDisabled = !!getWorkflowQuestionValidationValue(this.props.applicationState, disabledProcessState, workflow.id, chooseId, answer, allAnswers, questionPiece.isDisabledPiece);
        }

        if (questionPiece.isHiddenPiece) {
            const hiddenWorkflowProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(processState));
            isHidden = !!getWorkflowQuestionValidationValue(this.props.applicationState, hiddenWorkflowProcessState, workflow.id, chooseId, answer, allAnswers, questionPiece.isHiddenPiece);
        }

        if (questionPiece.isRequiredPiece && !isDisabled && !isHidden) {
            const requiredProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(processState));
            const isRequired = !!getWorkflowQuestionValidationValue(this.props.applicationState, requiredProcessState, workflow.id, chooseId, answer, allAnswers, questionPiece.isRequiredPiece);

            if (isRequired && typeof answer === 'undefined') {
                return 'This answer is required';
            }
        }

        let errorMessage: string = '';

        if (questionPiece.innerPiece && !isDisabled) {
            const validationProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(processState));
            let validationErrorMessage = getWorkflowQuestionValidation(this.props.applicationState, validationProcessState, questionPiece.innerPiece, workflow.id, chooseId, answer, allAnswers);

            if (typeof validationErrorMessage === 'undefined') {
                validationErrorMessage = '';
            }

            if (typeof validationErrorMessage !== 'string') {
                throw new Error('Invalid value for validation');
            }

            errorMessage = validationErrorMessage;
        }


        return errorMessage;
    }

    updateAnswers = async (workflowId: string, questionIds: Array<string>, processState: WorkflowProcessState, userInputs: { [key: string]: CustomFieldValueType }, showErrorMessage: (message: string, questionId: string) => void) => {

        for (let i = 0; i < questionIds.length; i += 1) {
            const questionId = questionIds[i];
            const questionPiece = this.props.piecesData.byId[questionId];

            if (questionPiece.type !== PieceType.QUESTION && questionPiece.type !== PieceType.GROUPED_QUESTION) {
                throw new Error('The ID should point to a piece of the question type');
            }

            if (!questionPiece.customFieldId) {
                throw new Error('The question must be attached to a valid custom field');
            }

            const customField = this.props.workflowData.types.customFields.byId[questionPiece.customFieldId];
            const updateAnswerShorthand = this.updateWorkflowWithAnswer.bind(this, workflowId, customField, questionId, processState);
            let errorMessage: CustomFieldValueType = await this.validateAnswer(questionIds, questionId, userInputs[questionPiece.customFieldId], processState);

            if (!!errorMessage) {
                if (typeof errorMessage !== 'string') {
                    throw new Error('An error message must be a string type');
                }
                showErrorMessage(errorMessage, questionId);
                return;
            }

            switch (customField.type) {
                case FieldType.BOOLEAN:
                    let booleanValue: boolean | undefined;

                    if (!!userInputs[questionPiece.customFieldId]) {
                        booleanValue = userInputs[questionPiece.customFieldId] === 'Yes';
                    }

                    processState = updateAnswerShorthand(booleanValue);
                    break;

                case FieldType.TEXT:
                    processState = updateAnswerShorthand(userInputs[questionPiece.customFieldId]);
                    break;

                case FieldType.NUMBER:
                    const numberValue = Number(userInputs[questionPiece.customFieldId])
                    if (userInputs[questionPiece.customFieldId] === '' || isNaN(numberValue)) {
                        processState = updateAnswerShorthand(undefined);
                    } else {
                        processState = updateAnswerShorthand(numberValue);
                    }
                    break;

                case FieldType.DATE:
                    processState = updateAnswerShorthand(userInputs[questionPiece.customFieldId]);
                    break;

                case FieldType.SINGLE_SELECT:
                    processState = updateAnswerShorthand(userInputs[questionPiece.customFieldId]);
                    break;

                case FieldType.MULTI_SELECT:
                    processState = updateAnswerShorthand(userInputs[questionPiece.customFieldId]);
                    break;

                case FieldType.LOCATION:
                    processState = updateAnswerShorthand(userInputs[questionPiece.customFieldId]);
                    break;

                case FieldType.PHONE:
                    processState = updateAnswerShorthand(userInputs[questionPiece.customFieldId]);
                    break;

                case FieldType.FILE:
                    processState = updateAnswerShorthand(userInputs[questionPiece.customFieldId]);
                    break;

                case FieldType.FREE_TEXT:
                    processState = updateAnswerShorthand(userInputs[questionPiece.customFieldId]);
                    break;

                default:
                    throw new Error('Answering has not been implemented for this type of question');
            }

        }

        return processState;

    }

    updateChoices = async (questionIds: Array<string>, chooseIds: Array<string>, processState: WorkflowProcessState, choiceInputs: { [key: string]: string | Array<string> }, showErrorMessage: (message: string, questionId: string) => void) => {

        for (let i = 0; i < chooseIds.length; i += 1) {
            const chooseId = chooseIds[i];
            const choosePiece = this.props.piecesData.byId[chooseId];

            if (choosePiece.type !== PieceType.CHOOSE && choosePiece.type !== PieceType.GROUPED_CHOOSE) {
                throw new Error('The ID should point to a piece of the choose type');
            }

            if (!choosePiece.choiceVariable) {
                throw new Error('The choice must be pointed to a valid variable');
            }

            let errorMessage: CustomFieldValueType = '';

            errorMessage = await this.validateChoice(questionIds, chooseId, choiceInputs[chooseId], processState);

            if (!!errorMessage) {
                if (typeof errorMessage !== 'string') {
                    throw new Error('An error message must be a string type');
                }
                showErrorMessage(errorMessage, chooseId);
                return;
            }

            processState.variables[choosePiece.choiceVariable] = choiceInputs[chooseId];

        }

        return processState;

    }

    submitChoice = async (workflowId: string, questionId: string) => {
        const workflow = this.props.workflowData.byId[workflowId];
        let processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: workflow.history[workflow.historyIndex].customFields,
            lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
            executionStack: workflow.history[workflow.historyIndex].executionStack,
            forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
            variables: workflow.history[workflow.historyIndex].variables,
            displayingQuestionPieceId: undefined,
            displayingShowPieceId: undefined,
            displayingGroupPieceId: undefined,
            displayingTransferPieceId: undefined,
            displayingContinuePieceId: undefined,
            displayingAddWorkflowPieceId: undefined,
            createdWorkflowId: undefined,
            displayingFinsalLoanProcessPieceId: undefined,
        }));

        const updatedProcessState = await this.updateChoices([], [questionId], processState, this.state.choiceInputs, this.showErrorMessageForChoice);

        if (typeof updatedProcessState === 'undefined') {
            return;
        }

        this.setState(prevState => {
            return {
                userInputs: {
                    ...prevState.userInputs,
                    [questionId]: ''
                },
                toastModal: undefined,
            };
        });

        updatedProcessState.lastComputedPiece = questionId;

        this.setState({
            isButtonClicked: true,
        });

        setTimeout(() => {

            try {

                this.props.addToScreenInputs(workflow.id, {
                    pieceId: questionId,
                    workflowIndex: workflow.historyIndex,
                    answers: {},
                    groupedAnswers: {},
                    choices: this.state.choiceInputs,
                });

                startOrResumeWorkflow(this.props.applicationState, processState, workflowId, this.props.updateStatus, this.props.updateDueDate, this.updateCustomFieldValue, this.updateLocation, this.props.addToHistory, this.props.addMember, this.props.addGroup, this.props.setMembersForGroup, this.props.addWorkflow);
            } catch (e) {
                if (e instanceof Error) {
                    this.reportWorkflowError(e.message);
                }
            }

            this.setState({
                isButtonClicked: false,
            });
        }, 500);
    }

    submitAnswer = async (workflowId: string, questionId: string) => {
        const workflow = this.props.workflowData.byId[workflowId];
        let processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: workflow.history[workflow.historyIndex].customFields,
            lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
            executionStack: workflow.history[workflow.historyIndex].executionStack,
            forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
            variables: workflow.history[workflow.historyIndex].variables,
            displayingQuestionPieceId: undefined,
            displayingShowPieceId: undefined,
            displayingGroupPieceId: undefined,
            displayingTransferPieceId: undefined,
            displayingContinuePieceId: undefined,
            displayingAddWorkflowPieceId: undefined,
            createdWorkflowId: undefined,
            displayingFinsalLoanProcessPieceId: undefined,
        }));

        let updatedProcessState = await this.updateAnswers(workflowId, [questionId], processState, this.state.userInputs, this.showErrorMessage);

        if (typeof updatedProcessState === 'undefined') {
            return;
        }

        this.setState(prevState => {
            return {
                userInputs: {
                    ...prevState.userInputs,
                    [questionId]: ''
                },
                toastModal: undefined,
            };
        });

        updatedProcessState.lastComputedPiece = questionId;

        this.setState({
            isButtonClicked: true,
        });

        setTimeout(() => {
            try {

                this.props.addToScreenInputs(workflow.id, {
                    pieceId: questionId,
                    workflowIndex: workflow.historyIndex,
                    answers: this.state.userInputs,
                    groupedAnswers: {},
                    choices: {},
                });

                startOrResumeWorkflow(this.props.applicationState, processState, workflowId, this.props.updateStatus, this.props.updateDueDate, this.updateCustomFieldValue, this.updateLocation, this.props.addToHistory, this.props.addMember, this.props.addGroup, this.props.setMembersForGroup, this.props.addWorkflow);
            } catch (e) {
                if (e instanceof Error) {
                    this.reportWorkflowError(e.message);
                }
            }

            this.setState({
                isButtonClicked: false,
            });

        }, 500);

    }

    submitGroup = async (workflowId: string, groupId: string, questionIds: Array<string>, choiceIds: Array<string>) => {
        const workflow = this.props.workflowData.byId[workflowId];
        const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: workflow.history[workflow.historyIndex].customFields,
            lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
            executionStack: workflow.history[workflow.historyIndex].executionStack,
            forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
            variables: workflow.history[workflow.historyIndex].variables,
            displayingQuestionPieceId: undefined,
            displayingShowPieceId: undefined,
            displayingGroupPieceId: undefined,
            displayingTransferPieceId: undefined,
            displayingContinuePieceId: undefined,
            displayingAddWorkflowPieceId: undefined,
            createdWorkflowId: undefined,
            displayingFinsalLoanProcessPieceId: undefined,
        }));
        let updatedProcessState: WorkflowProcessState | undefined = {
            ...processState,
            variables: {
                ...processState.variables,
            },
            customFields: {
                ...processState.customFields,
            }
        };

        const groupPiece = this.props.piecesData.byId[groupId];

        const newState = {
            ...this.state,
            userInputs: {},
            userInputsForList: {},
            toastModal: undefined,
        };

        if (groupPiece.type === PieceType.GROUP) {

            updatedProcessState = await this.updateAnswers(workflowId, questionIds, updatedProcessState, this.state.userInputs, this.showErrorMessage);

            if (typeof updatedProcessState === 'undefined') {
                return;
            }

            updatedProcessState = await this.updateChoices(questionIds, choiceIds, updatedProcessState, this.state.choiceInputs, this.showErrorMessageForChoice);

            if (typeof updatedProcessState === 'undefined') {
                return;
            }

        } else if (groupPiece.type === PieceType.GROUP_FOR_LIST) {
            if (!groupPiece.iterableVariable) {
                throw new Error('This piece must have an iterable variable');
            }

            if (!groupPiece.loopVariable) {
                throw new Error('This piece must have a loop variable');
            }

            const loopVariable = groupPiece.loopVariable;

            let iterableValue = getWorkflowPieceValue(this.props.applicationState, processState, workflowId, groupPiece.iterableVariable);

            if (typeof iterableValue === 'undefined') {
                iterableValue = [];
            }

            if (!Array.isArray(iterableValue)) {
                throw new Error('The iterable value must be an array');
            }

            if (Array.isArray(iterableValue)) {

                if (iterableValue.length > 0 && Array.isArray(iterableValue[0])) {
                    // Cannot be a multidimensional array
                    throw new Error('The value cannot be a multi-dimensional array')
                }

                iterableValue = iterableValue as Array<string>;
            }

            for (let i = 0; i < iterableValue.length; i += 1) {
                const listItem = iterableValue[i];
                updatedProcessState.variables[loopVariable] = listItem;
                let userInputs = this.state.userInputsForList[listItem];

                if (typeof userInputs === 'undefined') {
                    userInputs = {};
                }

                updatedProcessState = await this.updateAnswers(workflowId, questionIds, updatedProcessState, userInputs, this.showErrorMessageForList.bind(this, listItem));

                if (typeof updatedProcessState === 'undefined') {
                    return;
                }
            }
        }

        if (typeof updatedProcessState === 'undefined') {
            return;
        }

        if (groupPiece.type === PieceType.GROUP) {
            this.props.addToScreenInputs(workflow.id, {
                pieceId: groupPiece.id,
                workflowIndex: workflow.historyIndex,
                answers: this.state.userInputs,
                groupedAnswers: {},
                choices: this.state.choiceInputs,
            });
        } else if (groupPiece.type === PieceType.GROUP_FOR_LIST) {
            this.props.addToScreenInputs(workflow.id, {
                pieceId: groupPiece.id,
                workflowIndex: workflow.historyIndex,
                answers: {},
                groupedAnswers: this.state.userInputsForList,
                choices: {},
            });
        }

        this.setState(newState);

        updatedProcessState.lastComputedPiece = groupId;

        this.setState({
            isButtonClicked: true,
        });

        setTimeout(() => {
            if (!updatedProcessState) {
                this.setState({
                    isButtonClicked: false,
                });

                return;
            }

            try {
                startOrResumeWorkflow(this.props.applicationState, updatedProcessState, workflowId, this.props.updateStatus, this.props.updateDueDate, this.updateCustomFieldValue, this.updateLocation, this.props.addToHistory, this.props.addMember, this.props.addGroup, this.props.setMembersForGroup, this.props.addWorkflow);
            } catch (e) {
                if (e instanceof Error) {
                    this.reportWorkflowError(e.message);
                }
            }

            this.setState({
                isButtonClicked: false,
            });
        }, 500);
    }

    goToWorkflows = () => {
        this.props.history.push('/workflows/list')
    }

    goToFlowchart = (workflowTypeId: string) => {
        this.props.history.push(`/workflows/flowchart/${workflowTypeId}/beta`)
    }

    toggleTimeline = () => {
        this.setState(prevState => {
            return {
                expandTimeline: !prevState.expandTimeline,
            };
        });
    }

    toggleSortTimeline = () => {
        this.setState(prevState => {
            return {
                sortedTimeline: !prevState.sortedTimeline,
            };
        });
    }

    addIframeListener = () => {
        window.addEventListener(
            "message",
            (ev: MessageEvent<{ type: string; message: string }>) => {
                if (typeof ev.data !== "object") return;
                if (!ev.data.message) return;

                if (ev.data.message === 'Finsal complete') {
                    this.markFinsalComplete();
                }
            }
        );
    }

    componentDidMount() {

        this.addIframeListener();

        if (!this.props.isLoaded) {
            return;
        }

        if (!this.props.match) {
            return;
        }

        const workflowId = this.props.match.params.id;
        const workflow = this.props.workflowData.byId[workflowId];

        if (!workflow) {
            return;
        }

        const workflowProcessState = workflow.history[workflow.historyIndex];

        if (!this.props.isReadable) {
            return;
        }

        if (isUUID(this.props.myId) && workflow.user !== this.props.myId) {
            return;
        }

        let display: string;
        const workflowStatus = this.props.workflowData.types.statuses.byId[workflow.status];

        if (this.state.errorForWorkflowExecution) {
            display = 'error';
        } else if (workflowProcessState.displayingQuestionPieceId) {
            const questionPiece = this.props.piecesData.byId[workflowProcessState.displayingQuestionPieceId];

            if (questionPiece.type === PieceType.QUESTION) {
                display = 'question';
            } else {
                display = 'choose';
            }

        } else if (workflowProcessState.displayingShowPieceId) {
            display = 'show';
        } else if (workflowProcessState.displayingGroupPieceId) {
            display = 'group';
        } else if (workflowProcessState.displayingTransferPieceId) {
            display = 'transfer';
        } else if (workflowProcessState.displayingContinuePieceId) {
            display = 'continue';
        } else if (workflowStatus.isTerminal) {
            display = 'end';
        } else if (workflowProcessState.displayingAddWorkflowPieceId) {
            display = 'add-workflow';
        } else if (workflowProcessState.displayingFinsalLoanProcessPieceId) {
            display = 'finsal-loan-process';
        } else if (workflowProcessState.createdWorkflowId) {
            display = 'switch';
        } else {
            display = 'start';
        }

        if (display === 'start' || !display) {
            this.startOrResumeWorkflow();
        }

    }

    componentDidUpdate(prevProps: Props) {
        const workflowProcessState = this.props.workflowProcessState;
        const prevWorkflowProcessState = prevProps.workflowProcessState;

        if (!this.props.workflow || !workflowProcessState || !prevWorkflowProcessState) {
            return;
        }

        if (this.props.workflow.id !== prevProps.workflow?.id || (workflowProcessState.lastComputedPiece && prevWorkflowProcessState.lastComputedPiece !== workflowProcessState.lastComputedPiece)) {
            const workflowStatus = this.props.workflowData.types.statuses.byId[this.props.workflow.status];

            let display: string;

            if (this.state.errorForWorkflowExecution) {
                display = 'error';
            } else if (workflowProcessState.displayingQuestionPieceId) {
                const questionPiece = this.props.piecesData.byId[workflowProcessState.displayingQuestionPieceId];

                if (questionPiece.type === PieceType.QUESTION) {
                    display = 'question';
                } else {
                    display = 'choose';
                }

            } else if (workflowProcessState.displayingShowPieceId) {
                display = 'show';
            } else if (workflowProcessState.displayingGroupPieceId) {
                display = 'group';
            } else if (workflowProcessState.displayingTransferPieceId) {
                display = 'transfer';
            } else if (workflowProcessState.displayingContinuePieceId) {
                display = 'continue';
            } else if (workflowStatus.isTerminal) {
                display = 'end';
            } else if (workflowProcessState.displayingAddWorkflowPieceId) {
                display = 'add-workflow';
            } else if (workflowProcessState.displayingFinsalLoanProcessPieceId) {
                display = 'finsal-loan-process';
            } else if (workflowProcessState.createdWorkflowId) {
                display = 'switch';
            } else {
                display = 'start';
                this.startOrResumeWorkflow();
            }

            if (display === 'end' && this.props.workflow.triggeringWorkflow && workflowProcessState.lastComputedPiece) {
                const endPiece = this.props.piecesData.byId[workflowProcessState.lastComputedPiece];
                let triggeringWorkflow = this.props.workflowData.byId[this.props.workflow.triggeringWorkflow];

                while (triggeringWorkflow) {
                    const triggeringWorkflowStatus = triggeringWorkflow ? this.props.workflowData.types.statuses.byId[triggeringWorkflow.status] : undefined;

                    if (endPiece.type === PieceType.END && !endPiece.message && triggeringWorkflowStatus && !triggeringWorkflowStatus.isTerminal) {
                        break;
                    }

                    if (!triggeringWorkflow.triggeringWorkflow) {
                        break;
                    }

                    triggeringWorkflow = this.props.workflowData.byId[triggeringWorkflow.triggeringWorkflow];

                }
            }
        }

    }

    makeFinsalCall = async () => {

        if (!this.props.workflow || !this.props.workflowProcessState) {
            this.props.setErrorMessage('Cannot make Finsall call - missing workflow details');

            setTimeout(() => {
                this.props.clearErrorMessage();
            }, 4000);
            return;
        };

        const workflowId = this.props.workflow.id;
        const workflowProcessState = this.props.workflowProcessState;

        if (!workflowProcessState.displayingFinsalLoanProcessPieceId) {
            this.props.setErrorMessage('Cannot make Finsall call - no selected finsal piece');

            setTimeout(() => {
                this.props.clearErrorMessage();
            }, 4000);
            return;
        }

        const piece = this.props.piecesData.byId[workflowProcessState.displayingFinsalLoanProcessPieceId];

        if (piece.type !== PieceType.FINSAL_LOAN_PROCESS) {
            this.props.setErrorMessage('Cannot make Finsall call - piece is not a Finsall piece');

            setTimeout(() => {
                this.props.clearErrorMessage();
            }, 4000);

            return;
        }

        if (!piece.response) {
            this.props.setErrorMessage('Cannot make Finsall call - no custom field value available');

            setTimeout(() => {
                this.props.clearErrorMessage();
            }, 4000);

            return;
        }

        await storeCurrentSnapshot(store.getState());

        const finsalResponse = await makeLoanProcessCall(piece, workflowId, workflowProcessState, this.props.applicationState);

        this.updateCustomFieldValue(workflowId, workflowId, VariableType.WORKFLOW, piece.response, finsalResponse);

        await storeCurrentSnapshot(store.getState());

    }

    reloadFinsalIframe = () => {
        if (this.finsalIframeRef.current) {
            this.finsalIframeRef.current.src = this.finsalIframeRef.current?.src;
        }
    }

    checkPaymentStatus = async () => {

        if (!this.props.workflow || !this.props.workflowProcessState) {
            this.props.setErrorMessage('Cannot make Finsall call - missing workflow details');

            setTimeout(() => {
                this.props.clearErrorMessage();
            }, 4000);
            return;
        };

        const workflowId = this.props.workflow.id;
        const affiliatedId = this.props.workflow.affiliatedEntity;
        const processState = this.props.workflowProcessState;

        const workflow = this.props.applicationState.workflows.byId[workflowId];

        if (!processState.displayingFinsalLoanProcessPieceId) {
            throw new Error('Does not have a displaying finsall piece');
        }

        const finsallPiece = this.props.applicationState.flowchart.pieces.byId[processState.displayingFinsalLoanProcessPieceId];

        if (!finsallPiece || finsallPiece.type !== PieceType.FINSAL_LOAN_PROCESS) {
            throw new Error('Could not find finsall piece');
        }

        try {
            const memberId = finsallPiece.member ? getWorkflowPieceValue(this.props.applicationState, processState, workflowId, finsallPiece.member) : '';

            if (typeof memberId !== 'string') {
                throw new Error('Could not get member ID');
            }

            const status = await checkPaymentStatus(finsallPiece, memberId, workflowId, this.props.applicationState, processState);
            this.props.setInfoMessage('Complete! Please continue the workflow');

            this.setState({
                isFinsalComplete: true,
            });

            setTimeout(() => {
                this.props.clearInfoMessage();
            }, 4000);
        } catch (e) {

            this.props.setInfoMessage('The payment for this loan is still pending');

            setTimeout(() => {
                this.props.clearInfoMessage();
            }, 4000);
        }
    }

    markFinsalComplete = () => {
        this.props.setInfoMessage('Complete! Please continue the workflow');
        this.setState({
            isFinsalComplete: true,
        });

        setTimeout(() => {
            this.props.clearInfoMessage();
        }, 4000);
    }

    moveToBrowser = (url: string) => {
        window.open(url, '_blank');
    }

    blockBackNavigation = () => {
        let nonExecutionSteps = 0;

        if (!this.props.workflow) {
            return true;
        }

        for (const step of this.props.workflow.history) {
            if (!!step.lastComputedPiece) {
                break;
            }

            nonExecutionSteps += 1;
        }

        if (this.props.workflow.historyIndex <= nonExecutionSteps) {
            return true;
        }

        if (typeof this.props.workflow.restrictedHistoryIndex !== 'undefined' && this.props.workflow.historyIndex <= this.props.workflow.restrictedHistoryIndex + 1) {
            return true;
        }

        const lastStep = this.props.workflow.history[this.props.workflow.historyIndex - 1];

        if (!!lastStep.createdWorkflowId) {
            const switchWorkflow = this.props.workflowData.byId[lastStep.createdWorkflowId];
            const switchedStatus = this.props.workflowData.types.statuses.byId[switchWorkflow.status];

            if (switchedStatus.isTerminal) {
                return true;
            }
        }

        return false;
    }

    render() {

        if (!this.props.isLoaded) {
            return <div></div>;
        }

        if (!this.props.match) {
            return <div></div>;
        }

        if (!this.props.workflow || !this.props.workflowProcessState) {
            return <div></div>
        }

        const workflowId = this.props.workflow.id;
        const workflowProcessState = this.props.workflowProcessState;

        const workflowStatus = this.props.workflowData.types.statuses.byId[this.props.workflow.status];
        const isWorkflowComplete = workflowStatus.isTerminal;

        const heading = this.getHeading(workflowId);

        if (!this.props.isReadable) {
            return <Redirect to="/dashboard" />;
        }

        if (isUUID(this.props.myId) && this.props.workflow.user !== this.props.myId) {
            return <div>
                <div className={styles.innerFocus}>
                    <WorkflowData workflowId={workflowId} goToWorkflows={this.goToWorkflows} />
                </div>
            </div>
        }

        let display: string, displayMarkup: JSX.Element | undefined, nextWorkflowId: string | undefined;

        if (this.state.errorForWorkflowExecution) {
            display = 'error';
        } else if (workflowProcessState.displayingQuestionPieceId) {
            const questionPiece = this.props.piecesData.byId[workflowProcessState.displayingQuestionPieceId];

            if (questionPiece.type === PieceType.QUESTION) {
                display = 'question';
            } else {
                display = 'choose';
            }

        } else if (workflowProcessState.displayingShowPieceId) {
            display = 'show';
        } else if (workflowProcessState.displayingGroupPieceId) {
            display = 'group';
        } else if (workflowProcessState.displayingTransferPieceId) {
            display = 'transfer';
        } else if (workflowProcessState.displayingContinuePieceId) {
            display = 'continue';
        } else if (workflowStatus.isTerminal) {
            display = 'end';
        } else if (workflowProcessState.displayingAddWorkflowPieceId) {
            display = 'add-workflow';
        } else if (workflowProcessState.displayingFinsalLoanProcessPieceId) {
            display = 'finsal-loan-process';
        } else if (workflowProcessState.createdWorkflowId) {
            display = 'switch';
        } else {
            display = 'start';
        }

        displaySwitch:
        switch (display) {
            case 'error':
                displayMarkup = <div className={styles.showDataContainer}> <div className={styles.iconHolder}> <ReadIcon /> </div><section>{this.state.errorForWorkflowExecution}</section></div>;
                submitButton = <Button text={translatePhrase('Go back')} isRounded={true} isBlock={true} padding={'0px 20px'} color={'primary'} onClick={() => this.props.history.push(`/workflows/list`)} />;
                break;
            case 'add-workflow':
                displayMarkup = <div className={styles.showDataContainer}> <div className={styles.iconHolder}> <ReadIcon /> </div><section>{this.state.addWorkflowErrorMessage ? this.state.addWorkflowErrorMessage : 'Add workflow failed'} {translatePhrase('Click below to retry')}</section></div>;
                submitButton = <Button isDisabled={this.state.isButtonClicked} text={translatePhrase('Retry')} isRounded={true} isBlock={true} padding={'0px 20px'} color={'primary'} onClick={this.startOrResumeWorkflow} />;
                break;
            case 'start':
                displayMarkup = <div className={styles.showDataContainer}> <div className={styles.iconHolder}> <ReadIcon /> </div><section> {translatePhrase('Click the button below to start the workflow')}</section></div>;
                submitButton = <span style={{ marginLeft: 'auto' }}>  <Button isDisabled={this.state.isButtonClicked} text={translatePhrase('Continue')} isRounded={true} isBlock={true} padding={'0px 20px'} color={'contrast'} onClick={this.startOrResumeWorkflow} /> </span>;
                break;
            case 'continue':
                displayMarkup = <div>
                    <section className={styles.promptText}><Continue workflowId={workflowId} continuePieceId={workflowProcessState.displayingContinuePieceId ? workflowProcessState.displayingContinuePieceId : ''} /></section>
                </div>
                submitButton = undefined;
                break;

            case 'question':
                if (workflowProcessState.displayingQuestionPieceId) {

                    const questionPiece = this.props.piecesData.byId[workflowProcessState.displayingQuestionPieceId];

                    if (questionPiece.type !== PieceType.QUESTION) {
                        throw new Error('The piece must be a question piece');
                    }

                    if (!questionPiece.customFieldId) {
                        throw new Error('The question piece must have a custom field');
                    };

                    let isHidden: boolean | undefined = false;

                    if (questionPiece.isHiddenPiece) {
                        const allAnswers = typeof this.state.userInputsForList === 'undefined' ? { ...this.state.userInputs } : { ...this.state.userInputsForList };
                        const questionWorkflowProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(workflowProcessState));
                        isHidden = questionPiece.isHiddenPiece ? !!getWorkflowQuestionValidationValue(this.props.applicationState, questionWorkflowProcessState, this.props.workflow.id, questionPiece.id, this.state.userInputs[questionPiece.id], allAnswers, questionPiece.isHiddenPiece) : undefined;
                    };

                    if (isHidden) {
                        displayMarkup = undefined;
                    } else {
                        displayMarkup = <div key={workflowProcessState.displayingQuestionPieceId}>
                            <Question
                                workflowId={workflowId}
                                questionId={workflowProcessState.displayingQuestionPieceId}
                                userInput={this.state.userInputs[questionPiece.customFieldId]}
                                isExpanded={this.state.expandedInputs[questionPiece.customFieldId]}
                                errorMessage={this.state.errorMessages[workflowProcessState.displayingQuestionPieceId]}
                                validateAnswer={this.validateAnswer.bind(this, [workflowProcessState.displayingQuestionPieceId])}
                                onInputChange={this.updateUserInput.bind(this, questionPiece.customFieldId)}
                                onExpandToggle={this.toggleInputExpansion.bind(this, questionPiece.customFieldId)}
                            />
                        </div>;
                    }

                    submitButton = <Button isDisabled={this.state.isButtonClicked} text={translatePhrase('Continue')} isRounded={true} isBlock={true} padding={'0px 20px'} color={'contrast'} onClick={this.submitAnswer.bind(this, workflowId, workflowProcessState.displayingQuestionPieceId)} />;

                } else {
                    submitButton = undefined;
                    throw new Error('The question piece ID should not be undefined');
                }
                break;

            case 'choose':
                if (workflowProcessState.displayingQuestionPieceId) {

                    const choosePiece = this.props.piecesData.byId[workflowProcessState.displayingQuestionPieceId];

                    if (choosePiece.type !== PieceType.CHOOSE) {
                        throw new Error('The piece must be a question piece');
                    }

                    if (!choosePiece.variablePiece) {
                        throw new Error('The question piece must have a choices list');
                    }

                    if (!choosePiece.choiceVariable) {
                        throw new Error('The question piece must have a choice variable');
                    };

                    let isHidden: boolean | undefined = false;

                    if (choosePiece.isHiddenPiece) {
                        const allAnswers = typeof this.state.userInputsForList === 'undefined' ? { ...this.state.userInputs } : { ...this.state.userInputsForList };
                        const questionWorkflowProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(workflowProcessState));
                        isHidden = choosePiece.isHiddenPiece ? !!getWorkflowQuestionValidationValue(this.props.applicationState, questionWorkflowProcessState, this.props.workflow.id, choosePiece.id, this.state.userInputs[choosePiece.id], allAnswers, choosePiece.isHiddenPiece) : undefined;
                    };

                    if (isHidden) {
                        displayMarkup = undefined;
                    } else {
                        displayMarkup = <div key={workflowProcessState.displayingQuestionPieceId}>
                            <Choose
                                workflowId={workflowId}
                                questionId={workflowProcessState.displayingQuestionPieceId}
                                userInput={this.state.choiceInputs[choosePiece.id]}
                                errorMessage={this.state.choiceErrorMessages[workflowProcessState.displayingQuestionPieceId]}
                                validateAnswer={this.validateChoice.bind(this, [workflowProcessState.displayingQuestionPieceId])}
                                onInputChange={this.updateUserInputForChoice.bind(this, choosePiece.id)}
                                choiceInputs={this.state.choiceInputs}
                            />
                        </div>;
                    }

                    submitButton = <Button isDisabled={this.state.isButtonClicked} text={translatePhrase('Continue')} isRounded={true} isBlock={true} padding={'0px 20px'} color={'contrast'} onClick={this.submitChoice.bind(this, workflowId, workflowProcessState.displayingQuestionPieceId)} />;

                } else {
                    submitButton = undefined;
                    throw new Error('The question piece ID should not be undefined');
                }
                break;
            case 'transfer':
                submitButton = undefined;
                if (workflowProcessState.displayingTransferPieceId) {
                    displayMarkup = <Transfer workflowId={workflowId} transferId={workflowProcessState.displayingTransferPieceId} />
                } else {
                    throw new Error('The transfer piece ID should not be undefined');
                }
                break;
            case 'show':
                if (workflowProcessState.displayingShowPieceId) {
                    const showPiece = this.props.piecesData.byId[workflowProcessState.displayingShowPieceId];

                    if (showPiece.type !== PieceType.SHOW && showPiece.type !== PieceType.GROUPED_SHOW) {
                        throw new Error('This piece must be a show piece');
                    }

                    let isHidden: boolean | undefined = false;

                    if (showPiece.isHiddenPiece) {
                        const allAnswers = typeof this.state.userInputsForList === 'undefined' ? { ...this.state.userInputs } : { ...this.state.userInputsForList };
                        const hiddenWorkflowProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(workflowProcessState));
                        isHidden = showPiece.isHiddenPiece ? !!getWorkflowQuestionValidationValue(this.props.applicationState, hiddenWorkflowProcessState, this.props.workflow.id, showPiece.id, this.state.userInputs[showPiece.id], allAnswers, showPiece.isHiddenPiece) : undefined;
                    };

                    if (isHidden) {
                        console.log('Show Piece is Hidden');
                        displayMarkup = undefined;
                    } else {
                        displayMarkup = <div className={styles.showDataContainer}>
                            <Show userInputs={this.state.userInputs} listUserInputs={this.state.userInputsForList} workflowId={workflowId} showPieceId={showPiece.id} />
                        </div>;
                    };
                    submitButton = <Button isDisabled={this.state.isButtonClicked} text={translatePhrase('Continue')} isRounded={true} isBlock={true} padding={'0px 20px'} color={'contrast'} onClick={this.continueAfterDisplay} />;

                } else {
                    submitButton = undefined;
                    throw new Error('The show piece ID should not be undefined');
                }
                break;
            case 'group':
                if (workflowProcessState.displayingGroupPieceId) {
                    const groupPiece = this.props.piecesData.byId[workflowProcessState.displayingGroupPieceId];
                    const { questionIds, chooseIds } = this.getAllQuestionsInGroup(workflowProcessState.displayingGroupPieceId);
                    const groupHeadingWorkflowProcessState = this.getWorkflowProcessState();

                    if (groupPiece.type !== PieceType.GROUP && groupPiece.type !== PieceType.GROUP_FOR_LIST) {
                        throw new Error('The piece must be a Group or Group For piece');
                    }

                    const groupHeading = groupPiece.heading && isUUID(groupPiece.heading) ? getWorkflowPieceValue(this.props.applicationState, groupHeadingWorkflowProcessState, workflowId, groupPiece.heading) : groupPiece.heading;

                    if (groupPiece.type === PieceType.GROUP) {

                        displayMarkup = <div>
                            <section key={workflowProcessState.displayingGroupPieceId} className={styles.groupHolder}>
                                {groupHeading && <h3 className={styles.groupHeading}>
                                    <span>{typeof groupHeading === 'string' ? translatePhrase(groupHeading) : ''}</span>
                                </h3>}
                                <Group
                                    key={workflowProcessState.displayingGroupPieceId}
                                    answerKey={this.state.answerKey}
                                    workflowId={workflowId}
                                    groupPieceId={groupPiece.id}
                                    inputExpansions={this.state.expandedInputs}
                                    expandInput={this.toggleInputExpansion}
                                    userInputs={this.state.userInputs}
                                    userInputsForChoice={this.state.choiceInputs}
                                    validateAnswer={this.validateAnswer.bind(this, questionIds)}
                                    validateChoice={this.validateChoice.bind(this, questionIds)}
                                    errorMessages={this.state.errorMessages}
                                    errorMessagesForChoice={this.state.choiceErrorMessages}
                                    updateUserInput={this.updateUserInput}
                                    updateUserInputForChoice={this.updateUserInputForChoice}
                                />
                            </section>
                        </div>;

                        submitButton = <Button isDisabled={this.state.isButtonClicked} text={translatePhrase('Continue')} isRounded={true} isBlock={true} padding={'0px 20px'} color={'contrast'} onClick={this.submitGroup.bind(this, workflowId, workflowProcessState.displayingGroupPieceId, questionIds, chooseIds)} />;
                    } else if (groupPiece.type === PieceType.GROUP_FOR_LIST) {

                        if (!groupPiece.iterableVariable) {
                            throw new Error('This piece must have an iterable variable');
                        }

                        if (!groupPiece.loopVariable) {
                            throw new Error('This piece must have a loop variable');
                        }

                        const loopVariable = groupPiece.loopVariable;

                        let iterableValue = getWorkflowPieceValue(this.props.applicationState, workflowProcessState, workflowId, groupPiece.iterableVariable);

                        if (typeof iterableValue === 'undefined') {
                            iterableValue = [];
                        }

                        if (!Array.isArray(iterableValue)) {
                            throw new Error('The iterable value must be an array');
                        }

                        if (Array.isArray(iterableValue)) {

                            if (iterableValue.length > 0 && Array.isArray(iterableValue[0])) {
                                // Cannot be a multidimensional array
                                throw new Error('The value cannot be a multi-dimensional array')
                            }

                            iterableValue = iterableValue as Array<string>;
                        }

                        const groupElementsForList = iterableValue.map(overwrittenValue => {
                            return <Group
                                key={overwrittenValue}
                                answerKey={this.state.answerKey}
                                workflowId={workflowId}
                                groupPieceId={groupPiece.id}
                                inputExpansions={this.state.expandedInputsForList[overwrittenValue] || {}}
                                expandInput={this.toggleInputExpansionForList.bind(this, overwrittenValue)}
                                listUserInputs={this.state.userInputsForList}
                                userInputs={this.state.userInputsForList[overwrittenValue] || {}}
                                validateAnswer={this.validateAnswer.bind(this, questionIds)}
                                errorMessages={this.state.errorMessagesForList[overwrittenValue] || {}}
                                updateUserInput={this.updateUserInputForList.bind(this, overwrittenValue)}
                                overWrittenVariable={loopVariable}
                                overWrittenValue={overwrittenValue}
                            />
                        });

                        displayMarkup = <div>
                            <section key={workflowProcessState.displayingGroupPieceId} className={styles.groupHolder}>
                                {groupHeading && <h3 className={styles.groupHeading}>
                                    <span>{typeof groupHeading === 'string' ? translatePhrase(groupHeading) : ''}</span>
                                </h3>}
                                {groupElementsForList}
                            </section>
                        </div>;

                        submitButton = <Button isDisabled={this.state.isButtonClicked} text={translatePhrase('Continue')} isRounded={true} isBlock={true} padding={'0px 20px'} color={'contrast'} onClick={this.submitGroup.bind(this, workflowId, workflowProcessState.displayingGroupPieceId, questionIds, chooseIds)} />;
                    }

                } else {
                    submitButton = undefined;
                    throw new Error('The group piece ID should not be undefined');
                }
                break;

            case 'finsal-loan-process':
                const finsalLoanProcessPiece = workflowProcessState.displayingFinsalLoanProcessPieceId ? this.props.piecesData.byId[workflowProcessState.displayingFinsalLoanProcessPieceId] : undefined;

                if (!finsalLoanProcessPiece || finsalLoanProcessPiece.type !== PieceType.FINSAL_LOAN_PROCESS) {
                    throw new Error('Could not find Finsal piece');
                }

                if (!finsalLoanProcessPiece.response) {
                    throw new Error('The return response should be selected');
                }

                const finsalResponse = workflowProcessState.customFields[finsalLoanProcessPiece.response];

                if (typeof finsalResponse !== 'string') {
                    submitButton = undefined;
                    displayMarkup = <section className={styles.finsalDataContainer}>
                        <Button
                            text={translatePhrase('Start loan application process')}
                            type="primary"
                            size="medium"
                            isRounded
                            onClick={this.makeFinsalCall}
                        />
                    </section>

                    break;
                }

                if (!finsalResponse.startsWith('http')) {
                    submitButton = undefined;
                    displayMarkup = <section className={styles.finsalDataContainer}>
                        <div>Error: {finsalResponse}</div>
                        <Button
                            text="Retry Finsall call"
                            type="primary"
                            size="medium"
                            isRounded
                            onClick={this.makeFinsalCall}
                        />
                    </section>

                    break;
                }


                submitButton = <Button isDisabled={this.state.isButtonClicked} text={translatePhrase('Continue')} isRounded={true} isBlock={true} padding={'0px 20px'} color={'contrast'} onClick={this.continueAfterFinsal} />;

                displayMarkup = <section className={styles.finsalDataContainer}>
                    <section className={styles.finsalHolder}>
                        <iframe
                            ref={this.finsalIframeRef}
                            src={finsalResponse}
                        >
                        </iframe>
                    </section>
                    <Button
                        text="Move to browser"
                        type="secondary"
                        size="medium"
                        isRounded
                        onClick={() => this.moveToBrowser(finsalResponse)}
                    />
                </section>;
                break;
            case 'switch':
                const switchingWorkflow = this.props.applicationState.workflows.byId[workflowProcessState.createdWorkflowId || ''];
                const switchingWorkflowSubtitle = this.getNextWorkflowSubtitleDetails(switchingWorkflow.id);
                const switchingWorkflowType = this.props.applicationState.workflows.types.byId[switchingWorkflow.type];
                const switchingWorkflowStatus = this.props.applicationState.workflows.types.statuses.byId[switchingWorkflow.status];

                displayMarkup = <div>
                    <div className={styles.showDataContainer} key={workflowId}> <div className={styles.iconHolder}> <ReadIcon /> </div><section> {translatePhrase('You will be switching to')}</section></div>
                    <div className={styles.nextWorkflowContainer} key={workflowId}>
                        <section className={styles.nextWorkflowTypeName}> {switchingWorkflowType.name} </section>
                        {!!switchingWorkflowSubtitle && <section className={styles.nextWorkflowDetails}> {switchingWorkflowSubtitle} </section>}
                        <section className={styles.nextWorkflowMeta}>
                            <span className={styles.metaValue}>{switchingWorkflowStatus.name}</span>
                            <span className={styles.separator}> | </span>
                            <span className={styles.metaValue}>{moment(switchingWorkflow.dueDate).format('DD MMM YYYY')}</span>
                        </section>
                    </div>
                </div>;

                submitButton = <Button isDisabled={this.state.isButtonClicked} text={translatePhrase('Continue') + ' ' + switchingWorkflowType.name} isRounded={true} isBlock={true} padding={'0px 20px'} color={'contrast'} onClick={() => this.switchToNewWorkflow(workflowProcessState.createdWorkflowId)} />;

                break;

            case 'end':

                let endWorkflowText = translatePhrase('You have completed this workflow');
                let endWorkflowMessage = '';
                const endPieceId = workflowProcessState.lastComputedPiece;

                if (typeof endPieceId !== 'undefined') {
                    const endPiece = this.props.piecesData.byId[endPieceId];
                    try {
                        switch (endPiece.type) {
                            case PieceType.END:
                                if (endPiece.message) {
                                    const message = endPiece.message;
                                    if (isUUID(message)) {
                                        const messageVariableValue = getWorkflowPieceValue(this.props.applicationState, workflowProcessState, workflowId, message);

                                        if (typeof messageVariableValue === 'string') {
                                            endWorkflowText = messageVariableValue;
                                            endWorkflowMessage = messageVariableValue;
                                        }
                                    } else {
                                        endWorkflowText = message;
                                        endWorkflowMessage = message;
                                    }
                                }
                                break;
                            default: break;
                        }
                    } catch {

                    }

                    if (endPiece.type === PieceType.END && endPiece.workflow) {
                        // fetching variable details
                        const nextPieceProcessState = this.getWorkflowProcessState();

                        const endPieceWorkflow = nextPieceProcessState.variables[endPiece.workflow];

                        if (typeof endPieceWorkflow === 'string') {
                            nextWorkflowId = endPieceWorkflow;
                            const nextWorkflow = this.props.applicationState.workflows.byId[nextWorkflowId];
                            const nextWorkflowSubtitle = this.getNextWorkflowSubtitleDetails(nextWorkflow.id);
                            const nextWorkflowType = this.props.applicationState.workflows.types.byId[nextWorkflow.type];
                            const nextWorkflowStatus = this.props.applicationState.workflows.types.statuses.byId[nextWorkflow.status];
                            // Display markup for continue piece

                            displayMarkup = <div>
                                {endWorkflowMessage && <div className={styles.showDataContainer} key={workflowId + '-end-message'}>
                                    <div className={styles.iconHolder}> <ReadIcon /> </div>
                                    <section> {translatePhrase(endWorkflowMessage)}</section>
                                </div>}
                                <div className={styles.showDataContainer} key={workflowId}>
                                    <div className={styles.iconHolder}> <ReadIcon /> </div>
                                    <section> {translatePhrase('You have completed this workflow')}. {translatePhrase('Click the button below to continue to the next workflow')}</section>
                                </div>
                                <div className={styles.nextWorkflowContainer} key={workflowId}>
                                    <section className={styles.nextWorkflowTypeName}> {nextWorkflowType.name} </section>
                                    {!!nextWorkflowSubtitle && <section className={styles.nextWorkflowDetails}> {nextWorkflowSubtitle} </section>}
                                    <section className={styles.nextWorkflowMeta}>
                                        <span className={styles.metaValue}>{nextWorkflowStatus.name}</span>
                                        <span className={styles.separator}> | </span>
                                        <span className={styles.metaValue}>{moment(nextWorkflow.dueDate).format('DD MMM YYYY')}</span>
                                    </section>
                                </div>
                            </div>;

                            submitButton = <span style={{ marginLeft: 'auto' }}>
                                <Button type={'primary'} color={'contrast'} text={translatePhrase('Continue') + ' ' + nextWorkflowType.name} onClick={() => this.switchToNewWorkflow(endPieceWorkflow)} padding={'0px 20px'} isRounded />
                            </span>

                            break;
                        }
                    }
                }

                if (typeof nextWorkflowId === 'undefined' && this.props.workflow.triggeringWorkflow) {
                    let triggeringWorkflow = this.props.workflowData.byId[this.props.workflow.triggeringWorkflow];
                    const triggeringWorkflowSubtitle = this.getNextWorkflowSubtitleDetails(triggeringWorkflow.id);
                    const triggeringWorkflowType = this.props.applicationState.workflows.types.byId[triggeringWorkflow.type];

                    while (triggeringWorkflow) {
                        const triggeringWorkflowStatus = this.props.workflowData.types.statuses.byId[triggeringWorkflow.status];

                        if (!triggeringWorkflowStatus.isTerminal && (triggeringWorkflow.user === this.props.myId || this.props.myId === 'SuperUser')) {

                            displayMarkup = <div>
                                {endWorkflowMessage && <div className={styles.showDataContainer} key={workflowId + '-end-message'}>
                                    <div className={styles.iconHolder}> <ReadIcon /> </div>
                                    <section> {translatePhrase(endWorkflowMessage)}</section>
                                </div>}
                                <div className={styles.showDataContainer} key={workflowId}>
                                    <div className={styles.iconHolder}> <ReadIcon /> </div>
                                    <section> {translatePhrase('You have completed this workflow')}. {translatePhrase('Click the button below to resume the triggering workflow')}</section>
                                </div>
                                <div className={styles.nextWorkflowContainer} key={workflowId}>
                                    <section className={styles.nextWorkflowTypeName}> {triggeringWorkflowType.name} </section>
                                    {!!triggeringWorkflowSubtitle && <section className={styles.nextWorkflowDetails}> {triggeringWorkflowSubtitle} </section>}
                                    <section className={styles.nextWorkflowMeta}>
                                        <span className={styles.metaValue}>{triggeringWorkflowStatus.name}</span>
                                        <span className={styles.separator}> | </span>
                                        <span className={styles.metaValue}>{moment(triggeringWorkflow.dueDate).format('DD MMM YYYY')}</span>
                                    </section>
                                </div>
                            </div>;

                            submitButton = <span style={{ marginLeft: 'auto' }}>
                                <Button type={'primary'} color={'contrast'} text={translatePhrase('Resume') + ' ' + triggeringWorkflowType.name} onClick={() => this.props.history.push(`/workflow/${triggeringWorkflow.id}/execute`)} padding={'0px 20px'} isRounded />
                            </span>;
                            break displaySwitch;
                        }

                        if (!triggeringWorkflow.triggeringWorkflow) {
                            break;
                        }

                        triggeringWorkflow = this.props.workflowData.byId[triggeringWorkflow.triggeringWorkflow];
                    }
                }

                displayMarkup = <div>
                    {endWorkflowMessage && <div className={styles.showDataContainer} key={workflowId + '-end-message'}>
                        <div className={styles.iconHolder}> <ReadIcon /> </div>
                        <section> {translatePhrase(endWorkflowMessage)}</section>
                    </div>}
                    <div className={styles.showDataContainer} key={workflowId}>
                        <div className={styles.iconHolder}> <ReadIcon /> </div>
                        <section> {translatePhrase('You have completed this workflow')}</section>
                    </div>
                </div>;

                submitButton = <span style={{ marginLeft: 'auto' }}> <Button type={'secondary'} color={'contrast'} text={'Go To Workflows'} onClick={() => this.goToWorkflows()} padding={'0px 20px'} isRounded /> </span>;
        }

        const uniqueKeyForStep = workflowProcessState.lastComputedPiece + JSON.stringify(workflowProcessState.forIterationCounts);
        const workflowTypeId = this.props.workflow.type;

        let nonExecutionSteps = 0;

        for (const step of this.props.workflow.history) {
            if (!!step.lastComputedPiece) {
                break;
            }

            nonExecutionSteps += 1;
        }

        return (<section className={styles.FocusSpace} key={uniqueKeyForStep}>
            <header className={styles.pageHeader}>
                <h2 className={styles.heading}> {translatePhrase('Workflow')} / {translatePhrase('Executor')} </h2>

                <Tabs></Tabs>
            </header>

            <div className={styles.innerFocus}>
                <div className={styles.displayContents}>
                    <div className={styles.displayWindow}>
                        <header className={styles.flowHeader}>
                            <h2 className={styles.heading}>
                                {heading.heading}
                                {heading.subTitle !== '-' && <div className={styles.status}>
                                    {heading.subTitle}
                                </div>}
                                <div className={styles.status}>
                                    {translatePhrase(workflowStatus.name)} <span className={styles.separator}> | </span> {this.props.workflow.dueDate ? moment(this.props.workflow.dueDate).format('DD MMM YYYY') : '-'}
                                </div>
                            </h2>
                            {this.props.canViewConfiguration && <Button title={translatePhrase("Go to flowchart")} onClick={() => this.goToFlowchart(workflowTypeId)} icon={<StructureIcon />} type={'secondary'} isRounded />}
                            {!isWorkflowComplete && <Button title={translatePhrase("Pause")} onClick={this.goToWorkflows} icon={<ExitIcon />} type={'secondary'} isRounded />}
                        </header>

                        <ProgressBar workflowId={workflowId} />

                        {displayMarkup}
                    </div>

                    <div className={styles.navButtonsHolder}>
                        <Button isBlock={true} padding={'0px 20px'} isRounded={true}
                            color={'primary'} type={'secondary'} text={translatePhrase('Go Back')}
                            onClick={this.props.navigateBack.bind(this, workflowId)}
                            isDisabled={display === 'end' || this.blockBackNavigation()}
                        />

                        {submitButton}
                    </div>
                </div>
                <Timeline shrinkTimeline={!this.state.expandTimeline} isSortActive={this.state.sortedTimeline}
                    toggleShrinkTimeline={() => this.toggleTimeline()} workflowId={workflowId}
                    toggleSortTimeline={() => this.toggleSortTimeline()} />
            </div>

            {this.state.toastModal}
        </section>);

    }
}

const WorkflowProcess = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectedWorkflowProcess) as any);

export default WorkflowProcess;