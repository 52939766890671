import {
    ADD_REPORT, DELETE_REPORT, UPDATE_REPORT, UPDATE_REPORT_DATA, SEARCH_REPORT_TABLE, GO_TO_PAGE_REPORT_TABLE,
    SET_PAGE_SIZE_REPORT_TABLE, SORT_REPORT_TABLE, ReportState, ReportActionTypes, IReport, STORE_DATA_FOR_REPORT, FILTER_REPORT_TABLE, UPDATE_REPORTS_DATA, SYNCHRONIZE_REPORTS_DATA, CLEAR_REPORTS_DELTA, START_REPORT_DATA_GENERATION
} from './types';
import { addEntity, updateEntity, deleteEntity, updateEntries, synchronizeEntries, clearDelta } from '../normalized-model';
import { reportTypesReducer, initialState as reportTypesInitialState } from './types/reducer';
import { reportSchedulesReducer, initialState as reportSchedulesInitialState } from './schedules/reducer';

const initialState: ReportState = {
    byId: {},
    allEntries: [],
    filteredEntries: [],

    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),

    types: reportTypesInitialState,
    schedules: reportSchedulesInitialState,

    pageSize: 10,
    currentPageNumber: 1,

    isGeneratingReport: false,

    filters: {
        projects: [],
        types: [],
        users: [],
        createdDateRange: [],
        lastUpdatedDateRange: [],
        generatedDateRange: [],
        unsynced: false,
        archived: false,
    },
    sort: {
        column: undefined,
        order: 'ASC',
    },
    searchTerm: undefined,
};

export function reportsReducer(state = initialState, action: ReportActionTypes): ReportState {
    state = {
        ...state,
        types: reportTypesReducer(state.types, action),
        schedules: reportSchedulesReducer(state.schedules, action),
    };

    switch (action.type) {

        case ADD_REPORT:
            return addEntity<ReportState, IReport>(state, action.payload);

        case UPDATE_REPORT:
            return updateEntity<ReportState, IReport>(state, action.payload, action.currentTime);

        case DELETE_REPORT:
            return deleteEntity<ReportState, IReport>(state, action.id, action.currentTime);

        case START_REPORT_DATA_GENERATION:
            return {
                ...state,
                isGeneratingReport: true,
            }

        case STORE_DATA_FOR_REPORT:
            return {
                ...state,
                isGeneratingReport: false,
                byId: {
                    ...state.byId,
                    [action.reportId]: {
                        ...state.byId[action.reportId],
                        data: action.data,
                        generatedTime: action.currentTime,
                    },
                },
                updatedIds: new Set([...state.updatedIds, action.reportId]),
            }


        case SEARCH_REPORT_TABLE:
            return {
                ...state,
                searchTerm: action.searchTerm,
            }

        case FILTER_REPORT_TABLE:
            return {
                ...state,
                filters: {
                    projects: action.projects,
                    types: action.types,
                    users: action.users,
                    createdDateRange: action.createdDateRange,
                    lastUpdatedDateRange: action.lastUpdatedDateRange,
                    generatedDateRange: action.generatedDateRange,
                    unsynced: action.unsynced,
                    archived: action.archived,
                },
            }

        case GO_TO_PAGE_REPORT_TABLE:
            return {
                ...state,
                currentPageNumber: action.pageNumber,
            }

        case SET_PAGE_SIZE_REPORT_TABLE:
            return {
                ...state,
                pageSize: action.pageSize,
            }

        case SORT_REPORT_TABLE:
            return {
                ...state,
                sort: {
                    column: action.column,
                    order: action.order
                }
            }

        case UPDATE_REPORTS_DATA:
            return updateEntries<ReportState, IReport>(state, action.data);

        case SYNCHRONIZE_REPORTS_DATA:
            return synchronizeEntries<ReportState, IReport>(state, action.data);

        case CLEAR_REPORTS_DELTA:
            return clearDelta<ReportState, IReport>(state);


        // Internationalization actions
        case UPDATE_REPORT_DATA:
            return {
                ...action.data,
                createdIds: state.createdIds,
                updatedIds: state.updatedIds,
                deletedIds: state.deletedIds,

                types: {
                    ...action.data.types,
                    createdIds: state.types.createdIds,
                    updatedIds: state.types.updatedIds,
                    deletedIds: state.types.deletedIds,
                },

                schedules: {
                    ...action.data.schedules,
                    createdIds: state.schedules.createdIds,
                    updatedIds: state.schedules.updatedIds,
                    deletedIds: state.schedules.deletedIds,
                }
            }


        default:
            return state
    }
}