import React, { Component } from 'react';
import styles from './ModifyWidget.module.scss';
import MultiSelectInputText from '../../widgets/form/MultiSelectInput';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { ReactComponent as CloseIcon } from '../../common/assets/close.svg';
import { ReactComponent as SaveIcon } from '../../common/assets/save.svg';
import { ReactComponent as PlusIcon } from '../../common/assets/plus.svg';
import { ReactComponent as ExpandIcon } from '../../common/assets/expand.svg';
import { ReactComponent as CheckIcon } from '../../common/assets/check.svg';

import { ApplicationState } from '../../shared/store/types';

import { RouteComponentProps, withRouter } from "react-router";
import InputText from '../../widgets/form/InputText';
import { IUpdateableWidgetData, IWidget } from '../../shared/store/widgets/types';
import uuid from 'uuid';
import { Option } from '../../components/flowchart/drop-down/ListItem';
import Checkbox from '../../widgets/form/Checkbox';
import { addWidget, updateWidget } from '../../shared/store/widgets/actions';
import { translatePhrase } from '../../shared/helpers/translation';
import WidgetFlowchart from './WidgetFlowchart';
import WidgetRichTextFlowchart from './WidgetRichTextFlowchart';
import { IReturnRichTextPiece, PieceType } from '../../shared/store/flowchart/pieces/types';
import { hideRichTextEditor, setReturnValue } from '../../shared/store/flowchart/pieces/actions';
import RichTextModal from '../workflows/flowchart/RichTextModal';
import LoaderModal from '../../widgets/loader/LoaderModal';
import Button from '../../widgets/button/CommonButton';
import { isUUID } from '../../shared/helpers/utilities';

type OwnProps = {
    modifyingWidgetId: string,
    closeWidgetModal: () => void;
};

const mapStateToProps = (state: ApplicationState) => {

    const isShowingRichTextEditor = state.flowchart.pieces.isShowingRichTextEditor;
    let returnPieceData: IReturnRichTextPiece | undefined;

    if (isShowingRichTextEditor && state.flowchart.pieces.lastDraggedPiece) {
        const lastDraggedPiece = state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece];

        if (lastDraggedPiece.type === PieceType.RETURN_RICH_TEXT) {
            returnPieceData = lastDraggedPiece;
        }
    }

    return {
        projectsData: state.structure.projects,
        levelsData: state.structure.levels,
        rolesData: state.structure.roles,
        userData: state.users,
        memberData: state.members,
        groupData: state.groups,
        workflowData: state.workflows,
        widgetsData: state.widgets,
        myId: state.myData.id,

        isShowingRichTextEditor,
        returnPieceData,
        isFlowchartExpanded: state.myData.isFlowchartExpanded
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        addWidget: (widgetData: IUpdateableWidgetData) => dispatch(addWidget(widgetData)),
        updateWidget: (widgetData: IUpdateableWidgetData) => dispatch(updateWidget(widgetData)),

        hideRichTextEditor: () => dispatch(hideRichTextEditor()),
        setReturnValue: (targetPieceId: string, draggedPieceId: string) => dispatch(setReturnValue(targetPieceId, draggedPieceId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps;

type OwnState = {
    widgetData: IUpdateableWidgetData,
    submitTimer: number | undefined,
    errorMessage: string,
    isFlowChartExpanded: boolean,
    fullScreenFlowChart: boolean,
}

class ConnectedModifyWidget extends Component<Props, OwnState> {
    widgetName!: string;

    constructor(props: Readonly<Props>) {
        super(props);

        const widgetId = props.modifyingWidgetId;
        const widget = props.widgetsData.byId[widgetId];
        this.widgetName = widget && widget.name ? widget.name : '';

        let widgetData: IUpdateableWidgetData;
        if (!widget) {
            // This is a new workflow
            widgetData = {
                id: uuid.v4(),
                name: '',
                type: '',
                roles: [],
                systemFields: [],
                customFields: [],
                exportingCustomFields: [],
                displayType: 'table',
                aggregation: 'none',
                creatingUser: this.props.myId,
                seedEntityVariable: uuid.v4(),
            };
        } else {
            widgetData = {
                id: widget.id,
                name: widget.name,
                type: widget.type,
                typeId: widget.typeId,
                roles: widget.roles,
                systemFields: widget.systemFields ? widget.systemFields : [],
                customFields: widget.customFields,
                exportingSystemFields: widget.exportingSystemFields,
                exportingCustomFields: widget.exportingCustomFields,
                displayType: widget.displayType,
                aggregation: widget.aggregation,
                creatingUser: widget.creatingUser,
                seedEntityVariable: widget.seedEntityVariable,
                seedUsersVariable: widget.seedUsersVariable,
                seedMembersVariable: widget.seedMembersVariable,
                seedGroupsVariable: widget.seedGroupsVariable,
                seedWorkflowsVariable: widget.seedWorkflowsVariable,
            }
        }

        this.state = {
            widgetData: widgetData,
            submitTimer: undefined,
            errorMessage: '',
            isFlowChartExpanded: false,
            fullScreenFlowChart: false,
        };
    }

    static getDerivedStateFromProps(props: Readonly<Props>, state: Readonly<OwnState>) {
        if (!state.widgetData.name) {
            const widgetId = props.modifyingWidgetId;
            const widget = props.widgetsData.byId[widgetId];

            if (widget) {
                return {
                    widgetData: {
                        id: widget.id,
                        name: widget.name,
                        type: widget.type,
                        typeId: widget.typeId,
                        roles: widget.roles,
                        customFields: widget.customFields,
                        displayType: widget.displayType,
                    },
                };
            }
        }

        return null;
    }


    toggleSelectAllForShow = (systemFieldOptions: Array<Option>, customFieldOptions: Array<Option>, areAllFieldsSelected: boolean) => {

        let systemFieldsList: Array<string> = [],
            customFieldsList: Array<string> = [];

        if (areAllFieldsSelected) {
            let updatedIUpdateableWidgetData: IUpdateableWidgetData = {
                ...this.state.widgetData,
                systemFields: systemFieldsList,
                customFields: customFieldsList,
            };

            this.setState({
                widgetData: updatedIUpdateableWidgetData,
            });

        } else {
            systemFieldsList = systemFieldOptions.map(systemFieldOption => systemFieldOption.value);
            customFieldsList = customFieldOptions.map(customFieldOption => customFieldOption.value);

            let updatedIUpdateableWidgetData: IUpdateableWidgetData = {
                ...this.state.widgetData,
                systemFields: systemFieldsList,
                customFields: customFieldsList,
            };

            this.setState({
                widgetData: updatedIUpdateableWidgetData,
            });
        }
    }

    toggleSelectAllForExport = (systemFieldOptions: Array<Option>, customFieldOptions: Array<Option>, areAllFieldsSelected: boolean) => {

        let systemFieldsList: Array<string> = [],
            customFieldsList: Array<string> = [];

        if (areAllFieldsSelected) {
            let updatedIUpdateableWidgetData: IUpdateableWidgetData = {
                ...this.state.widgetData,
                exportingSystemFields: systemFieldsList,
                exportingCustomFields: customFieldsList,
            };

            this.setState({
                widgetData: updatedIUpdateableWidgetData,
            });

        } else {
            systemFieldsList = systemFieldOptions.map(systemFieldOption => systemFieldOption.value);
            customFieldsList = customFieldOptions.map(customFieldOption => customFieldOption.value);

            let updatedIUpdateableWidgetData: IUpdateableWidgetData = {
                ...this.state.widgetData,
                exportingSystemFields: systemFieldsList,
                exportingCustomFields: customFieldsList,
            };

            this.setState({
                widgetData: updatedIUpdateableWidgetData,
            });
        }
    }

    changeName = (value: string) => {
        let updatedIUpdateableWidgetData: IUpdateableWidgetData = {
            ...this.state.widgetData,
            name: value,
        };

        this.setState({
            widgetData: updatedIUpdateableWidgetData,
        });
    }

    changeType = (value: string) => {
        let updatedIUpdateableWidgetData: IUpdateableWidgetData = {
            ...this.state.widgetData,
            type: value,
            typeId: undefined,
            systemFields: [],
            exportingSystemFields: [],
            customFields: [],
            exportingCustomFields: [],
        };

        this.setState({
            widgetData: updatedIUpdateableWidgetData,
        });
    }

    changeTypeId = (value: string) => {
        let updatedIUpdateableWidgetData: IUpdateableWidgetData = {
            ...this.state.widgetData,
            typeId: value,
            customFields: [],
            exportingCustomFields: [],
        };

        this.setState({
            widgetData: updatedIUpdateableWidgetData,
        });
    }

    changeRoles = (value: Array<string>) => {
        let updatedIUpdateableWidgetData: IUpdateableWidgetData = {
            ...this.state.widgetData,
            roles: value,
        };

        this.setState({
            widgetData: updatedIUpdateableWidgetData,
        });
    }

    changeDisplayType = (value: string) => {
        let displayType: 'table' | 'bar' | 'line' | 'donut' | 'message' | 'formatted-table' = 'table';

        if (value === 'bar') {
            displayType = 'bar';
        } else if (value === 'line') {
            displayType = 'line';
        } else if (value === 'donut') {
            displayType = 'donut';
        } else if (value === 'table') {
            displayType = 'table';
        } else if (value === 'message') {
            displayType = 'message';
        } else if (value === 'formatted-table') {
            displayType = 'formatted-table';
        }

        this.setState(prevState => {
            const shouldHaveSeedVariables = displayType === 'message' || displayType === 'formatted-table';

            const seedUsersVariable = shouldHaveSeedVariables ? uuid.v4() : undefined;
            const seedMembersVariable = shouldHaveSeedVariables ? uuid.v4() : undefined;
            const seedGroupsVariable = shouldHaveSeedVariables ? uuid.v4() : undefined;
            const seedWorkflowsVariable = shouldHaveSeedVariables ? uuid.v4() : undefined;

            let updatedIUpdateableWidgetData: IUpdateableWidgetData = {
                ...prevState.widgetData,
                displayType: displayType,
                seedUsersVariable,
                seedMembersVariable,
                seedGroupsVariable,
                seedWorkflowsVariable,
            };

            return {
                widgetData: updatedIUpdateableWidgetData,
            }
        });
    }

    changeAggregation = (value: string) => {
        let aggregationType: 'none' | 'count' | 'sum' | 'average' = 'none';

        if (value === 'none') {
            aggregationType = 'none';
        } else if (value === 'count') {
            aggregationType = 'count';
        } else if (value === 'sum') {
            aggregationType = 'sum';
        } else if (value === 'average') {
            aggregationType = 'average';
        }

        let updatedIUpdateableWidgetData: IUpdateableWidgetData = {
            ...this.state.widgetData,
            aggregation: aggregationType,
        };

        this.setState({
            widgetData: updatedIUpdateableWidgetData,
        });
    }

    updateSelectedSystemFields = (value: string) => {
        let systemFieldsList: Array<string>;

        if (this.state.widgetData.systemFields && this.state.widgetData.systemFields.includes(value)) {
            systemFieldsList = this.state.widgetData.systemFields.filter(customFieldId => customFieldId !== value);
        } else {
            const existingSystemFields = this.state.widgetData.systemFields ? this.state.widgetData.systemFields : []
            systemFieldsList = existingSystemFields.concat([value]);
        }

        let updatedIUpdateableWidgetData: IUpdateableWidgetData = {
            ...this.state.widgetData,
            systemFields: systemFieldsList,
        };

        this.setState({
            widgetData: updatedIUpdateableWidgetData,
        });
    }

    updateSelectedCustomFields = (value: string) => {
        let customFieldsList: Array<string>;

        if (this.state.widgetData.customFields.includes(value)) {
            customFieldsList = this.state.widgetData.customFields.filter(customFieldId => customFieldId !== value);
        } else {
            customFieldsList = this.state.widgetData.customFields.concat([value]);
        }

        let updatedIUpdateableWidgetData: IUpdateableWidgetData = {
            ...this.state.widgetData,
            customFields: customFieldsList,
        };

        this.setState({
            widgetData: updatedIUpdateableWidgetData,
        });
    }

    updateSelectedExportSystemFields = (value: string) => {
        let systemFieldsList: Array<string> = [];

        if (this.state.widgetData.exportingSystemFields) {
            systemFieldsList = this.state.widgetData.exportingSystemFields.slice();
        }

        if (systemFieldsList.includes(value)) {
            systemFieldsList = systemFieldsList.filter(customFieldId => customFieldId !== value);
        } else {
            systemFieldsList = systemFieldsList.concat([value]);
        }

        let updatedIUpdateableWidgetData: IUpdateableWidgetData = {
            ...this.state.widgetData,
            exportingSystemFields: systemFieldsList,
        };

        this.setState({
            widgetData: updatedIUpdateableWidgetData,
        });
    }

    updateSelectedExportCustomFields = (value: string) => {
        let customFieldsList: Array<string> = [];

        if (this.state.widgetData.exportingCustomFields) {
            customFieldsList = this.state.widgetData.exportingCustomFields.slice();
        }

        if (customFieldsList.includes(value)) {
            customFieldsList = customFieldsList.filter(customFieldId => customFieldId !== value);
        } else {
            customFieldsList = customFieldsList.concat([value]);
        }

        let updatedIUpdateableWidgetData: IUpdateableWidgetData = {
            ...this.state.widgetData,
            exportingCustomFields: customFieldsList,
        };

        this.setState({
            widgetData: updatedIUpdateableWidgetData,
        });
    }

    showErrorMessage = (message: string) => {
        let that = this

        this.setState({
            errorMessage: message
        });

        window.setTimeout(() => {
            that.setState({
                errorMessage: ''
            });
        }, 3000);

    }

    preValidateIWidgetsData = () => {
        if (!this.state.widgetData.name) {
            return false;
        }

        if (!this.state.widgetData.type) {
            return false;
        }

        if (!this.state.widgetData.displayType) {
            return false;
        }

        let tableFields = this.state.widgetData.customFields;

        if (this.state.widgetData.systemFields) {
            tableFields = tableFields.concat(this.state.widgetData.systemFields);
        }

        if (this.state.widgetData.aggregation === 'none' && this.state.widgetData.displayType !== 'message' && this.state.widgetData.displayType !== 'formatted-table' && tableFields.length === 0) {
            return false;
        }

        if (tableFields.length > 2 && this.state.widgetData.displayType !== 'table') {
            return false;
        }

        if (tableFields.length > 1 && (this.state.widgetData.displayType !== 'table' && this.state.widgetData.displayType !== 'bar')) {
            return false;
        }

        const isGlobalAggregation = tableFields.length === 1;
        const isLocalAggregation = this.state.widgetData.displayType === 'bar' && tableFields.length === 2;

        if (this.state.widgetData.aggregation !== 'none' && !(isGlobalAggregation || isLocalAggregation)) {
            return false;
        }

        return true
    }

    validateIWidgetsData = () => {

        if (!this.state.widgetData.name) {
            this.showErrorMessage('Select a valid name');
            return;
        }

        if (!this.state.widgetData.type) {
            this.showErrorMessage('Select a valid entity');
            return;
        }

        if (!this.state.widgetData.displayType) {
            this.showErrorMessage('Select a valid chart type');
            return;
        }

        let tableFields = this.state.widgetData.customFields;

        if (this.state.widgetData.systemFields) {
            tableFields = tableFields.concat(this.state.widgetData.systemFields);
        }

        if (this.state.widgetData.aggregation === 'none' && this.state.widgetData.displayType !== 'message' && this.state.widgetData.displayType !== 'formatted-table' && tableFields.length === 0) {
            this.showErrorMessage('Select at least one custom field or system field');
            return;
        }

        if (tableFields.length > 1 && (this.state.widgetData.displayType !== 'table' && this.state.widgetData.displayType !== 'bar')) {
            this.showErrorMessage('If you are selecting more than one field, you can only have tabular data');
            return;
        }

        const isGlobalAggregation = tableFields.length === 1;
        const isLocalAggregation = this.state.widgetData.displayType === 'bar' && tableFields.length === 2;

        if (this.state.widgetData.aggregation !== 'none' && !(isGlobalAggregation || isLocalAggregation)) {
            this.showErrorMessage('If you are selecting more than one field, you can not have any aggregations');
            return;
        }

        return true
    }

    submitWidgetForm = () => {
        if (this.validateIWidgetsData()) {
            this.markForSubmit();
        }
    }

    markForSubmit = () => {
        let that = this;
        const widgetId = this.props.modifyingWidgetId;

        const timeout = window.setTimeout(function () {
            if (widgetId) {
                that.props.updateWidget(that.state.widgetData);
            } else {
                that.props.addWidget(that.state.widgetData);
            }
        }, 1000);

        this.setState({
            submitTimer: timeout
        });

        this.props.closeWidgetModal();
    }

    updateRichTextValue = (stringifiedValue: string) => {
        if (this.props.returnPieceData) {
            this.props.setReturnValue(this.props.returnPieceData.id, stringifiedValue);
        }
    }

    render() {
        const entityOptions = ['User', 'Member', 'Group', 'Workflow'];
        let chartTypeOptions = [{
            name: 'Table',
            value: 'table',
        }, {
            name: 'Bar',
            value: 'bar',
        }, {
            name: 'Line',
            value: 'line',
        }, {
            name: 'Donut',
            value: 'donut',
        }, {
            name: 'Message',
            value: 'message',
        }, {
            name: 'Formatted Table',
            value: 'formatted-table',
        }];

        const aggregationOptions = [{
            name: 'None',
            value: 'none',
        }, {
            name: 'Count',
            value: 'count',
        }, {
            name: 'Sum',
            value: 'sum',
        }, {
            name: 'Average',
            value: 'average',
        }];

        const widgetId = this.props.modifyingWidgetId;
        const widget = this.props.widgetsData.byId[widgetId];
        let projectId = '';

        let entityTypeOptions: Array<Option> = [];
        let systemFieldOptions: Array<Option> = [{
            name: 'Created',
            value: 'created',
        }, {
            name: 'Last updated',
            value: 'last_updated',
        }];
        let customFieldOptions: Array<Option> = [];

        if (this.state.widgetData.type) {
            switch (this.state.widgetData.type) {
                case 'User':
                    entityTypeOptions = this.props.rolesData.allEntries.map(roleId => {
                        return {
                            name: this.props.rolesData.byId[roleId].name,
                            value: roleId,
                        };
                    });

                    systemFieldOptions = systemFieldOptions.concat([{
                        name: 'Projects',
                        value: 'projects',
                    }, {
                        name: 'Levels',
                        value: 'levels',
                    }, {
                        name: 'Roles',
                        value: 'roles',
                    }, {
                        name: 'Locations',
                        value: 'locations',
                    }]);

                    if (this.state.widgetData.typeId) {
                        const role = this.props.rolesData.byId[this.state.widgetData.typeId];
                        const level = this.props.levelsData.byId[role.level];
                        projectId = level.project;

                        const userCustomFields = this.props.userData.customFields.allFields.map(customFieldId => {
                            return {
                                name: this.props.userData.customFields.byId[customFieldId].name,
                                value: customFieldId,
                            };
                        });

                        const roleCustomFields = role.customFields.map(customFieldId => {
                            return {
                                name: this.props.rolesData.customFields.byId[customFieldId].name,
                                value: customFieldId,
                            };
                        });

                        customFieldOptions = userCustomFields.concat(roleCustomFields);
                    } else {
                        systemFieldOptions = systemFieldOptions.concat([{
                            name: 'Name',
                            value: 'name',
                        }, {
                            name: 'Subtitle',
                            value: 'subtitle',
                        }]);
                    }
                    break;

                case 'Member':
                    entityTypeOptions = this.props.memberData.types.allEntries.map(typeId => {
                        return {
                            name: this.props.memberData.types.byId[typeId].name,
                            value: typeId,
                        };
                    });

                    systemFieldOptions = systemFieldOptions.concat([{
                        name: 'Location',
                        value: 'location',
                    }]);

                    if (this.state.widgetData.typeId) {
                        const memberType = this.props.memberData.types.byId[this.state.widgetData.typeId];
                        projectId = memberType.project;

                        customFieldOptions = memberType.customFields.map(customFieldId => {
                            return {
                                name: this.props.memberData.types.customFields.byId[customFieldId].name,
                                value: customFieldId,
                            };
                        });
                    } else {
                        systemFieldOptions = systemFieldOptions.concat([{
                            name: 'Name',
                            value: 'name',
                        }, {
                            name: 'Subtitle',
                            value: 'subtitle',
                        }, {
                            name: 'Last seen',
                            value: 'last_seen',
                        }]);
                    }
                    break;

                case 'Group':
                    entityTypeOptions = this.props.groupData.types.allEntries.map(typeId => {
                        return {
                            name: this.props.groupData.types.byId[typeId].name,
                            value: typeId,
                        };
                    });

                    systemFieldOptions = systemFieldOptions.concat([{
                        name: 'Location',
                        value: 'location',
                    }]);

                    if (this.state.widgetData.typeId) {
                        const groupType = this.props.groupData.types.byId[this.state.widgetData.typeId];
                        projectId = groupType.project;

                        customFieldOptions = groupType.customFields.map(customFieldId => {
                            return {
                                name: this.props.groupData.types.customFields.byId[customFieldId].name,
                                value: customFieldId,
                            };
                        });
                    } else {
                        systemFieldOptions = systemFieldOptions.concat([{
                            name: 'Name',
                            value: 'name',
                        }, {
                            name: 'Subtitle',
                            value: 'subtitle',
                        }]);
                    }
                    break;

                case 'Workflow':
                    entityTypeOptions = this.props.workflowData.types.allEntries.map(typeId => {
                        return {
                            name: this.props.workflowData.types.byId[typeId].name,
                            value: typeId,
                        };
                    });

                    systemFieldOptions = systemFieldOptions.concat([{
                        name: 'Last worked on',
                        value: 'last_worked_on',
                    }, {
                        name: 'Type',
                        value: 'type',
                    }, {
                        name: 'Subtitle',
                        value: 'subtitle',
                    }, {
                        name: 'Status',
                        value: 'status',
                    }, {
                        name: 'Is Terminal',
                        value: 'is_terminal',
                    }, {
                        name: 'Due date',
                        value: 'due_date',
                    }, {
                        name: 'User',
                        value: 'user',
                    }, {
                        name: 'Location',
                        value: 'location',
                    }, {
                        name: 'Member/Group',
                        value: 'affiliation',
                    }]);

                    if (this.state.widgetData.typeId) {
                        const workflowType = this.props.workflowData.types.byId[this.state.widgetData.typeId];
                        projectId = workflowType.project;

                        customFieldOptions = workflowType.customFields.map(customFieldId => {
                            return {
                                name: this.props.workflowData.types.customFields.byId[customFieldId].name,
                                value: customFieldId,
                            };
                        });

                        if (isUUID(workflowType.affiliatedEntity)) {
                            if (workflowType.affiliation === 'member') {
                                const memberType = this.props.memberData.types.byId[workflowType.affiliatedEntity];

                                if (memberType) {
                                    const memberCustomFieldOptions = memberType.customFields.map(customFieldId => {
                                        return {
                                            name: 'Member: ' + this.props.memberData.types.customFields.byId[customFieldId].name,
                                            value: customFieldId,
                                        };
                                    });

                                    customFieldOptions = customFieldOptions.concat(memberCustomFieldOptions);
                                }
                            } else if (workflowType.affiliation === 'group') {
                                const groupType = this.props.groupData.types.byId[workflowType.affiliatedEntity];

                                if (groupType) {
                                    const groupCustomFieldOptions = groupType.customFields.map(customFieldId => {
                                        return {
                                            name: 'Group: ' + this.props.groupData.types.customFields.byId[customFieldId].name,
                                            value: customFieldId,
                                        };
                                    });

                                    customFieldOptions = customFieldOptions.concat(groupCustomFieldOptions);
                                }
                            }
                        }
                    }
                    break;

                default:
                    throw new Error('Unknown widget type');
            }
        }

        const rolesList = this.props.rolesData.allEntries
            .filter(roleId => {
                if (!projectId) {
                    return true;
                }

                const role = this.props.rolesData.byId[roleId];
                const level = this.props.levelsData.byId[role.level];

                return level.project === projectId;
            })
            .map(roleId => {

                const role = this.props.rolesData.byId[roleId];
                const level = this.props.levelsData.byId[role.level];
                const project = this.props.projectsData.byId[level.project];

                return {
                    name: `${translatePhrase(role.name)} (${translatePhrase(project.name)} > ${translatePhrase(level.name)})`,
                    value: roleId,
                };
            });

        const selectedEntityType = widget ? entityTypeOptions.find(entityType => entityType.value === widget.typeId) : undefined;

        let textToShowInRichTextModal = '';

        if (this.props.returnPieceData) {
            if (this.props.returnPieceData.returnValue) {
                textToShowInRichTextModal = this.props.returnPieceData.returnValue;
            }
        }

        const totalNumberOfFields = systemFieldOptions.length + customFieldOptions.length;
        let totalNumberOfSelectedFieldsForShow = this.state.widgetData.customFields.length;

        if (this.state.widgetData.systemFields) {
            totalNumberOfSelectedFieldsForShow += this.state.widgetData.systemFields.length;
        }

        let totalNumberOfSelectedFieldsForExport = 0;

        if (this.state.widgetData.exportingSystemFields) {
            totalNumberOfSelectedFieldsForExport += this.state.widgetData.exportingSystemFields.length;
        }

        if (this.state.widgetData.exportingCustomFields) {
            totalNumberOfSelectedFieldsForExport += this.state.widgetData.exportingCustomFields.length;
        }

        const selectAllForShow = totalNumberOfFields === totalNumberOfSelectedFieldsForShow;
        const selectAllForExport = totalNumberOfFields === totalNumberOfSelectedFieldsForExport;

        const canEditWidget = this.props.myId === 'SuperUser' || this.props.myId === this.state.widgetData.creatingUser;

        return (<section className={(this.state.fullScreenFlowChart ? "" : styles.commonModalHolder) + ' ' + (this.state.fullScreenFlowChart ? styles.fullScreenChartMode : '')}>

            {this.state.errorMessage && <LoaderModal loaderText={[translatePhrase('Error') + ' - ' + translatePhrase(this.state.errorMessage)]} isError={true} />}

            {this.state.widgetData.displayType !== 'message' && this.state.widgetData.displayType !== 'formatted-table' && this.state.isFlowChartExpanded && <section className={(widget ? styles.visibleFilterContainer : styles.filterContainer) + " " + (this.state.fullScreenFlowChart ? styles.fullScreenFlowChartModal : " ")}>
                <header className={`${styles.widgetHeader} ${this.props.isFlowchartExpanded && styles.widgetHeaderExpanded}`}>
                    <h2> {this.widgetName} </h2>
                </header>
                <div className={styles.flowchartContainer}>
                    {widget && <WidgetFlowchart widgetId={widgetId} onChange={() => this.setState({ isFlowChartExpanded: false, fullScreenFlowChart: false })} />}
                </div>
            </section>}

            {this.state.widgetData.displayType === 'message' && this.state.isFlowChartExpanded && <section className={(widget ? styles.visibleFilterContainer : styles.filterContainer) + " " + (this.state.fullScreenFlowChart ? styles.fullScreenFlowChartModal : " ")}>
                <header className={`${styles.widgetHeader} ${this.props.isFlowchartExpanded && styles.widgetHeaderExpanded}`}>
                    <h2> Announcement </h2>
                </header>

                <div className={styles.flowchartContainer + ' ' + (this.props.isShowingRichTextEditor ? styles.richTextChart : '')}>
                    {widget && <WidgetRichTextFlowchart isShowingRichText={this.props.isShowingRichTextEditor} widgetId={widgetId} onChange={() => this.setState({ isFlowChartExpanded: false, fullScreenFlowChart: false })} />}
                </div>

                {this.props.isShowingRichTextEditor && <section className={styles.richTextModal}>
                    <RichTextModal
                        textToShow={textToShowInRichTextModal}
                        onContentUpdate={this.updateRichTextValue}
                    />
                    <div className={styles.saveButton}>
                        <Button icon={<SaveIcon />} onClick={() => this.props.hideRichTextEditor()} text={'Save'} type={'secondary'} padding={'0px 10px'} size={'small'} isRounded />
                    </div>
                </section>}
            </section>}

            {this.state.widgetData.displayType === 'formatted-table' && this.state.isFlowChartExpanded && <section className={(widget ? styles.visibleFilterContainer : styles.filterContainer) + " " + (this.state.fullScreenFlowChart ? styles.fullScreenFlowChartModal : " ")}>
                <header className={`${styles.widgetHeader} ${this.props.isFlowchartExpanded && styles.widgetHeaderExpanded}`}>
                    <h2> {this.widgetName} - Formatted table </h2>
                </header>

                <div className={styles.flowchartContainer + ' ' + (this.props.isShowingRichTextEditor ? styles.richTextChart : '')}>
                    {widget && <WidgetRichTextFlowchart widgetId={widgetId} onChange={() => this.setState({ isFlowChartExpanded: false, fullScreenFlowChart: false })} />}
                </div>
            </section>}

            <section className={styles.addOrModifyListCard + ' ' + (this.state.isFlowChartExpanded ? styles.expandedModal : '')}>
                <header>
                    <h2>{widget ? 'Update Widget' : 'Add Widget'}</h2>
                    <Button icon={<CloseIcon />} padding={'0px 10px'} size={'small'} isRounded={true} isBlock={false} text={translatePhrase('Close')} onClick={() => this.props.closeWidgetModal()} />
                </header>

                <div className={styles.container}>
                    <div className={styles.allInputsHolder} key={this.state.widgetData.id}>
                        <div className={styles.inputSegment}>
                            <InputText placeholder="Name" default={widget ? widget.name : ''} onChange={this.changeName} />
                        </div>
                        <div className={styles.inputSegment}>
                            <InputText
                                placeholder="Chart type"
                                isDisabled={!!this.props.modifyingWidgetId && (this.state.widgetData.displayType === 'message'
                                    || this.state.widgetData.displayType === 'formatted-table')}
                                default={widget ? widget.displayType[0].toUpperCase() + widget.displayType.substring(1).split('-').join(' ') : ''}
                                options={chartTypeOptions}
                                onChange={this.changeDisplayType}
                                isAutoFocus={true}
                            />
                        </div>
                        <div className={styles.inputSegment}>
                            <InputText placeholder="Entity" default={widget ? widget.type : ''} onChange={this.changeType} options={entityOptions} isAutoFocus={true} />
                        </div>
                        {this.state.widgetData.type && <div key={this.state.widgetData.type} className={styles.inputSegment}>
                            <InputText placeholder="Entity type" default={selectedEntityType ? selectedEntityType.name : ''} onChange={this.changeTypeId} options={entityTypeOptions} isAutoFocus={true} />
                        </div>}
                        <div className={styles.inputSegment}>
                            <MultiSelectInputText
                                options={rolesList}
                                onChange={this.changeRoles}
                                default={this.state.widgetData.roles}
                                placeholder={translatePhrase('Shown to')}
                                isAutoFocus={true}
                            />
                        </div>
                    </div>

                    <div className={styles.chartContentConfiguration}>
                        {this.props.modifyingWidgetId && !this.state.isFlowChartExpanded && canEditWidget && <div className={styles.showChartButton}>
                            <Button onClick={() => this.setState({ isFlowChartExpanded: true, fullScreenFlowChart: true })} isRounded text={translatePhrase('Go to Flowchart')} padding={'0px 15px'} type={'secondary'} isBlock />
                        </div>}

                        {this.state.widgetData.displayType !== 'message' && this.state.widgetData.displayType !== 'formatted-table' && (systemFieldOptions.length > 0 || customFieldOptions.length > 0) && <section className={styles.customFieldsContainer}>
                            <div className={styles.allInputsHolder}>

                                <h2 className={styles.containerHeader}> Show </h2>
                                <div className={styles.customFieldsHeader}>
                                    <Checkbox
                                        name={selectAllForShow ? translatePhrase('Remove all fields') : translatePhrase('Select all fields')}
                                        value={selectAllForShow ? 'Yes' : 'No'}
                                        defaultChecked={selectAllForShow}
                                        toggle={() => this.toggleSelectAllForShow(systemFieldOptions, customFieldOptions, selectAllForShow)}
                                    />
                                </div>

                                {systemFieldOptions.map(systemFieldOption => {
                                    return <div className={styles.customFieldOption}>
                                        <Checkbox
                                            key={systemFieldOption.value}
                                            name={systemFieldOption.name}
                                            value={systemFieldOption.value}
                                            defaultChecked={this.state.widgetData.systemFields && this.state.widgetData.systemFields.includes(systemFieldOption.value)}
                                            toggle={this.updateSelectedSystemFields}
                                        />
                                    </div>
                                })}
                                {customFieldOptions.map(customFieldOption => {
                                    return <div className={styles.customFieldOption}>
                                        <Checkbox
                                            key={customFieldOption.value}
                                            name={customFieldOption.name}
                                            value={customFieldOption.value}
                                            defaultChecked={this.state.widgetData.customFields.includes(customFieldOption.value)}
                                            toggle={this.updateSelectedCustomFields}
                                        />
                                    </div>
                                })}
                            </div>
                        </section>}

                        {this.state.widgetData.displayType !== 'message' && this.state.widgetData.displayType !== 'formatted-table' && (systemFieldOptions.length > 0 || customFieldOptions.length > 0) && <section className={styles.customFieldsContainer}>
                            <div className={styles.allInputsHolder}>

                                <div className={styles.inputSegment}>
                                    <InputText placeholder="Aggregation" default={widget ? widget.aggregation[0].toUpperCase() + widget.aggregation.substring(1) : ''} options={aggregationOptions} onChange={this.changeAggregation} isAutoFocus={true} />
                                </div>
                            </div>
                        </section>}

                        {this.state.widgetData.displayType !== 'message' && this.state.widgetData.displayType !== 'formatted-table' && (systemFieldOptions.length > 0 || customFieldOptions.length > 0) && <section className={styles.customFieldsContainer}>
                            <div className={styles.allInputsHolder}>

                                <h2 className={styles.containerHeader}> Export </h2>

                                <div className={styles.customFieldsHeader}>
                                    <Checkbox
                                        name={selectAllForExport ? translatePhrase('Remove all from Export') : translatePhrase('Export all fields')}
                                        value={selectAllForExport ? 'Yes' : 'No'}
                                        defaultChecked={selectAllForExport ? true : false}
                                        toggle={() => this.toggleSelectAllForExport(systemFieldOptions, customFieldOptions, selectAllForExport)}
                                    />
                                </div>

                                {systemFieldOptions.map(systemFieldOption => {
                                    return <div className={styles.customFieldOption}>
                                        <Checkbox
                                            key={systemFieldOption.value}
                                            name={systemFieldOption.name}
                                            value={systemFieldOption.value}
                                            defaultChecked={this.state.widgetData.exportingSystemFields && this.state.widgetData.exportingSystemFields.includes(systemFieldOption.value)}
                                            toggle={this.updateSelectedExportSystemFields}
                                        />
                                    </div>
                                })}
                                {customFieldOptions.map(customFieldOption => {
                                    return <div className={styles.customFieldOption}>
                                        <Checkbox
                                            key={customFieldOption.value}
                                            name={customFieldOption.name}
                                            value={customFieldOption.value}
                                            defaultChecked={this.state.widgetData.exportingCustomFields && this.state.widgetData.exportingCustomFields.includes(customFieldOption.value)}
                                            toggle={this.updateSelectedExportCustomFields}
                                        />
                                    </div>
                                })}
                            </div>

                        </section>}
                    </div>
                </div>

                <div className={styles.buttonsHolder}>
                    {this.state.submitTimer && <Button isRounded={true} color={'contrast'} padding={'0px 10px'} icon={<CheckIcon />} text={widget ? translatePhrase('Updated Widget') : translatePhrase('Added Widget')} />}
                    {!this.state.submitTimer && <Button isDisabled={!this.preValidateIWidgetsData()} isRounded={true} color={'contrast'} padding={'0px 10px'} onClick={() => this.submitWidgetForm()} icon={widget ? <CheckIcon /> : <PlusIcon />} text={widget ? translatePhrase('Update') : translatePhrase('Add') + ' ' + translatePhrase('Widget')} />}
                </div>
            </section>
        </section>);
    }
}

const ModifyWidget = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectedModifyWidget));

export default ModifyWidget;