import { takeLatest, select, call, put } from "redux-saga/effects";
import { ADD_SYNC_LOG, AddSyncLog, DeviceSpecs, FAILED_SYNC_LOG, FailedSyncLog, GENERATE_SYNC_LOG, GenerateSynclog, SyncMetaLog, UPDATE_SYNC_LOG } from "./types";
import { ApplicationState } from "../types";
import moment from "moment";
import axios, { AxiosResponse } from 'axios';
import { isMobilePlatform } from "../../helpers/utilities";
import { collectDataToPush } from "../../helpers/synchronize";

const httpClient = axios.create();
const FIVE_MINUTES = 5 * 60 * 1000;
httpClient.defaults.timeout = FIVE_MINUTES;


function abortSignal(timeoutMs: number) {
    const abortController = new AbortController();
    setTimeout(() => abortController.abort(), timeoutMs || 0);

    return abortController.signal;
}


function fetchUserIP() {
    return httpClient.get<{ ip: string }>('https://api.ipify.org?format=json', {
        signal: abortSignal(FIVE_MINUTES),
        timeout: FIVE_MINUTES
    })
}

function getInternetSpeedFromNavigator() {
    const connection = (navigator as any).connection || (navigator as any).mozConnection || (navigator as any).webkitConnection;

    if (connection) {
        return {
            downlink: connection.downlink, // estimated download bandwidth in Mbps
            effectiveType: connection.effectiveType, // e.g., '4g', '3g'
        };
    } else {
        console.warn("Network Information API not supported on this browser.");
        return null;
    }
}


function* addSyncLog(action: GenerateSynclog) {
    if (!window.navigator.onLine) {
        console.error("No Internet connection")
        return;
    }

    const state: ApplicationState = yield select();
    const now = moment().utc().format();

    const errors = state.errors.allEntries.map(entry => state.errors.byId[entry]);
    const organization = state.organization.code;
    const userId = state.myData.id;
    const platform = isMobilePlatform() ? "Mobile" : "Desktop";

    const dataDelta = collectDataToPush(state);
    const encoder = new TextEncoder();
    const dataSizeBytes = encoder.encode(JSON.stringify(dataDelta)).length;

    let ip = '';

    try {
        const response: AxiosResponse<{ ip: string }> = yield call(fetchUserIP);
        ip = response.data.ip
    } catch (error) {
        console.error("Unable to fetch the user IP")
    }

    let deviceSpecs: DeviceSpecs | undefined = undefined;

    const deviceSpecsString = localStorage.getItem('deviceSpecs');
    if (deviceSpecsString) {
        deviceSpecs = JSON.parse(deviceSpecsString) as DeviceSpecs;
    }

    const internetSpeed: { downlink: string, effectiveType: string } = yield call(getInternetSpeedFromNavigator);

    const synclog: SyncMetaLog = {
        id: action.id,
        syncLogId: "",
        organization,
        userId,
        timestamp: {
            browserRequestTime: Date.now(),
            serverRequestRecievedTime: 0,
            processComputationTime: {
                startTime: 0,
                endTime: 0
            },
            dataDownloadTime: {
                startTime: 0,
                endTime: 0
            }
        },
        platform,
        ip,
        internetSpeed: internetSpeed.downlink,
        pushDataSize: dataSizeBytes,
        pullDataSize: 0,
        errors: errors,
        deviceSpecs: deviceSpecs,
        status: false,
        syncProcessMessage: "",
        createdTime: now,
        lastUpdatedTime: now,
        failedSyncErrorMessage: ""
    };

    yield put({
        type: ADD_SYNC_LOG,
        payload: synclog
    })
}

function* logFailedSync(action: FailedSyncLog) {
    const state: ApplicationState = yield select();
    let updatedSynclog = state.synclogState.byId[state.synclogState.currentSyncLogId];
    updatedSynclog = {
        ...updatedSynclog,
        syncProcessMessage: state.synclogState.readableSyncMessage || "",
        status: false,
        failedSyncErrorMessage: action.message
    };

    yield put({
        type: UPDATE_SYNC_LOG,
        payload: updatedSynclog
    })

}

export function* watchAddSyncLog() {
    yield takeLatest(GENERATE_SYNC_LOG, addSyncLog);
}

export function* watchFailedSyncLog() {
    yield takeLatest(FAILED_SYNC_LOG, logFailedSync);
}


