import React, { Component } from 'react';
import styles from './QuestionPiece.module.scss';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setQuestionRequiredPiece, setQuestionDisabledPiece, setQuestionDefaultPiece, setQuestionImage, setCustomField, setQuestionHiddenPiece } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import { booleanPieceSlotTarget, valuePieceSlotTarget } from './utilities';
import Input from '../Input';
import { ICON_NAMES } from '../../../helpers/in-app-icons';
import DropDownSearchBox from '../drop-down/DropDownSearchBox';


type QuestionPieceEssentialsProps = {
    isRequiredPiece?: JSX.Element,
    isDisabledPiece?: JSX.Element,
    isHiddenPiece?: JSX.Element,
    defaultPiece?: JSX.Element,
    image?: string,
    defaultText?: string,

    isValid?: boolean,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string | undefined) => dispatch(setTargetPiece(pieceId)),
        setRequiredPiece: (targetPieceId: string, draggedPieceId: string) => dispatch(setQuestionRequiredPiece(targetPieceId, draggedPieceId)),
        setDisabledPiece: (targetPieceId: string, draggedPieceId: string) => dispatch(setQuestionDisabledPiece(targetPieceId, draggedPieceId)),
        setHiddenPiece: (targetPieceId: string, draggedPieceId: string) => dispatch(setQuestionHiddenPiece(targetPieceId, draggedPieceId)),
        setDefaultPiece: (targetPieceId: string, draggedPieceId: string) => dispatch(setQuestionDefaultPiece(targetPieceId, draggedPieceId)),
        setCustomField: (pieceId: string, value: string) => dispatch(setCustomField(pieceId, value)),
        setQuestionImage: (targetPieceId: string, image: string) => dispatch(setQuestionImage(targetPieceId, image)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = QuestionPieceEssentialsProps & StateProps & DispatchProps & FlowchartPieceProps;

type QuestionPieceEssentialsState = {
    isHoveringOverRequiredPiece: boolean,
    isHoveringOverDisabledPiece: boolean,
    isHoveringOverHiddenPiece: boolean,
    isHoveringOverDefaultPiece: boolean,
    imageName: string,
}

class ConnectedQuestionPieceEssentials extends Component<Props, QuestionPieceEssentialsState>  {

    state = {
        isHoveringOverRequiredPiece: false,
        isHoveringOverDisabledPiece: false,
        isHoveringOverHiddenPiece: false,
        isHoveringOverDefaultPiece: false,
        imageName: "",
    };

    handleHoverOverDisabledPiece = () => {
        this.setState({
            isHoveringOverDisabledPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        booleanPieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfDisabledPiece = () => {
        this.setState({
            isHoveringOverDisabledPiece: false,
        });
    };

    handleHoverOverHiddenPiece = () => {
        this.setState({
            isHoveringOverHiddenPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        booleanPieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfHiddenPiece = () => {
        this.setState({
            isHoveringOverHiddenPiece: false,
        });
    };


    handleHoverOverDefaultPiece = () => {
        this.setState({
            isHoveringOverDefaultPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfDefaultPiece = () => {
        this.setState({
            isHoveringOverDefaultPiece: false,
        });
    };

    handleHoverOverRequiredPiece = () => {
        this.setState({
            isHoveringOverRequiredPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        booleanPieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfRequiredPiece = () => {
        this.setState({
            isHoveringOverRequiredPiece: false,
        });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && (this.state.isHoveringOverRequiredPiece || this.state.isHoveringOverDisabledPiece || this.state.isHoveringOverDefaultPiece || this.state.isHoveringOverHiddenPiece)) {
            if (this.state.isHoveringOverRequiredPiece) {
                this.props.setRequiredPiece(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverDisabledPiece) {
                this.props.setDisabledPiece(this.props.pieceId, this.props.lastDraggedPiece.id)
            } else if (this.state.isHoveringOverDefaultPiece) {
                this.props.setDefaultPiece(this.props.pieceId, this.props.lastDraggedPiece.id)
            } else if (this.state.isHoveringOverHiddenPiece) {
                this.props.setHiddenPiece(this.props.pieceId, this.props.lastDraggedPiece.id)
            }

            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverRequiredPiece: false,
                isHoveringOverDisabledPiece: false,
                isHoveringOverHiddenPiece: false,
                isHoveringOverDefaultPiece: false,
            });
        }
    }

    handleDefaultValueUpdate = (value: string) => {
        this.props.setDefaultPiece(this.props.pieceId, value);
    }

    searchForImage = (searchTerm: string) => {
        this.setState({
            imageName: searchTerm,
        });
    }

    render() {
        const { image, setQuestionImage, pieceId, isRequiredPiece, isDragging, targetPiece, isDisabledPiece, defaultPiece, defaultText, isHiddenPiece } = this.props;
        const { imageName, isHoveringOverRequiredPiece, isHoveringOverDisabledPiece, isHoveringOverDefaultPiece, isHoveringOverHiddenPiece } = this.state;

        const filteredIconNames = ICON_NAMES.filter(iconName => iconName.toLocaleLowerCase().includes(imageName.toLocaleLowerCase()));

        const imageSelectBox = <SelectBox theme="camo" selectionPromptText={image || 'Select Image'}>
            <DropDownList theme="camo" dismissAfterSelection={false}>
                <DropDownSearchBox
                    handleSearchInputChange={this.searchForImage}
                    placeholder={"Search by name"}
                    searchTerm={imageName}
                />
                {filteredIconNames.map(icon => <ListItem name={icon} key={icon} value={icon} theme="camo" onClick={setQuestionImage.bind(this, pieceId)} />)}
            </DropDownList>
        </SelectBox>;

        return (
            <div className={styles.visibleItems}>
                <div className={styles.text}>Is Required?</div>
                {isRequiredPiece ? isRequiredPiece : <div className={(isHoveringOverRequiredPiece && isDragging && targetPiece ? styles.booleanIndicatorHovering : styles.booleanIndicator) + ' attachment-target'} onMouseOver={this.handleHoverOverRequiredPiece} onMouseOut={this.handleHoverOutOfRequiredPiece}></div>}

                <div className={styles.text}>Is Disabled?</div>
                {isDisabledPiece ? isDisabledPiece : <div className={(isHoveringOverDisabledPiece && isDragging && targetPiece ? styles.booleanIndicatorHovering : styles.booleanIndicator) + ' attachment-target'} onMouseOver={this.handleHoverOverDisabledPiece} onMouseOut={this.handleHoverOutOfDisabledPiece}></div>}

                <div className={styles.text}>Is Hidden?</div>
                {isHiddenPiece ? isHiddenPiece : <div className={(isHoveringOverHiddenPiece && isDragging && targetPiece ? styles.booleanIndicatorHovering : styles.booleanIndicator) + ' attachment-target'} onMouseOver={this.handleHoverOverHiddenPiece} onMouseOut={this.handleHoverOutOfHiddenPiece}></div>}

                <div className={styles.text}>Default</div>
                {defaultPiece ? defaultPiece : <Input isDisabled canReceiveDrag={isDragging && isHoveringOverDefaultPiece && !!targetPiece} placeholderText="Default" minSize={9} defaultText={defaultText} onMouseOver={this.handleHoverOverDefaultPiece} onMouseOut={this.handleHoverOutOfDefaultPiece} />}
                <div className={styles.text}>with image</div>
                {imageSelectBox}
            </div>
        )

    }
}

const QuestionPieceEssentials = connect(mapStateToProps, mapDispatchToProps)(ConnectedQuestionPieceEssentials);

export default QuestionPieceEssentials;