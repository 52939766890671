import { Component, MouseEvent } from 'react';
import styles from './Card.module.scss';
import { Draggable } from 'react-beautiful-dnd';
import onClickOutside from 'react-onclickoutside';

import { translatePhrase } from '../../shared/helpers/translation';

import CountDown from './CountDown';

import { ReactComponent as DeleteIcon } from "../../assets/new-custom-icons/dashboard/trash.svg";
import { ReactComponent as EditIcon } from '../../assets/new-custom-icons/common/edit.svg';
import { ReactComponent as UndoIcon } from '../../assets/undo.svg';
import { ReactComponent as CloseIcon } from "../../assets/action-icons/cancel.svg";
import { ReactComponent as MoreIcon } from "../../assets/new-custom-icons/common/more.svg";
import { ReactComponent as DuplicateIcon } from "../../assets/new-custom-icons/common/duplicate.svg";
import { ReactComponent as ExportIcon } from "../../assets/new-custom-icons/common/export.svg";
import { ReactComponent as FlowchartIcon } from '../../assets/new-custom-icons/new-revision/flow-data.svg';
import Button from "../../widgets/button/CommonButton";

export interface CardType {
    id: string,
    name: string,
    details?: string,
    isReadOnly?: boolean,
    isDeleteRestricted?: boolean,
}

export type OptionalCardType = CardType & { isRequired: boolean };

type OwnProps = {
    card: CardType,

    isPotentiallyDraggable: boolean, // This checks if you will need to use the draggable library to render the card
    isDragDisabled: boolean, // This is only relevant if the draggable library is used. It checks whether the draggable library allows the cards to be dragged.

    isActive: boolean,
    isReadonly: boolean,
    isDeleteRestricted: boolean,
    isShowingAddForm: boolean,
    isShowingEditForm: boolean,
    isShowingIndex: boolean,
    index: number,
    isShowMoreHighlighted?: boolean;

    isHighlightedImport?: boolean
    isHighlightedExport?: boolean

    modifyForm: JSX.Element | undefined,

    onSelectCard: (id: string) => void,
    onUnselectCard: () => void,
    onEditCard: () => void,
    onDeleteCard: (id: string) => void,
    onDuplicate?: () => void,
    onGenerateFlowchart?: () => void,
    onExport?: () => void,
    onLocationExport?: () => void,
    cancelForm?: () => void,
    showFlowchart?: () => void,
}

type OwnState = {
    isShowingEditForm: boolean,
    deleteTimer: number | undefined,
    showMoreOptions: boolean,
    onDelete: boolean,
}

class Card extends Component<OwnProps, OwnState> {

    constructor(props: Readonly<OwnProps>) {
        super(props);

        this.state = {
            deleteTimer: undefined,
            isShowingEditForm: props.isShowingEditForm,
            showMoreOptions: false,
            onDelete: false,
        };
    }

    static defaultProps = {
        isPotentiallyDraggable: false,
        isDragDisabled: true,
        isActive: false,
        isReadonly: false,
        isDeleteRestricted: false,
        isShowingAddForm: false,
        isShowingEditForm: false,
        isShowingIndex: true,
        modifyForm: undefined,
    }

    static getDerivedStateFromProps(props: Readonly<OwnProps>, state: Readonly<OwnState>) {
        if (props.isShowingEditForm !== state.isShowingEditForm) {

            return {
                isShowingEditForm: props.isShowingEditForm
            };

        } else {
            return null;
        }
    }

    handleClickOutside = (event: MouseEvent) => {
        if (this.props.isActive) {
            // this.props.onUnselectCard && this.props.onUnselectCard();
            this.setState({ showMoreOptions: false })
        }
    }

    editCard = () => {
        this.props.onSelectCard(this.props.card.id);

        setTimeout(() => {
            this.props.onEditCard();
            this.setState({
                isShowingEditForm: true,
                showMoreOptions: false,
            });
        }, 10);
    }

    deleteCard = () => {
        this.props.onSelectCard(this.props.card.id)
        this.props.onDeleteCard(this.props.card.id);
    }

    markForDelete = () => {
        let that = this;
        const timeout = window.setTimeout(that.deleteCard, 5000);

        this.setState({
            deleteTimer: timeout,
            onDelete: true,
            showMoreOptions: false,
        });
    }

    cancelDelete = () => {
        window.clearTimeout(this.state.deleteTimer);
        this.setState({
            deleteTimer: undefined,
            onDelete: false,
            showMoreOptions: true,
        });
    }


    getPaddedValue = (number: Number) => {
        return number.toString().padStart(2, '0');
    }

    selectedCard = () => {
        if (!this.props.isActive && this.state.isShowingEditForm) {
            this.props.cancelForm && this.props.cancelForm()
        }
    }

    checkIsMultipleActionButton = () => {
        let count = 0;
        if (this.props.onGenerateFlowchart) {
            count = count + 1;
        }
        if (this.props.onLocationExport) {
            count = count + 1;
        }
        if (this.props.onExport) {
            count = count + 1;
        }
        if (this.props.onDuplicate) {
            count = count + 1;
        }
        if (!this.props.isReadonly) {
            count = count + 1;
        }
        if (!this.props.isDeleteRestricted) {
            count = count + 1;
        }

        return count > 1;
    }

    render() {
        /** These classes ensure that any clicks on the Card component will not 
            trigger the "handleClickOutside"  functions of the default as well 
            as the top level components. 
        
            EXTRA: "onClickOutside" is a function that wraps a component inside 
            another component. You can define your own classes that ignore 
            outside clicks when creating a wrapped component. Refer 
            "EnhancedTopCard" in this file to see how.
        */

        const onClickOutsideClasses = ' ignore-react-onclickoutside ignore-top-level-onclickoutside';

        const actionButtonMarkUp = <div className={styles.actionButton}>
            {!this.props.isReadonly && this.props.onGenerateFlowchart &&
                <Button
                    onClick={this.props.onGenerateFlowchart}
                    title={translatePhrase('Generate')}
                    icon={<FlowchartIcon />}
                    isRounded
                    size={'small'}
                    type={'secondary'} />
            }
            {this.props.onLocationExport &&
                <div className={styles.exportButton}>
                    <Button
                        onClick={this.props.onLocationExport}
                        title={translatePhrase('Export')}
                        icon={<ExportIcon />}
                        isRounded
                        size={'small'}
                        type={'secondary'}
                        isHighlighted={this.props.isHighlightedExport} />
                </div>
            }
            {this.props.onExport &&
                <div className={styles.exportButton}>
                    <Button
                        onClick={this.props.onExport}
                        title={translatePhrase('Export')}
                        icon={<ExportIcon />}
                        isRounded
                        size={'small'}
                        type={'secondary'} />
                </div>

            }
            {!this.props.isReadonly && this.props.onDuplicate &&
                <div className={styles.duplicateButton}>
                    <Button
                        onClick={this.props.onDuplicate}
                        title={translatePhrase('Duplicate')}
                        icon={<DuplicateIcon />}
                        isRounded
                        size={'small'}
                        type={'secondary'} />
                </div>
            }
            {!this.props.isReadonly &&
                <Button
                    onClick={this.editCard}
                    title={translatePhrase('Edit')}
                    isDisabled={this.props.isShowingAddForm || this.props.isShowingEditForm}
                    icon={<EditIcon />}
                    isRounded
                    size={'small'}
                    type={'secondary'} />
            }
            {!this.props.isReadonly && !this.props.isDeleteRestricted &&
                <div className={styles.deleteButton}>
                    <Button
                        onClick={this.markForDelete}
                        title={translatePhrase('Delete')}
                        icon={<DeleteIcon />}
                        isRounded
                        size={'small'}
                        type={'secondary'}
                        isDisabled={this.props.isDeleteRestricted}
                        isDanger={true} />
                </div>
            }
        </div>


        const hasMoreActions = !this.props.isReadonly || this.props.onLocationExport || this.props.onExport;

        const cardMarkup = <div className={(this.state.deleteTimer ? styles.deleting : (this.props.isActive ? styles.active : styles.normal)) + onClickOutsideClasses}>
            {this.props.isShowingIndex && <div className={styles.index}> {this.getPaddedValue(this.props.index + 1)} </div>}
            <div className={styles.details + ' ' + (this.state.showMoreOptions ? styles.hide : ' ')}>
                {this.state.deleteTimer ? <div className={styles.deletetitle}>{translatePhrase('Undo')} <UndoIcon /> <CountDown remaining={5} color={'#ea7a7a'} /></div> : <div className={styles.title}>
                    <span title={this.props.card.name}>{this.props.card.name}</span>
                </div>}
                {!this.state.deleteTimer && this.props.card.details && <div className={styles.subTitle}>
                    <span title={this.props.card.details}>{this.props.card.details}</span>
                </div>}
            </div>

            {!this.state.showMoreOptions && hasMoreActions && this.checkIsMultipleActionButton() &&
                <div className={styles.moreOptionBtn + ' ' + (this.state.onDelete ? styles.hide : '')} >
                    <Button isHighlighted={this.props.isShowMoreHighlighted} title={translatePhrase('More')} size={'small'} onClick={() => {
                        setTimeout(() => this.setState({ showMoreOptions: true }), 100);
                    }} icon={<MoreIcon />} type={'secondary'} isRounded={true} />
                </div>}

            {!this.state.showMoreOptions && hasMoreActions && !this.checkIsMultipleActionButton() &&
                actionButtonMarkUp
            }

            <div className={styles.moreOptionsHolder + ' ' + (this.state.showMoreOptions ? styles.active : '')}>
                <ul className={styles.moreOptions}>
                    <div className={styles.actionButtonsColumn + ' ' + (this.state.onDelete ? styles.hide : '')} onClick={(e) => e.stopPropagation()}>
                        {!this.props.isReadonly && this.props.onGenerateFlowchart &&
                            // <button onClick={this.props.onGenerateFlowchart} title={translatePhrase('Generate')} > <FlowchartIcon /></button>
                            <Button
                                onClick={this.props.onGenerateFlowchart}
                                title={translatePhrase('Generate')}
                                icon={<FlowchartIcon />}
                                isRounded
                                size={"small"}
                                color={'primary'}
                                type={'secondary'} />}

                        {this.props.onLocationExport &&
                            // <button className={this.props.isHighlightedExport ? styles.isHighlighted : ''} onClick={this.props.onLocationExport} title={translatePhrase('Export')} > <ExportIcon /></button>
                            <Button
                                onClick={this.props.onLocationExport}
                                title={translatePhrase('Export')}
                                icon={<ExportIcon />}
                                isRounded
                                size={"small"}
                                color={'primary'}
                                type={'secondary'}
                                isHighlighted={this.props.isHighlightedExport} />
                        }

                        {this.props.onExport &&
                            // <button className={styles.export} title={translatePhrase('Export')} onClick={this.props.onExport}> <ExportIcon /> </button>
                            <Button
                                onClick={this.props.onExport}
                                title={translatePhrase('Export')}
                                size={"small"}
                                color={'primary'}
                                type={'secondary'}
                                icon={<ExportIcon />}
                                isRounded />
                        }

                        {!this.props.isReadonly && this.props.onDuplicate &&
                            // <button className={styles.duplicate} title={translatePhrase('Duplicate')} onClick={this.props.onDuplicate}> <DuplicateIcon /> </button>
                            <Button
                                onClick={this.props.onDuplicate}
                                title={translatePhrase('Duplicate')}
                                size={"small"}
                                color={'primary'}
                                type={'secondary'}
                                icon={<DuplicateIcon />}
                                isRounded />
                        }

                        {!this.props.isReadonly &&
                            <Button
                                onClick={this.editCard}
                                isDisabled={this.props.isShowingAddForm || this.props.isShowingEditForm}
                                title={translatePhrase('Edit')}
                                size={"small"}
                                icon={<EditIcon />}
                                color={'primary'}
                                type={'secondary'}
                                isRounded />

                            // <button className={styles.edit} onClick={this.editCard} title={translatePhrase('Edit')}> <EditIcon /></button>
                        }

                        {!this.props.isReadonly && !this.props.isDeleteRestricted &&
                            // <button className={styles.delete} title={translatePhrase('Delete')} onClick={this.markForDelete} disabled={this.props.isDeleteRestricted}> <DeleteIcon /> </button>
                            <Button
                                onClick={this.markForDelete}
                                title={translatePhrase('Delete')}
                                icon={<DeleteIcon />}
                                size={"small"}
                                color={"danger"}
                                type={"secondary"}
                                isDanger={true}
                                isDisabled={this.props.isDeleteRestricted}
                                isRounded />
                        }

                        <Button
                            onClick={() => this.setState({ showMoreOptions: false })}
                            title={translatePhrase('Close')}
                            size={"small"}
                            icon={<CloseIcon />}
                            type={"secondary"}
                            isRounded />

                        {/* <button className={styles.closeMore} title={translatePhrase('Close')} onClick={(e) => this.setState({ showMoreOptions: false })}><CloseIcon /></button> */}
                    </div>
                </ul>
            </div>
        </div>

        const cardInnards = this.props.isActive && this.state.isShowingEditForm ? this.props.modifyForm : cardMarkup;
        return (
            this.props.isPotentiallyDraggable ?
                <Draggable key={this.props.card.id} draggableId={this.props.card.id} index={this.props.index} isDragDisabled={this.props.isDragDisabled}>
                    {(provided, snapshot) => {
                        return (<li
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onClick={() => {
                                this.state.deleteTimer ? this.cancelDelete() : this.props.onSelectCard(this.props.card.id); this.selectedCard();
                            }}
                            className={styles.listItem}
                        >
                            {cardInnards}
                        </li>)
                    }}
                </Draggable>
                :
                <li
                    onClick={(e) => {
                        this.state.deleteTimer ? this.cancelDelete() : this.props.onSelectCard(this.props.card.id); this.selectedCard();
                    }}
                    className={styles.listItem}>
                    {cardInnards}
                </li>
        );

    }
}

const EnhancedCard = onClickOutside(Card, {
    excludeScrollbar: true
});

class EnhancedTopCard extends Component<OwnProps> {
    render() {
        return <EnhancedCard outsideClickIgnoreClass={'ignore-top-level-onclickoutside'} {...this.props} />;
    }
}

export default EnhancedCard;

export { EnhancedTopCard };