import { createSelector } from 'reselect';
import { getVisibleWorkflowIdsSelector } from '../../../helpers/selectors';
import { filterWorkflows } from '../../../shared/helpers/filters';
import store from '../../../shared/store/main';
import { ApplicationState } from '../../../shared/store/types';

const getWorkflowState = (state: ApplicationState) => state.workflows.byId;
const getSearchTerm = (state: ApplicationState) => state.workflows.searchTerm;
const getCurrentPageNumber = (state: ApplicationState) => state.workflows.currentPageNumber;
const getPageSize = (state: ApplicationState) => state.workflows.pageSize;
const getFilters = (state: ApplicationState) => state.workflows.filters;

export const getSortedWorkflows = createSelector(
    [getWorkflowState, getFilters, getVisibleWorkflowIdsSelector],
    (workflowState, filters, workflowEntries) => {
        const workflows = workflowEntries.map(workflowId => workflowState[workflowId]);
        const sortedWorkflows = workflows.sort((a, b) => {
            if (!a.dueDate) {
                return -1;
            }

            if (!b.dueDate) {
                return 1;
            }

            const aDate = new Date(a.dueDate);
            const bDate = new Date(b.dueDate);

            return aDate.getTime() - bDate.getTime();
        });
        return sortedWorkflows;
    }
)

export const getFilteredWorkflows = createSelector(
    [getSortedWorkflows, getCurrentPageNumber, getPageSize, getSearchTerm, getFilters],
    (sortedWorkflows, currentPageNumber, pageSize, searchTerm, filters) => {
        const state = store.getState();

        const filteredWorkflows = filterWorkflows(state, sortedWorkflows);
        return filteredWorkflows;
    }
)