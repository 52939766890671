import React, { Component } from 'react';
import styles from './Operator.module.scss';
import Input from '../../Input';
import leftArrow from '../../../../assets/flowchart/triangle-left.svg';
import rightArrow from '../../../../assets/flowchart/triangle-right.svg';
import FlowchartPiece, { OwnProps as FlowchartPieceProps } from '../FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setOperand } from '../../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../../shared/store/types';
import { valuePieceSlotTarget, booleanPieceSlotTarget } from '../utilities';
import { FlowchartContext, PieceHighlightColour } from '../../../../contexts/flowchart-context';


export type UnaryOperatorProps = {
    operandPiece?: JSX.Element,
    operandText?: string,

    operatorSymbol: string,
    isBoolean: boolean,
    isOperatorBoolean: boolean,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string | undefined) => dispatch(setTargetPiece(pieceId)),
        setOperand: (targetPieceId: string, draggedPieceId: string) => dispatch(setOperand(targetPieceId, draggedPieceId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = UnaryOperatorProps & StateProps & DispatchProps & FlowchartPieceProps;

type StorePieceState = {
    isHoveringOverOperand: boolean,
}

class ConnectedUnaryOperator extends Component<Props, StorePieceState> {

    state = {
        isHoveringOverOperand: false,
    }

    static defaultProps = {
        isBoolean: false,
        isOperatorBoolean: false,
    };

    handleHoverOverOperand = () => {
        this.setState({
            isHoveringOverOperand: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        if (this.props.isOperatorBoolean) {
            booleanPieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
        } else {
            valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
        }
    };

    handleHoverOutOfOperandPiece = () => {
        this.setState({
            isHoveringOverOperand: false,
        });
    };

    handleLeftOperandValueUpdate = (value: string) => {
        this.props.setOperand(this.props.pieceId, value);
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && this.state.isHoveringOverOperand) {

            this.props.setOperand(this.props.pieceId, this.props.lastDraggedPiece.id);
            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverOperand: false,
            });
        }
    }

    render() {

        return <FlowchartContext.Consumer>
            {
                (flowchartContext) => {
                    const highlightColor = flowchartContext.highlights && flowchartContext.highlights[this.props.pieceId];
                    let highlightClass = styles.noHighlight;

                    switch (highlightColor) {
                        case PieceHighlightColour.GREEN:
                            highlightClass = styles.addedHighlight;
                            break;
                        case PieceHighlightColour.YELLOW:
                            highlightClass = styles.updatedHighlight;
                            break;
                        case PieceHighlightColour.PURPLE:
                            highlightClass = styles.movedHighlight;
                            break;
                        case PieceHighlightColour.RED:
                            highlightClass = styles.deletedHighlight;
                            break;
                    }

                    return (
                        <FlowchartPiece {...this.props}>
                            <div className={highlightClass}>
                                <div className={this.props.isBoolean ? styles.booleanUnaryOperator : styles.unaryOperator}>
                                    <div className={styles.operatorSymbol}>{this.props.operatorSymbol}</div>

                                    {this.props.operandPiece ? this.props.operandPiece :
                                        this.props.isOperatorBoolean ?
                                            <div className={(this.state.isHoveringOverOperand && this.props.isDragging && this.props.targetPiece ? styles.booleanIndicatorHovering : styles.booleanIndicator) + ' attachment-target'} onMouseOver={this.handleHoverOverOperand} onMouseOut={this.handleHoverOutOfOperandPiece}></div>
                                            :
                                            <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverOperand && !!this.props.targetPiece} defaultText={this.props.operandText} onMouseOver={this.handleHoverOverOperand} onMouseOut={this.handleHoverOutOfOperandPiece} onChange={this.handleLeftOperandValueUpdate} />
                                    }


                                    {this.props.isBoolean && <div className={styles.booleanLeftPiece}><img className={styles.booleanPieceImage} alt="Left piece of boolean operator" src={leftArrow} /></div>}
                                    {this.props.isBoolean && <div className={styles.booleanRightPiece}><img className={styles.booleanPieceImage} alt="Right piece of boolean operator" src={rightArrow} /></div>}
                                </div>
                            </div>
                        </FlowchartPiece>
                    )
                }
            }
        </FlowchartContext.Consumer>
    }
}

const UnaryOperator = connect(mapStateToProps, mapDispatchToProps)(ConnectedUnaryOperator);

export default UnaryOperator;