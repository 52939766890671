import { ReOrderedListsForEntity } from '../../helpers/common-types';
import { NormalizedModel, Synchronizable } from '../normalized-model';
import { DataFragmentActionTypes, DataFragmentState } from './data-fragment/types';

export const SELECT_STATIC_DATA_HOLDER = 'SELECT_STATIC_DATA_HOLDER';
export const UN_SELECT_STATIC_DATA_HOLDER = 'UN_SELECT_STATIC_DATA_HOLDER';
export const RE_ORDER_STATIC_DATA_HOLDERS = 'RE_ORDER_STATIC_DATA_HOLDERS';
export const ADD_STATIC_DATA_HOLDER_REQUEST = 'ADD_STATIC_DATA_HOLDER_REQUEST';
export const ADD_STATIC_DATA_HOLDER = 'ADD_STATIC_DATA_HOLDER';
export const DELETE_STATIC_DATA_HOLDER = 'DELETE_STATIC_DATA_HOLDER';
export const UPDATE_STATIC_DATA_HOLDER = 'UPDATE_STATIC_DATA_HOLDER';

export const ADD_DATA_FRAGMENT_TO_STATIC_DATA_HOLDER = 'ADD_DATA_FRAGMENT_TO_STATIC_DATA_HOLDER';
export const REMOVE_DATA_FRAGMENT_FROM_STATIC_DATA_HOLDER = 'REMOVE_DATA_FRAGMENT_FROM_STATIC_DATA_HOLDER';
export const ADD_DATA_FRAGMENTS_TO_STATIC_DATA_HOLDER = 'ADD_DATA_FRAGMENTS_TO_STATIC_DATA_HOLDER';

export const UPDATE_STATIC_DATA_HOLDERS_DATA = 'UPDATE_STATIC_DATA_HOLDERS_DATA';
export const SYNCHRONIZE_STATIC_DATA_HOLDERS_DATA = 'SYNCHRONIZE_STATIC_DATA_HOLDERS_DATA';
export const CLEAR_STATIC_DATA_HOLDERS_DELTA = 'CLEAR_STATIC_DATA_HOLDERS_DELTA';


export interface INewStaticDataHolderData {
    name: string;
}

export interface IUpdateableStaticDataHolderData extends INewStaticDataHolderData {
    id: string;
}

export interface IStaticDataHolder extends IUpdateableStaticDataHolderData, Synchronizable {
    children: Array<string>,
    archived?: boolean,
}

export interface StaticDataHolderState extends NormalizedModel<IStaticDataHolder> {
    selected: string | undefined,
    areStaticDataHoldersReordered: boolean,
    fragments: DataFragmentState,
}

export interface SelectStaticDataHolderAction {
    type: typeof SELECT_STATIC_DATA_HOLDER,
    id: string,
}

export interface UnSelectStaticDataHolderAction {
    type: typeof UN_SELECT_STATIC_DATA_HOLDER,
}

export interface ReOrderStaticDataHoldersAction {
    type: typeof RE_ORDER_STATIC_DATA_HOLDERS,
    sourceIndex: number,
    destinationIndex: number,
}

export interface AddStaticDataHolderRequestAction {
    type: typeof ADD_STATIC_DATA_HOLDER_REQUEST,
    payload: INewStaticDataHolderData,
}

export interface AddStaticDataHolderAction {
    type: typeof ADD_STATIC_DATA_HOLDER,
    payload: IStaticDataHolder,
    currentTime: string,
}

export interface DeleteStaticDataHolderAction {
    type: typeof DELETE_STATIC_DATA_HOLDER,
    id: string,
    currentTime: string,
}

export interface UpdateStaticDataHolderAction {
    type: typeof UPDATE_STATIC_DATA_HOLDER,
    payload: IUpdateableStaticDataHolderData,
    currentTime: string,
}

export interface AddDataFragmentToStaticDataHolderAction {
    type: typeof ADD_DATA_FRAGMENT_TO_STATIC_DATA_HOLDER,
    staticDataHolderId: string,
    dataFragmentId: string,
}

export interface RemoveDataFragmentFromStaticDataHolderAction {
    type: typeof REMOVE_DATA_FRAGMENT_FROM_STATIC_DATA_HOLDER,
    staticDataHolderId: string,
    dataFragmentId: string,
}

export interface AddDataFragmentsToStaticDataHolderAction {
    type: typeof ADD_DATA_FRAGMENTS_TO_STATIC_DATA_HOLDER,
    payload: Array<{
        staticDataHolderId: string,
        dataFragmentId: string,
    }>,
}

export interface UpdateStaticDataHoldersData {
    type: typeof UPDATE_STATIC_DATA_HOLDERS_DATA,
    data: Array<IStaticDataHolder>,
}

export interface SynchronizeStaticDataHoldersData {
    type: typeof SYNCHRONIZE_STATIC_DATA_HOLDERS_DATA,
    data: Array<IStaticDataHolder>,
    reOrderedTopLevelDataFragments: ReOrderedListsForEntity,
}

export interface ClearStaticDataHoldersDelta {
    type: typeof CLEAR_STATIC_DATA_HOLDERS_DELTA,
}

export type StaticDataHolderActionTypes = SelectStaticDataHolderAction | UnSelectStaticDataHolderAction | ReOrderStaticDataHoldersAction | AddStaticDataHolderRequestAction | AddStaticDataHolderAction | DeleteStaticDataHolderAction | UpdateStaticDataHolderAction | AddDataFragmentToStaticDataHolderAction | RemoveDataFragmentFromStaticDataHolderAction | AddDataFragmentsToStaticDataHolderAction | UpdateStaticDataHoldersData | SynchronizeStaticDataHoldersData | ClearStaticDataHoldersDelta | DataFragmentActionTypes;