import React from 'react';
import styles from './Members.module.scss';
import { Switch, Route, Redirect } from "react-router-dom";
import { ApplicationState } from '../../shared/store/types';
import { Permissions } from '../../shared/store/permissions/types';
import Configuration from './configuration/Configuration';
import List from './list/List';
import { connect } from 'react-redux';
import Header from '../../widgets/header/Header';
import { ReactComponent as MembersIcon } from '../../common/assets/user-favorite-alt.svg';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canViewMembersConfiguration = state.permissions.myPermissions.general.MembersConfiguration === Permissions.WRITE || state.permissions.myPermissions.general.MembersConfiguration === Permissions.READ;

    const isOnline = state.myData.isOnline

    return {
        canGoToConfiguration: canViewMembersConfiguration,
        canGoToList: true,
        currentMembersCount: Object.keys(state.members.byId).length,
        totalMembersCount: state.myData.totalNoOfMembers,
        hasMembers: state.members.allEntries.length > 0,
        isOnline,
        isPresentable: state.myData.isLoaded || state.myData.isPartiallyLoaded,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;

const ConnectedMembers: React.FC<Props> = (props) => {

    const { canGoToConfiguration, canGoToList, currentMembersCount, 
        totalMembersCount, hasMembers, isOnline, isPresentable } = props;

    if (!(canGoToConfiguration || canGoToList)) {
        return <Redirect to="/dashboard" />;
    }
    const tabs = [
        {
            tabName: "List",
            link: "/members/list",
            hasAccess: canGoToList,
            dataSelector: "members-list-tab",
        },
        {
            tabName: "Configuration",
            link: "/members/configuration",
            hasAccess: canGoToConfiguration,
            dataSelector: "members-configuration-tab",
        }
    ];
    return (
        <section className={styles.FocusSpace}>

            <Header
                pageName={"members"}
                tabs={tabs}    
                icon={<MembersIcon />}
                currentCount={currentMembersCount}
                totalCount={totalMembersCount}
            />

            {isPresentable && <div className={styles.innerFocus}>
                <Switch>
                    <Route path="/members/configuration" render={() => <Configuration />} />
                    <Route path="/members/list" render={() => <List />} />
                    <Route render={() => <Redirect to={canGoToList && (hasMembers || isOnline || !canGoToConfiguration) ? '/members/list' : '/members/configuration'} />} />
                </Switch>
            </div>}

        </section>
    );
};

const Members = connect(mapStateToProps)(ConnectedMembers);

export default Members;