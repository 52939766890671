import React, { Component, ReactNode } from 'react';
import styles from './ModifyForm.module.scss';

import { translatePhrase } from '../../shared/helpers/translation';
import Button from '../button/CommonButton';
import { ReactComponent as Close } from ".././../common/assets/close.svg";
import { ReactComponent as Plus } from ".././../common/assets/plus.svg";
import { Dispatch } from 'redux';
import { clearErrorMessage, setErrorMessage } from '../../shared/store/my-data/actions';
import { connect } from 'react-redux';



type OwnProps = {
    isNew: boolean,
    hideCancel?: boolean,
    children: ReactNode,

    submitForm: () => void,
    validateForm?: () => boolean | string | Promise<boolean | string>,
    cancelForm: () => void,
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setErrorMessage: (message: string) => dispatch(setErrorMessage(message)),
        clearErrorMessage: () => dispatch(clearErrorMessage()),
    };
}

type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = OwnProps & DispatchProps;

type OwnState = { submitTimer: number | undefined }

class ConnectedModifyForm extends Component<Props, OwnState> {
    state: OwnState = {
        submitTimer: undefined,
    }

    static defaultProps = {
        isNew: false,
        hideCancel: false
    }

    submitCard = () => {
        this.props.submitForm();
        this.setState({
            submitTimer: undefined
        });
    }

    markForSubmit = async () => {
        let that = this;

        if (this.props.validateForm) {
            let validationResult = await this.props.validateForm();

            if (typeof (validationResult) === 'string') {
                this.props.setErrorMessage(validationResult);

                setTimeout(() => {
                    that.props.clearErrorMessage();
                }, 4000);

                return;
            }
        }

        const timeout = window.setTimeout(that.submitCard, 500);
        this.setState({
            submitTimer: timeout
        });
    }

    render() {
        return (
            <section className={(this.props.isNew ? styles.addForm : styles.modifyForm) + ' ignore-react-onclickoutside'}>
                {this.props.children}
                {this.state.submitTimer ? <button className={styles.confirmFormButton}>{this.props.isNew ? translatePhrase('Added') : translatePhrase('Updated')}</button>
                    : <div className={styles.buttonsHolder}>
                        <Button onClick={this.markForSubmit} text={this.props.isNew ? translatePhrase('Add') : translatePhrase('Update')} padding={'0px 15px'} isRounded size={'small'} color={'contrast'} icon={<Plus />} />
                        <Button onClick={this.props.cancelForm} isRounded padding={'0px 15px'} size={'small'} color={'primary'} type={'secondary'} text={translatePhrase('Cancel')} icon={<Close />} />
                    </div>}
            </section>
        );
    }
}

const ModifyForm = connect(null, mapDispatchToProps)(ConnectedModifyForm);

export default ModifyForm;