import React, { Component } from 'react';
import styles from './UserFilter.module.scss';

import { ReactComponent as CancelIcon } from '../../../common/assets/close.svg';
import { ReactComponent as FilterIcon } from '../../../common/assets/filter.svg';
import { ReactComponent as SmartFilterIcon } from '../../../assets/new-custom-icons/dashboard/smartfilter.svg';

import { translatePhrase } from '../../../shared/helpers/translation';

import { ApplicationState } from '../../../shared/store/types';

import { filterUserTable } from '../../../shared/store/users/actions';

import { connect } from 'react-redux';
import { FieldType } from '../../../shared/store/custom-fields/types';
import { Dispatch } from 'redux';
import InputText from '../../../widgets/form/InputText';
import MultiSelectInputText from '../../../widgets/form/MultiSelectInput';
import { getAncestorChainOfLocation } from '../../../shared/helpers/locations';
import Button from '../../../widgets/button/CommonButton';

import onClickOutside from "react-onclickoutside";
import LoaderModal from '../../../widgets/loader/LoaderModal';

type OwnProps = {
    closeFilter: () => void,
    tags: Array<string>,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    return {
        projectsData: state.structure.projects,
        levelsData: state.structure.levels,
        rolesData: state.structure.roles,
        locationsData: state.structure.locations,
        usersData: state.users,
        customFieldsData: state.users.customFields,
        customFieldOptionsData: state.users.customFieldOptions,
        roleCustomFieldsData: state.structure.roles.customFields,
        roleCustomFieldOptionsData: state.structure.roles.customFieldOptions,
        myId: state.myData.id,

        filters: state.users.filters,
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        filterUserTable: (projects: Array<string>, levels: Array<string>, roles: Array<string>, locations: Array<string>, unsynced: boolean, areLowerLevelsIncluded: boolean, archived: boolean, isOnline: boolean, isBetaTester: boolean, customFields: { [customFieldId: string]: Array<string> }) => dispatch(filterUserTable(projects, levels, roles, locations, unsynced, areLowerLevelsIncluded, archived, isOnline, isBetaTester, customFields)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;


type OwnState = {
    projects: Array<string>,
    levels: Array<string>,
    roles: Array<string>,
    locations: Array<string>,
    unsynced: boolean,
    isOnline: boolean,
    isBetaTester: boolean,
    archived: boolean,
    toastLoader: JSX.Element | undefined,
    areLowerLevelsIncluded: boolean,

    customFields: {
        [customFieldId: string]: Array<string>,
    }

    clearKey: number,
};

class ConnectedUserModify extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            projects: props.filters.projects,
            levels: props.filters.levels,
            roles: props.filters.roles,
            locations: props.filters.locations,
            unsynced: props.filters.unsynced,
            archived: false,
            isOnline: props.filters.isOnline,
            isBetaTester: props.filters.isBetaTester,
            areLowerLevelsIncluded: true,
            customFields: props.filters.customFields,
            toastLoader: undefined,
            clearKey: 0,
        };
    }

    changeProjects = (projects: Array<string>) => {
        const newLevels = this.state.levels.filter(levelId => {
            const level = this.props.levelsData.byId[levelId];
            return projects.includes(level.project);
        });

        const newRoles = this.state.roles.filter(roleId => {
            const role = this.props.rolesData.byId[roleId];
            return newLevels.includes(role.level);
        });

        const newLocations = this.state.locations.filter(locationId => {
            const location = this.props.locationsData.byId[locationId];

            if (location.parent) {
                let projectId = '';
                let levelIndex = 0;

                if (location.parent in this.props.locationsData.byId) {
                    let currentLocation = location;

                    while (currentLocation.parent && currentLocation.parent in this.props.locationsData.byId) {
                        currentLocation = this.props.locationsData.byId[currentLocation.parent];
                        levelIndex += 1;
                    }

                    if (currentLocation.parent && currentLocation.parent in this.props.projectsData.byId) {
                        projectId = currentLocation.parent;
                    }
                } else if (location.parent in this.props.projectsData.byId) {
                    projectId = location.parent;
                }

                const project = this.props.projectsData.byId[projectId];

                if (!project) {
                    console.error('Location: ' + location.name + ' not associated with a project');
                    return false;
                }

                const levelId = project.children[levelIndex];

                return (projects.length === 0 || projects.includes(projectId)) && (newLevels.length === 0 || newLevels.includes(levelId));
            }

            return false;
        });

        this.setState({
            projects,
            levels: newLevels,
            roles: newRoles,
            locations: newLocations,
        });
    }

    changeLevels = (levels: Array<string>) => {

        const newRoles = this.state.roles.filter(roleId => {
            const role = this.props.rolesData.byId[roleId];
            return levels.includes(role.level);
        });

        const newLocations = this.state.locations.filter(locationId => {
            const location = this.props.locationsData.byId[locationId];

            if (location.parent) {
                let projectId = '';
                let levelIndex = 0;

                if (location.parent in this.props.locationsData.byId) {
                    let currentLocation = location;

                    while (currentLocation.parent && currentLocation.parent in this.props.locationsData.byId) {
                        currentLocation = this.props.locationsData.byId[currentLocation.parent];
                        levelIndex += 1;
                    }

                    if (currentLocation.parent && currentLocation.parent in this.props.projectsData.byId) {
                        projectId = currentLocation.parent;
                    }
                } else if (location.parent in this.props.projectsData.byId) {
                    projectId = location.parent;
                }

                const project = this.props.projectsData.byId[projectId];

                if (!project) {
                    console.error('Location: ' + location.name + ' not associated with a project');
                    return false;
                }

                const levelId = project.children[levelIndex];

                return (this.state.projects.length === 0 || this.state.projects.includes(projectId)) && (levels.length === 0 || levels.includes(levelId));
            }

            return false;
        });

        this.setState({
            levels,
            locations: newLocations,
            roles: newRoles,
        });
    }

    changeRoles = (roles: Array<string>) => {
        this.setState({
            roles,
        });
    }

    changeLocations = (locations: Array<string>) => {
        this.setState({
            locations,
        });
    }

    changeArchived = (archived: boolean) => {

        this.setState({
            archived,
        });

        setTimeout(() => {
            this.submitFilters();
        }, 100);
    }

    changeIsOnline = (isOnline: boolean) => {

        this.setState({
            isOnline,
        });

        setTimeout(() => {
            this.submitFilters();
        }, 100);
    }

    changeIsBetaTester = (isBetaTester: boolean) => {

        this.setState({
            isBetaTester,
        });

        setTimeout(() => {
            this.submitFilters();
        }, 100);
    }

    changeUnsynced = (unsynced: boolean) => {

        this.setState({
            unsynced,
        });

        setTimeout(() => {
            this.submitFilters();
        }, 100);
    }

    changeCustomFieldOptions = (customFieldId: string, options: Array<string>) => {
        const newCustomFields = {
            ...this.state.customFields,
            [customFieldId]: options,
        };

        this.setState({
            customFields: newCustomFields,
        });
    }

    submitFilters = () => {
        let prevFilters = {
            projects: this.state.projects,
            levels: this.state.levels,
            roles: this.state.roles,
            locations: this.state.locations,
            unsynced: this.state.unsynced,
            areLowerLevelsIncluded: this.state.areLowerLevelsIncluded,
            archived: this.state.archived,
            isOnline: this.state.isOnline,
            isBetaTester: this.state.isBetaTester,
            customFields: this.state.customFields,
        }

        if (JSON.stringify(prevFilters) !== JSON.stringify(this.props.filters)) {
            this.showToastLoader(translatePhrase("Please wait"));

            setTimeout(() => {
                this.props.filterUserTable(
                    this.state.projects,
                    this.state.levels,
                    this.state.roles,
                    this.state.locations,
                    this.state.unsynced,
                    this.state.areLowerLevelsIncluded,
                    this.state.archived,
                    this.state.isOnline,
                    this.state.isBetaTester,
                    this.state.customFields,
                );

                if (JSON.stringify(prevFilters) === JSON.stringify(this.props.filters)) {
                    this.setState((prevState) => {
                        return {
                            toastLoader: undefined,
                            clearKey: prevState.clearKey + 1
                        }
                    });
                }
            }, 500);
        }
    }

    showToastLoader = (text: string) => {
        this.setState({
            toastLoader: <LoaderModal loaderText={[text]} isIndeterminate />
        });
    }

    clearFilters = () => {
        this.props.filterUserTable([], [], [], [], false, true, false, false, false, {});

        this.setState(prevState => {
            return {
                projects: [],
                levels: [],
                roles: [],
                locations: [],
                customFields: {},
                archived: false,
                unsynced: false,

                clearKey: prevState.clearKey + 1,
            };
        });
    }

    handleClickOutside = (event: MouseEvent) => {
        this.props.closeFilter();
    }

    render() {

        const projectsList = this.props.projectsData.allEntries.map(projectId => {
            return {
                name: translatePhrase(this.props.projectsData.byId[projectId].name),
                value: projectId,
            };
        });

        const levelsList = this.props.levelsData.allEntries
            .filter(levelId => {
                const level = this.props.levelsData.byId[levelId];
                const project = this.props.projectsData.byId[level.project];
                const highestSelectedLevelInProject = project.children.find(levelId => this.state.levels.includes(levelId));

                if (this.state.areLowerLevelsIncluded && highestSelectedLevelInProject) {
                    const isHigherLevelSelected = project.children.indexOf(levelId) > project.children.indexOf(highestSelectedLevelInProject);

                    if (isHigherLevelSelected) {
                        return false;
                    }
                }

                return this.state.projects.length === 0 || this.state.projects.includes(level.project);
            })
            .map(levelId => {
                const level = this.props.levelsData.byId[levelId];
                const project = this.props.projectsData.byId[level.project];
                return {
                    name: `${translatePhrase(level.name)} (${translatePhrase(project.name)})`,
                    value: levelId,
                };
            });

        let effectiveLevels: Array<string> = [];

        if (this.state.areLowerLevelsIncluded) {
            effectiveLevels = this.state.levels.map(levelId => {
                const level = this.props.levelsData.byId[levelId];
                const project = this.props.projectsData.byId[level.project];
                const levelIndex = project.children.indexOf(level.id);
                return project.children.slice(levelIndex)
            }).flat();
        } else {
            effectiveLevels = this.state.levels;
        }

        const rolesList = this.props.rolesData.allEntries
            .filter(roleId => {
                const role = this.props.rolesData.byId[roleId];
                return effectiveLevels.length === 0 || effectiveLevels.includes(role.level);
            })
            .map(roleId => {
                const role = this.props.rolesData.byId[roleId];
                const level = this.props.levelsData.byId[role.level];
                const project = this.props.projectsData.byId[level.project];

                return {
                    name: `${translatePhrase(role.name)} (${translatePhrase(level.name)} - ${translatePhrase(project.name)})`,
                    value: roleId,
                };
            });

        const locationsList = this.props.locationsData.allEntries
            .filter(locationId => {
                const location = this.props.locationsData.byId[locationId];

                const locationChain = getAncestorChainOfLocation(location.id).reverse().concat([location.id]);

                const isAncestorArchived = locationChain.some(locationId => this.props.locationsData.byId[locationId].archived);

                if (isAncestorArchived) {
                    return false;
                }

                const highestLocation = this.props.locationsData.byId[locationChain[0]];
                const projectId = highestLocation.parent ? highestLocation.parent : '';

                const project = this.props.projectsData.byId[projectId];

                if (!project) {
                    console.error('Location: ' + location.name + ' not associated with a project');
                    return false;
                }

                const levelId = project.children[locationChain.length - 1];

                return (this.state.projects.length === 0 || this.state.projects.includes(projectId)) && (this.state.levels.length === 0 || this.state.levels.includes(levelId));
            })
            .map(locationId => {
                const location = this.props.locationsData.byId[locationId];
                let parentName = '';

                if (location.parent) {
                    if (location.parent in this.props.locationsData.byId) {
                        let currentLocation = location;
                        let projectName = '';

                        while (currentLocation.parent && currentLocation.parent in this.props.locationsData.byId) {
                            currentLocation = this.props.locationsData.byId[currentLocation.parent];
                        }

                        if (currentLocation.parent && currentLocation.parent in this.props.projectsData.byId) {
                            projectName = this.props.projectsData.byId[currentLocation.parent].name;
                        }

                        parentName = translatePhrase(this.props.locationsData.byId[location.parent].name) + ' - ' + translatePhrase(projectName);
                    } else if (location.parent in this.props.projectsData.byId) {
                        parentName = translatePhrase(this.props.projectsData.byId[location.parent].name);
                    }
                }

                return {
                    name: `${translatePhrase(location.name)} (${parentName})`,
                    value: locationId,
                };
            });

        return (
            <section className={styles.commonModalHolder + ' ' + styles.filterHolder}>
                <div className={styles.filterCloseButton} onClick={this.props.closeFilter}>
                    <Button title={translatePhrase("Close")} icon={<CancelIcon />} size={'small'} isRounded />
                </div>

                <section className={styles.addOrModifyListCard}>
                    <header>
                        <h2> <FilterIcon /> {translatePhrase('Filter')}</h2>
                        <Button onClick={this.clearFilters} type={'tertiary'} text={translatePhrase('Clear Filter')} isRounded={true} size={'small'} padding={'0px 10px'} />
                    </header>

                    <div className={styles.smartAndAdvancedFilterContainer}>
                        <div className={styles.smartFilter}>
                            <h4> <SmartFilterIcon />  {translatePhrase('Quick Filters')} </h4>
                            <p> {translatePhrase('Coming soon')} </p>
                        </div>

                        <div className={styles.container}>
                            <div key={this.state.clearKey} className={styles.allInputsHolder}>
                                <header className={styles.groupInputHeading}>
                                    <h4> {translatePhrase("Structure")} </h4>
                                </header>
                                <section className={styles.groupInputs}>
                                    <div className={styles.inputSegment} onMouseLeave={this.submitFilters}>
                                        <MultiSelectInputText
                                            options={projectsList}
                                            onChange={this.changeProjects}
                                            default={this.state.projects}
                                            placeholder={translatePhrase('Projects')}
                                            isAutoFocus={true}
                                        />
                                    </div>
                                    <div className={styles.inputSegment} onMouseLeave={this.submitFilters}>
                                        <MultiSelectInputText
                                            options={levelsList}
                                            onChange={this.changeLevels}
                                            default={this.state.levels}
                                            placeholder={translatePhrase('Levels')}
                                            isAutoFocus={true}
                                        />
                                    </div>
                                    <div className={styles.inputSegment} onMouseLeave={this.submitFilters}>
                                        <MultiSelectInputText
                                            options={locationsList}
                                            onChange={this.changeLocations}
                                            default={this.state.locations}
                                            placeholder={translatePhrase('Locations')}
                                            isAutoFocus={true}
                                        />
                                    </div>
                                    <div className={styles.inputSegment} onMouseLeave={this.submitFilters}>
                                        <MultiSelectInputText
                                            options={rolesList}
                                            onChange={this.changeRoles}
                                            default={this.state.roles}
                                            placeholder={translatePhrase('Roles')}
                                            isAutoFocus={true}
                                        />
                                    </div>
                                </section>

                                {this.props.customFieldsData.allFields.filter(customFieldId => {
                                    if (customFieldId === this.props.usersData.nameFieldId || customFieldId === this.props.usersData.subTitleFieldId || customFieldId === this.props.usersData.locationFieldId) {
                                        return false;
                                    }
                                    const customField = this.props.customFieldsData.byId[customFieldId];
                                    return customField.type === FieldType.SINGLE_SELECT || customField.type === FieldType.MULTI_SELECT;
                                }).length > 0 && <React.Fragment>
                                        <header className={styles.groupInputHeading}>
                                            <h4> {translatePhrase("Type Fields")}  </h4>
                                        </header>
                                        <section className={styles.groupInputs}>
                                            {this.props.customFieldsData.allFields.map(customFieldId => {
                                                const customField = this.props.customFieldsData.byId[customFieldId];
                                                const customFieldOptions = customField.choices.map(optionId => {
                                                    return {
                                                        name: translatePhrase(this.props.customFieldOptionsData.byId[optionId].name),
                                                        value: optionId,
                                                    };
                                                });

                                                return <div className={styles.inputSegment} key={customFieldId} onMouseLeave={this.submitFilters}>
                                                    <MultiSelectInputText
                                                        options={customFieldOptions}
                                                        onChange={this.changeCustomFieldOptions.bind(this, customFieldId)}
                                                        default={this.state.customFields[customFieldId] || []}
                                                        placeholder={translatePhrase(customField.name)}
                                                    />
                                                </div>
                                            })}
                                        </section>
                                    </React.Fragment>}

                                <header className={styles.groupInputHeading}>
                                    <h4> {translatePhrase("Misc")} </h4>
                                </header>
                                <section className={styles.groupInputs}>
                                    <div className={styles.inputSegment}>
                                        <InputText isBooleanField={true} placeholder={translatePhrase('Unsynced')} onChange={value => this.changeUnsynced(value === 'Yes')}
                                            defaultBooleanValue={this.props.filters.unsynced ? true : false} />
                                    </div>
                                    <div className={styles.inputSegment}>
                                        <InputText isBooleanField={true} placeholder={translatePhrase('Archived')} onChange={value => this.changeArchived(value === 'Yes')}
                                            defaultBooleanValue={this.props.filters.archived ? true : false} />
                                    </div>
                                    <div className={styles.inputSegment}>
                                        <InputText isBooleanField={true} placeholder={translatePhrase('Is Online')} onChange={value => this.changeIsOnline(value === 'Yes')}
                                            defaultBooleanValue={this.props.filters.isOnline ? true : false} />
                                    </div>
                                    <div className={styles.inputSegment}>
                                        <InputText isBooleanField={true} placeholder={translatePhrase('Is Tester')} onChange={value => this.changeIsBetaTester(value === 'Yes')}
                                            defaultBooleanValue={this.props.filters.isBetaTester ? true : false} />
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>

                </section>

                {this.state.toastLoader}
            </section>);
    }
}

const EnhancedUserModify = onClickOutside(ConnectedUserModify, {
    excludeScrollbar: true
});

const UserModify = connect(mapStateToProps, mapDispatchToProps)(EnhancedUserModify);

export default UserModify;
