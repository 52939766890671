import React from 'react';
import styles from './Signature.module.scss';
import SignatureCanvas from 'react-signature-canvas';
import Button from '../button/CommonButton';
import { ReactComponent as CancelIcon } from '../../assets/cancel.svg';
import { ReactComponent as CheckIcon } from '../../common/assets/check.svg';
import { translatePhrase } from '../../shared/helpers/translation';

type OwnProps = {
    confirmSignature: (signatureImage: string) => void;
};


const SignatureWidget: React.FC <OwnProps> = (props) => {

    const clearSignature = () => {
        signPadRef.clear();
        props.confirmSignature(signPadRef.getTrimmedCanvas().toDataURL('image/png'));
    }
    
    const getSignature = () => {        
        props.confirmSignature(signPadRef.getTrimmedCanvas().toDataURL('image/png'));
    }

    let signPadRef: any;

    return (
        <section className={styles.SignatureWidget}>
            <SignatureCanvas ref={(ref) => { signPadRef = ref }} penColor='black' canvasProps={{width: 300, height: 300, className: styles.SignatureCanvas}} />
            <div className={styles.actionButtons}>
                <Button type={'secondary'} padding={'0px 10px'} onClick={clearSignature} color={'danger'} icon={<CancelIcon />} text={translatePhrase('Clear')} size={'small'} isBlock={false} isRounded={true} />
                <Button padding={'0px 10px'} onClick={getSignature} color={'primary'} icon={<CheckIcon />} text={translatePhrase('Save')} size={'small'} isBlock={false} isRounded={true} />
            </div>
        </section>
    );
}


export default SignatureWidget;