import React, { useState, useEffect } from 'react';
import styles from './LoaderModal.module.scss';
import { ReactComponent as AlertIcon } from '../../assets/action-icons/exclamation.svg';
import { ReactComponent as SuccessIcon } from '../../assets/action-icons/checkmark.svg';
import { ReactComponent as CheckIcon } from '../../assets/new-custom-icons/profile/check-mark.svg';
import { ReactComponent as CloseIcon } from '../../assets/action-icons/cancel.svg';
import { ReactComponent as SyncIcon } from '../../common/assets/sync.svg';
import { CircularLoader } from './CircularLoader';

type OwnProps = {
    loaderText: Array<string>,
    interval?: number,
    isIndeterminate?: boolean,
    isError?: boolean,
    isInfo?: boolean,
    isSuccess?: boolean,
    isInvisible?: boolean,
    isOutsideClickable?: boolean,
    isNotAllowedToCloseManually?: boolean,
    forceShowClose?: boolean,
    shouldAllowRetry?: boolean,
    link?: JSX.Element,
    progress?: number,
    confirmApproval?: (flag: boolean) => void;

    closeModal?: () => void,
    retryCallback?: () => void,
};


const LoaderModal: React.FC<OwnProps> = (props) => {
    const [loaderIndex, setIndex] = useState<number>(0);
    const [showModal, setShowModal] = useState<boolean>(true);
    const [isLongSinceModalStarted, setIsLongSinceModalStarted] = useState<boolean>(false);

    useEffect(() => {
        const TWO_MINUTES = 2 * 60 * 1000;

        const timeoutId = window.setTimeout(() => {
            setIsLongSinceModalStarted(true);
        }, TWO_MINUTES);

        let intervalId: number | undefined;

        if (props.loaderText.length > 1) {
            intervalId = window.setInterval(() => {
                if (loaderIndex < (props.loaderText.length - 1)) {
                    setIndex(loaderIndex + 1);
                } else {
                    setIndex(0);
                }

            }, props.interval ? props.interval : 5000);
        }

        return () => {
            window.clearTimeout(timeoutId);
            if (intervalId) {
                window.clearInterval(intervalId)
            }
        };
    }, []);

    const closeModal = () => {
        setShowModal(false);
        props.closeModal && props.closeModal();
    };

    const shouldShowClose = props.forceShowClose || (!props.isNotAllowedToCloseManually && (props.isSuccess || props.isError || isLongSinceModalStarted));

    return (showModal ? <div data-selector="Loader Modal" className={styles.backDrop + ' ' + (props.isInvisible ? '' : styles.active) + ' ' + (props.isOutsideClickable ? styles.outsideClickable : '')}>
        <section className={styles.modal + ' ' + (props.isError ? styles.errorMessage : '') + (props.isSuccess ? styles.successMessage : '')}>
            <header className={props.isIndeterminate ? styles.indeterminate : ''}>
                {!props.isSuccess && !props.isError && !props.isInfo && (props.isIndeterminate ? <div className={styles.indeterminate}>
                    <div className={styles.dot}></div>
                    <div className={styles.dot}></div>
                    <div className={styles.dot}></div>
                </div> : <CircularLoader progress={props.progress} />)}

                {(props.isSuccess || props.isError || props.isInfo) && <div className={styles.iconContainer}>
                    {props.isSuccess && <SuccessIcon />}
                    {props.isError && <AlertIcon />}
                    {props.isInfo && <AlertIcon />}
                </div>}

                <h3> {props.loaderText[loaderIndex]}. <br /> {props.link} </h3>
            </header>

            {props.shouldAllowRetry && props.retryCallback && <section className={styles.exitModal} onClick={props.retryCallback}>
                <button> <SyncIcon /> </button>
            </section>}

            {shouldShowClose && <section className={styles.exitModal} onClick={closeModal}>
                <button> <CloseIcon /> </button>
            </section>}

            {props.confirmApproval !== undefined && <section className={styles.confirmationBox}>
                <button onClick={() => props.confirmApproval ? props.confirmApproval(true) : null}> <CheckIcon /> </button>
                <button onClick={() => props.confirmApproval ? props.confirmApproval(false) : null}> <CloseIcon /> </button>
            </section>}

        </section>
    </div> : <div></div>);
}


export default LoaderModal;