import { Component } from 'react';
import styles from './CardsList.module.scss';

import { translatePhrase } from '../../shared/helpers/translation';

import TriToggleCard, { TriToggleCardType } from './TriToggleCard';
import Toggle, { TriToggleOptions } from '../form/TriToggle';


type OwnProps = {
    heading: string,

    isReadOnly: boolean,
    cards: Array<TriToggleCardType>,

    negativeText?: string,
    middleText?: string,
    positiveText?: string,
}

type OwnState = {
    toggleState: TriToggleOptions
}

export default class CardsList extends Component<OwnProps, OwnState> {

    static defaultProps = {
        isReadOnly: false,
    };

    state: OwnState = {
        toggleState: 'middle'
    }

    handleToggleAll = (id: string, state: TriToggleOptions) => {
        this.setState({ toggleState: state })
        for (const card of this.props.cards) {
            card.onToggle(card.id, state)
        }
    }

    componentDidUpdate(prevProps: OwnProps, prevState: OwnState) {
        if (prevProps.cards !== this.props.cards) {
            this.updateTriToggleStates();
        }
    }

    updateTriToggleStates = () => {
        let middleArr = [];
        let positiveArr = [];
        let negativeArr = [];

        for (const card of this.props.cards) {
            if (card.toggleState === 'middle') {
                middleArr.push(card);
            } else if (card.toggleState === 'negative') {
                negativeArr.push(card);
            } else if (card.toggleState === 'positive')
                positiveArr.push(card);
        };

        if (middleArr.length === positiveArr.length && middleArr.length === negativeArr.length && negativeArr.length === positiveArr.length) {
            return;
        } else if (middleArr.length === this.props.cards.length) {
            this.setState({ toggleState: 'middle' })
        } else if (positiveArr.length === this.props.cards.length) {
            this.setState({ toggleState: 'positive' })
        } else if (negativeArr.length === this.props.cards.length) {
            this.setState({ toggleState: 'negative' })
        };
    }

    componentDidMount = () => {
        this.updateTriToggleStates();
    };



    getToggleClassName = () => {
        let arrToggleState: Array<string> = [];
        this.props.cards.map((card, index) => {
            arrToggleState.push(card.toggleState);
        })

        if (new Set(arrToggleState).size === 1) {
            return ''
        } else return styles.greyOutToggle
    }

    render() {
        let toggleLegend: string;

        switch (this.state.toggleState) {
            case 'positive':
                toggleLegend = 'WRITE';
                break;
            case 'middle':
                toggleLegend = 'READ';
                break;
            case 'negative':
                toggleLegend = 'NONE'
                break;
            default:
                throw new Error('No other states for toggle');
        }

        let cards: Array<JSX.Element> = this.props.cards.map((card, index) => {
            return <TriToggleCard
                key={card.id}
                isReadOnly={this.props.isReadOnly}
                card={card}
                isActive={false}
                toggleState={card.toggleState}
                onToggle={card.onToggle}
                negativeText={this.props.negativeText}
                middleText={this.props.middleText}
                positiveText={this.props.positiveText}
            />
        });

        const className = styles.cardsList + ' ignore-react-onclickoutside ignore-top-level-onclickoutside';
        const toggleClassName = this.getToggleClassName();

        const cardsListMarkup = <div>
            <header>
                <div className={styles.heading + ' ' + (!this.props.isReadOnly ? styles.disable_width : '')}>{translatePhrase(this.props.heading)} </div>

                <div className={`${styles.triToggleHolder} ${toggleClassName}`}>
                    <div className={styles.legend}>{toggleLegend}</div>
                    <Toggle toggleId={this.props.heading} toggleState={this.state.toggleState} onToggle={(id, state) => this.handleToggleAll(id, state)} />
                </div>
            </header>
            {cards.length > 0 && <div className={styles.content}>
                <ul className={styles.structureList}>
                    {cards}
                </ul>
            </div>}
        </div>

        return <section
            className={className}
        >
            {cardsListMarkup}
        </section>;
    }

}