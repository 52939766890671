import { NormalizedModel } from '../../normalized-model';

export const ADD_VARIABLE = 'ADD_VARIABLE';
export const ADD_VARIABLES = 'ADD_VARIABLES';
export const DELETE_VARIABLE = 'DELETE_VARIABLE';
export const UPDATE_VARIABLE = 'UPDATE_VARIABLE';

export const UPDATE_VARIABLES_DATA = 'UPDATE_VARIABLES_DATA';
export const SYNCHRONIZE_VARIABLES_DATA = 'SYNCHRONIZE_VARIABLES_DATA';
export const CLEAR_VARIABLES_DELTA = 'CLEAR_VARIABLES_DELTA';


export enum VariableType {
    BOOLEAN = 'BOOLEAN',
    NUMBER = 'NUMBER',
    TEXT = 'TEXT',
    DATE = 'DATE',

    USER = 'USER',
    MEMBER = 'MEMBER',
    GROUP = 'GROUP',
    LOCATION = 'LOCATION',
    ROLE = 'ROLE',
    LEVEL = 'LEVEL',
    PROJECT = 'PROJECT',
    WORKFLOW = 'WORKFLOW',
    STATUS = 'STATUS',
    REPORT = 'REPORT',

    STATIC_DATA = 'STATIC_DATA',
    DATA_FRAGMENT = 'DATA_FRAGMENT',

    TEXT_LIST = 'TEXT_LIST',
    USERS_LIST = 'USERS_LIST',
    MEMBERS_LIST = 'MEMBERS_LIST',
    GROUPS_LIST = 'GROUPS_LIST',
    LOCATIONS_LIST = 'LOCATIONS_LIST',
    ROLES_LIST = 'ROLES_LIST',
    LEVELS_LIST = 'LEVELS_LIST',
    PROJECTS_LIST = 'PROJECTS_LIST',
    WORKFLOWS_LIST = 'WORKFLOWS_LIST',
    REPORTS_LIST = 'REPORTS_LIST',
    DATA_FRAGMENTS_LIST = 'DATA_FRAGMENTS_LIST',

    TABLE_DATA = 'TABLE_DATA',

    STYLED_TABLE_CELL = 'STYLED_TABLE_CELL',
    STYLED_TABLE_ROW = 'STYLED_TABLE_ROW',
    STYLED_TABLE_DATA = 'STYLED_TABLE_DATA',
}

export interface INewVariableData {
    name: string;
    type: VariableType,
}

export interface IUpdateableVariableData extends INewVariableData {
    id: string,
}

export interface IVariable extends IUpdateableVariableData {
    archived?: boolean,
    createdTime: string,
    lastUpdatedTime: string,
    lastSyncedTime?: string,
}

export interface VariableState extends NormalizedModel<IVariable> {
}

export interface AddVariableAction {
    type: typeof ADD_VARIABLE,
    payload: IUpdateableVariableData,
    createdTime: string,
}

export interface AddVariablesAction {
    type: typeof ADD_VARIABLES,
    payload: Array<IUpdateableVariableData>,
    createdTime: string,
}

export interface DeleteVariableAction {
    type: typeof DELETE_VARIABLE,
    id: string,
    parentId: string,
}

export interface UpdateVariableAction {
    type: typeof UPDATE_VARIABLE,
    payload: IUpdateableVariableData,
}

export interface UpdateVariablesData {
    type: typeof UPDATE_VARIABLES_DATA,
    data: Array<IVariable>,
}

export interface SynchronizeVariablesData {
    type: typeof SYNCHRONIZE_VARIABLES_DATA,
    data: Array<IVariable>,
}

export interface ClearVariablesDelta {
    type: typeof CLEAR_VARIABLES_DELTA,
}

export type VariableActionTypes = AddVariableAction | AddVariablesAction | DeleteVariableAction | UpdateVariableAction | UpdateVariablesData | SynchronizeVariablesData | ClearVariablesDelta;