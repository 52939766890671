import moment from "moment";
import uuid from "uuid";
import { addFullPieces } from "../../../shared/store/flowchart/pieces/actions";
import { AllPieceTypes, ICustomFieldPiece, IGetValuePiece, IGroupedQuestionPiece, IGroupPiece, IHexagonalTruePiece, ISectionPiece, IStorePiece, IVariablePiece, PieceType } from "../../../shared/store/flowchart/pieces/types";
import store from "../../../shared/store/main";
import { ApplicationState } from "../../../shared/store/types";
import { setBetaIsolatedWorkflowTypePiece } from "../../../shared/store/workflows/types/actions";
import { IWorkflowType } from "../../../shared/store/workflows/types/types";

export function addNewScreen(workflowType: IWorkflowType) {
    const flowchartHolderElement = document.getElementById('flowchart-holder');
    const now = moment().format();

    if (!flowchartHolderElement) {
        throw new Error('This element needs to exist');
    }

    const newGroupId = uuid.v4();
    const newSectionPieceIdOne = uuid.v4();
    const newSectionPieceIdTwo = uuid.v4();

    const groupPiece: IGroupPiece = {
        id: newGroupId,
        type: PieceType.GROUP,
        createdTime: now,
        lastUpdatedTime: now,
        innerPiece: newSectionPieceIdOne,
    };

    const sectionPieceOne: ISectionPiece = {
        id: newSectionPieceIdOne,
        type: PieceType.SECTION,
        createdTime: now,
        lastUpdatedTime: now,
        nextPiece: newSectionPieceIdTwo,
    };

    const sectionPieceTwo: ISectionPiece = {
        id: newSectionPieceIdTwo,
        type: PieceType.SECTION,
        createdTime: now,
        lastUpdatedTime: now,
    };

    const allPiecesToAdd: Array<AllPieceTypes> = [groupPiece, sectionPieceOne, sectionPieceTwo];

    store.dispatch(addFullPieces(allPiecesToAdd));
    store.dispatch(setBetaIsolatedWorkflowTypePiece({
        piece: newGroupId,
        position: {
            x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
            y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
        },
    }, workflowType.id));
}

export function addNewQuestionInGroup(workflowType: IWorkflowType) {
    const flowchartHolderElement = document.getElementById('flowchart-holder');
    const now = moment().format();

    if (!flowchartHolderElement) {
        throw new Error('This element needs to exist');
    }

    const newGroupId = uuid.v4();
    const newGroupedQuestionId = uuid.v4();
    const truePieceIdForRequired = uuid.v4();

    const groupPiece: IGroupPiece = {
        id: newGroupId,
        type: PieceType.GROUP,
        createdTime: now,
        lastUpdatedTime: now,
        innerPiece: newGroupedQuestionId,
    };

    const groupedQuestionPiece: IGroupedQuestionPiece = {
        id: newGroupedQuestionId,
        type: PieceType.GROUPED_QUESTION,
        createdTime: now,
        lastUpdatedTime: now,
        customFieldId: workflowType.customFields.length > 0 ? workflowType.customFields[0] : undefined,
        isRequiredPiece: truePieceIdForRequired,
    }

    const truePiece: IHexagonalTruePiece = {
        id: truePieceIdForRequired,
        type: PieceType.HEXAGONAL_TRUE,
        createdTime: now,
        lastUpdatedTime: now,
    };

    const allPiecesToAdd: Array<AllPieceTypes> = [groupPiece, groupedQuestionPiece, truePiece];

    store.dispatch(addFullPieces(allPiecesToAdd));
    store.dispatch(setBetaIsolatedWorkflowTypePiece({
        piece: newGroupId,
        position: {
            x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
            y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
        },
    }, workflowType.id));
}

export function getMemberCustomFieldData(workflowType: IWorkflowType) {
    const flowchartHolderElement = document.getElementById('flowchart-holder');
    const now = moment().format();

    const applicationState: ApplicationState = store.getState();

    if (workflowType.affiliation !== 'member' || applicationState.members.types.allEntries.length === 0) {
        return;
    }

    if (!flowchartHolderElement) {
        throw new Error('This element needs to exist');
    }

    const getPieceId = uuid.v4();
    const memberVariablePieceId = uuid.v4();

    const memberType = workflowType.affiliatedEntity && workflowType.affiliatedEntity in applicationState.members.types.byId ? applicationState.members.types.byId[workflowType.affiliatedEntity] : applicationState.members.types.byId[applicationState.members.types.allEntries[0]]

    const getPiece: IGetValuePiece = {
        id: getPieceId,
        type: PieceType.GET_VALUE,
        createdTime: now,
        lastUpdatedTime: now,

        variablePiece: memberVariablePieceId,
        entityType: memberType.id,
        customFieldId: memberType.customFields.length > 0 ? memberType.customFields[0] : undefined,
    }

    const memberVariablePiece: IVariablePiece = {
        id: memberVariablePieceId,
        type: PieceType.VARIABLE,
        createdTime: now,
        lastUpdatedTime: now,
        variable: workflowType.seedAffiliationVariable,
    }

    const allPiecesToAdd: Array<AllPieceTypes> = [getPiece, memberVariablePiece];

    store.dispatch(addFullPieces(allPiecesToAdd));
    store.dispatch(setBetaIsolatedWorkflowTypePiece({
        piece: getPieceId,
        position: {
            x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
            y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
        },
    }, workflowType.id));

}

export function storeMemberCustomFieldData(workflowType: IWorkflowType) {
    const flowchartHolderElement = document.getElementById('flowchart-holder');
    const now = moment().format();

    const applicationState: ApplicationState = store.getState();

    if (workflowType.affiliation !== 'member' || applicationState.members.types.allEntries.length === 0) {
        return;
    }

    if (!flowchartHolderElement) {
        throw new Error('This element needs to exist');
    }

    const storePieceId = uuid.v4();
    const customFieldPieceId = uuid.v4();
    const memberVariablePieceId = uuid.v4();

    const memberType = workflowType.affiliatedEntity && workflowType.affiliatedEntity in applicationState.members.types.byId ? applicationState.members.types.byId[workflowType.affiliatedEntity] : applicationState.members.types.byId[applicationState.members.types.allEntries[0]]
    const memberCustomFieldId = memberType.customFields.length > 0 ? memberType.customFields[0] : undefined;

    if (!memberCustomFieldId) {
        return;
    }

    const memberCustomField = applicationState.members.types.customFields.byId[memberCustomFieldId];

    let workflowCustomFieldId = workflowType.customFields.find(customFieldId => {
        const customField = applicationState.workflows.types.customFields.byId[customFieldId];
        return customField.name === memberCustomField.name;
    });

    const storePiece: IStorePiece = {
        id: storePieceId,
        type: PieceType.STORE,
        createdTime: now,
        lastUpdatedTime: now,

        dataToStore: customFieldPieceId,
        variablePiece: memberVariablePieceId,
        entityType: memberType.id,
        customFieldId: memberType.customFields.length > 0 ? memberType.customFields[0] : undefined,
    }

    const customFieldPiece: ICustomFieldPiece = {
        id: customFieldPieceId,
        type: PieceType.CUSTOM_FIELD,
        createdTime: now,
        lastUpdatedTime: now,
        customField: workflowCustomFieldId,
    }

    const memberVariablePiece: IVariablePiece = {
        id: memberVariablePieceId,
        type: PieceType.VARIABLE,
        createdTime: now,
        lastUpdatedTime: now,
        variable: workflowType.seedAffiliationVariable,
    }

    const allPiecesToAdd: Array<AllPieceTypes> = [storePiece, customFieldPiece, memberVariablePiece];

    store.dispatch(addFullPieces(allPiecesToAdd));
    store.dispatch(setBetaIsolatedWorkflowTypePiece({
        piece: storePieceId,
        position: {
            x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
            y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
        },
    }, workflowType.id));

}



export function getGroupCustomFieldData(workflowType: IWorkflowType) {
    const flowchartHolderElement = document.getElementById('flowchart-holder');
    const now = moment().format();

    const applicationState: ApplicationState = store.getState();

    if (workflowType.affiliation !== 'group' || applicationState.groups.types.allEntries.length === 0) {
        return;
    }

    if (!flowchartHolderElement) {
        throw new Error('This element needs to exist');
    }

    const getPieceId = uuid.v4();
    const groupVariablePieceId = uuid.v4();

    const groupType = workflowType.affiliatedEntity && workflowType.affiliatedEntity in applicationState.groups.types.byId ? applicationState.groups.types.byId[workflowType.affiliatedEntity] : applicationState.groups.types.byId[applicationState.groups.types.allEntries[0]]

    const getPiece: IGetValuePiece = {
        id: getPieceId,
        type: PieceType.GET_VALUE,
        createdTime: now,
        lastUpdatedTime: now,

        variablePiece: groupVariablePieceId,
        entityType: groupType.id,
        customFieldId: groupType.customFields.length > 0 ? groupType.customFields[0] : undefined,
    }

    const groupVariablePiece: IVariablePiece = {
        id: groupVariablePieceId,
        type: PieceType.VARIABLE,
        createdTime: now,
        lastUpdatedTime: now,
        variable: workflowType.seedAffiliationVariable,
    }

    const allPiecesToAdd: Array<AllPieceTypes> = [getPiece, groupVariablePiece];

    store.dispatch(addFullPieces(allPiecesToAdd));
    store.dispatch(setBetaIsolatedWorkflowTypePiece({
        piece: getPieceId,
        position: {
            x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
            y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
        },
    }, workflowType.id));

}

export function storeGroupCustomFieldData(workflowType: IWorkflowType) {
    const flowchartHolderElement = document.getElementById('flowchart-holder');
    const now = moment().format();

    const applicationState: ApplicationState = store.getState();

    if (workflowType.affiliation !== 'group' || applicationState.groups.types.allEntries.length === 0) {
        return;
    }

    if (!flowchartHolderElement) {
        throw new Error('This element needs to exist');
    }

    const storePieceId = uuid.v4();
    const customFieldPieceId = uuid.v4();
    const groupVariablePieceId = uuid.v4();

    const groupType = workflowType.affiliatedEntity && workflowType.affiliatedEntity in applicationState.groups.types.byId ? applicationState.groups.types.byId[workflowType.affiliatedEntity] : applicationState.groups.types.byId[applicationState.groups.types.allEntries[0]]
    const groupCustomFieldId = groupType.customFields.length > 0 ? groupType.customFields[0] : undefined;

    if (!groupCustomFieldId) {
        return;
    }

    const groupCustomField = applicationState.groups.types.customFields.byId[groupCustomFieldId];

    let workflowCustomFieldId = workflowType.customFields.find(customFieldId => {
        const customField = applicationState.workflows.types.customFields.byId[customFieldId];
        return customField.affiliation === 'group' && customField.name === groupCustomField.name;
    });

    const storePiece: IStorePiece = {
        id: storePieceId,
        type: PieceType.STORE,
        createdTime: now,
        lastUpdatedTime: now,

        dataToStore: customFieldPieceId,
        variablePiece: groupVariablePieceId,
        entityType: groupType.id,
        customFieldId: groupType.customFields.length > 0 ? groupType.customFields[0] : undefined,
    }

    const customFieldPiece: ICustomFieldPiece = {
        id: customFieldPieceId,
        type: PieceType.CUSTOM_FIELD,
        createdTime: now,
        lastUpdatedTime: now,
        customField: workflowCustomFieldId,
    }

    const groupVariablePiece: IVariablePiece = {
        id: groupVariablePieceId,
        type: PieceType.VARIABLE,
        createdTime: now,
        lastUpdatedTime: now,
        variable: workflowType.seedAffiliationVariable,
    }

    const allPiecesToAdd: Array<AllPieceTypes> = [storePiece, customFieldPiece, groupVariablePiece];

    store.dispatch(addFullPieces(allPiecesToAdd));
    store.dispatch(setBetaIsolatedWorkflowTypePiece({
        piece: storePieceId,
        position: {
            x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
            y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
        },
    }, workflowType.id));

}