import React, { Component, ChangeEvent } from 'react';
import styles from './MemberCSV.module.scss';

import InputText from '../../../widgets/form/InputText';

import { translatePhrase } from '../../../shared/helpers/translation';

import { ApplicationState } from '../../../shared/store/types';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { ReactComponent as TemplateIcon } from '../../../common/assets/table.svg';
import { ReactComponent as ImportIcon } from '../../../assets/new-custom-icons/common/import.svg';
import { ReactComponent as ExportCSVIcon } from "../../../assets/new-custom-icons/common/export.svg";
import { ReactComponent as CancelIcon } from '../../../common/assets/close.svg';
import { ReactComponent as ExportIcon } from '../../../common/assets/export.svg';
import chevronIcon from '../../../assets/chevron-arrow-down.svg';

import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
import uuid from 'uuid';
import moment from 'moment';
import store from '../../../shared/store/main';

import { ILocation, LocationState } from '../../../shared/store/structure/location/types';
import { IMember, IUpdateableMemberData, MemberFilters, MemberState } from '../../../shared/store/members/types';
import { appendMembers, bulkAddMember, bulkUpdateMembersRequest, clearMemberEntries, filterMemberTable, setMemberTypeForCSV } from '../../../shared/store/members/actions';
import { getCustomFieldValueForInput, getReadableDataForCustomField, exampleValueForCustomField } from '../../../shared/store/custom-fields';
import { getAncestorChainOfLocation } from '../../../shared/helpers/locations';
import { IMemberType } from '../../../shared/store/members/types/types';
import { isUUID } from '../../../shared/helpers/utilities';
import { setToastMessage, clearToastMessage, freezeBackground, unFreezeBackground, setErrorMessage } from '../../../shared/store/my-data/actions';
import { filterMembers } from '../../../shared/helpers/filters';
import LoaderModal from '../../../widgets/loader/LoaderModal';
import { workerInstance } from '../../../helpers/common-worker';
import Button from '../../../widgets/button/CommonButton';
import { SearchIndex } from '../../../shared/helpers/common-types';
import axios from 'axios';
import { BASE_URL } from '../../../shared/store/url';
import { GroupState, IGroup } from '../../../shared/store/groups/types';
import { appendGroups, clearGroupEntries } from '../../../shared/store/groups/actions';
import { IProject } from '../../../shared/store/structure/project/types';
import { OnlineMemberQueryData, PageDataForOnlineEntities } from '../../../shared/helpers/synchronize/types';
import { IWorkflow } from '../../../shared/store/workflows/types';
import { appendWorkflows, clearWorkflowEntries } from '../../../shared/store/workflows/actions';
import { FieldType } from '../../../shared/store/custom-fields/types';
import { saveAs } from 'file-saver';

type OwnProps = {
    closeCSV: () => void,
};

const ID_COLUMN_VALUE = 'ID - DO NOT EDIT/DELETE THIS HEADER ROW';
const LEVEL_PREFIX = 'Level: ';
const GROUP_PREFIX = 'Group: ';
const GROUP_SUFFIX = ' groups';
const CUSTOM_FIELD_PREFIX = 'Custom field: ';
const COMPUTED_FIELD_PREFIX = 'Custom field (read only): ';

interface ColumnMapping {
    id: number;
    type?: number;
    createdTime?: number;
    lastUpdatedTime?: number;

    levels: {
        [key: string]: number;
    };
    groupTypes: {
        [key: string]: number;
    };
    customFields: {
        [key: string]: number;
    };
}

interface GroupDataHolder {
    [groupTypeId: string]: Array<string>;
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    return {
        structureData: state.structure,
        memberData: state.members,
        groupData: state.groups,
        languageData: state.internationalization.languages,
        isSuperUser: !isUUID(state.myData.id),
        isOnline: state.myData.isOnline,
        totalNoOfMembers: state.members.totalNoOfMembers,
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        bulkAddMembers: (payload: Array<IUpdateableMemberData>) => dispatch(bulkAddMember(payload)),
        bulkUpdateMemberRequest: (payload: Array<IUpdateableMemberData>) => dispatch(bulkUpdateMembersRequest(payload)),

        appendMembers: (members: Array<IMember>) => dispatch(appendMembers(members)),
        appendGroups: (members: Array<IGroup>) => dispatch(appendGroups(members)),
        appendWorkflows: (workflows: Array<IWorkflow>) => dispatch(appendWorkflows(workflows)),
        filterMemberTable: (projects: Array<string>, types: Array<string>, locations: Array<string>, customFields: { [customFieldId: string]: Array<string> }, createdDateRange: Array<string>, lastUpdatedDateRange: Array<string>, unsynced: boolean, archived: boolean) => dispatch(filterMemberTable(projects, types, locations, customFields, createdDateRange, lastUpdatedDateRange, unsynced, archived)),

        setToastMessage: (message: string, persistMessage: boolean) => dispatch(setToastMessage(message, persistMessage)),
        clearToastMessage: () => dispatch(clearToastMessage()),

        setErrorMessage: (message: string, persistMessage: boolean) => dispatch(setErrorMessage(message, persistMessage)),

        freezeBackground: () => dispatch(freezeBackground()),
        unFreezeBackground: () => dispatch(unFreezeBackground()),

        clearMemberEntries: () => dispatch(clearMemberEntries()),
        clearGroupEntries: () => dispatch(clearGroupEntries()),
        clearWorkflowEntries: () => dispatch(clearWorkflowEntries()),

        setMemberTypeForCSV: (id: string | undefined) => dispatch(setMemberTypeForCSV(id))
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;


type OwnState = {
    memberTypeId: string | undefined,
    successMessage: string,
    errorMessage: string,
    importTemplateData: Array<Array<string>>,
    exportData: Array<Array<string>>,
    erroneousData: Array<Array<string>>,
    showLoader: boolean,
    loaderText: Array<string>,
};

class ConnectedMemberModify extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        let memberTypeId: string | undefined;

        if (props.memberData.filters.types.length === 1) {
            memberTypeId = props.memberData.filters.types[0];
        }

        if (!memberTypeId && props.memberData.types.allEntries.length > 1) {
            memberTypeId = props.memberData.types.allEntries[0];
        }

        this.state = {
            memberTypeId,
            successMessage: '',
            errorMessage: '',
            importTemplateData: [],
            exportData: [],
            erroneousData: [],
            showLoader: false,
            loaderText: [],
        }
    }

    hideMessages = () => {
        setTimeout(() => {
            this.dismissSuccessMessage();
        }, 3000);
    }

    changeMemberType = (memberTypeId: string | undefined) => {

        this.setState({
            memberTypeId,
            exportData: [],
        });

        this.props.setMemberTypeForCSV(memberTypeId);
    }

    importRow = (memberRowData: Array<string>, createdMembers: Array<IUpdateableMemberData>, updatedMembers: Array<IUpdateableMemberData>, columnMapping: ColumnMapping, memberData: MemberState, groupData: GroupState) => {
        let memberID = memberRowData[columnMapping.id].trim();

        if (memberID && !(memberID in memberData.byId)) {
            throw new Error('Unknown ID for member');
        }

        const existingMember = memberID ? memberData.byId[memberID] : undefined;

        let memberTypeId: string | undefined;

        if (typeof columnMapping.type !== 'undefined') {
            const memberTypeName = memberRowData[columnMapping.type].trim();
            memberTypeId = memberData.types.allEntries.find(memberTypeId => {
                return memberData.types.byId[memberTypeId].name === memberTypeName;
            });
        } else {
            memberTypeId = existingMember?.type;
        }

        if (!memberTypeId) {
            throw new Error('Unknown member type');
        }

        const memberType = memberData.types.byId[memberTypeId];
        const project = this.props.structureData.projects.byId[memberType.project];

        let rawImportInput = '';
        let parentLocation: ILocation | undefined;
        let locationStateData = store.getState().structure.locations;

        // Given a list of sibling IDs and a location, return the data of the location which has the given name within that list of siblings
        function getLocationFromName(siblingLocationIds: Array<string>, locationName: string, locationStateData: LocationState) {
            const currentLocationId = siblingLocationIds.find(locationId => locationStateData.byId[locationId].name.trim().toLocaleLowerCase() === locationName.trim().toLocaleLowerCase());

            return typeof currentLocationId !== 'undefined' ? locationStateData.byId[currentLocationId] : undefined;
        }

        let currentLocation: ILocation | undefined = undefined;

        if (Object.keys(columnMapping.levels).length === 0) {
            if (!existingMember) {
                throw new Error('Location data must be provided for new member')
            } else {
                currentLocation = this.props.structureData.locations.byId[existingMember.location];
            }
        } else {
            // Loop through each level
            for (let i = 0; i < project.children.length; i += 1) {
                const levelId = project.children[i];
                const level = this.props.structureData.levels.byId[levelId];

                if (typeof columnMapping.levels[levelId] !== 'undefined') {
                    rawImportInput = memberRowData[columnMapping.levels[levelId]].trim();
                }

                if (!rawImportInput) {
                    throw new Error('Could not get location data for level: ' + level.name);
                }

                // Get current location data
                if (i === 0) {
                    currentLocation = getLocationFromName(locationStateData.byProject[project.id], rawImportInput, locationStateData);
                } else {

                    if (typeof parentLocation === 'undefined') {
                        throw new Error('The parent location must be defined for lower level locations');
                    }

                    currentLocation = getLocationFromName(parentLocation.children, rawImportInput, locationStateData);
                }

                if (typeof currentLocation === 'undefined') {
                    throw new Error('This location does not exist');
                }

                parentLocation = currentLocation;
            }
        }

        if (typeof currentLocation === 'undefined') {
            throw new Error('This member does not have a valid location');
        }

        const newMemberId = uuid.v4();
        const newMemberData: IUpdateableMemberData = {
            id: memberID || newMemberId,
            type: memberType.id,
            location: currentLocation.id,
            groups: {},
            customFields: {},
        }

        if (existingMember) {
            newMemberData.groups = JSON.parse(JSON.stringify(existingMember.groups));
            newMemberData.customFields = JSON.parse(JSON.stringify(existingMember.customFields));
        }

        const allowedLocations = getAncestorChainOfLocation(newMemberData.location).concat(newMemberData.location);

        // Loop through the groups and store the value in the new member data
        groupData.types.allEntries
            .filter(groupTypeId => {
                const groupType = groupData.types.byId[groupTypeId];
                return Object.keys(columnMapping.groupTypes).includes(groupTypeId) && groupType.project === project.id;
            })
            .forEach(groupTypeId => {
                const groupType = groupData.types.byId[groupTypeId];

                const uniqueFieldId = groupType.uniqueFieldId;

                if (!uniqueFieldId) {
                    if (!!memberID) {
                        // If member already exists, preserve the existing group links
                        const member = memberData.byId[memberID];
                        newMemberData.groups[groupTypeId] = member.groups[groupTypeId];
                    }

                    return;
                }

                if (groupTypeId in columnMapping.groupTypes) {
                    const uniqueField = groupData.types.customFields.byId[uniqueFieldId];

                    const groupIdentifiers = memberRowData[columnMapping.groupTypes[groupTypeId]].trim().split(',');

                    if (groupIdentifiers.length > 0 && groupIdentifiers.every(word => word.trim() !== '' && word.trim() !== '-')) {

                        const relevantGroupUniqueIdentifiers = groupData.allEntries
                            .filter(groupId => {
                                const group = groupData.byId[groupId];

                                if (!group) {
                                    return false;
                                }

                                if (group.type !== groupType.id) {
                                    return false;
                                }

                                if (!allowedLocations.includes(group.location)) {
                                    return false;
                                }

                                return true;
                            })
                            .map(groupId => {
                                const group = groupData.byId[groupId];
                                const uniqueValueForGroup = getReadableDataForCustomField(group.customFields[uniqueField.id], uniqueField, group.id, 'group');
                                return uniqueValueForGroup.trim();
                            });

                        for (const csvIdentifier of groupIdentifiers) {
                            if (!relevantGroupUniqueIdentifiers.includes(csvIdentifier)) {
                                throw new Error(`Unknown group - ${csvIdentifier} does not match any known groups`);
                            }
                        }

                        const groupIds = groupData.allEntries.filter(groupId => {
                            const group = groupData.byId[groupId];

                            if (!group) {
                                return false;
                            }

                            if (group.type !== groupType.id) {
                                return false;
                            }

                            if (!allowedLocations.includes(group.location)) {
                                return false;
                            }

                            const uniqueValueForGroup = getReadableDataForCustomField(group.customFields[uniqueField.id], uniqueField, group.id, 'group');
                            return groupIdentifiers.includes(uniqueValueForGroup);
                        });

                        newMemberData.groups[groupTypeId] = groupIds;
                    } else {
                        newMemberData.groups[groupTypeId] = [];
                    }
                }

            });

        // Loop through the groups and check if locations of all groups are in the right place
        groupData.types.allEntries
            .filter(groupTypeId => {
                const groupType = groupData.types.byId[groupTypeId];
                return groupType && groupType.project === project.id;
            })
            .forEach(groupTypeId => {
                const groupType = groupData.types.byId[groupTypeId];
                if (!groupType) {
                    console.warn(`GroupType with ID ${groupTypeId} not found.`);
                    return; // Skip this iteration if groupType is not found
                }

                const uniqueFieldId = groupType.uniqueFieldId;
                const uniqueField = groupData.types.customFields.byId[uniqueFieldId];

                if (!uniqueField) {
                    console.warn(`Unique field with ID ${uniqueFieldId} not found.`);
                    return; // Skip this iteration if the unique field is not found
                }

                if (groupTypeId in newMemberData.groups) {
                    for (const groupId of newMemberData.groups[groupTypeId]) {
                        let group = groupData.byId[groupId];

                        if (!group) {
                            throw new Error(`The group with ID ${groupId} could not be found for the member. It's possible that the group has been archived or under threshold.`);
                        }

                        const groupName = getReadableDataForCustomField(
                            group.customFields[uniqueFieldId], uniqueField, groupId, 'group'
                        );

                        if (!group.archived && !allowedLocations.includes(group.location)) {
                            throw new Error(`Incorrect location for group - ${groupName} does not match the member's location`);
                        }
                    }
                }
            });


        // Loop through all group types and ensure that required groups are imported
        groupData.types.allEntries
            .filter(groupTypeId => {
                const groupType = groupData.types.byId[groupTypeId];
                return groupType.project === project.id;
            })
            .forEach(groupTypeId => {
                const groupIds = newMemberData.groups[groupTypeId];
                const groupType = groupData.types.byId[groupTypeId];

                if (((!groupIds || groupIds.length === 0) && groupType.isRequired)) {
                    throw new Error(`Required group(s) of group type missing - ${groupType.name}`);
                }
            });

        const updatingCustomFieldIds = memberType.customFields.filter(customFieldId => {
            const customField = memberData.types.customFields.byId[customFieldId];
            return Object.keys(columnMapping.customFields).includes(customFieldId) && !customField.isComputed;
        });

        // Loop through the custom fields, and store the value in the new Location Data
        for (const customFieldId of updatingCustomFieldIds) {
            const customField = memberData.types.customFields.byId[customFieldId];

            rawImportInput = memberRowData[columnMapping.customFields[customFieldId]].trim();

            // Custom field values of the new location is stored
            const customFieldValue = getCustomFieldValueForInput(customField, rawImportInput, memberData.types.customFieldOptions);

            newMemberData.customFields[customField.id] = customFieldValue;

        }

        if (memberID in memberData.byId) {
            updatedMembers.push(newMemberData);
        } else {
            createdMembers.push(newMemberData);
        }

    }

    getMemberTypeId = () => {
        if (this.state.memberTypeId) {
            return this.state.memberTypeId;
        }

        if (this.props.memberData.filters.types.length === 1) {
            return this.props.memberData.filters.types[0];
        }

        if (this.props.memberData.types.allEntries.length === 1) {
            return this.props.memberData.types.allEntries[0];
        }

        return undefined;
    }

    getLevelIdFromName = (levelName: string) => {
        const memberTypeId = this.getMemberTypeId();

        if (!memberTypeId) {
            return undefined;
        }

        const memberType = this.props.memberData.types.byId[memberTypeId];

        const project = this.props.structureData.projects.byId[memberType.project];

        return project.children.find(levelId => {
            const level = this.props.structureData.levels.byId[levelId];
            return level.name.toLocaleLowerCase().trim() === levelName.toLocaleLowerCase().trim();
        });
    }

    getGroupTypeIdFromName = (groupTypeName: string) => {
        const memberTypeId = this.getMemberTypeId();

        if (!memberTypeId) {
            return undefined;
        }

        const memberType = this.props.memberData.types.byId[memberTypeId];

        const groupTypeIds = this.props.groupData.types.allEntries.filter(groupTypeId => {
            const groupType = this.props.groupData.types.byId[groupTypeId];
            return groupType.project === memberType.project;
        });

        return groupTypeIds.find(groupTypeId => {
            const groupType = this.props.groupData.types.byId[groupTypeId];
            return groupType.name.toLocaleLowerCase().trim() === groupTypeName.toLocaleLowerCase().trim();
        });
    }

    getCustomFieldIdFromName = (customFieldName: string) => {
        const memberTypeId = this.getMemberTypeId();

        if (!memberTypeId) {
            return undefined;
        }

        const memberType = this.props.memberData.types.byId[memberTypeId];

        return memberType.customFields
            .find(customFieldId => {
                const customField = this.props.memberData.types.customFields.byId[customFieldId];
                return customField.name.toLocaleLowerCase().trim() === customFieldName.toLocaleLowerCase().trim();
            });
    }

    getColumnMappingForImport = (headingRow: Array<string>): ColumnMapping => {
        const columnMapping: ColumnMapping = {
            id: 0,
            levels: {},
            groupTypes: {},
            customFields: {},
        };

        for (let i = 0; i < headingRow.length; i += 1) {
            const headingCell = headingRow[i];

            const hardCodedHeadings = [
                ID_COLUMN_VALUE.toLocaleLowerCase(),
                'type',
                'created time',
                'last updated time',
            ];

            if (hardCodedHeadings.includes(headingCell.toLocaleLowerCase())) {
                switch (headingCell.toLocaleLowerCase()) {
                    case ID_COLUMN_VALUE.toLocaleLowerCase():
                        columnMapping.id = i;
                        break;
                    case 'type':
                        columnMapping.type = i;
                        break;
                    case 'created time':
                        columnMapping.createdTime = i;
                        break;
                    case 'last updated time':
                        columnMapping.lastUpdatedTime = i;
                        break;
                    default:
                        ;
                }

                continue;
            }

            const levelId = headingCell.startsWith(LEVEL_PREFIX) ? this.getLevelIdFromName(headingCell.substring(LEVEL_PREFIX.length)) : undefined;
            const groupTypeId = headingCell.startsWith(GROUP_PREFIX) ? this.getGroupTypeIdFromName(headingCell.substring(GROUP_PREFIX.length, headingCell.length - GROUP_SUFFIX.length)) : undefined;
            const customFieldId = headingCell.startsWith(CUSTOM_FIELD_PREFIX) ?
                this.getCustomFieldIdFromName(headingCell.substring(CUSTOM_FIELD_PREFIX.length)) :
                headingCell.startsWith(COMPUTED_FIELD_PREFIX) ?
                    this.getCustomFieldIdFromName(headingCell.substring(COMPUTED_FIELD_PREFIX.length)) :
                    undefined;

            if (levelId) {
                columnMapping.levels[levelId] = i;
                continue;
            } else if (groupTypeId) {
                columnMapping.groupTypes[groupTypeId] = i;
                continue;
            } else if (customFieldId) {
                const customField = this.props.memberData.types.customFields.byId[customFieldId];
                if (!customField.isComputed) {
                    columnMapping.customFields[customFieldId] = i;
                }
                continue;
            }

            if (headingCell.toLocaleLowerCase() === 'id' || headingCell.toLocaleLowerCase().startsWith('id ')) {
                columnMapping.id = i;
                continue;
            }

            throw new Error(`The heading ${headingCell} does not map to any known value`);
        }

        return columnMapping;
    }

    fetchMembersDetailsFromServer = async (memberIds: Array<string>, groupIds: GroupDataHolder) => {

        if (!window.navigator.onLine) {
            this.setState({
                showLoader: true,
                errorMessage: 'You need to be online to import these members',
            });
        }

        try {
            const fetchUrl = BASE_URL + `/members-details/`;

            const membersDetailsResponse = await axios.post<{
                members: Array<IMember>,
                groups: Array<IGroup>,
            }>(fetchUrl, {
                ids: memberIds,
                groupIds,
            }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                }
            });

            if (membersDetailsResponse.status === 200) {
                this.props.appendMembers(membersDetailsResponse.data.members);
                this.props.appendGroups(membersDetailsResponse.data.groups);
            } else {
                this.setState({
                    showLoader: true,
                    errorMessage: 'Error when fetching member details',
                });
            }
        } catch (e) {
            if (e instanceof Error) {
                this.setState({
                    showLoader: true,
                    errorMessage: e.message,
                });
            }
            return;
        }
    }

    importMembers = async (importData: Array<Array<string>>) => {

        const erroneousData: Array<Array<string>> = [];

        if (importData.length < 2) {
            return;
        }

        const createdMembers: Array<IUpdateableMemberData> = [];
        const updatedMembers: Array<IUpdateableMemberData> = [];

        if (!importData[0].some(cellValue => cellValue.toLocaleLowerCase() === 'id' || cellValue.toLocaleLowerCase().startsWith('id '))) {
            this.setState({
                showLoader: true,
                errorMessage: translatePhrase('The ID column is required'),
            });

            return;
        }

        let columnMapping: ColumnMapping | undefined;

        try {
            columnMapping = this.getColumnMappingForImport(importData[0]);
        } catch (e) {
            if (e instanceof Error) {
                this.setState({
                    showLoader: true,
                    errorMessage: e.message,
                });
            }
            return;
        }

        const errorFileHeading = this.getImportFileHeader(columnMapping).concat(['Errors']);
        const noOfColumns = errorFileHeading.length - 1;
        erroneousData.push(errorFileHeading);

        const dataWithoutHeader = importData.slice(1);
        let totalMembersImported = 0;

        const memberIdsNotStoredLocally: Array<string> = [];
        const groupIdsNotStoredLocally: GroupDataHolder = {};

        for (const rowData of dataWithoutHeader) {
            try {
                const memberId: string = rowData[columnMapping.id];

                if (!(memberId in this.props.memberData.byId)) {
                    memberIdsNotStoredLocally.push(memberId);
                }

                for (const groupTypeId in columnMapping.groupTypes) {
                    const groupType = this.props.groupData.types.byId[groupTypeId];
                    const groupIdentifiers: Array<string> = rowData[columnMapping.groupTypes[groupTypeId]].split(',').filter(identifier => !!identifier);

                    const groupIdsNotStoredLocallyForGroupType: Array<string> = [];

                    for (const groupIdentifier of groupIdentifiers) {
                        const groupId = this.props.groupData.allEntries.find(groupId => {
                            const group = this.props.groupData.byId[groupId];
                            return group.customFields[groupType.uniqueFieldId] === groupIdentifier;
                        });

                        if (!groupId) {
                            groupIdsNotStoredLocallyForGroupType.push(groupIdentifier);
                        }
                    }

                    if (groupIdsNotStoredLocallyForGroupType.length > 0) {
                        if (!(groupTypeId in groupIdsNotStoredLocally)) {
                            groupIdsNotStoredLocally[groupTypeId] = groupIdsNotStoredLocallyForGroupType;
                        } else {
                            groupIdsNotStoredLocally[groupTypeId] = groupIdsNotStoredLocally[groupTypeId].concat(groupIdsNotStoredLocallyForGroupType);
                        }
                    }
                }
            } catch (e) {
                console.error('Issue when getting member/group IDs not stored locally for row: ' + JSON.stringify(rowData), e);
            }
        }

        let memberData = this.props.memberData;
        let groupData = this.props.groupData;

        if (memberIdsNotStoredLocally.length > 0 || Object.keys(groupIdsNotStoredLocally).length > 0) {
            await this.fetchMembersDetailsFromServer(memberIdsNotStoredLocally, groupIdsNotStoredLocally);
            memberData = store.getState().members;
            groupData = store.getState().groups;
        }

        for (const rowData of dataWithoutHeader) {
            if (rowData.length > 1 || (rowData.length === 1 && rowData[0].trim() !== '')) {
                try {
                    this.importRow(rowData, createdMembers, updatedMembers, columnMapping, memberData, groupData);
                    totalMembersImported += 1;
                } catch (e) {
                    if (e instanceof Error) {
                        const erroneousRow = Array(noOfColumns).fill('').map((entry, index) => {
                            if (index < rowData.length) {
                                return rowData[index];
                            } else {
                                return '';
                            }
                        });
                        erroneousRow.push(e.message);
                        erroneousData.push(erroneousRow);
                    }
                }
            }
        }

        if (createdMembers.length > 0) {
            this.props.bulkAddMembers(createdMembers);
        }

        if (updatedMembers.length > 0) {
            this.props.bulkUpdateMemberRequest(updatedMembers);
        }

        if (erroneousData.length > 1) {
            this.setState({
                errorMessage: 'There were errors during import',
                successMessage: '',
                erroneousData,
            });

            this.hideMessages();

        } else {
            this.setState({
                errorMessage: '',
                successMessage: `The import completed successfully! ${totalMembersImported} member(s) imported.`,
                erroneousData,
            });

            this.hideMessages();
        }

    }

    handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        const file = !!e.target.files ? e.target.files[0] : undefined;

        if (!!file) {

            if (file.type !== 'text/csv') {
                this.props.setErrorMessage(translatePhrase(translatePhrase('Wrong file format')), false);
                return;
            }

            this.setState({ showLoader: true });
            this.setState({ loaderText: [translatePhrase('Compressing the file(s)') + '...', translatePhrase('Uploading')] });

            Papa.parse(file, {
                complete: (results) => {
                    const csvData = results.data as Array<Array<string>>;
                    this.importMembers(csvData);

                    this.setState({ showLoader: false });
                    this.setState({ loaderText: [] });

                }
            });
        }
    }

    getMemberExportForQualifiedMemberIds = async (exportDataForMembers: Array<Array<string>>, project: IProject, qualifiedMemberIds: Array<string>) => {
        for (const memberId of qualifiedMemberIds) {
            const member = this.props.memberData.byId[memberId];
            const rowData: Array<string> = [];

            rowData.push(member.id);

            const memberType = this.props.memberData.types.byId[member.type];

            rowData.push(memberType.name);

            rowData.push(moment(member.createdTime).format('DD MMM YYYY hh:mm A'));
            rowData.push(moment(member.lastUpdatedTime).format('DD MMM YYYY hh:mm A'));

            const ancestorChain = getAncestorChainOfLocation(member.location).reverse();

            for (const ancestorId of ancestorChain) {
                const location = this.props.structureData.locations.byId[ancestorId];
                rowData.push(location.name);
            }

            const memberLocation = this.props.structureData.locations.byId[member.location].name;
            rowData.push(memberLocation);

            // Fill up remaining location spaces with empty strings
            for (let i = 0; i < project.children.length - ancestorChain.length - 1; i += 1) {
                rowData.push('');
            }

            this.props.groupData.types.allEntries
                .filter(groupTypeId => {
                    const groupType = this.props.groupData.types.byId[groupTypeId];
                    return groupType.project === project.id;
                })
                .forEach(groupTypeId => {
                    const groupType = this.props.groupData.types.byId[groupTypeId];
                    const uniqueFieldId = groupType.uniqueFieldId;

                    if (!uniqueFieldId) {
                        rowData.push('');
                        return;
                    }

                    const uniqueField = this.props.groupData.types.customFields.byId[uniqueFieldId];

                    const groupIds = member.groups[groupTypeId] ? Array.from(new Set(member.groups[groupTypeId])) : [];
                    const readableValues = groupIds
                        .filter(groupId => {
                            const group = this.props.groupData.byId[groupId];

                            if (!group) {
                                return false;
                            }

                            return true;
                        })
                        .map(groupId => {
                            const group = this.props.groupData.byId[groupId];
                            const uniqueIdentifier = getReadableDataForCustomField(group.customFields[uniqueField.id], uniqueField, group.id, 'group');
                            return uniqueIdentifier;
                        });

                    rowData.push(readableValues.join(','));
                });

            for (const customFieldId of memberType.customFields) {
                const customField = this.props.memberData.types.customFields.byId[customFieldId];
                const readableValue = getReadableDataForCustomField(member.customFields[customFieldId], customField, member.id, 'member');

                if (customField.type === FieldType.FREE_TEXT) {
                    let dataToPush = readableValue === '-' ? 'Not Available' : 'Available';
                    rowData.push(dataToPush);
                } else if (customField.type === FieldType.FILE) {
                    let fileValue = readableValue;
                    if (fileValue.startsWith('http') && fileValue.includes('diceflow.in')) {
                        const fileUrlComponents = fileValue.split('/');
                        const orgCode = fileUrlComponents[4];
                        const fileName = fileUrlComponents[5];
                        fileValue = `${window.location.origin}/file-download/${encodeURIComponent(orgCode)}${encodeURIComponent('/')}${encodeURIComponent(fileName)}`;
                    } else if (/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/.test(fileValue)) {
                        fileValue = 'File data not synced';
                    }
                    rowData.push(fileValue);
                } else {
                    rowData.push(readableValue);
                }

            }

            exportDataForMembers.push(rowData);
        }
    }

    getOnlineMemberFilters = async () => {
        const serverUrl = BASE_URL + '/online-member-filters/';

        const responseData = await axios.get<OnlineMemberQueryData>(serverUrl, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });

        return responseData.data;
    }

    applyOnlineMemberFilters = async (filters: MemberFilters, searchTerm: string, pageSize: number) => {
        const serverUrl = new URL('/online-member-filters/', BASE_URL);

        const queryData: OnlineMemberQueryData = {
            filters,
            searchTerm,
            pageSize,
        }

        return axios.post(serverUrl.toString(), queryData, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });
    }

    getPageData = async (pageNumber: number, pageSize: number) => {
        const serverUrl = new URL('/online-members/', BASE_URL);

        serverUrl.searchParams.set('pageSize', String(pageSize));
        serverUrl.searchParams.set('currentPageNumber', String(pageNumber));

        const responseData = await axios.get<PageDataForOnlineEntities>(serverUrl.toString(), {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });

        return responseData.data;
    }

    getMemberDataToExport = async () => {
        const memberTypeId = this.getMemberTypeId();

        if (!memberTypeId) {
            return [];
        }

        const memberType = this.props.memberData.types.byId[memberTypeId];

        const project = this.props.structureData.projects.byId[memberType.project];

        const exportDataForMembers: Array<Array<string>> = [];
        const headings = this.getExportFileHeader();
        exportDataForMembers.push(headings);

        if (this.props.isOnline) {
            const PAGE_SIZE_FOR_EXPORT = 1000;
            const originalMemberQueryData = await this.getOnlineMemberFilters();
            const noOfPages = Math.ceil(this.props.totalNoOfMembers / PAGE_SIZE_FOR_EXPORT);
            const filterForType: MemberFilters = {
                ...originalMemberQueryData.filters,
                types: [memberType.id],
            }

            let applicationState = store.getState();

            await this.applyOnlineMemberFilters(filterForType, originalMemberQueryData.searchTerm, PAGE_SIZE_FOR_EXPORT);

            for (let i = 0; i < noOfPages; i += 1) {
                const pageNumber = i + 1;
                const pageData = await this.getPageData(pageNumber, PAGE_SIZE_FOR_EXPORT);

                this.props.clearMemberEntries();
                this.props.clearGroupEntries();
                this.props.clearWorkflowEntries();

                this.props.appendMembers(pageData.members);
                this.props.appendGroups(pageData.groups);
                this.props.appendWorkflows(pageData.workflows);

                applicationState = store.getState();

                const qualifiedMemberIds = pageData.members.map(member => member.id);
                await this.getMemberExportForQualifiedMemberIds(exportDataForMembers, project, qualifiedMemberIds);

            }

            this.props.filterMemberTable(
                originalMemberQueryData.filters.projects,
                originalMemberQueryData.filters.types,
                originalMemberQueryData.filters.locations,
                originalMemberQueryData.filters.customFields,
                originalMemberQueryData.filters.createdDateRange,
                originalMemberQueryData.filters.lastUpdatedDateRange,
                originalMemberQueryData.filters.unsynced,
                originalMemberQueryData.filters.archived
            );
        } else {
            const applicationState = store.getState();
            const qualifiedMemberIds = filterMembers(applicationState).filter(member => member.type === memberType.id).map(member => member.id);
            await this.getMemberExportForQualifiedMemberIds(exportDataForMembers, project, qualifiedMemberIds);
        }

        return exportDataForMembers;

    }

    getExportFileHeader = () => {
        let templateMarkup: Array<string> = [];

        const memberTypeId = this.getMemberTypeId();

        if (!memberTypeId) {
            return [];
        }

        const memberType = this.props.memberData.types.byId[memberTypeId];

        const project = this.props.structureData.projects.byId[memberType.project];

        templateMarkup.push(ID_COLUMN_VALUE);
        templateMarkup.push('Type');
        templateMarkup.push('Created time');
        templateMarkup.push('Last updated time');

        for (const levelId of project.children) {
            const level = this.props.structureData.levels.byId[levelId];

            templateMarkup.push(LEVEL_PREFIX + level.name);
        }

        this.props.groupData.types.allEntries
            .filter(groupTypeId => {
                const groupType = this.props.groupData.types.byId[groupTypeId];
                return groupType.project === project.id;
            })
            .forEach(groupTypeId => {
                const groupType = this.props.groupData.types.byId[groupTypeId];

                templateMarkup.push(`${GROUP_PREFIX}${groupType.name} groups`);
            });

        for (const customFieldId of memberType.customFields) {
            const customField = this.props.memberData.types.customFields.byId[customFieldId];

            if (customField.isComputed) {
                templateMarkup.push(COMPUTED_FIELD_PREFIX + customField.name);
            } else {
                templateMarkup.push(CUSTOM_FIELD_PREFIX + customField.name);
            }

        }

        return templateMarkup;

    }

    getImportFileHeader = (columnMapping?: ColumnMapping) => {
        let templateMarkup: Array<string> = [];

        const memberTypeId = this.getMemberTypeId();

        if (!memberTypeId) {
            return [];
        }

        const memberType = this.props.memberData.types.byId[memberTypeId];

        const project = this.props.structureData.projects.byId[memberType.project];

        if (!columnMapping) {
            templateMarkup.push(ID_COLUMN_VALUE);
        } else if (typeof columnMapping.id === 'number') {
            templateMarkup[columnMapping.id] = ID_COLUMN_VALUE;
        }

        if (!columnMapping) {
            templateMarkup.push('Type');
        } else if (typeof columnMapping.type === 'number') {
            templateMarkup[columnMapping.type] = 'Type';
        }

        for (const levelId of project.children) {
            const level = this.props.structureData.levels.byId[levelId];

            if (!columnMapping) {
                templateMarkup.push(LEVEL_PREFIX + level.name);
            } else if (typeof columnMapping.levels[levelId] === 'number') {
                templateMarkup[columnMapping.levels[levelId]] = LEVEL_PREFIX + level.name;
            }
        }

        this.props.groupData.types.allEntries
            .filter(groupTypeId => {
                const groupType = this.props.groupData.types.byId[groupTypeId];
                return groupType.project === project.id;
            })
            .forEach(groupTypeId => {
                const groupType = this.props.groupData.types.byId[groupTypeId];

                if (!columnMapping) {
                    templateMarkup.push(`${GROUP_PREFIX}${groupType.name} groups`);
                } else if (typeof columnMapping.groupTypes[groupTypeId] === 'number') {
                    templateMarkup[columnMapping.groupTypes[groupTypeId]] = `${GROUP_PREFIX}${groupType.name} groups`;
                }
            });

        for (const customFieldId of memberType.customFields) {
            const customField = this.props.memberData.types.customFields.byId[customFieldId];

            if (!customField.isComputed) {
                if (!columnMapping) {
                    templateMarkup.push(CUSTOM_FIELD_PREFIX + customField.name);
                } else if (typeof columnMapping.customFields[customFieldId] === 'number') {
                    templateMarkup[columnMapping.customFields[customFieldId]] = CUSTOM_FIELD_PREFIX + customField.name;
                }
            }

        }

        return templateMarkup;

    }

    getImportFileSampleRow = () => {
        let templateMarkup: Array<string> = [];

        const memberTypeId = this.getMemberTypeId();

        if (!memberTypeId) {
            return [];
        }

        const memberType = this.props.memberData.types.byId[memberTypeId];

        const project = this.props.structureData.projects.byId[memberType.project];

        templateMarkup.push('Copy ID from export file for update; Leave BLANK for completely new values');
        templateMarkup.push(memberType.name);

        for (const levelId of project.children) {
            const level = this.props.structureData.levels.byId[levelId];

            templateMarkup.push(`Type ${level.name} as in system`);
        }

        this.props.groupData.types.allEntries
            .filter(groupTypeId => {
                const groupType = this.props.groupData.types.byId[groupTypeId];
                return groupType.project === project.id;
            })
            .forEach(groupTypeId => {
                const groupType = this.props.groupData.types.byId[groupTypeId];
                const uniqueFieldId = groupType.uniqueFieldId;

                if (!uniqueFieldId) {
                    templateMarkup.push(`Leave empty - ${groupType.name} has no unique field`);
                } else {
                    const uniqueField = this.props.groupData.types.customFields.byId[uniqueFieldId];
                    templateMarkup.push(`Type exact values of ${uniqueField.name} of ${groupType.name} as in system in comma separated formats`);
                }

            });

        const importableCustomFields = memberType.customFields.filter(customFieldId => {
            const field = this.props.memberData.types.customFields.byId[customFieldId];
            return field.type !== FieldType.FREE_TEXT;
        });

        for (const customFieldId of importableCustomFields) {
            const customField = this.props.memberData.types.customFields.byId[customFieldId];

            if (!customField.isComputed) {
                templateMarkup.push(exampleValueForCustomField(customField, this.props.memberData.types.customFieldOptions));
            }

        }

        return templateMarkup;

    }

    exportData = async () => {

        const memberTypeId = this.getMemberTypeId();

        if (!memberTypeId) {
            this.setState({
                errorMessage: 'Select a member type'
            });

            this.hideMessages();

            return false;
        }

        const memberType = this.props.memberData.types.byId[memberTypeId];

        try {
            this.setState({ showLoader: true });

            this.setState({ loaderText: [translatePhrase('Preparing export') + '...'] });
        } catch (e) {
            this.hideMessages();

            this.setState({ showLoader: false });

            this.setState({ loaderText: [] });

            return;
        }


        window.setTimeout(async () => {

            const exportDataForMembers = await this.getMemberDataToExport();

            const fileData = Papa.unparse(exportDataForMembers);
            const fileBlob = new Blob([fileData], { type: 'application/csv' });

            saveAs(fileBlob, `${memberType.name} Export.csv`);

            this.hideMessages();

            this.setState({
                showLoader: false,
                loaderText: [],
                successMessage: translatePhrase('Export ready'),
            });

            window.setTimeout(() => {
                this.dismissSuccessMessage();
            }, 3000);
        }, 2000);
    }

    collectImportTemplate = () => {

        const memberTypeId = this.getMemberTypeId();

        if (!memberTypeId) {
            this.setState({
                errorMessage: 'Select a member type'
            });

            this.hideMessages();

            return false;
        }

        const memberType = this.props.memberData.types.byId[memberTypeId];

        const headings = this.getImportFileHeader();

        const sampleData = this.getImportFileSampleRow();

        this.setState({
            importTemplateData: [headings, sampleData],
        });
    }

    dismissErrorMessage = () => {
        this.setState({
            successMessage: '',
            errorMessage: '',
            erroneousData: [],
        });
    }

    dismissSuccessMessage = () => {
        this.setState({
            successMessage: '',
        });
    }

    startDataExport = () => {
        this.setState({
            errorMessage: '',
        });

        const memberTypeId = this.getMemberTypeId();

        if (!memberTypeId) {
            this.setState({
                errorMessage: 'Select a member type',
            });

            this.hideMessages();
            return false;
        }

        return true;
    }

    startDataImport = () => {
        this.setState({
            errorMessage: '',
        });

        const memberTypeId = this.getMemberTypeId();

        if (!memberTypeId) {
            this.setState({
                errorMessage: 'Select a member type',
            });

            this.hideMessages();
            return;
        } else {
            const fileElement = document.getElementById('member-import-file-input');
            if (!!fileElement) {
                fileElement.click();
            }
        }
    }

    render() {
        let allowedTypes = this.props.memberData.types.allEntries;

        if (this.props.memberData.filters.types.length > 0) {
            allowedTypes = this.props.memberData.filters.types.slice();
        }

        const memberTypesList = allowedTypes.map(memberTypeId => {
            return {
                name: translatePhrase(this.props.memberData.types.byId[memberTypeId].name),
                value: memberTypeId,
            };
        });

        const memberType = this.state.memberTypeId ? this.props.memberData.types.byId[this.state.memberTypeId] : undefined;
        let fileName = '';

        if (memberType) {
            const memberTypeName = memberType ? memberType.name : '';
            const project = this.props.structureData.projects.byId[memberType?.project]
            fileName = `${project.name.toLocaleLowerCase().split(' ').join('-')}-${memberTypeName.toLocaleLowerCase().split(' ').join('-')}`;
        }

        let defaultMemberTypeName = memberTypesList.length > 0 ? translatePhrase(memberTypesList[0].name) : '';


        return (
            <section>

                {this.state.showLoader && <LoaderModal loaderText={this.state.loaderText} isIndeterminate={true} />}

                {this.state.successMessage && <LoaderModal loaderText={[this.state.successMessage]} isSuccess={true} />}

                {this.state.errorMessage && <LoaderModal closeModal={this.dismissErrorMessage} loaderText={[this.state.errorMessage]} link={this.state.erroneousData.length > 1 ? <CSVLink data={this.state.erroneousData} filename="Errors.csv" className={styles.errorFileDownload}>Download erroneous data</CSVLink> : <div></div>} isError={true} />}

                <section className={styles.commonModalHolder + ' ' + styles.csvModal}>
                    <div className={styles.filterCloseButton} onClick={this.props.closeCSV}>
                        <Button title={translatePhrase("Close")} icon={<CancelIcon />} size={'small'} isRounded />
                    </div>

                    <section className={styles.addOrModifyListCard}>
                        <header>
                            <h2> <ExportIcon /> {translatePhrase('Import/Export')} {translatePhrase('Members')} </h2>
                        </header>


                        <div className={styles.container}>
                            <div className={styles.allInputsHolder}>
                                <div className={styles.inputSegment}>
                                    <InputText placeholder="Member Type" icon={chevronIcon} default={defaultMemberTypeName} options={memberTypesList} onChange={this.changeMemberType} />
                                </div>
                            </div>
                        </div>

                        <input type="file" accept=".csv, text/csv" id="member-import-file-input" className={styles.hiddenFile} onChange={this.handleFileUpload} />

                        <div className={styles.buttonsHolder}>
                            {this.props.memberData.allEntries.length > 0 && this.state.exportData.length === 0 && <Button icon={<ExportCSVIcon />} isRounded isBlock padding={'0px 10px'} text={translatePhrase(`Export Table`)} onClick={this.exportData} />}
                            <div className={styles.actionButtons}>
                                {this.props.isSuperUser && <Button icon={<ImportIcon />} type={'secondary'} isRounded isBlock padding={'0px 10px'} text={translatePhrase('Import')} onClick={this.startDataImport} />}
                                <CSVLink data={this.state.importTemplateData} onClick={this.collectImportTemplate} filename={`${fileName} template.csv`}><Button icon={<TemplateIcon />} type={'tertiary'} isRounded isBlock text={translatePhrase('Download Template')} onClick={this.startDataExport} /></CSVLink>
                            </div>
                        </div>

                    </section>
                </section>
            </section>
        );
    }
}

const MemberModify = connect(mapStateToProps, mapDispatchToProps)(ConnectedMemberModify);

export default MemberModify;