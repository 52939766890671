import { NudgeData } from "./index";
import { ApplicationState } from "../../../shared/store/types";
import { NudgeType } from "../../../shared/store/my-data/types";

export function getLocationsNudges(applicationState: ApplicationState): Array<NudgeData> {

    let nudgesData: Array<NudgeData> = [];

    if (applicationState.structure.projects.selected) {
        const selectedProject = applicationState.structure.projects.byId[applicationState.structure.projects.selected];

        if (selectedProject.children.length === 0) {
            nudgesData.push({
                id: NudgeType.LOCATIONS_DOWNLOAD_IMPORT_LOCATIONS_TEMPLATE,
                header: 'Download import template',
                content: 'Click on the download template button to download a CSV file. This gives the location template to be followed for this project.\nThis import allows you to fill in the locations according to the level, and any custom fields defined for that level. Take a look at the header of the downloaded template for more clarity',
            });
        } else {
            nudgesData.push({
                id: NudgeType.LOCATIONS_DOWNLOAD_EXPORT_DATA,
                header: 'Download export data',
                content: 'Click on this button to download the export data for this project in CSV format',
            });
        }

        nudgesData.push({
            id: NudgeType.LOCATIONS_IMPORT_LOCATIONS_FOR_PROJECT,
            header: 'Import locations',
            content: `Click on the import button and select a CSV file to bulk import the locations. 
                Download the template/export data to get the format to use for import.`,
        });
    }

    return nudgesData;
};