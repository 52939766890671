import React, { Component } from 'react';
import styles from './step-piece/StepPiece.module.scss';
import StepPiece from './step-piece/StepPiece'
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';


type SetPieceProps = {
    nextPiece?: JSX.Element,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = SetPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

class ConnectedSetPiece extends Component<Props> {

    render() {
    
        return (
            <StepPiece theme="aqua" {...this.props}>
                <div className={styles.text}>restrict navigation</div>
            </StepPiece>
        )
        
    }
}

const SetPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedSetPiece)

export default SetPiece;