import moment from "moment";
import { isWebUri } from "valid-url";
import { ApplicationState } from "../store/types";
import { BASE_URL } from "../store/url";
import { BlobFile, FileLimit } from "./common-types";

export function getFileExtensionsCategories() {
    const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
    const audioExtensions = ['.mp3', '.aac', '.m4a', '.wav', '.x-m4a'];
    const videoExtensions = ['.mp4', '.mov'];
    const downloadableExtensions = ['.pdf', '.css',];

    return {
        imageExtensions,
        audioExtensions,
        videoExtensions,
        downloadableExtensions
    };
}

export async function convertFileToBlobAndDataURL(file: File): Promise<BlobFile> {
    const readFile = (file: File): Promise<ArrayBuffer> => {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.onload = function (event) {
                resolve(event.target?.result as ArrayBuffer);
            };
            reader.readAsArrayBuffer(file);
        });
    };

    const convertBlobToDataURL = (blobURL: string): Promise<string> => {
        return new Promise<string>((resolve) => {
            const xhr = new XMLHttpRequest();
            xhr.onload = function () {
                const reader = new FileReader();
                reader.onloadend = function () {
                    resolve(reader.result as string);
                };
                reader.readAsDataURL(xhr.response);
            };
            xhr.open('GET', blobURL);
            xhr.responseType = 'blob';
            xhr.send();
        });
    }



    try {
        const arrayBuffer = await readFile(file);
        const blob = new Blob([arrayBuffer], { type: file.type });
        const blobURL = URL.createObjectURL(blob);
        const dataURL = await convertBlobToDataURL(blobURL);

        return {
            blob,
            blobURL,
            dataURL,
            originalFile: file,
        };
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export function updateLinkToCurrentOrigin(link: string) {
    if (link.startsWith('/file-f123f')) {
        link = BASE_URL + '/file-upload' + link
    };

    if (window.location.hostname === 'localhost') {
        return BASE_URL + (new URL(link)).pathname;
    }
    return window.location.origin + (new URL(link)).pathname
}

export function isFileFromCamera(file: File) {
    // HACK: Browser has restrictions on finding the source of the file, 
    // so we check if the file is an image that was modified in the last few seconds
    const fiveSecondsAgo = moment().subtract(5, 'seconds');

    const isImageOrVideo = file.type.startsWith('image') || file.type.startsWith('video');
    const isRecentlyModified = file.lastModified > fiveSecondsAgo.valueOf();

    return isImageOrVideo && isRecentlyModified;
};

export function getFileLimit(state: ApplicationState, file: File): FileLimit {
    const globalFileSizeLimit = 1024; // 1 MB
    const fileSizeLimit: number = state.organization.fileSizeLimitInMB ? state.organization.fileSizeLimitInMB : globalFileSizeLimit;

    const fileSizeLimitInMB = fileSizeLimit * 1024 * 1024;

    const fileLimit: FileLimit = {
        isLimitExceeded: false,
        size: fileSizeLimit
    };

    if (file.size > fileSizeLimitInMB) {
        fileLimit.isLimitExceeded = true;
    };

    return fileLimit;
};

export function isOnlineFile(file: string) {
    if (isWebUri(file) || file.startsWith('https') || file.startsWith('/file-f123f-')) {
        return true;
    };
    return false;
};

export const getFormatedFileSize = (bytes: number) => {
    if (bytes < 1024) {
        return bytes + ' B';
    } else if (bytes < 1024 * 1024) {
        return (bytes / 1024).toFixed(2) + ' KB';
    } else {
        return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
    }
}

export const getOnlineFileSize = async (file: string): Promise<number | undefined> => {
    if (isOnlineFile(file)) {
        const token = localStorage.getItem('token') || '';
        let url = `${file}/?token=${token}`;

        if (file.startsWith('/file-f123f-')) {
            url = BASE_URL + '/file-upload' + url
        };

        try {
            const response = await fetch(url, {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            };

            const blob = await response.blob();
            const fileSize = blob.size.toString();

            return parseInt(fileSize, 10);
        } catch (error) {
            console.log(error);
            return;
        }
    }
}


export const getFileExtension = (filename: string) => {
    return filename.slice(((filename.lastIndexOf(".") - 1) >>> 0) + 2);
}

export function getFileNameFromUrl(url: string) {
    return url.includes('/') ? url.substring(url.lastIndexOf('/')) : undefined;
}

export function getFileUrlForExport(value: string, baseUrl?: string) {
    const url = baseUrl ? baseUrl : BASE_URL;

    if (value.startsWith('http') && value.includes('diceflow.in')) {
        const fileUrlValue = value.split('/');
        const firstUriComponent = fileUrlValue[4] ? encodeURIComponent(fileUrlValue[4]) : '';
        const secondUriComponent = fileUrlValue[5] ? encodeURIComponent('/') + encodeURIComponent(fileUrlValue[5]) : '';
        return url + '/file-upload/' + firstUriComponent + secondUriComponent;

    } else if (/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/.test(value)) {
        return 'File data not synced';
    } else if (value.startsWith('/file')) {
        const fileName = value.slice(1)
        return url + '/file-upload/' + encodeURIComponent(fileName);
    }
    return value;
}