import React, { useRef, useState } from 'react';
import styles from './PackageDetails.module.scss';
import logo from '../../../common/assets/logo.svg';
import Button from '../../../widgets/button/CommonButton';
import Table from '../../../widgets/table/Table';
import marketImage from '../../../common/images/market_image.png';
import { useHistory, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../shared/store/types';
import { TableRow } from '../../../shared/helpers/common-types';
import { FieldType } from '../../../shared/store/custom-fields/types';
import { saveAs } from 'file-saver';

interface OwnProps {
    packageId: string;
}

export const WorkflowPackageDetails: React.FC<OwnProps> = (props: OwnProps) => {
    const [selectedImageIndex, setSelectedImage] = useState<number>(0);
    const [selectedSegment, setSelectedSegment] = useState<'description' | 'custom_fields' | 'screenshots'>('description');
    const history = useHistory();

    const descriptionRef = useRef<HTMLHeadingElement>(null);
    const customFieldsRef = useRef<HTMLHeadingElement>(null);
    const screenshotsRef = useRef<HTMLHeadingElement>(null);

    const packageId = props.packageId;

    const selectedPackage = useSelector((state: ApplicationState) => state.packages.byId[packageId]);

    if (!selectedPackage || selectedPackage.type !== 'Workflow') {
        return <div></div>;
    }

    let customFieldData: Array<TableRow> = [{
        id: '',
        entries: ['1', 'Member Name', 'Text']
    }, {
        id: '',
        entries: ['2', 'Member Name', 'Text']
    }, {
        id: '',
        entries: ['3', 'Member Name', 'Text']
    }, {
        id: '',
        entries: ['4', 'Member Name', 'Text']
    }, {
        id: '',
        entries: ['5', 'Member Name', 'Text']
    }];

    function getReadableStringForType(type: FieldType) {
        const segments = type.split('_');
        const transformedSegments = segments.join(' ');
        return transformedSegments[0].toUpperCase() + transformedSegments.substring(1).toLocaleLowerCase();
    }

    if (selectedPackage.packageData) {
        customFieldData = selectedPackage.packageData.workflowType.customFields.map((customField, index) => {
            return {
                id: customField.id,
                entries: [
                    String(index + 1),
                    customField.name,
                    getReadableStringForType(customField.type),
                ],
            };
        })
    }

    function downloadPackageData() {
        if (!selectedPackage || !selectedPackage.packageData) {
            return;
        }

        const exportData = JSON.stringify(selectedPackage.packageData, null, 2);

        const fileBlob = new Blob([exportData.split('\n').join('\r\n')], { type: 'application/json' });

        saveAs(fileBlob, `${selectedPackage.name} Package download.json`);
    }

    function goBack() {
        history.goBack();
    }

    function handleHeaderClick(segment: 'description' | 'custom_fields' | 'screenshots') {
        setSelectedSegment(segment);

        switch (segment) {
            case 'description':
                const descriptionElement = descriptionRef.current;

                if (descriptionElement) {
                    descriptionElement.scrollIntoView({
                        block: 'center',
                    })
                }
                break;
            case 'custom_fields':
                const customFieldsElement = customFieldsRef.current;

                if (customFieldsElement) {
                    customFieldsElement.scrollIntoView({
                        block: 'center',
                    })
                }
                break;
            case 'screenshots':
                const screenshotsElement = screenshotsRef.current;

                if (screenshotsElement) {
                    screenshotsElement.scrollIntoView({
                        block: 'center',
                    })
                }
                break;
        }
    }

    let affiliation: string | undefined;

    if (selectedPackage.hasAffiliation && selectedPackage.packageData) {
        affiliation = selectedPackage.packageData.workflowType.affiliation;
    }

    return <section className={styles.marketPage}>

        <header className={styles.pageHeading}>
            <h1> {selectedPackage.name} </h1>
        </header>

        <div className={styles.segmentContainer}>
            <Button text={'Go back'} isRounded size={'medium'} color={'contrast'} type="secondary" padding={'0px 15px'} onClick={goBack} />
            <div className={styles.segmentHolder}>
                <a onClick={() => handleHeaderClick('description')} className={selectedSegment === 'description' ? styles.active : ''}> Description </a>
                <a onClick={() => handleHeaderClick('custom_fields')} className={selectedSegment === 'custom_fields' ? styles.active : ''}> Custom Fields </a>
                <a onClick={() => handleHeaderClick('screenshots')} className={selectedSegment === 'screenshots' ? styles.active : ''}> Screenshots </a>
            </div>
            {selectedPackage.packageData && <Button text={'Download'} isRounded size={'medium'} color={'contrast'} padding={'0px 15px'} onClick={downloadPackageData} />}
        </div>

        <section className={styles.detailsHolder}>

            <h4 ref={descriptionRef} id="description"> Description: </h4>
            <div onMouseOver={() => setSelectedSegment('description')} className={styles.sector + ' ' + styles.description}>
                <p>A workflow package with {selectedPackage.customFieldsCount} custom field{selectedPackage.customFieldsCount > 1 ? 's' : ''} {affiliation ? 'and affiliated with a ' + affiliation : ''}</p>
                <p> {selectedPackage.description} </p>
            </div>

            <h4 ref={customFieldsRef} id="custom_fields"> Custom Fields: </h4>
            <div onMouseOver={() => setSelectedSegment('custom_fields')} className={styles.sector + ' ' + styles.tableContainer}>
                <Table
                    areActionsHidden={true}
                    headings={[{
                        name: 'Sl No',
                        width: 10,
                        isSortable: false
                    }, {
                        name: 'Fields',
                        width: 10,
                        isSortable: false
                    }, {
                        name: 'Type',
                        width: 10,
                        isSortable: false
                    }]}
                    entries={customFieldData}
                    isReadOnly={true}
                />
            </div>

            <h4 ref={screenshotsRef} id="screenshots"> Screenshots </h4>
            <div onMouseOver={() => setSelectedSegment('screenshots')} className={styles.sector + ' ' + styles.slideShowHolder}>
                <img className={styles.selectedImage} src={selectedPackage.imageLinks[selectedImageIndex]} />
            </div>

        </section>

    </section>
}