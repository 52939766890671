import { OrganizationState } from '../organization/types';
import { WidgetActionTypes, IWidgetConfigurationState } from './widgets/types';

export const UPDATE_MY_ID = 'UPDATE_MY_ID';
export const UPDATE_MY_PERMISSIONS = 'UPDATE_MY_PERMISSIONS';
export const UPDATE_MY_DATA = 'UPDATE_MY_DATA';
export const UPDATE_LAST_SYNC_TIME = 'UPDATE_LAST_SYNC_TIME';
export const CHANGE_SUPER_USER_PASSWORD = 'CHANGE_SUPER_USER_PASSWORD';
export const SELECT_NUDGE = 'SELECT_NUDGE';

export const START_OLDER_DATA_FETCH = 'START_OLDER_DATA_FETCH';
export const COMPLETE_OLDER_DATA_FETCH = 'COMPLETE_OLDER_DATA_FETCH';

export const SET_IS_TOP_BAR_EXPANDED = 'SET_IS_TOP_BAR_EXPANDED';
export const SET_IS_FLOWCHART_EXPANDED = 'SET_IS_FLOWCHART_EXPANDED';
export const SET_IS_FETCHING_FOR_DATA_UPDATE = 'SET_IS_FETCHING_FOR_DATA_UPDATE';

export const FREEZE_BACKGROUND = 'FREEZE_BACKGROUND';
export const UN_FREEZE_BACKGROUND = 'UN_FREEZE_BACKGROUND';

export const SET_TOAST_MESSAGE = 'SET_TOAST_MESSAGE';
export const CLEAR_TOAST_MESSAGE = 'CLEAR_TOAST_MESSAGE';

export const SET_INFO_MESSAGE = 'SET_INFO_MESSAGE';
export const CLEAR_INFO_MESSAGE = 'CLEAR_INFO_MESSAGE';

export const SET_INDETERMINATE_MESSAGE = 'SET_INDETERMINATE_MESSAGE';
export const CLEAR_INDETERMINATE_MESSAGE = 'CLEAR_INDETERMINATE_MESSAGE';

export const SET_WARNING_MESSAGE = 'SET_WARNING_MESSAGE';
export const CLEAR_WARNING_MESSAGE = 'CLEAR_WARNING_MESSAGE';

export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const CLEAR_ERROR_MESSAGE = 'CLEAR_ERROR_MESSAGE';

export const SET_IS_EDITING_ORG_PROFILE = 'SET_IS_EDITING_ORG_PROFILE';
export const SET_EDITING_ORG_STATE = 'SET_EDITING_ORG_STATE';
export const SET_IS_LOGGING_OUT = 'SET_IS_LOGGING_OUT';
export const SET_SHOULD_UPDATE_APPLICATION = 'SET_SHOULD_UPDATE_APPLICATION';
export const SET_LATEST_VERSION_NUMBER = 'SET_LATEST_VERSION_NUMBER';


export const FETCH_DATA_REQUEST = 'FETCH_DATA_REQUEST';
export const COMPLETE_PARTIAL_DATA_FETCH = 'COMPLETE_PARTIAL_DATA_FETCH';
export const RESUME_PARTIAL_DATA_FETCH = 'RESUME_PARTIAL_DATA_FETCH';
export const COMPLETE_DATA_FETCH = 'COMPLETE_DATA_FETCH';
export const START_DATA_PUSH = 'START_DATA_PUSH';
export const COMPLETE_DATA_PUSH = 'COMPLETE_DATA_PUSH';
export const MARK_NETWORK_INTERRUPT = 'MARK_NETWORK_INTERRUPT';
export const RESET_NETWORK_CALL = 'RESET_NETWORK_CALL';

export const SET_IS_ONLINE = 'SET_IS_ONLINE';

export const SET_UP_SAMPLE_PROJECT = 'SET_UP_SAMPLE_PROJECT';

export const SET_LAST_REFRESH_TIME = 'SET_LAST_REFRESH_TIME';

export const COMPLETE_DASHBOARD_RELOAD = 'COMPLETE_DASHBOARD_RELOAD';
export const AUTOSAVE_TO_DB = 'AUTOSAVE_TO_DB';

export enum NudgeType {
    DASHBOARD_RELOAD_ALL = 'DASHBOARD_RELOAD_ALL',
    DASHBOARD_ADD_NEW = 'DASHBOARD_ADD_NEW',
    DASHBOARD_SEARCH_WIDGET = 'DASHBOARD_SEARCH_WIDGET',
    DASHBOARD_FILTER_WIDGET = 'DASHBOARD_FILTER_WIDGET',
    DASHBOARD_SHOW_MORE_WIDGET_OPTIONS = 'DASHBOARD_SHOW_MORE_WIDGET_OPTIONS',
    DASHBOARD_SWITCH_WIDGET_VIEW = 'DASHBOARD_SWITCH_WIDGET_VIEW',

    HIERARCHY_SET_UP_SAMPLE_PROJECT = 'HIERARCHY_SET_UP_SAMPLE_PROJECT',

    LOCATIONS_DOWNLOAD_IMPORT_LOCATIONS_TEMPLATE = 'LOCATIONS_DOWNLOAD_IMPORT_LOCATIONS_TEMPLATE',
    LOCATIONS_DOWNLOAD_EXPORT_DATA = 'LOCATIONS_DOWNLOAD_EXPORT_DATA',
    LOCATIONS_IMPORT_LOCATIONS_FOR_PROJECT = 'LOCATIONS_IMPORT_LOCATIONS_FOR_PROJECT',

    USERS_ADD_USER = 'USERS_ADD_USER',
    USERS_MODIFY_USER_DETAILS = 'USERS_MODIFY_USER_DETAILS',
    USERS_SEARCH = 'USERS_SEARCH',
    USERS_FILTER = 'USERS_FILTER',
    USERS_METRICS = 'USERS_METRICS',
    USERS_METRICS_WORKFLOW_TIMING = 'USERS_METRICS_WORKFLOW_TIMING',
    USERS_IMPORT_EXPORT = 'USERS_IMPORT_EXPORT',
    USERS_IMPORT_EXPORT_USER_DETAILS = 'USERS_IMPORT_EXPORT_USER_DETAILS',
    USERS_LIST_SHOW_MORE = 'USERS_LIST_SHOW_MORE',

    MEMBERS_ADD_MEMBER = 'MEMBERS_ADD_MEMBER',
    MEMBERS_MODIFY_MEMBER_DETAILS = 'MEMBERS_MODIFY_MEMBER_DETAILS',
    MEMBERS_SEARCH = 'MEMBERS_SEARCH',
    MEMBERS_FILTER = 'MEMBERS_FILTER',
    MEMBERS_IMPORT_EXPORT = 'MEMBERS_IMPORT_EXPORT',
    MEMBERS_IMPORT_EXPORT_MEMBER_DETAILS = 'MEMBERS_IMPORT_EXPORT_MEMBER_DETAILS',
    MEMBERS_LIST_FIRE_WORKFLOW = 'MEMBERS_LIST_FIRE_WORKFLOW',
    MEMBERS_LIST_SHOW_MORE = 'MEMBERS_LIST_SHOW_MORE',

    GROUPS_ADD_GROUP = 'GROUPS_ADD_GROUP',
    GROUPS_MODIFY_GROUP_DETAILS = 'GROUPS_MODIFY_GROUP_DETAILS',
    GROUPS_SEARCH = 'GROUPS_SEARCH',
    GROUPS_FILTER = 'GROUPS_FILTER',
    GROUPS_IMPORT_EXPORT = 'GROUPS_IMPORT_EXPORT',
    GROUPS_IMPORT_EXPORT_GROUP_DETAILS = 'GROUPS_IMPORT_EXPORT_GROUP_DETAILS',
    GROUPS_LIST_FIRE_WORKFLOW = 'GROUPS_LIST_FIRE_WORKFLOW',
    GROUPS_LIST_SHOW_MORE = 'GROUPS_LIST_SHOW_MORE',

    WORKFLOWS_ADD_WORKFLOW = 'WORKFLOWS_ADD_WORKFLOW',
    WORKFLOWS_SEARCH = 'WORKFLOWS_SEARCH',
    WORKFLOWS_FILTER = 'WORKFLOWS_FILTER',
    WORKFLOWS_EXPORT = 'WORKFLOWS_EXPORT',
    WORKFLOWS_OVERDUE_TOGGLE = 'WORKFLOWS_OVERDUE_TOGGLE',
    WORKFLOWS_LIST_UPDATE_DUE_DATE = 'WORKFLOWS_LIST_UPDATE_DUE_DATE',
    WORKFLOWS_LIST_RESUME = 'WORKFLOWS_LIST_RESUME',
    WORKFLOWS_LIST_SHOW_MORE = 'WORKFLOWS_LIST_SHOW_MORE',

    WORKFLOWS_CONFIGURATION_IMPORT = 'WORKFLOWS_CONFIGURATION_IMPORT',

    ORGANIZATION_EDIT_DETAILS = 'ORGANIZATION_EDIT_DETAILS',

    FLOWCHART_COPY_LIVE_TO_BETA = 'FLOWCHART_COPY_LIVE_TO_BETA',
    FLOWCHART_PUBLISH_TO_LIVE = 'FLOWCHART_PUBLISH_TO_LIVE',
    FLOWCHART_BACK_TO_CONFIGURATION = 'FLOWCHART_BACK_TO_CONFIGURATION',
    FLOWCHART_SEARCH_PIECES = 'FLOWCHART_SEARCH_PIECES',
    FLOWCHART_PIECES = 'FLOWCHART_PIECES',
    FLOWCHART_COOKBOOK = 'FLOWCHART_COOKBOOK',
    FLOWCHART_LIVE = 'FLOWCHART_LIVE',
    FLOWCHART_BETA = 'FLOWCHART_BETA',
    FLOWCHART_EXPAND = 'FLOWCHART_EXPAND',
}

export interface MyDataState {
    id: string,
    token: string,
    isPushingData: boolean,
    isLoaded: boolean,
    isPartiallyLoaded: boolean,
    isNetworkCallInterrupted: boolean,
    lastMemberId?: string,
    lastGroupId?: string,
    lastWorkflowId?: string,
    partialResponseId: string | undefined,
    lastSyncTime: string | undefined,
    isFrozen: boolean,
    isTopBarExpanded: boolean,
    isFlowchartExpanded: boolean,
    isFetchingOlderData: boolean,
    isOlderDataFetched: boolean,
    selectedNudgeId?: NudgeType,

    lastAction?: string,

    shouldReloadDashboard?: boolean,

    totalNoOfMembers?: number,
    totalNoOfGroups?: number,
    totalNoOfWorkflows?: number,
    lastRefreshTime?: string,

    isOnline: boolean,

    isEditingOrgProfile?: boolean,
    isLoggingOut?: boolean,
    isFetchingForDataUpdate?: boolean,
    fetchingDataForUpdateMessage?: string,

    shouldUpdateApplication?: boolean,
    latestVersionNumber?: string,

    errorMessage: string,
    infoMessage: string,
    indeterminateMessage: string,
    warningMessage: string,

    widgets: IWidgetConfigurationState,
    editingOrgState: OrganizationState | undefined,
}

export interface IUpdateMyIDAction {
    type: typeof UPDATE_MY_ID,
    id: string,
}

export interface SetIsTopBarExpandedAction {
    type: typeof SET_IS_TOP_BAR_EXPANDED;
    isExpanded: boolean;
}

export interface SetIsFlowchartExpandedAction {
    type: typeof SET_IS_FLOWCHART_EXPANDED;
    isExpanded: boolean;
}

export interface SetIsFetchingForDataUpdateAction {
    type: typeof SET_IS_FETCHING_FOR_DATA_UPDATE;
    isFetchingForDataUpdate: boolean;
    message: string;
}

export interface IUpdateMyPermissionsAction {
    type: typeof UPDATE_MY_PERMISSIONS,
}

export interface IUpdateLastSyncTimeAction {
    type: typeof UPDATE_LAST_SYNC_TIME,
    lastSyncTime: string,
}

interface IUpdateMyDataAction {
    type: typeof UPDATE_MY_DATA,
    data: MyDataState,
}

interface IStartDataPush {
    type: typeof START_DATA_PUSH,
}

interface ICompleteDataPush {
    type: typeof COMPLETE_DATA_PUSH,
}

interface IStartOlderDataFetch {
    type: typeof START_OLDER_DATA_FETCH,
}

interface ICompleteOlderDataFetch {
    type: typeof COMPLETE_OLDER_DATA_FETCH,
}

export interface IChangeSuperUserPassword {
    type: typeof CHANGE_SUPER_USER_PASSWORD;
    password: string;
}

export interface SelectNudgeAction {
    type: typeof SELECT_NUDGE;
    nudgeId?: NudgeType;
}

export interface ISetToastMessageAction {
    type: typeof SET_TOAST_MESSAGE,
    message: string,
    persistMessage: boolean,
}

export interface IClearToastMessageAction {
    type: typeof CLEAR_TOAST_MESSAGE,
}

export interface ISetInfoMessageAction {
    type: typeof SET_INFO_MESSAGE,
    message: string,
    persistMessage: boolean,
}

export interface IClearInfoMessageAction {
    type: typeof CLEAR_INFO_MESSAGE,
}

export interface ISetIndeterminateMessageAction {
    type: typeof SET_INDETERMINATE_MESSAGE,
    message: string,
}

export interface IClearIndeterminateMessageAction {
    type: typeof CLEAR_INDETERMINATE_MESSAGE,
}

export interface ISetErrorMessageAction {
    type: typeof SET_ERROR_MESSAGE,
    message: string,
    persistMessage: boolean,
}

export interface ISetWarningMessageAction {
    type: typeof SET_WARNING_MESSAGE,
    message: string,
}

export interface IClearWarningMessageAction {
    type: typeof CLEAR_WARNING_MESSAGE
}

export interface IClearErrorMessageAction {
    type: typeof CLEAR_ERROR_MESSAGE,
}

export interface IFreezeBackgroundAction {
    type: typeof FREEZE_BACKGROUND,
}

export interface IUnFreezeBackgroundAction {
    type: typeof UN_FREEZE_BACKGROUND,
}

export interface MarkNetworkInterruptAction {
    type: typeof MARK_NETWORK_INTERRUPT;
}

export interface ResetNetworkCallAction {
    type: typeof RESET_NETWORK_CALL,
}

export interface SetIsOnlineAction {
    type: typeof SET_IS_ONLINE;
    isOnline: boolean;
}

export interface SetupSampleProjectAction {
    type: typeof SET_UP_SAMPLE_PROJECT;
}

export interface FetchDataRquestAction {
    type: typeof FETCH_DATA_REQUEST,
};

export interface CompleteDataFetchAction {
    type: typeof COMPLETE_DATA_FETCH,
};

export interface CompletePartialDataFetchAction {
    type: typeof COMPLETE_PARTIAL_DATA_FETCH,
    responseId: string,

    totalNoOfMembers?: number,
    totalNoOfGroups?: number,
    totalNoOfWorkflows?: number,

    lastMemberId?: string,
    lastGroupId?: string,
    lastWorkflowId?: string,
};

export interface ResumePartialDataFetchAction {
    type: typeof RESUME_PARTIAL_DATA_FETCH,
};

export interface SetIsEditingOrgProfileAction {
    type: typeof SET_IS_EDITING_ORG_PROFILE;
    isEditing: boolean;
}

export interface SetEditingOrgStateAction {
    type: typeof SET_EDITING_ORG_STATE;
    organizationState: OrganizationState;
}

export interface SetIsLoggingOutAction {
    type: typeof SET_IS_LOGGING_OUT;
    isLoggingOut: boolean;
}

export interface SetShouldUpdateApplicationAction {
    type: typeof SET_SHOULD_UPDATE_APPLICATION;
    shouldUpdateApplication: boolean;
}

export interface SetLatestVersionNumberAction {
    type: typeof SET_LATEST_VERSION_NUMBER;
    latestVersionNumber: string;
}

export interface SetLastRefreshTimeAction {
    type: typeof SET_LAST_REFRESH_TIME;
    lastRefreshTime: string;
}

export interface CompleteDashboardReloadAction {
    type: typeof COMPLETE_DASHBOARD_RELOAD,
}

export interface AutoSaveToDbAction {
    type: typeof AUTOSAVE_TO_DB,
}

export type MyDataActions = IUpdateMyIDAction | SetIsTopBarExpandedAction | SetIsFetchingForDataUpdateAction | IUpdateMyPermissionsAction | IUpdateLastSyncTimeAction | IUpdateMyDataAction | IStartDataPush | ICompleteDataPush | IStartOlderDataFetch | ICompleteOlderDataFetch | IChangeSuperUserPassword | SelectNudgeAction | ISetToastMessageAction | IClearToastMessageAction | ISetInfoMessageAction | ISetIndeterminateMessageAction | IClearIndeterminateMessageAction | IClearInfoMessageAction | ISetErrorMessageAction | IClearErrorMessageAction | WidgetActionTypes | IFreezeBackgroundAction | IUnFreezeBackgroundAction | MarkNetworkInterruptAction | ResetNetworkCallAction | FetchDataRquestAction | CompleteDataFetchAction | CompletePartialDataFetchAction | ResumePartialDataFetchAction | SetIsEditingOrgProfileAction | SetEditingOrgStateAction | SetIsLoggingOutAction | SetShouldUpdateApplicationAction | SetLatestVersionNumberAction | SetIsOnlineAction | SetLastRefreshTimeAction | SetupSampleProjectAction | SetIsFlowchartExpandedAction | CompleteDashboardReloadAction | AutoSaveToDbAction | ISetWarningMessageAction | IClearWarningMessageAction;