import React, { Component } from 'react';
import styles from './TimingChart.module.scss';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../../shared/store/types';
import { translatePhrase } from '../../../../shared/helpers/translation';
import moment from 'moment';
import { PieceType } from '../../../../shared/store/flowchart/pieces/types';

interface ActionWindow {
    from: string;
    to: string;
}

interface OwnProps {
    userIds: Array<string>;
    selectedWorkflowType?: string;
    date: string;
    toleranceInMinutes?: number;
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    return {
        workflowsData: state.workflows,
        piecesData: state.flowchart.pieces,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = StateProps & DispatchProps & OwnProps;

interface OwnState {
    timestampWindows: Array<ActionWindow>;
}

class ConnectedTimingChart extends Component<Props, OwnState> {

    constructor(props: Props) {
        super(props);

        const workflowIdsInDate = this.getWorkflowsInDate();
        const actionTimeStamps = this.getActionTimeStampsForWorkflows(workflowIdsInDate);
        const timestampWindows = this.getActionWindowsForTimeStamps(actionTimeStamps);

        this.state = {
            timestampWindows,
        };
    }

    getWorkflowsInDate = () => {
        // Consider the last 3000 workflows
        const lastWorkflowIds = this.props.workflowsData.allEntries.slice(-3000);

        // Consider all active flows as well as the last 3000 workflows
        const workflowsToConsider = Array.from(new Set([...lastWorkflowIds, ...this.props.workflowsData.activeWorkflowEntries]));

        const workflowIdsInDate = workflowsToConsider
            .filter(workflowId => {
                const workflow = this.props.workflowsData.byId[workflowId];

                if (this.props.selectedWorkflowType) {
                    if (workflow.type !== this.props.selectedWorkflowType) {
                        return false;
                    }
                }

                const isTrackedByUser = this.props.userIds.includes(workflow.user) || this.props.userIds.some(userId => workflow.trackingUsers.includes(userId));
                const wasActiveForDate = moment(workflow.createdTime).format('YYYY-MM-DD') <= this.props.date && moment(workflow.lastUpdatedTime).format('YYYY-MM-DD') >= this.props.date;

                return isTrackedByUser && wasActiveForDate;
            });

        return workflowIdsInDate;
    }

    getActionTimeStampsForWorkflows = (workflowIds: Array<string>) => {
        const actionTimeStamps: Array<string> = [];

        for (const workflowId of workflowIds) {
            const workflow = this.props.workflowsData.byId[workflowId];

            const processStepTimeStamps = workflow.history
                .filter((step, index) => {
                    const previousStep = index === 0 ? undefined : workflow.history[index - 1];
                    const isExecutingUser = this.props.userIds.includes(step.executingUser);
                    const isPreviousExecutingUser = previousStep ? this.props.userIds.includes(previousStep.executingUser) : false;

                    let transferredStep = false;

                    if (previousStep && previousStep.executingUser != step.executingUser) {
                        const lastPiece = step.lastComputedPiece ? this.props.piecesData.byId[step.lastComputedPiece] : undefined;

                        if (lastPiece && lastPiece.type === PieceType.TRANSFER_WORKFLOW) {
                            transferredStep = true;
                        }
                    }

                    return transferredStep ? isPreviousExecutingUser : isExecutingUser;
                })
                .map(step => step.executionTime);

            for (const timestamp of processStepTimeStamps) {
                if (timestamp.startsWith(this.props.date)) {
                    actionTimeStamps.push(timestamp);
                }
            }
        }

        return actionTimeStamps;
    }

    getActionWindowsForTimeStamps = (timestamps: Array<string>) => {
        const sortedTimeStamps = Array.from(new Set(timestamps)).sort();
        const actionWindows: Array<ActionWindow> = [];
        let startingTimeStamp = sortedTimeStamps[0];
        let lastTimeStamp = sortedTimeStamps[0];
        const toleranceInMinutes = this.props.toleranceInMinutes ? this.props.toleranceInMinutes : 15;

        for (let i = 0; i < sortedTimeStamps.length; i += 1) {
            const timestamp = sortedTimeStamps[i];
            const currentTimeStampValue = moment(timestamp);
            const previousTimeStampValue = moment(lastTimeStamp);

            if ((i === sortedTimeStamps.length - 1) || currentTimeStampValue.diff(previousTimeStampValue, 'minutes') > toleranceInMinutes) {

                actionWindows.push({
                    from: startingTimeStamp,
                    to: startingTimeStamp !== lastTimeStamp ? lastTimeStamp : moment(lastTimeStamp).add(5, 'minutes').format(),
                });

                startingTimeStamp = timestamp;
            }

            lastTimeStamp = timestamp;
        }

        return actionWindows;
    }

    getReadableDuration = (durationInSeconds: number) => {
        const seconds = Math.floor(durationInSeconds % 60);
        const minutes = Math.floor(durationInSeconds / 60) % 60;
        const hours = Math.floor(durationInSeconds / 3600);

        if (hours > 0) {
            return `${hours}h ${minutes}m`;
        } else if (minutes > 0) {
            return `${minutes}m ${seconds}s`;
        } else {
            return `${seconds}s`;
        }
    }

    render() {
        const timestampWindows = this.state.timestampWindows;
        const timeAtMidnight = moment(this.props.date);

        timeAtMidnight.set('hours', 0);
        timeAtMidnight.set('minutes', 0);
        timeAtMidnight.set('seconds', 0);
        timeAtMidnight.set('milliseconds', 0);

        const totalDurationInSeconds = timestampWindows.map(window => moment(window.to).diff(window.from, 'seconds', true)).reduce((prev, current) => prev + current, 0);

        return <section className={styles.timingChartHolder}>
            <div className={styles.tableData}>
                <div className={styles.date}>{moment(this.props.date).format('ddd, Do MMM')}</div>

                <div className={styles.chart}>
                    <div className={styles.windowContainer}>
                        {timestampWindows.map(window => {
                            const startOffset = moment(window.from).diff(timeAtMidnight, 'days', true);
                            const endOffset = moment(window.to).diff(timeAtMidnight, 'days', true);

                            const offsetDifference = endOffset - startOffset;

                            return <section
                                className={styles.timestampWindow}
                                style={{
                                    left: `${startOffset * 100.0}%`,
                                    width: `${offsetDifference * 100.0}%`,
                                }}
                            >
                            </section>
                        })}
                    </div>
                </div>

                <div className={styles.total}>{translatePhrase('Total')}: <span className={styles.highlight}>{this.getReadableDuration(totalDurationInSeconds)}</span> {this.props.userIds.length > 1 && <span>(Avg: <span className={styles.highlight}>{this.getReadableDuration(totalDurationInSeconds / this.props.userIds.length)}</span>)</span>}</div>
            </div>
        </section>
    }
}

const TimingChart = connect(mapStateToProps, mapDispatchToProps)(ConnectedTimingChart);

export default TimingChart;