import React, { Component, ReactNode } from 'react';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';
import { Option } from '../drop-down/ListItem';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import ContainerPiece from './container-piece/ContainerPiece';
import FinsalLoanProcessUpperArm from './FinsalLoanProcessUpperArm';
import FinsalLoanProcessLowerArm from './FinsalLoanProcessLowerArm';


import { nextPieceTarget } from './utilities';
import { WorkflowTypeContext } from '../../../contexts/workflow-type-context';
import { FlowchartContext, FlowchartInfoForPiece } from '../../../contexts/flowchart-context';


type FinsalLoanProcessPieceProps = {
    nextPiece?: JSX.Element,
    innerPiece?: JSX.Element,
    children?: ReactNode,

    premiumPiece: JSX.Element | undefined,
    memberFirstNamePiece: JSX.Element | undefined,
    memberLastNamePiece: JSX.Element | undefined,
    memberEmailPiece: JSX.Element | undefined,
    memberPhonePiece: JSX.Element | undefined,
    memberPanPiece: JSX.Element | undefined,
    memberStatePiece: JSX.Element | undefined,
    memberCityPiece: JSX.Element | undefined,


    memberAddressLine1Piece: JSX.Element | undefined,
    memberAddressLine2Piece: JSX.Element | undefined,
    memberPinCodePiece: JSX.Element | undefined,

    memberDobPiece: JSX.Element | undefined,
    memberGenderPiece: JSX.Element | undefined,
    memberFatherNamePiece: JSX.Element | undefined,
    memberMotherNamePiece: JSX.Element | undefined,
    memberAnnualIncomePiece: JSX.Element | undefined,
    memberMaritalStatusPiece: JSX.Element | undefined,

    userPhonePiece: JSX.Element | undefined,
    userEmailPiece: JSX.Element | undefined,
    memberPiece: JSX.Element | undefined,

    customFields: Array<Option>,
    responseCustomFieldId?: string,

    mode?: string,
    applyForLoanPiece?: JSX.Element | undefined,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string | undefined) => dispatch(setTargetPiece(pieceId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = FinsalLoanProcessPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

class ConnectedFinsalLoanProcessPiece extends Component<Props> {

    handleHoverOverInnerPiece = () => {

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        nextPieceTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    updateValidity = (isValid: boolean, flowchartContext: FlowchartInfoForPiece) => {
        if (!isValid) {
            const invalidPieceInfo = flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId);

            if (!invalidPieceInfo && flowchartContext.setInvalidPiece) {
                flowchartContext.setInvalidPiece(this.props.pieceId, flowchartContext.parentSplitPieceIds);
            }
        }

        if (isValid && flowchartContext.removeInvalidPiece && flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId)) {
            flowchartContext.removeInvalidPiece(this.props.pieceId);
        }
    }

    render() {
        return <WorkflowTypeContext.Consumer>
            {workflowType => {

                return <FlowchartContext.Consumer>
                    {
                        flowchartContext => {
                            let isValid = true;

                            this.updateValidity(isValid, flowchartContext);

                            const upperArmContent = <FinsalLoanProcessUpperArm {...this.props} />;
                            const lowerArmContent = <FinsalLoanProcessLowerArm {...this.props} />;
                            return <ContainerPiece
                                {...this.props}
                                theme={isValid ? "aqua" : "red"}
                                handleHoverOverInnerPiece={this.handleHoverOverInnerPiece}
                                upperArmContent={upperArmContent}
                                lowerArmContent={lowerArmContent}
                            >
                                {this.props.innerPiece}
                            </ContainerPiece>;
                        }
                    }
                </FlowchartContext.Consumer>

            }}
        </WorkflowTypeContext.Consumer>
    }
}

const FinsalLoanProcessPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedFinsalLoanProcessPiece);

export default FinsalLoanProcessPiece;