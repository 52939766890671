import React, { Component } from 'react';
import styles from './Question.module.scss';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/types';

import { updateStatus, updateProcessState, addToHistory, addWorkflow } from '../../../shared/store/workflows/actions';
import { WorkflowProcessState, IUpdateableWorkflowData } from '../../../shared/store/workflows/types';

import { getWorkflowPieceValue } from '../../../shared/store/flowchart/helpers/workflow';
import { PieceType } from '../../../shared/store/flowchart/pieces/types';
import { isUUID } from '../../../shared/helpers/utilities';
import { getWorkflowQuestionValidationValue } from '../../../shared/store/flowchart/helpers/question';
import { VariableType } from '../../../shared/store/flowchart/variables/types';
import { IUser } from '../../../shared/store/users/types';
import { IGroup } from '../../../shared/store/groups/types';
import { getReadableDataForCustomField } from '../../../shared/store/custom-fields';
import { CustomField, CustomFieldOptionsDataType } from '../../../shared/store/custom-fields/types';
import { IMember } from '../../../shared/store/members/types';
import { getPieceValueType } from '../../../shared/store/flowchart/helpers';
import { translatePhrase } from '../../../shared/helpers/translation';
import moment from 'moment';
import { RadioButton } from './RadioButton';
import InputText from '../../../widgets/form/InputText';
import TextDecorator from './TextDecorator';


export type ChoosePieceProps = {
    key?: string,
    workflowId: string,
    questionId: string,
    userInput: string | Array<string>,
    errorMessage?: string,
    overWrittenVariable?: string,
    overWrittenValue?: string,

    isDisabled?: boolean,
    isRequired?: boolean,

    isSectioned?: boolean,

    choiceInputs: {
        [questionId: string]: string | Array<string>,
    },

    validateAnswer: (questionId: string, answer: string | Array<string>, processState: WorkflowProcessState) => Promise<string>,
    onInputChange: (value: string | Array<string> | undefined) => void,
};

const mapStateToProps = (state: ApplicationState, ownProps: ChoosePieceProps) => {
    const choosePiece = state.flowchart.pieces.byId[ownProps.questionId];
    const workflow = state.workflows.byId[ownProps.workflowId];
    let isMultiSelect = false;

    if (choosePiece.type !== PieceType.CHOOSE && choosePiece.type !== PieceType.GROUPED_CHOOSE) {
        throw new Error('The ID should point to a piece of the question type');
    }

    if (!choosePiece.choiceVariable) {
        throw new Error('The question must point to a valid choice variable');
    }

    if (!choosePiece.variablePiece) {
        throw new Error('The question must point to a valid choice list variable');
    }

    const variable = state.flowchart.variables.byId[choosePiece.choiceVariable];

    switch (variable.type) {
        case VariableType.PROJECTS_LIST:
        case VariableType.LEVELS_LIST:
        case VariableType.ROLES_LIST:
        case VariableType.LOCATIONS_LIST:
        case VariableType.USERS_LIST:
        case VariableType.MEMBERS_LIST:
        case VariableType.GROUPS_LIST:
        case VariableType.WORKFLOWS_LIST:
        case VariableType.DATA_FRAGMENTS_LIST:
        case VariableType.TEXT_LIST:
            isMultiSelect = true;
            break;
        default:
            isMultiSelect = false;
    }

    let lastStoredInput: string | Array<string> = '';

    if (state.workflows.screenInputs[workflow.id] && workflow.historyIndex < workflow.history.length - 1) {
        const workflowScreenInputs = state.workflows.screenInputs[workflow.id].find(screenInputs => workflow.historyIndex === screenInputs.workflowIndex);

        if (!!workflowScreenInputs) {
            lastStoredInput = workflowScreenInputs.choices[choosePiece.id];
        }
    }

    return {
        applicationState: state,
        piecesData: state.flowchart.pieces,
        workflowData: state.workflows,
        membersData: state.members,
        questionPiece: choosePiece,
        choicesVariablePieceId: choosePiece.variablePiece,
        lastStoredInput,
        isMultiSelect,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateStatus: (workflowId: string, statusId: string) => dispatch(updateStatus(workflowId, statusId)),
        updateWorkflowProcessState: (processState: WorkflowProcessState, workflowId: string, userId: string) => dispatch(updateProcessState(processState, workflowId, userId)),
        addToHistory: (processState: WorkflowProcessState, workflowId: string, userId: string) => dispatch(addToHistory(processState, workflowId, userId)),
        addWorkflow: (payload: IUpdateableWorkflowData) => dispatch(addWorkflow(payload)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = ChoosePieceProps & StateProps & DispatchProps;

type OwnState = {
    questionText: string,
    allowedChoices: Array<string>,
    isDisabled: boolean,
    isRequired: boolean,
    showMore: boolean,
    optionsSearchText: string,
}

class ConnectedQuestion extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        const workflow = this.props.workflowData.byId[this.props.workflowId];
        let processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: workflow.history[workflow.historyIndex].customFields,
            lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
            executionStack: workflow.history[workflow.historyIndex].executionStack,
            forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
            variables: workflow.history[workflow.historyIndex].variables,
            displayingQuestionPieceId: workflow.history[workflow.historyIndex].displayingQuestionPieceId,
            displayingShowPieceId: workflow.history[workflow.historyIndex].displayingShowPieceId,
            displayingGroupPieceId: workflow.history[workflow.historyIndex].displayingGroupPieceId,
            displayingTransferPieceId: workflow.history[workflow.historyIndex].displayingTransferPieceId,
            displayingContinuePieceId: workflow.history[workflow.historyIndex].displayingContinuePieceId,
            displayingAddWorkflowPieceId: workflow.history[workflow.historyIndex].displayingAddWorkflowPieceId,
            createdWorkflowId: workflow.history[workflow.historyIndex].createdWorkflowId,
        }));

        if (this.props.overWrittenVariable) {
            processState.variables[this.props.overWrittenVariable] = this.props.overWrittenValue;
        }

        for (let questionId in this.props.choiceInputs) {
            if (this.props.choiceInputs.hasOwnProperty(questionId)) {
                const choosePiece = this.props.piecesData.byId[questionId];

                if (choosePiece.type !== PieceType.CHOOSE && choosePiece.type !== PieceType.GROUPED_CHOOSE) {
                    throw new Error('The piece must be a choose piece');
                }

                if (typeof choosePiece.choiceVariable === 'undefined') {
                    throw new Error('The piece must point to a choice variable');
                }

                processState.variables[choosePiece.choiceVariable] = this.props.choiceInputs[questionId];
            }
        }

        let expandedQuestion = this.props.questionPiece.question && isUUID(this.props.questionPiece.question) ? getWorkflowPieceValue(this.props.applicationState, processState, workflow.id, this.props.questionPiece.question) : this.props.questionPiece.question;

        if (typeof expandedQuestion !== 'string') {
            throw new Error('A question value needs to be a string');
        }

        if (typeof this.props.userInput === 'undefined') {

            if (this.props.lastStoredInput) {
                this.props.onInputChange(props.lastStoredInput);
            } else {

                let defaultValue = this.props.questionPiece.default && isUUID(this.props.questionPiece.default) ? getWorkflowPieceValue(this.props.applicationState, processState, workflow.id, this.props.questionPiece.default) : this.props.questionPiece.default;

                if (!!defaultValue && typeof defaultValue === 'string') {
                    this.props.onInputChange(defaultValue);
                }
            }
        }

        let isDisabled: boolean,
            isRequired: boolean,
            answer = props.userInput;

        if (typeof props.isDisabled === 'undefined') {
            const disabledWorkflowProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(processState));
            isDisabled = props.questionPiece.isDisabledPiece ? !!getWorkflowQuestionValidationValue(this.props.applicationState, disabledWorkflowProcessState, workflow.id, props.questionId, answer, {}, props.questionPiece.isDisabledPiece) : false;
        } else {
            isDisabled = props.isDisabled;
        }

        if (typeof props.isRequired === 'undefined') {
            const requiredWorkflowProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(processState));
            isRequired = props.questionPiece.isRequiredPiece ? !!getWorkflowQuestionValidationValue(this.props.applicationState, requiredWorkflowProcessState, workflow.id, props.questionId, answer, {}, props.questionPiece.isRequiredPiece) : false;
        } else {
            isRequired = props.isRequired;
        }

        this.state = {
            questionText: expandedQuestion,
            allowedChoices: [],
            isDisabled,
            isRequired,
            showMore: false,
            optionsSearchText: '',
        };
    }

    toggleShowMore = () => {
        this.setState(prevState => {
            return {
                showMore: !prevState.showMore,
            }
        });
    }

    validateAllowedChoices = async () => {

        const workflow = this.props.workflowData.byId[this.props.workflowId];

        let processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: workflow.history[workflow.historyIndex].customFields,
            lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
            executionStack: workflow.history[workflow.historyIndex].executionStack,
            forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
            variables: workflow.history[workflow.historyIndex].variables,
            displayingQuestionPieceId: workflow.history[workflow.historyIndex].displayingQuestionPieceId,
            displayingShowPieceId: workflow.history[workflow.historyIndex].displayingShowPieceId,
            displayingGroupPieceId: workflow.history[workflow.historyIndex].displayingGroupPieceId,
            displayingTransferPieceId: workflow.history[workflow.historyIndex].displayingTransferPieceId,
            displayingContinuePieceId: workflow.history[workflow.historyIndex].displayingContinuePieceId,
            displayingAddWorkflowPieceId: workflow.history[workflow.historyIndex].displayingAddWorkflowPieceId,
            createdWorkflowId: workflow.history[workflow.historyIndex].createdWorkflowId,
        }));

        if (this.props.overWrittenVariable) {
            processState.variables[this.props.overWrittenVariable] = this.props.overWrittenValue;
        }

        for (let questionId in this.props.choiceInputs) {
            if (this.props.choiceInputs.hasOwnProperty(questionId)) {
                const choosePiece = this.props.piecesData.byId[questionId];

                if (choosePiece.type !== PieceType.CHOOSE && choosePiece.type !== PieceType.GROUPED_CHOOSE) {
                    throw new Error('The piece must be a choose piece');
                }

                if (typeof choosePiece.choiceVariable === 'undefined') {
                    throw new Error('The piece must point to a choice variable');
                }

                processState.variables[choosePiece.choiceVariable] = this.props.choiceInputs[questionId];
            }
        }

        let choicesValue = getWorkflowPieceValue(this.props.applicationState, JSON.parse(JSON.stringify(processState)), this.props.workflowId, this.props.choicesVariablePieceId);

        if (typeof choicesValue === 'undefined') {
            choicesValue = [];
        }

        if (!Array.isArray(choicesValue)) {
            throw new Error('The choices list must point to an array of values')
        }

        if (Array.isArray(choicesValue)) {

            if (choicesValue.length > 0 && Array.isArray(choicesValue[0])) {
                // Cannot be a multidimensional array
                throw new Error('The value cannot be a multi-dimensional array')
            }

            choicesValue = choicesValue as Array<string>;
        }

        let allowedChoices = choicesValue;
        const chooseListType = getPieceValueType(this.props.choicesVariablePieceId, this.props.applicationState.flowchart.pieces, this.props.applicationState.flowchart.variables);

        switch (chooseListType) {
            case VariableType.PROJECTS_LIST:
                allowedChoices = allowedChoices.filter(choiceId => choiceId in this.props.applicationState.structure.projects.byId && !this.props.applicationState.structure.projects.byId[choiceId].archived);
                break;

            case VariableType.LEVELS_LIST:
                allowedChoices = allowedChoices.filter(choiceId => choiceId in this.props.applicationState.structure.levels.byId && !this.props.applicationState.structure.levels.byId[choiceId].archived);
                break;

            case VariableType.ROLES_LIST:
                allowedChoices = allowedChoices.filter(choiceId => choiceId in this.props.applicationState.structure.roles.byId && !this.props.applicationState.structure.roles.byId[choiceId].archived);
                break;

            case VariableType.LOCATIONS_LIST:
                allowedChoices = allowedChoices.filter(choiceId => choiceId in this.props.applicationState.structure.locations.byId && !this.props.applicationState.structure.locations.byId[choiceId].archived);
                break;

            case VariableType.USERS_LIST:
                allowedChoices = allowedChoices.filter(choiceId => choiceId in this.props.applicationState.users.byId && !this.props.applicationState.users.byId[choiceId].archived);
                break;

            case VariableType.MEMBERS_LIST:
                allowedChoices = allowedChoices.filter(choiceId => choiceId in this.props.applicationState.members.byId && !this.props.applicationState.members.byId[choiceId].archived);
                break;

            case VariableType.GROUPS_LIST:
                allowedChoices = allowedChoices.filter(choiceId => choiceId in this.props.applicationState.groups.byId && !this.props.applicationState.groups.byId[choiceId].archived);
                break;

            case VariableType.WORKFLOWS_LIST:
                allowedChoices = allowedChoices.filter(choiceId => choiceId in this.props.applicationState.workflows.byId && !this.props.applicationState.workflows.byId[choiceId].archived);
                break;

            case VariableType.DATA_FRAGMENTS_LIST:
                allowedChoices = allowedChoices.filter(choiceId => choiceId in this.props.applicationState.staticInfo.fragments.byId && !this.props.applicationState.staticInfo.fragments.byId[choiceId].archived);
                break;

            default:
                break;
        }

        if (this.props.questionPiece.innerPiece) {
            const filteredChoices = [];
            for (const choice of allowedChoices) {
                const errorMessage = await this.props.validateAnswer(this.props.questionId, choice, processState);
                if (!errorMessage) filteredChoices.push(choice);
            }
            allowedChoices = filteredChoices;
        }

        this.setState({ allowedChoices: allowedChoices });
    }

    static getDerivedStateFromProps(props: Readonly<Props>, state: Readonly<OwnState>) {
        if (props.isDisabled !== state.isDisabled || props.isRequired !== state.isRequired) {
            const workflow = props.workflowData.byId[props.workflowId];
            let processState: WorkflowProcessState = JSON.parse(JSON.stringify({
                customFields: workflow.history[workflow.historyIndex].customFields,
                lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
                executionStack: workflow.history[workflow.historyIndex].executionStack,
                forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
                variables: workflow.history[workflow.historyIndex].variables,
                displayingQuestionPieceId: workflow.history[workflow.historyIndex].displayingQuestionPieceId,
                displayingShowPieceId: workflow.history[workflow.historyIndex].displayingShowPieceId,
                displayingGroupPieceId: workflow.history[workflow.historyIndex].displayingGroupPieceId,
                displayingTransferPieceId: workflow.history[workflow.historyIndex].displayingTransferPieceId,
                displayingContinuePieceId: workflow.history[workflow.historyIndex].displayingContinuePieceId,
                displayingAddWorkflowPieceId: workflow.history[workflow.historyIndex].displayingAddWorkflowPieceId,
                createdWorkflowId: workflow.history[workflow.historyIndex].createdWorkflowId,
            }));

            if (props.overWrittenVariable) {
                processState.variables[props.overWrittenVariable] = props.overWrittenValue;
            }

            let isDisabled: boolean, isRequired: boolean,
                answer = props.userInput;

            if (typeof props.isDisabled === 'undefined') {
                const disabledWorkflowProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(processState));

                isDisabled = props.questionPiece.isDisabledPiece ? !!getWorkflowQuestionValidationValue(props.applicationState, disabledWorkflowProcessState, workflow.id, props.questionId, answer, {}, props.questionPiece.isDisabledPiece) : false;
            } else {
                isDisabled = props.isDisabled;
            }

            if (typeof props.isRequired === 'undefined') {
                let requiredWorkflowProcessState: WorkflowProcessState = JSON.parse(JSON.stringify(processState));

                isRequired = props.questionPiece.isRequiredPiece ? !!getWorkflowQuestionValidationValue(props.applicationState, requiredWorkflowProcessState, workflow.id, props.questionId, answer, {}, props.questionPiece.isRequiredPiece) : false;
            } else {
                isRequired = props.isRequired;
            }

            return {
                isDisabled,
                isRequired
            };
        }

        return null;
    }

    selectChoice = (value: string) => {
        const selectedValue = value;
        let newValue: string | Array<string> | undefined;

        if (this.props.isMultiSelect) {
            const existingValue = Array.isArray(this.props.userInput) ? this.props.userInput : [];

            if (existingValue.includes(selectedValue)) {
                newValue = existingValue.filter(selectedChoice => selectedChoice !== selectedValue);
            } else {
                newValue = [...existingValue, selectedValue];
            }
        } else {
            const existingValue = !Array.isArray(this.props.userInput) ? this.props.userInput : undefined;

            if (selectedValue === existingValue) {
                newValue = undefined;
            } else {
                newValue = selectedValue;
            }
        }

        this.props.onInputChange(newValue);
    }

    searchOptions = (e: string) => {
        this.setState({
            optionsSearchText: e,
        });
    }

    getCommonCustomFieldValue = (entity: IUser | IMember | IGroup, type: 'user' | 'member' | 'group', customField: CustomField, optionsData: CustomFieldOptionsDataType) => {
        let customFieldValue = entity.customFields[customField.id];

        return getReadableDataForCustomField(customFieldValue, customField, entity.id, type);
    }

    isChoiceSelected = (choiceId: string) => {
        if (Array.isArray(this.props.userInput)) {
            return this.props.userInput.includes(choiceId);
        }

        return choiceId === this.props.userInput;
    }

    componentDidMount() {
        this.validateAllowedChoices();
    }

    render() {
        let answerMarkup: JSX.Element;

        const chooseListType = getPieceValueType(this.props.choicesVariablePieceId, this.props.applicationState.flowchart.pieces, this.props.applicationState.flowchart.variables);
        const lowerCaseSearchTerm = this.state.optionsSearchText.toLocaleLowerCase().trim();

        switch (chooseListType) {
            case VariableType.PROJECTS_LIST:

                answerMarkup = <section className={styles.choicesList}>
                    <section className={styles.options}>
                        {this.state.allowedChoices.length > 10 && <div className={styles.searchInput}>
                            <InputText type="text" onChange={(e) => this.searchOptions(e)} placeholder={translatePhrase('Search')} />
                        </div>}
                        {(this.state.showMore ? this.state.allowedChoices : this.state.allowedChoices.slice(0, 10)).filter(choiceId => this.props.applicationState.structure.projects.byId[choiceId].name.toLocaleLowerCase().includes(this.state.optionsSearchText.toLocaleLowerCase())).map(choiceId => {
                            return (<section data-selector={'choice-input-option-' + this.props.applicationState.structure.projects.byId[choiceId].name} key={choiceId} className={this.isChoiceSelected(choiceId) ? styles.activeChoice : styles.choice} onClick={() => this.selectChoice(choiceId)}>
                                <RadioButton isActive={this.isChoiceSelected(choiceId)} />
                                <div className={styles.choiceMainText}>{translatePhrase(this.props.applicationState.structure.projects.byId[choiceId].name)}</div>
                            </section>)
                        })}
                        {this.state.allowedChoices.length > 10 && <div className={styles.moreOptions} onClick={this.toggleShowMore}>{this.state.showMore ? translatePhrase('Show Less') : translatePhrase('Show More')}...</div>}
                    </section>
                </section>;
                break;

            case VariableType.LEVELS_LIST:

                answerMarkup = <section className={styles.choicesList}>
                    <section className={styles.options}>
                        {this.state.allowedChoices.map(choiceId => {
                            const level = this.props.applicationState.structure.levels.byId[choiceId];
                            return (<section data-selector={'choice-input-option-' + level.name} key={choiceId} className={this.isChoiceSelected(choiceId) ? styles.activeChoice : styles.choice} onClick={() => this.selectChoice(choiceId)}>
                                <RadioButton isActive={this.isChoiceSelected(choiceId)} />
                                <div className={styles.choiceMainText}>{translatePhrase(level.name)}</div>
                            </section>)
                        })}
                    </section>
                </section>;
                break;

            case VariableType.ROLES_LIST:

                answerMarkup = <section className={styles.choicesList}>
                    <section className={styles.options}>
                        {this.state.allowedChoices.map(choiceId => {
                            return (<section data-selector={'choice-input-option-' + this.props.applicationState.structure.roles.byId[choiceId].name} key={choiceId} className={this.isChoiceSelected(choiceId) ? styles.activeChoice : styles.choice} onClick={() => this.selectChoice(choiceId)}>
                                <RadioButton isActive={this.isChoiceSelected(choiceId)} />
                                <div className={styles.choiceMainText}>{translatePhrase(this.props.applicationState.structure.roles.byId[choiceId].name)}</div>
                            </section>)
                        })}
                    </section>
                </section>;
                break;

            case VariableType.LOCATIONS_LIST:
                const locationChoiceElements: Array<JSX.Element> = [];

                for (const choiceId of this.state.allowedChoices) {
                    const location = this.props.applicationState.structure.locations.byId[choiceId];
                    let parentName = '-';

                    if (location.parent) {
                        if (location.parent in this.props.applicationState.structure.locations.byId) {
                            parentName = this.props.applicationState.structure.locations.byId[location.parent].name;
                        } else {
                            parentName = this.props.applicationState.structure.projects.byId[location.parent].name;
                        }
                    }

                    const choiceElement = <section data-selector={'choice-input-option-' + location.name} key={choiceId} className={this.isChoiceSelected(choiceId) ? styles.activeChoice : styles.choice} onClick={() => this.selectChoice(choiceId)}>
                        <RadioButton isActive={this.isChoiceSelected(choiceId)} />
                        <div className={styles.choiceMainText}>{translatePhrase(location.name)}</div>
                        <div className={styles.choiceSubText}>{translatePhrase(parentName)}</div>
                    </section>;

                    const matchesSearch = translatePhrase(location.name).toLocaleLowerCase().includes(lowerCaseSearchTerm) || translatePhrase(parentName).toLocaleLowerCase().includes(lowerCaseSearchTerm);

                    if (matchesSearch) {
                        locationChoiceElements.push(choiceElement);
                    }
                }

                answerMarkup = <section className={styles.choicesList}>
                    <section className={styles.options}>
                        {this.state.allowedChoices.length > 10 && <div className={styles.searchInput}>
                            <InputText type="text" onChange={(e) => this.searchOptions(e)} placeholder={translatePhrase('Search')} />
                        </div>}
                        {this.state.showMore ? locationChoiceElements : locationChoiceElements.slice(0, 10)}
                        {locationChoiceElements.length > 10 && <div className={styles.moreOptions} onClick={this.toggleShowMore}>{this.state.showMore ? translatePhrase('Show Less') : translatePhrase('Show More')}...</div>}
                    </section>
                </section>;
                break;

            case VariableType.USERS_LIST:

                const userChoiceElements: Array<JSX.Element> = [];

                for (const choiceId of this.state.allowedChoices) {
                    const user = this.props.applicationState.users.byId[choiceId];
                    const nameField = this.props.applicationState.users.customFields.byId[this.props.applicationState.users.nameFieldId];
                    let nameOfUser = this.getCommonCustomFieldValue(user, 'user', nameField, this.props.applicationState.users.customFieldOptions);
                    const subTitleField = this.props.applicationState.users.customFields.byId[this.props.applicationState.users.subTitleFieldId];
                    let subTitleOfUser = this.getCommonCustomFieldValue(user, 'user', subTitleField, this.props.applicationState.users.customFieldOptions);

                    const choiceElement = <section data-selector={'choice-input-option-' + nameOfUser} key={choiceId} className={this.isChoiceSelected(choiceId) ? styles.activeChoice : styles.choice} onClick={() => this.selectChoice(choiceId)}>
                        <RadioButton isActive={this.isChoiceSelected(choiceId)} />
                        <div className={styles.choiceMainText}>{nameOfUser}</div>
                        <div className={styles.choiceSubText}>{subTitleOfUser}</div>
                    </section>;

                    const matchesSearch = nameOfUser.toLocaleLowerCase().includes(lowerCaseSearchTerm) || subTitleOfUser.toLocaleLowerCase().includes(lowerCaseSearchTerm);

                    if (matchesSearch) {
                        userChoiceElements.push(choiceElement);
                    }
                }

                answerMarkup = <section className={styles.choicesList}>
                    <section className={styles.options}>
                        {this.state.allowedChoices.length > 10 && <div className={styles.searchInput}>
                            <InputText type="text" onChange={(e) => this.searchOptions(e)} placeholder={translatePhrase('Search')} />
                        </div>}
                        {this.state.showMore ? userChoiceElements : userChoiceElements.slice(0, 10)}
                        {userChoiceElements.length > 10 && <div className={styles.moreOptions} onClick={this.toggleShowMore}>{this.state.showMore ? translatePhrase('Show Less') : translatePhrase('Show More')}...</div>}
                    </section>
                </section>;
                break;

            case VariableType.MEMBERS_LIST:

                const memberChoiceElements: Array<JSX.Element> = [];

                for (const choiceId of this.state.allowedChoices) {
                    const member = this.props.applicationState.members.byId[choiceId];
                    const memberType = this.props.applicationState.members.types.byId[member.type];
                    const nameField = this.props.applicationState.members.types.customFields.byId[memberType.nameFieldId];
                    let nameOfMember = this.getCommonCustomFieldValue(member, 'member', nameField, this.props.applicationState.members.types.customFieldOptions);
                    const subTitleField = this.props.applicationState.members.types.customFields.byId[memberType.subTitleFieldId];
                    let subTitleOfMember = this.getCommonCustomFieldValue(member, 'member', subTitleField, this.props.applicationState.members.types.customFieldOptions);

                    const choiceElement = <section data-selector={'choice-input-option-' + nameOfMember} key={choiceId} className={this.isChoiceSelected(choiceId) ? styles.activeChoice : styles.choice} onClick={() => this.selectChoice(choiceId)}>
                        <RadioButton isActive={this.isChoiceSelected(choiceId)} />
                        <div className={styles.choiceTextHolder}>
                            <div className={styles.choiceMainText}>{nameOfMember}</div>
                            <div className={styles.choiceSubText}>{subTitleOfMember}</div>
                        </div>
                    </section>;

                    const matchesSearch = nameOfMember.toLocaleLowerCase().includes(lowerCaseSearchTerm) || subTitleOfMember.toLocaleLowerCase().includes(lowerCaseSearchTerm);

                    if (matchesSearch) {
                        memberChoiceElements.push(choiceElement);
                    }
                }

                answerMarkup = <section className={styles.choicesList}>
                    <section className={styles.options}>
                        {this.state.allowedChoices.length > 10 && <div className={styles.searchInput}>
                            <InputText type="text" onChange={(e) => this.searchOptions(e)} placeholder={translatePhrase('Search')} />
                        </div>}
                        {this.state.showMore ? memberChoiceElements : memberChoiceElements.slice(0, 10)}
                        {memberChoiceElements.length > 10 && <div className={styles.moreOptions} onClick={this.toggleShowMore}>{this.state.showMore ? translatePhrase('Show Less') : translatePhrase('Show More')}...</div>}
                    </section>
                </section>;
                break;

            case VariableType.GROUPS_LIST:

                const groupChoiceElements: Array<JSX.Element> = [];

                for (const choiceId of this.state.allowedChoices) {
                    const group = this.props.applicationState.groups.byId[choiceId];
                    const groupType = this.props.applicationState.groups.types.byId[group.type];
                    const nameField = this.props.applicationState.groups.types.customFields.byId[groupType.nameFieldId];
                    let nameOfGroup = this.getCommonCustomFieldValue(group, 'group', nameField, this.props.applicationState.groups.types.customFieldOptions);
                    const subTitleField = this.props.applicationState.groups.types.customFields.byId[groupType.subTitleFieldId];
                    let subTitleOfGroup = this.getCommonCustomFieldValue(group, 'group', subTitleField, this.props.applicationState.groups.types.customFieldOptions);

                    const choiceElement = <section data-selector={'choice-input-option-' + nameOfGroup} key={choiceId} className={this.isChoiceSelected(choiceId) ? styles.activeChoice : styles.choice} onClick={() => this.selectChoice(choiceId)}>
                        <RadioButton isActive={this.isChoiceSelected(choiceId)} />
                        <div className={styles.choiceMainText}>{nameOfGroup}</div>
                        <div className={styles.choiceSubText}>{subTitleOfGroup}</div>
                    </section>;

                    const matchesSearch = nameOfGroup.toLocaleLowerCase().includes(lowerCaseSearchTerm) || subTitleOfGroup.toLocaleLowerCase().includes(lowerCaseSearchTerm);

                    if (matchesSearch) {
                        groupChoiceElements.push(choiceElement);
                    }
                }

                answerMarkup = <section className={styles.choicesList}>
                    <section className={styles.options}>
                        {this.state.allowedChoices.length > 10 && <div className={styles.searchInput}>
                            <InputText type="text" onChange={(e) => this.searchOptions(e)} placeholder={translatePhrase('Search')} />
                        </div>}
                        {this.state.showMore ? groupChoiceElements : groupChoiceElements.slice(0, 10)}
                        {groupChoiceElements.length > 10 && <div className={styles.moreOptions} onClick={this.toggleShowMore}>{this.state.showMore ? translatePhrase('Show Less') : translatePhrase('Show More')}...</div>}
                    </section>
                </section>;
                break;

            case VariableType.WORKFLOWS_LIST:

                const workflowChoiceElements: Array<JSX.Element> = [];

                for (const choiceId of this.state.allowedChoices) {
                    const workflow = this.props.applicationState.workflows.byId[choiceId];
                    const workflowType = this.props.applicationState.workflows.types.byId[workflow.type];
                    let subTitleOfWorkflow = '';

                    if (workflowType.affiliation === 'member') {
                        const member = this.props.applicationState.members.byId[workflow.affiliatedEntity];
                        const memberType = this.props.applicationState.members.types.byId[member.type];
                        const nameField = this.props.applicationState.members.types.customFields.byId[memberType.nameFieldId];
                        const nameValue = this.getCommonCustomFieldValue(member, 'member', nameField, this.props.applicationState.members.types.customFieldOptions);
                        const subTitleField = this.props.applicationState.members.types.customFields.byId[memberType.subTitleFieldId];
                        const subTitleValue = this.getCommonCustomFieldValue(member, 'member', subTitleField, this.props.applicationState.members.types.customFieldOptions);

                        subTitleOfWorkflow = `${nameValue} (${subTitleValue}) at ${moment(workflow.createdTime).format('DD MMM YYYY')})`;
                    } else if (workflowType.affiliation === 'group') {
                        const group = this.props.applicationState.groups.byId[workflow.affiliatedEntity];
                        const groupType = this.props.applicationState.groups.types.byId[group.type];
                        const nameField = this.props.applicationState.groups.types.customFields.byId[groupType.nameFieldId];
                        const nameValue = this.getCommonCustomFieldValue(group, 'group', nameField, this.props.applicationState.groups.types.customFieldOptions);
                        const subTitleField = this.props.applicationState.groups.types.customFields.byId[groupType.subTitleFieldId];
                        const subTitleValue = this.getCommonCustomFieldValue(group, 'group', subTitleField, this.props.applicationState.groups.types.customFieldOptions);

                        subTitleOfWorkflow = `${nameValue} (${subTitleValue}) at ${moment(workflow.createdTime).format('DD MMM YYYY')})`;
                    }

                    if (workflowType.subTitleFieldId) {
                        try {
                            const subTitleField = this.props.workflowData.types.customFields.byId[workflowType.subTitleFieldId];
                            const detailsValue = workflow.history[workflow.historyIndex].customFields[workflowType.subTitleFieldId];

                            // Only allow fields that are not member-affiliated in group workflows
                            if (Array.isArray(detailsValue) || typeof detailsValue !== 'object') {
                                subTitleOfWorkflow = getReadableDataForCustomField(detailsValue, subTitleField, workflow.id, 'workflow')
                            }
                        } catch (e) {
                            console.error(e);
                        }
                    }

                    const choiceElement = <section data-selector={'choice-input-option-' + workflowType.name} key={choiceId} className={this.isChoiceSelected(choiceId) ? styles.activeChoice : styles.choice} onClick={() => this.selectChoice(choiceId)}>
                        <RadioButton isActive={this.isChoiceSelected(choiceId)} />
                        <div className={styles.choiceMainText}>{workflowType.name}</div>
                        <div className={styles.choiceSubText}>{subTitleOfWorkflow}</div>
                    </section>;

                    const matchesSearch = workflowType.name.toLocaleLowerCase().includes(lowerCaseSearchTerm) || subTitleOfWorkflow.toLocaleLowerCase().includes(lowerCaseSearchTerm);

                    if (matchesSearch) {
                        workflowChoiceElements.push(choiceElement);
                    }
                }

                answerMarkup = <section className={styles.choicesList}>
                    <section className={styles.options}>
                        {this.state.allowedChoices.length > 10 && <div className={styles.searchInput}>
                            <InputText type="text" onChange={(e) => this.searchOptions(e)} placeholder={translatePhrase('Search')} />
                        </div>}
                        {this.state.showMore ? workflowChoiceElements : workflowChoiceElements.slice(0, 10)}
                        {workflowChoiceElements.length > 10 && <div className={styles.moreOptions} onClick={this.toggleShowMore}>{this.state.showMore ? translatePhrase('Show Less') : translatePhrase('Show More')}...</div>}
                    </section>
                </section>;
                break;

            case VariableType.TEXT_LIST:
                const lowerCaseSearchText = this.state.optionsSearchText.toLocaleLowerCase();
                const textListElements = this.state.allowedChoices.filter(choiceId => choiceId.toLocaleLowerCase().includes(lowerCaseSearchText)).map(choice => {
                    return (<section data-selector={'choice-input-option-' + choice} key={choice} className={this.isChoiceSelected(choice) ? styles.activeChoice : styles.choice} onClick={() => this.selectChoice(choice)}>
                        <div className={styles.choiceMainText}>{choice}</div>
                    </section>)
                });

                answerMarkup = <section className={styles.choicesList}>
                    <section className={styles.options}>
                        {this.state.allowedChoices.length > 10 && <div className={styles.searchInput}>
                            <InputText type="text" onChange={(e) => this.searchOptions(e)} placeholder={translatePhrase('Search')} />
                        </div>}

                        {this.state.showMore ? textListElements : textListElements.slice(0, 10)}
                        {textListElements.length > 10 && <div className={styles.moreOptions} onClick={this.toggleShowMore}>{this.state.showMore ? translatePhrase('Show Less') : translatePhrase('Show More')}...</div>}
                    </section>
                </section>;
                break;

            case VariableType.DATA_FRAGMENTS_LIST:

                const choiceElements = this.state.allowedChoices.filter(choiceId => this.props.applicationState.staticInfo.fragments.byId[choiceId].name.toLocaleLowerCase().includes(this.state.optionsSearchText.toLocaleLowerCase())).map(choiceId => {
                    return (<section data-selector={'choice-input-option-' + this.props.applicationState.staticInfo.fragments.byId[choiceId].name} key={choiceId} className={this.isChoiceSelected(choiceId) ? styles.activeChoice : styles.choice} onClick={() => this.selectChoice(choiceId)}>
                        <div className={styles.choiceMainText}>{translatePhrase(this.props.applicationState.staticInfo.fragments.byId[choiceId].name)}</div>
                    </section>)
                });

                answerMarkup = <section className={styles.choicesList}>
                    <section className={styles.options}>
                        {this.state.allowedChoices.length > 10 && <div className={styles.searchInput}>
                            <InputText type="text" onChange={(e) => this.searchOptions(e)} placeholder={translatePhrase('Search')} />
                        </div>}
                        {this.state.showMore ? choiceElements : choiceElements.slice(0, 10)}
                        {choiceElements.length > 10 && <div className={styles.moreOptions} onClick={this.toggleShowMore}>{this.state.showMore ? translatePhrase('Show Less') : translatePhrase('Show More')}...</div>}
                    </section>
                </section>;
                break;

            default:
                const defaultElements = this.state.allowedChoices.filter(choiceId => choiceId.toLocaleLowerCase().includes(this.state.optionsSearchText.toLocaleLowerCase())).map(choice => {
                    return (<section data-selector={'choice-input-option-' + choice} key={choice} className={this.isChoiceSelected(choice) ? styles.activeChoice : styles.choice} onClick={() => this.selectChoice(choice)}>
                        <div className={styles.choiceMainText}>{choice}</div>
                    </section>)
                });

                answerMarkup = <section className={styles.choicesList}>
                    <section className={styles.options}>
                        {this.state.allowedChoices.length > 10 && <div className={styles.searchInput}>
                            <InputText type="text" onChange={(e) => this.searchOptions(e)} placeholder={translatePhrase('Search')} />
                        </div>}
                        {this.state.showMore ? defaultElements : defaultElements.slice(0, 10)}
                        {defaultElements.length > 10 && <div className={styles.moreOptions} onClick={this.toggleShowMore}>{this.state.showMore ? translatePhrase('Show Less') : translatePhrase('Show More')}...</div>}
                    </section>
                </section>;
                break;
        }

        return <div>
            <div key={this.state.questionText} id={this.props.overWrittenValue ? this.props.questionId + this.props.overWrittenValue : this.props.questionId} className={(this.state.isDisabled ? styles.disabledQuestion : styles.question) + ' ' + (this.props.errorMessage ? styles.errorQuestion : '')}>
                <section className={styles.questionText}>
                    <span className={styles.questionWords}>
                        <TextDecorator text={translatePhrase(this.state.questionText)} />
                        {!this.state.isRequired && <span className={styles.optionalIndicator}>({translatePhrase('optional')})</span>}
                    </span>
                </section>
                <div className={styles.questionDetails}>
                    {answerMarkup}
                </div>
            </div>
        </div>

    }

}

const Question = connect(mapStateToProps, mapDispatchToProps)(ConnectedQuestion);

export default Question;