import React, { Component } from 'react';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/types';
import { INewRoleData, IUpdateableRoleData } from '../../../shared/store/structure/role/types';
import { reOrderRoles, addRole, updateRole, deleteRole } from '../../../shared/store/structure/role/actions';
import { translatePhrase } from '../../../shared/helpers/translation';

import CardsList from '../../../widgets/card/CardsList';
import ModifyForm from '../../../widgets/card/ModifyForm';
import EnhancedInputText from '../../../widgets/form/InputText';
import { CardType } from '../../../widgets/card/Card';

interface OwnProps {
    projectId?: string,
    levelId?: string,
    isShowingProjectName?: boolean,
    isShowingLevelName?: boolean,

    selectedId?: string,
    heading: string,
    isReadOnly: boolean,
    isSearchable?: boolean,
    showCardCount?: boolean,

    onSelectCard: (id: string) => void,
    onUnSelectCard: () => void,
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    let allRoleIds: Array<string> = [];

    if (ownProps.levelId) {
        allRoleIds = state.structure.levels.byId[ownProps.levelId].children;
    } else if (ownProps.projectId) {
        const project = state.structure.projects.byId[ownProps.projectId];
        allRoleIds = project.children.map(levelId => state.structure.levels.byId[levelId].children).flat();
    } else {
        allRoleIds = state.structure.projects.allEntries
            .map(projectId => state.structure.projects.byId[projectId].children).flat()
            .map(levelId => state.structure.levels.byId[levelId].children).flat()
            .filter(roleId => {
                const role = state.structure.roles.byId[roleId];
                const level = state.structure.levels.byId[role.level];
                const project = state.structure.projects.byId[level.project];

                return !role.archived && !level.archived && !project.archived;
            });
    }


    let allRoles: Array<IUpdateableRoleData & CardType> = allRoleIds.map(roleId => {
        const role = state.structure.roles.byId[roleId];
        let suffix = '';

        if (ownProps.isShowingLevelName) {
            const level = state.structure.levels.byId[role.level];
            suffix = translatePhrase(level.name);
        } else if (ownProps.isShowingProjectName) {
            const level = state.structure.levels.byId[role.level];
            const project = state.structure.projects.byId[level.project];
            suffix = translatePhrase(project.name);
        }

        const hasUsers = state.users.allEntries.some(userId => state.users.byId[userId].roles.includes(roleId));

        return {
            ...role,
            name: translatePhrase(role.name),
            isDeleteRestricted: hasUsers,
            details: suffix
        };

    });

    return {
        read: true,
        write: true,
        restrictStructureChanges: false,

        cardsList: allRoles,
        selectedCard: ownProps.selectedId ? allRoles.find(role => role.id === ownProps.selectedId) : undefined,
    };
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
    return {
        reOrderCards: (sourceIndex: number, destinationIndex: number) => dispatch(reOrderRoles(sourceIndex, destinationIndex, ownProps.levelId || '')),
        addCard: (payload: INewRoleData) => dispatch(addRole(payload, ownProps.levelId || '')),
        deleteCard: (id: string) => dispatch(deleteRole(id, ownProps.levelId || '')),
        updateCard: (payload: IUpdateableRoleData) => dispatch(updateRole(payload)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

interface OwnState {
    isShowingAddForm: boolean,
    isShowingModifyForm: boolean,
    modifyingCardName: string,
    modifyingCardMemberThresholdDays: string,
    modifyingCardGroupThresholdDays: string,
    modifyingCardWorkflowThresholdDays: string,
    modifyingCardBulkActions: string,
};

class ConnectedRolesList extends Component<Props, OwnState> {

    state: OwnState = {
        isShowingAddForm: false,
        isShowingModifyForm: false,
        modifyingCardName: '',
        modifyingCardMemberThresholdDays: '',
        modifyingCardGroupThresholdDays: '',
        modifyingCardWorkflowThresholdDays: '',
        modifyingCardBulkActions: '',
    }

    static defaultProps = {
        isReadOnly: false,
        isShowingProjectName: false,
        showCardCount: true,
    }

    handleSelectCard = (id: string) => {
        this.props.onSelectCard(id);
    }

    toggleModifyForm = () => {
        let toggledState = !this.state.isShowingAddForm;

        if (this.state.isShowingModifyForm) {
            this.setState({
                isShowingModifyForm: false,
                isShowingAddForm: false
            });
        } else {
            this.setState({
                isShowingAddForm: toggledState
            });
        }
    }

    editSelectedCard = () => {

        if (!this.props.selectedCard) {
            throw new Error('Cannot edit card since nothing is selected');
        }

        this.setState({
            isShowingModifyForm: true,
            modifyingCardName: this.props.selectedCard.name,
            modifyingCardMemberThresholdDays: typeof this.props.selectedCard.thresholdDaysForMembers !== 'undefined' ? String(this.props.selectedCard.thresholdDaysForMembers) : '',
            modifyingCardGroupThresholdDays: typeof this.props.selectedCard.thresholdDaysForGroups !== 'undefined' ? String(this.props.selectedCard.thresholdDaysForGroups) : '',
            modifyingCardWorkflowThresholdDays: typeof this.props.selectedCard.thresholdDaysForWorkflows !== 'undefined' ? String(this.props.selectedCard.thresholdDaysForWorkflows) : '',
            modifyingCardBulkActions: this.props.selectedCard.bulkActions ? 'Yes' : 'No',
        });
    }

    updateCardName = (value: string) => {
        this.setState({
            modifyingCardName: value,
        });
    }

    updateCardMemberThresholdDays = (value: string) => {
        this.setState({
            modifyingCardMemberThresholdDays: value,
        });
    }

    updateCardGroupThresholdDays = (value: string) => {
        this.setState({
            modifyingCardGroupThresholdDays: value,
        });
    }

    updateCardWorkflowThresholdDays = (value: string) => {
        this.setState({
            modifyingCardWorkflowThresholdDays: value,
        });
    }

    updateCardBulkActions = (value: string) => {
        this.setState({
            modifyingCardBulkActions: value,
        });
    }

    addCard = () => {

        this.props.addCard({
            name: this.state.modifyingCardName,
            thresholdDaysForMembers: !this.state.modifyingCardMemberThresholdDays ? undefined : Number(this.state.modifyingCardMemberThresholdDays),
            thresholdDaysForGroups: !this.state.modifyingCardGroupThresholdDays ? undefined : Number(this.state.modifyingCardGroupThresholdDays),
            thresholdDaysForWorkflows: !this.state.modifyingCardWorkflowThresholdDays ? undefined : Number(this.state.modifyingCardWorkflowThresholdDays),
            bulkActions: this.state.modifyingCardBulkActions === 'Yes',
        });

        this.setState({
            modifyingCardName: '',
            modifyingCardMemberThresholdDays: '',
            modifyingCardGroupThresholdDays: '',
            modifyingCardWorkflowThresholdDays: '',
            modifyingCardBulkActions: 'No',
            isShowingAddForm: false
        });
    }

    updateCard = () => {

        if (!this.props.selectedCard) {
            return;
        }

        this.props.updateCard({
            id: this.props.selectedCard.id,
            name: this.state.modifyingCardName,
            thresholdDaysForMembers: !this.state.modifyingCardMemberThresholdDays || this.state.modifyingCardMemberThresholdDays.trim() === '0' ? undefined : Number(this.state.modifyingCardMemberThresholdDays),
            thresholdDaysForGroups: !this.state.modifyingCardGroupThresholdDays || this.state.modifyingCardGroupThresholdDays.trim() === '0' ? undefined : Number(this.state.modifyingCardGroupThresholdDays),
            thresholdDaysForWorkflows: !this.state.modifyingCardWorkflowThresholdDays || this.state.modifyingCardWorkflowThresholdDays.trim() === '0' ? undefined : Number(this.state.modifyingCardWorkflowThresholdDays),
            bulkActions: this.state.modifyingCardBulkActions === 'Yes',
        });

        this.setState({
            isShowingModifyForm: false,
            modifyingCardName: '',
            modifyingCardMemberThresholdDays: '',
            modifyingCardGroupThresholdDays: '',
            modifyingCardWorkflowThresholdDays: '',
            isShowingAddForm: false
        });
    }

    validateCard = () => {
        if (!this.state.modifyingCardName) {
            return translatePhrase('Enter a valid name');
        }

        return true;
    }

    render() {

        const modifyForm = <ModifyForm hideCancel isNew={!this.state.isShowingModifyForm} submitForm={this.state.isShowingModifyForm ? this.updateCard : this.addCard} cancelForm={this.toggleModifyForm} validateForm={this.validateCard}>
            <EnhancedInputText placeholder="Name" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardName} default={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.name : ''} key={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.id : 0} />
            <EnhancedInputText placeholder="Member Threshold" type="tel" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardMemberThresholdDays} default={this.state.isShowingModifyForm && this.props.selectedCard && !isNaN(Number(this.props.selectedCard.thresholdDaysForMembers)) ? String(this.props.selectedCard.thresholdDaysForMembers) : ''} key={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.id : 0} />
            <EnhancedInputText placeholder="Group Threshold" type="tel" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardGroupThresholdDays} default={this.state.isShowingModifyForm && this.props.selectedCard && !isNaN(Number(this.props.selectedCard.thresholdDaysForGroups)) ? String(this.props.selectedCard.thresholdDaysForGroups) : ''} key={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.id : 0} />
            <EnhancedInputText placeholder="Workflow Threshold" type="tel" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardWorkflowThresholdDays} default={this.state.isShowingModifyForm && this.props.selectedCard && !isNaN(Number(this.props.selectedCard.thresholdDaysForWorkflows)) ? String(this.props.selectedCard.thresholdDaysForWorkflows) : ''} key={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.id : 0} />
            <EnhancedInputText isBooleanField placeholder="Bulk Actions" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardBulkActions} defaultBooleanValue={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.bulkActions : false} />
        </ModifyForm>;

        return <CardsList
            heading={this.props.heading}
            cards={this.props.cardsList}
            selectedCard={this.props.selectedCard}
            onSelectCard={this.handleSelectCard}
            onUnselectCard={this.props.onUnSelectCard}
            onDeleteCard={this.props.deleteCard}
            onEditCard={this.editSelectedCard}
            onReorderCards={this.props.reOrderCards}
            modifyForm={modifyForm}
            isShowingAddForm={this.state.isShowingAddForm}
            isShowingEditForm={this.state.isShowingModifyForm}
            onAddCard={this.toggleModifyForm}
            isDeleteRestricted={this.props.restrictStructureChanges}
            isReadOnly={this.props.isReadOnly || !this.props.write}
            isSearchable={!!this.props.isSearchable}
            showCardCount={!!this.props.showCardCount}
        />
    }

}

const RolesList = connect(mapStateToProps, mapDispatchToProps)(ConnectedRolesList);

export default RolesList;