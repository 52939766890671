import { PieceType } from '../store/flowchart/pieces/types';
import { isUUID } from './utilities';
import store from '../store/main';
import { ApplicationState } from '../store/types';
import { FieldType } from '../store/custom-fields/types';

export const hardCodedWords = [

    // Tooltip words
    'Recompute',
    'Search',
    'Help',
    'Close',
    'Org Profile',
    'Personal Profile',
    'Reload all widgets',
    'Loading widgets',
    'Filter',
    'More',
    'Cancel',
    'Performance details',
    'Edit',
    'View',
    'Fire Workflow',
    'Resume',
    'Update due date',
    'Transfer',
    'Share',
    'Import/Export',
    'Release notes',
    'Shown to',
    'Fullscreen',
    'Exit fullscreen',

    //// Desktop words
    'Please check your internet',
    'Network error. Please check internet and try again',
    'Search Phrases',

    // Modify widget
    'Select all fields',
    'Select searched fields',
    'Remove all fields',
    'Remove all from Export',
    'Export all fields',
    'Updated',
    'Worked on',

    //Desktop Loader

    'Preparing data to Sync',
    'Fetching your data',
    'Analysing',
    'Calculating',
    'Drawing your Widgets & Charts',
    'Compressing the file(s)',
    'Uploading',
    'Preparing export',
    'Export ready',

    'Active workflows only. Click here to fetch completed workflows',
    'Fetching completed workflows',
    'Fetching fresh data as User configuration has changed',
    'Logging out',
    'Saving your data',
    'Getting fresh data as per new user settings',
    'Getting fresh data due to role(s) change',
    'Getting fresh data due to location(s) change',

    'Update to the next version',
    'Recomputing computed field values',
    'Completed recomputation',


    // Header
    'Search',
    'Search for page',
    'Save',
    'Saving',
    'Hi',
    'Profile',
    'Language',

    // Sidebar
    'Dashboard',
    'Structure',
    'Users',
    'Members',
    'Groups',
    'Workflows',
    'Reports',
    'Fetch remaining data',
    'Refresh data',

    // Cards List
    'Card',
    'Cards',
    'No Cards',
    'Click to add new card',
    'Add',
    'Cancel',
    'Done',
    'Added',
    'Update',
    'Updated',
    'Undo',
    'defined',

    // Custom fields
    'Type',
    'Type Fields',
    'Misc',
    'Types',
    'Yes',
    'No',
    'Is Computed',
    'Is In Table',
    'Is Searchable',
    'Searchable / Show in Table',
    'Enter a valid name',
    'Enter a valid type',
    'Enter a valid computed field',
    'Enter a valid in-table field',
    'Enter a valid unique field',
    'Enter valid:',
    'Name',
    'Add Custom Field',
    'Country Code',
    'Phone Number',
    'Latitude',
    'Longitude',

    'No data available',

    // Structure - Hierarchy
    'Hierarchy',
    'Projects',
    'Levels',
    'Roles',
    'Name',
    'Add Level Custom Field',
    'Add Role Custom Field',
    'Add Member Type Custom Field',
    'Add Group Type Custom Field',
    'Add Workflow Type Custom Field',
    'Custom Fields',
    'Default Fields',

    // Structure - Locations
    'Locations',
    'Download template',
    'Import',
    'Import/Export',
    'This row does not have data for this level',
    'The parent location must be defined for lower level locations',
    'This row does not have data for this custom field',
    'Errors during import. Please download this file to fix errors and re-upload',
    'Error file',
    'Dismiss',

    // Structure - Permissions
    'Permissions',
    'General',
    'User Configuration',
    'Members Configuration',
    'Groups Configuration',
    'Workflows Configuration',
    'Reports Configuration',
    'Organization Profile',
    'Languages',
    'Member',
    'Group',
    'Workflow',
    'Report',
    'Session timeout (in min.)',

    // Users
    'List',
    'Configuration',
    'Show',
    'Filter Users',
    'Select all',
    'All selected',
    'Apply filter',
    'Clear filters',
    'Reset filter',
    'Add User',
    'Edit User',
    'Update User',
    'Added User',
    'Updated User',
    'Add User Requested',
    'Updated User Requested',
    'Subtitle',
    'Details',
    'Action',
    'Import',
    'Export',
    'Export CSV',
    'Export Table',
    'Users can only be added when online',
    'Online',
    'Beta',
    'Tester',
    'Is Tester?',
    'Error while fetching user count. Please try again',
    'Incorrect data format',
    'You have reached the maximum limit',
    'The user has been created!',
    'File size too large',
    'Coming soon',

    'Sl. no',
    'Project',
    'Level',
    'Location',
    'Role',
    'Phone',
    'Password',
    'latitude',
    'longitude',

    'Created',
    'Archived',

    'Archive all entries',
    'Transfer all entries',

    'The data has been copied to clipboard',

    'User Metrics',
    'Syncs',
    'Push events',
    'Events',
    'Avg per user',
    'Click for location',

    // Members
    'Filter Members',
    'Add Member',
    'Edit Member',
    'Update Member',
    'Added Member',
    'Updated Member',
    'This is handled by the workflow type:',
    'Please start the workflow by clicking the button below',
    'There is already another worklflow of the same type in progress',
    'You do not have permissions to start a workflow of this type',
    'Please ensure that this workflow type has WRITE permissions',
    'There is no template for this action. Please create a workflow type and link this manually',
    'Groups are still being indexed. The list may be incomplete',
    'Enter a valid name',
    'Wrong file format',
    'Cannot change location with member/group links',

    // Groups
    'Filter Groups',
    'Add Group',
    'Edit Group',
    'Update Group',
    'Added Group',
    'Updated Group',
    'Members are still being indexed. The list may be incomplete',
    'Unique field',
    'Cannot remove the last required group from a member',

    // Workflows
    'Seems like there is already a workflow existing... Would you like to resume it?',
    'Click the button below to continue to the next workflow',
    'Click the button below to resume the triggering workflow',
    'Another user already has a workflow of this type open for this member/group. View data?',
    'Note: Number in brackets is the number of active workflows for user',
    'Transfer active workflows in table',
    'No Workflows to fire',
    'Filter Workflows',
    'Add Workflow',
    'Edit Workflow',
    'Update Workflow',
    'Added Workflow',
    'Updated Workflow',
    'Due date updated to',
    'Other users',
    'Other users flows',
    'Core',
    'Secondary',
    'Affiliated',
    'Affiliated with',
    'All Member Types',
    'All Group Types',
    'All Workflow Types',
    'member',
    'group',
    'Statuses',
    'Open',
    'Closed',
    'Continue',
    'optional',
    'Status and Owner',
    'Go to Workflows',
    'Go Back',
    'Go Forward',
    'Created time',
    'Text list',
    'Timeline',
    'Show Timeline',
    'Please confirm transfer to',
    'Confirm',
    'You will be switching to',
    'You have completed this workflow',
    'Click the button below to start the workflow',
    'Click on Save button',

    'Importing your data',
    'Import complete',
    'Exporting your data',

    'A workflow of this type should have at least one non terminal status',
    'The start workflow piece must have an affiliation variable',
    'This piece must be a variable piece',
    'This value must be an ID for the affiliated entity',
    'A workflow with this affiliation already exists',
    'Add workflow failed',
    'Click here to view data of existing workflow',
    'Click here to resume existing workflow',
    'Click below to retry',
    'Retry',

    'Field name',
    'Value',
    'Select the user to transfer',
    'This workflow has been transferred to',
    'Transfer',
    'Flows',
    'Members and Groups',
    'Main flowchart not found. Export with beta flowchart?',
    'Please remove the last piece and continue',

    'Outdated',

    // Static info
    'Static Info',
    'Data',
    'Data Configuration',
    'Back to Configuration',

    // Reports
    'Filter Reports',
    'Add Report',
    'Edit Report',
    'Update Report',
    'Added Report',
    'Updated Report',
    'View Report data',

    'Start Date',
    'End Date',
    'Report Start Date',
    'Report End Date',
    'Download',
    'Generate report',

    'Add Report Schedule',
    'The mail has been sent to the users successfully successfully',
    'There was a problem sending the mail. Please try again later',

    'Export Sync Meta Log',


    //// Mobile words
    'Loading widgets',
    'of',
    'Search by Member, Groups & Sub Title',
    'Search by Name, Sl no. etc',
    'No widgets to display',
    'Show Entities',
    'Search results',
    'Tap here to exit',
    'Total',
    'Search within the list below',
    'Filter',
    'Reset',
    'Close',
    'Apply',
    'Due',
    'Dues',
    'Overdue',
    'Flows',
    'All Flows',
    'My Flows',
    'Status',
    'Active',
    'Show More',
    'Show Less',
    'Submit',
    'Start',
    'View Details',
    'Completed',
    'Type to search',
    'Created time',
    'Due date',
    'User',
    'Select Member',
    'Select Group',
    'No Members with location found',
    'Show List',
    'Sync Data',
    'Edit Profile',
    'Fetch Data',
    'Success',
    'Oops!, something went wrong',
    'Sort',
    'Collapse',
    'Expand',
    'Near Me',
    'Navigate',
    'Chat',
    'Activity Log',
    'Sync Log',
    'Change Password',
    'Confirm your old Password',
    'New Password',
    'Logout',
    'Do you really want to logout',
    'Not Applicable',
    'Tap on icon to copy',
    'Copied to Clipboard',
    'Copy Object to clipboard',
    'Loading',
    'Select a Member',
    'Search by Name',
    'Select a workflow type to duplicate',
    'Duplicates in the same project must have different names',
    'Affiliation',
    'Affiliations',
    'Messages',
    'Select the User to transfer',
    'Please select a user',
    'Select a User',
    'Transfer',
    'This workflow has been transferred',
    'Search by name',
    'Select A Member',
    'Go back',
    'Non-core workflows',
    'Last Server Sync',
    'Last worked on',
    'Release Notes',
    'Reload',
    'Logout',
    'Select',
    'Select Language',
    'This answer is required',
    'Wait',
    'Okay',
    'The changes you have made will not be saved',
    'Leave',
    'Pull to Refresh',
    'Unsynced',
    'Dashboard',
    'To view statistical data & widgets',
    'Please reload to see data',
    'Members & Groups Tab',
    'To view list of members & groups under your location',
    'Workflows Tab',
    'To view list of workflows which were fired by you',
    'More Tab',
    'To view other options like language settings, edit profile etc',
    'Members segment',
    'List of members under your location',
    'Groups segment',
    'List of groups under your location',
    'Add New Workflow',
    'Tap to view the list of workflow types that can be fired',
    'Select a Workflow type',
    'Tap on any core or non-core workflow type to start',
    'Pull To Refresh the app',
    'To Manually update the app or simply reload the app',
    'Sync Data',
    'Sync your data with the server',
    'App Introduction',
    'Welcome',
    'Would you like an introduction',
    'This workflow has been transferred to',
    'Go to workflows',
    'Go to flowchart',
    'Pause',
    'Search results for',
    'Report a bug',
    'Title',
    'Description',
    'Screenshot',
    'You must be online to submit a bug report',
    'The title is required',
    'Sending',
    'Send',
    'Sent',
    'Delete Unsynced Data',
    'Changed Language to',
    'Storing new data',
    'Logging in',
    'please wait',
    'Please wait',
    'Pushing',
    'Fetching',
    'updating application',
    'Fetch older data',
    'Previous page',
    'Next page',
    'Max File Size',

    // Maps
    'Select Location',
    'Type to search and select the location',
    'Tap this to go back to default location',
    'Search location',

    // More pages
    'Get clean data',
    'This will fetch data from the server again. Your local changes will be lost. If your project has large volumes of data, say above 50000 records, then based on your system configuration this operation can take long time or slow down the application.',
    'This will fetch all the older data that has not been sent down to your app from the server. If your project has large volumes of data, say above 50000 records, then based on your system configuration this operation can take long time or slow down the application.',
    'Fetching older data',
    'Application updated to',
    'New update',
    'Last Sync',
    'Not Synced',

    // Smart filter
    "Recent Searches",
    "Recent Filters",
    "Quick Filters",
    "Added this week",
    "Added previous week",
    "Completed current week",
    "Completed last week",
    "Added last month",
    "Added current month",
    "Completed last month",
    "Completed current month",
    "Clear",
    "Due Date: From - To",
    "Last worked on Date: From - To",
    "Created on Date: From - To",
    "Count",

    // date selector
    "Month",
    "Year",
    "Today",

    // App introduction
    "Recent Filters/Searches",
    "Last 5 filters and searches are stored here for future use",

    'Open Finsal Window',
    'Finsall flow completed. Please continue.',
    'Check payment status',
    'Please continue the workflow',
    'Continue to',
    'Resume loan application',
    'Start loan application',
    'The payment for this loan is still pending',
    'Read More',

    // Sync Progress messages
    "Starting to sync your data",
    "Sending your data to the server",
    "Your data has been saved",
    "Receiving new data",
    "Updating your data",
    "Sync complete",
    "Sync failed",

    'Offline',
    'Under Maintenance',
    'Your session has expired. Please log in again to continue.'

];


export function getTranslatablePhrasesFromFlowchart(pieceId: string) {
    const piecesData = store.getState().flowchart.pieces;
    const pieceToAnalyze = piecesData.byId[pieceId];

    let translatablePhrases: Array<string> = [];
    const getTranslatablePhrasesShorthand = getTranslatablePhrasesFromFlowchart;

    if ('nextPiece' in pieceToAnalyze) {
        if (typeof pieceToAnalyze.nextPiece === 'string') {
            translatablePhrases = translatablePhrases.concat(getTranslatablePhrasesShorthand(pieceToAnalyze.nextPiece));
        }
    }

    if ('innerPiece' in pieceToAnalyze) {
        if (typeof pieceToAnalyze.innerPiece === 'string') {
            translatablePhrases = translatablePhrases.concat(getTranslatablePhrasesShorthand(pieceToAnalyze.innerPiece));
        }
    }

    if (pieceToAnalyze.type === PieceType.SPLIT) {

        if (typeof pieceToAnalyze.ifPieceData !== 'undefined') {
            for (let ifDatum of pieceToAnalyze.ifPieceData) {

                if (typeof ifDatum.nextPiece === 'string') {
                    translatablePhrases = translatablePhrases.concat(getTranslatablePhrasesShorthand(ifDatum.nextPiece));
                }
            }
        }
    }

    if ('iterableVariable' in pieceToAnalyze) {
        if (typeof pieceToAnalyze.iterableVariable === 'string') {
            if (isUUID(pieceToAnalyze.iterableVariable)) {
                translatablePhrases = translatablePhrases.concat(getTranslatablePhrasesShorthand(pieceToAnalyze.iterableVariable));
            }
        }
    }

    if ('operand' in pieceToAnalyze) {
        if (typeof pieceToAnalyze.operand === 'string') {
            if (isUUID(pieceToAnalyze.operand)) {
                translatablePhrases = translatablePhrases.concat(getTranslatablePhrasesShorthand(pieceToAnalyze.operand));
            } else {
                translatablePhrases = translatablePhrases.concat(pieceToAnalyze.operand);
            }
        }
    }

    if ('leftOperand' in pieceToAnalyze) {
        if (typeof pieceToAnalyze.leftOperand === 'string') {
            if (isUUID(pieceToAnalyze.leftOperand)) {
                translatablePhrases = translatablePhrases.concat(getTranslatablePhrasesShorthand(pieceToAnalyze.leftOperand));
            } else {
                translatablePhrases = translatablePhrases.concat(pieceToAnalyze.leftOperand);
            }
        }
    }

    if ('rightOperand' in pieceToAnalyze) {
        if (typeof pieceToAnalyze.rightOperand === 'string') {
            if (isUUID(pieceToAnalyze.rightOperand)) {
                translatablePhrases = translatablePhrases.concat(getTranslatablePhrasesShorthand(pieceToAnalyze.rightOperand));
            } else {
                translatablePhrases = translatablePhrases.concat(pieceToAnalyze.rightOperand);
            }
        }
    }

    if ('heading' in pieceToAnalyze) {
        if (typeof pieceToAnalyze.heading === 'string') {
            if (isUUID(pieceToAnalyze.heading)) {
                translatablePhrases = translatablePhrases.concat(getTranslatablePhrasesShorthand(pieceToAnalyze.heading));
            } else {
                translatablePhrases = translatablePhrases.concat(pieceToAnalyze.heading);
            }
        }
    }

    if ('question' in pieceToAnalyze) {
        if (typeof pieceToAnalyze.question === 'string') {
            if (isUUID(pieceToAnalyze.question)) {
                translatablePhrases = translatablePhrases.concat(getTranslatablePhrasesShorthand(pieceToAnalyze.question));
            } else {
                translatablePhrases = translatablePhrases.concat(pieceToAnalyze.question);
            }
        }
    }

    if ('error' in pieceToAnalyze) {
        if (typeof pieceToAnalyze.error === 'string') {
            if (isUUID(pieceToAnalyze.error)) {
                translatablePhrases = translatablePhrases.concat(getTranslatablePhrasesShorthand(pieceToAnalyze.error));
            } else {
                translatablePhrases = translatablePhrases.concat(pieceToAnalyze.error);
            }
        }
    }

    if ('dataToSet' in pieceToAnalyze) {
        if (typeof pieceToAnalyze.dataToSet === 'string') {
            if (isUUID(pieceToAnalyze.dataToSet)) {
                translatablePhrases = translatablePhrases.concat(getTranslatablePhrasesShorthand(pieceToAnalyze.dataToSet));
            } else {
                translatablePhrases = translatablePhrases.concat(pieceToAnalyze.dataToSet);
            }
        }
    }

    if ('returnValue' in pieceToAnalyze) {
        if (typeof pieceToAnalyze.returnValue === 'string') {
            if (isUUID(pieceToAnalyze.returnValue)) {
                translatablePhrases = translatablePhrases.concat(getTranslatablePhrasesShorthand(pieceToAnalyze.returnValue));
            } else {
                translatablePhrases = translatablePhrases.concat(pieceToAnalyze.returnValue);
            }
        }
    }

    if ('variableToShow' in pieceToAnalyze) {
        if (typeof pieceToAnalyze.variableToShow === 'string') {
            if (isUUID(pieceToAnalyze.variableToShow)) {
                translatablePhrases = translatablePhrases.concat(getTranslatablePhrasesShorthand(pieceToAnalyze.variableToShow));
            } else if (!pieceToAnalyze.isRichText) {
                translatablePhrases = translatablePhrases.concat(pieceToAnalyze.variableToShow);
            }
        }
    }

    if ('message' in pieceToAnalyze) {
        if (typeof pieceToAnalyze.message === 'string') {
            if (isUUID(pieceToAnalyze.message)) {
                translatablePhrases = translatablePhrases.concat(getTranslatablePhrasesShorthand(pieceToAnalyze.message));
            } else {
                translatablePhrases = translatablePhrases.concat(pieceToAnalyze.message);
            }
        }
    }

    if ('widgetTitle' in pieceToAnalyze) {
        if (typeof pieceToAnalyze.widgetTitle === 'string') {
            if (isUUID(pieceToAnalyze.widgetTitle)) {
                translatablePhrases = translatablePhrases.concat(getTranslatablePhrasesShorthand(pieceToAnalyze.widgetTitle));
            } else {
                translatablePhrases = translatablePhrases.concat(pieceToAnalyze.widgetTitle);
            }
        }
    }

    return translatablePhrases.filter(phrase => isNaN(Number(phrase)));
}

export function translatePhrase(phrase: string) {
    const tPhrase = phrase.trim();
    const state = store.getState();
    const myId = state.myData.id;

    if (!isUUID(myId)) {
        return tPhrase;
    }

    const languageId = state.users.byId[myId].language;
    const languageTranslations = state.internationalization.translations.byLanguage[languageId];

    if (typeof languageTranslations === 'undefined') {
        return tPhrase;
    }

    if (!(tPhrase in languageTranslations)) {
        return tPhrase;
    }

    if (!languageTranslations[tPhrase]) {
        return tPhrase;
    }

    return languageTranslations[tPhrase];
}

export function getTranslateableWords(applicationState: ApplicationState) {
    const masterSet: Array<Array<string>> = hardCodedWords.map(word => [word, 'System defined']);

    Object.keys(FieldType).forEach(name => {
        const readableName = name.split('_').join(' ');
        masterSet.push([readableName, 'System defined']);
    });

    // Adding widget names
    applicationState.widgets.allEntries.forEach(widgetId => masterSet.push([applicationState.widgets.byId[widgetId].name, 'Dashboard > Widget']));

    // Adding widget flowchart pieces
    applicationState.widgets.allEntries.forEach(widgetId => {
        const widget = applicationState.widgets.byId[widgetId];

        if (widget.displayType === 'formatted-table' && widget.richTextStartPiece) {
            getTranslatablePhrasesFromFlowchart(widget.richTextStartPiece.piece).forEach(phrase => masterSet.push([phrase.trim(), 'Dashboard > Widget > ' + widget.name]));
        }
    });

    // Adding project names
    applicationState.structure.projects.allEntries.forEach(projectId => masterSet.push([applicationState.structure.projects.byId[projectId].name, 'Structure > Hierarchy > Projects']));

    // Adding level names
    applicationState.structure.levels.allEntries.forEach(levelId => masterSet.push([applicationState.structure.levels.byId[levelId].name, 'Structure > Hierarchy > Levels']));

    // Adding level custom fields
    applicationState.structure.levels.customFields.allFields.forEach(fieldId => {
        const customField = applicationState.structure.levels.customFields.byId[fieldId];
        const levelName = customField.parentId ? applicationState.structure.levels.byId[customField.parentId].name : '-';
        masterSet.push([customField.name.trim(), 'Structure > Hierarchy > Level > ' + levelName + ' > Custom field']);

        if (customField.isComputed && customField.startPiece && customField.startPiece) {
            getTranslatablePhrasesFromFlowchart(customField.startPiece.piece).forEach(phrase => masterSet.push([phrase.trim(), 'Structure > Hierarchy > Level > ' + levelName + ' > Custom field > ' + customField + ' > Flowchart']));
        }
    });

    // Adding level custom field options
    applicationState.structure.levels.customFieldOptions.allOptions.forEach(optionId => {
        const option = applicationState.structure.levels.customFieldOptions.byId[optionId];
        const customField = applicationState.structure.levels.customFields.byId[option.parentId];
        const levelName = customField.parentId ? applicationState.structure.levels.byId[customField.parentId].name : '-';
        masterSet.push([applicationState.structure.levels.customFieldOptions.byId[optionId].name.trim(), 'Structure > Hierarchy > Level > ' + levelName + ' > Custom field > ' + customField + ' > Flowchart'])
    });

    // Adding role names
    applicationState.structure.roles.allEntries.forEach(roleId => masterSet.push([applicationState.structure.roles.byId[roleId].name, 'Structure > Hierarchy > Roles']));

    // Adding role custom fields
    applicationState.structure.roles.customFields.allFields.forEach(fieldId => {
        const customField = applicationState.structure.roles.customFields.byId[fieldId];
        const roleName = customField.parentId ? applicationState.structure.roles.byId[customField.parentId].name : '-';
        masterSet.push([customField.name.trim(), 'Structure > Hierarchy > Role > ' + roleName + ' > Custom field']);

        if (customField.isComputed && customField.startPiece && customField.startPiece) {
            getTranslatablePhrasesFromFlowchart(customField.startPiece.piece).forEach(phrase => masterSet.push([phrase.trim(), 'Structure > Hierarchy > Role > ' + roleName + ' > Custom field > ' + customField + ' > Flowchart']));
        }
    });

    // Adding role custom field options
    applicationState.structure.roles.customFieldOptions.allOptions.forEach(optionId => {
        const option = applicationState.structure.roles.customFieldOptions.byId[optionId];
        const customField = applicationState.structure.roles.customFields.byId[option.parentId];
        const roleName = customField.parentId ? applicationState.structure.roles.byId[customField.parentId].name : '-';
        masterSet.push([applicationState.structure.roles.customFieldOptions.byId[optionId].name.trim(), 'Structure > Hierarchy > Role > ' + roleName + ' > Custom field > ' + customField + ' > Flowchart'])
    });

    // Adding location names
    applicationState.structure.locations.allEntries.forEach(locationId => masterSet.push([applicationState.structure.locations.byId[locationId].name, 'Structure > Locations']));

    // Adding user custom fields
    applicationState.users.customFields.allFields.forEach(fieldId => {
        const customField = applicationState.users.customFields.byId[fieldId];
        masterSet.push([customField.name.trim(), 'Users > Configuration']);

        if (customField.isComputed && customField.startPiece && customField.startPiece) {
            getTranslatablePhrasesFromFlowchart(customField.startPiece.piece).forEach(phrase => masterSet.push([phrase.trim(), 'Users > Configuration > Custom field > ' + customField.name + ' > Flowchart']));
        }
    });

    // Adding user custom field options
    applicationState.users.customFieldOptions.allOptions.forEach(optionId => {
        const option = applicationState.users.customFieldOptions.byId[optionId];
        const field = applicationState.users.customFields.byId[option.parentId];
        masterSet.push([option.name.trim(), 'Users > Configuration > Custom Field > ' + field.name + ' > Options']);
    });

    // Adding member types
    applicationState.members.types.allEntries.forEach(typeId => {
        const memberType = applicationState.members.types.byId[typeId];
        const projectName = applicationState.structure.projects.byId[memberType.project].name;
        masterSet.push([memberType.name.trim(), `Members > Configuration > ${projectName}`])
    });

    // Adding member custom fields
    applicationState.members.types.customFields.allFields.forEach(fieldId => {
        const customField = applicationState.members.types.customFields.byId[fieldId];
        try {
            const memberType = customField.parentId ? applicationState.members.types.byId[customField.parentId] : undefined;
            const memberTypeName = memberType ? memberType.name : '-';
            const projectName = memberType ? applicationState.structure.projects.byId[memberType.project].name : '-';
            masterSet.push([customField.name.trim(), `Members > Configuration > ${projectName} > ${memberTypeName} > Custom field`]);

            if (customField.isComputed && customField.startPiece && customField.startPiece) {
                getTranslatablePhrasesFromFlowchart(customField.startPiece.piece).forEach(phrase => masterSet.push([phrase.trim(), `Members > Configuration > ${projectName} > ${memberTypeName} > Custom field > ${customField.name} > Flowchart`]));
            }
        } catch (e) {
            console.error(e);
        }
    });

    // Adding member custom field options
    applicationState.members.types.customFieldOptions.allOptions.forEach(optionId => {
        const option = applicationState.members.types.customFieldOptions.byId[optionId];
        const field = applicationState.members.types.customFields.byId[option.parentId];
        try {
            const memberType = field.parentId ? applicationState.members.types.byId[field.parentId] : undefined;
            const memberTypeName = memberType ? memberType.name : '-';
            const projectName = memberType ? applicationState.structure.projects.byId[memberType.project].name : '-';
            masterSet.push([option.name.trim(), `Members > Configuration > ${projectName} > ${memberTypeName} > Custom Field > ${field.name} > Options`]);
        } catch {
            // Removing this error log sice it keeps throwing it each time we search the language
            // console.error(e);
        }
    });

    // Adding member types
    applicationState.groups.types.allEntries.forEach(typeId => {
        const groupType = applicationState.groups.types.byId[typeId];
        const projectName = applicationState.structure.projects.byId[groupType.project].name;
        masterSet.push([groupType.name.trim(), `Groups > Configuration > ${projectName}`])
    });

    // Adding group custom fields
    applicationState.groups.types.customFields.allFields.forEach(fieldId => {
        const customField = applicationState.groups.types.customFields.byId[fieldId];
        try {
            const groupType = customField.parentId ? applicationState.groups.types.byId[customField.parentId] : undefined;
            const groupTypeName = groupType ? groupType.name : '-';
            const projectName = groupType ? applicationState.structure.projects.byId[groupType.project].name : '-';
            masterSet.push([customField.name.trim(), `Groups > Configuration > ${projectName} > ${groupTypeName} > Custom field`]);

            if (customField.isComputed && customField.startPiece && customField.startPiece) {
                getTranslatablePhrasesFromFlowchart(customField.startPiece.piece).forEach(phrase => masterSet.push([phrase.trim(), `Groups > Configuration > ${projectName} > ${groupTypeName} > Custom field > ${customField.name} > Flowchart`]));
            }
        } catch (e) {
            console.error(e);
        }
    });

    // Adding group custom field options
    applicationState.groups.types.customFieldOptions.allOptions.forEach(optionId => {
        const option = applicationState.groups.types.customFieldOptions.byId[optionId];
        const field = applicationState.groups.types.customFields.byId[option.parentId];
        try {
            const groupType = field.parentId ? applicationState.groups.types.byId[field.parentId] : undefined;
            const groupTypeName = groupType ? groupType.name : '-';
            const projectName = groupType ? applicationState.structure.projects.byId[groupType.project].name : '-';
            masterSet.push([option.name.trim(), `Groups > Configuration > ${projectName} > ${groupTypeName} > Custom Field > ${field.name} > Options`]);
        } catch (e) {
            console.error(e);
        }
    });

    // Adding workflow types
    applicationState.workflows.types.allEntries.forEach(typeId => {
        try {
            const workflowType = applicationState.workflows.types.byId[typeId];
            const projectName = applicationState.structure.projects.byId[workflowType.project].name;
            masterSet.push([workflowType.name.trim(), `Workflows > Configuration > ${projectName}`]);

            if (workflowType.startPiece) {
                getTranslatablePhrasesFromFlowchart(workflowType.startPiece.piece).forEach(phrase => masterSet.push([phrase.trim(), `Workflows > Configuration > ${projectName} > ${workflowType.name} > Flowchart`]));
            }
        } catch (e) {
            console.error(e);
        }
    });

    // Adding workflow statuses
    applicationState.workflows.types.statuses.allEntries.forEach(statusId => {
        try {
            const workflowTypeStatus = applicationState.workflows.types.statuses.byId[statusId];
            const workflowType = applicationState.workflows.types.byId[workflowTypeStatus.parentId];
            const projectName = applicationState.structure.projects.byId[workflowType.project].name;
            masterSet.push([workflowTypeStatus.name.trim(), `Workflows > Configuration > ${projectName} > ${workflowType.name} > Statuses`]);
        } catch {
            // console.error(e);
        }
    });

    // Adding workflow type fields
    applicationState.workflows.types.customFields.allFields.forEach(fieldId => {
        const customField = applicationState.workflows.types.customFields.byId[fieldId];
        try {
            const workflowType = customField.parentId ? applicationState.workflows.types.byId[customField.parentId] : undefined;
            const workflowTypeName = workflowType ? workflowType.name : '-';
            const projectName = workflowType ? applicationState.structure.projects.byId[workflowType.project].name : '-';
            masterSet.push([customField.name.trim(), `Workflows > Configuration > ${projectName} > ${workflowTypeName} > Custom field`]);

            if (customField.isComputed && customField.startPiece && customField.startPiece) {
                getTranslatablePhrasesFromFlowchart(customField.startPiece.piece).forEach(phrase => masterSet.push([phrase.trim(), `Workflows > Configuration > ${projectName} > ${workflowTypeName} > Custom field > ${customField.name} > Flowchart`]));
            }
        } catch (e) {
            console.error(e);
        }
    });

    // Adding workflow custom field options
    applicationState.workflows.types.customFieldOptions.allOptions.forEach(optionId => {
        const option = applicationState.workflows.types.customFieldOptions.byId[optionId];
        const field = applicationState.workflows.types.customFields.byId[option.parentId];
        try {
            const workflowType = field.parentId ? applicationState.workflows.types.byId[field.parentId] : undefined;
            const workflowTypeName = workflowType ? workflowType.name : '-';
            const projectName = workflowType ? applicationState.structure.projects.byId[workflowType.project].name : '-';
            masterSet.push([option.name.trim(), `Workflows > Configuration > ${projectName} > ${workflowTypeName} > Custom Field > ${field.name} > Options`]);
        } catch (e) {
            console.error(e);
        }
    });


    // Adding static data
    applicationState.staticInfo.allEntries.forEach(staticDataId => {
        const staticDataHolder = applicationState.staticInfo.byId[staticDataId];
        masterSet.push([staticDataHolder.name.trim(), 'Data']);
    });

    applicationState.staticInfo.fragments.allEntries.forEach(dataFragmentId => {
        const dataFragmentName = applicationState.staticInfo.fragments.byId[dataFragmentId];
        masterSet.push([dataFragmentName.name.trim(), 'Data']);
    });

    const masterSetWithoutDuplicates = new Set<Array<string>>();
    const duplicateTrackerID = new Set<string>();
    const duplicateTracker: string[][] = [];

    for (const pair of masterSet) {
        const word = pair[0];
        if (!duplicateTrackerID.has(word)) {
            duplicateTrackerID.add(word);
            duplicateTracker.push(pair);
            masterSetWithoutDuplicates.add(pair);
        }
    }

    return {
        masterSetWithoutDuplicates,
        duplicateTracker
    };
}