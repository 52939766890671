import React from 'react';
import styles from './List.module.scss';

import { Redirect } from "react-router-dom";

import { connect } from 'react-redux';

import ReportsTable from './ReportsTable';

import { ApplicationState } from '../../../shared/store/types';
import { Permissions } from '../../../shared/store/permissions/types';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {

    let isWritable = true;
    let isReadable = true;

    const granularPermissions = new Set(Object.keys(state.permissions.myPermissions.reports));
    const areAllTypesHandledByGranularPermissions = state.reports.types.allEntries.every(typeId => granularPermissions.has(typeId));

    if (areAllTypesHandledByGranularPermissions && Object.keys(state.permissions.myPermissions.reports).length > 0) {
        isWritable = Object.keys(state.permissions.myPermissions.reports).some(reportTypeId => {
            return state.permissions.myPermissions.reports[reportTypeId] === Permissions.WRITE;
        });
    
        isReadable = isWritable || Object.keys(state.permissions.myPermissions.reports).some(reportTypeId => {
            return state.permissions.myPermissions.reports[reportTypeId] === Permissions.READ;
        });
    }
    
    return {
        filtersExpanded: false,
        read: isReadable,
        write: isWritable,
        isSettled: (state.myData.isLoaded || state.myData.isPartiallyLoaded) && !state.myData.isPushingData,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = StateProps & OwnProps;

const ConnectedReports: React.FC<Props> = function (props) {
        
    if (!props.read) {
        return <Redirect to="/dashboard" />;
    }

    if (!props.isSettled) {
        return <section className={styles.reportsSection}>
        </section>
    }
    
    return (
        <section className={styles.reportsSection}>
            <div className={props.filtersExpanded ? styles.tableArea : styles.expandedTableArea}>
                <ReportsTable isReadOnly={!props.write} />
            </div>
        </section>
    );
};

const Reports = connect(mapStateToProps)(ConnectedReports);

export default Reports;