import React, { Component } from 'react';
import styles from './Checkbox.module.scss';
import toggleStyles from './Toggle.module.scss';
import { translatePhrase } from '../../shared/helpers/translation';
import uuid from 'uuid';


type OwnProps = {
    name: string,
    description?: string,
    value: string,
    search: string,
    isSelected: boolean,
    defaultChecked: boolean,
};

type OwnState = {
    checkboxKey: number,
    uniqueId: string,
}

class MultiSelectToggle extends Component<OwnProps, OwnState> {
    state: OwnState = {
        checkboxKey: 0,
        uniqueId: uuid.v4(),
    };

    static defaultProps = {
        defaultChecked: false,
    };

    render() {
        const isAvailable = !!this.props.search && this.props.name.toLocaleLowerCase().indexOf(this.props.search.toLocaleLowerCase().trim()) !== -1;
        const startIndex = this.props.name.toLocaleLowerCase().indexOf(this.props.search.toLocaleLowerCase());
        const endIndex = startIndex + this.props.search.length;

        let labelMarkup;

        if (!isAvailable || startIndex === endIndex) {
            labelMarkup = <label htmlFor={this.state.uniqueId} className={styles.text}>{translatePhrase(this.props.name)}</label>;
        } else {
            labelMarkup = <label htmlFor={this.state.uniqueId} className={styles.text}>{this.props.name.substring(0, startIndex)}<em>{this.props.name.substring(startIndex, endIndex)}</em>{this.props.name.substring(endIndex)}</label>;
        }

        return (            
            <div className={styles.multiSelectToggleHolder} title={this.props.description} key={this.state.checkboxKey}>
                {labelMarkup}
                <input id={this.state.uniqueId} type="checkbox" checked={this.props.isSelected} readOnly />
                <section className={this.props.isSelected ? toggleStyles.on : toggleStyles.off}>
                    <div className={toggleStyles.slider}></div>
                </section>
            </div>
        );
    }
}

export default MultiSelectToggle;

