import React from 'react';
import { translatePhrase } from '../../shared/helpers/translation';
import LoaderModal from '../loader/LoaderModal';
import styles from './Alert.module.scss';

type OwnProps = {
    alertHeader: string,
    alertSentence: string,
    isConfirmation?: boolean,
    confirmApproval: (flag: boolean) => void;
};


const AlertModal: React.FC <OwnProps> = (props) => {
    return (
        <LoaderModal loaderText={[props.alertSentence]} isError isNotAllowedToCloseManually confirmApproval={(flag)=> props.confirmApproval(flag)} />
    );
}


export default AlertModal;