import { delay, put, select } from 'redux-saga/effects'
import { ApplicationState } from '../../types';

import uuid from 'uuid';
import { addProject } from '../../structure/project/actions';
import { IUpdateableProjectData } from '../../structure/project/types';
import { addLevel } from '../../structure/level/actions';
import { IUpdateableLevelData } from '../../structure/level/types';
import { IUpdateableRoleData } from '../../structure/role/types';
import { addRole } from '../../structure/role/actions';
import { IUpdateableLocationData } from '../../structure/location/types';
import { addLocation } from '../../structure/location/actions';
import { importWorkflowTypeFromExportFile } from '../../../helpers/duplicate/import';
import { SAMPLE_PROJECT_WORKFLOW_DATA } from './sample-project-workflow-data';
import { ExportWorkflowTypeData } from '../../../helpers/duplicate';
import { addUser } from '../../users/actions';
import { addMember } from '../../members/actions';
import { addWidget } from '../../widgets/actions';
import { addWorkflow } from '../../workflows/actions';
import moment from 'moment';
import { setIsTopBarExpanded, selectNudge } from '../actions';
import { NudgeType } from '../types';

export function* setupSampleProject() {
    let state: ApplicationState = yield select();

    let projectCount = 1;

    const projectId = uuid.v4();
    let projectName = 'SAMPLE Health ' + projectCount;

    while (state.structure.projects.allEntries.find(projectId => state.structure.projects.byId[projectId].name === projectName)) {
        projectCount += 1;
        projectName = 'SAMPLE Health ' + projectCount;
    }

    const projectData: IUpdateableProjectData = {
        id: projectId,
        name: projectName,
    };

    yield put(addProject(projectData));

    const countryLevelId = uuid.v4();
    const stateLevelId = uuid.v4();
    const districtLevelId = uuid.v4();
    const tehsilLevelId = uuid.v4();
    const villageLevelId = uuid.v4();

    const countryData: IUpdateableLevelData = {
        id: countryLevelId,
        name: 'Country',
    };

    const stateData: IUpdateableLevelData = {
        id: stateLevelId,
        name: 'State',
    };

    const districtData: IUpdateableLevelData = {
        id: districtLevelId,
        name: 'District',
    };

    const tehsilData: IUpdateableLevelData = {
        id: tehsilLevelId,
        name: 'Tehsil',
    };

    const villageData: IUpdateableLevelData = {
        id: villageLevelId,
        name: 'Village',
    };

    yield put(addLevel(countryData, projectId));
    yield put(addLevel(stateData, projectId));
    yield put(addLevel(districtData, projectId));
    yield put(addLevel(tehsilData, projectId));
    yield put(addLevel(villageData, projectId));

    const directorRoleId = uuid.v4();
    const healthSpmRoleId = uuid.v4();
    const healthManagerId = uuid.v4();
    const healthNurseId = uuid.v4();
    const healthWorkerId = uuid.v4();

    const directorData: IUpdateableRoleData = {
        id: directorRoleId,
        name: 'SAMPLE Director',
    };

    const healthSpmData: IUpdateableRoleData = {
        id: healthSpmRoleId,
        name: 'SAMPLE Health SPM',
    };

    const healthManagerData: IUpdateableRoleData = {
        id: healthManagerId,
        name: 'SAMPLE Health Manager',
    };

    const healthNurseData: IUpdateableRoleData = {
        id: healthNurseId,
        name: 'SAMPLE Health Nurse',
    };

    const healthWorkerData: IUpdateableRoleData = {
        id: healthWorkerId,
        name: 'SAMPLE Health Worker',
    };

    yield put(addRole(directorData, countryLevelId));
    yield put(addRole(healthSpmData, stateLevelId));
    yield put(addRole(healthManagerData, districtLevelId));
    yield put(addRole(healthNurseData, tehsilLevelId));
    yield put(addRole(healthWorkerData, villageLevelId));

    const countryLocationId = uuid.v4();
    const stateLocationId = uuid.v4();
    const districtLocationId = uuid.v4();
    const tehsilLocationId = uuid.v4();
    const villageLocationId = uuid.v4();

    const countryLocationData: IUpdateableLocationData = {
        id: countryLocationId,
        name: 'India',
        customFields: {}
    }

    const stateLocationData: IUpdateableLocationData = {
        id: stateLocationId,
        name: 'Karnataka',
        customFields: {}
    }

    const districtLocationData: IUpdateableLocationData = {
        id: districtLocationId,
        name: 'Bangalore Rural',
        customFields: {}
    }

    const tehsilLocationData: IUpdateableLocationData = {
        id: tehsilLocationId,
        name: 'Devanahalli',
        customFields: {}
    }

    const villageLocationData: IUpdateableLocationData = {
        id: villageLocationId,
        name: 'Attibele',
        customFields: {}
    }

    yield put(addLocation(countryLocationData, projectId));
    yield put(addLocation(stateLocationData, countryLocationId));
    yield put(addLocation(districtLocationData, stateLocationId));
    yield put(addLocation(tehsilLocationData, districtLocationId));
    yield put(addLocation(villageLocationData, tehsilLocationId));

    const exportFileData: ExportWorkflowTypeData = SAMPLE_PROJECT_WORKFLOW_DATA as any;

    importWorkflowTypeFromExportFile(
        exportFileData,
        'New Visit Record',
        projectId,
        '',
        'member',
        'SAMPLE Patient',
        [],
        undefined,
        true,
        true,
    );

    yield delay(1000);

    state = yield select();

    const countryCode = state.organization.phoneCountryCode;
    const phoneNumberPrefix = state.organization.phoneNumber.substring(0, state.organization.phoneNumber.length - 1 - Math.ceil(projectCount / 10));

    const firstWorkerId = uuid.v4();

    yield put(addUser({
        id: firstWorkerId,
        projects: [projectId],
        levels: [villageLevelId],
        roles: [healthWorkerId],
        locations: [villageLocationId],
        isAppUser: false,
        isOnline: false,
        isBetaTester: false,
        phone: {
            countryCode: countryCode,
            number: phoneNumberPrefix + projectCount + '1',
        },
        language: '0',
        customFields: {
            [state.users.nameFieldId]: 'SAMPLE Health Worker 1'
        },
        password: '1234',
    }));

    const nurseId = uuid.v4();

    yield put(addUser({
        id: nurseId,
        projects: [projectId],
        levels: [tehsilLevelId],
        roles: [healthNurseId],
        locations: [villageLocationId],
        isAppUser: false,
        isOnline: false,
        isBetaTester: false,
        phone: {
            countryCode: countryCode,
            number: phoneNumberPrefix + projectCount + '2',
        },
        language: '0',
        customFields: {
            [state.users.nameFieldId]: 'SAMPLE Nurse 1'
        },
        password: '1234',
    }));

    yield put(addUser({
        id: uuid.v4(),
        projects: [projectId],
        levels: [districtLevelId],
        roles: [healthManagerId],
        locations: [tehsilLocationId],
        isAppUser: false,
        isOnline: false,
        isBetaTester: false,
        phone: {
            countryCode: countryCode,
            number: phoneNumberPrefix + projectCount + '3',
        },
        language: '0',
        customFields: {
            [state.users.nameFieldId]: 'SAMPLE Health Manager 1'
        },
        password: '1234',
    }));

    const memberTypeId = state.members.types.allEntries.find(typeId => state.members.types.byId[typeId].project === projectId);

    let firstMemberId = uuid.v4();
    let secondMemberId = uuid.v4();

    if (memberTypeId) {
        const memberType = state.members.types.byId[memberTypeId];

        yield put(addMember({
            id: firstMemberId,
            type: memberTypeId,
            location: villageLocationId,
            customFields: {
                [memberType.nameFieldId]: 'SAMPLE Member 1',
            },
            groups: {},
        }));

        yield put(addMember({
            id: secondMemberId,
            type: memberTypeId,
            location: villageLocationId,
            customFields: {
                [memberType.nameFieldId]: 'SAMPLE Member 2',
            },
            groups: {},
        }));

        yield put(addWidget({
            id: uuid.v4(),

            name: 'Patient count',

            type: 'Member',
            typeId: memberTypeId,
            systemFields: [],
            customFields: [memberType.nameFieldId],
            exportingSystemFields: [],
            exportingCustomFields: [],
            creatingUser: state.myData.id,

            roles: [],

            seedEntityVariable: uuid.v4(),

            displayType: 'table',
            aggregation: 'count',
        }));

        yield put(setIsTopBarExpanded(true));
        yield put(selectNudge(NudgeType.HIERARCHY_SET_UP_SAMPLE_PROJECT));
    }

    const workflowTypeId = state.workflows.types.allEntries.find(typeId => state.workflows.types.byId[typeId].project === projectId);

    if (workflowTypeId) {
        const workflowType = state.workflows.types.byId[workflowTypeId];
        const firstOpenStatusId = workflowType.statuses.find(statusId => !state.workflows.types.statuses.byId[statusId].isTerminal);

        if (firstOpenStatusId) {
            yield put(addWorkflow({
                id: uuid.v4(),
                type: workflowTypeId,
                status: firstOpenStatusId,
                dueDate: moment().add(7, 'days').format('YYYY-MM-DD'),
                user: firstWorkerId,
                affiliatedEntity: firstMemberId,
            }));

            yield put(addWorkflow({
                id: uuid.v4(),
                type: workflowTypeId,
                status: firstOpenStatusId,
                dueDate: moment().add(7, 'days').format('YYYY-MM-DD'),
                user: nurseId,
                affiliatedEntity: secondMemberId,
            }));
        }

        const customFieldId = workflowType.customFields.find(fieldId => {
            const field = state.workflows.types.customFields.byId[fieldId];

            return field.name === 'COVID Vaccine Status';
        });

        if (customFieldId) {
            yield put(addWidget({
                id: uuid.v4(),

                name: 'Vaccination status',

                type: 'Workflow',
                typeId: workflowTypeId,
                systemFields: [],
                customFields: [customFieldId],
                exportingSystemFields: [],
                exportingCustomFields: [],
                creatingUser: state.myData.id,

                roles: [],

                seedEntityVariable: uuid.v4(),

                displayType: 'donut',
                aggregation: 'none',
            }));
        }

    }



}
