import React, { Component, createRef, RefObject } from 'react';
import onClickOutside from 'react-onclickoutside';

import { ReactComponent as DropdownIcon } from '../../assets/new-custom-icons/common/dropdown.svg';
import styles from './InputText.module.scss';
import MultiSelectOptionsList from './MultiSelectOptionsList';
import { translatePhrase } from '../../shared/helpers/translation';
import Button from '../button/CommonButton';

export type Option = {
    id: number,
    name: string,
    description?: string,
    value: string,
}

export type OptionInput = {
    name: string,
    description?: string,
    value: string,
}

type OwnProps = {
    default?: Array<string>,
    options: Array<OptionInput | string>,
    placeholder?: string,
    isDisabled: boolean,
    isRequired?: boolean,
    isSearchDisabled?: boolean,
    hideInputPlaceholder?: boolean,
    focusColor?: string,
    isAutoFocus?: boolean,

    onChange: (changedValue: Array<string>) => void,
}

type OwnState = {
    isShowingOptions: boolean,

    options: Array<OptionInput>,
    selectedOptions: Array<OptionInput>,
    highlightedOptionIndex?: number,
}

class MultiSelectInputText extends Component<OwnProps, OwnState> {
    input: RefObject<HTMLInputElement> = createRef();
    optionsListElement: RefObject<HTMLInputElement> = createRef();


    constructor(props: Readonly<OwnProps>) {
        super(props);

        this.state = {
            isShowingOptions: false,
            options: [],
            selectedOptions: [],
        };

        // If there are options provided for the input...
        if (this.props.options) {

            // ...Mold them to the proper format...
            const options: Array<OptionInput> = this.props.options.map((option, index) => {
                return {
                    name: typeof option === 'string' ? option : option.name,
                    description: typeof option === 'string' ? undefined : option.description,
                    value: typeof option === 'string' ? option : option.value,
                };
            });

            const defaultSelectedOptions = options.filter(option => this.props.default?.includes(option.value));

            // ...then add them to the state
            this.state = {
                ...this.state,
                options: options,
                selectedOptions: defaultSelectedOptions,
            };
        }
    }

    componentDidUpdate(prevProps: Readonly<OwnProps>) {
        if (JSON.stringify(this.props.options) !== JSON.stringify(prevProps.options)) {
            // ...Mold them to the proper format...
            const options: Array<OptionInput> = this.props.options.map((option, index) => {
                return {
                    name: typeof option === 'string' ? option : option.name,
                    description: typeof option === 'string' ? undefined : option.description,
                    value: typeof option === 'string' ? option : option.value,
                };
            });

            let newSelectedOptions: Array<OptionInput> = [];

            if (this.state.selectedOptions.length === 0) {
                newSelectedOptions = options.filter(option => this.props.default?.includes(option.value));
            } else {
                newSelectedOptions = this.state.selectedOptions.filter(selectedOption => {
                    return !!options.find(option => option.value === selectedOption.value);
                });
            }

            // ...then add them to the state
            this.setState({
                ...this.state,
                options: options,
                selectedOptions: newSelectedOptions,
            });
        }
    }

    static defaultProps = {
        isDisabled: false,
    }

    handleClickOutside = (event: MouseEvent) => {
        if (this.state.isShowingOptions) {
            this.setState({
                isShowingOptions: false,
            });
        }
    }

    onSelect = (name: string, value: string) => {

        let updatedSelectedOptions: Array<OptionInput> = JSON.parse(JSON.stringify(this.state.selectedOptions));

        if (updatedSelectedOptions.find(option => option.value === value)) {
            updatedSelectedOptions = updatedSelectedOptions.filter(option => option.value !== value);
        } else {
            updatedSelectedOptions.push({
                name: name,
                value: value,
            });
        }
        this.setState({
            selectedOptions: updatedSelectedOptions,
            highlightedOptionIndex: undefined,
        });


        const selectedIds = updatedSelectedOptions.map(option => option.value);

        this.props.onChange && this.props.onChange(selectedIds);
    }

    showOptions = () => {
        this.setState({
            isShowingOptions: true,
        })
    }

    toggleOptions = () => {
        const newIsShowingOptions = !this.state.isShowingOptions;

        this.setState(prevState => {
            return {
                isShowingOptions: !prevState.isShowingOptions,
            };
        })


        setTimeout(() => {
            if (newIsShowingOptions) {
                this.optionsListElement.current?.scrollIntoView({
                    behavior: 'smooth',
                    block: 'center',
                    inline: 'center',
                });
            }
        }, 400);

    }

    selectAllOptions = () => {
        let updatedSelectedOptions: Array<OptionInput> = JSON.parse(JSON.stringify(this.state.options));

        this.setState({
            selectedOptions: updatedSelectedOptions,
        });

        const selectedIds = updatedSelectedOptions.map(option => option.value);
        this.props.onChange && this.props.onChange(selectedIds);

    };

    selectSearchedOptions = (options: Array<OptionInput>) => {
        let updatedSelectedOptions: Array<OptionInput> = JSON.parse(JSON.stringify(options));

        this.setState({
            selectedOptions: updatedSelectedOptions,
        });

        const selectedIds = updatedSelectedOptions.map(option => option.value);
        this.props.onChange && this.props.onChange(selectedIds);

    }

    selectNone = () => {

        this.setState({
            selectedOptions: [],
        });

        this.props.onChange && this.props.onChange([]);
    }

    render() {
        let inputHolderClass = styles.inputHolder;

        if (this.props.isDisabled) {
            inputHolderClass += ` ${styles.disableInput}`;
        }

        if (this.props.isRequired) {
            inputHolderClass += ` ${styles.isRequired}`;
        }

        if (this.state.isShowingOptions) {
            if (this.props.focusColor !== 'pink') {
                inputHolderClass += ` ${styles.active}`;
            } else {
                inputHolderClass += ` ${styles.activePink}`;
            }
        }

        if (this.props.hideInputPlaceholder && this.state.isShowingOptions) {
            inputHolderClass += ` ${styles.hideInputPlaceholder}`;
        }

        return (
            <section className={styles.multiSelect}>
                <div className={inputHolderClass}
                    data-selector={"form-input-holder-" + (this.props.placeholder ? this.props.placeholder.toLowerCase() : '')} title={this.props.placeholder && translatePhrase(this.props.placeholder + ': ' + this.state.selectedOptions.length + ' ' + translatePhrase('selected'))}>

                    {this.props.placeholder && <div onClick={() => this.toggleOptions()} className={styles.placeholder + ' ' + (this.state.isShowingOptions ? styles.shrink : '')}>
                        {translatePhrase(this.props.placeholder)} :
                    </div>}
                    <div onClick={() => this.toggleOptions()} className={styles.selectedOptionText}> {this.state.selectedOptions.length > 0 ? this.state.selectedOptions.length + ' ' + translatePhrase('selected') : ''}</div>

                    {this.props.options && <Button dataSelector="form-toggle-options" icon={<DropdownIcon />} onClick={() => this.toggleOptions()}
                        isRounded={true} isBlock={false} size={'small'} />}


                </div>

                <section ref={this.optionsListElement} style={{ display: this.state.isShowingOptions ? 'block' : 'none' }}>
                    <MultiSelectOptionsList
                        isShowing={this.state.isShowingOptions}
                        selectedOptions={this.state.selectedOptions}
                        options={this.state.options}
                        isSearchDisabled={this.props.isSearchDisabled}
                        isAutoFocus={this.props.isAutoFocus}
                        onSelect={this.onSelect}
                        selectAll={this.selectAllOptions}
                        selectNone={this.selectNone}
                        selectSearchedOptions={this.selectSearchedOptions}
                    />
                </section>
            </section>
        );
    }
}

const EnhancedMultiSelectInputText = onClickOutside(MultiSelectInputText, {
    excludeScrollbar: true
});

export default EnhancedMultiSelectInputText;