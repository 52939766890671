import { WorkflowTypeState, WorkflowTypeActionTypes } from './types/types';
import { StatusActions } from './types/statuses/types';
import { VariableActionTypes } from '../flowchart/variables/types';
import { FlowchartProcessState } from '../flowchart/types';

import { NormalizedModel, Synchronizable } from '../normalized-model';
import { CustomFieldDataForIndividualMembers, CustomFieldValueType, WorkflowTypeCustomFieldDataHolder } from '../custom-fields/types';
import { SearchIndex } from '../../helpers/common-types';

export const ADD_WORKFLOW = 'ADD_WORKFLOW';
export const UPDATE_WORKFLOW = 'UPDATE_WORKFLOW';
export const DELETE_WORKFLOW = 'DELETE_WORKFLOW';
export const UN_ARCHIVE_WORKFLOW = 'UN_ARCHIVE_WORKFLOW';

export const BULK_DELETE_WORKFLOWS = 'BULK_DELETE_WORKFLOWS';

export const TRANSFER_WORKFLOW = 'TRANSFER_WORKFLOW';
export const BULK_TRANSFER_WORKFLOWS = 'BULK_TRANSFER_WORKFLOWS';

export const RECALCULATE_COMPUTED_FIELDS_FOR_WORKFLOW = 'RECALCULATE_COMPUTED_FIELDS_FOR_WORKFLOW';
export const BULK_RECALCULATE_COMPUTED_FIELDS_FOR_WORKFLOW = 'BULK_RECALCULATE_COMPUTED_FIELDS_FOR_WORKFLOW';
export const RECOMPUTE_ALL_WORKFLOWS = 'RECOMPUTE_ALL_WORKFLOWS';

export const SEARCH_WORKFLOW_TABLE = 'SEARCH_WORKFLOW_TABLE';
export const FILTER_WORKFLOW_TABLE = 'FILTER_WORKFLOW_TABLE';
export const SET_IS_FILTERING_FOR_WORKFLOW_TABLE = 'SET_IS_FILTERING_FOR_WORKFLOW_TABLE';
export const GO_TO_PAGE_WORKFLOW_TABLE = 'GO_TO_PAGE_WORKFLOW_TABLE';
export const SET_PAGE_SIZE_WORKFLOW_TABLE = 'SET_PAGE_SIZE_WORKFLOW_TABLE';
export const SORT_WORKFLOW_TABLE = 'SORT_WORKFLOW_TABLE';

export const UPDATE_WORKFLOW_CUSTOM_FIELD_DATA = 'UPDATE_WORKFLOW_CUSTOM_FIELD_DATA';
export const UPDATE_WORKFLOW_COMPUTED_FIELD_DATA = 'UPDATE_WORKFLOW_COMPUTED_FIELD_DATA';
export const BULK_UPDATE_WORKFLOW_COMPUTED_FIELD_DATA = 'BULK_UPDATE_WORKFLOW_COMPUTED_FIELD_DATA';

export const UPDATE_WORKFLOW_PROCESS_STATE = 'UPDATE_WORKFLOW_PROCESS_STATE';
export const ADD_TO_WORKFLOW_HISTORY = 'ADD_TO_WORKFLOW_HISTORY';
export const ADD_TO_SCREEN_INPUTS = 'ADD_TO_SCREEN_INPUTS';
export const RESTRICT_WORKFLOW_NAVIGATION = 'RESTRICT_WORKFLOW_NAVIGATION';
export const WORKFLOW_NAVIGATE_BACK = 'WORKFLOW_NAVIGATE_BACK';
export const WORKFLOW_NAVIGATE_FORWARD = 'WORKFLOW_NAVIGATE_FORWARD';
export const CLEAN_OUT_WORKFLOW_VARIABLES = 'CLEAN_OUT_WORKFLOW_VARIABLES';

export const UPDATE_WORKFLOW_STATUS = 'UPDATE_WORKFLOW_STATUS';
export const UPDATE_WORKFLOW_DUE_DATE = 'UPDATE_WORKFLOW_DUE_DATE';

export const UPDATE_WORKFLOWS_DATA = 'UPDATE_WORKFLOWS_DATA';

export const SYNCHRONIZE_WORKFLOWS_DATA = 'SYNCHRONIZE_WORKFLOWS_DATA';
export const ADD_COMPLETED_WORKFLOWS_DATA = 'ADD_COMPLETED_WORKFLOWS_DATA';
export const APPEND_WORKFLOWS_DATA = 'APPEND_WORKFLOWS_DATA';
export const CLEAR_WORKFLOWS_DELTA = 'CLEAR_WORKFLOWS_DELTA';

export const UPDATE_WORKFLOW_DATA = 'UPDATE_WORKFLOW_DATA';
export const CLEAR_WORKFLOW_ENTRIES = 'CLEAR_WORKFLOW_ENTRIES';

export const FETCH_WORKFLOWS_FROM_SERVER = 'FETCH_WORKFLOWS_FROM_SERVER';
export const SET_TOTAL_NUMBER_OF_WORKFLOWS_FROM_SERVER = 'SET_TOTAL_NUMBER_OF_WORKFLOWS_FROM_SERVER';

export const SET_WORKFLOW_IDS_FOR_CSV = 'SET_WORKFLOW_IDS_FOR_CSV';

export const SET_IS_SHOWING_CSV_FORM_FOR_WORKFLOW = 'SET_IS_SHOWING_CSV_FORM_FOR_WORKFLOW';
export const SET_WORKFLOW_START_PIECE = 'SET_WORKFLOW_START_PIECE';

export interface WorkflowProcessState extends FlowchartProcessState {
    customFields: {
        [customFieldId: string]: CustomFieldValueType | CustomFieldDataForIndividualMembers,
    },
}

export interface WorkflowProcessStep extends WorkflowProcessState {
    executingUser: string;
    executionTime: string;
}


export interface INewWorkflowData {
    type: string,
    status: string,
    dueDate: string | undefined,

    user: string,
    affiliatedEntity: string,

    triggeringWorkflow?: string,
    startPieceId?: string,
}

export interface IUpdateableWorkflowData extends INewWorkflowData {
    id: string,
}

export interface IWorkflow extends IUpdateableWorkflowData, Synchronizable {
    archived?: boolean,
    createdTime: string,
    trackingUsers: Array<string>,  // Set of user IDs of all the users who can read this workflow
    history: Array<WorkflowProcessStep>,
    historyIndex: number,
    restrictedHistoryIndex?: number,
    lastWorkedOnTime?: string;
    lastUpdateTimeForFields: {
        [customFieldId: string]: string,
    }
}

export interface IWorkflowScreenInput {
    pieceId: string;
    workflowIndex: number;
    answers: {
        [customFieldId: string]: CustomFieldValueType,
    };
    groupedAnswers: {
        [memberId: string]: {
            [customFieldId: string]: CustomFieldValueType,
        },
    };
    choices: {
        [choiceId: string]: string | Array<string>,
    };
}

export interface WorkflowFilters {
    dues: Array<string>,
    projects: Array<string>,
    types: Array<string>,
    statuses: Array<string>,
    users: Array<string>,
    locations: Array<string>,
    otherUsers: Array<string>,
    affiliations: Array<string>,
    customFields: {
        [fieldId: string]: Array<string>,
    },
    createdDateRange: Array<string>,
    lastUpdatedDateRange: Array<string>,
    lastWorkedOn: {
        startTime?: string,
        endTime?: string,
    },
    dueDateRange: Array<string>,
    unsynced: boolean,
    archived: boolean,
    isOutdated: boolean,
};

export interface WorkflowState extends NormalizedModel<IWorkflow> {
    types: WorkflowTypeState,

    activeWorkflowEntries: Array<string>,

    isFiltering: boolean,

    noOfWorkflowsReceivedInLastSync: number,
    totalNoOfWorkflows: number,

    pageSize: number,
    currentPageNumber: number,
    filters: WorkflowFilters,
    sort: {
        column: string | undefined,
        order: 'ASC' | 'DESC',
    },
    searchTerm: string | undefined,
    screenInputs: {
        [workflowId: string]: Array<IWorkflowScreenInput>,
    },

    markedForIndex: Array<string>,

    selectedWorkflowTypeIdsForCSV: Array<string>,
    isShowingCSVForm?: boolean;
}

export interface SetIsShowingCSVFormForWorkflowsAction {
    type: typeof SET_IS_SHOWING_CSV_FORM_FOR_WORKFLOW;
    showValue: boolean;
}


export interface SetWorkflowTypeIdsForCSV {
    type: typeof SET_WORKFLOW_IDS_FOR_CSV,
    ids: Array<string>
}

export interface AddWorkflowAction {
    type: typeof ADD_WORKFLOW,
    payload: IWorkflow,
    currentTime: string,
}

export interface UpdateWorkflowAction {
    type: typeof UPDATE_WORKFLOW,
    payload: IUpdateableWorkflowData,
    currentTime: string,
}

export interface DeleteWorkflowAction {
    type: typeof DELETE_WORKFLOW,
    id: string,
    currentTime: string,
}

export interface BulkDeleteWorkflowAction {
    type: typeof BULK_DELETE_WORKFLOWS,
    ids: Array<string>,
    currentTime: string,
}

export interface UnArchiveWorkflowAction {
    type: typeof UN_ARCHIVE_WORKFLOW,
    id: string,
    currentTime: string,
}


export interface SearchWorkflowTableAction {
    type: typeof SEARCH_WORKFLOW_TABLE,
    searchTerm: string,
}

export interface FilterWorkflowTableAction {
    type: typeof FILTER_WORKFLOW_TABLE,
    dues: Array<string>,
    projects: Array<string>,
    types: Array<string>,
    statuses: Array<string>,
    users: Array<string>,
    locations: Array<string>,
    otherUsers: Array<string>,
    affiliations: Array<string>,
    customFields: {
        [customFieldId: string]: Array<string>,
    },
    createdDateRange: Array<string>,
    lastUpdatedDateRange: Array<string>,
    lastWorkedOn: {
        startTime?: string,
        endTime?: string,
    },
    dueDateRange: Array<string>,
    unsynced: boolean,
    archived: boolean,
    isOutdated: boolean,
}

export interface SetIsFilteringForWorkflowTableAction {
    type: typeof SET_IS_FILTERING_FOR_WORKFLOW_TABLE;
    isFiltering: boolean;
}

export interface GoToPageWorkflowTableAction {
    type: typeof GO_TO_PAGE_WORKFLOW_TABLE,
    pageNumber: number,
}

export interface SetPageSizeWorkflowTableAction {
    type: typeof SET_PAGE_SIZE_WORKFLOW_TABLE,
    pageSize: number,
}

export interface SortWorkflowTableAction {
    type: typeof SORT_WORKFLOW_TABLE,
    column: string,
    order: 'ASC' | 'DESC'
}


export interface UpdateWorkflowProcessStateAction {
    type: typeof UPDATE_WORKFLOW_PROCESS_STATE,
    processState: WorkflowProcessStep,
    workflowId: string,
    updateTime: string,
}

export interface AddToWorkflowHistory {
    type: typeof ADD_TO_WORKFLOW_HISTORY,
    workflowId: string,
    processState: WorkflowProcessStep,
    updateTime: string,
}

export interface AddToScreenInputs {
    type: typeof ADD_TO_SCREEN_INPUTS;
    workflowId: string;
    screenInput: IWorkflowScreenInput;
}

export interface RestrictWorkflowNavigationAction {
    type: typeof RESTRICT_WORKFLOW_NAVIGATION,
    workflowId: string,
    currentTime: string,
}

export interface WorkflowNavigateForwardAction {
    type: typeof WORKFLOW_NAVIGATE_FORWARD,
    workflowId: string,
    currentTime: string,
}

export interface CleanOutWorkflowVariablesAction {
    type: typeof CLEAN_OUT_WORKFLOW_VARIABLES,
    workflowId: string,
    exceptions: Array<string>,
    currentTime: string,
}

export interface WorkflowNavigateBackAction {
    type: typeof WORKFLOW_NAVIGATE_BACK,
    workflowId: string,
    currentTime: string,
}

export interface UpdateWorkflowStatusAction {
    type: typeof UPDATE_WORKFLOW_STATUS,
    workflowId: string,
    statusId: string,
    currentTime: string,
}

export interface UpdateWorkflowDueDateAction {
    type: typeof UPDATE_WORKFLOW_DUE_DATE,
    workflowId: string,
    dueDate: string,
    currentTime: string,
}

export interface TransferWorkflowAction {
    type: typeof TRANSFER_WORKFLOW,
    workflowId: string,
    user: string,
    currentTime: string,
}

export interface BulkTransferWorkflowsAction {
    type: typeof BULK_TRANSFER_WORKFLOWS,
    workflowIds: Array<string>,
    user: string,
    currentTime: string,
}

export interface ChangeDeltaForWorkflowComputedFields {
    workflowId: string,
    workflowChanged: boolean,
    usersChanged: boolean,
    affiliationChanged: boolean,
}

export interface RecalculateComputedFieldsForWorkflowAction extends ChangeDeltaForWorkflowComputedFields {
    type: typeof RECALCULATE_COMPUTED_FIELDS_FOR_WORKFLOW,
}

export interface BulkRecalculateComputedFieldsForWorkflowAction {
    type: typeof BULK_RECALCULATE_COMPUTED_FIELDS_FOR_WORKFLOW,
    payload: Array<ChangeDeltaForWorkflowComputedFields>
}

export interface UpdateWorkflowCustomFieldDataAction {
    type: typeof UPDATE_WORKFLOW_CUSTOM_FIELD_DATA,
    changingWorkflowId: string,
    workflowId: string,
    customFieldData: WorkflowTypeCustomFieldDataHolder,
    currentTime: string,
}

export interface UpdateWorkflowComputedFieldDataAction {
    type: typeof UPDATE_WORKFLOW_COMPUTED_FIELD_DATA,
    workflowId: string,
    customFieldData: WorkflowTypeCustomFieldDataHolder,
    currentTime: string,
}

export interface ComputedFieldUpdatePayloadForWorkflow {
    workflowId: string;
    customFieldData: WorkflowTypeCustomFieldDataHolder;
}

export interface BulkUpdateWorkflowComputedFieldDataAction {
    type: typeof BULK_UPDATE_WORKFLOW_COMPUTED_FIELD_DATA,
    payload: Array<ComputedFieldUpdatePayloadForWorkflow>,
    currentTime: string,
}

export interface RecomputeAllWorkflowsAction {
    type: typeof RECOMPUTE_ALL_WORKFLOWS,
}

export interface UpdateWorkflowsData {
    type: typeof UPDATE_WORKFLOWS_DATA,
    data: Array<IWorkflow>,
    totalNoOfWorkflows: number;
}

export interface SynchronizeWorkflowsData {
    type: typeof SYNCHRONIZE_WORKFLOWS_DATA,
    data: Array<IWorkflow>,
}

export interface AddCompletedWorkflowsData {
    type: typeof ADD_COMPLETED_WORKFLOWS_DATA,
    data: Array<IWorkflow>,
}

export interface AppendWorkflowsData {
    type: typeof APPEND_WORKFLOWS_DATA,
    data: Array<IWorkflow>,
}

export interface ClearWorkflowsDelta {
    type: typeof CLEAR_WORKFLOWS_DELTA,
}

export interface IUpdateWorkflowData {
    type: typeof UPDATE_WORKFLOW_DATA,
    data: WorkflowState,
}

export interface IClearWorkflowEntriesAction {
    type: typeof CLEAR_WORKFLOW_ENTRIES,
}

export interface IFetchWorkflowsFromServerAction {
    type: typeof FETCH_WORKFLOWS_FROM_SERVER,
}

export interface ISetTotalNumberOfWorkflowsFromServerAction {
    type: typeof SET_TOTAL_NUMBER_OF_WORKFLOWS_FROM_SERVER,
    totalNumberOfWorkflows: number,
}

export interface SetWorkflowStartPiece {
    type: typeof SET_WORKFLOW_START_PIECE;
    startPieceId: string;
    workflowId: string;
}


type WorkflowActions = AddWorkflowAction | UpdateWorkflowAction | DeleteWorkflowAction | BulkDeleteWorkflowAction | UnArchiveWorkflowAction | SetWorkflowTypeIdsForCSV;

type WorkflowTableActions = SearchWorkflowTableAction | FilterWorkflowTableAction | SetIsFilteringForWorkflowTableAction | GoToPageWorkflowTableAction | SetPageSizeWorkflowTableAction | SortWorkflowTableAction | IClearWorkflowEntriesAction | IFetchWorkflowsFromServerAction | ISetTotalNumberOfWorkflowsFromServerAction | SetIsShowingCSVFormForWorkflowsAction;

type ExecutionActions = UpdateWorkflowProcessStateAction | UpdateWorkflowStatusAction | UpdateWorkflowDueDateAction | TransferWorkflowAction | BulkTransferWorkflowsAction | UpdateWorkflowCustomFieldDataAction | RecalculateComputedFieldsForWorkflowAction | BulkRecalculateComputedFieldsForWorkflowAction | UpdateWorkflowComputedFieldDataAction | BulkUpdateWorkflowComputedFieldDataAction | RecomputeAllWorkflowsAction | AddToWorkflowHistory | AddToScreenInputs | RestrictWorkflowNavigationAction | WorkflowNavigateForwardAction | CleanOutWorkflowVariablesAction | WorkflowNavigateBackAction;

export type WorkflowActionTypes = WorkflowActions | WorkflowTableActions | ExecutionActions | WorkflowTypeActionTypes | StatusActions | VariableActionTypes | IUpdateWorkflowData | UpdateWorkflowsData | SynchronizeWorkflowsData | AddCompletedWorkflowsData | AppendWorkflowsData | ClearWorkflowsDelta | SetWorkflowStartPiece;