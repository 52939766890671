import React, { Component } from 'react';
import styles from './ReleaseNotes.module.scss';
import { translatePhrase } from '../../shared/helpers/translation';

import { VERSION_NUMBER } from '../../shared/helpers/version';

import { ReactComponent as ClipboardIcon } from  '../../assets/new-custom-icons/common/clipboard.svg';
import { BUG_FIXES, FEATURES_AND_ENHANCEMENTS } from '../../shared/helpers/release-data';
import PageHeader from '../page-header/PageHeader';

class ReleaseNotes extends Component {

    render () {
        return (<section className={styles.FocusSpace}>
            <header className={styles.pageHeader}>
                <h2 className={styles.heading}> <ClipboardIcon />  {translatePhrase('Release Notes') + ' ' + VERSION_NUMBER + ' '} available now </h2>                
                <PageHeader />
            </header>

            <section className={styles.innerFocus}>
                <div className={styles.notesContainer}>
                    <section className={styles.notes}>
                        <h3> Features / Enhancements </h3>
                        <ol>
                            {FEATURES_AND_ENHANCEMENTS.map(feature => {
                                return <li key={feature}><p>{feature}</p></li>
                            })}
                        </ol>
                    </section>

                    <section className={styles.notes}>
                        <h3> Bug Fixes </h3>
                        <ol>
                            {BUG_FIXES.map(feature => {
                                return <li key={feature}><p>{feature}</p></li>
                            })}
                        </ol>
                    </section>
                </div>
            </section>
            
        </section>);
    }
}

export default ReleaseNotes;