import React from 'react';
import { VariableState, VariableType } from '../../../shared/store/flowchart/variables/types';
import { PieceType, PieceState, FlowchartPieceActions } from '../../../shared/store/flowchart/pieces/types';
import ForPiece from '../../../components/flowchart/pieces/ForPiece';
import SplitPiece, { IfPieceData } from '../../../components/flowchart/pieces/SplitPiece';

import AdditionOperator from '../pieces/operators/AdditionOperator';
import VariableToBooleanOperator from '../pieces/operators/VariableToBooleanOperator';

import { isUUID } from '../../../shared/helpers/utilities';
import SetPiece from '../../../components/flowchart/pieces/SetPiece';
import StructurePiece from '../../../components/flowchart/pieces/StructurePiece';
import StaticDataPiece from '../../../components/flowchart/pieces/StaticDataPiece';
import AddToTablePiece from '../../../components/flowchart/pieces/AddToTablePiece';
import AddToListPiece from '../../../components/flowchart/pieces/AddToListPiece';
import RemoveFromListPiece from '../../../components/flowchart/pieces/RemoveFromListPiece';
import VariablePiece from '../../../components/flowchart/pieces/VariablePiece';
import NewDatePiece from '../../../components/flowchart/pieces/NewDatePiece';
import { PiecePositionState, Position } from '../../../shared/helpers/common-types';
import SubtractionOperator from '../pieces/operators/SubtractionOperator';
import MultiplicationOperator from '../pieces/operators/MultiplicationOperator';
import DivisionOperator from '../pieces/operators/DivisionOperator';
import ExponentOperator from '../pieces/operators/ExponentOperator';
import LesserThanOperator from '../pieces/operators/LesserThanOperator';
import LesserThanOrEqualToOperator from '../pieces/operators/LesserThanOrEqualToOperator';
import GreaterThanOperator from '../pieces/operators/GreaterThanOperator';
import GreaterThanOrEqualToOperator from '../pieces/operators/GreaterThanOrEqualToOperator';
import EqualToOperator from '../pieces/operators/EqualToOperator';
import NotEqualToOperator from '../pieces/operators/NotEqualToOperator';
import BooleanToVariableOperator from '../pieces/operators/BooleanToVariableOperator';
import AndOperator from '../pieces/operators/AndOperator';
import OrOperator from '../pieces/operators/OrOperator';
import NotOperator from '../pieces/operators/NotOperator';
import ConstantPiece from '../pieces/ConstantPiece';
import InOperator from '../pieces/operators/InOperator';
import NotInOperator from '../pieces/operators/NotInOperator';
import GetPiece from '../pieces/GetPiece';
import GetValuePiece from '../pieces/GetValuePiece';
import StorePiece from '../pieces/StorePiece';
import AddMonthsOperator from '../pieces/operators/AddMonthsOperator';
import AddYearsOperator from '../pieces/operators/AddYearsOperator';
import SubtractMonthsOperator from '../pieces/operators/SubtractMonthsOperator';
import SubtractYearsOperator from '../pieces/operators/SubtractYearsOperator';
import PickFirstElementOperator from '../pieces/operators/PickFirstElementOperator';
import PickLastElementOperator from '../pieces/operators/PickLastElementOperator';
import PickFirstNElementsOperator from '../pieces/operators/PickFirstNElementsOperator';
import PickLastNElementsOperator from '../pieces/operators/PickLastNElementsOperator';
import PickNthElementOperator from '../pieces/operators/PickNthElementOperator';
import { getPieceValueType, getVariableValueType } from '../../../shared/store/flowchart/helpers';
import IsDefinedOperator from '../pieces/operators/IsDefinedOperator';
import IsNotDefinedOperator from '../pieces/operators/IsNotDefinedOperator';
import LengthOperator from '../pieces/operators/LengthOperator';
import TranslateOperator from '../pieces/operators/TranslateOperator';
import GetDateOperator from '../pieces/operators/GetDateOperator';
import GetDayOperator from '../pieces/operators/GetDayOperator';
import GetMonthOperator from '../pieces/operators/GetMonthOperator';
import GetReadableMonthOperator from '../pieces/operators/GetReadableMonthOperator';
import GetYearOperator from '../pieces/operators/GetYearOperator';
import FormatPiece from '../pieces/FormatPiece';
import GetTimeDifferenceOperator from '../pieces/operators/GetTimeDifferenceOperator';
import GetAffiliationForWorkflowPiece from '../pieces/GetAffiliationForWorkflowPiece';
import GetEntitiesPiece from '../pieces/GetEntitiesPiece';
import StyleTableSectionPiece from '../pieces/StyleTableSectionPiece';
import SplitBySeparatorOperator from '../pieces/operators/SplitBySeparatorOperator';

type CategoryPieceType = {
    name: string,
    type: PieceType,
    tooltip?: string,
}

export type CategoryValue = {
    color: string,
    pieces: Array<CategoryPieceType>,
}

export function getComponent(pieceId: string, piecesState: PieceState, variablesState: VariableState, variableIds: Array<string>, getInnerComponent: (pieceId: string, detachPiece?: () => void) => JSX.Element, isEditable = true, flowchartPieceActions: FlowchartPieceActions, isolatePiece: (pieceState: PiecePositionState) => void, removeIsolatedPiece: (pieceId: string) => void, registerVariable: (variableId: string) => void, detachPiece?: () => void, initialPosition?: Position): JSX.Element {
    const piece = piecesState.byId[pieceId];

    let operandPiece: JSX.Element | undefined;
    let operandText: string | undefined;

    let leftOperandPiece: JSX.Element | undefined;
    let leftOperandText: string | undefined;


    let rightOperandPiece: JSX.Element | undefined;
    let rightOperandText: string | undefined;

    switch (piece.type) {
        case PieceType.FOR:
            const nextPiece = piece.nextPiece ? getInnerComponent(piece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
            const innerPiece = piece.innerPiece ? getInnerComponent(piece.innerPiece, flowchartPieceActions.setInnerPiece.bind({}, pieceId, undefined)) : undefined;
            const iterablePiece = piece.iterableVariable && isUUID(piece.iterableVariable) ? getInnerComponent(piece.iterableVariable, flowchartPieceActions.setIterableVariable.bind({}, pieceId, undefined)) : undefined;
            const iterableVariableType = piece.iterableVariable && isUUID(piece.iterableVariable) ? getPieceValueType(piece.iterableVariable, piecesState, variablesState) : undefined;
            const iterableText = !(piece.iterableVariable && isUUID(piece.iterableVariable)) ? piece.iterableVariable : undefined;

            const loopVariableOptions = variableIds
                .filter(variableId => {
                    const variable = variablesState.byId[variableId];

                    if (piece.iterableVariable && !isUUID(piece.iterableVariable) && !isNaN(Number(piece.iterableVariable))) {
                        return variable.type === VariableType.NUMBER;
                    }

                    switch (iterableVariableType) {
                        case VariableType.TEXT_LIST:
                            return variable.type === VariableType.TEXT;
                        case VariableType.TEXT_LIST:
                            return variable.type === VariableType.TEXT;
                        case VariableType.USERS_LIST:
                            return variable.type === VariableType.USER;
                        case VariableType.MEMBERS_LIST:
                            return variable.type === VariableType.MEMBER;
                        case VariableType.GROUPS_LIST:
                            return variable.type === VariableType.GROUP;
                        case VariableType.LOCATIONS_LIST:
                            return variable.type === VariableType.LOCATION;
                        case VariableType.ROLES_LIST:
                            return variable.type === VariableType.ROLE;
                        case VariableType.LEVELS_LIST:
                            return variable.type === VariableType.LEVEL;
                        case VariableType.PROJECTS_LIST:
                            return variable.type === VariableType.PROJECT;
                        case VariableType.WORKFLOWS_LIST:
                            return variable.type === VariableType.WORKFLOW;
                        case VariableType.REPORTS_LIST:
                            return variable.type === VariableType.REPORT;
                        case VariableType.DATA_FRAGMENTS_LIST:
                            return variable.type === VariableType.DATA_FRAGMENT;

                        case VariableType.TABLE_DATA:
                            return variable.type === VariableType.TEXT_LIST;
                        case VariableType.NUMBER:
                            return variable.type === VariableType.NUMBER;

                        case VariableType.STYLED_TABLE_ROW:
                            return variable.type === VariableType.STYLED_TABLE_CELL;
                        case VariableType.STYLED_TABLE_DATA:
                            return variable.type === VariableType.STYLED_TABLE_ROW;

                        default:
                            return true;
                    }
                })
                .map(variableId => {
                    return {
                        name: variablesState.byId[variableId].name,
                        value: variableId,
                    };
                });

            return <ForPiece
                pieceId={pieceId}
                iterablePiece={iterablePiece}
                iterableText={iterableText}
                iterableVariableType={iterableVariableType}
                nextPiece={nextPiece}
                variables={loopVariableOptions}
                loopVariableId={piece.loopVariable}
                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            >
                {innerPiece}
            </ForPiece>


        case PieceType.GET:

            const getNextPiece = piece.nextPiece ? getInnerComponent(piece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
            const copyingVariableName = piece.variableToCopy ? variablesState.byId[piece.variableToCopy].name : undefined;
            const getMemberVariable = piece.memberVariablePiece ? getInnerComponent(piece.memberVariablePiece, flowchartPieceActions.setMemberVariable.bind({}, pieceId, undefined)) : undefined;

            const getPieceVariable = piece.variablePiece && isUUID(piece.variablePiece) ? getInnerComponent(piece.variablePiece, flowchartPieceActions.setVariableForCustomField.bind({}, pieceId, undefined)) : undefined;

            const getVariableType = piece.variablePiece && isUUID(piece.variablePiece) ? getPieceValueType(piece.variablePiece, piecesState, variablesState) : VariableType.TEXT;

            let selectedTypeForGet: 'Location' | 'User' | 'Member' | 'Group' | 'Workflow' | undefined;

            if (getVariableType === VariableType.LOCATION) {
                selectedTypeForGet = 'Location';
            } else if (getVariableType === VariableType.USER) {
                selectedTypeForGet = 'User';
            } else if (getVariableType === VariableType.MEMBER) {
                selectedTypeForGet = 'Member';
            } else if (getVariableType === VariableType.GROUP) {
                selectedTypeForGet = 'Group';
            } else if (getVariableType === VariableType.WORKFLOW) {
                selectedTypeForGet = 'Workflow';
            }

            const getVariables = variableIds.map(variableId => {
                return {
                    name: variablesState.byId[variableId].name,
                    value: variableId,
                };
            });

            return <GetPiece
                pieceId={pieceId}
                gettingCustomField={piece.customFieldId}
                copyingVariableName={copyingVariableName}
                memberVariable={getMemberVariable}
                variables={getVariables}

                selectedType={selectedTypeForGet}
                selectedEntityType={piece.entityType}
                variablePiece={getPieceVariable}

                nextPiece={getNextPiece}
                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />


        case PieceType.GET_VALUE:

            const getValueMemberVariable = piece.memberVariablePiece ? getInnerComponent(piece.memberVariablePiece, flowchartPieceActions.setMemberVariable.bind({}, pieceId, undefined)) : undefined;

            const getValuePieceVariable = piece.variablePiece && isUUID(piece.variablePiece) ? getInnerComponent(piece.variablePiece, flowchartPieceActions.setVariableForCustomField.bind({}, pieceId, undefined)) : undefined;

            const getValueVariableType = piece.variablePiece && isUUID(piece.variablePiece) ? getPieceValueType(piece.variablePiece, piecesState, variablesState) : VariableType.TEXT;

            let selectedTypeForGetValue: 'Location' | 'User' | 'Member' | 'Group' | 'Workflow' | undefined;

            if (getValueVariableType === VariableType.LOCATION) {
                selectedTypeForGetValue = 'Location';
            } else if (getValueVariableType === VariableType.USER) {
                selectedTypeForGetValue = 'User';
            } else if (getValueVariableType === VariableType.MEMBER) {
                selectedTypeForGetValue = 'Member';
            } else if (getValueVariableType === VariableType.GROUP) {
                selectedTypeForGetValue = 'Group';
            } else if (getValueVariableType === VariableType.WORKFLOW) {
                selectedTypeForGetValue = 'Workflow';
            }

            return <GetValuePiece
                pieceId={pieceId}
                gettingCustomField={piece.customFieldId}
                memberVariable={getValueMemberVariable}

                selectedType={selectedTypeForGetValue}
                selectedEntityType={piece.entityType}
                variablePiece={getValuePieceVariable}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.STORE:
            const storeNextPiece = piece.nextPiece ? getInnerComponent(piece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;

            const storeVariable = piece.dataToStore && isUUID(piece.dataToStore) ? getInnerComponent(piece.dataToStore, flowchartPieceActions.setDataStoreValue.bind({}, pieceId, undefined)) : undefined;
            const storeText = piece.dataToStore && !isUUID(piece.dataToStore) ? piece.dataToStore : undefined;

            const storeMemberVariable = piece.memberVariablePiece ? getInnerComponent(piece.memberVariablePiece, flowchartPieceActions.setMemberVariable.bind({}, pieceId, undefined)) : undefined;

            const storePieceVariable = piece.variablePiece && isUUID(piece.variablePiece) ? getInnerComponent(piece.variablePiece, flowchartPieceActions.setVariableForCustomField.bind({}, pieceId, undefined)) : undefined;

            const storeVariableType = piece.variablePiece && isUUID(piece.variablePiece) ? getPieceValueType(piece.variablePiece, piecesState, variablesState) : VariableType.TEXT;

            let selectedTypeForStore: 'Location' | 'User' | 'Member' | 'Group' | 'Workflow' | undefined;

            if (storeVariableType === VariableType.LOCATION) {
                selectedTypeForStore = 'Location';
            } else if (storeVariableType === VariableType.USER) {
                selectedTypeForStore = 'User';
            } else if (storeVariableType === VariableType.MEMBER) {
                selectedTypeForStore = 'Member';
            } else if (storeVariableType === VariableType.GROUP) {
                selectedTypeForStore = 'Group';
            } else if (storeVariableType === VariableType.WORKFLOW) {
                selectedTypeForStore = 'Workflow';
            }

            return <StorePiece
                pieceId={pieceId}
                memberVariable={storeMemberVariable}
                dataVariablePiece={storeVariable}
                textToStore={storeText}

                selectedType={selectedTypeForStore}
                selectedEntityType={piece.entityType}
                storingCustomField={piece.customFieldId}
                variablePiece={storePieceVariable}

                nextPiece={storeNextPiece}
                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.VARIABLE:

            const nestingData = piece.nesting || [];
            return <VariablePiece pieceId={pieceId} variableIds={variableIds} selectedVariableId={piece.variable} nesting={nestingData} detachPiece={detachPiece} isolatePiece={isolatePiece} removeIsolatedPiece={removeIsolatedPiece} registerVariable={registerVariable} initialPosition={initialPosition} />;

        case PieceType.STRUCTURE:

            return <StructurePiece
                pieceId={pieceId}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.STATIC_DATA:

            return <StaticDataPiece
                pieceId={pieceId}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.IS_DEFINED:
            operandPiece = piece.operand && isUUID(piece.operand) ? getInnerComponent(piece.operand, flowchartPieceActions.setOperand.bind({}, pieceId, undefined)) : undefined;

            return <IsDefinedOperator
                pieceId={pieceId}
                operandPiece={operandPiece}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.IS_NOT_DEFINED:
            operandPiece = piece.operand && isUUID(piece.operand) ? getInnerComponent(piece.operand, flowchartPieceActions.setOperand.bind({}, pieceId, undefined)) : undefined;

            return <IsNotDefinedOperator
                pieceId={pieceId}
                operandPiece={operandPiece}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.VARIABLE_TO_BOOLEAN:
            operandPiece = piece.operand && isUUID(piece.operand) ? getInnerComponent(piece.operand, flowchartPieceActions.setOperand.bind({}, pieceId, undefined)) : undefined;

            return <VariableToBooleanOperator
                pieceId={pieceId}
                operandPiece={operandPiece}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.BOOLEAN_TO_VARIABLE:
            operandPiece = piece.operand && isUUID(piece.operand) ? getInnerComponent(piece.operand, flowchartPieceActions.setOperand.bind({}, pieceId, undefined)) : undefined;

            return <BooleanToVariableOperator
                pieceId={pieceId}
                operandPiece={operandPiece}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.SPLIT:
            const splitNextPiece = piece.nextPiece ? getInnerComponent(piece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
            const initialIfPiecesData: Array<Partial<IfPieceData>> = piece.ifPieceData ? piece.ifPieceData.map((ifPieceData, index) => {
                const conditionPiece = ifPieceData.conditionPiece ? getInnerComponent(ifPieceData.conditionPiece, flowchartPieceActions.setConditionForIfPiece.bind({}, pieceId, index, undefined)) : undefined;
                const nextPiece = ifPieceData.nextPiece ? getInnerComponent(ifPieceData.nextPiece, flowchartPieceActions.setConditionNextPiece.bind({}, pieceId, index, undefined)) : undefined;

                return {
                    position: ifPieceData.position,
                    nextPiece,
                    conditionPiece,
                }
            }) : [];

            return <SplitPiece pieceId={pieceId} nextPiece={splitNextPiece} initialIfPieces={initialIfPiecesData.length > 0 ? initialIfPiecesData : undefined} isDragDisabled={!isEditable} detachPiece={detachPiece} isolatePiece={isolatePiece} removeIsolatedPiece={removeIsolatedPiece} initialPosition={initialPosition} />

        case PieceType.ADD_TO_TABLE:
            const addToTableVariables = variableIds
                .filter(variableId => {
                    const variableType = variablesState.byId[variableId].type;

                    return variableType === VariableType.TABLE_DATA || variableType === VariableType.STYLED_TABLE_DATA;
                })
                .map(variableId => {
                    return {
                        name: variablesState.byId[variableId].name,
                        value: variableId,
                    };
                });
            const addToTableNextPiece = piece.nextPiece ? getInnerComponent(piece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
            const addTableVariableName = piece.listVariable ? variablesState.byId[piece.listVariable].name : undefined;
            const addToTableVariablePiece = piece.dataToSet && isUUID(piece.dataToSet) ? getInnerComponent(piece.dataToSet, flowchartPieceActions.setDataForList.bind({}, pieceId, undefined)) : undefined;

            return <AddToTablePiece
                pieceId={pieceId}
                variables={addToTableVariables}
                nextPiece={addToTableNextPiece}
                listVariableName={addTableVariableName}
                variablePiece={addToTableVariablePiece}
                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.GET_AFFILIATION_FROM_WORKFLOW:

            const getAffiliationVariablePiece = piece.variablePiece && isUUID(piece.variablePiece) ? getInnerComponent(piece.variablePiece, flowchartPieceActions.setVariablePiece.bind({}, pieceId, undefined)) : undefined;
            const getAffiliationVariableType = piece.variablePiece && isUUID(piece.variablePiece) ? getPieceValueType(piece.variablePiece, piecesState, variablesState) : undefined;

            return <GetAffiliationForWorkflowPiece
                pieceId={pieceId}

                workflowType={piece.workflowType}
                variablePiece={getAffiliationVariablePiece}
                variableType={getAffiliationVariableType}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.ADD_TO_LIST:
            let addToListVariables: any = [];
            try {
                addToListVariables = variableIds
                    .filter(variableId => {
                        const variableType = variablesState.byId[variableId].type;

                        switch (variableType) {
                            case VariableType.TEXT_LIST:
                            case VariableType.PROJECTS_LIST:
                            case VariableType.LEVELS_LIST:
                            case VariableType.ROLES_LIST:
                            case VariableType.LOCATIONS_LIST:
                            case VariableType.USERS_LIST:
                            case VariableType.MEMBERS_LIST:
                            case VariableType.GROUPS_LIST:
                            case VariableType.WORKFLOWS_LIST:
                            case VariableType.DATA_FRAGMENTS_LIST:
                            case VariableType.STYLED_TABLE_ROW:
                                return true;
                            default:
                                return false;
                        }
                    })
                    .map(variableId => {
                        return {
                            name: variablesState.byId[variableId].name,
                            value: variableId,
                        };
                    });
            } catch (e) {
                console.log(e);
            }
            const addToListNextPiece = piece.nextPiece ? getInnerComponent(piece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
            const addListVariableName = piece.listVariable && piece.listVariable in variablesState.byId ? variablesState.byId[piece.listVariable].name : undefined;
            const addToListVariablePiece = piece.dataToSet && isUUID(piece.dataToSet) ? getInnerComponent(piece.dataToSet, flowchartPieceActions.setDataForList.bind({}, pieceId, undefined)) : undefined;
            const addListDataText = !(piece.dataToSet && isUUID(piece.dataToSet)) ? piece.dataToSet : undefined;

            return <AddToListPiece
                pieceId={pieceId}
                variables={addToListVariables}
                nextPiece={addToListNextPiece}
                listVariableId={piece.listVariable}
                listVariableName={addListVariableName}
                variablePiece={addToListVariablePiece}
                dataToAdd={addListDataText}
                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.REMOVE_FROM_LIST:
            const removeFromListVariables = variableIds
                .filter(variableId => {
                    const variableType = variablesState.byId[variableId].type;

                    switch (variableType) {
                        case VariableType.TEXT_LIST:
                        case VariableType.PROJECTS_LIST:
                        case VariableType.LEVELS_LIST:
                        case VariableType.ROLES_LIST:
                        case VariableType.LOCATIONS_LIST:
                        case VariableType.USERS_LIST:
                        case VariableType.MEMBERS_LIST:
                        case VariableType.GROUPS_LIST:
                        case VariableType.WORKFLOWS_LIST:
                        case VariableType.DATA_FRAGMENTS_LIST:
                            return true;
                        default:
                            return false;
                    }
                })
                .map(variableId => {
                    return {
                        name: variablesState.byId[variableId].name,
                        value: variableId,
                    };
                });
            const removeFromListNextPiece = piece.nextPiece ? getInnerComponent(piece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
            const removeListVariableName = piece.listVariable ? variablesState.byId[piece.listVariable].name : undefined;
            const removeFromListVariablePiece = piece.dataToSet && isUUID(piece.dataToSet) ? getInnerComponent(piece.dataToSet, flowchartPieceActions.setDataForList.bind({}, pieceId, undefined)) : undefined;
            const removeListDataText = !(piece.dataToSet && isUUID(piece.dataToSet)) ? piece.dataToSet : undefined;

            return <RemoveFromListPiece
                pieceId={pieceId}
                variables={removeFromListVariables}
                nextPiece={removeFromListNextPiece}
                listVariableName={removeListVariableName}
                variablePiece={removeFromListVariablePiece}
                dataToRemove={removeListDataText}
                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.ADD:
            leftOperandPiece = piece.leftOperand && isUUID(piece.leftOperand) ? getInnerComponent(piece.leftOperand, flowchartPieceActions.setLeftOperand.bind({}, pieceId, undefined)) : undefined;
            leftOperandText = !(piece.leftOperand && isUUID(piece.leftOperand)) ? piece.leftOperand : undefined;

            rightOperandPiece = piece.rightOperand && isUUID(piece.rightOperand) ? getInnerComponent(piece.rightOperand, flowchartPieceActions.setRightOperand.bind({}, pieceId, undefined)) : undefined;
            rightOperandText = !(piece.rightOperand && isUUID(piece.rightOperand)) ? piece.rightOperand : undefined;

            return <AdditionOperator
                pieceId={pieceId}
                leftOperandPiece={leftOperandPiece}
                leftOperandText={leftOperandText}

                rightOperandPiece={rightOperandPiece}
                rightOperandText={rightOperandText}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.SUBTRACT:
            leftOperandPiece = piece.leftOperand && isUUID(piece.leftOperand) ? getInnerComponent(piece.leftOperand, flowchartPieceActions.setLeftOperand.bind({}, pieceId, undefined)) : undefined;
            leftOperandText = !(piece.leftOperand && isUUID(piece.leftOperand)) ? piece.leftOperand : undefined;

            rightOperandPiece = piece.rightOperand && isUUID(piece.rightOperand) ? getInnerComponent(piece.rightOperand, flowchartPieceActions.setRightOperand.bind({}, pieceId, undefined)) : undefined;
            rightOperandText = !(piece.rightOperand && isUUID(piece.rightOperand)) ? piece.rightOperand : undefined;

            return <SubtractionOperator
                pieceId={pieceId}
                leftOperandPiece={leftOperandPiece}
                leftOperandText={leftOperandText}

                rightOperandPiece={rightOperandPiece}
                rightOperandText={rightOperandText}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.MULTIPLY:
            leftOperandPiece = piece.leftOperand && isUUID(piece.leftOperand) ? getInnerComponent(piece.leftOperand, flowchartPieceActions.setLeftOperand.bind({}, pieceId, undefined)) : undefined;
            leftOperandText = !(piece.leftOperand && isUUID(piece.leftOperand)) ? piece.leftOperand : undefined;

            rightOperandPiece = piece.rightOperand && isUUID(piece.rightOperand) ? getInnerComponent(piece.rightOperand, flowchartPieceActions.setRightOperand.bind({}, pieceId, undefined)) : undefined;
            rightOperandText = !(piece.rightOperand && isUUID(piece.rightOperand)) ? piece.rightOperand : undefined;

            return <MultiplicationOperator
                pieceId={pieceId}
                leftOperandPiece={leftOperandPiece}
                leftOperandText={leftOperandText}

                rightOperandPiece={rightOperandPiece}
                rightOperandText={rightOperandText}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.DIVIDE:
            leftOperandPiece = piece.leftOperand && isUUID(piece.leftOperand) ? getInnerComponent(piece.leftOperand, flowchartPieceActions.setLeftOperand.bind({}, pieceId, undefined)) : undefined;
            leftOperandText = !(piece.leftOperand && isUUID(piece.leftOperand)) ? piece.leftOperand : undefined;

            rightOperandPiece = piece.rightOperand && isUUID(piece.rightOperand) ? getInnerComponent(piece.rightOperand, flowchartPieceActions.setRightOperand.bind({}, pieceId, undefined)) : undefined;
            rightOperandText = !(piece.rightOperand && isUUID(piece.rightOperand)) ? piece.rightOperand : undefined;

            return <DivisionOperator
                pieceId={pieceId}
                leftOperandPiece={leftOperandPiece}
                leftOperandText={leftOperandText}

                rightOperandPiece={rightOperandPiece}
                rightOperandText={rightOperandText}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.EXPONENT:
            leftOperandPiece = piece.leftOperand && isUUID(piece.leftOperand) ? getInnerComponent(piece.leftOperand, flowchartPieceActions.setLeftOperand.bind({}, pieceId, undefined)) : undefined;
            leftOperandText = !(piece.leftOperand && isUUID(piece.leftOperand)) ? piece.leftOperand : undefined;

            rightOperandPiece = piece.rightOperand && isUUID(piece.rightOperand) ? getInnerComponent(piece.rightOperand, flowchartPieceActions.setRightOperand.bind({}, pieceId, undefined)) : undefined;
            rightOperandText = !(piece.rightOperand && isUUID(piece.rightOperand)) ? piece.rightOperand : undefined;

            return <ExponentOperator
                pieceId={pieceId}
                leftOperandPiece={leftOperandPiece}
                leftOperandText={leftOperandText}

                rightOperandPiece={rightOperandPiece}
                rightOperandText={rightOperandText}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.LESSER_THAN:
            leftOperandPiece = piece.leftOperand && isUUID(piece.leftOperand) ? getInnerComponent(piece.leftOperand, flowchartPieceActions.setLeftOperand.bind({}, pieceId, undefined)) : undefined;
            leftOperandText = !(piece.leftOperand && isUUID(piece.leftOperand)) ? piece.leftOperand : undefined;

            rightOperandPiece = piece.rightOperand && isUUID(piece.rightOperand) ? getInnerComponent(piece.rightOperand, flowchartPieceActions.setRightOperand.bind({}, pieceId, undefined)) : undefined;
            rightOperandText = !(piece.rightOperand && isUUID(piece.rightOperand)) ? piece.rightOperand : undefined;

            return <LesserThanOperator
                pieceId={pieceId}
                leftOperandPiece={leftOperandPiece}
                leftOperandText={leftOperandText}

                rightOperandPiece={rightOperandPiece}
                rightOperandText={rightOperandText}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.LESSER_THAN_OR_EQUAL_TO:
            leftOperandPiece = piece.leftOperand && isUUID(piece.leftOperand) ? getInnerComponent(piece.leftOperand, flowchartPieceActions.setLeftOperand.bind({}, pieceId, undefined)) : undefined;
            leftOperandText = !(piece.leftOperand && isUUID(piece.leftOperand)) ? piece.leftOperand : undefined;

            rightOperandPiece = piece.rightOperand && isUUID(piece.rightOperand) ? getInnerComponent(piece.rightOperand, flowchartPieceActions.setRightOperand.bind({}, pieceId, undefined)) : undefined;
            rightOperandText = !(piece.rightOperand && isUUID(piece.rightOperand)) ? piece.rightOperand : undefined;

            return <LesserThanOrEqualToOperator
                pieceId={pieceId}
                leftOperandPiece={leftOperandPiece}
                leftOperandText={leftOperandText}

                rightOperandPiece={rightOperandPiece}
                rightOperandText={rightOperandText}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.GREATER_THAN:
            leftOperandPiece = piece.leftOperand && isUUID(piece.leftOperand) ? getInnerComponent(piece.leftOperand, flowchartPieceActions.setLeftOperand.bind({}, pieceId, undefined)) : undefined;
            leftOperandText = !(piece.leftOperand && isUUID(piece.leftOperand)) ? piece.leftOperand : undefined;

            rightOperandPiece = piece.rightOperand && isUUID(piece.rightOperand) ? getInnerComponent(piece.rightOperand, flowchartPieceActions.setRightOperand.bind({}, pieceId, undefined)) : undefined;
            rightOperandText = !(piece.rightOperand && isUUID(piece.rightOperand)) ? piece.rightOperand : undefined;

            return <GreaterThanOperator
                pieceId={pieceId}
                leftOperandPiece={leftOperandPiece}
                leftOperandText={leftOperandText}

                rightOperandPiece={rightOperandPiece}
                rightOperandText={rightOperandText}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.GREATER_THAN_OR_EQUAL_TO:
            leftOperandPiece = piece.leftOperand && isUUID(piece.leftOperand) ? getInnerComponent(piece.leftOperand, flowchartPieceActions.setLeftOperand.bind({}, pieceId, undefined)) : undefined;
            leftOperandText = !(piece.leftOperand && isUUID(piece.leftOperand)) ? piece.leftOperand : undefined;

            rightOperandPiece = piece.rightOperand && isUUID(piece.rightOperand) ? getInnerComponent(piece.rightOperand, flowchartPieceActions.setRightOperand.bind({}, pieceId, undefined)) : undefined;
            rightOperandText = !(piece.rightOperand && isUUID(piece.rightOperand)) ? piece.rightOperand : undefined;

            return <GreaterThanOrEqualToOperator
                pieceId={pieceId}
                leftOperandPiece={leftOperandPiece}
                leftOperandText={leftOperandText}

                rightOperandPiece={rightOperandPiece}
                rightOperandText={rightOperandText}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.EQUAL_TO:
            leftOperandPiece = piece.leftOperand && isUUID(piece.leftOperand) ? getInnerComponent(piece.leftOperand, flowchartPieceActions.setLeftOperand.bind({}, pieceId, undefined)) : undefined;
            leftOperandText = !(piece.leftOperand && isUUID(piece.leftOperand)) ? piece.leftOperand : undefined;

            rightOperandPiece = piece.rightOperand && isUUID(piece.rightOperand) ? getInnerComponent(piece.rightOperand, flowchartPieceActions.setRightOperand.bind({}, pieceId, undefined)) : undefined;
            rightOperandText = !(piece.rightOperand && isUUID(piece.rightOperand)) ? piece.rightOperand : undefined;

            return <EqualToOperator
                pieceId={pieceId}
                leftOperandPiece={leftOperandPiece}
                leftOperandText={leftOperandText}

                rightOperandPiece={rightOperandPiece}
                rightOperandText={rightOperandText}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.NOT_EQUAL_TO:
            leftOperandPiece = piece.leftOperand && isUUID(piece.leftOperand) ? getInnerComponent(piece.leftOperand, flowchartPieceActions.setLeftOperand.bind({}, pieceId, undefined)) : undefined;
            leftOperandText = !(piece.leftOperand && isUUID(piece.leftOperand)) ? piece.leftOperand : undefined;

            rightOperandPiece = piece.rightOperand && isUUID(piece.rightOperand) ? getInnerComponent(piece.rightOperand, flowchartPieceActions.setRightOperand.bind({}, pieceId, undefined)) : undefined;
            rightOperandText = !(piece.rightOperand && isUUID(piece.rightOperand)) ? piece.rightOperand : undefined;

            return <NotEqualToOperator
                pieceId={pieceId}
                leftOperandPiece={leftOperandPiece}
                leftOperandText={leftOperandText}

                rightOperandPiece={rightOperandPiece}
                rightOperandText={rightOperandText}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.IN:
            leftOperandPiece = piece.leftOperand && isUUID(piece.leftOperand) ? getInnerComponent(piece.leftOperand, flowchartPieceActions.setLeftOperand.bind({}, pieceId, undefined)) : undefined;
            leftOperandText = !(piece.leftOperand && isUUID(piece.leftOperand)) ? piece.leftOperand : undefined;

            rightOperandPiece = piece.rightOperand && isUUID(piece.rightOperand) ? getInnerComponent(piece.rightOperand, flowchartPieceActions.setRightOperand.bind({}, pieceId, undefined)) : undefined;
            rightOperandText = !(piece.rightOperand && isUUID(piece.rightOperand)) ? piece.rightOperand : undefined;

            return <InOperator
                pieceId={pieceId}
                leftOperandPiece={leftOperandPiece}
                leftOperandText={leftOperandText}

                rightOperandPiece={rightOperandPiece}
                rightOperandText={rightOperandText}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.NOT_IN:
            leftOperandPiece = piece.leftOperand && isUUID(piece.leftOperand) ? getInnerComponent(piece.leftOperand, flowchartPieceActions.setLeftOperand.bind({}, pieceId, undefined)) : undefined;
            leftOperandText = !(piece.leftOperand && isUUID(piece.leftOperand)) ? piece.leftOperand : undefined;

            rightOperandPiece = piece.rightOperand && isUUID(piece.rightOperand) ? getInnerComponent(piece.rightOperand, flowchartPieceActions.setRightOperand.bind({}, pieceId, undefined)) : undefined;
            rightOperandText = !(piece.rightOperand && isUUID(piece.rightOperand)) ? piece.rightOperand : undefined;

            return <NotInOperator
                pieceId={pieceId}
                leftOperandPiece={leftOperandPiece}
                leftOperandText={leftOperandText}

                rightOperandPiece={rightOperandPiece}
                rightOperandText={rightOperandText}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.AND:
            leftOperandPiece = piece.leftOperand && isUUID(piece.leftOperand) ? getInnerComponent(piece.leftOperand, flowchartPieceActions.setLeftOperand.bind({}, pieceId, undefined)) : undefined;
            leftOperandText = !(piece.leftOperand && isUUID(piece.leftOperand)) ? piece.leftOperand : undefined;

            rightOperandPiece = piece.rightOperand && isUUID(piece.rightOperand) ? getInnerComponent(piece.rightOperand, flowchartPieceActions.setRightOperand.bind({}, pieceId, undefined)) : undefined;
            rightOperandText = !(piece.rightOperand && isUUID(piece.rightOperand)) ? piece.rightOperand : undefined;

            return <AndOperator
                pieceId={pieceId}
                leftOperandPiece={leftOperandPiece}
                leftOperandText={leftOperandText}

                rightOperandPiece={rightOperandPiece}
                rightOperandText={rightOperandText}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.OR:
            leftOperandPiece = piece.leftOperand && isUUID(piece.leftOperand) ? getInnerComponent(piece.leftOperand, flowchartPieceActions.setLeftOperand.bind({}, pieceId, undefined)) : undefined;
            leftOperandText = !(piece.leftOperand && isUUID(piece.leftOperand)) ? piece.leftOperand : undefined;

            rightOperandPiece = piece.rightOperand && isUUID(piece.rightOperand) ? getInnerComponent(piece.rightOperand, flowchartPieceActions.setRightOperand.bind({}, pieceId, undefined)) : undefined;
            rightOperandText = !(piece.rightOperand && isUUID(piece.rightOperand)) ? piece.rightOperand : undefined;

            return <OrOperator
                pieceId={pieceId}
                leftOperandPiece={leftOperandPiece}
                leftOperandText={leftOperandText}

                rightOperandPiece={rightOperandPiece}
                rightOperandText={rightOperandText}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.NOT:
            operandPiece = piece.operand && isUUID(piece.operand) ? getInnerComponent(piece.operand, flowchartPieceActions.setOperand.bind({}, pieceId, undefined)) : undefined;

            return <NotOperator
                pieceId={pieceId}
                operandPiece={operandPiece}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />


        // Date operators

        case PieceType.ADD_MONTHS:
            leftOperandPiece = piece.leftOperand && isUUID(piece.leftOperand) ? getInnerComponent(piece.leftOperand, flowchartPieceActions.setLeftOperand.bind({}, pieceId, undefined)) : undefined;
            leftOperandText = !(piece.leftOperand && isUUID(piece.leftOperand)) ? piece.leftOperand : undefined;

            rightOperandPiece = piece.rightOperand && isUUID(piece.rightOperand) ? getInnerComponent(piece.rightOperand, flowchartPieceActions.setRightOperand.bind({}, pieceId, undefined)) : undefined;
            rightOperandText = !(piece.rightOperand && isUUID(piece.rightOperand)) ? piece.rightOperand : undefined;

            return <AddMonthsOperator
                pieceId={pieceId}
                leftOperandPiece={leftOperandPiece}
                leftOperandText={leftOperandText}

                rightOperandPiece={rightOperandPiece}
                rightOperandText={rightOperandText}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.ADD_YEARS:
            leftOperandPiece = piece.leftOperand && isUUID(piece.leftOperand) ? getInnerComponent(piece.leftOperand, flowchartPieceActions.setLeftOperand.bind({}, pieceId, undefined)) : undefined;
            leftOperandText = !(piece.leftOperand && isUUID(piece.leftOperand)) ? piece.leftOperand : undefined;

            rightOperandPiece = piece.rightOperand && isUUID(piece.rightOperand) ? getInnerComponent(piece.rightOperand, flowchartPieceActions.setRightOperand.bind({}, pieceId, undefined)) : undefined;
            rightOperandText = !(piece.rightOperand && isUUID(piece.rightOperand)) ? piece.rightOperand : undefined;

            return <AddYearsOperator
                pieceId={pieceId}
                leftOperandPiece={leftOperandPiece}
                leftOperandText={leftOperandText}

                rightOperandPiece={rightOperandPiece}
                rightOperandText={rightOperandText}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.SUBTRACT_MONTHS:
            leftOperandPiece = piece.leftOperand && isUUID(piece.leftOperand) ? getInnerComponent(piece.leftOperand, flowchartPieceActions.setLeftOperand.bind({}, pieceId, undefined)) : undefined;
            leftOperandText = !(piece.leftOperand && isUUID(piece.leftOperand)) ? piece.leftOperand : undefined;

            rightOperandPiece = piece.rightOperand && isUUID(piece.rightOperand) ? getInnerComponent(piece.rightOperand, flowchartPieceActions.setRightOperand.bind({}, pieceId, undefined)) : undefined;
            rightOperandText = !(piece.rightOperand && isUUID(piece.rightOperand)) ? piece.rightOperand : undefined;

            return <SubtractMonthsOperator
                pieceId={pieceId}
                leftOperandPiece={leftOperandPiece}
                leftOperandText={leftOperandText}

                rightOperandPiece={rightOperandPiece}
                rightOperandText={rightOperandText}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.SUBTRACT_YEARS:
            leftOperandPiece = piece.leftOperand && isUUID(piece.leftOperand) ? getInnerComponent(piece.leftOperand, flowchartPieceActions.setLeftOperand.bind({}, pieceId, undefined)) : undefined;
            leftOperandText = !(piece.leftOperand && isUUID(piece.leftOperand)) ? piece.leftOperand : undefined;

            rightOperandPiece = piece.rightOperand && isUUID(piece.rightOperand) ? getInnerComponent(piece.rightOperand, flowchartPieceActions.setRightOperand.bind({}, pieceId, undefined)) : undefined;
            rightOperandText = !(piece.rightOperand && isUUID(piece.rightOperand)) ? piece.rightOperand : undefined;

            return <SubtractYearsOperator
                pieceId={pieceId}
                leftOperandPiece={leftOperandPiece}
                leftOperandText={leftOperandText}

                rightOperandPiece={rightOperandPiece}
                rightOperandText={rightOperandText}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.GET_DATE:
            operandPiece = piece.operand && isUUID(piece.operand) ? getInnerComponent(piece.operand, flowchartPieceActions.setOperand.bind({}, pieceId, undefined)) : undefined;

            return <GetDateOperator
                pieceId={pieceId}
                operandPiece={operandPiece}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.GET_DAY:
            operandPiece = piece.operand && isUUID(piece.operand) ? getInnerComponent(piece.operand, flowchartPieceActions.setOperand.bind({}, pieceId, undefined)) : undefined;

            return <GetDayOperator
                pieceId={pieceId}
                operandPiece={operandPiece}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.GET_MONTH:
            operandPiece = piece.operand && isUUID(piece.operand) ? getInnerComponent(piece.operand, flowchartPieceActions.setOperand.bind({}, pieceId, undefined)) : undefined;

            return <GetMonthOperator
                pieceId={pieceId}
                operandPiece={operandPiece}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.GET_READABLE_MONTH:
            operandPiece = piece.operand && isUUID(piece.operand) ? getInnerComponent(piece.operand, flowchartPieceActions.setOperand.bind({}, pieceId, undefined)) : undefined;

            return <GetReadableMonthOperator
                pieceId={pieceId}
                operandPiece={operandPiece}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.GET_YEAR:
            operandPiece = piece.operand && isUUID(piece.operand) ? getInnerComponent(piece.operand, flowchartPieceActions.setOperand.bind({}, pieceId, undefined)) : undefined;

            return <GetYearOperator
                pieceId={pieceId}
                operandPiece={operandPiece}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.GET_TIME_DIFFERENCE:
            leftOperandPiece = piece.leftOperand && isUUID(piece.leftOperand) ? getInnerComponent(piece.leftOperand, flowchartPieceActions.setLeftOperand.bind({}, pieceId, undefined)) : undefined;
            leftOperandText = !(piece.leftOperand && isUUID(piece.leftOperand)) ? piece.leftOperand : undefined;

            rightOperandPiece = piece.rightOperand && isUUID(piece.rightOperand) ? getInnerComponent(piece.rightOperand, flowchartPieceActions.setRightOperand.bind({}, pieceId, undefined)) : undefined;
            rightOperandText = !(piece.rightOperand && isUUID(piece.rightOperand)) ? piece.rightOperand : undefined;

            const timeDifferenceFormat = piece.format;

            return <GetTimeDifferenceOperator
                pieceId={pieceId}
                leftOperandPiece={leftOperandPiece}
                leftOperandText={leftOperandText}

                rightOperandPiece={rightOperandPiece}
                rightOperandText={rightOperandText}

                format={timeDifferenceFormat}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />


        // List operators

        case PieceType.PICK_FIRST_ELEMENT:
            operandPiece = piece.operand && isUUID(piece.operand) ? getInnerComponent(piece.operand, flowchartPieceActions.setOperand.bind({}, pieceId, undefined)) : undefined;

            return <PickFirstElementOperator
                pieceId={pieceId}
                operandPiece={operandPiece}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.PICK_FIRST_N_ELEMENTS:
            leftOperandPiece = piece.leftOperand && isUUID(piece.leftOperand) ? getInnerComponent(piece.leftOperand, flowchartPieceActions.setLeftOperand.bind({}, pieceId, undefined)) : undefined;
            leftOperandText = !(piece.leftOperand && isUUID(piece.leftOperand)) ? piece.leftOperand : undefined;

            rightOperandPiece = piece.rightOperand && isUUID(piece.rightOperand) ? getInnerComponent(piece.rightOperand, flowchartPieceActions.setRightOperand.bind({}, pieceId, undefined)) : undefined;
            rightOperandText = !(piece.rightOperand && isUUID(piece.rightOperand)) ? piece.rightOperand : undefined;

            return <PickFirstNElementsOperator
                pieceId={pieceId}
                leftOperandPiece={leftOperandPiece}
                leftOperandText={leftOperandText}

                rightOperandPiece={rightOperandPiece}
                rightOperandText={rightOperandText}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.PICK_LAST_ELEMENT:
            operandPiece = piece.operand && isUUID(piece.operand) ? getInnerComponent(piece.operand, flowchartPieceActions.setOperand.bind({}, pieceId, undefined)) : undefined;

            return <PickLastElementOperator
                pieceId={pieceId}
                operandPiece={operandPiece}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.PICK_LAST_N_ELEMENTS:
            leftOperandPiece = piece.leftOperand && isUUID(piece.leftOperand) ? getInnerComponent(piece.leftOperand, flowchartPieceActions.setLeftOperand.bind({}, pieceId, undefined)) : undefined;
            leftOperandText = !(piece.leftOperand && isUUID(piece.leftOperand)) ? piece.leftOperand : undefined;

            rightOperandPiece = piece.rightOperand && isUUID(piece.rightOperand) ? getInnerComponent(piece.rightOperand, flowchartPieceActions.setRightOperand.bind({}, pieceId, undefined)) : undefined;
            rightOperandText = !(piece.rightOperand && isUUID(piece.rightOperand)) ? piece.rightOperand : undefined;

            return <PickLastNElementsOperator
                pieceId={pieceId}
                leftOperandPiece={leftOperandPiece}
                leftOperandText={leftOperandText}

                rightOperandPiece={rightOperandPiece}
                rightOperandText={rightOperandText}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.PICK_NTH_ELEMENT:
            leftOperandPiece = piece.leftOperand && isUUID(piece.leftOperand) ? getInnerComponent(piece.leftOperand, flowchartPieceActions.setLeftOperand.bind({}, pieceId, undefined)) : undefined;
            leftOperandText = !(piece.leftOperand && isUUID(piece.leftOperand)) ? piece.leftOperand : undefined;

            rightOperandPiece = piece.rightOperand && isUUID(piece.rightOperand) ? getInnerComponent(piece.rightOperand, flowchartPieceActions.setRightOperand.bind({}, pieceId, undefined)) : undefined;
            rightOperandText = !(piece.rightOperand && isUUID(piece.rightOperand)) ? piece.rightOperand : undefined;

            return <PickNthElementOperator
                pieceId={pieceId}
                leftOperandPiece={leftOperandPiece}
                leftOperandText={leftOperandText}

                rightOperandPiece={rightOperandPiece}
                rightOperandText={rightOperandText}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.SPLIT_BY_SEPARATOR:
            leftOperandPiece = piece.leftOperand && isUUID(piece.leftOperand) ? getInnerComponent(piece.leftOperand, flowchartPieceActions.setLeftOperand.bind({}, pieceId, undefined)) : undefined;
            leftOperandText = !(piece.leftOperand && isUUID(piece.leftOperand)) ? piece.leftOperand : undefined;

            rightOperandPiece = piece.rightOperand && isUUID(piece.rightOperand) ? getInnerComponent(piece.rightOperand, flowchartPieceActions.setRightOperand.bind({}, pieceId, undefined)) : undefined;
            rightOperandText = !(piece.rightOperand && isUUID(piece.rightOperand)) ? piece.rightOperand : undefined;

            return <SplitBySeparatorOperator
                pieceId={pieceId}
                leftOperandPiece={leftOperandPiece}
                leftOperandText={leftOperandText}

                rightOperandPiece={rightOperandPiece}
                rightOperandText={rightOperandText}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.LENGTH:
            operandPiece = piece.operand && isUUID(piece.operand) ? getInnerComponent(piece.operand, flowchartPieceActions.setOperand.bind({}, pieceId, undefined)) : undefined;

            return <LengthOperator
                pieceId={pieceId}
                operandPiece={operandPiece}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.TRANSLATE:
            operandPiece = piece.operand && isUUID(piece.operand) ? getInnerComponent(piece.operand, flowchartPieceActions.setLeftOperand.bind({}, pieceId, undefined)) : undefined;
            operandText = !(piece.operand && isUUID(piece.operand)) ? piece.operand : undefined;

            return <TranslateOperator
                pieceId={pieceId}
                operandPiece={operandPiece}
                operandText={operandText}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.SET_VARIABLE:
            const variables = variableIds.map(variableId => {
                return {
                    name: variablesState.byId[variableId].name,
                    value: variableId,
                };
            });
            const setNextPiece = piece.nextPiece ? getInnerComponent(piece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
            const setVariablePiece = piece.dataToSet && isUUID(piece.dataToSet) ? getInnerComponent(piece.dataToSet, flowchartPieceActions.setDataSetValue.bind({}, pieceId, undefined)) : undefined;
            const setDataText = !(piece.dataToSet && isUUID(piece.dataToSet)) ? piece.dataToSet : undefined;

            return <SetPiece
                pieceId={pieceId}
                variables={variables}
                nextPiece={setNextPiece}
                settingVariableId={piece.variableToSet}
                variablePiece={setVariablePiece}
                dataToSet={setDataText}
                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                registerVariable={registerVariable}
                initialPosition={initialPosition}
            />

        case PieceType.STYLE_TABLE_SECTION:
            const tableVariables = variableIds
                .filter(variableId => {
                    const variable = variablesState.byId[variableId];
                    return variable.type === VariableType.STYLED_TABLE_CELL ||
                        variable.type === VariableType.STYLED_TABLE_ROW ||
                        variable.type === VariableType.STYLED_TABLE_DATA;
                })
                .map(variableId => {
                    return {
                        name: variablesState.byId[variableId].name,
                        value: variableId,
                    };
                });

            const styleTableSectionNextPiece = piece.nextPiece ? getInnerComponent(piece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;

            return <StyleTableSectionPiece
                pieceId={pieceId}

                settingVariableId={piece.variablePiece}
                variables={tableVariables}

                isBold={piece.isBold}
                isItalic={piece.isItalic}
                backgroundColor={piece.backgroundColor}
                fontColor={piece.fontColor}
                fontSize={piece.fontSize}

                nextPiece={styleTableSectionNextPiece}
                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                registerVariable={registerVariable}
                initialPosition={initialPosition}
            />

        case PieceType.FORMAT:
            const formatVariablePiece = piece.variablePiece && isUUID(piece.variablePiece) ? getInnerComponent(piece.variablePiece, flowchartPieceActions.setVariablePiece.bind({}, pieceId, undefined)) : undefined;
            const formatVariableType = piece.variablePiece && isUUID(piece.variablePiece) ? getPieceValueType(piece.variablePiece, piecesState, variablesState) : undefined;

            return <FormatPiece
                pieceId={pieceId}
                variablePiece={formatVariablePiece}
                variableType={formatVariableType}
                formatModifier={piece.formatModifier}
                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.NEW_DATE:
            const newDateVariables = variableIds.map(variableId => {
                return {
                    name: variablesState.byId[variableId].name,
                    value: variableId,
                };
            });

            const newDateNextPiece = piece.nextPiece ? getInnerComponent(piece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;

            const setYearVariablePiece = piece.yearVariablePiece && isUUID(piece.yearVariablePiece) ? getInnerComponent(piece.yearVariablePiece, flowchartPieceActions.setYearPiece.bind({}, pieceId, undefined)) : undefined;
            const setYearText = !(piece.yearVariablePiece && isUUID(piece.yearVariablePiece)) ? piece.yearVariablePiece : undefined;

            const setMonthVariablePiece = piece.monthVariablePiece && isUUID(piece.monthVariablePiece) ? getInnerComponent(piece.monthVariablePiece, flowchartPieceActions.setMonthPiece.bind({}, pieceId, undefined)) : undefined;
            const setMonthText = !(piece.monthVariablePiece && isUUID(piece.monthVariablePiece)) ? piece.monthVariablePiece : undefined;

            const setDateVariablePiece = piece.dateVariablePiece && isUUID(piece.dateVariablePiece) ? getInnerComponent(piece.dateVariablePiece, flowchartPieceActions.setDatePiece.bind({}, pieceId, undefined)) : undefined;
            const setDateText = !(piece.dateVariablePiece && isUUID(piece.dateVariablePiece)) ? piece.dateVariablePiece : undefined;

            const newDateVariableText = piece.variablePiece ? variablesState.byId[piece.variablePiece].name : undefined;

            return <NewDatePiece
                pieceId={pieceId}
                nextPiece={newDateNextPiece}
                variables={newDateVariables}
                dateVariableName={newDateVariableText}

                yearVariablePiece={setYearVariablePiece}
                yearVariableText={setYearText}
                monthVariablePiece={setMonthVariablePiece}
                monthVariableText={setMonthText}
                dateVariablePiece={setDateVariablePiece}
                dateVariableText={setDateText}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.TODAY:

            return <ConstantPiece
                pieceId={pieceId}

                constantName="TODAY"

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.NOW:

            return <ConstantPiece
                pieceId={pieceId}

                constantName="NOW"

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.TRUE:

            return <ConstantPiece
                pieceId={pieceId}

                constantName="TRUE"

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.HEXAGONAL_TRUE:

            return <ConstantPiece
                pieceId={pieceId}

                constantName="TRUE"
                isBoolean={true}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.FALSE:

            return <ConstantPiece
                pieceId={pieceId}

                constantName="FALSE"

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.HEXAGONAL_FALSE:

            return <ConstantPiece
                pieceId={pieceId}

                constantName="FALSE"
                isBoolean={true}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.LOGGED_IN_USER:

            return <ConstantPiece
                pieceId={pieceId}

                constantName="LOGGED IN USER"

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.ANSWER:

            return <ConstantPiece
                pieceId={pieceId}

                constantName="ANSWER"

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.MY_GROUPS:

            return <ConstantPiece
                pieceId={pieceId}

                constantName="MY GROUPS"

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.MY_MEMBERS:

            return <ConstantPiece
                pieceId={pieceId}

                constantName="MY MEMBERS"

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.FINANCIAL_YEAR_MONTHS:

            return <ConstantPiece
                pieceId={pieceId}

                constantName="FINANCIAL YEAR MONTHS"

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.GET_ENTITIES:
            const getEntitiesVariablePiece = piece.variablePiece && isUUID(piece.variablePiece) ? getInnerComponent(piece.variablePiece, flowchartPieceActions.setVariablePiece.bind({}, pieceId, undefined)) : undefined;

            return <GetEntitiesPiece
                pieceId={pieceId}

                entity={piece.entity}
                entityType={piece.entityType}
                locationPiece={getEntitiesVariablePiece}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />


        default:
            return <div></div>;
    }
}