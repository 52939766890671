import { ADD_REPORT_SCHEDULE, UPDATE_REPORT_SCHEDULE, DELETE_REPORT_SCHEDULE, IUpdateableReportScheduleData, AddReportScheduleAction, SELECT_REPORT_SCHEDULE, UN_SELECT_REPORT_SCHEDULE, RE_ORDER_REPORT_SCHEDULES, INewReportScheduleData, UpdateReportScheduleAction, DeleteReportScheduleAction, IReportSchedule, InstantiateReportScheduleAction, INSTANTIATE_REPORT_SCHEDULE } from './types';
import moment from 'moment';
import uuid from 'uuid';

export function selectReportSchedule(id: string) {
    return {
        type: SELECT_REPORT_SCHEDULE,
        id,
    }
}

export function unSelectReportSchedule() {
    return {
        type: UN_SELECT_REPORT_SCHEDULE,
    }
}

export function reOrderReportSchedules(sourceIndex: number, destinationIndex: number) {
    return {
        type: RE_ORDER_REPORT_SCHEDULES,
        sourceIndex,
        destinationIndex,
    }
}

export function addReportSchedule(payload: INewReportScheduleData): AddReportScheduleAction {

    const now = moment.utc().format();

    return {
        type: ADD_REPORT_SCHEDULE,
        payload: {
            id: uuid.v4(),
            createdTime: now,
            lastUpdatedTime: now,
            ...payload,
        },
        currentTime: now,
    }
}

export function instantiateReportSchedule(payload: IReportSchedule): InstantiateReportScheduleAction {

    const now = moment.utc().format();

    return {
        type: INSTANTIATE_REPORT_SCHEDULE,
        payload,
        currentTime: now,
    }
}

export function updateReportSchedule(payload: IUpdateableReportScheduleData): UpdateReportScheduleAction {
    return {
        type: UPDATE_REPORT_SCHEDULE,
        payload,
        currentTime: moment.utc().format(),
    }
}

export function deleteReportSchedule(id: string): DeleteReportScheduleAction {
    return {
        type: DELETE_REPORT_SCHEDULE,
        id,
        currentTime: moment.utc().format(),
    }
}