import React from 'react';
import styles from './LoaderModal.module.scss';
import { ReactComponent as CheckIcon } from '../../assets/new-custom-icons/profile/check-mark.svg';
import { ReactComponent as CloseIcon } from '../../assets/action-icons/cancel.svg';
import { ReactComponent as AlertIcon } from '../../assets/action-icons/exclamation.svg';
import { translatePhrase } from '../../shared/helpers/translation';

type OwnProps = {
    confirmText: string,
    confirm: () => void,
    cancel: () => void,
};


const ConfirmModal: React.FC<OwnProps> = (props) => {

    return <div data-selector="Loader Modal" className={styles.backDrop + ' ' + styles.active + ' ignore-react-onclickoutside'}>
        <section className={styles.modal}>
            <header>
                <div className={styles.iconContainer}>
                    <AlertIcon />
                </div>
                <h3> {props.confirmText} </h3>
            </header>

            <section className={styles.confirmationBoxWithText}>
                <button onClick={props.confirm}>
                    <CheckIcon />
                    <span>{translatePhrase('Yes')}</span>
                </button>
                <button onClick={props.cancel}>
                    <CloseIcon />
                    <span>{translatePhrase('No')}</span>
                </button>
            </section>

        </section>
    </div>;
}


export default ConfirmModal;