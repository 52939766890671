import React from 'react';
import styles from './Timeline.module.scss';

import { connect } from 'react-redux';

import { getReadableDataForCustomField } from '../../../shared/store/custom-fields';

import { ApplicationState } from '../../../shared/store/types';
import moment from 'moment';
import { isWebUri } from 'valid-url';
import { translatePhrase } from '../../../shared/helpers/translation';
import { DefaultFlowchartProcessState } from '../../../shared/store/flowchart/types';
import { getMemberComputedFieldValue } from '../../../shared/store/flowchart/helpers/custom-fields/member';
import { ReactComponent as TransferIcon } from '../../../assets/transfer.svg';
import { ReactComponent as SortIcon } from '../../../assets/action-icons/sort.svg';
import { ReactComponent as TimeLineIcon } from '../../../common/assets/timeline.svg';
import { ReactComponent as ExpandIcon } from '../../../common/assets/expand.svg';
import { ReactComponent as ShrinkIcon } from '../../../common/assets/shrink.svg';
import { ReactComponent as FlowIcon } from '../../../common/assets/flow-data.svg';
import Button from '../../../widgets/button/CommonButton';
import { VariableValueType } from '../../../shared/helpers/common-types';
import { WorkflowTypeCustomField, CustomFieldValueType, FieldType, CustomFieldDataForIndividualMembers } from '../../../shared/store/custom-fields/types';
import { getStoredFileInDB } from '../../../shared/store/file-operations';
import { IWorkflow, WorkflowProcessStep } from '../../../shared/store/workflows/types';
import axios from 'axios';
import { BASE_URL } from '../../../shared/store/url';

import { getOnlineFileSize, updateLinkToCurrentOrigin } from '../../../shared/helpers/file-utilities';
import TimeLineFieldList from './TimeLineFieldList';
import { completionPercentageOfWorkflow } from '../../../shared/store/flowchart/helpers/progress';
import LoaderModal from '../../../widgets/loader/LoaderModal';

type OwnProps = {
    workflowId: string,
    shrinkTimeline: boolean,
    isSortActive: boolean,
    toggleShrinkTimeline?: () => void,
    toggleSortTimeline?: () => void,
};

export type Option = {
    name: string;
    oldValue: string;
    value: string;
    type: string;
    fileSize?: number;
}

export interface UserEntry {
    userId: string,
    time: string,
    normalEntries: Array<Option>,
    transferedUser: string,
    perMemberEntries: Array<{
        name: string,
        subTitle: string,
        value: Array<Option>,
    }>
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    const workflow = state.workflows.byId[ownProps.workflowId];
    const workflowType = state.workflows.types.byId[workflow.type];
    const processState = workflow.history[workflow.historyIndex];
    const isWorkflowCompleted = state.workflows.types.statuses.byId[workflow.status].isTerminal;
    const isMyWorkflow = workflow.user === state.myData.id;

    return {
        workflow,
        processState,
        workflowType,
        workflowData: state.workflows,
        membersData: state.members,
        groupsData: state.groups,
        optionsData: state.workflows.types.customFieldOptions,

        applicationState: state,
        isWorkflowCompleted,
        isMyWorkflow
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

type OwnState = {
    token: string;
    userEntries: Array<UserEntry>;
    hasFetchedLatestHistory: boolean;
    history: Array<WorkflowProcessStep>;
    loaderModal: JSX.Element | undefined;
}

class ConnectedWorkflowData extends React.Component<Props, OwnState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            token: localStorage.getItem('token') || '',
            userEntries: [],
            hasFetchedLatestHistory: false,
            history: props.workflow.history,
            loaderModal: undefined
        };
    }

    getUserName(userId: string) {
        try {
            const user = this.props.applicationState.users.byId[userId];
            let userName: VariableValueType;

            const nameField = this.props.applicationState.users.customFields.byId[this.props.applicationState.users.nameFieldId];

            if (nameField.isComputed && typeof nameField.startPiece !== 'undefined') {
                const processState: DefaultFlowchartProcessState = {
                    displayingContinuePieceId: undefined,
                    displayingAddWorkflowPieceId: undefined,
                    customFields: { ...user.customFields },
                    variables: {
                        [nameField.seedEntityVariable]: user.id,
                    },
                    lastComputedPiece: undefined,
                    executionStack: [],
                    forIterationCounts: {},
                    displayingQuestionPieceId: undefined,
                    displayingShowPieceId: undefined,
                    displayingGroupPieceId: undefined,
                    displayingTransferPieceId: undefined,
                    createdWorkflowId: undefined,
                    displayingFinsalLoanProcessPieceId: undefined,
                };

                userName = getMemberComputedFieldValue(this.props.applicationState, processState, nameField.startPiece.piece, user.id, nameField);
            } else {
                userName = user.customFields[this.props.applicationState.users.nameFieldId];
            }

            if (Array.isArray(userName)) {

                if (userName.length > 0 && Array.isArray(userName[0])) {
                    // Cannot be a multidimensional array
                    throw new Error('The value cannot be a multi-dimensional array')
                }

                userName = userName as Array<string>;
            }

            userName = getReadableDataForCustomField(userName, this.props.applicationState.users.customFields.byId[this.props.applicationState.users.nameFieldId], user.id, 'user');

            return userName;
        } catch {
            return '-';
        }
    }

    getMemberName(memberId: string) {
        const member = this.props.membersData.byId[memberId];
        const memberType = this.props.membersData.types.byId[member.type];
        let memberName: VariableValueType;

        const nameField = this.props.membersData.types.customFields.byId[memberType.nameFieldId];

        if (nameField.isComputed && typeof nameField.startPiece !== 'undefined') {
            const processState: DefaultFlowchartProcessState = {
                displayingContinuePieceId: undefined,
                displayingAddWorkflowPieceId: undefined,
                customFields: { ...member.customFields },
                variables: {
                    [nameField.seedEntityVariable]: member.id,
                },
                lastComputedPiece: undefined,
                executionStack: [],
                forIterationCounts: {},
                displayingQuestionPieceId: undefined,
                displayingShowPieceId: undefined,
                displayingGroupPieceId: undefined,
                displayingTransferPieceId: undefined,
                createdWorkflowId: undefined,
                displayingFinsalLoanProcessPieceId: undefined,
            };

            memberName = getMemberComputedFieldValue(this.props.applicationState, processState, nameField.startPiece.piece, member.id, nameField);
        } else {
            memberName = member.customFields[memberType.nameFieldId];
        }

        if (Array.isArray(memberName)) {

            if (memberName.length > 0 && Array.isArray(memberName[0])) {
                // Cannot be a multidimensional array
                throw new Error('The value cannot be a multi-dimensional array')
            }

            memberName = memberName as Array<string>;
        }

        memberName = getReadableDataForCustomField(memberName, nameField, member.id, 'member');

        return memberName;
    }

    getMemberSubtitle(memberId: string) {
        try {
            const member = this.props.membersData.byId[memberId];
            const memberType = this.props.membersData.types.byId[member.type];
            let memberSubtitle: VariableValueType;

            const subTitleField = this.props.membersData.types.customFields.byId[memberType.subTitleFieldId];

            if (subTitleField.isComputed && typeof subTitleField.startPiece !== 'undefined') {
                const processState: DefaultFlowchartProcessState = {
                    displayingContinuePieceId: undefined,
                    displayingAddWorkflowPieceId: undefined,
                    customFields: { ...member.customFields },
                    variables: {
                        [subTitleField.seedEntityVariable]: member.id,
                    },
                    lastComputedPiece: undefined,
                    executionStack: [],
                    forIterationCounts: {},
                    displayingQuestionPieceId: undefined,
                    displayingShowPieceId: undefined,
                    displayingGroupPieceId: undefined,
                    displayingTransferPieceId: undefined,
                    createdWorkflowId: undefined,
                    displayingFinsalLoanProcessPieceId: undefined,
                };

                memberSubtitle = getMemberComputedFieldValue(this.props.applicationState, processState, subTitleField.startPiece.piece, member.id, subTitleField);
            } else {
                memberSubtitle = member.customFields[memberType.nameFieldId];
            }

            if (Array.isArray(memberSubtitle)) {

                if (memberSubtitle.length > 0 && Array.isArray(memberSubtitle[0])) {
                    // Cannot be a multidimensional array
                    throw new Error('The value cannot be a multi-dimensional array')
                }

                memberSubtitle = memberSubtitle as Array<string>;
            }

            memberSubtitle = getReadableDataForCustomField(memberSubtitle, subTitleField, member.id, 'member');

            return memberSubtitle;
        } catch {
            return '-';
        }
    }

    getLatestHistory = async () => {
        try {
            const detailsUrl = BASE_URL + '/workflow-details/'
            const groupsDetailsResponse = await axios.post<IWorkflow>(detailsUrl, this.props.workflow, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                }
            });

            if (groupsDetailsResponse.data) {
                this.setState({
                    history: groupsDetailsResponse.data.history,
                    hasFetchedLatestHistory: true,
                    loaderModal: undefined
                }, () => {
                    this.updateUserEntries();
                });
            }
        } catch (error) {
            console.log(error);
            const loaderModal = <LoaderModal
                loaderText={[translatePhrase('Please check your internet')]}
                isError
                isIndeterminate
                shouldAllowRetry={true}
                retryCallback={this.getLatestHistory}
                closeModal={() => this.setState({ loaderModal: undefined })}
            />
            this.setState({
                loaderModal
            })
        }
    }

    getCompletionPercentage = () => {
        let completionPercentage = 0;

        try {
            completionPercentage = completionPercentageOfWorkflow(this.props.workflow.id);

            if (!this.props.isWorkflowCompleted && completionPercentage > 96) {
                completionPercentage = 96;
            }

            completionPercentage = Math.round(completionPercentage / 5) * 5;
        } catch (e) {
            console.error(e);
        };

        return completionPercentage;
    }

    updateUserEntries = async () => {
        let userEntries: Array<UserEntry> = [];

        let normalCustomFields: Array<WorkflowTypeCustomField> = [];
        let perMemberCustomFields: Array<WorkflowTypeCustomField> = [];

        const allCustomFields = this.props.workflowType.customFields.map(customFieldId => this.props.workflowData.types.customFields.byId[customFieldId]);

        if (this.state.history) for (const workflowProcessState of this.state.history) {
            let normalEntries = [],
                perMemberEntries = [];

            const lastUserEntry = userEntries.length > 0 ? userEntries[userEntries.length - 1] : undefined;

            let transferredUserId = '';

            if (lastUserEntry) {
                if (lastUserEntry.userId !== workflowProcessState.executingUser) {
                    transferredUserId = lastUserEntry.userId;
                }
            }

            if (this.props.workflowType.affiliation === 'group') {
                normalCustomFields = allCustomFields.filter(customField => customField.affiliation === 'group');
                perMemberCustomFields = allCustomFields.filter(customField => customField.affiliation === 'member');
            } else {
                normalCustomFields = allCustomFields.slice(0);
            }

            for (const customField of normalCustomFields) {
                let customFieldValue = workflowProcessState.customFields[customField.id];

                if (!Array.isArray(customFieldValue) && customFieldValue !== null && typeof customFieldValue === 'object') {
                    throw new Error('There should not be any custom fields for individual members in normal entries');
                }

                let readableCustomFieldValue = getReadableDataForCustomField(customFieldValue as CustomFieldValueType, customField, this.props.workflow.id, 'workflow');
                const oldValue = readableCustomFieldValue;
                let fileSize = undefined;

                if (customField.type === FieldType.SINGLE_SELECT) {
                    readableCustomFieldValue = translatePhrase(readableCustomFieldValue);
                } else if (customField.type === FieldType.MULTI_SELECT) {
                    readableCustomFieldValue = readableCustomFieldValue.split(',').map((value: string) => translatePhrase(value.trim())).join(', ');
                } else if (customField.type === FieldType.FILE) {
                    if (!isWebUri(readableCustomFieldValue) && !readableCustomFieldValue.startsWith("/file-f123f-")) {
                        const savedFile = await getStoredFileInDB(readableCustomFieldValue);
                        if (savedFile?.file?.name) {
                            readableCustomFieldValue = savedFile.file.name;
                        }
                        if (savedFile?.file?.size) {
                            fileSize = savedFile.file.size;
                        }
                    } else if (isWebUri(readableCustomFieldValue)) {
                        fileSize = await getOnlineFileSize(readableCustomFieldValue);
                    } else if (readableCustomFieldValue.startsWith("/file-f123f-")) {
                        fileSize = await getOnlineFileSize(readableCustomFieldValue);
                    }
                };

                let hasValueChanged = true;
                const customFieldName = translatePhrase(customField.name);

                let lastCustomFieldEntry: Option | undefined;

                for (const userEntry of userEntries) {
                    if (!lastCustomFieldEntry) {
                        lastCustomFieldEntry = userEntry.normalEntries.find(normalEntry => normalEntry.name === customFieldName);
                    }
                }

                if (lastCustomFieldEntry && lastCustomFieldEntry.value === readableCustomFieldValue) {
                    hasValueChanged = false;
                }

                if (hasValueChanged && readableCustomFieldValue.trim() !== '-') {
                    normalEntries.push({
                        name: translatePhrase(customField.name),
                        oldValue,
                        value: readableCustomFieldValue,
                        type: customField.type,
                        fileSize: fileSize,
                    });
                }

            }

            if (this.props.workflowType.affiliation === 'group') {
                const group = this.props.groupsData.byId[this.props.workflow.affiliatedEntity];

                for (const memberId of group.members) {
                    if (memberId in this.props.membersData.byId) {
                        const memberName = this.getMemberName(memberId);
                        const memberSubTitle = this.getMemberSubtitle(memberId);

                        let tableValues: Array<Option> = [];

                        for (const customField of perMemberCustomFields) {
                            let customFieldValue = workflowProcessState.customFields[customField.id] as CustomFieldDataForIndividualMembers;

                            if (typeof customFieldValue === 'undefined') {
                                customFieldValue = {};
                            }

                            if (typeof customFieldValue !== 'object') {
                                throw new Error('There should only be custom fields for individual members in per-member entries');
                            }

                            if (customField.isComputed && customField.startPiece) {
                                throw new Error('You cannot have computed fields for per-member fields');
                            } else {

                                if (typeof customFieldValue === 'undefined') {
                                    customFieldValue = {};
                                }

                                if (typeof customFieldValue !== 'object') {
                                    throw new Error('There should only be custom fields for individual members in per-member entries');
                                }

                                let hasValueChanged = true;
                                const customFieldName = translatePhrase(customField.name);

                                let lastCustomFieldEntry: Option | undefined;

                                for (const userEntry of userEntries) {
                                    if (!lastCustomFieldEntry) {
                                        const memberEntry = userEntry.perMemberEntries.find(memberEntry => memberEntry.name === memberName && memberEntry.subTitle === memberSubTitle);
                                        if (!!memberEntry) {
                                            lastCustomFieldEntry = memberEntry.value.find(normalEntry => normalEntry.name === customFieldName);
                                        }
                                    }
                                }

                                let readableCustomFieldValue = getReadableDataForCustomField(customFieldValue[memberId], customField, this.props.workflow.id, 'workflow');
                                let oldValue = readableCustomFieldValue;
                                let fileSize = undefined;

                                if (customField.type === FieldType.SINGLE_SELECT) {
                                    readableCustomFieldValue = translatePhrase(readableCustomFieldValue);
                                } else if (customField.type === FieldType.MULTI_SELECT) {
                                    readableCustomFieldValue = readableCustomFieldValue.split(',').map((value: string) => translatePhrase(value.trim())).join(', ');
                                } else if (customField.type === FieldType.FILE) {
                                    if (!isWebUri(readableCustomFieldValue)) {
                                        const savedFile = await getStoredFileInDB(readableCustomFieldValue);

                                        if (savedFile?.file?.name) {
                                            readableCustomFieldValue = savedFile.file.name;
                                        }

                                        if (savedFile?.file?.size) {
                                            fileSize = savedFile.file.size;
                                        }
                                    } else if (isWebUri(readableCustomFieldValue)) {
                                        fileSize = await getOnlineFileSize(readableCustomFieldValue);
                                    }
                                }

                                if (lastCustomFieldEntry && lastCustomFieldEntry.value === readableCustomFieldValue) {
                                    hasValueChanged = false;
                                }

                                if (hasValueChanged && readableCustomFieldValue !== '-') {
                                    tableValues.push({
                                        name: customFieldName,
                                        value: readableCustomFieldValue,
                                        oldValue,
                                        type: customField.type,
                                        fileSize: fileSize,
                                    })
                                }
                            }
                        }

                        if (tableValues.length > 0) {
                            perMemberEntries.push({
                                name: memberName,
                                subTitle: memberSubTitle,
                                value: tableValues,
                            });
                        }
                    }
                }
            }

            userEntries.push({
                userId: workflowProcessState.executingUser,
                time: lastUserEntry && transferredUserId ? lastUserEntry.time : workflowProcessState.executionTime,
                transferedUser: transferredUserId,
                normalEntries,
                perMemberEntries
            });
        }

        if (userEntries.length > 0 && userEntries[userEntries.length - 1].userId !== this.props.workflow.user) {
            userEntries.push({
                userId: userEntries[userEntries.length - 1].userId,
                time: userEntries[userEntries.length - 1].time,
                transferedUser: this.props.workflow.user,
                normalEntries: [],
                perMemberEntries: [],
            })
        }

        const filteredEntries = userEntries.filter(userEntry => {
            return userEntry.normalEntries.length > 0 || userEntry.perMemberEntries.length > 0 || userEntry.transferedUser;
        });

        this.setState({
            userEntries: filteredEntries,
        });
    }

    async componentDidMount() {
        const newWorkflow = this.props.applicationState.workflows.createdIds.has(this.props.workflowId);
        const updatedWorkflow = this.props.applicationState.workflows.updatedIds.has(this.props.workflowId);

        const completionPercentage = this.getCompletionPercentage();

        if (!newWorkflow && !updatedWorkflow && (completionPercentage === 100 || !this.props.isMyWorkflow)) {
            await this.getLatestHistory();
        } else {
            this.setState({
                hasFetchedLatestHistory: true
            }, () => {
                this.updateUserEntries();
            })
        }
    }

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<OwnState>, snapshot?: any) {
        if (prevProps.workflow.historyIndex !== this.props.workflow.historyIndex) {
            this.updateUserEntries();
        }
    }

    render() {
        let userEntries: Array<UserEntry> = this.props.isSortActive ? this.state.userEntries.slice().reverse() : this.state.userEntries.slice();

        return <ul className={styles.reportTimeline + ' ' + (this.props.shrinkTimeline ? styles.deflate : '')}>

            {this.state.loaderModal}

            <header className={styles.timelineHeader}>
                <TimeLineIcon className={styles.headingIcon} />

                <div className={styles.heading}>
                    <h2> {translatePhrase('Timeline')} </h2>
                    <p> {userEntries.length} {userEntries.length > 1 ? translatePhrase('Activities') : translatePhrase('Activity')} </p>
                </div>

                <Button title={translatePhrase("Sort")} icon={<SortIcon />} size={'small'} isRounded type={this.props.isSortActive ? 'primary' : 'secondary'} onClick={this.props.toggleSortTimeline} />

                {!this.state.hasFetchedLatestHistory && <span className={styles.shrinkButton}>
                    <Button title={translatePhrase("Fetch full timeline")} icon={<FlowIcon />} size={'small'} isRounded type={'primary'} onClick={this.getLatestHistory} />
                </span>}

                <span className={styles.shrinkButton}>
                    <Button title={this.props.shrinkTimeline ? translatePhrase("Expand") : translatePhrase("Collapse")} icon={this.props.shrinkTimeline ? <ExpandIcon /> : <ShrinkIcon />} type={!this.props.shrinkTimeline ? 'primary' : 'secondary'} size={'small'} isRounded onClick={this.props.toggleShrinkTimeline} />
                </span>
            </header>

            <div className={styles.dataContainer}>

                {userEntries
                    .map((userEntry, index) => {
                        return <li className={userEntry.transferedUser ? styles.transfered : ''} key={index}>

                            {!this.props.isSortActive && <div className={styles.count}>
                                {(index + 1).toString().padStart(2, '0')}
                            </div>}

                            {this.props.isSortActive && <div className={styles.count}>
                                {(userEntries.length - index).toString().padStart(2, '0')}
                            </div>}

                            {userEntry.transferedUser &&
                                <div className={styles.transferDataHolder}>
                                    <p> {this.getUserName(userEntry.transferedUser)}  </p>

                                    <div className={styles.iconHolder}>
                                        <TransferIcon />
                                    </div>

                                    <p> {this.getUserName(userEntry.userId)}  </p>
                                </div>
                            }

                            {!this.props.shrinkTimeline && !userEntry.transferedUser && userEntry.normalEntries.length > 0 &&
                                <TimeLineFieldList
                                    userEntry={userEntry}
                                    token={this.state.token}
                                />
                            }

                            {!userEntry.transferedUser && userEntry.perMemberEntries.map((perMemberEntry, index) => {
                                return <section className={styles.fieldList} key={index}>
                                    <header className={styles.memberHeader}>
                                        <h5> {perMemberEntry.name} ({perMemberEntry.subTitle}) </h5>
                                    </header>

                                    {perMemberEntry.value.map((option, optionIndex) => {
                                        return <div className={styles.entry} key={optionIndex}>
                                            <label> {option.name} </label>
                                            <span className={styles.selectedOptions}>
                                                {isWebUri(option.value) || option.value.includes("https://") ? <a className={styles.fileLink} download rel="noopener noreferrer" href={updateLinkToCurrentOrigin(option.value) + '?token=' + this.state.token}>
                                                    {option.value?.split("file-upload/")[1].split("_")[0]}
                                                    {"." + option.value?.split("file-upload/")[1].split("_")[1].split(".")[1]} </a> : <span>{option.value}</span>}
                                            </span>
                                        </div>
                                    })}

                                </section>
                            })}

                            {!this.props.shrinkTimeline && <header className={styles.userHeader}>
                                <p>
                                    {moment(userEntry.time).format('D MMM YYYY, hh:mm:ss A')}
                                </p>
                                <h5>
                                    {this.getUserName(userEntry.userId)}
                                </h5>
                            </header>}

                            {this.props.shrinkTimeline && <header className={styles.userHeader}>
                                <h5>
                                    {this.getUserName(userEntry.userId)}
                                </h5>
                                <p>
                                    {moment(userEntry.time).format('D MMM YY')}
                                </p>
                            </header>}
                        </li>
                    })}
            </div>
        </ul>
    }
}

const WorkflowData = connect(mapStateToProps)(ConnectedWorkflowData);

export default WorkflowData;