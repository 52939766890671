import { useParams } from "react-router";
import { DataPackageDetails } from "./DataPackageDetails";
import { WorkflowPackageDetails } from "./WorkflowPackageDetails";

interface PackageDetailParameters {
    packageType: string;
    packageId: string;
}

export function PackageDetails() {
    const { packageId, packageType } = useParams<PackageDetailParameters>();

    switch (packageType) {
        case 'workflow':
            return <WorkflowPackageDetails packageId={packageId} />
        default:
            return <DataPackageDetails packageId={packageId} />
    }
}