import { createSelector } from 'reselect';
import { getVisibleMemberIdsSelector } from '../../../helpers/selectors';
import { filterMembers } from '../../../shared/helpers/filters';
import store from '../../../shared/store/main';
import { ApplicationState } from '../../../shared/store/types';
import moment from 'moment';
import { IMember } from '../../../shared/store/members/types';

const getMemberState = (state: ApplicationState) => state.members.byId;
const getSearchTerm = (state: ApplicationState) => state.members.searchTerm;
const getCurrentPageNumber = (state: ApplicationState) => state.members.currentPageNumber;
const getPageSize = (state: ApplicationState) => state.members.pageSize;
const getFilters = (state: ApplicationState) => state.members.filters;

const getMemberTypes = (state: ApplicationState) => state.members.types;
const getLocation = (state: ApplicationState) => state.structure.locations;
const getIsOnline = (state: ApplicationState) => state.myData.isOnline;
const getTotalMembers = (state: ApplicationState) => state.members.totalNoOfMembers;
const getProjects = (state: ApplicationState) => state.structure.projects;

export const getSortedMembers = createSelector(
    [getMemberState, getFilters, getVisibleMemberIdsSelector],
    (memberState, filters, visibleMemberIds) => {
        const visibleMembers = visibleMemberIds.map(memberId => memberState[memberId]);
        const sortedMembers = visibleMembers.sort((a, b) => {
            const aDate = new Date(a.lastUpdatedTime);
            const bDate = new Date(b.lastUpdatedTime);

            return bDate.getTime() - aDate.getTime();
        });
        return sortedMembers;
    }
)

export const getFilteredMembers = createSelector(
    [getSortedMembers, getMemberState, getCurrentPageNumber, getPageSize, getSearchTerm, getIsOnline, getFilters],
    (sortedMembers, memberById, currentPageNumber, pageSize, searchTerm, isOnline, filters) => {
        const state = store.getState();
        let filteredMembers: Array<IMember> = [];
        if (isOnline) {
            if (filters.archived) {
                filteredMembers = Object.keys(memberById).map(memberId => memberById[memberId]).filter(member => member.archived);
            } else {
                filteredMembers = state.members.allEntries.map(memberId => memberById[memberId])
            }
        } else {
            filteredMembers = filterMembers(state, sortedMembers.map(member => member.id));
        }
        return filteredMembers;

    }
)

export const getTotalNoOfMembers = createSelector(
    [getIsOnline, getFilteredMembers, getTotalMembers],
    (isOnline, filteredMembers, totalMembers) => {
        return !isOnline ? filteredMembers.length : totalMembers;
    }
)

export const getTags = createSelector(
    [getFilters, getMemberTypes, getLocation, getTotalNoOfMembers, getProjects], 
    (filters, memberTypes, locations, totalNoOfMembers, projects) => {
        const tags: Array<string> = [];

        if (filters.projects.length > 0) {
            tags.push('Projects: ' + filters.projects.map(projectId => projects.byId[projectId].name).join(', '));
        }    

        if (filters.types.length > 0) {
            tags.push('Types: ' + filters.types.map(typeId => memberTypes.byId[typeId].name).join(', '));
        }

        if (filters.locations.length > 0) {
            tags.push('Locations: ' + filters.locations.map(locationId => locations.byId[locationId].name).join(', ') + ' and below');
        }

        if (filters.unsynced) {
            tags.push('Unsynced');
        }

        if (filters.archived) {
            tags.push('Archived');
        }
        
        if (filters.createdDateRange.length > 1) {
            tags.push(`Created date range: ${moment(filters.createdDateRange[0]).format('DD MMM YYYY')} - ${moment(filters.createdDateRange[1]).format('DD MMM YYYY')}`);
        }

        if (filters.lastUpdatedDateRange.length > 1) {
            tags.push(`Last updated date range: ${moment(filters.lastUpdatedDateRange[0]).format('DD MMM YYYY')} - ${moment(filters.lastUpdatedDateRange[1]).format('DD MMM YYYY')}`);
        }

        if (filters.types.length === 1) {
            for (const customFieldId in filters.customFields) {
                const customField = memberTypes.customFields.byId[customFieldId];
                const selectedOptions = filters.customFields[customFieldId].map(optionId => memberTypes.customFieldOptions.byId[optionId]);
    
                tags.push(`${customField.name}: ${selectedOptions.map(option => option.name).join(', ')}`);
            }
        }

        if (tags.length > 0) {
            tags.push('Count: ' + totalNoOfMembers);
        }

        return tags;
});

