import React, { Component } from 'react';
import styles from './GroupFilter.module.scss';

import { ReactComponent as CancelIcon } from '../../../common/assets/close.svg';
import { ReactComponent as SmartFilterIcon } from '../../../assets/new-custom-icons/dashboard/smartfilter.svg';
import { ReactComponent as FilterIcon } from '../../../common/assets/filter.svg';
import { ReactComponent as PlusIcon } from '../../../assets/new-custom-icons/dashboard/plus.svg';



import { translatePhrase } from '../../../shared/helpers/translation';

import { ApplicationState } from '../../../shared/store/types';

import { filterGroupTable } from '../../../shared/store/groups/actions';

import { connect } from 'react-redux';
import { FieldType } from '../../../shared/store/custom-fields/types';
import { Dispatch } from 'redux';
import InputText from '../../../widgets/form/InputText';
import MultiSelectInputText from '../../../widgets/form/MultiSelectInput';
import { getAncestorChainOfLocation, getLocationsAtLevel } from '../../../shared/helpers/locations';
import moment from 'moment';
import Button from '../../../widgets/button/CommonButton';
import DateInput from '../../../widgets/form/DateInput';
import { GroupFilters } from '../../../shared/store/groups/types';

import onClickOutside from "react-onclickoutside";
import { GroupSmartFilters, SmartFilter } from '../../../shared/helpers/smart-filters';
import LoaderModal from '../../../widgets/loader/LoaderModal';

type OwnProps = {
    closeFilter: () => void,
    tags: Array<string>,
    groupFilters: GroupFilters,
    getSmartFilter?: (smartFilter: string) => void,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    const allAllowedProjects = state.structure.projects.allEntries;

    let allAllowedLocations: Array<string> = []

    allAllowedProjects.forEach(projectId => {
        const projectData = state.structure.projects.byId[projectId];
        const allLevelsIdsInProject = projectData.children.filter(levelId => levelId in state.structure.levels.byId);

        for (const levelId of allLevelsIdsInProject) {
            const locationsAtLevel = getLocationsAtLevel(levelId).map(location => location.id);
            allAllowedLocations = allAllowedLocations.concat(locationsAtLevel);
        }
    });

    return {
        projectsData: state.structure.projects,
        levelsData: state.structure.levels,
        rolesData: state.structure.roles,
        locationsData: state.structure.locations,
        groupsData: state.groups,
        customFieldsData: state.groups.types.customFields,
        customFieldOptionsData: state.groups.types.customFieldOptions,
        roleCustomFieldsData: state.structure.roles.customFields,
        roleCustomFieldOptionsData: state.structure.roles.customFieldOptions,
        allAllowedLocations,
        myId: state.myData.id,

        filters: state.groups.filters,
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        filterGroupTable: (projects: Array<string>, types: Array<string>, locations: Array<string>, customFields: { [customFieldId: string]: Array<string> }, createdDateRange: Array<string>, lastUpdatedDateRange: Array<string>, unsynced: boolean, archived: boolean) => dispatch(filterGroupTable(projects, types, locations, customFields, createdDateRange, lastUpdatedDateRange, unsynced, archived)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;


type OwnState = {
    projects: Array<string>,
    types: Array<string>,
    locations: Array<string>,
    toastLoader: JSX.Element | undefined,

    customFields: {
        [customFieldId: string]: Array<string>,
    },

    createdDateRange: Array<string>,
    lastUpdatedDateRange: Array<string>,

    unsynced: boolean,
    archived: boolean,

    appliedSmartFilter: string,

    clearKey: number,
};

class ConnectedGroupModify extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            projects: props.filters.projects,
            types: props.filters.types,
            locations: props.filters.locations,
            customFields: props.filters.customFields,
            toastLoader: undefined,

            createdDateRange: props.filters.createdDateRange,
            lastUpdatedDateRange: props.filters.lastUpdatedDateRange,

            unsynced: props.filters.unsynced,
            archived: props.filters.archived,
            appliedSmartFilter: '',

            clearKey: 0,
        };
    }

    changeProjects = (projects: Array<string>) => {
        this.setState({
            projects,
            types: [],
            customFields: {},
        });
    }

    changeTypes = (types: Array<string>) => {
        this.setState({
            types,
            customFields: {},
        });
    }

    changeLocations = (locations: Array<string>) => {
        this.setState({
            locations,
        });
    }

    changeUnsynced = (unsynced: boolean) => {
        this.setState({
            unsynced,
        });

        setTimeout(() => {
            this.submitFilters();
        }, 100);
    }

    changeArchived = (archived: boolean) => {


        this.setState({
            archived,
        });

        setTimeout(() => {
            this.submitFilters();
        }, 100);
    }

    changeCustomFieldOptions = (customFieldId: string, options: Array<string>) => {


        const newCustomFields = {
            ...this.state.customFields,
            [customFieldId]: options,
        };

        this.setState({
            customFields: newCustomFields,
        });

        setTimeout(() => {
            this.submitFilters();
        }, 100);
    }

    changeCreatedFromDate = (value: string) => {


        this.setState(prevState => {
            const newDateRange = [value];
            if (prevState.createdDateRange.length > 1) {
                newDateRange.push(prevState.createdDateRange[1]);
            } else {
                newDateRange.push(value);
            }

            return {
                createdDateRange: newDateRange,
            };
        });
    }

    changeCreatedToDate = (value: string) => {
        this.setState(prevState => {
            const newDateRange = [];
            if (prevState.createdDateRange.length > 0) {
                newDateRange.push(prevState.createdDateRange[0]);
            } else {
                newDateRange.push(value);
            }

            newDateRange.push(value);

            return {
                createdDateRange: newDateRange,
            };
        });
    }

    changeLastUpdatedFromDate = (value: string) => {
        this.setState(prevState => {
            const newDateRange = [value];
            if (prevState.lastUpdatedDateRange.length > 1) {
                newDateRange.push(prevState.lastUpdatedDateRange[1]);
            } else {
                newDateRange.push(value);
            }

            return {
                lastUpdatedDateRange: newDateRange,
            };
        });
    }

    changeLastUpdatedToDate = (value: string) => {
        this.setState(prevState => {
            const newDateRange = [];
            if (prevState.lastUpdatedDateRange.length > 0) {
                newDateRange.push(prevState.lastUpdatedDateRange[0]);
            } else {
                newDateRange.push(value);
            }

            newDateRange.push(value);

            return {
                lastUpdatedDateRange: newDateRange,
            };
        });
    }

    submitFilters = () => {
        let prevFilters = {
            projects: this.state.projects,
            types: this.state.types,
            locations: this.state.locations,
            customFields: this.state.customFields,
            createdDateRange: this.state.createdDateRange,
            lastUpdatedDateRange: this.state.lastUpdatedDateRange,
            unsynced: this.state.unsynced,
            archived: this.state.archived,
        }

        if (JSON.stringify(prevFilters) !== JSON.stringify(this.props.filters)) {
            this.showToastLoader(translatePhrase("Please wait"));

            setTimeout(() => {
                this.props.filterGroupTable(
                    this.state.projects,
                    this.state.types,
                    this.state.locations,
                    this.state.customFields,
                    this.state.createdDateRange,
                    this.state.lastUpdatedDateRange,
                    this.state.unsynced,
                    this.state.archived,
                );
    
                if (JSON.stringify(prevFilters) === JSON.stringify(this.props.filters)) {
                    this.setState((prevState) => {
                        return {
                            toastLoader: undefined,
                            clearKey: prevState.clearKey + 1
                        }
                    });
                }
            }, 500);
        }        
    }

    showToastLoader = (text: string) => {
        this.setState({
            toastLoader: <LoaderModal loaderText={[text]} isIndeterminate />
        });
    }

    clearFilters = () => {
        this.props.filterGroupTable([], [], [], {}, [], [], false, false);
        this.setState({ appliedSmartFilter: "" });
        if (this.props.getSmartFilter) {
            this.props.getSmartFilter("");
        }
        this.setState(prevState => {
            return {
                projects: [],
                types: [],
                locations: [],
                customFields: {},
                createdDateRange: [],
                lastUpdatedDateRange: [],
                archived: false,
                unsynced: false,

                clearKey: prevState.clearKey + 1,
            }
        });
    }

    selectSmartFilter = (filter: SmartFilter) => {
        if (filter.name === this.state.appliedSmartFilter) {
            this.removeSmartFilter();
            return;
        }
        this.changeCreatedFromDate(filter.dateRange.from);
        this.changeCreatedToDate(filter.dateRange.to);
        const createdDateRange = [moment(filter.dateRange.from).format("YYYY-MM-DD"), moment(filter.dateRange.to).format("YYYY-MM-DD")];
        this.setState({ createdDateRange: createdDateRange, appliedSmartFilter: filter.name });
        setTimeout(() => {
            if (this.props.getSmartFilter) {
                this.props.getSmartFilter(filter.name);
            }
        }, 200);

        setTimeout(() => {
            this.submitFilters();
        }, 100);
    }

    removeSmartFilter = () => {
        if (this.state.appliedSmartFilter) {
            this.clearFilters();
            this.setState({ appliedSmartFilter: "" });
            if (this.props.getSmartFilter) {
                this.props.getSmartFilter("");
            }
        }
    }

    checkIfSmartFilterIsApplied = () => {
        if (this.props.groupFilters.createdDateRange) {
            let createdDateRangeFromDate = moment(this.props.groupFilters.createdDateRange[0]).format("YYYY-MM-DD");
            let createdDateRangeToDate = moment(this.props.groupFilters.createdDateRange[1]).format("YYYY-MM-DD");

            for (const filter of GroupSmartFilters) {
                if (createdDateRangeFromDate === filter.dateRange.from && createdDateRangeToDate === filter.dateRange.to) {
                    this.setState({ appliedSmartFilter: filter.name });
                }
            }
        }
    }

    handleClickOutside = (event: MouseEvent) => {
        this.props.closeFilter();
    }

    componentDidMount() {
        this.checkIfSmartFilterIsApplied();
    }

    render() {

        const smartFilters = GroupSmartFilters.map(filter => {
            return (
                <div key={filter.name}
                    title={translatePhrase(filter.name)}
                    className={styles.filterTag + " " + (this.state.appliedSmartFilter === filter.name ? styles.active : "")}
                    onClick={() => this.selectSmartFilter(filter)}>
                    <div className={styles.filterTerm}>{translatePhrase(filter.name)}</div>
                    {this.state.appliedSmartFilter !== filter.name &&
                        <div className={styles.plusIcon}>
                            <PlusIcon />
                        </div>
                    }
                    {this.state.appliedSmartFilter === filter.name &&
                        <div className={styles.closeIcon}>
                            <CancelIcon />
                        </div>
                    }
                </div>
            )
        });

        const projectsList = this.props.projectsData.allEntries.map(projectId => {
            return {
                name: this.props.projectsData.byId[projectId].name,
                value: projectId,
            };
        });

        const typesList = this.props.groupsData.types.allEntries
            .filter(typeId => this.state.projects.length === 0 || this.state.projects.includes(this.props.groupsData.types.byId[typeId].project))
            .map(typeId => {
                return {
                    name: this.props.groupsData.types.byId[typeId].name,
                    value: typeId,
                };
            });

        const allAllowedProjects: Set<string> = new Set();

        for (const projectId of this.state.projects) {
            allAllowedProjects.add(projectId);
        }

        for (const typeId of this.state.types) {
            const type = this.props.groupsData.types.byId[typeId];
            allAllowedProjects.add(type.project);
        }

        const locationsList = this.props.allAllowedLocations
            .filter(locationId => {
                if (allAllowedProjects.size === 0) {
                    return true;
                }

                const parentLocationIds = getAncestorChainOfLocation(locationId);
                parentLocationIds.reverse();

                const topMostLocation = this.props.locationsData.byId[parentLocationIds[0]];

                return topMostLocation && topMostLocation.parent && allAllowedProjects.has(topMostLocation.parent);
            })
            .map(locationId => {
                const location = this.props.locationsData.byId[locationId];
                const parent = location.parent && location.parent in this.props.locationsData.byId ? this.props.locationsData.byId[location.parent] : undefined;

                const parentLocationIds = getAncestorChainOfLocation(locationId);
                parentLocationIds.reverse();
                const locationChain = parentLocationIds
                    .filter(locationId => locationId in this.props.locationsData.byId)
                    .concat([locationId])
                    .map(locationId => this.props.locationsData.byId[locationId].name)
                    .join(' > ');

                let parentName = '';

                if (location.parent) {
                    if (location.parent in this.props.locationsData.byId) {
                        let currentLocation = location;
                        let projectName = '';

                        while (currentLocation.parent && currentLocation.parent in this.props.locationsData.byId) {
                            currentLocation = this.props.locationsData.byId[currentLocation.parent];
                        }

                        if (currentLocation.parent && currentLocation.parent in this.props.projectsData.byId) {
                            projectName = this.props.projectsData.byId[currentLocation.parent].name;
                        }

                        parentName = translatePhrase(this.props.locationsData.byId[location.parent].name) + ' - ' + translatePhrase(projectName);
                    } else if (location.parent in this.props.projectsData.byId) {
                        parentName = translatePhrase(this.props.projectsData.byId[location.parent].name);
                    }
                }

                return {
                    name: `${translatePhrase(location.name)} (${parentName})`,
                    description: locationChain,
                    value: locationId,
                };
            });

        let groupType = this.state.types.length === 1 ? this.props.groupsData.types.byId[this.state.types[0]] : undefined;
        let customFieldsMarkup: Array<JSX.Element> | undefined;

        if (typeof groupType !== 'undefined' && this.state.types.length === 1) {
            const validGroupType = groupType;
            customFieldsMarkup = this.props.customFieldsData.allFields
                .filter(customFieldId => {
                    if (customFieldId === this.props.groupsData.types.byId[validGroupType.id].nameFieldId || customFieldId === this.props.groupsData.types.byId[validGroupType.id].subTitleFieldId) {
                        return false;
                    }

                    if (!groupType || !groupType.customFields.includes(customFieldId)) {
                        return false;
                    }

                    const customField = this.props.customFieldsData.byId[customFieldId];

                    return customField.type === FieldType.SINGLE_SELECT || customField.type === FieldType.MULTI_SELECT;
                })
                .map(customFieldId => {
                    const customField = this.props.customFieldsData.byId[customFieldId];
                    const customFieldOptions = customField.choices.map(optionId => {
                        return {
                            name: this.props.customFieldOptionsData.byId[optionId].name,
                            value: optionId,
                        };
                    });

                    return <div className={styles.inputSegment} key={customFieldId} onMouseLeave={this.submitFilters}>
                        <MultiSelectInputText
                            options={customFieldOptions}
                            onChange={this.changeCustomFieldOptions.bind(this, customFieldId)}
                            default={this.state.customFields[customFieldId] || []}
                            placeholder={translatePhrase(customField.name)}
                            isAutoFocus={true}
                        />
                    </div>
                });
        }

        return (
            <section className={styles.commonModalHolder + ' ' + styles.filterHolder}>
                <div className={styles.filterCloseButton} onClick={this.props.closeFilter}>
                    <Button title={translatePhrase("Close")} icon={<CancelIcon />} size={'small'} isRounded />
                </div>

                <section className={styles.addOrModifyListCard}>
                    <header>
                        <h2> <FilterIcon /> {translatePhrase('Group')} {translatePhrase('Filter')}</h2>
                        <Button onClick={this.clearFilters} type={'tertiary'} text={translatePhrase('Clear Filter')} isRounded={true} size={'small'} padding={'0px 10px'} />
                    </header>

                    <div className={styles.smartAndAdvancedFilterContainer}>
                        <div className={styles.smartFilter}>
                            <h4> <SmartFilterIcon />  {translatePhrase('Quick Filters')} </h4>
                            {smartFilters}
                        </div>

                        <div className={styles.container}>

                            <div key={this.state.clearKey} className={styles.allInputsHolder}>
                                <header className={styles.groupInputHeading}>
                                    <h4> {translatePhrase("Structure")} </h4>
                                </header>
                                <section className={styles.groupInputs}>
                                    <div className={styles.inputSegment} onMouseLeave={this.submitFilters}>
                                        <MultiSelectInputText
                                            options={projectsList}
                                            onChange={this.changeProjects}
                                            default={this.state.projects}
                                            placeholder={translatePhrase('Projects')}
                                            isAutoFocus={true}
                                        />
                                    </div>
                                    <div className={styles.inputSegment} onMouseLeave={this.submitFilters}>
                                        <MultiSelectInputText
                                            options={typesList}
                                            onChange={this.changeTypes}
                                            default={this.state.types}
                                            placeholder={translatePhrase('Types')}
                                            isAutoFocus={true}
                                        />
                                    </div>
                                    <div className={styles.inputSegment} onMouseLeave={this.submitFilters}>
                                        <MultiSelectInputText
                                            options={locationsList}
                                            onChange={this.changeLocations}
                                            default={this.state.locations}
                                            placeholder={translatePhrase('Locations')}
                                            isAutoFocus={true}
                                        />
                                    </div>
                                </section>

                                {customFieldsMarkup && <React.Fragment>
                                    <header className={styles.groupInputHeading}>
                                        <h4> {translatePhrase("Type Fields")}{this.state.types && <React.Fragment>: {this.state.types.map(typeId => this.props.groupsData.types.byId[typeId].name)} </React.Fragment>}  </h4>
                                    </header>
                                    <section className={styles.groupInputs}>
                                        {customFieldsMarkup}
                                    </section>
                                </React.Fragment>}

                                <header className={styles.groupInputHeading}>
                                    <h4> {translatePhrase("Dates")} </h4>
                                </header>
                                <section className={styles.groupInputs}>
                                    <div onMouseLeave={this.submitFilters} className={styles.inputSegment + ' ' + styles.dateInput} title={translatePhrase('Created Date') + ' (' + translatePhrase('From') + ')'}>
                                        <DateInput placeholder={translatePhrase('Created Date') + ' (' + translatePhrase('From') + ')'}
                                            default={this.state.createdDateRange.length > 0 && moment(this.state.createdDateRange[0], 'YYYY-MM-DD', true).isValid() ? new Date(this.state.createdDateRange[0]) : undefined}
                                            onChange={(e) => this.changeCreatedFromDate(e)} />
                                    </div>
                                    <div onMouseLeave={this.submitFilters} className={styles.inputSegment + ' ' + styles.dateInput} title={translatePhrase('Created Date') + ' (' + translatePhrase('To') + ')'}>
                                        <DateInput placeholder={translatePhrase('Created Date') + ' (' + translatePhrase('To') + ')'}
                                            default={this.state.createdDateRange.length > 1 && moment(this.state.createdDateRange[1], 'YYYY-MM-DD', true).isValid() ? new Date(this.state.createdDateRange[1]) : undefined}
                                            onChange={(e) => this.changeCreatedToDate(e)} />
                                    </div>

                                    <div onMouseLeave={this.submitFilters} className={styles.inputSegment + ' ' + styles.dateInput} title={translatePhrase('Last Updated Date') + ' (' + translatePhrase('From') + ')'}>
                                        <DateInput placeholder={translatePhrase('Last Updated Date') + ' (' + translatePhrase('From') + ')'}
                                            default={this.state.lastUpdatedDateRange.length > 0 && moment(this.state.lastUpdatedDateRange[0], 'YYYY-MM-DD', true).isValid() ? new Date(this.state.lastUpdatedDateRange[0]) : undefined}
                                            onChange={(e) => this.changeLastUpdatedFromDate(e)} />
                                    </div>
                                    <div onMouseLeave={this.submitFilters} className={styles.inputSegment + ' ' + styles.dateInput} title={translatePhrase('Last Updated Date') + ' (' + translatePhrase('To') + ')'}>
                                        <DateInput placeholder={translatePhrase('Last Updated Date') + ' (' + translatePhrase('To') + ')'}
                                            default={this.state.lastUpdatedDateRange.length > 1 && moment(this.state.lastUpdatedDateRange[1], 'YYYY-MM-DD', true).isValid() ? new Date(this.state.lastUpdatedDateRange[1]) : undefined}
                                            onChange={(e) => this.changeLastUpdatedToDate(e)} />
                                    </div>
                                </section>

                                <header className={styles.groupInputHeading}>
                                    <h4> {translatePhrase("Misc")} </h4>
                                </header>
                                <section className={styles.groupInputs}>
                                    <div className={styles.inputSegment}>
                                        <InputText isBooleanField={true} placeholder={translatePhrase('Unsynced')} onChange={value => this.changeUnsynced(value === 'Yes')}
                                            defaultBooleanValue={this.state.unsynced ? true : false} />
                                    </div>

                                    <div className={styles.inputSegment}>
                                        <InputText isBooleanField={true} placeholder={translatePhrase('Archived')} onChange={value => this.changeArchived(value === 'Yes')}
                                            defaultBooleanValue={this.state.archived ? true : false} />
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </section>
                {this.state.toastLoader}
            </section>
        );
    }
}

const EnhancedGroupModify = onClickOutside(ConnectedGroupModify, {
    excludeScrollbar: true
});

const GroupModify = connect(mapStateToProps, mapDispatchToProps)(EnhancedGroupModify);

export default GroupModify;
