import { ReportScheduleState, ADD_REPORT_SCHEDULE, UPDATE_REPORT_SCHEDULE, DELETE_REPORT_SCHEDULE, UPDATE_REPORT_SCHEDULE_DATA, IReportSchedule, SELECT_REPORT_SCHEDULE, UN_SELECT_REPORT_SCHEDULE, RE_ORDER_REPORT_SCHEDULES, UPDATE_REPORT_SCHEDULES_DATA, SYNCHRONIZE_REPORT_SCHEDULES_DATA, CLEAR_REPORT_SCHEDULES_DELTA, INSTANTIATE_REPORT_SCHEDULE } from './types';
import { ReportActionTypes } from '../types';
import { reOrderList } from '../../../helpers/utilities';
import { updateEntries, synchronizeEntries, clearDelta, addEntity, updateEntity, deleteEntity } from '../../normalized-model';

export const initialState: ReportScheduleState = {
    byId: {},
    allEntries: [],
    filteredEntries: [],
    selected: undefined,
    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),
};

export function reportSchedulesReducer(state = initialState, action: ReportActionTypes): ReportScheduleState {
    let newState = state;

    switch (action.type) {

        case SELECT_REPORT_SCHEDULE:
            return {
                ...state,
                selected: action.id,
            }

        case UN_SELECT_REPORT_SCHEDULE:
            return {
                ...state,
                selected: undefined,
            }

        case RE_ORDER_REPORT_SCHEDULES:
            return {
                ...state,
                allEntries: reOrderList(state.allEntries, action.sourceIndex, action.destinationIndex),
            };

        case ADD_REPORT_SCHEDULE:
            return addEntity<ReportScheduleState, IReportSchedule>(state, action.payload);

        case INSTANTIATE_REPORT_SCHEDULE:
            return addEntity<ReportScheduleState, IReportSchedule>(state, action.payload);

        case UPDATE_REPORT_SCHEDULE:
            return updateEntity<ReportScheduleState, IReportSchedule>(state, action.payload, action.currentTime);

        case DELETE_REPORT_SCHEDULE:
            newState = deleteEntity<ReportScheduleState, IReportSchedule>(state, action.id, action.currentTime);
            newState.selected = undefined;
            return newState;

        case UPDATE_REPORT_SCHEDULE_DATA:
            return {
                ...action.data,
            };

        case UPDATE_REPORT_SCHEDULES_DATA:
            return updateEntries<ReportScheduleState, IReportSchedule>(state, action.data);

        case SYNCHRONIZE_REPORT_SCHEDULES_DATA:
            return synchronizeEntries<ReportScheduleState, IReportSchedule>(state, action.data);

        case CLEAR_REPORT_SCHEDULES_DELTA:
            return clearDelta<ReportScheduleState, IReportSchedule>(state);

        default:
            return state;
    }
}