import React, { Component } from 'react';
import styles from './Operator.module.scss';
import Input from '../../Input';
import FlowchartPiece, { OwnProps as FlowchartPieceProps } from '../FlowchartPiece';

import SelectBox from '../../drop-down/SelectBox';
import DropDownList from '../../drop-down/DropDownList';
import ListItem, { Option } from '../../drop-down/ListItem';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setLeftOperand, setRightOperand, setTimeDifferenceFormat } from '../../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../../shared/store/types';
import { valuePieceSlotTarget } from '../utilities';
import { FlowchartContext, PieceHighlightColour } from '../../../../contexts/flowchart-context';
import DropDownSearchBox, { getFilteredOptionsBySearch } from '../../drop-down/DropDownSearchBox';


export type GetTimeDifferenceOperatorProps = {
    leftOperandPiece?: JSX.Element,
    rightOperandPiece?: JSX.Element,
    leftOperandText?: string,
    rightOperandText?: string,
    format?: string,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string | undefined) => dispatch(setTargetPiece(pieceId)),
        setLeftOperand: (targetPieceId: string, draggedPieceId: string) => dispatch(setLeftOperand(targetPieceId, draggedPieceId)),
        setRightOperand: (targetPieceId: string, draggedPieceId: string) => dispatch(setRightOperand(targetPieceId, draggedPieceId)),
        setTimeDifferenceFormat: (targetPieceId: string, format: string) => dispatch(setTimeDifferenceFormat(targetPieceId, format)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = GetTimeDifferenceOperatorProps & StateProps & DispatchProps & FlowchartPieceProps;

type StorePieceState = {
    isHoveringOverLeftOperand: boolean,
    isHoveringOverRightOperand: boolean,
    timeDifferenceSearchTerm: string
}

class ConnectedGetTimeDifferenceOperator extends Component<Props, StorePieceState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            isHoveringOverLeftOperand: false,
            isHoveringOverRightOperand: false,
            timeDifferenceSearchTerm: "",
        }
    }

    handleHoverOverLeftOperand = () => {
        this.setState({
            isHoveringOverLeftOperand: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfLeftOperandPiece = () => {
        this.setState({
            isHoveringOverLeftOperand: false,
        });
    };

    handleLeftOperandValueUpdate = (value: string) => {
        this.props.setLeftOperand(this.props.pieceId, value);
    }

    handleHoverOverRightOperand = () => {
        this.setState({
            isHoveringOverRightOperand: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfRightOperandPiece = () => {
        this.setState({
            isHoveringOverRightOperand: false,
        });
    };

    handleRightOperandValueUpdate = (value: string) => {
        this.props.setRightOperand(this.props.pieceId, value);
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && (this.state.isHoveringOverLeftOperand || this.state.isHoveringOverRightOperand)) {

            if (this.state.isHoveringOverLeftOperand) {
                this.props.setLeftOperand(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverRightOperand) {
                this.props.setRightOperand(this.props.pieceId, this.props.lastDraggedPiece.id);
            }

            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverLeftOperand: false,
                isHoveringOverRightOperand: false,
            });
        }
    }

    searchForTimeDifference = (searchTerm: string) => {
        this.setState({ timeDifferenceSearchTerm: searchTerm })
    }

    render() {
        return <FlowchartContext.Consumer>
            {
                (flowchartContext) => {
                    const differenceFormats = [
                        'Years',
                        'Months',
                        'Weeks',
                        'Days',
                        'Hours',
                        'Minutes',
                        'Seconds',
                    ];

                    const highlightColor = flowchartContext.highlights && flowchartContext.highlights[this.props.pieceId];
                    let highlightClass = styles.noHighlight;

                    switch (highlightColor) {
                        case PieceHighlightColour.GREEN:
                            highlightClass = styles.addedHighlight;
                            break;
                        case PieceHighlightColour.YELLOW:
                            highlightClass = styles.updatedHighlight;
                            break;
                        case PieceHighlightColour.PURPLE:
                            highlightClass = styles.movedHighlight;
                            break;
                        case PieceHighlightColour.RED:
                            highlightClass = styles.deletedHighlight;
                            break;
                    }

                    const differenceFormatSelector = <SelectBox theme="dark-orange" selectionPromptText={this.props.format && differenceFormats.includes(this.props.format) ? this.props.format : undefined}>
                        <DropDownList theme="orange">
                            {differenceFormats.map(format => <ListItem theme="orange" name={`${format}`} key={format} value={format} onClick={this.props.setTimeDifferenceFormat.bind(this, this.props.pieceId)} />)}
                        </DropDownList>
                    </SelectBox>;

                    return (
                        <FlowchartPiece {...this.props}>
                            <div className={highlightClass}>
                                <div className={styles.binaryOperator}>
                                    {this.props.leftOperandPiece ? this.props.leftOperandPiece :
                                        <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverLeftOperand && !!this.props.targetPiece} defaultText={this.props.leftOperandText} onMouseOver={this.handleHoverOverLeftOperand} onMouseOut={this.handleHoverOutOfLeftOperandPiece} onChange={this.handleLeftOperandValueUpdate} />
                                    }

                                    <div className={styles.operatorSymbol}>-</div>

                                    {this.props.rightOperandPiece ? this.props.rightOperandPiece :
                                        <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverRightOperand && !!this.props.targetPiece} defaultText={this.props.rightOperandText} onMouseOver={this.handleHoverOverRightOperand} onMouseOut={this.handleHoverOutOfRightOperandPiece} onChange={this.handleRightOperandValueUpdate} />
                                    }

                                    <div className={styles.text}>in no. of </div>

                                    {differenceFormatSelector}
                                </div>
                            </div>
                        </FlowchartPiece>
                    )
                }
            }
        </FlowchartContext.Consumer>
    }
}

const GetTimeDifferenceOperator = connect(mapStateToProps, mapDispatchToProps)(ConnectedGetTimeDifferenceOperator);

export default GetTimeDifferenceOperator;