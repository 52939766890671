import React from 'react';
import styles from './List.module.scss';
import { connect } from 'react-redux';
import MembersTable from './MembersTable';
import { ApplicationState } from '../../../shared/store/types';
import { Permissions } from '../../../shared/store/permissions/types';

type OwnProps = {
};

const mapStateToProps = (state: ApplicationState) => {

    let isWritable = true;

    const isOnline = state.myData.isOnline
    const granularPermissions = new Set(Object.keys(state.permissions.myPermissions.members));
    const areAllTypesHandledByGranularPermissions = state.members.types.allEntries.every(typeId => granularPermissions.has(typeId));

    if (areAllTypesHandledByGranularPermissions && Object.keys(state.permissions.myPermissions.members).length > 0) {
        isWritable = Object.keys(state.permissions.myPermissions.members).some(memberTypeId => {
            return state.permissions.myPermissions.members[memberTypeId] === Permissions.WRITE;
        });
    }

    return {
        filtersExpanded: false,
        write: isWritable,
        isOnline,
        isSettled: (state.myData.isLoaded || state.myData.isPartiallyLoaded) && !state.myData.isPushingData,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;
type Props = StateProps & OwnProps;

const List: React.FC<Props>  = (props) => {
    const { filtersExpanded, isOnline, write, isSettled } = props;

    if (!isSettled) {
        return <section>
        </section>
    }

    return (
        <section>
            <div className={filtersExpanded ? styles.tableArea : styles.expandedTableArea}>
                <MembersTable isReadOnly={isOnline || !write} />
            </div>
        </section>
    );
}

export default connect(mapStateToProps)(List);
