import React, { ChangeEvent, Component } from 'react';
import StepPiece from './step-piece/StepPiece'
import styles from './step-piece/StepPiece.module.scss';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTransferRoles } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import { isUUID } from '../../../shared/helpers/utilities';
import { FlowchartContext, FlowchartInfoForPiece } from '../../../contexts/flowchart-context';


type TransferPieceProps = {
    pieceId: string,
    projectId: string,
    nextPiece?: JSX.Element,
    selectedRoles?: Array<string>,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,
        projectsData: state.structure.projects,
        levelsData: state.structure.levels,
        rolesData: state.structure.roles,
    }
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: TransferPieceProps) => {

    return {
        setTransferRoles: (roles: Array<string>) => dispatch(setTransferRoles(ownProps.pieceId, roles)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = TransferPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type TransferPieceState = {
    searchText: string,
}

class ConnectedTransferPiece extends Component<Props> {
    state = {
        searchText: '',
    };

    updateSelectedRoles = (option: string) => {
        let selectedRoles = this.props.selectedRoles ? this.props.selectedRoles.slice(0) : [];

        if (selectedRoles.includes(option)) {
            selectedRoles = selectedRoles.filter(selectedOption => option !== selectedOption);
        } else {
            selectedRoles.push(option);
        }

        let roleIdsInProject: Set<string> = new Set();

        const project = this.props.projectsData.byId[this.props.projectId];

        for (const levelId of project.children) {
            const level = this.props.levelsData.byId[levelId];

            for (const roleId of level.children) {
                roleIdsInProject.add(roleId);
            }
        }

        const validSelectedRoles = selectedRoles.filter(roleId => !isUUID(roleId) || roleIdsInProject.has(roleId));

        this.props.setTransferRoles(validSelectedRoles);
    }

    updateValidity = (isValid: boolean, flowchartContext: FlowchartInfoForPiece) => {
        if (!isValid) {
            const invalidPieceInfo = flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId);

            if (!invalidPieceInfo && flowchartContext.setInvalidPiece) {
                flowchartContext.setInvalidPiece(this.props.pieceId, flowchartContext.parentSplitPieceIds);
            }
        }

        if (isValid && flowchartContext.removeInvalidPiece && flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId)) {
            flowchartContext.removeInvalidPiece(this.props.pieceId);
        }
    }

    searchForRole = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            searchText: e.target.value,
        });
    }

    render() {
        return <FlowchartContext.Consumer>
            {
                (flowchartContext) => {
                    let roleIdsInProject: Set<string> = new Set();

                    const project = this.props.projectsData.byId[this.props.projectId];

                    for (const levelId of project.children) {
                        const level = this.props.levelsData.byId[levelId];

                        for (const roleId of level.children) {
                            roleIdsInProject.add(roleId);
                        }
                    }

                    const rolesMarkup = [...roleIdsInProject]
                        .filter(roleId => {
                            const role = this.props.rolesData.byId[roleId];
                            return role.name.toLocaleLowerCase().includes(this.state.searchText.toLocaleLowerCase())
                        })
                        .map(roleId => {
                            const role = this.props.rolesData.byId[roleId];
                            const level = this.props.levelsData.byId[role.level];

                            return <ListItem key={roleId} theme="aqua" name={role.name} detail={level.name} value={roleId} onClick={this.updateSelectedRoles} />
                        });

                    const validSelectedRoles = this.props.selectedRoles ? this.props.selectedRoles.filter(roleId => !isUUID(roleId) || roleIdsInProject.has(roleId)) : [];

                    const rolesText = validSelectedRoles.length > 0 ? validSelectedRoles.map(roleId => isUUID(roleId) ? this.props.rolesData.byId[roleId].name : 'Previous owners').join(', ') : 'Select 1 or more roles';

                    const isValid = !!this.props.selectedRoles && this.props.selectedRoles.every(selectedRoleId => roleIdsInProject.has(selectedRoleId) || selectedRoleId === 'Previous owners' || selectedRoleId === 'previous');

                    this.updateValidity(isValid, flowchartContext);

                    return (
                        <StepPiece theme={isValid ? "aqua" : "red"} {...this.props}>
                            <div className={styles.text}>Transfer to</div>
                            <SelectBox theme="dark-aqua" selectionPromptText={rolesText}>
                                <DropDownList theme="aqua" dismissAfterSelection={false}>
                                    <div className={styles.nameContainer + ' ignore-options-onclickoutside'}>
                                        <input className={styles.nameInput} onChange={this.searchForRole} value={this.state.searchText} type="text" placeholder="Search role" />
                                    </div>
                                    {rolesMarkup}
                                    {'previous owners'.includes(this.state.searchText.toLocaleLowerCase()) && <ListItem key="previous" theme="aqua" name="Previous owners" value="previous" onClick={this.updateSelectedRoles} />}
                                </DropDownList>
                            </SelectBox>
                        </StepPiece>
                    )
                }
            }
        </FlowchartContext.Consumer>
    }
}

const TransferPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedTransferPiece);

export default TransferPiece;