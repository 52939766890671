import { ReportTypeState, ADD_REPORT_TYPE, UPDATE_REPORT_TYPE, DELETE_REPORT_TYPE, UPDATE_REPORT_TYPE_DATA, REGISTER_REPORT_TYPE_VARIABLE, UPDATE_REPORT_TYPE_START_PIECE, SET_ISOLATED_REPORT_TYPE_PIECE, REMOVE_ISOLATED_REPORT_TYPE_PIECE, IReportType, SELECT_REPORT_TYPE, UN_SELECT_REPORT_TYPE, RE_ORDER_REPORT_TYPES, UPDATE_REPORT_TYPES_DATA, SYNCHRONIZE_REPORT_TYPES_DATA, CLEAR_REPORT_TYPES_DELTA, INSTANTIATE_REPORT_TYPE } from './types';
import { ReportActionTypes } from '../types';
import { reOrderList } from '../../../helpers/utilities';
import { updateEntries, synchronizeEntries, clearDelta, addEntity, updateEntity, deleteEntity } from '../../normalized-model';

export const initialState: ReportTypeState = {
    byId: {},
    allEntries: [],
    filteredEntries: [],
    selected: undefined,
    createdIds: new Set(),
    updatedIds: new Set(),
    deletedIds: new Set(),
    areReportTypesReordered : false
};

export function reportTypesReducer(state = initialState, action: ReportActionTypes): ReportTypeState {
    let newState = state;

    switch (action.type) {
        
        case SELECT_REPORT_TYPE:
            return {
                ...state,
                selected: action.id,
            }
        
        case UN_SELECT_REPORT_TYPE:
            return {
                ...state,
                selected: undefined,
            }

        case RE_ORDER_REPORT_TYPES:
            return {
                ...state,
                allEntries: reOrderList(state.allEntries, action.sourceIndex, action.destinationIndex),
                areReportTypesReordered: true
            };

        case ADD_REPORT_TYPE:
            return addEntity<ReportTypeState, IReportType>(state, action.payload);

        case INSTANTIATE_REPORT_TYPE:
            return addEntity<ReportTypeState, IReportType>(state, action.payload);
        
        case UPDATE_REPORT_TYPE:
            return updateEntity<ReportTypeState, IReportType>(state, action.payload, action.currentTime);
        
        case DELETE_REPORT_TYPE:
            newState = deleteEntity<ReportTypeState, IReportType>(state, action.id, action.currentTime);
            newState.selected = undefined;
            return newState;

        case UPDATE_REPORT_TYPE_DATA:
            return {
                ...action.data,
            };

        case UPDATE_REPORT_TYPE_START_PIECE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.reportTypeId]: {
                        ...state.byId[action.reportTypeId],
                        startPiece: action.payload,
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.reportTypeId]),
            }

        case SET_ISOLATED_REPORT_TYPE_PIECE:
            const newReportTypeIsolatedPieces = state.byId[action.reportTypeId].isolatedPieces.slice(0);
            const customFieldIsolatedPieceIndex = newReportTypeIsolatedPieces.findIndex(isolatedPieceData => isolatedPieceData.piece === action.payload.piece);
        
            if (customFieldIsolatedPieceIndex < 0) {
                newReportTypeIsolatedPieces.push(action.payload);
            } else {
                newReportTypeIsolatedPieces[customFieldIsolatedPieceIndex] = action.payload;
            }
        
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.reportTypeId]: {
                        ...state.byId[action.reportTypeId],
                        isolatedPieces: newReportTypeIsolatedPieces,
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.reportTypeId]),
            }

        case REMOVE_ISOLATED_REPORT_TYPE_PIECE:

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.reportTypeId]: {
                        ...state.byId[action.reportTypeId],
                        isolatedPieces: state.byId[action.reportTypeId].isolatedPieces.filter(pieceData => pieceData.piece !== action.pieceId),
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.reportTypeId]),
            }


        case REGISTER_REPORT_TYPE_VARIABLE:
            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.reportTypeId]: {
                        ...state.byId[action.reportTypeId],
                        variables: state.byId[action.reportTypeId].variables.concat([action.variableId]),
                    }
                },
                updatedIds: new Set([...state.updatedIds, action.reportTypeId]),
            }

        case UPDATE_REPORT_TYPES_DATA:
            return updateEntries<ReportTypeState, IReportType>(state, action.data);

        case SYNCHRONIZE_REPORT_TYPES_DATA:
            return synchronizeEntries<ReportTypeState, IReportType>(state, action.data);

        case CLEAR_REPORT_TYPES_DELTA:
            newState = clearDelta<ReportTypeState, IReportType>(state);
            newState.areReportTypesReordered = false;
            return newState;
        
        default:
            return state;
    }
}