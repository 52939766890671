import React, { Component } from 'react';
import StepPiece from './step-piece/StepPiece'
import styles from './step-piece/StepPiece.module.scss';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';
import { Option } from '../drop-down/ListItem';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setUpdateStatusValue, setWorkflow, setWorkflowType } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import { FlowchartContext, FlowchartInfoForPiece } from '../../../contexts/flowchart-context';
import DropDownSearchBox from '../drop-down/DropDownSearchBox';
import { getFilteredOptionsBySearch } from '../drop-down/DropDownSearchBox';


type UpdateStatusPieceProps = {
    nextPiece?: JSX.Element,
    workflowVariableId?: string,
    variables: Array<Option>,
    selectedWorkflowTypeId: string,
    states: Array<Option>,
    updateStatusId?: string,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,
        workflowTypeState: state.workflows.types,
        variableState: state.flowchart.variables,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setUpdateStatusValue: (pieceId: string, value: string) => dispatch(setUpdateStatusValue(pieceId, value)),
        setWorkflow: (pieceId: string, workflowId: string) => dispatch(setWorkflow(pieceId, workflowId)),
        setWorkflowType: (pieceId: string, workflowTypeId: string) => dispatch(setWorkflowType(pieceId, workflowTypeId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = UpdateStatusPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type OwnState = {
    statusSearchTerm: string,
    workflowSearchTerm: string,
    typesSearchTerm: string,
};

class ConnectedUpdateStatusPiece extends Component<Props, OwnState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            statusSearchTerm: "",
            workflowSearchTerm: "",
            typesSearchTerm: ""
        }
    }

    updateValidity = (isValid: boolean, flowchartContext: FlowchartInfoForPiece) => {
        if (!isValid) {
            const invalidPieceInfo = flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId);

            if (!invalidPieceInfo && flowchartContext.setInvalidPiece) {
                flowchartContext.setInvalidPiece(this.props.pieceId, flowchartContext.parentSplitPieceIds);
            }
        }

        if (isValid && flowchartContext.removeInvalidPiece && flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId)) {
            flowchartContext.removeInvalidPiece(this.props.pieceId);
        }
    }

    searchForStatus = (searchTerm: string) => {
        this.setState({ statusSearchTerm: searchTerm });
    }

    searchForWorkflows = (searchTerm: string) => {
        this.setState({ workflowSearchTerm: searchTerm });
    }

    searchForTypes = (searchTerm: string) => {
        this.setState({ typesSearchTerm: searchTerm });
    }

    render() {
        const { statusSearchTerm, workflowSearchTerm, typesSearchTerm } = this.state;
        return <FlowchartContext.Consumer>
            {
                flowchartContext => {
                    let isValid = !this.props.updateStatusId || !!this.props.states.find(option => option.value === this.props.updateStatusId);

                    if (isValid) {
                        isValid = !this.props.workflowVariableId || !!this.props.variables.find(option => option.value === this.props.workflowVariableId);
                    }

                    const variableName = this.props.workflowVariableId && this.props.workflowVariableId in this.props.variableState.byId ? this.props.variableState.byId[this.props.workflowVariableId].name : undefined;
                    
                    const filteredWorkflows = getFilteredOptionsBySearch(this.props.variables, workflowSearchTerm);

                    const variableSelectBox = <SelectBox theme="indigo" selectionPromptText={variableName ? variableName : 'Current workflow'}>
                        <DropDownList theme="indigo" dismissAfterSelection={false}>
                            <DropDownSearchBox
                                handleSearchInputChange={this.searchForWorkflows}
                                placeholder={"Search by name"}
                                searchTerm={workflowSearchTerm}
                            />
                            {filteredWorkflows.map((variable, index) => <ListItem name={variable.name} value={variable.value} key={index} theme="indigo" onClick={this.props.setWorkflow.bind(this, this.props.pieceId)} />)}
                        </DropDownList>
                    </SelectBox>;

                    const selectedWorkflowType = this.props.workflowTypeState.byId[this.props.selectedWorkflowTypeId];

                    const allowedWorkflowTypes = this.props.workflowTypeState.allEntries
                    .filter(workflowTypeId => {
                        const workflowType = this.props.workflowTypeState.byId[workflowTypeId];
                        return workflowType.project === selectedWorkflowType.project;
                    })
                    .map(workflowTypeId => this.props.workflowTypeState.byId[workflowTypeId])

                    if (isValid) {
                        isValid = !this.props.selectedWorkflowTypeId || !!allowedWorkflowTypes.find(workflowType => workflowType.id === this.props.selectedWorkflowTypeId);
                    }

                    this.updateValidity(isValid, flowchartContext);
                    let filteredWorkflowTypes: Option[] = allowedWorkflowTypes.map(workflowType => {
                        return {
                            name: workflowType.name,
                            value: workflowType.id,
                        }
                    }) 

                    filteredWorkflowTypes = getFilteredOptionsBySearch(filteredWorkflowTypes, typesSearchTerm);

                    const typesSelectBox = <SelectBox theme="aqua" selectionPromptText={selectedWorkflowType.name}>
                        <DropDownList theme="aqua" dismissAfterSelection={false}>
                            <DropDownSearchBox
                                handleSearchInputChange={this.searchForTypes}
                                placeholder={"Search by name"}
                                searchTerm={typesSearchTerm}
                            />
                            {filteredWorkflowTypes.map(workflowType => <ListItem name={workflowType.name} value={workflowType.value} key={workflowType.value} theme="aqua" onClick={this.props.setWorkflowType.bind(this, this.props.pieceId)} />)}
                        </DropDownList>
                    </SelectBox>;

                    const statesMarkup = getFilteredOptionsBySearch(this.props.states, statusSearchTerm).map(state => <ListItem key={state.name} theme="aqua" name={state.name} value={state.value} onClick={this.props.setUpdateStatusValue.bind(this, this.props.pieceId)} />);
                    const statusName = this.props.updateStatusId && this.props.updateStatusId in this.props.workflowTypeState.statuses.byId ? this.props.workflowTypeState.statuses.byId[this.props.updateStatusId].name : undefined;
                
                    return (
                        <StepPiece theme={isValid ? "aqua" : "red"} {...this.props}>
                            <div className={styles.text}>Update status of</div>
                            {variableSelectBox}
                            {this.props.workflowVariableId && <div className={styles.text}>of type</div>}
                            {this.props.workflowVariableId && typesSelectBox}
                            <div className={styles.text}>to</div>
                            <SelectBox theme="dark-aqua" selectionPromptText={statusName ? statusName : "Select a status"}>
                                <DropDownList theme="aqua" dismissAfterSelection={false}>  
                                    <DropDownSearchBox
                                        handleSearchInputChange={this.searchForStatus}
                                        placeholder={"Search by name"}
                                        searchTerm={statusSearchTerm}
                                    />
                                    {statesMarkup}
                                </DropDownList>
                            </SelectBox>
                        </StepPiece>
                    )
                }
            }
        </FlowchartContext.Consumer>
    }
}

const UpdateStatusPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedUpdateStatusPiece);

export default UpdateStatusPiece;