import React, { Component, ReactNode } from 'react';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setLoopVariable, setIterableVariable } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import ContainerPiece from './container-piece/ContainerPiece';

import { Option } from '../drop-down/ListItem';
import QuestionPieceEssentials from './QuestionPieceEssentials';
import QuestionPieceOptionals from './QuestionPieceOptionals';
import { nextPieceTarget } from './utilities';
import { WorkflowTypeContext } from '../../../contexts/workflow-type-context';
import { FlowchartContext, FlowchartInfoForPiece } from '../../../contexts/flowchart-context';


type QuestionPieceProps = {
    questionVariablePiece?: JSX.Element,
    questionText?: string,
    memberVariable?: JSX.Element,
    isForSingleMember?: boolean,

    isRequiredPiece?: JSX.Element,
    isDisabledPiece?: JSX.Element,
    isHiddenPiece?: JSX.Element,
    
    defaultPiece?: JSX.Element,
    image?: string,
    defaultText?: string,

    selectedCustomField?: string,
    customFields: Array<Option>,
    nextPiece?: JSX.Element,
    innerPiece?: JSX.Element,
    children?: ReactNode,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
        setLoopVariable: (targetPieceId: string, draggedPieceId: string) => dispatch(setLoopVariable(targetPieceId, draggedPieceId)),
        setIterableVariable: (targetPieceId: string, draggedPieceId: string) => dispatch(setIterableVariable(targetPieceId, draggedPieceId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = QuestionPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

class ConnectedQuestionPiece extends Component<Props> {

    handleHoverOverInnerPiece = () => {

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        nextPieceTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    updateValidity = (isValid: boolean, flowchartContext: FlowchartInfoForPiece) => {
        if (!isValid) {
            const invalidPieceInfo = flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId);

            if (!invalidPieceInfo && flowchartContext.setInvalidPiece) {
                flowchartContext.setInvalidPiece(this.props.pieceId, flowchartContext.parentSplitPieceIds);
            }
        }

        if (isValid && flowchartContext.removeInvalidPiece && flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId)) {
            flowchartContext.removeInvalidPiece(this.props.pieceId);
        }
    }

    render() {
        return <WorkflowTypeContext.Consumer>
            {workflowType => {

                return <FlowchartContext.Consumer>
                    {
                        flowchartContext => {
                            let isValid = !this.props.selectedCustomField || !workflowType || workflowType.customFields.includes(this.props.selectedCustomField);
                            
                            if (flowchartContext.highlightIncompletePieces) {
                                const isIncomplete = !this.props.selectedCustomField;
                                isValid = isValid && !isIncomplete;
                            }
        
                            this.updateValidity(isValid, flowchartContext);

                            const upperArmContent = <QuestionPieceEssentials isValid={isValid} pieceName="question" {...this.props} />;
                            const lowerArmContent = <QuestionPieceOptionals isValid={isValid} {...this.props} />;
                            return <ContainerPiece 
                                {...this.props}
                                theme={isValid ? "camo" : "red"}
                                handleHoverOverInnerPiece={this.handleHoverOverInnerPiece}
                                upperArmContent={upperArmContent}
                                lowerArmContent={lowerArmContent}
                            >
                                {this.props.innerPiece}
                            </ContainerPiece>;
                        }
                    }
                </FlowchartContext.Consumer>

            }}
        </WorkflowTypeContext.Consumer>
    }
}

const QuestionPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedQuestionPiece);

export default QuestionPiece;