import React, { FC, useEffect } from 'react';
import styles from './Nudges.module.scss';

import { ReactComponent as ChevronDownIcon } from '../../common/assets/chevron-down.svg';
import { ReactComponent as NudgeIcon } from '../../common/assets/nudge.svg';
import { ApplicationState } from '../../shared/store/types';
import { useLocation } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getDashboardNudges } from './nudges/dashboard';
import { NudgeData } from './nudges/index';
import { selectNudge } from '../../shared/store/my-data/actions';
import { translatePhrase } from '../../shared/helpers/translation';
import { getMembersListNudges } from './nudges/members-list';
import { getGroupsListNudges } from './nudges/groups-list';
import { getWorkflowsListNudges } from './nudges/workflows-list';
import { getLocationsNudges } from './nudges/locations';
import { getUsersListNudges } from './nudges/users-list';
import { getOrganizationNudges } from './nudges/organization';
import { getStructureHierarchyNudges } from './nudges/structure-hierarchy';
import { getWorkflowsConfigurationNudges } from './nudges/workflows-configuration';
import { getFlowchartNudges } from './nudges/flowchart';
import Button from '../../widgets/button/CommonButton';

interface Props {
}

const Nudges: FC<Props> = (props) => {
    const applicationState = useSelector((state: ApplicationState) => state);
    const location = useLocation();
    const dispatch = useDispatch();
    const selectedWorkflowId = applicationState.workflows.types.selected

    const isEditingOrgProfile = useSelector((state: ApplicationState) => !!state.myData.isEditingOrgProfile);

    const selectedNudgeId = useSelector((state: ApplicationState) => state.myData.selectedNudgeId);

    let nudges: Array<NudgeData> = [];

    if (isEditingOrgProfile) {
        nudges = getOrganizationNudges(applicationState);
    } else if (location.pathname === '/structure/hierarchy') {
        nudges = getStructureHierarchyNudges(applicationState);
    } else if (location.pathname === '/dashboard') {
        nudges = getDashboardNudges(applicationState);
    } else if (location.pathname === '/structure/locations') {
        nudges = getLocationsNudges(applicationState);
    } else if (location.pathname === '/users/list') {
        nudges = getUsersListNudges(applicationState);
    } else if (location.pathname === '/members/list') {
        nudges = getMembersListNudges(applicationState);
    } else if (location.pathname === '/groups/list') {
        nudges = getGroupsListNudges(applicationState);
    } else if (location.pathname === '/workflows/list') {
        nudges = getWorkflowsListNudges(applicationState);
    } else if (location.pathname === '/reports/list') {
        nudges = [];
    } else if (location.pathname === '/workflows/configuration') {
        nudges = getWorkflowsConfigurationNudges(applicationState);
    } else if (location.pathname === `/workflows/flowchart/${selectedWorkflowId}/beta`) {
        nudges = getFlowchartNudges(applicationState);
    } else if (location.pathname === `/workflows/flowchart/${selectedWorkflowId}/live`) {
        nudges = getFlowchartNudges(applicationState);
    }

    useEffect(() => {
        if (nudges.length === 1) {
            if (nudges[0].id !== selectedNudgeId) {
                dispatch(selectNudge(nudges[0].id));
            }
        }
    }, []);

    const selectedNudge = nudges.find(nudge => nudge.id === selectedNudgeId);

    const setSelectedNudge = (nudge?: NudgeData) => {
        dispatch(selectNudge(nudge?.id));
    }

    const getHelpLink = () => {
        let url = location.pathname;

        switch (url) {
            case '/dashboard': return 'https://support.diceflow.in/desktop-user-manual/logindashboard#h.4afj6pocmvsj';
            case '/members/list': return 'https://support.diceflow.in/desktop-user-manual/members';
            case '/workflows/list': return 'https://support.diceflow.in/desktop-user-manual/workflows';
            case '/reports/list': return 'https://support.diceflow.in/desktop-user-manual/reports';
            case '/structure/hierarchy': return 'https://support.diceflow.in/configuration/structure';
            case `/workflows/flowchart/${selectedWorkflowId}/beta`: return 'https://support.diceflow.in/configuration/flowchart-in-detail';
            case `/workflows/flowchart/${selectedWorkflowId}/live`: return 'https://support.diceflow.in/configuration/flowchart-in-detail';
            default: return '';
        }
    }

    return <section className={styles.help}>
        <ul>
            <header className={styles.helpType}>
                <h4> <NudgeIcon /> {translatePhrase('Help')} </h4>
                <span className={styles.count}>{nudges.length}</span>
            </header>
            {nudges.map((nudge, index) => {
                const isNudgeSelected = selectedNudge?.id === nudge.id;

                return <li key={index} className={isNudgeSelected ? styles.active : ''}>
                    <header onClick={() => !isNudgeSelected ? setSelectedNudge(nudge) : setSelectedNudge(undefined)}>
                        <h5> {nudge.header} </h5>
                        <ChevronDownIcon />
                    </header>
                    {isNudgeSelected && <React.Fragment>
                        <div className={styles.Content}>
                            {nudge.content.split('\n').map(paragraph => <p>{paragraph.split(' ').map(word => word.startsWith('http') ? <a href={word} target="_blank" rel="noreferrer noopener">{word}</a> : word + ' ')}</p>)}
                            {nudge.link && <a className={styles.helpLink} href={nudge.link} target='_blank' rel="noreferrer">
                                <Button text={translatePhrase('Read More')} isRounded padding={'0px 10px'} size={'small'} type={'tertiary'} />
                            </a>}
                        </div>
                    </React.Fragment>}
                </li>
            })}
        </ul>

        {getHelpLink() && <a className={styles.helpLink} href={getHelpLink()} target='_blank' rel="noreferrer">
            <Button text={translatePhrase('Read More')} isRounded padding={'0px 10px'} size={'small'} type={'tertiary'} />
        </a>}
    </section>

}

export default Nudges;