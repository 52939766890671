import { NudgeData } from "./index";
import { ApplicationState } from "../../../shared/store/types";
import { NudgeType } from "../../../shared/store/my-data/types";
import { Permissions } from '../../../shared/store/permissions/types';

export function getWorkflowsListNudges(applicationState: ApplicationState): Array<NudgeData> {

    let nudgesData: Array<NudgeData> = [];

    let hasWorkflowTypesToAdd = applicationState.myData.id === 'SuperUser';

    if (!hasWorkflowTypesToAdd) {
        const allowedWorkflowTypes = applicationState.workflows.types.allEntries
        .filter(typeId => {
            const workflowType = applicationState.workflows.types.byId[typeId];
            const loggedInUser = applicationState.myData.id ? applicationState.users.byId[applicationState.myData.id] : undefined;
    
            if (loggedInUser) {
                return loggedInUser.projects.includes(workflowType.project);
            } else {
                return true;
            }
        });

        for (const workflowTypeId of allowedWorkflowTypes) {
    
            if (applicationState.permissions.myPermissions.workflows[workflowTypeId] === Permissions.WRITE || typeof applicationState.permissions.myPermissions.workflows[workflowTypeId] === 'undefined') {
                hasWorkflowTypesToAdd = true;
            }
        }
    }

    if (hasWorkflowTypesToAdd) {
        nudgesData = [{
            id: NudgeType.WORKFLOWS_ADD_WORKFLOW,
            header: 'Add workflow',
            content: 'Click on the add button on the top right to add a new workflow',
        }];
    }
    
    nudgesData = nudgesData.concat([{
        id: NudgeType.WORKFLOWS_FILTER,
        header: 'Filter workflows',
        content: 'Click on the filter button to filter the existing workflows list',
    }, {
        id: NudgeType.WORKFLOWS_SEARCH,
        header: 'Search workflows',
        content: 'Type into the search bar to search the existing workflows list',
    }, {
        id: NudgeType.WORKFLOWS_EXPORT,
        header: 'Export',
        content: 'Click this button to export workflows in the form of a CSV.',
    }, {
        id: NudgeType.WORKFLOWS_OVERDUE_TOGGLE,
        header: 'Overdue toggle',
        content: 'By default, only DUE workflows are shown when you land on this page, You can view DUE and OVERDUE workflows by toggling this button.',
    }, {
        id: NudgeType.WORKFLOWS_LIST_UPDATE_DUE_DATE,
        header: 'Update due date',
        content: 'Update the due date for a given workflow',
    }, {
        id: NudgeType.WORKFLOWS_LIST_RESUME,
        header: 'Resume workflow',
        content: 'Resume the execution of a given workflow',
    }, {
        id: NudgeType.WORKFLOWS_LIST_SHOW_MORE,
        header: 'Show more workflow options',
        content: 'Click on this button to show more options for the workflow',
    }]);

    return nudgesData;
}