import './App.scss';
import { BrowserRouter, Route } from 'react-router-dom';

import { Provider } from 'react-redux';
import store from './shared/store/main';

import OaaSApp from './components/OaaSApp';
import { Market } from './components/market/Market';


function App() {
    return  <Provider store={store}> 
        <BrowserRouter>
            <Route render={({location}) => {                
                if (location.pathname.startsWith('/marketplace')) {
                    return <Market />;
                } else {
                    return <OaaSApp />;
                }
            } } />
        </BrowserRouter>
    </Provider>;
}

export default App;
