export const FEATURES_AND_ENHANCEMENTS: Array<string> = [
    'Desktop - Add Total counts of data used by Widget to each widget subtitle',
    'Desktop - Continue and Go Back button usability',
    'Desktop - User profile panel now shows assigned locations',
    'Mobile - Dashboard and List pages UI design simplified and aligned with Desktop',
    'Mobile - More Page design simplified and aligned with Desktop',
    'Mobile - Consistent borders across the application',
    'Mobile - Overlay screens made smaller and consistent',
    'Admin - Allow clearing of variables and custom field values',
    'Admin - Get entities piece implemented to get Users of a Role, Members of a Type, Groups of a Type, Workflows for a Type and Locations of a Level - we can easily get these entities in a filtered manner',
    'Admin - Set location piece implemented to set location of a Individual or List of Members/Groups - we can generate Transfer workflows using this piece ',
    'Admin - Add Search Box to Customfield and Variable Type lists',
    'Desktop - Export to be streamed to file',
    'Desktop - Workflow export now contains data of Members/Groups (affiliation) and their locations ONLY for affiliated workflows',
    'Desktop - Report generated from the context of Report user in the Add Report form',
];

export const BUG_FIXES: Array<string> = [
    'Desktop - Search Inconsistency for various Choose/Group Choose lists',
    'Mobile - Search box on top of app doesn\'t work for some accounts',
    'Mobile - Mobile Update issue upon Login',
    'Mobile - Sync timestamp issue fixed',
    'Desktop and Mobile - Transfer not showing allowed Roles in some cases ',
    'Desktop - Date Navigation moving months between years did not work ',
    'Desktop - Allow search on Projects, Roles configuration screens',
    'Mobile - Keep "Sync Log" button active during sync process',
    'Admin - Duplication of Type duplicates the default fields',
    'Admin - "For" loop validation added',
    'Admin - Allow storage of multi select values using "Choose/Group Choose" pieces',
    'Desktop Shared Widget Threshold and Title issue',
    'Desktop - Users with READ permission for for workflow config cannot see flowchart',
];