import { CoOrdinates } from '../../helpers/common-types';
import { NormalizedModel, CustomFieldDeltaTrackable } from '../normalized-model';
import { MemberTypeState } from './types/types';
import { MemberTypeActionTypes } from './types/types';
import { MemberTypeActionActions } from './types/actions/types';
import { SynchronizeWorkflowsData } from '../workflows/types';
import { CustomFieldDataHolder } from '../custom-fields/types';

export const ADD_MEMBER = 'ADD_MEMBER';
export const UPDATE_MEMBER_REQUEST = 'UPDATE_MEMBER_REQUEST';
export const UPDATE_MEMBER = 'UPDATE_MEMBER';
export const DELETE_MEMBER = 'DELETE_MEMBER';
export const UN_ARCHIVE_MEMBER = 'UN_ARCHIVE_MEMBER';

export const BULK_ADD_MEMBERS = 'BULK_ADD_MEMBERS';
export const BULK_UPDATE_MEMBERS_REQUEST = 'BULK_UPDATE_MEMBERS_REQUEST';
export const BULK_UPDATE_MEMBERS = 'BULK_UPDATE_MEMBERS';
export const BULK_DELETE_MEMBERS = 'BULK_DELETE_MEMBERS';

export const ADD_GROUP_TO_MEMBER = 'ADD_GROUP_TO_MEMBER';
export const REMOVE_GROUP_FROM_MEMBER = 'REMOVE_GROUP_FROM_MEMBER';

export const RECALCULATE_COMPUTED_FIELDS_FOR_MEMBER = 'RECALCULATE_COMPUTED_FIELDS_FOR_MEMBER';
export const BULK_RECALCULATE_COMPUTED_FIELDS_FOR_MEMBER = 'BULK_RECALCULATE_COMPUTED_FIELDS_FOR_MEMBER';
export const RECOMPUTE_ALL_MEMBERS = 'RECOMPUTE_ALL_MEMBERS';

export const BULK_ADD_GROUPS_TO_MEMBERS = 'BULK_ADD_GROUPS_TO_MEMBERS';
export const BULK_REMOVE_GROUPS_FROM_MEMBERS = 'BULK_REMOVE_GROUPS_FROM_MEMBERS';

export const ADD_WORKFLOW_TO_MEMBER = 'ADD_WORKFLOW_TO_MEMBER';
export const REMOVE_WORKFLOW_FROM_MEMBER = 'REMOVE_WORKFLOW_FROM_MEMBER';

export const BULK_ADD_WORKFLOWS_TO_MEMBERS = 'BULK_ADD_WORKFLOWS_TO_MEMBERS';
export const BULK_REMOVE_WORKFLOWS_FROM_MEMBERS = 'BULK_REMOVE_WORKFLOWS_FROM_MEMBERS';

export const UPDATE_MEMBER_CUSTOM_FIELD_DATA = 'UPDATE_MEMBER_CUSTOM_FIELD_DATA';
export const UPDATE_MEMBER_COMPUTED_FIELD_DATA = 'UPDATE_MEMBER_COMPUTED_FIELD_DATA';
export const BULK_UPDATE_MEMBER_COMPUTED_FIELD_DATA = 'BULK_UPDATE_MEMBER_COMPUTED_FIELD_DATA';
export const UPDATE_MEMBERS_LOCATION_REQUEST = 'UPDATE_MEMBERS_LOCATION_REQUEST';
export const UPDATE_MEMBERS_LOCATION = 'UPDATE_MEMBERS_LOCATION';

export const SET_IS_SHOWING_ADD_FORM_FOR_MEMBERS = 'SET_IS_SHOWING_ADD_FORM_FOR_MEMBERS';
export const SET_IS_SHOWING_MODIFY_FORM_FOR_MEMBERS = 'SET_IS_SHOWING_MODIFY_FORM_FOR_MEMBERS';
export const SET_IS_SHOWING_FILTER_FORM_FOR_MEMBERS = 'SET_IS_SHOWING_FILTER_FORM_FOR_MEMBERS';
export const SET_IS_SHOWING_CSV_FORM_FOR_MEMBERS = 'SET_IS_SHOWING_CSV_FORM_FOR_MEMBERS';

export const SEARCH_MEMBER_TABLE = 'SEARCH_MEMBER_TABLE';
export const FILTER_MEMBER_TABLE = 'FILTER_MEMBER_TABLE';
export const SET_IS_FILTERING_FOR_MEMBER_TABLE = 'SET_IS_FILTERING_FOR_MEMBER_TABLE';
export const GO_TO_PAGE_MEMBER_TABLE = 'GO_TO_PAGE_MEMBER_TABLE';
export const SET_PAGE_SIZE_MEMBER_TABLE = 'SET_PAGE_SIZE_MEMBER_TABLE';
export const SORT_MEMBER_TABLE = 'SORT_MEMBER_TABLE';

export const UPDATE_MEMBERS_DATA = 'UPDATE_MEMBERS_DATA';

export const SYNCHRONIZE_MEMBERS_DATA = 'SYNCHRONIZE_MEMBERS_DATA';
export const APPEND_MEMBERS_DATA = 'APPEND_MEMBERS_DATA';
export const CLEAR_MEMBERS_DELTA = 'CLEAR_MEMBERS_DELTA';

export const UPDATE_MEMBER_DATA = 'UPDATE_MEMBER_DATA';
export const CLEAR_MEMBER_ENTRIES = 'CLEAR_MEMBER_ENTRIES';

export const FETCH_MEMBERS_FROM_SERVER = 'FETCH_MEMBERS_FROM_SERVER';
export const SET_TOTAL_NUMBER_OF_MEMBERS_FROM_SERVER = 'SET_TOTAL_NUMBER_OF_MEMBERS_FROM_SERVER';

export const SET_MEMBER_TYPE_FOR_CSV = 'SET_MEMBER_TYPE_FOR_CSV';

export interface INewMemberData {
    type: string,
    location: string,

    customFields: CustomFieldDataHolder,

    groups: {
        [groupTypeId: string]: Array<string>,
    },
}

export interface IUpdateableMemberData extends INewMemberData {
    id: string,
}

export interface IMember extends IUpdateableMemberData, CustomFieldDeltaTrackable {
    archived?: boolean,
    lastKnownLocation: CoOrdinates | undefined,
    lastMetTime: string | undefined,
    createdTime: string,

    workflows: {
        [workflowTypeId: string]: Array<string>,
    },
}

export interface MemberFilters {
    projects: Array<string>,
    types: Array<string>,
    locations: Array<string>,
    customFields: {
        [fieldId: string]: Array<string>,
    },
    createdDateRange: Array<string>,
    lastUpdatedDateRange: Array<string>,
    archived: boolean,
    unsynced: boolean,
}

export interface MemberState extends NormalizedModel<IMember> {

    types: MemberTypeState,
    isFiltering: boolean,

    noOfMembersReceivedInLastSync: number;
    totalNoOfMembers: number,

    pageSize: number,
    currentPageNumber: number,
    filters: MemberFilters,
    sort: {
        column: string | undefined,
        order: 'ASC' | 'DESC',
    },
    searchTerm: string | undefined,

    markedForIndex: Array<string>,

    isShowingAddForm?: boolean;
    isShowingModifyForm?: boolean;
    isShowingFilterForm?: boolean;
    isShowingCSVForm?: boolean;

    selectedMemberTypeForCSV?: string | undefined
}

export interface AddMemberAction {
    type: typeof ADD_MEMBER,
    payload: IMember,
    currentTime: string,
}

export interface UpdateMemberRequestAction {
    type: typeof UPDATE_MEMBER_REQUEST,
    payload: IUpdateableMemberData,
}

export interface UpdateMemberAction {
    type: typeof UPDATE_MEMBER,
    payload: IUpdateableMemberData,
    currentTime: string,
}

export interface BulkAddMembersAction {
    type: typeof BULK_ADD_MEMBERS,
    payload: Array<IMember>,
    currentTime: string,
}

export interface BulkUpdateMembersRequestAction {
    type: typeof BULK_UPDATE_MEMBERS_REQUEST,
    payload: Array<IUpdateableMemberData>,
}

export interface BulkUpdateMembersAction {
    type: typeof BULK_UPDATE_MEMBERS,
    payload: Array<IUpdateableMemberData>,
    currentTime: string,
}

export interface AddGroupToMemberAction {
    type: typeof ADD_GROUP_TO_MEMBER,
    memberId: string,
    groupId: string,
    groupTypeId: string,
}

export interface RemoveGroupFromMemberAction {
    type: typeof REMOVE_GROUP_FROM_MEMBER,
    memberId: string,
    groupId: string,
    groupTypeId: string,
}

export interface ChangeDeltaForMemberComputedFields {
    memberId: string,
    memberChanged: boolean,
    locationChanged: boolean,
    groupTypesChanged: Array<string>,
    workflowTypesChanged: Array<string>,
}

export interface RecalculateComputedFieldsForMemberAction extends ChangeDeltaForMemberComputedFields {
    type: typeof RECALCULATE_COMPUTED_FIELDS_FOR_MEMBER,
}

export interface BulkRecalculateComputedFieldsForMemberAction {
    type: typeof BULK_RECALCULATE_COMPUTED_FIELDS_FOR_MEMBER,
    payload: Array<ChangeDeltaForMemberComputedFields>
}

export interface MemberGroupLink {
    memberId: string;
    groupId: string;
    groupTypeId: string;
}

export interface BulkAddGroupsToMembersAction {
    type: typeof BULK_ADD_GROUPS_TO_MEMBERS;
    links: Array<MemberGroupLink>;
}

export interface BulkRemoveGroupsFromMembersAction {
    type: typeof BULK_REMOVE_GROUPS_FROM_MEMBERS;
    links: Array<MemberGroupLink>;
}

export interface AddWorkflowToMemberAction {
    type: typeof ADD_WORKFLOW_TO_MEMBER,
    memberId: string,
    workflowTypeId: string,
    workflowId: string,
}

export interface RemoveWorkflowFromMemberAction {
    type: typeof REMOVE_WORKFLOW_FROM_MEMBER,
    memberId: string,
    workflowTypeId: string,
    workflowId: string,
}

export interface MemberWorkflowLink {
    memberId: string;
    workflowId: string;
    workflowTypeId: string;
}

export interface BulkAddWorkflowsToMembersAction {
    type: typeof BULK_ADD_WORKFLOWS_TO_MEMBERS;
    links: Array<MemberWorkflowLink>;
}

export interface BulkRemoveWorkflowsFromMembersAction {
    type: typeof BULK_REMOVE_WORKFLOWS_FROM_MEMBERS;
    links: Array<MemberWorkflowLink>;
}

export interface DeleteMemberAction {
    type: typeof DELETE_MEMBER,
    id: string,
    currentTime: string,
}

export interface BulkDeleteMembersAction {
    type: typeof BULK_DELETE_MEMBERS,
    ids: Array<string>,
    currentTime: string,
}

export interface UnArchiveMemberAction {
    type: typeof UN_ARCHIVE_MEMBER,
    id: string,
    currentTime: string,
}

export interface UpdateMemberCustomFieldDataAction {
    type: typeof UPDATE_MEMBER_CUSTOM_FIELD_DATA,
    workflowId: string,
    memberId: string,
    customFieldData: CustomFieldDataHolder,
    currentTime: string,
}

export interface UpdateMemberComputedFieldDataAction {
    type: typeof UPDATE_MEMBER_COMPUTED_FIELD_DATA,
    memberId: string,
    customFieldData: CustomFieldDataHolder,
    currentTime: string,
}

export interface ComputedFieldUpdatePayloadForMember {
    memberId: string;
    customFieldData: CustomFieldDataHolder;
}

export interface BulkUpdateMemberComputedFieldDataAction {
    type: typeof BULK_UPDATE_MEMBER_COMPUTED_FIELD_DATA,
    payload: Array<ComputedFieldUpdatePayloadForMember>,
    currentTime: string,
}

export interface RecomputeAllMembersAction {
    type: typeof RECOMPUTE_ALL_MEMBERS,
}

export interface UpdateMembersLocationRequestAction {
    type: typeof UPDATE_MEMBERS_LOCATION_REQUEST,
    memberIds: Array<string>,
    locationId: string,
    currentTime: string,
}

export interface UpdateMembersLocationAction {
    type: typeof UPDATE_MEMBERS_LOCATION,
    memberIds: Array<string>,
    locationId: string,
    currentTime: string,
}


export interface SetIsShowingAddFormForMembersAction {
    type: typeof SET_IS_SHOWING_ADD_FORM_FOR_MEMBERS;
    showValue: boolean;
}

export interface SetIsShowingModifyFormForMembersAction {
    type: typeof SET_IS_SHOWING_MODIFY_FORM_FOR_MEMBERS;
    showValue: boolean;
}

export interface SetIsShowingFilterFormForMembersAction {
    type: typeof SET_IS_SHOWING_FILTER_FORM_FOR_MEMBERS;
    showValue: boolean;
}

export interface SetIsShowingCSVFormForMembersAction {
    type: typeof SET_IS_SHOWING_CSV_FORM_FOR_MEMBERS;
    showValue: boolean;
}

export interface SetMemberTypeForCSV {
    type: typeof SET_MEMBER_TYPE_FOR_CSV,
    id: string | undefined
}

export interface SearchMemberTableAction {
    type: typeof SEARCH_MEMBER_TABLE,
    searchTerm: string,
}

export interface FilterMemberTableAction {
    type: typeof FILTER_MEMBER_TABLE,
    projects: Array<string>,
    types: Array<string>,
    locations: Array<string>,
    customFields: {
        [customFieldId: string]: Array<string>,
    },
    createdDateRange: Array<string>,
    lastUpdatedDateRange: Array<string>,
    archived: boolean,
    unsynced: boolean,
}

export interface SetIsFilteringForMemberTableAction {
    type: typeof SET_IS_FILTERING_FOR_MEMBER_TABLE;
    isFiltering: boolean;
}

export interface GoToPageMemberTableAction {
    type: typeof GO_TO_PAGE_MEMBER_TABLE,
    pageNumber: number,
}

export interface SetPageSizeMemberTableAction {
    type: typeof SET_PAGE_SIZE_MEMBER_TABLE,
    pageSize: number,
}

export interface SortMemberTableAction {
    type: typeof SORT_MEMBER_TABLE,
    column: string,
    order: 'ASC' | 'DESC'
}

export interface UpdateMembersData {
    type: typeof UPDATE_MEMBERS_DATA,
    data: Array<IMember>,
    totalNoOfMembers: number,
}

export interface SynchronizeMembersData {
    type: typeof SYNCHRONIZE_MEMBERS_DATA,
    data: Array<IMember>,
}

export interface AppendMembersData {
    type: typeof APPEND_MEMBERS_DATA,
    data: Array<IMember>,
}

export interface ClearMembersDelta {
    type: typeof CLEAR_MEMBERS_DELTA,
}


export interface IUpdateMemberData {
    type: typeof UPDATE_MEMBER_DATA,
    data: MemberState,
}

export interface IClearMemberEntriesAction {
    type: typeof CLEAR_MEMBER_ENTRIES,
}

export interface IFetchMembersFromServerAction {
    type: typeof FETCH_MEMBERS_FROM_SERVER,
}

export interface ISetTotalNumberOfMembersFromServerAction {
    type: typeof SET_TOTAL_NUMBER_OF_MEMBERS_FROM_SERVER,
    totalNumberOfMembers: number,
}

type MemberActions = AddMemberAction | UpdateMemberRequestAction | UpdateMemberAction | BulkAddMembersAction | BulkUpdateMembersRequestAction | BulkUpdateMembersAction | AddGroupToMemberAction | RemoveGroupFromMemberAction | AddWorkflowToMemberAction | RemoveWorkflowFromMemberAction | BulkAddWorkflowsToMembersAction | BulkRemoveWorkflowsFromMembersAction | DeleteMemberAction | BulkDeleteMembersAction | UnArchiveMemberAction | UpdateMemberCustomFieldDataAction | UpdateMembersLocationRequestAction | UpdateMembersLocationAction | RecalculateComputedFieldsForMemberAction | BulkRecalculateComputedFieldsForMemberAction | BulkAddGroupsToMembersAction | BulkRemoveGroupsFromMembersAction | UpdateMemberComputedFieldDataAction | BulkUpdateMemberComputedFieldDataAction | RecomputeAllMembersAction | SetMemberTypeForCSV;

type MemberTableActions = SearchMemberTableAction | FilterMemberTableAction | SetIsFilteringForMemberTableAction | GoToPageMemberTableAction | SetPageSizeMemberTableAction | SortMemberTableAction | SetIsShowingAddFormForMembersAction | SetIsShowingModifyFormForMembersAction | SetIsShowingFilterFormForMembersAction | SetIsShowingCSVFormForMembersAction | IClearMemberEntriesAction | IFetchMembersFromServerAction | ISetTotalNumberOfMembersFromServerAction;

export type MemberActionTypes = MemberActions | MemberTypeActionTypes | MemberTypeActionActions | MemberTableActions | UpdateMembersData | SynchronizeMembersData | AppendMembersData | ClearMembersDelta | IUpdateMemberData | SynchronizeWorkflowsData;