import React, { Component } from 'react';
import styles from './ConstantPiece.module.scss';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem, { Option } from '../drop-down/ListItem';
import FlowchartPiece, { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../shared/store/types';
import { updateStatusPiece } from '../../../shared/store/flowchart/pieces/actions';
import { FlowchartContext, FlowchartInfoForPiece, PieceHighlightColour } from '../../../contexts/flowchart-context';
import DropDownSearchBox from '../drop-down/DropDownSearchBox';
import { getFilteredOptionsBySearch } from '../drop-down/DropDownSearchBox';

type OwnProps = {
    pieceId: string,
    statusIds: Array<string>,
    selectedStatusId: string | undefined,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    return {
        status: !!ownProps.selectedStatusId ? state.workflows.types.statuses.byId[ownProps.selectedStatusId] : undefined,
        statusesData: state.workflows.types.statuses,
    }
}



const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
    return {
        updateStatusPiece: (statusId: string | undefined) => dispatch(updateStatusPiece(ownProps.pieceId, statusId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type OwnState = {
    isHoveringOverMemberPiece: boolean,
    statusSearchTerm: string,
};

type Props = OwnProps & StateProps & DispatchProps & FlowchartPieceProps;

class ConnectedGroupedAnswerPiece extends Component<Props, OwnState> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isHoveringOverMemberPiece: false,
            statusSearchTerm: "",
        }
    }


    updateValidity = (isValid: boolean, flowchartContext: FlowchartInfoForPiece) => {
        if (!isValid) {
            const invalidPieceInfo = flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId);

            if (!invalidPieceInfo && flowchartContext.setInvalidPiece) {
                flowchartContext.setInvalidPiece(this.props.pieceId, flowchartContext.parentSplitPieceIds);
            }
        }

        if (isValid && flowchartContext.removeInvalidPiece && flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId)) {
            flowchartContext.removeInvalidPiece(this.props.pieceId);
        }
    }

    searchForStatus = (searchTerm: string) => {
        this.setState({ statusSearchTerm: searchTerm });
    }

    render() {
        return <FlowchartContext.Consumer>
            {
                flowchartContext => {
                    const statusPrompt = this.props.status ? ('Status > ' + this.props.status.name) : 'Status';

                    const highlightColor = flowchartContext.highlights && flowchartContext.highlights[this.props.pieceId];
                    let highlightClass = styles.noHighlight;

                    switch (highlightColor) {
                        case PieceHighlightColour.GREEN:
                            highlightClass = styles.addedHighlight;
                            break;
                        case PieceHighlightColour.YELLOW:
                            highlightClass = styles.updatedHighlight;
                            break;
                        case PieceHighlightColour.PURPLE:
                            highlightClass = styles.movedHighlight;
                            break;
                        case PieceHighlightColour.RED:
                            highlightClass = styles.deletedHighlight;
                            break;
                    }

                    let listEntries: Array<Option>;

                    listEntries = this.props.statusIds.map(statusId => {
                        const status = this.props.statusesData.byId[statusId];
                        return {
                            name: status.name,
                            value: status.id,
                        };
                    });

                    const isValid = !this.props.selectedStatusId || this.props.statusIds.includes(this.props.selectedStatusId);
                    this.updateValidity(isValid, flowchartContext);

                    listEntries = [{
                        name: 'Current Status',
                        value: '',
                    }].concat(listEntries);

                    const filteredEntries = getFilteredOptionsBySearch(listEntries, this.state.statusSearchTerm);

                    return (<FlowchartPiece {...this.props}>
                        <div className={highlightClass}>
                            <SelectBox isRounded selectionPromptText={statusPrompt} theme={isValid ? "aqua" : "red"}>
                                <DropDownList theme={isValid ? "aqua" : "red"} dismissAfterSelection={false}>
                                    <DropDownSearchBox
                                        handleSearchInputChange={this.searchForStatus}
                                        placeholder={"Search by name"}
                                        searchTerm={this.state.statusSearchTerm}
                                    />
                                    {filteredEntries.map(listEntry => <ListItem name={listEntry.name} value={listEntry.value} key={listEntry.value} theme={isValid ? "aqua" : "red"} onClick={this.props.updateStatusPiece} />)}
                                </DropDownList>
                            </SelectBox>
                        </div>
                    </FlowchartPiece>);
                }
            }
        </FlowchartContext.Consumer>
    }
}

const GroupedAnswerPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedGroupedAnswerPiece);

export default GroupedAnswerPiece;