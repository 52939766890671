import axios from "axios";

export let BASE_URL = '';

if (typeof window !== 'undefined') {
    if (window.location.hostname === 'localhost') {
        BASE_URL = 'http://localhost:8001';
        // BASE_URL = 'https://backend.staging.diceflow.in';
    } else if (window.location.hostname === 'diceflow.in' || window.location.hostname === 'm.diceflow.in' || window.location.hostname === 'www.diceflow.in' || window.location.hostname === 'www.m.diceflow.in') {
        BASE_URL = 'https://backend.diceflow.in';
    } else if (window.location.hostname === 'dev.diceflow.in' || window.location.hostname === 'm.dev.diceflow.in' || window.location.hostname === 'www.dev.diceflow.in' || window.location.hostname === 'www.m.dev.diceflow.in') {
        BASE_URL = 'https://backend.dev.diceflow.in';
    } else if (window.location.hostname === 'perf.diceflow.in' || window.location.hostname === 'm.perf.diceflow.in' || window.location.hostname === 'www.perf.diceflow.in' || window.location.hostname === 'www.m.perf.diceflow.in') {
        BASE_URL = 'https://backend.perf.diceflow.in';
    } else if (window.location.hostname === 'tor.diceflow.in' || window.location.hostname === 'm.tor.diceflow.in' || window.location.hostname === 'www.tor.diceflow.in' || window.location.hostname === 'www.m.tor.diceflow.in') {
        BASE_URL = 'https://backend.tor.diceflow.in';
    } else {
        BASE_URL = 'https://backend.staging.diceflow.in';
    }

    if (window.location.hostname !== 'localhost') {
        // Redirect user to login when authentication fails
        axios.interceptors.response.use(response => response, error => {
            if (error.response.status === 401) {
                window.localStorage.removeItem('myId');
                window.localStorage.removeItem('token');
                window.location.assign('/login');
            }
        })
    }
}