import React, { Component } from 'react';
import styles from './Checkbox.module.scss';
import toggleStyles from './Toggle.module.scss';
import { translatePhrase } from '../../shared/helpers/translation';
import uuid from 'uuid';


type OwnProps = {
    name: string,
    value: string,
    defaultChecked: boolean,
    isHighlighted?: boolean,

    toggle: (id: string) => void,
};

type OwnState = {
    checkboxKey: number,
    uniqueId: string,
}

class Checkbox extends Component<OwnProps, OwnState> {
    state: OwnState = {
        checkboxKey: 0,
        uniqueId: uuid.v4(),
    };

    static defaultProps = {
        defaultChecked: false,
    };

    handleClick = () => {
        this.props.toggle(this.props.value);
        this.setState((prevState, props) => {
            return {
                checkboxKey: prevState.checkboxKey,
            };
        })
    }

    render() {
        return (
            <div className={this.props.isHighlighted ? styles.booleanInputHolder + " " + styles.selectedOption : styles.booleanInputHolder}
                key={this.state.checkboxKey} onClick={this.handleClick}>
                <label htmlFor={this.state.uniqueId} className={styles.text}>{translatePhrase(this.props.name)}</label>
                <input id={this.state.uniqueId} type="checkbox" defaultChecked={this.props.defaultChecked} onChange={this.handleClick} />
                <section className={this.props.defaultChecked ? toggleStyles.on : toggleStyles.off}>
                    <div className={toggleStyles.slider}></div>
                </section>
            </div>
        );
    }
}

export default Checkbox;

