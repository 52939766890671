import React, { Component, createRef, RefObject } from 'react';
import styles from './FlowchartPiece.module.scss';
import Draggable, { DraggableEvent, DraggableData } from 'react-draggable';
import { PiecePositionState, Position } from '../../../shared/helpers/common-types';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { startPieceDrag, stopPieceDrag } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import { FlowchartContext } from '../../../contexts/flowchart-context';
import store from '../../../shared/store/main';

export interface OwnProps {
    pieceId: string,
    isDragDisabled?: boolean,
    initialPosition?: Position,

    onUpdatePosition?: (x: number, y: number) => void,
    detachPiece?: () => void,
    isolatePiece?: (pieceState: PiecePositionState) => void,
    removeIsolatedPiece?: (pieceId: string) => void,
};

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        draggedPiece: state.flowchart.pieces.lastDraggedPiece,
        targetPiece: state.flowchart.pieces.targetPiece,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        startPieceDrag: (pieceId: string, draggedPieceSplitParents: Array<string>, draggedPieceIfIndices: Array<number>) => dispatch(startPieceDrag(pieceId, draggedPieceSplitParents, draggedPieceIfIndices)),
        stopPieceDrag: () => dispatch(stopPieceDrag()),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

type State = {
    startingX: number,
    startingY: number,
}

class ConnectedFlowchartPiece extends Component<Props, State> {
    flowchartPiece: RefObject<HTMLDivElement> = createRef();

    state = {
        startingX: 0,
        startingY: 0,
    };

    handleDragStart = (e: DraggableEvent, data: DraggableData, draggedPieceSplitParents: Array<string>, draggedPieceIfIndices: Array<number>) => {

        /* 
            The draggable event is the underlying mouse/touch event. 
            We cannot stop it from propagating without stopping the mouse event from propagating.

            As such, since we have nested draggable components, we need to find a way to conditionally
            trigger the start of the drag without cancelling the mouse event (which we rely on to be propagated)

            The startPieceDrag dispatch method sets the dragging piece, as well as the isDragging attribute.
            Redux is synchronous, so we can be sure that the values are set before the exit of this function

            Since mapStateToProps is not guaranteed to be called before the next handleDragStart method, 
            we cannot rely on the react-redux wrapper. Hence we need to get the state from the store directly.

        */
        const isDragging = store.getState().flowchart.pieces.isDragging;

        if (!isDragging) {
            // Only start the dragging if no other piece is being dragged yet
            this.setState({
                startingX: data.x,
                startingY: data.y,
            });

            this.props.startPieceDrag(this.props.pieceId, draggedPieceSplitParents, draggedPieceIfIndices);
        } else {
            // If another piece is already being dragged, cancel this drag event.
            // (The return cancels only this drag event - the parent's drag start handlers will still be called)
            return false;
        }

        this.setState({
            startingX: data.x,
            startingY: data.y,
        });

        this.props.startPieceDrag(this.props.pieceId, draggedPieceSplitParents, draggedPieceIfIndices);
    }

    handleDragEnd = (flowchartHolderElement: HTMLElement | null, e: DraggableEvent, data: DraggableData) => {

        const deltaX = Math.abs(data.x - this.state.startingX);
        const deltaY = Math.abs(data.y - this.state.startingY);

        if (deltaX < 5 && deltaY < 5) {
            this.props.stopPieceDrag();
            return;  // Don't bother tracking changes for small movements
        }

        if (this.props.onUpdatePosition) {
            this.props.onUpdatePosition(data.x, data.y);
        }

        if (this.props.detachPiece) {
            this.props.detachPiece();
        }

        if (this.props.isolatePiece) {
            let x, y;

            if (flowchartHolderElement) {
                x = flowchartHolderElement.scrollLeft + window.innerWidth / 2;
            } else if ('clientX' in e) {
                x = e.clientX - 60;  // Subtract sidebar width
            } else {
                x = data.x;
            }

            if (flowchartHolderElement) {
                y = flowchartHolderElement.scrollTop + window.innerHeight / 2;
            } else if ('clientY' in e) {
                y = e.clientY - 70;  // Subtract header height
            } else {
                y = data.y;
            }

            this.props.isolatePiece({
                piece: this.props.pieceId,
                position: {
                    x,
                    y,
                },
            });
        }

        this.props.stopPieceDrag();
    };

    render() {

        return <FlowchartContext.Consumer>
            {
                (flowchartContext) => {

                    const isSelectedPiece = this.props.draggedPiece === this.props.pieceId;
                    const selectedClass = isSelectedPiece ? styles.selectedPiece : styles.normalPiece;

                    return <Draggable
                        nodeRef={this.flowchartPiece}
                        cancel={'input, .rdw-editor-wrapper *'}
                        defaultPosition={this.props.initialPosition}
                        disabled={this.props.isDragDisabled || flowchartContext.isReadonly}
                        onStart={(e: DraggableEvent, data: DraggableData) => this.handleDragStart(e, data, flowchartContext.parentSplitPieceIds, flowchartContext.parentIfPieceIndices)}
                        onStop={this.handleDragEnd.bind(this, flowchartContext.flowchartHolderElement)}>
                        <div
                            ref={this.flowchartPiece}
                            data-piece-id={this.props.pieceId}
                            className={`${this.props.isDragging && this.props.draggedPiece === this.props.pieceId ? styles.draggedPiece + ' dragged-piece' : selectedClass + ' children-clickable'}`}>
                            {this.props.children}
                        </div>
                    </Draggable>
                }
            }
        </FlowchartContext.Consumer>;
    }

}

const FlowchartPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedFlowchartPiece)

export default FlowchartPiece