import React, { Component } from 'react';

import { CardType } from '../../../widgets/card/Card';
import CardsList from '../../../widgets/card/CardsList';
import ModifyForm from '../../../widgets/card/ModifyForm';
import { INewStaticDataHolderData, IUpdateableStaticDataHolderData, IStaticDataHolder } from '../../../shared/store/static-info/types';
import EnhancedInputText from '../../../widgets/form/InputText';

export interface OwnProps {
    selectedId?: string,
    heading: string,
    isReadOnly?: boolean,
    isSearchable?: boolean,
    showCardCount?: boolean,

    onSelectCard: (id: string) => void,
    onUnSelectCard: () => void,
    onDuplicate: () => void,
    onExport: () => void,
    onImport: () => void,
}

export interface StateProps {
    restrictStructureChanges: boolean,

    cardsList: Array<CardType>,
    selectedCard?: IStaticDataHolder,
}

export interface DispatchProps {
    reOrderCards: (sourceIndex: number, destinationIndex: number) => void,
    addCard: (payload: INewStaticDataHolderData) => void,
    deleteCard: (id: string) => void,
    updateCard: (payload: IUpdateableStaticDataHolderData) => void,
}

type Props = OwnProps & StateProps & DispatchProps;

export interface OwnState {
    isShowingAddForm: boolean,
    isShowingModifyForm: boolean,
    modifyingCardName: string,
};

class StaticDataHolderVertical<TProps extends Props, TState extends OwnState> extends Component<TProps, TState> {

    handleSelectCard = (id: string) => {
        this.props.onSelectCard(id);
    }

    toggleModifyForm = () => {
        let toggledState = !this.state.isShowingAddForm;

        if (this.state.isShowingModifyForm) {
            this.setState({
                isShowingModifyForm: false,
                isShowingAddForm: false
            });
        } else {
            this.setState({
                isShowingAddForm: toggledState
            });
        }
    }

    editSelectedCard = () => {

        if (!this.props.selectedCard) {
            throw new Error('Cannot edit card since nothing is selected');
        }

        this.setState({
            isShowingModifyForm: true,
            modifyingCardName: this.props.selectedCard.name,
        });
    }

    updateCardName = (value: string) => {
        this.setState({
            modifyingCardName: value
        });
    }

    addCard = () => {

        if (typeof this.validateCard() === 'string') {
            return;
        }

        this.props.addCard({
            name: this.state.modifyingCardName,
        });

        this.setState({
            modifyingCardName: '',
            isShowingAddForm: false
        });
    }

    updateCard = () => {

        if (typeof this.validateCard() === 'string') {
            return;
        }

        if (!this.props.selectedCard) {
            return;
        }

        this.props.updateCard({
            id: this.props.selectedCard.id,
            name: this.state.modifyingCardName,
        });

        this.setState({
            isShowingModifyForm: false,
            modifyingCardName: '',
            isShowingAddForm: false
        });
    }

    validateCard = () => {
        if (!this.state.modifyingCardName) {
            return 'Enter a valid name';
        }

        return true;
    }

    render() {

        const modifyForm = <ModifyForm hideCancel isNew={!this.state.isShowingModifyForm} submitForm={this.state.isShowingModifyForm ? this.updateCard : this.addCard} cancelForm={this.toggleModifyForm} validateForm={this.validateCard}>
            <EnhancedInputText placeholder="Name" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardName} default={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.name : ''} key={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.id : 0} />
        </ModifyForm>;

        return <CardsList
            heading={this.props.heading}
            cards={this.props.cardsList}
            selectedCard={this.props.selectedCard}
            onSelectCard={this.handleSelectCard}
            onUnselectCard={this.props.onUnSelectCard}
            onDeleteCard={this.props.deleteCard}
            onEditCard={this.editSelectedCard}
            onReorderCards={this.props.reOrderCards}
            modifyForm={modifyForm}
            isShowingAddForm={this.state.isShowingAddForm}
            isShowingEditForm={this.state.isShowingModifyForm}
            onAddCard={this.toggleModifyForm}
            isDeleteRestricted={this.props.restrictStructureChanges}
            isReadOnly={this.props.isReadOnly}
            isSearchable={!!this.props.isSearchable}
            showCardCount={!!this.props.showCardCount}
            onDuplicate={this.props.onDuplicate}
            onExport={this.props.onExport}
            onImport={this.props.onImport}
        />
    }
}

export default StaticDataHolderVertical;