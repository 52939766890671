import React, { useState, useEffect } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import styles from './LoaderModal.module.scss'

interface OwnProps {
    indeterminate?: boolean,
    progress?: number,
}

export const CircularLoader: React.FC<OwnProps> = (props: OwnProps) => {
    const [loaderProgress, setLoaderProgress] = useState<number>(typeof props.progress !== 'undefined' ? props.progress : 0);

    useEffect(() => {
        if (typeof props.progress === 'undefined') {
            const loaderProgressInterval = window.setInterval(() => {
                if (loaderProgress === 90) {
                    setLoaderProgress(99);
                    window.clearInterval(loaderProgressInterval);
                } else {
                    setLoaderProgress(loaderProgress + 10);
                }        
            }, 1000);

            return () => {
                window.clearInterval(loaderProgressInterval);
            }
        }
    }, [])

    const progress = typeof props.progress === 'undefined' ? loaderProgress : props.progress;
    
    return <div className={styles.progressHolder}>
        <CircularProgressbar className={styles.progress}
            value={progress !== undefined || progress !== Infinity ? progress : 0}
            strokeWidth={10}
            styles={{
                path: {
                    stroke: document.documentElement.style.getPropertyValue('--primary-color') ? document.documentElement.style.getPropertyValue('--primary-color') : '#803f97',
                    strokeLinecap: 'round',
                    filter: 'brightness(1.6)'
                },
                trail: {
                    stroke: '#e6e6e6',
                    strokeLinecap: 'round',
                }}
            }
        />
        <span className={styles.progressCount}>  {progress}% </span>
    </div>
}