import React, { Component, ChangeEvent } from 'react';
import styles from './Flowchart.module.scss';
import { RouteComponentProps } from 'react-router';
import { Link, NavLink, Redirect } from "react-router-dom";
import { default as tabStyles } from '../../../widgets/tabs/Tabs.module.scss';
import { ReactComponent as ExternalIcon } from '../../../common/assets/help.svg';

import { ReactComponent as ChevronDownIcon } from '../../../assets/chevron-arrow-down.svg';

import { updateWorkflowTypeCustomFieldStartPiece, setIsolatedWorkflowTypeCustomFieldPiece, removeIsolatedWorkflowTypeCustomFieldPiece, registerWorkflowTypeCustomFieldVariable } from '../../../shared/store/workflows/types/actions';
import { setNextPiece, setInnerPiece, setConditionForIfPiece, setConditionPiece, setConditionNextPiece, setLoopVariable, setIterableVariable, setOperand, setLeftOperand, setRightOperand, setQuestionData, setMemberVariable, setDataStoreValue, setDataSetValue, setDataCopyVariable, setReturnValue, addPiece, deletePiece, setVariableForShow, setVariableForCustomField, setQuestionRequiredPiece, setQuestionDisabledPiece, setQuestionDefaultPiece, setQuestionImage, setLocationPiece, setPieceForList, setVariablePiece, setWorkflowAffiliationVariable, setHeading, setDate, setMonth, setYear, setMessage, addFullPiece, setUpdateDueDateValue, copyPiece, setEntity, setEntityType, setError, setQuestionHiddenPiece, setHiddenPieceForShow } from '../../../shared/store/flowchart/pieces/actions';
import { FlowchartPieceActions, PieceType, AllPieceTypes } from '../../../shared/store/flowchart/pieces/types';
import { ReactComponent as WorkflowIcon } from '../../../common/assets/flow-data.svg';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import uuid from 'uuid';

import { ApplicationState } from '../../../shared/store/types';
import { CategoryValue } from '../../flowchart/helpers/index';
import { piecesByCategory as customFieldPiecesByCategory, getComponentForLevelComputedField, getComponentForRoleComputedField, getComponentForUserComputedField, getComponentForMemberComputedField, getComponentForGroupComputedField, getComponentForWorkflowComputedField } from '../../flowchart/helpers/custom-field';
import { setIsolatedLevelCustomFieldPiece, removeIsolatedLevelCustomFieldPiece, registerLevelCustomFieldVariable } from '../../../shared/store/structure/level/actions';
import { setIsolatedRoleCustomFieldPiece, removeIsolatedRoleCustomFieldPiece, registerRoleCustomFieldVariable } from '../../../shared/store/structure/role/actions';
import { setIsolatedUserCustomFieldPiece, removeIsolatedUserCustomFieldPiece, registerUserCustomFieldVariable, recomputeAllUsers } from '../../../shared/store/users/actions';
import { setIsolatedMemberTypeCustomFieldPiece, removeIsolatedMemberTypeCustomFieldPiece, registerMemberTypeCustomFieldVariable } from '../../../shared/store/members/types/actions';
import { setIsolatedGroupTypeCustomFieldPiece, removeIsolatedGroupTypeCustomFieldPiece, registerGroupTypeCustomFieldVariable } from '../../../shared/store/groups/types/actions';
import { Permissions } from '../../../shared/store/permissions/types';
import { setIsFlowchartExpanded, setIsTopBarExpanded, clearIndeterminateMessage, clearInfoMessage, setIndeterminateMessage, setInfoMessage, setToastMessage } from '../../../shared/store/my-data/actions';
import { duplicatePiece, getAllPiecesInPiece, pastePiece } from '../../../shared/store/flowchart/helpers/pieces';
import { FlowchartContext, FlowchartInfoForPiece } from '../../../contexts/flowchart-context';
import { PiecePositionState } from '../../../shared/helpers/common-types';
import { translatePhrase } from '../../../shared/helpers/translation';
import { recomputeAllGroups } from '../../../shared/store/groups/actions';
import { recomputeAllMembers } from '../../../shared/store/members/actions';
import { recomputeAllWorkflows } from '../../../shared/store/workflows/actions';
import FlowchartWindow from '../../flowchart-window/FlowchartWindow';
import { NudgeType } from '../../../shared/store/my-data/types';
import Tabs from '../../../widgets/tabs/Tabs';
import { getTimeoutPromise } from '../../../shared/helpers/utilities';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canViewWorkflowConfiguration = state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.WRITE || state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.READ;

    return {
        showTranslations: false,
        translationsExpanded: false,
        canGoToList: true,
        canGoToConfiguration: canViewWorkflowConfiguration,
        isPartiallyLoaded: state.myData.isPartiallyLoaded,
        isLoaded: state.myData.isLoaded,
        isDragging: state.flowchart.pieces.isDragging,
        piecesData: state.flowchart.pieces,
        variablesData: state.flowchart.variables,
        applicationState: state,
        myPermissions: state.permissions.myPermissions,

        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece,
        selectedNudge: state.myData.selectedNudgeId,
        isFlowchartExpanded: state.myData.isFlowchartExpanded,
        lastCopiedPiece: state.flowchart.pieces.lastCopiedPiece,
        lastCopiedPieceFlowchartContext: state.flowchart.pieces.lastCopiedPieceFlowchartContext,
        selectedWorkflowType: state.workflows.types.selected ? state.workflows.types.byId[state.workflows.types.selected] : undefined,
    }
}



const mapDispatchToProps = (dispatch: Dispatch) => {
    var emptyMethod = () => { };

    const flowchartPieceActions: FlowchartPieceActions = {
        setNextPiece: (pieceId, value) => dispatch(setNextPiece(pieceId, value)),
        setInnerPiece: (pieceId, value) => dispatch(setInnerPiece(pieceId, value)),
        setConditionForIfPiece: (pieceId, index, value) => dispatch(setConditionForIfPiece(pieceId, index, value)),
        setConditionPiece: (pieceId, value) => dispatch(setConditionPiece(pieceId, value)),
        setConditionNextPiece: (pieceId, index, value) => dispatch(setConditionNextPiece(pieceId, index, value)),
        setLoopVariable: (pieceId, value) => dispatch(setLoopVariable(pieceId, value)),
        setIterableVariable: (pieceId, value) => dispatch(setIterableVariable(pieceId, value)),
        setOperand: (pieceId, value) => dispatch(setOperand(pieceId, value)),
        setLeftOperand: (pieceId, value) => dispatch(setLeftOperand(pieceId, value)),
        setRightOperand: (pieceId, value) => dispatch(setRightOperand(pieceId, value)),
        setQuestionData: (pieceId, value) => dispatch(setQuestionData(pieceId, value)),
        setMemberVariable: (pieceId, value) => dispatch(setMemberVariable(pieceId, value)),
        setRequiredPiece: (pieceId, value) => dispatch(setQuestionRequiredPiece(pieceId, value)),
        setDisabledPiece: (pieceId, value) => dispatch(setQuestionDisabledPiece(pieceId, value)),
        setHiddenPiece: (pieceId, value) => dispatch(setQuestionHiddenPiece(pieceId, value)),
        setDefaultPiece: (pieceId, value) => dispatch(setQuestionDefaultPiece(pieceId, value)),
        setImage: (pieceId, value) => dispatch(setQuestionImage(pieceId, value)),
        setDataStoreValue: (pieceId, value) => dispatch(setDataStoreValue(pieceId, value)),
        setDataSetValue: (pieceId, value) => dispatch(setDataSetValue(pieceId, value)),
        setDataCopyVariable: (pieceId, value) => dispatch(setDataCopyVariable(pieceId, value)),
        setDataForList: (pieceId, value) => dispatch(setPieceForList(pieceId, value)),
        setReturnVariable: (pieceId, value) => dispatch(setReturnValue(pieceId, value)),
        setLocationPiece: (pieceId, value) => dispatch(setLocationPiece(pieceId, value)),
        setVariableForShow: (pieceId, value) => dispatch(setVariableForShow(pieceId, value)),
        setHiddenPieceForShow: (pieceId, value) => dispatch(setHiddenPieceForShow(pieceId, value)),
        setVariableForCustomField: (pieceId, value) => dispatch(setVariableForCustomField(pieceId, value)),
        setVariablePiece: (pieceId, value) => dispatch(setVariablePiece(pieceId, value)),
        setAffiliationVariablePiece: (pieceId, value) => dispatch(setWorkflowAffiliationVariable(pieceId, value)),
        setHeadingPiece: (pieceId, value) => dispatch(setHeading(pieceId, value)),
        setMessage: (pieceId, value) => dispatch(setMessage(pieceId, value)),

        setDatePiece: (pieceId, value) => dispatch(setDate(pieceId, value)),
        setMonthPiece: (pieceId, value) => dispatch(setMonth(pieceId, value)),
        setYearPiece: (pieceId, value) => dispatch(setYear(pieceId, value)),
        setDueDatePiece: (pieceId, value) => dispatch(setUpdateDueDateValue(pieceId, value)),

        setEntity: (pieceId, value) => dispatch(setEntity(pieceId, value)),
        setEntityType: (pieceId, value) => dispatch(setEntityType(pieceId, value)),

        setErrorValue: (pieceId: string, value: string | undefined) => dispatch(setError(pieceId, value)),

        setStartPieceData: (customFieldId: string, payload: PiecePositionState) => dispatch(updateWorkflowTypeCustomFieldStartPiece(payload, customFieldId)),

        setFinsalPremium: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberFirstName: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberLastName: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberEmail: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberPhone: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberPan: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberState: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberCity: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberAddressLine1: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberAddressLine2: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberPinCode: (pieceId: string, value: string | undefined) => emptyMethod(),

        setFinsalUserEmail: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalUserPhone: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMember: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberDOB: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberGender: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberFatherName: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberMotherName: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberAnnualIncome: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberMaritalStatus: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalApplyForLoan: (pieceId: string, value: string | undefined) => emptyMethod(),
    };

    return {
        flowchartPieceActions,
        setIsolatedLevelCustomFieldPiece: (customFieldId: string, payload: PiecePositionState) => dispatch(setIsolatedLevelCustomFieldPiece(payload, customFieldId)),
        removeIsolatedLevelCustomFieldPiece: (customFieldId: string, pieceId: string) => dispatch(removeIsolatedLevelCustomFieldPiece(pieceId, customFieldId)),
        registerLevelCustomFieldVariable: (customFieldId: string, variableId: string) => dispatch(registerLevelCustomFieldVariable(variableId, customFieldId)),

        setIsolatedRoleCustomFieldPiece: (customFieldId: string, payload: PiecePositionState) => dispatch(setIsolatedRoleCustomFieldPiece(payload, customFieldId)),
        removeIsolatedRoleCustomFieldPiece: (customFieldId: string, pieceId: string) => dispatch(removeIsolatedRoleCustomFieldPiece(pieceId, customFieldId)),
        registerRoleCustomFieldVariable: (customFieldId: string, variableId: string) => dispatch(registerRoleCustomFieldVariable(variableId, customFieldId)),

        setIsolatedUserCustomFieldPiece: (customFieldId: string, payload: PiecePositionState) => dispatch(setIsolatedUserCustomFieldPiece(payload, customFieldId)),
        removeIsolatedUserCustomFieldPiece: (customFieldId: string, pieceId: string) => dispatch(removeIsolatedUserCustomFieldPiece(pieceId, customFieldId)),
        registerUserCustomFieldVariable: (customFieldId: string, variableId: string) => dispatch(registerUserCustomFieldVariable(variableId, customFieldId)),

        setIsolatedMemberTypeCustomFieldPiece: (customFieldId: string, payload: PiecePositionState) => dispatch(setIsolatedMemberTypeCustomFieldPiece(payload, customFieldId)),
        removeIsolatedMemberTypeCustomFieldPiece: (customFieldId: string, pieceId: string) => dispatch(removeIsolatedMemberTypeCustomFieldPiece(pieceId, customFieldId)),
        registerMemberTypeCustomFieldVariable: (customFieldId: string, variableId: string) => dispatch(registerMemberTypeCustomFieldVariable(variableId, customFieldId)),

        setIsolatedGroupTypeCustomFieldPiece: (customFieldId: string, payload: PiecePositionState) => dispatch(setIsolatedGroupTypeCustomFieldPiece(payload, customFieldId)),
        removeIsolatedGroupTypeCustomFieldPiece: (customFieldId: string, pieceId: string) => dispatch(removeIsolatedGroupTypeCustomFieldPiece(pieceId, customFieldId)),
        registerGroupTypeCustomFieldVariable: (customFieldId: string, variableId: string) => dispatch(registerGroupTypeCustomFieldVariable(variableId, customFieldId)),

        setIsolatedWorkflowTypeCustomFieldPiece: (customFieldId: string, payload: PiecePositionState) => dispatch(setIsolatedWorkflowTypeCustomFieldPiece(payload, customFieldId)),
        removeIsolatedWorkflowTypeCustomFieldPiece: (customFieldId: string, pieceId: string) => dispatch(removeIsolatedWorkflowTypeCustomFieldPiece(pieceId, customFieldId)),
        registerWorkflowTypeCustomFieldVariable: (customFieldId: string, variableId: string) => dispatch(registerWorkflowTypeCustomFieldVariable(variableId, customFieldId)),

        addPiece: (pieceId: string, pieceType: PieceType) => dispatch(addPiece(pieceId, pieceType)),
        addFullPiece: (pieceData: AllPieceTypes) => dispatch(addFullPiece(pieceData)),
        deletePiece: (pieceId: string) => dispatch(deletePiece(pieceId)),
        copyPiece: (pieceId: string, flowchartContext: string) => dispatch(copyPiece(pieceId, flowchartContext)),
        setToastMessage: (message: string) => dispatch(setToastMessage(message)),
        toggleFlowChartExpansion: (flag: boolean) => dispatch(setIsFlowchartExpanded(flag)),
        toggleTopBarExpansion: (flag: boolean) => dispatch(setIsTopBarExpanded(flag)),

        recomputeAllUsers: () => dispatch(recomputeAllUsers()),
        recomputeAllMembers: () => dispatch(recomputeAllMembers()),
        recomputeAllGroups: () => dispatch(recomputeAllGroups()),
        recomputeAllWorkflows: () => dispatch(recomputeAllWorkflows()),

        setIndeterminateMessage: (message: string) => dispatch(setIndeterminateMessage(message)),
        clearIndeterminateMessage: () => dispatch(clearIndeterminateMessage()),

        setInfoMessage: (message: string) => dispatch(setInfoMessage(message)),
        clearInfoMessage: () => dispatch(clearInfoMessage()),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps<{ fieldId: string }>;

type OwnState = {
    isWaitingForDrag: boolean,
    expandedCategory: string | undefined,
    searchTerm: string,
    isFlowchartExpanded: boolean,
}

class ConnectedComputedFieldFlowchart extends Component<Props, OwnState> {

    state = {
        isWaitingForDrag: false,
        expandedCategory: undefined,
        searchTerm: '',
        isFlowchartExpanded: false,
    };

    getEntityType = (customFieldId: string) => {
        let entityType: 'level' | 'role' | 'user' | 'member' | 'group' | 'workflow' | undefined;

        if (this.props.applicationState.structure.levels.customFields.byId.hasOwnProperty(customFieldId)) {
            entityType = 'level';
        } else if (this.props.applicationState.structure.roles.customFields.byId.hasOwnProperty(customFieldId)) {
            entityType = 'role';
        } else if (this.props.applicationState.users.customFields.byId.hasOwnProperty(customFieldId)) {
            entityType = 'user';
        } else if (this.props.applicationState.members.types.customFields.byId.hasOwnProperty(customFieldId)) {
            entityType = 'member';
        } else if (this.props.applicationState.groups.types.customFields.byId.hasOwnProperty(customFieldId)) {
            entityType = 'group';
        } else if (this.props.applicationState.workflows.types.customFields.byId.hasOwnProperty(customFieldId)) {
            entityType = 'workflow';
        }

        return entityType;
    }

    getCustomField = (fieldId: string) => {
        const entityType = this.getEntityType(fieldId);

        switch (entityType) {
            case 'level':
                return this.props.applicationState.structure.levels.customFields.byId[fieldId];
            case 'role':
                return this.props.applicationState.structure.roles.customFields.byId[fieldId];
            case 'user':
                return this.props.applicationState.users.customFields.byId[fieldId];
            case 'member':
                return this.props.applicationState.members.types.customFields.byId[fieldId];
            case 'group':
                return this.props.applicationState.groups.types.customFields.byId[fieldId];
            case 'workflow':
                return this.props.applicationState.workflows.types.customFields.byId[fieldId];
            default:
                throw new Error('Custom field with this ID does not exist');
        }

    }

    deletePiece = (pieceId: string) => {

        const customFieldId = this.props.match.params.fieldId;
        const customField = this.getCustomField(customFieldId);

        const pieceFound = !!customField.isolatedPieces.find(isolatedPiece => isolatedPiece.piece === pieceId);

        if (!pieceFound) {
            // You can only delete isolated pieces
            return;
        }

        const entityType = this.getEntityType(customFieldId);

        switch (entityType) {
            case 'level':
                this.props.removeIsolatedLevelCustomFieldPiece(customFieldId, pieceId);
                break;
            case 'role':
                this.props.removeIsolatedRoleCustomFieldPiece(customFieldId, pieceId);
                break;
            case 'user':
                this.props.removeIsolatedUserCustomFieldPiece(customFieldId, pieceId);
                break;
            case 'member':
                this.props.removeIsolatedMemberTypeCustomFieldPiece(customFieldId, pieceId);
                break;
            case 'group':
                this.props.removeIsolatedGroupTypeCustomFieldPiece(customFieldId, pieceId);
                break;
            case 'workflow':
                this.props.removeIsolatedWorkflowTypeCustomFieldPiece(customFieldId, pieceId);
                break;
            default:
                throw new Error('Custom field with this ID does not exist');
        }

        this.props.deletePiece(pieceId);
    }

    duplicatePiece = (pieceId: string, attachedPiece: boolean = false) => {
        const pieceToCopy = this.props.piecesData.byId[pieceId];
        const flowchartHolderElement = document.getElementById('flowchart-holder');

        const customFieldId = this.props.match.params.fieldId;

        if (pieceToCopy.type === PieceType.START) {
            return '';
        }

        if (!flowchartHolderElement) {
            throw new Error('This element needs to exist');
        }

        const newId = duplicatePiece(this.props.piecesData, this.props.addFullPiece, undefined, pieceId)

        if (!attachedPiece) {
            const entityType = this.getEntityType(customFieldId);

            switch (entityType) {
                case 'level':
                    this.props.setIsolatedLevelCustomFieldPiece(customFieldId, {
                        piece: newId,
                        position: {
                            x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                            y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                        },
                    });
                    break;
                case 'role':
                    this.props.setIsolatedRoleCustomFieldPiece(customFieldId, {
                        piece: newId,
                        position: {
                            x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                            y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                        },
                    });
                    break;
                case 'user':
                    this.props.setIsolatedUserCustomFieldPiece(customFieldId, {
                        piece: newId,
                        position: {
                            x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                            y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                        },
                    });
                    break;
                case 'member':
                    this.props.setIsolatedMemberTypeCustomFieldPiece(customFieldId, {
                        piece: newId,
                        position: {
                            x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                            y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                        },
                    });
                    break;
                case 'group':
                    this.props.setIsolatedGroupTypeCustomFieldPiece(customFieldId, {
                        piece: newId,
                        position: {
                            x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                            y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                        },
                    });
                    break;
                case 'workflow':
                    this.props.setIsolatedWorkflowTypeCustomFieldPiece(customFieldId, {
                        piece: newId,
                        position: {
                            x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                            y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                        },
                    });
                    break;
                default:
                    throw new Error('Custom field with this ID does not exist');
            }
        }

        return newId;
    }

    pastePiece = (pieceId: string, attachedPiece: boolean = false) => {

        if (this.props.lastCopiedPieceFlowchartContext === this.props.match.params.fieldId) {
            this.duplicatePiece(pieceId, attachedPiece);
            return;
        }

        const pieceToPaste = this.props.piecesData.byId[pieceId];
        const flowchartHolderElement = document.getElementById('flowchart-holder');

        const customFieldId = this.props.match.params.fieldId;

        if (pieceToPaste.type === PieceType.START) {
            return '';
        }

        if (!flowchartHolderElement) {
            throw new Error('This element needs to exist');
        }

        const newId = pastePiece(this.props.piecesData, this.props.addFullPiece, pieceId)

        if (!attachedPiece) {
            const entityType = this.getEntityType(customFieldId);

            switch (entityType) {
                case 'level':
                    this.props.setIsolatedLevelCustomFieldPiece(customFieldId, {
                        piece: newId,
                        position: {
                            x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                            y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                        },
                    });
                    break;
                case 'role':
                    this.props.setIsolatedRoleCustomFieldPiece(customFieldId, {
                        piece: newId,
                        position: {
                            x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                            y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                        },
                    });
                    break;
                case 'user':
                    this.props.setIsolatedUserCustomFieldPiece(customFieldId, {
                        piece: newId,
                        position: {
                            x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                            y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                        },
                    });
                    break;
                case 'member':
                    this.props.setIsolatedMemberTypeCustomFieldPiece(customFieldId, {
                        piece: newId,
                        position: {
                            x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                            y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                        },
                    });
                    break;
                case 'group':
                    this.props.setIsolatedGroupTypeCustomFieldPiece(customFieldId, {
                        piece: newId,
                        position: {
                            x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                            y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                        },
                    });
                    break;
                case 'workflow':
                    this.props.setIsolatedWorkflowTypeCustomFieldPiece(customFieldId, {
                        piece: newId,
                        position: {
                            x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                            y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                        },
                    });
                    break;
                default:
                    throw new Error('Custom field with this ID does not exist');
            }
        }

        return newId;
    }

    handleKeyPress = (e: KeyboardEvent) => {
        // Do not apply any shortcuts when you are typing something into an input element
        if (window.document.activeElement && window.document.activeElement.tagName === 'INPUT') {
            return;
        }

        switch (e.key) {
            case 'Backspace':
            case 'Delete':
                this.props.lastDraggedPiece && this.deletePiece(this.props.lastDraggedPiece);
                return;
            case 'D':
            case 'd':
                this.props.lastDraggedPiece && this.duplicatePiece(this.props.lastDraggedPiece);
                return;
            case 'C':
            case 'c':
                if (this.props.lastDraggedPiece && (e.ctrlKey || e.metaKey)) {
                    this.props.copyPiece(this.props.lastDraggedPiece, this.props.match.params.fieldId);
                    this.props.setToastMessage('The piece has been copied');
                }
                return;
            case 'V':
            case 'v':
                (e.ctrlKey || e.metaKey) && this.props.lastCopiedPiece && this.pastePiece(this.props.lastCopiedPiece);
                return;
        }
    }

    handleKeyLift = (e: KeyboardEvent) => {
        // Do not apply any shortcuts when you are typing something into an input element
        if (window.document.activeElement && (window.document.activeElement.tagName === 'INPUT' || window.document.activeElement.classList.contains('public-DraftEditor-content'))) {
            return;
        }

        switch (e.key) {
            case '/':
                document.getElementById('piece-search')?.focus();
                return;
        }
    }

    componentWillMount() {
        document.addEventListener('keydown', this.handleKeyPress);
        document.addEventListener('keyup', this.handleKeyLift);
    }

    recomputeEntitiesIfFlowchartChanged = async () => {
        const customFieldId = this.props.match.params.fieldId;
        const customField = this.getCustomField(customFieldId);

        if (!customField.startPiece?.piece) {
            return;
        }

        const piecesInFlowchart = getAllPiecesInPiece(this.props.piecesData, customField.startPiece?.piece);
        const areSomePiecesChanged = piecesInFlowchart.some(piece => {
            return this.props.piecesData.createdIds.has(piece.id) ||
                this.props.piecesData.updatedIds.has(piece.id) ||
                this.props.piecesData.deletedIds.has(piece.id);
        });

        if (!areSomePiecesChanged) {
            return;
        }

        let entityType = this.getEntityType(customFieldId);

        const parentId = customField.parentId ? customField.parentId : '';

        this.props.setIndeterminateMessage('Recomputing computed field values');
        await getTimeoutPromise(2000);

        switch (entityType) {

            case 'role':
                const role = this.props.applicationState.structure.roles.byId[parentId];
                const userIdsInRole = this.props.applicationState.users.allEntries.filter(userId => {
                    const user = this.props.applicationState.users.byId[userId];

                    return user.roles.includes(role.id);
                });

                this.props.recomputeAllUsers();

                break;

            case 'user':
                const allUserIds = this.props.applicationState.users.allEntries;

                this.props.recomputeAllUsers();
                break;

            case 'member':
                const memberType = this.props.applicationState.members.types.byId[parentId];
                const allMemberIdsOfType = this.props.applicationState.members.allEntries.filter(memberId => {
                    const member = this.props.applicationState.members.byId[memberId];

                    return member.type === memberType.id;
                });

                this.props.recomputeAllMembers();
                break;

            case 'group':
                const groupType = this.props.applicationState.groups.types.byId[parentId];
                const allGroupIdsOfType = this.props.applicationState.groups.allEntries.filter(groupId => {
                    const group = this.props.applicationState.groups.byId[groupId];

                    return group.type === groupType.id;
                });

                this.props.recomputeAllGroups();
                break;

            case 'workflow':
                const workflowType = this.props.applicationState.workflows.types.byId[parentId];
                const allWorkflowIdsOfType = this.props.applicationState.workflows.allEntries.filter(workflowId => {
                    const workflow = this.props.applicationState.workflows.byId[workflowId];

                    return workflow.type === workflowType.id;
                });

                this.props.recomputeAllWorkflows();
                break;
            default:
                break;
        }

        this.props.clearIndeterminateMessage();
        this.props.setInfoMessage('Completed recomputation');
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
        document.removeEventListener('keyup', this.handleKeyLift);

        this.recomputeEntitiesIfFlowchartChanged();
        if (this.props.isFlowchartExpanded) {
            this.props.toggleFlowChartExpansion(false);
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        // The drag event interferes with the click event. Put the change in flowchart state as the last thing in the event queue so that the click event is fired before pointer events are removed from the flowchart
        window.setTimeout(() => {
            this.setState({
                isWaitingForDrag: this.props.isDragging,
            });
        }, 500);

    }

    expandCategory = (category: string) => {
        this.setState(prevState => {
            return {
                expandedCategory: prevState.expandedCategory === category ? undefined : category,
            }
        });
    }

    addPiece = (pieceType: PieceType) => {
        const customFieldId = this.props.match.params.fieldId;
        const newId = uuid.v4();
        const flowchartHolderElement = document.getElementById('flowchart-holder');

        if (!flowchartHolderElement) {
            throw new Error('This element needs to exist');
        }

        this.props.addPiece(newId, pieceType);

        const entityType = this.getEntityType(customFieldId);

        switch (entityType) {
            case 'level':
                this.props.setIsolatedLevelCustomFieldPiece(customFieldId, {
                    piece: newId,
                    position: {
                        x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                        y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                    },
                });
                break;
            case 'role':
                this.props.setIsolatedRoleCustomFieldPiece(customFieldId, {
                    piece: newId,
                    position: {
                        x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                        y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                    },
                });
                break;
            case 'user':
                this.props.setIsolatedUserCustomFieldPiece(customFieldId, {
                    piece: newId,
                    position: {
                        x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                        y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                    },
                });
                break;
            case 'member':
                this.props.setIsolatedMemberTypeCustomFieldPiece(customFieldId, {
                    piece: newId,
                    position: {
                        x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                        y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                    },
                });
                break;
            case 'group':
                this.props.setIsolatedGroupTypeCustomFieldPiece(customFieldId, {
                    piece: newId,
                    position: {
                        x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                        y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                    },
                });
                break;
            case 'workflow':
                this.props.setIsolatedWorkflowTypeCustomFieldPiece(customFieldId, {
                    piece: newId,
                    position: {
                        x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                        y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                    },
                });
                break;
            default:
                throw new Error('Custom field with this ID does not exist');
        }


    }

    updateSearchTerm = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            searchTerm: e.target.value,
        });
    }

    toggleExpandFlowchart = () => {
        this.setState({ isFlowchartExpanded: !this.state.isFlowchartExpanded }, () => {
            this.props.toggleFlowChartExpansion(!this.props.isFlowchartExpanded);
            this.props.toggleTopBarExpansion(false)
        });
    }

    render() {

        if (!this.props.match) {
            return <div></div>;
        }

        if (!this.props.isLoaded && !this.props.isPartiallyLoaded) {
            return <div></div>;
        }

        const customFieldId = this.props.match.params.fieldId;

        if (!customFieldId) {
            return <div></div>
        }

        let entityType = this.getEntityType(customFieldId);

        let isReadable = true;
        let isWritable = false;


        if (!isReadable) {
            return <Redirect to="/dashboard" />;
        }

        switch (entityType) {
            case 'level':
                isWritable = this.props.myPermissions.general.Hierarchy === Permissions.WRITE;
                isReadable = isWritable || this.props.myPermissions.general.Hierarchy === Permissions.READ;
                break;

            case 'role':
                isWritable = this.props.myPermissions.general.Hierarchy === Permissions.WRITE;
                isReadable = isWritable || this.props.myPermissions.general.Hierarchy === Permissions.READ;
                break;

            case 'user':
                isWritable = this.props.myPermissions.general.UserConfiguration === Permissions.WRITE;
                isReadable = isWritable || this.props.myPermissions.general.UserConfiguration === Permissions.READ;
                break;

            case 'member':
                isWritable = this.props.myPermissions.general.MembersConfiguration === Permissions.WRITE;
                isReadable = isWritable || this.props.myPermissions.general.MembersConfiguration === Permissions.READ;
                break;

            case 'group':
                isWritable = this.props.myPermissions.general.GroupsConfiguration === Permissions.WRITE;
                isReadable = isWritable || this.props.myPermissions.general.GroupsConfiguration === Permissions.READ;
                break;

            case 'workflow':
                isWritable = this.props.myPermissions.general.WorkflowsConfiguration === Permissions.WRITE;
                isReadable = isWritable || this.props.myPermissions.general.WorkflowsConfiguration === Permissions.READ;
                break;
            default:
                break;
        }

        if (typeof entityType === 'undefined') {
            return <div></div>;
        }

        let startPiece: JSX.Element | undefined;
        let isolatedPieces: Array<JSX.Element | undefined> = [];

        const customField = this.getCustomField(customFieldId);
        let backLink = '';
        let configurationLink = '';
        let listLink = ''
        let tabButtons: JSX.Element | undefined;

        let projectValues: Array<string> = [];

        const parentId = customField.parentId ? customField.parentId : '';

        switch (entityType) {
            case 'level':
                const level = this.props.applicationState.structure.levels.byId[parentId];
                projectValues = [level.project];
                startPiece = customField.startPiece ? getComponentForLevelComputedField(customFieldId, this.props.applicationState.structure.levels, this.props.piecesData, this.props.variablesData, customField.variables, isWritable, this.props.flowchartPieceActions, this.props.setIsolatedLevelCustomFieldPiece.bind(this, customFieldId), this.props.removeIsolatedLevelCustomFieldPiece.bind(this, customFieldId), this.props.registerLevelCustomFieldVariable.bind(this, customFieldId), customField.startPiece.piece, undefined) : undefined;

                isolatedPieces = customField.isolatedPieces.map(isolatedPieceData => {
                    const isolatedPiece = isolatedPieceData.piece ? getComponentForLevelComputedField(customFieldId, this.props.applicationState.structure.levels, this.props.piecesData, this.props.variablesData, customField.variables, isWritable, this.props.flowchartPieceActions, this.props.setIsolatedLevelCustomFieldPiece.bind(this, customFieldId), this.props.removeIsolatedLevelCustomFieldPiece.bind(this, customFieldId), this.props.registerLevelCustomFieldVariable.bind(this, customFieldId), isolatedPieceData.piece, undefined, isolatedPieceData.position) : undefined;

                    return isolatedPiece;
                });

                backLink = '/structure/hierarchy';
                tabButtons = <React.Fragment>
                    <NavLink to='/structure/hierarchy' data-selector="workflows-list-tab" className={tabStyles.Tab} activeClassName={tabStyles.active}> {translatePhrase('Hierarchy')} </NavLink>
                    <NavLink to='/structure/locations' data-selector="groups-configuration-tab" className={tabStyles.Tab} activeClassName={tabStyles.active}> {translatePhrase('Locations')} </NavLink>
                    <NavLink to='/structure/permissions' data-selector="groups-configuration-tab" className={tabStyles.Tab} activeClassName={tabStyles.active}> {translatePhrase('Permissions')} </NavLink>
                </React.Fragment>
                break;

            case 'role':
                const role = this.props.applicationState.structure.roles.byId[parentId];
                const levelOfRole = this.props.applicationState.structure.levels.byId[role.level];
                projectValues = [levelOfRole.project];

                startPiece = customField.startPiece ? getComponentForRoleComputedField(customFieldId, this.props.applicationState.structure.roles, this.props.piecesData, this.props.variablesData, customField.variables, isWritable, this.props.flowchartPieceActions, this.props.setIsolatedRoleCustomFieldPiece.bind(this, customFieldId), this.props.removeIsolatedRoleCustomFieldPiece.bind(this, customFieldId), this.props.registerRoleCustomFieldVariable.bind(this, customFieldId), customField.startPiece.piece, undefined) : undefined;

                isolatedPieces = customField.isolatedPieces.map(isolatedPieceData => {
                    const isolatedPiece = isolatedPieceData.piece ? getComponentForRoleComputedField(customFieldId, this.props.applicationState.structure.roles, this.props.piecesData, this.props.variablesData, customField.variables, isWritable, this.props.flowchartPieceActions, this.props.setIsolatedRoleCustomFieldPiece.bind(this, customFieldId), this.props.removeIsolatedRoleCustomFieldPiece.bind(this, customFieldId), this.props.registerRoleCustomFieldVariable.bind(this, customFieldId), isolatedPieceData.piece, undefined, isolatedPieceData.position) : undefined;

                    return isolatedPiece;
                });

                backLink = '/users/configuration';
                tabButtons = <React.Fragment>
                    <NavLink to='/users/list' data-selector="users-list-tab" className={tabStyles.Tab} activeClassName={tabStyles.active}> {translatePhrase('List')} </NavLink>
                    <NavLink to='/users/configuration' data-selector="users-configuration-tab" className={tabStyles.Tab} activeClassName={tabStyles.active}> {translatePhrase('Configuration')} </NavLink>
                </React.Fragment>
                break;

            case 'user':
                startPiece = customField.startPiece ? getComponentForUserComputedField(customFieldId, this.props.applicationState.users, this.props.piecesData, this.props.variablesData, customField.variables, isWritable, this.props.flowchartPieceActions, this.props.setIsolatedUserCustomFieldPiece.bind(this, customFieldId), this.props.removeIsolatedUserCustomFieldPiece.bind(this, customFieldId), this.props.registerUserCustomFieldVariable.bind(this, customFieldId), customField.startPiece.piece, undefined) : undefined;

                isolatedPieces = customField.isolatedPieces.map(isolatedPieceData => {
                    const isolatedPiece = isolatedPieceData.piece ? getComponentForUserComputedField(customFieldId, this.props.applicationState.users, this.props.piecesData, this.props.variablesData, customField.variables, isWritable, this.props.flowchartPieceActions, this.props.setIsolatedUserCustomFieldPiece.bind(this, customFieldId), this.props.removeIsolatedUserCustomFieldPiece.bind(this, customFieldId), this.props.registerUserCustomFieldVariable.bind(this, customFieldId), isolatedPieceData.piece, undefined, isolatedPieceData.position) : undefined;

                    return isolatedPiece;
                });

                backLink = '/users/configuration';
                break;

            case 'member':
                const memberType = this.props.applicationState.members.types.byId[parentId];
                projectValues = [memberType.project];

                startPiece = customField.startPiece ? getComponentForMemberComputedField(customFieldId, this.props.applicationState.members.types, this.props.piecesData, this.props.variablesData, customField.variables, isWritable, this.props.flowchartPieceActions, this.props.setIsolatedMemberTypeCustomFieldPiece.bind(this, customFieldId), this.props.removeIsolatedMemberTypeCustomFieldPiece.bind(this, customFieldId), this.props.registerMemberTypeCustomFieldVariable.bind(this, customFieldId), customField.startPiece.piece, undefined) : undefined;

                isolatedPieces = customField.isolatedPieces.map(isolatedPieceData => {
                    const isolatedPiece = isolatedPieceData.piece ? getComponentForMemberComputedField(customFieldId, this.props.applicationState.members.types, this.props.piecesData, this.props.variablesData, customField.variables, isWritable, this.props.flowchartPieceActions, this.props.setIsolatedMemberTypeCustomFieldPiece.bind(this, customFieldId), this.props.removeIsolatedMemberTypeCustomFieldPiece.bind(this, customFieldId), this.props.registerMemberTypeCustomFieldVariable.bind(this, customFieldId), isolatedPieceData.piece, undefined, isolatedPieceData.position) : undefined;

                    return isolatedPiece;
                });

                backLink = '/members/configuration';
                tabButtons = <React.Fragment>
                    <NavLink to='/members/list' data-selector="workflows-list-tab" className={tabStyles.Tab} activeClassName={tabStyles.active}> {translatePhrase('List')} </NavLink>
                    <NavLink to='/members/configuration' data-selector="groups-configuration-tab" className={tabStyles.Tab} activeClassName={tabStyles.active}> {translatePhrase('Configuration')} </NavLink>
                </React.Fragment>
                break;

            case 'group':
                const groupType = this.props.applicationState.groups.types.byId[parentId];
                projectValues = [groupType.project];

                startPiece = customField.startPiece ? getComponentForGroupComputedField(customFieldId, this.props.applicationState.groups.types, this.props.piecesData, this.props.variablesData, customField.variables, isWritable, this.props.flowchartPieceActions, this.props.setIsolatedGroupTypeCustomFieldPiece.bind(this, customFieldId), this.props.removeIsolatedGroupTypeCustomFieldPiece.bind(this, customFieldId), this.props.registerGroupTypeCustomFieldVariable.bind(this, customFieldId), customField.startPiece.piece, undefined) : undefined;

                isolatedPieces = customField.isolatedPieces.map(isolatedPieceData => {
                    const isolatedPiece = isolatedPieceData.piece ? getComponentForGroupComputedField(customFieldId, this.props.applicationState.groups.types, this.props.piecesData, this.props.variablesData, customField.variables, isWritable, this.props.flowchartPieceActions, this.props.setIsolatedGroupTypeCustomFieldPiece.bind(this, customFieldId), this.props.removeIsolatedGroupTypeCustomFieldPiece.bind(this, customFieldId), this.props.registerGroupTypeCustomFieldVariable.bind(this, customFieldId), isolatedPieceData.piece, undefined, isolatedPieceData.position) : undefined;

                    return isolatedPiece;
                });

                backLink = '/groups/configuration';
                tabButtons = <React.Fragment>
                    <NavLink to='/groups/list' data-selector="workflows-list-tab" className={tabStyles.Tab} activeClassName={tabStyles.active}> {translatePhrase('List')} </NavLink>
                    <NavLink to='/groups/configuration' data-selector="groups-configuration-tab" className={tabStyles.Tab} activeClassName={tabStyles.active}> {translatePhrase('Configuration')} </NavLink>
                </React.Fragment>
                break;

            case 'workflow':
                const workflowType = this.props.applicationState.workflows.types.byId[parentId];
                projectValues = [workflowType.project];

                startPiece = customField.startPiece ? getComponentForWorkflowComputedField(customFieldId, this.props.applicationState.workflows.types, this.props.piecesData, this.props.variablesData, customField.variables, isWritable, this.props.flowchartPieceActions, this.props.setIsolatedWorkflowTypeCustomFieldPiece.bind(this, customFieldId), this.props.removeIsolatedWorkflowTypeCustomFieldPiece.bind(this, customFieldId), this.props.registerWorkflowTypeCustomFieldVariable.bind(this, customFieldId), customField.startPiece.piece, undefined) : undefined;

                isolatedPieces = customField.isolatedPieces.map(isolatedPieceData => {
                    const isolatedPiece = isolatedPieceData.piece ? getComponentForWorkflowComputedField(customFieldId, this.props.applicationState.workflows.types, this.props.piecesData, this.props.variablesData, customField.variables, isWritable, this.props.flowchartPieceActions, this.props.setIsolatedWorkflowTypeCustomFieldPiece.bind(this, customFieldId), this.props.removeIsolatedWorkflowTypeCustomFieldPiece.bind(this, customFieldId), this.props.registerWorkflowTypeCustomFieldVariable.bind(this, customFieldId), isolatedPieceData.piece, undefined, isolatedPieceData.position) : undefined;
                    return isolatedPiece;
                });

                backLink = '/workflows/configuration';
                tabButtons = <React.Fragment>
                    <NavLink to='/workflows/list' data-selector="workflows-list-tab" className={tabStyles.Tab} activeClassName={tabStyles.active}> {translatePhrase('List')} </NavLink>
                    <NavLink to='/workflows/configuration' data-selector="groups-configuration-tab" className={tabStyles.Tab} activeClassName={tabStyles.active}> {translatePhrase('Configuration')} </NavLink>
                </React.Fragment>
                break;
            default:
                break;
        }

        const piecesByCategory = customFieldPiecesByCategory;

        const categoryOrdering = [
            'Control',
            'Custom Fields',
            'Variables',
            'Boolean Operators',
            'Arithmetic Operators',
            'List Operators',
            'Date Operators',
        ];

        const otherCategories = Object.keys(piecesByCategory).filter(category => {
            return !categoryOrdering.includes(category);
        });

        const flowchartHolderElement = document.getElementById('flowchart-holder');
        const flowchartInfoForPiece: FlowchartInfoForPiece = {
            flowchartHolderElement,
            projects: projectValues,
            variables: customField.variables,
            isReadonly: !isWritable,
            parentSplitPieceIds: [],
            parentIfPieceIndices: [],
            searchTerm: this.state.searchTerm,
        };

        return (
            <section className={this.props.showTranslations && this.props.translationsExpanded ? styles.ConstrainedFocusSpace : styles.FocusSpace}>
                <header className={`${styles.pageHeader} ${this.props.isFlowchartExpanded && styles.pageHeaderExpanded}`}>
                    <h2 className={styles.heading} id="page-header"> <WorkflowIcon />  {translatePhrase(entityType)} : Computed </h2>
                    <Tabs>{tabButtons}</Tabs>
                </header>
                <div className={styles.innerFocus}>
                    <FlowchartWindow
                        selectedNudge={this.props.selectedNudge}
                        toggleExpandFlowChart={this.toggleExpandFlowchart}
                        isExpanded={this.props.isFlowchartExpanded}
                        flowchartType={customField}
                        goBack={() => this.props.history.push(backLink)}>
                        <div className={`${this.state.isWaitingForDrag ? styles.waitingForDragFlowchartHolder : styles.normalFlowchartHolder} ${this.state.isFlowchartExpanded && styles.expandedFlowchart} ${styles.flowchartWithPiceContainer}`}>
                            <section className={styles.piecesCollection}>
                                <section className={styles.pieceOptionWrapper}>
                                    <section className={styles.searchSection}>
                                        <input type="text"
                                            id="piece-search"
                                            placeholder="Search for pieces"
                                            className={styles.searchInput + " " + (NudgeType.FLOWCHART_SEARCH_PIECES === this.props.selectedNudge ? styles.nudgeActive : "")}
                                            value={this.state.searchTerm}
                                            onChange={this.updateSearchTerm} />
                                    </section>
                                </section>

                                {categoryOrdering.concat(otherCategories).map(category => {
                                    let piecesInCategory: Array<JSX.Element | undefined> = [];
                                    if (category in piecesByCategory) {
                                        piecesInCategory = (piecesByCategory as { [key: string]: CategoryValue; })[category].pieces.map(piece => (!!this.state.searchTerm && !piece.name.toLocaleLowerCase().includes(this.state.searchTerm.toLocaleLowerCase())) ? undefined :
                                            <section className={styles.pieceEntry} key={piece.name} onClick={() => this.addPiece(piece.type)}>
                                                <div className={styles.pieceEntryName}>{piece.name}</div>
                                                {piece.tooltip && <a target='_blank' rel="noreferrer" href={piece.tooltip}>
                                                    <span className={styles.infoIcon}> <ExternalIcon /> </span></a>
                                                }
                                            </section>).filter(pieceElement => typeof pieceElement !== 'undefined');
                                    }
                                    return piecesInCategory.length > 0 && <section key={category}>
                                        <section className={styles.categoryHeading} onClick={() => this.expandCategory(category)}>
                                            <div className={styles.categoryIndicator} style={{ background: (piecesByCategory as { [key: string]: CategoryValue; })[category].color }}></div>
                                            <div className={styles.categoryName}>{category}</div>
                                            <div className={this.state.expandedCategory === category ? styles.expandedCategoryChevron : styles.categoryChevron}><ChevronDownIcon /></div>
                                        </section>
                                        {(!!this.state.searchTerm || this.state.expandedCategory === category) && <div>
                                            {category in piecesByCategory && piecesInCategory}
                                        </div>}
                                    </section>;
                                })}
                            </section>

                            <div id="flowchart-holder" className={`${styles.flowchartContainer} ${!isWritable && ' react-drag-disabled'}`}>
                                <FlowchartContext.Provider value={flowchartInfoForPiece}>
                                    <h4>{customField.name}</h4>
                                    <div className={!isWritable ? styles.readOnlyChart : ''}>
                                        {startPiece}
                                    </div>
                                    {isolatedPieces.map((isolatedPiece, i) => <div className={styles.pieceHolder} key={i}>{isolatedPiece}</div>)}
                                </FlowchartContext.Provider>
                            </div>
                        </div>
                    </FlowchartWindow>
                </div>
            </section>
        );
    }
}

const ComputedFieldFlowchart = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectedComputedFieldFlowchart) as any);

export default ComputedFieldFlowchart;