import React, { Component, ReactNode } from 'react';
import { OwnProps as FlowchartPieceProps } from '../FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece } from '../../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../../shared/store/types';
import StepPieceEssentials from './StepPieceEssentials';
import { groupedNextPieceTarget } from '../utilities';


type GroupedStepPieceProps = {
    hasUpperLock?: boolean,
    hasLowerLock?: boolean,
    nextPiece?: JSX.Element,
    children?: ReactNode,
    theme?: 'indigo'|'pink'|'aqua'|'camo',
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = GroupedStepPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

class ConnectedGroupedStepPiece extends Component<Props> {

    handleHoverOverNextPiece = () => {

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        groupedNextPieceTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    render() {
        return <StepPieceEssentials {...this.props} handleHoverOverNextPiece={this.handleHoverOverNextPiece} />
    }
}

const GroupedStepPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedGroupedStepPiece);

export default GroupedStepPiece;