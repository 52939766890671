import React from 'react';
import { WorkflowTypeState, IWorkflowType } from '../../../shared/store/workflows/types/types';
import { VariableState } from '../../../shared/store/flowchart/variables/types';
import { PieceType, PieceState, FlowchartPieceActions } from '../../../shared/store/flowchart/pieces/types';

import { getComponent } from './index';
import { PiecePositionState, Position } from '../../../shared/helpers/common-types';
import ReturnPiece from '../pieces/ReturnPiece';
import { isUUID } from '../../../shared/helpers/utilities';
import CustomFieldPiece from '../pieces/CustomFieldPiece';
import ErrorPiece from '../pieces/ErrorPiece';
import GroupedAnswerPiece from '../pieces/GroupedAnswerPiece';
import StatusPiece from '../pieces/StatusPiece';
import SuccessPiece from '../pieces/SuccessPiece';
import { WorkflowTypeCustomField } from '../../../shared/store/custom-fields/types';

export function getComponentForQuestionPiece(typeState: WorkflowTypeState, piecesState: PieceState, variablesState: VariableState, workflowType: IWorkflowType, isEditable = true, flowchartPieceActions: FlowchartPieceActions, isolatePiece: (pieceState: PiecePositionState) => void, removeIsolatedPiece: (pieceId: string) => void, registerVariable: (variableId: string) => void, pieceId: string, detachPiece?: () => void, initialPosition?: Position) : JSX.Element {
    const workflowPiece = piecesState.byId[pieceId];

    const getTempInnerComponentShorthand = getComponentForQuestionPiece.bind({}, typeState, piecesState, variablesState, workflowType);
    const getTemp2InnerComponentShorthand = getTempInnerComponentShorthand.bind({}, isEditable, flowchartPieceActions, isolatePiece, removeIsolatedPiece);
    const getInnerComponentShorthand = getTemp2InnerComponentShorthand.bind({}, registerVariable);

    let customField: WorkflowTypeCustomField|undefined;
    let isShowingMemberPiece: boolean;
    let customFieldMemberVariable: JSX.Element|undefined;

    switch(workflowPiece.type) {

        case PieceType.CUSTOM_FIELD:
            customField = workflowPiece.customField ? typeState.customFields.byId[workflowPiece.customField] : undefined;

            isShowingMemberPiece = customField && !workflowPiece.customFieldOption ? workflowType.affiliation === 'group' && customField.affiliation === 'member' : false;

            customFieldMemberVariable = workflowPiece.memberVariablePiece ? getInnerComponentShorthand(workflowPiece.memberVariablePiece, flowchartPieceActions.setMemberVariable.bind({}, pieceId, undefined)) : undefined;

            return <CustomFieldPiece 
                pieceId={pieceId}

                customFieldIds={workflowType.customFields}
                selectedCustomFieldId={workflowPiece.customField}
                selectedCustomFieldOptionId={workflowPiece.customFieldOption}
                type="Workflow"

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}

                isShowingMemberVariablePiece={isShowingMemberPiece}
                memberVariablePiece={customFieldMemberVariable}
            />

        case PieceType.GROUPED_ANSWER:
            customField = workflowPiece.customField ? typeState.customFields.byId[workflowPiece.customField] : undefined;

            isShowingMemberPiece = customField ? workflowType.affiliation === 'group' && customField.affiliation === 'member' : false;

            customFieldMemberVariable = workflowPiece.memberVariablePiece ? getInnerComponentShorthand(workflowPiece.memberVariablePiece, flowchartPieceActions.setMemberVariable.bind({}, pieceId, undefined)) : undefined;

            const allowedCustomFieldIds = workflowType.customFields
            .filter(customFieldId => {
                const customField = typeState.customFields.byId[customFieldId];
                return !customField.isComputed;
            });

            return <GroupedAnswerPiece
                pieceId={pieceId}

                customFieldIds={allowedCustomFieldIds}
                selectedAnswerId={workflowPiece.customField}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}

                isShowingMemberVariablePiece={isShowingMemberPiece}
                memberVariablePiece={customFieldMemberVariable}
            />

        case PieceType.RETURN:
            const returnVariablePiece = workflowPiece.returnValue && isUUID(workflowPiece.returnValue) ? getInnerComponentShorthand(workflowPiece.returnValue, flowchartPieceActions.setReturnVariable.bind({}, pieceId, undefined)) : undefined;
            const returnVariableText = workflowPiece.returnValue;

            return <ReturnPiece
                pieceId={pieceId}
                returnVariablePiece={returnVariablePiece}
                returnVariableText={returnVariableText}
                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        
        case PieceType.ERROR:
            const errorVariableText = workflowPiece.error;
            const errorVariablePiece = workflowPiece.error && isUUID(workflowPiece.error) ? getInnerComponentShorthand(workflowPiece.error, flowchartPieceActions.setVariableForShow.bind({}, pieceId, undefined)) : undefined;

            return <ErrorPiece
                pieceId={pieceId}
                errorVariableText={errorVariableText}
                errorVariablePiece={errorVariablePiece}
                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        
        case PieceType.SUCCESS:

            return <SuccessPiece
                pieceId={pieceId}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        case PieceType.STATUS:

            return <StatusPiece 
                pieceId={pieceId}

                statusIds={workflowType.statuses}
                selectedStatusId={workflowPiece.statusId}

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />
        
        default:
            return getComponent(pieceId, piecesState, variablesState, workflowType.variables, getInnerComponentShorthand, isEditable, flowchartPieceActions, isolatePiece, removeIsolatedPiece, registerVariable, detachPiece, initialPosition);
    }
}