import { RouteParam, SetCurrentRoute, SET_CURRENT_ROUTE, RouteComponent, SET_COMPONENTS_WIDGET_SEARCH_TERM, ComponentWidgets, SetComponentsWidgetSearchTerm } from "./types";


const getRouteInfo = (pathname: string): { routeParam: RouteParam, routeComponent: RouteComponent } => {
    let routeParam: RouteParam;
    let routeComponent: RouteComponent;
    switch (pathname) {
        case '/structure/hierarchy':
            routeParam = RouteParam.STRUCTURE_HIERARCHY
            routeComponent = RouteComponent.STRUCTURE
            break;
        case '/structure/locations':
            routeParam = RouteParam.STRUCTURE_LOCATIONS
            routeComponent = RouteComponent.STRUCTURE
            break;
        case '/structure/permissions':
            routeParam = RouteParam.STRUCTURE_PERMISSIONS
            routeComponent = RouteComponent.STRUCTURE
            break;

        case '/users/list':
            routeParam = RouteParam.USERS_LIST
            routeComponent = RouteComponent.USERS
            break;
        case '/users/configuration':
            routeParam = RouteParam.USERS_CONFIGURATION
            routeComponent = RouteComponent.USERS
            break;

        case '/members/list':
            routeParam = RouteParam.MEMBERS_LIST
            routeComponent = RouteComponent.MEMBERS
            break;
        case '/members/configuration':
            routeParam = RouteParam.MEMBERS_CONFIGURATION
            routeComponent = RouteComponent.MEMBERS
            break;

        case '/groups/list':
            routeParam = RouteParam.GROUPS_LIST
            routeComponent = RouteComponent.GROUPS
            break;
        case '/groups/configuration':
            routeParam = RouteParam.GROUPS_CONFIGURATION
            routeComponent = RouteComponent.GROUPS
            break;

        case '/workflows/list':
            routeParam = RouteParam.WORKFLOWS_LIST
            routeComponent = RouteComponent.WORKFLOWS
            break;
        case '/workflows/configuration':
            routeParam = RouteParam.WORKFLOWS_CONFIGURATION
            routeComponent = RouteComponent.WORKFLOWS
            break;

        case '/reports/list':
            routeParam = RouteParam.REPORTS_LIST
            routeComponent = RouteComponent.REPORTS
            break;
        case '/reports/configuration':
            routeParam = RouteParam.REPORTS_CONFIGURATION
            routeComponent = RouteComponent.REPORTS
            break;

        case '/static-data/configuration':
            routeParam = RouteParam.DATA
            routeComponent = RouteComponent.DATA
            break;

        case '/languages':
            routeParam = RouteParam.LANGUAGES
            routeComponent = RouteComponent.LANGUAGES
            break;

        default:
            routeParam = RouteParam.DASHBOARD;
            routeComponent = RouteComponent.DASHBOARD;
            break;
    };

    return { routeParam, routeComponent }
}

export function setCurrentRoute(pathname: string): SetCurrentRoute {
    let routeParam = getRouteInfo(pathname).routeParam;
    let routeComponent = getRouteInfo(pathname).routeComponent;
    
    return {
        type: SET_CURRENT_ROUTE,
        routeComponent,
        routeParam,
    }
}


export function setSearchTermForComponentsWidget(widgetName: ComponentWidgets , searchTerm: string): SetComponentsWidgetSearchTerm {
    return {
        type: SET_COMPONENTS_WIDGET_SEARCH_TERM,
        widgetName,
        searchTerm
    }
}
