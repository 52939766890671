import { InternationalizationState } from './internationalization/types';
import { PermissionState } from './permissions/types';
import { OrganizationState } from './organization/types';
import { StructureState } from './structure/types';
import { FlowchartState } from './flowchart/types';
import { UserState } from './users/types';
import { MemberState } from './members/types';
import { GroupState } from './groups/types';
import { WorkflowState } from './workflows/types';
import { WidgetState } from './widgets/types';
import { ReportState } from './reports/types';
import { MyDataState } from './my-data/types';
import { StaticDataHolderState } from './static-info/types';
import { EventState } from './events/types';
import { ErrorState } from './errors/types';
import { PackageState } from './package/types';
import { SavedStates } from './state-save/types';
import { SyncLogState } from './sync-logs/types';

export interface ApplicationState {
    internationalization: InternationalizationState,
    permissions: PermissionState,
    organization: OrganizationState,
    structure: StructureState,
    flowchart: FlowchartState,
    users: UserState,
    members: MemberState,
    groups: GroupState,
    workflows: WorkflowState,
    widgets: WidgetState,
    staticInfo: StaticDataHolderState,
    reports: ReportState,
    myData: MyDataState,
    events: EventState,
    errors: ErrorState,
    packages: PackageState,
    savedStates: SavedStates,
    synclogState: SyncLogState
}


export const FETCH_LOCAL_DATA_REQUEST = 'FETCH_LOCAL_DATA_REQUEST';
export const FETCH_LOCAL_DATA = 'FETCH_LOCAL_DATA';
export const RESET_SESSION = 'RESET_SESSION';

export const SAVE_FILE_TO_IDB = "SAVE_FILE_TO_IDB";
export const DELETE_FILE_IN_IDB = "DELETE_FILE_IN_IDB";

export const SAVE_DATA_TO_SNAPSHOT_DURING_SYSTEM_UPDATE = "SAVE_DATA_TO_SNAPSHOT_DURING_SYSTEM_UPDATE";

export interface FetchLocalDataRequestAction {
    type: typeof FETCH_LOCAL_DATA_REQUEST,
};

export interface FetchLocalDataAction {
    type: typeof FETCH_LOCAL_DATA,
    data: ApplicationState,
};
export interface ResetSessionAction {
    type: typeof RESET_SESSION,
};

export interface SaveFilesToIDB {
    type: typeof SAVE_FILE_TO_IDB,
    id: string,
    file: File,
    commit: boolean,
};

export interface DeleteFilesInIDB {
    id: string,
    type: typeof DELETE_FILE_IN_IDB,
    commit: boolean,
};

export interface SaveDataToSnapshotDuringSystemUpdate {
    type: typeof SAVE_DATA_TO_SNAPSHOT_DURING_SYSTEM_UPDATE,
};


export type GlobalActions = FetchLocalDataRequestAction | FetchLocalDataAction | ResetSessionAction | SaveDataToSnapshotDuringSystemUpdate;