import { NudgeData } from "./index";
import { ApplicationState } from "../../../shared/store/types";
import { NudgeType } from "../../../shared/store/my-data/types";

export function getFlowchartNudges(applicationState: ApplicationState): Array<NudgeData> {

    let nudgesData: Array<NudgeData> = [];
    
    nudgesData = [{
        id: NudgeType.FLOWCHART_PUBLISH_TO_LIVE,
        header: 'Publish to Live',
        content: `When you are ready to publish to production, click on "Publish to Live" button. 
        The "Published" section is not editable and is a view only page.`,
    }, {
        id: NudgeType.FLOWCHART_COPY_LIVE_TO_BETA,
        header: 'Copy Live to Test',
        content: 'Click this button to copy the Published Flowchart to "Test"',
    }, {
        id: NudgeType.FLOWCHART_BACK_TO_CONFIGURATION,
        header: 'Back to Configuration',
        content: 'Click this button to go back to the configuration page',
    },
    {
        id: NudgeType.FLOWCHART_SEARCH_PIECES,
        header: 'Search pieces',
        content: 'Type into the search bar to search the existing pieces',
    },
    {
        id: NudgeType.FLOWCHART_PIECES,
        header: 'Pieces',
        content: 'Click this button to show all the pieces',
        link: 'https://support.diceflow.in/configuration/pieces',
    },
    {
        id: NudgeType.FLOWCHART_COOKBOOK,
        header: 'Cookbook',
        content: 'Here you will find some shortcuts to a combination of pieces',
    },
    {
        id: NudgeType.FLOWCHART_LIVE,
        header: 'Published View',
        content: 'You can see Published flowchart view',
    },
    {
        id: NudgeType.FLOWCHART_BETA,
        header: 'Test View',
        content: 'You can see Test flowchart view',
    },
    {
        id: NudgeType.FLOWCHART_EXPAND,
        header: 'Flowchart fullscreen',
        content: 'Click this button to make flowchart fullscreen',
    }];

    return nudgesData;
}