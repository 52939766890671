import React, { Component } from 'react';
import styles from './ConstantPiece.module.scss';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem, { Option } from '../drop-down/ListItem';
import FlowchartPiece, { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { ApplicationState } from '../../../shared/store/types';
import { updateGroupedAnswerPiece, setTargetPiece, setMemberVariable } from '../../../shared/store/flowchart/pieces/actions';
import Input from '../Input';
import { valuePieceSlotTarget } from './utilities';
import { WorkflowTypeContext } from '../../../contexts/workflow-type-context';
import { FlowchartContext, FlowchartInfoForPiece, PieceHighlightColour } from '../../../contexts/flowchart-context';
import DropDownSearchBox from '../drop-down/DropDownSearchBox';
import { getFilteredOptionsBySearch } from '../drop-down/DropDownSearchBox';

type OwnProps = {
    pieceId: string,
    customFieldIds: Array<string>,
    selectedAnswerId: string | undefined,

    isShowingMemberVariablePiece: boolean
    memberVariablePiece?: JSX.Element,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    return {
        state: state,
        customField: !!ownProps.selectedAnswerId ? state.workflows.types.customFields.byId[ownProps.selectedAnswerId] : undefined,
        customFieldData: state.workflows.types.customFields,
        customFieldOptionsData: state.workflows.types.customFieldOptions,


        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined
    }
}



const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
    return {
        updateGroupedAnswerPiece: (customField: string | undefined) => dispatch(updateGroupedAnswerPiece(ownProps.pieceId, customField)),

        setTargetPiece: (pieceId: string | undefined) => dispatch(setTargetPiece(pieceId)),
        setMemberVariable: (targetPieceId: string, draggedPieceId: string) => dispatch(setMemberVariable(targetPieceId, draggedPieceId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type OwnState = {
    isHoveringOverMemberPiece: boolean,
    answerSearchTerm: string,
};

type Props = OwnProps & StateProps & DispatchProps & FlowchartPieceProps;

class ConnectedGroupedAnswerPiece extends Component<Props, OwnState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            isHoveringOverMemberPiece: false,
            answerSearchTerm: "",
        }
    }


    handleHoverOverMemberPiece = () => {
        this.setState({
            isHoveringOverMemberPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfMemberPiece = () => {
        this.setState({
            isHoveringOverMemberPiece: false,
        });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && this.state.isHoveringOverMemberPiece) {

            this.props.setMemberVariable(this.props.pieceId, this.props.lastDraggedPiece.id);
            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverMemberPiece: false,
            });
        }
    }

    updateValidity = (isValid: boolean, flowchartContext: FlowchartInfoForPiece) => {
        if (!isValid) {
            const invalidPieceInfo = flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId);

            if (!invalidPieceInfo && flowchartContext.setInvalidPiece) {
                flowchartContext.setInvalidPiece(this.props.pieceId, flowchartContext.parentSplitPieceIds);
            }
        }

        if (isValid && flowchartContext.removeInvalidPiece && flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId)) {
            flowchartContext.removeInvalidPiece(this.props.pieceId);
        }
    }

    searchForAnswers = (searchTerm: string) => {
        this.setState({ answerSearchTerm: searchTerm });
    }

    render() {

        return <WorkflowTypeContext.Consumer>
            {workflowType => {

                return <FlowchartContext.Consumer>
                    {
                        flowchartContext => {

                            const highlightColor = flowchartContext.highlights && flowchartContext.highlights[this.props.pieceId];
                            let highlightClass = styles.noHighlight;

                            switch (highlightColor) {
                                case PieceHighlightColour.GREEN:
                                    highlightClass = styles.addedHighlight;
                                    break;
                                case PieceHighlightColour.YELLOW:
                                    highlightClass = styles.updatedHighlight;
                                    break;
                                case PieceHighlightColour.PURPLE:
                                    highlightClass = styles.movedHighlight;
                                    break;
                                case PieceHighlightColour.RED:
                                    highlightClass = styles.deletedHighlight;
                                    break;
                            }

                            let isValid = !this.props.selectedAnswerId || !workflowType || workflowType.customFields.includes(this.props.selectedAnswerId);

                            if (flowchartContext.highlightIncompletePieces) {
                                const isIncomplete = !this.props.selectedAnswerId;
                                isValid = isValid && !isIncomplete;
                            }

                            const currentGroupedAnswerName = this.props.customField ? this.props.customField.name : 'Select Answer';

                            let listEntries: Array<Option>;

                            const variablePiece = this.props.memberVariablePiece || <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverMemberPiece && !!this.props.targetPiece} isDisabled onMouseOver={this.handleHoverOverMemberPiece} onMouseOut={this.handleHoverOutOfMemberPiece} />

                            listEntries = this.props.customFieldIds.map(customFieldId => {
                                const customField = this.props.customFieldData.byId[customFieldId];
                                return {
                                    name: customField.name,
                                    value: customField.id,
                                }
                            });

                            const filteredEntries = getFilteredOptionsBySearch(listEntries, this.state.answerSearchTerm);

                            this.updateValidity(isValid, flowchartContext);

                            return (<FlowchartPiece {...this.props}>
                                <div className={highlightClass}>
                                    <SelectBox isRounded selectionPromptText={this.props.isShowingMemberVariablePiece ? currentGroupedAnswerName + ' for ' : currentGroupedAnswerName} dismissDropDownAfterSelection={false} theme={isValid ? "camo" : "red"} hasVariableSlot={this.props.isShowingMemberVariablePiece} variablePiece={variablePiece}>
                                        <DropDownList theme={isValid ? "camo" : "red"} dismissAfterSelection={false}>
                                            <DropDownSearchBox
                                                handleSearchInputChange={this.searchForAnswers}
                                                placeholder={"Search by name"}
                                                searchTerm={this.state.answerSearchTerm}
                                            />
                                            {filteredEntries.map(listEntry => <ListItem name={listEntry.name} value={listEntry.value} key={listEntry.value} theme={isValid ? "camo" : "red"} onClick={(selected) => this.props.updateGroupedAnswerPiece(selected)} />)}
                                        </DropDownList>
                                    </SelectBox>
                                </div>
                            </FlowchartPiece>);
                        }
                    }
                </FlowchartContext.Consumer>
            }}
        </WorkflowTypeContext.Consumer>
    }
}

const GroupedAnswerPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedGroupedAnswerPiece);

export default GroupedAnswerPiece;