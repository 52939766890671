import React, { Component } from 'react';
import styles from './List.module.scss';

import { connect } from 'react-redux';

import GroupsTable from './GroupsTable';

import { ApplicationState } from '../../../shared/store/types';

import { Permissions } from '../../../shared/store/permissions/types';
import { isUUID } from '../../../shared/helpers/utilities';

type OwnProps = {

};

const mapStateToProps = (state: ApplicationState) => {

    let isWritable = true;
    let isReadable = true;

    const isOnline = state.myData.isOnline

    const granularPermissions = new Set(Object.keys(state.permissions.myPermissions.groups));
    const areAllTypesHandledByGranularPermissions = state.groups.types.allEntries.every(typeId => granularPermissions.has(typeId));

    if (areAllTypesHandledByGranularPermissions && Object.keys(state.permissions.myPermissions.groups).length > 0) {
        isWritable = Object.keys(state.permissions.myPermissions.groups).some(groupTypeId => {
            return state.permissions.myPermissions.groups[groupTypeId] === Permissions.WRITE;
        });

        isReadable = isWritable || Object.keys(state.permissions.myPermissions.groups).some(groupTypeId => {
            return state.permissions.myPermissions.groups[groupTypeId] === Permissions.READ;
        });
    }

    return {
        filtersExpanded: false,
        read: isReadable,
        write: isWritable,
        isOnline,
        isSuperUser: !isUUID(state.myData.id),
        isSettled: (state.myData.isLoaded || state.myData.isPartiallyLoaded) && !state.myData.isPushingData,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = StateProps & OwnProps;

type OwnState = {

};

class ConnectedGroups extends Component<Props, OwnState> {
    render() {

        if (!this.props.isSettled) {
            return <section>
            </section>
        }

        return (
            <section>
                <div className={this.props.filtersExpanded ? styles.tableArea : styles.expandedTableArea}>
                    <GroupsTable isReadOnly={this.props.isOnline || !this.props.write} />
                </div>
            </section>
        );
    }
};

const Groups = connect(mapStateToProps)(ConnectedGroups);

export default Groups;