export const UPDATE_TRANSLATION = 'UPDATE_TRANSLATION';
export const UPDATE_MULTIPLE_TRANSLATIONS = 'UPDATE_MULTIPLE_TRANSLATIONS';
export const UPDATE_TRANSLATIONS = 'UPDATE_TRANSLATIONS';
export const CLEAR_TRANSLATIONS_DELTA = 'CLEAR_TRANSLATIONS_DELTA';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const SET_SHOW_UNTRANSLATED = 'SET_SHOW_UNTRANSLATED';

export interface LanguageTranslations {
    [word: string]: string,
}

export interface TranslationsState {
    byLanguage: {
        [languageId: string]: LanguageTranslations,
    },
    updatedWords: {
        [languageId: string]: Array<string>,
    }
    currentLanguage: string|undefined,
    showUntranslated: boolean,
    searchTerm?: string | undefined,    
}

interface UpdateTranslationAction {
    type: typeof UPDATE_TRANSLATION,
    originalWord: string,
    translatedWord: string,
    language: string,
}

interface UpdateMultipleTranslationsAction {
    type: typeof UPDATE_MULTIPLE_TRANSLATIONS,
    translations: Array<{originalWord: string, translatedWord: string}>,
    language: string,
}

interface UpdateTranslationsAction {
    type: typeof UPDATE_TRANSLATIONS,
    data: {
        [languageId: string]: LanguageTranslations
    },
};

interface ClearTranslationsDeltaAction {
    type: typeof CLEAR_TRANSLATIONS_DELTA,
};

interface SetSearchTermAction {
    type: typeof SET_SEARCH_TERM,
    searchTerm: string
};

interface SetShowUntranslated {
    type: typeof SET_SHOW_UNTRANSLATED,
    showUntranslated: boolean
};

export type TranslationActionTypes = UpdateTranslationAction | UpdateMultipleTranslationsAction | UpdateTranslationsAction | ClearTranslationsDeltaAction | SetSearchTermAction | SetShowUntranslated;