import { ChangeEvent, Component } from 'react';
import styles from './Table.module.scss';

import { RouteComponentProps, withRouter } from 'react-router';
import Table from '../../widgets/table/Table';
import { TableHeading, TableRow } from './Table';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../shared/store/types';

import { translatePhrase } from '../../shared/helpers/translation';

import { ReactComponent as ExportCSVIcon } from "../../assets/new-custom-icons/common/export.svg";
import { ReactComponent as RefreshIcon } from '../../assets/new-custom-icons/new-revision/refresh.svg';
import { ReactComponent as FilterIcon } from '../../common/assets/filter.svg';
import { ReactComponent as TransferIcon } from '../../common/assets/transfer.svg';
import { ReactComponent as DeleteIcon } from '../../assets/action-icons/archive.svg';
import { ReactComponent as UserMetricsIcon } from '../../common/assets/user-metrics.svg';
import { ReactComponent as PlusIcon } from '../../common/assets/plus.svg';
import { ReactComponent as ImportExportIcon } from '../../common/assets/export.svg';

import { ReactComponent as RightArrow } from '../../common/assets/right-arrow.svg';
import { ReactComponent as ChevronRight } from '../../common/assets/double-chevron-right.svg';
import { ReactComponent as CancelIcon } from '../../common/assets/close.svg';


import { IUpdateableWorkflowData } from '../../shared/store/workflows/types';
import { addWorkflow, filterWorkflowTable } from '../../shared/store/workflows/actions';
import Button from '../button/CommonButton';
import DecrementingCounter from './DecrementingCounter';
import ConfirmModal from '../loader/ConfirmModal';

import EnhancedRecentSearchesAndFiltersModify from './RecentSearchesAndFilters';
import { filterUserTable } from '../../shared/store/users/actions';
import { filterReportTable } from '../../shared/store/reports/actions';
import { filterGroupTable } from '../../shared/store/groups/actions';
import { filterMemberTable } from '../../shared/store/members/actions';
import LoaderModal from '../loader/LoaderModal';
import { getBulkActionsSelector } from '../../selectors/selectors';


interface RecentSearchesAndFilters {
    myId: string;
    memberSearchTerms: string[];
    groupSearchTerms: string[];
    workflowSearchTerms: string[];
    userSearchTerms: string[];
    reportSearchTerms: string[];
    memberFilterTerms: string[][];
    groupFilterTerms: string[][];
    workflowFilterTerms: string[][];
    userFilterTerms: string[][];
    reportFilterTerms: string[][];
    memberFilterObj: any[];
    groupFilterObj: any[];
    workflowFilterObj: any[];
    userFilterObj: any[];
    reportFilterObj: any[];

}

export enum SearchAndFilterType {
    MEMBER = "Member",
    GROUP = "Group",
    WORKFLOW = "Workflow",
    USER = "User",
    REPORT = "Report"
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    const { bulkActions } = getBulkActionsSelector()(state);

    return {
        myId: state.myData.id,
        workflowsData: state.workflows,
        workflowTypesData: state.workflows.types,
        membersFilters: state.members.filters,
        groupsFilters: state.groups.filters,
        workflowsFilters: state.workflows.filters,
        usersFilters: state.users.filters,
        reportFilters: state.reports.filters,
        MemberCustomFeilds: state.members.types.customFields,
        groupCustomFields: state.groups.types.customFields,
        workflowCustomFields: state.workflows.types.customFields,
        bulkActions
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        addWorkflow: (payload: IUpdateableWorkflowData) => dispatch(addWorkflow(payload)),
        filterGroupTable: (projects: Array<string>, types: Array<string>, locations: Array<string>, customFields: { [customFieldId: string]: Array<string> }, createdDateRange: Array<string>, lastUpdatedDateRange: Array<string>, unsynced: boolean, archived: boolean) => dispatch(filterGroupTable(projects, types, locations, customFields, createdDateRange, lastUpdatedDateRange, unsynced, archived)),
        filterMemberTable: (projects: Array<string>, types: Array<string>, locations: Array<string>, customFields: { [customFieldId: string]: Array<string> }, createdDateRange: Array<string>, lastUpdatedDateRange: Array<string>, unsynced: boolean, archived: boolean) => dispatch(filterMemberTable(projects, types, locations, customFields, createdDateRange, lastUpdatedDateRange, unsynced, archived)),
        filterWorkflowTable: (dues: Array<string>, projects: Array<string>, types: Array<string>, statuses: Array<string>, users: Array<string>, locations: Array<string>, otherUsers: Array<string>, affiliations: Array<string>, customFields: { [customFieldId: string]: Array<string> }, createdDateRange: Array<string>, lastUpdatedDateRange: Array<string>, lastWorkedOn: { startTime?: string, endTime?: string }, dueDateRange: Array<string>, unsynced: boolean, archived: boolean, isOutdated: boolean) => dispatch(filterWorkflowTable(dues, projects, types, statuses, users, locations, otherUsers, affiliations, customFields, createdDateRange, lastUpdatedDateRange, lastWorkedOn, dueDateRange, unsynced, archived, isOutdated)),
        filterUserTable: (projects: Array<string>, levels: Array<string>, roles: Array<string>, locations: Array<string>, unsynced: boolean, areLowerLevelsIncluded: boolean, archived: boolean, isOnline: boolean, isBetaTester: boolean, customFields: { [customFieldId: string]: Array<string> }) => dispatch(filterUserTable(projects, levels, roles, locations, unsynced, areLowerLevelsIncluded, archived, isOnline, isBetaTester, customFields)),
        filterReportTable: (projects: Array<string>, types: Array<string>, users: Array<string>, createdDateRange: Array<string>, lastUpdatedDateRange: Array<string>, generatedDateRange: Array<string>, unsynced: boolean, archived: boolean) => dispatch(filterReportTable(projects, types, users, createdDateRange, lastUpdatedDateRange, generatedDateRange, archived, unsynced)),
    };
}

type OwnProps = {
    entityType: string,
    headings: Array<TableHeading>,
    entries: Array<TableRow>,

    exportLink?: string,

    extraHeaderElements?: Array<JSX.Element>,

    sortedColumn?: string,
    sortType?: 'ASC' | 'DESC',
    isReadOnly: boolean,
    areFiltersExpanded: boolean,

    searchTerm?: string,
    placeHolderForSearch?: string,
    totalPages: number,
    totalNoOfEntries: number,
    pageNumber: number,
    isAddAllowed: boolean,
    isLimitReached?: boolean,
    isShowingAddForm: boolean,
    isShowingModifyForm: boolean,
    addForm: JSX.Element | undefined,

    isAddHighlighted?: boolean,
    isFilterHighlighted?: boolean,
    isSearchHighlighted?: boolean,
    isImportExportHighlighted?: boolean,
    isShowMoreHighlighted?: boolean,

    isShowingFilterForm?: boolean,
    isShowingTransferForm?: boolean,
    isShowingPerformanceDetails?: boolean,
    filterForm?: JSX.Element,
    transferForm?: JSX.Element,
    isShowingCSVForm?: boolean,

    isShowingUserSyncMetaLogExportForm?: boolean,
    syncMetaLogExportForm?: JSX.Element | undefined,

    tags?: Array<string>,

    notifyActionName?: string,
    selectedSmartFilter?: string,

    transferSearch?: (searchString: string) => void,

    onSort: (name: string) => void,
    onDelete: (id: string) => void,
    onUnArchive?: (id: string) => void,
    onNotify?: (id: string) => void,
    showAddForm: () => void,
    showModifyForm: () => void,
    showPerformanceDetails?: () => void,
    showPerformanceDetailsForUser?: (userId: string) => void,
    showExportSyncMetaLog?: () => void
    showFilterForm?: () => void,
    showTransferForm?: () => void,
    reindexTable?: () => void,
    handleCSVForm?: () => void,
    archiveEntities?: () => void,

    search: (searchTerm: string) => void,
    changePageSize: (pageSize: number) => void,
    goToPage: (pageNumber: number) => void,
    sort: (column: string, order: 'ASC' | 'DESC') => void,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps;

interface OwnState {
    searchTerm: string;
    searchTimer: number | undefined;
    newPageSize: number,
    newPageNumber: number,
    deleteTimer: number | undefined,
    recentSearchTerms: string[],
    recentFilterTerms: string[][],
    stringfiedFilterTerms: string[],
    recentFiltersObj: any[];
    isAskingForBulkArchive: boolean,
    isRemoveFilterTag: boolean,
    selectedSmartFilter: string | undefined,
    toastLoader: JSX.Element | undefined,
    filterTags: JSX.Element[] | undefined,
    pages: any[],
}

class ConnectedTableWithMeta extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            searchTerm: '',
            searchTimer: undefined,
            newPageSize: props.entries.length,
            newPageNumber: props.pageNumber,
            deleteTimer: undefined,
            recentSearchTerms: [],
            recentFilterTerms: [],
            stringfiedFilterTerms: [],
            recentFiltersObj: [],
            isAskingForBulkArchive: false,
            isRemoveFilterTag: false,
            selectedSmartFilter: "",
            toastLoader: undefined,
            filterTags: undefined,
            pages: [],
        };
    }

    componentDidUpdate(prevProps: Props) {
        const { totalPages, pageNumber, tags,
            isShowingAddForm, isShowingCSVForm, isShowingFilterForm,
            isShowingTransferForm, isShowingPerformanceDetails, isShowingUserSyncMetaLogExportForm } = this.props;

        const isBlur = isShowingAddForm || isShowingCSVForm! || isShowingFilterForm
            || isShowingTransferForm || isShowingPerformanceDetails || isShowingUserSyncMetaLogExportForm;

        const prevIsBlur = prevProps.isShowingAddForm || prevProps.isShowingCSVForm!
            || prevProps.isShowingFilterForm || prevProps.isShowingTransferForm
            || prevProps.isShowingPerformanceDetails || prevProps.isShowingUserSyncMetaLogExportForm!;

        if (prevProps.entries.length !== this.props.entries.length) {
            if (this.state.newPageSize === prevProps.entries.length) {
                this.setState({
                    newPageSize: this.props.entries.length,
                });
            }
        }

        if (prevProps.pageNumber !== this.props.pageNumber) {
            this.setState({
                newPageNumber: this.props.pageNumber,
            });
        }
        if (this.props.isShowingFilterForm === false && prevProps.isShowingFilterForm === true && this.props.tags?.length! > 0) {
            this.storeRecentSearchAndFilterTerms("filter");
        }

        if (this.props.selectedSmartFilter !== prevProps.selectedSmartFilter) {
            this.setState({ selectedSmartFilter: this.props.selectedSmartFilter });
        }

        if (JSON.stringify(tags) !== JSON.stringify(prevProps.tags) || isBlur !== prevIsBlur) {
            this.setFilterTags();
        }

        if (totalPages !== prevProps.totalPages || pageNumber !== prevProps.pageNumber) {
            this.setPages();
        }
    }

    static defaultProps = {
        isReadOnly: false,
        isAddAllowed: true,
        showViewInsteadOfEdit: false,
    }

    handleFilterClick = () => {
        this.props.showFilterForm && this.props.showFilterForm();
    }

    showToastLoader = (text: string) => {
        this.setState({
            toastLoader: <LoaderModal loaderText={[text]} isIndeterminate />
        });
    }

    callSearch = () => {
        if (this.props.searchTerm !== this.state.searchTerm) {
            this.showToastLoader(translatePhrase("Please wait"));
        }
        setTimeout(() => {
            this.props.search(this.state.searchTerm);
            this.setState({
                searchTimer: undefined,
                toastLoader: undefined
            });
        }, 1000);
    }

    handleSearch = (searchTerm: string) => {
        const searchTimer = window.setTimeout(() => {
            this.callSearch();
        }, 1000);

        if (this.state.searchTimer) {
            this.showToastLoader(translatePhrase("Please wait"));
            window.clearTimeout(this.state.searchTimer);
        }

        this.setState({
            searchTerm,
            searchTimer,
        });
    }

    incrementPageSize = () => {
        this.props.changePageSize(this.props.entries.length + 10);
    }

    decrementPageSize = () => {
        this.props.changePageSize(this.props.entries.length - 10);
    }

    updatePageSizeValue = (e: ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.currentTarget.value);

        if (!isNaN(value) && value) {
            this.setState({
                newPageSize: value,
            });
        }
    }

    submitPageSize = () => {
        this.props.changePageSize(this.state.newPageSize);
    }

    incrementPageNumber = () => {
        this.props.goToPage(this.props.pageNumber + 1);
    }

    decrementPageNumber = () => {
        this.props.goToPage(this.props.pageNumber - 1);
    }

    updatePageNumberValue = (e: ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.currentTarget.value);

        if (!isNaN(value) && value) {
            this.setState({
                newPageNumber: value,
            });
        }
    }

    submitPageNumber = () => {
        this.props.goToPage(this.state.newPageNumber);
    }

    handleSort = (column: string) => {
        const isNewColumn = this.props.sortedColumn !== column;
        const sortOrder = !isNewColumn && this.props.sortType === 'ASC' ? 'DESC' : 'ASC';

        this.props.sort(column, sortOrder);
    }

    goToPage() {
        this.props.goToPage(this.props.pageNumber + 1)
    }

    askForDelete = () => {
        this.setState({
            isAskingForBulkArchive: true,
        });
    }

    cancelDelete = () => {
        this.setState({
            isAskingForBulkArchive: false,
        });
    }

    markForDelete = () => {
        this.setState({
            isAskingForBulkArchive: false,
        });

        if (!this.props.archiveEntities) {
            return;
        }

        const timeout = window.setTimeout(() => {
            this.props.archiveEntities && this.props.archiveEntities();
            this.setState({
                deleteTimer: undefined
            });
        }, 5000);

        this.setState({
            deleteTimer: timeout
        });
    }

    revertDelete = () => {
        clearTimeout(this.state.deleteTimer);
        this.setState({
            deleteTimer: undefined
        });
    }

    removeRecentSearchTerm = (recentSearchTerm: string) => {
        this.setState(prevState => {
            return {
                recentSearchTerms: prevState.recentSearchTerms.filter(term => term !== recentSearchTerm),
            };
        });
    }

    storeRecentSearchAndFilterTerms = (type: "search" | "filter") => {
        if (type === "search") {
            if (!this.state.searchTerm) return;
        } else {
            if (!this.props.tags) return;
            if (this.state.selectedSmartFilter) return;
        }

        const myId = localStorage.getItem("myId")!;

        if (!localStorage.getItem("recentSearches")) {
            let recentSearchesAndFilters: RecentSearchesAndFilters[] = [];
            let newRecentSearchesAndFilters: RecentSearchesAndFilters = {
                myId: myId,
                memberSearchTerms: [],
                groupSearchTerms: [],
                workflowSearchTerms: [],
                userSearchTerms: [],
                reportSearchTerms: [],
                memberFilterTerms: [],
                groupFilterTerms: [],
                workflowFilterTerms: [],
                userFilterTerms: [],
                reportFilterTerms: [],
                memberFilterObj: [],
                groupFilterObj: [],
                workflowFilterObj: [],
                userFilterObj: [],
                reportFilterObj: [],
            };

            if (type === "search") {
                this.calculateRecentSearchTerms(this.state.searchTerm, this.state.recentSearchTerms);
            } else {
                if (this.props.tags) this.calculateRecentFilterTerms(this.props.tags, this.state.recentFilterTerms);
            }
            setTimeout(() => {
                switch (this.props.entityType) {
                    case SearchAndFilterType.MEMBER:
                        if (type === "search") {
                            newRecentSearchesAndFilters.memberSearchTerms = this.state.recentSearchTerms.slice();
                        } else {
                            newRecentSearchesAndFilters.memberFilterTerms = this.state.recentFilterTerms;
                            newRecentSearchesAndFilters.memberFilterObj = this.state.recentFiltersObj.slice();
                        }
                        break;
                    case SearchAndFilterType.GROUP:
                        if (type === "search") {
                            newRecentSearchesAndFilters.groupSearchTerms = this.state.recentSearchTerms.slice();
                        } else {
                            newRecentSearchesAndFilters.groupFilterTerms = this.state.recentFilterTerms;
                            newRecentSearchesAndFilters.groupFilterObj = this.state.recentFiltersObj.slice();
                        }
                        break;
                    case SearchAndFilterType.WORKFLOW:
                        if (type === "search") {
                            newRecentSearchesAndFilters.workflowSearchTerms = this.state.recentSearchTerms.slice();
                        } else {
                            newRecentSearchesAndFilters.workflowFilterTerms = this.state.recentFilterTerms;
                            newRecentSearchesAndFilters.workflowFilterObj = this.state.recentFiltersObj.slice();
                        }
                        break;
                    case SearchAndFilterType.USER:
                        if (type === "search") {
                            newRecentSearchesAndFilters.userSearchTerms = this.state.recentSearchTerms.slice();
                        } else {
                            newRecentSearchesAndFilters.userFilterTerms = this.state.recentFilterTerms;
                            newRecentSearchesAndFilters.userFilterObj = this.state.recentFiltersObj.slice();
                        }
                        break;
                    case SearchAndFilterType.REPORT:
                        if (type === "search") {
                            newRecentSearchesAndFilters.reportSearchTerms = this.state.recentSearchTerms.slice();
                        } else {
                            newRecentSearchesAndFilters.reportFilterTerms = this.state.recentFilterTerms;
                            newRecentSearchesAndFilters.reportFilterObj = this.state.recentFiltersObj.slice();
                        }
                        break;
                }
                recentSearchesAndFilters.push(newRecentSearchesAndFilters);
                localStorage.setItem("recentSearches", JSON.stringify(recentSearchesAndFilters));
                setTimeout(() => this.stringifyRecentFilterTerms(this.state.recentFilterTerms), 100);

            }, 500);
        } else {
            if (type === "search") {
                this.calculateRecentSearchTerms(this.state.searchTerm, this.state.recentSearchTerms);
            } else {
                if (this.props.tags) this.calculateRecentFilterTerms(this.props.tags, this.state.recentFilterTerms);
            }
            let recentSearchesAndFilters: RecentSearchesAndFilters[] = JSON.parse(localStorage.getItem("recentSearches")!);

            const myRecentSearchAndFilter: RecentSearchesAndFilters = recentSearchesAndFilters.find(search => search.myId === myId)!;
            const index = recentSearchesAndFilters.indexOf(myRecentSearchAndFilter!);

            let newRecentSearchAndFilter: RecentSearchesAndFilters = {
                myId: myId,
                memberSearchTerms: this.state.recentSearchTerms.slice(),
                groupSearchTerms: [],
                workflowSearchTerms: [],
                userSearchTerms: [],
                reportSearchTerms: [],
                memberFilterTerms: [],
                groupFilterTerms: [],
                workflowFilterTerms: [],
                userFilterTerms: [],
                reportFilterTerms: [],
                memberFilterObj: [],
                groupFilterObj: [],
                workflowFilterObj: [],
                userFilterObj: [],
                reportFilterObj: [],
            };
            if (type === "search") {
                this.setSearchTermBasedOnEntityType(myRecentSearchAndFilter, newRecentSearchAndFilter, recentSearchesAndFilters, index);
            } else {
                setTimeout(() => {
                    this.setFilterTermBasedOnEntityType(myRecentSearchAndFilter, newRecentSearchAndFilter, recentSearchesAndFilters, index);
                }, 500);
            }
        }
    }

    setSearchTermBasedOnEntityType = (myRecentSearchAndFilter: RecentSearchesAndFilters, newRecentSearchAndFilter: RecentSearchesAndFilters, recentSearchesAndFilters: RecentSearchesAndFilters[], index: number) => {
        switch (this.props.entityType) {
            case SearchAndFilterType.MEMBER:
                if (myRecentSearchAndFilter) {
                    recentSearchesAndFilters[index].memberSearchTerms = this.state.recentSearchTerms.slice();
                } else {
                    newRecentSearchAndFilter.memberSearchTerms = this.state.recentSearchTerms.slice();
                }
                break;
            case SearchAndFilterType.GROUP:
                if (myRecentSearchAndFilter) {
                    recentSearchesAndFilters[index].groupSearchTerms = this.state.recentSearchTerms.slice();
                } else {
                    newRecentSearchAndFilter.groupSearchTerms = this.state.recentSearchTerms.slice();
                }
                break;
            case SearchAndFilterType.WORKFLOW:
                if (myRecentSearchAndFilter) {
                    recentSearchesAndFilters[index].workflowSearchTerms = this.state.recentSearchTerms.slice();
                } else {
                    newRecentSearchAndFilter.workflowSearchTerms = this.state.recentSearchTerms.slice();
                }
                break;
            case SearchAndFilterType.USER:
                if (myRecentSearchAndFilter) {
                    recentSearchesAndFilters[index].userSearchTerms = this.state.recentSearchTerms.slice();
                } else {
                    newRecentSearchAndFilter.userSearchTerms = this.state.recentSearchTerms.slice();
                }
                break;
            case SearchAndFilterType.REPORT:
                if (myRecentSearchAndFilter) {
                    recentSearchesAndFilters[index].reportSearchTerms = this.state.recentSearchTerms.slice();
                } else {
                    newRecentSearchAndFilter.reportSearchTerms = this.state.recentSearchTerms.slice();
                }
                break;
        }
        if (!myRecentSearchAndFilter) recentSearchesAndFilters.push(newRecentSearchAndFilter);
        localStorage.setItem("recentSearches", JSON.stringify(recentSearchesAndFilters));
    }

    setFilterTermBasedOnEntityType = (myRecentSearchAndFilter: RecentSearchesAndFilters, newRecentSearchAndFilter: RecentSearchesAndFilters, recentSearchesAndFilters: RecentSearchesAndFilters[], index: number) => {
        switch (this.props.entityType) {
            case SearchAndFilterType.MEMBER:
                if (myRecentSearchAndFilter) {
                    recentSearchesAndFilters[index].memberFilterTerms = this.state.recentFilterTerms;
                    recentSearchesAndFilters[index].memberFilterObj = this.state.recentFiltersObj;
                } else {
                    newRecentSearchAndFilter.memberFilterTerms = this.state.recentFilterTerms;
                    newRecentSearchAndFilter.memberFilterObj = this.state.recentFiltersObj;
                }
                break;
            case SearchAndFilterType.GROUP:
                if (myRecentSearchAndFilter) {
                    recentSearchesAndFilters[index].groupFilterTerms = this.state.recentFilterTerms;
                    recentSearchesAndFilters[index].groupFilterObj = this.state.recentFiltersObj;
                } else {
                    newRecentSearchAndFilter.groupFilterTerms = this.state.recentFilterTerms;
                    newRecentSearchAndFilter.groupFilterObj = this.state.recentFiltersObj;
                }
                break;
            case SearchAndFilterType.WORKFLOW:
                if (myRecentSearchAndFilter) {
                    recentSearchesAndFilters[index].workflowFilterTerms = this.state.recentFilterTerms;
                    recentSearchesAndFilters[index].workflowFilterObj = this.state.recentFiltersObj;
                } else {
                    newRecentSearchAndFilter.workflowFilterTerms = this.state.recentFilterTerms;
                    newRecentSearchAndFilter.workflowFilterObj = this.state.recentFiltersObj;
                }
                break;
            case SearchAndFilterType.USER:
                if (myRecentSearchAndFilter) {
                    recentSearchesAndFilters[index].userFilterTerms = this.state.recentFilterTerms;
                    recentSearchesAndFilters[index].userFilterObj = this.state.recentFiltersObj;
                } else {
                    newRecentSearchAndFilter.userFilterTerms = this.state.recentFilterTerms;
                    newRecentSearchAndFilter.userFilterObj = this.state.recentFiltersObj;
                }
                break;
            case SearchAndFilterType.REPORT:
                if (myRecentSearchAndFilter) {
                    recentSearchesAndFilters[index].reportFilterTerms = this.state.recentFilterTerms;
                    recentSearchesAndFilters[index].reportFilterObj = this.state.recentFiltersObj;
                } else {
                    newRecentSearchAndFilter.reportFilterTerms = this.state.recentFilterTerms;
                    newRecentSearchAndFilter.reportFilterObj = this.state.recentFiltersObj;
                }
                break;
        }
        setTimeout(() => this.stringifyRecentFilterTerms(this.state.recentFilterTerms), 100);
        if (!myRecentSearchAndFilter) recentSearchesAndFilters.push(newRecentSearchAndFilter);
        localStorage.setItem("recentSearches", JSON.stringify(recentSearchesAndFilters));
    }

    stringifyRecentFilterTerms = (recentFilterTerms: string[][]) => {
        let stringfiedSearchTerms: string[] = [];
        for (let i = 0; i < recentFilterTerms.length; i++) {
            stringfiedSearchTerms[i] = recentFilterTerms[i].slice().join();
        }

        this.setState({ stringfiedFilterTerms: stringfiedSearchTerms });
    }

    calculateRecentSearchTerms = (searchTerm: string, recentSearchTerms: string[]) => {
        if (recentSearchTerms.includes(searchTerm)) {
            // if searchTerm already exist removes it
            const index = recentSearchTerms.indexOf(searchTerm);
            if (index > -1) {
                recentSearchTerms.splice(index, 1);
            }
        }
        if (recentSearchTerms.length < 5) {
            recentSearchTerms.unshift(searchTerm);
        } else {
            recentSearchTerms.pop();
            recentSearchTerms.unshift(searchTerm);
        }
        this.setState({ recentSearchTerms: recentSearchTerms });
    }

    calculateRecentFilterTerms = (filterTags: string[], recentFilterTerms: string[][]) => {
        let recentFiltersObjArr: any[] = []
        if (recentFilterTerms.length > 0) {
            const recentFiltersStringify = recentFilterTerms.map(recentFilterTerm => JSON.stringify(recentFilterTerm));
            const recentFiltersObj = this.state.recentFiltersObj.map(recentFilterObj => JSON.stringify(recentFilterObj));
            if (recentFiltersStringify.includes(JSON.stringify(filterTags))) {
                // if searchTerm already exist removes it
                const index = recentFiltersStringify.indexOf(JSON.stringify(filterTags));
                if (index > -1) {
                    recentFiltersStringify.splice(index, 1);
                    recentFiltersObj.splice(index, 1);
                }
            }
            recentFilterTerms = [];
            recentFilterTerms = recentFiltersStringify.map(recentFilter => JSON.parse(recentFilter)).slice();
            recentFiltersObjArr = recentFiltersObj.map(recentFilterObj => JSON.parse(recentFilterObj)).slice();
        }
        if (recentFilterTerms.length >= 5) {
            recentFilterTerms.pop();
            recentFiltersObjArr.pop();
        }
        recentFilterTerms.unshift(filterTags);
        recentFiltersObjArr.unshift(this.addRecentFilterObjBasedOnEntityType());

        this.setState({
            recentFilterTerms: recentFilterTerms,
            recentFiltersObj: recentFiltersObjArr,
        });
    }

    addRecentFilterObjBasedOnEntityType = () => {
        if (this.props.entityType === SearchAndFilterType.MEMBER) return this.props.membersFilters;
        if (this.props.entityType === SearchAndFilterType.GROUP) return this.props.groupsFilters;
        if (this.props.entityType === SearchAndFilterType.WORKFLOW) return this.props.workflowsFilters;
        if (this.props.entityType === SearchAndFilterType.USER) return this.props.usersFilters;
        if (this.props.entityType === SearchAndFilterType.REPORT) return this.props.reportFilters;
    }

    clearRecentSearches = () => {
        let recentSearches: RecentSearchesAndFilters[] = JSON.parse(localStorage.getItem("recentSearches")!);
        const myRecentSearch = recentSearches.find(search => search.myId === localStorage.getItem("myId"))!;

        if (this.props.entityType === SearchAndFilterType.MEMBER) {
            myRecentSearch.memberSearchTerms = [];
        } else if (this.props.entityType === SearchAndFilterType.GROUP) {
            myRecentSearch.groupSearchTerms = [];
        } else if (this.props.entityType === SearchAndFilterType.WORKFLOW) {
            myRecentSearch.workflowSearchTerms = [];
        } else if (this.props.entityType === SearchAndFilterType.USER) {
            myRecentSearch.userSearchTerms = [];
        } else if (this.props.entityType === SearchAndFilterType.REPORT) {
            myRecentSearch.reportSearchTerms = [];
        }
        this.setState({ recentSearchTerms: [] });
        localStorage.setItem("recentSearches", JSON.stringify(recentSearches));
    }

    clearRecentFilters = () => {
        let recentFilters: RecentSearchesAndFilters[] = JSON.parse(localStorage.getItem("recentSearches")!);
        const myRecentFilter = recentFilters.find(search => search.myId === localStorage.getItem("myId"))!;

        if (this.props.entityType === SearchAndFilterType.MEMBER) {
            myRecentFilter.memberFilterTerms = [];
            myRecentFilter.memberFilterObj = [];
        } else if (this.props.entityType === SearchAndFilterType.GROUP) {
            myRecentFilter.groupFilterTerms = [];
            myRecentFilter.groupFilterObj = [];
        } else if (this.props.entityType === SearchAndFilterType.WORKFLOW) {
            myRecentFilter.workflowFilterTerms = [];
            myRecentFilter.workflowFilterObj = [];
        } else if (this.props.entityType === SearchAndFilterType.USER) {
            myRecentFilter.userFilterTerms = [];
            myRecentFilter.userFilterObj = [];
        } else if (this.props.entityType === SearchAndFilterType.REPORT) {
            myRecentFilter.reportFilterTerms = [];
            myRecentFilter.reportFilterObj = [];
        }
        this.setState({
            recentFilterTerms: [],
            recentFiltersObj: [],
            stringfiedFilterTerms: [],
        });
        localStorage.setItem("recentSearches", JSON.stringify(recentFilters));
    }

    getRecentSearchTerms = () => {
        let recentSearchTerms: string[] = [];
        if (localStorage.getItem("recentSearches")) {
            let recentSearches: RecentSearchesAndFilters[] = JSON.parse(localStorage.getItem("recentSearches")!);
            const myId = localStorage.getItem("myId");
            const recentSearch: RecentSearchesAndFilters = recentSearches.find(search => search.myId === myId)!;

            if (this.props.entityType === SearchAndFilterType.MEMBER && recentSearch) {
                recentSearchTerms = recentSearch.memberSearchTerms.slice();
            } else if (this.props.entityType === SearchAndFilterType.GROUP && recentSearch) {
                recentSearchTerms = recentSearch.groupSearchTerms.slice();
            } else if (this.props.entityType === SearchAndFilterType.WORKFLOW && recentSearch) {
                recentSearchTerms = recentSearch.workflowSearchTerms.slice();
            } else if (this.props.entityType === SearchAndFilterType.USER && recentSearch) {
                recentSearchTerms = recentSearch.userSearchTerms.slice();
            } else if (this.props.entityType === SearchAndFilterType.REPORT && recentSearch) {
                recentSearchTerms = recentSearch.reportSearchTerms.slice();
            }
        }
        return recentSearchTerms;
    }

    getRecentFilterTerms = () => {
        let recentFilterTerms: string[][] = [];
        if (localStorage.getItem("recentSearches")) {
            let recentFilters: RecentSearchesAndFilters[] = JSON.parse(localStorage.getItem("recentSearches")!);
            const myId = localStorage.getItem("myId");
            const recentFilter: RecentSearchesAndFilters = recentFilters.find(search => search.myId === myId)!;

            if (this.props.entityType === SearchAndFilterType.MEMBER && recentFilter) {
                recentFilterTerms = recentFilter.memberFilterTerms;
            } else if (this.props.entityType === SearchAndFilterType.GROUP && recentFilter) {
                recentFilterTerms = recentFilter.groupFilterTerms;
            } else if (this.props.entityType === SearchAndFilterType.WORKFLOW && recentFilter) {
                recentFilterTerms = recentFilter.workflowFilterTerms;
            } else if (this.props.entityType === SearchAndFilterType.USER && recentFilter) {
                recentFilterTerms = recentFilter.userFilterTerms;
            } else if (this.props.entityType === SearchAndFilterType.REPORT && recentFilter) {
                recentFilterTerms = recentFilter.reportFilterTerms;
            }
        }
        if (!recentFilterTerms) recentFilterTerms = [];
        setTimeout(() => this.stringifyRecentFilterTerms(this.state.recentFilterTerms), 100);
        return recentFilterTerms;
    }

    getRecentFilterObj = () => {
        let recentFiltersobj: string[][] = [];
        if (localStorage.getItem("recentSearches")) {
            let recentFilters: RecentSearchesAndFilters[] = JSON.parse(localStorage.getItem("recentSearches")!);
            const myId = localStorage.getItem("myId");
            const recentFilterObj: RecentSearchesAndFilters = recentFilters.find(search => search.myId === myId)!;

            if (this.props.entityType === SearchAndFilterType.MEMBER && recentFilterObj) {
                recentFiltersobj = recentFilterObj.memberFilterObj;
            } else if (this.props.entityType === SearchAndFilterType.GROUP && recentFilterObj) {
                recentFiltersobj = recentFilterObj.groupFilterObj;
            } else if (this.props.entityType === SearchAndFilterType.WORKFLOW && recentFilterObj) {
                recentFiltersobj = recentFilterObj.workflowFilterObj;
            } else if (this.props.entityType === SearchAndFilterType.USER && recentFilterObj) {
                recentFiltersobj = recentFilterObj.userFilterObj;
            } else if (this.props.entityType === SearchAndFilterType.REPORT && recentFilterObj) {
                recentFiltersobj = recentFilterObj.reportFilterObj;
            }
        }
        if (!recentFiltersobj) recentFiltersobj = [];
        return recentFiltersobj;
    }

    removeFilter = (filter: string) => {
        console.log(filter)
        this.setState({ isRemoveFilterTag: !this.state.isRemoveFilterTag });
        const filterType = filter.split(":")[0].toLowerCase();
        switch (this.props.entityType) {
            case SearchAndFilterType.MEMBER:
                this.removeMembersTag(filterType);
                this.setState({ selectedSmartFilter: "" });
                break;
            case SearchAndFilterType.GROUP:
                this.removeGroupsTag(filterType);
                this.setState({ selectedSmartFilter: "" });
                break;
            case SearchAndFilterType.WORKFLOW:
                this.removeWorkflowsTag(filterType);
                this.setState({ selectedSmartFilter: "" });
                break;
            case SearchAndFilterType.USER:
                this.removeUsersTag(filterType);
                break;
            case SearchAndFilterType.REPORT:
                this.removeReportsTag(filterType);
                break;
        }
    }

    removeMembersTag = (filterType: string) => {
        let memberFilter = this.props.membersFilters;
        filterType = filterType.replaceAll(' ', '');
        let customfields = [];
        if (memberFilter.types.length === 1) {

            for (const customFieldId in memberFilter.customFields) {
                const customField = this.props.MemberCustomFeilds.byId[customFieldId];
                customfields.push(customField);
            }
        }

        switch (filterType) {
            case "projects":
                memberFilter.projects = [];
                break;
            case "types":
                memberFilter.types = [];
                break;
            case "locations":
                memberFilter.locations = [];
                break;
            case "unsynced":
                memberFilter.unsynced = false;
                break;
            case "archived":
                memberFilter.archived = false;
                break;
            case "createddaterange":
                memberFilter.createdDateRange = [];
                break;
            case "lastupdateddaterange":
                memberFilter.lastUpdatedDateRange = [];
                break;
            default:
                if (filterType) {
                    customfields.forEach(field => {
                        if (field && field.name.toLowerCase().replaceAll(' ', '') === filterType) {
                            delete memberFilter.customFields[field.id];
                        }
                    })
                }
        }

        this.applyMemberFilter(memberFilter);
    }

    removeGroupsTag = (filterType: string) => {
        let groupFilter = this.props.groupsFilters;
        filterType = filterType.replaceAll(' ', '');
        let customfields = [];
        if (groupFilter.types.length === 1) {
            for (const customFieldId in groupFilter.customFields) {
                const customField = this.props.groupCustomFields.byId[customFieldId];
                customfields.push(customField);
            }
        }

        switch (filterType) {
            case "projects":
                groupFilter.projects = [];
                break;
            case "types":
                groupFilter.types = [];
                break;
            case "locations":
                groupFilter.locations = [];
                break;
            case "unsynced":
                groupFilter.unsynced = false;
                break;
            case "archived":
                groupFilter.archived = false;
                break;
            case "createddaterange":
                groupFilter.createdDateRange = [];
                break;
            case "lastupdateddaterange":
                groupFilter.lastUpdatedDateRange = [];
                break;
            default:
                if (filterType) {
                    customfields.forEach(field => {
                        if (field && field.name.toLowerCase().replaceAll(' ', '') === filterType) {
                            delete groupFilter.customFields[field.id];
                        }
                    })
                }
        }
        this.applyGroupFilter(groupFilter);
    }

    removeWorkflowsTag = (filterType: string) => {
        let workflowsFilters = this.props.workflowsFilters;
        filterType = filterType.replaceAll(' ', '');
        let customfields = [];
        if (workflowsFilters.types.length === 1) {
            for (const customFieldId in workflowsFilters.customFields) {
                const customField = this.props.workflowCustomFields.byId[customFieldId];
                customfields.push(customField);
            }
        }

        switch (filterType) {
            case "projects":
                workflowsFilters.projects = [];
                break;
            case "types":
                workflowsFilters.types = [];
                break;
            case "locations":
                workflowsFilters.locations = [];
                break;
            case "unsynced":
                workflowsFilters.unsynced = false;
                break;
            case "archived":
                workflowsFilters.archived = false;
                break;
            case "createddaterange":
                workflowsFilters.createdDateRange = [];
                break;
            case "lastupdateddaterange":
                workflowsFilters.lastUpdatedDateRange = [];
                break;
            case "due":
                workflowsFilters.dues = [];
                break;
            case "duedaterange":
                workflowsFilters.dueDateRange = [];
                break;
            case "lastworkedondaterange":
                workflowsFilters.lastWorkedOn.startTime = undefined;
                workflowsFilters.lastWorkedOn.endTime = undefined;
                break;
            case "flows":
                workflowsFilters.users = [];
                break;
            case "affiliations":
                workflowsFilters.affiliations = [];
                break;
            case "statuses":
                workflowsFilters.statuses = [];
                break;
            case "otherusers":
                workflowsFilters.otherUsers = [];
                break;
            case "outdated":
                workflowsFilters.isOutdated = false;
                break;
            default:
                if (filterType) {
                    customfields.forEach(field => {
                        if (field && field.name.toLowerCase().replaceAll(' ', '') === filterType) {
                            delete workflowsFilters.customFields[field.id];
                        }
                    })
                }
        }

        this.applyWorkflowFilter(workflowsFilters);
    }

    removeUsersTag = (filterType: string) => {
        let userFilters = this.props.usersFilters;
        filterType = filterType.replaceAll(' ', '');

        switch (filterType) {
            case "projects":
                userFilters.projects = [];
                break;
            case "locations":
                userFilters.locations = [];
                break;
            case "unsynced":
                userFilters.unsynced = false;
                break;
            case "archived":
                userFilters.archived = false;
                break;
            case "roles":
                userFilters.roles = [];
                break;
            case "areLowerLevelsIncluded":
                userFilters.areLowerLevelsIncluded = false;
                break;
            case "levels":
                userFilters.levels = [];
                break;
            case "isonline":
                userFilters.isOnline = false;
                break;
            case "isbetatester":
                userFilters.isBetaTester = false;
                break;
        }
        this.applyUserFilter(userFilters);
    }

    removeReportsTag = (filterType: string) => {
        let reportFilters = this.props.reportFilters;
        filterType = filterType.replaceAll(' ', '');

        switch (filterType) {
            case "projects":
                reportFilters.projects = [];
                break;
            case "types":
                reportFilters.types = [];
                break;
            case "unsynced":
                reportFilters.unsynced = false;
                break;
            case "archived":
                reportFilters.archived = false;
                break;
            case "users":
                reportFilters.users = [];
                break;
            case "lastupdateddaterange":
                reportFilters.lastUpdatedDateRange = [];
                break;
            case "generateddaterange":
                reportFilters.generatedDateRange = [];
                break;
            case "createddaterange":
                reportFilters.createdDateRange = [];
                break;

        }

        this.applyReportFIlter(reportFilters);
    }


    applyWorkflowFilter = (selectedFilter: any) => {
        this.showToastLoader(translatePhrase("Please wait"));

        setTimeout(() => {
            this.props.filterWorkflowTable(
                selectedFilter.dues,
                selectedFilter.projects,
                selectedFilter.types,
                selectedFilter.statuses,
                selectedFilter.users,
                selectedFilter.locations,
                selectedFilter.otherUsers,
                selectedFilter.affiliations,
                selectedFilter.customFields,
                selectedFilter.createdDateRange,
                selectedFilter.lastUpdatedDateRange,
                selectedFilter.lastWorkedOn,
                selectedFilter.dueDateRange,
                selectedFilter.unsynced,
                selectedFilter.archived,
                selectedFilter.isOutdated,
            );

            this.setState({
                toastLoader: undefined
            });
        }, 1000)
    }


    applyMemberFilter = (selectedFilter: any) => {
        this.showToastLoader(translatePhrase("Please wait"));

        setTimeout(() => {
            this.props.filterMemberTable(
                selectedFilter.projects,
                selectedFilter.types,
                selectedFilter.locations,
                selectedFilter.customFields,
                selectedFilter.createdDateRange,
                selectedFilter.lastUpdatedDateRange,
                selectedFilter.unsynced,
                selectedFilter.archived,
            );

            this.setState({
                toastLoader: undefined
            });
        }, 1000);
    }

    applyGroupFilter = (selectedFilter: any) => {
        this.showToastLoader(translatePhrase("Please wait"));

        setTimeout(() => {
            this.props.filterGroupTable(
                selectedFilter.projects,
                selectedFilter.types,
                selectedFilter.locations,
                selectedFilter.customFields,
                selectedFilter.createdDateRange,
                selectedFilter.lastUpdatedDateRange,
                selectedFilter.unsynced,
                selectedFilter.archived,
            );

            this.setState({
                toastLoader: undefined
            });
        }, 1000);
    }

    applyUserFilter = (selectedFilter: any) => {
        this.showToastLoader(translatePhrase("Please wait"));

        setTimeout(() => {
            this.props.filterUserTable(
                selectedFilter.projects,
                selectedFilter.levels,
                selectedFilter.roles,
                selectedFilter.locations,
                selectedFilter.unsynced,
                selectedFilter.areLowerLevelsIncluded,
                selectedFilter.archived,
                selectedFilter.isOnline,
                selectedFilter.isBetaTester,
                selectedFilter.customFields,
            );

            this.setState({
                toastLoader: undefined
            });
        }, 1000);
    }

    applyReportFIlter = (selectedFilter: any) => {
        this.showToastLoader(translatePhrase("Please wait"));

        setTimeout(() => {
            this.props.filterReportTable(
                selectedFilter.projects,
                selectedFilter.types,
                selectedFilter.users,
                selectedFilter.createdDateRange,
                selectedFilter.lastUpdatedDateRange,
                selectedFilter.generatedDateRange,
                selectedFilter.unsynced,
                selectedFilter.archived,
            );

            this.setState({
                toastLoader: undefined
            });
        }, 1000);
    }

    componentDidMount() {
        this.setState({
            recentSearchTerms: this.getRecentSearchTerms(),
            recentFilterTerms: this.getRecentFilterTerms(),
            recentFiltersObj: this.getRecentFilterObj(),
            selectedSmartFilter: this.props.selectedSmartFilter,
        });
        this.setFilterTags();
        this.setPages();
    }

    goToExportLink = () => {
        if (this.props.exportLink) {
            window.open(this.props.exportLink, '_blank');
        }
    }

    isBlur = () => {
        let isBlur = this.props.isShowingAddForm || this.props.isShowingCSVForm
            || this.props.isShowingFilterForm || this.props.isShowingTransferForm
            || this.props.isShowingPerformanceDetails || this.props.isShowingUserSyncMetaLogExportForm

        return isBlur ? true : false;
    }

    setFilterTags = () => {
        if (this.props.tags) {
            const filterTags = this.props.tags.map(tag => {
                return (
                    <div key={tag} title={tag} className={styles.filterTag}>
                        {tag}
                        {
                            tag.split(":")[0] !== "Count" && !this.isBlur() && (
                                <div className={styles.cancelIcon} onClick={() => this.removeFilter(tag)}>
                                    <CancelIcon />
                                </div>
                            )
                        }
                    </div>
                );
            });
            this.setState({ filterTags: filterTags });
        }
    }

    setPages = () => {
        let pages = [], startIndex, endIndex;
        const { totalPages, pageNumber, goToPage } = this.props;
        if (totalPages < 5) {
            startIndex = 1;
            endIndex = totalPages;
        } else if (pageNumber < 5) {
            startIndex = 1;
            endIndex = 5
        } else if (pageNumber > (totalPages - 5)) {
            startIndex = totalPages - 4;
            endIndex = totalPages;
        } else {
            startIndex = pageNumber - 2;
            endIndex = pageNumber + 2;
        }

        for (let i = startIndex; i <= endIndex; i += 1) {
            pages.push(
                <section
                    key={i}
                    className={styles.pageLink + ' ' + (pageNumber === i ? styles.activePageLink : '')}
                    onClick={e => goToPage(i)}>
                    {i}
                </section>
            );
        }
        this.setState({ pages: pages });
    }

    render() {

        return (
            <div className={styles.entireTableHolder + ' ' + (this.props.isShowingAddForm || this.props.isShowingCSVForm || this.props.isShowingUserSyncMetaLogExportForm ||
                this.props.isShowingFilterForm || this.props.isShowingTransferForm || this.props.isShowingPerformanceDetails ? styles.blur : '') +
                (this.props.isShowingModifyForm ? styles.blurOnlyTop : '')}>

                <section className={this.props.areFiltersExpanded ? styles.meta : styles.expandedMeta}>
                    <EnhancedRecentSearchesAndFiltersModify
                        isSearchHighlighted={this.props.isSearchHighlighted}
                        searchTerm={this.props.searchTerm}
                        recentSearchTerms={this.state.recentSearchTerms}
                        storeRecentSearchAndFilterTerms={this.storeRecentSearchAndFilterTerms}
                        clearRecentSearches={this.clearRecentSearches}
                        clearRecentFilters={this.clearRecentFilters}
                        stringfiedFilterTerms={this.state.stringfiedFilterTerms}
                        recentFiltersObj={this.state.recentFiltersObj}
                        filterTags={this.props.tags}
                        recentFilterTerms={this.state.recentFilterTerms}
                        handleSearch={this.handleSearch}
                        removeRecentSearchTerm={this.removeRecentSearchTerm}
                        reindexTable={this.props.reindexTable}
                        entityType={this.props.entityType}
                        groupsFilters={this.props.groupsFilters}
                        membersFilters={this.props.membersFilters}
                        workflowsFilters={this.props.workflowsFilters}
                        usersFilters={this.props.usersFilters}
                        reportFilters={this.props.reportFilters}
                        isBlur={this.isBlur()}
                        applyWorkflowFilter={this.applyWorkflowFilter}
                        applyMemberFilter={this.applyMemberFilter}
                        applyGroupFilter={this.applyGroupFilter}
                        applyUserFilter={this.applyUserFilter}
                        applyReportFIlter={this.applyReportFIlter}
                        isRemoveFilterTag={this.state.isRemoveFilterTag}
                    />
                    <div className={styles.refreshButton}>
                        {this.props.reindexTable && <Button title={translatePhrase('Recompute')} size={'small'} type={'secondary'} icon={<RefreshIcon />} isRounded onClick={this.props.reindexTable} />}
                    </div>
                    {this.props.extraHeaderElements && this.props.extraHeaderElements.length > 0 && <div className={styles.extraHeaderElements}>
                        {this.props.extraHeaderElements}
                    </div>}


                    {this.props.isLimitReached && <div className={styles.limitReachedMessage}>You have reached the maximum limit</div>}

                    {this.props.bulkActions && this.props.showExportSyncMetaLog && <Button title={translatePhrase('Export Sync Meta Log')} icon={<ExportCSVIcon />} size={'small'} type={'secondary'} isRounded onClick={this.props.showExportSyncMetaLog} />}

                    {!this.props.isReadOnly && this.props.showTransferForm && <Button title={translatePhrase('Transfer')} size={'small'} type={'secondary'} icon={<TransferIcon />} isRounded onClick={this.props.showTransferForm} />}
                    {!this.props.isReadOnly && this.props.archiveEntities && (this.state.deleteTimer ? <Button title={translatePhrase('Undo')} functionalText={<DecrementingCounter additionalText={translatePhrase('Undo before')} remaining={5} />} size={'small'} type={'secondary'} isRounded onClick={this.revertDelete} /> : <Button title={translatePhrase('Bulk Archive results shown in table below')} icon={<DeleteIcon />} size={'small'} type={'secondary'} isRounded onClick={this.askForDelete} />)}
                    {this.props.showPerformanceDetails && <Button title={translatePhrase('Performance details')} icon={<UserMetricsIcon />} size={'small'} type={'secondary'} isRounded onClick={this.props.showPerformanceDetails} />}
                    {this.props.exportLink ? <Button isHighlighted={this.props.isImportExportHighlighted} title={translatePhrase('Export')} size={'small'} type={'secondary'} icon={<ImportExportIcon />} isRounded onClick={this.goToExportLink} />
                        : this.props.handleCSVForm && <Button isHighlighted={this.props.isImportExportHighlighted} title={translatePhrase('Import/Export')} size={'small'} type={'secondary'} icon={<ImportExportIcon />} isRounded onClick={this.props.handleCSVForm} />}
                    {this.props.showFilterForm && <Button isHighlighted={this.props.isFilterHighlighted} title={translatePhrase('Filter')} hasData={(this.props.tags && this.props.tags?.length > 0) ? true : false} icon={<FilterIcon />} size={'small'} type={'secondary'} isRounded onClick={this.handleFilterClick} />}
                    {this.props.isShowingFilterForm && <section className={styles.newEntryFormHolder}>
                        {this.props.filterForm}
                    </section>}
                    {this.props.isShowingTransferForm && <section className={styles.newEntryFormHolder}>
                        {this.props.transferForm}
                    </section>}
                    {this.props.isShowingUserSyncMetaLogExportForm && <section className={styles.newEntryFormHolder}>
                        {this.props.syncMetaLogExportForm}
                    </section>}

                    <div className={styles.addEntryButton}>
                        <Button isHighlighted={this.props.isAddHighlighted} title={translatePhrase('Add')} size={'small'} padding={'0px 15px'} isDisabled={!(!this.props.isReadOnly && this.props.isAddAllowed)} icon={<PlusIcon />}
                            text={translatePhrase(`Add ${this.props.entityType}`)} dataSelector={`add-${this.props.entityType.toLowerCase()}`} isBlock={true} isRounded={true} color={'contrast'}
                            onClick={() => {
                                if (!this.props.isReadOnly && this.props.isAddAllowed) {
                                    this.props.showAddForm();
                                }
                            }} />
                    </div>

                    {this.props.isShowingAddForm && <section className={styles.newEntryFormHolder}>
                        {this.props.addForm}
                    </section>}
                </section>
                {this.props.tags && <div id="filter-tags" className={styles.filterTagsHolder}>
                    {this.state.filterTags}
                </div>}
                <div className={styles.tableContainer}>
                    <Table
                        pushHeaderDown={!!this.props.placeHolderForSearch}
                        headings={this.props.headings}
                        entries={this.props.entries}
                        sortedColumn={this.props.sortedColumn}
                        sortType={this.props.sortType}
                        onSort={this.handleSort}
                        transferSearch={this.props.transferSearch}
                        onDelete={this.props.onDelete}
                        onUnArchive={this.props.onUnArchive}
                        onNotify={this.props.onNotify}
                        showModifyForm={this.props.showModifyForm}
                        showPerformanceDetails={this.props.showPerformanceDetailsForUser}
                        isShowingModifyForm={this.props.isShowingModifyForm}
                        isReadOnly={this.props.isReadOnly}
                        isShowMoreHighlighted={this.props.isShowMoreHighlighted}
                        isFullTable
                        notifyActionName={this.props.notifyActionName}
                    />
                </div>

                <div className={styles.pageActions}>
                    <div className={styles.pageSize}>
                        <div>{translatePhrase('Show')}</div>
                        <input type="text" key={this.props.entries.length} onChange={this.updatePageSizeValue} defaultValue={this.props.entries.length} />
                        {this.props.entries.length > 5 && this.state.newPageSize !== this.props.entries.length && <button className={styles.nav + ' ' + styles.decreaseSize} onClick={() => this.decrementPageSize}> <RightArrow /> </button>}
                        {this.props.entries.length < 35 && this.state.newPageSize !== this.props.entries.length && <button className={styles.nav + ' ' + styles.increaseSize} onClick={() => this.incrementPageSize}> <RightArrow /> </button>}
                        {this.state.newPageSize !== this.props.entries.length && <Button onClick={this.submitPageSize} text={translatePhrase('Go')} size={'small'} padding={'0px 10px'} isRounded />}
                    </div>

                    {this.state.pages.length > 1 && <div className={styles.pageSize}>
                        <button disabled={this.props.pageNumber === 1} className={styles.nav + ' ' + styles.prevPage + ' ' + (this.props.pageNumber === 1 ? styles.disableButton : '')} onClick={() => this.props.goToPage(1)}> <ChevronRight /> </button>
                        <button disabled={this.props.pageNumber === 1} className={styles.nav + ' ' + styles.prevPage + ' ' + (this.props.pageNumber === 1 ? styles.disableButton : '')} onClick={() => this.props.goToPage(this.props.pageNumber - 1)}> <RightArrow /> </button>
                        <input type="text" key={this.props.pageNumber} onChange={this.updatePageNumberValue} defaultValue={this.props.pageNumber} />
                        <div style={{ marginRight: '7px' }}> {translatePhrase('of')} {this.props.totalPages} </div>
                        {this.state.newPageNumber !== this.props.pageNumber && <button disabled={this.props.pageNumber === this.props.totalPages} className={styles.nav + ' ' + styles.nextPage + ' ' + styles.highlight} onClick={() => this.submitPageNumber()}> <RightArrow /> </button>}
                        {this.state.newPageNumber === this.props.pageNumber && <button disabled={this.props.pageNumber === this.props.totalPages} className={styles.nav + ' ' + styles.nextPage + ' ' + (this.props.pageNumber === this.props.totalPages ? styles.disableButton : '') + ' ' + (this.props.pageNumber)} onClick={() => this.goToPage()}> <RightArrow /> </button>}
                        <button disabled={this.props.pageNumber === this.props.totalPages} className={styles.nav + ' ' + styles.nextPage + ' ' + (this.props.pageNumber === this.props.totalPages ? styles.disableButton : '')} onClick={() => this.props.goToPage(this.props.totalPages)}> <ChevronRight /> </button>
                        {/* { this.state.newPageNumber !== this.props.pageNumber && <Button onClick={this.submitPageNumber} text={translatePhrase('Go')} size={'small'} padding={'0px 10px'} isRounded /> } */}
                    </div>}
                </div>
                {this.state.isAskingForBulkArchive && <ConfirmModal
                    confirmText={`${translatePhrase('Archive all entries')}? (${this.props.totalNoOfEntries})`}
                    confirm={this.markForDelete}
                    cancel={this.cancelDelete}
                />}
                {this.state.toastLoader}
            </div>
        );
    }
}

const TableWithMetaWithRoute = connect(mapStateToProps, mapDispatchToProps)(ConnectedTableWithMeta);
const TableWithMeta = withRouter(TableWithMetaWithRoute);

export default TableWithMeta;