import React, { FC, useState, useEffect, createRef } from 'react';
import { connect } from 'react-redux';
import { getAllLocationsVisibleToUser, getAncestorChainOfLocation, getLocationsAtLevel } from '../../../shared/helpers/locations';
import { translatePhrase } from '../../../shared/helpers/translation';
import { ApplicationState } from '../../../shared/store/types';
import { IUpdateableUserData } from '../../../shared/store/users/types';

import chevronIcon from '../../../assets/chevron-arrow-down.svg';
import { ReactComponent as DeleteIcon } from '../../../common/assets/trash.svg';
import InputText, { OptionInput } from '../../../widgets/form/InputText';
import MultiSelectInputText from '../../../widgets/form/MultiSelectInput';
import styles from './UserModify.module.scss';
import { isUUID } from '../../../shared/helpers/utilities';
import DecrementingCounter from '../../../widgets/table/DecrementingCounter';
import CustomFieldInput from '../../../widgets/form/CustomField';
import Button from '../../../widgets/button/CommonButton';

type OwnProps = {
    index: number;
    userData: IUpdateableUserData;
    roleId?: string;

    changeProjects: (projects: Array<string>) => void;
    changeLevels: (levels: Array<string>) => void;
    changeRoles: (roles: Array<string>) => void;
    changeLocations: (locations: Array<string>) => void;

    changeCustomField: (fieldId: string, value: string | string[] | undefined | boolean) => void;

    removeEmptyProfile?: () => void;
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    const allAllowedProjects = state.structure.projects.allEntries,
        allAllowedLevels = state.structure.levels.allEntries;

    let allAllowedLocations: Array<string> = Object.keys(state.structure.locations.byId);

    return {
        projectsData: state.structure.projects,
        levelsData: state.structure.levels,
        rolesData: state.structure.roles,
        locationsData: state.structure.locations,
        structureData: state.structure,

        roleCustomFieldsData: state.structure.roles.customFields,
        roleCustomFieldOptionsData: state.structure.roles.customFieldOptions,

        allowedData: {
            projects: allAllowedProjects,
            levels: allAllowedLevels,
            locations: allAllowedLocations,
        },

        myId: state.myData.id,
    }
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;

const ConnectedProfileModify: FC<Props> = (props) => {
    const [roleId, setRoleId] = useState(props.roleId);
    const role = roleId ? props.rolesData.byId[roleId] : undefined;
    const level = role ? props.levelsData.byId[role.level] : undefined;

    const [levelId, setLevelId] = useState(role?.level);
    const [projectId, setProjectId] = useState(level?.project);

    const [locationIds, setLocationIds] = useState<Array<string>>([]);

    const dropdownRef = createRef<HTMLDivElement>();

    useEffect(() => {
        const locationsIdsAtLevel = level ? getLocationsAtLevel(level.id).map(location => location.id) : [];
        const selectedLocationIdsForProfile = props.userData.locations.filter(locationId => locationsIdsAtLevel.includes(locationId));
        setLocationIds(selectedLocationIdsForProfile);
    }, [projectId, roleId]);

    const getRolesInProject = () => {

        if (!projectId) {
            return [];
        }

        const allLevelsInProject = props.projectsData.byId[projectId].children;
        const allRolesInLevel = allLevelsInProject.map(levelId => props.levelsData.byId[levelId].children).flat();
        const allRolesNotInOtherProfiles = allRolesInLevel.filter(roleId => !props.userData.roles.includes(roleId));

        if (roleId && !allRolesNotInOtherProfiles.includes(roleId)) {
            const role = props.rolesData.byId[roleId];
            const level = props.levelsData.byId[role.level];

            if (level.project === projectId) {
                allRolesNotInOtherProfiles.push(roleId);
            }
        }

        const allowedRolesData = allRolesNotInOtherProfiles.map(roleId => {
            const role = props.rolesData.byId[roleId];
            const level = props.levelsData.byId[role.level];

            return {
                name: `${translatePhrase(role.name)} (${translatePhrase(level.name)})`,
                value: roleId,
            };
        });

        return allowedRolesData;
    }

    const otherRoleIds = props.roleId ? props.userData.roles.filter(roleId => roleId !== props.roleId) : props.userData.roles.slice();
    const otherRoleProjectIds = new Set<string>();

    for (const otherRoleId of otherRoleIds) {
        const otherRole = props.rolesData.byId[otherRoleId];

        if (otherRole) {
            const otherLevel = props.levelsData.byId[otherRole.level];
            otherRoleProjectIds.add(otherLevel.project);
        }
    }

    const getLocationsForProfile = () => {

        if (!role) {
            return [];
        }

        const allLocationsForRole = getLocationsAtLevel(role.level);

        if (!levelId) {
            return [];
        }

        let allowedLocations = allLocationsForRole.filter(location => props.allowedData.locations.includes(location.id));

        const locationsData = allowedLocations.map(location => {
            let parentName = ''

            if (location.parent) {
                parentName = location.parent in props.locationsData.byId ? translatePhrase(props.locationsData.byId[location.parent].name) : translatePhrase(props.projectsData.byId[location.parent].name);
            }

            return {
                name: `${translatePhrase(location.name)} (${parentName})`,
                value: location.id,
            };
        });

        return locationsData;
    }

    const [projectsList, setProjectsList] = useState<Array<OptionInput>>([]);
    const [rolesList, setRolesList] = useState<Array<OptionInput>>([]);
    const [locationsList, setLocationsList] = useState<Array<OptionInput>>([]);

    useEffect(() => {
        const projectsList = props.allowedData.projects
            .filter(projectId => !otherRoleProjectIds.has(projectId))
            .map(projectId => {
                return {
                    name: translatePhrase(props.projectsData.byId[projectId].name),
                    value: projectId,
                };
            });

        setProjectsList(projectsList);

        const rolesList = getRolesInProject();
        setRolesList(rolesList);

        const locationsAtLevel = getLocationsForProfile();
        const allowedLocations = isUUID(props.myId) ? getAllLocationsVisibleToUser(props.myId) : props.locationsData.allEntries;
        const locationsListForSingleSelect = locationsAtLevel.filter(locationAtLevel => allowedLocations.includes(locationAtLevel.value));

        const locationsList = locationsListForSingleSelect.map(location => {
            const parentLocationIds = getAncestorChainOfLocation(location.value);
            parentLocationIds.reverse();
            const locationChain = parentLocationIds
                .filter(locationId => locationId in props.locationsData.byId)
                .concat([location.value])
                .map(locationId => props.locationsData.byId[locationId].name)
                .join(' > ');

            return {
                name: location.name,
                description: locationChain,
                value: location.value,
            };
        });

        setLocationsList(locationsList);
    }, [props.allowedData.projects, projectId, roleId]);

    const selectProject = (newProjectId: string) => {
        setProjectId(newProjectId);

        let newProjects = props.userData.projects.slice();

        let otherRoleIds = props.userData.roles.slice();

        if (roleId) {
            otherRoleIds = otherRoleIds.filter(existingRoleId => existingRoleId !== roleId);
        }

        const otherRoles = otherRoleIds.map(roleId => props.rolesData.byId[roleId]);
        const otherProjectIds = otherRoles.map(otherRole => {
            const level = props.levelsData.byId[otherRole.level];
            return level.project;
        });

        if (!otherProjectIds.includes(newProjectId)) {
            newProjects = newProjects.filter(existingProjectId => existingProjectId !== projectId);
        }

        if (!newProjects.includes(newProjectId)) {
            const newProjectIds = newProjects.concat([newProjectId]);
            props.changeProjects(newProjectIds);
        } else {
            props.changeProjects(newProjects);
        }
    }

    const selectRole = (newRoleId: string) => {
        const existingRole = roleId ? props.rolesData.byId[roleId] : undefined;

        const newRole = props.rolesData.byId[newRoleId];
        const newLevel = props.levelsData.byId[newRole.level];

        setRoleId(newRoleId);
        setLevelId(newLevel.id);

        const newRoles = props.userData.roles.filter(existingRoleId => existingRoleId !== roleId).concat([newRoleId]);
        let newLocations = props.userData.locations.slice();
        let newLevels = props.userData.levels.slice();
        let newProjects = props.userData.projects.slice();

        if (!newLevels.includes(newLevel.id)) {
            newLevels.push(newLevel.id);
        }

        if (!newProjects.includes(newLevel.project)) {
            newProjects.push(newLevel.project);
        }

        const otherRoles = props.userData.roles.map(roleId => props.rolesData.byId[roleId]);

        const hasOtherRolesInLevel = !!otherRoles.find(otherRole => {
            return otherRole.level === newLevel.id;
        });

        if (!hasOtherRolesInLevel && existingRole) {
            newLevels = newLevels.filter(levelId => levelId !== existingRole.level);
            const locationIdsAtLevels = newLevels.map(levelId => getLocationsAtLevel(levelId)).flat().map(location => location.id);
            newLocations = props.userData.locations.filter(locationId => !locationIdsAtLevels.includes(locationId));

            const hasOtherLevelsInProject = !!otherRoles.find(role => {
                const otherLevel = props.levelsData.byId[role.level];
                return otherLevel.project === newLevel.project;
            });

            if (!hasOtherLevelsInProject) {
                newProjects = newProjects.filter(projectId => projectId !== newLevel.project);
            }
        }

        if (!newLevels.includes(newLevel.id)) {
            newLevels.push(newLevel.id);
        }

        if (!newProjects.includes(newLevel.project)) {
            newProjects.push(newLevel.project);
        }

        props.changeLocations(newLocations);
        props.changeRoles(newRoles);
        props.changeLevels(newLevels);
        props.changeProjects(newProjects);

        if (props.removeEmptyProfile) {
            props.removeEmptyProfile();
        }
    }

    const changeLocations = (locationIds: Array<string>) => {
        if (!role) {
            return;
        }

        setLocationIds(locationIds);

        const allLocationIdsAtLevel = getLocationsAtLevel(role.level).map(location => location.id);
        const newLocationIds = props.userData.locations.slice().filter(locationId => !allLocationIdsAtLevel.includes(locationId)).concat(locationIds);
        props.changeLocations(newLocationIds);
    }

    const [isMarkedForDelete, setIsMarkedForDelete] = useState(false);
    const [deleteTimer, setDeleteTimer] = useState<number>();

    const deleteProfile = () => {
        if (!role) {
            return;
        }

        if (!level) {
            return;
        }

        const newRoles = props.userData.roles.filter(roleId => roleId !== role.id);
        const hasOtherRolesInLevel = !!newRoles.find(roleId => {
            const newRole = props.rolesData.byId[roleId];
            return newRole.level === role.level;
        });

        props.changeRoles(newRoles);

        if (!hasOtherRolesInLevel) {
            const newLevels = props.userData.levels.filter(levelId => levelId !== role.level);
            const newLocations = props.userData.locations.filter(locationId => !locationIds.includes(locationId));

            props.changeLevels(newLevels);
            props.changeLocations(newLocations);

            const hasOtherLevelsInProject = newLevels.find(levelId => {
                const newLevel = props.levelsData.byId[levelId];
                return newLevel.project === level.project;
            });

            if (!hasOtherLevelsInProject) {
                const newProjects = props.userData.projects.filter(projectId => projectId !== level.project);
                props.changeProjects(newProjects);
            }
        }

    }

    const markForDelete = () => {
        if (props.roleId) {
            setIsMarkedForDelete(true);
            const timeout = window.setTimeout(deleteProfile, 5000);
            setDeleteTimer(timeout);
        } else if (props.removeEmptyProfile) {
            props.removeEmptyProfile();
        }
    }

    const cancelDelete = () => {
        setIsMarkedForDelete(false);
        window.clearTimeout(deleteTimer);
        setDeleteTimer(undefined);
    }

    const customFields = role?.customFields.map(fieldId => {
        return <CustomFieldInput
            entityType="role"
            entity={props.userData}
            fieldId={fieldId}
            customFieldsData={props.roleCustomFieldsData}
            customFieldOptionsData={props.roleCustomFieldOptionsData}
            changeField={props.changeCustomField}
        />
    });

    return <div className={styles.profileHolder}>
        <div className={styles.meta}>
            <div className={styles.index}>Role {props.index + 1}</div>
            {isMarkedForDelete ? <span><DecrementingCounter remaining={5} /> <Button type={'secondary'} color={'contrast'} text={translatePhrase('Undo')} onClick={cancelDelete} isRounded={true} size={'small'} padding={'0px 10px'} /></span> : <Button onClick={markForDelete} icon={<DeleteIcon />} isRounded={true} size={'small'} type={'tertiary'} color={'contrast'} />}
        </div>
        <div className={styles.allInputsHolder + ' ' + (isMarkedForDelete ? styles.inactiveProfile : '')}>
            <div className={styles.inputSegment}>
                <InputText placeholder="Project" isDisabled={!!projectId} icon={chevronIcon} default={projectId} onChange={selectProject} options={projectsList} isAutoFocus={true} />
            </div>

            <div ref={dropdownRef} className={styles.inputSegment}>
                <InputText key={projectId} isDisabled={!projectId} placeholder="Role" icon={chevronIcon} default={roleId} onChange={selectRole} options={rolesList} isAutoFocus={true} />
            </div>

            <div className={styles.inputSegment}>
                <MultiSelectInputText
                    key={roleId}
                    options={locationsList}
                    default={props.userData.locations}
                    onChange={changeLocations}
                    placeholder={translatePhrase('Locations')}
                    isDisabled={!roleId}
                    isAutoFocus={true}
                />
            </div>

            {customFields}

        </div>
    </div>
}

const ProfileModify = connect(mapStateToProps)(ConnectedProfileModify);

export default ProfileModify;