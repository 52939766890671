import React, { Component, RefObject } from 'react';
import styles from './RichTextModal.module.scss';
import { EditorState, convertFromRaw, convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';


type OwnProps = {
    textToShow: string,
    onContentUpdate: (value: string) => void,
}

type OwnState = {
    editorState: EditorState,
}

export default class RichTextModal extends Component<OwnProps, OwnState> {
    draftEditor: RefObject<Editor>;

    constructor(props: Readonly<OwnProps>) {
        super(props);
        this.draftEditor = React.createRef();
        let initialEditorState: EditorState;

        if (props.textToShow) {
            try {
                initialEditorState = EditorState.createWithContent(convertFromRaw(JSON.parse(props.textToShow)));
            } catch (e) {
                initialEditorState = EditorState.createEmpty();
            }
        } else {
            initialEditorState = EditorState.createEmpty();
        }

        this.state = {
            editorState: initialEditorState,
        };
    }

    updateEditorText = (editorState: EditorState) => {
        this.setState({
            editorState,
        });

        const strigifiedValue = JSON.stringify(convertToRaw(editorState.getCurrentContent()));
        this.props.onContentUpdate(strigifiedValue);
    }

    render() {
        return <div className={styles.editorHolder}>
            <Editor ref={this.draftEditor}
                editorState={this.state.editorState}
                onEditorStateChange={this.updateEditorText}
            />
        </div>;
    }
}