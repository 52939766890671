import { StateIDMapping, ExportedConfiguration, ExportedMemberType, ExportedGroupType, ExportedWorkflowType, ExportedReportType, ExportedVariable, ExportWorkflowTypeData, DuplicationIDMappng, WorkflowImportIdMap, ExportReportTypeData, ReportImportIdMap, ExportedStaticData } from './index';
import uuid from 'uuid';
import moment from 'moment';
import store from '../../store/main';
import { instantiateLevel, instantiateLevelCustomField, instantiateLevelCustomFieldOption } from '../../store/structure/level/actions';
import { instantiateLevelsForProject } from '../../store/structure/project/actions';
import { instantiateRole, instantiateRoleCustomField, instantiateRoleCustomFieldOption } from '../../store/structure/role/actions';
import { duplicatePiece, importPiece } from './piece';
import { addFullPiece, addFullPieces } from '../../store/flowchart/pieces/actions';
import { addVariable, addVariables } from '../../store/flowchart/variables/actions';
import { instantiateMemberType, instantiateMemberTypeCustomField, instantiateMemberTypeCustomFieldOption } from '../../store/members/types/actions';
import { instantiateGroupTypeCustomField, instantiateGroupTypeCustomFieldOption, instantiateGroupType } from '../../store/groups/types/actions';
import { instantiateWorkflowType, instantiateWorkflowTypeCustomField, instantiateWorkflowTypeCustomFieldOption } from '../../store/workflows/types/actions';
import { isUUID } from '../utilities';
import { instantiateStatus } from '../../store/workflows/types/statuses/actions';
import { instantiateReportType } from '../../store/reports/types/actions';
import { AllPieceTypes, PieceState } from '../../store/flowchart/pieces/types';
import { instantiateMemberTypeAction } from '../../store/members/types/actions/actions';
import { instantiateGroupTypeAction } from '../../store/groups/types/actions/actions';
import { IUpdateableVariableData } from '../../store/flowchart/variables/types';
import { DataFragmentState, IDataFragment, INewDataFragmentData, IUpdateableDataFragmentData } from '../../store/static-info/data-fragment/types';
import { addStaticDataHolder, addStaticDataHolderRequest } from '../../store/static-info/actions';
import { addDataFragment } from '../../store/static-info/data-fragment/actions';

export function mapConfigIDs(configuration: ExportedConfiguration) {
    const duplicationMap: StateIDMapping = {
        projectIds: {},
        levelIds: {},
        levelCustomFieldIds: {},
        levelCustomFieldOptionIds: {},
        roleIds: {},
        roleCustomFieldIds: {},
        roleCustomFieldOptionIds: {},

        variableIds: {},

        memberTypeIds: {},
        memberTypeCustomFieldIds: {},
        memberTypeCustomFieldOptionIds: {},

        groupTypeIds: {},
        groupTypeCustomFieldIds: {},
        groupTypeCustomFieldOptionIds: {},

        workflowTypeIds: {},
        workflowStatusIds: {},
        workflowTypeCustomFieldIds: {},
        workflowTypeCustomFieldOptionIds: {},

        reportTypeIds: {},
    };

    // Structure ID maps
    for (const level of configuration.levels) {
        duplicationMap.levelIds[level.id] = uuid.v4();

        for (const customField of level.customFields) {
            duplicationMap.levelCustomFieldIds[customField.id] = uuid.v4();

            for (const customFieldOption of customField.options) {
                duplicationMap.levelCustomFieldOptionIds[customFieldOption.id] = uuid.v4();
            }
        }

        for (const role of level.roles) {
            duplicationMap.roleIds[role.id] = uuid.v4();

            for (const customField of role.customFields) {
                duplicationMap.roleCustomFieldIds[customField.id] = uuid.v4();

                for (const customFieldOption of customField.options) {
                    duplicationMap.roleCustomFieldOptionIds[customFieldOption.id] = uuid.v4();
                }
            }
        }
    }

    // Member ID maps
    for (const memberType of configuration.memberTypes) {
        duplicationMap.memberTypeIds[memberType.id] = uuid.v4();

        for (const customField of memberType.customFields) {
            duplicationMap.memberTypeCustomFieldIds[customField.id] = uuid.v4();

            for (const customFieldOption of customField.options) {
                duplicationMap.memberTypeCustomFieldOptionIds[customFieldOption.id] = uuid.v4();
            }
        }
    }

    // Group ID maps
    for (const groupType of configuration.groupTypes) {
        duplicationMap.groupTypeIds[groupType.id] = uuid.v4();

        for (const customField of groupType.customFields) {
            duplicationMap.groupTypeCustomFieldIds[customField.id] = uuid.v4();

            for (const customFieldOption of customField.options) {
                duplicationMap.groupTypeCustomFieldOptionIds[customFieldOption.id] = uuid.v4();
            }
        }
    }

    // Workflow ID maps
    for (const workflowType of configuration.workflowTypes) {
        duplicationMap.workflowTypeIds[workflowType.id] = uuid.v4();

        for (const status of workflowType.statuses) {
            duplicationMap.workflowStatusIds[status.id] = uuid.v4();
        }

        for (const customField of workflowType.customFields) {
            duplicationMap.workflowTypeCustomFieldIds[customField.id] = uuid.v4();

            for (const customFieldOption of customField.options) {
                duplicationMap.workflowTypeCustomFieldOptionIds[customFieldOption.id] = uuid.v4();
            }
        }
    }

    // Report ID maps
    for (const reportType of configuration.reportTypes) {
        duplicationMap.reportTypeIds[reportType.id] = uuid.v4();
    }

    // Variable ID maps
    for (const variable of configuration.variables) {
        duplicationMap.variableIds[variable.id] = uuid.v4();
    }

    return duplicationMap;
}

function addFullPieceToStore(pieceData: AllPieceTypes) {
    store.dispatch(addFullPiece(pieceData));
}

export function importMemberType(memberType: ExportedMemberType, pieceState: PieceState, duplicationMap: StateIDMapping, projectId: string, exportedVariables: Array<ExportedVariable>, isCopiedInSameProject = false) {
    const now = moment.utc().format();
    const piecesMap: {
        [oldPieceId: string]: string,
    } = {};

    store.dispatch(instantiateMemberType({
        id: duplicationMap.memberTypeIds[memberType.id],
        name: isCopiedInSameProject ? memberType.name + ' copy' : memberType.name,
        project: projectId,
        customFields: [],

        nameFieldId: duplicationMap.memberTypeCustomFieldIds[memberType.nameFieldId],
        subTitleFieldId: duplicationMap.memberTypeCustomFieldIds[memberType.subTitleFieldId],
        locationFieldId: duplicationMap.memberTypeCustomFieldIds[memberType.locationFieldId],

        members: [],
        actions: memberType.actions.map(action => action.id),

        createdTime: now,
        lastUpdatedTime: now,
    }));

    for (const action of memberType.actions) {
        store.dispatch(instantiateMemberTypeAction({
            id: action.id,
            name: action.name,
            icon: action.icon,
            workflowType: action.workflowType && action.workflowType in duplicationMap.workflowTypeIds ? duplicationMap.workflowTypeIds[action.workflowType] : action.workflowType,
        }, memberType.id));
    }

    for (const customField of memberType.customFields) {

        for (const variableId of customField.variables) {
            const exportedVariable = exportedVariables.find(exportedVariable => exportedVariable.id === variableId);

            if (!!exportedVariable) {
                store.dispatch(addVariable({
                    id: duplicationMap.variableIds[exportedVariable.id],
                    name: exportedVariable.name,
                    type: exportedVariable.type,
                }));
            }
        }

        if (customField.startPiece) {
            let newId = '';
            try {
                newId = duplicatePiece(pieceState, duplicationMap, projectId, isCopiedInSameProject, addFullPieceToStore, customField.startPiece.piece);
            } catch (e) {
                if (e instanceof Error) {
                    throw new Error(`Error when importing custom field ${customField.name}: ${e.message}`);
                }
            }

            piecesMap[customField.startPiece.piece] = newId;
        }

        store.dispatch(instantiateMemberTypeCustomField({
            id: duplicationMap.memberTypeCustomFieldIds[customField.id],
            name: customField.name,
            type: customField.type,
            isComputed: customField.isComputed,
            isInTable: customField.isInTable,
            isEditable: customField.isEditable,
            isDeletable: customField.isDeletable,
            seedEntityVariable: duplicationMap.variableIds[customField.seedEntityVariable],

            parentId: duplicationMap.levelIds[memberType.id],
            choices: [],

            startPiece: customField.startPiece ? {
                piece: piecesMap[customField.startPiece.piece],
                position: {
                    x: 0,
                    y: 0,
                }
            } : undefined,
            variables: customField.variables.map(variableId => duplicationMap.variableIds[variableId]),
            isolatedPieces: [],

            createdTime: now,
            lastUpdatedTime: now,
        }, duplicationMap.memberTypeIds[memberType.id]));

        for (const customFieldOption of customField.options) {
            store.dispatch(instantiateMemberTypeCustomFieldOption({
                id: duplicationMap.memberTypeCustomFieldOptionIds[customFieldOption.id],
                name: customFieldOption.name,
                parentId: duplicationMap.memberTypeCustomFieldIds[customField.id],
                createdTime: now,
                lastUpdatedTime: now,
            }, duplicationMap.memberTypeCustomFieldIds[customField.id]));
        }
    }
}

export function importGroupType(groupType: ExportedGroupType, pieceState: PieceState, duplicationMap: StateIDMapping, projectId: string, exportedVariables: Array<ExportedVariable>, isCopiedInSameProject = false) {
    const now = moment.utc().format();
    const piecesMap: {
        [oldPieceId: string]: string,
    } = {};

    store.dispatch(instantiateGroupType({
        id: duplicationMap.groupTypeIds[groupType.id],
        name: isCopiedInSameProject ? groupType.name + ' copy' : groupType.name,
        project: projectId,
        level: !isCopiedInSameProject ? duplicationMap.levelIds[groupType.level] : groupType.level,
        customFields: [],

        nameFieldId: duplicationMap.groupTypeCustomFieldIds[groupType.nameFieldId],
        subTitleFieldId: duplicationMap.groupTypeCustomFieldIds[groupType.subTitleFieldId],

        isRequired: groupType.isRequired,

        uniqueFieldId: duplicationMap.groupTypeCustomFieldIds[groupType.uniqueFieldId],

        groups: [],
        actions: groupType.actions.map(action => action.id),

        createdTime: now,
        lastUpdatedTime: now,
    }));

    for (const action of groupType.actions) {
        store.dispatch(instantiateGroupTypeAction({
            id: action.id,
            name: action.name,
            icon: action.icon,
            workflowType: action.workflowType && action.workflowType in duplicationMap.workflowTypeIds ? duplicationMap.workflowTypeIds[action.workflowType] : action.workflowType,
        }, groupType.id));
    }

    for (const customField of groupType.customFields) {

        for (const variableId of customField.variables) {
            const exportedVariable = exportedVariables.find(exportedVariable => exportedVariable.id === variableId);

            if (!!exportedVariable) {
                store.dispatch(addVariable({
                    id: duplicationMap.variableIds[exportedVariable.id],
                    name: exportedVariable.name,
                    type: exportedVariable.type,
                }));
            }
        }

        if (customField.startPiece) {
            let newId = '';

            try {
                newId = duplicatePiece(pieceState, duplicationMap, projectId, isCopiedInSameProject, addFullPieceToStore, customField.startPiece.piece);
            } catch (e) {
                if (e instanceof Error) {
                    throw new Error(`Error when importing custom field ${customField.name}: ${e.message}`);
                }
            }

            piecesMap[customField.startPiece.piece] = newId;
        }

        store.dispatch(instantiateGroupTypeCustomField({
            id: duplicationMap.groupTypeCustomFieldIds[customField.id],
            name: customField.name,
            type: customField.type,
            isComputed: customField.isComputed,
            isInTable: customField.isInTable,
            isEditable: customField.isEditable,
            isDeletable: customField.isDeletable,
            seedEntityVariable: duplicationMap.variableIds[customField.seedEntityVariable],

            parentId: duplicationMap.levelIds[groupType.id],
            choices: [],

            startPiece: customField.startPiece ? {
                piece: piecesMap[customField.startPiece.piece],
                position: {
                    x: 0,
                    y: 0,
                }
            } : undefined,
            variables: customField.variables.map(variableId => duplicationMap.variableIds[variableId]),
            isolatedPieces: [],

            createdTime: now,
            lastUpdatedTime: now,
        }, duplicationMap.groupTypeIds[groupType.id]));

        for (const customFieldOption of customField.options) {
            store.dispatch(instantiateGroupTypeCustomFieldOption({
                id: duplicationMap.groupTypeCustomFieldOptionIds[customFieldOption.id],
                name: customFieldOption.name,
                parentId: duplicationMap.groupTypeCustomFieldIds[customField.id],
                createdTime: now,
                lastUpdatedTime: now,
            }, duplicationMap.groupTypeCustomFieldIds[customField.id]));
        }
    }
}

function prepareDuplicateIdsFromReportTypeImportData(importData: ExportReportTypeData) {
    const idMap: ReportImportIdMap = {
        variableIds: {},
    };

    for (const id of importData.variableState.allEntries) {
        idMap.variableIds[id] = uuid.v4();
    }

    return idMap;
}

export function importReportTypeFromExportFile(
    importData: ExportReportTypeData,
    importReportTypeName: string,
    importProjectId: string,
) {
    const now = moment.utc().format();
    const pieceState = importData.pieceState;
    const piecesMap: {
        [oldPieceId: string]: string,
    } = {};

    const newPieces: Array<AllPieceTypes> = [];
    const newVariables: Array<IUpdateableVariableData> = [];

    const addNewPieceToList = (piece: AllPieceTypes) => {
        newPieces.push(piece);
    }

    const reportType = importData.reportType;

    const duplicationMap = prepareDuplicateIdsFromReportTypeImportData(importData);

    const getIdFromMapIfExists = (id: string, mapping: DuplicationIDMappng) => {
        return id in mapping ? mapping[id] : id;
    }

    for (const variableId of reportType.variables) {
        const exportedVariable = importData.variableState.byId[variableId]

        if (!!exportedVariable) {
            newVariables.push({
                id: duplicationMap.variableIds[exportedVariable.id],
                name: exportedVariable.name,
                type: exportedVariable.type,
            });
        }
    }

    if (reportType.startPiece) {
        let newId = '';

        try {
            const importDuplicationMap: WorkflowImportIdMap = {
                ...duplicationMap,
                affiliationTypeIds: {},
                affiliationTypeActionIds: {},
                affiliationTypeCustomFieldIds: {},
                affiliationTypeCustomFieldOptionIds: {},
                workflowTypeIds: {},
                workflowTypeCustomFieldIds: {},
                workflowTypeCustomFieldOptionIds: {},
                workflowStatusIds: {},
            };

            newId = importPiece(pieceState, importDuplicationMap, addNewPieceToList, reportType.startPiece.piece);
        } catch (e) {
            if (e instanceof Error) {
                throw new Error(`Error when importing workflow type ${reportType.name}: ${e.message}`);
            }
        }
        piecesMap[reportType.startPiece.piece] = newId;
    }

    store.dispatch(instantiateReportType({
        id: uuid.v4(),
        name: importReportTypeName,
        project: importProjectId,

        isolatedPieces: [],
        variables: reportType.variables.map(variableId => duplicationMap.variableIds[variableId]),
        startPiece: {
            piece: reportType.startPiece ? piecesMap[reportType.startPiece.piece] : '',
            position: {
                x: 0,
                y: 0,
            }
        },

        seedStartDateVariable: duplicationMap.variableIds[reportType.seedStartDateVariable],
        seedEndDateVariable: duplicationMap.variableIds[reportType.seedEndDateVariable],
        seedUserVariable: duplicationMap.variableIds[reportType.seedUserVariable],
        seedUsersVariable: duplicationMap.variableIds[reportType.seedUsersVariable],
        seedMembersVariable: duplicationMap.variableIds[reportType.seedMembersVariable],
        seedGroupsVariable: duplicationMap.variableIds[reportType.seedGroupsVariable],
        seedWorkflowsVariable: duplicationMap.variableIds[reportType.seedWorkflowsVariable],

        createdTime: now,
        lastUpdatedTime: now,
    }));

    store.dispatch(addFullPieces(newPieces));
    store.dispatch(addVariables(newVariables));
}

function prepareDuplicateIdsFromWorkflowTypeImportData(importData: ExportWorkflowTypeData, isImportingAffiliation: boolean) {
    const idMap: WorkflowImportIdMap = {
        variableIds: {},

        affiliationTypeIds: {},
        affiliationTypeCustomFieldIds: {},
        affiliationTypeCustomFieldOptionIds: {},
        affiliationTypeActionIds: {},

        workflowTypeIds: {},
        workflowStatusIds: {},
        workflowTypeCustomFieldIds: {},
        workflowTypeCustomFieldOptionIds: {},
    };

    for (const id of importData.variableState.allEntries) {
        idMap.variableIds[id] = uuid.v4();
    }

    if (importData.affiliation && isImportingAffiliation) {
        idMap.affiliationTypeIds[importData.affiliation.id] = uuid.v4();

        for (const customField of importData.affiliation.customFields) {
            idMap.affiliationTypeCustomFieldIds[customField.id] = uuid.v4();

            for (const customFieldOption of customField.options) {
                idMap.affiliationTypeCustomFieldOptionIds[customFieldOption.id] = uuid.v4();
            }
        }

        for (const action of importData.affiliation.actions) {
            idMap.affiliationTypeActionIds[action.id] = uuid.v4();
        }
    }

    idMap.workflowTypeIds[importData.workflowType.id] = uuid.v4();

    for (const customField of importData.workflowType.customFields) {
        idMap.workflowTypeCustomFieldIds[customField.id] = uuid.v4();

        for (const customFieldOption of customField.options) {
            idMap.workflowTypeCustomFieldOptionIds[customFieldOption.id] = uuid.v4();
        }
    }

    for (const action of importData.workflowType.statuses) {
        idMap.workflowStatusIds[action.id] = uuid.v4();
    }

    return idMap;
}

function importStaticDataRow(fragmentData: Array<string>, staticData: ExportedStaticData, addNewDataFragment: (payload: INewDataFragmentData, parentId: string) => void) {
    let parentDataFragment: IDataFragment | undefined;
    let fragmentStateData = store.getState().staticInfo.fragments;

    // Given a list of sibling IDs and a location, return the data of the location which has the given name within that list of siblings
    function getFragmentFromName(siblingFragmentIds: Array<string>, fragmentName: string, fragmentStateData: DataFragmentState) {
        const currentFragmentId = siblingFragmentIds.find(fragmentId => fragmentId in fragmentStateData.byId && fragmentStateData.byId[fragmentId].name === fragmentName);

        return typeof currentFragmentId !== 'undefined' ? fragmentStateData.byId[currentFragmentId] : undefined;
    }

    for (const fragmentName of fragmentData) {

        let currentDataFragment: IDataFragment | undefined;

        if (typeof parentDataFragment === 'undefined') {
            currentDataFragment = getFragmentFromName(fragmentStateData.byStaticDataHolder[staticData.id], fragmentName, fragmentStateData);
        } else {
            currentDataFragment = getFragmentFromName(parentDataFragment.children, fragmentName, fragmentStateData);
        }

        // If the current data fragment does not exist, prepare the new fragment data.
        if (typeof currentDataFragment === 'undefined') {
            const newFragmentId = uuid.v4();
            const newFragmentData: IUpdateableDataFragmentData = {
                id: newFragmentId,
                name: fragmentName,
            };

            const parentId = typeof parentDataFragment === 'undefined' ? staticData.id : parentDataFragment.id;

            addNewDataFragment(newFragmentData, parentId);
            fragmentStateData = store.getState().staticInfo.fragments;
            currentDataFragment = fragmentStateData.byId[newFragmentId];
        }

        parentDataFragment = currentDataFragment;
    }

}

function importStaticData(staticData: ExportedStaticData, addNewDataFragment: (payload: INewDataFragmentData, parentId: string) => void) {
    const importData = staticData.data;

    const dataWithoutHeader = importData.slice(1);

    for (const rowData of dataWithoutHeader) {

        let lastColumnWithData = 0;

        for (let i = 0; i < rowData.length; i += 1) {
            const fragmentName = rowData[i];

            if (fragmentName) {
                lastColumnWithData = i;
            }
        }

        const filteredRowData = rowData.slice(0, lastColumnWithData + 1);

        if (filteredRowData.length > 1 || (filteredRowData.length === 1 && filteredRowData[0].trim() !== '')) {
            importStaticDataRow(filteredRowData, staticData, addNewDataFragment);
        }
    }

}

export function importWorkflowTypeFromExportFile(
    importData: ExportWorkflowTypeData,
    importWorkflowTypeName: string,
    importProjectId: string,
    importLevelId: string,
    importAffiliation: 'none' | 'member' | 'group',
    importAffiliatedEntityName: string,
    importStaticIds: Array<string>,
    existingAffiliatedId: string | undefined,
    importIsCore: boolean,
    importAreMultipleInstancesAllowed: boolean,
) {
    const now = moment.utc().format();
    const pieceState = importData.pieceState;
    const piecesMap: {
        [oldPieceId: string]: string,
    } = {};
    let affiliatedEntity = '';

    if (existingAffiliatedId) {
        affiliatedEntity = existingAffiliatedId;
    }

    const newPieces: Array<AllPieceTypes> = [];
    const newVariables: Array<IUpdateableVariableData> = [];

    const addNewPieceToList = (piece: AllPieceTypes) => {
        newPieces.push(piece);
    }

    const workflowType = importData.workflowType;

    const duplicationMap = prepareDuplicateIdsFromWorkflowTypeImportData(importData, !existingAffiliatedId);

    const getIdFromMapIfExists = (id: string, mapping: DuplicationIDMappng) => {
        return id in mapping ? mapping[id] : id;
    }

    for (const variableId of workflowType.variables) {
        const exportedVariable = importData.variableState.byId[variableId]

        if (!!exportedVariable) {
            newVariables.push({
                id: duplicationMap.variableIds[exportedVariable.id],
                name: exportedVariable.name,
                type: exportedVariable.type,
            });
        }
    }

    if (!existingAffiliatedId && importData.affiliation && importAffiliation === 'member' && isUUID(workflowType.affiliatedEntity)) {
        const affiliation = importData.affiliation;
        affiliatedEntity = affiliation.id;

        store.dispatch(instantiateMemberType({
            id: duplicationMap.affiliationTypeIds[affiliation.id],
            name: importAffiliatedEntityName,
            project: importProjectId,
            customFields: [],

            nameFieldId: getIdFromMapIfExists(affiliation.nameFieldId, duplicationMap.affiliationTypeCustomFieldIds),
            subTitleFieldId: getIdFromMapIfExists(affiliation.subTitleFieldId, duplicationMap.affiliationTypeCustomFieldIds),
            locationFieldId: 'locationFieldId' in affiliation ? getIdFromMapIfExists(affiliation.locationFieldId, duplicationMap.affiliationTypeCustomFieldIds) : '',

            members: [],
            actions: affiliation.actions.map(action => getIdFromMapIfExists(action.id, duplicationMap.affiliationTypeActionIds)),

            createdTime: now,
            lastUpdatedTime: now,
        }));

        for (const action of affiliation.actions) {
            store.dispatch(instantiateMemberTypeAction({
                id: getIdFromMapIfExists(action.id, duplicationMap.affiliationTypeActionIds),
                name: action.name,
                icon: action.icon,
                workflowType: action.workflowType
            }, duplicationMap.affiliationTypeIds[affiliation.id]));
        }

        for (const customField of affiliation.customFields) {

            if (customField.startPiece) {
                let newId = '';
                try {
                    newId = importPiece(pieceState, duplicationMap, addNewPieceToList, customField.startPiece.piece);
                } catch (e) {
                    if (e instanceof Error) {
                        throw new Error(`Error when importing custom field ${customField.name}: ${e.message}`);
                    }
                }

                piecesMap[customField.startPiece.piece] = newId;
            }

            for (const variableId of customField.variables) {
                const exportedVariable = importData.variableState.byId[variableId]

                if (!!exportedVariable) {
                    newVariables.push({
                        id: duplicationMap.variableIds[exportedVariable.id],
                        name: exportedVariable.name,
                        type: exportedVariable.type,
                    });
                }
            }

            store.dispatch(instantiateMemberTypeCustomField({
                id: duplicationMap.affiliationTypeCustomFieldIds[customField.id],
                name: customField.name,
                type: customField.type,
                isComputed: customField.isComputed,
                isInTable: customField.isInTable,
                isEditable: customField.isEditable,
                isDeletable: customField.isDeletable,
                seedEntityVariable: duplicationMap.variableIds[customField.seedEntityVariable],

                parentId: duplicationMap.affiliationTypeIds[affiliation.id],
                choices: [],

                startPiece: customField.startPiece ? {
                    piece: piecesMap[customField.startPiece.piece],
                    position: {
                        x: 0,
                        y: 0,
                    }
                } : undefined,
                variables: customField.variables.map(variableId => duplicationMap.variableIds[variableId]),
                isolatedPieces: [],

                createdTime: now,
                lastUpdatedTime: now,
            }, duplicationMap.affiliationTypeIds[affiliation.id]));

            for (const customFieldOption of customField.options) {
                store.dispatch(instantiateMemberTypeCustomFieldOption({
                    id: duplicationMap.affiliationTypeCustomFieldOptionIds[customFieldOption.id],
                    name: customFieldOption.name,
                    parentId: duplicationMap.affiliationTypeCustomFieldIds[customField.id],
                    createdTime: now,
                    lastUpdatedTime: now,
                }, duplicationMap.affiliationTypeCustomFieldIds[customField.id]));
            }
        }
    } else if (!existingAffiliatedId && importData.affiliation && importAffiliation === 'group' && isUUID(workflowType.affiliatedEntity)) {
        const affiliation = importData.affiliation as ExportedGroupType;
        affiliatedEntity = affiliation.id;

        store.dispatch(instantiateGroupType({
            id: duplicationMap.affiliationTypeIds[affiliation.id],
            name: importAffiliatedEntityName,
            project: importProjectId,
            level: importLevelId,
            isRequired: true,
            customFields: [],

            nameFieldId: getIdFromMapIfExists(affiliation.nameFieldId, duplicationMap.affiliationTypeCustomFieldIds),
            subTitleFieldId: getIdFromMapIfExists(affiliation.subTitleFieldId, duplicationMap.affiliationTypeCustomFieldIds),

            uniqueFieldId: getIdFromMapIfExists(affiliation.uniqueFieldId, duplicationMap.affiliationTypeCustomFieldIds),

            groups: [],
            actions: affiliation.actions.map(action => getIdFromMapIfExists(action.id, duplicationMap.affiliationTypeActionIds)),

            createdTime: now,
            lastUpdatedTime: now,
        }));

        for (const action of affiliation.actions) {
            store.dispatch(instantiateGroupTypeAction({
                id: getIdFromMapIfExists(action.id, duplicationMap.affiliationTypeActionIds),
                name: action.name,
                icon: action.icon,
                workflowType: action.workflowType,
            }, duplicationMap.affiliationTypeIds[affiliation.id]));
        }

        for (const customField of affiliation.customFields) {

            if (customField.startPiece) {
                let newId = '';
                try {
                    newId = importPiece(pieceState, duplicationMap, addNewPieceToList, customField.startPiece.piece);
                } catch (e) {
                    if (e instanceof Error) {
                        throw new Error(`Error when importing custom field ${customField.name}: ${e.message}`);
                    }
                }

                piecesMap[customField.startPiece.piece] = newId;
            }

            for (const variableId of customField.variables) {
                const exportedVariable = importData.variableState.byId[variableId]

                if (!!exportedVariable) {
                    newVariables.push({
                        id: duplicationMap.variableIds[exportedVariable.id],
                        name: exportedVariable.name,
                        type: exportedVariable.type,
                    });
                }
            }

            store.dispatch(instantiateGroupTypeCustomField({
                id: duplicationMap.affiliationTypeCustomFieldIds[customField.id],
                name: customField.name,
                type: customField.type,
                isComputed: customField.isComputed,
                isInTable: customField.isInTable,
                isEditable: customField.isEditable,
                isDeletable: customField.isDeletable,
                seedEntityVariable: duplicationMap.variableIds[customField.seedEntityVariable],

                parentId: duplicationMap.affiliationTypeIds[affiliation.id],
                choices: [],

                startPiece: customField.startPiece ? {
                    piece: piecesMap[customField.startPiece.piece],
                    position: {
                        x: 0,
                        y: 0,
                    }
                } : undefined,
                variables: customField.variables.map(variableId => duplicationMap.variableIds[variableId]),
                isolatedPieces: [],

                createdTime: now,
                lastUpdatedTime: now,
            }, duplicationMap.affiliationTypeIds[affiliation.id]));

            for (const customFieldOption of customField.options) {
                store.dispatch(instantiateGroupTypeCustomFieldOption({
                    id: duplicationMap.affiliationTypeCustomFieldOptionIds[customFieldOption.id],
                    name: customFieldOption.name,
                    parentId: duplicationMap.affiliationTypeCustomFieldIds[customField.id],
                    createdTime: now,
                    lastUpdatedTime: now,
                }, duplicationMap.affiliationTypeCustomFieldIds[customField.id]));
            }
        }
    }

    let betaStartPieceId = '';

    if (workflowType.startPiece) {
        let newId = '';

        try {
            newId = importPiece(pieceState, duplicationMap, addNewPieceToList, workflowType.startPiece.piece);
            betaStartPieceId = importPiece(pieceState, duplicationMap, addNewPieceToList, workflowType.startPiece.piece);
        } catch (e) {
            if (e instanceof Error) {
                throw new Error(`Error when importing workflow type ${workflowType.name}: ${e.message}`);
            }
        }
        piecesMap[workflowType.startPiece.piece] = newId;
    }

    store.dispatch(instantiateWorkflowType({
        id: duplicationMap.workflowTypeIds[workflowType.id],
        name: importWorkflowTypeName,
        project: importProjectId,

        affiliation: importAffiliation,
        affiliatedEntity: getIdFromMapIfExists(affiliatedEntity, duplicationMap.affiliationTypeIds),

        isolatedPieces: [],
        betaIsolatedPieces: [],
        previousVersions: [],
        statuses: workflowType.statuses.map(status => duplicationMap.workflowStatusIds[status.id]),
        pieces: [],
        variables: workflowType.variables.map(variableId => duplicationMap.variableIds[variableId]),
        customFields: [],

        isCore: importIsCore,
        isManaged: workflowType.isManaged,
        areMultipleInstancesAllowed: importAreMultipleInstancesAllowed,
        startPiece: {
            piece: piecesMap[workflowType.startPiece.piece],
            position: {
                x: 0,
                y: 0,
            }
        },
        betaStartPiece: {
            piece: betaStartPieceId,
            position: {
                x: 0,
                y: 0,
            }
        },
        seedEntityVariable: duplicationMap.variableIds[workflowType.seedEntityVariable],
        seedAffiliationVariable: duplicationMap.variableIds[workflowType.seedAffiliationVariable],

        workflows: [],

        createdTime: now,
        lastUpdatedTime: now,
    }));

    const addNewDataFragment = (payload: INewDataFragmentData, parentId: string) => {
        store.dispatch(addDataFragment(payload, parentId));
    }

    for (const staticInfoId of importStaticIds) {
        const staticInfo = importData.staticData?.find(data => data.id === staticInfoId);
        if (staticInfo) {
            staticInfo.id = uuid.v4();
            staticInfo.name = staticInfo.name + ' - ' + workflowType.name;
            store.dispatch(addStaticDataHolder(staticInfo));

            importStaticData(staticInfo, addNewDataFragment);
        }
    }

    for (const status of workflowType.statuses) {
        store.dispatch(instantiateStatus({
            id: duplicationMap.workflowStatusIds[status.id],
            name: status.name,
            isTerminal: status.isTerminal,
            dueInDays: status.dueInDays,
        }, duplicationMap.workflowTypeIds[workflowType.id]));
    }

    for (const customField of workflowType.customFields) {

        if (customField.startPiece) {
            let newId = '';

            try {
                newId = importPiece(pieceState, duplicationMap, addNewPieceToList, customField.startPiece.piece);
            } catch (e) {
                if (e instanceof Error) {
                    throw new Error(`Error when importing custom field ${customField.name}: ${e.message}`);
                }
            }

            piecesMap[customField.startPiece.piece] = newId;
        }

        for (const variableId of customField.variables) {
            const exportedVariable = importData.variableState.byId[variableId]

            if (!!exportedVariable) {
                newVariables.push({
                    id: duplicationMap.variableIds[exportedVariable.id],
                    name: exportedVariable.name,
                    type: exportedVariable.type,
                });
            }
        }

        store.dispatch(instantiateWorkflowTypeCustomField({
            id: duplicationMap.workflowTypeCustomFieldIds[customField.id],
            name: customField.name,
            type: customField.type,
            isComputed: customField.isComputed,
            isInTable: customField.isInTable,
            isEditable: customField.isEditable,
            isDeletable: customField.isDeletable,
            seedEntityVariable: duplicationMap.variableIds[customField.seedEntityVariable],
            seedAffiliationVariable: customField.seedAffiliationVariable ? duplicationMap.variableIds[customField.seedAffiliationVariable] : undefined,
            affiliation: customField.affiliation,

            parentId: duplicationMap.workflowTypeIds[workflowType.id],
            choices: [],

            startPiece: customField.startPiece ? {
                piece: piecesMap[customField.startPiece.piece],
                position: {
                    x: 0,
                    y: 0,
                }
            } : undefined,
            variables: customField.variables.map(variableId => duplicationMap.variableIds[variableId]),
            isolatedPieces: [],

            createdTime: now,
            lastUpdatedTime: now,
        }, duplicationMap.workflowTypeIds[workflowType.id]));

        for (const customFieldOption of customField.options) {
            store.dispatch(instantiateWorkflowTypeCustomFieldOption({
                id: duplicationMap.workflowTypeCustomFieldOptionIds[customFieldOption.id],
                name: customFieldOption.name,
                parentId: duplicationMap.workflowTypeCustomFieldIds[customField.id],
                createdTime: now,
                lastUpdatedTime: now,
            }, duplicationMap.workflowTypeCustomFieldIds[customField.id]));
        }
    }

    store.dispatch(addFullPieces(newPieces));
    store.dispatch(addVariables(newVariables));
}

export function importWorkflowType(workflowType: ExportedWorkflowType, pieceState: PieceState, duplicationMap: StateIDMapping, projectId: string, exportedVariables: Array<ExportedVariable>, isCopiedInSameProject = false) {
    const now = moment.utc().format();
    const piecesMap: {
        [oldPieceId: string]: string,
    } = {};
    let affiliatedEntity = '';

    if (workflowType.affiliation === 'member' && isUUID(workflowType.affiliatedEntity)) {
        affiliatedEntity = !isCopiedInSameProject && workflowType.affiliatedEntity in duplicationMap.memberTypeIds ? duplicationMap.memberTypeIds[workflowType.affiliatedEntity] : workflowType.affiliatedEntity;
    } else if (workflowType.affiliation === 'group' && isUUID(workflowType.affiliatedEntity)) {
        affiliatedEntity = !isCopiedInSameProject && workflowType.affiliatedEntity in duplicationMap.groupTypeIds ? duplicationMap.groupTypeIds[workflowType.affiliatedEntity] : workflowType.affiliatedEntity;
    }

    for (const variableId of workflowType.variables) {
        const exportedVariable = exportedVariables.find(exportedVariable => exportedVariable.id === variableId);

        if (!!exportedVariable) {
            store.dispatch(addVariable({
                id: duplicationMap.variableIds[exportedVariable.id],
                name: exportedVariable.name,
                type: exportedVariable.type,
            }));
        }
    }

    let betaStartPieceId = '';

    if (workflowType.startPiece) {
        let newId = '';

        try {
            newId = duplicatePiece(pieceState, duplicationMap, projectId, isCopiedInSameProject, addFullPieceToStore, workflowType.startPiece.piece);
            betaStartPieceId = duplicatePiece(pieceState, duplicationMap, projectId, isCopiedInSameProject, addFullPieceToStore, workflowType.startPiece.piece);
        } catch (e) {
            if (e instanceof Error) {
                throw new Error(`Error when importing workflow type ${workflowType.name}: ${e.message}`);
            }
        }
        piecesMap[workflowType.startPiece.piece] = newId;
    }

    store.dispatch(instantiateWorkflowType({
        id: duplicationMap.workflowTypeIds[workflowType.id],
        name: workflowType.name,
        project: projectId,

        affiliation: workflowType.affiliation,
        affiliatedEntity,

        isolatedPieces: [],
        betaIsolatedPieces: [],
        previousVersions: [],
        statuses: workflowType.statuses.map(status => duplicationMap.workflowStatusIds[status.id]),
        pieces: [],
        variables: workflowType.variables.map(variableId => duplicationMap.variableIds[variableId]),
        customFields: [],

        isCore: workflowType.isCore,
        isManaged: workflowType.isManaged,
        areMultipleInstancesAllowed: workflowType.areMultipleInstancesAllowed,
        startPiece: {
            piece: piecesMap[workflowType.startPiece.piece],
            position: {
                x: 0,
                y: 0,
            }
        },
        betaStartPiece: {
            piece: betaStartPieceId,
            position: {
                x: 0,
                y: 0,
            }
        },
        seedEntityVariable: duplicationMap.variableIds[workflowType.seedEntityVariable],
        seedAffiliationVariable: duplicationMap.variableIds[workflowType.seedAffiliationVariable],

        workflows: [],

        createdTime: now,
        lastUpdatedTime: now,
    }));

    for (const status of workflowType.statuses) {
        store.dispatch(instantiateStatus({
            id: duplicationMap.workflowStatusIds[status.id],
            name: status.name,
            isTerminal: status.isTerminal,
            dueInDays: status.dueInDays,
        }, duplicationMap.workflowTypeIds[workflowType.id]));
    }

    for (const customField of workflowType.customFields) {

        for (const variableId of customField.variables) {
            const exportedVariable = exportedVariables.find(exportedVariable => exportedVariable.id === variableId);

            if (!!exportedVariable) {
                store.dispatch(addVariable({
                    id: duplicationMap.variableIds[exportedVariable.id],
                    name: exportedVariable.name,
                    type: exportedVariable.type,
                }));
            }
        }

        if (customField.startPiece) {
            let newId = '';

            try {
                newId = duplicatePiece(pieceState, duplicationMap, projectId, isCopiedInSameProject, addFullPieceToStore, customField.startPiece.piece);
            } catch (e) {
                if (e instanceof Error) {
                    throw new Error(`Error when importing custom field ${customField.name}: ${e.message}`);
                }
            }

            piecesMap[customField.startPiece.piece] = newId;
        }

        store.dispatch(instantiateWorkflowTypeCustomField({
            id: duplicationMap.workflowTypeCustomFieldIds[customField.id],
            name: customField.name,
            type: customField.type,
            isComputed: customField.isComputed,
            isInTable: customField.isInTable,
            isEditable: customField.isEditable,
            isDeletable: customField.isDeletable,
            seedEntityVariable: duplicationMap.variableIds[customField.seedEntityVariable],
            affiliation: customField.affiliation,

            parentId: duplicationMap.levelIds[workflowType.id],
            choices: [],

            startPiece: customField.startPiece ? {
                piece: piecesMap[customField.startPiece.piece],
                position: {
                    x: 0,
                    y: 0,
                }
            } : undefined,
            variables: customField.variables.map(variableId => duplicationMap.variableIds[variableId]),
            isolatedPieces: [],

            createdTime: now,
            lastUpdatedTime: now,
        }, duplicationMap.workflowTypeIds[workflowType.id]));

        for (const customFieldOption of customField.options) {
            store.dispatch(instantiateWorkflowTypeCustomFieldOption({
                id: duplicationMap.workflowTypeCustomFieldOptionIds[customFieldOption.id],
                name: customFieldOption.name,
                parentId: duplicationMap.workflowTypeCustomFieldIds[customField.id],
                createdTime: now,
                lastUpdatedTime: now,
            }, duplicationMap.workflowTypeCustomFieldIds[customField.id]));
        }
    }
}

export function importReportType(reportType: ExportedReportType, pieceState: PieceState, duplicationMap: StateIDMapping, projectId: string, exportedVariables: Array<ExportedVariable>, isCopiedInSameProject = false) {
    const now = moment.utc().format();
    const piecesMap: {
        [oldPieceId: string]: string,
    } = {};

    for (const variableId of reportType.variables) {
        const exportedVariable = exportedVariables.find(exportedVariable => exportedVariable.id === variableId);

        if (!!exportedVariable) {
            store.dispatch(addVariable({
                id: duplicationMap.variableIds[exportedVariable.id],
                name: exportedVariable.name,
                type: exportedVariable.type,
            }));
        }
    }

    if (reportType.startPiece) {
        let newId = '';

        try {
            newId = duplicatePiece(pieceState, duplicationMap, projectId, isCopiedInSameProject, addFullPieceToStore, reportType.startPiece.piece);
        } catch (e) {
            if (e instanceof Error) {
                throw new Error(`Error when importing report type ${reportType.name}: ${e.message}`);
            }
        }

        piecesMap[reportType.startPiece.piece] = newId;
    }

    store.dispatch(instantiateReportType({
        id: duplicationMap.reportTypeIds[reportType.id],
        name: isCopiedInSameProject ? reportType.name + ' copy' : reportType.name,

        variables: reportType.variables.map(variableId => duplicationMap.variableIds[variableId]),
        startPiece: reportType.startPiece ? {
            piece: piecesMap[reportType.startPiece.piece],
            position: {
                x: 0,
                y: 0,
            }
        } : undefined,
        isolatedPieces: [],
        project: projectId,

        seedUserVariable: duplicationMap.variableIds[reportType.seedUserVariable],
        seedUsersVariable: duplicationMap.variableIds[reportType.seedUsersVariable],
        seedMembersVariable: duplicationMap.variableIds[reportType.seedMembersVariable],
        seedGroupsVariable: duplicationMap.variableIds[reportType.seedGroupsVariable],
        seedWorkflowsVariable: duplicationMap.variableIds[reportType.seedWorkflowsVariable],
        seedStartDateVariable: duplicationMap.variableIds[reportType.seedStartDateVariable],
        seedEndDateVariable: duplicationMap.variableIds[reportType.seedEndDateVariable],

        createdTime: now,
        lastUpdatedTime: now,
    }));
}

export function importFromConfiguration(configuration: ExportedConfiguration, projectId: string) {
    const duplicationMap = mapConfigIDs(configuration);
    const now = moment.utc().format();
    const piecesMap: {
        [oldPieceId: string]: string,
    } = {};

    // Structure ID maps
    for (const level of configuration.levels) {
        store.dispatch(instantiateLevel({
            id: duplicationMap.levelIds[level.id],
            name: level.name,
            project: projectId,
            children: level.roles.map(role => duplicationMap.roleIds[role.id]),
            customFields: [],
            createdTime: now,
            lastUpdatedTime: now,
        }, projectId));

        for (const customField of level.customFields) {

            for (const variableId of customField.variables) {
                const exportedVariable = configuration.variables.find(exportedVariable => exportedVariable.id === variableId);

                if (!!exportedVariable) {
                    store.dispatch(addVariable({
                        id: duplicationMap.variableIds[exportedVariable.id],
                        name: exportedVariable.name,
                        type: exportedVariable.type,
                    }));
                }
            }

            if (customField.startPiece) {
                let newId = '';

                try {
                    newId = duplicatePiece(configuration.pieceState, duplicationMap, projectId, false, addFullPieceToStore, customField.startPiece.piece);
                } catch (e) {
                    if (e instanceof Error) {
                        throw new Error(`Error when importing custom field ${customField.name}: ${e.message}`);
                    }
                }
                piecesMap[customField.startPiece.piece] = newId;
            }

            store.dispatch(instantiateLevelCustomField({
                id: duplicationMap.levelCustomFieldIds[customField.id],
                name: customField.name,
                type: customField.type,
                isComputed: customField.isComputed,
                isInTable: customField.isInTable,
                isEditable: customField.isEditable,
                isDeletable: customField.isDeletable,
                seedEntityVariable: duplicationMap.variableIds[customField.seedEntityVariable],

                parentId: duplicationMap.levelIds[level.id],
                choices: [],

                startPiece: customField.startPiece ? {
                    piece: piecesMap[customField.startPiece.piece],
                    position: {
                        x: 0,
                        y: 0,
                    }
                } : undefined,
                variables: customField.variables.map(variableId => duplicationMap.variableIds[variableId]),
                isolatedPieces: [],

                createdTime: now,
                lastUpdatedTime: now,
            }, duplicationMap.levelIds[level.id]));

            for (const customFieldOption of customField.options) {
                store.dispatch(instantiateLevelCustomFieldOption({
                    id: duplicationMap.levelCustomFieldOptionIds[customFieldOption.id],
                    name: customFieldOption.name,
                    parentId: duplicationMap.levelCustomFieldIds[customField.id],
                    createdTime: now,
                    lastUpdatedTime: now,
                }, duplicationMap.levelCustomFieldIds[customField.id]));
            }
        }

        for (const role of level.roles) {
            store.dispatch(instantiateRole({
                id: duplicationMap.roleIds[role.id],
                name: role.name,
                level: duplicationMap.levelIds[level.id],
                customFields: [],
                createdTime: now,
                lastUpdatedTime: now,
            }, duplicationMap.levelIds[level.id]));

            for (const customField of role.customFields) {

                for (const variableId of customField.variables) {
                    const exportedVariable = configuration.variables.find(exportedVariable => exportedVariable.id === variableId);

                    if (!!exportedVariable) {
                        store.dispatch(addVariable({
                            id: duplicationMap.variableIds[exportedVariable.id],
                            name: exportedVariable.name,
                            type: exportedVariable.type,
                        }));
                    }
                }

                if (customField.startPiece) {
                    let newId = '';

                    try {
                        newId = duplicatePiece(configuration.pieceState, duplicationMap, projectId, false, addFullPieceToStore, customField.startPiece.piece);
                    } catch (e) {
                        if (e instanceof Error) {
                            throw new Error(`Error when importing custom field ${customField.name}: ${e.message}`);
                        }
                    }
                    piecesMap[customField.startPiece.piece] = newId;
                }

                store.dispatch(instantiateRoleCustomField({
                    id: duplicationMap.levelCustomFieldIds[customField.id],
                    name: customField.name,
                    type: customField.type,
                    isComputed: customField.isComputed,
                    isInTable: customField.isInTable,
                    isEditable: customField.isEditable,
                    isDeletable: customField.isDeletable,
                    seedEntityVariable: duplicationMap.variableIds[customField.seedEntityVariable],

                    parentId: duplicationMap.roleIds[role.id],
                    choices: [],

                    startPiece: customField.startPiece ? {
                        piece: piecesMap[customField.startPiece.piece],
                        position: {
                            x: 0,
                            y: 0,
                        }
                    } : undefined,
                    variables: customField.variables.map(variableId => duplicationMap.variableIds[variableId]),
                    isolatedPieces: [],

                    createdTime: now,
                    lastUpdatedTime: now,
                }, duplicationMap.roleIds[role.id]));

                for (const customFieldOption of customField.options) {
                    store.dispatch(instantiateRoleCustomFieldOption({
                        id: duplicationMap.roleCustomFieldOptionIds[customFieldOption.id],
                        name: customFieldOption.name,
                        parentId: duplicationMap.roleCustomFieldIds[customField.id],
                        createdTime: now,
                        lastUpdatedTime: now,
                    }, duplicationMap.roleCustomFieldIds[customField.id]));
                }
            }
        }
    }

    store.dispatch(instantiateLevelsForProject(projectId, configuration.levels.map(level => duplicationMap.levelIds[level.id])));


    for (const memberType of configuration.memberTypes) {
        importMemberType(memberType, configuration.pieceState, duplicationMap, projectId, configuration.variables);
    }


    for (const groupType of configuration.groupTypes) {
        importGroupType(groupType, configuration.pieceState, duplicationMap, projectId, configuration.variables);
    }


    for (const workflowType of configuration.workflowTypes) {
        importWorkflowType(workflowType, configuration.pieceState, duplicationMap, projectId, configuration.variables);
    }


    for (const reportType of configuration.reportTypes) {
        importReportType(reportType, configuration.pieceState, duplicationMap, projectId, configuration.variables);
    }
}