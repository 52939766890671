import React from 'react';
import { WorkflowTypeState, IWorkflowType } from '../../../shared/store/workflows/types/types';
import { VariableState, VariableType } from '../../../shared/store/flowchart/variables/types';
import { PieceType, PieceState, FlowchartPieceActions } from '../../../shared/store/flowchart/pieces/types';
import StopPiece from '../../../components/flowchart/pieces/StopPiece';
import QuestionPiece from '../../../components/flowchart/pieces/QuestionPiece';
import StartPiece from '../../../components/flowchart/pieces/StartPiece';

import { isUUID } from '../../../shared/helpers/utilities';
import UpdateStatusPiece from '../../../components/flowchart/pieces/UpdateStatusPiece';
import UpdateDueDatePiece from '../../../components/flowchart/pieces/UpdateDueDatePiece';

import { getComponent } from './index';
import { getComponentForQuestionPiece } from './question';
import { PiecePositionState, Position } from '../../../shared/helpers/common-types';
import ShowPiece from '../pieces/ShowPiece';
import StartWorkflowPiece from '../pieces/StartWorkflowPiece';
import TransferPiece from '../pieces/TransferPiece';
import GroupPiece from '../pieces/GroupPiece';
import SectionPiece from '../pieces/SectionPiece';
import GroupedQuestionPiece from '../pieces/GroupedQuestionPiece';
import GroupedShowPiece from '../pieces/GroupedShowPiece';
import ErrorPiece from '../pieces/ErrorPiece';
import CustomFieldPiece from '../pieces/CustomFieldPiece';
import { getPieceValueType } from '../../../shared/store/flowchart/helpers';
import ReturnPiece from '../pieces/ReturnPiece';
import GroupedAnswerPiece from '../pieces/GroupedAnswerPiece';
import StatusPiece from '../pieces/StatusPiece';
import SuccessPiece from '../pieces/SuccessPiece';
import AddMemberPiece from '../pieces/AddMemberPiece';
import AddGroupPiece from '../pieces/AddGroupPiece';
import AddWorkflowPiece from '../pieces/AddWorkflowPiece';
import AddReportPiece from '../pieces/AddReportPiece';
import ChoosePiece from '../pieces/ChoosePiece';
import GroupedChoosePiece from '../pieces/GroupedChoosePiece';
import SetMembersInGroupPiece from '../pieces/SetMembersInGroupPiece';
import SwitchWorkflowPiece from '../pieces/SwitchWorkflowPiece';
import RestrictNavigationPiece from '../pieces/RestrictNavigationPiece';
import ContinuePiece from '../pieces/ContinuePiece';
import GetCurrentLocationPiece from '../pieces/GetCurrentLocationPiece';
import ArchivePiece from '../pieces/ArchivePiece';
import { setReportName, setReportUser, setReportStartDate, setReportEndDate } from '../../../shared/store/flowchart/pieces/actions';
import store from '../../../shared/store/main';
import { IStatus } from '../../../shared/store/workflows/types/statuses/types';
import { FieldType, WorkflowTypeCustomField } from '../../../shared/store/custom-fields/types';
import SetLocationPiece from '../pieces/SetLocationPiece';
import FinsalLoanProcessPiece from '../pieces/FinsalLoanProcessPiece';
import { ArithmeticOperatorsPieces, BooleanOperatorsPieces, ConstantPieces, ControlPieces, CustomFieldPieces, DateOperatorsPieces, IntegrationsPieces, ListOperatorsPieces, QuestionAnswerPieces, VariablePieces } from './piece-categories';

export function getComponentForWorkflowPiece(typeState: WorkflowTypeState, piecesState: PieceState, variablesState: VariableState, workflowType: IWorkflowType, isEditable = true, flowchartPieceActions: FlowchartPieceActions, isolatePiece: (pieceState: PiecePositionState) => void, removeIsolatedPiece: (pieceId: string) => void, registerVariable: (variableId: string) => void, pieceId: string, detachPiece?: () => void, initialPosition?: Position, version = 'live'): JSX.Element {
    try {
        const workflowPiece = piecesState.byId[pieceId];

        const statuses = workflowType.statuses.map(statusId => typeState.statuses.byId[statusId]);
        const getTempInnerComponentShorthand = getComponentForWorkflowPiece.bind({}, typeState, piecesState, variablesState, workflowType);
        const getTemp2InnerComponentShorthand = getTempInnerComponentShorthand.bind({}, isEditable, flowchartPieceActions, isolatePiece, removeIsolatedPiece);
        const getInnerComponentShorthand = getTemp2InnerComponentShorthand.bind({}, registerVariable);

        const getTemp3InnerComponentShorthand = getComponentForQuestionPiece.bind({}, typeState, piecesState, variablesState, workflowType);
        const getTemp4InnerComponentShorthand = getTemp3InnerComponentShorthand.bind({}, isEditable, flowchartPieceActions, isolatePiece, removeIsolatedPiece);
        const getQuestionComponentShorthand = getTemp4InnerComponentShorthand.bind({}, registerVariable);

        const detachNextPiece = flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined);
        const detachInnerPiece = flowchartPieceActions.setInnerPiece.bind({}, pieceId, undefined);

        let customFields, currentCustomField;

        let customField: WorkflowTypeCustomField | undefined;
        let isShowingMemberPiece: boolean;
        let customFieldMemberVariable: JSX.Element | undefined;

        switch (workflowPiece.type) {

            case PieceType.START:
                const startNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, detachNextPiece) : undefined;
                return <StartPiece pieceId={pieceId} initialPosition={workflowType.startPiece && workflowType.startPiece.position} nextPiece={startNextPiece} isDragDisabled={!isEditable} removeIsolatedPiece={removeIsolatedPiece} />

            case PieceType.RESTRICT_NAVIGATION:
                const restrictNavigationNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, detachNextPiece) : undefined;

                return <RestrictNavigationPiece
                    pieceId={pieceId}

                    nextPiece={restrictNavigationNextPiece}

                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    initialPosition={initialPosition}
                />

            case PieceType.GET_CURRENT_LOCATION:

                const variables = workflowType.variables.map(variableId => {
                    return {
                        name: variablesState.byId[variableId].name,
                        value: variableId,
                    };
                });
                const getCurrentLocationNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, detachNextPiece) : undefined;
                const settingVariableName = workflowPiece.variable ? variablesState.byId[workflowPiece.variable].name : undefined;

                return <GetCurrentLocationPiece
                    pieceId={pieceId}

                    nextPiece={getCurrentLocationNextPiece}

                    variables={variables}
                    settingVariableName={settingVariableName}

                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    initialPosition={initialPosition}
                />

            case PieceType.CONTINUE:
                const continueNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, detachNextPiece) : undefined;

                const conditionPiece = workflowPiece.condition ? getInnerComponentShorthand(workflowPiece.condition, flowchartPieceActions.setConditionPiece.bind({}, pieceId, undefined)) : undefined;

                const continueMessageVariablePiece = workflowPiece.message && isUUID(workflowPiece.message) ? getInnerComponentShorthand(workflowPiece.message, flowchartPieceActions.setMessage.bind({}, pieceId, undefined)) : undefined;
                const continueMessageVariableText = workflowPiece.message;

                return <ContinuePiece
                    pieceId={pieceId}

                    nextPiece={continueNextPiece}

                    conditionPiece={conditionPiece}
                    messagePiece={continueMessageVariablePiece}
                    messageText={continueMessageVariableText}

                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    initialPosition={initialPosition}
                />

            case PieceType.END:
                const terminalStates = statuses.filter(status => status.isTerminal).map(status => {
                    return {
                        name: status.name,
                        value: status.id,
                    };
                });

                const messageVariablePiece = workflowPiece.message && isUUID(workflowPiece.message) ? getInnerComponentShorthand(workflowPiece.message, flowchartPieceActions.setMessage.bind({}, pieceId, undefined)) : undefined;
                const messageVariableText = workflowPiece.message;

                const workflowVariables = workflowType.variables
                    .filter(variableId => {
                        const variable = variablesState.byId[variableId];
                        return variable.type === VariableType.WORKFLOW;
                    })
                    .map(variableId => {
                        return {
                            name: variablesState.byId[variableId].name,
                            value: variableId,
                        };
                    });

                return <StopPiece
                    pieceId={pieceId}

                    terminalStates={terminalStates}
                    stopStateId={workflowPiece.status}
                    messageVariablePiece={messageVariablePiece}
                    messageText={messageVariableText}

                    selectedWorkflow={workflowPiece.workflow}
                    workflowVariables={workflowVariables}

                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    initialPosition={initialPosition}
                />

            case PieceType.RETURN:
                const returnVariablePiece = workflowPiece.returnValue && isUUID(workflowPiece.returnValue) ? getInnerComponentShorthand(workflowPiece.returnValue, flowchartPieceActions.setReturnVariable.bind({}, pieceId, undefined)) : undefined;
                const returnVariableText = workflowPiece.returnValue;

                return <ReturnPiece
                    pieceId={pieceId}
                    returnVariablePiece={returnVariablePiece}
                    returnVariableText={returnVariableText}
                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    initialPosition={initialPosition}
                />


            case PieceType.SUCCESS:

                return <SuccessPiece
                    pieceId={pieceId}

                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    initialPosition={initialPosition}
                />


            case PieceType.ERROR:
                const errorVariableText = workflowPiece.error;
                const errorVariablePiece = workflowPiece.error && isUUID(workflowPiece.error) ? getInnerComponentShorthand(workflowPiece.error, flowchartPieceActions.setErrorValue.bind({}, pieceId, undefined)) : undefined;

                return <ErrorPiece
                    errorVariablePiece={errorVariablePiece}
                    pieceId={pieceId}
                    errorVariableText={errorVariableText}
                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    initialPosition={initialPosition}
                />

            case PieceType.SHOW:
            case PieceType.GROUPED_SHOW:
                let showNextPiece;

                try {
                    showNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, detachNextPiece) : undefined;
                } catch {
                    // To avoid maximum callstack error for a one kind of flowchart workflow type
                }

                const showPieceVariable = workflowPiece.variableToShow && isUUID(workflowPiece.variableToShow) ? getInnerComponentShorthand(workflowPiece.variableToShow, flowchartPieceActions.setVariableForShow.bind({}, pieceId, undefined)) : undefined;

                const showIsHiddenPiece = workflowPiece.isHiddenPiece ? getInnerComponentShorthand(workflowPiece.isHiddenPiece, flowchartPieceActions.setHiddenPieceForShow.bind({}, pieceId, undefined)) : undefined;

                const showPieceText = workflowPiece.variableToShow && !isUUID(workflowPiece.variableToShow) ? workflowPiece.variableToShow : undefined;

                const showPieceRichTextEditor = workflowPiece.isRichText;

                const showVariableType = workflowPiece.variableToShow && isUUID(workflowPiece.variableToShow) ? getPieceValueType(workflowPiece.variableToShow, piecesState, variablesState) : VariableType.TEXT;

                const showDisplayType = workflowPiece.startingDisplayType;
                const showWidgetTitle = workflowPiece.widgetTitle;
                const showXAxis = workflowPiece.xAxis;
                const showYAxis = workflowPiece.yAxis;
                const showYAxisAggregation = workflowPiece.yAxisAggregation;

                let selectedTypeForShow: 'User' | 'Member' | 'Group' | 'Workflow' | 'Data fragment' | 'Text' | 'Table' = 'Text';

                if (typeof showVariableType === 'undefined') {
                    selectedTypeForShow = 'Text';
                } else if (showVariableType === VariableType.TEXT || showVariableType === VariableType.TEXT_LIST) {
                    selectedTypeForShow = 'Text';
                } else if (showVariableType === VariableType.USER || showVariableType === VariableType.USERS_LIST) {
                    selectedTypeForShow = 'User';
                } else if (showVariableType === VariableType.MEMBER || showVariableType === VariableType.MEMBERS_LIST) {
                    selectedTypeForShow = 'Member';
                } else if (showVariableType === VariableType.GROUP || showVariableType === VariableType.GROUPS_LIST) {
                    selectedTypeForShow = 'Group';
                } else if (showVariableType === VariableType.WORKFLOW || showVariableType === VariableType.WORKFLOWS_LIST) {
                    selectedTypeForShow = 'Workflow';
                } else if (showVariableType === VariableType.DATA_FRAGMENT || showVariableType === VariableType.DATA_FRAGMENTS_LIST) {
                    selectedTypeForShow = 'Data fragment';
                } else if (showVariableType === VariableType.TABLE_DATA || showVariableType === VariableType.STYLED_TABLE_DATA) {
                    selectedTypeForShow = 'Table';
                }

                if (workflowPiece.type === PieceType.SHOW) {

                    return <ShowPiece
                        pieceId={pieceId}

                        selectedType={selectedTypeForShow}
                        variableType={showVariableType}
                        selectedEntityType={workflowPiece.entityType}
                        selectedFields={workflowPiece.customFieldIds}
                        variablePiece={showPieceVariable}
                        textToShow={showPieceText}
                        isRichText={showPieceRichTextEditor}
                        widgetTitle={showWidgetTitle}
                        startingDisplayType={showDisplayType}
                        xAxis={showXAxis}
                        yAxis={showYAxis}
                        yAxisAggregation={showYAxisAggregation}

                        nextPiece={showNextPiece}
                        isDragDisabled={!isEditable}
                        detachPiece={detachPiece}
                        isolatePiece={isolatePiece}
                        removeIsolatedPiece={removeIsolatedPiece}
                        initialPosition={initialPosition}
                        isHiddenPiece={showIsHiddenPiece}
                    />

                } else if (workflowPiece.type === PieceType.GROUPED_SHOW) {

                    return <GroupedShowPiece
                        pieceId={pieceId}

                        selectedType={selectedTypeForShow}
                        variableType={showVariableType}
                        selectedEntityType={workflowPiece.entityType}
                        selectedFields={workflowPiece.customFieldIds}
                        variablePiece={showPieceVariable}
                        textToShow={showPieceText}
                        isRichText={showPieceRichTextEditor}
                        widgetTitle={showWidgetTitle}
                        startingDisplayType={showDisplayType}
                        xAxis={showXAxis}
                        yAxis={showYAxis}
                        yAxisAggregation={showYAxisAggregation}

                        nextPiece={showNextPiece}
                        isDragDisabled={!isEditable}
                        detachPiece={detachPiece}
                        isolatePiece={isolatePiece}
                        removeIsolatedPiece={removeIsolatedPiece}
                        initialPosition={initialPosition}
                        isHiddenPiece={showIsHiddenPiece}
                    />

                } else {
                    throw new Error('Unknown show piece type');
                }


            case PieceType.START_WORKFLOW:
                const startWorkflowNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, detachNextPiece) : undefined;

                const startWorkflowDueDateVariable = workflowPiece.workflowDueDateVariable && isUUID(workflowPiece.workflowDueDateVariable) ? getInnerComponentShorthand(workflowPiece.workflowDueDateVariable, flowchartPieceActions.setQuestionData.bind({}, pieceId, undefined)) : undefined;

                const startWorkflowAffiliationVariable = workflowPiece.affiliationVariable && isUUID(workflowPiece.affiliationVariable) ? getInnerComponentShorthand(workflowPiece.affiliationVariable, flowchartPieceActions.setQuestionData.bind({}, pieceId, undefined)) : undefined;

                return <StartWorkflowPiece
                    pieceId={pieceId}

                    workflowIsAsync={workflowPiece.isAsync}
                    workflowType={workflowPiece.workflowType}
                    workflowStatus={workflowPiece.workflowStatus}
                    workflowDueDatePiece={startWorkflowDueDateVariable}
                    workflowAffiliationVariablePiece={startWorkflowAffiliationVariable}

                    nextPiece={startWorkflowNextPiece}
                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    initialPosition={initialPosition}
                />


            case PieceType.SWITCH_WORKFLOW:
                const switchWorkflowNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, detachNextPiece) : undefined;

                const switchWorkflowVariable = workflowPiece.variablePiece && isUUID(workflowPiece.variablePiece) ? getInnerComponentShorthand(workflowPiece.variablePiece, flowchartPieceActions.setVariablePiece.bind({}, pieceId, undefined)) : undefined;

                return <SwitchWorkflowPiece
                    pieceId={pieceId}

                    workflowVariablePiece={switchWorkflowVariable}

                    nextPiece={switchWorkflowNextPiece}
                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    initialPosition={initialPosition}
                />

            case PieceType.UPDATE_STATUS:
                const updateStatusNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, detachNextPiece) : undefined;

                const updateStatusWorkflowVariables = workflowType.variables
                    .filter(variableId => {
                        const variable = variablesState.byId[variableId];
                        return variable.type === VariableType.WORKFLOW;
                    })
                    .map(variableId => {
                        return {
                            name: variablesState.byId[variableId].name,
                            value: variableId,
                        };
                    });

                let updatingWorkflowStatuses: Array<IStatus> = [];

                if (workflowPiece.workflowType && isUUID(workflowPiece.workflowType)) {
                    const statusIds = typeState.byId[workflowPiece.workflowType].statuses;
                    updatingWorkflowStatuses = statusIds.map(statusId => typeState.statuses.byId[statusId]);
                } else {
                    updatingWorkflowStatuses = statuses;
                }

                const states = updatingWorkflowStatuses.map(status => {
                    return {
                        name: status.name,
                        value: status.id,
                    };
                });

                return <UpdateStatusPiece
                    pieceId={pieceId}
                    states={states}
                    selectedWorkflowTypeId={workflowPiece.workflowType ? workflowPiece.workflowType : workflowType.id}
                    workflowVariableId={workflowPiece.workflow}
                    variables={updateStatusWorkflowVariables}
                    updateStatusId={workflowPiece.status}
                    nextPiece={updateStatusNextPiece}
                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    initialPosition={initialPosition}
                />

            case PieceType.UPDATE_DUE_DATE:
                const updateDueDateNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, detachNextPiece) : undefined;

                const dueDatePiece = workflowPiece.dueDate && isUUID(workflowPiece.dueDate) ? getInnerComponentShorthand(workflowPiece.dueDate, flowchartPieceActions.setDueDatePiece.bind({}, pieceId, undefined)) : undefined;
                const dueDateText = !(workflowPiece.dueDate && isUUID(workflowPiece.dueDate)) ? workflowPiece.dueDate : undefined;

                const updateDateWorkflowVariables = workflowType.variables
                    .filter(variableId => {
                        const variable = variablesState.byId[variableId];
                        return variable.type === VariableType.WORKFLOW;
                    })
                    .map(variableId => {
                        return {
                            name: variablesState.byId[variableId].name,
                            value: variableId,
                        };
                    });

                return <UpdateDueDatePiece
                    pieceId={pieceId}

                    dueDatePiece={dueDatePiece}
                    dueDateText={dueDateText}
                    workflowVariableId={workflowPiece.workflow}
                    variables={updateDateWorkflowVariables}

                    nextPiece={updateDueDateNextPiece}
                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    initialPosition={initialPosition}
                />


            case PieceType.QUESTION:
            case PieceType.GROUPED_QUESTION:
                const questionPiece = workflowPiece.question && isUUID(workflowPiece.question) ? getInnerComponentShorthand(workflowPiece.question, flowchartPieceActions.setQuestionData.bind({}, pieceId, undefined)) : undefined;
                const questionText = !(workflowPiece.question && isUUID(workflowPiece.question)) ? workflowPiece.question : undefined;

                const questionDefaultPiece = workflowPiece.default && isUUID(workflowPiece.default) ? getInnerComponentShorthand(workflowPiece.default, flowchartPieceActions.setDefaultPiece.bind({}, pieceId, undefined)) : undefined;
                const questionDefaultText = !(workflowPiece.default && isUUID(workflowPiece.default)) ? workflowPiece.default : undefined;

                const questionNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, detachNextPiece) : undefined;

                const questionInnerPiece = workflowPiece.innerPiece ? getQuestionComponentShorthand(workflowPiece.innerPiece, detachInnerPiece) : undefined;

                customFields = workflowType.customFields
                    .map(customFieldId => typeState.customFields.byId[customFieldId])
                    .filter(customField => !customField.isComputed);

                customFields = customFields.map(customField => {
                    return {
                        name: customField.name,
                        value: customField.id,
                    };
                });
                currentCustomField = workflowPiece.customFieldId ? typeState.customFields.byId[workflowPiece.customFieldId] : undefined;

                const questionMemberVariable = workflowPiece.memberVariablePiece ? getInnerComponentShorthand(workflowPiece.memberVariablePiece, flowchartPieceActions.setMemberVariable.bind({}, pieceId, undefined)) : undefined;

                const questionIsRequiredPiece = workflowPiece.isRequiredPiece ? getInnerComponentShorthand(workflowPiece.isRequiredPiece, flowchartPieceActions.setRequiredPiece.bind({}, pieceId, undefined)) : undefined;

                const questionIsDisabledPiece = workflowPiece.isDisabledPiece ? getInnerComponentShorthand(workflowPiece.isDisabledPiece, flowchartPieceActions.setDisabledPiece.bind({}, pieceId, undefined)) : undefined;
                
                const questionIsHiddenPiece = workflowPiece.isHiddenPiece ? getInnerComponentShorthand(workflowPiece.isHiddenPiece, flowchartPieceActions.setHiddenPiece.bind({}, pieceId, undefined)) : undefined;

                const questionImage = workflowPiece.image;

                const isForSingleMember = workflowType.affiliation === 'group' && currentCustomField && currentCustomField.affiliation === 'member';

                if (workflowPiece.type === PieceType.QUESTION) {

                    return <QuestionPiece
                        pieceId={pieceId}
                        questionVariablePiece={questionPiece}
                        questionText={questionText}
                        customFields={customFields}

                        isRequiredPiece={questionIsRequiredPiece}
                        isDisabledPiece={questionIsDisabledPiece}
                        isHiddenPiece={questionIsHiddenPiece}

                        defaultPiece={questionDefaultPiece}
                        image={questionImage}
                        defaultText={questionDefaultText}

                        isForSingleMember={isForSingleMember}
                        memberVariable={questionMemberVariable}
                        selectedCustomField={currentCustomField ? currentCustomField.id : undefined}

                        nextPiece={questionNextPiece}
                        innerPiece={questionInnerPiece}

                        isDragDisabled={!isEditable}
                        detachPiece={detachPiece}
                        isolatePiece={isolatePiece}
                        removeIsolatedPiece={removeIsolatedPiece}
                        initialPosition={initialPosition}
                    />

                } else if (workflowPiece.type === PieceType.GROUPED_QUESTION) {

                    return <GroupedQuestionPiece
                        pieceId={pieceId}
                        questionVariablePiece={questionPiece}
                        questionText={questionText}
                        customFields={customFields}

                        isRequiredPiece={questionIsRequiredPiece}
                        isDisabledPiece={questionIsDisabledPiece}
                        isHiddenPiece={questionIsHiddenPiece}

                        defaultPiece={questionDefaultPiece}
                        image={questionImage}
                        defaultText={questionDefaultText}

                        isForSingleMember={isForSingleMember}
                        memberVariable={questionMemberVariable}
                        selectedCustomField={currentCustomField ? currentCustomField.id : undefined}

                        nextPiece={questionNextPiece}
                        innerPiece={questionInnerPiece}

                        isDragDisabled={!isEditable}
                        detachPiece={detachPiece}
                        isolatePiece={isolatePiece}
                        removeIsolatedPiece={removeIsolatedPiece}
                        initialPosition={initialPosition}
                    />
                } else {
                    throw new Error('Unknown question piece type');
                }


            case PieceType.CHOOSE:
            case PieceType.GROUPED_CHOOSE:
                const chooseListPiece = workflowPiece.variablePiece && isUUID(workflowPiece.variablePiece) ? getInnerComponentShorthand(workflowPiece.variablePiece, flowchartPieceActions.setVariablePiece.bind({}, pieceId, undefined)) : undefined;
                const chooseListType = workflowPiece.variablePiece && isUUID(workflowPiece.variablePiece) ? getPieceValueType(workflowPiece.variablePiece, piecesState, variablesState) : VariableType.TEXT_LIST;
                let selectedChooseListType: 'Project' | 'Level' | 'Role' | 'Location' | 'User' | 'Member' | 'Group' | 'Workflow' | 'Data fragment' | 'Text' = 'Text';

                if (chooseListType === VariableType.PROJECTS_LIST) {
                    selectedChooseListType = 'Project';
                } else if (chooseListType === VariableType.LEVELS_LIST) {
                    selectedChooseListType = 'Level';
                } else if (chooseListType === VariableType.ROLES_LIST) {
                    selectedChooseListType = 'Role';
                } else if (chooseListType === VariableType.LOCATIONS_LIST) {
                    selectedChooseListType = 'Location';
                } else if (chooseListType === VariableType.USERS_LIST) {
                    selectedChooseListType = 'User';
                } else if (chooseListType === VariableType.MEMBERS_LIST) {
                    selectedChooseListType = 'Member';
                } else if (chooseListType === VariableType.GROUPS_LIST) {
                    selectedChooseListType = 'Group';
                } else if (chooseListType === VariableType.WORKFLOWS_LIST) {
                    selectedChooseListType = 'Workflow';
                } else if (chooseListType === VariableType.DATA_FRAGMENTS_LIST) {
                    selectedChooseListType = 'Data fragment';
                } else if (chooseListType === VariableType.TEXT_LIST) {
                    selectedChooseListType = 'Text';
                }

                const chooseQuestionPiece = workflowPiece.question && isUUID(workflowPiece.question) ? getInnerComponentShorthand(workflowPiece.question, flowchartPieceActions.setQuestionData.bind({}, pieceId, undefined)) : undefined;
                const chooseQuestionText = !(workflowPiece.question && isUUID(workflowPiece.question)) ? workflowPiece.question : undefined;

                const chooseDefaultPiece = workflowPiece.default && isUUID(workflowPiece.default) ? getInnerComponentShorthand(workflowPiece.default, flowchartPieceActions.setDefaultPiece.bind({}, pieceId, undefined)) : undefined;
                const chooseDefaultText = !(workflowPiece.default && isUUID(workflowPiece.default)) ? workflowPiece.default : undefined;

                const choiceVariables = workflowType.variables
                    .filter(variableId => {
                        const variable = variablesState.byId[variableId];

                        switch (selectedChooseListType) {
                            case 'Project':
                                return variable.type === VariableType.PROJECT || variable.type === VariableType.PROJECTS_LIST;
                            case 'Level':
                                return variable.type === VariableType.LEVEL || variable.type === VariableType.LEVELS_LIST;
                            case 'Role':
                                return variable.type === VariableType.ROLE || variable.type === VariableType.ROLES_LIST;
                            case 'Location':
                                return variable.type === VariableType.LOCATION || variable.type === VariableType.LOCATIONS_LIST;
                            case 'User':
                                return variable.type === VariableType.USER || variable.type === VariableType.USERS_LIST;
                            case 'Member':
                                return variable.type === VariableType.MEMBER || variable.type === VariableType.MEMBERS_LIST;
                            case 'Group':
                                return variable.type === VariableType.GROUP || variable.type === VariableType.GROUPS_LIST;
                            case 'Workflow':
                                return variable.type === VariableType.WORKFLOW || variable.type === VariableType.WORKFLOWS_LIST;
                            case 'Data fragment':
                                return variable.type === VariableType.DATA_FRAGMENT || variable.type === VariableType.DATA_FRAGMENTS_LIST;
                            case 'Text':
                                return variable.type === VariableType.TEXT || variable.type === VariableType.TEXT_LIST;
                            default:
                                return false;
                        }
                    })
                    .map(variableId => {
                        return {
                            name: variablesState.byId[variableId].name,
                            value: variableId,
                        };
                    });


                const chooseNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, detachNextPiece) : undefined;

                const chooseInnerPiece = workflowPiece.innerPiece ? getQuestionComponentShorthand(workflowPiece.innerPiece, detachInnerPiece) : undefined;

                const chooseIsRequiredPiece = workflowPiece.isRequiredPiece ? getInnerComponentShorthand(workflowPiece.isRequiredPiece, flowchartPieceActions.setRequiredPiece.bind({}, pieceId, undefined)) : undefined;

                const chooseIsDisabledPiece = workflowPiece.isDisabledPiece ? getInnerComponentShorthand(workflowPiece.isDisabledPiece, flowchartPieceActions.setDisabledPiece.bind({}, pieceId, undefined)) : undefined;

                const chooseIsHiddenPiece = workflowPiece.isHiddenPiece ? getInnerComponentShorthand(workflowPiece.isHiddenPiece, flowchartPieceActions.setHiddenPiece.bind({}, pieceId, undefined)) : undefined;

                if (workflowPiece.type === PieceType.CHOOSE) {

                    return <ChoosePiece
                        pieceId={pieceId}
                        questionVariablePiece={chooseQuestionPiece}
                        questionText={chooseQuestionText}

                        choiceListVariablePiece={chooseListPiece}
                        listType={selectedChooseListType}

                        isRequiredPiece={chooseIsRequiredPiece}
                        isDisabledPiece={chooseIsDisabledPiece}
                        isHiddenPiece={chooseIsHiddenPiece}

                        defaultPiece={chooseDefaultPiece}
                        defaultText={chooseDefaultText}

                        selectedChoiceVariable={workflowPiece.choiceVariable}
                        variables={choiceVariables}

                        nextPiece={chooseNextPiece}
                        innerPiece={chooseInnerPiece}

                        isDragDisabled={!isEditable}
                        detachPiece={detachPiece}
                        isolatePiece={isolatePiece}
                        removeIsolatedPiece={removeIsolatedPiece}
                        registerVariable={registerVariable}
                        initialPosition={initialPosition}
                    />

                } else if (workflowPiece.type === PieceType.GROUPED_CHOOSE) {

                    return <GroupedChoosePiece
                        pieceId={pieceId}
                        questionVariablePiece={chooseQuestionPiece}
                        questionText={chooseQuestionText}

                        choiceListVariablePiece={chooseListPiece}
                        listType={selectedChooseListType}

                        isRequiredPiece={chooseIsRequiredPiece}
                        isDisabledPiece={chooseIsDisabledPiece}
                        isHiddenPiece={chooseIsHiddenPiece}

                        defaultPiece={chooseDefaultPiece}
                        defaultText={chooseDefaultText}

                        selectedChoiceVariable={workflowPiece.choiceVariable}
                        variables={choiceVariables}

                        nextPiece={chooseNextPiece}
                        innerPiece={chooseInnerPiece}

                        isDragDisabled={!isEditable}
                        detachPiece={detachPiece}
                        isolatePiece={isolatePiece}
                        removeIsolatedPiece={removeIsolatedPiece}
                        registerVariable={registerVariable}
                        initialPosition={initialPosition}
                    />
                } else {
                    throw new Error('Unknown question piece type');
                }

            case PieceType.GROUP:
                const groupNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
                const groupInnerPiece = workflowPiece.innerPiece ? getInnerComponentShorthand(workflowPiece.innerPiece, flowchartPieceActions.setInnerPiece.bind({}, pieceId, undefined)) : undefined;
                const groupHeadingPiece = workflowPiece.heading && isUUID(workflowPiece.heading) ? getInnerComponentShorthand(workflowPiece.heading, flowchartPieceActions.setHeadingPiece.bind({}, pieceId, undefined)) : undefined;
                const groupHeadingText = !(workflowPiece.heading && isUUID(workflowPiece.heading)) ? workflowPiece.heading : undefined;

                return <GroupPiece
                    pieceId={pieceId}
                    isForMembersList={false}

                    nextPiece={groupNextPiece}

                    headingPiece={groupHeadingPiece}
                    headingText={groupHeadingText}
                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    initialPosition={initialPosition}
                >
                    {groupInnerPiece}
                </GroupPiece>

            case PieceType.SECTION:
                const sectionNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
                const sectionInnerPiece = workflowPiece.innerPiece ? getInnerComponentShorthand(workflowPiece.innerPiece, flowchartPieceActions.setInnerPiece.bind({}, pieceId, undefined)) : undefined;
                const sectionHeadingPiece = workflowPiece.heading && isUUID(workflowPiece.heading) ? getInnerComponentShorthand(workflowPiece.heading, flowchartPieceActions.setHeadingPiece.bind({}, pieceId, undefined)) : undefined;
                const sectionHeadingText = !(workflowPiece.heading && isUUID(workflowPiece.heading)) ? workflowPiece.heading : undefined;
                const sectionColumnsText = workflowPiece.columns;

                return <SectionPiece
                    pieceId={pieceId}
                    nextPiece={sectionNextPiece}

                    headingPiece={sectionHeadingPiece}
                    headingText={sectionHeadingText}
                    columns={sectionColumnsText}

                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    initialPosition={initialPosition}
                >
                    {sectionInnerPiece}
                </SectionPiece>

            case PieceType.GROUP_FOR_LIST:
                const nextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
                const innerPiece = workflowPiece.innerPiece ? getInnerComponentShorthand(workflowPiece.innerPiece, flowchartPieceActions.setInnerPiece.bind({}, pieceId, undefined)) : undefined;
                const iterablePiece = workflowPiece.iterableVariable ? getInnerComponentShorthand(workflowPiece.iterableVariable, flowchartPieceActions.setIterableVariable.bind({}, pieceId, undefined)) : undefined;
                const groupListHeadingPiece = workflowPiece.heading && isUUID(workflowPiece.heading) ? getInnerComponentShorthand(workflowPiece.heading, flowchartPieceActions.setHeadingPiece.bind({}, pieceId, undefined)) : undefined;
                const groupListHeadingText = !(workflowPiece.heading && isUUID(workflowPiece.heading)) ? workflowPiece.heading : undefined;

                const loopVariableOptions = workflowType.variables.map(variableId => {
                    return {
                        name: variablesState.byId[variableId].name,
                        value: variableId,
                    };
                });

                return <GroupPiece
                    pieceId={pieceId}
                    isForMembersList={true}
                    headingPiece={groupListHeadingPiece}
                    headingText={groupListHeadingText}
                    iterablePiece={iterablePiece}
                    variables={loopVariableOptions}
                    loopVariableId={workflowPiece.loopVariable}

                    nextPiece={nextPiece}

                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    initialPosition={initialPosition}
                >
                    {innerPiece}
                </GroupPiece>

            case PieceType.TRANSFER_WORKFLOW:
                const transferNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, detachNextPiece) : undefined;

                return <TransferPiece
                    pieceId={pieceId}
                    projectId={workflowType.project}
                    selectedRoles={workflowPiece.roles}

                    nextPiece={transferNextPiece}

                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    initialPosition={initialPosition}
                />

            case PieceType.CUSTOM_FIELD:
                customField = workflowPiece.customField ? typeState.customFields.byId[workflowPiece.customField] : undefined;

                isShowingMemberPiece = customField && !workflowPiece.customFieldOption ? workflowType.affiliation === 'group' && customField.affiliation === 'member' : false;

                customFieldMemberVariable = workflowPiece.memberVariablePiece ? getInnerComponentShorthand(workflowPiece.memberVariablePiece, flowchartPieceActions.setMemberVariable.bind({}, pieceId, undefined)) : undefined;

                return <CustomFieldPiece
                    pieceId={pieceId}

                    customFieldIds={workflowType.customFields}
                    selectedCustomFieldId={workflowPiece.customField}
                    selectedCustomFieldOptionId={workflowPiece.customFieldOption}
                    type="Workflow"

                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    initialPosition={initialPosition}

                    isShowingMemberVariablePiece={isShowingMemberPiece}
                    memberVariablePiece={customFieldMemberVariable}
                />

            case PieceType.GROUPED_ANSWER:
                customField = workflowPiece.customField ? typeState.customFields.byId[workflowPiece.customField] : undefined;

                isShowingMemberPiece = customField ? workflowType.affiliation === 'group' && customField.affiliation === 'member' : false;

                customFieldMemberVariable = workflowPiece.memberVariablePiece ? getInnerComponentShorthand(workflowPiece.memberVariablePiece, flowchartPieceActions.setMemberVariable.bind({}, pieceId, undefined)) : undefined;

                return <GroupedAnswerPiece
                    pieceId={pieceId}

                    customFieldIds={workflowType.customFields}
                    selectedAnswerId={workflowPiece.customField}

                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    initialPosition={initialPosition}

                    isShowingMemberVariablePiece={isShowingMemberPiece}
                    memberVariablePiece={customFieldMemberVariable}
                />


            case PieceType.ADD_MEMBER:
                const addMemberVariables = workflowType.variables.map(variableId => {
                    return {
                        name: variablesState.byId[variableId].name,
                        value: variableId,
                    };
                });
                const addMemberNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
                const addMemberLocationPiece = workflowPiece.locationPiece ? getInnerComponentShorthand(workflowPiece.locationPiece, flowchartPieceActions.setLocationPiece.bind({}, pieceId, undefined)) : undefined;

                return <AddMemberPiece
                    pieceId={pieceId}
                    nextPiece={addMemberNextPiece}

                    selectedEntityType={workflowPiece.entityType}
                    addingVariableId={workflowPiece.variable}
                    locationPiece={addMemberLocationPiece}
                    variables={addMemberVariables}

                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    registerVariable={registerVariable}
                    initialPosition={initialPosition}
                />


            case PieceType.ADD_GROUP:
                const addGroupVariables = workflowType.variables.map(variableId => {
                    return {
                        name: variablesState.byId[variableId].name,
                        value: variableId,
                    };
                });
                const addGroupNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
                const addGroupLocationPiece = workflowPiece.locationPiece ? getInnerComponentShorthand(workflowPiece.locationPiece, flowchartPieceActions.setLocationPiece.bind({}, pieceId, undefined)) : undefined;

                return <AddGroupPiece
                    pieceId={pieceId}
                    nextPiece={addGroupNextPiece}

                    selectedEntityType={workflowPiece.entityType}
                    addingVariableId={workflowPiece.variable}
                    locationPiece={addGroupLocationPiece}
                    variables={addGroupVariables}

                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    registerVariable={registerVariable}
                    initialPosition={initialPosition}
                />


            case PieceType.ADD_WORKFLOW:
                const addWorkflowVariables = workflowType.variables.map(variableId => {
                    return {
                        name: variablesState.byId[variableId].name,
                        value: variableId,
                    };
                });
                const addWorkflowNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
                const addWorkflowAffiliatedPiece = workflowPiece.affiliationVariable ? getInnerComponentShorthand(workflowPiece.affiliationVariable, flowchartPieceActions.setAffiliationVariablePiece.bind({}, pieceId, undefined)) : undefined;
                const addWorkflowShowErrors = workflowPiece.showWorkflowShowErrors;

                return <AddWorkflowPiece
                    pieceId={pieceId}
                    nextPiece={addWorkflowNextPiece}

                    selectedEntityType={workflowPiece.workflowType}
                    addingVariableId={workflowPiece.variable}
                    affiliatedPiece={addWorkflowAffiliatedPiece}
                    variables={addWorkflowVariables}
                    isShowingErrors={addWorkflowShowErrors}

                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    registerVariable={registerVariable}
                    initialPosition={initialPosition}
                />


            case PieceType.ADD_REPORT:
                const addReportVariables = workflowType.variables.map(variableId => {
                    return {
                        name: variablesState.byId[variableId].name,
                        value: variableId,
                    };
                });
                const addReportNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
                const addReportVariableName = workflowPiece.variable ? variablesState.byId[workflowPiece.variable].name : undefined;

                const reportNameDetach = () => store.dispatch(setReportName(pieceId, undefined));
                const reportUserDetach = () => store.dispatch(setReportUser(pieceId, undefined));
                const reportStartDateDetach = () => store.dispatch(setReportStartDate(pieceId, undefined));
                const reportEndDateDetach = () => store.dispatch(setReportEndDate(pieceId, undefined));

                const addReportNamePiece = workflowPiece.name && isUUID(workflowPiece.name) ? getInnerComponentShorthand(workflowPiece.name, reportNameDetach) : undefined;
                const addReportNameText = !(workflowPiece.name && isUUID(workflowPiece.name)) ? workflowPiece.name : undefined;

                const addReportUserPiece = workflowPiece.user && isUUID(workflowPiece.user) ? getInnerComponentShorthand(workflowPiece.user, reportUserDetach) : undefined;

                const addReportStartDatePiece = workflowPiece.startDate && isUUID(workflowPiece.startDate) ? getInnerComponentShorthand(workflowPiece.startDate, reportStartDateDetach) : undefined;
                const addReportStartDateText = !(workflowPiece.startDate && isUUID(workflowPiece.startDate)) ? workflowPiece.startDate : undefined;

                const addReportEndDatePiece = workflowPiece.endDate && isUUID(workflowPiece.endDate) ? getInnerComponentShorthand(workflowPiece.endDate, reportEndDateDetach) : undefined;
                const addReportEndDateText = !(workflowPiece.endDate && isUUID(workflowPiece.endDate)) ? workflowPiece.endDate : undefined;

                return <AddReportPiece
                    pieceId={pieceId}
                    nextPiece={addReportNextPiece}

                    selectedEntityType={workflowPiece.reportType}
                    addingVariableName={addReportVariableName}
                    namePiece={addReportNamePiece}
                    nameText={addReportNameText}
                    userPiece={addReportUserPiece}
                    startDatePiece={addReportStartDatePiece}
                    startDateText={addReportStartDateText}
                    endDatePiece={addReportEndDatePiece}
                    endDateText={addReportEndDateText}
                    variables={addReportVariables}

                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    registerVariable={registerVariable}
                    initialPosition={initialPosition}
                />

            case PieceType.ARCHIVE:
                const archiveNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
                const variablePieceForArchive = workflowPiece.variablePiece ? getInnerComponentShorthand(workflowPiece.variablePiece, flowchartPieceActions.setVariablePiece.bind({}, pieceId, undefined)) : undefined;

                return <ArchivePiece
                    pieceId={pieceId}
                    nextPiece={archiveNextPiece}
                    variablePiece={variablePieceForArchive}

                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    initialPosition={initialPosition}
                />

            case PieceType.SET_MEMBERS_IN_GROUP:

                const setMembersNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
                const variablePieceForSetMembers = workflowPiece.variablePiece ? getInnerComponentShorthand(workflowPiece.variablePiece, flowchartPieceActions.setVariablePiece.bind({}, pieceId, undefined)) : undefined;
                const iterablePieceForSetMembers = workflowPiece.iterableVariable ? getInnerComponentShorthand(workflowPiece.iterableVariable, flowchartPieceActions.setIterableVariable.bind({}, pieceId, undefined)) : undefined;

                return <SetMembersInGroupPiece
                    pieceId={pieceId}
                    nextPiece={setMembersNextPiece}

                    memberType={workflowPiece.memberType}
                    variablePiece={variablePieceForSetMembers}
                    iterableVariablePiece={iterablePieceForSetMembers}

                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    initialPosition={initialPosition}
                />

            case PieceType.SET_LOCATION:

                const setLocationNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
                const locationVariablePieceForSetLocation = workflowPiece.variablePiece ? getInnerComponentShorthand(workflowPiece.variablePiece, flowchartPieceActions.setVariablePiece.bind({}, pieceId, undefined)) : undefined;
                const affiliationVariablePieceForSetLocation = workflowPiece.affiliationVariable ? getInnerComponentShorthand(workflowPiece.affiliationVariable, flowchartPieceActions.setVariablePiece.bind({}, pieceId, undefined)) : undefined;
                const locationPieceType = workflowPiece.variablePiece ? getPieceValueType(workflowPiece.variablePiece, piecesState, variablesState) : undefined;
                const affiliationPieceType = workflowPiece.affiliationVariable ? getPieceValueType(workflowPiece.affiliationVariable, piecesState, variablesState) : undefined;

                return <SetLocationPiece
                    pieceId={pieceId}
                    nextPiece={setLocationNextPiece}

                    locationPieceType={locationPieceType}
                    affiliationPieceType={affiliationPieceType}
                    locationPiece={locationVariablePieceForSetLocation}
                    affiliationPiece={affiliationVariablePieceForSetLocation}

                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    initialPosition={initialPosition}
                />

            case PieceType.STATUS:

                return <StatusPiece
                    pieceId={pieceId}

                    statusIds={workflowType.statuses}
                    selectedStatusId={workflowPiece.statusId}

                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    initialPosition={initialPosition}
                />

            case PieceType.FINSAL_LOAN_PROCESS:
                const finsalPremiumPiece = workflowPiece.premium ? getInnerComponentShorthand(workflowPiece.premium, flowchartPieceActions.setFinsalPremium.bind({}, pieceId, undefined)) : undefined;
                const finsalMemberFirstNamePiece = workflowPiece.memberFirstName ? getInnerComponentShorthand(workflowPiece.memberFirstName, flowchartPieceActions.setFinsalMemberFirstName.bind({}, pieceId, undefined)) : undefined;
                const finsalMemberLastNamePiece = workflowPiece.memberLastName ? getInnerComponentShorthand(workflowPiece.memberLastName, flowchartPieceActions.setFinsalMemberLastName.bind({}, pieceId, undefined)) : undefined;
                const finsalMemberEmailPiece = workflowPiece.memberEmail ? getInnerComponentShorthand(workflowPiece.memberEmail, flowchartPieceActions.setFinsalMemberEmail.bind({}, pieceId, undefined)) : undefined;
                const finsalMemberPhonePiece = workflowPiece.memberPhone ? getInnerComponentShorthand(workflowPiece.memberPhone, flowchartPieceActions.setFinsalMemberPhone.bind({}, pieceId, undefined)) : undefined;
                const finsalMemberPanPiece = workflowPiece.memberPan ? getInnerComponentShorthand(workflowPiece.memberPan, flowchartPieceActions.setFinsalMemberPan.bind({}, pieceId, undefined)) : undefined;
                const finsalMemberStatePiece = workflowPiece.memberState ? getInnerComponentShorthand(workflowPiece.memberState, flowchartPieceActions.setFinsalMemberState.bind({}, pieceId, undefined)) : undefined;
                const finsalMemberCityPiece = workflowPiece.memberCity ? getInnerComponentShorthand(workflowPiece.memberCity, flowchartPieceActions.setFinsalMemberCity.bind({}, pieceId, undefined)) : undefined;
                const finsalMemberAddressLine1Piece = workflowPiece.memberAddressLine1 ? getInnerComponentShorthand(workflowPiece.memberAddressLine1, flowchartPieceActions.setFinsalMemberAddressLine1.bind({}, pieceId, undefined)) : undefined;
                const finsalMemberAddressLine2Piece = workflowPiece.memberAddressLine2 ? getInnerComponentShorthand(workflowPiece.memberAddressLine2, flowchartPieceActions.setFinsalMemberAddressLine2.bind({}, pieceId, undefined)) : undefined;
                const finsalMemberPinCodePiece = workflowPiece.memberPinCode ? getInnerComponentShorthand(workflowPiece.memberPinCode, flowchartPieceActions.setFinsalMemberPinCode.bind({}, pieceId, undefined)) : undefined;

                const finsalUserEmailPiece = workflowPiece.userEmail ? getInnerComponentShorthand(workflowPiece.userEmail, flowchartPieceActions.setFinsalUserEmail.bind({}, pieceId, undefined)) : undefined;
                const finsalUserPhonePiece = workflowPiece.userPhone ? getInnerComponentShorthand(workflowPiece.userPhone, flowchartPieceActions.setFinsalUserPhone.bind({}, pieceId, undefined)) : undefined;
                const finsalMemberPiece = workflowPiece.member ? getInnerComponentShorthand(workflowPiece.member, flowchartPieceActions.setFinsalMember.bind({}, pieceId, undefined)) : undefined;
                const finsalMemberDobPiece = workflowPiece.memberDob ? getInnerComponentShorthand(workflowPiece.memberDob, flowchartPieceActions.setFinsalMemberDOB.bind({}, pieceId, undefined)) : undefined;
                const finsalMemberGenderPiece = workflowPiece.memberGender ? getInnerComponentShorthand(workflowPiece.memberGender, flowchartPieceActions.setFinsalMemberGender.bind({}, pieceId, undefined)) : undefined;
                const finsalMemberFatherNamePiece = workflowPiece.memberFatherName ? getInnerComponentShorthand(workflowPiece.memberFatherName, flowchartPieceActions.setFinsalMemberFatherName.bind({}, pieceId, undefined)) : undefined;
                const finsalMemberMotherNamePiece = workflowPiece.memberMotherName ? getInnerComponentShorthand(workflowPiece.memberMotherName, flowchartPieceActions.setFinsalMemberMotherName.bind({}, pieceId, undefined)) : undefined;
                const finsalMemberAnnualIncomePiece = workflowPiece.memberAnnualIncome ? getInnerComponentShorthand(workflowPiece.memberAnnualIncome, flowchartPieceActions.setFinsalMemberAnnualIncome.bind({}, pieceId, undefined)) : undefined;
                const finsalMemberMaritalStatusPiece = workflowPiece.memberMaritalStatus ? getInnerComponentShorthand(workflowPiece.memberMaritalStatus, flowchartPieceActions.setFinsalMemberMaritalStatus.bind({}, pieceId, undefined)) : undefined;
                const finsalApplyForLoanPiece = workflowPiece.applyForLoan ? getInnerComponentShorthand(workflowPiece.applyForLoan, flowchartPieceActions.setFinsalApplyForLoan.bind({}, pieceId, undefined)) : undefined;

                const loanProcessNextPiece = workflowPiece.nextPiece ? getInnerComponentShorthand(workflowPiece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;

                const finsalTextCustomFields = workflowType.customFields
                    .filter(customFieldId => {
                        const customField = typeState.customFields.byId[customFieldId];
                        return customField.type === FieldType.TEXT;
                    })
                    .map(customFieldId => {
                        const customField = typeState.customFields.byId[customFieldId];
                        return {
                            name: customField.name,
                            value: customFieldId,
                        };
                    });

                return <FinsalLoanProcessPiece
                    pieceId={pieceId}
                    nextPiece={loanProcessNextPiece}

                    premiumPiece={finsalPremiumPiece}
                    memberFirstNamePiece={finsalMemberFirstNamePiece}
                    memberLastNamePiece={finsalMemberLastNamePiece}
                    memberEmailPiece={finsalMemberEmailPiece}
                    memberPhonePiece={finsalMemberPhonePiece}
                    memberPanPiece={finsalMemberPanPiece}
                    memberStatePiece={finsalMemberStatePiece}
                    memberCityPiece={finsalMemberCityPiece}

                    memberAddressLine1Piece={finsalMemberAddressLine1Piece}
                    memberAddressLine2Piece={finsalMemberAddressLine2Piece}
                    memberPinCodePiece={finsalMemberPinCodePiece}
                    memberDobPiece={finsalMemberDobPiece}
                    memberGenderPiece={finsalMemberGenderPiece}
                    memberFatherNamePiece={finsalMemberFatherNamePiece}
                    memberMotherNamePiece={finsalMemberMotherNamePiece}
                    memberAnnualIncomePiece={finsalMemberAnnualIncomePiece}
                    memberMaritalStatusPiece={finsalMemberMaritalStatusPiece}
                    applyForLoanPiece={finsalApplyForLoanPiece}

                    userPhonePiece={finsalUserPhonePiece}
                    userEmailPiece={finsalUserEmailPiece}
                    memberPiece={finsalMemberPiece}

                    mode={workflowPiece.mode}

                    responseCustomFieldId={workflowPiece.response}
                    customFields={finsalTextCustomFields}

                    isDragDisabled={!isEditable}
                    detachPiece={detachPiece}
                    isolatePiece={isolatePiece}
                    removeIsolatedPiece={removeIsolatedPiece}
                    initialPosition={initialPosition}
                />

            default:
                return getComponent(pieceId, piecesState, variablesState, workflowType.variables, getInnerComponentShorthand, isEditable, flowchartPieceActions, isolatePiece, removeIsolatedPiece, registerVariable, detachPiece, initialPosition);
        }
    } catch {
        return <React.Fragment></React.Fragment>;
    }
}

export const piecesByCategory = {
    'Control': {
        color: '#14b1ab',
        pieces: [
            ControlPieces.For,
            ControlPieces.End,
            ControlPieces.Return,
            ControlPieces.Status,
            ControlPieces.UpdateStatus,
            ControlPieces.UpdateDueDate,
            ControlPieces.Split,
            ControlPieces.Show,
            ControlPieces.GroupedShow,
            ControlPieces.SwitchWorkflow,
            ControlPieces.Transfer,
            ControlPieces.Group,
            ControlPieces.GroupForList,
            ControlPieces.Section,
            ControlPieces.AddMember,
            ControlPieces.AddGroup,
            ControlPieces.AddWorkflow,
            ControlPieces.AddReport,
            ControlPieces.Archive,
            ControlPieces.SetMembersInGroup,
            ControlPieces.SetLocation,
            ControlPieces.Structure,
            ControlPieces.StaticData,
            ControlPieces.GetAffiliation,
            ControlPieces.RestrictNavigation,
            ControlPieces.GetCurrentLocation,
            ControlPieces.Continue,
            ControlPieces.Translate,
            ControlPieces.Format,
            ControlPieces.GetEntities,
        ],
    },
    'Constants': {
        color: '#efaa4b',
        pieces: [
            ConstantPieces.Today,
            ConstantPieces.Now,
            ConstantPieces.True,
            ConstantPieces.TrueHexagonal,
            ConstantPieces.False,
            ConstantPieces.FalseHexagonal,
            ConstantPieces.LoggedInUser,
            ConstantPieces.FinancialYearMonths,
        ],
    },
    'Arithmetic Operators': {
        color: '#efaa4b',
        pieces: [
            ArithmeticOperatorsPieces.Subtract,
            ArithmeticOperatorsPieces.Add,
            ArithmeticOperatorsPieces.Multiply,
            ArithmeticOperatorsPieces.Divide,
            ArithmeticOperatorsPieces.Exponent,
        ],
    },
    'Boolean Operators': {
        color: '#efaa4b',
        pieces: [
            BooleanOperatorsPieces.LesserThan,
            BooleanOperatorsPieces.LesserThanOrEqualTo,
            BooleanOperatorsPieces.GreaterThan,
            BooleanOperatorsPieces.GreaterThanOrEqualTo,
            BooleanOperatorsPieces.EqualTo,
            BooleanOperatorsPieces.NotEqualTo,
            BooleanOperatorsPieces.In,
            BooleanOperatorsPieces.NotIn,
            BooleanOperatorsPieces.And,
            BooleanOperatorsPieces.Or,
            BooleanOperatorsPieces.Not,
            BooleanOperatorsPieces.VariableToBoolean,
            BooleanOperatorsPieces.BooleanToVariable,
            BooleanOperatorsPieces.IsDefined,
            BooleanOperatorsPieces.IsNotDefined
        ],
    },
    'List Operators': {
        color: '#efaa4b',
        pieces: [
            ListOperatorsPieces.PickFirstElement,
            ListOperatorsPieces.PickFirstNElements,
            ListOperatorsPieces.PickLastElement,
            ListOperatorsPieces.PickLastNElements,
            ListOperatorsPieces.PickNthElement,
            ListOperatorsPieces.SplitBySeparator,
            ListOperatorsPieces.AddToList,
            ListOperatorsPieces.RemoveFromList,
            ListOperatorsPieces.AddToTable,
            ListOperatorsPieces.Length,
        ],
    },
    'Date Operators': {
        color: '#efaa4b',
        pieces: [
            DateOperatorsPieces.AddMonths,
            DateOperatorsPieces.AddYears,
            DateOperatorsPieces.SubtractMonths,
            DateOperatorsPieces.SubtractYears,
            DateOperatorsPieces.GetDate,
            DateOperatorsPieces.GetDay,
            DateOperatorsPieces.GetMonth,
            DateOperatorsPieces.GetReadableMonth,
            DateOperatorsPieces.GetYear,
            DateOperatorsPieces.GetTimeDifference,
        ],
    },
    'Variables': {
        color: '#8891c8',
        pieces: [
            VariablePieces.Variable,
            VariablePieces.SetVariable,
            VariablePieces.StyleTableVariable,
        ],
    },
    'Custom Fields': {
        color: '#d289c0',
        pieces: [
            CustomFieldPieces.CustomField,
            CustomFieldPieces.Get,
            CustomFieldPieces.Store,
        ],
    },
    'Questions/Answers': {
        color: '#889e46',
        pieces: [
            QuestionAnswerPieces.Question,
            QuestionAnswerPieces.GroupedQuestion,
            QuestionAnswerPieces.Choose,
            QuestionAnswerPieces.GroupedChoose,
            QuestionAnswerPieces.Answer,
            QuestionAnswerPieces.GroupedAnswer,
            QuestionAnswerPieces.Success,
            QuestionAnswerPieces.Error,
        ],
    },
    'Integrations': {
        color: '#14b1ab',
        pieces: [
            IntegrationsPieces.FinsallLoanProcess
        ],
    },
};