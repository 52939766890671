import { DBSavedFiles } from "../helpers/common-types";
import { getDICEflowDB } from "./database";

export async function getDICEflowFilesDB() {
    const myId = localStorage.getItem('myId');
    if (!myId) {
        return [];
    };
    const database = await getDICEflowDB();
    const diceflowFiles: Array<DBSavedFiles> = await database.get('diceflowFiles', myId);

    if (!diceflowFiles) {
        await database.put('diceflowFiles', [], myId);
        return [];
    };

    return diceflowFiles;

}

export async function storeFilesInDB(id: string, file: File, commit = true) {
    const myId = localStorage.getItem('myId');
    if (!myId) {
        return;
    };

    if (!commit) {
        id += '-dirty';
    }

    const database = await getDICEflowDB();
    const diceflowFiles = await getDICEflowFilesDB();

    const updatedFile: DBSavedFiles = { id, file };

    const existingIndex = diceflowFiles.findIndex((item) => item.id === id);

    if (existingIndex !== -1) {
        diceflowFiles[existingIndex] = updatedFile;
    } else diceflowFiles.push(updatedFile);

    await database.put('diceflowFiles', diceflowFiles, myId);
};

export async function getStoredFileInDB(id: string) {
    const diceflowFiles = await getDICEflowFilesDB();
    const dirtyFile = diceflowFiles.find((item) => item.id === id + '-dirty');

    if (dirtyFile) {
        return dirtyFile;
    }

    return diceflowFiles.find((item) => item.id === id);
};

export async function commitTempFiles() {
    const myId = localStorage.getItem('myId');
    if (!myId) {
        return;
    };

    const database = await getDICEflowDB();
    const diceflowFiles = await getDICEflowFilesDB();
    const tempFiles = diceflowFiles.filter(item => item.id.endsWith('-dirty'));
    const committedFiles = diceflowFiles.filter(item => !item.id.endsWith('-dirty'));

    for (const tempFile of tempFiles) {
        const id = tempFile.id.substring(0, tempFile.id.length - 6);
        const updatedFile: DBSavedFiles = { id, file: tempFile.file };

        const existingIndex = committedFiles.findIndex((item) => item.id === id);

        if (existingIndex !== -1) {
            committedFiles[existingIndex] = updatedFile;
        } else committedFiles.push(updatedFile);

    };

    await database.put('diceflowFiles', committedFiles, myId);
}

export async function clearTempFiles() {
    const myId = localStorage.getItem('myId');
    if (!myId) {
        return;
    };

    const database = await getDICEflowDB();
    const diceflowFiles = await getDICEflowFilesDB();
    const committedFiles = diceflowFiles.filter(item => !item.id.endsWith('-dirty'));

    await database.put('diceflowFiles', committedFiles, myId);
}

export async function deleteStoredFileInDB(id: string, commit = true) {
    const myId = localStorage.getItem('myId');
    if (!myId) {
        return;
    };

    if (!commit) {
        id += '-dirty';
    }

    const database = await getDICEflowDB();
    const diceflowFiles = await getDICEflowFilesDB();
    let updatedDiceflowFiles = diceflowFiles.slice();

    if (commit) {
        updatedDiceflowFiles = diceflowFiles.filter(item => item.id !== id);
    } else {
        const updatedFile: DBSavedFiles = { id, file: undefined };

        const existingIndex = updatedDiceflowFiles.findIndex((item) => item.id === id);

        if (existingIndex !== -1) {
            updatedDiceflowFiles[existingIndex] = updatedFile;
        } else {
            updatedDiceflowFiles.push(updatedFile);
        }
    }
    await database.put('diceflowFiles', updatedDiceflowFiles, myId);
}