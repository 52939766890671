import { OrganizationState, UPDATE_ORGANIZATION, WIPE_DATA, UPDATE_SUPER_USER_PASSWORD, UpdateSuperUserPassword } from './types';

export function updateOrgProfile(orgData: OrganizationState) {
    return {
        type: UPDATE_ORGANIZATION,
        payload: orgData,
    }
}

export function updateSuperUserPassword(password: string): UpdateSuperUserPassword {
    return {
        type: UPDATE_SUPER_USER_PASSWORD,
        password,
    }
}

export function wipeData() {
    return {
        type: WIPE_DATA,
    }
}