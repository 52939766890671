import React, { Component } from 'react';
import styles from './MessageWidget.module.scss';
import { setToastMessage } from '../../shared/store/my-data/actions';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { ApplicationState } from '../../shared/store/types';

import { ReactComponent as ShareIcon } from '../../common/assets/share.svg';
import { ReactComponent as MoreIcon } from '../../assets/new-custom-icons/common/more.svg';
import { ReactComponent as CloseIcon } from '../../assets/action-icons/cancel.svg';
import { ReactComponent as MessageIcon } from '../../assets/new-custom-icons/new-revision/message.svg';

import { translatePhrase } from '../../shared/helpers/translation';
import { copyStringToClipboard, isUUID } from '../../shared/helpers/utilities';
import draftToHtml from 'draftjs-to-html';
import ReactHtmlParser from 'react-html-parser';
import Button from '../../widgets/button/CommonButton';

export type OwnProps = {
    widgetId: string;
    optionsMarkup?: JSX.Element,
    setToastLoaderForCopy: () => void,
    textToShow: string,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    return {
        widget: state.widgets.byId[ownProps.widgetId],
        myId: state.myData.id,
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setToastMessage: (message: string) => dispatch(setToastMessage(message)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
    showMoreOptions: boolean,
}

class ConnectedMessageWidget extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            showMoreOptions: false,
        }
    }

    getShareLink = () => {
        let hostname = window.location.host;

        if (hostname.startsWith('www')) {
            hostname = hostname.substring(4);
        }

        const shareUrl = `https://backend.${hostname}/public-widget/?id=${this.props.widgetId}`;

        // this.props.setToastMessage(translatePhrase('The share URL has been copied to clipboard'));
        this.props.setToastLoaderForCopy();
        copyStringToClipboard(shareUrl);
    }

    getMarkupForRichText = (value: string) => {
        let stringifiedHtml: string;
        try {
            stringifiedHtml = draftToHtml(JSON.parse(value));
        } catch (e) {
            stringifiedHtml = '<p>Incorrect HTML</p>';
        }

        return ReactHtmlParser(stringifiedHtml);
    }

    render() {

        const canShowShareLink = this.props.widgetId && !isUUID(this.props.myId);

        const header = <header className={styles.showHeader}>
            <h3 title={translatePhrase(this.props.widget.name)} className={styles.showHeading + ' ' + (this.state.showMoreOptions ? styles.hide : '')}>
                <MessageIcon />
                <div className={styles.heading}>
                    <div title={translatePhrase(this.props.widget.name)}> {translatePhrase(this.props.widget.name)} </div>
                    <span> {translatePhrase('Messages')} </span>
                </div>
            </h3>

            <div className={styles.actionButtonsContainer}>
                {!this.state.showMoreOptions && <Button title={translatePhrase('More')} icon={<MoreIcon />} onClick={() => this.setState({ showMoreOptions: true })}
                    isRounded={true} type={'secondary'} size={'small'} />}
            </div>


            <div className={styles.moreOptionsHolder + ' ' + (this.state.showMoreOptions ? styles.active : '')}>
                <ul className={styles.moreOptions}>
                    {this.props.optionsMarkup}
                    {canShowShareLink && <Button 
                    isRounded size={"small"} type={"secondary"} icon={<ShareIcon />}
                    title={translatePhrase('Share')} onClick={this.getShareLink} />}

                    <Button isRounded size={"small"} icon={<CloseIcon />} type={"primary"}
                        title={translatePhrase('Close')}
                        onClick={() => { this.setState({ showMoreOptions: false }) }} />
                </ul>
            </div>

        </header>;

        return <section className={styles.showDataContainer}>
            {header}
            <div className={styles.dataContainer}>
                {this.props.textToShow && <div className={styles.dataContent}>
                    <section>{this.getMarkupForRichText(this.props.textToShow)}</section>
                </div>}
            </div>

        </section>

    }
}

const MessageWidget = connect(mapStateToProps, mapDispatchToProps)(ConnectedMessageWidget);

export default MessageWidget;
