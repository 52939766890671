import { addEntity, clearDelta, synchronizeEntries, updateEntity, updateEntries } from "../normalized-model";
import { ADD_SYNC_LOG, CLEAR_SYNC_LOG_DELTA, CLEAR_SYNC_PROCESS_MESSAGE, GENERATE_SYNC_LOG, SET_SYNC_PROCESS_MESSAGE, SYNCHRONIZE_SYNC_LOG_DATA, SyncLogActionTypes, SyncLogState, SyncMessage, SyncMetaLog, UPDATE_SYNC_LOG, UPDATE_SYNC_LOG_DATA } from "./types";

const initialState: SyncLogState = {
    byId: {},
    allEntries: [],
    filteredEntries: [],

    currentSyncLogId: "",

    createdIds: new Set<string>(),
    updatedIds: new Set<string>(),
    deletedIds: new Set<string>(),

    syncProcessMessage: undefined,
    readableSyncMessage: undefined
}

export function syncLogReducer(state = initialState, action: SyncLogActionTypes) {

    switch (action.type) {
        case GENERATE_SYNC_LOG:
            return {
                ...state,
                currentSyncLogId: action.id
            };

        case ADD_SYNC_LOG:
            return addEntity<SyncLogState, SyncMetaLog>(state, action.payload);

        case UPDATE_SYNC_LOG:
            return updateEntity<SyncLogState, SyncMetaLog>(state, action.payload, action.payload.createdTime);

        case UPDATE_SYNC_LOG_DATA:
            return updateEntries<SyncLogState, SyncMetaLog>(state, action.data);

        case SET_SYNC_PROCESS_MESSAGE:
            let { readableSyncMessage } = state;

            let messagesArray = readableSyncMessage ? readableSyncMessage.split(" > ") : [];

            if (action.message && messagesArray[messagesArray.length - 1] !== action.message) {
                if (!messagesArray.includes(SyncMessage.SyncFailed)) {
                    messagesArray.push(action.message);
                }
            };

            readableSyncMessage = messagesArray.join(" > ");

            return {
                ...state,
                syncProcessMessage: action.message,
                readableSyncMessage
            };

        case CLEAR_SYNC_PROCESS_MESSAGE:
            return {
                ...state,
                syncProcessMessage: '',
                readableSyncMessage: '',
            }

        case SYNCHRONIZE_SYNC_LOG_DATA:
            return synchronizeEntries<SyncLogState, SyncMetaLog>(state, action.data);

        case CLEAR_SYNC_LOG_DELTA:
            return clearDelta<SyncLogState, SyncMetaLog>(state);

        default:
            return state;
    }
}