import React, { Component } from 'react';
import styles from './GroupModify.module.scss';

import InputText from '../../../widgets/form/InputText';
import chevronIcon from '../../../assets/chevron-arrow-down.svg';
import { ReactComponent as CancelIcon } from '../../../common/assets/close.svg';
import { ReactComponent as PlusIcon } from '../../../assets/new-custom-icons/dashboard/plus.svg';
import { ReactComponent as CheckIcon } from '../../../assets/new-custom-icons/profile/check-mark.svg';

import { translatePhrase } from '../../../shared/helpers/translation';
import { FieldType } from '../../../shared/store/custom-fields/types';

import { ApplicationState } from '../../../shared/store/types';
import { IUpdateableGroupData } from '../../../shared/store/groups/types';

import { connect } from 'react-redux';
import uuid from 'uuid';
import moment from 'moment';
import axios from 'axios';
import { getAllLocationsVisibleToUser, getAllLeafLocationsUnderLocation, getLocationsAtLevel } from '../../../shared/helpers/locations';
import { isUUID } from '../../../shared/helpers/utilities';
import { Permissions } from '../../../shared/store/permissions/types';
import { IWorkflowType } from '../../../shared/store/workflows/types/types';
import { IMemberTypeAction } from '../../../shared/store/members/types/actions/types';
import { Dispatch } from 'redux';
import { IUpdateableWorkflowData } from '../../../shared/store/workflows/types';
import { addWorkflow } from '../../../shared/store/workflows/actions';
import { withRouter, RouteComponentProps } from 'react-router';
import MultiSelectInputText from '../../../widgets/form/MultiSelectInput';
import CustomFieldInput from '../../../widgets/form/CustomField';
import Button from '../../../widgets/button/CommonButton';
import { BASE_URL } from '../../../shared/store/url';
import { getReadableDataForCustomField } from '../../../shared/store/custom-fields';
import DateInput from '../../../widgets/form/DateInput';
import { setErrorMessage, clearErrorMessage } from '../../../shared/store/my-data/actions';

type OwnProps = {
    groupId?: string,
    isReadOnly?: boolean,

    submit: (groupData: IUpdateableGroupData) => void,
    cancel: () => void,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    let allAllowedLocations: Array<string> = Object.keys(state.structure.locations.byId);

    return {
        myPermissions: state.permissions.myPermissions,
        usersData: state.users,
        group: ownProps.groupId ? state.groups.byId[ownProps.groupId] : undefined,
        groupsData: state.groups,

        applicationState: state,
        myId: state.myData.id,

        projectsData: state.structure.projects,
        locationsData: state.structure.locations,
        memberData: state.members,
        groupTypesData: state.groups.types,
        customFieldsData: state.groups.types.customFields,
        customFieldOptionsData: state.groups.types.customFieldOptions,

        workflowsData: state.workflows,
        workflowTypesData: state.workflows.types,

        allowedData: {
            locations: allAllowedLocations,
        },
        orgCode: state.organization.code,
    }
};

type StateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        addWorkflow: (workflowData: IUpdateableWorkflowData) => dispatch(addWorkflow(workflowData)),
        setErrorMessage: (message: string) => dispatch(setErrorMessage(message, true)),
        clearErrorMessage: () => dispatch(clearErrorMessage()),
    };
};

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps;

type OwnState = {
    groupData: IUpdateableGroupData,
    submitTimer: number | undefined,
    locationKey: number,
};

class ConnectedGroupModify extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        let groupData: IUpdateableGroupData;
        if (!props.group) {
            // This is a new group
            groupData = {
                id: uuid.v4(),
                type: '',
                location: '',
                members: [],
                representatives: [],
                customFields: {}
            };
        } else {
            groupData = {
                id: props.group.id,
                type: props.group.type,
                location: props.group.location,
                members: props.group.members,
                representatives: props.group.representatives,
                customFields: JSON.parse(JSON.stringify(props.group.customFields)),
            }
        }

        this.props.clearErrorMessage();

        this.state = {
            groupData: groupData,
            submitTimer: undefined,
            locationKey: 0,
        };
    }

    changeType = (type: string) => {
        let updatedIUpdateableGroupData: IUpdateableGroupData = {
            ...this.state.groupData,
            type: type,
            location: '',
        };

        this.setState({
            groupData: updatedIUpdateableGroupData
        });
    }

    changeLocation = (user: string) => {
        let updatedIUpdateableGroupData: IUpdateableGroupData = {
            ...this.state.groupData,
            location: user
        };

        this.setState({
            groupData: updatedIUpdateableGroupData
        });
    }

    changeMembers = (members: Array<string>) => {
        let updatedIUpdateableGroupData: IUpdateableGroupData = {
            ...this.state.groupData,
            members: members
        };

        this.setState({
            groupData: updatedIUpdateableGroupData
        });
    }

    changeRepresentatives = (representatives: Array<string>) => {
        let updatedIUpdateableGroupData: IUpdateableGroupData = {
            ...this.state.groupData,
            representatives: representatives
        };

        this.setState({
            groupData: updatedIUpdateableGroupData
        });
    }

    showErrorMessage = (message: string) => {
        let that = this

        this.props.setErrorMessage(message);

        window.setTimeout(() => {
            that.props.clearErrorMessage();
        }, 3000);

    }

    getLocationsAtLevel = () => {

        if (!this.state.groupData.type) {
            return [];
        }

        const groupType = this.props.groupTypesData.byId[this.state.groupData.type];
        const locations = getLocationsAtLevel(groupType.level);

        const allowedLocations = locations.filter(location => this.props.allowedData.locations.includes(location.id));

        const locationsData = allowedLocations.map(location => {
            let parentName = ''

            if (location.parent) {
                parentName = location.parent in this.props.locationsData.byId ? translatePhrase(this.props.locationsData.byId[location.parent].name) : translatePhrase(this.props.projectsData.byId[location.parent].name);
            }

            return {
                name: `${translatePhrase(location.name)} (${parentName})`,
                value: location.id,
            };
        });

        return locationsData;
    }


    preValidateIUpdateableGroupData = () => {

        if (!this.state.groupData.type) {
            return;
        }

        if (!this.state.groupData.location) {
            return;
        }

        for (let i = 0; i < this.props.groupTypesData.byId[this.state.groupData.type].customFields.length; i += 1) {
            const fieldId = this.props.groupTypesData.byId[this.state.groupData.type].customFields[i];
            const field = this.props.customFieldsData.byId[fieldId];

            if (field.isComputed) {
                continue;
            }

            if (field.type === FieldType.MULTI_SELECT) {
                if (this.state.groupData.customFields[fieldId] && !Array.isArray(this.state.groupData.customFields[fieldId])) {
                    throw new Error('The value of a multi-select field must be an array');
                }
            } else {
                if (this.state.groupData.customFields[fieldId] && Array.isArray(this.state.groupData.customFields[fieldId])) {
                    throw new Error('The value of a non multi-select field must not be an array');
                }
            }

            if (field.type === FieldType.PHONE) {
                const phoneNumber = this.state.groupData.customFields[fieldId];

                if (!!phoneNumber) {
                    if (typeof phoneNumber !== 'string') {
                        throw new Error('A phone number must be a string');
                    }
                }
            }

            if (field.type === FieldType.LOCATION) {
                const location = this.state.groupData.customFields[fieldId];

                if (!!location) {
                    if (typeof location !== 'string') {
                        throw new Error('A location must be a string');
                    }
                }
            }
        };

        return true
    }

    validateIUpdateableGroupData = () => {

        if (!this.state.groupData.type) {
            this.showErrorMessage(translatePhrase('Enter valid:') + ' ' + translatePhrase('Type'));
            return;
        }

        if (!this.state.groupData.location) {
            this.showErrorMessage(translatePhrase('Enter valid:') + ' ' + translatePhrase('Location'));
            return;
        }

        if (this.state.groupData.location !== this.props.group?.location) {
            const membersInDifferentLocation = this.state.groupData.members.filter(memberId => {
                const member = this.props.memberData.byId[memberId];
                return !member || member.location !== this.state.groupData.location;
            });

            let hasMembersInDifferentLocation = membersInDifferentLocation.length > 0;

            if (hasMembersInDifferentLocation) {
                this.showErrorMessage('Cannot change location with member/group links');
                return;
            }
        }

        const groupType = this.props.groupTypesData.byId[this.state.groupData.type];

        const nameField = this.props.customFieldsData.byId[groupType.nameFieldId];

        if (!this.state.groupData.customFields[nameField.id]) {
            this.showErrorMessage(translatePhrase('Enter valid:') + ' ' + translatePhrase('Name'));
            return;
        }

        if (groupType.isRequired && this.props.group) {
            // Check that a member is not left without a group if the members has changed
            const removedMemberIds = this.props.group.members.filter(memberId => !this.state.groupData.members.includes(memberId));

            const isMemberMissingRequiredGroup = removedMemberIds.some(memberId => {
                const member = this.props.memberData.byId[memberId];

                if (!member.groups[groupType.id]) {
                    return false;
                }

                const remainingGroupIds = member.groups[groupType.id].filter(groupId => this.state.groupData.id !== groupId);

                return remainingGroupIds.length === 0;
            });

            if (isMemberMissingRequiredGroup) {
                this.showErrorMessage('Cannot remove the last required group from a member');
                return;
            }
        }

        for (let i = 0; i < groupType.customFields.length; i += 1) {
            const fieldId = groupType.customFields[i];
            const field = this.props.customFieldsData.byId[fieldId];

            if (field.isComputed) {
                continue;
            }

            if (field.type === FieldType.MULTI_SELECT) {
                if (this.state.groupData.customFields[fieldId] && !Array.isArray(this.state.groupData.customFields[fieldId])) {
                    throw new Error('The value of a multi-select field must be an array');
                }
            } else {
                if (this.state.groupData.customFields[fieldId] && Array.isArray(this.state.groupData.customFields[fieldId])) {
                    throw new Error('The value of a non multi-select field must not be an array');
                }
            }

            if (field.type === FieldType.PHONE) {
                const phoneNumber = this.state.groupData.customFields[fieldId];

                if (!!phoneNumber) {
                    if (typeof phoneNumber !== 'string') {
                        throw new Error('A phone number must be a string');
                    }

                    if (phoneNumber.trim().split(' ').length !== 2) {
                        this.showErrorMessage(translatePhrase('Enter valid:') + ' ' + translatePhrase(field.name));
                        return;
                    }
                }
            }

            if (field.type === FieldType.LOCATION) {
                const location = this.state.groupData.customFields[fieldId];

                if (!!location) {
                    if (typeof location !== 'string') {
                        throw new Error('A phone number must be a string');
                    }

                    if (location.trim().split(' ').length !== 2) {
                        this.showErrorMessage(translatePhrase('Enter valid:') + ' ' + translatePhrase(field.name));
                        return;
                    }
                }
            }
        };

        return true
    }

    submitGroupForm = async () => {
        if (this.validateIUpdateableGroupData()) {
            this.markForSubmit();

        }
    }

    markForSubmit = () => {
        let that = this;

        const timeout = window.setTimeout(function () {
            that.props.submit(that.state.groupData);
        }, 1000);

        this.setState({
            submitTimer: timeout
        });
    }

    changeCustomField = (fieldId: string, value: string | string[] | undefined | boolean) => {
        const field = this.props.customFieldsData.byId[fieldId];

        let updatedIUpdateableGroupData: IUpdateableGroupData = {
            ...this.state.groupData,
            customFields: {
                ...this.state.groupData.customFields,
                [fieldId]: field.type === FieldType.NUMBER ? Number(value) : value,
            }
        };

        this.setState({
            groupData: updatedIUpdateableGroupData
        });
    }

    fireWorkflow = (workflowType: IWorkflowType) => {
        const newWorkflowStatuses = workflowType.statuses.map(statusId => this.props.workflowTypesData.statuses.byId[statusId]).filter(workflowStatus => !workflowStatus.isTerminal);

        if (newWorkflowStatuses.length === 0) {
            throw new Error('A workflow of this type should have at least one non terminal status');
        }

        const dueInDays = newWorkflowStatuses[0].dueInDays || 7;

        const newWorkflowDueDate = moment().add(dueInDays, 'days').format('YYYY-MM-DD');

        const addWorkflowAffiliationValue = workflowType.affiliation === 'group' && this.props.groupId ? this.props.groupId : '';

        // The workflow can be added
        const addedWorkflowId = uuid.v4();

        this.props.addWorkflow({
            id: addedWorkflowId,
            type: workflowType.id,
            status: newWorkflowStatuses[0].id,
            dueDate: newWorkflowDueDate,
            affiliatedEntity: addWorkflowAffiliationValue,
            user: isUUID(this.props.myId) ? this.props.myId : this.props.usersData.allEntries[0],
        });

        this.props.history.push(`/workflow/${addedWorkflowId}/execute`);
    }

    componentWillUnmount(): void {
        this.props.cancel();
    }

    render() {

        const typesList = this.props.groupTypesData.allEntries
            .filter(typeId => {
                const groupType = this.props.groupTypesData.byId[typeId];
                const loggedInUser = this.props.myId ? this.props.usersData.byId[this.props.myId] : undefined;

                if (loggedInUser) {
                    return loggedInUser.projects.includes(groupType.project);
                } else {
                    return true;
                }
            })
            .filter(typeId => {
                return !this.props.myPermissions.groups[typeId] || this.props.myPermissions.groups[typeId] === Permissions.WRITE;
            })
            .map(typeId => {
                return {
                    name: translatePhrase(this.props.groupTypesData.byId[typeId].name),
                    value: typeId,
                };
            });

        let membersList: Array<{ name: string, value: string }> = [];
        const locationsAtLevel = this.getLocationsAtLevel();
        const allowedLocations = isUUID(this.props.myId) ? getAllLocationsVisibleToUser(this.props.myId) : this.props.locationsData.allEntries;
        const locationsList = locationsAtLevel.filter(locationAtLevel => allowedLocations.includes(locationAtLevel.value));
        let areAllMembersIndexed = true;

        if (this.state.groupData.type) {
            const groupType = this.props.groupTypesData.byId[this.state.groupData.type];

            const allowedMemberTypes = this.props.memberData.types.allEntries.filter(typeId => {
                const memberType = this.props.memberData.types.byId[typeId];

                return memberType.project === groupType.project;
            });

            membersList = this.props.memberData.allEntries
                .filter(memberId => {

                    const memberData = this.props.memberData.byId[memberId];

                    if (this.state.groupData.location) {
                        const allowedLeafLocationIds = getAllLeafLocationsUnderLocation(this.state.groupData.location);

                        if (!allowedLeafLocationIds.includes(memberData.location)) {
                            return false;
                        }
                    }

                    return allowedMemberTypes.includes(memberData.type);
                })
                .map(memberId => {
                    const member = this.props.memberData.byId[memberId];
                    const memberType = this.props.memberData.types.byId[member.type];

                    const nameField = this.props.memberData.types.customFields.byId[memberType.nameFieldId];
                    const subTitleField = this.props.memberData.types.customFields.byId[memberType.subTitleFieldId];

                    const memberName = getReadableDataForCustomField(member.customFields[memberType.nameFieldId], nameField, memberId, 'member', this.props.applicationState);
                    const memberSubtitle = getReadableDataForCustomField(member.customFields[memberType.subTitleFieldId], subTitleField, memberId, 'member', this.props.applicationState);

                    return {
                        name: `${memberName} (${memberSubtitle})`,
                        value: memberId,
                    };
                });
        }

        const selectedMembersList = membersList.filter(member => this.state.groupData.members.includes(member.value));

        let customFields: Array<JSX.Element | undefined> = [];

        let nameField: JSX.Element | undefined;
        let subtitleField: JSX.Element | undefined;

        if (this.state.groupData.type) {
            const groupType = this.props.groupTypesData.byId[this.state.groupData.type];
            customFields = groupType.customFields
                .filter(fieldId => {
                    return fieldId !== groupType.nameFieldId && fieldId !== groupType.subTitleFieldId;
                })
                .map(fieldId => {
                    return <CustomFieldInput
                        entityType="group"
                        entity={this.state.groupData}
                        fieldId={fieldId}
                        customFieldsData={this.props.customFieldsData}
                        customFieldOptionsData={this.props.customFieldOptionsData}
                        changeField={this.changeCustomField}
                    />
                });

            nameField = <CustomFieldInput
                entityType="group"
                entity={this.state.groupData}
                fieldId={groupType.nameFieldId}
                customFieldsData={this.props.customFieldsData}
                customFieldOptionsData={this.props.customFieldOptionsData}
                changeField={this.changeCustomField}
            />

            subtitleField = <CustomFieldInput
                entityType="group"
                entity={this.state.groupData}
                fieldId={groupType.subTitleFieldId}
                customFieldsData={this.props.customFieldsData}
                customFieldOptionsData={this.props.customFieldOptionsData}
                changeField={this.changeCustomField}
            />

        }

        const availableMembers = this.state.groupData.members.filter(memberId => {
            const member = this.props.memberData.byId[memberId];

            return member && !member.archived;
        });

        const availableRepresentatives = this.state.groupData.representatives.filter(memberId => {
            const member = this.props.memberData.byId[memberId];

            return member && !member.archived;
        });

        const groupType = this.state.groupData.type ? this.props.groupTypesData.byId[this.state.groupData.type] : undefined;
        let workflowType: IWorkflowType | undefined;
        let isHandledByWorkflow = false;
        let hasPermissionsToCreateWorkflow = true;
        let isBlockedByOtherWorkflowInstance = false;

        if (groupType) {
            let action: IMemberTypeAction;
            if (this.props.groupId) {
                // This is an edit group form
                action = this.props.groupTypesData.actions.byId[groupType.actions[1]];
            } else {
                // This is an add group form
                action = this.props.groupTypesData.actions.byId[groupType.actions[0]];
            }

            isHandledByWorkflow = !!action.workflowType;

            if (action.workflowType) {
                workflowType = this.props.workflowTypesData.byId[action.workflowType];

                if (!workflowType.areMultipleInstancesAllowed && Array.isArray(workflowType.workflows)) {
                    for (const workflowOfTypeId of workflowType.workflows) {
                        if (workflowOfTypeId in this.props.workflowsData.byId) {
                            const workflowOfType = this.props.workflowsData.byId[workflowOfTypeId];
                            let isAffiliatedWithAnotherEntity = false;

                            if (workflowType.affiliation === 'none') {
                                isAffiliatedWithAnotherEntity = workflowOfType.user === this.props.myId;
                            } else if (workflowType.affiliation === 'group' && this.props.groupId) {
                                isAffiliatedWithAnotherEntity = workflowOfType.affiliatedEntity === this.props.groupId;
                            }

                            if (workflowOfType && !workflowOfType.archived && isAffiliatedWithAnotherEntity && !this.props.workflowTypesData.statuses.byId[workflowOfType.status].isTerminal) {
                                isBlockedByOtherWorkflowInstance = true;
                                break;
                            }
                        }
                    }
                }

                if (this.props.myPermissions.groups[groupType.id] && this.props.myPermissions.groups[groupType.id] !== Permissions.WRITE) {
                    hasPermissionsToCreateWorkflow = false;
                } else if (this.props.myPermissions.workflows[action.workflowType]) {
                    hasPermissionsToCreateWorkflow = this.props.myPermissions.workflows[action.workflowType] === Permissions.WRITE;
                }
            }
        }

        let infoMessage = '';

        if (!areAllMembersIndexed) {
            infoMessage = 'Members are still being indexed. The list may be incomplete';
        }

        return (
            <section className={styles.commonModalHolder}>
                <section data-selector="group-upsert-form" data-group-id={this.props.groupId ? this.props.groupId : ''} className={this.props.isReadOnly ? styles.viewOnlyGroup : styles.addOrModifyListCard}>

                    <header>
                        <h2>{this.props.group ? this.props.isReadOnly ? translatePhrase('View Group') : translatePhrase('Edit Group') : translatePhrase('Add Group')}</h2>
                        <Button padding={'0px 10px'} onClick={this.props.cancel} icon={<CancelIcon />} text={this.props.isReadOnly ? translatePhrase('Close') : translatePhrase('Cancel')} isRounded size={'small'} />
                    </header>

                    <div className={styles.container}>
                        <div className={styles.allInputsHolder}>
                            <div className={styles.inputSegment}>
                                <InputText isDisabled={!!this.props.groupId && isHandledByWorkflow} placeholder={translatePhrase('Type')} icon={chevronIcon} default={this.props.group ? translatePhrase(this.props.groupTypesData.byId[this.props.group.type].name) : ''} options={typesList} onChange={this.changeType} isAutoFocus={true} />
                            </div>

                            {(this.props.isReadOnly || !isHandledByWorkflow) && this.state.groupData.type && <div className={styles.inputSegment} key={this.state.groupData.type}>
                                <InputText isDisabled={isUUID(this.props.myId) && !!this.props.groupId} placeholder={translatePhrase('Location')} icon={chevronIcon} default={this.props.group ? translatePhrase(this.props.locationsData.byId[this.props.group.location].name) : ''} options={locationsList} onChange={this.changeLocation} isAutoFocus={true} />
                            </div>}

                            {!isHandledByWorkflow && nameField}
                            {!isHandledByWorkflow && subtitleField}

                            {this.props.group && <div className={styles.inputSegment}>
                                <DateInput isDisabled placeholder={translatePhrase('Created time')} default={new Date(this.props.group.createdTime)} onChange={() => { }} />
                            </div>}
                            {this.props.group && <div className={styles.inputSegment}>
                                <DateInput isDisabled placeholder={translatePhrase('Last updated time')} default={new Date(this.props.group.lastUpdatedTime)} onChange={() => { }} />
                            </div>}

                            {(this.props.isReadOnly || !isHandledByWorkflow) && this.state.groupData.type && <div className={styles.inputSegment}>
                                <MultiSelectInputText
                                    options={membersList}
                                    default={availableMembers}
                                    onChange={this.changeMembers}
                                    placeholder={'Members'}
                                    isAutoFocus={true}
                                />
                            </div>}

                            {(this.props.isReadOnly || !isHandledByWorkflow) && this.state.groupData.type && <div className={styles.inputSegment}>
                                <MultiSelectInputText
                                    options={selectedMembersList}
                                    default={availableRepresentatives}
                                    onChange={this.changeRepresentatives}
                                    placeholder={'Representatives'}
                                    isAutoFocus={true}
                                />
                            </div>}

                            {!isHandledByWorkflow && customFields}

                        </div>

                        {isHandledByWorkflow ?
                            hasPermissionsToCreateWorkflow ?
                                !isBlockedByOtherWorkflowInstance ?
                                    <section className={styles.message} >{`${translatePhrase('This is handled by the workflow type:')} ${workflowType ? translatePhrase(workflowType.name) : ''}. ${translatePhrase('Please start the workflow by clicking the button below')}`}</section>
                                    :
                                    <section className={styles.message} >{`${translatePhrase('This is handled by the workflow type:')} ${workflowType ? translatePhrase(workflowType.name) : ''}. ${translatePhrase('There is already another worklflow of the same type in progress')}`}</section>
                                :
                                <section className={styles.message} >{`${translatePhrase('This is handled by the workflow type:')}. ${translatePhrase('You do not have permissions to start a workflow of this type')}`}</section>
                            :
                            undefined
                        }

                    </div>

                    {isHandledByWorkflow && hasPermissionsToCreateWorkflow && !isBlockedByOtherWorkflowInstance &&
                        <div className={styles.buttonsHolder}>
                            <Button text={translatePhrase('Fire Workflow')} onClick={() => workflowType && this.fireWorkflow(workflowType)} color={'contrast'} padding={'0px 10px'} isRounded />

                        </div>}

                    {!isHandledByWorkflow && !this.props.isReadOnly && <div className={styles.buttonsHolder}>
                        {this.state.submitTimer ?
                            <Button color={'contrast'} isRounded padding={'0px 10px'} icon={<CheckIcon />} text={this.props.group ? translatePhrase('Updated Group') : translatePhrase('Added Group')} /> :
                            <Button isDisabled={!this.preValidateIUpdateableGroupData()} color={'contrast'} isRounded padding={'0px 10px'} icon={this.props.group ? <CheckIcon /> : <PlusIcon />} onClick={this.submitGroupForm} text={this.props.group ? translatePhrase('Update Group') : translatePhrase('Add Group')} />}
                    </div>}
                </section>

            </section>
        );
    }
}

const GroupModify = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectedGroupModify));

export default GroupModify;