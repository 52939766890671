import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/types';
import { INewLocationData, IUpdateableLocationData } from '../../../shared/store/structure/location/types';
import { reOrderLocations, addLocation, updateLocation, deleteLocation } from '../../../shared/store/structure/location/actions';

import CardTreeVertical, {
    OwnProps as CardTreeOwnProps,
    StateProps as CardTreeStateProps,
    DispatchProps as CardTreeDispatchProps,
    OwnState as CardTreeOwnState
} from './LocationsVertical';
import { Permissions } from '../../../shared/store/permissions/types';
import { translatePhrase } from '../../../shared/helpers/translation';
import uuid from 'uuid';

interface OwnProps extends CardTreeOwnProps {
    parentId: string,
    isReadOnly?: boolean,
}

interface StateProps extends CardTreeStateProps {
}

interface DispatchProps extends CardTreeDispatchProps {
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): StateProps => {
    const locationIds = ownProps.parentId in state.structure.locations.byProject ? state.structure.locations.byProject[ownProps.parentId] : state.structure.locations.byId[ownProps.parentId].children;
    const availableLocations = locationIds.filter(locationId => locationId in state.structure.locations.byId);

    const allLocations = availableLocations.map(locationId => {
        const location = state.structure.locations.byId[locationId];
        const locationSubtitleIds = location.children;

        const locationSubtitleName = locationSubtitleIds.map(locationId => {
            const location = state.structure.locations.byId[locationId];
            if (location && location.name) {
                return translatePhrase(location.name)
            }
        }).join(', ');

        return {
            id: location.id,
            name: translatePhrase(location.name),
            isDeleteRestricted: location.children.length > 0 || location.users.length > 0 || location.members.length > 0 || location.groups.length > 0,
            details: locationSubtitleName
        }
    });

    const canEditLocations = state.permissions.myPermissions.general.Locations === Permissions.WRITE;
    const canReadLocations = state.permissions.myPermissions.general.Locations === Permissions.READ || canEditLocations;

    return {
        read: canReadLocations,
        write: canEditLocations,
        restrictStructureChanges: false,

        customFieldIds: state.structure.levels.byId[ownProps.levelId].customFields,
        customFieldsData: state.structure.levels.customFields,
        customFieldOptionsData: state.structure.levels.customFieldOptions,

        cardsList: allLocations,
        selectedCard: ownProps.selectedId ? state.structure.locations.byId[ownProps.selectedId] : undefined,

        myToken: state.myData.token,
        orgCode: state.organization.code,
    };
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
    return {
        reOrderCards: (sourceIndex: number, destinationIndex: number) => dispatch(reOrderLocations(sourceIndex, destinationIndex, ownProps.parentId || '')),
        addCard: (payload: INewLocationData) => dispatch(addLocation(payload, ownProps.parentId || '')),
        deleteCard: (id: string) => dispatch(deleteLocation(id, ownProps.parentId || '')),
        updateCard: (payload: IUpdateableLocationData) => dispatch(updateLocation(payload)),
    };
}

type Props = OwnProps & StateProps & DispatchProps;

interface OwnState extends CardTreeOwnState {
};

class ConnectedLocationsList extends CardTreeVertical<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            isShowingAddForm: false,
            isShowingModifyForm: false,
            modifyingCardId: props.selectedCard ? props.selectedCard.id : uuid.v4(),
            modifyingCardName: props.selectedCard ? props.selectedCard.name : '',
            customFields: props.selectedCard ? props.selectedCard.customFields : {},
            token: localStorage.getItem('token') || '',
            newFileName: ''
        };
    }

    static defaultProps = {
        isReadOnly: false,
    }

}

const LocationsList = connect(mapStateToProps, mapDispatchToProps)(ConnectedLocationsList);

export default LocationsList;