export const UPDATE_GENERAL_PERMISSION = 'UPDATE_GENERAL_PERMISSION';

export const UPDATE_MEMBER_PERMISSION = 'UPDATE_MEMBER_PERMISSION';
export const UPDATE_GROUP_PERMISSION = 'UPDATE_GROUP_PERMISSION';
export const UPDATE_WORKFLOW_PERMISSION = 'UPDATE_WORKFLOW_PERMISSION';
export const UPDATE_REPORT_PERMISSION = 'UPDATE_REPORT_PERMISSION';
export const UPDATE_STATIC_INFO_PERMISSION = 'UPDATE_STATIC_INFO_PERMISSION';
export const UPDATE_LANGUAGE_PERMISSION = 'UPDATE_LANGUAGE_PERMISSION';

export const SET_MY_PERMISSIONS = 'SET_MY_PERMISSIONS';

export const UPDATE_PERMISSIONS_DATA = 'UPDATE_PERMISSIONS_DATA';
export const SYNCHRONIZE_PERMISSIONS_DATA = 'SYNCHRONIZE_PERMISSIONS_DATA';
export const CLEAR_PERMISSIONS_DELTA = 'CLEAR_PERMISSIONS_DELTA';

export const UPDATE_PERMISSION_DATA = 'UPDATE_PERMISSION_DATA';

export const SET_DEFAULT_MEMBER_TYPE_PERMISSION = 'SET_DEFAULT_MEMBER_TYPE_PERMISSION';
export const SET_DEFAULT_GROUP_TYPE_PERMISSION = 'SET_DEFAULT_GROUP_TYPE_PERMISSION';
export const SET_DEFAULT_WORKFLOW_TYPE_PERMISSION = 'SET_DEFAULT_WORKFLOW_TYPE_PERMISSION';
export const SET_DEFAULT_REPORT_TYPE_PERMISSION = 'SET_DEFAULT_REPORT_TYPE_PERMISSION';
export const SET_DEFAULT_STATIC_DATA_PERMISSION = 'SET_DEFAULT_STATIC_DATA_PERMISSION';
export const SET_DEFAULT_LANGUAGE_PERMISSION = 'SET_DEFAULT_LANGUAGE_PERMISSION';

export enum Permissions {
    NONE = 'NONE',
    READ = 'READ',
    WRITE = 'WRITE',
}

export const DEFAULT_GENERAL_PERMISSIONS = {
    Hierarchy: Permissions.READ,
    Locations: Permissions.READ,
    Users: Permissions.READ,
    SyncMetaLog: Permissions.NONE,
    UserConfiguration: Permissions.NONE,
    MembersConfiguration: Permissions.NONE,
    GroupsConfiguration: Permissions.NONE,
    WorkflowsConfiguration: Permissions.NONE,
    OrganizationProfile: Permissions.READ,
    LanguagesConfiguration: Permissions.NONE,
    ReportsConfiguration: Permissions.NONE,
    StaticInfoConfiguration: Permissions.NONE,
    DashboardConfiguration: Permissions.READ,
}

export const DEFAULT_PERMISSIONS: PermissionSet = {
    general: DEFAULT_GENERAL_PERMISSIONS,
    members: {},
    groups: {},
    workflows: {},
    reports: {},
    staticInfo: {},
    languages: {},
    role: ''
}

export type PermissionSet = {
    general: {
        Hierarchy: Permissions,
        Locations: Permissions,
        Users: Permissions,
        UserConfiguration: Permissions,
        MembersConfiguration: Permissions,
        GroupsConfiguration: Permissions,
        WorkflowsConfiguration: Permissions,
        OrganizationProfile: Permissions,
        LanguagesConfiguration: Permissions,
        ReportsConfiguration: Permissions,
        StaticInfoConfiguration: Permissions,
        DashboardConfiguration: Permissions,
        [id: string]: Permissions,
    },
    members: {
        [memberTypeId: string]: Permissions,
    },
    groups: {
        [groupTypeId: string]: Permissions,
    },
    workflows: {
        [workflowTypeId: string]: Permissions,
    },
    reports: {
        [reportTypeId: string]: Permissions,
    },
    staticInfo: {
        [staticInfoId: string]: Permissions,
    },
    languages: {
        [languageId: string]: Permissions,
    },
    role: string;
}

export type PermissionSetWithRole = PermissionSet & { role: string };

export interface PermissionState {
    myPermissions: PermissionSet,
    rolePermissions: {
        [id: string]: PermissionSet,
    },
    updatedIds: Set<string>,
}

export interface SetMyPermissions {
    type: typeof SET_MY_PERMISSIONS,
    permissions: PermissionSet,
}

export interface UpdateGeneralPermission {
    type: typeof UPDATE_GENERAL_PERMISSION,
    id: string,
    role: string,
    permission: Permissions,
}


export interface UpdateMemberPermission {
    type: typeof UPDATE_MEMBER_PERMISSION,
    memberTypeId: string,
    role: string,
    permission: Permissions,
}

export interface UpdateGroupPermission {
    type: typeof UPDATE_GROUP_PERMISSION,
    groupTypeId: string,
    role: string,
    permission: Permissions,
}

export interface UpdateWorkflowPermission {
    type: typeof UPDATE_WORKFLOW_PERMISSION,
    workflowTypeId: string,
    role: string,
    permission: Permissions,
}

export interface UpdateReportPermission {
    type: typeof UPDATE_REPORT_PERMISSION,
    reportTypeId: string,
    role: string,
    permission: Permissions,
}

export interface UpdateStaticInfoPermission {
    type: typeof UPDATE_STATIC_INFO_PERMISSION,
    staticInfoId: string,
    role: string,
    permission: Permissions,
}

export interface UpdateLanguagePermission {
    type: typeof UPDATE_LANGUAGE_PERMISSION,
    languageId: string,
    role: string,
    permission: Permissions,
}

export interface UpdatePermissionsData {
    type: typeof UPDATE_PERMISSIONS_DATA,
    data: Array<PermissionSetWithRole>,
}

export interface SynchronizePermissionsData {
    type: typeof SYNCHRONIZE_PERMISSIONS_DATA,
    data: Array<PermissionSetWithRole>,
}

export interface ClearPermissionsDelta {
    type: typeof CLEAR_PERMISSIONS_DELTA,
}

export interface IUpdatePermissionData {
    type: typeof UPDATE_PERMISSION_DATA,
    data: PermissionState,
}

export interface ISetDefaultMemberTypePermission {
    type: typeof SET_DEFAULT_MEMBER_TYPE_PERMISSION;
    entityId: string;
}

export interface ISetDefaultGroupTypePermission {
    type: typeof SET_DEFAULT_GROUP_TYPE_PERMISSION;
    entityId: string;
}

export interface ISetDefaultWorkflowTypePermission {
    type: typeof SET_DEFAULT_WORKFLOW_TYPE_PERMISSION;
    entityId: string;
}

export interface ISetDefaultReportTypePermission {
    type: typeof SET_DEFAULT_REPORT_TYPE_PERMISSION;
    entityId: string;
}

export interface ISetDefaultStaticDataPermission {
    type: typeof SET_DEFAULT_STATIC_DATA_PERMISSION;
    entityId: string;
}

export interface ISetDefaultLanguagePermission {
    type: typeof SET_DEFAULT_LANGUAGE_PERMISSION;
    entityId: string;
}

export type RolePermissionActionTypes = UpdateGeneralPermission | UpdateMemberPermission | UpdateGroupPermission | UpdateWorkflowPermission | UpdateReportPermission | UpdateStaticInfoPermission | UpdateLanguagePermission;
export type PermissionActionTypes = SetMyPermissions | UpdateGeneralPermission | UpdateMemberPermission | UpdateGroupPermission | UpdateWorkflowPermission | UpdateReportPermission | UpdateStaticInfoPermission | UpdateLanguagePermission | UpdatePermissionsData | SynchronizePermissionsData | ClearPermissionsDelta | IUpdatePermissionData | ISetDefaultMemberTypePermission | ISetDefaultGroupTypePermission | ISetDefaultWorkflowTypePermission | ISetDefaultReportTypePermission | ISetDefaultStaticDataPermission | ISetDefaultLanguagePermission;