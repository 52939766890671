import { Component } from 'react';
import styles from './step-piece/StepPiece.module.scss';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';
import { Option } from '../drop-down/ListItem';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setWorkflowType, setWorkflowAffiliationVariable, setVariableForAddWorkflow, updateVariablePiece, setWorkflowShowErrors } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import StepPiece from './step-piece/StepPiece';
import Input from '../Input';
import { valuePieceSlotTarget } from './utilities';
import { NestingData } from '../../../shared/store/flowchart/pieces/types';
import { addVariable } from '../../../shared/store/flowchart/variables/actions';
import { IUpdateableVariableData } from '../../../shared/store/flowchart/variables/types';
import VariableModify from './VariableModify';
import { FlowchartContext, FlowchartInfoForPiece } from '../../../contexts/flowchart-context';
import DropDownSearchBox from '../drop-down/DropDownSearchBox';
import { getFilteredOptionsBySearch } from '../drop-down/DropDownSearchBox';


type AddWorkflowPieceProps = {
    selectedEntityType?: string,
    nextPiece?: JSX.Element,
    affiliatedPiece?: JSX.Element,
    variables: Array<Option>,
    addingVariableId?: string,
    isShowingErrors?: boolean,

    registerVariable?: (variableId: string) => void,
}

const ERROR_OPTIONS: Option[] = [
    {
        name: "Show errors",
        value: "yes",
    },
    {
        name: "Don't show errors",
        value: "no",
    },
];

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,

        workflowTypes: state.workflows.types,
        variableState: state.flowchart.variables,
    }
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: AddWorkflowPieceProps & FlowchartPieceProps) => {

    return {
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
        setWorkflowType: (pieceId: string, workflowType: string) => dispatch(setWorkflowType(pieceId, workflowType)),
        setWorkflowAffiliationVariable: (pieceId: string, affiliationVariable: string) => dispatch(setWorkflowAffiliationVariable(pieceId, affiliationVariable)),
        setWorkflowShowErrors: (pieceId: string, shouldShowErrors: boolean) => dispatch(setWorkflowShowErrors(pieceId, shouldShowErrors)),
        setVariableForAddWorkflow: (pieceId: string, value: string) => dispatch(setVariableForAddWorkflow(pieceId, value)),
        
        updateVariablePiece: (variable: string|undefined, nesting: Array<NestingData>|undefined) => dispatch(updateVariablePiece(ownProps.pieceId, variable, nesting)),
        addVariable: (variableData: IUpdateableVariableData) => dispatch(addVariable(variableData)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = AddWorkflowPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type OwnState = {
    isHoveringOverAffiliationVariablePiece: boolean,
    isAddingVariable: boolean,
    variableSearchTerm: string,
    errorSearchTerm: string,
    workflowTypeSearchTerm: string,
}

class ConnectedAddMemberPiece extends Component<Props, OwnState> {

    constructor(props: Props) {
        super(props);
        
        this.state = {
            isHoveringOverAffiliationVariablePiece: false,
            isAddingVariable: false,
            variableSearchTerm: "",
            errorSearchTerm: "",
            workflowTypeSearchTerm: "",
        }
    }

    handleHoverOverAffiliationPiece = () => {
        this.setState({
            isHoveringOverAffiliationVariablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfAffiliationPiece = () => {
        this.setState({
            isHoveringOverAffiliationVariablePiece: false,
        });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && this.state.isHoveringOverAffiliationVariablePiece) {

            this.props.setWorkflowAffiliationVariable(this.props.pieceId, this.props.lastDraggedPiece.id);
            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverAffiliationVariablePiece: false,
            });
        }
    }

    searchForVariable = (searchTerm: string) => {
        this.setState({
            variableSearchTerm: searchTerm,
        });
    }

    submitVariableData = (variableData: IUpdateableVariableData) => {
        this.props.addVariable(variableData);
        this.props.registerVariable && this.props.registerVariable(variableData.id);
        this.setState({
            isAddingVariable: false,
        });
    }

    hideVariableForm = () => {
        this.setState({
            isAddingVariable: false,
        });
    }

    showVariableForm = () => {
        this.setState({
            isAddingVariable: true,
        });
    }

    updateValidity = (isValid: boolean, flowchartContext: FlowchartInfoForPiece) => {
        if (!isValid) {
            const invalidPieceInfo = flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId);

            if (!invalidPieceInfo && flowchartContext.setInvalidPiece) {
                flowchartContext.setInvalidPiece(this.props.pieceId, flowchartContext.parentSplitPieceIds);
            }
        }

        if (isValid && flowchartContext.removeInvalidPiece && flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId)) {
            flowchartContext.removeInvalidPiece(this.props.pieceId);
        }
    }

    searchForWorkflowType = (searchTerm: string) => {
        this.setState({ workflowTypeSearchTerm: searchTerm });
    }

    searchForErrors = (searchTerm: string) => {
        this.setState({ errorSearchTerm: searchTerm });
    } 

    render() {


        return <FlowchartContext.Consumer>
            {
                (flowchartContext) => {
                    const pieceFooter = this.state.isAddingVariable ? <VariableModify submit={this.submitVariableData} cancel={this.hideVariableForm} /> : <section className={styles.addPrompt} onClick={this.showVariableForm}>+ Add Variable</section>;

                    const entityTypeSelectCallback = this.props.setWorkflowType.bind(this, this.props.pieceId);
                    const selectedEntityType = this.props.selectedEntityType && this.props.workflowTypes.byId.hasOwnProperty(this.props.selectedEntityType) ? this.props.workflowTypes.byId[this.props.selectedEntityType] : undefined;

                    let entityTypeSelectBox: JSX.Element|undefined;

                    const addingVariableName = this.props.addingVariableId && this.props.addingVariableId in this.props.variableState.byId ? this.props.variableState.byId[this.props.addingVariableId].name : undefined;
                    
                    const filteredVariables = getFilteredOptionsBySearch(this.props.variables, this.state.variableSearchTerm);
                    const variableSelectBox = <SelectBox theme="indigo" selectionPromptText={addingVariableName}>
                        <DropDownList footer={pieceFooter} theme="indigo" dismissAfterSelection={false}>
                            <DropDownSearchBox
                                handleSearchInputChange={this.searchForVariable}
                                placeholder={"Search by name"}
                                searchTerm={this.state.variableSearchTerm}
                            />
                            {filteredVariables.map((variable, index) => <ListItem name={variable.name} value={variable.value} key={index} theme="indigo" onClick={this.props.setVariableForAddWorkflow.bind(this, this.props.pieceId)} />)}
                        </DropDownList>
                    </SelectBox>;

                    const filteredErrorOptions = getFilteredOptionsBySearch(ERROR_OPTIONS, this.state.errorSearchTerm);
                    const showErrorsSelectBox = <SelectBox theme="indigo" selectionPromptText={this.props.isShowingErrors ? 'Show errors' : 'Don\'t show errors'}>
                        <DropDownList footer={pieceFooter} theme="indigo" dismissAfterSelection={false}>
                            <DropDownSearchBox
                                handleSearchInputChange={this.searchForErrors}
                                placeholder={"Search by name"}
                                searchTerm={this.state.errorSearchTerm}
                            />
                            {filteredErrorOptions.map(option => {
                                return <ListItem name={option.name} value={option.value} theme="indigo" onClick={() => this.props.setWorkflowShowErrors(this.props.pieceId, true)} />
                            })}
                        </DropDownList>
                    </SelectBox>;


                    let allowedWorkflowTypeIds = this.props.workflowTypes.allEntries;

                    if (flowchartContext.projects.length > 0) {
                        allowedWorkflowTypeIds = allowedWorkflowTypeIds.filter(workflowTypeId => {
                            const workflowType = this.props.workflowTypes.byId[workflowTypeId];
                            return flowchartContext.projects.includes(workflowType.project);
                        });
                    }
                    const filteredWorkflowTypeIds = allowedWorkflowTypeIds.filter(workflowTypeId => this.props.workflowTypes.byId[workflowTypeId].name.toLocaleLowerCase().includes(this.state.workflowTypeSearchTerm.toLocaleLowerCase()));
                    entityTypeSelectBox = <SelectBox theme="aqua" selectionPromptText={selectedEntityType ? selectedEntityType.name : undefined}>
                        <DropDownList theme="aqua" dismissAfterSelection={false}>
                            <DropDownSearchBox
                                handleSearchInputChange={this.searchForWorkflowType}
                                placeholder={"Search by name"}
                                searchTerm={this.state.workflowTypeSearchTerm}
                            />
                            {filteredWorkflowTypeIds.map(workflowTypeId => <ListItem theme="aqua" name={this.props.workflowTypes.byId[workflowTypeId].name} key={workflowTypeId} value={workflowTypeId} onClick={entityTypeSelectCallback} />)}
                        </DropDownList>
                    </SelectBox>;

                    let isValid = true;

                    if (this.props.selectedEntityType) {
                        isValid = allowedWorkflowTypeIds.includes(this.props.selectedEntityType);
                    }

                    if (isValid) {
                        isValid = !this.props.addingVariableId || !!this.props.variables.find(option => option.value === this.props.addingVariableId)
                    }

                    this.updateValidity(isValid, flowchartContext);
                
                    return (
                        <StepPiece theme={isValid ? "aqua" : "red"} {...this.props}>
                            <div className={styles.visibleItems}>
                                <div className={styles.text}>add workflow of type</div>
                                {entityTypeSelectBox}
                                {(!selectedEntityType || selectedEntityType.affiliation !== 'none') && <div className={styles.text}>affiliated to </div>}
                                {(!selectedEntityType || selectedEntityType.affiliation !== 'none') && (this.props.affiliatedPiece ? this.props.affiliatedPiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverAffiliationVariablePiece && !!this.props.targetPiece} onMouseOver={this.handleHoverOverAffiliationPiece} onMouseOut={this.handleHoverOutOfAffiliationPiece} />)}
                                <div className={styles.text}>and store in</div>
                                {variableSelectBox}
                                <div className={styles.text}>and</div>
                                {showErrorsSelectBox}
                            </div>
                        </StepPiece>
                    )
                }
            }
        </FlowchartContext.Consumer>

    }
}

const AddMemberPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedAddMemberPiece);

export default AddMemberPiece;