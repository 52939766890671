import React, { Component, ReactNode } from 'react';
import styles from './container-piece/ContainerPiece.module.scss';
import Input from '../Input';

import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setHeading, setColumns } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import GroupedContainerPiece from './container-piece/GroupedContainerPiece';
import { valuePieceSlotTarget, groupedNextPieceTarget } from './utilities';
import { PieceType } from '../../../shared/store/flowchart/pieces/types';


type ForPieceProps = {
    nextPiece?: JSX.Element,
    headingPiece?: JSX.Element,
    headingText?: string,
    children?: ReactNode,

    columns?: string,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string | undefined) => dispatch(setTargetPiece(pieceId)),
        setHeading: (targetPieceId: string, value: string) => dispatch(setHeading(targetPieceId, value)),
        setColumns: (pieceId: string, value: string) => dispatch(setColumns(pieceId, value)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = ForPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type ForPieceState = {
    isHoveringOverHeadingPiece: boolean,
}

class ConnectedForPiece extends Component<Props, ForPieceState>  {

    state = {
        isHoveringOverHeadingPiece: false,
    };

    handleHoverOverNextPiece = () => {

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        switch (this.props.lastDraggedPiece.type) {
            case PieceType.SECTION:
                this.props.setTargetPiece(this.props.pieceId);
                break;

            default:
                break;
        }
    };

    handleHoverOverInnerPiece = () => {

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        groupedNextPieceTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);

        switch (this.props.lastDraggedPiece.type) {
            case PieceType.SECTION:
                this.props.setTargetPiece(this.props.pieceId);
                break;

            default:
                break;
        }
    };

    handleHoverOverHeadingPiece = () => {
        this.setState({
            isHoveringOverHeadingPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }
        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfHeadingPiece = () => {
        this.setState({
            isHoveringOverHeadingPiece: false,
        });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && (this.state.isHoveringOverHeadingPiece)) {
            if (this.state.isHoveringOverHeadingPiece) {
                this.props.setHeading(this.props.pieceId, this.props.lastDraggedPiece.id);
            }

            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverHeadingPiece: false,
            });
        }
    }

    handleHeadingUpdate = (value: string) => {
        this.props.setHeading(this.props.pieceId, value);
    }

    handleColumnsUpdate = (value: string) => {
        this.props.setColumns(this.props.pieceId, value);
    }

    render() {

        const upperArmContent = (<div className={styles.upperArmContent}>
            <div className={styles.text}>section </div>
            {this.props.headingPiece ? this.props.headingPiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverHeadingPiece && !!this.props.targetPiece} onMouseOver={this.handleHoverOverHeadingPiece} onMouseOut={this.handleHoverOutOfHeadingPiece} defaultText={this.props.headingText} onChange={this.handleHeadingUpdate} />}
            <div className={styles.text}>with </div>
            <Input defaultText={this.props.columns} onChange={this.handleColumnsUpdate} placeholderText="No. of columns" minSize={15} />
        </div>);

        return (<GroupedContainerPiece
            {...this.props}
            theme="aqua"
            handleHoverOverNextPiece={this.handleHoverOverNextPiece}
            handleHoverOverInnerPiece={this.handleHoverOverInnerPiece}

            upperArmContent={upperArmContent}
        >{this.props.children}</GroupedContainerPiece>);

    }
}

const ForPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedForPiece);

export default ForPiece;