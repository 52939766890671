import React, { Component, ChangeEvent } from 'react';
import styles from './GroupCSV.module.scss';

import InputText from '../../../widgets/form/InputText';

import { translatePhrase } from '../../../shared/helpers/translation';

import { ApplicationState } from '../../../shared/store/types';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { ReactComponent as TemplateIcon } from '../../../common/assets/table.svg';
import { ReactComponent as ImportIcon } from '../../../assets/new-custom-icons/common/import.svg';
import { ReactComponent as ExportCSVIcon } from "../../../assets/new-custom-icons/common/export.svg";
import { ReactComponent as CancelIcon } from '../../../common/assets/close.svg';
import { ReactComponent as ExportIcon } from '../../../common/assets/export.svg';
import chevronIcon from '../../../assets/chevron-arrow-down.svg';

import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
import uuid from 'uuid';
import moment from 'moment';
import store from '../../../shared/store/main';

import { ILocation, LocationState } from '../../../shared/store/structure/location/types';
import { GroupFilters, GroupState, IGroup, IUpdateableGroupData } from '../../../shared/store/groups/types';
import { addGroup, appendGroups, bulkAddGroup, bulkUpdateGroupsRequest, clearGroupEntries, filterGroupTable, setGroupIdForCSVForm, clearGroupIdForCSVForm, updateGroupRequest } from '../../../shared/store/groups/actions';
import { getCustomFieldValueForInput, getReadableDataForCustomField, exampleValueForCustomField } from '../../../shared/store/custom-fields';
import { getAncestorChainOfLocation } from '../../../shared/helpers/locations';
import { IGroupType } from '../../../shared/store/groups/types/types';
import { isUUID } from '../../../shared/helpers/utilities';
import { setToastMessage, clearToastMessage, freezeBackground, unFreezeBackground, setErrorMessage } from '../../../shared/store/my-data/actions';
import { filterGroups } from '../../../shared/helpers/filters';
import LoaderModal from '../../../widgets/loader/LoaderModal';
import Button from '../../../widgets/button/CommonButton';
import axios from 'axios';
import { BASE_URL } from '../../../shared/store/url';
import { workerInstance } from '../../../helpers/common-worker';
import { SearchIndex } from '../../../shared/helpers/common-types';
import { ChangeDeltaForMemberComputedFields, IMember } from '../../../shared/store/members/types';
import { appendMembers, bulkRecalculateComputedFieldsForMember, clearMemberEntries } from '../../../shared/store/members/actions';
import { appendWorkflows, clearWorkflowEntries } from '../../../shared/store/workflows/actions';
import { IWorkflow } from '../../../shared/store/workflows/types';
import { IProject } from '../../../shared/store/structure/project/types';
import { OnlineGroupQueryData, PageDataForOnlineEntities } from '../../../shared/helpers/synchronize/types';
import { FieldType } from '../../../shared/store/custom-fields/types';
import { saveAs } from 'file-saver';

type OwnProps = {
    closeCSV: () => void,
};

const ID_COLUMN_VALUE = 'ID - DO NOT EDIT/DELETE THIS HEADER ROW';
const LEVEL_PREFIX = 'Level: ';
const CUSTOM_FIELD_PREFIX = 'Custom field: ';
const COMPUTED_FIELD_PREFIX = 'Custom field (read only): ';

interface ColumnMapping {
    id: number;
    type?: number;
    createdTime?: number;
    lastUpdatedTime?: number;

    levels: {
        [key: string]: number;
    };
    customFields: {
        [key: string]: number;
    };
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    return {
        structureData: state.structure,
        groupData: state.groups,
        languageData: state.internationalization.languages,
        isSuperUser: !isUUID(state.myData.id),
        isOnline: state.myData.isOnline,
        totalNoOfGroups: state.groups.totalNoOfGroups,
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        bulkAddGroups: (payload: Array<IUpdateableGroupData>) => dispatch(bulkAddGroup(payload)),
        bulkUpdateGroupRequest: (payload: Array<IUpdateableGroupData>) => dispatch(bulkUpdateGroupsRequest(payload)),

        addGroup: (payload: IUpdateableGroupData) => dispatch(addGroup(payload)),
        updateGroupRequest: (payload: IUpdateableGroupData) => dispatch(updateGroupRequest(payload)),

        appendMembers: (members: Array<IMember>) => dispatch(appendMembers(members)),
        appendGroups: (members: Array<IGroup>) => dispatch(appendGroups(members)),
        appendWorkflows: (workflows: Array<IWorkflow>) => dispatch(appendWorkflows(workflows)),
        filterGroupTable: (projects: Array<string>, types: Array<string>, locations: Array<string>, customFields: { [customFieldId: string]: Array<string> }, createdDateRange: Array<string>, lastUpdatedDateRange: Array<string>, unsynced: boolean, archived: boolean) => dispatch(filterGroupTable(projects, types, locations, customFields, createdDateRange, lastUpdatedDateRange, unsynced, archived)),

        bulkRecalculateComputedFieldsForMember: (changeDeltas: Array<ChangeDeltaForMemberComputedFields>) => dispatch(bulkRecalculateComputedFieldsForMember(changeDeltas)),

        setToastMessage: (message: string, persistMessage: boolean) => dispatch(setToastMessage(message, persistMessage)),
        clearToastMessage: () => dispatch(clearToastMessage()),

        setErrorMessage: (message: string, persistMessage: boolean) => dispatch(setErrorMessage(message, persistMessage)),

        freezeBackground: () => dispatch(freezeBackground()),
        unFreezeBackground: () => dispatch(unFreezeBackground()),

        clearMemberEntries: () => dispatch(clearMemberEntries()),
        clearGroupEntries: () => dispatch(clearGroupEntries()),
        clearWorkflowEntries: () => dispatch(clearWorkflowEntries()),

        setGroupIdForCSVForm: (groupId: string | undefined) => dispatch(setGroupIdForCSVForm(groupId)),
        clearGroupIdForCSVForm: (groupId: string) => dispatch(clearGroupIdForCSVForm())
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;


type OwnState = {
    groupTypeId: string | undefined,
    successMessage: string,
    importTemplateData: Array<Array<string>>,
    errorMessage: string,
    exportData: Array<Array<string>>,
    erroneousData: Array<Array<string>>,
    showLoader: boolean,
    loaderText: Array<string>
};

class ConnectedGroupModify extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        let groupTypeId: string | undefined;

        if (props.groupData.filters.types.length === 1) {
            groupTypeId = props.groupData.filters.types[0];
        }

        if (!groupTypeId && props.groupData.types.allEntries.length > 1) {
            groupTypeId = props.groupData.types.allEntries[0];
        }

        this.state = {
            groupTypeId,
            successMessage: '',
            importTemplateData: [],
            errorMessage: '',
            exportData: [],
            erroneousData: [],
            showLoader: false,
            loaderText: [],
        }
    }

    changeGroupType = (groupTypeId: string | undefined) => {

        this.setState({
            groupTypeId,
            exportData: [],
        });
        this.props.setGroupIdForCSVForm(groupTypeId);
    }

    hideMessages = () => {
        setTimeout(() => {
            this.dismissSuccessMessage();
        }, 3000);
    }

    importRow = (groupRowData: Array<string>, createdGroups: Array<IUpdateableGroupData>, updatedGroups: Array<IUpdateableGroupData>, columnMapping: ColumnMapping, groupData: GroupState) => {
        let groupID = groupRowData[columnMapping.id].trim();

        if (groupID && !(groupID in groupData.byId)) {
            throw new Error('Unknown ID for group');
        }

        const existingGroup = groupID ? groupData.byId[groupID] : undefined;

        let groupTypeId: string | undefined;

        if (typeof columnMapping.type !== 'undefined') {
            const groupTypeName = groupRowData[columnMapping.type].trim();
            groupTypeId = groupData.types.allEntries.find(groupTypeId => {
                return groupData.types.byId[groupTypeId].name === groupTypeName;
            });
        } else {
            groupTypeId = existingGroup?.type;
        }

        if (!groupTypeId) {
            throw new Error('Unknown group type');
        }

        const groupType = groupData.types.byId[groupTypeId];
        const project = this.props.structureData.projects.byId[groupType.project];

        const firstLocationColumnIndex = 2;  // The first three columns are project, level, and role
        const levelIndex = project.children.findIndex(levelId => levelId === groupType.level);

        if (levelIndex === -1) {
            throw new Error('Could not find level in project');
        }

        let rawImportInput = '';
        let parentLocation: ILocation | undefined;
        let locationStateData = store.getState().structure.locations;

        // Given a list of sibling IDs and a location, return the data of the location which has the given name within that list of siblings
        function getLocationFromName(siblingLocationIds: Array<string>, locationName: string, locationStateData: LocationState) {
            const currentLocationId = siblingLocationIds.find(locationId => locationStateData.byId[locationId].name.trim().toLocaleLowerCase() === locationName.trim().toLocaleLowerCase());

            return typeof currentLocationId !== 'undefined' ? locationStateData.byId[currentLocationId] : undefined;
        }

        let currentLocation: ILocation | undefined = undefined;

        if (Object.keys(columnMapping.levels).length === 0) {
            if (!existingGroup) {
                throw new Error('Location data must be provided for new member')
            } else {
                currentLocation = this.props.structureData.locations.byId[existingGroup.location];
            }
        } else {
            const childLevelIds = project.children.slice(0, levelIndex + 1);

            // Loop through each level
            for (let i = 0; i < childLevelIds.length; i += 1) {
                const levelId = childLevelIds[i];
                const level = this.props.structureData.levels.byId[levelId];

                if (typeof columnMapping.levels[levelId] !== 'undefined') {
                    rawImportInput = groupRowData[columnMapping.levels[levelId]].trim();
                }

                if (!rawImportInput) {
                    throw new Error('Could not get location data for level: ' + level.name);
                }

                // Get current location data
                if (i === 0) {
                    currentLocation = getLocationFromName(locationStateData.byProject[project.id], rawImportInput, locationStateData);
                } else {

                    if (typeof parentLocation === 'undefined') {
                        throw new Error('The parent location must be defined for lower level locations');
                    }

                    currentLocation = getLocationFromName(parentLocation.children, rawImportInput, locationStateData);
                }

                if (typeof currentLocation === 'undefined') {
                    throw new Error('This location does not exist');
                }

                parentLocation = currentLocation;
            }
        }

        if (typeof currentLocation === 'undefined') {
            throw new Error('This group does not have a valid location');
        }

        const newGroupId = uuid.v4();
        const newGroupData: IUpdateableGroupData = {
            id: groupID || newGroupId,
            type: groupType.id,
            location: currentLocation.id,
            representatives: [],
            members: [],
            customFields: {},
        }

        if (existingGroup) {
            newGroupData.members = existingGroup.members;
            newGroupData.representatives = existingGroup.representatives;
            newGroupData.customFields = existingGroup.customFields;
        }

        const updatingCustomFieldIds = groupType.customFields.filter(customFieldId => {
            const customField = groupData.types.customFields.byId[customFieldId];
            return Object.keys(columnMapping.customFields).includes(customFieldId) && !customField.isComputed;
        });

        // Loop through the custom fields, and store the value in the new group Data
        for (const customFieldId of updatingCustomFieldIds) {
            if (customFieldId in columnMapping.customFields) {
                const customField = groupData.types.customFields.byId[customFieldId];
                rawImportInput = groupRowData[columnMapping.customFields[customFieldId]].trim();

                // Custom field values of the new group is stored
                const customFieldValue = getCustomFieldValueForInput(customField, rawImportInput, groupData.types.customFieldOptions);
                newGroupData.customFields[customField.id] = customFieldValue;
            }
        }

        if (groupID in groupData.byId) {
            updatedGroups.push(newGroupData);
        } else {
            createdGroups.push(newGroupData);
        }

    }

    getGroupTypeId = () => {
        if (this.state.groupTypeId) {
            return this.state.groupTypeId;
        }

        if (this.props.groupData.filters.types.length === 1) {
            return this.props.groupData.filters.types[0];
        }

        if (this.props.groupData.types.allEntries.length === 1) {
            return this.props.groupData.types.allEntries[0];
        }

        return undefined;
    }

    getLevelIdFromName = (levelName: string) => {
        const groupTypeId = this.getGroupTypeId();

        if (!groupTypeId) {
            return undefined;
        }

        const groupType = this.props.groupData.types.byId[groupTypeId];

        const project = this.props.structureData.projects.byId[groupType.project];
        const endLevelIndex = project.children.indexOf(groupType.level);

        return project.children.slice(0, endLevelIndex + 1).find(levelId => {
            const level = this.props.structureData.levels.byId[levelId];
            return level.name.toLocaleLowerCase().trim() === levelName.toLocaleLowerCase().trim();
        });
    }

    getCustomFieldIdFromName = (customFieldName: string) => {
        const groupTypeId = this.getGroupTypeId();

        if (!groupTypeId) {
            return undefined;
        }

        const groupType = this.props.groupData.types.byId[groupTypeId];

        return groupType.customFields
            .find(customFieldId => {
                const customField = this.props.groupData.types.customFields.byId[customFieldId];
                return customField.name.toLocaleLowerCase().trim() === customFieldName.toLocaleLowerCase().trim();
            });
    }

    getColumnMappingForImport = (headingRow: Array<string>): ColumnMapping => {
        const columnMapping: ColumnMapping = {
            id: 0,
            levels: {},
            customFields: {},
        };

        for (let i = 0; i < headingRow.length; i += 1) {
            const headingCell = headingRow[i];

            const hardCodedHeadings = [
                ID_COLUMN_VALUE.toLocaleLowerCase(),
                'type',
                'created time',
                'last updated time',
            ];

            if (hardCodedHeadings.includes(headingCell.toLocaleLowerCase())) {
                switch (headingCell.toLocaleLowerCase()) {
                    case ID_COLUMN_VALUE.toLocaleLowerCase():
                        columnMapping.id = i;
                        break;
                    case 'type':
                        columnMapping.type = i;
                        break;
                    case 'created time':
                        columnMapping.createdTime = i;
                        break;
                    case 'last updated time':
                        columnMapping.lastUpdatedTime = i;
                        break;
                    default:
                        ;
                }

                continue;
            }

            const levelId = headingCell.startsWith(LEVEL_PREFIX) ? this.getLevelIdFromName(headingCell.substring(LEVEL_PREFIX.length)) : undefined;
            const customFieldId = headingCell.startsWith(CUSTOM_FIELD_PREFIX) ?
                this.getCustomFieldIdFromName(headingCell.substring(CUSTOM_FIELD_PREFIX.length)) :
                headingCell.startsWith(COMPUTED_FIELD_PREFIX) ?
                    this.getCustomFieldIdFromName(headingCell.substring(COMPUTED_FIELD_PREFIX.length)) :
                    undefined;

            if (levelId) {
                columnMapping.levels[levelId] = i;
                continue;
            } else if (customFieldId) {
                const customField = this.props.groupData.types.customFields.byId[customFieldId];
                if (!customField.isComputed) {
                    columnMapping.customFields[customFieldId] = i;
                }
                continue;
            }

            if (headingCell.toLocaleLowerCase() === 'id' || headingCell.toLocaleLowerCase().startsWith('id ')) {
                columnMapping.id = i;
                continue;
            }

            throw new Error(`The heading ${headingCell} does not map to any known value`);
        }

        return columnMapping;
    }

    fetchGroupsDetailsFromServer = async (groupIds: Array<string>) => {

        if (!window.navigator.onLine) {
            this.setState({
                showLoader: true,
                errorMessage: 'You need to be online to import these groups',
            });
        }

        try {
            const fetchUrl = BASE_URL + `/groups-details/?ids=${groupIds.join(',')}`;

            const groupsDetailsResponse = await axios.get<Array<IGroup>>(fetchUrl, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                }
            });

            if (groupsDetailsResponse.status === 200) {
                this.props.appendGroups(groupsDetailsResponse.data);
            } else {
                this.setState({
                    showLoader: true,
                    errorMessage: 'Error when fetching group details',
                });
            }
        } catch (e) {
            if (e instanceof Error) {
                this.setState({
                    showLoader: true,
                    errorMessage: e.message,
                });
            }
            return;
        }
    }

    importGroups = async (importData: Array<Array<string>>) => {

        const erroneousData: Array<Array<string>> = [];

        const createdGroups: Array<IUpdateableGroupData> = [];
        const updatedGroups: Array<IUpdateableGroupData> = [];

        if (importData.length < 2) {
            return;
        }

        if (!importData[0].some(cellValue => cellValue.toLocaleLowerCase() === 'id' || cellValue.toLocaleLowerCase().startsWith('id '))) {
            this.setState({
                showLoader: true,
                errorMessage: translatePhrase('The ID column is required'),
            });

            return;
        }

        let columnMapping: ColumnMapping | undefined;

        try {
            columnMapping = this.getColumnMappingForImport(importData[0]);
        } catch (e) {
            if (e instanceof Error) {
                this.setState({
                    showLoader: true,
                    errorMessage: e.message,
                });
            }

            return;
        }

        const errorFileHeading = this.getImportFileHeader(columnMapping).concat(['Errors']);
        const noOfColumns = errorFileHeading.length - 1;
        erroneousData.push(errorFileHeading);

        const dataWithoutHeader = importData.slice(1);
        let totalGroupsImported = 0;

        const groupIdsNotStoredLocally: Array<string> = [];

        for (const rowData of dataWithoutHeader) {
            try {
                const groupId: string = rowData[columnMapping.id];

                if (!(groupId in this.props.groupData.byId)) {
                    groupIdsNotStoredLocally.push(groupId);
                }
            } catch (e) {
                console.error('Issue when getting group IDs not stored locally for row: ' + JSON.stringify(rowData), e);
            }
        }

        let groupData = this.props.groupData;

        if (groupIdsNotStoredLocally.length > 0) {
            await this.fetchGroupsDetailsFromServer(groupIdsNotStoredLocally);
            groupData = store.getState().groups;
        }

        for (const rowData of dataWithoutHeader) {
            if (rowData.length > 1 || (rowData.length === 1 && rowData[0].trim() !== '')) {
                try {
                    this.importRow(rowData, createdGroups, updatedGroups, columnMapping, groupData);
                    totalGroupsImported += 1;
                } catch (e) {
                    if (e instanceof Error) {
                        const erroneousRow = Array(noOfColumns).fill('').map((entry, index) => {
                            if (index < rowData.length) {
                                return rowData[index];
                            } else {
                                return '';
                            }
                        });
                        erroneousRow.push(e.message);
                        erroneousData.push(erroneousRow);
                    }
                }
            }
        }

        if (createdGroups.length > 0) {
            this.props.bulkAddGroups(createdGroups);
        }

        if (updatedGroups.length > 0) {
            this.props.bulkUpdateGroupRequest(updatedGroups);
        }

        const groupTypesMap: {
            [memberId: string]: Array<string>
        } = {};

        const upsertedGroupData = createdGroups.concat(updatedGroups);

        for (const upsertedGroup of upsertedGroupData) {
            for (const memberId of upsertedGroup.members) {
                if (!(memberId in groupTypesMap)) {
                    groupTypesMap[memberId] = [];
                }

                groupTypesMap[memberId].push(upsertedGroup.type);
            }
        }

        const memberIds = upsertedGroupData.map(groupData => groupData.members).flat();

        const changeDeltas: Array<ChangeDeltaForMemberComputedFields> = memberIds.map(memberId => {
            return {
                memberId,
                memberChanged: false,
                locationChanged: false,
                groupTypesChanged: groupTypesMap[memberId],
                workflowTypesChanged: [],
            };
        })

        if (createdGroups.length > 0 || updatedGroups.length > 0) {
            this.props.bulkRecalculateComputedFieldsForMember(changeDeltas);
        }

        if (erroneousData.length > 1) {
            this.setState({
                errorMessage: 'There were errors during import',
                successMessage: '',
                erroneousData,
            });

            this.hideMessages();

        } else {
            this.setState({
                errorMessage: '',
                successMessage: `The import completed successfully! ${totalGroupsImported} group(s) imported.`,
                erroneousData,
            });

            this.hideMessages();
        }

    }

    handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        const file = !!e.target.files ? e.target.files[0] : undefined;

        if (!!file) {

            if (file.type !== 'text/csv') {
                this.props.setErrorMessage(translatePhrase(translatePhrase('Wrong file format')), false);
                return;
            }

            this.setState({ showLoader: true });
            this.setState({ loaderText: [translatePhrase('Compressing the file(s)') + '...', translatePhrase('Uploading')] });

            Papa.parse(file, {
                complete: (results) => {
                    const csvData = results.data as Array<Array<string>>;

                    // this.props.setToastMessage('Importing', true);
                    // this.props.freezeBackground();

                    this.setState({ showLoader: false });
                    this.setState({ loaderText: [] });

                    window.setTimeout(() => {
                        this.importGroups(csvData);

                        // this.props.clearToastMessage();
                        // this.props.unFreezeBackground();
                    }, 2000);
                }
            });
        }
    }

    getGroupExportForQualifiedGroupIds = async (exportDataForGroups: Array<Array<string>>, project: IProject, qualifiedGroupIds: Array<string>) => {
        for (const groupId of qualifiedGroupIds) {
            const group = this.props.groupData.byId[groupId];
            const rowData: Array<string> = [];

            rowData.push(group.id);

            const groupType = this.props.groupData.types.byId[group.type];

            rowData.push(groupType.name);

            rowData.push(moment(group.createdTime).format('DD MMM YYYY hh:mm A'));
            rowData.push(moment(group.lastUpdatedTime).format('DD MMM YYYY hh:mm A'));

            const ancestorChain = getAncestorChainOfLocation(group.location).reverse();

            for (const ancestorId of ancestorChain) {
                const location = this.props.structureData.locations.byId[ancestorId];
                rowData.push(location.name);
            }

            const groupLocation = this.props.structureData.locations.byId[group.location].name;
            rowData.push(groupLocation);

            // Fill up remaining location spaces with empty strings
            for (let i = 0; i < project.children.length - ancestorChain.length - 1; i += 1) {
                rowData.push('');
            }

            for (const customFieldId of groupType.customFields) {
                const customField = this.props.groupData.types.customFields.byId[customFieldId];

                const readableValue = getReadableDataForCustomField(group.customFields[customFieldId], customField, group.id, 'group');

                if (customField.type === FieldType.FREE_TEXT) {
                    let dataToPush = readableValue === '-' ? 'Not Available' : 'Available';
                    rowData.push(dataToPush);
                } else if (customField.type === FieldType.FILE) {
                    let fileValue = readableValue;
                    if (fileValue.startsWith('http') && fileValue.includes('diceflow.in')) {
                        const fileUrlComponents = fileValue.split('/');
                        const orgCode = fileUrlComponents[4];
                        const fileName = fileUrlComponents[5];
                        fileValue = `${window.location.origin}/file-download/${encodeURIComponent(orgCode)}${encodeURIComponent('/')}${encodeURIComponent(fileName)}`;
                    } else if (/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/.test(fileValue)) {
                        fileValue = 'File data not synced';
                    }
                    rowData.push(fileValue);
                } else {
                    rowData.push(readableValue);
                }

            }

            exportDataForGroups.push(rowData);
        }
    }

    getOnlineGroupFilters = async () => {
        const serverUrl = BASE_URL + '/online-group-filters/';

        const responseData = await axios.get<OnlineGroupQueryData>(serverUrl, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });

        return responseData.data;
    }

    applyOnlineGroupFilters = async (filters: GroupFilters, searchTerm: string, pageSize: number) => {
        const serverUrl = new URL('/online-group-filters/', BASE_URL);

        const queryData: OnlineGroupQueryData = {
            filters,
            searchTerm,
            pageSize,
        }

        return axios.post(serverUrl.toString(), queryData, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });
    }

    getPageData = async (pageNumber: number, pageSize: number) => {
        const serverUrl = new URL('/online-groups/', BASE_URL);

        serverUrl.searchParams.set('pageSize', String(pageSize));
        serverUrl.searchParams.set('currentPageNumber', String(pageNumber));

        const responseData = await axios.get<PageDataForOnlineEntities>(serverUrl.toString(), {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });

        return responseData.data;
    }

    getGroupDataToExport = async () => {
        const groupTypeId = this.getGroupTypeId();

        if (!groupTypeId) {
            return [];
        }

        const groupType = this.props.groupData.types.byId[groupTypeId];

        const exportDataForGroups: Array<Array<string>> = [];
        const headings = this.getExportFileHeader();
        exportDataForGroups.push(headings);

        const project = this.props.structureData.projects.byId[groupType.project];

        if (this.props.isOnline) {
            const PAGE_SIZE_FOR_EXPORT = 1000;
            const originalGroupQueryData = await this.getOnlineGroupFilters();
            const noOfPages = Math.ceil(this.props.totalNoOfGroups / PAGE_SIZE_FOR_EXPORT);
            const filterForType: GroupFilters = {
                ...originalGroupQueryData.filters,
                types: [groupType.id],
            }

            let applicationState = store.getState();

            await this.applyOnlineGroupFilters(filterForType, originalGroupQueryData.searchTerm, PAGE_SIZE_FOR_EXPORT);

            for (let i = 0; i < noOfPages; i += 1) {
                const pageNumber = i + 1;
                const pageData = await this.getPageData(pageNumber, PAGE_SIZE_FOR_EXPORT);

                this.props.clearMemberEntries();
                this.props.clearGroupEntries();
                this.props.clearWorkflowEntries();

                this.props.appendMembers(pageData.members);
                this.props.appendGroups(pageData.groups);
                this.props.appendWorkflows(pageData.workflows);

                applicationState = store.getState();

                const qualifiedGroupIds = pageData.groups.map(group => group.id);
                await this.getGroupExportForQualifiedGroupIds(exportDataForGroups, project, qualifiedGroupIds);

            }

            this.props.filterGroupTable(
                originalGroupQueryData.filters.projects,
                originalGroupQueryData.filters.types,
                originalGroupQueryData.filters.locations,
                originalGroupQueryData.filters.customFields,
                originalGroupQueryData.filters.createdDateRange,
                originalGroupQueryData.filters.lastUpdatedDateRange,
                originalGroupQueryData.filters.unsynced,
                originalGroupQueryData.filters.archived
            );
        } else {
            const applicationState = store.getState();
            const qualifiedGroupIds = filterGroups(applicationState).filter(group => group.type === groupType.id).map(group => group.id);
            await this.getGroupExportForQualifiedGroupIds(exportDataForGroups, project, qualifiedGroupIds);
        }

        return exportDataForGroups;

    }

    getExportFileHeader = () => {
        let templateMarkup: Array<string> = [];
        const groupTypeId = this.getGroupTypeId();

        if (!groupTypeId) {
            return [];
        }

        const groupType = this.props.groupData.types.byId[groupTypeId];

        const project = this.props.structureData.projects.byId[groupType.project];

        templateMarkup.push(ID_COLUMN_VALUE);
        templateMarkup.push('Type');
        templateMarkup.push('Created time');
        templateMarkup.push('Last updated time');

        for (const levelId of project.children) {
            const level = this.props.structureData.levels.byId[levelId];

            templateMarkup.push(LEVEL_PREFIX + level.name);
        }

        for (const customFieldId of groupType.customFields) {
            const customField = this.props.groupData.types.customFields.byId[customFieldId];

            templateMarkup.push(CUSTOM_FIELD_PREFIX + customField.name);

        }

        return templateMarkup;

    }

    getImportFileHeader = (columnMapping?: ColumnMapping) => {
        let templateMarkup: Array<string> = [];
        const groupTypeId = this.getGroupTypeId();

        if (!groupTypeId) {
            return [];
        }

        const groupType = this.props.groupData.types.byId[groupTypeId];

        const project = this.props.structureData.projects.byId[groupType.project];

        if (!columnMapping) {
            templateMarkup.push(ID_COLUMN_VALUE);
        } else if (typeof columnMapping.id === 'number') {
            templateMarkup[columnMapping.id] = ID_COLUMN_VALUE;
        }

        if (!columnMapping) {
            templateMarkup.push('Type');
        } else if (typeof columnMapping.type === 'number') {
            templateMarkup[columnMapping.type] = 'Type';
        }

        for (const levelId of project.children) {
            const level = this.props.structureData.levels.byId[levelId];

            if (!columnMapping) {
                templateMarkup.push(LEVEL_PREFIX + level.name);
            } else if (typeof columnMapping.levels[levelId] === 'number') {
                templateMarkup[columnMapping.levels[levelId]] = LEVEL_PREFIX + level.name;
            }
        }

        for (const customFieldId of groupType.customFields) {
            const customField = this.props.groupData.types.customFields.byId[customFieldId];

            if (!customField.isComputed) {
                if (!columnMapping) {
                    templateMarkup.push(CUSTOM_FIELD_PREFIX + customField.name);
                } else if (typeof columnMapping.customFields[customFieldId] === 'number') {
                    templateMarkup[columnMapping.customFields[customFieldId]] = CUSTOM_FIELD_PREFIX + customField.name;
                }
            }

        }

        return templateMarkup;

    }

    getImportFileSampleRow = () => {
        let templateMarkup: Array<string> = [];
        const groupTypeId = this.getGroupTypeId();

        if (!groupTypeId) {
            return [];
        }

        const groupType = this.props.groupData.types.byId[groupTypeId];

        const project = this.props.structureData.projects.byId[groupType.project];

        templateMarkup.push('Copy ID from export file for update; Leave BLANK for completely new values');
        templateMarkup.push(groupType.name);

        for (const levelId of project.children) {
            const level = this.props.structureData.levels.byId[levelId];

            templateMarkup.push(`Type ${level.name} as in system`);
        }

        const importableCustomFields = groupType.customFields.filter(customFieldId => {
            const field = this.props.groupData.types.customFields.byId[customFieldId];
            return field.type !== FieldType.FREE_TEXT;
        });

        for (const customFieldId of importableCustomFields) {
            const customField = this.props.groupData.types.customFields.byId[customFieldId];

            if (!customField.isComputed) {
                templateMarkup.push(exampleValueForCustomField(customField, this.props.groupData.types.customFieldOptions));
            }

        }

        return templateMarkup;

    }

    exportData = async () => {
        const groupTypeId = this.getGroupTypeId();

        if (!groupTypeId) {
            this.setState({
                errorMessage: 'Select a group type'
            });

            this.hideMessages();

            return false;
        }

        const groupType = this.props.groupData.types.byId[groupTypeId];

        try {
            this.setState({ showLoader: true });

            this.setState({ loaderText: [translatePhrase('Preparing export') + '...'] });
        } catch (e) {
            this.hideMessages();

            this.setState({ showLoader: false });

            this.setState({ loaderText: [] });

            return;
        }

        window.setTimeout(async () => {
            const exportDataForGroups = await this.getGroupDataToExport();

            const fileData = Papa.unparse(exportDataForGroups);
            const fileBlob = new Blob([fileData], { type: 'application/csv' });

            saveAs(fileBlob, `${groupType.name} Export.csv`);

            this.hideMessages();

            this.setState({
                showLoader: false,
                loaderText: [],
                successMessage: translatePhrase('Export ready'),
            });

            window.setTimeout(() => {
                this.dismissSuccessMessage();
            }, 3000);
        }, 2000);
    }

    collectImportTemplate = () => {
        const groupTypeId = this.getGroupTypeId();

        if (!groupTypeId) {
            this.setState({
                errorMessage: 'Select a group type'
            });

            this.hideMessages();

            return false;
        }

        const headings = this.getImportFileHeader();

        const sampleData = this.getImportFileSampleRow();

        this.setState({
            importTemplateData: [headings, sampleData],
        });
    }

    dismissErrorMessage = () => {
        this.setState({
            showLoader: false,
            successMessage: '',
            errorMessage: '',
            erroneousData: [],
        });
    }

    dismissSuccessMessage = () => {
        this.setState({
            showLoader: false,
            successMessage: '',
        });
    }

    startDataExport = () => {
        this.setState({
            errorMessage: '',
        });

        const groupTypeId = this.getGroupTypeId();

        if (!groupTypeId) {
            this.setState({
                errorMessage: 'Select a group type',
            });

            this.hideMessages();
        }

        return true;
    }

    startDataImport = () => {
        this.setState({
            errorMessage: '',
        });


        const groupTypeId = this.getGroupTypeId();

        if (!groupTypeId) {
            this.setState({
                errorMessage: 'Select a group type',
            });

            this.hideMessages();
        } else {
            const fileElement = document.getElementById('import-file-input');
            if (!!fileElement) {
                fileElement.click();
            }
        }
    }

    render() {
        let allowedTypes = this.props.groupData.types.allEntries;

        if (this.props.groupData.filters.types.length > 0) {
            allowedTypes = this.props.groupData.filters.types.slice();
        }

        let groupTypesList = allowedTypes.map(groupTypeId => {
            return {
                name: translatePhrase(this.props.groupData.types.byId[groupTypeId].name),
                value: groupTypeId,
            };
        });

        const groupType = this.state.groupTypeId ? this.props.groupData.types.byId[this.state.groupTypeId] : undefined;
        let fileName = '';

        if (groupType) {
            const groupTypeName = groupType ? groupType.name : '';
            const project = this.props.structureData.projects.byId[groupType?.project]
            fileName = `${project.name.toLocaleLowerCase().split(' ').join('-')}-${groupTypeName.toLocaleLowerCase().split(' ').join('-')}`;
        }

        let defaultGroupTypeName = groupTypesList.length > 0 ? translatePhrase(groupTypesList[0].name) : '';

        return (
            <section>

                {this.state.showLoader && <LoaderModal loaderText={this.state.loaderText} isIndeterminate={true} />}

                {this.state.successMessage && <LoaderModal loaderText={[this.state.successMessage]} isSuccess={true} />}

                {this.state.errorMessage && <LoaderModal closeModal={this.dismissErrorMessage} loaderText={[this.state.errorMessage]} link={this.state.erroneousData.length > 1 ? <CSVLink data={this.state.erroneousData} filename="Errors.csv" className={styles.errorFileDownload}>Download erroneous data</CSVLink> : <div></div>} isError={true} />}

                <section className={styles.commonModalHolder + ' ' + styles.csvModal}>
                    <div className={styles.filterCloseButton} onClick={this.props.closeCSV}>
                        <Button title={translatePhrase("Close")} icon={<CancelIcon />} size={'small'} isRounded />
                    </div>

                    <section className={styles.addOrModifyListCard}>
                        <header>
                            <h2> <ExportIcon /> {translatePhrase('Import/Export')} {translatePhrase('Groups')} </h2>
                        </header>

                        <div className={styles.container}>
                            <div className={styles.allInputsHolder}>
                                <div className={styles.inputSegment}>
                                    <InputText placeholder="Group Type" icon={chevronIcon} default={defaultGroupTypeName} options={groupTypesList} onChange={this.changeGroupType} />
                                </div>
                            </div>
                        </div>

                        <input type="file" accept=".csv, text/csv" id="import-file-input" className={styles.hiddenFile} onChange={this.handleFileUpload} />

                        <div className={styles.buttonsHolder}>
                            {this.props.groupData.allEntries.length > 0 && this.state.exportData.length === 0 && <Button icon={<ExportCSVIcon />} isRounded isBlock padding={'0px 10px'} text={translatePhrase(`Export Table`)} onClick={this.exportData} />}
                            <div className={styles.actionButtons}>
                                {this.props.isSuperUser && <Button icon={<ImportIcon />} isRounded isBlock padding={'0px 10px'} type={'secondary'} text={translatePhrase('Import')} onClick={this.startDataImport} />}
                                <CSVLink data={this.state.importTemplateData} onClick={this.collectImportTemplate} filename={`${fileName} template.csv`}><Button icon={<TemplateIcon />} isRounded type={'tertiary'} isBlock text={translatePhrase('Download Template')} onClick={this.startDataExport} /></CSVLink>
                            </div>
                        </div>
                    </section>
                </section>

            </section>
        );
    }
}

const GroupModify = connect(mapStateToProps, mapDispatchToProps)(ConnectedGroupModify);

export default GroupModify;