import React, { KeyboardEvent, useState } from 'react';
import Button from '../../../widgets/button/CommonButton';
import styles from './AllPackages.module.scss';
import { ReactComponent as CloseIcon } from '../../../common/assets/close.svg';
import { ReactComponent as SearchIcon } from '../../../common/assets/search.svg';
import { ReactComponent as CheckIcon } from '../../../common/assets/check.svg';

import { Link } from 'react-router-dom';
import { PackageSnapshot } from '../package-snapshot/PackageSnapshot';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../shared/store/types';
import { MarketplacePackage } from '../../../shared/store/package/types';

const FilterParams = {
    types: ['Workflow', 'Data'],
    category: [
        'Health',
        'Agriculture',
        'Education',
        'Financial Inclusion',
        'Technology',
        'WASH',
        'Gender Based Violence',
        'Social Protection'
    ]
}

export const AllPackages: React.FC = () => {
    const [selectedTypes, setSelectedTypes] = useState<Array<string>>([]);
    const [selectedCategories, setSelectedCategories] = useState<Array<string>>([]);
    const [selectedSegment, setSelectedSegment] = useState<'market' | 'my_packages'>('market');
    const [searchText, setSearchText] = useState<string>('');

    const myUserId = useSelector((state: ApplicationState) => state.myData.id);
    const myOrganizationId = useSelector((state: ApplicationState) => (state.organization as any).id);

    const isLoggedIn = myUserId && myOrganizationId && myUserId === 'SuperUser';

    function handleSearch(e: KeyboardEvent<HTMLInputElement>) {
        const searchText = e.currentTarget.value;
        setSearchText(searchText);
    }

    function toggleSelectedType(type: string) {
        if (selectedTypes.includes(type)) {
            setSelectedTypes(selectedTypes.filter(selectedType => selectedType !== type));
        } else {
            setSelectedTypes(selectedTypes.concat(type));
        }
    }

    function toggleSelectedCategory(category: string) {
        if (selectedCategories.includes(category)) {
            setSelectedCategories(selectedCategories.filter(selectedCategory => selectedCategory !== category));
        } else {
            setSelectedCategories(selectedCategories.concat(category));
        }
    }

    const allPackages: Array<MarketplacePackage> = useSelector((state: ApplicationState) => state.packages.allEntries
    .map(packageId => {
        const marketplacePackage = state.packages.byId[packageId];
        return marketplacePackage;
    }))

    const filteredPackages = allPackages.filter(marketplacePackage => {
        if (searchText) {
            const basicTerms = [
                marketplacePackage.name.toLocaleLowerCase(),
                marketplacePackage.description.toLocaleLowerCase(),
                marketplacePackage.type.toLocaleLowerCase(),
            ]

            const categoryTerms = marketplacePackage.categories.map(category => category.toLocaleLowerCase());

            const searchableTerms = basicTerms.concat(categoryTerms);

            const matchesSearch = searchableTerms.some(searchableTerm => searchableTerm.includes(searchText.toLocaleLowerCase()));

            if (!matchesSearch) {
                return false;
            }
        }

        if (selectedTypes.length > 0) {
            if (!selectedTypes.includes(marketplacePackage.type)) {
                return false;
            }
        }

        if (selectedCategories.length > 0) {
            if (!marketplacePackage.categories.some(category => selectedCategories.includes(category))) {
                return false;
            }
        }

        if (selectedSegment === 'my_packages') {
            if (marketplacePackage.organization !== myOrganizationId || marketplacePackage.author !== myUserId) {
                return false;
            }
        }

        return true;
    });

    return <section>

        <header className={styles.pageHeading}>
            <h1> Marketplace </h1>

            <div className={styles.searchInputContainer}>
                <div className={styles.searchInput}>
                    <span className={styles.icon}><SearchIcon /></span>
                    <input type="text" onKeyUp={handleSearch} placeholder={'Search by types & categories...'} />
                    <button> <CloseIcon onClick={() => setSearchText('')} /> </button>
                </div>
                { isLoggedIn && selectedSegment === 'my_packages' && <Link to="/marketplace/add-package"> 
                    <Button text={'Add My Package'} isRounded padding={'0px 15px'} /> 
                </Link> }
            </div>
        </header>

        <div className={styles.container}>
            <section className={styles.filter}>
                <ul>
                    <header>
                        <h5> View </h5>
                    </header>
                    <div className={styles.segmentHolder}>
                        <button onClick={() => setSelectedSegment('market')} className={selectedSegment === 'market' ? styles.active : ''}> Marketplace </button>
                        {isLoggedIn && <button onClick={() => setSelectedSegment('my_packages')} className={selectedSegment === 'my_packages' ? styles.active : ''}> My Packages </button>}
                    </div>
                </ul>
                <ul>
                    <header>
                        <h5> Types </h5>
                        <span className={styles.count}> {selectedTypes.length} </span>
                    </header>
                    { FilterParams.types.map(type => {
                        return <li key={type} onClick={() => toggleSelectedType(type)}>
                            <label> {type}s </label>
                            <div className={styles.checkbox + ' ' + (selectedTypes.includes(type) ? styles.active : '')}> <CheckIcon /> </div>
                        </li>
                    })}                
                </ul>
                <ul>
                    <header>
                        <h5> Category </h5>
                        <span className={styles.count}> {selectedCategories.length} </span>
                    </header>
                    { FilterParams.category.map(category => {
                        return <li key={category} onClick={() => toggleSelectedCategory(category)}>
                            <label> {category} </label>
                            <div className={styles.checkbox + ' ' + (selectedCategories.includes(category) ? styles.active : '')}> <CheckIcon /> </div>
                        </li>
                    })}
                </ul>
                <ul>
                    <header>
                        <h5> Filter Tags </h5>
                        <button className={styles.clearButton} onClick={() => {
                            setSelectedTypes([]);
                            setSelectedCategories([]);
                        }}> Clear All </button>
                    </header>

                    <div className={styles.tagsHolder}>
                        { selectedTypes.map((type, index) => {
                            return <div key={index} className={styles.tag}>
                                { type }
                                <button onClick={() => toggleSelectedType(type)}> <CloseIcon /> </button>
                            </div>
                        }) }
                        { selectedCategories.map((category, index) => {
                            return <div key={index} className={styles.tag}>
                                { category }
                                <button onClick={() => toggleSelectedCategory(category)}> <CloseIcon /> </button>
                            </div>
                        }) }
                    </div>
                </ul>
            </section>

            <div className={styles.resultHolder}>
                {searchText && <h3 className={styles.searchResultText}> You got {filteredPackages.length} results for <span> "{searchText}" </span> </h3>}

                <ul className={styles.productList}>
                    { filteredPackages.map(result => <PackageSnapshot key={result.id} package={result} />) }
                </ul> 

                {/* <Button text={'Load More'} padding={'0px 15px'} isBlock isRounded /> */}
            </div>            
        </div>
    </section>
}