import React, { FC, useState, KeyboardEvent, createRef, RefObject, ChangeEvent, useEffect } from 'react';
import styles from './CustomField.module.scss';

import InputText from './InputText';
import MultiSelectInputText from './MultiSelectInput';
import chevronIcon from '../../assets/chevron-arrow-down.svg';
import { ReactComponent as MapIcon } from '../../common/assets/mapmarker.svg';

import { translatePhrase } from '../../shared/helpers/translation';
import { CustomFieldDataType, CustomFieldOptionsDataType, FieldType } from '../../shared/store/custom-fields/types';
import { CustomFieldHolder } from '../../shared/store/normalized-model';
import Button from '../button/CommonButton';
import DateInput from './DateInput';
import { isFileFromCamera, updateLinkToCurrentOrigin } from '../../shared/helpers/file-utilities';
import { isUUID } from '../../shared/helpers/utilities';

import { ReactComponent as Attach } from '../../common/assets/attach.svg';
import { ReactComponent as Cancel } from '../../assets/cancel.svg';
import { ApplicationState } from '../../shared/store/types';
import { Dispatch } from 'redux';
import { RouteComponentProps } from 'react-router';
import { saveFilesToIDB, deleteFilesInIDB } from '../../shared/store/actions';
import { setErrorMessage, clearErrorMessage } from '../../shared/store/my-data/actions';
import { connect } from 'react-redux';
import { getStoredFileInDB } from '../../shared/store/file-operations';
import { saveAs } from 'file-saver';

interface OwnProps {
    entityType: 'level' | 'role' | 'user' | 'member' | 'group' | 'workflow';
    entity?: CustomFieldHolder & { id: string };
    fieldId: string;
    isRequired?: boolean;
    customFieldsData: CustomFieldDataType;
    customFieldOptionsData: CustomFieldOptionsDataType;
    changeField: (fieldId: string, value: string | string[] | undefined | boolean) => void
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    return {
        fileSizeLimitInMB: state.organization.fileSizeLimitInMB,
    }
};

type StateProps = ReturnType<typeof mapStateToProps>;

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setErrorMessage: (message: string) => dispatch(setErrorMessage(message, true)),
        clearErrorMessage: () => dispatch(clearErrorMessage()),
        saveFilesToIDB: (id: string, file: File) => dispatch(saveFilesToIDB(id, file, false)),
        deleteFilesInIDB: (id: string) => dispatch(deleteFilesInIDB(id, false)),
    };
};

type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

const ConnectedCustomFieldInput: FC<Props> = (props) => {
    const inputRef: RefObject<HTMLInputElement> = createRef();
    const [token, setToken] = useState('');

    useEffect(() => {
        setToken(localStorage.getItem('token') || '');
    }, []);

    const field = props.customFieldsData.byId[props.fieldId];
    const choices = field.choices.map(choiceId => {
        return {
            name: translatePhrase(props.customFieldOptionsData.byId[choiceId].name),
            value: choiceId,
        }
    });

    let customFieldValue = props.entity ? props.entity.customFields[props.fieldId] : undefined;

    const [locationKey, setLocationKey] = useState(0);

    const getLocationForField = () => {
        const geolocation = navigator.geolocation;

        geolocation.getCurrentPosition((position) => {
            props.changeField(props.fieldId, `${position.coords.latitude} ${position.coords.longitude}`);
            setLocationKey(locationKey => locationKey + 2);
        });
    }


    const [selectedFileName, setSelectedFileName] = useState('');

    useEffect(() => {
        if (field.type === FieldType.FILE) {
            const customFieldValue = props.entity?.customFields[props.fieldId];

            if (typeof customFieldValue === 'string') {

                if (customFieldValue.startsWith('http')) {
                    const fileName = decodeURI(customFieldValue).split('/')[5];
                    setSelectedFileName(fileName)
                } else {
                    const fileId = props.entity?.id + props.fieldId;
                    getStoredFileInDB(fileId).then(value => {
                        if (value?.file?.name) {
                            setSelectedFileName(value.file.name);
                        }
                    });
                }
            }
        }
    }, [props.entity, props.fieldId]);


    const handleFileInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
        if (event.target.files && event.target.files[0]) {
            let file: File = event.target.files[0];

            if (isFileFromCamera(file)) {
                file = new File([file], `camera_${file.name}`);
            }

            const fileId = props.entity?.id + props.fieldId;

            const fileSizeLimit = props.fileSizeLimitInMB ? props.fileSizeLimitInMB : 1;

            const FILE_SIZE_IN_MB = fileSizeLimit * 1024 * 1024;

            if (file.size > FILE_SIZE_IN_MB) {
                console.error('Max file size limit reached');
                const errorMessage = translatePhrase('Max File Size') + ': ' + fileSizeLimit + ' MB';
                props.setErrorMessage(errorMessage);
                window.setTimeout(() => {
                    props.clearErrorMessage();
                }, 4000);
                return false;
            };

            props.saveFilesToIDB(fileId, file);
            setSelectedFileName(file.name);
            props.changeField(props.fieldId, fileId);
        } else {
            const fileId = props.entity?.id + props.fieldId;
            props.deleteFilesInIDB(fileId);
            props.changeField(props.fieldId, undefined);
        }
    };

    const clearSelectedFile = () => {
        if (inputRef && inputRef.current) {
            const fileId = props.entity?.id + props.fieldId;
            inputRef.current.value = '';
            setSelectedFileName(inputRef.current.value);
            props.deleteFilesInIDB(fileId);
            props.changeField(props.fieldId, undefined);
        }
    }

    const downloadLocalFile = () => {
        if (field.type === FieldType.FILE) {
            const customFieldValue = props.entity?.customFields[props.fieldId];

            if (typeof customFieldValue === 'string') {

                if (!customFieldValue.startsWith('http')) {
                    const fileId = props.entity?.id + props.fieldId;
                    getStoredFileInDB(fileId).then(value => {
                        if (value?.file) {
                            saveAs(value.file, value.file.name);
                        }
                    });
                }
            }
        }
    }

    switch (field.type) {
        case FieldType.SINGLE_SELECT:
            if (Array.isArray(customFieldValue)) {
                throw new Error('A single select field should not have an array value.');
            }

            if (typeof customFieldValue === 'boolean') {
                throw new Error('A single select field should not have a boolean value.');
            }

            if (typeof customFieldValue === 'string' && !isUUID(customFieldValue)) {
                customFieldValue = field.choices.find(choiceId => {
                    const choice = props.customFieldOptionsData.byId[choiceId];
                    return choice.name === customFieldValue;
                });
            }

            return <div key={field.id} className={styles.inputSegment}>
                <InputText isRequired={props.isRequired} isDisabled={field.isComputed} placeholder={field.name} onChange={value => props.changeField(field.id, value)} default={props.entity && customFieldValue ? translatePhrase(props.customFieldOptionsData.byId[customFieldValue].name) : ''} options={choices} isAutoFocus={true} />
            </div>

        case FieldType.NUMBER:
            if (Array.isArray(customFieldValue)) {
                throw new Error('A text field should not have an array value.');
            }

            if (typeof customFieldValue === 'boolean') {
                throw new Error('A number field should not have a boolean value.');
            }

            return <div key={field.id} className={styles.inputSegment}>
                <InputText isRequired={props.isRequired} isDisabled={field.isComputed} placeholder={field.name} type="number" onChange={value => props.changeField(field.id, value)} default={props.entity && customFieldValue ? String(customFieldValue) : ''} />
            </div>

        case FieldType.TEXT:
            if (Array.isArray(customFieldValue)) {
                throw new Error('A text field should not have an array value.');
            }

            if (typeof customFieldValue === 'boolean') {
                throw new Error('A text field should not have a boolean value.');
            }

            return <div key={field.id} className={styles.inputSegment}>
                <InputText isRequired={props.isRequired} isDisabled={field.isComputed} placeholder={field.name} onChange={value => props.changeField(field.id, value)} default={props.entity && customFieldValue ? String(customFieldValue) : ''} />
            </div>

        case FieldType.FREE_TEXT:
            if (customFieldValue) {
                return <div key={field.id} className={styles.freeTextImage}>
                    <label>  {field.name}: </label>
                    <img src={customFieldValue.toString()} alt="free-text-image" />
                </div>
            } else {
                return <div key={field.id} className={styles.freeTextImage}>
                    <label>  {field.name}: - </label>
                </div>
            }

        case FieldType.BOOLEAN:
            if (typeof customFieldValue !== 'boolean' && typeof customFieldValue !== 'undefined') {
                if (String(customFieldValue).toLocaleLowerCase() === 'yes') {
                    customFieldValue = true;
                } else if (String(customFieldValue).toLocaleLowerCase() === 'no') {
                    customFieldValue = false;
                } else {
                    throw new Error('A boolean field should have a boolean value.');
                }
            }

            return <div key={field.id} className={styles.inputSegment}>
                <InputText isRequired={props.isRequired} isDisabled={field.isComputed} isBooleanField={true} placeholder={field.name} onChange={value => props.changeField(field.id, value === 'Yes')}
                    defaultBooleanValue={props.entity ? customFieldValue ? true : false : false} />
            </div>

        case FieldType.DATE:
            if (Array.isArray(customFieldValue)) {
                throw new Error('A date field should not have an array value.');
            }

            if (typeof customFieldValue === 'boolean') {
                throw new Error('A date field should not have a boolean value.');
            }

            return <div key={field.id} className={styles.inputSegment}>
                <DateInput questionId={field.id} placeholder={translatePhrase(field.name)} default={customFieldValue ? new Date(customFieldValue) : undefined} onChange={e => props.changeField(field.id, e)} />
            </div>

        case FieldType.MULTI_SELECT:
            if (typeof customFieldValue !== 'undefined' && !Array.isArray(customFieldValue)) {
                throw new Error('A multi select field should have an array value');
            }

            const multiSelectChoices = choices.map(choice => {
                return {
                    name: translatePhrase(choice.name),
                    value: choice.value,
                };
            });

            if (typeof customFieldValue !== 'undefined' && customFieldValue.some(value => !isUUID(value))) {
                customFieldValue = field.choices.filter(choiceId => {
                    const choice = props.customFieldOptionsData.byId[choiceId];
                    return choice.name === customFieldValue;
                });
            }

            return <div key={field.id} className={styles.inputSegment}>
                <MultiSelectInputText
                    isDisabled={field.isComputed}
                    options={multiSelectChoices}
                    default={customFieldValue ? customFieldValue : []}
                    onChange={props.changeField.bind({}, field.id)}
                    placeholder={translatePhrase(field.name)}
                />
            </div>

        case FieldType.PHONE:
            if (typeof customFieldValue === 'undefined') {
                customFieldValue = '';
            }

            if (typeof customFieldValue !== 'string') {
                throw new Error('A phone field should have a string value');
            }

            let phoneCountryCode = '+91';
            let phoneNumber = '';

            if (customFieldValue.split(' ').length > 1) {
                phoneCountryCode = customFieldValue.split(' ')[0];
                phoneNumber = customFieldValue.split(' ')[1];
            }

            return <div>
                <div className={styles.inputSegment}>
                    <InputText isRequired={props.isRequired} placeholder={translatePhrase('Country Code')} isDisabled={field.isComputed} icon={chevronIcon} default={phoneCountryCode} options={['+91', '+1']} isSearchDisabled onChange={value => props.changeField(field.id, `${value} ${phoneNumber}`)} />
                </div>
                <div className={styles.inputSegment}>
                    <InputText isRequired={props.isRequired} isDisabled={field.isComputed} placeholder={field.name} type="tel" default={phoneNumber} onChange={value => props.changeField(field.id, `${phoneCountryCode} ${value}`)} />
                </div>
            </div>

        case FieldType.LOCATION:
            if (typeof customFieldValue === 'undefined') {
                customFieldValue = '';
            }

            if (typeof customFieldValue !== 'string') {
                throw new Error('A phone field should have a string value');
            }

            let latitude = '';
            let longitude = '';

            if (customFieldValue.split(' ').length > 1) {
                latitude = customFieldValue.split(' ')[0];
                longitude = customFieldValue.split(' ')[1];
            }

            return <div className={styles.locationInput}>
                <div>
                    <div className={styles.inputSegment}>
                        <InputText isRequired={props.isRequired} isDisabled={field.isComputed} placeholder={`${translatePhrase(field.name)} ${translatePhrase('latitude')}`} type="number" key={locationKey} default={latitude} onChange={value => props.changeField(field.id, `${value} ${longitude}`)} />
                    </div>
                    <div className={styles.inputSegment}>
                        <InputText isRequired={props.isRequired} isDisabled={field.isComputed} placeholder={`${translatePhrase(field.name)} ${translatePhrase('longitude')}`} type="number" key={locationKey + 1} default={longitude} onChange={value => props.changeField(field.id, `${latitude} ${value}`)} />
                    </div>
                </div>
                <Button title={translatePhrase("Select Location")} icon={<MapIcon />} isRounded={true} onClick={getLocationForField} />
            </div>

        case FieldType.FILE:
            return <div key={field.id + '-' + props.entity?.id} className={styles.inputSegment}>

                <div className={styles.inputFile}>
                    <span className={styles.placeHolderText}>{translatePhrase(field.name)}:</span>
                    <input
                        id={props.entity?.id + '-file-' + field.id}
                        type="file"
                        onChange={handleFileInputChange}
                        ref={inputRef}
                    />
                    {selectedFileName ?
                        <div className={styles.fileName}>
                            {customFieldValue && typeof customFieldValue === 'string' && customFieldValue.startsWith('http')
                                ?
                                <a style={{ 'pointerEvents': 'auto' }} href={updateLinkToCurrentOrigin(customFieldValue) + '?token=' + token} target="_blank" className={styles.defaultText + ' ' + styles.uploadedFile} rel="noreferrer">{selectedFileName}</a>
                                :
                                <span onClick={downloadLocalFile} className={styles.defaultText + ' ' + styles.uploadedFile}>{selectedFileName}</span>
                            }
                            <Button onClick={clearSelectedFile} icon={<Cancel />} size={'small'} isBlock={false} isRounded={true} />
                        </div>
                        :
                        <div>
                            <span className={styles.defaultText}>{translatePhrase('No file chosen')}</span>
                        </div>

                    }
                    <label htmlFor={props.entity?.id + '-file-' + field.id}>
                        <Attach />
                    </label>
                </div>

            </div>

        // TODO Implement the free text field

        default:
            return <div></div>;

    }
};

const CustomFieldInput = connect(mapStateToProps, mapDispatchToProps)(ConnectedCustomFieldInput);

export default CustomFieldInput;