import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/types';
import { deleteRoleCustomField, updateRoleCustomField, selectRoleCustomFieldOption, unSelectRoleCustomFieldOption, reOrderRoleCustomFieldOption, addRoleCustomFieldOption, deleteRoleCustomFieldOption, updateRoleCustomFieldOption } from '../../../shared/store/structure/role/actions';

import CustomFieldVertical, { 
    OwnProps as CustomFieldVerticalOwnProps, 
    StateProps as CustomFieldVerticalStateProps, 
    DispatchProps as CustomFieldVerticalDispatchProps, 
    OwnState as CustomFieldVerticalOwnState 
} from '../../../helpers/CustomFieldVertical';
import { Permissions } from '../../../shared/store/permissions/types';
import { translatePhrase } from '../../../shared/helpers/translation';

interface OwnProps extends CustomFieldVerticalOwnProps {
    roleId: string,
}

interface StateProps extends CustomFieldVerticalStateProps {
}

interface DispatchProps extends CustomFieldVerticalDispatchProps {
}

interface OwnState extends CustomFieldVerticalOwnState {
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) : StateProps => {
    const canEditRoleConfiguration = state.permissions.myPermissions.general.Hierarchy === Permissions.WRITE;
    const canViewRoleConfiguration = state.permissions.myPermissions.general.Hierarchy === Permissions.READ || canEditRoleConfiguration;

    const customFieldData = state.structure.roles.customFields.byId[ownProps.customFieldId];

    return {
        read: canViewRoleConfiguration,
        write: canEditRoleConfiguration,
        restrictStructureChanges: false,
        customFieldData: customFieldData,
        cardsList: customFieldData.choices.map(choiceId => {
            return {
                id: choiceId,
                name: translatePhrase(state.structure.roles.customFieldOptions.byId[choiceId].name),
            }
        }),
        selectedCard: state.structure.roles.selectedOption && customFieldData.choices.includes(state.structure.roles.selectedOption) ? {
            id: state.structure.roles.selectedOption,
            name: state.structure.roles.customFieldOptions.byId[state.structure.roles.selectedOption].name,
        } : undefined
    };
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) : DispatchProps => {
    return {
        onSelectCard: (id) => dispatch(selectRoleCustomFieldOption(id)),
        onUnSelectCard: () => dispatch(unSelectRoleCustomFieldOption()),
        reOrderCards: (sourceIndex, destinationIndex) => dispatch(reOrderRoleCustomFieldOption(sourceIndex, destinationIndex, ownProps.customFieldId)),
        addCard: (payload) => dispatch(addRoleCustomFieldOption(payload, ownProps.customFieldId)),
        deleteCard: (id) => dispatch(deleteRoleCustomFieldOption(id, ownProps.customFieldId)),
        updateCard: (payload) => dispatch(updateRoleCustomFieldOption(payload)),

        deleteVertical: (id) => dispatch(deleteRoleCustomField(id, ownProps.roleId)),
        updateVertical: (payload) => dispatch(updateRoleCustomField(payload)),
    }
}

type Props = OwnProps & StateProps & DispatchProps;

class ConnectedRoleCustomFieldVertical extends CustomFieldVertical<Props, OwnState> {

    state: OwnState = {
        isShowingAddForm: false,
        isShowingModifyForm: false,
        isShowingModifyVerticalForm: false,

        modifyingCardName: this.props.selectedCard ? this.props.selectedCard.name : '',
        modifyingVerticalName: this.props.customFieldData ? this.props.customFieldData.name : '',
        modifyingVerticalType: this.props.customFieldData ? this.props.customFieldData.type : '',
        modifyingVerticalIsComputed: this.props.customFieldData ? this.props.customFieldData.isComputed ? 'Yes' : 'No' : 'No',
        modifyingVerticalIsInTable: this.props.customFieldData ? this.props.customFieldData.isInTable ? 'Yes' : 'No' : 'No',

        isUpdating: false,
    };

    static defaultProps = {
        isReadOnly: false,
    }

}

const RoleCustomFieldVertical = connect(mapStateToProps, mapDispatchToProps)(ConnectedRoleCustomFieldVertical);

export default RoleCustomFieldVertical;