import { FC } from 'react';
import styles from './OaaSApp.module.scss';
import { Switch, Route } from 'react-router-dom';

import Languages from './languages/Languages';

import { TransitionGroup, CSSTransition } from "react-transition-group";

import ReleaseNotes from './release-notes/ReleaseNotes';
import Dashboard from './dashboard/Dashboard';
import Structure from './structure/Structure';
import Users from './users/Users';
import Members from './members/Members';
import Groups from './groups/Groups';
import Workflows from './workflows/Workflows';
import WorkflowTypeFlowchart from './workflows/flowchart/WorkflowTypeFlowchart';
import ReportFlowchart from './reports/configuration/ReportFlowchart';
import ComputedFieldFlowchart from './workflows/flowchart/ComputedFieldFlowchart';
import WorkflowProcess from './workflow/process/Process';
import Reports from './reports/Reports';
import Chat from './chat/Chat';
import APIDocs from './api-docs/APIDocs';
import StaticData from './static-data/StaticData';
import Workflow from './workflow/Workflow';

import CommonErrorBoundary from './CommonErrorBoundary';
import { useLocation } from 'react-router';
import { ApplicationState } from '../shared/store/types';
import { useSelector } from 'react-redux';
import FileDownload from './file-download/FileDownload';

type OwnProps = {};

export const AppRoutesForRouter = [{
    path: '/release-notes',
    name: 'Release Notes',
    Component: ReleaseNotes,
}, {
    path: '/dashboard',
    name: 'Dashboard',
    Component: Dashboard,
}, {
    path: '/structure',
    name: 'Structure',
    Component: Structure,
}, {
    path: '/users',
    name: 'Users',
    Component: Users,
}, {
    path: '/members',
    name: 'Members',
    Component: Members,
}, {
    path: '/groups',
    name: 'Groups',
    Component: Groups,
}, {
    path: '/workflows',
    name: 'Workflows',
    Component: Workflows,
},
// {
//     path: '/flowchart/workflow/:id/:version',
//     name: 'WorkflowTypeFlowchart',
//     Component: WorkflowTypeFlowchart,
// }, 

// {
//     path: '/flowchart/report/:id',
//     name: 'ReportFlowchart',
//     Component: ReportFlowchart,
// }, 

{
    path: '/flowchart/field/:fieldId',
    name: 'ComputedFieldFlowchart',
    Component: ComputedFieldFlowchart,
},

{
    path: '/workflow/:id/execute',
    name: 'WorkflowProcess',
    Component: WorkflowProcess,
}, {
    path: '/workflow-data/:id',
    name: 'Workflow',
    Component: Workflow,
}, {
    path: '/reports',
    name: 'Reports',
    Component: Reports,
}, {
    path: '/static-data',
    name: 'Static Data',
    Component: StaticData,
}, {
    path: '/languages',
    name: 'Languages',
    Component: Languages,
}, {
    path: '/chat',
    name: 'Chat',
    Component: Chat,
}, {
    path: '/api-docs',
    name: 'API Docs',
    Component: APIDocs,
}, {
    path: '/file-download/:link',
    name: 'File Download',
    Component: FileDownload,
}];

const AppRoutes: FC<OwnProps> = (props) => {

    const routerLocation = useLocation();
    const isTopBarExpanded = useSelector((state: ApplicationState) => state.myData.isTopBarExpanded);

    return <TransitionGroup className={styles.focusWindow + ' ' + (isTopBarExpanded ? styles.makeSpaceForTopBar : '')}>
        <CSSTransition
            timeout={{ enter: 300, exit: 300 }}
            classNames={{
                enter: styles.pageEnter,
                enterActive: styles.pageEnterActive,
                exit: styles.pageExit,
                exitActive: styles.pageExitActive,
            }}
        >
            <CommonErrorBoundary>
                <Switch>
                    {AppRoutesForRouter.map(({ path, Component }) => <Route key={path} path={path} children={<Component />} />)}
                </Switch>
            </CommonErrorBoundary>
        </CSSTransition>
    </TransitionGroup>
}

export default AppRoutes;