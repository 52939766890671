import React, { Component } from 'react';

import { CardType } from '../../../widgets/card/Card';
import FlowchartCard from '../../../widgets/card/FlowchartCard';
import CardsList from '../../../widgets/card/CardsList';
import ModifyForm from '../../../widgets/card/ModifyForm';
import { translatePhrase } from '../../../shared/helpers/translation';
import { INewWorkflowTypeCustomFieldData, IUpdateableWorkflowTypeCustomFieldData, FieldType } from '../../../shared/store/custom-fields/types';
import EnhancedInputText from '../../../widgets/form/InputText';

export interface OwnProps {
    workflowTypeId: string,
    customFieldId: string,
    existingNames: Array<string>,

    selectedId?: string,
    isReadOnly?: boolean,

}

export interface StateProps {
    read: boolean,
    write: boolean,
    restrictStructureChanges: boolean,

    isSubtitleField: boolean,

    canHaveGroupAffiliation: boolean,
    customFieldData: INewWorkflowTypeCustomFieldData,
    cardsList: Array<CardType>,
    selectedCard: CardType | undefined,
}

export interface DispatchProps {
    onSelectCard: (id: string) => void,
    onUnSelectCard: () => void,
    reOrderCards: (sourceIndex: number, destinationIndex: number) => void,
    addCard: (payload: { name: string }) => void,
    deleteCard: (id: string) => void,
    updateCard: (payload: { id: string, name: string }) => void,

    deleteVertical: (id: string) => void,
    updateVertical: (payload: IUpdateableWorkflowTypeCustomFieldData) => void,
}

type Props = OwnProps & StateProps & DispatchProps;

export interface OwnState {
    isShowingAddForm: boolean,
    isShowingModifyForm: boolean,
    modifyingCardName: string,

    isShowingModifyVerticalForm: boolean,
    modifyingVerticalName: string,
    modifyingVerticalType: string,
    modifyingVerticalIsComputed: string,
    modifyingVerticalIsInTable: string,
    modifyingVerticalAffiliation: 'member' | 'group',

    isUpdating: boolean,
};

class CustomFieldVertical<TProps extends Props, TState extends OwnState> extends Component<TProps, TState> {

    static getDerivedStateFromProps(props: Readonly<Props>, state: Readonly<OwnState>) {
        if (state.isUpdating && props.customFieldData && !state.modifyingVerticalName) {
            return {
                modifyingCardName: props.selectedCard ? props.selectedCard.name : '',
                modifyingVerticalName: props.customFieldData ? props.customFieldData.name : '',
                modifyingVerticalType: props.customFieldData.type ? props.customFieldData.type : '',
                modifyingVerticalIsComputed: props.customFieldData ? props.customFieldData.isComputed ? 'Yes' : 'No' : '',
                modifyingVerticalIsInTable: props.customFieldData ? props.customFieldData.isInTable ? 'Yes' : 'No' : '',
                modifyingVerticalAffiliation: props.customFieldData ? props.customFieldData.affiliation : 'member',
                isUpdating: false,
            };
        }

        return null;
    }

    handleSelectCard = (id: string) => {
        this.props.onSelectCard(id);
    }

    toggleVerticalEditForm = () => {
        if (this.state.isShowingModifyVerticalForm) {
            this.setState({
                isShowingModifyVerticalForm: false,
                modifyingVerticalName: '',
                modifyingVerticalType: '',
                modifyingVerticalIsComputed: '',
                modifyingVerticalIsInTable: '',
                modifyingVerticalAffiliation: 'member',
            });
        } else {
            this.setState({
                isShowingModifyVerticalForm: true,
                modifyingVerticalName: this.props.customFieldData.name,
                modifyingVerticalType: this.props.customFieldData.type,
                modifyingVerticalIsComputed: this.props.customFieldData.isComputed ? 'Yes' : 'No',
                modifyingVerticalIsInTable: this.props.customFieldData.isInTable ? 'Yes' : 'No',
                modifyingVerticalAffiliation: this.props.customFieldData.affiliation,
            });
        }
    }

    updateFieldName = (value: string) => {
        this.setState({
            modifyingVerticalName: value,
        });
    }

    updateFieldType = (value: string) => {
        this.setState({
            modifyingVerticalType: value,
        });
    }

    updateFieldAffiliation = (value: string) => {
        const affiliationValue: 'member' | 'group' = value === 'member' ? value : 'group';
        this.setState({
            modifyingVerticalAffiliation: affiliationValue,
        });
    }

    updateFieldIsInTable = (value: string) => {
        this.setState({
            modifyingVerticalIsInTable: value,
        });
    }

    updateFieldIsComputed = (value: string) => {
        this.setState({
            modifyingVerticalIsComputed: value,
        });
    }

    deleteCustomField = () => {
        this.props.deleteVertical(this.props.customFieldId);
    }

    updateCustomField = () => {
        const fieldType = this.state.modifyingVerticalType as keyof typeof FieldType;

        this.props.updateVertical({
            id: this.props.customFieldId,
            name: this.state.modifyingVerticalName,
            type: FieldType[fieldType],
            isComputed: this.state.modifyingVerticalIsComputed === 'Yes',
            isEditable: true,
            isDeletable: true,
            isInTable: this.state.modifyingVerticalIsInTable === 'Yes',
            affiliation: this.state.modifyingVerticalAffiliation,
            seedEntityVariable: this.props.customFieldData.seedEntityVariable,
            seedAffiliationVariable: this.props.customFieldData.seedAffiliationVariable,
        });

        this.setState({
            isShowingModifyVerticalForm: false,
            modifyingVerticalName: '',
            modifyingVerticalType: '',
            modifyingVerticalIsComputed: '',
            modifyingVerticalIsInTable: '',

            isUpdating: true,
        });
    }

    validateVerticalForm = () => {
        const fieldTypes = Object.keys(FieldType);
        const binaryTypes = ['Yes', 'No'];
        const affiliationTypes = ['member', 'group'];

        if (!this.state.modifyingVerticalName) {
            return 'Enter a valid name';
        }

        if (!fieldTypes.includes(this.state.modifyingVerticalType)) {
            return 'Enter a valid type';
        }

        if (!binaryTypes.includes(this.state.modifyingVerticalIsComputed)) {
            return 'Enter a valid computed field'
        }

        if (!binaryTypes.includes(this.state.modifyingVerticalIsInTable)) {
            return 'Enter a valid in-table field'
        }

        if (this.props.canHaveGroupAffiliation && !affiliationTypes.includes(this.state.modifyingVerticalAffiliation)) {
            return 'Enter a valid affiliation'
        }

        return true;
    }



    toggleModifyForm = () => {
        let toggledState = !this.state.isShowingAddForm;

        if (this.state.isShowingModifyForm) {
            this.setState({
                isShowingModifyForm: false,
                isShowingAddForm: false
            });
        } else {
            this.setState({
                isShowingAddForm: toggledState
            });
        }
    }

    editSelectedCard = () => {

        if (!this.props.selectedCard) {
            throw new Error('Cannot edit card since nothing is selected');
        }

        this.setState({
            isShowingAddForm: false,
            isShowingModifyForm: true,
            modifyingCardName: this.props.selectedCard.name
        });
    }

    updateCardName = (value: string) => {
        this.setState({
            modifyingCardName: value
        });
    }

    addCard = () => {

        this.props.addCard({
            name: this.state.modifyingCardName
        });

        this.setState({
            modifyingCardName: '',
            isShowingAddForm: false
        });
    }

    updateCard = () => {

        if (!this.props.selectedCard) {
            return;
        }

        this.props.updateCard({
            id: this.props.selectedCard.id,
            name: this.state.modifyingCardName
        });

        this.setState({
            isShowingModifyForm: false,
            modifyingCardName: '',
            isShowingAddForm: false
        });
    }

    validateCard = () => {
        if (!this.state.modifyingCardName) {
            return 'Enter a valid name';
        }

        if (this.props.existingNames.includes(this.state.modifyingCardName.toLocaleLowerCase())) {
            return 'A custom field with this name already exists';
        }

        return true;
    }

    render() {
        const affiliationOptions = [{
            name: 'Member',
            value: 'member',
        }, {
            name: 'Group',
            value: 'group',
        }];
        const typeOptions = Object.keys(FieldType).map(name => {
            return {
                name: translatePhrase(name.split('_').join(' ')),
                value: name,
            }
        });

        const editVerticalForm = <ModifyForm isNew={false} submitForm={this.updateCustomField} cancelForm={this.toggleVerticalEditForm} validateForm={this.validateVerticalForm}>
            <EnhancedInputText placeholder="Name" onEnterPress={this.updateCustomField} onChange={this.updateFieldName} default={this.props.customFieldData.name} />
            <EnhancedInputText placeholder="Type" isDisabled={!!this.props.customFieldId} onEnterPress={this.updateCustomField} onChange={this.updateFieldType} default={this.props.customFieldData.type} options={typeOptions} />
            <EnhancedInputText isBooleanField placeholder="Is Computed" onEnterPress={this.updateCustomField} onChange={this.updateFieldIsComputed} defaultBooleanValue={this.props.customFieldData.isComputed ? true : false} />
            <EnhancedInputText isBooleanField placeholder="Searchable / Show in Table" onEnterPress={this.updateCustomField} onChange={this.updateFieldIsInTable} defaultBooleanValue={this.props.customFieldData.isInTable || this.props.isSubtitleField} isDisabled={this.props.isSubtitleField} />
            {this.props.canHaveGroupAffiliation && <EnhancedInputText placeholder="Affiliation" isDisabled={!!this.props.customFieldId} default={this.props.customFieldData.affiliation === 'member' ? 'Member' : 'Group'} onEnterPress={this.updateCustomField} onChange={this.updateFieldAffiliation} options={affiliationOptions} />}
        </ModifyForm>;

        const modifyForm = <ModifyForm isNew={!this.state.isShowingModifyForm} submitForm={this.state.isShowingModifyForm ? this.updateCard : this.addCard} cancelForm={this.toggleModifyForm} validateForm={this.validateCard}>
            <EnhancedInputText placeholder="Name" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardName} default={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.name : ''} key={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.id : 0} />
        </ModifyForm>;

        const choicesVisible = this.props.customFieldData.type === FieldType.SINGLE_SELECT || this.props.customFieldData.type === FieldType.MULTI_SELECT;
        let cards: Array<CardType> | undefined;
        let flowchartCard = undefined;

        if (choicesVisible) {
            cards = this.props.cardsList;
        }

        if (this.props.customFieldData.isComputed) {
            const cardData: CardType = {
                id: this.props.customFieldData.type.split('_').join(' '),
                name: translatePhrase(this.props.customFieldData.type.split('_').join(' ')),
            }
            flowchartCard = <FlowchartCard card={cardData} link={`/flowchart/field/${this.props.customFieldId}`} />
            cards = undefined;
        }

        return <CardsList
            heading={this.props.customFieldData.name}
            cards={cards}
            selectedCard={this.props.selectedCard}
            onSelectCard={this.handleSelectCard}
            onUnselectCard={this.props.onUnSelectCard}
            onDeleteCard={this.props.deleteCard}
            onEditCard={this.editSelectedCard}
            onReorderCards={this.props.reOrderCards}
            modifyForm={modifyForm}
            isShowingAddForm={this.state.isShowingAddForm}
            isShowingEditForm={this.state.isShowingModifyForm}
            onAddCard={this.toggleModifyForm}
            isDeleteRestricted={this.props.restrictStructureChanges || !this.props.customFieldData.isDeletable}
            isReadOnly={this.props.isReadOnly || !this.props.write || !this.props.customFieldData.isEditable}

            isAddRestricted={!choicesVisible}
            isShowingIndex={choicesVisible}
            isShowingEditVerticalForm={this.state.isShowingModifyVerticalForm}
            modifyVerticalForm={editVerticalForm}
            onDeleteVertical={this.deleteCustomField}
            onEditVertical={this.toggleVerticalEditForm}
            subHeader={flowchartCard ? flowchartCard.props.card.name : (cards && choicesVisible ? cards.length + ' defined' : this.props.customFieldData.type.split('_').join(' '))}
            isCollapsible={true}
            flowChartLink={flowchartCard ? flowchartCard.props.link : ''}
        >
            {flowchartCard}
        </CardsList>
    }
}

export default CustomFieldVertical;