import { combineReducers, AnyAction } from 'redux';
import { ApplicationState, RESET_SESSION } from './types';
import { internationalizationReducer } from './internationalization/reducers';
import { permissionsReducer } from './permissions/reducers';
import { organizationReducer } from './organization/reducers';
import { structureReducer } from './structure/reducers';
import { flowchartReducer } from './flowchart/reducers';
import { usersReducer } from './users/reducer';
import { membersReducer } from './members/reducer';
import { groupsReducer } from './groups/reducer';
import { workflowsReducer } from './workflows/reducer';
import { widgetsReducer } from './widgets/reducer';
import { reportsReducer } from './reports/reducer';
import { staticDataHoldersReducer } from './static-info/reducer';
import { myDataReducer } from './my-data/reducer';
import { eventsReducer } from './events/reducers';
import { errorsReducer } from './errors/reducers';
import { FETCH_LOCAL_DATA } from './types';
import { packagesReducer } from './package/reducer';
import { savedStatesReducer } from './state-save/reducers';
import { syncLogReducer } from './sync-logs/reducer';


const appReducer = combineReducers<ApplicationState>({
    internationalization: internationalizationReducer,
    permissions: permissionsReducer as any,
    organization: organizationReducer as any,
    structure: structureReducer,
    flowchart: flowchartReducer,
    users: usersReducer,
    members: membersReducer,
    groups: groupsReducer,
    workflows: workflowsReducer,
    widgets: widgetsReducer as any,
    staticInfo: staticDataHoldersReducer,
    reports: reportsReducer,
    myData: myDataReducer as any,
    events: eventsReducer,
    errors: errorsReducer,
    packages: packagesReducer,
    savedStates: savedStatesReducer,
    synclogState: syncLogReducer
});

export const rootReducer = (state: ApplicationState | undefined, action: AnyAction) => {
    if (action.type === FETCH_LOCAL_DATA) {
        const packageState = state?.packages;
        state = action.data as ApplicationState;

        if (packageState) {
            state.packages = packageState;
        }
    }

    if (action.type === RESET_SESSION) {
        state = undefined;
    }

    return appReducer(state, action)
}