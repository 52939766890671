import React, { Component } from 'react';
import styles from './DecrementingCounter.module.scss';

type OwnProps = {
    remaining: number,
    additionalText?: string,
};

type OwnState = {
    remainingSeconds: number,
    intervalId: number|undefined,
};

class DecrementingCounter extends Component<OwnProps, OwnState> {
    constructor(props: Readonly<OwnProps>) {
        super(props);
        
        const intervalId = window.setInterval(this.decrementSeconds, 1000);

        this.state = {
            remainingSeconds: props.remaining,
            intervalId,
        };
        
    }
    
    decrementSeconds = () => {
        this.setState((prevState, props) => {
            return {remainingSeconds: prevState.remainingSeconds > 0 ? prevState.remainingSeconds - 1 : 0};
        });
    }

    componentWillUnmount() {
        if (this.state.intervalId) {
            window.clearInterval(this.state.intervalId);
        }
    }
    
    render() {
        return <span className={styles.counter}> {this.props.additionalText} 00:{this.state.remainingSeconds < 10 ? '0' + this.state.remainingSeconds : this.state.remainingSeconds}</span>;
    }
}

export default DecrementingCounter;