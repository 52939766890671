import { MyDataState, MyDataActions, UPDATE_MY_DATA, START_DATA_PUSH, COMPLETE_DATA_PUSH, UPDATE_MY_ID, UPDATE_LAST_SYNC_TIME, SET_TOAST_MESSAGE, CLEAR_TOAST_MESSAGE, FREEZE_BACKGROUND, UN_FREEZE_BACKGROUND, SET_IS_TOP_BAR_EXPANDED, COMPLETE_OLDER_DATA_FETCH, START_OLDER_DATA_FETCH, SELECT_NUDGE, COMPLETE_DATA_FETCH, COMPLETE_PARTIAL_DATA_FETCH, FETCH_DATA_REQUEST, MARK_NETWORK_INTERRUPT, RESUME_PARTIAL_DATA_FETCH, SET_IS_EDITING_ORG_PROFILE, SET_EDITING_ORG_STATE, SET_IS_FETCHING_FOR_DATA_UPDATE, SET_IS_LOGGING_OUT, SET_INFO_MESSAGE, CLEAR_INFO_MESSAGE, SET_IS_ONLINE, SET_LAST_REFRESH_TIME, SET_SHOULD_UPDATE_APPLICATION, SET_LATEST_VERSION_NUMBER, SET_ERROR_MESSAGE, CLEAR_ERROR_MESSAGE, CLEAR_INDETERMINATE_MESSAGE, SET_INDETERMINATE_MESSAGE, SET_IS_FLOWCHART_EXPANDED, COMPLETE_DASHBOARD_RELOAD, RESET_NETWORK_CALL, SET_WARNING_MESSAGE, CLEAR_WARNING_MESSAGE } from './types';
import { widgetsReducer, initialState as widgetsInitialState } from './widgets/reducer';
import { GlobalActions } from '../types';

const initialState: MyDataState = {
    id: '',
    token: '',
    isPushingData: false,
    isPartiallyLoaded: false,
    isNetworkCallInterrupted: false,
    lastMemberId: undefined,
    lastGroupId: undefined,
    lastWorkflowId: undefined,
    partialResponseId: undefined,
    isLoaded: false,
    lastSyncTime: undefined,
    isFrozen: false,
    isTopBarExpanded: false,
    isFlowchartExpanded: false,
    isFetchingOlderData: false,
    isFetchingForDataUpdate: false,
    fetchingDataForUpdateMessage: '',
    isOlderDataFetched: false,
    selectedNudgeId: undefined,

    shouldUpdateApplication: false,
    latestVersionNumber: undefined,

    lastRefreshTime: '',

    isOnline: false,

    infoMessage: '',
    indeterminateMessage: '',
    errorMessage: '',
    warningMessage: '',

    shouldReloadDashboard: false,

    widgets: widgetsInitialState,
    editingOrgState: undefined,
}

export function myDataReducer(state = initialState, action: MyDataActions | GlobalActions): MyDataState {
    state = {
        ...state,
        lastAction: action.type,
        widgets: widgetsReducer(state.widgets, action),
    }

    switch (action.type) {
        case UPDATE_MY_ID:
            return {
                ...state,
                id: action.id,
            }

        case UPDATE_LAST_SYNC_TIME:
            return {
                ...state,
                lastSyncTime: action.lastSyncTime,
            }

        case UPDATE_MY_DATA:
            return {
                ...action.data,
                widgets: {
                    ...action.data.widgets,
                    updatedIds: state.widgets.updatedIds,
                    createdConfigurations: state.widgets.createdConfigurations,
                    deletedConfigurations: state.widgets.deletedConfigurations,
                },
                isLoaded: true,
            }

        case SELECT_NUDGE:
            return {
                ...state,
                selectedNudgeId: action.nudgeId,
            }

        case SET_IS_TOP_BAR_EXPANDED:
            return {
                ...state,
                isTopBarExpanded: action.isExpanded,
            };

        case SET_IS_FLOWCHART_EXPANDED:
            return {
                ...state,
                isFlowchartExpanded: action.isExpanded,
            };

        case SET_IS_FETCHING_FOR_DATA_UPDATE:
            return {
                ...state,
                isFetchingForDataUpdate: action.isFetchingForDataUpdate,
                fetchingDataForUpdateMessage: action.message,
            };

        case FETCH_DATA_REQUEST:
            return {
                ...state,
                isLoaded: false,
                isPartiallyLoaded: false,
                totalNoOfMembers: undefined,
                totalNoOfGroups: undefined,
                totalNoOfWorkflows: undefined,
                isNetworkCallInterrupted: false,
            }

        case RESUME_PARTIAL_DATA_FETCH:
            return {
                ...state,
                isNetworkCallInterrupted: false,
            }

        case MARK_NETWORK_INTERRUPT:
            return {
                ...state,
                isNetworkCallInterrupted: true,
            }

        case COMPLETE_PARTIAL_DATA_FETCH:
            return {
                ...state,
                isPartiallyLoaded: true,
                partialResponseId: action.responseId,

                totalNoOfMembers: typeof action.totalNoOfMembers !== 'undefined' ? action.totalNoOfMembers : state.totalNoOfMembers,
                totalNoOfGroups: typeof action.totalNoOfGroups !== 'undefined' ? action.totalNoOfGroups : state.totalNoOfGroups,
                totalNoOfWorkflows: typeof action.totalNoOfWorkflows !== 'undefined' ? action.totalNoOfWorkflows : state.totalNoOfWorkflows,

                lastMemberId: action.lastMemberId,
                lastGroupId: action.lastGroupId,
                lastWorkflowId: action.lastWorkflowId,
            }

        case COMPLETE_DATA_FETCH:
            return {
                ...state,
                isLoaded: true,
                isPartiallyLoaded: false,
                isFetchingForDataUpdate: false,
                totalNoOfMembers: undefined,
                totalNoOfGroups: undefined,
                totalNoOfWorkflows: undefined,
            }

        case START_DATA_PUSH:
            return {
                ...state,
                isPushingData: true,
                isPartiallyLoaded: false,
                totalNoOfMembers: undefined,
                totalNoOfGroups: undefined,
                totalNoOfWorkflows: undefined,
                isNetworkCallInterrupted: false,
            }

        case COMPLETE_DATA_PUSH:
            return {
                ...state,
                isPushingData: false,
                isPartiallyLoaded: false,
                totalNoOfMembers: undefined,
                totalNoOfGroups: undefined,
                totalNoOfWorkflows: undefined,
                isNetworkCallInterrupted: false,
                shouldReloadDashboard: true,
            }

        case START_OLDER_DATA_FETCH:
            return {
                ...state,
                isFetchingOlderData: true,
                isPartiallyLoaded: false,
                totalNoOfMembers: undefined,
                totalNoOfGroups: undefined,
                totalNoOfWorkflows: undefined,
                isNetworkCallInterrupted: false,
            }

        case COMPLETE_OLDER_DATA_FETCH:
            return {
                ...state,
                isFetchingOlderData: false,
                isOlderDataFetched: true,
                isPartiallyLoaded: false,
                totalNoOfMembers: undefined,
                totalNoOfGroups: undefined,
                totalNoOfWorkflows: undefined,
                isNetworkCallInterrupted: false,
            }

        case RESET_NETWORK_CALL:
            return {
                ...state,
                isFetchingOlderData: false,
                isOlderDataFetched: true,
                isPartiallyLoaded: false,
                totalNoOfMembers: undefined,
                totalNoOfGroups: undefined,
                totalNoOfWorkflows: undefined,
                isNetworkCallInterrupted: false,
                isPushingData: false,
            }

        case SET_TOAST_MESSAGE:
            return {
                ...state,
                errorMessage: action.message,
            }

        case CLEAR_TOAST_MESSAGE:
            return {
                ...state,
                errorMessage: '',
            }

        case SET_INFO_MESSAGE:
            return {
                ...state,
                infoMessage: action.message,
            }

        case CLEAR_INFO_MESSAGE:
            return {
                ...state,
                infoMessage: '',
            }

        case SET_INDETERMINATE_MESSAGE:
            return {
                ...state,
                indeterminateMessage: action.message,
            }

        case CLEAR_INDETERMINATE_MESSAGE:
            return {
                ...state,
                indeterminateMessage: '',
            }

        case SET_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: action.message,
            }

        case CLEAR_ERROR_MESSAGE:
            return {
                ...state,
                errorMessage: '',
            }

        case SET_WARNING_MESSAGE:
            return {
                ...state,
                warningMessage: action.message,
            }

        case CLEAR_WARNING_MESSAGE:
            return {
                ...state,
                warningMessage: '',
            }

        case FREEZE_BACKGROUND:
            return {
                ...state,
                isFrozen: true,
            }

        case UN_FREEZE_BACKGROUND:
            return {
                ...state,
                isFrozen: false,
            }

        case SET_IS_EDITING_ORG_PROFILE:
            return {
                ...state,
                isEditingOrgProfile: action.isEditing,
            }

        case SET_IS_LOGGING_OUT:
            return {
                ...state,
                isLoggingOut: action.isLoggingOut,
            }

        case SET_SHOULD_UPDATE_APPLICATION:
            return {
                ...state,
                shouldUpdateApplication: action.shouldUpdateApplication,
            }

        case SET_LATEST_VERSION_NUMBER:
            return {
                ...state,
                latestVersionNumber: action.latestVersionNumber,
            }

        case SET_IS_ONLINE:
            return {
                ...state,
                isOnline: action.isOnline,
            }

        case SET_LAST_REFRESH_TIME:
            return {
                ...state,
                lastRefreshTime: action.lastRefreshTime,
            }

        case SET_EDITING_ORG_STATE:
            return {
                ...state,
                editingOrgState: action.organizationState,
            }

        case COMPLETE_DASHBOARD_RELOAD:
            return {
                ...state,
                shouldReloadDashboard: false,
            }

        default:
            return state;
    }
}