import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/types';
import { INewWorkflowTypeData, IUpdateableWorkflowTypeData } from '../../../shared/store/workflows/types/types';
import { reOrderWorkflowTypes, addWorkflowType, updateWorkflowType, deleteWorkflowType, setWorkflowTypeSearchString } from '../../../shared/store/workflows/types/actions';

import CardTreeVertical, {
    OwnProps as CardTreeOwnProps,
    StateProps as CardTreeStateProps,
    DispatchProps as CardTreeDispatchProps,
    OwnState as CardTreeOwnState,
    WorkflowCardType
} from './WorkflowTypesVertical';
import { Permissions } from '../../../shared/store/permissions/types';
import { translatePhrase } from '../../../shared/helpers/translation';

interface OwnProps extends CardTreeOwnProps {
}

interface StateProps extends CardTreeStateProps {
}

interface DispatchProps extends CardTreeDispatchProps {
    addCard: (payload: IUpdateableWorkflowTypeData) => void,
    updateCard: (payload: IUpdateableWorkflowTypeData) => void,
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): StateProps => {
    const canEditConfiguration = state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.WRITE;
    const canViewConfiguration = canEditConfiguration || state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.READ;

    const allWorkflowTypes: Array<WorkflowCardType> = state.workflows.types.allEntries
        .map(workflowTypeId => {
            const workflowType = state.workflows.types.byId[workflowTypeId];
            const project = state.structure.projects.byId[workflowType.project];
            const specificPermission = state.permissions.myPermissions.workflows[workflowTypeId];

            let affiliationText;

            if (workflowType.affiliation === 'none') {
                affiliationText = 'Not affiliated';
            } else if (workflowType.affiliation === 'member') {
                if (!!workflowType.affiliatedEntity) {
                    affiliationText = `${translatePhrase('Affiliated')}: ${translatePhrase(state.members.types.byId[workflowType.affiliatedEntity].name)}`;
                } else {
                    affiliationText = `${translatePhrase('Affiliated')}: ${translatePhrase('All Member Types')}`;
                }
            } else if (workflowType.affiliation === 'group') {
                if (!!workflowType.affiliatedEntity) {
                    affiliationText = `${translatePhrase('Affiliated')}: ${translatePhrase(state.groups.types.byId[workflowType.affiliatedEntity].name)}`;
                } else {
                    affiliationText = `${translatePhrase('Affiliated')}: ${translatePhrase('All Group Types')}`;
                }
            }

            const memberActions = state.members.types.actions.allEntries.map(actionId => state.members.types.actions.byId[actionId]);
            const groupActions = state.groups.types.actions.allEntries.map(actionId => state.groups.types.actions.byId[actionId]);
            const isManaged = memberActions.concat(groupActions).some(action => action.workflowType && action.workflowType === workflowTypeId);

            return {
                id: workflowTypeId,
                name: translatePhrase(workflowType.name),
                details: `${translatePhrase(project.name)}, ${affiliationText ? translatePhrase(affiliationText) : ''}`,
                isReadOnly: typeof specificPermission !== 'undefined' && specificPermission === Permissions.READ,
                isDeleteRestricted: isManaged,
                affiliation: workflowType.affiliation,
                affiliatedEntity: workflowType.affiliatedEntity,
                isCore: workflowType.isCore,
                areMultipleInstancesAllowed: workflowType.areMultipleInstancesAllowed,
                subTitleFieldId: workflowType.subTitleFieldId,
                project: workflowType.project,
                seedEntityVariable: workflowType.seedEntityVariable,
                seedAffiliationVariable: workflowType.seedAffiliationVariable,
                startPiece: workflowType.startPiece,
                betaStartPiece: workflowType.betaStartPiece,
            }
        });

    const selectedCard = allWorkflowTypes.find(workflowType => workflowType.id === ownProps.selectedId);

    return {
        read: canViewConfiguration,
        write: canEditConfiguration,
        restrictStructureChanges: false,

        searchInput: state.workflows.types.searchString,

        projectsData: state.structure.projects,
        memberTypesData: state.members.types,
        groupTypesData: state.groups.types,
        workflowTypesData: state.workflows.types,
        cardsList: allWorkflowTypes,
        selectedCard,
    };
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
    return {
        reOrderCards: (sourceIndex: number, destinationIndex: number) => dispatch(reOrderWorkflowTypes(sourceIndex, destinationIndex)),
        addCard: (payload: INewWorkflowTypeData) => dispatch(addWorkflowType(payload)),
        deleteCard: (id: string) => dispatch(deleteWorkflowType(id)),
        updateCard: (payload: IUpdateableWorkflowTypeData) => dispatch(updateWorkflowType(payload)),

        setSearchInput: (searchString: string) => dispatch(setWorkflowTypeSearchString(searchString)),
    };
}

type Props = OwnProps & StateProps & DispatchProps;

interface OwnState extends CardTreeOwnState {
};

class ConnectedWorkflowTypesList extends CardTreeVertical<Props, OwnState> {

    constructor(props: Props) {
        super(props);

        this.state = {
            isShowingAddForm: false,
            isShowingModifyForm: false,
            modifyingCardName: props.selectedCard ? props.selectedCard.name : '',
            modifyingCardProject: props.selectedCard ? props.selectedCard.project : '',
            modifyingCardIsCore: props.selectedCard && !props.selectedCard.isCore ? 'No' : 'Yes',
            modifyingCardAreMultipleInstancesAllowed: props.selectedCard && props.selectedCard.areMultipleInstancesAllowed ? 'Yes' : 'No',
            modifyingCardAffiliation: props.selectedCard ? props.selectedCard.affiliation : 'none',
            modifyingCardAffiliatedEntity: props.selectedCard ? props.selectedCard.affiliatedEntity : '',
            modifyingCardSubtitleFieldId: props.selectedCard && props.selectedCard.subTitleFieldId ? props.selectedCard.subTitleFieldId : '',

            seedAffiliationVariable: props.selectedCard ? props.selectedCard.seedAffiliationVariable : '',
            lastSelectedCard: props.selectedCard ? props.selectedCard.id : undefined,
        };
    }

    static defaultProps = {
        isReadOnly: false,
        showCardCount: true,
        isSearchable: true
    }

}

const WorkflowTypesList = connect(mapStateToProps, mapDispatchToProps)(ConnectedWorkflowTypesList);

export default WorkflowTypesList;