import React, { useRef, useState } from 'react';
import styles from './PackageDetails.module.scss';
import logo from '../../../common/assets/logo.svg';
import Button from '../../../widgets/button/CommonButton';
import marketImage from '../../../common/images/market_image.png';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../../../shared/store/types';
import { useHistory } from 'react-router';
import { saveAs } from 'file-saver';

const images = [marketImage, logo]

interface OwnProps {
    packageId: string;
}

export const DataPackageDetails: React.FC<OwnProps> = (props: OwnProps) => {
    const [selectedImageIndex, setSelectedImage] = useState<number>(0);
    const [selectedSegment, setSelectedSegment] = useState<'description' | 'screenshots'>('description');
    const history = useHistory();


    const descriptionRef = useRef<HTMLHeadingElement>(null);
    const screenshotsRef = useRef<HTMLHeadingElement>(null);

    const packageId = props.packageId;

    const selectedPackage = useSelector((state: ApplicationState) => state.packages.byId[packageId]);

    if (!selectedPackage || selectedPackage.type !== 'Data') {
        return <div></div>;
    }

    function downloadPackageData() {
        if (!selectedPackage || !selectedPackage.packageData) {
            return;
        }

        const exportData = JSON.stringify(selectedPackage.packageData, null, 2);

        const fileBlob = new Blob([exportData.split('\n').join('\r\n')], { type: 'application/json' });

        saveAs(fileBlob, `${selectedPackage.name} Package download.json`);
    }

    function goBack() {
        history.goBack();
    }

    function handleHeaderClick(segment: 'description' | 'screenshots') {
        setSelectedSegment(segment);

        switch (segment) {
            case 'description':
                const descriptionElement = descriptionRef.current;

                if (descriptionElement) {
                    descriptionElement.scrollIntoView({
                        block: 'center',
                    })
                }
                break;
            case 'screenshots':
                const screenshotsElement = screenshotsRef.current;

                if (screenshotsElement) {
                    screenshotsElement.scrollIntoView({
                        block: 'center',
                    })
                }
                break;
        }
    }

    return <section className={styles.marketPage}>

        <header className={styles.pageHeading}>
            <h1> {selectedPackage.name} </h1>
        </header>

        <div className={styles.segmentContainer}>
            <Button text={'Go back'} isRounded size={'medium'} color={'contrast'} type="secondary" padding={'0px 15px'} onClick={goBack} />
            <div className={styles.segmentHolder}>
                <a onClick={() => handleHeaderClick('description')} className={selectedSegment === 'description' ? styles.active : ''}> Description </a>
                <a onClick={() => handleHeaderClick('screenshots')} className={selectedSegment === 'screenshots' ? styles.active : ''}> Screenshots </a>
            </div>
            {selectedPackage.packageData && <Button text={'Download'} isRounded size={'medium'} color={'contrast'} padding={'0px 15px'} onClick={downloadPackageData} />}
        </div>

        <section className={styles.detailsHolder}>

            <h4 ref={descriptionRef} id="description"> Description: </h4>
            <div onMouseOver={() => setSelectedSegment('description')} className={styles.sector + ' ' + styles.description}>
                <p>A data package with {selectedPackage.levelsCount} level{selectedPackage.levelsCount > 1 ? 's' : ''}</p>
                <p> {selectedPackage.description} </p>
            </div>

            <h4 ref={screenshotsRef} id="screenshots"> Screenshots </h4>
            <div onMouseOver={() => setSelectedSegment('screenshots')} className={styles.sector + ' ' + styles.slideShowHolder}>
                <img className={styles.selectedImage} src={selectedPackage.imageLinks[selectedImageIndex]} />
            </div>

        </section>

    </section>
}