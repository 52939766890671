import React from 'react';
import styles from './Groups.module.scss';
import { Switch, Route, Redirect } from "react-router-dom";

import { ApplicationState } from '../../shared/store/types';

import { Permissions } from '../../shared/store/permissions/types';

import Configuration from './configuration/Configuration';
import List from './list/List';

import { connect } from 'react-redux';

import { ReactComponent as GroupsIcon } from '../../common/assets/group.svg';
import Header from '../../widgets/header/Header';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canViewGroupConfiguration =
      state.permissions.myPermissions.general.GroupsConfiguration === Permissions.WRITE ||
      state.permissions.myPermissions.general.GroupsConfiguration === Permissions.READ;

    const isOnline = state.myData.isOnline

    return {
        canGoToConfiguration: canViewGroupConfiguration,
        canGoToList: true,
        currentGroupsCount: Object.keys(state.groups.byId).length,
        totalGroupsCount: state.myData.totalNoOfGroups,
        hasGroups: state.groups.allEntries.length > 0,
        isOnline,
        isPresentable: state.myData.isLoaded || state.myData.isPartiallyLoaded,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;

const ConnectedGroups: React.FC<Props> = (props) => {
    const { canGoToConfiguration, canGoToList, currentGroupsCount, 
        totalGroupsCount, isOnline, hasGroups, isPresentable } = props;

    if (!(canGoToConfiguration || canGoToList)) {
        return <Redirect to="/dashboard" />;
    }

    const tabs = [
        {
            tabName: "List",
            link: "/groups/list",
            hasAccess: canGoToList,
            dataSelector: "groups-list-tab",
        },
        {
            tabName: "Configuration",
            link: "/groups/configuration",
            hasAccess: canGoToConfiguration,
            dataSelector: "groups-configuration-tab",
        }
    ];

    return (
        <section className={styles.FocusSpace}>
            
            <Header
                pageName={"groups"}
                tabs={tabs}
                icon={<GroupsIcon />}
                currentCount={currentGroupsCount}
                totalCount={totalGroupsCount}
            />


            {isPresentable && <div className={styles.innerFocus}>
                <Switch>
                    <Route path="/groups/configuration" render={() => <Configuration />} />
                    <Route path="/groups/list" render={() => <List />} />
                    <Route render={() => <Redirect to={canGoToList && (hasGroups || isOnline || !canGoToConfiguration) ? '/groups/list' : '/groups/configuration'} />} />
                </Switch>
            </div>}
        </section>
    );
};

const Groups = connect(mapStateToProps)(ConnectedGroups);

export default Groups;