import React from 'react';
import styles from './Process.module.scss';

import { connect } from 'react-redux';

import { getReadableDataForCustomField } from '../../../shared/store/custom-fields';

import { ApplicationState } from '../../../shared/store/types';
import { translatePhrase } from '../../../shared/helpers/translation';
import { DefaultFlowchartProcessState } from '../../../shared/store/flowchart/types';
import { getMemberComputedFieldValue } from '../../../shared/store/flowchart/helpers/custom-fields/member';
import moment from 'moment';
import Timeline from './Timeline';
import Tabs from '../../../widgets/tabs/Tabs';
import Button from '../../../widgets/button/CommonButton';
import ProgressBar from './ProgressBar';
import { VariableValueType } from '../../../shared/helpers/common-types';
import { CustomFieldValueType } from '../../../shared/store/custom-fields/types';

type OwnProps = {
    workflowId: string,
    openByDefault?: boolean,
    goToWorkflows: () => void,
};

type Option = {
    name: string;
    value: string;
}

type OwnState = {
    expandTimeline: boolean,
    sortedTimeline: boolean,
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    const workflow = state.workflows.byId[ownProps.workflowId];
    const isCompleted = state.workflows.types.statuses.byId[workflow.status].isTerminal;
    const workflowType = state.workflows.types.byId[workflow.type];
    const processState = workflow.history[workflow.historyIndex];

    return {
        workflow,
        isCompleted,
        processState,
        workflowType,
        workflowData: state.workflows,
        membersData: state.members,
        groupsData: state.groups,
        optionsData: state.workflows.types.customFieldOptions,

        applicationState: state,
    };
}


type StateProps = ReturnType<typeof mapStateToProps>;
type Props = OwnProps & StateProps;

class ConnectedWorkflowData extends React.Component<Props, OwnState> {
    constructor(props: Props) {
        super(props);

        this.state = {
            expandTimeline: false,
            sortedTimeline: true,
        }

    }

    componentDidUpdate(prevProps: Props) {

    }

    getUserName(userId: string) {
        try {
            const user = this.props.applicationState.users.byId[userId];
            let userName: VariableValueType;

            const nameField = this.props.applicationState.users.customFields.byId[this.props.applicationState.users.nameFieldId];

            if (nameField.isComputed && typeof nameField.startPiece !== 'undefined') {
                const processState: DefaultFlowchartProcessState = {
                    displayingContinuePieceId: undefined,
                    displayingAddWorkflowPieceId: undefined,
                    customFields: { ...user.customFields },
                    variables: {
                        [nameField.seedEntityVariable]: user.id,
                    },
                    lastComputedPiece: undefined,
                    executionStack: [],
                    forIterationCounts: {},
                    displayingQuestionPieceId: undefined,
                    displayingShowPieceId: undefined,
                    displayingGroupPieceId: undefined,
                    displayingTransferPieceId: undefined,
                    createdWorkflowId: undefined,
                    displayingFinsalLoanProcessPieceId: undefined,
                };

                userName = getMemberComputedFieldValue(this.props.applicationState, processState, nameField.startPiece.piece, user.id, nameField);
            } else {
                userName = user.customFields[this.props.applicationState.users.nameFieldId];
            }

            if (Array.isArray(userName)) {

                if (userName.length > 0 && Array.isArray(userName[0])) {
                    // Cannot be a multidimensional array
                    throw new Error('The value cannot be a multi-dimensional array')
                }

                userName = userName as Array<string>;
            }

            userName = getReadableDataForCustomField(userName, this.props.applicationState.users.customFields.byId[this.props.applicationState.users.nameFieldId], user.id, 'user');

            return userName;
        } catch {
            return '-';
        }
    }

    getMemberName(memberId: string) {
        const member = this.props.membersData.byId[memberId];
        const memberType = this.props.membersData.types.byId[member.type];
        let memberName: VariableValueType;

        const nameField = this.props.membersData.types.customFields.byId[memberType.nameFieldId];

        if (nameField.isComputed && typeof nameField.startPiece !== 'undefined') {
            const processState: DefaultFlowchartProcessState = {
                displayingContinuePieceId: undefined,
                displayingAddWorkflowPieceId: undefined,
                customFields: { ...member.customFields },
                variables: {
                    [nameField.seedEntityVariable]: member.id,
                },
                lastComputedPiece: undefined,
                executionStack: [],
                forIterationCounts: {},
                displayingQuestionPieceId: undefined,
                displayingShowPieceId: undefined,
                displayingGroupPieceId: undefined,
                displayingTransferPieceId: undefined,
                createdWorkflowId: undefined,
                displayingFinsalLoanProcessPieceId: undefined,
            };

            memberName = getMemberComputedFieldValue(this.props.applicationState, processState, nameField.startPiece.piece, member.id, nameField);
        } else {
            memberName = member.customFields[memberType.nameFieldId];
        }

        if (Array.isArray(memberName)) {

            if (memberName.length > 0 && Array.isArray(memberName[0])) {
                // Cannot be a multidimensional array
                throw new Error('The value cannot be a multi-dimensional array')
            }

            memberName = memberName as Array<string>;
        }

        memberName = getReadableDataForCustomField(memberName, nameField, member.id, 'member');

        return memberName;
    }

    getMemberSubtitle(memberId: string) {
        try {
            const member = this.props.membersData.byId[memberId];
            const memberType = this.props.membersData.types.byId[member.type];
            let memberSubtitle: VariableValueType;

            const subTitleField = this.props.membersData.types.customFields.byId[memberType.subTitleFieldId];

            if (subTitleField.isComputed && typeof subTitleField.startPiece !== 'undefined') {
                const processState: DefaultFlowchartProcessState = {
                    displayingContinuePieceId: undefined,
                    displayingAddWorkflowPieceId: undefined,
                    customFields: { ...member.customFields },
                    variables: {
                        [subTitleField.seedEntityVariable]: member.id,
                    },
                    lastComputedPiece: undefined,
                    executionStack: [],
                    forIterationCounts: {},
                    displayingQuestionPieceId: undefined,
                    displayingShowPieceId: undefined,
                    displayingGroupPieceId: undefined,
                    displayingTransferPieceId: undefined,
                    createdWorkflowId: undefined,
                    displayingFinsalLoanProcessPieceId: undefined,
                };

                memberSubtitle = getMemberComputedFieldValue(this.props.applicationState, processState, subTitleField.startPiece.piece, member.id, subTitleField);
            } else {
                memberSubtitle = member.customFields[memberType.nameFieldId];
            }

            if (Array.isArray(memberSubtitle)) {

                if (memberSubtitle.length > 0 && Array.isArray(memberSubtitle[0])) {
                    // Cannot be a multidimensional array
                    throw new Error('The value cannot be a multi-dimensional array')
                }

                memberSubtitle = memberSubtitle as Array<string>;
            }

            memberSubtitle = getReadableDataForCustomField(memberSubtitle, subTitleField, member.id, 'member');

            return memberSubtitle;
        } catch {
            return '-';
        }
    }

    getHeading = (workflowId: string | undefined) => {
        if (!workflowId) {
            throw new Error('Cannot get the heading of a workflow that does not exist');
        }

        const workflow = this.props.workflowData.byId[workflowId];
        const workflowTypesData = this.props.applicationState.workflows.types;
        const workflowType = this.props.workflowData.types.byId[workflow.type];

        let workflowAffiliatedName = translatePhrase('Not Applicable');


        if (workflowType.affiliation === 'member') {
            const member = this.props.membersData.byId[workflow.affiliatedEntity];
            let memberName: CustomFieldValueType = '';
            if(member){
                const memberType = this.props.membersData.types.byId[member.type];
                memberName = member.customFields[memberType.nameFieldId];;
                const nameField = this.props.membersData.types.customFields.byId[memberType.nameFieldId];
                memberName = getReadableDataForCustomField(memberName, nameField, member.id, 'member');
            }
            workflowAffiliatedName = translatePhrase(workflowType.name) + ' - ' + memberName;
        } else if (workflowType.affiliation === 'group') {
            const group = this.props.groupsData.byId[workflow.affiliatedEntity];
            let groupName: CustomFieldValueType;
            if(group){
                const groupType = this.props.groupsData.types.byId[group.type];
                groupName = group.customFields[groupType.nameFieldId];
                const nameField = this.props.groupsData.types.customFields.byId[groupType.nameFieldId];
                groupName = getReadableDataForCustomField(groupName, nameField, group.id, 'group');
            }
            workflowAffiliatedName = translatePhrase(workflowType.name) + ' - ' + groupName;
        } else if (workflowType.affiliation === 'none') {
            workflowAffiliatedName = translatePhrase(workflowType.name);
        }

        let details = '-';

        if (workflowType.subTitleFieldId) {
            const subTitleField = workflowTypesData.customFields.byId[workflowType.subTitleFieldId];
            const detailsValue = workflow.history[workflow.historyIndex].customFields[workflowType.subTitleFieldId];

            // Only allow fields that are not member-affiliated in group workflows
            if (Array.isArray(detailsValue) || typeof detailsValue !== 'object') {
                details = getReadableDataForCustomField(detailsValue, subTitleField, workflow.id, 'workflow')
            }
        }

        return {
            heading: workflowAffiliatedName,
            subTitle: details
        };


    }


    toggleSortTimeline = () => {
        this.setState(prevState => {
            return {
                sortedTimeline: !prevState.sortedTimeline,
            };
        });
    }

    toggleTimeline = () => {
        this.setState(prevState => {
            return {
                expandTimeline: !prevState.expandTimeline,
            };
        });
    }

    render() {
        const workflow = this.props.workflowData.byId[this.props.workflowId];
        const heading = this.getHeading(this.props.workflowId);
        const workflowStatus = this.props.workflowData.types.statuses.byId[workflow.status];

        return (<section className={styles.FocusSpace}>
            <header className={styles.pageHeader}>
                <h2 className={styles.heading}> {translatePhrase('Workflow')} / {translatePhrase('Executor')} </h2>

                <Tabs></Tabs>
            </header>

            <div className={styles.innerFocus}>
                <div className={styles.displayContents}>
                    <div className={styles.displayWindow}>
                        <header className={styles.flowHeader}>
                            <h2 className={styles.heading}> {heading.heading}
                                {heading.subTitle !== '-' && <div className={styles.status}>
                                    {heading.subTitle}
                                </div>}
                                <div className={styles.status}>
                                    {translatePhrase(workflowStatus.name)} <span className={styles.separator}> | </span> {workflow.dueDate ? moment(workflow.dueDate).format('DD MMM YYYY') : '-'}
                                </div>
                            </h2>
                        </header>

                        <ProgressBar isComplete={this.props.isCompleted} workflowId={this.props.workflowId} />

                        <div className={styles.goToButton}>
                            <Button type={'secondary'} text={'Go To Workflows'} onClick={this.props.goToWorkflows} padding={'0px 20px'} isRounded />
                        </div>
                    </div>
                </div>
                <Timeline
                    isSortActive={this.state.sortedTimeline}
                    shrinkTimeline={this.state.expandTimeline}
                    toggleShrinkTimeline={() => this.toggleTimeline()}
                    toggleSortTimeline={() => this.toggleSortTimeline()}
                    workflowId={this.props.workflowId} />
            </div>
        </section>);
    }
}

const WorkflowData = connect(mapStateToProps)(ConnectedWorkflowData);

export default WorkflowData;