import React, { Component, MouseEvent } from 'react';
import { Link } from "react-router-dom";
import styles from './Sidebar.module.scss';
import { NavLink } from "react-router-dom";
import { withRouter, RouteComponentProps } from "react-router";
import { ApplicationState } from '../../shared/store/types';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { startDataPush, setToastMessage, clearToastMessage, freezeBackground, unFreezeBackground, startOlderDataFetch, initiateFetchAppData, setShouldUpdateApplication, setLatestVersionNumber } from '../../shared/store/my-data/actions';
import { resetSession } from '../../shared/store/actions';
import { translatePhrase } from '../../shared/helpers/translation';
import { Permissions } from '../../shared/store/permissions/types';
import { getTimeoutPromise, isUUID } from '../../shared/helpers/utilities';
import { hasLocalChanges } from '../../shared/helpers/synchronize';
import { clearCurrentSnapshot, storeCurrentSnapshot } from '../../shared/store/database';
import moment from 'moment';
import { VERSION_NUMBER } from '../../shared/helpers/version';

// Icons import
import logo from '../../common/assets/logo.svg';
import { ReactComponent as DashboardIcon } from '../../common/assets/dashboard-line.svg';
import { ReactComponent as StructureIcon } from '../../common/assets/data-structured.svg';
import { ReactComponent as UsersIcon } from '../../common/assets/user-certification.svg';
import { ReactComponent as MembersIcon } from '../../common/assets/user-favorite-alt.svg';
import { ReactComponent as GroupsIcon } from '../../common/assets/group.svg';
import { ReactComponent as WorkflowIcon } from '../../common/assets/flow-data.svg';
import { ReactComponent as ReportsIcon } from '../../common/assets/report.svg';
import { ReactComponent as DataIcon } from '../../common/assets/data-base.svg';
import { ReactComponent as SaveIcon } from '../../common/assets/sync.svg';
import { ReactComponent as CheckedIcon } from '../../common/assets/check.svg';
import { ReactComponent as LanguageIcon } from '../../common/assets/translate.svg';
import { ReactComponent as ClipboardIcon } from '../../common/assets/ui-checks.svg';

import Button from '../../widgets/button/CommonButton';
import { BASE_URL } from '../../shared/store/url';
import axios, { AxiosError, AxiosResponse } from 'axios';
import ReportBug from './ReportBug';
import { AUTOSAVE_TO_DB } from '../../shared/store/my-data/types';
import { isPageExcluded } from '../OaaSApp';
import { SystemStatusService } from '../../shared/services/SystemStatusService';
import LoaderModal from '../../widgets/loader/LoaderModal';
import { clearSyncProcessMessage, generateSynclog, setSyncProcessMessage } from '../../shared/store/sync-logs/actions';
import { SyncMessage } from '../../shared/store/sync-logs/types';


type OwnProps = {
    logo?: string,
};

type OwnState = {
    isSyncing: boolean,
    lastSavedText: string,
    lastRefreshedText: string,
    hasData: boolean,
    isDataSavedLocally: boolean,
    saveTextUpdateIntervalId: number | undefined,
    highlightSyncPending: boolean,
    isMaintainanceModeOn: 'offline' | 'maintenance' | undefined,
    syncProcessMessage: string | undefined,
    showloader: boolean
}

const mapStateToProps = (state: ApplicationState, props: OwnProps & RouteComponentProps) => {
    const isOnline = state.myData.isOnline
    const lastRefreshTime = state.myData.lastRefreshTime
    const isEntityListPage = props.location.pathname === '/members/list' || props.location.pathname === '/groups/list' ||
        props.location.pathname === '/workflows/list';

    const canViewHierarchy = state.permissions.myPermissions.general.Hierarchy === Permissions.WRITE || state.permissions.myPermissions.general.Hierarchy === Permissions.READ;
    const canViewLocations = state.permissions.myPermissions.general.Locations === Permissions.WRITE || state.permissions.myPermissions.general.Locations === Permissions.READ;

    const canViewUsersList = state.permissions.myPermissions.general.Users === Permissions.WRITE || state.permissions.myPermissions.general.Users === Permissions.READ;
    const canViewUserConfiguration = state.permissions.myPermissions.general.UserConfiguration === Permissions.WRITE || state.permissions.myPermissions.general.UserConfiguration === Permissions.READ;

    const canViewMembersList = true;
    const canViewMembersConfiguration = state.permissions.myPermissions.general.MembersConfiguration === Permissions.WRITE || state.permissions.myPermissions.general.MembersConfiguration === Permissions.READ;

    const canViewGroupsList = true;
    const canViewGroupsConfiguration = state.permissions.myPermissions.general.GroupsConfiguration === Permissions.WRITE || state.permissions.myPermissions.general.GroupsConfiguration === Permissions.READ;

    const canViewWorkflowsList = true;
    const canViewWorkflowsConfiguration = state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.WRITE || state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.READ;

    const canViewReportsList = true;
    const canViewReportsConfiguration = state.permissions.myPermissions.general.ReportsConfiguration === Permissions.WRITE || state.permissions.myPermissions.general.ReportsConfiguration === Permissions.READ;

    const canViewStaticData = state.permissions.myPermissions.general.StaticInfoConfiguration === Permissions.WRITE || state.permissions.myPermissions.general.StaticInfoConfiguration === Permissions.READ;

    const hasMemberThreshold = state.organization.completedMemberThreshold !== null && state.organization.completedMemberThreshold !== Infinity && !isNaN(Number(state.organization.completedMemberThreshold));
    const hasGroupThreshold = state.organization.completedGroupThreshold !== null && state.organization.completedGroupThreshold !== Infinity && !isNaN(Number(state.organization.completedGroupThreshold));
    const hasWorkflowThreshold = state.organization.completedWorkflowThreshold !== null && state.organization.completedWorkflowThreshold !== Infinity && !isNaN(Number(state.organization.completedWorkflowThreshold));

    let hasThreshold = hasMemberThreshold || hasGroupThreshold || hasWorkflowThreshold;

    const thresholdData: Array<{
        roleName: string,
        memberThreshold: number | undefined,
        groupThreshold: number | undefined,
        workflowThreshold: number | undefined,
    }> = [];

    if (isUUID(state.myData.id)) {
        const loggedInUser = state.users.byId[state.myData.id];

        if (!hasThreshold) {
            hasThreshold = loggedInUser.roles.some(roleId => {
                const role = state.structure.roles.byId[roleId];
                const hasMemberThreshold = role.thresholdDaysForMembers !== null && !isNaN(Number(role.thresholdDaysForMembers));
                const hasGroupThreshold = role.thresholdDaysForGroups !== null && !isNaN(Number(role.thresholdDaysForGroups));
                const hasWorkflowThreshold = role.thresholdDaysForWorkflows !== null && !isNaN(Number(role.thresholdDaysForWorkflows));
                return hasMemberThreshold || hasGroupThreshold || hasWorkflowThreshold;
            })
        }

        loggedInUser.roles.forEach(roleId => {
            const role = state.structure.roles.byId[roleId];

            let memberThresholdDays: number | undefined;

            if (typeof role.thresholdDaysForMembers !== 'undefined' && role.thresholdDaysForMembers !== null && role.thresholdDaysForMembers !== Infinity) {
                memberThresholdDays = role.thresholdDaysForMembers;
            } else if (state.organization.completedMemberThreshold !== null && state.organization.completedMemberThreshold !== Infinity && !isNaN(Number(state.organization.completedMemberThreshold))) {
                memberThresholdDays = state.organization.completedMemberThreshold;
            }

            let groupThresholdDays: number | undefined;

            if (typeof role.thresholdDaysForGroups !== 'undefined' && role.thresholdDaysForGroups !== null && role.thresholdDaysForGroups !== Infinity) {
                groupThresholdDays = role.thresholdDaysForGroups;
            } else if (state.organization.completedGroupThreshold !== null && state.organization.completedGroupThreshold !== Infinity && !isNaN(Number(state.organization.completedGroupThreshold))) {
                groupThresholdDays = state.organization.completedGroupThreshold;
            }

            let workflowThresholdDays: number | undefined;

            if (typeof role.thresholdDaysForWorkflows !== 'undefined' && role.thresholdDaysForWorkflows !== null && role.thresholdDaysForWorkflows !== Infinity) {
                workflowThresholdDays = role.thresholdDaysForWorkflows;
            } else if (state.organization.completedWorkflowThreshold !== null && state.organization.completedWorkflowThreshold !== Infinity && !isNaN(Number(state.organization.completedWorkflowThreshold))) {
                workflowThresholdDays = state.organization.completedWorkflowThreshold;
            }

            if (typeof memberThresholdDays !== 'undefined' || typeof groupThresholdDays !== 'undefined' || typeof workflowThresholdDays !== 'undefined') {
                thresholdData.push({
                    roleName: translatePhrase(role.name),
                    memberThreshold: memberThresholdDays,
                    groupThreshold: groupThresholdDays,
                    workflowThreshold: workflowThresholdDays,
                });
            }
        });
    } else {
        if (!hasThreshold) {
            const hasMemberThreshold = state.organization.completedMemberThresholdForSuperUser !== null && !isNaN(Number(state.organization.completedMemberThresholdForSuperUser));
            const hasGroupThreshold = state.organization.completedGroupThresholdForSuperUser !== null && !isNaN(Number(state.organization.completedGroupThresholdForSuperUser));
            const hasWorkflowThreshold = state.organization.completedWorkflowThresholdForSuperUser !== null && !isNaN(Number(state.organization.completedWorkflowThresholdForSuperUser));

            hasThreshold = hasMemberThreshold || hasGroupThreshold || hasWorkflowThreshold;
        }

        let memberThresholdDays: number | undefined;

        if (typeof state.organization.completedMemberThresholdForSuperUser !== 'undefined' && !isNaN(state.organization.completedMemberThresholdForSuperUser)) {
            memberThresholdDays = state.organization.completedMemberThresholdForSuperUser;
        } else if (state.organization.completedMemberThreshold !== null && state.organization.completedMemberThreshold !== Infinity && !isNaN(Number(state.organization.completedMemberThreshold))) {
            memberThresholdDays = state.organization.completedMemberThreshold;
        }

        let groupThresholdDays: number | undefined;

        if (typeof state.organization.completedGroupThresholdForSuperUser !== 'undefined' && !isNaN(state.organization.completedGroupThresholdForSuperUser)) {
            groupThresholdDays = state.organization.completedGroupThresholdForSuperUser;
        } else if (state.organization.completedGroupThreshold !== null && state.organization.completedGroupThreshold !== Infinity && !isNaN(Number(state.organization.completedGroupThreshold))) {
            groupThresholdDays = state.organization.completedGroupThreshold;
        }

        let workflowThresholdDays: number | undefined;

        if (typeof state.organization.completedWorkflowThresholdForSuperUser !== 'undefined' && !isNaN(state.organization.completedWorkflowThresholdForSuperUser)) {
            workflowThresholdDays = state.organization.completedWorkflowThresholdForSuperUser;
        } else if (state.organization.completedWorkflowThreshold !== null && state.organization.completedWorkflowThreshold !== Infinity && !isNaN(Number(state.organization.completedWorkflowThreshold))) {
            workflowThresholdDays = state.organization.completedWorkflowThreshold;
        }

        if (typeof memberThresholdDays !== 'undefined' || typeof groupThresholdDays !== 'undefined' || typeof workflowThresholdDays !== 'undefined') {
            thresholdData.push({
                roleName: 'Super User',
                memberThreshold: memberThresholdDays,
                groupThreshold: groupThresholdDays,
                workflowThreshold: workflowThresholdDays,
            });
        }
    }

    return {
        structurePermissions: canViewHierarchy || canViewLocations,
        userPermissions: canViewUsersList || canViewUserConfiguration,
        memberPermissions: canViewMembersList || canViewMembersConfiguration,
        groupPermissions: canViewGroupsList || canViewGroupsConfiguration,
        workflowPermissions: canViewWorkflowsList || canViewWorkflowsConfiguration,
        reportPermissions: canViewReportsList || canViewReportsConfiguration,
        staticDataPermissions: canViewStaticData,
        myId: state.myData.id,
        myLanguage: isUUID(state.myData.id) ? state.users.byId[state.myData.id].language : '0',
        applicationState: state,
        logo: state.organization.image,
        isPushingData: state.myData.isPushingData,
        isFetchingData: !state.myData.isLoaded,
        isFetchingOlderData: state.myData.isFetchingOlderData,
        isOlderDataFetched: state.myData.isOlderDataFetched,
        lastSyncTime: state.myData.lastSyncTime,
        savedStates: state.savedStates,
        hasLocalChanges: hasLocalChanges(state),
        isLanguagesReadable: state.permissions.myPermissions.general.LanguagesConfiguration !== Permissions.NONE,
        hasThreshold,
        isThresholdDirty: state.organization.hasThresholdChanged,
        thresholdData,
        isSettled: state.myData.isLoaded && !state.myData.isPushingData,
        isOnline,
        lastRefreshTime,
        isEntityListPage,
        lastAction: state.myData.lastAction,
        syncProcessMessage: state.synclogState.syncProcessMessage
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        save: () => dispatch(startDataPush()),
        resetSession: () => dispatch(resetSession()),
        fetchAppDataFromServer: () => dispatch(initiateFetchAppData()),

        setToastMessage: (message: string, persistMessage: boolean) => dispatch(setToastMessage(message, persistMessage)),
        clearToastMessage: () => dispatch(clearToastMessage()),

        freezeBackground: () => dispatch(freezeBackground()),
        unFreezeBackground: () => dispatch(unFreezeBackground()),

        startOlderDataFetch: () => dispatch(startOlderDataFetch()),
        setShouldUpdateApplication: (shouldUpdateApplication: boolean) => dispatch(setShouldUpdateApplication(shouldUpdateApplication)),
        setLatestVersionNumber: (latestVersionNumber: string) => dispatch(setLatestVersionNumber(latestVersionNumber)),

        setSyncProcessMessage: (message: SyncMessage) => dispatch(setSyncProcessMessage(message)),
        clearSyncProcessMessage: () => dispatch(clearSyncProcessMessage()),
        generateSynclog: () => dispatch(generateSynclog())
    }
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps;

class ConnectedSidebar extends Component<Props, OwnState> {

    state: OwnState = {
        hasData: false,
        isDataSavedLocally: false,
        isSyncing: false,
        lastSavedText: 'Not Synced',
        lastRefreshedText: '-',
        saveTextUpdateIntervalId: undefined,
        highlightSyncPending: false,
        isMaintainanceModeOn: undefined,
        syncProcessMessage: undefined,
        showloader: false

    };

    updateLastSavedText = () => {
        if (this.props.lastSyncTime) {
            const hasData = hasLocalChanges(this.props.applicationState);
            const text = `Synced ${moment(this.props.lastSyncTime).fromNow()}`;

            this.setState({
                lastSavedText: text,
                hasData: hasData,
            });
        }
    }

    navigateUser = (url: string) => {

        if (url.includes('http')) {
            window.open(url, '_blank');
        } else {
            this.props.history.push(url);
        }
    }

    showErrorIfDataNotSynced = (e: BeforeUnloadEvent) => {
        if (this.state.isDataSavedLocally) {
            return;
        }

        const hasData = hasLocalChanges(this.props.applicationState);
        const isAutoSaved = this.props.lastAction === AUTOSAVE_TO_DB;

        if (hasData && !isAutoSaved) {
            e.preventDefault();
            e.returnValue = '';

        } else {
            const hasData = hasLocalChanges(this.props.applicationState);
            const isAutoSaved = this.props.lastAction === AUTOSAVE_TO_DB;

            if (hasData && !isAutoSaved) {
                e.preventDefault();
                e.returnValue = '';

            } else {
                delete e.returnValue;
            }
        }
    }

    componentDidMount() {
        const intervalId = window.setInterval(this.updateLastSavedText, 5000);
        this.setState({
            saveTextUpdateIntervalId: intervalId,
        });

        this.props.history.listen(listener => {
            this.setState({
                highlightSyncPending: true,
            });

            window.setTimeout(() => {
                this.setState({
                    highlightSyncPending: false,
                })
            }, 3000);
        })


        window.addEventListener('beforeunload', this.showErrorIfDataNotSynced);
    }

    componentWillUnmount() {
        window.clearInterval(this.state.saveTextUpdateIntervalId);
        this.setState({
            saveTextUpdateIntervalId: undefined,
        });
    }

    getServerVersion = async () => {
        const versionData: AxiosResponse<string> = await axios.get(BASE_URL + '/version/');

        if (versionData.status === 200) {
            const latestVersion = versionData.data;

            console.log('Server Version', latestVersion);
            console.log("Desktop Version", VERSION_NUMBER);

            if (latestVersion !== VERSION_NUMBER) {
                this.props.setShouldUpdateApplication(true);
                this.props.setLatestVersionNumber(latestVersion);
            }
        }
    }

    async componentDidUpdate(prevProps: Props) {
        if (this.state.isSyncing) {
            if (
                (prevProps.isFetchingData !== this.props.isFetchingData && !this.props.isFetchingData) ||
                (prevProps.isPushingData !== this.props.isPushingData && !this.props.isPushingData)
            ) {
                this.setState({
                    isSyncing: false,
                });
                this.updateLastSavedText();
                this.props.clearToastMessage();
                this.props.unFreezeBackground();
                this.getServerVersion();
            }
        }

        if (this.props.lastRefreshTime !== prevProps.lastRefreshTime) {
            this.setState({
                lastRefreshedText: 'Refreshed ' + moment(this.props.lastRefreshTime).fromNow(),
            })
        };

        if (this.props.syncProcessMessage === SyncMessage.DataSaved && this.state.syncProcessMessage !== SyncMessage.DataSaved) {
            this.setState({ syncProcessMessage: SyncMessage.DataSaved });
        };

        if (this.props.syncProcessMessage === SyncMessage.SyncComplete && this.state.syncProcessMessage === SyncMessage.DataSaved) {
            setTimeout(() => {
                this.props.clearSyncProcessMessage()
            }, 5000);
        };

        if (prevProps.location.pathname !== this.props.location.pathname && this.state.syncProcessMessage === SyncMessage.DataSaved) {
            this.setState({ syncProcessMessage: undefined });
        };

        if (prevProps.location.pathname !== this.props.location.pathname && this.state.isMaintainanceModeOn) {
            this.setState({ isMaintainanceModeOn: undefined })
        }

    }

    async checkForMaintainanceMode() {
        if (!window.navigator.onLine) {
            this.setState({ isMaintainanceModeOn: 'offline', showloader: true })
            return;
        }

        try {
            const maintenanceMode = await SystemStatusService.getMaintenanceMode();
            if (maintenanceMode) {
                this.setState({ isMaintainanceModeOn: 'maintenance', showloader: true });
                setTimeout(() => {
                    this.setState({ showloader: false })
                }, 3000)

            } else {
                this.setState({ isMaintainanceModeOn: undefined, showloader: false })
            }
        } catch (error) {
            this.setState({ isMaintainanceModeOn: 'offline', showloader: true })
        }

    };

    sync = async (e: MouseEvent) => {

        if (!this.props.isSettled) {
            return;
        }

        this.props.generateSynclog();

        await this.checkForMaintainanceMode()
        if (this.state.isMaintainanceModeOn) {
            return;
        };

        const refreshTarget = this.props.isOnline && this.props.isEntityListPage;

        if (
            (e.shiftKey && (e.ctrlKey || e.metaKey)) || refreshTarget
        ) {

            if (this.state.hasData && refreshTarget) {
                window.alert('Please sync before proceeding so you do not lose your local changes');
                return;
            }

            const confirmed = !this.state.hasData || window.confirm(translatePhrase('The changes you have made will not be saved'));

            if (confirmed) {
                let myId = this.props.myId;

                if (myId === 'SuperUser') {
                    const orgAdminData = localStorage.getItem('orgAdminIds')
                    if (orgAdminData) {
                        const orgAdminIds = JSON.parse(orgAdminData);
                        myId = orgAdminIds[this.props.applicationState.organization.code];
                    }
                }

                try {
                    await clearCurrentSnapshot(myId);
                } catch (e) {
                    console.warn('No snapshot to clear');
                }

                this.props.resetSession();
                this.props.fetchAppDataFromServer();
            }
            return;
        }

        this.setState({
            isSyncing: true,
        });

        let myId = this.props.myId;

        if (myId === 'SuperUser') {
            const orgAdminData = localStorage.getItem('orgAdminIds')
            if (orgAdminData) {
                const orgAdminIds = JSON.parse(orgAdminData);
                myId = orgAdminIds[this.props.applicationState.organization.code];
            }
        }

        setTimeout(async () => {
            if (myId === localStorage.getItem('myId')) {
                this.props.clearSyncProcessMessage();
                this.props.freezeBackground();
                this.props.setSyncProcessMessage(SyncMessage.SyncStarted);
                await getTimeoutPromise(500)
                this.props.save();
            } else {
                await storeCurrentSnapshot(this.props.applicationState, true);
                this.setState({
                    isDataSavedLocally: true,
                });
                window.location.assign('/login/');
            }
        }, 600);

    }

    startOlderDataFetch = async () => {

        await this.checkForMaintainanceMode()
        if (this.state.isMaintainanceModeOn) {
            return;
        };

        const confirmed = window.confirm(translatePhrase('This will fetch all the older data that has not been sent down to your app from the server. If your project has large volumes of data, say above 50000 records, then based on your system configuration this operation can take long time or slow down the application.'));

        if (confirmed) {
            setTimeout(() => this.props.startOlderDataFetch(), 100);
        }
    }

    render() {
        const syncPendingClass = this.state.highlightSyncPending ? styles.highlightedSyncPending : styles.syncPending;

        let thresholdString = translatePhrase('Data till') + ' ';

        if (this.props.thresholdData.length === 1) {
            if (this.props.thresholdData[0].memberThreshold === this.props.thresholdData[0].groupThreshold && this.props.thresholdData[0].memberThreshold === this.props.thresholdData[0].workflowThreshold) {
                thresholdString += moment().subtract(this.props.thresholdData[0].memberThreshold, 'days').format('DD MMM YYYY');
            } else {
                if (typeof this.props.thresholdData[0].memberThreshold === 'number') {
                    thresholdString += `${moment().subtract(this.props.thresholdData[0].memberThreshold, 'days').format('DD MMM YYYY')} (Members), `;
                }

                if (typeof this.props.thresholdData[0].groupThreshold === 'number') {
                    thresholdString += `${moment().subtract(this.props.thresholdData[0].groupThreshold, 'days').format('DD MMM YYYY')} (Groups), `;
                }

                if (typeof this.props.thresholdData[0].workflowThreshold === 'number') {
                    thresholdString += `${moment().subtract(this.props.thresholdData[0].workflowThreshold, 'days').format('DD MMM YYYY')} (Workflows)`;
                }
            }
        } else {
            const roleThresholdStrings = this.props.thresholdData.map(thresholdDatum => {
                if (typeof thresholdDatum.memberThreshold === 'number' && thresholdDatum.memberThreshold === thresholdDatum.groupThreshold && thresholdDatum.memberThreshold === thresholdDatum.workflowThreshold) {
                    return `${moment().subtract(thresholdDatum.memberThreshold, 'days').format('DD MMM YYYY')} for ${thresholdDatum.roleName}`;
                } else {
                    let thresholdString = '';
                    if (typeof thresholdDatum.memberThreshold === 'number') {
                        thresholdString += `${moment().subtract(thresholdDatum.memberThreshold, 'days').format('DD MMM YYYY')} for ${thresholdDatum.roleName} (Members), `;
                    }

                    if (typeof thresholdDatum.groupThreshold === 'number') {
                        thresholdString += `${moment().subtract(thresholdDatum.groupThreshold, 'days').format('DD MMM YYYY')} for ${thresholdDatum.roleName} (Groups), `;
                    }

                    if (typeof thresholdDatum.workflowThreshold === 'number') {
                        thresholdString += `${moment().subtract(thresholdDatum.workflowThreshold, 'days').format('DD MMM YYYY')} for ${thresholdDatum.roleName} (Workflows)`;
                    }

                    return thresholdString;
                }
            });

            thresholdString += roleThresholdStrings.join(', ');
        }

        let syncButtonText = translatePhrase('Sync');

        if (this.props.isOnline && this.props.isEntityListPage) {
            syncButtonText = translatePhrase('Refresh');
        };

        const isExcluded = isPageExcluded();

        if (isExcluded) {
            return <div></div>;
        };

        return (
            <div>
                <section className={styles.SideBar}
                    id="navigation-side-menu">

                    <Link to="/structure" className={styles.logoHolder}>
                        <picture className={styles.Logo}>
                            <img src={this.props.logo ? this.props.logo : logo} alt="DICEflow logo" />
                        </picture>
                    </Link>

                    {this.props.syncProcessMessage === SyncMessage.SyncComplete ? <button className={styles.SyncCompleteButton}>
                        <span className={styles.syncDataSavedMessage}>{translatePhrase('Success')}</span>
                        <CheckedIcon />
                    </button> :
                        <button disabled={(!!this.state.isMaintainanceModeOn) || !this.props.isSettled} className={styles.SyncButton + ' ' + (this.state.highlightSyncPending && this.props.hasLocalChanges ? styles.flashButton : '') + ' ' + (this.state.isMaintainanceModeOn ? styles.isDisabled : '')} onClick={this.sync} >
                            <span> {this.state.isMaintainanceModeOn === 'maintenance' ? translatePhrase('Under Maintenance') : this.state.isMaintainanceModeOn === 'offline' ? translatePhrase('Offline') : syncButtonText} </span>
                            {this.props.hasLocalChanges && <div className={syncPendingClass}>!</div>}
                            <SaveIcon />
                        </button>
                    }

                    {!this.state.syncProcessMessage && this.props.isOnline && this.props.isEntityListPage && <p className={styles.lastSyncClass}> {this.state.lastRefreshedText} </p>}

                    {!this.state.syncProcessMessage && (!this.props.isOnline || !this.props.isEntityListPage) && <p className={styles.lastSyncClass}> {this.state.lastSavedText} </p>}

                    {this.state.syncProcessMessage && <p className={styles.dataSavedSyncClass}> {translatePhrase(SyncMessage.DataSaved)} <CheckedIcon /> </p>}

                    <nav className={styles.NavLinksHolder}>
                        <NavLink data-selector="dashboard-nav-button" to={this.props.savedStates.previousRoute.dashboard} className={styles.NavigationLink} activeClassName={styles.active}>
                            <DashboardIcon />
                            <span>{translatePhrase('Dashboard')}</span>
                        </NavLink>
                        {this.props.structurePermissions && <NavLink data-selector="structure-nav-button" to={this.props.savedStates.previousRoute.structure} className={styles.NavigationLink} activeClassName={styles.active}>
                            <StructureIcon />
                            <span>{translatePhrase('Structure')}</span>
                        </NavLink>}
                        {this.props.userPermissions && <NavLink data-selector="users-nav-button" to={this.props.savedStates.previousRoute.users} className={styles.NavigationLink} activeClassName={styles.active}>
                            <UsersIcon />
                            <span>{translatePhrase('Users')}</span>
                        </NavLink>}
                        {this.props.memberPermissions && <NavLink data-selector="members-side-nav-button" to={this.props.savedStates.previousRoute.members} className={styles.NavigationLink} activeClassName={styles.active}>
                            <MembersIcon />
                            <span>{translatePhrase('Members')}</span>
                        </NavLink>}
                        {this.props.groupPermissions && <NavLink data-selector="groups-side-nav-button" to={this.props.savedStates.previousRoute.groups} className={styles.NavigationLink} activeClassName={styles.active}>
                            <GroupsIcon />
                            <span>{translatePhrase('Groups')}</span>
                        </NavLink>}
                        {this.props.workflowPermissions && <NavLink data-selector="workflows-side-nav-button" to={this.props.savedStates.previousRoute.workflows} className={styles.NavigationLink} activeClassName={styles.active}>
                            <WorkflowIcon />
                            <span>{translatePhrase('Workflows')}</span>
                        </NavLink>}
                        {this.props.reportPermissions && <NavLink data-selector="reports-side-nav-button" to={this.props.savedStates.previousRoute.reports} className={styles.NavigationLink} activeClassName={styles.active}>
                            <ReportsIcon />
                            <span>{translatePhrase('Reports')}</span>
                        </NavLink>}
                        {this.props.staticDataPermissions && <NavLink data-selector="data-side-nav-button" to={this.props.savedStates.previousRoute.data} className={styles.NavigationLink} activeClassName={styles.active}>
                            <DataIcon />
                            <span>{translatePhrase('Data')}</span>
                        </NavLink>}
                        {this.props.isLanguagesReadable && <NavLink data-selector="languages-side-nav-button" to={this.props.savedStates.previousRoute.languages} className={styles.NavigationLink} activeClassName={styles.active}>
                            <LanguageIcon />
                            <span>{translatePhrase('Languages')}</span>
                        </NavLink>}
                    </nav>

                    {this.state.isMaintainanceModeOn && this.state.showloader && <LoaderModal
                        isInfo
                        forceShowClose
                        loaderText={[translatePhrase(this.state.isMaintainanceModeOn === 'maintenance' ? "Under Maintenance" : "Please check your internet")]}
                    />}

                    {!this.props.isOnline && !this.props.isOlderDataFetched && this.props.hasThreshold && !this.props.isThresholdDirty && <div className={styles.previousDaysText}>{thresholdString}</div>}
                    {!this.props.isOnline && !this.props.isOlderDataFetched && this.props.hasThreshold && !this.props.isThresholdDirty &&
                        <button disabled={!!(this.state.isMaintainanceModeOn)} className={styles.fetchOlderDataButton + ' ' + (this.props.isFetchingOlderData ? styles.syncActive : '') + ' ' + (this.state.isMaintainanceModeOn ? styles.isDisabled : '')} onClick={this.startOlderDataFetch}>
                            <span>{translatePhrase('Fetch remaining data')}</span>
                            <SaveIcon />
                        </button>}

                    <ReportBug isMaintainanceModeOn={!!(this.state.isMaintainanceModeOn)} checkForMaintenanceMode={this.checkForMaintainanceMode.bind(this)} />

                    <a href="https://sites.google.com/view/diceflow-kb/release-notes" target="_blank" rel="noreferrer noopener" className={styles.help}>
                        <Button title={translatePhrase('Release notes')} icon={<ClipboardIcon />} type="secondary" size="small" isBlock={false} isRounded />
                        <p className={styles.version} title={translatePhrase('Version')}>V {VERSION_NUMBER}</p>
                    </a>
                </section>
            </div >
        )
    };
}

const Sidebar = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectedSidebar) as any);

export default Sidebar;
