import React from 'react';
import styles from './Structure.module.scss';
import { Switch, Route, Redirect } from "react-router-dom";

import { ApplicationState } from '../../shared/store/types';

import { Permissions } from '../../shared/store/permissions/types';

import Hierarchy from './hierarchy/Hierarchy';
import Locations from './locations/Locations';
import PermissionsPage from './permissions/Permissions';

import { connect } from 'react-redux';

import { ReactComponent as StructureIcon } from '../../common/assets/data-structured.svg';
import Header from '../../widgets/header/Header';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canViewHierarchy = state.permissions.myPermissions.general.Hierarchy === Permissions.WRITE || state.permissions.myPermissions.general.Hierarchy === Permissions.READ;
    const canViewLocations = state.permissions.myPermissions.general.Locations === Permissions.WRITE || state.permissions.myPermissions.general.Locations === Permissions.READ;
    
    return {
        canGoToHierarchy: canViewHierarchy,
        canGoToLocations: canViewLocations,
        myId: state.myData.id,
        isLoaded: state.myData.isLoaded,
        isPartiallyLoaded: state.myData.isPartiallyLoaded,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;

const ConnectedStructure: React.FC<Props> = (props) => {
    const { canGoToHierarchy, canGoToLocations } = props;
    if (!(canGoToHierarchy || canGoToLocations)) {
        return <Redirect to="/dashboard" />;
    }
    const tabs = [
        {
            tabName: "Hierarchy",
            link: "/structure/hierarchy",
            hasAccess: canGoToHierarchy
        },
        {
            tabName: "Locations",
            link: "/structure/locations",
            hasAccess: canGoToLocations
        },
        {
            tabName: "Permissions",
            link: "/structure/permissions",
            hasAccess: canGoToHierarchy
        }
    ]
    return (
        <section className={styles.FocusSpace}>
            <Header 
                pageName={"structure"}
                tabs={tabs}
                icon={<StructureIcon />}
            />
            <div className={styles.innerSpace}>
                <Switch>
                    <Route path="/structure/hierarchy" render={() => <Hierarchy />} />
                    <Route path="/structure/locations" render={() => <Locations />} />
                    <Route path="/structure/permissions" render={() => <PermissionsPage />} />
                    <Route render={() => <Redirect to={props.canGoToHierarchy ? '/structure/hierarchy' : '/structure/locations'} />}/>
                </Switch>
            </div>            
        </section>
    );
};

const Structure = connect(mapStateToProps)(ConnectedStructure);

export default Structure;