import { createSelector } from 'reselect';
import { ApplicationState } from '../../shared/store/types';
import { IGroup } from '../../shared/store/groups/types';
import { translatePhrase } from '../../shared/helpers/translation';
import { Permissions } from '../../shared/store/permissions/types';
import { IMember } from '../../shared/store/members/types';

const getWorkflowTypesData = (state: ApplicationState) => state.workflows.types;
const getPermissions = (state: ApplicationState) => state.permissions;
const getWorkflow = (state: ApplicationState) => state.workflows;

export const getAffiliatedWorkflowsListForGroups = (
    group: IGroup
) => createSelector([
    getWorkflowTypesData, getPermissions, getWorkflow], (
        workflowTypeData, permissions, workflows) => {
    const tooltipEntries = workflowTypeData.allEntries
        .filter(workflowTypeId => {
            const workflowType = workflowTypeData.byId[workflowTypeId];
            const nonTerminalStatuses = workflowType.statuses.filter(statusId => !workflowTypeData.statuses.byId[statusId].isTerminal);

            if (permissions.myPermissions.workflows[workflowType.id]) {
                if (permissions.myPermissions.workflows[workflowType.id] !== Permissions.WRITE) {
                    return false;
                }
            }

            if (nonTerminalStatuses.length === 0) {
                return false;
            }

            if (workflowType.affiliation !== 'group') {
                return false;
            }

            if (workflowType.affiliatedEntity && workflowType.affiliatedEntity !== group.type) {
                return false;
            }

            if (!workflowType.areMultipleInstancesAllowed) {
                const otherWorkflowsOfSameType = workflowType.workflows.filter(workflowId => {
                    const workflow = workflowTypeData.byId[workflowId];

                    return workflow && !workflow.archived && workflow.affiliatedEntity === group.id;
                });

                const openWorkflowsOfSameType = otherWorkflowsOfSameType.filter(workflowId => {
                    const workflow = workflows.byId[workflowId];

                    return nonTerminalStatuses.includes(workflow.status);
                });

                if (openWorkflowsOfSameType.length > 0) {
                    return false;
                }
            }

            return true;
        })
        .map((workflowTypeId, index) => {
            const workflowType = workflowTypeData.byId[workflowTypeId];
            return {
                id: index,
                name: translatePhrase(workflowType.name),
                value: workflowType.id
            }
        });
    return tooltipEntries;

})

export const getAffiliatedWorkflowsListForMembers = (
    member: IMember
) => createSelector([
    getWorkflowTypesData, getPermissions, getWorkflow], (
        workflowTypeData, permissions, workflows) => {
    const tooltipEntries = workflowTypeData.allEntries
        .filter(workflowTypeId => {
            const workflowType = workflowTypeData.byId[workflowTypeId];
            const nonTerminalStatuses = workflowType.statuses.filter(statusId => !workflowTypeData.statuses.byId[statusId].isTerminal);
            if (permissions.myPermissions.workflows[workflowType.id]) {
                if (permissions.myPermissions.workflows[workflowType.id] !== Permissions.WRITE) {
                    return false;
                }
            }

            if (nonTerminalStatuses.length === 0) {
                return false;
            }

            if (workflowType.affiliation !== 'member') {
                return false;
            }


            if (member && workflowType.affiliatedEntity && workflowType.affiliatedEntity !== member.type) {
                return false;
            }

            if (!workflowType.areMultipleInstancesAllowed) {

                const otherWorkflowsOfSameType = workflowType.workflows.filter(workflowId => {
                    const workflow = workflowTypeData.byId[workflowId];

                    return workflow && !workflow.archived && workflow.affiliatedEntity === member.id;
                });
                const openWorkflowsOfSameType = otherWorkflowsOfSameType.filter(workflowId => {
                    const workflow = workflows.byId[workflowId];

                    return nonTerminalStatuses.includes(workflow.status);
                });

                if (openWorkflowsOfSameType.length > 0) {
                    return false;
                }
            }
            return true;
        })
        .map((workflowTypeId, index) => {
            const workflowType = workflowTypeData.byId[workflowTypeId];
            return {
                id: index,
                name: translatePhrase(workflowType.name),
                value: workflowType.id
            }
        });
    return tooltipEntries;
})

interface AffiliationEntityMember {
    type: 'member';
    entity: IMember
}


interface AffiliationEntityGroup {
    type: 'group';
    entity: IGroup
}

export type AffiliationEntity = AffiliationEntityMember | AffiliationEntityGroup;

export const getAffiliatedWorkflowsList = (affiliationEntity: AffiliationEntity, state: ApplicationState) => {
    let selectorsResult = [];
    if (affiliationEntity.type === 'group') {
        selectorsResult = getAffiliatedWorkflowsListForGroups(affiliationEntity.entity)(state);
    } else {
        selectorsResult = getAffiliatedWorkflowsListForMembers(affiliationEntity.entity)(state);
    }
    return selectorsResult;
}