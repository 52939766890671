import React, { Component } from 'react';
import styles from './step-piece/StepPiece.module.scss';
import Input from '../Input';
import StepPiece from './step-piece/StepPiece'

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';
import { Option } from '../drop-down/ListItem';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setVariableForList, setPieceForList } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import { valuePieceSlotTarget } from './utilities';
import DropDownSearchBox, { getFilteredOptionsBySearch } from '../drop-down/DropDownSearchBox';


type AddToTablePieceProps = {
    nextPiece?: JSX.Element,
    variables: Array<Option>,
    listVariableName?: string,

    variablePiece?: JSX.Element,
    dataToAdd?: string,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
        setPieceForList: (targetPieceId: string, draggedPieceId: string) => dispatch(setPieceForList(targetPieceId, draggedPieceId)),
        setVariableForList: (pieceId: string, value: string) => dispatch(setVariableForList(pieceId, value)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = AddToTablePieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type AddToTablePieceState = {
    isHoveringOverVariablePiece: boolean,
    searchTerm: string;
}

class ConnectedAddToTablePiece extends Component<Props, AddToTablePieceState> {

    state = {
        isHoveringOverVariablePiece: false,
        searchTerm: "",
    };

    handleHoverOverVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: false,
        });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && this.state.isHoveringOverVariablePiece) {

            this.props.setPieceForList(this.props.pieceId, this.props.lastDraggedPiece.id);
            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverVariablePiece: false,
            });
        }
    }

    searchForVariable = (searchTerm: string) => {
        this.setState({ searchTerm: searchTerm });
    }

    render() {
        const filteredVariables = getFilteredOptionsBySearch(this.props.variables, this.state.searchTerm);
        const variableSelectBox = <SelectBox theme="indigo" selectionPromptText={this.props.listVariableName}>
            <DropDownList theme="indigo" dismissAfterSelection={false}>
                <DropDownSearchBox
                    handleSearchInputChange={this.searchForVariable}
                    placeholder={"Search by name"}
                    searchTerm={this.state.searchTerm}
                />
                {filteredVariables.map((variable, index) => <ListItem name={variable.name} value={variable.value} key={index} theme="indigo" onClick={this.props.setVariableForList.bind(this, this.props.pieceId)} />)}
            </DropDownList>
        </SelectBox>;
    
        return (
            <StepPiece theme="indigo" {...this.props}>
                <div className={styles.text}>add</div>
                {this.props.variablePiece ? this.props.variablePiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverVariablePiece && !!this.props.targetPiece} onMouseOver={this.handleHoverOverVariablePiece} onMouseOut={this.handleHoverOutOfVariablePiece} isDisabled />}
                <div className={styles.text}>to</div>
                {variableSelectBox}
            </StepPiece>
        )
        
    }
}

const AddToTablePiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedAddToTablePiece)

export default AddToTablePiece;