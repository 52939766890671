import React, { Component, ChangeEvent, KeyboardEvent, createRef, RefObject } from 'react';
import styles from './Question.module.scss';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/types';

import { updateStatus, addToHistory, addWorkflow } from '../../../shared/store/workflows/actions';
import { WorkflowProcessState, IUpdateableWorkflowData, IWorkflow } from '../../../shared/store/workflows/types';

import { getWorkflowPieceValue } from '../../../shared/store/flowchart/helpers/workflow';
import { PieceType } from '../../../shared/store/flowchart/pieces/types';
import { getFileExtension, getFormatedFileSize, getOnlineFileSize, isFileFromCamera, updateLinkToCurrentOrigin } from '../../../shared/helpers/file-utilities';
import { isUUID } from '../../../shared/helpers/utilities';
import { getWorkflowQuestionValidationValue } from '../../../shared/store/flowchart/helpers/question';
import InputText from '../../../widgets/form/InputText';
import mapIcon from '../../../assets/new-custom-icons/common/pin.svg';
import { translatePhrase } from '../../../shared/helpers/translation';
// import { getIconComponentFromName } from '../../../helpers/in-app-icons';
import { COUNTRY_CODES } from '../../../shared/helpers/country-codes';
import { RadioButton } from './RadioButton';
import DateInput from '../../../widgets/form/DateInput';

import { ReactComponent as RadioIcon } from '../../../common/assets/radio.svg';
import { ReactComponent as Cancel } from '../../../assets/cancel.svg';
import { ReactComponent as CheckIcon } from '../../../common/assets/checked.svg';
import { ReactComponent as AttachIcon } from '../../../common/assets/attach.svg';
import { ReactComponent as PhoneIcon } from '../../../common/assets/phone.svg';
import { ReactComponent as MapIcon } from '../../../assets/map-pin.svg';
import { ReactComponent as EditIcon } from '../../../common/assets/edit.svg';
import { ReactComponent as CalendarIcon } from '../../../common/assets/calendar.svg';
import { CustomFieldValueType, FieldType } from '../../../shared/store/custom-fields/types';
import Button from '../../../widgets/button/CommonButton';
import { clearErrorMessage, setErrorMessage } from '../../../shared/store/my-data/actions';
import SignatureWidget from '../../../widgets/signature/Signature';
import { deleteFilesInIDB, saveFilesToIDB } from '../../../shared/store/actions';
import { getStoredFileInDB } from '../../../shared/store/file-operations';
import { saveAs } from 'file-saver';
import TextDecorator from './TextDecorator';

export type QuestionProps = {
    workflowId: string,
    questionId: string,
    userInput: CustomFieldValueType,
    errorMessage?: string,
    overWrittenVariable?: string,
    overWrittenValue?: string,

    isDisabled?: boolean,
    isExpanded?: boolean,

    isRequired?: boolean,
    isSectioned?: boolean,

    validateAnswer: (questionId: string, answer: CustomFieldValueType, processState: WorkflowProcessState) => Promise<string>,
    onInputChange: (value: CustomFieldValueType) => void,
    onExpandToggle: () => void,
};

const mapStateToProps = (state: ApplicationState, ownProps: QuestionProps) => {
    const questionPiece = state.flowchart.pieces.byId[ownProps.questionId];
    const workflow = state.workflows.byId[ownProps.workflowId];

    if (questionPiece.type !== PieceType.QUESTION && questionPiece.type !== PieceType.GROUPED_QUESTION) {
        throw new Error('The ID should point to a piece of the question type');
    }

    if (!questionPiece.customFieldId) {
        throw new Error('The question must be attached to a valid custom field');
    }

    const customField = state.workflows.types.customFields.byId[questionPiece.customFieldId];

    let lastStoredInput: CustomFieldValueType;

    if (state.workflows.screenInputs[workflow.id] && workflow.historyIndex < workflow.history.length - 1) {
        const workflowScreenInputs = state.workflows.screenInputs[workflow.id].find(screenInputs => workflow.historyIndex === screenInputs.workflowIndex);

        if (!!workflowScreenInputs) {
            if (ownProps.overWrittenValue) {
                lastStoredInput = workflowScreenInputs.groupedAnswers[ownProps.overWrittenValue][questionPiece.customFieldId];
            } else {
                lastStoredInput = workflowScreenInputs.answers[questionPiece.customFieldId];
            }
        }
    }

    return {
        applicationState: state,
        piecesData: state.flowchart.pieces,
        workflowData: state.workflows,
        membersData: state.members,
        questionPiece: questionPiece,
        customField,
        lastStoredInput,
        fileSizeLimitInMB: state.organization.fileSizeLimitInMB,
        workflow,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateStatus: (workflowId: string, statusId: string) => dispatch(updateStatus(workflowId, statusId)),
        addToHistory: (processState: WorkflowProcessState, workflowId: string, userId: string) => dispatch(addToHistory(processState, workflowId, userId)),
        addWorkflow: (payload: IUpdateableWorkflowData) => dispatch(addWorkflow(payload)),
        setErrorMessage: (message: string) => dispatch(setErrorMessage(message)),
        clearErrorMessage: () => dispatch(clearErrorMessage()),
        saveFilesToIDB: (id: string, file: File) => dispatch(saveFilesToIDB(id, file)),
        deleteFilesInIDB: (id: string) => dispatch(deleteFilesInIDB(id)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = QuestionProps & StateProps & DispatchProps;

type OwnState = {
    questionText: string,
    allowedChoices: Array<string>,
    isDisabled: boolean,
    isRequired: boolean,
    locationKey: number,
    optionsSearchText: string,
    fileName: string | undefined,
    token: string,
    fileSize: number | undefined;
}

class ConnectedQuestion extends Component<Props, OwnState> {

    input: RefObject<HTMLInputElement> = createRef();

    constructor(props: Readonly<Props>) {
        super(props);
        const workflow = props.workflowData.byId[props.workflowId];
        let processState = this.getWorkflowProcessState(workflow);

        let expandedQuestion = props.questionPiece.question && isUUID(props.questionPiece.question) ? getWorkflowPieceValue(props.applicationState, processState, workflow.id, props.questionPiece.question) : props.questionPiece.question;

        if (typeof props.userInput === 'undefined') {

            if (typeof props.lastStoredInput !== 'undefined') {
                props.onInputChange(props.lastStoredInput);
            } else {
                let defaultValue = props.questionPiece.default && isUUID(props.questionPiece.default) ? getWorkflowPieceValue(props.applicationState, processState, workflow.id, props.questionPiece.default) : props.questionPiece.default;

                if (Array.isArray(defaultValue)) {

                    if (defaultValue.length > 0 && Array.isArray(defaultValue[0])) {
                        // Cannot be a multidimensional array
                        throw new Error('The value cannot be a multi-dimensional array')
                    }

                    defaultValue = defaultValue as Array<string>;
                }

                if (typeof defaultValue !== 'undefined') {
                    if (props.customField.type === FieldType.SINGLE_SELECT) {
                        defaultValue = props.customField.choices.find(optionId => {
                            const option = props.workflowData.types.customFieldOptions.byId[optionId];

                            return option.name === defaultValue;
                        });
                    } else if (props.customField.type === FieldType.MULTI_SELECT) {
                        defaultValue = props.customField.choices.filter(optionId => {
                            const option = props.workflowData.types.customFieldOptions.byId[optionId];

                            if (!Array.isArray(defaultValue)) {
                                throw new Error('The default value must be an array of strings');
                            }

                            if (Array.isArray(defaultValue)) {

                                if (defaultValue.length > 0 && Array.isArray(defaultValue[0])) {
                                    // Cannot be a multidimensional array
                                    throw new Error('The value cannot be a multi-dimensional array')
                                }

                                defaultValue = defaultValue as Array<string>;
                            }

                            return defaultValue.includes(option.name);
                        });
                    } else if (props.customField.type === FieldType.BOOLEAN) {
                        defaultValue = defaultValue ? 'Yes' : 'No';
                    }

                    if (!Array.isArray(defaultValue) && typeof defaultValue === 'object') {
                        throw new Error('The value cannot be a table cell');
                    }

                    props.onInputChange(defaultValue);

                }
            }
        }

        if (expandedQuestion === '' || typeof expandedQuestion === 'undefined') {
            expandedQuestion = props.customField.name;
        }

        if (typeof expandedQuestion !== 'string') {
            throw new Error('A question value needs to be a string');
        }

        if (expandedQuestion === '') {
            expandedQuestion = props.customField.name;
        }

        let isDisabled: boolean,
            isRequired: boolean,
            answer = props.userInput;

        if (props.customField.type === FieldType.SINGLE_SELECT && typeof answer === 'string' && !!answer) {
            answer = props.workflowData.types.customFieldOptions.byId[answer].name;
        } else if (props.customField.type === FieldType.MULTI_SELECT && Array.isArray(answer)) {
            answer = answer.map(optionId => props.workflowData.types.customFieldOptions.byId[optionId].name);
        } else if (props.customField.type === FieldType.NUMBER && typeof answer !== 'undefined' && !isNaN(Number(answer))) {
            answer = Number(answer);
        } else if (props.customField.type === FieldType.BOOLEAN) {
            answer = answer === 'Yes';
        }

        if (typeof props.isDisabled === 'undefined') {
            const disabledWorkflowProcessState: WorkflowProcessState = this.getWorkflowProcessState(workflow);
            isDisabled = props.questionPiece.isDisabledPiece ? !!getWorkflowQuestionValidationValue(props.applicationState, disabledWorkflowProcessState, workflow.id, props.questionId, answer, {}, props.questionPiece.isDisabledPiece) : false;
        } else {
            isDisabled = props.isDisabled;
        }

        if (typeof props.isRequired === 'undefined') {
            const requiredWorkflowProcessState: WorkflowProcessState = this.getWorkflowProcessState(workflow);
            isRequired = props.questionPiece.isRequiredPiece ? !!getWorkflowQuestionValidationValue(props.applicationState, requiredWorkflowProcessState, workflow.id, props.questionId, answer, {}, props.questionPiece.isRequiredPiece) : false;
        } else {
            isRequired = props.isRequired;
        }

        this.state = {
            questionText: expandedQuestion,
            allowedChoices: [],
            isDisabled,
            isRequired,
            optionsSearchText: '',
            locationKey: 0,
            fileName: '',
            token: localStorage.getItem('token') || '',
            fileSize: undefined,
        };
    }

    validateAllowedChoice = async () => {
        const workflow = this.props.workflowData.byId[this.props.workflowId];
        let allowedChoices: Array<string> = this.props.customField.choices;
        let processState = this.getWorkflowProcessState(workflow);

        if (this.props.questionPiece.innerPiece) {
            if (this.props.customField.type === FieldType.SINGLE_SELECT) {
                const filteredChoices: Array<string> = [];

                for (const optionId of allowedChoices) {
                    const errorMessage = await this.props.validateAnswer(this.props.questionId, this.props.workflowData.types.customFieldOptions.byId[optionId].name, processState);

                    if (!errorMessage) {
                        filteredChoices.push(optionId);
                    }
                }

                allowedChoices = filteredChoices;
            } else if (this.props.customField.type === FieldType.MULTI_SELECT) {
                const filteredChoices: Array<string> = [];

                for (const optionId of allowedChoices) {
                    const errorMessage = await this.props.validateAnswer(this.props.questionId, [this.props.workflowData.types.customFieldOptions.byId[optionId].name], processState);

                    if (!errorMessage) {
                        filteredChoices.push(optionId);
                    }
                }

                allowedChoices = filteredChoices;
            }
        }

        this.setState({ allowedChoices: allowedChoices });
    }

    toggleShowMore = () => {
        this.props.onExpandToggle();
    }

    getCoOrdinates = () => {
        const geolocation = navigator.geolocation;

        geolocation.getCurrentPosition((position) => {
            this.handleLocationChange(`${position.coords.latitude} ${position.coords.longitude}`);
            this.setState(prevState => {
                return {
                    locationKey: prevState.locationKey + 1,
                }
            })
        });
    }

    static getDerivedStateFromProps(props: Readonly<Props>, state: Readonly<OwnState>) {
        if (props.isDisabled !== state.isDisabled || props.isRequired !== state.isRequired) {
            const workflow = props.workflowData.byId[props.workflowId];

            let isDisabled: boolean, isRequired: boolean, answer = props.userInput;

            if (props.customField.type === FieldType.SINGLE_SELECT && typeof answer === 'string' && !!answer) {
                answer = props.workflowData.types.customFieldOptions.byId[answer].name;
            } else if (props.customField.type === FieldType.MULTI_SELECT && Array.isArray(answer)) {
                answer = answer.map(optionId => props.workflowData.types.customFieldOptions.byId[optionId].name);
            } else if (props.customField.type === FieldType.NUMBER && typeof answer !== 'undefined' && !isNaN(Number(answer))) {
                answer = Number(answer);
            } else if (props.customField.type === FieldType.BOOLEAN) {
                answer = answer === 'Yes';
            }

            if (typeof props.isDisabled === 'undefined') {
                let processState: WorkflowProcessState = JSON.parse(JSON.stringify({
                    customFields: workflow.history[workflow.historyIndex].customFields,
                    lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
                    executionStack: workflow.history[workflow.historyIndex].executionStack,
                    forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
                    variables: workflow.history[workflow.historyIndex].variables,
                    displayingQuestionPieceId: workflow.history[workflow.historyIndex].displayingQuestionPieceId,
                    displayingShowPieceId: workflow.history[workflow.historyIndex].displayingShowPieceId,
                    displayingGroupPieceId: workflow.history[workflow.historyIndex].displayingGroupPieceId,
                    displayingTransferPieceId: workflow.history[workflow.historyIndex].displayingTransferPieceId,
                    displayingContinuePieceId: workflow.history[workflow.historyIndex].displayingContinuePieceId,
                    displayingAddWorkflowPieceId: workflow.history[workflow.historyIndex].displayingAddWorkflowPieceId,
                    createdWorkflowId: workflow.history[workflow.historyIndex].createdWorkflowId,
                }));

                if (props.overWrittenVariable) {
                    processState.variables[props.overWrittenVariable] = props.overWrittenValue;
                }

                isDisabled = props.questionPiece.isDisabledPiece ? !!getWorkflowQuestionValidationValue(props.applicationState, processState, workflow.id, props.questionId, answer, {}, props.questionPiece.isDisabledPiece) : false;
            } else {
                isDisabled = props.isDisabled;
            }

            if (typeof props.isRequired === 'undefined') {
                let processState: WorkflowProcessState = JSON.parse(JSON.stringify({
                    customFields: workflow.history[workflow.historyIndex].customFields,
                    lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
                    executionStack: workflow.history[workflow.historyIndex].executionStack,
                    forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
                    variables: workflow.history[workflow.historyIndex].variables,
                    displayingQuestionPieceId: workflow.history[workflow.historyIndex].displayingQuestionPieceId,
                    displayingShowPieceId: workflow.history[workflow.historyIndex].displayingShowPieceId,
                    displayingGroupPieceId: workflow.history[workflow.historyIndex].displayingGroupPieceId,
                    displayingTransferPieceId: workflow.history[workflow.historyIndex].displayingTransferPieceId,
                    displayingContinuePieceId: workflow.history[workflow.historyIndex].displayingContinuePieceId,
                    displayingAddWorkflowPieceId: workflow.history[workflow.historyIndex].displayingAddWorkflowPieceId,
                    createdWorkflowId: workflow.history[workflow.historyIndex].createdWorkflowId,
                }));

                if (props.overWrittenVariable) {
                    processState.variables[props.overWrittenVariable] = props.overWrittenValue;
                }

                isRequired = props.questionPiece.isRequiredPiece ? !!getWorkflowQuestionValidationValue(props.applicationState, processState, workflow.id, props.questionId, answer, {}, props.questionPiece.isRequiredPiece) : false;
            } else {
                isRequired = props.isRequired;
            }

            return {
                isDisabled,
                isRequired,
            };
        }

        return null;
    }

    getWorkflowProcessState = (workflow: IWorkflow) => {
        const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: workflow.history[workflow.historyIndex].customFields,
            lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
            executionStack: workflow.history[workflow.historyIndex].executionStack,
            forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
            variables: workflow.history[workflow.historyIndex].variables,
            displayingQuestionPieceId: workflow.history[workflow.historyIndex].displayingQuestionPieceId,
            displayingShowPieceId: workflow.history[workflow.historyIndex].displayingShowPieceId,
            displayingGroupPieceId: workflow.history[workflow.historyIndex].displayingGroupPieceId,
            displayingTransferPieceId: workflow.history[workflow.historyIndex].displayingTransferPieceId,
            displayingContinuePieceId: workflow.history[workflow.historyIndex].displayingContinuePieceId,
            displayingAddWorkflowPieceId: workflow.history[workflow.historyIndex].displayingAddWorkflowPieceId,
            createdWorkflowId: workflow.history[workflow.historyIndex].createdWorkflowId,
        }));

        if (this.props.overWrittenVariable) {
            processState.variables[this.props.overWrittenVariable] = this.props.overWrittenValue;
        }

        return processState;
    }

    selectChoice = (value: string) => {
        if (this.props.userInput === value) {
            this.props.onInputChange(undefined);
        } else {
            this.props.onInputChange(value);
        }
    }

    selectMultipleChoice = (value: string) => {
        const newValue = Array.isArray(this.props.userInput) ? this.props.userInput : [];

        if (newValue.includes(value)) {
            newValue.splice(newValue.indexOf(value), 1);
        } else {
            newValue.push(value);
        }

        this.props.onInputChange(newValue);
    }

    generateFileId = () => {
        let id = this.props.workflowId + this.props.questionId;

        const workflow = this.props.workflowData.byId[this.props.workflowId];
        const workflowType = this.props.workflowData.types.byId[workflow.type];

        if (this.props.overWrittenValue) {
            id += this.props.overWrittenValue;
        }

        const isForSingleMember = workflowType.affiliation === 'group' && this.props.customField.affiliation === 'member';
        if (isForSingleMember) {

            const processState = this.getWorkflowProcessState(workflow);

            if (this.props.questionPiece.memberVariablePiece) {
                const memberId = getWorkflowPieceValue(this.props.applicationState, processState, workflow.id, this.props.questionPiece.memberVariablePiece);
                if (typeof memberId !== 'string') {
                    throw new Error('The member ID must be a string');
                }

                id = this.props.questionId + memberId;
            }

        };

        return id;
    }

    handleFileInputChange = async (event: ChangeEvent<HTMLInputElement>) => {

        let key = this.generateFileId();

        if (event.target.files && event.target.files[0]) {
            let file: File = event.target.files[0];

            if (isFileFromCamera(file)) {
                file = new File([file], `camera_${file.name}`);
            }

            const fileSizeLimit = this.props.fileSizeLimitInMB ? this.props.fileSizeLimitInMB : 1;

            const FILE_SIZE_IN_MB = fileSizeLimit * 1024 * 1024;

            if (file.size > FILE_SIZE_IN_MB) {
                console.error('Max file size limit reached');
                const errorMessage = translatePhrase('Max File Size') + ': ' + fileSizeLimit + ' MB';
                this.props.setErrorMessage(errorMessage);
                window.setTimeout(() => {
                    this.props.clearErrorMessage();
                }, 4000);
                return false;
            };

            this.props.saveFilesToIDB(key, file);
            this.setState({
                fileName: file.name,
                fileSize: file.size,
            });
            this.props.onInputChange(key);
        } else {
            this.props.deleteFilesInIDB(key);
            this.props.onInputChange(undefined);
        }
    };

    clearUploadedFile = (userInput: CustomFieldValueType) => {
        if (typeof userInput === 'string') {
            if (userInput.startsWith('https')) {
                this.setState({
                    fileName: '',
                    fileSize: undefined,
                });
                this.props.onInputChange('');
            } else {
                if (this.input && this.input.current) {

                    let key = this.props.workflowId + this.props.questionId;

                    if (this.props.overWrittenValue) {
                        key += this.props.overWrittenValue;
                    }

                    this.input.current.value = '';
                    this.setState({
                        fileName: '',
                        fileSize: undefined,
                    });
                    this.props.deleteFilesInIDB(key);
                    this.props.onInputChange('');
                }
            }

        }

    }

    downloadLocalFile = () => {
        if (this.props.customField.type === FieldType.FILE) {
            const customFieldValue = this.props.userInput;
            let key = this.props.workflowId + this.props.questionId;

            if (this.props.overWrittenValue) {
                key += this.props.overWrittenValue;
            }

            if (typeof customFieldValue === 'string') {

                if (!customFieldValue.startsWith('http')) {
                    getStoredFileInDB(key).then(value => {
                        if (value?.file) {
                            saveAs(value.file, value.file.name);
                        }
                    });
                }
            }
        }
    }

    componentDidMount() {
        this.updateFileCustomField();
        this.validateAllowedChoice();
    };

    componentDidUpdate(prevProps: Props, prevState: OwnState) {
        if (prevProps.userInput !== this.props.userInput) {
            this.updateFileCustomField();
        };
    };

    async updateFileCustomField() {
        if (this.props.customField.type === FieldType.FILE) {
            const customFieldValue = this.props.userInput;

            if (typeof customFieldValue === 'string' && customFieldValue.startsWith('http')) {
                const fileName = decodeURI(customFieldValue).split('/')[5];
                const fileSize = await getOnlineFileSize(decodeURI(customFieldValue));
                this.setState({
                    fileName,
                    fileSize,
                });
            } else if (typeof customFieldValue === 'undefined') {
                this.setState({
                    fileName: undefined,
                    fileSize: undefined,
                });
            } else if (typeof customFieldValue === 'string') {
                getStoredFileInDB(customFieldValue).then(value => {
                    if (value?.file?.name) {
                        this.setState({
                            fileName: value.file.name,
                            fileSize: value.file.size,
                        });
                    }
                });
            }
        }
    }


    handleInputChange = (text: string) => {
        this.props.onInputChange(text);
    }

    handlePhoneChange = (value: string) => {
        this.props.onInputChange(value);
    }

    handleLocationChange = (value: string) => {
        this.props.onInputChange(value);
    }

    handleDateChange = (value: string | undefined) => {
        this.props.onInputChange(value);
    }

    searchOptions = (e: string) => {
        this.setState({
            optionsSearchText: e,
        });
    }

    checkIfURLHasDiceflow = (url: string) => {
        if (url.includes('diceflow.in')) {
            return true;
        }
        return false;
    }

    render() {
        let answerMarkup: JSX.Element;
        let questionIcon: JSX.Element = <RadioIcon />;
        let customFieldValue = this.props.userInput;

        switch (this.props.customField.type) {
            case FieldType.BOOLEAN:
                questionIcon = <RadioIcon />;
                answerMarkup = <section className={styles.choicesList}>
                    <section className={styles.options}>
                        <section data-selector={'question-input-option-' + this.props.customField.name + '-yes'} tabIndex={this.state.isDisabled ? undefined : 0} className={this.props.userInput === 'Yes' ? styles.activeChoice : styles.choice} onClick={() => this.selectChoice('Yes')}>
                            <RadioButton isActive={this.props.userInput === 'Yes'} />
                            <div className={styles.choiceMainText}>
                                {translatePhrase('Yes')}
                            </div>
                        </section>
                        <section data-selector={'question-input-option-' + this.props.customField.name + '-no'} tabIndex={this.state.isDisabled ? undefined : 0} className={this.props.userInput === 'No' ? styles.activeChoice : styles.choice} onClick={() => this.selectChoice('No')}>
                            <RadioButton isActive={this.props.userInput === 'No'} />
                            <div className={styles.choiceMainText}>
                                {translatePhrase('No')}
                            </div>
                        </section>
                    </section>
                </section>;
                break;

            case FieldType.SINGLE_SELECT:
                questionIcon = <RadioIcon />;
                answerMarkup = <section className={styles.choicesList}>
                    {this.state.allowedChoices.length > 10 && <div className={styles.searchInput}>
                        <InputText type="text" onChange={(e) => this.searchOptions(e)} placeholder={translatePhrase('Search')} />
                    </div>}
                    <section className={styles.options}>
                        {(this.props.isExpanded ? this.state.allowedChoices : this.state.allowedChoices.slice(0, 10)).filter(choiceId => this.props.workflowData.types.customFieldOptions.byId[choiceId].name.toLocaleLowerCase().includes(this.state.optionsSearchText.toLocaleLowerCase())).map(choiceId => {
                            return (<section data-selector={'question-input-option-' + this.props.customField.name + '-' + this.props.workflowData.types.customFieldOptions.byId[choiceId].name} tabIndex={this.state.isDisabled ? undefined : 0} key={choiceId} className={this.props.userInput === choiceId ? styles.activeChoice : styles.choice} onClick={() => this.selectChoice(choiceId)}>
                                <RadioButton isActive={this.props.userInput === choiceId} />
                                <div className={styles.choiceMainText}>{translatePhrase(this.props.workflowData.types.customFieldOptions.byId[choiceId].name)}</div>
                            </section>)
                        })}
                    </section>
                    {this.state.allowedChoices.length > 10 && <div className={styles.moreOptions} onClick={this.toggleShowMore}>{this.props.isExpanded ? translatePhrase('Show Less') : translatePhrase('Show More')}...</div>}
                </section>;
                break;

            case FieldType.MULTI_SELECT:
                questionIcon = <CheckIcon />;
                answerMarkup = <section className={styles.choicesList}>
                    {this.state.allowedChoices.length > 10 && <section className={styles.searchInput}>
                        <InputText type="text" onChange={(e) => this.searchOptions(e)} placeholder={translatePhrase('Search')} />
                    </section>}
                    <section className={styles.options}>
                        {(this.props.isExpanded ? this.state.allowedChoices : this.state.allowedChoices.slice(0, 10)).filter(choiceId => this.props.workflowData.types.customFieldOptions.byId[choiceId].name.toLocaleLowerCase().includes(this.state.optionsSearchText.toLocaleLowerCase())).map(choiceId => {
                            return (<section data-selector={'question-input-option-' + this.props.customField.name + '-' + this.props.workflowData.types.customFieldOptions.byId[choiceId].name} key={choiceId} className={Array.isArray(this.props.userInput) && this.props.userInput.includes(choiceId) ? styles.activeChoice : styles.choice} onClick={() => this.selectMultipleChoice(choiceId)}>
                                <RadioButton isActive={Array.isArray(this.props.userInput) && this.props.userInput.includes(choiceId)} />
                                <div className={styles.choiceMainText}>{translatePhrase(this.props.workflowData.types.customFieldOptions.byId[choiceId].name)}</div>
                            </section>)
                        })}
                    </section>
                    {this.state.allowedChoices.length > 10 && <div className={styles.moreOptions} onClick={this.toggleShowMore}>{this.props.isExpanded ? translatePhrase('Show Less') : translatePhrase('Show More')}...</div>}
                </section>;
                break;

            case FieldType.PHONE:
                questionIcon = <PhoneIcon />;
                const countryCodes = COUNTRY_CODES.map(code => code.dial_code);

                if (typeof customFieldValue === 'undefined') {
                    customFieldValue = '';
                }

                if (typeof customFieldValue !== 'string') {
                    throw new Error('A phone field should have a string value');
                }

                let phoneCountryCode = '+91';
                let phoneNumber = '';

                if (customFieldValue.split(' ').length > 1) {
                    phoneCountryCode = customFieldValue.split(' ')[0];

                    if (!countryCodes.includes(phoneCountryCode)) {
                        phoneCountryCode = '+91';
                    }

                    phoneNumber = customFieldValue.split(' ')[1];
                }

                answerMarkup = <section className={styles.textInputHolder + ' ' + styles.phoneInput}>
                    <InputText key={phoneCountryCode} options={countryCodes} default={phoneCountryCode} onChange={value => this.handlePhoneChange(`${value} ${phoneNumber}`)} />
                    <InputText type="text" isDisabled={this.state.isDisabled} default={phoneNumber} onChange={e => this.handlePhoneChange(`${phoneCountryCode} ${e}`)} />
                </section>
                break;

            case FieldType.LOCATION:
                questionIcon = <MapIcon />;
                if (typeof customFieldValue === 'undefined') {
                    customFieldValue = '';
                }

                if (typeof customFieldValue !== 'string') {
                    throw new Error('A phone field should have a string value');
                }

                let latitude = '';
                let longitude = '';

                if (customFieldValue.split(' ').length > 1) {
                    latitude = customFieldValue.split(' ')[0];
                    longitude = customFieldValue.split(' ')[1];
                }

                answerMarkup = <section className={styles.locationHolder} key={this.state.locationKey}>
                    <InputText type="number" default={latitude} onChange={e => this.handleLocationChange(`${e} ${longitude}`)} />
                    <InputText type="number" default={longitude} onChange={e => this.handleLocationChange(`${latitude} ${e}`)} />
                    <img src={mapIcon} alt="Get data" className={styles.locationFetch} onClick={this.getCoOrdinates} />
                </section>
                break;

            case FieldType.NUMBER:
                questionIcon = <EditIcon />;
                answerMarkup = <div className={styles.textInputHolder + ' ' + styles.numberInput}> <InputText type="number" isDisabled={this.state.isDisabled} onChange={(e) => this.handleInputChange(e)} default={typeof this.props.userInput === 'string' ? this.props.userInput : typeof this.props.userInput === 'number' ? String(this.props.userInput) : undefined} /> </div>
                break;

            case FieldType.TEXT:
                questionIcon = <EditIcon />;
                answerMarkup = <div className={styles.textInputHolder}> <InputText hideInputPlaceholder={true} placeholder={translatePhrase(this.state.questionText)} type="text" isDisabled={this.state.isDisabled} onChange={(e) => this.handleInputChange(e)} default={typeof this.props.userInput === 'string' ? this.props.userInput : ''} /> </div>
                break;

            case FieldType.DATE:
                questionIcon = <CalendarIcon />;
                answerMarkup = <section className={styles.textInputHolder + ' ' + styles.dateInput}>
                    <DateInput questionId={this.props.questionId} key={this.props.userInput ? '1' : '2'} default={typeof this.props.userInput === 'string' ? new Date(this.props.userInput) : undefined} onChange={value => this.handleDateChange(value)} />
                </section>
                break;

            case FieldType.FILE:
                questionIcon = <AttachIcon />;
                answerMarkup = <div className={styles.inputFileType} key={this.props.workflowId + '-file-' + this.props.questionId + (this.props.overWrittenValue ? '-' + this.props.overWrittenValue : '')}>
                    <label htmlFor={this.props.workflowId + '-file-' + this.props.questionId + (this.props.overWrittenValue ? '-' + this.props.overWrittenValue : '')}>
                        <span>{translatePhrase('File')}</span>
                        <AttachIcon />
                    </label>
                    <input
                        id={this.props.workflowId + '-file-' + this.props.questionId + (this.props.overWrittenValue ? '-' + this.props.overWrittenValue : '')}
                        className={this.props.isSectioned ? styles.sectionedInput : styles.input}
                        type="file"
                        onChange={this.handleFileInputChange}
                        ref={this.input} />
                    {this.state.fileName ?
                        <div className={styles.fileName}>
                            {this.props.userInput && typeof this.props.userInput === 'string' && this.props.userInput.startsWith('http')
                                ?
                                <a style={{ 'pointerEvents': 'auto' }} href={updateLinkToCurrentOrigin(this.props.userInput.toString()) + '?token=' + this.state.token} download className={styles.defaultText + ' ' + styles.uploadedFile} rel="noreferrer">{this.state.fileName}</a>
                                :
                                <span onClick={this.downloadLocalFile} className={styles.defaultText + ' ' + styles.isLink}>
                                    {this.state.fileName}
                                </span>
                            }
                            <div className={styles.fileDetails}>
                                <div className={styles.fileExtensionName}>{getFileExtension(this.state.fileName).toLocaleUpperCase()}</div>
                                <div className={styles.dot}></div>
                                <div className={styles.fileSize}>{this.state.fileSize && getFormatedFileSize(this.state.fileSize)}</div>
                                <div className={styles.closeButton} onClick={() => this.clearUploadedFile(this.props.userInput)} >
                                    <div className={styles.bar}></div>
                                </div>
                            </div>
                        </div>
                        :
                        <div>
                            <span className={styles.defaultText}>{translatePhrase('No file chosen')}</span>
                        </div>

                    }
                </div>
                break;

            case FieldType.FREE_TEXT:
                answerMarkup = <SignatureWidget confirmSignature={this.handleInputChange} />
                break;

            default:
                answerMarkup = <div></div>;
                break;
        }


        // {this.props.questionPiece.image && <div className={styles.iconHolder}>{getIconComponentFromName(this.props.questionPiece.image)}</div>}

        return (
            <div>
                <div key={this.props.overWrittenValue ? this.props.questionId + this.props.overWrittenValue : this.props.questionId} id={this.props.overWrittenValue ? this.props.questionId + this.props.overWrittenValue : this.props.questionId} className={(this.state.isDisabled ? styles.disabledQuestion : styles.question) + ' ' + (this.props.errorMessage ? styles.errorQuestion : '')}>
                    <section className={styles.questionText}>
                        <div className={styles.iconHolder}>
                            {questionIcon}
                        </div>
                        <span className={styles.questionWords}>
                            <TextDecorator text={translatePhrase(this.state.questionText)} />
                            {!this.state.isRequired && <span className={styles.optionalIndicator}>({translatePhrase('optional')})</span>}
                        </span>
                    </section>
                    <div className={styles.questionDetails}>
                        {answerMarkup}
                    </div>
                </div>
            </div>)
    }

}

const Question = connect(mapStateToProps, mapDispatchToProps)(ConnectedQuestion);

export default Question;