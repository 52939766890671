import React from 'react';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import styles from './CommonButton.module.scss';
import { translatePhrase } from '../../shared/helpers/translation';

type OwnProps = {
    text?: string | JSX.Element,
    title?: string,
    icon?: JSX.Element,
    isDisabled?: boolean,
    type?: 'primary' | 'secondary' | 'tertiary',
    color?: 'primary' | 'contrast' | 'danger',
    size?: 'small' | 'medium' | 'large',
    isRounded?: boolean,
    isBlock?: boolean,
    isSelected?: boolean,
    isDanger?: boolean,
    isHighlighted?: boolean,
    onClick?: () => void,
    padding?: string,
    hasData?: boolean,
    functionalText?: JSX.Element,
    dataSelector?: string,
}

const Button: React.FC<OwnProps> = (props) => {
    const reactToClick = () => {
        if (!props.isDisabled && props.onClick) {
            props.onClick();
        } else {
            console.log("No reaction");
        }
    }

    const getButtonStyle = () => {
        let buttonType, buttonColor, buttonSize;

        if (props.type) {
            switch (props.type) {
                case 'primary': buttonType = styles.Primary; break;
                case 'secondary': buttonType = styles.Secondary; break;
                case 'tertiary': buttonType = styles.Tertiary; break;
                default: buttonType = styles.Primary;
            }
        } else {
            buttonType = styles.Primary;
        }

        if (props.color === 'contrast') {
            buttonColor = styles.ContrastColor;
        } else if (props.color === 'danger') {
            buttonColor = styles.DangerColor;
        } else {
            buttonColor = '';
        }

        if (props.size) {
            switch (props.size) {
                case 'small': buttonSize = styles.Small; break;
                case 'medium': buttonSize = styles.Medium; break;
                case 'large': buttonSize = styles.Large; break;
                default: buttonSize = styles.Medium;
            }
        } else {
            buttonSize = styles.Medium;
        }

        return buttonType + ' ' + buttonColor + ' ' + buttonSize
            + ' ' + (props.isRounded ? styles.RoundButton : '')
            + ' ' + (props.isBlock ? styles.BlockButton : '')
            + ' ' + (props.isHighlighted ? styles.Highlighted : '')
            + ' ' + (props.isDanger ? styles.Danger : '')
            + ' ' + (props.isSelected ? styles.Active : '');
    }

    return <React.Fragment>
        {props.title ? <Tippy className="my-tippy" content={<span> {props.title} </span>}>
            <button type="button"
                onClick={reactToClick}
                className={styles.CommonButton + ' ' + getButtonStyle()}
                disabled={props.isDisabled ? true : false}
                data-selector={props.dataSelector}
                style={{ 'padding': props.padding }}>
                {props.hasData && <span className={styles.dot}></span>}
                {props.icon}
                {props.text && typeof (props.text) === 'string' && <span className={styles.text + ' ' + (props.icon ? styles.pushText : '')}>
                    {translatePhrase(props.text)}
                </span>}
                {props.text && typeof (props.text) !== 'string' && <span className={styles.text + ' ' + (props.icon ? styles.pushText : '')}>
                    {props.text}
                </span>}
                {props.functionalText && <span className={styles.text + ' ' + (props.icon ? styles.pushText : '')}> {props.functionalText} </span>}
            </button>
        </Tippy> : <button type="button"
            onClick={reactToClick}
            title={props.title}
            className={styles.CommonButton + ' ' + getButtonStyle()}
            disabled={props.isDisabled ? true : false}
            data-selector={props.dataSelector}
            style={{ 'padding': props.padding }}>
            {props.hasData && <span className={styles.dot}></span>}
            {props.icon}
            {props.text && typeof (props.text) === 'string' && <span className={styles.text + ' ' + (props.icon ? styles.pushText : '')}>
                {translatePhrase(props.text)}
            </span>}
            {props.text && typeof (props.text) !== 'string' && <span className={styles.text + ' ' + (props.icon ? styles.pushText : '')}>
                {props.text}
            </span>}
            {props.functionalText && <span className={styles.text + ' ' + (props.icon ? styles.pushText : '')}> {props.functionalText} </span>}
        </button>}
    </React.Fragment>
}

export default Button;