import React, { Component, ChangeEvent } from 'react';
import styles from './Hierarchy.module.scss';
import { Redirect } from "react-router-dom";

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { selectProject, unSelectProject } from '../../../shared/store/structure/project/actions';
import { selectLevel, unSelectLevel, addLevelCustomField } from '../../../shared/store/structure/level/actions';
import { selectRole, unSelectRole } from '../../../shared/store/structure/role/actions';

import { Permissions } from '../../../shared/store/permissions/types';

import { ApplicationState } from '../../../shared/store/types';
import ProjectsList from './ProjectsList';
import LevelsList from './LevelsList';
import RolesList from './RolesList';
import ModifyForm from '../../../widgets/card/ModifyForm';

import { ReactComponent as CancelIcon } from '../../../common/assets/close.svg';
import { ReactComponent as PlusIcon } from '../../../assets/new-custom-icons/dashboard/plus.svg';

import { saveAs } from 'file-saver';

import { translatePhrase } from '../../../shared/helpers/translation';
import LevelCustomFieldVertical from './LevelCustomFieldVertical';
import uuid from 'uuid';
import { getConfigurationToExport, ExportedConfiguration } from '../../../shared/helpers/duplicate';
import { importFromConfiguration } from '../../../shared/helpers/duplicate/import';
import { isUUID } from '../../../shared/helpers/utilities';
import axios from 'axios';
import { clearInfoMessage, clearToastMessage, freezeBackground, initiateFetchAppData, selectNudge, setInfoMessage, setIsTopBarExpanded, setToastMessage, setupSampleProject, unFreezeBackground } from '../../../shared/store/my-data/actions';
import LoaderModal from '../../../widgets/loader/LoaderModal';
import { INewCustomFieldData, FieldType } from '../../../shared/store/custom-fields/types';
import { BASE_URL } from '../../../shared/store/url';
import EnhancedInputText from '../../../widgets/form/InputText';
import Button from '../../../widgets/button/CommonButton';
import { NudgeType } from '../../../shared/store/my-data/types';
import { resetSession } from '../../../shared/store/actions';
import ConfirmModal from '../../../widgets/loader/ConfirmModal';
import { setSearchTermForComponentsWidget } from '../../../shared/store/state-save/actions';
import { ComponentWidgets } from '../../../shared/store/state-save/types';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canEditHierarchy = state.permissions.myPermissions.general.Hierarchy === Permissions.WRITE
    const canViewHierarchy = canEditHierarchy || state.permissions.myPermissions.general.Hierarchy === Permissions.READ;
    const selectedLevelId = state.structure.levels.selected;
    const levelCustomFieldIds = selectedLevelId ? state.structure.levels.byId[selectedLevelId].customFields : [];

    const selectedRoleId = state.structure.roles.selected;
    const roleCustomFieldIds = selectedRoleId ? state.structure.roles.byId[selectedRoleId].customFields : [];
    const isSelectedProjectEmpty = state.structure.projects.selected ? state.structure.projects.byId[state.structure.projects.selected].children.length === 0 : false;

    return {
        isReadable: canViewHierarchy,
        isWritable: canEditHierarchy,

        projectData: state.structure.projects,
        levelsData: state.structure.levels,

        selectedProject: state.structure.projects.selected,
        selectedLevel: state.structure.levels.selected,
        selectedRole: state.structure.roles.selected,
        levelCustomFieldIds,
        roleCustomFieldIds,

        isSelectedProjectEmpty,
        isSuperUser: !isUUID(state.myData.id),

        isLoaded: state.myData.isLoaded,
        isTopBarExpanded: state.myData.isTopBarExpanded,
        selectedNudge: state.myData.selectedNudgeId,
        isTestOrg: !!state.organization.isTestOrganization,
        searchTerms: state.savedStates.searchTerms
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        selectProject: (id: string) => dispatch(selectProject(id)),
        unSelectProject: () => dispatch(unSelectProject()),

        selectLevel: (id: string) => dispatch(selectLevel(id)),
        unSelectLevel: () => dispatch(unSelectLevel()),
        addLevelCustomField: (payload: INewCustomFieldData, levelId: string) => dispatch(addLevelCustomField(payload, levelId)),

        selectRole: (id: string) => dispatch(selectRole(id)),
        unSelectRole: () => dispatch(unSelectRole()),

        setToastMessage: (message: string, persistMessage: boolean) => dispatch(setToastMessage(message, persistMessage)),
        clearToastMessage: () => dispatch(clearToastMessage()),

        setInfoMessage: (message: string, persistMessage: boolean) => dispatch(setInfoMessage(message, persistMessage)),
        clearInfoMessage: () => dispatch(clearInfoMessage()),

        freezeBackground: () => dispatch(freezeBackground()),
        unFreezeBackground: () => dispatch(unFreezeBackground()),

        setupSampleProject: () => dispatch(setupSampleProject()),

        resetSession: () => dispatch(resetSession()),
        fetchAppDataFromServer: () => dispatch(initiateFetchAppData()),

        setIsTopBarExpanded: (isExpanded: boolean) => dispatch(setIsTopBarExpanded(isExpanded)),
        selectNudge: (nudge: NudgeType) => dispatch(selectNudge(nudge)),

        setSearchTermForComponentsWidget: (widgetName: ComponentWidgets, searchTerm: string) => dispatch(setSearchTermForComponentsWidget(widgetName, searchTerm))
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
    isShowingAddForm: boolean,
    modifyingVerticalName: string,
    modifyingVerticalType: string,
    modifyingVerticalIsComputed: string,
    modifyingVerticalIsInTable: string,
    loader: JSX.Element | undefined,
    isDeletingProject: boolean,
}

class ConnectedLevels extends Component<Props, OwnState> {

    state: OwnState = {
        isShowingAddForm: false,
        modifyingVerticalName: '',
        modifyingVerticalType: '',
        modifyingVerticalIsComputed: 'No',
        modifyingVerticalIsInTable: 'No',
        loader: undefined,
        isDeletingProject: false,
    };

    toggleCustomFieldAddForm = () => {
        this.setState((prevState) => {
            return {
                isShowingAddForm: !prevState.isShowingAddForm,
            };
        });
    }

    updateFieldName = (value: string) => {
        this.setState({
            modifyingVerticalName: value,
        });
    }

    updateFieldType = (value: string) => {
        this.setState({
            modifyingVerticalType: value,
        });
    }

    updateFieldIsComputed = (value: string) => {
        this.setState({
            modifyingVerticalIsComputed: value,
        });
    }

    updateFieldIsInTable = (value: string) => {
        this.setState({
            modifyingVerticalIsInTable: value,
        });
    }

    addCustomField = () => {
        const fieldType = this.state.modifyingVerticalType as keyof typeof FieldType;

        if (this.props.selectedLevel) {
            this.props.addLevelCustomField({
                name: this.state.modifyingVerticalName,
                type: FieldType[fieldType],
                isComputed: this.state.modifyingVerticalIsComputed === 'Yes',
                isEditable: true,
                isDeletable: true,
                isInTable: this.state.modifyingVerticalIsInTable === 'Yes',
                seedEntityVariable: uuid.v4(),
            }, this.props.selectedLevel);

            this.setState({
                isShowingAddForm: false,
                modifyingVerticalName: '',
                modifyingVerticalType: '',
                modifyingVerticalIsComputed: 'No',
                modifyingVerticalIsInTable: 'No',
            });
        }
    }

    validateVerticalForm = () => {
        const fieldTypes = Object.keys(FieldType);
        const binaryTypes = ['Yes', 'No'];

        if (!this.state.modifyingVerticalName) {
            return 'Enter a valid name';
        }

        const levelCustomFieldNames: Array<string> = this.props.levelCustomFieldIds.map(id => {
            return this.props.levelsData.customFields.byId[id].name.toLocaleLowerCase();
        });

        if (levelCustomFieldNames.includes(this.state.modifyingVerticalName.toLocaleLowerCase())) {
            return 'A custom field with this name already exists';
        }

        if (!fieldTypes.includes(this.state.modifyingVerticalType)) {
            return 'Enter a valid type';
        }

        if (!binaryTypes.includes(this.state.modifyingVerticalIsInTable)) {
            return 'Enter a valid in-table field'
        }

        return true;
    }

    handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        const file = !!e.target.files ? e.target.files[0] : undefined;

        if (!!file) {
            var reader = new FileReader();

            this.props.setToastMessage('Importing', true);

            this.setState({
                loader: (
                    <LoaderModal
                        loaderText={[
                            translatePhrase("Compressing the file(s)") + " ...",
                            translatePhrase("Uploading") + "...",
                        ]}
                        isIndeterminate={true}
                    />
                ),
            });

            // this.props.freezeBackground();
            reader.onload = (evt: any) => {
                if (this.props.selectedProject) {
                    const importedData = evt.target.result;
                    const configuration: ExportedConfiguration = JSON.parse(importedData);
                    importFromConfiguration(configuration, this.props.selectedProject);
                    this.props.clearToastMessage();
                    // this.props.unFreezeBackground();
                    this.setState({ loader: undefined });
                }
            }

            reader.readAsText(file, "UTF-8");
        }
    }

    saveConfig = () => {
        if (!this.props.selectedProject) {
            return;
        }

        const configurationData = JSON.stringify(getConfigurationToExport(this.props.selectedProject), null, 2);

        const csvBlob = new Blob([configurationData.split('\n').join('\r\n')], { type: 'application/json' });

        saveAs(csvBlob, `${this.props.projectData.byId[this.props.selectedProject].name} Export.json`);
    }

    wipeProjectData = async (projectId: string) => {
        this.setState({
            isDeletingProject: false,
        });

        this.props.setInfoMessage('Clearing Project data', true);

        try {
            await axios.get(BASE_URL + '/wipe-project-data/?id=' + projectId, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            });

            this.props.clearInfoMessage();

            this.props.resetSession();
            this.props.fetchAppDataFromServer();
        } catch (e) {
            this.props.setInfoMessage('Could not clear project data', false);
        }
    }

    componentDidUpdate(prevProps: Props) {
        if (!this.props.isTopBarExpanded && !this.props.isLoaded && this.props.isSuperUser && this.props.projectData.allEntries.length !== prevProps.projectData.allEntries.length) {
            if (this.props.projectData.allEntries.length === 0) {
                this.props.setIsTopBarExpanded(true);
                this.props.selectNudge(NudgeType.HIERARCHY_SET_UP_SAMPLE_PROJECT);
            }
        }
    }

    componentDidMount() {
        if (!this.props.isTopBarExpanded && this.props.isLoaded && this.props.isSuperUser && this.props.projectData.allEntries.length === 0) {
            this.props.setIsTopBarExpanded(true);
            this.props.selectNudge(NudgeType.HIERARCHY_SET_UP_SAMPLE_PROJECT);
        }
    }

    askForProjectWipe = () => {
        this.setState({
            isDeletingProject: true,
        });
    }

    cancelProjectWipe = () => {
        this.setState({
            isDeletingProject: false,
        });
    }

    setSearchTermForComponentsWidget = (searchTerm: string) => {
        this.props.setSearchTermForComponentsWidget(ComponentWidgets.STRUCTURE_HIERARCHY_PROJECTS, searchTerm)
    };

    render() {

        if (!this.props.isReadable) {
            return <Redirect to="/dashboard" />;
        }

        const typeOptions = Object.keys(FieldType).map(name => {
            return {
                name: translatePhrase(name.split('_').join(' ')),
                value: name,
            }
        });

        const searchTerm = '';

        const addFieldForm =
            <div className={styles.modifyFormCard}>
                <div>
                    <header>
                        <div className={styles.heading}>
                            {translatePhrase('Add Level Custom Field')}
                        </div>
                        <button className={styles.cancelCustomFieldButton} title={translatePhrase('Cancel')} onClick={this.toggleCustomFieldAddForm}> <CancelIcon /> </button>
                    </header>
                </div>
                <div className={styles.container}>
                    <ModifyForm hideCancel={true} isNew={true} submitForm={this.addCustomField} cancelForm={this.toggleCustomFieldAddForm} validateForm={this.validateVerticalForm}>
                        <EnhancedInputText placeholder="Name" onEnterPress={this.addCustomField} onChange={this.updateFieldName} />
                        <EnhancedInputText placeholder="Type" onEnterPress={this.addCustomField} onChange={this.updateFieldType} options={typeOptions} />
                        <EnhancedInputText isBooleanField placeholder="Is Computed" onEnterPress={this.addCustomField} onChange={this.updateFieldIsComputed} defaultBooleanValue={false} />
                        <EnhancedInputText isBooleanField placeholder="Searchable / Show in Table" onEnterPress={this.addCustomField} onChange={this.updateFieldIsInTable} defaultBooleanValue={false} />
                    </ModifyForm>
                </div>
            </div>;

        const levelCustomFieldNames: Array<string> = this.props.levelCustomFieldIds.map(id => {
            return this.props.levelsData.customFields.byId[id].name.toLocaleLowerCase();
        });


        return <div className={styles.innerFocusContainer}>
            {this.state.isDeletingProject && <ConfirmModal
                confirmText="Wipe project?"
                confirm={() => this.props.selectedProject && this.wipeProjectData(this.props.selectedProject)}
                cancel={this.cancelProjectWipe}
            />}
            {this.props.isSuperUser && <div className={styles.pageButtons}>
                <Button isHighlighted={this.props.selectedNudge && this.props.selectedNudge === NudgeType.HIERARCHY_SET_UP_SAMPLE_PROJECT} icon={<PlusIcon />} onClick={() => this.props.setupSampleProject()}
                    text={translatePhrase('Setup Sample Project')} padding={'0px 15px'} isBlock={false}
                    isRounded={true} color={'contrast'} size={'small'} />
                {this.props.isTestOrg && this.props.selectedProject && <span className='ignore-react-onclickoutside'><Button onClick={this.askForProjectWipe}
                    text={translatePhrase('Wipe Project')} padding={'0px 15px'} isBlock={false}
                    isRounded={true} color={'contrast'} size={'small'} type="secondary" /></span>}
            </div>}
            <div className={styles.cardsTree + ' ignore-react-onclickoutside'}>
                {this.state.loader}

                <ProjectsList isSearchable heading="Projects" onSelectCard={this.props.selectProject} onUnSelectCard={this.props.unSelectProject} selectedId={this.props.selectedProject} isReadOnly={!this.props.isWritable} searchTerm={searchTerm} setSearchTermForComponentsWidget={this.setSearchTermForComponentsWidget} />
                {this.props.selectedProject && <LevelsList heading="Levels" projectId={this.props.selectedProject} onSelectCard={this.props.selectLevel} onUnSelectCard={this.props.unSelectLevel} selectedId={this.props.selectedLevel} isReadOnly={!this.props.isWritable} />}
                {this.props.selectedLevel && <RolesList heading="Roles" levelId={this.props.selectedLevel} onSelectCard={this.props.selectRole} onUnSelectCard={this.props.unSelectRole} selectedId={this.props.selectedRole} isReadOnly={!this.props.isWritable} isShowingProjectName />}
                {this.props.selectedLevel && !this.props.selectedRole && this.props.levelCustomFieldIds.map(id => {
                    const customField = this.props.levelsData.customFields.byId[id];
                    const remainingNames = levelCustomFieldNames.filter(customFieldName => customFieldName.toLocaleLowerCase() !== customField.name.toLocaleLowerCase());

                    return <LevelCustomFieldVertical
                        levelId={this.props.selectedLevel || ''}
                        existingCardNames={remainingNames}
                        customFieldId={id}
                        key={id}
                        isReadOnly={!this.props.isWritable}
                    />
                })}
                {this.props.isWritable && this.props.selectedLevel && !this.props.selectedRole && <section className={styles.newVerticalHolder + ' ignore-react-onclickoutside'}>
                    {this.state.isShowingAddForm ? addFieldForm : <section onClick={this.toggleCustomFieldAddForm} className={styles.newCustomFieldPrompt}>+ {translatePhrase('Add Level Custom Field')}</section>}
                </section>}
            </div>
        </div>;
    }

}

const Levels = connect(mapStateToProps, mapDispatchToProps)(ConnectedLevels);

export default Levels;