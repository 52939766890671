import React, { Component, ReactNode } from 'react';
import styles from './container-piece/ContainerPiece.module.scss';
import Input from '../Input';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';
import { Option } from '../drop-down/ListItem';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setLoopVariable, setIterableVariable, setHeading } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import ContainerPiece from './container-piece/ContainerPiece';
import { valuePieceSlotTarget, groupedNextPieceTarget } from './utilities';
import { PieceType } from '../../../shared/store/flowchart/pieces/types';
import { WorkflowTypeContext } from '../../../contexts/workflow-type-context';
import { FlowchartContext, FlowchartInfoForPiece } from '../../../contexts/flowchart-context';
import DropDownSearchBox from '../drop-down/DropDownSearchBox';
import { getFilteredOptionsBySearch } from '../drop-down/DropDownSearchBox';


type GroupPieceProps = {
    variables?: Array<Option>,
    loopVariableId?: string,

    headingPiece?: JSX.Element,
    headingText?: string,

    nextPiece?: JSX.Element,
    iterablePiece?: JSX.Element,

    isForMembersList?: boolean,
    children?: ReactNode,
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,
        variableState: state.flowchart.variables,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string | undefined) => dispatch(setTargetPiece(pieceId)),
        setLoopVariable: (targetPieceId: string, draggedPieceId: string) => dispatch(setLoopVariable(targetPieceId, draggedPieceId)),
        setIterableVariable: (targetPieceId: string, draggedPieceId: string) => dispatch(setIterableVariable(targetPieceId, draggedPieceId)),
        setHeadingPiece: (targetPieceId: string, value: string) => dispatch(setHeading(targetPieceId, value)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = GroupPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type GroupPieceState = {
    isHoveringOverIterablePiece: boolean,
    isHoveringOverHeadingPiece: boolean,
    searchTerm: string,
}

class ConnectedGroupPiece extends Component<Props, GroupPieceState>  {
    
    constructor(props: Props) {
        super(props);
        
        this.state = {
            isHoveringOverIterablePiece: false,
            isHoveringOverHeadingPiece: false,
            searchTerm: "",
        };
    }

    handleHoverOverInnerPiece = () => {

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        groupedNextPieceTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);

        switch (this.props.lastDraggedPiece.type) {
            case PieceType.SECTION:
                this.props.setTargetPiece(this.props.pieceId);
                break;

            default:
                break;
        }
    };

    handleHoverOverHeadingPiece = () => {
        this.setState({
            isHoveringOverHeadingPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }
        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };


    handleHoverOverIterablePiece = () => {
        this.setState({
            isHoveringOverIterablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfHeadingPiece = () => {
        this.setState({
            isHoveringOverHeadingPiece: false,
        });
    };

    handleHoverOutOfIterablePiece = () => {
        this.setState({
            isHoveringOverIterablePiece: false,
        });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && (this.state.isHoveringOverIterablePiece)) {
            if (this.state.isHoveringOverIterablePiece) {
                this.props.setIterableVariable(this.props.pieceId, this.props.lastDraggedPiece.id);
            }

            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverIterablePiece: false,
            });
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && (this.state.isHoveringOverHeadingPiece)) {
            if (this.state.isHoveringOverHeadingPiece) {
                this.props.setHeadingPiece(this.props.pieceId, this.props.lastDraggedPiece.id);
            }

            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverHeadingPiece: false,
            });
        }
    }

    handleHeadingUpdate = (value: string) => {
        this.props.setHeadingPiece(this.props.pieceId, value);
    }

    updateValidity = (isValid: boolean, flowchartContext: FlowchartInfoForPiece) => {
        if (!isValid) {
            const invalidPieceInfo = flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId);

            if (!invalidPieceInfo && flowchartContext.setInvalidPiece) {
                flowchartContext.setInvalidPiece(this.props.pieceId, flowchartContext.parentSplitPieceIds);
            }
        }

        if (isValid && flowchartContext.removeInvalidPiece && flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId)) {
            flowchartContext.removeInvalidPiece(this.props.pieceId);
        }
    }

    searchFortOptions = (searchTerm: string) => {
        this.setState({ searchTerm: searchTerm });
    }

    render() {

        return <WorkflowTypeContext.Consumer>
            {workflowType => {

                return <FlowchartContext.Consumer>
                    {
                        flowchartContext => {
                            let isValid = !this.props.loopVariableId || !workflowType || workflowType.variables.includes(this.props.loopVariableId);

                            if (this.props.isForMembersList && flowchartContext.highlightIncompletePieces) {
                                const isIncomplete = !this.props.loopVariableId || !this.props.iterablePiece;
                                isValid = isValid && !isIncomplete;
                            }

                            this.updateValidity(isValid, flowchartContext);

                            const variableName = this.props.loopVariableId && this.props.loopVariableId in this.props.variableState.byId ? this.props.variableState.byId[this.props.loopVariableId].name : undefined;
                            
                            const filteredVariable = this.props.variables ? getFilteredOptionsBySearch(this.props.variables, this.state.searchTerm) : [];
                            
                            const variableSelectBox = <SelectBox theme="indigo" selectionPromptText={variableName}>
                                <DropDownList theme="indigo" dismissAfterSelection={false}>
                                    <DropDownSearchBox
                                        handleSearchInputChange={this.searchFortOptions}
                                        placeholder={"Search by name"}
                                        searchTerm={this.state.searchTerm}
                                    />
                                    {filteredVariable.map((variable, index) => <ListItem name={variable.name} value={variable.value} key={index} theme="indigo" onClick={this.props.setLoopVariable.bind(this, this.props.pieceId)} />)}
                                </DropDownList>
                            </SelectBox>;

                            const upperArmContent = (<div className={styles.upperArmContent}>
                                <div className={styles.text}>{this.props.isForMembersList ? 'group for' : 'group'}</div>
                                {this.props.isForMembersList && variableSelectBox}
                                {this.props.isForMembersList && <div className={styles.text}>in </div>}
                                {this.props.isForMembersList && (this.props.iterablePiece ? this.props.iterablePiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverIterablePiece && !!this.props.targetPiece} isDisabled={true} onMouseOver={this.handleHoverOverIterablePiece} onMouseOut={this.handleHoverOutOfIterablePiece} />)}
                                <div className={styles.text}>with heading</div>
                                {this.props.headingPiece ? this.props.headingPiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverHeadingPiece && !!this.props.targetPiece} onMouseOver={this.handleHoverOverHeadingPiece} onMouseOut={this.handleHoverOutOfHeadingPiece} defaultText={this.props.headingText} onChange={this.handleHeadingUpdate} />}
                            </div>);

                            return (<ContainerPiece
                                {...this.props}
                                theme={isValid ? "aqua" : "red"}
                                handleHoverOverInnerPiece={this.handleHoverOverInnerPiece}
                                upperArmContent={upperArmContent}
                                isCollapsible={!this.props.isForMembersList}
                            >
                                {this.props.children}
                            </ContainerPiece>);
                        }
                    }
                </FlowchartContext.Consumer>
            }}
        </WorkflowTypeContext.Consumer>

    }
}

const GroupPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedGroupPiece);

export default GroupPiece;