import { Component, ChangeEvent } from 'react';
import styles from './Flowchart.module.scss';
import { Redirect } from "react-router-dom";

import { Permissions } from '../../shared/store/permissions/types';
import { ReactComponent as ChevronDownIcon } from '../../assets/chevron-arrow-down.svg';

import { setNextPiece, setInnerPiece, setConditionForIfPiece, setConditionPiece, setConditionNextPiece, setLoopVariable, setIterableVariable, setOperand, setLeftOperand, setRightOperand, setQuestionData, setMemberVariable, setDataStoreValue, setDataSetValue, setDataCopyVariable, setReturnValue, addPiece, deletePiece, setVariableForShow, setVariableForCustomField, setQuestionRequiredPiece, setQuestionDisabledPiece, setQuestionDefaultPiece, setQuestionImage, setLocationPiece, setPieceForList, setVariablePiece, setHeading, setWorkflowAffiliationVariable, setDate, setMonth, setYear, setMessage, addFullPiece, setUpdateDueDateValue, copyPiece, setEntity, setEntityType, setError, setQuestionHiddenPiece, setHiddenPieceForShow } from '../../shared/store/flowchart/pieces/actions';
import { FlowchartPieceActions, PieceType, AllPieceTypes } from '../../shared/store/flowchart/pieces/types';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import uuid from 'uuid';

import { ApplicationState } from '../../shared/store/types';
import { CategoryValue } from '../flowchart/helpers/index';
import { piecesByCategory as customFieldPiecesByCategory, getComponentForWidgetFields } from '../flowchart/helpers/widget';
import { setIsolatedWidgetPiece, removeIsolatedWidgetPiece, registerWidgetVariable, updateWidgetStartPiece } from '../../shared/store/widgets/actions';
import { setIsFlowchartExpanded, setIsTopBarExpanded, setToastMessage } from '../../shared/store/my-data/actions';
import { duplicatePiece, pastePiece } from '../../shared/store/flowchart/helpers/pieces';
import { FlowchartContext, FlowchartInfoForPiece } from '../../contexts/flowchart-context';
import { PiecePositionState } from '../../shared/helpers/common-types';
import FlowchartWindow from '../flowchart-window/FlowchartWindow';
import { isUUID } from '../../shared/helpers/utilities';
import { ReactComponent as ExternalIcon } from '../../common/assets/help.svg';


type OwnProps = {
    widgetId: string,
    onChange?: () => void | undefined,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    const canEditConfiguration = !isUUID(state.myData.id) || state.permissions.myPermissions.general.DashboardConfiguration === Permissions.WRITE;
    const canViewConfiguration = canEditConfiguration || state.permissions.myPermissions.general.DashboardConfiguration === Permissions.READ;

    return {
        isReadable: canViewConfiguration,
        isWritable: canEditConfiguration,
        isDragging: state.flowchart.pieces.isDragging,
        piecesData: state.flowchart.pieces,
        variablesData: state.flowchart.variables,
        applicationState: state,
        widget: state.widgets.byId[ownProps.widgetId],

        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece,
        lastCopiedPiece: state.flowchart.pieces.lastCopiedPiece,
        lastCopiedPieceFlowchartContext: state.flowchart.pieces.lastCopiedPieceFlowchartContext,

        selectedNudge: state.myData.selectedNudgeId,
        isTopBarExpanded: state.myData.isTopBarExpanded,
        isFlowchartExpanded: state.myData.isFlowchartExpanded,
    }
}



const mapDispatchToProps = (dispatch: Dispatch) => {
    var emptyMethod = () => { };

    const flowchartPieceActions: FlowchartPieceActions = {
        setNextPiece: (pieceId, value) => dispatch(setNextPiece(pieceId, value)),
        setInnerPiece: (pieceId, value) => dispatch(setInnerPiece(pieceId, value)),
        setConditionForIfPiece: (pieceId, index, value) => dispatch(setConditionForIfPiece(pieceId, index, value)),
        setConditionPiece: (pieceId, value) => dispatch(setConditionPiece(pieceId, value)),
        setConditionNextPiece: (pieceId, index, value) => dispatch(setConditionNextPiece(pieceId, index, value)),
        setLoopVariable: (pieceId, value) => dispatch(setLoopVariable(pieceId, value)),
        setIterableVariable: (pieceId, value) => dispatch(setIterableVariable(pieceId, value)),
        setOperand: (pieceId, value) => dispatch(setOperand(pieceId, value)),
        setLeftOperand: (pieceId, value) => dispatch(setLeftOperand(pieceId, value)),
        setRightOperand: (pieceId, value) => dispatch(setRightOperand(pieceId, value)),
        setQuestionData: (pieceId, value) => dispatch(setQuestionData(pieceId, value)),
        setMemberVariable: (pieceId, value) => dispatch(setMemberVariable(pieceId, value)),
        setRequiredPiece: (pieceId, value) => dispatch(setQuestionRequiredPiece(pieceId, value)),
        setDisabledPiece: (pieceId, value) => dispatch(setQuestionDisabledPiece(pieceId, value)),
        setHiddenPiece: (pieceId, value) => dispatch(setQuestionHiddenPiece(pieceId, value)),
        setDefaultPiece: (pieceId, value) => dispatch(setQuestionDefaultPiece(pieceId, value)),
        setImage: (pieceId, value) => dispatch(setQuestionImage(pieceId, value)),
        setDataStoreValue: (pieceId, value) => dispatch(setDataStoreValue(pieceId, value)),
        setDataSetValue: (pieceId, value) => dispatch(setDataSetValue(pieceId, value)),
        setDataCopyVariable: (pieceId, value) => dispatch(setDataCopyVariable(pieceId, value)),
        setDataForList: (pieceId, value) => dispatch(setPieceForList(pieceId, value)),
        setReturnVariable: (pieceId, value) => dispatch(setReturnValue(pieceId, value)),
        setLocationPiece: (pieceId, value) => dispatch(setLocationPiece(pieceId, value)),
        setVariableForShow: (pieceId, value) => dispatch(setVariableForShow(pieceId, value)),
        setHiddenPieceForShow: (pieceId, value) => dispatch(setHiddenPieceForShow(pieceId, value)),
        setVariableForCustomField: (pieceId, value) => dispatch(setVariableForCustomField(pieceId, value)),
        setVariablePiece: (pieceId, value) => dispatch(setVariablePiece(pieceId, value)),
        setAffiliationVariablePiece: (pieceId, value) => dispatch(setWorkflowAffiliationVariable(pieceId, value)),
        setHeadingPiece: (pieceId, value) => dispatch(setHeading(pieceId, value)),
        setMessage: (pieceId, value) => dispatch(setMessage(pieceId, value)),

        setDatePiece: (pieceId, value) => dispatch(setDate(pieceId, value)),
        setMonthPiece: (pieceId, value) => dispatch(setMonth(pieceId, value)),
        setYearPiece: (pieceId, value) => dispatch(setYear(pieceId, value)),
        setDueDatePiece: (pieceId, value) => dispatch(setUpdateDueDateValue(pieceId, value)),

        setEntity: (pieceId, value) => dispatch(setEntity(pieceId, value)),
        setEntityType: (pieceId, value) => dispatch(setEntityType(pieceId, value)),

        setErrorValue: (pieceId: string, value: string | undefined) => dispatch(setError(pieceId, value)),

        setStartPieceData: (widgetId: string, payload: PiecePositionState) => dispatch(updateWidgetStartPiece(payload, widgetId)),

        setFinsalPremium: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberFirstName: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberLastName: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberEmail: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberPhone: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberPan: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberState: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberCity: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberAddressLine1: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberAddressLine2: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberPinCode: (pieceId: string, value: string | undefined) => emptyMethod(),

        setFinsalUserEmail: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalUserPhone: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMember: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberDOB: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberGender: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberFatherName: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberMotherName: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberAnnualIncome: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalMemberMaritalStatus: (pieceId: string, value: string | undefined) => emptyMethod(),
        setFinsalApplyForLoan: (pieceId: string, value: string | undefined) => emptyMethod(),
    };

    return {
        flowchartPieceActions,
        setIsolatedWidgetPiece: (widgetId: string, payload: PiecePositionState) => dispatch(setIsolatedWidgetPiece(payload, widgetId)),
        removeIsolatedWidgetPiece: (widgetId: string, pieceId: string) => dispatch(removeIsolatedWidgetPiece(pieceId, widgetId)),
        registerWidgetVariable: (widgetId: string, variableId: string) => dispatch(registerWidgetVariable(variableId, widgetId)),

        addPiece: (pieceId: string, pieceType: PieceType) => dispatch(addPiece(pieceId, pieceType)),
        addFullPiece: (pieceData: AllPieceTypes) => dispatch(addFullPiece(pieceData)),
        deletePiece: (pieceId: string) => dispatch(deletePiece(pieceId)),
        copyPiece: (pieceId: string, flowchartContext: string) => dispatch(copyPiece(pieceId, flowchartContext)),
        setToastMessage: (message: string) => dispatch(setToastMessage(message)),
        toggleTopBarExpansion: (flag: boolean) => dispatch(setIsTopBarExpanded(flag)),
        toggleFlowChartExpansion: (flag: boolean) => dispatch(setIsFlowchartExpanded(flag)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
    isWaitingForDrag: boolean,

    expandedCategory: string | undefined,
    searchTerm: string,
}

class ConnectedWidgetFlowchart extends Component<Props, OwnState> {

    state = {
        isWaitingForDrag: false,
        expandedCategory: undefined,
        searchTerm: '',
    };

    deletePiece = (pieceId: string) => {

        const pieceFound = !!this.props.widget.isolatedPieces.find(isolatedPiece => isolatedPiece.piece === pieceId);

        if (!pieceFound) {
            // You can only delete isolated pieces
            return;
        }

        this.props.removeIsolatedWidgetPiece(this.props.widgetId, pieceId);
        this.props.deletePiece(pieceId);
    }

    duplicatePiece = (pieceId: string, attachedPiece: boolean = false) => {
        const pieceToCopy = this.props.piecesData.byId[pieceId];
        const flowchartHolderElement = document.getElementById('flowchart-holder');

        if (pieceToCopy.type === PieceType.START) {
            return '';
        }

        if (!flowchartHolderElement) {
            throw new Error('This element needs to exist');
        }

        const newId = duplicatePiece(this.props.piecesData, this.props.addFullPiece, undefined, pieceId)

        if (!attachedPiece) {
            this.props.setIsolatedWidgetPiece(this.props.widgetId, {
                piece: newId,
                position: {
                    x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                    y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                },
            });
        }

        return newId;
    }

    pastePiece = (pieceId: string, attachedPiece: boolean = false) => {

        if (this.props.lastCopiedPieceFlowchartContext === this.props.widgetId) {
            this.duplicatePiece(pieceId, attachedPiece);
            return;
        }

        const pieceToPaste = this.props.piecesData.byId[pieceId];
        const flowchartHolderElement = document.getElementById('flowchart-holder');

        if (pieceToPaste.type === PieceType.START) {
            return '';
        }

        if (!flowchartHolderElement) {
            throw new Error('This element needs to exist');
        }

        const newId = pastePiece(this.props.piecesData, this.props.addFullPiece, pieceId)

        if (!attachedPiece) {
            this.props.setIsolatedWidgetPiece(this.props.widgetId, {
                piece: newId,
                position: {
                    x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                    y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                },
            });
        }

        return newId;
    }

    handleKeyPress = (e: KeyboardEvent) => {
        // Do not apply any shortcuts when you are typing something into an input element
        if (window.document.activeElement && window.document.activeElement.tagName === 'INPUT') {
            return;
        }

        switch (e.key) {
            case 'Backspace':
            case 'Delete':
                this.props.lastDraggedPiece && this.deletePiece(this.props.lastDraggedPiece);
                return;
            case 'D':
            case 'd':
                this.props.lastDraggedPiece && this.duplicatePiece(this.props.lastDraggedPiece);
                return;
            case 'C':
            case 'c':
                if (this.props.lastDraggedPiece && (e.ctrlKey || e.metaKey)) {
                    this.props.copyPiece(this.props.lastDraggedPiece, this.props.widgetId);
                    this.props.setToastMessage('The piece has been copied');
                }
                return;
            case 'V':
            case 'v':
                (e.ctrlKey || e.metaKey) && this.props.lastCopiedPiece && this.pastePiece(this.props.lastCopiedPiece);
                return;
        }
    }

    handleKeyLift = (e: KeyboardEvent) => {
        // Do not apply any shortcuts when you are typing something into an input element
        if (window.document.activeElement && (window.document.activeElement.tagName === 'INPUT' || window.document.activeElement.classList.contains('public-DraftEditor-content'))) {
            return;
        }

        switch (e.key) {
            case '/':
                document.getElementById('piece-search')?.focus();
                return;
        }
    }

    componentWillMount() {
        document.addEventListener('keydown', this.handleKeyPress);
        document.addEventListener('keyup', this.handleKeyLift);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
        document.removeEventListener('keyup', this.handleKeyLift);
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        // The drag event interferes with the click event. Put the change in flowchart state as the last thing in the event queue so that the click event is fired before pointer events are removed from the flowchart
        window.setTimeout(() => {
            this.setState({
                isWaitingForDrag: this.props.isDragging,
            });
        }, 500);

    }

    expandCategory = (category: string) => {
        this.setState(prevState => {
            return {
                expandedCategory: prevState.expandedCategory === category ? undefined : category,
            }
        });
    }

    addPiece = (pieceType: PieceType) => {
        const newId = uuid.v4();
        const flowchartHolderElement = document.getElementById('flowchart-holder');

        if (!flowchartHolderElement) {
            throw new Error('This element needs to exist');
        }

        this.props.addPiece(newId, pieceType);

        this.props.setIsolatedWidgetPiece(this.props.widgetId, {
            piece: newId,
            position: {
                x: flowchartHolderElement.scrollLeft + (0.7 * window.innerWidth) / 2,
                y: flowchartHolderElement.scrollTop + 250,
            },
        });


    }

    updateSearchTerm = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            searchTerm: e.target.value,
        });
    }

    toggleExpandFlowchart = () => {
        this.props.toggleFlowChartExpansion(!this.props.isFlowchartExpanded);
        this.props.toggleTopBarExpansion(false);
    }


    render() {

        if (!this.props.isReadable) {
            return <Redirect to="/dashboard" />;
        }

        let startPiece: JSX.Element | undefined;
        let isolatedPieces: Array<JSX.Element | undefined> = [];

        startPiece = this.props.widget.startPiece ? getComponentForWidgetFields(this.props.widget, this.props.applicationState, this.props.piecesData, this.props.variablesData, this.props.widget.variables, this.props.isWritable, this.props.flowchartPieceActions, this.props.setIsolatedWidgetPiece.bind(this, this.props.widgetId), this.props.removeIsolatedWidgetPiece.bind(this, this.props.widgetId), this.props.registerWidgetVariable.bind(this, this.props.widgetId), this.props.widget.startPiece.piece, undefined) : undefined;

        isolatedPieces = this.props.widget.isolatedPieces.map(isolatedPieceData => {
            const isolatedPiece = isolatedPieceData.piece ? getComponentForWidgetFields(this.props.widget, this.props.applicationState, this.props.piecesData, this.props.variablesData, this.props.widget.variables, this.props.isWritable, this.props.flowchartPieceActions, this.props.setIsolatedWidgetPiece.bind(this, this.props.widgetId), this.props.removeIsolatedWidgetPiece.bind(this, this.props.widgetId), this.props.registerWidgetVariable.bind(this, this.props.widgetId), isolatedPieceData.piece, undefined, isolatedPieceData.position) : undefined;

            return isolatedPiece;
        });

        const piecesByCategory = customFieldPiecesByCategory;

        const categoryOrdering = [
            'Control',
            'Custom Fields',
            'Variables',
            'Boolean Operators',
            'Arithmetic Operators',
            'List Operators',
            'Date Operators',
        ];

        const otherCategories = Object.keys(piecesByCategory).filter(category => {
            return !categoryOrdering.includes(category);
        });

        let projectValues: Array<string> = [];

        switch (this.props.widget.type) {
            case 'User':
                if (this.props.widget.typeId) {
                    const role = this.props.applicationState.structure.roles.byId[this.props.widget.typeId];
                    const level = this.props.applicationState.structure.levels.byId[role.level];
                    projectValues = [level.project];
                }
                break;
            case 'Member':
                if (this.props.widget.typeId) {
                    const memberType = this.props.applicationState.members.types.byId[this.props.widget.typeId];
                    projectValues = [memberType.project];
                }
                break;
            case 'Group':
                if (this.props.widget.typeId) {
                    const groupType = this.props.applicationState.groups.types.byId[this.props.widget.typeId];
                    projectValues = [groupType.project];
                }
                break;
            case 'Workflow':
                if (this.props.widget.typeId) {
                    const workflowType = this.props.applicationState.workflows.types.byId[this.props.widget.typeId];
                    projectValues = [workflowType.project];
                }
                break;
            default:
                projectValues = [];
                break;
        }

        const flowchartHolderElement = document.getElementById('flowchart-holder');
        const flowchartInfoForPiece: FlowchartInfoForPiece = {
            flowchartHolderElement,
            projects: projectValues,
            variables: this.props.widget.variables,
            parentSplitPieceIds: [],
            parentIfPieceIndices: [],
            searchTerm: this.state.searchTerm,
        }

        return (
            <FlowchartWindow
                selectedNudge={this.props.selectedNudge}
                goBack={() => this.props.onChange && this.props.onChange()}
                toggleExpandFlowChart={() => this.toggleExpandFlowchart()}
                isExpanded={this.props.isFlowchartExpanded}>
                <div className={`${this.state.isWaitingForDrag ? styles.waitingForDragFlowchartHolder : styles.normalFlowchartHolder} ${styles.flowchartWithPiceContainer}`}>
                    <section className={styles.piecesCollection}>
                        <section className={styles.searchSection}><input type="text" id="piece-search" placeholder="Search for pieces" className={styles.searchInput} value={this.state.searchTerm} onChange={this.updateSearchTerm} /></section>
                        {categoryOrdering.concat(otherCategories).map(category => {
                            let piecesInCategory: Array<JSX.Element | undefined> = [];
                            if (category in piecesByCategory) {
                                piecesInCategory = (piecesByCategory as { [key: string]: CategoryValue; })[category].pieces.map(piece => (!!this.state.searchTerm && !piece.name.toLocaleLowerCase().includes(this.state.searchTerm.toLocaleLowerCase())) ? undefined :
                                    <section className={styles.pieceEntry} key={piece.name} onClick={() => this.addPiece(piece.type)}>
                                        <div className={styles.pieceEntryName}>{piece.name}</div>
                                        {piece.tooltip && <a target='_blank' rel="noreferrer" href={piece.tooltip}>
                                            <span className={styles.infoIcon}> <ExternalIcon /> </span></a>
                                        }
                                    </section>).filter(pieceElement => typeof pieceElement !== 'undefined');
                            }
                            return piecesInCategory.length > 0 && <section key={category}>
                                <section className={styles.categoryHeading} onClick={() => this.expandCategory(category)}>
                                    <div className={styles.categoryIndicator} style={{ background: (piecesByCategory as { [key: string]: CategoryValue; })[category].color }}></div>
                                    <div className={styles.categoryName}>{category}</div>
                                    <div className={this.state.expandedCategory === category ? styles.expandedCategoryChevron : styles.categoryChevron}><ChevronDownIcon /></div>
                                </section>
                                {(!!this.state.searchTerm || this.state.expandedCategory === category) && <div>
                                    {category in piecesByCategory && piecesInCategory}
                                </div>}
                            </section>;
                        })}
                    </section>
                    <div id="flowchart-holder" className={`${styles.flowchartContainer} ${!this.props.isWritable && ' react-drag-disabled'}`}>
                        <FlowchartContext.Provider value={flowchartInfoForPiece}>
                            {startPiece}
                            {isolatedPieces.map((isolatedPiece, i) => <div className={styles.pieceHolder} key={i}>{isolatedPiece}</div>)}
                        </FlowchartContext.Provider>
                    </div>
                </div>
            </FlowchartWindow>
        );
    }
}

const WidgetFlowchart = connect(mapStateToProps, mapDispatchToProps)(ConnectedWidgetFlowchart);

export default WidgetFlowchart;