import React, { Component } from 'react';
import styles from './FormatPiece.module.scss';
import Input from '../Input';
import FlowchartPiece, { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem, { Option } from '../drop-down/ListItem';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setVariablePiece, setFormatModifier } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import { valuePieceSlotTarget } from './utilities';
import { VariableType } from '../../../shared/store/flowchart/variables/types';
import moment from 'moment';
import { FlowchartContext, PieceHighlightColour } from '../../../contexts/flowchart-context';
import DropDownSearchBox, { getFilteredOptionsBySearch } from '../drop-down/DropDownSearchBox';


type FormatPieceProps = {
    nextPiece?: JSX.Element,

    variablePiece?: JSX.Element,
    variableType?: VariableType,
    formatModifier?: string,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string | undefined) => dispatch(setTargetPiece(pieceId)),
        setFormatModifier: (targetPieceId: string, formatModifier?: string) => dispatch(setFormatModifier(targetPieceId, formatModifier)),
        setVariablePiece: (pieceId: string, value: string) => dispatch(setVariablePiece(pieceId, value)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = FormatPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type SetPieceState = {
    isHoveringOverVariablePiece: boolean,
    dateFormatSearchTerm: string,
}

class ConnectedSetPiece extends Component<Props, SetPieceState> {

    state = {
        isHoveringOverVariablePiece: false,
        dateFormatSearchTerm: "",
    };

    handleHoverOverVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: false,
        });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && this.state.isHoveringOverVariablePiece) {

            this.props.setVariablePiece(this.props.pieceId, this.props.lastDraggedPiece.id);
            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverVariablePiece: false,
            });
        }
    }

    searchForDateFormats = (searchTerm: string) => {
        this.setState({ dateFormatSearchTerm: searchTerm });
    }

    render() {
        return <FlowchartContext.Consumer>
            {
                (flowchartContext) => {

                    const highlightColor = flowchartContext.highlights && flowchartContext.highlights[this.props.pieceId];
                    let highlightClass = styles.noHighlight;

                    switch (highlightColor) {
                        case PieceHighlightColour.GREEN:
                            highlightClass = styles.addedHighlight;
                            break;
                        case PieceHighlightColour.YELLOW:
                            highlightClass = styles.updatedHighlight;
                            break;
                        case PieceHighlightColour.PURPLE:
                            highlightClass = styles.movedHighlight;
                            break;
                        case PieceHighlightColour.RED:
                            highlightClass = styles.deletedHighlight;
                            break;
                    }

                    let formatModifierMarkup: JSX.Element | undefined;

                    if (this.props.variableType) {
                        switch (this.props.variableType) {
                            case VariableType.DATE:
                                const dateFormats = [
                                    'YYYY-MM-DD',
                                    'DD MMM YYYY',
                                    'HH:mm:ss',
                                    'hh:mm:ss A',
                                    'YYYY-MM-DD HH:mm:ss',
                                    'DD MMM YYYY hh:mm:ss A',
                                ];

                                const dateFormatSelector = <SelectBox theme="aqua" selectionPromptText={this.props.formatModifier && dateFormats.includes(this.props.formatModifier) ? this.props.formatModifier : undefined}>
                                    <DropDownList theme="aqua">
                                        {dateFormats.map(format => <ListItem theme="aqua" name={`${format} (e.g. ${moment().format(format)})`} key={format} value={format} onClick={this.props.setFormatModifier.bind(this, this.props.pieceId)} />)}
                                    </DropDownList>
                                </SelectBox>;

                                formatModifierMarkup = <span className={styles.formatModifierHolder}>
                                    <div className={styles.text}>from these options:</div>
                                    {dateFormatSelector}
                                </span>
                                break;
                            case VariableType.NUMBER:

                                formatModifierMarkup = <span className={styles.formatModifierHolder}>
                                    <div className={styles.text}>with</div>
                                    <Input canReceiveDrag={false} type="number" defaultText={!isNaN(Number(this.props.formatModifier)) ? this.props.formatModifier : undefined} onChange={this.props.setFormatModifier.bind(this, this.props.pieceId)} />
                                    <div className={styles.text}>digits after the decimal point</div>
                                </span>
                                break;
                            case VariableType.TEXT_LIST:
                                const separatorFormats = [{
                                    name: 'Comma separator (,)',
                                    value: ','
                                }, {
                                    name: 'Space separator ( )',
                                    value: ' '
                                }];

                                let selectedFormatModifierName: string | undefined = undefined;

                                if (this.props.formatModifier && separatorFormats.map(separator => separator.value).includes(this.props.formatModifier)) {
                                    const selectedFormat = separatorFormats.find(separator => separator.value === this.props.formatModifier);
                                    if (selectedFormat) {
                                        selectedFormatModifierName = selectedFormat.name;
                                    }
                                }

                                const separatorSelector = <SelectBox theme="aqua" selectionPromptText={selectedFormatModifierName}>
                                    <DropDownList theme="aqua">
                                        {separatorFormats.map(format => <ListItem theme="aqua" name={format.name} key={format.value} value={format.value} onClick={this.props.setFormatModifier.bind(this, this.props.pieceId)} />)}
                                    </DropDownList>
                                </SelectBox>;

                                formatModifierMarkup = <span className={styles.formatModifierHolder}>
                                    <div className={styles.text}>with</div>
                                    {separatorSelector}
                                    <div className={styles.text}>as the separator</div>
                                </span>
                                break;
                            default:
                                break;
                        }
                    }

                    return (<FlowchartPiece {...this.props}>
                        <div className={styles.formatPieceWrapper + ' ' + highlightClass}>
                            <div className={styles.text}>format</div>
                            {this.props.variablePiece ? this.props.variablePiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverVariablePiece && !!this.props.targetPiece} onMouseOver={this.handleHoverOverVariablePiece} onMouseOut={this.handleHoverOutOfVariablePiece} isDisabled />}
                            {formatModifierMarkup}
                        </div>
                    </FlowchartPiece>)
                }
            }
        </FlowchartContext.Consumer>

    }
}

const SetPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedSetPiece)

export default SetPiece;