import { takeEvery, put, all, select, PutEffect } from 'redux-saga/effects'
import { registerWidgetCreation, unregisterWidgetCreation } from '../my-data/widgets/actions';
import { ADD_WIDGET, DELETE_WIDGET, AddWidgetAction, DeleteWidgetAction, UpdateWidgetAction, UPDATE_WIDGET } from './types';
import { addVariable, addVariables } from '../flowchart/variables/actions';
import { IUpdateableVariableData, IVariable, VariableType } from '../flowchart/variables/types';
import uuid from 'uuid';
import { addPiece } from '../flowchart/pieces/actions';
import { PieceType } from '../flowchart/pieces/types';
import { updateWidgetStartPiece, updateWidgetRichTextStartPiece, updateWidgetMessageCache, updateWidgetCache, updateWidgetTableCache, registerWidgetRichTextVariable } from './actions';
import { ApplicationState } from '../types';
import { getShowDataForWidget, getValueForMessageWidget, getValueForTableWidget } from '../../helpers/widgets';
import { getVisibleUserIds, getVisibleMemberIds, getVisibleGroupIds, getVisibleWorkflowIds } from '../../helpers/visible-entities';
import moment from 'moment';

function* recalculateDataAndCreateSeedFlowchartAndRegisterWidget(action: AddWidgetAction) {
    let variableName: string, variableType: VariableType;

    const startPieceId = uuid.v4();

    switch (action.payload.type) {
        case 'User':
            variableName = 'User';
            variableType = VariableType.USER;
            break;
        case 'Member':
            variableName = 'Member';
            variableType = VariableType.MEMBER;
            break;
        case 'Group':
            variableName = 'Group';
            variableType = VariableType.GROUP;
            break;
        case 'Workflow':
            variableName = 'Workflow';
            variableType = VariableType.WORKFLOW;
            break;
        default:
            throw new Error('Unknown entity');
    }

    const seedFlowchartSideEffects: Array<PutEffect<any>> = [
        put(registerWidgetCreation(action.payload.id)),
        put(addVariable({
            id: action.payload.seedEntityVariable,
            name: variableName,
            type: variableType,
        })),
        put(addPiece(startPieceId, PieceType.START)),
        put(updateWidgetStartPiece({
            piece: startPieceId,
            position: {
                x: 0,
                y: 0,
            }
        }, action.payload.id)),
    ];

    if (action.payload.seedUsersVariable) {
        seedFlowchartSideEffects.push(put(addVariable({
            id: action.payload.seedUsersVariable,
            name: 'Users',
            type: VariableType.USERS_LIST,
        })));
    }

    if (action.payload.seedMembersVariable) {
        seedFlowchartSideEffects.push(put(addVariable({
            id: action.payload.seedMembersVariable,
            name: 'Members',
            type: VariableType.MEMBERS_LIST,
        })));
    }

    if (action.payload.seedGroupsVariable) {
        seedFlowchartSideEffects.push(put(addVariable({
            id: action.payload.seedGroupsVariable,
            name: 'Groups',
            type: VariableType.GROUPS_LIST,
        })));
    }

    if (action.payload.seedWorkflowsVariable) {
        seedFlowchartSideEffects.push(put(addVariable({
            id: action.payload.seedWorkflowsVariable,
            name: 'Workflows',
            type: VariableType.WORKFLOWS_LIST,
        })));
    }

    const state: ApplicationState = yield select();

    const visibleUserIds = getVisibleUserIds(state, true);
    const visibleMemberIds = getVisibleMemberIds(state, true);
    const visibleGroupIds = getVisibleGroupIds(state, true);
    const visibleWorkflowIds = getVisibleWorkflowIds(state, visibleUserIds, visibleMemberIds, visibleGroupIds, true);

    if (action.payload.displayType === 'message') {
        const richTextStartPieceId = uuid.v4();

        seedFlowchartSideEffects.push(put(addPiece(richTextStartPieceId, PieceType.START)));
        seedFlowchartSideEffects.push(put(updateWidgetRichTextStartPiece({
            piece: richTextStartPieceId,
            position: {
                x: 0,
                y: 0,
            }
        }, action.payload.id)));

        const messageValue = getValueForMessageWidget(action.payload.id, visibleUserIds, visibleMemberIds, visibleGroupIds, visibleWorkflowIds, state);

        if (messageValue) {
            seedFlowchartSideEffects.push(put(updateWidgetMessageCache(action.payload.id, messageValue)));
        }
    } else if (action.payload.displayType === 'formatted-table') {
        const richTextStartPieceId = uuid.v4();

        seedFlowchartSideEffects.push(put(addPiece(richTextStartPieceId, PieceType.START)));
        seedFlowchartSideEffects.push(put(updateWidgetRichTextStartPiece({
            piece: richTextStartPieceId,
            position: {
                x: 0,
                y: 0,
            }
        }, action.payload.id)));

        const newCellId = uuid.v4();
        const newRowId = uuid.v4();
        const newTableId = uuid.v4();

        const now = moment().format();

        const styledTableVariables: Array<IUpdateableVariableData> = [{
            id: newCellId,
            name: 'New Cell',
            type: VariableType.STYLED_TABLE_CELL,
        }, {
            id: newRowId,
            name: 'New Row',
            type: VariableType.STYLED_TABLE_ROW,
        }, {
            id: newTableId,
            name: 'New Table',
            type: VariableType.STYLED_TABLE_DATA,
        }];

        seedFlowchartSideEffects.push(put(addVariables(styledTableVariables)));

        for (const styledTableVariable of styledTableVariables) {
            seedFlowchartSideEffects.push(put(registerWidgetRichTextVariable(styledTableVariable.id, action.payload.id)));
        }

        const tableValue = getValueForTableWidget(action.payload.id, visibleUserIds, visibleMemberIds, visibleGroupIds, visibleWorkflowIds, state);

        if (tableValue) {
            seedFlowchartSideEffects.push(put(updateWidgetTableCache(action.payload.id, tableValue)));
        }
    } else {
        const widgetId = action.payload.id;
        let runningSlice = getShowDataForWidget(widgetId, state, visibleUserIds, visibleMemberIds, visibleGroupIds, visibleWorkflowIds);
        const widgetData = runningSlice.widgetData;

        while (runningSlice.lastEntityId) {
            runningSlice = getShowDataForWidget(widgetId, state, visibleUserIds, visibleMemberIds, visibleGroupIds, visibleWorkflowIds, runningSlice.lastEntityId);

            widgetData.entries = widgetData.entries.concat(runningSlice.widgetData.entries);
            widgetData.entityIds = widgetData.entityIds.concat(runningSlice.widgetData.entityIds);

            for (let i = 0; i < runningSlice.widgetData.chartLabels.length; i += 1) {
                const chartLabel = runningSlice.widgetData.chartLabels[i];
                const chartData = runningSlice.widgetData.chartData[i];

                const existingLabelIndex = widgetData.chartLabels.indexOf(chartLabel);

                if (existingLabelIndex === -1) {
                    widgetData.chartLabels.push(chartLabel);
                    widgetData.chartData.push(chartData);
                } else {
                    widgetData.chartData[existingLabelIndex] += chartData;
                }
            }
        }

        seedFlowchartSideEffects.push(put(updateWidgetCache(action.payload.id, widgetData)));
    }

    yield all(seedFlowchartSideEffects);
}

function* recalculateWidgteCache(action: UpdateWidgetAction) {
    const updateWidgetSideEffects = [];

    const state: ApplicationState = yield select();

    const visibleUserIds = getVisibleUserIds(state, true);
    const visibleMemberIds = getVisibleMemberIds(state, true);
    const visibleGroupIds = getVisibleGroupIds(state, true);
    const visibleWorkflowIds = getVisibleWorkflowIds(state, visibleUserIds, visibleMemberIds, visibleGroupIds, true);

    if (action.payload.displayType === 'message') {
        const messageValue = getValueForMessageWidget(action.payload.id, visibleUserIds, visibleMemberIds, visibleGroupIds, visibleWorkflowIds, state);

        if (messageValue) {
            updateWidgetSideEffects.push(put(updateWidgetMessageCache(action.payload.id, messageValue)));
        }
    } else if (action.payload.displayType === 'formatted-table') {
        const tableData = getValueForTableWidget(action.payload.id, visibleUserIds, visibleMemberIds, visibleGroupIds, visibleWorkflowIds, state);

        if (tableData) {
            updateWidgetSideEffects.push(put(updateWidgetTableCache(action.payload.id, tableData)));
        }
    } else {
        const widgetId = action.payload.id;
        let runningSlice = getShowDataForWidget(widgetId, state, visibleUserIds, visibleMemberIds, visibleGroupIds, visibleWorkflowIds);
        const widgetData = runningSlice.widgetData;

        while (runningSlice.lastEntityId) {
            runningSlice = getShowDataForWidget(widgetId, state, visibleUserIds, visibleMemberIds, visibleGroupIds, visibleWorkflowIds, runningSlice.lastEntityId);

            widgetData.entries = widgetData.entries.concat(runningSlice.widgetData.entries);
            widgetData.entityIds = widgetData.entityIds.concat(runningSlice.widgetData.entityIds);

            for (let i = 0; i < runningSlice.widgetData.chartLabels.length; i += 1) {
                const chartLabel = runningSlice.widgetData.chartLabels[i];
                const chartData = runningSlice.widgetData.chartData[i];

                const existingLabelIndex = widgetData.chartLabels.indexOf(chartLabel);

                if (existingLabelIndex === -1) {
                    widgetData.chartLabels.push(chartLabel);
                    widgetData.chartData.push(chartData);
                } else {
                    widgetData.chartData[existingLabelIndex] += chartData;
                }
            }
        }

        updateWidgetSideEffects.push(put(updateWidgetCache(action.payload.id, widgetData)));
    }

    yield all(updateWidgetSideEffects);
}

function* unregisterWidgetCreationForUser(action: DeleteWidgetAction) {
    yield put(unregisterWidgetCreation(action.id));
}

export function* watchWidgetCreationRequest() {
    yield takeEvery(ADD_WIDGET, recalculateDataAndCreateSeedFlowchartAndRegisterWidget);
}

export function* watchWidgetUpdateRequest() {
    yield takeEvery(UPDATE_WIDGET, recalculateWidgteCache);
}

export function* watchWidgetDeletionRequest() {
    yield takeEvery(DELETE_WIDGET, unregisterWidgetCreationForUser);
}