import { PiecePositionState } from '../../../helpers/common-types';
import { NormalizedModel, Synchronizable } from '../../normalized-model';

export const SELECT_REPORT_SCHEDULE = 'SELECT_REPORT_SCHEDULE';
export const UN_SELECT_REPORT_SCHEDULE = 'UN_SELECT_REPORT_SCHEDULE';
export const RE_ORDER_REPORT_SCHEDULES = 'RE_ORDER_REPORT_SCHEDULES';

export const ADD_REPORT_SCHEDULE = 'ADD_REPORT_SCHEDULE';
export const INSTANTIATE_REPORT_SCHEDULE = 'INSTANTIATE_REPORT_SCHEDULE';
export const UPDATE_REPORT_SCHEDULE = 'UPDATE_REPORT_SCHEDULE';
export const DELETE_REPORT_SCHEDULE = 'DELETE_REPORT_SCHEDULE';

export const UPDATE_REPORT_SCHEDULE_DATA = 'UPDATE_REPORT_SCHEDULE_DATA';

export const UPDATE_REPORT_SCHEDULES_DATA = 'UPDATE_REPORT_SCHEDULES_DATA';
export const SYNCHRONIZE_REPORT_SCHEDULES_DATA = 'SYNCHRONIZE_REPORT_SCHEDULES_DATA';
export const CLEAR_REPORT_SCHEDULES_DELTA = 'CLEAR_REPORT_SCHEDULES_DELTA';

export enum ReportScheduleFrequency {
    MONTHLY = 'MONTHLY',
    QUARTERLY = 'QUARTERLY',
}

export interface INewReportScheduleData {
    name: string;
    type: string;
    user: string;
    frequency: ReportScheduleFrequency,
    dayOfMonth?: number,
    startDate?: number,
    emails?: string;
}

export interface IUpdateableReportScheduleData extends INewReportScheduleData {
    id: string,
}

export interface IReportSchedule extends IUpdateableReportScheduleData, Synchronizable {
}

export interface ReportScheduleState extends NormalizedModel<IReportSchedule> {
    selected: string | undefined,
}


interface SelectReportScheduleAction {
    type: typeof SELECT_REPORT_SCHEDULE,
    id: string,
}

interface UnSelectReportScheduleAction {
    type: typeof UN_SELECT_REPORT_SCHEDULE,
}

interface ReOrderReportSchedulesAction {
    type: typeof RE_ORDER_REPORT_SCHEDULES,
    sourceIndex: number,
    destinationIndex: number,
}

export interface AddReportScheduleAction {
    type: typeof ADD_REPORT_SCHEDULE,
    payload: IReportSchedule,
    currentTime: string,
}

export interface InstantiateReportScheduleAction {
    type: typeof INSTANTIATE_REPORT_SCHEDULE,
    payload: IReportSchedule,
    currentTime: string,
}

export interface UpdateReportScheduleAction {
    type: typeof UPDATE_REPORT_SCHEDULE,
    payload: IUpdateableReportScheduleData,
    currentTime: string,
}

export interface DeleteReportScheduleAction {
    type: typeof DELETE_REPORT_SCHEDULE,
    id: string,
    currentTime: string,
}

export interface IUpdateReportScheduleData {
    type: typeof UPDATE_REPORT_SCHEDULE_DATA,
    data: ReportScheduleState,
}


export interface UpdateReportSchedulesData {
    type: typeof UPDATE_REPORT_SCHEDULES_DATA,
    data: Array<IReportSchedule>,
}

export interface SynchronizeReportSchedulesData {
    type: typeof SYNCHRONIZE_REPORT_SCHEDULES_DATA,
    data: Array<IReportSchedule>,
}

export interface ClearReportSchedulesDelta {
    type: typeof CLEAR_REPORT_SCHEDULES_DELTA,
}

export type ReportScheduleActionTypes = SelectReportScheduleAction | UnSelectReportScheduleAction | ReOrderReportSchedulesAction | AddReportScheduleAction | InstantiateReportScheduleAction | UpdateReportScheduleAction | DeleteReportScheduleAction | IUpdateReportScheduleData | UpdateReportSchedulesData | SynchronizeReportSchedulesData | ClearReportSchedulesDelta;