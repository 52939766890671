import React from 'react';
import styles from './Users.module.scss';
import { Switch, Route, Redirect } from "react-router-dom";

import { ApplicationState } from '../../shared/store/types';

import { Permissions } from '../../shared/store/permissions/types';

import Configuration from './configuration/Configuration';
import List from './list/List';

import { connect } from 'react-redux';

import { ReactComponent as UserIcon } from '../../common/assets/user-certification.svg';
import Header from '../../widgets/header/Header';
import { getBulkActionsSelector } from '../../selectors/selectors';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {

    const canViewUserConfiguration =
        state.permissions.myPermissions.general.UserConfiguration === Permissions.WRITE ||
        state.permissions.myPermissions.general.UserConfiguration === Permissions.READ;

    const canViewUserList =
        state.permissions.myPermissions.general.Users === Permissions.WRITE ||
        state.permissions.myPermissions.general.Users === Permissions.READ;

    return {
        canGoToConfiguration: canViewUserConfiguration,
        canGoToList: canViewUserList,
        hasUsers: state.users.allEntries.length > 0,
    };
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;

const ConnectedUsers: React.FC<Props> = (props) => {
    const { canGoToConfiguration, canGoToList, hasUsers } = props;

    if (!(canGoToConfiguration || canGoToList)) {
        return <Redirect to="/dashboard" />;
    }

    const tabs = [
        {
            tabName: "List",
            link: "/users/list",
            hasAccess: canGoToList,
        },
        {
            tabName: "Configuration",
            link: "/users/configuration",
            hasAccess: canGoToConfiguration,
        }
    ];

    return (
        <section className={styles.FocusSpace}>

            <Header
                pageName={"users"}
                tabs={tabs}
                icon={<UserIcon />}
            />

            <div className={styles.innerFocus}>
                <Switch>
                    <Route path="/users/configuration" render={() => <Configuration />} />
                    <Route path="/users/list" render={() => <List />} />
                    <Route render={() => <Redirect to={canGoToList && (hasUsers || !canGoToConfiguration) ? '/users/list' : '/users/configuration'} />} />
                </Switch>
            </div>
        </section>
    );
};

const Users = connect(mapStateToProps)(ConnectedUsers);

export default Users;