import { Component } from 'react';
import styles from './Configuration.module.scss';

import CardsList from '../../../widgets/card/CardsList';
import ModifyForm from '../../../widgets/card/ModifyForm';
import { translatePhrase } from '../../../shared/helpers/translation';
import EnhancedInputText from '../../../widgets/form/InputText';
import { ApplicationState } from '../../../shared/store/types';
import { formatNumberWithOrdinal, isUUID, validateEmail } from '../../../shared/helpers/utilities';
import { IUpdateableReportScheduleData, ReportScheduleFrequency } from '../../../shared/store/reports/schedules/types';
import { deleteReportSchedule, updateReportSchedule } from '../../../shared/store/reports/schedules/actions';
import { Dispatch } from 'redux';
import { setToastMessage } from '../../../shared/store/my-data/actions';
import { getReadableDataForCustomField } from '../../../shared/store/custom-fields';
import { connect } from 'react-redux';
import moment, { Moment } from 'moment';

export interface OwnProps {
    scheduleId: string,
    isReadOnly: boolean,
    remainingScheduleNames: Array<string>,

}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    const selectedReportSchedule = state.reports.schedules.byId[ownProps.scheduleId];
    const selectedReportType = state.reports.types.byId[selectedReportSchedule.type];

    return {
        selectedReportSchedule,
        selectedReportType,

        usersData: state.users,
        reportSchedulesData: state.reports.schedules,

        isSuperUser: !isUUID(state.myData.id),
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        updateReportSchedule: (payload: IUpdateableReportScheduleData) => dispatch(updateReportSchedule(payload)),
        deleteReportSchedule: (id: string) => dispatch(deleteReportSchedule(id)),

        setToastMessage: (message: string) => dispatch(setToastMessage(message)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

export interface OwnState {
    isShowingModifyVerticalForm: boolean,
    modifyingVerticalName: string,
    modifyingVerticalUser: string,
    modifyingVerticalFrequency: string,
    modifyingVerticalDayOfMonth: number,

    shouldSendEmails: boolean,
    modifyingVerticalEmails: string,

    isUpdating: boolean,
};

class ConnectedReportSchedule extends Component<Props, OwnState> {

    state: OwnState = {
        isShowingModifyVerticalForm: false,
        modifyingVerticalName: '',
        modifyingVerticalUser: '',
        modifyingVerticalFrequency: '',
        modifyingVerticalDayOfMonth: 1,

        shouldSendEmails: true,
        modifyingVerticalEmails: '',

        isUpdating: false,
    };

    static defaultProps = {
        isReadOnly: false,
    }

    static getDerivedStateFromProps(props: Readonly<Props>, state: Readonly<OwnState>) {
        if (state.isUpdating && !state.modifyingVerticalName) {
            return {
                modifyingVerticalName: props.selectedReportSchedule ? props.selectedReportSchedule.name : '',
                modifyingVerticalUser: props.selectedReportSchedule.user ? props.selectedReportSchedule.user : '',
                modifyingVerticalEmails: props.selectedReportSchedule.emails ? props.selectedReportSchedule.emails : '',
                modifyingVerticalFrequency: props.selectedReportSchedule.frequency ? props.selectedReportSchedule.frequency : '',
                modifyingVerticalDayOfMonth: props.selectedReportSchedule.dayOfMonth ? props.selectedReportSchedule.dayOfMonth : 1,
            };
        }

        return null;
    }

    toggleVerticalEditForm = () => {
        if (this.state.isShowingModifyVerticalForm) {
            this.setState({
                isShowingModifyVerticalForm: false,
                modifyingVerticalName: '',
                modifyingVerticalUser: '',
                shouldSendEmails: true,
                modifyingVerticalEmails: 'Yes',
                modifyingVerticalFrequency: '',
                modifyingVerticalDayOfMonth: 1,
            });
        } else {
            this.setState({
                isShowingModifyVerticalForm: true,
                modifyingVerticalName: this.props.selectedReportSchedule.name,
                modifyingVerticalUser: this.props.selectedReportSchedule.user,
                shouldSendEmails: !!this.props.selectedReportSchedule.emails,
                modifyingVerticalEmails: this.props.selectedReportSchedule.emails || '',
                modifyingVerticalFrequency: this.props.selectedReportSchedule.frequency,
                modifyingVerticalDayOfMonth: this.props.selectedReportSchedule.dayOfMonth || 1,
            });
        }
    }

    updateFieldName = (value: string) => {
        this.setState({
            modifyingVerticalName: value,
        });
    }

    updateFieldUser = (value: string) => {
        this.setState({
            modifyingVerticalUser: value,
        });
    }

    updateShouldSendEmails = (value: string) => {
        this.setState(prevState => {
            const shouldSendEmails = value === 'Yes';

            return {
                shouldSendEmails,
                modifyingVerticalEmails: shouldSendEmails ? prevState.modifyingVerticalEmails : '',
            }
        });
    }

    updateFieldEmails = (value: string) => {
        this.setState({
            modifyingVerticalEmails: value,
        });
    }

    updateFieldFrequency = (value: string) => {
        this.setState({
            modifyingVerticalFrequency: value,
        });
    }

    updateFieldDayOfMonth = (value: string) => {
        this.setState({
            modifyingVerticalDayOfMonth: isNaN(Number(value)) ? 1 : Number(value),
        });
    }

    deleteReportSchedule = () => {
        this.props.deleteReportSchedule(this.props.scheduleId);
    }

    updateCustomField = () => {
        const frequency = this.state.modifyingVerticalFrequency as keyof typeof ReportScheduleFrequency;

        this.props.updateReportSchedule({
            id: this.props.scheduleId,
            name: this.state.modifyingVerticalName,
            type: this.props.selectedReportType.id,
            frequency: ReportScheduleFrequency[frequency],
            dayOfMonth: this.state.modifyingVerticalDayOfMonth || undefined,
            user: this.state.modifyingVerticalUser,
            emails: this.state.modifyingVerticalEmails,
        });

        this.setState({
            isShowingModifyVerticalForm: false,
            modifyingVerticalName: '',
            modifyingVerticalUser: '',
            modifyingVerticalEmails: '',
            modifyingVerticalFrequency: '',
            modifyingVerticalDayOfMonth: 1,
            isUpdating: true,
        });
    }

    validateVerticalForm = () => {
        const frequencyTypes = Object.keys(ReportScheduleFrequency);

        if (!this.state.modifyingVerticalName) {
            return 'Enter a valid name';
        }

        if (this.props.remainingScheduleNames.includes(this.state.modifyingVerticalName.toLocaleLowerCase())) {
            return 'A report schedule with this name already exists'
        }

        if (!this.state.modifyingVerticalUser) {
            return 'Enter a valid user';
        }

        if (!frequencyTypes.includes(this.state.modifyingVerticalFrequency)) {
            return 'Enter a valid frequency';
        }

        const dayOfMonth = this.state.modifyingVerticalDayOfMonth;

        if (dayOfMonth < 1 || dayOfMonth > 31) {
            return 'Enter a valid day of month (between 1 and 31)';
        }

        if (this.state.shouldSendEmails) {

            if (!this.state.modifyingVerticalEmails) {
                return 'Enter at least one email address';
            }

            const emails = this.state.modifyingVerticalEmails.split(',').map(email => email.trim());
            const invalidEmails = emails.filter(email => !validateEmail(email));

            if (invalidEmails.length > 0) {
                const errorMessage = `Invalid email${invalidEmails.length > 1 ? 's' : ''}: ` + invalidEmails.join(',');
                return errorMessage;
            }
        }

        return true;
    }

    render() {

        const usersList = this.props.usersData.allEntries
            .filter(userId => {
                const userData = this.props.usersData.byId[userId];

                return userData.projects.includes(this.props.selectedReportType.project);
            })
            .map(userId => {
                const user = this.props.usersData.byId[userId];
                let userName = user.customFields[this.props.usersData.nameFieldId];

                const nameField = this.props.usersData.customFields.byId[this.props.usersData.nameFieldId];

                userName = getReadableDataForCustomField(userName, nameField, userId, 'user');

                return {
                    name: userName,
                    value: userId,
                };
            });

        const defaultUserName = usersList.find(user => user.value === this.props.selectedReportSchedule.user)?.name;


        const frequencyOptions = Object.keys(ReportScheduleFrequency).map(name => {
            const uppercaseName = name.split('_').join(' ');
            const normalisedName = uppercaseName[0] + uppercaseName.substring(1).toLocaleLowerCase();

            return {
                name: normalisedName,
                value: name,
            }
        });

        const resetTimeValuesForDate = (date: Moment) => {
            return date.hours(0).minutes(0).seconds(0).milliseconds(0);
        }


        let frequencyHelperMessage = '';

        let reportStartDate = resetTimeValuesForDate(moment());
        let reportEndDate = resetTimeValuesForDate(moment());
        let reportGenerationDate = resetTimeValuesForDate(moment());

        const dayOfMonth = this.state.modifyingVerticalDayOfMonth || 1;
        let lastDayOfMonth = dayOfMonth;
        const formattedDayOfMonth = moment().month(0).date(dayOfMonth).format('Do');

        if (this.state.modifyingVerticalDayOfMonth > 0 && this.state.modifyingVerticalDayOfMonth < 32) {
            switch (this.state.modifyingVerticalFrequency) {
                case ReportScheduleFrequency.MONTHLY:
                    reportGenerationDate = resetTimeValuesForDate(moment()).add(1, 'month');

                    lastDayOfMonth = reportGenerationDate.endOf('month').date();

                    reportGenerationDate = reportGenerationDate.date(dayOfMonth < lastDayOfMonth ? dayOfMonth : lastDayOfMonth);

                    reportStartDate = moment(reportGenerationDate).subtract(1, 'month').date(1);
                    reportEndDate = moment(reportStartDate).add(1, 'month').subtract(1, 'day');

                    frequencyHelperMessage = `Runs on ${formattedDayOfMonth} of every month for the previous month. Next report: ${reportStartDate.format('Do MMM')} - ${reportEndDate.format('Do MMM')} generated on ${reportGenerationDate.format('Do MMM')}`;
                    break;
                case ReportScheduleFrequency.QUARTERLY:
                    reportGenerationDate = resetTimeValuesForDate(moment()).add(1, 'month');

                    // The month is a 0-based index, so the first month of every quarter is divisible by 3
                    // The loop below keeps adding a month until the month is divisible by 3
                    while (reportGenerationDate.month() % 3) {
                        reportGenerationDate.add(1, 'month');
                    }

                    lastDayOfMonth = reportGenerationDate.endOf('month').date();

                    reportGenerationDate = reportGenerationDate.date(dayOfMonth < lastDayOfMonth ? dayOfMonth : lastDayOfMonth);

                    reportStartDate = moment(reportGenerationDate).subtract(3, 'months').date(1);
                    reportEndDate = moment(reportStartDate).add(3, 'months').subtract(1, 'day');

                    frequencyHelperMessage = `Report will be generated on the ${formattedDayOfMonth} of Jan, Apr, Jul, Oct. Next report: ${reportStartDate.format('Do MMM')} - ${reportEndDate.format('Do MMM')} generated on ${reportGenerationDate.format('Do MMM')}`;
                    break;
                default:
                    break;
            }
        }

        const selectedFrequencyOption = frequencyOptions.find(option => option.value === this.props.selectedReportSchedule.frequency);

        const editVerticalForm = <ModifyForm isNew={false} submitForm={this.updateCustomField} cancelForm={this.toggleVerticalEditForm} validateForm={this.validateVerticalForm}>
            <EnhancedInputText placeholder="Name" onEnterPress={this.updateCustomField} onChange={this.updateFieldName} default={translatePhrase(this.props.selectedReportSchedule.name)} />
            <EnhancedInputText placeholder="User" onEnterPress={this.updateCustomField} onChange={this.updateFieldUser} default={defaultUserName ? translatePhrase(defaultUserName) : ''} options={usersList} />
            <EnhancedInputText placeholder="Frequency" onEnterPress={this.updateCustomField} onChange={this.updateFieldFrequency} options={frequencyOptions} default={selectedFrequencyOption?.name} />
            <EnhancedInputText placeholder="Day of month" type="number" onEnterPress={this.updateCustomField} onChange={this.updateFieldDayOfMonth} default={String(this.state.modifyingVerticalDayOfMonth)} />
            {frequencyHelperMessage && dayOfMonth <= 31 && <section className={styles.frequencyHelperMessage}>{frequencyHelperMessage}</section>}
            <EnhancedInputText isBooleanField placeholder="Send emails" onEnterPress={this.updateCustomField} onChange={this.updateShouldSendEmails} defaultBooleanValue={!!this.props.selectedReportSchedule.emails} />
            {this.state.shouldSendEmails && <EnhancedInputText placeholder="Emails" onEnterPress={this.updateCustomField} onChange={this.updateFieldEmails} default={translatePhrase(this.props.selectedReportSchedule.emails || '')} />}
        </ModifyForm>;

        const emptyFunction = () => { };


        return <CardsList
            key={this.props.selectedReportSchedule.name}
            heading={this.props.selectedReportSchedule.name}
            cards={[]}
            onSelectCard={emptyFunction}
            onUnselectCard={emptyFunction}
            onDeleteCard={emptyFunction}
            onEditCard={emptyFunction}
            onReorderCards={emptyFunction}
            isShowingAddForm={false}
            isShowingEditForm={false}
            onAddCard={emptyFunction}
            isDeleteRestricted={false}
            isReadOnly={this.props.isReadOnly}

            isAddRestricted={true}
            isShowingIndex={false}
            isShowingEditVerticalForm={this.state.isShowingModifyVerticalForm}
            modifyVerticalForm={editVerticalForm}
            onDeleteVertical={this.deleteReportSchedule}
            onEditVertical={this.toggleVerticalEditForm}
            subHeader={selectedFrequencyOption ? `${selectedFrequencyOption.name} for ${defaultUserName}` : undefined}
            isCollapsible={true}
        >
        </CardsList>
    }
}

const ReportSchedule = connect(mapStateToProps, mapDispatchToProps)(ConnectedReportSchedule);

export default ReportSchedule;