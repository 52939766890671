import { NudgeData } from "./index";
import { ApplicationState } from "../../../shared/store/types";
import { NudgeType } from "../../../shared/store/my-data/types";
import { Permissions } from '../../../shared/store/permissions/types';

export function getUsersListNudges(applicationState: ApplicationState): Array<NudgeData> {

    let nudgesData: Array<NudgeData> = [];

    if (applicationState.users.isShowingAddForm || applicationState.users.isShowingModifyForm) {
        nudgesData.push({
            id: NudgeType.USERS_MODIFY_USER_DETAILS,
            header: applicationState.users.isShowingAddForm ? 'Add user details' : 'Update user details',
            content: `Name - Enter Full Name of the User
                Sub Title - If not a computed field, you may enter a Subtitle which is visible in User lists and Selection options
                Last Seen Latitude - Latitude where User was last seen
                Last Seen Longitude - Longitude where User was last seen
                Country Code - Login Phone Number's country code
                Phone - Phone Number used to login
                Password - Do we need to explain?
                Language - Select one of the predefined languages in this account
                Role 1 - Select Project, Role and Location for one Role at least
                Add Role - Add more roles in remaining configured projects - User can have ONLY ONE role per project
                [Additional Fields as configured by the Superuser for the above selected Role will show up here]`
        });

        return nudgesData;
    }

    if (applicationState.users.isShowingMetrics) {
        nudgesData = nudgesData.concat([{
            id: NudgeType.USERS_METRICS,
            header: 'Users metrics',
            content: `This is the section where you can see metrics for one or more users. They can grouped into 3:
            
            Workflows: This shows the amount of time spent by the selected user(s) on workflows

            Events: These record any events that a user has triggered, such as a login event

            Push events: These are events where the user has pushed data from their login. You can see the no. of members, groups, and workflows pushed, and the timestamp at which it was done.
            `,
        }, {
            id: NudgeType.USERS_METRICS_WORKFLOW_TIMING,
            header: 'Workflows tab',
            content: `Whenever a user works on a workflow (starts it, answers questions, transfers, etc - only the actions done INSIDE a workflow. It does NOT include changing the due date, transferring directly from the table, and so on) a timestamp is captured.

            All the timestamps for a user are collected and aggregated. 
            
            If a user has only worked on one step in a 15 minute window, the time worked shows as 5 minutes (starting from the worked time). E.g. If a user has completed 1 step at 12:20 and hasn't worked on any other steps until 12:35, it shows the user's working time as 12:20 - 12:25.
            
            If a user has worked another step within 15 minutes, it includes that step. It then checks a 15 minute window from that step - this goes on until there is no working for 15 min. E.g. If a user has completed 1 step at 12:20, it looks until 12:35. If it finds a step at 12:22, it includes that step and looks for another until 12:37. If it finds another step at 12:31, it includes that too, and looks for another until 12:46. Finally, when it doesn't find anything in the next 15 minute window, it stops with the list of timestamps. In our example that is [12:20, 12:22, 12:31]. The time is then marked from the first to the last step. In this example, the user is reported to have worked until 12:20 - 12:31.`
        }]);

        return nudgesData;
    }

    if (applicationState.users.isShowingCSVForm) {
        nudgesData.push({
            id: NudgeType.USERS_IMPORT_EXPORT_USER_DETAILS,
            header: 'Import/Export details',
            content: `Select a project and optionally a role. Click on "Export Table" and then "Export data" to export a CSV with user details.
                To import data, click on "Download template" to get the CSV template used for import. Fill up these details to import.`,
        });

        return nudgesData;
    }

    let canAddUser = applicationState.myData.id === 'SuperUser' || applicationState.permissions.myPermissions.general.Users === Permissions.WRITE;

    if (canAddUser) {
        nudgesData = [{
            id: NudgeType.USERS_ADD_USER,
            header: 'Add user',
            content: 'Click on the add button on the top right to add a new user',
        }];
    }

    nudgesData = nudgesData.concat([{
        id: NudgeType.USERS_FILTER,
        header: 'Filter users',
        content: 'Click on the filter button to filter the existing users list',
    }, {
        id: NudgeType.USERS_SEARCH,
        header: 'Search users',
        content: 'Type into the search bar to search the existing users list',
    }, {
        id: NudgeType.USERS_IMPORT_EXPORT,
        header: 'Import/Export',
        content: 'Click this button to import users into the app, or export users in the form of a CSV.',
    }, {
        id: NudgeType.USERS_LIST_SHOW_MORE,
        header: 'Show more user options',
        content: 'Click on this button to show more options for the user',
    }]);

    return nudgesData;

}