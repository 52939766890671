import { FC, useState, useEffect } from 'react';
import styles from './MembersTable.module.scss';

import MemberModify from './MemberModify';
import MemberFilter from './MemberFilter';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../shared/store/types';

import { addMember, deleteMember, searchMemberTable, goToPageMemberTable, setPageSizeMemberTable, sortMemberTable, unArchiveMember, updateMemberRequest, setIsShowingAddFormForMembers, setIsShowingCSVFormForMembers, setIsShowingFilterFormForMembers, setIsShowingModifyFormForMembers, recomputeAllMembers, bulkDeleteMembers } from '../../../shared/store/members/actions';
import { IUpdateableMemberData } from '../../../shared/store/members/types';
import TableWithMeta from '../../../widgets/table/TableWithMeta';
import { getReadableDataForCustomField } from '../../../shared/store/custom-fields';
import { isUUID } from '../../../shared/helpers/utilities';
import { translatePhrase } from '../../../shared/helpers/translation';
import { IUpdateableWorkflowData } from '../../../shared/store/workflows/types';
import { addWorkflow } from '../../../shared/store/workflows/actions';
import { Permissions } from '../../../shared/store/permissions/types';
import { clearToastMessage, setToastMessage, setInfoMessage, clearInfoMessage, clearIndeterminateMessage, setIndeterminateMessage } from '../../../shared/store/my-data/actions';
import { TableHeading, TableRow } from '../../../widgets/table/Table';
import { getFilteredMembers, getTags, getTotalNoOfMembers } from './selectors';

import MemberCSV from './MemberCSV';
import { NudgeType } from '../../../shared/store/my-data/types';
import { FieldType } from '../../../shared/store/custom-fields/types';
import { getAncestorChainOfLocation } from '../../../shared/helpers/locations';
import TableLocationList from '../../users/list/TableLocationList';
import { getActionableIdsAndBulkActionsSelector, getIsPartialFetch } from '../../../selectors/selectors';
import { clearTempFiles, commitTempFiles } from '../../../shared/store/file-operations';
import FireWorkFlowAction from '../../workflow-actions/FireWorkflowAction';

const mapStateToProps = (state: ApplicationState) => {

    const isOnline = state.myData.isOnline

    const currentPageNumber = state.members.currentPageNumber,
        pageSize = state.members.pageSize,
        memberSearchTerm = state.members.searchTerm,
        filters = state.members.filters;

    const filteredMembers = getFilteredMembers(state);

    const totalNoOfMembers = getTotalNoOfMembers(state);

    const { bulkActions, actionableIds } = getActionableIdsAndBulkActionsSelector(filteredMembers, state.members.types)(state);

    return {
        membersList: !isOnline ? filteredMembers.slice((currentPageNumber - 1) * pageSize, currentPageNumber * pageSize) : filteredMembers,
        actionableMemberIds: actionableIds,
        totalNoOfMembers,
        pageSize: pageSize,
        searchTerm: memberSearchTerm,
        pageNumber: currentPageNumber,
        totalPages: Math.ceil(totalNoOfMembers / pageSize),
        tags: getTags(state),

        isLoaded: state.myData.isLoaded,
        isPartiallyLoaded: state.myData.isPartiallyLoaded,

        isPartialFetch: getIsPartialFetch(state) ,

        usersData: state.users,
        myPermissions: state.permissions.myPermissions,

        memberFilters: filters,
        memberSort: state.members.sort,

        memberTypesData: state.members.types,
        customFieldsData: state.members.types.customFields,

        locationsData: state.structure.locations,
        permissionsData: state.permissions,
        workflowsData: state.workflows,
        workflowTypesData: state.workflows.types,

        areFiltersExpanded: false,
        bulkActions,

        organizationPlan: state.organization.plan,

        myId: state.myData.id,
        selectedNudge: state.myData.selectedNudgeId,

        isShowingAddForm: !!state.members.isShowingAddForm,
        isShowingModifyForm: !!state.members.isShowingModifyForm,
        isShowingFilterForm: !!state.members.isShowingFilterForm,
        isShowingCSVForm: !!state.members.isShowingCSVForm,
        isOnline,
        isSuperUser: !isUUID(state.myData.id),
        token: state.myData.token
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        searchTable: (searchTerm: string) => dispatch(searchMemberTable(searchTerm)),
        goToPage: (page: number) => dispatch(goToPageMemberTable(page)),
        changePageSize: (size: number) => dispatch(setPageSizeMemberTable(size)),
        sort: (column: string, order: 'ASC' | 'DESC') => dispatch(sortMemberTable(column, order)),

        setToastMessage: (message: string) => dispatch(setToastMessage(message)),
        clearToastMessage: () => dispatch(clearToastMessage()),

        setIndeterminateMessage: (message: string) => dispatch(setIndeterminateMessage(message)),
        clearIndeterminateMessage: () => dispatch(clearIndeterminateMessage()),

        setInfoMessage: (message: string) => dispatch(setInfoMessage(message)),
        clearInfoMessage: () => dispatch(clearInfoMessage()),

        addMember: (payload: IUpdateableMemberData) => dispatch(addMember(payload)),
        updateMember: (payload: IUpdateableMemberData) => dispatch(updateMemberRequest(payload)),
        deleteMember: (id: string) => dispatch(deleteMember(id)),
        bulkDeleteMembers: (ids: Array<string>) => dispatch(bulkDeleteMembers(ids)),
        recomputeAllMembers: () => dispatch(recomputeAllMembers()),
        unArchiveMember: (id: string) => dispatch(unArchiveMember(id)),
        addWorkflow: (payload: IUpdateableWorkflowData) => dispatch(addWorkflow(payload)),

        setIsShowingAddForm: (showValue: boolean) => dispatch(setIsShowingAddFormForMembers(showValue)),
        setIsShowingModifyForm: (showValue: boolean) => dispatch(setIsShowingModifyFormForMembers(showValue)),
        setIsShowingFilterForm: (showValue: boolean) => dispatch(setIsShowingFilterFormForMembers(showValue)),
        setIsShowingCSVForm: (showValue: boolean) => dispatch(setIsShowingCSVFormForMembers(showValue)),
    };
}

type OwnProps = {
    isReadOnly: boolean,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;

const ConnectedMembersTable: FC<Props> = (props) => {
    
    const [selectedSmartFilter, setSelectedSmartFilter] = useState("");
    const [headings, setHeadings] = useState<TableHeading[]>([]);
    const [entries, setEntries] = useState<TableRow[]>([]);
    const [hasMemberTypesToAdd, setHasMemberTypesToAdd] = useState(false);      

    const addBlur = () => {
        document.querySelector('#page-header')?.classList.add('blur');
        document.querySelector('#import-export-button-holder')?.classList.add('blur');
    }

    const removeBlur = () => {
        document.querySelector('#page-header')?.classList.remove('blur');
        document.querySelector('#import-export-button-holder')?.classList.remove('blur');
    }

    const showAddForm = () => {
        addBlur();
        props.setIsShowingAddForm(true);
    }

    const showFilterForm = () => {
        addBlur();
        props.setIsShowingFilterForm(true);
    }

    const showModifyForm = () => {
        addBlur();
        props.setIsShowingModifyForm(true);
    }

    const hideMemberForm = async () => {
        await clearTempFiles();
        removeBlur();
        props.setIsShowingAddForm(false);
        props.setIsShowingModifyForm(false);
    }

    const hideFilterForm = () => {
        removeBlur();
        props.setIsShowingFilterForm(false);
    }

    const handleCSVForm = () => {
        if (props.isShowingCSVForm) {
            removeBlur();
            props.setIsShowingCSVForm(false);
        } else {
            addBlur()
            props.setIsShowingCSVForm(true);
        }
    }

    const addMember = async (memberData: IUpdateableMemberData) => {
        await commitTempFiles();
        removeBlur();
        props.addMember(memberData);
        props.setIsShowingAddForm(false);
    }

    const updateMember = async (memberData: IUpdateableMemberData) => {
        await commitTempFiles();
        removeBlur();
        props.setIsShowingModifyForm(false);
        props.updateMember(memberData);
    }

    const deleteMember = (id: string) => {
        props.deleteMember(id);
        if (props.pageNumber !== 1 && props.membersList.length === 0) {
            props.goToPage(props.pageNumber - 1);
        }
    }

    const unArchiveMember = (id: string) => {
        props.unArchiveMember(id);
        if (props.pageNumber !== 1 && props.membersList.length === 0) {
            props.goToPage(props.pageNumber - 1);
        }
    }

    const handleSort = (column: string) => {
        const isNewColumn = props.memberSort.column !== column;
        const sortOrder = !isNewColumn && props.memberSort.order === 'ASC' ? 'DESC' : 'ASC';

        props.sort(column, sortOrder);
    }    

    const recomputeMemberComputedFields = () => {
        props.setIndeterminateMessage('Recomputing member data');

        setTimeout(() => {
            props.recomputeAllMembers();
            props.clearIndeterminateMessage();
        }, 500);
    }

    const deleteAllMembers = () => {
        if (props.bulkActions) {
            props.setIndeterminateMessage('Archiving members...');

            setTimeout(() => {
                props.bulkDeleteMembers(props.actionableMemberIds);
                props.clearIndeterminateMessage();
            }, 500);
        }
    }

    const getSmartFilter = (smartFilter: string) => {
        setSelectedSmartFilter(smartFilter);
    }

    const setTableHeadings = () => {
        const { memberFilters, memberTypesData, customFieldsData } = props;
        const headings: Array<TableHeading> = [{
            name: 'Sl. no',
            isSortable: false,
            isSticky: true,
            width: 90,
        }, {
            name: 'Name',
            isSortable: false,
            isSticky: true,
            width: 120,
        }, {
            name: 'Subtitle',
            isSortable: false,
            isSticky: false,
            width: 120,
        }, {
            name: 'Type',
            isSortable: false,
            width: 120,
        }, {
            name: 'Location',
            isSortable: false,
            width: 150,
        }];
    
        if (memberFilters.types.length === 1 || memberTypesData.allEntries.length === 1) {
            let memberType = memberTypesData.byId[memberTypesData.allEntries[0]];

            if (memberFilters.types.length === 1) {
                memberType = memberTypesData.byId[memberFilters.types[0]];
            }

            memberType.customFields.forEach(customFieldId => {
                const customField = customFieldsData.byId[customFieldId];
                const width = customField.type === FieldType.TEXT ? 200 : 150;

                if (customFieldId === memberType.nameFieldId || customFieldId === memberType.subTitleFieldId) {
                    return;
                }

                if (customField.isInTable) {
                    headings.push({
                        name: customField.name,
                        isSortable: false,
                        width: width,
                    });
                }
            });
        }
    
        headings.push({
            name: 'Action',
            isSortable: false,
            width: 200,
        });

        setHeadings(headings);
    }

    const setTableEntries = () => {
        const entries: Array<TableRow> = props.membersList.map<TableRow>((member, index) => {
            const memberType = props.memberTypesData.byId[member.type];
            let memberName = member.customFields[memberType.nameFieldId];
            const nameField = props.memberTypesData.customFields.byId[memberType.nameFieldId];
            memberName = getReadableDataForCustomField(memberName, nameField, member.id, 'member');

            let memberSubTitle = member.customFields[memberType.subTitleFieldId];
            const subTitleField = props.memberTypesData.customFields.byId[memberType.subTitleFieldId];

            memberSubTitle = getReadableDataForCustomField(memberSubTitle, subTitleField, member.id, 'member');

            const parentLocationIds = getAncestorChainOfLocation(member.location);
            parentLocationIds.reverse();
            const locationChain = parentLocationIds
                .filter(locationId => locationId in props.locationsData.byId)
                .concat([member.location])
                .map(locationId => props.locationsData.byId[locationId].name)
                .join(' > ');

            const locationName = member.location ? translatePhrase(props.locationsData.byId[member.location].name) : '-'

            const locationMarkup = <TableLocationList
                locationChain={locationChain}
                locationName={locationName}
                />

            const cells: Array<string | number | JSX.Element> = [
                (props.pageNumber - 1) * props.pageSize + index + 1,
                memberName,
                memberSubTitle,
                translatePhrase(props.memberTypesData.byId[member.type].name),
                locationMarkup,
            ];

            if (props.memberFilters.types.length === 1 || props.memberTypesData.allEntries.length === 1) {
                let memberType = props.memberTypesData.byId[props.memberTypesData.allEntries[0]];

                if (props.memberFilters.types.length === 1) {
                    memberType = props.memberTypesData.byId[props.memberFilters.types[0]];
                }

                memberType.customFields.forEach(customFieldId => {
                    const customField = props.customFieldsData.byId[customFieldId];

                    if (!customField.isInTable) {
                        return;
                    }

                    if (customFieldId === memberType.nameFieldId || customFieldId === memberType.subTitleFieldId) {
                        return;
                    }

                    let customFieldValue = member.customFields[customFieldId];

                    let cellValue = getReadableDataForCustomField(customFieldValue, customField, member.id, 'member');

                    if (customField.type === FieldType.FILE && cellValue.startsWith('http')) {
                        cells.push(<a className={styles.fileLink} target="_blank" rel="noreferrer noopener" href={cellValue + '?token=' + props.token}>Link</a>);
                    } else {
                        cells.push(cellValue);
                    }
                });
            }

            cells.push(
                <FireWorkFlowAction affiliationEntity={{ type: 'member', entity: member }} key={index} />
            );

            let showViewInsteadOfEdit = props.isOnline;
            const editAction = props.memberTypesData.actions.byId[memberType.actions[1]];

            if (member.type in props.myPermissions.members) {
                if (props.myPermissions.members[member.type] === Permissions.READ) {
                    showViewInsteadOfEdit = true;
                }
            }

            const memberEditForm = <MemberModify memberId={member.id} submit={updateMember} cancel={hideMemberForm} isReadOnly={showViewInsteadOfEdit || !!editAction.workflowType} />;
            const extraActions = memberType.actions.slice(3)
                .map(actionId => {
                    const action = props.memberTypesData.actions.byId[actionId];

                    return {
                        name: action.name,
                        icon: action.icon,
                        workflowType: action.workflowType || '',
                    }
                })
                .filter(action => {
                    if (!action.workflowType) {
                        return false;
                    }

                    if (action.workflowType in props.myPermissions.workflows && props.myPermissions.workflows[action.workflowType] !== Permissions.WRITE) {
                        return false;
                    }

                    return true;
                });

            const deleteAction = props.memberTypesData.actions.byId[memberType.actions[2]];
            let isDeleteAllowed = true;

            if (member.archived && props.myId !== 'SuperUser') {
                isDeleteAllowed = false;
            } else if (props.myPermissions.members[memberType.id] && props.myPermissions.members[memberType.id] !== Permissions.WRITE) {
                isDeleteAllowed = false;
            } else if (!!deleteAction.workflowType && props.myPermissions.workflows[deleteAction.workflowType]) {
                isDeleteAllowed = props.myPermissions.workflows[deleteAction.workflowType] === Permissions.WRITE;
            }

            const row: TableRow = {
                id: member.id,
                entries: cells,
                extraActions,
                isDeleteAllowed,
                deleteWorkflowTypeId: isDeleteAllowed ? deleteAction.workflowType : undefined,
                editForm: memberEditForm,
                isDeleted: member.archived,
                shareText: `Name: ${memberName}\nDescription: ${memberSubTitle}`,
                showViewInsteadOfEdit,
            }

            return row;
        });

        setEntries(entries);
    }

    const updateMemberTypesToAdd = () => {
        const { memberTypesData, myId, usersData, myPermissions } = props;
        let hasMemberTypesToAdd = false;

        const allowedMemberTypes = memberTypesData.allEntries
            .filter(typeId => {
                const memberType = memberTypesData.byId[typeId];
                const loggedInUser = myId ? usersData.byId[myId] : undefined;

                if (loggedInUser) {
                    return loggedInUser.projects.includes(memberType.project);
                } else {
                    return true;
                }
            })

        for (const memberTypeId of allowedMemberTypes) {
            if (myPermissions.members[memberTypeId] === Permissions.WRITE 
                || typeof myPermissions.members[memberTypeId] === 'undefined') {
                hasMemberTypesToAdd = true;
            }
        }

        setHasMemberTypesToAdd(hasMemberTypesToAdd);
    }

    useEffect(() => {
        if (props.isOnline) {
            props.goToPage(1);
        }
        setTableHeadings();
        setTableEntries();
        updateMemberTypesToAdd();
    }, []);
    
    useEffect(() => {
        setTableHeadings();
    }, [props.memberFilters, props.memberTypesData]);

    useEffect(() => {
        setTableEntries();
    }, [props.membersList]);

    useEffect(() => {
        updateMemberTypesToAdd();
    }, [props.memberTypesData])

    if (!props.isLoaded && !props.isPartiallyLoaded) {
        return <div></div>;
    }

    return (
        <div>
            <TableWithMeta
                entityType="Member"
                headings={headings}
                entries={entries}

                sortedColumn={props.memberSort.column}
                sortType={props.memberSort.order}
                isReadOnly={!!props.isReadOnly}
                areFiltersExpanded={props.areFiltersExpanded}
                tags={props.tags}

                totalPages={props.totalPages}
                totalNoOfEntries={props.totalNoOfMembers}
                pageNumber={props.pageNumber}
                searchTerm={props.searchTerm}
                placeHolderForSearch="Search within the list below"
                isAddAllowed={hasMemberTypesToAdd}
                isShowingAddForm={props.isShowingAddForm}
                isShowingModifyForm={props.isShowingModifyForm}
                addForm={<MemberModify submit={addMember} cancel={hideMemberForm} />}

                isShowingFilterForm={props.isShowingFilterForm}
                showFilterForm={showFilterForm}
                filterForm={
                    <MemberFilter 
                        tags={props.tags} 
                        closeFilter={hideFilterForm} 
                        memberFilters={props.memberFilters} 
                        getSmartFilter={getSmartFilter} />}

                onSort={handleSort}
                onDelete={deleteMember}
                onUnArchive={unArchiveMember}
                showAddForm={showAddForm}
                showModifyForm={showModifyForm}
                reindexTable={props.isSuperUser && !props.isPartialFetch ? recomputeMemberComputedFields : undefined}
                archiveEntities={props.bulkActions && entries.length > 0 ? () => deleteAllMembers() : undefined}

                search={props.searchTable}
                changePageSize={props.changePageSize}
                goToPage={props.goToPage}
                sort={props.sort}

                handleCSVForm={handleCSVForm}
                isShowingCSVForm={props.isShowingCSVForm}

                isAddHighlighted={props.selectedNudge === NudgeType.MEMBERS_ADD_MEMBER}
                isFilterHighlighted={props.selectedNudge === NudgeType.MEMBERS_FILTER}
                isSearchHighlighted={props.selectedNudge === NudgeType.MEMBERS_SEARCH}
                isImportExportHighlighted={props.selectedNudge === NudgeType.MEMBERS_IMPORT_EXPORT}
                isShowMoreHighlighted={props.selectedNudge === NudgeType.MEMBERS_LIST_SHOW_MORE}
                selectedSmartFilter={selectedSmartFilter}
            />

            {props.isShowingCSVForm && <MemberCSV closeCSV={handleCSVForm} />}
        </div>
    )
}

const MembersTable = connect(mapStateToProps, mapDispatchToProps)(ConnectedMembersTable);

export default MembersTable;