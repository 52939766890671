import { ChangeEvent, Component } from 'react';
import styles from './QuestionPiece.module.scss';
import Input from '../Input';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';
import { Option } from '../drop-down/ListItem';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';

import { setTargetPiece, setNextPiece, setFinsalUserEmail, setFinsalUserPhone, setFinsalMember, setFinsalResponse, setFinsalMode, setFinsalMemberDOB, setFinsalMemberGender, setFinsalMemberFatherName, setFinsalMemberMotherName, setFinsalMemberAnnualIncome, setFinsalMemberMaritalStatus, setFinsalApplyForLoan } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import { booleanPieceSlotTarget, valuePieceSlotTarget } from './utilities';
import DropDownSearchBox, { getFilteredOptionsBySearch } from '../drop-down/DropDownSearchBox';

const MODE_OPTIONS = [
    {
        name: "Prod",
        value: "prod",
    },
    {
        name: "UAT",
        value: "uat",
    }
];

type FinsalLoanProcessLowerArmProps = {
    memberDobPiece: JSX.Element | undefined,
    memberGenderPiece: JSX.Element | undefined,
    memberFatherNamePiece: JSX.Element | undefined,
    memberMotherNamePiece: JSX.Element | undefined,
    memberAnnualIncomePiece: JSX.Element | undefined,
    memberMaritalStatusPiece: JSX.Element | undefined,

    userPhonePiece: JSX.Element | undefined,
    userEmailPiece: JSX.Element | undefined,
    memberPiece: JSX.Element | undefined,

    customFields: Array<Option>,
    responseCustomFieldId?: string,

    mode?: string,
    applyForLoanPiece?: JSX.Element | undefined,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,

        variablesData: state.flowchart.variables,
        customFieldsData: state.workflows.types.customFields,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string | undefined) => dispatch(setTargetPiece(pieceId)),
        setNextPiece: (targetPieceId: string, draggedPieceId: string) => dispatch(setNextPiece(targetPieceId, draggedPieceId)),

        setFinsalUserEmail: (pieceId: string, value: string | undefined) => dispatch(setFinsalUserEmail(pieceId, value)),
        setFinsalUserPhone: (pieceId: string, value: string | undefined) => dispatch(setFinsalUserPhone(pieceId, value)),
        setFinsalMember: (pieceId: string, value: string | undefined) => dispatch(setFinsalMember(pieceId, value)),

        setFinsalMemberDOB: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberDOB(pieceId, value)),
        setFinsalMemberGender: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberGender(pieceId, value)),
        setFinsalMemberFatherName: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberFatherName(pieceId, value)),
        setFinsalMemberMotherName: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberMotherName(pieceId, value)),
        setFinsalMemberAnnualIncome: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberAnnualIncome(pieceId, value)),
        setFinsalMemberMaritalStatus: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberMaritalStatus(pieceId, value)),
        setFinsalApplyForLoan: (pieceId: string, value: string | undefined) => dispatch(setFinsalApplyForLoan(pieceId, value)),

        setFinsalMode: (pieceId: string, value: string | undefined) => dispatch(setFinsalMode(pieceId, value)),
        setFinsalResponse: (pieceId: string, value: string | undefined) => dispatch(setFinsalResponse(pieceId, value)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = FinsalLoanProcessLowerArmProps & StateProps & DispatchProps & FlowchartPieceProps;

type FinsalLoanProcessLowerArmState = {
    isHoveringOverDOBPiece: boolean,
    isHoveringOverGenderPiece: boolean,
    isHoveringOverFatherNamePiece: boolean,
    isHoveringOverMotherNamePiece: boolean,
    isHoveringOverAnnualIncomePiece: boolean,
    isHoveringOverMaritalStatusPiece: boolean,

    isHoveringOverApplyForLoanPiece: boolean,

    isHoveringOverMemberPiece: boolean,
    isHoveringOverEmailPiece: boolean,
    isHoveringOverPhonePiece: boolean,
    searchText: string,
    modeSearchTerm: string;
}

class ConnectedFinsalLoanProcessLowerArm extends Component<Props, FinsalLoanProcessLowerArmState>  {

    state = {
        isHoveringOverDOBPiece: false,
        isHoveringOverGenderPiece: false,
        isHoveringOverFatherNamePiece: false,
        isHoveringOverMotherNamePiece: false,
        isHoveringOverAnnualIncomePiece: false,
        isHoveringOverMaritalStatusPiece: false,
        isHoveringOverApplyForLoanPiece: false,

        isHoveringOverMemberPiece: false,
        isHoveringOverEmailPiece: false,
        isHoveringOverPhonePiece: false,
        searchText: '',
        modeSearchTerm: "",
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        const oneOfThePiecesAreHovering = this.state.isHoveringOverMemberPiece ||
            this.state.isHoveringOverEmailPiece ||
            this.state.isHoveringOverPhonePiece ||
            this.state.isHoveringOverDOBPiece ||
            this.state.isHoveringOverGenderPiece ||
            this.state.isHoveringOverFatherNamePiece ||
            this.state.isHoveringOverMotherNamePiece ||
            this.state.isHoveringOverAnnualIncomePiece ||
            this.state.isHoveringOverMaritalStatusPiece ||
            this.state.isHoveringOverApplyForLoanPiece;

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && oneOfThePiecesAreHovering) {
            if (this.state.isHoveringOverMemberPiece) {
                this.props.setFinsalMember(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverEmailPiece) {
                this.props.setFinsalUserEmail(this.props.pieceId, this.props.lastDraggedPiece.id)
            } else if (this.state.isHoveringOverPhonePiece) {
                this.props.setFinsalUserPhone(this.props.pieceId, this.props.lastDraggedPiece.id)
            } else if (this.state.isHoveringOverDOBPiece) {
                this.props.setFinsalMemberDOB(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverGenderPiece) {
                this.props.setFinsalMemberGender(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverFatherNamePiece) {
                this.props.setFinsalMemberFatherName(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverMotherNamePiece) {
                this.props.setFinsalMemberMotherName(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverAnnualIncomePiece) {
                this.props.setFinsalMemberAnnualIncome(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverMaritalStatusPiece) {
                this.props.setFinsalMemberMaritalStatus(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverApplyForLoanPiece) {
                this.props.setFinsalApplyForLoan(this.props.pieceId, this.props.lastDraggedPiece.id);
            }

            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverMemberPiece: false,
                isHoveringOverEmailPiece: false,
                isHoveringOverPhonePiece: false,
                isHoveringOverDOBPiece: false,
                isHoveringOverGenderPiece: false,
                isHoveringOverFatherNamePiece: false,
                isHoveringOverMotherNamePiece: false,
                isHoveringOverAnnualIncomePiece: false,
                isHoveringOverMaritalStatusPiece: false,
            });
        }
    }

    handleHoverOverEmailPiece = () => {
        this.setState({
            isHoveringOverEmailPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfEmailPiece = () => {
        this.setState({
            isHoveringOverEmailPiece: false,
        });
    };

    handleHoverOverPhonePiece = () => {
        this.setState({
            isHoveringOverPhonePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfPhonePiece = () => {
        this.setState({
            isHoveringOverPhonePiece: false,
        });
    };

    handleHoverOverMemberPiece = () => {
        this.setState({
            isHoveringOverMemberPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfMemberPiece = () => {
        this.setState({
            isHoveringOverMemberPiece: false,
        });
    };

    searchForCustomField = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            searchText: e.target.value,
        });
    }

    handleHoverOverDOBPiece = () => {
        this.setState({
            isHoveringOverDOBPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfDOBPiece = () => {
        this.setState({
            isHoveringOverDOBPiece: false,
        });
    };

    handleHoverOverGenderPiece = () => {
        this.setState({
            isHoveringOverGenderPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfGenderPiece = () => {
        this.setState({
            isHoveringOverGenderPiece: false,
        });
    };

    handleHoverOverFatherNamePiece = () => {
        this.setState({
            isHoveringOverFatherNamePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfFatherNamePiece = () => {
        this.setState({
            isHoveringOverFatherNamePiece: false,
        });
    };

    handleHoverOverMotherNamePiece = () => {
        this.setState({
            isHoveringOverMotherNamePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfMotherNamePiece = () => {
        this.setState({
            isHoveringOverMotherNamePiece: false,
        });
    };

    handleHoverOverAnnualIncomePiece = () => {
        this.setState({
            isHoveringOverAnnualIncomePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfAnnualIncomePiece = () => {
        this.setState({
            isHoveringOverAnnualIncomePiece: false,
        });
    };

    handleHoverOverMaritalStatusPiece = () => {
        this.setState({
            isHoveringOverMaritalStatusPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfMaritalStatusPiece = () => {
        this.setState({
            isHoveringOverMaritalStatusPiece: false,
        });
    };

    handleHoverOverApplyForLoanPiece = () => {
        this.setState({
            isHoveringOverApplyForLoanPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        booleanPieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfApplyForLoanPiece = () => {
        this.setState({
            isHoveringOverApplyForLoanPiece: false,
        });
    };

    searchForMode = (searchTerm: string) => {
        this.setState({ modeSearchTerm: searchTerm });
    }

    render() {

        const settingCustomFieldName = this.props.responseCustomFieldId && this.props.responseCustomFieldId in this.props.customFieldsData.byId ? this.props.customFieldsData.byId[this.props.responseCustomFieldId].name : undefined;

        const customFieldSelectBox = <SelectBox theme="pink" selectionPromptText={settingCustomFieldName}>
            <DropDownList theme="pink" dismissAfterSelection={false}>
                <div className={styles.searchBoxHolder + ' ignore-options-onclickoutside'}>
                    <input className={styles.searchBox} onChange={this.searchForCustomField} value={this.state.searchText} type="text" placeholder="Search by name" />
                </div>
                {this.props.customFields.filter(customField => customField.name.toLocaleLowerCase().includes(this.state.searchText.toLocaleLowerCase()))
                    .map((variable, index) => {
                        const variableData = this.props.customFieldsData.byId[variable.value];
                        return <ListItem name={variable.name} detail={variableData.type} value={variable.value} key={index} theme="pink" onClick={this.props.setFinsalResponse.bind(this, this.props.pieceId)} />
                    })}
            </DropDownList>
        </SelectBox>;
        const filteredModeOption = getFilteredOptionsBySearch(MODE_OPTIONS, this.state.modeSearchTerm);
        const modeSelectBox = <SelectBox theme="aqua" selectionPromptText={this.props.mode === 'prod' ? 'Prod' : 'UAT'}>
            <DropDownList theme="aqua" dismissAfterSelection={false}>
                  <DropDownSearchBox
                        handleSearchInputChange={this.searchForMode}
                        placeholder={"Search by name"}
                        searchTerm={this.state.modeSearchTerm}
                    />
                {filteredModeOption.map(mode => {
                    return <ListItem name={mode.name} value={mode.value} theme="aqua" onClick={this.props.setFinsalMode.bind(this, this.props.pieceId)} />
                })}
            </DropDownList>
        </SelectBox>;

        return (
            <div className={styles.visibleItems}>

                <div className={styles.text}>Member DOB:</div>
                {this.props.memberDobPiece ? this.props.memberDobPiece : <Input placeholderText="E.g. '1990-01-25T10:05:00'" minSize={24}
                    canReceiveDrag={this.props.isDragging && this.state.isHoveringOverDOBPiece && !!this.props.targetPiece}
                    onMouseOver={this.handleHoverOverDOBPiece}
                    onMouseOut={this.handleHoverOutOfDOBPiece}
                />}

                <div className={styles.text}>Member Gender:</div>
                {this.props.memberGenderPiece ? this.props.memberGenderPiece : <Input placeholderText="E.g. 'Female'" minSize={14}
                    canReceiveDrag={this.props.isDragging && this.state.isHoveringOverGenderPiece && !!this.props.targetPiece}
                    onMouseOver={this.handleHoverOverGenderPiece}
                    onMouseOut={this.handleHoverOutOfGenderPiece}
                />}

                <div className={styles.text}>Member Father name:</div>
                {this.props.memberFatherNamePiece ? this.props.memberFatherNamePiece : <Input placeholderText="E.g. 'Father name'" minSize={19}
                    canReceiveDrag={this.props.isDragging && this.state.isHoveringOverFatherNamePiece && !!this.props.targetPiece}
                    onMouseOver={this.handleHoverOverFatherNamePiece}
                    onMouseOut={this.handleHoverOutOfFatherNamePiece}
                />}

                <div className={styles.text}>Member Mother name:</div>
                {this.props.memberMotherNamePiece ? this.props.memberMotherNamePiece : <Input placeholderText="E.g. 'Mother name'" minSize={19}
                    canReceiveDrag={this.props.isDragging && this.state.isHoveringOverMotherNamePiece && !!this.props.targetPiece}
                    onMouseOver={this.handleHoverOverMotherNamePiece}
                    onMouseOut={this.handleHoverOutOfMotherNamePiece}
                />}

                <div className={styles.text}>Member Annual Income:</div>
                {this.props.memberAnnualIncomePiece ? this.props.memberAnnualIncomePiece : <Input placeholderText="E.g. '121000'" minSize={14}
                    canReceiveDrag={this.props.isDragging && this.state.isHoveringOverAnnualIncomePiece && !!this.props.targetPiece}
                    onMouseOver={this.handleHoverOverAnnualIncomePiece}
                    onMouseOut={this.handleHoverOutOfAnnualIncomePiece}
                />}

                <div className={styles.text}>Member Marital Status:</div>
                {this.props.memberMaritalStatusPiece ? this.props.memberMaritalStatusPiece : <Input placeholderText="E.g. 'Married'" minSize={14}
                    canReceiveDrag={this.props.isDragging && this.state.isHoveringOverMaritalStatusPiece && !!this.props.targetPiece}
                    onMouseOver={this.handleHoverOverMaritalStatusPiece}
                    onMouseOut={this.handleHoverOutOfMaritalStatusPiece}
                />}

                <div className={styles.text}>User Phone:</div>
                {this.props.userPhonePiece ? this.props.userPhonePiece : <Input placeholderText="E.g. '9845611111'" minSize={14}
                    canReceiveDrag={this.props.isDragging && this.state.isHoveringOverPhonePiece && !!this.props.targetPiece}
                    onMouseOver={this.handleHoverOverPhonePiece}
                    onMouseOut={this.handleHoverOutOfPhonePiece}
                />}

                <div className={styles.text}>User Email:</div>
                {this.props.userEmailPiece ? this.props.userEmailPiece : <Input placeholderText="E.g. 'field.worker@diceflow.in'" minSize={27}
                    canReceiveDrag={this.props.isDragging && this.state.isHoveringOverEmailPiece && !!this.props.targetPiece}
                    onMouseOver={this.handleHoverOverEmailPiece}
                    onMouseOut={this.handleHoverOutOfEmailPiece}
                />}

                <div className={styles.text}>Member:</div>
                {this.props.memberPiece ? this.props.memberPiece : <Input minSize={8}
                    canReceiveDrag={this.props.isDragging && this.state.isHoveringOverMemberPiece && !!this.props.targetPiece}
                    onMouseOver={this.handleHoverOverMemberPiece}
                    onMouseOut={this.handleHoverOutOfMemberPiece}
                />}

                <div className={styles.text}>Store in:</div>
                {customFieldSelectBox}

                <div className={styles.text}>Mode:</div>
                {modeSelectBox}

                <div className={styles.text}>Apply for loan:</div>
                {this.props.applyForLoanPiece ? this.props.applyForLoanPiece : <div className={(this.state.isHoveringOverApplyForLoanPiece && this.props.isDragging && this.props.targetPiece ? styles.aquaBooleanIndicatorHovering : styles.aquaBooleanIndicator) + ' attachment-target'} onMouseOver={this.handleHoverOverApplyForLoanPiece} onMouseOut={this.handleHoverOutOfApplyForLoanPiece}></div>}
            </div>
        )

    }
}

const FinsalLoanProcessLowerArm = connect(mapStateToProps, mapDispatchToProps)(ConnectedFinsalLoanProcessLowerArm);

export default FinsalLoanProcessLowerArm;