import { Component, ChangeEvent } from 'react';
import styles from './Flowchart.module.scss';
import { RouteComponentProps } from 'react-router';
import { Redirect } from "react-router-dom";
import 'tippy.js/dist/tippy.css';
import { ReactComponent as CancelIcon } from '../../../common/assets/close.svg';
import { ReactComponent as ExternalIcon } from '../../../common/assets/help.svg';

import { Permissions } from '../../../shared/store/permissions/types';
import { ReactComponent as ChevronDownIcon } from '../../../assets/chevron-arrow-down.svg';

import { selectWorkflowType, updateWorkflowTypeStartPiece, updateWorkflowTypeBetaStartPiece, setIsolatedWorkflowTypePiece, removeIsolatedWorkflowTypePiece, setBetaIsolatedWorkflowTypePiece, removeBetaIsolatedWorkflowTypePiece, registerWorkflowTypeVariable, publishFlowchartToLive, removeAllIsolatedWorkflowTypePieces, removeAllBetaIsolatedWorkflowTypePieces, updatePublishPieceMapping } from '../../../shared/store/workflows/types/actions';
import { setNextPiece, setInnerPiece, setConditionForIfPiece, setConditionPiece, setConditionNextPiece, setLoopVariable, setIterableVariable, setOperand, setLeftOperand, setRightOperand, setQuestionData, setMemberVariable, setQuestionRequiredPiece, setQuestionDisabledPiece, setQuestionDefaultPiece, setQuestionImage, setDataStoreValue, setDataSetValue, setDataCopyVariable, setReturnValue, addPiece, addFullPiece, deletePiece, setVariableForShow, setVariableForCustomField, setLocationPiece, setPieceForList, setVariablePiece, setWorkflowAffiliationVariable, setHeading, setDate, setMonth, setYear, setMessage, setUpdateDueDateValue, copyPiece, hideRichTextEditor, setEntity, setEntityType, addFullPieces, unSelectPieces, setFinsalMemberAddressLine1, setFinsalMemberAddressLine2, setFinsalMemberCity, setFinsalMemberEmail, setFinsalMemberFirstName, setFinsalMemberLastName, setFinsalMemberPan, setFinsalMemberPhone, setFinsalMemberPinCode, setFinsalMemberState, setFinsalPremium, setFinsalMember, setFinsalMemberAnnualIncome, setFinsalMemberDOB, setFinsalMemberFatherName, setFinsalMemberGender, setFinsalMemberMaritalStatus, setFinsalMemberMotherName, setFinsalUserEmail, setFinsalUserPhone, setFinsalApplyForLoan, setError, setWorkflowTypeComparison, stopPieceDrag, setQuestionHiddenPiece, setHiddenPieceForShow } from '../../../shared/store/flowchart/pieces/actions';
import { FlowchartPieceActions, PieceType, AllPieceTypes, IShowPiece, IGroupedShowPiece } from '../../../shared/store/flowchart/pieces/types';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import uuid from 'uuid';

import { ApplicationState } from '../../../shared/store/types';
import { CategoryValue } from '../../flowchart/helpers/index';
import { getComponentForWorkflowPiece, piecesByCategory as workflowPiecesByCategory } from '../../flowchart/helpers/workflow';
import { clearInfoMessage, setInfoMessage, setIsFlowchartExpanded, setIsTopBarExpanded, setToastMessage } from '../../../shared/store/my-data/actions';

import RichTextModal from './RichTextModal';
import { duplicatePiece, getAllPiecesInPiece, pastePiece } from '../../../shared/store/flowchart/helpers/pieces';
import { WorkflowTypeContext } from '../../../contexts/workflow-type-context';
import { FlowchartContext, FlowchartInfoForPiece, InvalidPieceDetails } from '../../../contexts/flowchart-context';
import { PiecePositionState } from '../../../shared/helpers/common-types';
import { addNewQuestionInGroup, addNewScreen, getGroupCustomFieldData, getMemberCustomFieldData, storeGroupCustomFieldData, storeMemberCustomFieldData } from './cookbook';
import Button from '../../../widgets/button/CommonButton';
import { translatePhrase } from '../../../shared/helpers/translation';
import DecrementingCounter from '../../../widgets/table/DecrementingCounter';
import ConfirmModal from '../../../widgets/loader/ConfirmModal';


import { ReactComponent as DuplicateIcon } from '../../../assets/new-custom-icons/common/duplicate.svg';
import { ReactComponent as ExportIcon } from '../../../common/assets/export.svg';

import { NudgeType } from '../../../shared/store/my-data/types';
import FlowchartWindow from '../../flowchart-window/FlowchartWindow';
import React from 'react';
import { PublishPieceMapping } from '../../../shared/store/workflows/types/types';


type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canEditConfiguration = state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.WRITE;
    const canViewConfiguration = canEditConfiguration || state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.READ;

    const isShowingRichTextEditor = state.flowchart.pieces.isShowingRichTextEditor;
    let showPieceData: IShowPiece | IGroupedShowPiece | undefined;

    if (isShowingRichTextEditor && state.flowchart.pieces.lastDraggedPiece) {
        const lastDraggedPiece = state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece];

        if (lastDraggedPiece.type === PieceType.SHOW || lastDraggedPiece.type === PieceType.GROUPED_SHOW) {
            showPieceData = lastDraggedPiece;
        }
    }

    return {
        isPartiallyLoaded: state.myData.isPartiallyLoaded,
        isLoaded: state.myData.isLoaded,
        isReadable: canViewConfiguration,
        isWritable: canEditConfiguration,
        isDragging: state.flowchart.pieces.isDragging,
        isShowingRichTextEditor,
        piecesData: state.flowchart.pieces,
        variablesData: state.flowchart.variables,
        workflowTypesData: state.workflows.types,

        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece,
        lastCopiedPiece: state.flowchart.pieces.lastCopiedPiece,
        lastCopiedPieceFlowchartContext: state.flowchart.pieces.lastCopiedPieceFlowchartContext,

        showPieceData,
        isTopBarExpanded: state.myData.isTopBarExpanded,
        isFlowchartExpanded: state.myData.isFlowchartExpanded,
        selectedNudge: state.myData.selectedNudgeId,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    const flowchartPieceActions: FlowchartPieceActions = {
        setNextPiece: (pieceId, value) => dispatch(setNextPiece(pieceId, value)),
        setInnerPiece: (pieceId, value) => dispatch(setInnerPiece(pieceId, value)),
        setConditionForIfPiece: (pieceId, index, value) => dispatch(setConditionForIfPiece(pieceId, index, value)),
        setConditionPiece: (pieceId, value) => dispatch(setConditionPiece(pieceId, value)),
        setConditionNextPiece: (pieceId, index, value) => dispatch(setConditionNextPiece(pieceId, index, value)),
        setLoopVariable: (pieceId, value) => dispatch(setLoopVariable(pieceId, value)),
        setIterableVariable: (pieceId, value) => dispatch(setIterableVariable(pieceId, value)),
        setOperand: (pieceId, value) => dispatch(setOperand(pieceId, value)),
        setLeftOperand: (pieceId, value) => dispatch(setLeftOperand(pieceId, value)),
        setRightOperand: (pieceId, value) => dispatch(setRightOperand(pieceId, value)),
        setQuestionData: (pieceId, value) => dispatch(setQuestionData(pieceId, value)),
        setMemberVariable: (pieceId, value) => dispatch(setMemberVariable(pieceId, value)),
        setRequiredPiece: (pieceId, value) => dispatch(setQuestionRequiredPiece(pieceId, value)),
        setDisabledPiece: (pieceId, value) => dispatch(setQuestionDisabledPiece(pieceId, value)),
        setHiddenPiece: (pieceId, value) => dispatch(setQuestionHiddenPiece(pieceId, value)),
        setDefaultPiece: (pieceId, value) => dispatch(setQuestionDefaultPiece(pieceId, value)),
        setImage: (pieceId, value) => dispatch(setQuestionImage(pieceId, value)),
        setDataStoreValue: (pieceId, value) => dispatch(setDataStoreValue(pieceId, value)),
        setDataSetValue: (pieceId, value) => dispatch(setDataSetValue(pieceId, value)),
        setDataCopyVariable: (pieceId, value) => dispatch(setDataCopyVariable(pieceId, value)),
        setDataForList: (pieceId, value) => dispatch(setPieceForList(pieceId, value)),
        setReturnVariable: (pieceId, value) => dispatch(setReturnValue(pieceId, value)),
        setLocationPiece: (pieceId, value) => dispatch(setLocationPiece(pieceId, value)),
        setVariableForShow: (pieceId, value) => dispatch(setVariableForShow(pieceId, value)),
        setHiddenPieceForShow: (pieceId, value) => dispatch(setHiddenPieceForShow(pieceId, value)),
        setVariableForCustomField: (pieceId, value) => dispatch(setVariableForCustomField(pieceId, value)),
        setVariablePiece: (pieceId, value) => dispatch(setVariablePiece(pieceId, value)),
        setAffiliationVariablePiece: (pieceId, value) => dispatch(setWorkflowAffiliationVariable(pieceId, value)),
        setHeadingPiece: (pieceId, value) => dispatch(setHeading(pieceId, value)),
        setMessage: (pieceId, value) => dispatch(setMessage(pieceId, value)),

        setDatePiece: (pieceId, value) => dispatch(setDate(pieceId, value)),
        setMonthPiece: (pieceId, value) => dispatch(setMonth(pieceId, value)),
        setYearPiece: (pieceId, value) => dispatch(setYear(pieceId, value)),
        setDueDatePiece: (pieceId, value) => dispatch(setUpdateDueDateValue(pieceId, value)),

        setEntity: (pieceId, value) => dispatch(setEntity(pieceId, value)),
        setEntityType: (pieceId, value) => dispatch(setEntityType(pieceId, value)),

        setErrorValue: (pieceId: string, value: string | undefined) => dispatch(setError(pieceId, value)),

        setStartPieceData: (workflowTypeId: string, payload: PiecePositionState) => dispatch(updateWorkflowTypeStartPiece(payload, workflowTypeId)),

        setFinsalPremium: (pieceId: string, value: string | undefined) => dispatch(setFinsalPremium(pieceId, value)),
        setFinsalMemberFirstName: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberFirstName(pieceId, value)),
        setFinsalMemberLastName: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberLastName(pieceId, value)),
        setFinsalMemberEmail: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberEmail(pieceId, value)),
        setFinsalMemberPhone: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberPhone(pieceId, value)),
        setFinsalMemberPan: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberPan(pieceId, value)),
        setFinsalMemberState: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberState(pieceId, value)),
        setFinsalMemberCity: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberCity(pieceId, value)),
        setFinsalMemberAddressLine1: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberAddressLine1(pieceId, value)),
        setFinsalMemberAddressLine2: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberAddressLine2(pieceId, value)),
        setFinsalMemberPinCode: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberPinCode(pieceId, value)),

        setFinsalUserEmail: (pieceId: string, value: string | undefined) => dispatch(setFinsalUserEmail(pieceId, value)),
        setFinsalUserPhone: (pieceId: string, value: string | undefined) => dispatch(setFinsalUserPhone(pieceId, value)),
        setFinsalMember: (pieceId: string, value: string | undefined) => dispatch(setFinsalMember(pieceId, value)),
        setFinsalMemberDOB: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberDOB(pieceId, value)),
        setFinsalMemberGender: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberGender(pieceId, value)),
        setFinsalMemberFatherName: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberFatherName(pieceId, value)),
        setFinsalMemberMotherName: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberMotherName(pieceId, value)),
        setFinsalMemberAnnualIncome: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberAnnualIncome(pieceId, value)),
        setFinsalMemberMaritalStatus: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberMaritalStatus(pieceId, value)),
        setFinsalApplyForLoan: (pieceId: string, value: string | undefined) => dispatch(setFinsalApplyForLoan(pieceId, value)),
    };

    const betaFlowchartPieceActions: FlowchartPieceActions = {
        ...flowchartPieceActions,
        setStartPieceData: (workflowTypeId: string, payload: PiecePositionState) => dispatch(updateWorkflowTypeBetaStartPiece(payload, workflowTypeId)),
    }

    return {
        selectWorkflowType: (id: string) => dispatch(selectWorkflowType(id)),
        flowchartPieceActions,
        betaFlowchartPieceActions,
        setIsolatedWorkflowTypePiece: (workflowTypeId: string, payload: PiecePositionState) => dispatch(setIsolatedWorkflowTypePiece(payload, workflowTypeId)),
        removeIsolatedWorkflowTypePiece: (workflowTypeId: string, pieceId: string) => dispatch(removeIsolatedWorkflowTypePiece(pieceId, workflowTypeId)),
        removeAllIsolatedWorkflowTypePieces: (workflowTypeId: string) => dispatch(removeAllIsolatedWorkflowTypePieces(workflowTypeId)),
        registerWorkflowTypeVariable: (workflowTypeId: string, variableId: string) => dispatch(registerWorkflowTypeVariable(variableId, workflowTypeId)),

        addPiece: (pieceId: string, pieceType: PieceType) => dispatch(addPiece(pieceId, pieceType)),
        addFullPiece: (pieceData: AllPieceTypes) => dispatch(addFullPiece(pieceData)),
        addFullPieces: (piecesData: Array<AllPieceTypes>) => dispatch(addFullPieces(piecesData)),
        deletePiece: (pieceId: string) => dispatch(deletePiece(pieceId)),
        copyPiece: (pieceId: string, flowchartContext: string) => dispatch(copyPiece(pieceId, flowchartContext)),
        setToastMessage: (message: string) => dispatch(setToastMessage(message)),

        stopPieceDrag: () => dispatch(stopPieceDrag()),

        hideRichTextEditor: () => dispatch(hideRichTextEditor()),
        setVariableForShow: (pieceId: string, value: string) => dispatch(setVariableForShow(pieceId, value)),
        toggleTopBarExpansion: (flag: boolean) => dispatch(setIsTopBarExpanded(flag)),
        toggleFlowChartExpansion: (flag: boolean) => dispatch(setIsFlowchartExpanded(flag)),

        setBetaIsolatedWorkflowTypePiece: (workflowTypeId: string, payload: PiecePositionState) => dispatch(setBetaIsolatedWorkflowTypePiece(payload, workflowTypeId)),
        removeBetaIsolatedWorkflowTypePiece: (workflowTypeId: string, pieceId: string) => dispatch(removeBetaIsolatedWorkflowTypePiece(pieceId, workflowTypeId)),
        removeAllBetaIsolatedWorkflowTypePieces: (workflowTypeId: string) => dispatch(removeAllBetaIsolatedWorkflowTypePieces(workflowTypeId)),

        publishToLive: (workflowTypeId: string) => dispatch(publishFlowchartToLive(workflowTypeId)),
        setNextPiece: (pieceId: string, value: string | undefined) => dispatch(setNextPiece(pieceId, value)),

        setWorkflowTypeComparison: (workflowTypeId: string) => dispatch(setWorkflowTypeComparison(workflowTypeId)),
        updatePublishPieceMapping: (workflowTypeId: string, publishPieceMapping: PublishPieceMapping) => dispatch(updatePublishPieceMapping(workflowTypeId, publishPieceMapping)),

        setInfoMessage: (message: string) => dispatch(setInfoMessage(message)),
        clearInfoMessage: () => dispatch(clearInfoMessage()),
        unSelectPieces: (flag: boolean) => dispatch(unSelectPieces(flag))
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps & RouteComponentProps<{ id: string, version: string }>;

type OwnState = {
    isWaitingForDrag: boolean,
    isShowingShortcuts: boolean,
    isShowingValidations: boolean,
    isShowingRecentPieces: boolean,

    selectedPieceOption: string,

    expandedCategory: string | undefined,
    searchTerm: string,
    recentPieces: Array<{
        type: PieceType,
        name: string,
    }>,

    invalidPieces: Array<InvalidPieceDetails>,
    publishTimer: number | undefined,
    isAskingForPublishToLive: boolean,
    isAskingForCopyToBeta: boolean,

    betaCounter: number,
    isFlowchartOptions: boolean,
}

class ConnectedWorkflowTypeFlowchart extends Component<Props, OwnState> {

    state = {
        isWaitingForDrag: false,
        isShowingShortcuts: false,
        isShowingValidations: false,
        isShowingRecentPieces: false,

        selectedPieceOption: 'pieces',

        expandedCategory: undefined,
        searchTerm: '',
        recentPieces: new Array<{
            type: PieceType,
            name: string
        }>(),
        invalidPieces: new Array<InvalidPieceDetails>(),

        publishTimer: undefined,
        isAskingForPublishToLive: false,
        isAskingForCopyToBeta: false,

        betaCounter: 0,
        isFlowchartOptions: false,
    };

    isButtonType: boolean = true;

    toggleShortcutVisibility = () => {
        this.setState(prevState => {
            return {
                isShowingShortcuts: !prevState.isShowingShortcuts,
            };
        });
    }

    toggleValidationVisibility = () => {
        this.setState(prevState => {
            return {
                isShowingValidations: !prevState.isShowingValidations,
            };
        });
    }

    toggleRecentPieceVisibility = () => {
        this.setState(prevState => {
            return {
                isShowingRecentPieces: !prevState.isShowingRecentPieces,
            };
        });
    }

    setInvalidPiece = (pieceId: string, parentSplitPieces: Array<string>) => {

        this.setState(prevState => {
            if (prevState.invalidPieces.find(pieceInfo => pieceInfo.pieceId === pieceId)) {
                return null;
            }

            const newInvalidPieces = prevState.invalidPieces.concat({
                pieceId,
                parentSplitPieces,
            });

            return {
                invalidPieces: newInvalidPieces,
            };
        });
    }

    removeInvalidPiece = (pieceId: string) => {
        this.setState(prevState => {
            if (!prevState.invalidPieces.find(pieceInfo => pieceInfo.pieceId === pieceId)) {
                return null;
            }

            const newInvalidPieces = prevState.invalidPieces.filter(piece => piece.pieceId !== pieceId);
            return {
                invalidPieces: newInvalidPieces,
            };
        });
    }

    deletePiece = (pieceId: string) => {
        const workflowTypeId = this.props.match.params.id;
        const workflowType = this.props.workflowTypesData.byId[workflowTypeId];

        const pieceFound = !!workflowType.betaIsolatedPieces.find(isolatedPiece => isolatedPiece.piece === pieceId);

        if (!pieceFound) {
            // You can only delete isolated pieces
            console.log('You can only delete isolated pieces');
            return;
        };

        this.props.removeBetaIsolatedWorkflowTypePiece(workflowTypeId, pieceId);

        const allPiecesInPiece = getAllPiecesInPiece(this.props.piecesData, pieceId);

        if (!!this.state.invalidPieces.find(pieceInfo => pieceInfo.pieceId === pieceId)) {
            this.removeInvalidPiece(pieceId);
        };

        // Remove main piece with all the inner and next pieces of isolated pieces
        allPiecesInPiece.map(piece => {
            this.props.deletePiece(piece.id);
        });
    }

    duplicatePiece = (pieceId: string, attachedPiece: boolean = false) => {
        const pieceToCopy = this.props.piecesData.byId[pieceId];
        const flowchartHolderElement = document.getElementById('flowchart-holder');
        const workflowTypeId = this.props.match.params.id;
        const isLive = this.props.match.params.version === 'live';
        const isBeta = this.props.match.params.version === 'beta';

        if (this.props.isShowingRichTextEditor) {
            return '';
        }

        if (pieceToCopy.type === PieceType.START) {
            return '';
        }

        if (!flowchartHolderElement) {
            throw new Error('This element needs to exist');
        }

        const newId = duplicatePiece(this.props.piecesData, this.props.addFullPiece, undefined, pieceId)

        if (!attachedPiece) {
            if (isLive) {
                this.props.setIsolatedWorkflowTypePiece(workflowTypeId, {
                    piece: newId,
                    position: {
                        x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                        y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                    },
                });
            }

            if (isBeta) {
                this.props.setBetaIsolatedWorkflowTypePiece(workflowTypeId, {
                    piece: newId,
                    position: {
                        x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                        y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                    },
                });
            }
        }

        return newId;
    }

    pastePiece = (pieceId: string, attachedPiece: boolean = false) => {

        if (this.props.lastCopiedPieceFlowchartContext === this.props.match.params.id) {
            this.duplicatePiece(pieceId, attachedPiece);
            return;
        }

        const pieceToPaste = this.props.piecesData.byId[pieceId];
        const flowchartHolderElement = document.getElementById('flowchart-holder');
        const workflowTypeId = this.props.match.params.id;
        const isLive = this.props.match.params.version === 'live';
        const isBeta = this.props.match.params.version === 'beta';

        if (this.props.isShowingRichTextEditor) {
            return '';
        }

        if (pieceToPaste.type === PieceType.START) {
            return '';
        }

        if (!flowchartHolderElement) {
            throw new Error('This element needs to exist');
        }

        const newId = pastePiece(this.props.piecesData, this.props.addFullPiece, pieceId)

        if (!attachedPiece) {
            if (isLive) {
                this.props.setIsolatedWorkflowTypePiece(workflowTypeId, {
                    piece: newId,
                    position: {
                        x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                        y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                    },
                });
            }

            if (isBeta) {
                this.props.setBetaIsolatedWorkflowTypePiece(workflowTypeId, {
                    piece: newId,
                    position: {
                        x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                        y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                    },
                });
            }
        }

        return newId;
    }

    handleKeyPress = (e: KeyboardEvent) => {
        // Do not apply any shortcuts when you are typing something into an input element
        if (window.document.activeElement && (window.document.activeElement.tagName === 'INPUT' || window.document.activeElement.classList.contains('public-DraftEditor-content'))) {
            return;
        }

        switch (e.key) {
            case 'Backspace':
            case 'Delete':
                this.props.lastDraggedPiece && this.deletePiece(this.props.lastDraggedPiece);
                return;
            case 'D':
            case 'd':
                this.props.lastDraggedPiece && this.duplicatePiece(this.props.lastDraggedPiece);
                return;
            case 'C':
            case 'c':
                if (this.props.lastDraggedPiece && (e.ctrlKey || e.metaKey)) {
                    this.props.copyPiece(this.props.lastDraggedPiece, this.props.match.params.id);
                    this.props.setToastMessage('The piece has been copied');
                }
                return;
            case 'V':
            case 'v':
                if (e.ctrlKey || e.metaKey) {
                    this.props.lastCopiedPiece && this.pastePiece(this.props.lastCopiedPiece);
                    this.props.setToastMessage('The pieces have been pasted. Please double check the variables, custom fields, and statuses.');
                }
                return;
        }
    }

    handleKeyLift = (e: KeyboardEvent) => {
        // Do not apply any shortcuts when you are typing something into an input element
        if (window.document.activeElement && (window.document.activeElement.tagName === 'INPUT' || window.document.activeElement.classList.contains('public-DraftEditor-content'))) {
            return;
        }

        switch (e.key) {
            case '/':
                document.getElementById('piece-search')?.focus();
                return;
        }
    }

    componentWillMount() {
        this.props.toggleTopBarExpansion(false);
        document.addEventListener('keydown', this.handleKeyPress);
        document.addEventListener('keyup', this.handleKeyLift);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.handleKeyPress);
        document.removeEventListener('keyup', this.handleKeyLift);

        if (this.props.isFlowchartExpanded) {
            this.props.toggleFlowChartExpansion(false);
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        // The drag event interferes with the click event. Put the change in flowchart state as the last thing in the event queue so that the click event is fired before pointer events are removed from the flowchart
        window.setTimeout(() => {
            this.setState({
                isWaitingForDrag: this.props.isDragging,
            });
        }, 500);
    }

    expandCategory = (category: string) => {
        this.setState(prevState => {
            return {
                expandedCategory: prevState.expandedCategory === category ? undefined : category,
            }
        });
    }

    addPiece = (pieceType: PieceType, pieceName: string) => {
        const workflowTypeId = this.props.match.params.id;
        const newId = uuid.v4();
        const flowchartHolderElement = document.getElementById('flowchart-holder');
        const isLive = this.props.match.params.version === 'live';
        const isBeta = this.props.match.params.version === 'beta';

        let newRecentPieces = this.state.recentPieces.slice().filter(recentPiece => recentPiece.type !== pieceType);

        newRecentPieces.unshift({
            type: pieceType,
            name: pieceName,
        });

        newRecentPieces = newRecentPieces.slice(0, 5);

        this.setState({
            recentPieces: newRecentPieces,
        });

        if (!flowchartHolderElement) {
            throw new Error('This element needs to exist');
        }

        this.props.addPiece(newId, pieceType);

        if (isLive) {
            this.props.setIsolatedWorkflowTypePiece(workflowTypeId, {
                piece: newId,
                position: {
                    x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                    y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                },
            });
        }

        if (isBeta) {
            this.props.setBetaIsolatedWorkflowTypePiece(workflowTypeId, {
                piece: newId,
                position: {
                    x: flowchartHolderElement.scrollLeft + window.innerWidth / 2,
                    y: flowchartHolderElement.scrollTop + window.innerHeight / 2,
                },
            });
        }
    }

    updateSearchTerm = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            searchTerm: e.target.value,
        });
    }

    updateRichTextValue = (stringifiedValue: string) => {
        if (this.props.showPieceData) {
            this.props.setVariableForShow(this.props.showPieceData.id, stringifiedValue);
        }
    }

    goToInvalidPiece = async (pieceInfo: InvalidPieceDetails) => {

        const pieceElement = document.querySelector(`[data-piece-id="${pieceInfo.pieceId}"]`);
        pieceElement?.scrollIntoView();
    }

    selectPieceOption = (option: string) => {
        this.isButtonType = !this.isButtonType;
        this.setState({
            selectedPieceOption: option
        });
    }

    publishFlowchartToLive = () => {
        const workflowTypeId = this.props.match.params.id;
        this.props.publishToLive(workflowTypeId);
    }

    cancelPublishToLive = () => {
        this.setState({
            isAskingForPublishToLive: false,
        });
    }

    askForPublishToLive = () => {
        this.setState({
            isAskingForPublishToLive: true,
        });
    }

    markForPublishToLive = () => {

        this.setState({
            isAskingForPublishToLive: false,
        })

        const timeout = window.setTimeout(() => {
            this.publishFlowchartToLive();
            this.setState({
                publishTimer: undefined,
            });

            this.props.setInfoMessage('Publishing to LIVE...');

            window.setTimeout(() => {
                this.props.clearInfoMessage();
                this.copyLiveToBeta();

                this.setState({ isFlowchartOptions: false })
            }, 2000);
        }, 5000);

        this.setState({
            publishTimer: timeout,
        });
    }

    cancelPublishToBeta = () => {
        this.setState({
            isAskingForCopyToBeta: false,
        });
    }

    askForPublishToBeta = () => {
        this.setState({
            isAskingForCopyToBeta: true,
        });
    }

    markForPublishToBeta = () => {

        this.setState({
            isAskingForCopyToBeta: false,
        })

        const timeout = window.setTimeout(() => {
            this.props.setInfoMessage('Publishing to BETA...');

            window.setTimeout(() => {
                this.props.clearInfoMessage();
                this.copyLiveToBeta();

                const workflowTypeId = this.props.match.params.id;
                const workflowType = this.props.workflowTypesData.byId[workflowTypeId];
                this.props.removeAllBetaIsolatedWorkflowTypePieces(workflowType.id);

                this.setState({ isFlowchartOptions: false })
            }, 2000);

        }, 5000);

        this.setState({
            publishTimer: timeout,
        });
    }

    revertPublish = () => {
        clearTimeout(this.state.publishTimer);
        this.setState({
            publishTimer: undefined,
        });
    }

    copyLiveToBeta = () => {
        const workflowTypeId = this.props.match.params.id;
        const workflowType = this.props.workflowTypesData.byId[workflowTypeId];

        const pieceToCopy = this.props.piecesData.byId[workflowType.startPiece.piece];
        const flowchartHolderElement = document.getElementById('flowchart-holder');

        if (this.props.isShowingRichTextEditor) {
            return '';
        }

        if (pieceToCopy.type !== PieceType.START) {
            return;
        }

        if (!pieceToCopy.nextPiece) {
            return;
        }

        if (!flowchartHolderElement) {
            throw new Error('This element needs to exist');
        }


        const newPieces: Array<AllPieceTypes> = [];

        const addPiece = (pieceData: AllPieceTypes) => {
            newPieces.push(pieceData);
        }

        setTimeout(() => {

            if (pieceToCopy.type !== PieceType.START) {
                return;
            }

            if (!pieceToCopy.nextPiece) {
                return;
            }

            const duplicationMap: PublishPieceMapping = {};

            const newId = duplicatePiece(this.props.piecesData, addPiece, duplicationMap, pieceToCopy.nextPiece);

            this.props.addFullPieces(newPieces);

            this.props.setNextPiece(workflowType.betaStartPiece.piece, newId);

            this.props.updatePublishPieceMapping(workflowType.id, duplicationMap);

            window.setTimeout(() => {
                this.props.clearInfoMessage();

                this.setState(prevState => {
                    return {
                        betaCounter: prevState.betaCounter + 1,
                    }
                });
            }, 2000);
        }, 500)

    }

    toggleExpandFlowchart = () => {
        this.props.toggleFlowChartExpansion(!this.props.isFlowchartExpanded);
        this.props.toggleTopBarExpansion(false);
    }

    toggleFlowchartOptions = () => {
        this.setState({
            isFlowchartOptions: !this.state.isFlowchartOptions,
            isShowingShortcuts: false,
            isShowingValidations: false,
            isShowingRecentPieces: false,
        });
    }

    unSelectPiece = () => {
        if (this.props.piecesData) {
            this.props.unSelectPieces(false);
        }
    }

    goBack = () => {
        if (!this.props.match) {
            return;
        }

        const workflowTypeId = this.props.match.params.id;
        const workflowType = this.props.workflowTypesData.byId[workflowTypeId];
        this.props.selectWorkflowType(workflowType.id); this.props.history.push('/workflows/configuration');

        if (this.props.isDragging) {
            this.props.stopPieceDrag();
        }
    }

    render() {
        if (!this.props.match) {
            return <div></div>;
        }

        if (!this.props.isLoaded && !this.props.isPartiallyLoaded) {
            return <div></div>;
        }

        const isLive = this.props.match.params.version === 'live';
        const isBeta = this.props.match.params.version === 'beta';

        const workflowTypeId = this.props.match.params.id;
        const workflowType = this.props.workflowTypesData.byId[workflowTypeId];

        if (!this.props.isReadable) {
            return <Redirect to="/dashboard" />;
        }

        if (!workflowType) {
            return <div></div>
        }

        const isEditable = this.props.isWritable && !isLive;

        const startPiece = workflowType.startPiece ? getComponentForWorkflowPiece(this.props.workflowTypesData, this.props.piecesData, this.props.variablesData, workflowType, isEditable, this.props.flowchartPieceActions, this.props.setIsolatedWorkflowTypePiece.bind(this, workflowTypeId), this.props.removeIsolatedWorkflowTypePiece.bind(this, workflowTypeId), this.props.registerWorkflowTypeVariable.bind(this, workflowTypeId), workflowType.startPiece.piece, undefined) : undefined;
        const betaStartPiece = workflowType.betaStartPiece ? getComponentForWorkflowPiece(this.props.workflowTypesData, this.props.piecesData, this.props.variablesData, workflowType, isEditable, this.props.betaFlowchartPieceActions, this.props.setBetaIsolatedWorkflowTypePiece.bind(this, workflowTypeId), this.props.removeBetaIsolatedWorkflowTypePiece.bind(this, workflowTypeId), this.props.registerWorkflowTypeVariable.bind(this, workflowTypeId), workflowType.betaStartPiece.piece, undefined) : undefined;

        const isolatedPieces = workflowType.isolatedPieces.map(isolatedPieceData => {
            const isolatedPiece = isolatedPieceData.piece ? getComponentForWorkflowPiece(this.props.workflowTypesData, this.props.piecesData, this.props.variablesData, workflowType, isEditable, this.props.flowchartPieceActions, this.props.setIsolatedWorkflowTypePiece.bind(this, workflowTypeId), this.props.removeIsolatedWorkflowTypePiece.bind(this, workflowTypeId), this.props.registerWorkflowTypeVariable.bind(this, workflowTypeId), isolatedPieceData.piece, undefined, isolatedPieceData.position) : undefined;

            return isolatedPiece;
        });

        const betaIsolatedPieces = workflowType.betaIsolatedPieces?.map(isolatedPieceData => {
            const isolatedPiece = isolatedPieceData.piece ? getComponentForWorkflowPiece(this.props.workflowTypesData, this.props.piecesData, this.props.variablesData, workflowType, isEditable, this.props.betaFlowchartPieceActions, this.props.setBetaIsolatedWorkflowTypePiece.bind(this, workflowTypeId), this.props.removeBetaIsolatedWorkflowTypePiece.bind(this, workflowTypeId), this.props.registerWorkflowTypeVariable.bind(this, workflowTypeId), isolatedPieceData.piece, undefined, isolatedPieceData.position) : undefined;

            return isolatedPiece;
        });

        const piecesByCategory = workflowPiecesByCategory;
        const categoryOrdering = [
            'Questions/Answers',
            'Control',
            'Custom Fields',
            'Variables',
            'Boolean Operators',
            'Arithmetic Operators',
            'List Operators',
            'Date Operators',
        ];

        const cookbookEntries: Array<{
            name: string,
            callback: () => void,
        }> = [{
            name: 'Add new screen',
            callback: addNewScreen.bind(this, workflowType),
        }, {
            name: 'Add new question',
            callback: addNewQuestionInGroup.bind(this, workflowType),
        }];

        if (workflowType.affiliation === 'member') {
            cookbookEntries.push({
                name: 'Get member data',
                callback: getMemberCustomFieldData.bind(this, workflowType),
            });
            cookbookEntries.push({
                name: 'Store member data',
                callback: storeMemberCustomFieldData.bind(this, workflowType),
            })
        } else if (workflowType.affiliation === 'group') {
            cookbookEntries.push({
                name: 'Get group data',
                callback: getGroupCustomFieldData.bind(this, workflowType),
            });
            cookbookEntries.push({
                name: 'Store group data',
                callback: storeGroupCustomFieldData.bind(this, workflowType),
            })
        }

        const otherCategories = Object.keys(piecesByCategory).filter(category => {
            return !categoryOrdering.includes(category);
        });

        let textToShowInRichTextModal = '';

        if (this.props.showPieceData) {
            if (this.props.showPieceData.variableToShow) {
                textToShowInRichTextModal = this.props.showPieceData.variableToShow;
            }
        }

        const flowchartHolderElement = document.getElementById('flowchart-holder');
        const flowchartInfoForPiece: FlowchartInfoForPiece = {
            flowchartHolderElement,
            projects: [workflowType.project],
            variables: workflowType.variables.slice(),
            parentSplitPieceIds: [],
            parentIfPieceIndices: [],
            invalidPieces: this.state.invalidPieces,
            isValidating: this.state.isShowingValidations,
            isReadonly: !this.props.isWritable || isLive,
            highlightIncompletePieces: true,
            setInvalidPiece: this.setInvalidPiece,
            removeInvalidPiece: this.removeInvalidPiece,
            searchTerm: this.state.searchTerm,
        };
        const invalidPieces = this.state.invalidPieces.filter(pieceInfo => pieceInfo.pieceId in this.props.piecesData.byId);

        const allPieces = Object.keys(piecesByCategory).map(category => (piecesByCategory as any)[category].pieces).flat();
        const pieceNameMapping: {
            [type: string]: string,
        } = {};

        allPieces.forEach(piece => {
            pieceNameMapping[piece.type] = piece.name;
        });

        return (
            <React.Fragment>
                {this.props.isShowingRichTextEditor && <div className={styles.modalHolder}>
                    <section className={styles.richTextModal}>
                        <h2>Rich Text Editor</h2>
                        <div className={styles.closeIcon} onClick={this.props.hideRichTextEditor}>
                            <CancelIcon />
                        </div>
                        <RichTextModal
                            textToShow={textToShowInRichTextModal}
                            onContentUpdate={this.updateRichTextValue}
                        />
                        <button className={styles.submitModal} onClick={this.props.hideRichTextEditor}>Close</button>
                    </section>
                </div>}

                <FlowchartWindow
                    flowchartType={workflowType}
                    selectedNudge={this.props.selectedNudge}
                    toggleExpandFlowChart={this.toggleExpandFlowchart}
                    toggleFlowChartOptions={this.toggleFlowchartOptions}
                    goBack={this.goBack}
                    isExpanded={this.props.isFlowchartExpanded}
                    unSelectPiece={this.unSelectPiece}
                    selectComparison={this.props.setWorkflowTypeComparison.bind(this, workflowTypeId)}
                    isFlowChartMoreOptionShown={this.state.isFlowchartOptions}
                >
                    <div key={this.state.betaCounter} className={`${this.state.isWaitingForDrag ? styles.waitingForDragFlowchartHolder : styles.normalFlowchartHolder} ${isEditable && styles.flowchartWithPiceContainer}`}>
                        {isEditable &&
                            <section className={styles.piecesCollection}>
                                <section className={styles.pieceOptionWrapper}>
                                    <section className={styles.pieceOptions}>
                                        <Button
                                            size={'small'}
                                            onClick={() => this.selectPieceOption('pieces')}
                                            isRounded text={translatePhrase('Pieces')}
                                            type={this.isButtonType ? 'primary' : 'secondary'}
                                            padding={"5px 20px"}
                                            isHighlighted={NudgeType.FLOWCHART_PIECES === this.props.selectedNudge} />
                                        <Button
                                            size={'small'}
                                            onClick={() => this.selectPieceOption('cookbook')}
                                            isRounded
                                            text={translatePhrase('Cookbook')}
                                            type={!this.isButtonType ? 'primary' : 'secondary'}
                                            padding={"5px 20px"}
                                            isHighlighted={NudgeType.FLOWCHART_COOKBOOK === this.props.selectedNudge} />
                                    </section>
                                    {this.state.selectedPieceOption === 'pieces' &&
                                        <section className={styles.searchSection}>
                                            <input type="text"
                                                id="piece-search"
                                                placeholder="Search"
                                                className={styles.searchInput + " " + (NudgeType.FLOWCHART_SEARCH_PIECES === this.props.selectedNudge ? styles.nudgeActive : "")}
                                                value={this.state.searchTerm}
                                                onChange={this.updateSearchTerm} />
                                        </section>}
                                </section>
                                {this.state.selectedPieceOption === 'pieces' && categoryOrdering.concat(otherCategories).map(category => {
                                    let piecesInCategory: Array<JSX.Element | undefined> = [];
                                    if (category in piecesByCategory) {
                                        piecesInCategory = (piecesByCategory as { [key: string]: CategoryValue })[category].pieces.map(piece => (!!this.state.searchTerm && !piece.name.toLocaleLowerCase().includes(this.state.searchTerm.toLocaleLowerCase())) ? undefined :
                                            <section className={styles.pieceEntry} key={piece.name} onClick={() => this.addPiece(piece.type, piece.name)}>
                                                <div className={styles.pieceEntryName}>{piece.name}</div> {piece.tooltip && <a target='_blank' rel="noreferrer" href={piece.tooltip}><span className={styles.infoIcon}> <ExternalIcon /> </span></a>} </section>).filter(pieceElement => typeof pieceElement !== 'undefined');
                                    }

                                    return piecesInCategory.length > 0 && <section key={category}>
                                        <section className={styles.categoryHeading} onClick={() => this.expandCategory(category)}>
                                            <div className={styles.categoryIndicator} style={{ background: (piecesByCategory as { [key: string]: CategoryValue })[category].color }}></div>
                                            <div className={styles.categoryName}>{category}</div>
                                            <div className={this.state.expandedCategory === category ? styles.expandedCategoryChevron : styles.categoryChevron}><ChevronDownIcon /></div>
                                        </section>
                                        {(!!this.state.searchTerm || this.state.expandedCategory === category) && <div>
                                            {category in piecesByCategory && piecesInCategory}
                                        </div>}
                                    </section>
                                })}
                                {this.state.selectedPieceOption === 'cookbook' && cookbookEntries.map(cookbookEntry => {
                                    return <section className={styles.cookbookEntry} onClick={cookbookEntry.callback}>
                                        <div className={styles.entryName}>{cookbookEntry.name}</div>
                                    </section>
                                })}
                            </section>}
                        <div id="flowchart-holder" className={`${styles.flowchartContainer} ${!isEditable && ' react-drag-disabled'}`}>
                            {this.state.isFlowchartOptions && <div className={styles.buttonHolder + ' ' + (this.props.isTopBarExpanded ? styles.adjustPosition : '')}>
                                {isBeta && this.state.publishTimer &&
                                    <div className={styles.ActionButton} onClick={this.revertPublish}>
                                        <DecrementingCounter additionalText={translatePhrase('Undo before')} remaining={5} />
                                    </div>}
                                {isLive && this.state.publishTimer &&
                                    <div className={styles.ActionButton} onClick={this.revertPublish}>
                                        <DecrementingCounter additionalText={translatePhrase('Undo before')} remaining={5} />
                                    </div>}
                                {isBeta && !this.state.publishTimer && <Button
                                    size={'small'}
                                    onClick={() => this.askForPublishToLive()}
                                    isRounded
                                    text={translatePhrase('Publish to Live')}
                                    type={'primary'}
                                    color={'contrast'}
                                    padding={"5px 10px"}
                                    icon={<ExportIcon />}
                                    isHighlighted={NudgeType.FLOWCHART_PUBLISH_TO_LIVE === this.props.selectedNudge} />
                                }
                                {isLive && !this.state.publishTimer && <Button
                                    size={'small'}
                                    onClick={() => this.askForPublishToBeta()}
                                    isRounded
                                    text={translatePhrase('Copy Live to Test')}
                                    type={'primary'}
                                    padding={"5px 10px"}
                                    icon={<DuplicateIcon />}
                                    isHighlighted={NudgeType.FLOWCHART_COPY_LIVE_TO_BETA === this.props.selectedNudge} />}
                            </div>}
                            <WorkflowTypeContext.Provider value={workflowType}>
                                <FlowchartContext.Provider value={flowchartInfoForPiece}>
                                    <h4>{workflowType.name}</h4>
                                    {isLive && startPiece}
                                    {isLive && isolatedPieces.map((isolatedPiece, i) => <div className={styles.pieceHolder} key={i}>{isolatedPiece}</div>)}

                                    {isBeta && betaStartPiece}
                                    {isBeta && betaIsolatedPieces && betaIsolatedPieces.map((isolatedPiece, i) => <div className={styles.pieceHolder} key={i}>{isolatedPiece}</div>)}
                                    {this.state.isAskingForPublishToLive && <ConfirmModal
                                        confirmText="Publish the flowchart?"
                                        confirm={this.markForPublishToLive}
                                        cancel={this.cancelPublishToLive}
                                    />}
                                    {this.state.isAskingForCopyToBeta && <ConfirmModal
                                        confirmText="Publish the flowchart?"
                                        confirm={this.markForPublishToBeta}
                                        cancel={this.cancelPublishToBeta}
                                    />}
                                    {isEditable && this.state.isFlowchartOptions && <div className={styles.rightSideFixedElements + ' ' + (this.props.isTopBarExpanded ? styles.adjustPosition : '')}>
                                        <section className={styles.recentPieces}>
                                            <section className={styles.recentPiecesHeading}>
                                                <h5>Recent Pieces</h5>
                                                {this.state.recentPieces.length > 0 && <div className={styles.toggle} onClick={this.toggleRecentPieceVisibility}>{this.state.isShowingRecentPieces ? 'Hide' : 'Show'}</div>}
                                            </section>
                                            {this.state.isShowingRecentPieces && this.state.recentPieces.map(piece => {
                                                return <section className={styles.recentPiece} onClick={() => this.addPiece(piece.type, piece.name)}>{piece.name}</section>
                                            })}
                                        </section>
                                        <section className={styles.shortCuts}>
                                            <section className={styles.shortCut}>
                                                <h5>Shortcuts</h5>
                                                <div className={styles.toggle} onClick={this.toggleShortcutVisibility}>{this.state.isShowingShortcuts ? 'Hide' : 'Show'}</div>
                                            </section>
                                            {this.state.isShowingShortcuts &&
                                                <div className={styles.shortcutHolder}>
                                                    <section className={styles.shortCut}>
                                                        <div className={styles.name}>Duplicate piece</div>
                                                        <div className={styles.value}>d / D</div>
                                                    </section>
                                                    <section className={styles.shortCut}>
                                                        <div className={styles.name}>Copy piece</div>
                                                        <div className={styles.value}>Ctrl + C</div>
                                                    </section>
                                                    <section className={styles.shortCut}>
                                                        <div className={styles.name}>Paste piece</div>
                                                        <div className={styles.value}>Ctrl + V</div>
                                                    </section>
                                                    <section className={styles.shortCut}>
                                                        <div className={styles.name}>Delete piece</div>
                                                        <div className={styles.value}>Backspace / Del</div>
                                                    </section>
                                                    <section className={styles.shortCut}>
                                                        <div className={styles.name}>Search for piece</div>
                                                        <div className={styles.value}>/</div>
                                                    </section>
                                                </div>
                                            }
                                        </section>
                                        <section className={styles.validations}>
                                            <section className={styles.validationHeading}>
                                                <h5>Validation</h5>
                                                <div className={styles.toggle} onClick={this.toggleValidationVisibility}>{this.state.isShowingValidations ? 'Hide' : 'Show'}</div>
                                            </section>
                                            {this.state.isShowingValidations && <section className={styles.validation}>
                                                <div className={styles.name}>Validating now</div>
                                                <div className={styles.value}>{invalidPieces.length > 0 ? 'Click on a piece below to view the piece' : 'There are no invalid pieces in the flowchart'}</div>
                                            </section>}
                                            {this.state.isShowingValidations && invalidPieces.map(pieceInfo => {
                                                return <section className={styles.validation} onClick={e => this.goToInvalidPiece(pieceInfo)}>
                                                    <div className={styles.name}>{pieceNameMapping[this.props.piecesData.byId[pieceInfo.pieceId].type]}</div>
                                                    <div className={styles.value}>{pieceInfo.parentSplitPieces.map(parentId => `#${parentId.substring(0, 5)}`).join(', ')}</div>
                                                </section>
                                            })}
                                        </section>
                                    </div>}
                                </FlowchartContext.Provider>
                            </WorkflowTypeContext.Provider>
                        </div>
                    </div>
                </FlowchartWindow>
            </React.Fragment>
        );

    }
}

const WorkflowTypeFlowchart = withRouter(connect(mapStateToProps, mapDispatchToProps)(ConnectedWorkflowTypeFlowchart) as any);

export default WorkflowTypeFlowchart;