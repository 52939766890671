import React, { Component } from 'react';
import styles from './WorkflowFilter.module.scss';

import { ReactComponent as SmartFilterIcon } from '../../../assets/new-custom-icons/dashboard/smartfilter.svg';
import { ReactComponent as CancelIcon } from '../../../common/assets/close.svg';
import { ReactComponent as FilterIcon } from '../../../common/assets/filter.svg';
import { ReactComponent as PlusIcon } from '../../../assets/new-custom-icons/dashboard/plus.svg';

import { translatePhrase } from '../../../shared/helpers/translation';

import { ApplicationState } from '../../../shared/store/types';

import { filterWorkflowTable } from '../../../shared/store/workflows/actions';

import { connect } from 'react-redux';
import { getReadableDataForCustomField } from '../../../shared/store/custom-fields';
import { Dispatch } from 'redux';
import InputText from '../../../widgets/form/InputText';
import MultiSelectInputText from '../../../widgets/form/MultiSelectInput';
import Button from '../../../widgets/button/CommonButton';
import DateInput from '../../../widgets/form/DateInput';
import moment from 'moment';
import { getAncestorChainOfLocation, getLocationsAtLevel } from '../../../shared/helpers/locations';
import { WorkflowFilters } from '../../../shared/store/workflows/types';
import { WorkFlowSmartFilters, SmartFilter } from '../../../shared/helpers/smart-filters';
import onClickOutside from "react-onclickoutside";
import LoaderModal from '../../../widgets/loader/LoaderModal';

type OwnProps = {
    closeFilter: () => void,
    tags: Array<string>,
    workflowFilters: WorkflowFilters,
    getSmartFilter?: (smartFilter: string) => void,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    const allAllowedProjects = state.structure.projects.allEntries;

    let allAllowedLocations: Array<string> = []

    allAllowedProjects.forEach(projectId => {
        const projectData = state.structure.projects.byId[projectId];
        const allLevelsIdsInProject = projectData.children.filter(levelId => levelId in state.structure.levels.byId);

        for (const levelId of allLevelsIdsInProject) {
            const locationsAtLevel = getLocationsAtLevel(levelId).map(location => location.id);
            allAllowedLocations = allAllowedLocations.concat(locationsAtLevel);
        }
    });

    return {
        projectsData: state.structure.projects,
        levelsData: state.structure.levels,
        rolesData: state.structure.roles,
        locationsData: state.structure.locations,
        usersData: state.users,
        membersData: state.members,
        groupsData: state.groups,
        workflowsData: state.workflows,
        customFieldsData: state.workflows.types.customFields,
        customFieldOptionsData: state.workflows.types.customFieldOptions,
        roleCustomFieldsData: state.structure.roles.customFields,
        roleCustomFieldOptionsData: state.structure.roles.customFieldOptions,
        allAllowedLocations,
        myId: state.myData.id,

        filters: state.workflows.filters,
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        filterWorkflowTable: (dues: Array<string>, projects: Array<string>,
            types: Array<string>, statuses: Array<string>, users: Array<string>,
            locations: Array<string>, otherUsers: Array<string>, affiliations: Array<string>,
            customFields: { [customFieldId: string]: Array<string> }, createdDateRange: Array<string>,
            lastUpdatedDateRange: Array<string>, lastWorkedOn: { startTime?: string, endTime?: string },
            dueDateRange: Array<string>, unsynced: boolean, archived: boolean, isOutdated: boolean) =>
            dispatch(filterWorkflowTable(dues, projects, types, statuses, users, locations, otherUsers, affiliations,
                customFields, createdDateRange, lastUpdatedDateRange, lastWorkedOn, dueDateRange, unsynced, archived, isOutdated)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;


type OwnState = {
    dues: Array<string>,
    types: Array<string>,
    projects: Array<string>,
    statuses: Array<string>,
    users: Array<string>,
    otherUsers: Array<string>,
    affiliations: Array<string>,
    locations: Array<string>,
    toastLoader: JSX.Element | undefined,

    customFields: {
        [customFieldId: string]: Array<string>,
    },

    createdDateRange: Array<string>,
    lastUpdatedDateRange: Array<string>,

    lastWorkedOn: {
        startTime?: string,
        endTime?: string,
    },

    dueDateRange: Array<string>,

    unsynced: boolean,
    archived: boolean,
    isOutdated: boolean,

    appliedSmartFilter: string,
    clearKey: number,
};

class ConnectedWorkflowModify extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            dues: props.filters.dues,
            projects: props.filters.projects,
            types: props.filters.types,
            statuses: props.filters.statuses,
            users: props.filters.users,
            otherUsers: props.filters.otherUsers,
            affiliations: props.filters.affiliations,
            locations: props.filters.locations,
            customFields: props.filters.customFields,
            createdDateRange: props.filters.createdDateRange,
            lastUpdatedDateRange: props.filters.lastUpdatedDateRange,
            lastWorkedOn: {
                ...props.filters.lastWorkedOn,
            },
            dueDateRange: props.filters.dueDateRange,
            unsynced: props.filters.unsynced,
            archived: props.filters.archived,
            appliedSmartFilter: '',
            toastLoader: undefined,
            clearKey: 0,
            isOutdated: props.filters.isOutdated,
        };
    }

    changeDues = (dues: Array<string>) => {
        this.setState({
            dues,
        });
    }

    changeLocations = (locations: Array<string>) => {
        this.setState({
            locations,
            otherUsers: [],
            affiliations: [],
        });
    }

    changeUsers = (users: Array<string>) => {
        this.setState({
            users,
            otherUsers: [],
        });
    }

    changeOtherUsers = (otherUsers: Array<string>) => {
        this.setState({
            otherUsers,
        });
    }

    changeProjects = (projects: Array<string>) => {
        this.setState({
            projects,
            types: [],
            statuses: [],
            affiliations: [],
            customFields: {},
        });
    }

    changeTypes = (types: Array<string>) => {
        this.setState({
            types,
            statuses: [],
            affiliations: [],
            customFields: {},
        });
    }

    changeStatuses = (statuses: Array<string>) => {
        this.setState({
            statuses,
        });
    }

    changeAffiliations = (affiliations: Array<string>) => {
        this.setState({
            affiliations,
        });
    }

    changeUnsynced = (unsynced: boolean) => {
        this.setState({
            unsynced,
        });

        setTimeout(() => {
            this.submitFilters();
        }, 100);
    }

    changeArchived = (archived: boolean) => {
        this.setState({
            archived,
        });

        setTimeout(() => {
            this.submitFilters();
        }, 100);
    }

    changeCreatedFromDate = (value: string) => {
        const isoDateValue = moment(value).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
        this.setState(prevState => {
            const newDateRange = [value];
            if (prevState.createdDateRange.length > 1) {
                newDateRange.push(prevState.createdDateRange[1]);
            } else {
                newDateRange.push(value);
            }

            return {
                createdDateRange: newDateRange,
            };
        });
    }

    changeCreatedToDate = (value: string) => {
        const isoDateValue = moment(value).endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
        this.setState(prevState => {
            const newDateRange = [];
            if (prevState.createdDateRange.length > 0) {
                newDateRange.push(prevState.createdDateRange[0]);
            } else {
                newDateRange.push(value);
            }

            newDateRange.push(value);

            return {
                createdDateRange: newDateRange,
            };
        });
    }

    changeLastUpdatedFromDate = (value: string) => {
        const isoDateValue = moment(value).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
        this.setState(prevState => {
            const newDateRange = [value];
            if (prevState.lastUpdatedDateRange.length > 1) {
                newDateRange.push(prevState.lastUpdatedDateRange[1]);
            } else {
                newDateRange.push(value);
            }

            return {
                lastUpdatedDateRange: newDateRange,
            };
        });
    }

    changeLastUpdatedToDate = (value: string) => {
        const isoDateValue = moment(value).endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
        this.setState(prevState => {
            const newDateRange = [];
            if (prevState.lastUpdatedDateRange.length > 0) {
                newDateRange.push(prevState.lastUpdatedDateRange[0]);
            } else {
                newDateRange.push(value);
            }

            newDateRange.push(value);

            return {
                lastUpdatedDateRange: newDateRange,
            };
        });
    }

    changeLastWorkedOnFromDate = (value: string) => {
        this.setState(prevState => {
            return {
                lastWorkedOn: {
                    ...prevState.lastWorkedOn,
                    startTime: value,
                    endTime: this.state.lastWorkedOn.endTime ? this.state.lastWorkedOn.endTime : value 
                },
            };
        });
    }

    changeLastWorkedOnToDate = (value: string) => {
        this.setState(prevState => {
            return {
                lastWorkedOn: {
                    ...prevState.lastWorkedOn,
                    endTime: value,
                },
            };
        });
    }

    changeDueFromDate = (value: string) => {
        const isoDateValue = moment(value).startOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
        this.setState(prevState => {
            const newDateRange = [value];
            if (prevState.dueDateRange.length > 1) {
                newDateRange.push(prevState.dueDateRange[1]);
            } else {
                newDateRange.push(value);
            }

            return {
                dueDateRange: newDateRange,
            };
        });
    }

    changeDueToDate = (value: string) => {
        const isoDateValue = moment(value).endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]');
        this.setState(prevState => {
            const newDateRange = [];
            if (prevState.dueDateRange.length > 0) {
                newDateRange.push(prevState.dueDateRange[0]);
            } else {
                newDateRange.push(value);
            }

            newDateRange.push(value);

            return {
                dueDateRange: newDateRange,
            };
        });
    }

    submitFilters = () => {
        let prevFilters = {
            dues: this.state.dues,
            projects: this.state.projects,
            types: this.state.types,
            statuses: this.state.statuses,
            users: this.state.users,
            locations: this.state.locations,
            otherUsers: this.state.otherUsers,
            affiliations: this.state.affiliations,
            customFields: this.state.customFields,
            createdDateRange: this.state.createdDateRange,
            lastUpdatedDateRange: this.state.lastUpdatedDateRange,
            lastWorkedOn: this.state.lastWorkedOn,
            dueDateRange: this.state.dueDateRange,
            unsynced: this.state.unsynced,
            archived: this.state.archived,
            isOutdated: this.state.isOutdated,
        }

        if (JSON.stringify(prevFilters) !== JSON.stringify(this.props.filters)) {
            this.showToastLoader(translatePhrase("Please wait"));

            setTimeout(() => {
                this.props.filterWorkflowTable(
                    this.state.dues,
                    this.state.projects,
                    this.state.types,
                    this.state.statuses,
                    this.state.users,
                    this.state.locations,
                    this.state.otherUsers,
                    this.state.affiliations,
                    this.state.customFields,
                    this.state.createdDateRange,
                    this.state.lastUpdatedDateRange,
                    this.state.lastWorkedOn,
                    this.state.dueDateRange,
                    this.state.unsynced,
                    this.state.archived,
                    this.state.isOutdated,
                );
                if (JSON.stringify(prevFilters) === JSON.stringify(this.props.filters)) {
                    this.setState((prevState) => {
                        return {
                            toastLoader: undefined,
                            clearKey: prevState.clearKey + 1
                        }
                    });
                }
            }, 500);
        }
    }

    showToastLoader = (text: string) => {
        this.setState({
            toastLoader: <LoaderModal loaderText={[text]} isIndeterminate />
        });
    }

    clearFilters = () => {
        this.setState({ appliedSmartFilter: "" });
        if (this.props.getSmartFilter) {
            this.props.getSmartFilter("");
        }
        this.setState(prevState => {
            return {
                dues: ['due'],
                projects: [],
                types: [],
                statuses: [],
                locations: [],
                otherUsers: [],
                users: ['my', 'other'],
                affiliations: [],
                customFields: {},
                createdDateRange: [],
                lastUpdatedDateRange: [],
                lastWorkedOn: {},
                dueDateRange: [],
                archived: false,
                unsynced: false,
                isOutdated: false,

                clearKey: prevState.clearKey + 1,
            };
        });

        setTimeout(() => {
            this.submitFilters();
        }, 100);
    }

    clearDateFilters = () => {
        this.setState(prevState => {
            return {
                createdDateRange: [],
                lastUpdatedDateRange: [],
                lastWorkedOn: {},
                dueDateRange: [],
                clearKey: prevState.clearKey + 1,
            };
        });

        setTimeout(() => {
            this.submitFilters();
        }, 100);
    }

    selectSmartFilter = (filter: SmartFilter) => {
        if (filter.name === this.state.appliedSmartFilter) {
            this.removeSmartFilter();
            return;
        }

        this.clearDateFilters();

        setTimeout(() => {
            if (this.props.getSmartFilter) {
                this.props.getSmartFilter(filter.name);
            }
        }, 200);
        setTimeout(() => {
            this.setState({ appliedSmartFilter: filter.name });

            if (filter.name === 'Due this Week' || filter.name === 'Due this Month') {
                this.changeDues([filter.type, 'due']);
            } else {
                this.changeDues([filter.type]);
            }

            if (filter.type === 'completed') {
                this.changeLastWorkedOnFromDate(filter.dateRange.from);
                this.changeLastWorkedOnToDate(filter.dateRange.to);
            };
            if (filter.type === 'overdue') {
                this.changeDueFromDate(filter.dateRange.from);
                this.changeDueToDate(filter.dateRange.to);
            };
        }, 50);
        setTimeout(() => {
            this.submitFilters();
        }, 200);
    }

    removeSmartFilter = () => {
        if (this.state.appliedSmartFilter) {
            this.clearFilters();
            this.setState({ appliedSmartFilter: "" });
            if (this.props.getSmartFilter) {
                this.props.getSmartFilter("");
            }
        }
    }

    checkIfSmartFilterIsApplied = () => {
        if (this.props.workflowFilters.lastWorkedOn) {
            let lastWorkedOnFromDate = moment(this.props.workflowFilters.lastWorkedOn.startTime).format("YYYY-MM-DD");
            let lastWorkedOnToDate = moment(this.props.workflowFilters.lastWorkedOn.endTime).format("YYYY-MM-DD");

            for (const filter of WorkFlowSmartFilters) {
                if (lastWorkedOnFromDate === filter.dateRange.from && lastWorkedOnToDate === filter.dateRange.to && filter.type === 'completed') {
                    this.setState({ appliedSmartFilter: filter.name });
                }
            }
        };

        if (this.props.workflowFilters.dueDateRange) {
            let dueFromDate = moment(this.props.workflowFilters.dueDateRange[0]).format("YYYY-MM-DD");
            let dueToDate = moment(this.props.workflowFilters.dueDateRange[1]).format("YYYY-MM-DD");

            for (const filter of WorkFlowSmartFilters) {
                if (dueFromDate === filter.dateRange.from && dueToDate === filter.dateRange.to && filter.type === 'overdue') {
                    this.setState({ appliedSmartFilter: filter.name });
                }
            }
        }
    }

    changeIsOutDated = (isOutdated: boolean) => {
        this.setState({ isOutdated: isOutdated });

        setTimeout(() => {
            this.submitFilters();
        }, 100);
    }

    handleClickOutside = (event: MouseEvent) => {
        this.props.closeFilter();
    }

    componentDidMount() {
        this.checkIfSmartFilterIsApplied();
    }

    render() {

        const smartFilters = WorkFlowSmartFilters.map((filter: SmartFilter) => {
            return (
                <div key={filter.name}
                    title={translatePhrase(filter.name)}
                    className={styles.filterTag + " " + (this.state.appliedSmartFilter === filter.name ? styles.active : "")}
                    onClick={() => this.selectSmartFilter(filter)}>
                    <div className={styles.filterTerm}>{translatePhrase(filter.name)}</div>
                    {this.state.appliedSmartFilter !== filter.name &&
                        <div className={styles.plusIcon}>
                            <PlusIcon />
                        </div>
                    }
                    {this.state.appliedSmartFilter === filter.name &&
                        <div className={styles.closeIcon}>
                            <CancelIcon />
                        </div>
                    }
                </div>
            )
        });

        const duesList = [{
            name: 'Due',
            value: 'due',
        }, {
            name: 'Overdue',
            value: 'overdue',
        }, {
            name: 'Completed',
            value: 'completed',
        }];

        const projectsList = this.props.projectsData.allEntries.map(projectId => {
            return {
                name: this.props.projectsData.byId[projectId].name,
                value: projectId,
            };
        });

        const typesList = this.props.workflowsData.types.allEntries
            .filter(typeId => this.state.projects.length === 0 || this.state.projects.includes(this.props.workflowsData.types.byId[typeId].project))
            .map(typeId => {
                return {
                    name: this.props.workflowsData.types.byId[typeId].name,
                    value: typeId,
                };
            });

        const flowsList: Array<{ name: string, value: string }> = [{
            name: 'My flows',
            value: 'my',
        }, {
            name: 'Other users flows',
            value: 'other',
        }];

        let workflowType = this.state.types.length === 1 ? this.props.workflowsData.types.byId[this.state.types[0]] : undefined;

        let statusesList: Array<{ name: string, value: string }> = [];

        const allAllowedProjects: Set<string> = new Set();

        for (const projectId of this.state.projects) {
            allAllowedProjects.add(projectId);
        }

        for (const typeId of this.state.types) {
            const type = this.props.workflowsData.types.byId[typeId];
            allAllowedProjects.add(type.project);
        }

        const locationsList = this.props.allAllowedLocations
            .filter(locationId => {
                if (allAllowedProjects.size === 0) {
                    return true;
                }

                const parentLocationIds = getAncestorChainOfLocation(locationId);
                parentLocationIds.reverse();

                const topMostLocation = this.props.locationsData.byId[parentLocationIds[0]];

                return topMostLocation && topMostLocation.parent && allAllowedProjects.has(topMostLocation.parent);
            })
            .map(locationId => {
                const location = this.props.locationsData.byId[locationId];
                const parent = location.parent && location.parent in this.props.locationsData.byId ? this.props.locationsData.byId[location.parent] : undefined;

                const parentLocationIds = getAncestorChainOfLocation(locationId);
                parentLocationIds.reverse();
                const locationChain = parentLocationIds
                    .filter(locationId => locationId in this.props.locationsData.byId)
                    .concat([locationId])
                    .map(locationId => this.props.locationsData.byId[locationId].name)
                    .join(' > ');

                let parentName = '';

                if (location.parent) {
                    if (location.parent in this.props.locationsData.byId) {
                        let currentLocation = location;
                        let projectName = '';

                        while (currentLocation.parent && currentLocation.parent in this.props.locationsData.byId) {
                            currentLocation = this.props.locationsData.byId[currentLocation.parent];
                        }

                        if (currentLocation.parent && currentLocation.parent in this.props.projectsData.byId) {
                            projectName = this.props.projectsData.byId[currentLocation.parent].name;
                        }

                        parentName = translatePhrase(this.props.locationsData.byId[location.parent].name) + ' - ' + translatePhrase(projectName);
                    } else if (location.parent in this.props.projectsData.byId) {
                        parentName = translatePhrase(this.props.projectsData.byId[location.parent].name);
                    }
                }

                return {
                    name: `${translatePhrase(location.name)} (${parentName})`,
                    description: locationChain,
                    value: locationId,
                };
            });

        const usersList = this.props.usersData.allEntries
            .map(userId => {
                const user = this.props.usersData.byId[userId];
                const nameField = this.props.usersData.customFields.byId[this.props.usersData.nameFieldId];
                const name = getReadableDataForCustomField(user.customFields[nameField.id], nameField, userId, 'user');

                return {
                    name,
                    value: userId,
                };
            })

        if (typeof workflowType !== 'undefined' && this.state.types.length === 1) {
            statusesList = workflowType.statuses.map(statusId => {
                const status = this.props.workflowsData.types.statuses.byId[statusId];

                return {
                    name: status.name,
                    value: statusId,
                };
            });
        }

        return (
            <section className={styles.commonModalHolder + ' ' + styles.filterHolder}>
                <div className={styles.filterCloseButton} onClick={this.props.closeFilter}>
                    <Button title={translatePhrase("Close")} icon={<CancelIcon />} size={'small'} isRounded />
                </div>

                <section className={styles.addOrModifyListCard}>

                    <header>
                        <h2> <FilterIcon /> {translatePhrase('Workflow')} {translatePhrase('Filter')}</h2>
                        <Button onClick={() => this.clearFilters()} type={'tertiary'} text={translatePhrase('Default Filter')} isRounded={true} size={'small'} padding={'0px 10px'} />
                    </header>

                    <div className={styles.smartAndAdvancedFilterContainer}>
                        <div className={styles.smartFilter}>
                            <h4> <SmartFilterIcon />  {translatePhrase('Quick Filters')} </h4>
                            {smartFilters}
                        </div>

                        <div className={styles.container}>

                            <div key={this.state.clearKey} className={styles.allInputsHolder}>
                                <header className={styles.groupInputHeading}>
                                    <h4> {translatePhrase("Structure")} </h4>
                                </header>
                                <section className={styles.groupInputs}>
                                    <div className={styles.inputSegment} onMouseLeave={this.submitFilters}>
                                        <MultiSelectInputText
                                            options={projectsList}
                                            onChange={this.changeProjects}
                                            default={this.state.projects}
                                            placeholder={translatePhrase('Projects')}
                                            isAutoFocus={true}
                                        />
                                    </div>
                                    <div className={styles.inputSegment} onMouseLeave={this.submitFilters}>
                                        <MultiSelectInputText
                                            options={typesList}
                                            onChange={this.changeTypes}
                                            default={this.state.types}
                                            placeholder={translatePhrase('Types')}
                                            isAutoFocus={true}
                                        />
                                    </div>
                                    <div className={styles.inputSegment} onMouseLeave={this.submitFilters}>
                                        <MultiSelectInputText
                                            options={locationsList}
                                            onChange={this.changeLocations}
                                            default={this.state.locations}
                                            placeholder={translatePhrase('Locations')}
                                            isAutoFocus={true}
                                        />
                                    </div>
                                </section>

                                <header className={styles.groupInputHeading}>
                                    <h4> {translatePhrase("Status and Owner")} {this.state.types && <React.Fragment>: {this.state.types.map(typeId => this.props.workflowsData.types.byId[typeId].name).join(", ")} </React.Fragment>} </h4>
                                </header>
                                <section className={styles.groupInputs}>
                                    <div className={styles.inputSegment} onMouseLeave={this.submitFilters}>
                                        <MultiSelectInputText
                                            options={duesList}
                                            onChange={this.changeDues}
                                            default={this.state.dues}
                                            placeholder={translatePhrase('Dues')}
                                            isAutoFocus={true}
                                        />
                                    </div>
                                    {this.state.types.length === 1 && <div className={styles.inputSegment} onMouseLeave={this.submitFilters}>
                                        <MultiSelectInputText
                                            options={statusesList}
                                            onChange={this.changeStatuses}
                                            default={this.state.statuses}
                                            placeholder={translatePhrase('Statuses')}
                                            isAutoFocus={true}
                                        />
                                    </div>}
                                    <div className={styles.inputSegment} onMouseLeave={this.submitFilters}>
                                        <MultiSelectInputText
                                            options={flowsList}
                                            onChange={this.changeUsers}
                                            default={this.state.users}
                                            placeholder={translatePhrase('Flows')}
                                            isAutoFocus={true}
                                        />
                                    </div>
                                    {this.state.users.includes('other') && <div className={styles.inputSegment}>
                                        <MultiSelectInputText
                                            options={usersList}
                                            onChange={this.changeOtherUsers}
                                            default={this.state.otherUsers}
                                            placeholder={translatePhrase('Other users')}
                                            isAutoFocus={true}
                                        />
                                    </div>}
                                </section>

                                <header className={styles.groupInputHeading}>
                                    <h4> {translatePhrase("Dates")} </h4>
                                </header>
                                <section className={styles.groupInputs}>
                                    <div onMouseLeave={this.submitFilters} className={styles.inputSegment + ' ' + styles.dateInput} title={translatePhrase('Due Date') + ' (' + translatePhrase('From') + ')'}>
                                        <DateInput placeholder={translatePhrase('Due Date') + ' (' + translatePhrase('From') + ')'}
                                            default={this.state.dueDateRange.length > 0 && moment(this.state.dueDateRange[0], 'YYYY-MM-DD', true).isValid() ? new Date(this.state.dueDateRange[0]) : undefined}
                                            onChange={(e) => this.changeDueFromDate(e)} />
                                    </div>
                                    <div onMouseLeave={this.submitFilters} className={styles.inputSegment + ' ' + styles.dateInput} title={translatePhrase('Due Date') + ' (' + translatePhrase('To') + ')'}>
                                        <DateInput placeholder={translatePhrase('Due Date') + ' (' + translatePhrase('To') + ')'}
                                            default={this.state.dueDateRange.length > 1 && moment(this.state.dueDateRange[1], 'YYYY-MM-DD', true).isValid() ? new Date(this.state.dueDateRange[1]) : undefined}
                                            onChange={(e) => this.changeDueToDate(e)} />
                                    </div>

                                    <div onMouseLeave={this.submitFilters} className={styles.inputSegment + ' ' + styles.dateInput} title={translatePhrase('Created Date') + ' (' + translatePhrase('From') + ')'}>
                                        <DateInput placeholder={translatePhrase('Created Date') + ' (' + translatePhrase('From') + ')'}
                                            default={this.state.createdDateRange.length > 0 && moment(this.state.createdDateRange[0], 'YYYY-MM-DD', true).isValid() ? new Date(this.state.createdDateRange[0]) : undefined}
                                            onChange={(e) => this.changeCreatedFromDate(e)} />
                                    </div>
                                    <div onMouseLeave={this.submitFilters} className={styles.inputSegment + ' ' + styles.dateInput} title={translatePhrase('Created Date') + ' (' + translatePhrase('To') + ')'}>
                                        <DateInput placeholder={translatePhrase('Created Date') + ' (' + translatePhrase('To') + ')'}
                                            default={this.state.createdDateRange.length > 1 && moment(this.state.createdDateRange[1], 'YYYY-MM-DD', true).isValid() ? new Date(this.state.createdDateRange[1]) : undefined}
                                            onChange={(e) => this.changeCreatedToDate(e)} />
                                    </div>

                                    <div onMouseLeave={this.submitFilters} className={styles.inputSegment + ' ' + styles.dateInput} title={translatePhrase('Last WorkedOn Date') + ' (' + translatePhrase('From') + ')'}>
                                        <DateInput placeholder={translatePhrase('Last Worked on Date') + ' (' + translatePhrase('From') + ')'}
                                            default={this.state.lastWorkedOn.startTime && moment(this.state.lastWorkedOn.startTime, 'YYYY-MM-DD', true).isValid() ? new Date(this.state.lastWorkedOn.startTime) : undefined}
                                            onChange={(e) => this.changeLastWorkedOnFromDate(e)} />
                                    </div>
                                    <div onMouseLeave={this.submitFilters} className={styles.inputSegment + ' ' + styles.dateInput} title={translatePhrase('Last WorkedOn Date') + ' (' + translatePhrase('To') + ')'}>
                                        <DateInput placeholder={translatePhrase('Last Worked on Date') + ' (' + translatePhrase('To') + ')'}
                                            default={this.state.lastWorkedOn.endTime && moment(this.state.lastWorkedOn.endTime, 'YYYY-MM-DD', true).isValid() ? new Date(this.state.lastWorkedOn.endTime) : undefined}
                                            onChange={(e) => this.changeLastWorkedOnToDate(e)} />
                                    </div>
                                </section>

                                <header className={styles.groupInputHeading}>
                                    <h4> {translatePhrase("Misc")} </h4>
                                </header>
                                <section className={styles.groupInputs}>
                                    <div className={styles.inputSegment}>
                                        <InputText isBooleanField={true} placeholder={translatePhrase('Unsynced')} onChange={value => this.changeUnsynced(value === 'Yes')}
                                            defaultBooleanValue={this.state.unsynced ? true : false} />
                                    </div>

                                    <div className={styles.inputSegment}>
                                        <InputText isBooleanField={true} placeholder={translatePhrase('Archived')} onChange={value => this.changeArchived(value === 'Yes')}
                                            defaultBooleanValue={this.state.archived ? true : false} />
                                    </div>
                                    <div className={styles.inputSegment}>
                                        <InputText
                                            isBooleanField={true}
                                            placeholder={translatePhrase('Outdated')}
                                            onChange={value => this.changeIsOutDated(value === 'Yes')}
                                            defaultBooleanValue={!!this.props.filters.isOutdated}
                                        />
                                    </div>
                                </section>

                            </div>

                        </div>
                    </div>

                </section>

                {this.state.toastLoader}
            </section>
        );
    }
}

const EnhancedWorkflowModify = onClickOutside(ConnectedWorkflowModify, {
    excludeScrollbar: true
});

const WorkflowModify = connect(mapStateToProps, mapDispatchToProps)(EnhancedWorkflowModify);

export default WorkflowModify;
