import { FC } from 'react';
import styles from './TimeLineFieldList.module.scss';
import { Option, UserEntry } from './Timeline';
import { isWebUri } from 'valid-url';
import { getFileExtension, getFormatedFileSize, updateLinkToCurrentOrigin } from '../../../shared/helpers/file-utilities';
import { saveAs } from 'file-saver';

import { ReactComponent as DownloadIcon } from '../../../common/assets/download-alt.svg';
import { ReactComponent as JpgIcon } from '../../../common/assets/bxs-file-jpg.svg';
import { ReactComponent as PngIcon } from '../../../common/assets/bxs-file-png.svg';
import { ReactComponent as PdfIcon } from '../../../common/assets/bxs-file-pdf.svg';
import { ReactComponent as AudioIcon } from '../../../common/assets/audio.svg';
import { ReactComponent as VideoIcon } from '../../../common/assets/video-filled.svg';
import { ReactComponent as CssIcon } from '../../../common/assets/bxs-file-css.svg';
import { ReactComponent as DocIcon } from '../../../common/assets/bxs-file-doc.svg';
import { ReactComponent as ImgIcon } from '../../../common/assets/bxs-file-img.svg';
import { ReactComponent as GifIcon } from '../../../common/assets/bxs-file-gif.svg';
import { ReactComponent as JpegIcon } from '../../../common/assets/bxs-file-jpeg.svg';
import { FieldType } from '../../../shared/store/custom-fields/types';
import { getStoredFileInDB } from '../../../shared/store/file-operations';

interface OwnProps {
    userEntry: UserEntry;
    token: string;
}

const TimeLineFieldList: FC<OwnProps> = (props: OwnProps) => {
    const { userEntry, token } = props;

    const getIconsByFileType = (fileType: string, props: any) => {

        switch (fileType.toUpperCase()) {
            case 'JPG':
                return <JpgIcon {...props} />
            case 'JPEG':
                return <JpegIcon {...props} />
            case 'PNG':
                return <PngIcon {...props} />
            case 'GIF':
                return <GifIcon {...props} />
            case 'IMG':
                return <ImgIcon {...props} />
            case 'CSS':
                return <CssIcon {...props} />
            case 'PDF':
                return <PdfIcon {...props} />
            case 'MP3':
            case 'AAC':
            case 'M4A':
            case 'WAV':
            case 'X-M4A':
                return <AudioIcon {...props} />
            case 'MP4':
            case 'MOV':
                return <VideoIcon {...props} />
            case 'DOC':
            case 'DOCX':
            case 'CSV':
                return <DocIcon {...props} />
            default:
                break;
        }
    }

    const downloadLocalFile = async (option: Option) => {
        if (option.type !== FieldType.FILE) {
            return;
        }

        const savedFile = await getStoredFileInDB(option.oldValue);

        if (savedFile?.file) {
            saveAs(savedFile.file, savedFile.file.name);
        }
    }

    return (
        <section className={styles.fieldList}>
            {userEntry.normalEntries.map((option) => {
                const fileTypeIconProps = {
                    width: "16",
                    height: "21",
                    fill: "#9A8CA0",
                }

                let fileExtensionName = "";
                let fileName = "";
                let isFileLink = false;
                let fileSize = option.fileSize ? getFormatedFileSize(option.fileSize) : "";

                if ((isWebUri(option.value) || option.value.includes("https://")) && !option.value.includes('finsal')) {
                    fileName = decodeURI(option.value).split('/')[5];
                    if (!fileName) {
                        fileName = decodeURI(option.value).split('/')[4];
                    }
                    fileExtensionName = getFileExtension(fileName).toLocaleUpperCase();
                    isFileLink = true;
                } else if (option.value.startsWith('/file-f123f-')) {
                    fileName = option.value.split('/file-f123f-')[1];
                    fileExtensionName = getFileExtension(option.value).toLocaleUpperCase();
                    isFileLink = true;
                } else {
                    fileName = option.value;
                    fileExtensionName = getFileExtension(option.value).toLocaleUpperCase();
                    isFileLink = false;
                }

                return (
                    <div className={styles.entry} key={option.name}>
                        <label> {option.name} </label>
                        {
                            option.type !== 'FREE_TEXT' ?
                                <span className={styles.selectedOptions}>
                                    {isFileLink ?
                                        <div className={styles.fieldDetailsContainer}>
                                            <div className={styles.fieldDetailsHolder}>
                                                <div className={styles.fileTypeIconHolder}>
                                                    {getIconsByFileType(fileExtensionName, fileTypeIconProps)}
                                                </div>
                                                <div className={styles.fieldDetails}>
                                                    <div className={styles.fileName} title={fileName}>{fileName}</div>
                                                    <div className={styles.fieldSubDetail}>
                                                        <div className={styles.fileExtensionName}>{fileExtensionName}</div>
                                                        <div className={styles.dot}></div>
                                                        <div className={styles.fileSize}>{fileSize}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <a className={styles.downloadIconHolder} target="_blank" rel="noopener noreferrer" href={updateLinkToCurrentOrigin(option.value) + '?token=' + token}>
                                                <DownloadIcon width={"11"} height={"11"} fill='#ffffff' />
                                            </a>
                                        </div>
                                        :
                                        option.type === "FILE" ? (
                                            <div className={styles.fieldDetailsContainer}>
                                                <div className={styles.fieldDetailsHolder}>
                                                    <div className={styles.fileTypeIconHolder}>
                                                        {getIconsByFileType(fileExtensionName, fileTypeIconProps)}
                                                    </div>
                                                    <div className={styles.fieldDetails}>
                                                        <div className={styles.fileName} title={fileName}>{fileName}</div>
                                                        <div className={styles.fieldSubDetail}>
                                                            <div className={styles.fileExtensionName}>{fileExtensionName}</div>
                                                            <div className={styles.dot}></div>
                                                            <div className={styles.fileSize}>{fileSize}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.downloadIconHolder} onClick={() => downloadLocalFile(option)}>
                                                    <DownloadIcon width={"11"} height={"11"} fill='#ffffff' />
                                                </div>
                                            </div>
                                        )
                                            :
                                            (
                                                <span onClick={() => downloadLocalFile(option)}>{option.value}</span>
                                            )
                                    }
                                </span>
                                :
                                <img src={option.value} alt="signature" />
                        }
                    </div>
                )
            })}
        </section>
    );
}

export default TimeLineFieldList;