import { ADD_SYNC_LOG, AddSyncLog, CLEAR_SYNC_PROCESS_MESSAGE, ClearSyncProcessMessage, FAILED_SYNC_LOG, FailedSyncLog, GENERATE_SYNC_LOG, GenerateSynclog, SET_SYNC_PROCESS_MESSAGE, SetSyncProcessMessage, SyncMessage, SyncMetaLog } from "./types";
import { v4 as uuid } from 'uuid'

export function generateSynclog(): GenerateSynclog {
    const synclogId = uuid();
    return {
        type: GENERATE_SYNC_LOG,
        id: synclogId
    }
};

export function addSyncLog(synclog: SyncMetaLog): AddSyncLog {
    return {
        type: ADD_SYNC_LOG,
        payload: synclog
    }
};

export function logFailedSyncLog(message: string): FailedSyncLog {
    return {
        type: FAILED_SYNC_LOG,
        message
    }
}

export function setSyncProcessMessage(message: SyncMessage): SetSyncProcessMessage {
    return {
        type: SET_SYNC_PROCESS_MESSAGE,
        message
    }
};

export function clearSyncProcessMessage(): ClearSyncProcessMessage {
    return {
        type: CLEAR_SYNC_PROCESS_MESSAGE,
    }
};
