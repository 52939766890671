import React, { Component, createRef, KeyboardEvent, RefObject } from 'react';
import { ReactComponent as SearchIcon } from '../../assets/new-custom-icons/dashboard/search.svg';
import styles from './OptionsList.module.scss';
import Option from './Option';
import { translatePhrase } from '../../shared/helpers/translation';
import { OptionInput } from './MultiSelectInput';
import Checkbox from './Checkbox';
import { all } from 'redux-saga/effects';

type OwnProps = {
    options: Array<OptionInput>,
    selectedOptions: Array<OptionInput>,
    isShowing: boolean,
    isSearchDisabled?: boolean,
    isAutoFocus?: boolean,

    onSelect: (selectedName: string, selectedValue: string) => void
    selectAll: () => void,
    selectNone: () => void,
    selectSearchedOptions: (options: Array<OptionInput>) => void
};

type OwnState = {
    search: string,
    highlightedIndex: number | undefined,
    options: Array<OptionInput>
};

class MultiSelectOptionsList extends Component<OwnProps, OwnState> {

    inputRef: RefObject<HTMLInputElement>;
    optionsRef: RefObject<HTMLElement>;

    constructor(props: Readonly<OwnProps>) {
        super(props);

        this.inputRef = createRef();
        this.optionsRef = createRef();

        this.state = {
            search: '',
            highlightedIndex: undefined,
            options: []
        };
    };

    componentDidUpdate = (prevProps: Readonly<OwnProps>, prevState: Readonly<OwnState>, snapshot?: any) => {
        if (prevProps.isShowing !== this.props.isShowing) {
            const selectedOptions: Array<OptionInput> = [];  // These are the selected options
            const otherOptions: Array<OptionInput> = [];  // These are the non selected options

            const selectedOptionIds = this.props.selectedOptions.map(option => option.value);

            for (const option of this.props.options) {
                if (selectedOptionIds.includes(option.value)) {
                    selectedOptions.push(option);
                } else {
                    otherOptions.push(option);
                }
            };

            const options = selectedOptions.concat(otherOptions)

            this.setState({ options });
        }
    }

    handleKeyPress = (e: KeyboardEvent<HTMLInputElement>) => {
        enum keyCodes { ENTER_KEY = 13, UP_KEY = 38, DOWN_KEY = 40 };

        // If the "Enter" key is pressed, select the highlighted option
        if (e.keyCode === keyCodes.ENTER_KEY) {

            if (this.state.highlightedIndex !== undefined && this.state.highlightedIndex === 0) {
                if (JSON.stringify(this.props.selectedOptions) === JSON.stringify(this.props.options)) {
                    this.props.selectNone();
                    return;
                };

                this.selectSearchedOptions()
            }

            if (this.state.highlightedIndex !== undefined && this.state.highlightedIndex >= 1) {
                const availableOptions = this.getAvailableOptions();
                const selectedOption = availableOptions[this.state.highlightedIndex - 1];
                this.props.onSelect(selectedOption.name, selectedOption.value);

                setTimeout(() => {
                    this.scrollToView("smooth");
                }, 200);

            }
        }

        if (e.keyCode === keyCodes.UP_KEY) {
            if (this.state.highlightedIndex === undefined) {

                // If no option is highlighted, highlight the last option...
                this.setState({
                    highlightedIndex: this.props.options.length - 1
                });
            } else {
                const currentOptionIndex = this.state.highlightedIndex;

                // If the highlighted option is the first option, highlight the last option. Otherwise, highlight the previous option

                const availableOptions = this.getAvailableOptions();
                const previousOptionIndex = currentOptionIndex > 0 ? currentOptionIndex - 1 : availableOptions.length;

                this.setState({
                    highlightedIndex: previousOptionIndex
                });
            }

            setTimeout(() => {
                this.scrollToView("auto");
            }, 200);
        }

        if (e.keyCode === keyCodes.DOWN_KEY) {

            if (this.state.highlightedIndex === undefined) {
                this.setState({
                    highlightedIndex: 0
                });
            } else {
                const availableOptions = this.getAvailableOptions();

                const currentOptionIndex = this.state.highlightedIndex;
                const nextOptionIndex = currentOptionIndex < availableOptions.length ? currentOptionIndex + 1 : 0;
                this.setState({
                    highlightedIndex: nextOptionIndex
                });
            }

            setTimeout(() => {
                this.scrollToView("auto");
            }, 200);
        }

        this.setState({
            search: e.currentTarget.value,
        });

    };

    selectSearchedOptions() {
        const availableOptions = this.getAvailableOptions();
        this.props.selectSearchedOptions(availableOptions);
    }

    scrollToView = (scroolBehavior: ScrollBehavior | undefined) => {
        if (this.state.highlightedIndex !== undefined) {

            if (this.optionsRef && this.optionsRef.current) {
                const index = this.state.highlightedIndex + 1;
                const option = this.optionsRef.current.children[index];
                if (option !== undefined) option.scrollIntoView({ behavior: scroolBehavior, block: "center" });

            }
        }
    }

    getAvailableOptions = () => {
        return this.state.search ?
            this.state.options.filter(option => option.name.toLocaleLowerCase().indexOf(this.state.search.toLocaleLowerCase().trim()) !== -1)
            :
            this.state.options;
    }

    setSearch = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.currentTarget.value) {
            this.setState({
                search: e.currentTarget.value,
            });
            return;
        }

        this.setState({
            search: ''
        })
    };

    toggleSearchedFields = () => {
        if (this.state.search) {
            const availableOptions = this.getAvailableOptions();
            this.props.selectedOptions.length === availableOptions.length ? this.props.selectNone() : this.selectSearchedOptions()
            return;
        }
        this.props.selectedOptions.length === this.props.options.length ? this.props.selectNone() : this.props.selectAll()
    }

    render() {

        const availableOptions = this.getAvailableOptions();

        // Todo: this code has to be moved to lifecycle method
        setTimeout(() => {
            if (this.props.isAutoFocus) {
                this.inputRef.current?.focus();
            }
        }, 200);

        const selectAllOption = <Checkbox
            isHighlighted={this.state.highlightedIndex === 0}
            name={this.state.search ? translatePhrase('Select searched fields') : this.props.selectedOptions.length === this.props.options.length ? translatePhrase('Remove all fields') : translatePhrase('Select all fields')}
            value={this.props.selectedOptions.length === this.props.options.length ? 'Yes' : 'No'}
            defaultChecked={this.state.search ? this.props.selectedOptions.length === availableOptions.length : this.props.selectedOptions.length === this.props.options.length}
            toggle={this.toggleSearchedFields}
        />

        const optionElements = availableOptions.map((option, index) => <Option
            key={option.value}
            isMultiple
            name={translatePhrase(option.name)}
            isSelected={this.props.selectedOptions.some(selectedOption => selectedOption.value === option.value)}
            isHighlighted={index + 1 === this.state.highlightedIndex}
            description={option.description}
            value={option.value}
            search={this.state.search}
            onClick={(name: string, value: string) => this.props.onSelect(name, value)} />)

        return <section data-selector="options-list" ref={this.optionsRef} className={this.props.isShowing ? styles.optionsList : styles.hiddenOptionsList}>
            {!this.props.isSearchDisabled && <div className={styles.searchInputHolder}>
                <input ref={this.inputRef} className={styles.searchInput} type="text" placeholder="Search" onKeyDown={this.handleKeyPress} onKeyUp={this.setSearch} />
                <SearchIcon />
            </div>}
            <div data-selector="select-all-options" className={styles.checkboxHolder}>

                {availableOptions.length > 0 && selectAllOption}

            </div>
            {optionElements}
        </section>;

    }

}

export default MultiSelectOptionsList;