import React, { Component, ChangeEvent } from 'react';
import styles from './ReportsTable.module.scss';
import moment from 'moment';

import ReportModify from './ReportModify';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../shared/store/types';

import { addReport, updateReport, deleteReport, searchReportTable, goToPageReportTable, setPageSizeReportTable, sortReportTable, storeDataForReport, StartReportDataGeneration } from '../../../shared/store/reports/actions';
import { IUpdateableReportData } from '../../../shared/store/reports/types';
import TableWithMeta from '../../../widgets/table/TableWithMeta';
import { getReadableDataForCustomField } from '../../../shared/store/custom-fields';
import { DefaultFlowchartProcessState } from '../../../shared/store/flowchart/types';
import { isUUID } from '../../../shared/helpers/utilities';
import { getReportValue } from '../../../shared/store/flowchart/helpers/report';
import { translatePhrase } from '../../../shared/helpers/translation';
import { Permissions } from '../../../shared/store/permissions/types';
import { setInfoMessage, setToastMessage } from '../../../shared/store/my-data/actions';
import { TableAction, TableHeading, TableRow } from '../../../widgets/table/Table';
import Button from '../../../widgets/button/CommonButton';

import { ReactComponent as RocketIcon } from '../../../common/assets/rocket.svg';
import { ReactComponent as DownArrowIcon } from '../../../assets/new-custom-icons/dashboard/down-arrow.svg';
import store from '../../../shared/store/main';
import { getVisibleGroupIds, getVisibleMemberIds, getVisibleUserIds, getVisibleWorkflowIds } from '../../../shared/helpers/visible-entities';
import { VariableValueType } from '../../../shared/helpers/common-types';
import { getFilteredReports } from './selector';
import ReportFilter from './ReportFilter';
import { BASE_URL } from '../../../shared/store/url';
import { CSVLink } from 'react-csv';
import axios from 'axios';
import LoaderModal from '../../../widgets/loader/LoaderModal';

const mapStateToProps = (state: ApplicationState) => {
    const currentPageNumber = state.reports.currentPageNumber,
        pageSize = state.reports.pageSize,
        reportSearchTerm = state.reports.searchTerm,
        filters = state.reports.filters;

    const filteredReports = getFilteredReports(state);

    const tags: Array<string> = [];

    if (filters.projects.length > 0) {
        tags.push('Projects: ' + filters.projects.map(projectId => state.structure.projects.byId[projectId].name).join(', '));
    }

    if (filters.types.length > 0) {
        tags.push('Types: ' + filters.types.map(typeId => state.reports.types.byId[typeId].name).join(', '));
    }

    if (filters.users.length > 0) {
        tags.push('Users: ' + filters.users.map(userId => state.users.byId[userId].customFields[state.users.nameFieldId] as string).join(', '));
    }

    if (filters.unsynced) {
        tags.push('Unsynced');
    }

    if (filters.archived) {
        tags.push('Archived');
    }

    if (filters.createdDateRange.length > 1) {
        tags.push(`Created date range: ${moment(filters.createdDateRange[0]).format('DD MMM YYYY')} - ${moment(filters.createdDateRange[1]).format('DD MMM YYYY')}`);
    }

    if (filters.lastUpdatedDateRange.length > 1) {
        tags.push(`Last updated date range: ${moment(filters.lastUpdatedDateRange[0]).format('DD MMM YYYY')} - ${moment(filters.lastUpdatedDateRange[1]).format('DD MMM YYYY')}`);
    }

    if (filters.generatedDateRange.length > 1) {
        tags.push(`Generated date range: ${moment(filters.generatedDateRange[0]).format('DD MMM YYYY')} - ${moment(filters.generatedDateRange[1]).format('DD MMM YYYY')}`);
    }

    if (tags.length > 0) {
        tags.push('Count: ' + filteredReports.length);
    }

    return {
        reportsList: filteredReports.slice((currentPageNumber - 1) * pageSize, currentPageNumber * pageSize),
        totalNoOfReports: filteredReports.length,
        pageSize: pageSize,
        searchTerm: reportSearchTerm,
        pageNumber: currentPageNumber,
        totalPages: Math.ceil(filteredReports.length / pageSize),
        tags,

        reportFilters: state.reports.filters,
        reportSort: state.reports.sort,

        reportsData: state.reports,
        reportTypesData: state.reports.types,
        usersData: state.users,
        membersData: state.members,
        groupsData: state.groups,
        myId: state.myData.id,
        myToken: state.myData.token,

        myPermissions: state.permissions.myPermissions,

        areFiltersExpanded: false,

        applicationState: state,
        createdIds: state.reports.createdIds,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        searchTable: (searchTerm: string) => dispatch(searchReportTable(searchTerm)),
        goToPage: (page: number) => dispatch(goToPageReportTable(page)),
        changePageSize: (size: number) => dispatch(setPageSizeReportTable(size)),
        sort: (column: string, order: 'ASC' | 'DESC') => dispatch(sortReportTable(column, order)),

        addReport: (payload: IUpdateableReportData) => dispatch(addReport(payload)),
        deleteReport: (id: string) => dispatch(deleteReport(id)),
        updateReport: (payload: IUpdateableReportData) => dispatch(updateReport(payload)),
        startReportDataGeneration: () => dispatch(StartReportDataGeneration()),
        storeDataForReport: (reportId: string, data: Array<Array<string>>) => dispatch(storeDataForReport(reportId, data)),
        setToastMessage: (message: string) => dispatch(setToastMessage(message)),
        setInfoMessage: (message: string) => dispatch(setInfoMessage(message)),
    };
}

type OwnProps = {
    isReadOnly: boolean,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
    isShowingAddForm: boolean,
    isShowingModifyForm: boolean,
    isShowingFilterForm: boolean,
    selectedSmartFilter: string,
    showLoader: boolean,
    loaderText: string
}

class ConnectedReportsTable extends Component<Props, OwnState> {

    state: OwnState = {
        isShowingAddForm: false,
        isShowingModifyForm: false,
        isShowingFilterForm: false,
        selectedSmartFilter: "",
        showLoader: false,
        loaderText: ''
    }

    static defaultProps = {
        isReadOnly: false,
    }

    addBlur() {
        document.querySelector('#page-header')?.classList.add('blur');
        document.querySelector('#import-export-button-holder')?.classList.add('blur');
    }

    removeBlur() {
        document.querySelector('#page-header')?.classList.remove('blur');
        document.querySelector('#import-export-button-holder')?.classList.remove('blur');
    }

    showAddForm = () => {
        this.addBlur();
        this.setState({
            isShowingAddForm: true,
        });
    }

    showFilterForm = () => {
        this.addBlur();
        this.setState({
            isShowingFilterForm: true,
        });
    }

    showModifyForm = () => {
        this.addBlur();
        this.setState({
            isShowingModifyForm: true,
        });
    }

    hideReportForm = () => {
        this.removeBlur();
        this.setState({
            isShowingAddForm: false,
            isShowingModifyForm: false,
        });
    }

    hideFilterForm = () => {
        this.removeBlur();
        this.setState({
            isShowingFilterForm: false,
        });
    }

    addReport = (reportData: IUpdateableReportData) => {
        this.removeBlur();
        this.props.addReport(reportData);
        this.setState({
            isShowingAddForm: false
        });
    }

    updateReport = (reportData: IUpdateableReportData) => {
        this.removeBlur();
        this.props.updateReport(reportData);
        this.setState({
            isShowingModifyForm: false
        });
    }

    deleteReport = (id: string) => {
        this.props.deleteReport(id);
        if (this.props.pageNumber !== 1 && this.props.reportsList.length === 0) {
            this.props.goToPage(this.props.pageNumber - 1);
        }
    }

    sendReportAsMail = async (id: string) => {
        this.props.setInfoMessage('Sending email');

        const report = this.props.reportsList.find(report => report.id === id);

        if (!report?.generatedTime) {
            return;
        }

        const serverUrl = `${BASE_URL}/send-report-email/?reportId=${id}&emails=${encodeURIComponent(report.emails || '')}`;

        const responseData = await axios.get<any>(serverUrl, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });

        this.props.setInfoMessage('');

        if (responseData.status === 200) {
            this.props.setToastMessage('The mail has been sent to the users successfully successfully');

            window.setTimeout(() => {
                this.props.setToastMessage('');
            }, 4000);
        } else {
            this.props.setToastMessage('There was a problem sending the mail. Please try again later');

            window.setTimeout(() => {
                this.props.setToastMessage('');
            }, 4000);
        }
    }

    handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
        this.props.searchTable(e.currentTarget.value);
    }

    handleSizeChange = (e: ChangeEvent<HTMLSelectElement>) => {
        this.props.changePageSize(Number(e.currentTarget.value));
    }

    handleSort = (column: string) => {
        const isNewColumn = this.props.reportSort.column !== column;
        const sortOrder = !isNewColumn && this.props.reportSort.order === 'ASC' ? 'DESC' : 'ASC';

        this.props.sort(column, sortOrder);
    }

    markReportForDataGeneration = (reportId: string) => {
        this.props.startReportDataGeneration();

        setTimeout(() => {
            this.generateReportData(reportId);
        }, 500);
    };


    generateReportData = (reportId: string) => {
        const report = this.props.reportsData.byId[reportId];
        const reportType = this.props.reportTypesData.byId[report.type];

        const applicationState = store.getState();

        const visibleUsers = getVisibleUserIds(applicationState, true),
            visibleMembers = getVisibleMemberIds(applicationState, true),
            visibleGroups = getVisibleGroupIds(applicationState, true);

        const visibleWorkflows = getVisibleWorkflowIds(applicationState, visibleUsers, visibleMembers, visibleGroups, true);

        const reportProcessState: DefaultFlowchartProcessState = {
            executionStack: [],
            forIterationCounts: {},
            customFields: {},
            variables: {
                [reportType.seedUserVariable]: report.user,
                [reportType.seedStartDateVariable]: report.startDate,
                [reportType.seedEndDateVariable]: report.endDate,
                [reportType.seedUsersVariable]: visibleUsers,
                [reportType.seedMembersVariable]: visibleMembers,
                [reportType.seedGroupsVariable]: visibleGroups,
                [reportType.seedWorkflowsVariable]: visibleWorkflows,
            },
            lastComputedPiece: undefined,
            displayingGroupPieceId: undefined,
            displayingQuestionPieceId: undefined,
            displayingShowPieceId: undefined,
            displayingTransferPieceId: undefined,
            displayingContinuePieceId: undefined,
            displayingAddWorkflowPieceId: undefined,
            createdWorkflowId: undefined,
            displayingFinsalLoanProcessPieceId: undefined,
        };

        const startPiece = reportType.startPiece ? reportType.startPiece.piece : undefined;

        let reportData: VariableValueType;

        try {
            reportData = getReportValue(this.props.applicationState, reportProcessState, reportId, startPiece);
        } catch (e) {
            if (e instanceof Error) {
                this.props.setToastMessage(e.message);
                console.error(e)
            }
        }

        if (!Array.isArray(reportData)) {
            throw new Error('The report data must be a table');
        }

        if (reportData.length > 0 && !Array.isArray(reportData[0])) {
            throw new Error('The report data must be a table');
        }

        reportData = reportData as Array<Array<string>>;

        this.props.storeDataForReport(reportId, reportData);
    }

    downloadReportFromServer = (reportId: string) => {
        const myToken = localStorage.getItem('token');
        window.open(BASE_URL + `/report-csv?reportId=${reportId}&token=${myToken}`, '_blank');

        this.setState({
            showLoader: true,
            loaderText: translatePhrase('Export ready'),
        });

        setTimeout(() => {
            this.setState({
                showLoader: false,
                loaderText: '',
            });
        }, 3000);

    }

    getSmartFilter = (smartFilter: string) => {
        this.setState({ selectedSmartFilter: smartFilter });
    }

    render() {

        const headings: Array<TableHeading> = [{
            name: 'Sl. no',
            isSortable: false,
            isSticky: true,
            width: 90,
        }, {
            name: 'Name',
            isSortable: false,
            width: 250,
        }, {
            name: 'Type',
            isSortable: false,
            width: 170,
        }, {
            name: 'Report Start Date',
            isSortable: false,
            width: 200,
        }, {
            name: 'Report End Date',
            isSortable: false,
            width: 200,
        }, {
            name: 'User',
            isSortable: false,
            width: 130,
        }, {
            name: 'Generated Time',
            isSortable: false,
            width: 200,
        }, {
            name: 'Action',
            isSortable: false,
            width: 200,
        }];

        const entries: Array<TableRow> = this.props.reportsList.map<TableRow>((report, index) => {
            const reportType = this.props.reportTypesData.byId[report.type];
            let userName: VariableValueType = '-';

            if (isUUID(report.user)) {
                const user = this.props.usersData.byId[report.user];
                userName = user.customFields[this.props.usersData.nameFieldId];

                const nameField = this.props.usersData.customFields.byId[this.props.usersData.nameFieldId];
                userName = getReadableDataForCustomField(userName, nameField, report.user, 'user');
            }

            const cells = [
                (this.props.pageNumber - 1) * this.props.pageSize + index + 1, // Sl. no
                report.name ? report.name : '-',
                translatePhrase(reportType ? reportType.name : '-'), // Type
                report.startDate ? moment(report.startDate).format('DD MMM YYYY') : '-', // Start Date
                report.endDate ? moment(report.endDate).format('DD MMM YYYY') : '-', // End Date
                userName, // User
                report.generatedTime ? moment(report.generatedTime).format('DD MMM YYYY, hh:mm:ss A') : '-', // Generated time
                // report.data ? <div className={styles.buttonHolder}><CSVLink data={report.data} filename={`${report.name}.csv`}><Button isRounded type={'secondary'} size={'small'} onClick={() => { }} title={translatePhrase('Download')} icon={<DownArrowIcon />} /></CSVLink></div> : <div className={styles.buttonHolder}><Button isRounded type={'secondary'} size={'small'} title={translatePhrase('Generate report')} onClick={this.markReportForDataGeneration.bind(this, report.id)} icon={<RocketIcon />} /></div>,
                report.data ? <div className={styles.buttonHolder}><Button isRounded type={'secondary'} size={'small'} onClick={() => this.downloadReportFromServer(report.id)} title={translatePhrase('Download')} icon={<DownArrowIcon />} /></div> : (this.props.createdIds.has(report.id) ? translatePhrase('Sync') : translatePhrase('Queued'))

            ];

            let showViewInsteadOfEdit = false;

            if (report.type in this.props.myPermissions.reports) {
                if (this.props.myPermissions.reports[report.type] === Permissions.READ) {
                    showViewInsteadOfEdit = true;
                }
            } else {
                if (this.props.myPermissions.general.Reports === Permissions.READ) {
                    showViewInsteadOfEdit = true;
                }
            }

            const reportEditForm = <ReportModify reportId={report.id} submit={this.updateReport} cancel={this.hideReportForm} isReadOnly={showViewInsteadOfEdit} />;

            return {
                id: report.id,
                entries: cells,
                editForm: reportEditForm,
                showViewInsteadOfEdit,
                isDeleteAllowed: reportType ? this.props.myPermissions.reports[reportType.id] !== Permissions.READ : false,
                isNotifyAllowed: !!report.generatedTime && !!report.emails,
            }
        });

        let reportTypesToAdd = false;

        const allowedMemberTypes = this.props.reportTypesData.allEntries
            .filter(typeId => {
                const reportType = this.props.reportTypesData.byId[typeId];
                const loggedInUser = this.props.myId ? this.props.usersData.byId[this.props.myId] : undefined;

                if (loggedInUser) {
                    return loggedInUser.projects.includes(reportType.project);
                } else {
                    return true;
                }
            })

        for (const reportTypeId of allowedMemberTypes) {

            if (this.props.myPermissions.reports[reportTypeId] === Permissions.WRITE || typeof this.props.myPermissions.reports[reportTypeId] === 'undefined') {
                reportTypesToAdd = true;
            }
        }

        return (
            <div>
                {this.state.showLoader && <LoaderModal loaderText={[this.state.loaderText]} isSuccess={true} />}
                <TableWithMeta
                    entityType="Report"
                    headings={headings}
                    entries={entries}

                    sortedColumn={this.props.reportSort.column}
                    sortType={this.props.reportSort.order}
                    isReadOnly={this.props.isReadOnly}
                    areFiltersExpanded={this.props.areFiltersExpanded}

                    placeHolderForSearch="Search within the list below"

                    totalPages={this.props.totalPages}
                    totalNoOfEntries={this.props.totalNoOfReports}
                    pageNumber={this.props.pageNumber}
                    searchTerm={this.props.searchTerm}
                    isAddAllowed={reportTypesToAdd}
                    isShowingAddForm={this.state.isShowingAddForm}
                    isShowingModifyForm={this.state.isShowingModifyForm}
                    addForm={<ReportModify submit={this.addReport} cancel={this.hideReportForm} />}

                    isShowingFilterForm={this.state.isShowingFilterForm}
                    showFilterForm={this.showFilterForm}
                    filterForm={
                        <ReportFilter 
                            tags={this.props.tags} 
                            closeFilter={this.hideFilterForm} 
                            reportFilters={this.props.reportFilters} 
                            getSmartFilter={this.getSmartFilter} />}
                    tags={this.props.tags}
                    notifyActionName={translatePhrase('Email report')}

                    onSort={this.handleSort}
                    onDelete={this.deleteReport}
                    onNotify={this.sendReportAsMail}
                    showAddForm={this.showAddForm}
                    showModifyForm={this.showModifyForm}

                    search={this.props.searchTable}
                    changePageSize={this.props.changePageSize}
                    goToPage={this.props.goToPage}
                    sort={this.props.sort}
                    selectedSmartFilter={this.state.selectedSmartFilter}
                />
            </div>
        );
    }
}

const ReportsTable = connect(mapStateToProps, mapDispatchToProps)(ConnectedReportsTable);

export default ReportsTable;