export const SET_CURRENT_ROUTE = 'SET_CURRENT_ROUTE';
export const SET_COMPONENTS_WIDGET_SEARCH_TERM = 'SET_COMPONENTS_WIDGET_SEARCH_TERM';

export enum RouteParam {
    DASHBOARD = '/dashboard',

    STRUCTURE_HIERARCHY = '/structure/hierarchy',
    STRUCTURE_LOCATIONS = '/structure/locations',
    STRUCTURE_PERMISSIONS = '/structure/permissions',

    USERS_LIST = '/users/list',
    USERS_CONFIGURATION = '/users/configuration',

    MEMBERS_LIST = '/members/list',
    MEMBERS_CONFIGURATION = '/members/configuration',

    GROUPS_LIST = '/groups/list',
    GROUPS_CONFIGURATION = '/groups/configuration',

    WORKFLOWS_LIST = '/workflows/list',
    WORKFLOWS_CONFIGURATION = '/workflows/configuration',

    REPORTS_LIST = '/reports/list',
    REPORTS_CONFIGURATION = '/reports/configuration',

    DATA = '/static-data/configuration',
    LANGUAGES = '/languages'
}

export enum RouteComponent {
    DASHBOARD = 'dashboard',
    STRUCTURE = 'structure',
    USERS = 'users',
    MEMBERS = 'members',
    GROUPS = 'groups',
    WORKFLOWS = 'workflows',
    REPORTS = 'reports',
    DATA = 'static-data',
    LANGUAGES = 'languages'
}

export interface WidgetSearchTerm {
    [widgetName: string]: string
}

export enum ComponentWidgets {
    STRUCTURE_HIERARCHY_PROJECTS = 'STRUCTURE_HIERARCHY_PROJECTS',
    STRUCTURE_LOCATION_PROJECTS = 'STRUCTURE_LOCATION_PROJECTS',

    USERS_CONFIGURATION_ROLES = 'USERS_CONFIGURATION_ROLES',
    USERS_LIST = 'USERS_LIST',

    MEMBERS_LIST = 'MEMBERS_LIST',
    MEMBERS_CONFIGURATION_MEMBER_TYPES = 'MEMBERS_CONFIGURATION_MEMBER_TYPES',

    GROUPS_LIST = 'GROUPS_LIST',
    GROUPS_CONFIGURATION_GROUP_TYPES = 'GROUPS_CONFIGURATION_GROUP_TYPES',

    WORKFLOWS_LIST = 'WORKFLOWS_LIST',
    WORKFLOWS_CONFIGURATION_WORKFLOW_TYPES = 'WORKFLOWS_CONFIGURATION_WORKFLOW_TYPES',

    REPORTS_LIST = 'REPORTS_LIST',
    REPORTS_CONFIGURATION_REPORT_TYPES = 'REPORTS_CONFIGURATION_REPORT_TYPES',

    DATA_CONFIGURATION_STATIC_DATA = 'DATA_CONFIGURATION_STATIC_DATA',

}

export interface ComponentState {
    previousRoute: RouteParam,
    searchTerm: Array<WidgetSearchTerm>
}

export interface SavedStates {
    previousRoute: {
        dashboard: string,
        structure: string,
        users: string,
        groups: string,
        members: string,
        workflows: string,
        reports: string,
        data: string,
        languages: string,
    },
    searchTerms: Array<WidgetSearchTerm>
}

export interface SetCurrentRoute {
    type: typeof SET_CURRENT_ROUTE,
    routeComponent: RouteComponent
    routeParam: RouteParam
};

export interface SetComponentsWidgetSearchTerm {
    type: typeof SET_COMPONENTS_WIDGET_SEARCH_TERM,
    searchTerm: string,
    widgetName: ComponentWidgets,
}



export type SavedStatesActionTypes = SetCurrentRoute | SetComponentsWidgetSearchTerm;