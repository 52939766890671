import React, { ChangeEvent, Component } from 'react';
import styles from './GetValuePiece.module.scss';
import Input from '../Input';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import FlowchartPiece, { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setMemberVariable, setCustomField, setVariableForCustomField, setEntityType } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import { valuePieceSlotTarget } from './utilities';
import { FlowchartContext, FlowchartInfoForPiece, PieceHighlightColour } from '../../../contexts/flowchart-context';


type GetPieceProps = {
    selectedType?: string,
    selectedEntityType?: string,

    variablePiece?: JSX.Element,
    memberVariable?: JSX.Element,
    gettingCustomField?: string,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,

        projectsData: state.structure.projects,
        levelsData: state.structure.levels,
        rolesData: state.structure.roles,
        userData: state.users,
        memberTypes: state.members.types,
        groupTypes: state.groups.types,
        workflowTypes: state.workflows.types,
        pieceData: state.flowchart.pieces
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string | undefined) => dispatch(setTargetPiece(pieceId)),
        setMemberVariable: (targetPieceId: string, draggedPieceId: string) => dispatch(setMemberVariable(targetPieceId, draggedPieceId)),
        setCustomField: (pieceId: string, value: string) => dispatch(setCustomField(pieceId, value)),
        setVariableForCustomField: (pieceId: string, value: string) => dispatch(setVariableForCustomField(pieceId, value)),
        setEntityType: (pieceId: string, value: string) => dispatch(setEntityType(pieceId, value)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = GetPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type GetPieceState = {
    isHoveringOverVariablePiece: boolean,
    isHoveringOverMemberPiece: boolean,
    entityTypeSearchText: string,
    fieldSearchText: string,
}

class ConnectedGetPiece extends Component<Props, GetPieceState> {

    state = {
        isHoveringOverVariablePiece: false,
        isHoveringOverMemberPiece: false,
        entityTypeSearchText: '',
        fieldSearchText: '',
    };

    searchForEntityType = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            entityTypeSearchText: e.target.value,
        });
    }

    searchForCustomField = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            fieldSearchText: e.target.value,
        });
    }

    handleHoverOverMemberPiece = () => {
        this.setState({
            isHoveringOverMemberPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfMemberPiece = () => {
        this.setState({
            isHoveringOverMemberPiece: false,
        });
    };

    handleHoverOverVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: false,
        });
    };

    updateValueWhenPieceDropped = (prevProps: Props) => {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && (this.state.isHoveringOverVariablePiece || this.state.isHoveringOverMemberPiece)) {

            if (this.state.isHoveringOverVariablePiece) {
                this.props.setVariableForCustomField(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverMemberPiece) {
                this.props.setMemberVariable(this.props.pieceId, this.props.lastDraggedPiece.id);
            }

            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverVariablePiece: false,
                isHoveringOverMemberPiece: false,
            });
        }
    }

    updateValueWhenTypeChanged(prevProps: Props) {
        if (!this.props.selectedType) {
            return;
        }

        if (this.props.selectedType === prevProps.selectedType) {
            return;
        }

        const context: FlowchartInfoForPiece = this.context;

        switch (this.props.selectedType) {
            case 'Location':
                break;
            case 'User':
                this.props.setCustomField(this.props.pieceId, this.props.userData.nameFieldId);
                break;
            case 'Member':
                const allowedMemberTypeIds = context.projects.length > 0 ? this.props.memberTypes.allEntries.filter(memberTypeId => {
                    const memberType = this.props.memberTypes.byId[memberTypeId];
                    return context.projects.includes(memberType.project);
                }) : this.props.memberTypes.allEntries.slice();

                if (allowedMemberTypeIds.length > 0) {
                    const memberTypeId = allowedMemberTypeIds[0];
                    const memberType = this.props.memberTypes.byId[memberTypeId];
                    this.props.setEntityType(this.props.pieceId, allowedMemberTypeIds[0]);
                    this.props.setCustomField(this.props.pieceId, memberType.nameFieldId);
                }
                break;
            case 'Group':
                const allowedGroupTypeIds = context.projects.length > 0 ? this.props.groupTypes.allEntries.filter(groupTypeId => {
                    const groupType = this.props.groupTypes.byId[groupTypeId];
                    return context.projects.includes(groupType.project);
                }) : this.props.groupTypes.allEntries.slice();

                if (allowedGroupTypeIds.length > 0) {
                    const groupTypeId = allowedGroupTypeIds[0];
                    const groupType = this.props.groupTypes.byId[groupTypeId];
                    this.props.setEntityType(this.props.pieceId, allowedGroupTypeIds[0]);
                    this.props.setCustomField(this.props.pieceId, groupType.nameFieldId);
                }
                break;
            case 'Workflow':
                break;
        }
    }

    componentDidUpdate(prevProps: Props) {
        this.updateValueWhenPieceDropped(prevProps);
        this.updateValueWhenTypeChanged(prevProps);
    }

    updateValidity = (isValid: boolean, flowchartContext: FlowchartInfoForPiece) => {
        if (!isValid) {
            const invalidPieceInfo = flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId);

            if (!invalidPieceInfo && flowchartContext.setInvalidPiece) {
                flowchartContext.setInvalidPiece(this.props.pieceId, flowchartContext.parentSplitPieceIds);
            }
        }

        if (isValid && flowchartContext.removeInvalidPiece && flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId)) {
            flowchartContext.removeInvalidPiece(this.props.pieceId);
        }
    }

    render() {

        const piece = this.props.pieceData.byId[this.props.pieceId]
        return <FlowchartContext.Consumer>
            {
                (flowchartContext) => {
                    const entityTypeSelectCallback = this.props.setEntityType.bind(this, this.props.pieceId);

                    let entityTypeSelectBox: JSX.Element | undefined;
                    let customFieldsSelectBox: JSX.Element | undefined;
                    let entityType: string | undefined;

                    let isValid = true;

                    const highlightColor = flowchartContext.highlights && flowchartContext.highlights[this.props.pieceId];
                    let highlightClass = styles.noHighlight;

                    switch (highlightColor) {
                        case PieceHighlightColour.GREEN:
                            highlightClass = styles.addedHighlight;
                            break;
                        case PieceHighlightColour.YELLOW:
                            highlightClass = styles.updatedHighlight;
                            break;
                        case PieceHighlightColour.PURPLE:
                            highlightClass = styles.movedHighlight;
                            break;
                        case PieceHighlightColour.RED:
                            highlightClass = styles.deletedHighlight;
                            break;
                    }

                    const entityTypeSearchElement = <div className={styles.nameContainer + ' ignore-options-onclickoutside'}>
                        <input className={styles.nameInput} onChange={this.searchForEntityType} value={this.state.entityTypeSearchText} type="text" placeholder="Search entity type" />
                    </div>;

                    const fieldSearchElement = <div className={styles.nameContainer + ' ignore-options-onclickoutside'}>
                        <input className={styles.nameInput} onChange={this.searchForCustomField} value={this.state.fieldSearchText} type="text" placeholder="Search custom field" />
                    </div>;

                    switch (this.props.selectedType) {

                        case 'Location':
                            entityType = this.props.selectedEntityType;

                            let allowedLevelIds = this.props.levelsData.allEntries;

                            if (flowchartContext.projects.length > 0) {
                                allowedLevelIds = allowedLevelIds.filter(levelId => {
                                    const level = this.props.levelsData.byId[levelId];
                                    return flowchartContext.projects.includes(level.project);
                                });
                            }

                            if (entityType) {
                                isValid = allowedLevelIds.includes(entityType);
                            }

                            entityTypeSelectBox = <SelectBox theme="pink" selectionPromptText={entityType && this.props.levelsData.byId.hasOwnProperty(entityType) ? this.props.levelsData.byId[entityType].name : undefined}>
                                <DropDownList theme="pink" dismissAfterSelection={true}>
                                    {entityTypeSearchElement}
                                    {allowedLevelIds
                                        .filter(levelId => {
                                            const level = this.props.levelsData.byId[levelId];
                                            return level.name.toLocaleLowerCase().includes(this.state.entityTypeSearchText.toLocaleLowerCase())
                                        })
                                        .map(levelId => {
                                            const levelData = this.props.levelsData.byId[levelId];
                                            const project = this.props.projectsData.byId[levelData.project];

                                            return <ListItem theme="pink" name={levelData.name} detail={project.name} key={levelId} value={levelId} onClick={entityTypeSelectCallback} />
                                        })}
                                </DropDownList>
                            </SelectBox>;


                            if (entityType && this.props.levelsData.byId.hasOwnProperty(entityType)) {

                                const level = this.props.levelsData.byId[entityType];

                                if (isValid && this.props.gettingCustomField) {
                                    isValid = level.customFields.includes(this.props.gettingCustomField);
                                }

                                customFieldsSelectBox = <SelectBox theme="pink" selectionPromptText={this.props.gettingCustomField && this.props.levelsData.customFields.byId[this.props.gettingCustomField] ? this.props.levelsData.customFields.byId[this.props.gettingCustomField].name : 'Select fields'}>
                                    <DropDownList theme="pink" dismissAfterSelection={true}>
                                        {fieldSearchElement}
                                        {level.customFields
                                            .filter(customFieldId => {
                                                const customField = this.props.levelsData.customFields.byId[customFieldId];
                                                return customField.name.toLocaleLowerCase().includes(this.state.fieldSearchText.toLocaleLowerCase())
                                            })
                                            .map(levelFieldId => <ListItem theme="pink" name={this.props.levelsData.customFields.byId[levelFieldId].name} key={levelFieldId} value={levelFieldId} onClick={this.props.setCustomField.bind(this, this.props.pieceId)} />)}
                                    </DropDownList>
                                </SelectBox>;
                            }

                            break;

                        case 'User':
                            entityType = this.props.selectedEntityType;

                            let allowedRoleIds = this.props.rolesData.allEntries;

                            if (flowchartContext.projects.length > 0) {
                                allowedRoleIds = allowedRoleIds.filter(roleId => {
                                    const role = this.props.rolesData.byId[roleId];
                                    const level = this.props.levelsData.byId[role.level];

                                    return flowchartContext.projects.includes(level.project);
                                });
                            }

                            entityTypeSelectBox = <SelectBox theme="pink" selectionPromptText={entityType && this.props.rolesData.byId.hasOwnProperty(entityType) ? this.props.rolesData.byId[entityType].name : 'Any role'}>
                                <DropDownList theme="pink" dismissAfterSelection={true}>
                                    {entityTypeSearchElement}
                                    {'any role'.toLocaleLowerCase().includes(this.state.entityTypeSearchText.toLocaleLowerCase()) && <ListItem theme="pink" name="Any role" key="0" value="" onClick={entityTypeSelectCallback} />}
                                    {allowedRoleIds
                                        .filter(roleId => {
                                            const role = this.props.rolesData.byId[roleId];
                                            return role.name.toLocaleLowerCase().includes(this.state.entityTypeSearchText.toLocaleLowerCase())
                                        })
                                        .map(roleId => {
                                            const role = this.props.rolesData.byId[roleId];
                                            const level = this.props.levelsData.byId[role.level];
                                            const project = this.props.projectsData.byId[level.project];

                                            return <ListItem theme="pink" name={role.name} detail={project.name} key={roleId} value={roleId} onClick={entityTypeSelectCallback} />
                                        })}
                                </DropDownList>
                            </SelectBox>;

                            if (entityType) {
                                isValid = allowedRoleIds.includes(entityType);
                            }

                            const customFieldName = this.props.gettingCustomField ? this.props.userData.customFields.byId.hasOwnProperty(this.props.gettingCustomField) ? this.props.userData.customFields.byId[this.props.gettingCustomField].name : this.props.rolesData.customFields.byId.hasOwnProperty(this.props.gettingCustomField) ? this.props.rolesData.customFields.byId[this.props.gettingCustomField].name : 'Select Fields' : 'Select Fields';

                            customFieldsSelectBox = <SelectBox theme="pink" selectionPromptText={customFieldName}>
                                <DropDownList theme="pink" dismissAfterSelection={true}>
                                    {fieldSearchElement}
                                    {this.props.userData.customFields.allFields
                                        .filter(customFieldId => {
                                            const customField = this.props.userData.customFields.byId[customFieldId];
                                            return customField.name.toLocaleLowerCase().includes(this.state.fieldSearchText.toLocaleLowerCase())
                                        })
                                        .map(userFieldId => <ListItem theme="pink" name={this.props.userData.customFields.byId[userFieldId].name} key={userFieldId} value={userFieldId} onClick={this.props.setCustomField.bind(this, this.props.pieceId)} />)}
                                    {entityType && this.props.rolesData.byId.hasOwnProperty(entityType) && this.props.rolesData.byId[entityType].customFields
                                        .filter(customFieldId => {
                                            const customField = this.props.rolesData.customFields.byId[customFieldId];
                                            return customField.name.toLocaleLowerCase().includes(this.state.fieldSearchText.toLocaleLowerCase())
                                        })
                                        .map(roleFieldId => <ListItem theme="pink" name={this.props.rolesData.customFields.byId[roleFieldId].name} key={roleFieldId} value={roleFieldId} onClick={this.props.setCustomField.bind(this, this.props.pieceId)} />)}
                                </DropDownList>
                            </SelectBox>;

                            break;

                        case 'Member':
                            entityType = this.props.selectedEntityType;

                            let allowedMemberTypeIds = this.props.memberTypes.allEntries;

                            if (flowchartContext.projects.length > 0) {
                                allowedMemberTypeIds = allowedMemberTypeIds.filter(memberTypeId => {
                                    const memberType = this.props.memberTypes.byId[memberTypeId];
                                    return flowchartContext.projects.includes(memberType.project);
                                });
                            }

                            if (entityType) {
                                isValid = allowedMemberTypeIds.includes(entityType);
                            }

                            entityTypeSelectBox = <SelectBox theme="pink" selectionPromptText={entityType && this.props.memberTypes.byId.hasOwnProperty(entityType) ? this.props.memberTypes.byId[entityType].name : undefined}>
                                {entityTypeSearchElement}
                                <DropDownList theme="pink" dismissAfterSelection={true}>
                                    {allowedMemberTypeIds
                                        .filter(memberTypeId => {
                                            const memberType = this.props.memberTypes.byId[memberTypeId];
                                            return memberType.name.toLocaleLowerCase().includes(this.state.entityTypeSearchText.toLocaleLowerCase())
                                        })
                                        .map(memberTypeId => {
                                            const memberType = this.props.memberTypes.byId[memberTypeId];
                                            const project = this.props.projectsData.byId[memberType.project];

                                            return <ListItem theme="pink" name={memberType.name} detail={project.name} key={memberTypeId} value={memberTypeId} onClick={entityTypeSelectCallback} />
                                        })}
                                </DropDownList>
                            </SelectBox>;


                            if (entityType && this.props.memberTypes.byId.hasOwnProperty(entityType)) {

                                const memberType = this.props.memberTypes.byId[entityType];

                                if (isValid && this.props.gettingCustomField) {
                                    isValid = memberType.customFields.includes(this.props.gettingCustomField);
                                }

                                customFieldsSelectBox = <SelectBox theme="pink" selectionPromptText={this.props.gettingCustomField && this.props.memberTypes.customFields.byId.hasOwnProperty(this.props.gettingCustomField) ? this.props.memberTypes.customFields.byId[this.props.gettingCustomField].name : 'Select fields'}>
                                    <DropDownList theme="pink" dismissAfterSelection={true}>
                                        {fieldSearchElement}
                                        {memberType.customFields
                                            .filter(customFieldId => {
                                                const customField = this.props.memberTypes.customFields.byId[customFieldId];
                                                return customField.name.toLocaleLowerCase().includes(this.state.fieldSearchText.toLocaleLowerCase())
                                            })
                                            .map(memberFieldId => <ListItem theme="pink" name={this.props.memberTypes.customFields.byId[memberFieldId].name} key={memberFieldId} value={memberFieldId} onClick={this.props.setCustomField.bind(this, this.props.pieceId)} />)}
                                    </DropDownList>
                                </SelectBox>;
                            }

                            break;

                        case 'Group':
                            entityType = this.props.selectedEntityType;

                            let allowedGroupTypeIds = this.props.groupTypes.allEntries;

                            if (flowchartContext.projects.length > 0) {
                                allowedGroupTypeIds = allowedGroupTypeIds.filter(groupTypeId => {
                                    const groupType = this.props.groupTypes.byId[groupTypeId];
                                    return flowchartContext.projects.includes(groupType.project);
                                });
                            }

                            if (entityType) {
                                isValid = allowedGroupTypeIds.includes(entityType);
                            }

                            entityTypeSelectBox = <SelectBox theme="pink" selectionPromptText={entityType && this.props.groupTypes.byId.hasOwnProperty(entityType) ? this.props.groupTypes.byId[entityType].name : undefined}>
                                <DropDownList theme="pink" dismissAfterSelection={true}>
                                    {entityTypeSearchElement}
                                    {allowedGroupTypeIds
                                        .filter(groupTypeId => {
                                            const groupType = this.props.groupTypes.byId[groupTypeId];
                                            return groupType.name.toLocaleLowerCase().includes(this.state.entityTypeSearchText.toLocaleLowerCase())
                                        })
                                        .map(groupTypeId => {
                                            const groupType = this.props.groupTypes.byId[groupTypeId];
                                            const project = this.props.projectsData.byId[groupType.project];

                                            return <ListItem theme="pink" name={groupType.name} detail={project.name} key={groupTypeId} value={groupTypeId} onClick={entityTypeSelectCallback} />
                                        })}
                                </DropDownList>
                            </SelectBox>;


                            if (entityType && this.props.groupTypes.byId.hasOwnProperty(entityType)) {

                                const groupType = this.props.groupTypes.byId[entityType];

                                if (isValid && this.props.gettingCustomField) {
                                    isValid = groupType.customFields.includes(this.props.gettingCustomField);
                                }

                                customFieldsSelectBox = <SelectBox theme="pink" selectionPromptText={this.props.gettingCustomField && this.props.groupTypes.customFields.byId.hasOwnProperty(this.props.gettingCustomField) ? this.props.groupTypes.customFields.byId[this.props.gettingCustomField].name : 'Select fields'}>
                                    <DropDownList theme="pink" dismissAfterSelection={true}>
                                        {fieldSearchElement}
                                        {groupType.customFields
                                            .filter(customFieldId => {
                                                const customField = this.props.groupTypes.customFields.byId[customFieldId];
                                                return customField.name.toLocaleLowerCase().includes(this.state.fieldSearchText.toLocaleLowerCase())
                                            })
                                            .map(groupFieldId => <ListItem theme="pink" name={this.props.groupTypes.customFields.byId[groupFieldId].name} key={groupFieldId} value={groupFieldId} onClick={this.props.setCustomField.bind(this, this.props.pieceId)} />)}
                                    </DropDownList>
                                </SelectBox>;
                            }
                            break;

                        case 'Workflow':
                            entityType = this.props.selectedEntityType;

                            let allowedWorkflowTypeIds = this.props.workflowTypes.allEntries;

                            if (flowchartContext.projects.length > 0) {
                                allowedWorkflowTypeIds = allowedWorkflowTypeIds.filter(workflowTypeId => {
                                    const workflowType = this.props.workflowTypes.byId[workflowTypeId];
                                    return flowchartContext.projects.includes(workflowType.project);
                                });
                            }

                            if (entityType) {
                                isValid = allowedWorkflowTypeIds.includes(entityType);
                            }

                            entityTypeSelectBox = <SelectBox theme="pink" selectionPromptText={entityType && this.props.workflowTypes.byId.hasOwnProperty(entityType) ? this.props.workflowTypes.byId[entityType].name : undefined}>
                                <DropDownList theme="pink" dismissAfterSelection={true}>
                                    {entityTypeSearchElement}
                                    {allowedWorkflowTypeIds
                                        .filter(workflowTypeId => {
                                            const workflowType = this.props.workflowTypes.byId[workflowTypeId];
                                            return workflowType.name.toLocaleLowerCase().includes(this.state.entityTypeSearchText.toLocaleLowerCase())
                                        })
                                        .map(workflowTypeId => {
                                            const workflowType = this.props.workflowTypes.byId[workflowTypeId];
                                            const project = this.props.projectsData.byId[workflowType.project];

                                            return <ListItem theme="pink" name={workflowType.name} detail={project.name} key={workflowTypeId} value={workflowTypeId} onClick={entityTypeSelectCallback} />
                                        })}
                                </DropDownList>
                            </SelectBox>;


                            if (entityType && this.props.workflowTypes.byId.hasOwnProperty(entityType)) {

                                const workflowType = this.props.workflowTypes.byId[entityType];

                                if (isValid && this.props.gettingCustomField) {
                                    isValid = workflowType.customFields.includes(this.props.gettingCustomField);
                                }

                                customFieldsSelectBox = <SelectBox theme="pink" selectionPromptText={this.props.gettingCustomField && this.props.workflowTypes.customFields.byId.hasOwnProperty(this.props.gettingCustomField) ? this.props.workflowTypes.customFields.byId[this.props.gettingCustomField].name : 'Select fields'}>
                                    <DropDownList theme="pink" dismissAfterSelection={true}>
                                        {fieldSearchElement}
                                        {workflowType.customFields
                                            .filter(customFieldId => {
                                                const customField = this.props.workflowTypes.customFields.byId[customFieldId];
                                                return customField.name.toLocaleLowerCase().includes(this.state.fieldSearchText.toLocaleLowerCase())
                                            })
                                            .map(workflowFieldId => <ListItem theme="pink" name={this.props.workflowTypes.customFields.byId[workflowFieldId].name} key={workflowFieldId} value={workflowFieldId} onClick={this.props.setCustomField.bind(this, this.props.pieceId)} />)}
                                    </DropDownList>
                                </SelectBox>;
                            }
                            break;

                        default:
                            entityTypeSelectBox = undefined;
                    }

                    if (flowchartContext.highlightIncompletePieces) {
                        let isIncomplete = !this.props.selectedType || !this.props.selectedEntityType || !this.props.gettingCustomField;

                        if (this.props.selectedType === 'User' && this.props.gettingCustomField &&
                            this.props.userData.customFields.allFields.includes(this.props.gettingCustomField)
                        ) {
                            isIncomplete = false;
                        }

                        isValid = isValid && !isIncomplete;
                    }

                    this.updateValidity(isValid, flowchartContext);

                    const isForSingleMember = this.props.selectedType === 'Workflow' && this.props.selectedEntityType && this.props.selectedEntityType in this.props.workflowTypes.byId && this.props.gettingCustomField && this.props.workflowTypes.byId[this.props.selectedEntityType].affiliation === 'group' && this.props.workflowTypes.customFields.byId[this.props.gettingCustomField].affiliation === 'member';

                    return (<FlowchartPiece {...this.props}>
                        <div className={isValid ? styles.getPieceWrapper : styles.invalidGetPieceWrapper + ' ' + highlightClass}>
                            <div className={styles.text}>get from </div>
                            {this.props.variablePiece ? this.props.variablePiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverVariablePiece && !!this.props.targetPiece} isDisabled onMouseOver={this.handleHoverOverVariablePiece} onMouseOut={this.handleHoverOutOfVariablePiece} placeholderText="Entity variable (e.g. the 'Workflow' variable)" minSize={44} />}
                            {this.props.selectedType && <div className={styles.text}>which is a {this.props.selectedType} of type </div>}
                            {this.props.selectedType && entityTypeSelectBox}
                            {customFieldsSelectBox && <div className={styles.text}> this custom field: </div>}
                            {customFieldsSelectBox && customFieldsSelectBox}
                            {this.props.gettingCustomField && isForSingleMember && <div className={styles.text}>for</div>}
                            {this.props.gettingCustomField && isForSingleMember && (this.props.memberVariable ? this.props.memberVariable : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverMemberPiece && !!this.props.targetPiece} isDisabled onMouseOver={this.handleHoverOverMemberPiece} onMouseOut={this.handleHoverOutOfMemberPiece} placeholderText="Member variable" minSize={15} />)}
                        </div>
                    </FlowchartPiece>)
                }
            }
        </FlowchartContext.Consumer>

    }
}

ConnectedGetPiece.contextType = FlowchartContext;

const GetPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedGetPiece);

export default GetPiece;