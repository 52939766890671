import React, { useState } from 'react';
import styles from './UserCSV.module.scss';
import { translatePhrase } from '../../../shared/helpers/translation';

import { ReactComponent as ExportCSVIcon } from "../../../assets/new-custom-icons/common/export.svg";
import { ReactComponent as CancelIcon } from '../../../common/assets/close.svg';
import { ReactComponent as ExportIcon } from '../../../common/assets/export.svg';

import moment from 'moment';

import Button from '../../../widgets/button/CommonButton';
import DateInput from '../../../widgets/form/DateInput';
import { BASE_URL } from '../../../shared/store/url';
import axios from 'axios';

import Papa from "papaparse";
import saveAs from "file-saver";

type OwnProps = {
    closeCSV: () => void,
    userIds: string[]
};

const UserSyncLogExportCSV: React.FC<OwnProps> = (props: OwnProps) => {

    const [startDate, setStartDate] = useState<Date>(moment(new Date()).subtract(1, 'month').toDate())
    const [endDate, setEndDate] = useState<Date>(moment(new Date()).toDate())

    const handleDateRangeChange = (type: 'start' | 'end', date: string) => {
        if (type === 'start') {
            setStartDate(moment(date).toDate())
        } else {
            setEndDate(moment(date).toDate())
        }
    };

    const exportSyncLogData = async () => {
        try {
            const detailsUrl = BASE_URL + '/export-sync-meta-log/';
            const formattedStartDate = moment(startDate).format('YYYY-MM-DD');
            const formattedEndDate = moment(endDate).format('YYYY-MM-DD');

            const { userIds } = props;

            const response = await axios.post(detailsUrl, { startDate: formattedStartDate, endDate: formattedEndDate, userIds }, {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                }
            });

            const fileData = Papa.unparse(response.data);
            const fileBlob = new Blob([fileData], { type: 'application/csv' });

            saveAs(fileBlob, `SyncMetaLog Export-${formattedStartDate}/${formattedEndDate}.csv`);

        } catch (error) {
            console.error(error)
        }
    };

    return <div>
        <section className={styles.commonModalHolder + ' ' + styles.syncModal + ' ' + styles.csvModal}>
            <div className={styles.filterCloseButton} onClick={props.closeCSV}>
                <Button title={translatePhrase("Close")} icon={<CancelIcon />} size={'small'} isRounded />
            </div>

            <section className={styles.addOrModifyListCard}>
                <header>
                    <h2> <ExportIcon /> {translatePhrase('Export Sync Meta Log')} </h2>
                </header>

                <div className={styles.container}>
                    <div className={styles.allInputsHolder}>
                        <div className={styles.inputSegment}>
                            <DateInput onChange={(date) => handleDateRangeChange('start', date)} placeholder={'Start Date'} default={startDate} />
                        </div>
                        <div className={styles.inputSegment}>
                            <DateInput onChange={(date) => handleDateRangeChange('end', date)} placeholder={'End Date'} default={endDate} />
                        </div>
                    </div>
                </div>

                <div className={styles.buttonsHolder}>
                    <Button icon={<ExportCSVIcon />} isRounded padding={'0px 10px'} text={translatePhrase(`Export Data`)} isBlock onClick={exportSyncLogData} />
                </div>
            </section>
        </section>
    </div>
};

export default UserSyncLogExportCSV;