import React, { Component, MouseEvent } from 'react';
import styles from './SelectBox.module.scss';
import onClickOutside from "react-onclickoutside";

import { ReactComponent as ChevronDownIcon } from '../../../assets/chevron-arrow-down.svg';

import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/types';


type SelectBoxDraggablePieceProps = {
    theme?: 'indigo'|'dark-indigo'|'aqua'|'dark-aqua'|'pink'|'dark-pink'|'camo'|'dark-camo'|'orange'|'dark-orange'|'red'|'dark-red',
    selectionPromptText?: string,
    isRounded?: boolean,
    isBlock?: boolean,

    hasVariableSlot: boolean,
    variablePiece?: JSX.Element,

    toggleOptionsVisibility: () => void,
    hideOptionsVisibility: () => void,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
    }
}

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = SelectBoxDraggablePieceProps & StateProps;

class ConnectedSelectBoxDraggablePiece extends Component<Props> {

    static defaultProps = {
        isRounded: false,
    };
    
    handleClickOutside = (event: MouseEvent) => {
        window.setTimeout(this.props.hideOptionsVisibility, 500);
    }

    render() {
        let themeClass;

        switch(this.props.theme) {
            case 'indigo':
                themeClass = styles.indigoThemedSelectBox;
                break;
            case 'dark-indigo':
                themeClass = styles.darkIndigoThemedSelectBox;
                break;
            case 'aqua':
                themeClass = styles.aquaThemedSelectBox;
                break;
            case 'dark-aqua':
                themeClass = styles.darkAquaThemedSelectBox;
                break;
            case 'pink':
                themeClass = styles.pinkThemedSelectBox;
                break;
            case 'dark-pink':
                themeClass = styles.darkPinkThemedSelectBox;
                break;
            case 'camo':
                themeClass = styles.camoThemedSelectBox;
                break;
            case 'dark-camo':
                themeClass = styles.darkCamoThemedSelectBox;
                break;
            case 'orange':
                themeClass = styles.orangeThemedSelectBox;
                break;
            case 'dark-orange':
                themeClass = styles.darkOrangeThemedSelectBox;
                break;
            case 'red':
                themeClass = styles.redThemedSelectBox;
                break;
            case 'dark-red':
                themeClass = styles.darkRedThemedSelectBox;
                break;
            default:
                themeClass = styles.indigoThemedSelectBox;
                break;
        }

        themeClass = this.props.isRounded ? themeClass + ' ' + styles.roundedBox : themeClass;
        themeClass = this.props.isBlock ? themeClass + ' ' + styles.blockBox : themeClass;

        return (
            <section className={themeClass}>
                <div className={styles.text} onClick={this.props.toggleOptionsVisibility}>{ this.props.selectionPromptText || 'Select an option' }</div>
                {this.props.hasVariableSlot && <div className={styles.variablePiece}>{this.props.variablePiece}</div>}
                <ChevronDownIcon />
            </section>
        )
    }
}

const EnhancedSelectBoxDraggablePiece = onClickOutside(ConnectedSelectBoxDraggablePiece, {
    excludeScrollbar: true
});

class EnhancedSelectBoxDraggablePieceWithIgnore extends Component<Props> {
    render() {
        return <EnhancedSelectBoxDraggablePiece outsideClickIgnoreClass={'ignore-options-onclickoutside'} {...this.props} />;
    }
}

const SelectBoxDraggablePiece = connect(mapStateToProps)(EnhancedSelectBoxDraggablePieceWithIgnore);

export default SelectBoxDraggablePiece;