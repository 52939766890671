import { useEffect } from 'react';
import { Redirect, Route, Switch } from "react-router";
import styles from './Market.module.scss';
import logo from '../../common/assets/logo.svg';
import { AddPackage } from "./add-package/AddPackage";
import { AllPackages } from "./all-packages/AllPackages";
import { PackageDetails } from "./package-details/PackageDetails";
import { getCurrentSnapshot } from "../../shared/store/database";
import { ApplicationState } from "../../shared/store/types";
import { useDispatch, useSelector } from "react-redux";
import { fetchLocalData } from "../../shared/store/actions";
import { updatePermissions } from "../../shared/store/my-data/actions";
import axios from 'axios';
import { updatePackagesData } from '../../shared/store/package/actions';
import { BASE_URL } from '../../shared/store/url';
import { MarketplacePackage } from '../../shared/store/package/types';

export const MarketRoutes = [{
    path: '/marketplace/packages',
    name: 'AllPackages',
    Component: AllPackages,
}, {
    path: '/marketplace/package-details/:packageType/:packageId',
    name: 'PackageDetails',
    Component: PackageDetails,
}, {
    path: '/marketplace/add-package',
    name: 'AddPackage',
    Component: AddPackage,
}];

export function Market() {

    const dispatch = useDispatch();

    async function fetchAppData() {
        const myId = window.localStorage.getItem('myId');

        if (!myId) {
            return;
        }

        const snapshot: ApplicationState = await getCurrentSnapshot(myId);

        if (!!snapshot && !snapshot.myData.isFrozen && snapshot.myData.isLoaded) {

            if (snapshot.myData.id === 'SuperUser') {
                let orgAdminIds: any = {};
    
                if (!localStorage.getItem('orgAdminIds')) {
                    localStorage.setItem('orgAdminIds', JSON.stringify(orgAdminIds));
                }
    
                const orgAdminData = localStorage.getItem('orgAdminIds');
    
                if (orgAdminData) {
                    orgAdminIds = JSON.parse(orgAdminData);
                    orgAdminIds[snapshot.organization.code] = myId;
                    localStorage.setItem('orgAdminIds', JSON.stringify(orgAdminIds));
                }
            }

            dispatch(fetchLocalData(snapshot));
            dispatch(updatePermissions());
        }
    }

    useEffect(() => {
        if (!window.localStorage.getItem('token')) {
            return;
        }

        fetchAppData();
    }, [])


    const hasFetchedPackages = useSelector((state: ApplicationState) => state.packages.hasFetchedPackages);

    const fetchAllPackages = async () => {
        const response = await axios.get<Array<MarketplacePackage>>(BASE_URL + '/all-packages/')
        if (response.status === 200) {
            const allPackages = response.data;
            dispatch(updatePackagesData(allPackages));
            return;
        } else {
            return;
        }
    }

    useEffect(() => {
        if (!hasFetchedPackages) {
            fetchAllPackages();
        }
    }, [hasFetchedPackages]);
    
    return <section className={styles.marketPage}>
        <div className={styles.logoHolder}>
            <img src={logo} alt="DICEflow logo" className={styles.logo} />
        </div>

        <Switch>
            {MarketRoutes.map(({path, Component}) => <Route key={path} path={path} children={<Component />} />)}
            <Redirect to='/marketplace/packages' />
        </Switch>
    </section>
}