import React, { Component } from 'react';
import styles from './ReportModify.module.scss';

import InputText from '../../../widgets/form/InputText';
import chevronIcon from '../../../assets/chevron-arrow-down.svg';
import { ReactComponent as CancelIcon } from '../../../common/assets/close.svg';
import { ReactComponent as PlusIcon } from '../../../assets/new-custom-icons/dashboard/plus.svg';
import { ReactComponent as CheckIcon } from '../../../assets/new-custom-icons/profile/check-mark.svg';

import { translatePhrase } from '../../../shared/helpers/translation';

import { ApplicationState } from '../../../shared/store/types';
import { IUpdateableReportData } from '../../../shared/store/reports/types';

import { connect } from 'react-redux';
import uuid from 'uuid';
import moment from 'moment';
import { getReadableDataForCustomField } from '../../../shared/store/custom-fields';
import { Permissions } from '../../../shared/store/permissions/types';
import LoaderModal from '../../../widgets/loader/LoaderModal';
import Button from '../../../widgets/button/CommonButton';
import DateInput from '../../../widgets/form/DateInput';
import { isUUID, validateEmail } from '../../../shared/helpers/utilities';
import { Dispatch } from 'redux';
import { clearErrorMessage, setErrorMessage } from '../../../shared/store/my-data/actions';
import { getVisibleUserIdsSelector } from '../../../helpers/selectors';

type OwnProps = {
    reportId?: string,
    isReadOnly?: boolean,

    submit: (reportData: IUpdateableReportData) => void,
    cancel: () => void,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    const reportTypes = state.reports.types.allEntries.map(reportTypeId => state.reports.types.byId[reportTypeId]);
    let visibleUserIds = getVisibleUserIdsSelector(state);

    if (isUUID(state.myData.id)) {
        visibleUserIds = visibleUserIds.concat([state.myData.id]);
    }

    return {
        report: ownProps.reportId ? state.reports.byId[ownProps.reportId] : undefined,
        reportTypes: reportTypes,

        usersData: state.users,
        visibleUserIds,
        myId: state.myData.id,
        myPermissions: state.permissions.myPermissions,

        memberData: state.members,
        groupsData: state.groups,
        groupTypesData: state.groups.types,

        reportsData: state.reports,
        reportTypesData: state.reports.types,

        applicationState: state,

        isSuperUser: !isUUID(state.myData.id),
        orgEmail: state.organization.email,
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setErrorMessage: (message: string) => dispatch(setErrorMessage(message, true)),
        clearErrorMessage: () => dispatch(clearErrorMessage()),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;


type OwnState = {
    reportData: IUpdateableReportData,
    submitTimer: number | undefined,
    locationKey: number,
    shouldSendEmail: boolean,
};

class ConnectedReportModify extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        let reportData: IUpdateableReportData;
        if (!props.report) {
            // This is a new report
            reportData = {
                id: uuid.v4(),
                name: '',
                type: '',
                startDate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD'),
                user: props.isSuperUser ? '' : props.myId,
                emails: props.isSuperUser && props.orgEmail ? props.orgEmail : '',
            };
        } else {
            reportData = {
                id: props.report.id,
                name: props.report.name,
                type: props.report.type,
                startDate: props.report.startDate,
                endDate: props.report.endDate,
                user: props.report.user,
                emails: props.report.emails,
            }
        }

        this.state = {
            reportData: reportData,
            submitTimer: undefined,
            locationKey: 0,
            shouldSendEmail: !!props.report ? !!props.report.emails : props.isSuperUser,
        };
    }

    static defaultProps = {
        isReadOnly: false,
    }

    changeType = (type: string) => {

        let updatedIUpdateableReportData: IUpdateableReportData = {
            ...this.state.reportData,
            type: type,
            user: this.props.isSuperUser ? '' : this.props.myId,
        };

        this.setState({
            reportData: updatedIUpdateableReportData
        });
    }

    changeName = (name: string) => {

        let updatedIUpdateableReportData: IUpdateableReportData = {
            ...this.state.reportData,
            name: name,
        };

        this.setState({
            reportData: updatedIUpdateableReportData
        });
    }

    changeStartDate = (startDate: string | undefined) => {
        let updatedIUpdateableReportData: IUpdateableReportData = {
            ...this.state.reportData,
            startDate: startDate
        };

        this.setState({
            reportData: updatedIUpdateableReportData
        });
    }

    changeEndDate = (endDate: string | undefined) => {
        let updatedIUpdateableReportData: IUpdateableReportData = {
            ...this.state.reportData,
            endDate: endDate
        };

        this.setState({
            reportData: updatedIUpdateableReportData
        });
    }

    changeUser = (user: string) => {
        let updatedIUpdateableReportData: IUpdateableReportData = {
            ...this.state.reportData,
            user: user
        };

        this.setState({
            reportData: updatedIUpdateableReportData
        });
    }

    changeShouldSendEmail = (shouldSendEmail: boolean) => {

        this.setState(prevState => {
            let updatedIUpdateableReportData: IUpdateableReportData = {
                ...prevState.reportData,
            };

            if (!shouldSendEmail) {
                updatedIUpdateableReportData.emails = '';
            }

            return {
                shouldSendEmail,
                reportData: updatedIUpdateableReportData,
            };
        });
    }

    changeEmails = (emails: string) => {
        let updatedIUpdateableReportData: IUpdateableReportData = {
            ...this.state.reportData,
            emails,
        };

        this.setState({
            reportData: updatedIUpdateableReportData
        });
    }

    showErrorMessage = (message: string) => {
        this.props.setErrorMessage(message);

        window.setTimeout(() => {
            this.props.clearErrorMessage();
        }, 4000);
    }

    preValidateIUpdateableReportData = () => {

        if (!this.state.reportData.name) {
            return;
        }

        if (!this.state.reportData.type) {
            return;
        }

        if (!this.state.reportData.startDate) {
            return;
        }

        if (!this.state.reportData.endDate) {
            return;
        }

        if (!this.state.reportData.user) {
            return;
        }

        return true
    }

    validateIUpdateableReportData = () => {

        if (!this.state.reportData.name) {
            this.showErrorMessage('Select a valid name');
            return;
        }

        if (!this.state.reportData.type) {
            this.showErrorMessage('Select a valid type');
            return;
        }

        if (!this.state.reportData.startDate) {
            this.showErrorMessage('Select a valid start date');
            return;
        }

        if (!this.state.reportData.endDate) {
            this.showErrorMessage('Select a valid end date');
            return;
        }

        if (this.state.reportData.endDate < this.state.reportData.startDate) {
            this.showErrorMessage('The end date cannot be lesser than the start date');
            return;
        }

        if (!this.state.reportData.user) {
            this.showErrorMessage('Select a valid user');
            return;
        }

        if (this.state.shouldSendEmail) {
            if (!this.state.reportData.emails) {
                this.showErrorMessage('Select at least one email');
                return;
            }

            const emails = this.state.reportData.emails.split(',').map(email => email.trim());
            const invalidEmails = emails.filter(email => !validateEmail(email));

            if (invalidEmails.length > 0) {
                this.showErrorMessage(`Invalid email${invalidEmails.length > 1 ? 's' : ''}: ` + invalidEmails.join(','));
                return;
            }
        }

        return true
    }

    submitReportForm = () => {
        if (this.validateIUpdateableReportData()) {
            this.markForSubmit();

        }
    }

    markForSubmit = () => {
        let that = this;

        const timeout = window.setTimeout(function () {
            that.props.submit(that.state.reportData);
        }, 1000);

        this.setState({
            submitTimer: timeout
        });
    }

    render() {

        const typesList = this.props.reportTypesData.allEntries
            .filter(typeId => {
                if (typeId in this.props.myPermissions.reports) {
                    return this.props.myPermissions.reports[typeId] === Permissions.WRITE;
                }

                return true;
            })
            .map(typeId => {
                return {
                    name: this.props.reportTypesData.byId[typeId].name,
                    value: typeId,
                };
            });

        let usersList: Array<{ name: string, value: string }> = [];

        if (this.state.reportData.type) {
            const reportType = this.props.reportTypesData.byId[this.state.reportData.type];

            usersList = this.props.visibleUserIds
                .map(userId => {
                    const user = this.props.usersData.byId[userId];
                    let userName = user.customFields[this.props.usersData.nameFieldId];

                    const nameField = this.props.usersData.customFields.byId[this.props.usersData.nameFieldId];

                    userName = getReadableDataForCustomField(userName, nameField, userId, 'user');

                    return {
                        name: userName,
                        value: userId,
                    };
                });

            if (reportType.project) {
                usersList = usersList.filter(entry => {
                    const userData = this.props.usersData.byId[entry.value];

                    return userData.projects.includes(reportType.project);
                })
            }
        }

        const currentUser = this.state.reportData.user ? usersList.find(userChoice => userChoice.value === this.state.reportData.user) : undefined;
        const currentUserName = typeof currentUser !== 'undefined' ? currentUser.name : undefined;

        return (
            <section className={styles.commonModalHolder}>
                <section data-report-id={this.props.reportId ? this.props.reportId : ''} className={this.props.isReadOnly ? styles.viewOnlyReport : styles.addOrModifyListCard}>

                    <header>
                        <h2>{this.props.isReadOnly ? translatePhrase('View Report data') : translatePhrase('Add Report')}</h2>
                        <Button padding={'0px 10px'} onClick={this.props.cancel} isRounded size={'small'} icon={<CancelIcon />} text={this.props.isReadOnly ? translatePhrase('Close') : translatePhrase('Cancel')} />
                    </header>

                    <div className={styles.container}>
                        <div className={styles.allInputsHolder}>
                            <div className={styles.inputSegment}>
                                <InputText isDisabled={!!this.props.reportId} placeholder={translatePhrase('Type')} icon={chevronIcon} default={this.props.report ? this.props.reportTypesData.byId[this.props.report.type].name : ''} options={typesList} onChange={this.changeType} isAutoFocus={true} />
                            </div>
                            {this.state.reportData.type && <div className={styles.inputSegment}>
                                <InputText placeholder={translatePhrase('Name')} icon={chevronIcon} default={this.props.report ? this.props.report.name : ''} onChange={this.changeName} />
                            </div>}
                            {this.state.reportData.type && <div className={styles.inputSegment} key={this.state.reportData.type}>
                                <InputText isDisabled={!!this.props.reportId} placeholder={translatePhrase('User')} icon={chevronIcon} default={currentUserName || ''} options={usersList} onChange={this.changeUser} isAutoFocus={true} />
                            </div>}
                            <div className={styles.inputSegment + ' ' + styles.dateInput}>
                                <DateInput isDisabled={!!this.props.reportId} placeholder={translatePhrase('Report Start Date')} default={this.state.reportData.startDate ? new Date(this.state.reportData.startDate) : this.props.report && this.props.report.startDate ? new Date(this.props.report.startDate) : undefined} onChange={value => this.changeStartDate(value)} />
                            </div>
                            <div className={styles.inputSegment + ' ' + styles.dateInput}>
                                <DateInput isDisabled={!!this.props.reportId} placeholder={translatePhrase('Report End Date')} default={this.state.reportData.endDate ? new Date(this.state.reportData.endDate) : this.props.report && this.props.report.endDate ? new Date(this.props.report.endDate) : undefined} onChange={value => this.changeEndDate(value)} />
                            </div>
                            <div className={styles.inputSegment}>
                                <InputText isBooleanField placeholder="Send email?" onChange={value => this.changeShouldSendEmail(value === 'Yes')}
                                    defaultBooleanValue={this.state.shouldSendEmail} />
                            </div>
                            {this.state.shouldSendEmail && <div className={styles.inputSegment} key={this.state.reportData.type}>
                                <InputText placeholder={translatePhrase('Emails')} icon={chevronIcon} default={this.state.reportData.emails} onChange={this.changeEmails} />
                            </div>}

                        </div>

                    </div>

                    {!this.props.isReadOnly && <div className={styles.buttonsHolder}>
                        {this.state.submitTimer ?

                            <Button isRounded color={'contrast'} padding={'0px 10px'} icon={<CheckIcon />} text={this.props.report ? translatePhrase('Updated Report') : translatePhrase('Added Report')} /> :
                            <Button isDisabled={!this.preValidateIUpdateableReportData()} isRounded color={'contrast'} padding={'0px 10px'} icon={this.props.report ? <CheckIcon /> : <PlusIcon />} onClick={this.submitReportForm} text={this.props.report ? translatePhrase('Update Report') : translatePhrase('Add Report')} />}
                    </div>}

                </section>

            </section>
        );
    }
}

const ReportModify = connect(mapStateToProps, mapDispatchToProps)(ConnectedReportModify);

export default ReportModify;