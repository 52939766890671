import React, { FC } from 'react';
import styles from './UsageNotes.module.scss';

type OwnProps = {};

const UsageNotes: FC<OwnProps> = (props) => {
    return <div className={styles.tableHolder}>
        <table className={styles.table}>
            <tr>
                <th>Permission name</th>
                <th>None</th>
                <th>Read</th>
                <th>Write</th>
            </tr>
            <tr>
                <td>Organization Profile</td>
                <td>Cannot see the org profile link in header</td>
                <td>Can view all org profile data, but cannot edit them</td>
                <td>Can view and edit all org profile data</td>
            </tr>
            <tr>
                <td>Hierarchy</td>
                <td>Cannot see the hierarchy tab in the structure pages</td>
                <td>Can view the entire hierarchy of organization, but cannot change it</td>
                <td>Can view and edit hierarchy of organization</td>
            </tr>
            <tr>
                <td>Locations</td>
                <td>Cannot see the location tab in the structure pages</td>
                <td>Can view the entire location tree of organization, but cannot change it</td>
                <td>Can view and edit the location tree of the organization</td>
            </tr>
            <tr>
                <td>Users</td>
                <td>Cannot see any users in the organization</td>
                <td>Can view users in the logged in user's descendant location tree, but cannot edit them</td>
                <td>Can view, add, and edit all users in the logged in user's descendant location tree</td>
            </tr>
            <tr>
                <td>User Configuration</td>
                <td>Cannot see the user configuration page</td>
                <td>Can view all user custom fields (per project), but cannot edit them</td>
                <td>Can view, add, and edit all user custom fields for all projects</td>
            </tr>
            <tr>
                <td>Members Configuration</td>
                <td>Cannot see the member configuration page</td>
                <td>Can view all member types and custom fields, but cannot edit them</td>
                <td>Can view, add, and edit all member types and custom fields</td>
            </tr>
            <tr>
                <td>Groups Configuration</td>
                <td>Cannot see the group configuration page</td>
                <td>Can view all group types and custom fields, but cannot edit them</td>
                <td>Can view, add, and edit all group types and custom fields</td>
            </tr>
            <tr>
                <td>Workflows Configuration</td>
                <td>Cannot see the workflow configuration page</td>
                <td>Can view all workflow types, statuses, and custom fields, but cannot edit them</td>
                <td>Can view, add, and edit all workflow types, statuses, and custom fields</td>
            </tr>
            <tr>
                <td>Reports Configuration</td>
                <td>Cannot see the report configuration page</td>
                <td>Can view all report types, but cannot edit them</td>
                <td>Can view, add, and edit all report types.</td>
            </tr>
            <tr>
                <td>Languages Configuration</td>
                <td>Cannot see the languages link in the header</td>
                <td>Can view all languages, but cannot edit them</td>
                <td>Can view, add, and edit all languages</td>
            </tr>
            <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td>Member (granular)</td>
                <td>Cannot see any members of that type</td>
                <td>Can view all members of that type in the logged in user's location tree and workflows, but cannot edit them</td>
                <td>Can view, add, and edit all members of that type in the logged in user's location tree and workflows</td>
            </tr>
            <tr>
                <td>Group (granular)</td>
                <td>Cannot see any groups of that type</td>
                <td>Can view all groups of that type in the logged in user's location tree and workflows, but cannot edit them</td>
                <td>Can view, add, and edit all groups of that type in the logged in user's location tree and workflows</td>
            </tr>
            <tr>
                <td>Workflow (granular)</td>
                <td>Cannot see any workflows of that type</td>
                <td>Can view all workflows of that type in the logged in user's location tree, but cannot add new workflows of that type, or transfer existing ones</td>
                <td>Can view, add, and transfer all workflows of that type in the logged in user's location tree.</td>
            </tr>
            <tr>
                <td>Report (granular)</td>
                <td>Cannot see any reports of that type</td>
                <td>Can view all reports of that type connected to the logged in user, but cannot edit them</td>
                <td>Can view, add, and edit all reports of that type</td>
            </tr>
            <tr>
                <td>Language (granular)</td>
                <td>Cannot see any translations of this language</td>
                <td>Can view all translations of this language, but cannot edit them</td>
                <td>Can view and update all translations for this language</td>
            </tr>
        </table>
    </div>
}

export default UsageNotes;