import React from 'react';
import PageHeader from '../../components/page-header/PageHeader';
import { ApplicationState } from '../../shared/store/types';
import styles from './Tabs.module.scss';
import { connect } from 'react-redux';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    return {
        isTopBarExpanded: state.myData.isTopBarExpanded
    }
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;

const ConnectedTabs: React.FC<Props> = (props) => {
    return (
        <section className={styles.Tabs + ' ' + (props.isTopBarExpanded ? styles.active : '')}>
            {props.children}
            <PageHeader />
        </section>
    );
}

const Tabs = connect(mapStateToProps)(ConnectedTabs);

export default Tabs;