import { FC, useState, useEffect } from 'react';
import styles from './WorkflowsTable.module.scss';
import moment from 'moment';
import 'tippy.js/dist/tippy.css';
import WorkflowModify from './WorkflowModify';
import WorkflowFilter from './WorkflowFilter';

import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import { Dispatch } from 'redux';
import { ApplicationState } from '../../../shared/store/types';

import { ReactComponent as RefreshIcon } from '../../../assets/new-custom-icons/new-revision/refresh.svg';
import { ReactComponent as ViewIcon } from "../../../assets/eye.svg";

import { addWorkflow, updateWorkflow, deleteWorkflow, transferWorkflow, searchWorkflowTable, goToPageWorkflowTable, setPageSizeWorkflowTable, sortWorkflowTable, unArchiveWorkflow, updateStatus, updateDueDate, addToHistory, updateWorkflowCustomFieldData, filterWorkflowTable, recomputeAllWorkflows, setIsShowingCSVFormForWorkflows, bulkDeleteWorkflows } from '../../../shared/store/workflows/actions';
import { IUpdateableWorkflowData, IWorkflow, WorkflowProcessState } from '../../../shared/store/workflows/types';
import TableWithMeta from '../../../widgets/table/TableWithMeta';
import { getReadableDataForCustomField } from '../../../shared/store/custom-fields';
import { isUUID } from '../../../shared/helpers/utilities';
import { translatePhrase } from '../../../shared/helpers/translation';
import { Permissions } from '../../../shared/store/permissions/types';
import { clearIndeterminateMessage, clearInfoMessage, clearToastMessage, setIndeterminateMessage, setInfoMessage, setToastMessage } from '../../../shared/store/my-data/actions';
import { PieceType } from '../../../shared/store/flowchart/pieces/types';
import { startOrResumeWorkflow, getWorkflowPieceValue } from '../../../shared/store/flowchart/helpers/workflow';
import { IUpdateableMemberData } from '../../../shared/store/members/types';
import { addMember, updateMemberCustomFieldData, updateMembersLocationRequest } from '../../../shared/store/members/actions';
import { IUpdateableGroupData } from '../../../shared/store/groups/types';
import { addGroup, setMembersForGroupRequest, updateGroupCustomFieldData, updateGroupsLocationRequest } from '../../../shared/store/groups/actions';
import { updateLocationCustomFieldData } from '../../../shared/store/structure/location/actions';
import { updateUserCustomFieldData } from '../../../shared/store/users/actions';
import { VariableType } from '../../../shared/store/flowchart/variables/types';
import { getAllPiecesInPiece } from '../../../shared/store/flowchart/helpers/pieces';
import { TableHeading, TableRow } from '../../../widgets/table/Table';
import AlertModal from '../../../widgets/alert/AlertModal';
import { completionPercentageOfWorkflow } from '../../../shared/store/flowchart/helpers/progress';
import store from '../../../shared/store/main';
import { getFilteredWorkflows } from './selectors';
import Button from '../../../widgets/button/CommonButton';
import WorkflowCSV from './WorkflowCSV';
import DateInput from '../../../widgets/form/DateInput';
import { NudgeType } from '../../../shared/store/my-data/types';
import Toggle from '../../../widgets/form/Toggle';
import LoaderModal from '../../../widgets/loader/LoaderModal';
import { VariableValueType } from '../../../shared/helpers/common-types';
import { CustomFieldDataHolder, WorkflowTypeCustomFieldDataHolder, CustomFieldValueType, FieldType } from '../../../shared/store/custom-fields/types';
import WorkflowsTransfer from './WorkflowsTransfer';
import { getAncestorChainOfLocation } from '../../../shared/helpers/locations';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import React from 'react';
import { BASE_URL } from '../../../shared/store/url';
import { getActionableIdsAndBulkActionsSelector, getIsPartialFetch } from '../../../selectors/selectors';
import { useHistory } from 'react-router';

const mapStateToProps = (state: ApplicationState) => {

    const currentPageNumber = state.workflows.currentPageNumber,
        pageSize = state.workflows.pageSize,
        workflowSearchTerm = state.workflows.searchTerm,
        filters = state.workflows.filters;

    const isOnline = state.myData.isOnline

    let filteredWorkflows: Array<IWorkflow> = [];

    if (isOnline) {
        if (state.workflows.filters.archived) {
            filteredWorkflows = Object.keys(state.workflows.byId).map(workflowId => state.workflows.byId[workflowId]).filter(workflow => workflow.archived);
        } else {
            filteredWorkflows = state.workflows.allEntries.map(workflowId => state.workflows.byId[workflowId])
        }
    } else {
        filteredWorkflows = getFilteredWorkflows(state);
    }

    const tags: Array<string> = [];

    if (filters.projects.length > 0) {
        tags.push('Projects: ' + filters.projects.map(projectId => state.structure.projects.byId[projectId].name).join(', '));
    }

    if (filters.dues.length > 0) {
        tags.push('Due: ' + filters.dues.join(', '));
    }

    if (filters.locations.length > 0) {
        tags.push('Locations: ' + filters.locations.map(locationId => state.structure.locations.byId[locationId].name).join(', ') + ' and below');
    }

    if (filters.users.length > 0) {
        const tagValues = filters.users.map(value => {
            if (value === 'other') {
                return 'other users'
            }

            return value;
        })

        tags.push('Flows: ' + tagValues.join(', '));
    }

    if (filters.otherUsers.length > 0) {
        tags.push('Other users: ' + filters.otherUsers.map(userId => {
            const user = state.users.byId[userId];

            const nameField = state.users.customFields.byId[state.users.nameFieldId];
            const userName = getReadableDataForCustomField(user.customFields[nameField.id], nameField, user.id, 'user');
            return userName;
        }));
    }

    if (filters.types.length > 0) {
        tags.push('Types: ' + filters.types.map(typeId => state.workflows.types.byId[typeId].name).join(', '));
    }

    if (filters.createdDateRange.length > 1) {
        tags.push(`Created date range: ${moment(filters.createdDateRange[0]).format('DD MMM YYYY')} - ${moment(filters.createdDateRange[1]).format('DD MMM YYYY')}`);
    }

    if (filters.lastUpdatedDateRange.length > 1) {
        tags.push(`Last updated date range: ${moment(filters.lastUpdatedDateRange[0]).format('DD MMM YYYY')} - ${moment(filters.lastUpdatedDateRange[1]).format('DD MMM YYYY')}`);
    }

    if (filters.lastWorkedOn.startTime || filters.lastWorkedOn.endTime) {
        tags.push(`Last worked on date range: ${moment(filters.lastWorkedOn.startTime).format('DD MMM YYYY')} - ${moment(filters.lastWorkedOn.endTime).format('DD MMM YYYY')}`);
    }

    if (filters.dueDateRange.length > 1) {
        tags.push(`Due date range: ${moment(filters.dueDateRange[0]).format('DD MMM YYYY')} - ${moment(filters.dueDateRange[1]).format('DD MMM YYYY')}`);
    }

    if (filters.unsynced) {
        tags.push('Unsynced');
    }

    if (filters.archived) {
        tags.push('Archived');
    }

    if (filters.isOutdated) tags.push("Outdated");

    if (filters.types.length === 1) {
        const workflowType = state.workflows.types.byId[filters.types[0]];

        if (filters.statuses.length > 0) {
            tags.push('Statuses: ' + filters.statuses.map(statusId => state.workflows.types.statuses.byId[statusId].name).join(', '));
        }

        if (filters.affiliations.length > 0) {
            let affiliationNames: Array<string> = [];

            if (workflowType.affiliation === 'member') {
                affiliationNames = filters.affiliations.map(affiliationId => {
                    const member = state.members.byId[affiliationId];
                    const memberType = state.members.types.byId[member.type];

                    const nameField = state.members.types.customFields.byId[memberType.nameFieldId];
                    const memberName = getReadableDataForCustomField(member.customFields[nameField.id], nameField, member.id, 'member');
                    return memberName;
                });
            } else if (workflowType.affiliation === 'group') {
                affiliationNames = filters.affiliations.map(affiliationId => {
                    const group = state.groups.byId[affiliationId];
                    const groupType = state.groups.types.byId[group.type];

                    const nameField = state.groups.types.customFields.byId[groupType.nameFieldId];
                    const groupName = getReadableDataForCustomField(group.customFields[nameField.id], nameField, group.id, 'group');
                    return groupName;
                });
            }

            tags.push('Affiliations: ' + affiliationNames.join(', '));
        }

        for (const customFieldId in filters.customFields) {
            const customField = state.workflows.types.customFields.byId[customFieldId];
            const selectedOptions = filters.customFields[customFieldId].map(optionId => state.workflows.types.customFieldOptions.byId[optionId]);

            tags.push(`${customField.name}: ${selectedOptions.map(option => option.name).join(', ')}`);
        }
    }

    const totalNoOfWorkflows = !isOnline ? filteredWorkflows.length : state.workflows.totalNoOfWorkflows;

    if (tags.length > 0) {
        tags.push('Count: ' + totalNoOfWorkflows);
    }

    const { bulkActions, actionableIds } = getActionableIdsAndBulkActionsSelector(filteredWorkflows, state.workflows.types)(state);

    return {
        workflowsList: !isOnline ? filteredWorkflows.slice((currentPageNumber - 1) * pageSize, currentPageNumber * pageSize) : filteredWorkflows,
        filteredWorkflows: filteredWorkflows,
        actionableWorkflowIds: actionableIds,
        totalNoOfWorkflows,
        pageSize: pageSize,
        pageNumber: currentPageNumber,
        totalPages: Math.ceil(totalNoOfWorkflows / pageSize),
        tags,

        searchTerm: workflowSearchTerm,
        workflowFilters: state.workflows.filters,
        workflowSort: state.workflows.sort,

        myId: state.myData.id,
        myPermissions: state.permissions.myPermissions,

        isPartialFetch: getIsPartialFetch(state),

        areFiltersExpanded: false,

        organizationPlan: state.organization.plan,
        selectedNudge: state.myData.selectedNudgeId,
        bulkActions,
        applicationState: state,

        isOnline,
        isSuperUser: !isUUID(state.myData.id),

        isShowingCSVForm: state.workflows.isShowingCSVForm,

        token: localStorage.getItem('token') || "",
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        searchTable: (searchTerm: string) => dispatch(searchWorkflowTable(searchTerm)),
        goToPage: (page: number) => dispatch(goToPageWorkflowTable(page)),
        changePageSize: (size: number) => dispatch(setPageSizeWorkflowTable(size)),
        sort: (column: string, order: 'ASC' | 'DESC') => dispatch(sortWorkflowTable(column, order)),

        addWorkflow: (payload: IUpdateableWorkflowData) => dispatch(addWorkflow(payload)),
        deleteWorkflow: (id: string) => dispatch(deleteWorkflow(id)),
        deleteWorkflows: (ids: Array<string>) => dispatch(bulkDeleteWorkflows(ids)),
        unArchiveWorkflow: (id: string) => dispatch(unArchiveWorkflow(id)),
        transferWorkflow: (id: string, user: string) => dispatch(transferWorkflow(id, user)),
        updateWorkflow: (payload: IUpdateableWorkflowData) => dispatch(updateWorkflow(payload)),

        filterWorkflowTable: (dues: Array<string>, projects: Array<string>, types: Array<string>, statuses: Array<string>, users: Array<string>, locations: Array<string>, otherUsers: Array<string>, affiliations: Array<string>, customFields: { [customFieldId: string]: Array<string> }, createdDateRange: Array<string>, lastUpdatedDateRange: Array<string>, lastWorkedOn: { startTime?: string, endTime?: string }, dueDateRange: Array<string>, unsynced: boolean, archived: boolean, isOutdated: boolean) => dispatch(filterWorkflowTable(dues, projects, types, statuses, users, locations, otherUsers, affiliations, customFields, createdDateRange, lastUpdatedDateRange, lastWorkedOn, dueDateRange, unsynced, archived, isOutdated)),

        setToastMessage: (message: string) => dispatch(setToastMessage(message)),
        clearToastMessage: () => dispatch(clearToastMessage()),

        setInfoMessage: (message: string) => dispatch(setInfoMessage(message)),
        clearInfoMessage: () => dispatch(clearInfoMessage()),

        setIndeterminateMessage: (message: string) => dispatch(setIndeterminateMessage(message)),
        clearIndeterminateMessage: () => dispatch(clearIndeterminateMessage()),

        updateLocationCustomFieldData: (locationId: string, customFieldData: CustomFieldDataHolder) => dispatch(updateLocationCustomFieldData(locationId, customFieldData)),
        updateUserCustomFieldData: (workflowId: string, userId: string, customFieldData: CustomFieldDataHolder) => dispatch(updateUserCustomFieldData(workflowId, userId, customFieldData)),
        updateMemberCustomFieldData: (workflowId: string, memberId: string, customFieldData: CustomFieldDataHolder) => dispatch(updateMemberCustomFieldData(workflowId, memberId, customFieldData)),
        updateGroupCustomFieldData: (workflowId: string, groupId: string, customFieldData: CustomFieldDataHolder) => dispatch(updateGroupCustomFieldData(workflowId, groupId, customFieldData)),
        updateWorkflowCustomFieldData: (changedWorkflowIdId: string, workflowIdId: string, customFieldData: WorkflowTypeCustomFieldDataHolder) => dispatch(updateWorkflowCustomFieldData(changedWorkflowIdId, workflowIdId, customFieldData)),

        updateStatus: (workflowId: string, statusId: string) => dispatch(updateStatus(workflowId, statusId)),
        updateDueDate: (workflowId: string, dueDate: string) => dispatch(updateDueDate(workflowId, dueDate)),
        addToHistory: (processState: WorkflowProcessState, workflowId: string, userId: string) => dispatch(addToHistory(processState, workflowId, userId)),
        addMember: (memberData: IUpdateableMemberData) => dispatch(addMember(memberData)),
        addGroup: (groupData: IUpdateableGroupData) => dispatch(addGroup(groupData)),

        recomputeAllWorkflows: () => dispatch(recomputeAllWorkflows()),

        setMembersForGroup: (groupId: string, memberTypes: 'representatives' | 'all_members', memberIds: Array<string>) => dispatch(setMembersForGroupRequest(groupId, memberTypes, memberIds)),

        updateMembersLocation: (memberIds: Array<string>, locationId: string) => dispatch(updateMembersLocationRequest(memberIds, locationId)),
        updateGroupsLocation: (groupIds: Array<string>, locationId: string) => dispatch(updateGroupsLocationRequest(groupIds, locationId)),
        setIsShowingCSVFormForWorkflows: (show: boolean) => dispatch(setIsShowingCSVFormForWorkflows(show)),
    };
}

type OwnProps = {
    isReadOnly: boolean,
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;

interface WorkflowTypeCompletionPercentage {
    [pieceId: string]: number,
};

type CompletionPercentagesState = {
    [workflowTypeId: string]: WorkflowTypeCompletionPercentage;
};

interface workflowTransferConfirmation {
    header: string,
    sentence: string,
    show: boolean,
    workflowId: string,
    userId: string,
}

const ConnectedWorkflowsTable: FC<Props> = (props) => {

    const [isShowingAddForm, setIsShowingAddForm] = useState(false);
    const [isShowingModifyForm, setIsShowingModifyForm] = useState(false);
    const [isShowingFilterForm, setIsShowingFilterForm] = useState(false);
    const [isShowingTransferForm, setIsShowingTransferForm] = useState(false);
    const [isShowingCalendar, setIsShowingCalendar] = useState(false);
    const [isShowingOverdue, setIsShowingOverdue] = useState(props.workflowFilters.dues.includes('overdue'));
    const [selectedWorkflow, setSelectedWorkflow] = useState<string | undefined>(undefined);
    const [transferSearchString, setTransferSearchString] = useState('');
    const [workflowTransferConfirmation, setWorkflowTransferConfirmation] = useState<workflowTransferConfirmation | undefined>(undefined);
    const [completionPercentages, setCompletionPercentages] = useState<CompletionPercentagesState>({});
    const [loaderToast, setLoaderToast] = useState<React.ReactNode | undefined>(undefined);
    const [selectedSmartFilter, setSelectedSmartFilter] = useState('');

    const history = useHistory();

    useEffect(() => {
        const newCompletionPercentages: {
            [workflowTypeId: string]: WorkflowTypeCompletionPercentage,
        } = {
            ...completionPercentages,
        };

        if (!isShowingAddForm && !isShowingModifyForm) {

            for (const workflow of props.workflowsList) {
                if (!(workflow.type in newCompletionPercentages)) {
                    newCompletionPercentages[workflow.type] = {};
                }

                try {
                    let currentProcessState;

                    if (workflow.historyIndex >= workflow.history.length) {
                        currentProcessState = workflow.history[workflow.history.length - 1];
                    } else {
                        currentProcessState = workflow.history[workflow.historyIndex];
                    }

                    const lastComputedPieceId = currentProcessState.lastComputedPiece;

                    if (lastComputedPieceId && !(lastComputedPieceId in newCompletionPercentages[workflow.type])) {
                        const completionPercentage = completionPercentageOfWorkflow(workflow.id);

                        newCompletionPercentages[workflow.type] = {
                            ...newCompletionPercentages[workflow.type],
                            [lastComputedPieceId]: completionPercentage === 0 ? 1 : completionPercentage > 96 ? 96 : completionPercentage,
                        };
                    }
                } catch (e) {
                    console.error(e);
                }
            }

            if (JSON.stringify(newCompletionPercentages) !== JSON.stringify(completionPercentages)) {
                setCompletionPercentages(newCompletionPercentages);
            }
        }
    }, [props.workflowsList, completionPercentages, isShowingAddForm, isShowingModifyForm]);

    useEffect(() => {
        const workflowOverDue = props.workflowFilters.dues.includes('overdue');
        setIsShowingOverdue(workflowOverDue);
    }, [props.workflowFilters]);

    const updateSearchString = (searchString: string) => {
        setTransferSearchString(searchString);
    }

    const showTransferForm = () => {
        addBlur();
        setIsShowingTransferForm(true);
    }

    const hideTransferForm = () => {
        removeBlur();
        setIsShowingTransferForm(false);
    }

    const showCalendar = (workflowId: string) => {
        setIsShowingCalendar(true);
        setSelectedWorkflow(workflowId)
    }

    const hideCalendar = () => {
        setIsShowingCalendar(false);
        setSelectedWorkflow(undefined)
    }

    const addBlur = () => {
        document.querySelector('#page-header')?.classList.add('blur');
        document.querySelector('#import-export-button-holder')?.classList.add('blur');
    }

    const removeBlur = () => {
        document.querySelector('#page-header')?.classList.remove('blur');
        document.querySelector('#import-export-button-holder')?.classList.remove('blur');
    }

    const handleCSVForm = () => {
        if (props.isShowingCSVForm) {
            removeBlur();
        } else {
            addBlur()
        }

        props.setIsShowingCSVFormForWorkflows(!props.isShowingCSVForm);
    }

    const showAddForm = () => {
        addBlur();
        setIsShowingAddForm(true);
    }

    const showFilterForm = () => {
        addBlur();
        setIsShowingFilterForm(true);
    }

    const showModifyForm = () => {
        addBlur();
        setIsShowingModifyForm(true);
    }

    const hideWorkflowForm = () => {
        removeBlur();
        setIsShowingAddForm(false);
        setIsShowingModifyForm(false);
    }

    const hideFilterForm = () => {
        removeBlur();
        setIsShowingFilterForm(false);
    }

    const addWorkflow = (workflowData: IUpdateableWorkflowData) => {
        removeBlur();
        props.addWorkflow(workflowData);

        initiateOrResumeWorkflow(workflowData.id);
        setIsShowingAddForm(false);
    }

    const deleteWorkflow = (id: string) => {
        props.deleteWorkflow(id);
        if (props.pageNumber !== 1 && props.workflowsList.length === 0) {
            props.goToPage(props.pageNumber - 1);
        }
    }

    const unArchiveWorkflow = (id: string) => {
        props.unArchiveWorkflow(id);
        if (props.pageNumber !== 1 && props.workflowsList.length === 0) {
            props.goToPage(props.pageNumber - 1);
        }
    }

    const transferAction = (flag: boolean) => {
        if (flag) {
            if (!workflowTransferConfirmation) {
                return;
            }

            props.transferWorkflow(
                workflowTransferConfirmation.workflowId,
                workflowTransferConfirmation.userId
            );

            setWorkflowTransferConfirmation(undefined);

            setLoaderToast(<LoaderModal isSuccess loaderText={['The workflow has been transferred']} />);

            setTimeout(() => {
                setLoaderToast(undefined);
            }, 4000);
        } else {
            setWorkflowTransferConfirmation(undefined);
        }

    }

    const handleSort = (column: string) => {
        const isNewColumn = props.workflowSort.column !== column;
        const sortOrder = !isNewColumn && props.workflowSort.order === 'ASC' ? 'DESC' : 'ASC';

        props.sort(column, sortOrder);
    }

    const getWorkflowProcessState = (workflowId: string) => {
        const applicationState = store.getState();
        const workflow = applicationState.workflows.byId[workflowId];
        const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: workflow.history[workflow.historyIndex].customFields,
            lastComputedPiece: workflow.history[workflow.historyIndex].lastComputedPiece,
            executionStack: workflow.history[workflow.historyIndex].executionStack,
            forIterationCounts: workflow.history[workflow.historyIndex].forIterationCounts,
            variables: workflow.history[workflow.historyIndex].variables,
            displayingQuestionPieceId: workflow.history[workflow.historyIndex].displayingQuestionPieceId,
            displayingShowPieceId: workflow.history[workflow.historyIndex].displayingShowPieceId,
            displayingGroupPieceId: workflow.history[workflow.historyIndex].displayingGroupPieceId,
            displayingTransferPieceId: workflow.history[workflow.historyIndex].displayingTransferPieceId,
            displayingContinuePieceId: workflow.history[workflow.historyIndex].displayingContinuePieceId,
            displayingAddWorkflowPieceId: workflow.history[workflow.historyIndex].displayingAddWorkflowPieceId,
            createdWorkflowId: workflow.history[workflow.historyIndex].createdWorkflowId,
        }));

        return processState;
    }

    const updateCustomFieldValue = (workflowId: string, entityId: string, type: VariableType, fieldId: string, value: CustomFieldValueType, memberId?: string) => {
        if (type === VariableType.LOCATION) {
            props.updateLocationCustomFieldData(entityId, { [fieldId]: value });
        } else if (type === VariableType.USER) {
            props.updateUserCustomFieldData(workflowId, entityId, { [fieldId]: value });
        } else if (type === VariableType.MEMBER) {
            props.updateMemberCustomFieldData(workflowId, entityId, { [fieldId]: value });
        } else if (type === VariableType.GROUP) {
            props.updateGroupCustomFieldData(workflowId, entityId, { [fieldId]: value });
        } else if (type === VariableType.WORKFLOW) {
            if (memberId) {
                props.updateWorkflowCustomFieldData(workflowId, entityId, { [fieldId]: { [memberId]: value } });
            } else {
                props.updateWorkflowCustomFieldData(workflowId, entityId, { [fieldId]: value });
            }
        }
    }

    const updateLocation = (entityIds: Array<string>, type: VariableType, locationId: string) => {
        if (type === VariableType.MEMBER || type === VariableType.MEMBERS_LIST) {
            props.updateMembersLocation(entityIds, locationId);
        } else if (type === VariableType.GROUP || type === VariableType.GROUPS_LIST) {
            props.updateGroupsLocation(entityIds, locationId);
        }
    }

    const doesWorkflowConditionPass = (workflowId: string) => {
        const applicationState = store.getState();
        const processState = getWorkflowProcessState(workflowId);

        if (!processState.displayingContinuePieceId) {
            return false;
        }

        const continuePiece = applicationState.flowchart.pieces.byId[processState.displayingContinuePieceId];

        if (continuePiece.type !== PieceType.CONTINUE) {
            return false;
        }

        if (!continuePiece.condition) {
            return false;
        }

        const isConditionPassing = !!getWorkflowPieceValue(applicationState, processState, workflowId, continuePiece.condition);

        return isConditionPassing;
    }

    const initiateOrResumeWorkflow = async (workflowId: string) => {
        const applicationState = store.getState();
        const workflow = applicationState.workflows.byId[workflowId];
        const workflowType = applicationState.workflows.types.byId[workflow.type];

        const allPiecesInWorkflow = getAllPiecesInPiece(applicationState.flowchart.pieces, workflowType.startPiece.piece);

        const getLocationPiece = allPiecesInWorkflow.find(piece => piece.type === PieceType.GET_CURRENT_LOCATION);

        if (!!getLocationPiece) {
            if (navigator.permissions) {
                const status = await navigator.permissions.query({ name: 'geolocation' });
                if (status.state === 'denied') {
                    props.setToastMessage('You need location permissions to execute this workflow');
                    return;
                } else if (status.state === 'prompt') {
                    props.setToastMessage('Please provide location permissions and try again');
                    navigator.geolocation.getCurrentPosition(() => { });
                    return;
                }
            }
        }

        const processState = getWorkflowProcessState(workflowId);
        let display: string;

        const workflowStatusesData = applicationState.workflows.types.statuses;
        const workflowStatus = workflowStatusesData.byId[workflow.status];

        if (processState.displayingQuestionPieceId) {
            const questionPiece = applicationState.flowchart.pieces.byId[processState.displayingQuestionPieceId];

            if (questionPiece.type === PieceType.QUESTION) {
                display = 'question';
            } else {
                display = 'choose';
            }

        } else if (processState.displayingShowPieceId) {
            display = 'show';
        } else if (processState.displayingGroupPieceId) {
            display = 'group';
        } else if (processState.displayingTransferPieceId) {
            display = 'transfer';
        } else if (processState.displayingContinuePieceId) {
            display = 'continue';
        } else if (processState.displayingAddWorkflowPieceId) {
            display = 'continue';
        } else if (workflowStatus.isTerminal) {
            display = 'end';
        } else if (processState.createdWorkflowId) {
            display = 'switch';
        } else {
            display = 'start';
        }

        let shouldWorkflowExecute = false;

        if (display === 'start') {
            shouldWorkflowExecute = true;
        } else if (display === 'continue') {
            const doesContinueConditionPass = doesWorkflowConditionPass(workflowId);
            shouldWorkflowExecute = doesContinueConditionPass;
            processState.displayingContinuePieceId = undefined;
        } else if (display === 'add-workflow') {
            shouldWorkflowExecute = false;
        }

        if (shouldWorkflowExecute) {
            try {
                await startOrResumeWorkflow(applicationState, processState, workflowId, props.updateStatus, props.updateDueDate, updateCustomFieldValue, updateLocation, props.addToHistory, props.addMember, props.addGroup, props.setMembersForGroup, props.addWorkflow);
            } catch (e) {
            }
        }

        history.push(`/workflow/${workflowId}/execute`)
    }

    const getReadableLocationChainFromId = (locationId: string) => {
        const applicationState = store.getState();

        const locationsData = applicationState.structure.locations;

        const parentLocationIds = getAncestorChainOfLocation(locationId);
        parentLocationIds.reverse();
        const locationChain = parentLocationIds
            .filter(locationId => locationId in locationsData.byId)
            .concat([locationId])
            .map(locationId => locationsData.byId[locationId].name)
            .join(' > ');

        return locationChain;
    }

    const getReadableLocationFromName = (locationId: string) => {
        const applicationState = store.getState();
        const location = applicationState.structure.locations.byId[locationId];

        if (!location) {
            return '-';
        }

        return translatePhrase(location.name);

    }

    const handleDueDateUpdate = (id: string, dueDate: string | undefined) => {
        if (dueDate) {
            let text = `${translatePhrase('Due date updated to')}: ${moment(dueDate).format('DD MMM YYYY')}`

            setLoaderToast(<LoaderModal isSuccess loaderText={[text]} />);

            props.updateDueDate(id, dueDate);


            setTimeout(() => {
                setLoaderToast(undefined);
            }, 4000);
        }
        hideCalendar();
    }

    const toggleIsShowingOverdue = () => {

        let newWorkflowFilterDues = [...props.workflowFilters.dues];

        if (newWorkflowFilterDues.includes('overdue')) {
            newWorkflowFilterDues = newWorkflowFilterDues.filter(dueFilter => dueFilter !== 'overdue');
        } else {
            newWorkflowFilterDues.push('overdue');
        }

        props.filterWorkflowTable(
            newWorkflowFilterDues,
            props.workflowFilters.projects,
            props.workflowFilters.types,
            props.workflowFilters.statuses,
            props.workflowFilters.users,
            props.workflowFilters.locations,
            props.workflowFilters.otherUsers,
            props.workflowFilters.affiliations,
            props.workflowFilters.customFields,
            props.workflowFilters.createdDateRange,
            props.workflowFilters.lastUpdatedDateRange,
            props.workflowFilters.lastWorkedOn,
            props.workflowFilters.dueDateRange,
            props.workflowFilters.unsynced,
            props.workflowFilters.archived,
            props.workflowFilters.isOutdated,
        );
    }

    const recomputeWorkflowComputedFields = () => {
        props.setIndeterminateMessage('Recomputing computed field values');

        setTimeout(() => {
            props.recomputeAllWorkflows();
            props.clearIndeterminateMessage();
        }, 500);
    }

    useEffect(() => {
        if (props.isOnline) {
            props.goToPage(1);
        }
    }, []);

    const deleteAllWorkflows = () => {
        if (props.bulkActions) {
            props.setIndeterminateMessage('Archiving workflows...');

            setTimeout(() => {
                props.deleteWorkflows(props.actionableWorkflowIds);

                props.clearIndeterminateMessage();
            }, 500);
        }
    }

    const getSmartFilter = (smartFilter: string) => {
        setSelectedSmartFilter(smartFilter);
    }

    const applicationState = store.getState();
    const workflowTypesData = applicationState.workflows.types;
    const workflowStatusesData = applicationState.workflows.types.statuses;
    const customFieldsData = applicationState.workflows.types.customFields;
    const usersData = applicationState.users;
    const membersData = applicationState.members;
    const groupsData = applicationState.groups;

    let MAX_NO_OF_WORKFLOWS = Infinity;

    if (props.organizationPlan === 'unlimited') {
        MAX_NO_OF_WORKFLOWS = Infinity;
    }

    const headings: Array<TableHeading> = [{
        name: 'Sl. no',
        isSortable: false,
        isSticky: true,
        width: 90,
    }, {
        name: 'Name',
        isSortable: false,
        width: 120,
    }, {
        name: 'Subtitle',
        isSortable: false,
        width: 120,
    }, {
        name: 'Status',
        isSortable: false,
        width: 100,
    }, {
        name: 'Due date',
        isSortable: false,
        width: 150,
    }, {
        name: 'Last worked on',
        isSortable: false,
        width: 150,
    }, {
        name: 'User',
        isSortable: false,
        width: 130,
    }, {
        name: 'Mem/Grp',
        isSortable: false,
        width: 140,
    }];

    if (props.workflowFilters.types.length === 1) {
        const workflowType = workflowTypesData.byId[props.workflowFilters.types[0]];
        workflowType.customFields.forEach(customFieldId => {
            const customField = customFieldsData.byId[customFieldId];
            const width = customField.type === FieldType.TEXT ? 200 : 150;

            if (customField.isInTable) {
                headings.push({
                    name: customField.name,
                    isSortable: false,
                    width: width,
                });
            }
        });
    }

    headings.push({
        name: 'Action',
        isSortable: false,
        width: 200,
        stickyFromRight: 130,
    });

    const today = moment().format('YYYY-MM-DD');

    const entries: Array<TableRow> = props.workflowsList.map<TableRow>((workflow, index) => {
        const workflowType = workflowTypesData.byId[workflow.type];
        const workflowStatus = workflowStatusesData.byId[workflow.status];
        const workflowDueDate = moment(workflow.dueDate).format('YYYY-MM-DD');

        const processState = workflow.historyIndex >= workflow.history.length ? workflow.history[workflow.history.length - 1] : workflow.history[workflow.historyIndex];
        const workflowLastWorkedOn = moment(processState.executionTime ? processState.executionTime : workflow.lastUpdatedTime).format('DD MMM YYYY hh:mm:ss A');

        let workflowAffiliatedName = translatePhrase('Not Applicable');
        let userName: VariableValueType = '-';
        let locationName = '-';
        let locationPath = '-';

        if (isUUID(workflow.user)) {
            const user = usersData.byId[workflow.user];
            if(user){
                userName = user.customFields[usersData.nameFieldId];
                const nameField = usersData.customFields.byId[usersData.nameFieldId];
                userName = getReadableDataForCustomField(userName, nameField, user.id, 'user');
            } else {
                userName = '-'
            }


        }

        if (workflowType.affiliation === 'member') {
            const member = membersData.byId[workflow.affiliatedEntity];
            if (member) {
                const memberType = membersData.types.byId[member.type];
                let memberName = member.customFields[memberType.nameFieldId];

                const nameField = membersData.types.customFields.byId[memberType.nameFieldId];

                memberName = getReadableDataForCustomField(memberName, nameField, member.id, 'member');

                workflowAffiliatedName = memberName;
                locationName = getReadableLocationFromName(member.location);

                try {
                    locationPath = getReadableLocationChainFromId(member.location);
                } catch {
                    locationPath = '-';
                }
            }
        } else if (workflowType.affiliation === 'group') {
            const group = groupsData.byId[workflow.affiliatedEntity];
            if (group) {
                const groupType = groupsData.types.byId[group.type];
                let groupName = group.customFields[groupType.nameFieldId];

                const nameField = groupsData.types.customFields.byId[groupType.nameFieldId];

                groupName = getReadableDataForCustomField(groupName, nameField, group.id, 'group');

                workflowAffiliatedName = groupName;
                locationName = getReadableLocationFromName(group.location);

                try {
                    locationPath = getReadableLocationChainFromId(group.location);
                } catch {
                    locationPath = '-';
                }
            }
        } else if (workflowType.affiliation === 'none') {
            workflowAffiliatedName = translatePhrase('Not Applicable');
        }

        let details = '-';

        if (workflowType.subTitleFieldId) {
            try {
                const subTitleField = workflowTypesData.customFields.byId[workflowType.subTitleFieldId];
                const detailsValue = workflow.history[workflow.historyIndex].customFields[workflowType.subTitleFieldId];

                // Only allow fields that are not member-affiliated in group workflows
                if (Array.isArray(detailsValue) || typeof detailsValue !== 'object') {
                    details = getReadableDataForCustomField(detailsValue, subTitleField, workflow.id, 'workflow')
                }
            } catch (e) {
                details = workflowAffiliatedName;
            }
        } else {
            details = workflowAffiliatedName;
        }

        const dueDateString = workflow.dueDate ? moment(workflow.dueDate).format('DD MMM YYYY') : '-';

        const isWorkflowModifiable = props.myPermissions.workflows[workflowType.id] !== Permissions.READ || workflow.user === props.myId;

        const isWorkflowCompleted = workflowStatus.isTerminal;
        const isWorkflowOverdue = !workflowStatus.isTerminal && workflowDueDate < today;

        let workflowDueStyles = styles.due;
        let statusColor = "#0066CC";

        if (isWorkflowCompleted) {
            workflowDueStyles = styles.completed;
            statusColor = "green"
        }
        if (isWorkflowOverdue) {
            workflowDueStyles = styles.overdue;
            statusColor = "#ea7a7a"
        }

        const dueDateElement = <div className={styles.dueDateMainElementHolder}>
            <div className={workflowDueStyles}>
                <Button
                    isDisabled={!isWorkflowModifiable || workflowStatus.isTerminal || props.isOnline}
                    isDanger={!workflowStatus.isTerminal && workflowDueDate < today}
                    isHighlighted={props.selectedNudge === NudgeType.WORKFLOWS_LIST_UPDATE_DUE_DATE}
                    title={translatePhrase('Update due date')}
                    text={dueDateString}
                    padding={'0px 10px'}
                    isRounded
                    type={'secondary'}
                    size={'small'}
                    onClick={() => showCalendar(workflow.id)} />
            </div>

            {isWorkflowModifiable && isShowingCalendar && workflow.id === selectedWorkflow && <div className={styles.dateDropdown}>
                <div className={styles.triangle}> ▲ </div>
                <DateInput expandCalendar={true} default={dueDateString ? new Date(moment(dueDateString).format()) : undefined} onChange={value => handleDueDateUpdate(workflow.id, value)} />
            </div>}
        </div>

        const workflowStatusMarkup = <span style={{ color: `${statusColor}` }}>{translatePhrase(workflowStatus.name)}</span>;

        let isOutDatedWorkflow = false;
        if (workflow.startPieceId) {
            const startPiece = workflowType.startPiece;
            const betaStartPiece = workflowType.betaStartPiece;
            const startPieceId = workflow.startPieceId;
            isOutDatedWorkflow = startPieceId !== startPiece.piece && startPieceId !== betaStartPiece.piece;
        }

        const workflowTypeName =
            <div className={styles.workflowNameElement}>
                <Tippy className="my-tippy" content={<span> {translatePhrase("Show Timeline")} </span>}>
                    <Link to={'/workflow-data/' + workflow.id} className="workflowTypeName">{translatePhrase(workflowType.name)}</Link>
                </Tippy>
                {/* {isOutDatedWorkflow && 
                <span className={styles.outdatedTag}>
                    {translatePhrase("Outdated")} 
                </span>
            } */}
            </div>;
        const cells: Array<string | number | JSX.Element> = [
            (props.pageNumber - 1) * props.pageSize + index + 1, // Sl. no
            workflowTypeName, // Type
            details, // Details
            workflowStatusMarkup, // Status
            dueDateElement, // Due Date
            workflowLastWorkedOn, // Last worked on
            userName, // User
            workflowAffiliatedName, // Affiliated with
        ];

        if (props.workflowFilters.types.length === 1) {
            const workflowType = workflowTypesData.byId[props.workflowFilters.types[0]];
            workflowType.customFields.forEach(customFieldId => {
                const customField = customFieldsData.byId[customFieldId];

                if (!customField.isInTable) {
                    return;
                }

                if (workflowType.affiliation === 'group' && customField.affiliation === 'member') {

                    try {
                        let customFieldValue = workflow.history[workflow.historyIndex].customFields[customFieldId];
                        const group = props.applicationState.groups.byId[workflow.affiliatedEntity];

                        const allValues = group.members.map(memberId => {
                            const member = props.applicationState.members.byId[memberId];

                            const memberType = props.applicationState.members.types.byId[member.type];
                            const nameField = props.applicationState.members.types.customFields.byId[memberType.nameFieldId];

                            const memberName = getReadableDataForCustomField(member.customFields[memberType.nameFieldId], nameField, member.id, 'member');

                            let cellValue = memberName + ': ';
                            if (typeof customFieldValue === 'object' && !Array.isArray(customFieldValue)) {
                                const customFieldIndividualValue = customFieldValue[memberId];
                                cellValue += getReadableDataForCustomField(customFieldIndividualValue, customField, workflow.id, 'workflow');
                            }

                            return cellValue;
                        });

                        cells.push(allValues.filter(value => !!value).join(', '));
                    } catch (e) {
                        cells.push('-')
                    }

                } else {
                    try {
                        let customFieldValue = workflow.history[workflow.historyIndex].customFields[customFieldId];

                        let cellValue = getReadableDataForCustomField(customFieldValue as CustomFieldValueType, customField, workflow.id, 'workflow');

                        if (customField.type === FieldType.FILE && cellValue.startsWith('http')) {
                            cells.push(<a className={styles.fileLink} target="_blank" rel="noreferrer noopener" href={cellValue + '?token=' + props.token}>Link</a>);
                        } else {
                            cells.push(cellValue);
                        }

                    } catch (e) {
                        cells.push('-')
                    }
                }

            });
        }

        // let usersList: Array<JSX.Element> = [];

        if (!props.isOnline && (!isUUID(props.myId) || workflow.user === props.myId) && !workflowStatus.isTerminal) {

            let completionPercentage: number | undefined = 0;
            
            try {
                const lastComputedPieceId = workflow.history[workflow.historyIndex].lastComputedPiece;

                if (lastComputedPieceId && workflow.type in completionPercentages && lastComputedPieceId in completionPercentages[workflow.type]) {
                    completionPercentage = completionPercentages[workflow.type][lastComputedPieceId];

                    if (completionPercentage) {
                        completionPercentage = Math.round(completionPercentage / 5) * 5;
                    }
                }
            } catch (e) {
                console.error(e);
            }

            cells.push(<div>
                <Tippy className="my-tippy" content={<span> {translatePhrase('Resume')} </span>}>
                    <button className={props.selectedNudge === NudgeType.WORKFLOWS_LIST_RESUME ? styles.highlightedWorkflowButton : styles.workflowButton} onClick={initiateOrResumeWorkflow.bind(this, workflow.id)}>
                        <div className={styles.completionLevel} style={{ height: completionPercentage + '%' }}></div> <RefreshIcon />
                        <span> {completionPercentage.toString() + '%'} </span> </button>
                </Tippy>
            </div>);
        } else {

            let completionPercentage: number | undefined;

            try {
                if (workflow.history[workflow.historyIndex].lastComputedPiece) {
                    completionPercentage = completionPercentageOfWorkflow(workflow.id);

                    if (completionPercentage === 0) {
                        completionPercentage = 1;
                    }

                    if (!workflowStatus.isTerminal && completionPercentage > 96) {
                        completionPercentage = 96;
                    }
                } else if (workflowStatus.isTerminal){
                    completionPercentage = 100;
                }
            } catch (e) {
                console.error(e);
            }

            cells.push(<div> 
                <Link to={'/workflow-data/' + workflow.id}>
                    <Button title={translatePhrase('View')} size={'small'} isRounded icon={<ViewIcon />} type={'secondary'} />
                </Link>
                {completionPercentage && completionPercentage < 100 && <span> {completionPercentage.toFixed(2) + '%'} </span>} </div>);
        }

        let shareText = '';

        if (workflowStatusesData.byId[workflow.status].isTerminal) {
            shareText = `Type: ${workflowType.name}\nStatus: ${workflowStatus.name}\nAffiliated with: ${workflowAffiliatedName}`;
        } else {
            shareText = `Type: ${workflowType.name}\nStatus: ${workflowStatus.name}\nDue date: ${workflow.dueDate}\nAffiliated with: ${workflowAffiliatedName}`;
        }


        let isDeleteAllowed = true;

        if (workflow.archived && props.myId !== 'SuperUser') {
            isDeleteAllowed = false;
        } else if (props.myPermissions.workflows[workflowType.id] && props.myPermissions.workflows[workflowType.id] !== Permissions.WRITE) {
            isDeleteAllowed = false;
        }

        return {
            id: workflow.id,
            entries: cells,
            isDeleted: workflow.archived,
            shareText,
            isDeleteAllowed: props.myPermissions.workflows[workflowType.id] !== Permissions.READ,
            showViewInsteadOfEdit: true,
            viewLink: '/workflow-data/' + workflow.id,
        }
    });


    let workflowTypesToAdd = false;

    const allowedMemberTypes = workflowTypesData.allEntries
        .filter(typeId => {
            const workflowType = workflowTypesData.byId[typeId];
            const loggedInUser = props.myId ? usersData.byId[props.myId] : undefined;

            if (loggedInUser) {
                return loggedInUser.projects.includes(workflowType.project);
            } else {
                return true;
            }
        })

    for (const workflowTypeId of allowedMemberTypes) {

        if (props.myPermissions.workflows[workflowTypeId] === Permissions.WRITE || typeof props.myPermissions.workflows[workflowTypeId] === 'undefined') {
            workflowTypesToAdd = true;
        }
    }

    const overDueToggleElement = <div className={styles.overdueToggle}>
        <span>Overdue: </span>
        <Toggle isHighlighted={props.selectedNudge === NudgeType.WORKFLOWS_OVERDUE_TOGGLE} toggleId="overdue-toggle" isOn={isShowingOverdue} onToggle={toggleIsShowingOverdue} />
    </div>;

    const extraHeaderElements = [overDueToggleElement];

    const exportLink = props.isOnline ? BASE_URL + '/online-workflow-export/?token=' + props.token : undefined;

    return (
        <div>
            {workflowTransferConfirmation && workflowTransferConfirmation.show &&
                <AlertModal alertHeader={workflowTransferConfirmation.header}
                    alertSentence={workflowTransferConfirmation.sentence} isConfirmation={true}
                    confirmApproval={transferAction} />}

            <TableWithMeta
                entityType="Workflow"
                headings={headings}
                entries={entries}

                extraHeaderElements={extraHeaderElements}

                sortedColumn={props.workflowSort.column}
                sortType={props.workflowSort.order}
                isReadOnly={!!props.isReadOnly}
                areFiltersExpanded={props.areFiltersExpanded}
                tags={props.tags}

                exportLink={exportLink}

                totalPages={props.totalPages}
                totalNoOfEntries={props.totalNoOfWorkflows}
                pageNumber={props.pageNumber}
                searchTerm={props.searchTerm}
                placeHolderForSearch="Search within the list below"
                isAddAllowed={workflowTypesToAdd && props.totalNoOfWorkflows < MAX_NO_OF_WORKFLOWS}
                isShowingAddForm={isShowingAddForm}
                isShowingModifyForm={isShowingModifyForm}
                addForm={<WorkflowModify submit={addWorkflow} cancel={hideWorkflowForm} />}

                isShowingFilterForm={isShowingFilterForm}
                showFilterForm={showFilterForm}
                isShowingTransferForm={props.bulkActions && entries.length > 0 ? isShowingTransferForm : undefined}
                showTransferForm={props.bulkActions && entries.length > 0 ? showTransferForm : undefined}
                filterForm={
                    <WorkflowFilter
                        tags={props.tags}
                        closeFilter={hideFilterForm}
                        workflowFilters={props.workflowFilters}
                        getSmartFilter={getSmartFilter}
                    />}
                transferForm={props.bulkActions && entries.length > 0 ? <WorkflowsTransfer closeTransferForm={hideTransferForm} workflowIds={props.actionableWorkflowIds} /> : undefined}

                transferSearch={updateSearchString}
                onSort={handleSort}
                onDelete={deleteWorkflow}
                onUnArchive={unArchiveWorkflow}
                showAddForm={showAddForm}
                showModifyForm={showModifyForm}
                reindexTable={props.isSuperUser && !props.isPartialFetch ? recomputeWorkflowComputedFields : undefined}
                archiveEntities={props.bulkActions && entries.length > 0 ? () => deleteAllWorkflows() : undefined}

                search={props.searchTable}
                changePageSize={props.changePageSize}
                goToPage={props.goToPage}
                sort={props.sort}
                handleCSVForm={handleCSVForm}
                isShowingCSVForm={props.isShowingCSVForm}

                isAddHighlighted={props.selectedNudge === NudgeType.WORKFLOWS_ADD_WORKFLOW}
                isFilterHighlighted={props.selectedNudge === NudgeType.WORKFLOWS_FILTER}
                isSearchHighlighted={props.selectedNudge === NudgeType.WORKFLOWS_SEARCH}
                isImportExportHighlighted={props.selectedNudge === NudgeType.WORKFLOWS_EXPORT}
                isShowMoreHighlighted={props.selectedNudge === NudgeType.WORKFLOWS_LIST_SHOW_MORE}
                selectedSmartFilter={selectedSmartFilter}
            />

            {props.isShowingCSVForm && <WorkflowCSV closeCSV={handleCSVForm} />}

            {loaderToast}
        </div>
    );
}

const WorkflowsTable = connect(mapStateToProps, mapDispatchToProps)(ConnectedWorkflowsTable);

export default WorkflowsTable;