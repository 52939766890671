import React, { Component, ChangeEvent } from 'react';
import styles from './Locations.module.scss';
import { Redirect } from "react-router-dom";

import { selectProject, unSelectProject } from '../../../shared/store/structure/project/actions';
import { selectLocation, unSelectLocation, addLocation, updateLocation } from '../../../shared/store/structure/location/actions';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/types';
import ProjectsList from '../hierarchy/ProjectsList';
import LocationsList from './LocationsList';
import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
import uuid from 'uuid';
import { ReactComponent as ExportIcon } from '../../../assets/new-custom-icons/common/export.svg';
import { ReactComponent as ImportIcon } from '../../../assets/new-custom-icons/common/import.svg';
import { ReactComponent as CancelIcon } from '../../../common/assets/close.svg';
import { INewLocationData, IUpdateableLocationData, ILocation, LocationState } from '../../../shared/store/structure/location/types';
import { getCustomFieldValueForInput, getReadableDataForCustomField, exampleValueForCustomField } from '../../../shared/store/custom-fields';
import store from '../../../shared/store/main';
import { translatePhrase } from '../../../shared/helpers/translation';
import { getAncestorChainOfLocation } from '../../../shared/helpers/locations';
import { isUUID } from '../../../shared/helpers/utilities';
import { setToastMessage, clearToastMessage, freezeBackground, unFreezeBackground } from '../../../shared/store/my-data/actions';
import LoaderModal from '../../../widgets/loader/LoaderModal';
import { NudgeType } from '../../../shared/store/my-data/types';
import { FieldType } from '../../../shared/store/custom-fields/types';
import { saveAs } from 'file-saver';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const selectedProjectId = state.structure.projects.selected,
        selectedLocationIds = state.structure.locations.selected;

    const allLevelsForProject = selectedProjectId ? state.structure.projects.byId[selectedProjectId].children.map(levelId => state.structure.levels.byId[levelId]) : [];

    return {
        isReadable: true,
        selectedProject: selectedProjectId ? state.structure.projects.byId[selectedProjectId] : undefined,
        levelsData: state.structure.levels,
        locationsData: state.structure.locations,
        selectedLocations: selectedLocationIds,
        allLevelsForProject,
        noOfLevelsInProject: selectedProjectId ? state.structure.projects.byId[selectedProjectId].children.length : 0,
        isSuperUser: !isUUID(state.myData.id),
        selectedNudge: state.myData.selectedNudgeId,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        selectProject: (id: string) => dispatch(selectProject(id)),
        unSelectProject: () => dispatch(unSelectProject()),

        selectLocation: (id: string, index: number) => dispatch(selectLocation(id, index)),
        unSelectLocation: (index: number) => dispatch(unSelectLocation(index)),
        addLocation: (payload: INewLocationData, parentId: string) => dispatch(addLocation(payload, parentId)),
        updateLocation: (payload: IUpdateableLocationData) => dispatch(updateLocation(payload)),

        setToastMessage: (message: string, persistMessage: boolean) => dispatch(setToastMessage(message, persistMessage)),
        clearToastMessage: () => dispatch(clearToastMessage()),

        freezeBackground: () => dispatch(freezeBackground()),
        unFreezeBackground: () => dispatch(unFreezeBackground()),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
    exportData: Array<Array<string>>,
    erroneousData: Array<Array<string>>,
    showLoader: boolean,
    loaderText: Array<string>,
    successLoader: boolean
}

class ConnectedLocations extends Component<Props, OwnState> {
    state = {
        exportData: [],
        erroneousData: [],
        showLoader: false,
        loaderText: [],
        successLoader: false
    };

    onSelectLocation = (locationIndex: number, id: string) => {
        this.props.selectLocation(id, locationIndex - 1);
    }

    onUnSelectLocation = (locationIndex: number) => {
        this.props.unSelectLocation(locationIndex - 1);
    }

    importRow = (locationData: Array<string>) => {
        const selectedProject = this.props.selectedProject;

        if (!selectedProject) {
            throw new Error('There must be a project selected');
        }

        let columnIndex = 0;
        let rawImportInput: string;
        let parentLocation: ILocation | undefined;
        let locationStateData = store.getState().structure.locations;

        // Given a list of sibling IDs and a location, return the data of the location which has the given name within that list of siblings
        function getLocationFromName(siblingLocationIds: Array<string>, locationName: string, locationStateData: LocationState) {
            const currentLocationId = siblingLocationIds.find(locationId => locationId in locationStateData.byId && locationStateData.byId[locationId].name === locationName);

            return typeof currentLocationId !== 'undefined' ? locationStateData.byId[currentLocationId] : undefined;
        }


        // Loop through each level
        for (const levelId of selectedProject.children) {
            const level = this.props.levelsData.byId[levelId];

            if (locationData.length <= columnIndex) {
                throw new Error(`This row does not have data for this level': ${level.name}`);
            }

            rawImportInput = locationData[columnIndex];
            let currentLocation: ILocation | undefined;

            // Get current location data
            if (columnIndex === 0) {
                currentLocation = getLocationFromName(locationStateData.byProject[selectedProject.id], rawImportInput, locationStateData);
            } else {
                if (typeof parentLocation === 'undefined') {
                    throw new Error('The parent location must be defined for lower level locations');
                }

                currentLocation = getLocationFromName(parentLocation.children, rawImportInput, locationStateData);
            }

            // If the current location does not exist, prepare the new location data.
            if (typeof currentLocation === 'undefined') {
                const newLocationId = uuid.v4();
                const newLocationData: IUpdateableLocationData = {
                    id: newLocationId,
                    name: rawImportInput,
                    customFields: {},
                };

                // Loop through the custom fields, and store the value in the new Location Data
                for (const customFieldId of level.customFields) {
                    const customField = this.props.levelsData.customFields.byId[customFieldId];

                    if (!customField.isComputed) {
                        columnIndex += 1;

                        if (locationData.length <= columnIndex) {
                            throw new Error(`This row does not have data for this custom field: ${customField.name}`);
                        }

                        rawImportInput = locationData[columnIndex];

                        // Custom field values of the new location is stored
                        const customFieldValue = getCustomFieldValueForInput(customField, rawImportInput, this.props.levelsData.customFieldOptions);
                        newLocationData.customFields[customField.id] = customFieldValue;
                    }

                }

                const parentId = typeof parentLocation === 'undefined' ? selectedProject.id : parentLocation.id;

                this.props.addLocation(newLocationData, parentId);
                locationStateData = store.getState().structure.locations;
                currentLocation = locationStateData.byId[newLocationId];
            } else {

                // Loop through the custom fields, and store the value in the new Location Data
                for (const customFieldId of level.customFields) {
                    const customField = this.props.levelsData.customFields.byId[customFieldId];

                    if (!customField.isComputed) {
                        columnIndex += 1;

                        if (locationData.length <= columnIndex) {
                            throw new Error(`This row does not have data for this custom field: ${customField.name}`);
                        }

                        rawImportInput = locationData[columnIndex];

                        // Custom field values of the new location is stored
                        const customFieldValue = getCustomFieldValueForInput(customField, rawImportInput, this.props.levelsData.customFieldOptions);
                        currentLocation.customFields[customField.id] = customFieldValue;
                    }

                }

                this.props.updateLocation(currentLocation);
            }

            columnIndex += 1;
            parentLocation = currentLocation;
        }

    }

    importLocations = (importData: Array<Array<string>>) => {

        const erroneousData: Array<Array<string>> = []

        if (!this.props.selectedProject) {
            throw new Error('There must be a project selected');
        }

        const errorFileHeading = this.getImportFileHeader().concat(['Errors']);
        const noOfColumns = errorFileHeading.length - 1;
        erroneousData.push(errorFileHeading);

        const dataWithoutHeader = importData.slice(1);

        for (const rowData of dataWithoutHeader) {
            if (rowData.length > 1 || (rowData.length === 1 && rowData[0].trim() !== '')) {
                try {
                    this.importRow(rowData);
                } catch (e) {
                    if (e instanceof Error) {
                        const erroneousRow = Array(noOfColumns).fill('').map((entry, index) => {
                            if (index < rowData.length) {
                                return rowData[index];
                            } else {
                                return '';
                            }
                        });
                        erroneousRow.push(e.message);
                        erroneousData.push(erroneousRow);
                    }
                }
            }
        }

        this.setState({
            erroneousData,
        });

    }

    handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        const file = !!e.target.files ? e.target.files[0] : undefined;

        if (!!file) {
            this.setState({ showLoader: true });
            this.setState({ loaderText: [translatePhrase('Compressing the file(s)') + '...', translatePhrase('Uploading') + '...'] });

            Papa.parse(file, {
                complete: (results) => {
                    const csvData = results.data as Array<Array<string>>;

                    if (csvData[csvData.length - 1].length === 1 && !csvData[csvData.length - 1][0]) {
                        csvData.pop();
                    }


                    // this.props.setToastMessage('Importing', true);                    

                    window.setTimeout(() => {
                        this.importLocations(csvData);

                        this.props.clearToastMessage();

                        this.setState({ showLoader: false });
                        this.setState({ loaderText: [] });

                        if (this.state.erroneousData.length <= 1) {
                            this.setState({ loaderText: [`Import complete - ${csvData.length - this.state.erroneousData.length} location(s) created/updated`] });
                            // this.props.setToastMessage(`Import complete - ${csvData.length - this.state.erroneousData.length} location(s) created/updated`, false);
                        }
                    }, 2000);
                }
            });
        }
    }

    getExportFileHeader = () => {
        let templateMarkup: Array<string> = [];

        if (this.props.selectedProject) {

            for (const levelId of this.props.selectedProject.children) {
                const level = this.props.levelsData.byId[levelId];

                templateMarkup.push(`Name of ${level.name}`);

                for (const customFieldId of level.customFields) {
                    const customField = this.props.levelsData.customFields.byId[customFieldId];
                    templateMarkup.push(customField.name);

                }
            }

        }

        return templateMarkup;

    }

    getImportFileHeader = () => {
        let templateMarkup: Array<string> = [];

        if (this.props.selectedProject) {

            for (const levelId of this.props.selectedProject.children) {
                const level = this.props.levelsData.byId[levelId];

                templateMarkup.push(`Name of ${level.name}`);

                for (const customFieldId of level.customFields) {
                    const customField = this.props.levelsData.customFields.byId[customFieldId];

                    if (!customField.isComputed) {
                        templateMarkup.push(customField.name);
                    }

                }
            }

        }

        return templateMarkup;

    }

    getImportFileSampleRow = () => {
        let templateMarkup: Array<string> = [];

        if (this.props.selectedProject) {

            for (const levelId of this.props.selectedProject.children) {
                const level = this.props.levelsData.byId[levelId];

                templateMarkup.push(`Location for ${level.name}`);

                const importableCustomFields = level.customFields.filter(customFieldId => {
                    const field = this.props.levelsData.customFields.byId[customFieldId];;
                    return field.type !== FieldType.FREE_TEXT;
                });

                for (const customFieldId of importableCustomFields) {
                    const customField = this.props.levelsData.customFields.byId[customFieldId];

                    if (!customField.isComputed) {
                        templateMarkup.push(exampleValueForCustomField(customField, this.props.levelsData.customFieldOptions));
                    }

                }
            }

        }

        return templateMarkup;

    }

    dismissErrorMessage = () => {
        this.setState({
            erroneousData: [],
        });
    }

    getLocationData = () => {
        const locationsData: Array<Array<string>> = [];

        if (!this.props.selectedProject) {
            return locationsData;
        }

        for (const locationId of this.props.locationsData.allEntries) {
            const location = this.props.locationsData.byId[locationId];

            if (location.children.length === 0) {
                const locationRow: Array<string> = []
                let levelIndex = 0;
                const ancestorIds = getAncestorChainOfLocation(location.id).reverse();
                ancestorIds.push(location.id);

                const isAncestorArchived = ancestorIds.some(ancestorId => this.props.locationsData.byId[ancestorId].archived);
                const isLocationInProject = this.props.selectedProject.locations.includes(ancestorIds[0]);

                if (isLocationInProject && !isAncestorArchived) {

                    for (const ancestorId of ancestorIds) {
                        const ancestorLocation = this.props.locationsData.byId[ancestorId];
                        const levelId = this.props.selectedProject.children[levelIndex];
                        const level = this.props.levelsData.byId[levelId];

                        locationRow.push(ancestorLocation.name);

                        for (const customFieldId of level.customFields) {
                            const customField = this.props.levelsData.customFields.byId[customFieldId];
                            const readableValue = getReadableDataForCustomField(ancestorLocation.customFields[customFieldId], customField, ancestorId, 'level');

                            if (customField.type === FieldType.FREE_TEXT) {
                                let dataToPush = readableValue === '-' ? 'Not Available' : 'Available';
                                locationRow.push(dataToPush);
                            } else if (customField.type === FieldType.FILE) {
                                let fileValue = readableValue;
                                if (fileValue.startsWith('http') && fileValue.includes('diceflow.in')) {
                                    const fileUrlComponents = fileValue.split('/');
                                    const orgCode = fileUrlComponents[4];
                                    const fileName = fileUrlComponents[5];
                                    fileValue = `${window.location.origin}/file-download/${encodeURIComponent(orgCode)}${encodeURIComponent('/')}${encodeURIComponent(fileName)}`;
                                } else if (/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/.test(fileValue)) {
                                    fileValue = 'File data not synced';
                                }
                                locationRow.push(fileValue);
                            } else {
                                locationRow.push(readableValue);
                            }
                        }

                        levelIndex += 1;
                    }

                    locationsData.push(locationRow);

                }
            }
        };

        return locationsData;
    }

    getLocationExportData = () => {
        if (!this.props.selectedProject) {
            return;
        };

        this.setState({
            showLoader: true,
            loaderText: [translatePhrase('Preparing export') + '...'],
        });

        const headers = this.getExportFileHeader();
        const sampleRow = this.getImportFileSampleRow();
        const data = this.getLocationData();

        const exportData = [headers, sampleRow, ...data];

        const csvString = Papa.unparse(exportData);
        const fileBlob = new Blob([csvString], { type: 'text/csv' });

        saveAs(fileBlob, `${this.props.selectedProject.name} Export.csv`);

        this.setState({
            showLoader: false,
            successLoader: true,
            loaderText: [translatePhrase('Export ready')],
        });

        window.setTimeout(() => {
            this.setState({
                successLoader: false,
                loaderText: [],
            });
        }, 3000);

    }

    onImport = () => {
        return this.handleFileUpload
    }

    render() {
        if (!this.props.isReadable) {
            return <Redirect to="/dashboard" />;
        }

        let locationsMarkup: Array<JSX.Element> = [];

        if (this.props.selectedProject && this.props.allLevelsForProject.length > 0) {

            const parentIds = [this.props.selectedProject.id].concat(this.props.selectedLocations.slice(0, this.props.noOfLevelsInProject - 1));

            locationsMarkup = parentIds.map((parentId, index) => {
                return <LocationsList
                    key={parentId}
                    heading={this.props.allLevelsForProject[index].name}
                    levelId={this.props.allLevelsForProject[index].id}
                    parentId={parentId}
                    onSelectCard={this.onSelectLocation.bind(this, index)}
                    onUnSelectCard={this.onUnSelectLocation.bind(this, index)}
                    selectedId={this.props.selectedLocations[index]}
                />;
            });

            locationsMarkup = locationsMarkup.slice(0, this.props.noOfLevelsInProject);

        }

        return (
            <div className={styles.innerFocusContainer}>
                <div className={styles.importExportButtonHolder + ' ignore-top-level-onclickoutside ignore-react-onclickoutside'}>
                    <input type="file" accept=".csv, text/csv" id="import-file-input" className={styles.hiddenFile} onChange={this.handleFileUpload} />
                    {this.props.isSuperUser && this.props.selectedProject && <label className={this.props.selectedNudge === NudgeType.LOCATIONS_IMPORT_LOCATIONS_FOR_PROJECT ? styles.highlighted : ''} htmlFor="import-file-input"> <ImportIcon /> {translatePhrase('Import')} </label>}
                    {this.props.selectedProject && <CSVLink data={this.state.exportData} onClick={this.getLocationExportData} filename={`${this.props.selectedProject.name} locations template.csv`}><button className={this.props.selectedNudge === NudgeType.LOCATIONS_DOWNLOAD_EXPORT_DATA || this.props.selectedNudge === NudgeType.LOCATIONS_DOWNLOAD_IMPORT_LOCATIONS_TEMPLATE ? styles.highlighted : ''}> <ExportIcon /> {translatePhrase('Export')} </button></CSVLink>}
                </div>

                <div className={styles.locationsHolder}>

                    {this.state.showLoader && <LoaderModal loaderText={this.state.loaderText} isIndeterminate={true} />}
                    {this.state.successLoader && <LoaderModal loaderText={this.state.loaderText} isSuccess={true} />}

                    <div className={styles.allProjects} onClick={this.props.unSelectProject}>
                        <ProjectsList
                            heading="Projects"
                            isSearchable
                            onSelectCard={this.props.selectProject}
                            isHighlightedImport={this.props.selectedNudge === NudgeType.LOCATIONS_IMPORT_LOCATIONS_FOR_PROJECT}
                            isHighlightedExport={this.props.selectedNudge === NudgeType.LOCATIONS_DOWNLOAD_EXPORT_DATA || this.props.selectedNudge === NudgeType.LOCATIONS_DOWNLOAD_IMPORT_LOCATIONS_TEMPLATE}
                            onImport={this.onImport}
                            onLocationExport={this.getLocationExportData}
                            onUnSelectCard={this.props.unSelectProject}
                            selectedId={this.props.selectedProject ? this.props.selectedProject.id : undefined}
                        />
                    </div>
                    {this.props.selectedProject &&
                        <div className={styles.cardsTree + ' ignore-react-onclickoutside'}>
                            {locationsMarkup}
                        </div>}

                    {this.state.erroneousData.length > 1 && <div className={styles.errorMessageHolder + ' ignore-react-onclickoutside'}>
                        {translatePhrase('Errors during import. Please download this file to fix errors and re-upload')}: <CSVLink data={this.state.erroneousData} filename={`Import errors.csv`}><span className={styles.highlight}>{translatePhrase('Error file')}</span></CSVLink>.

                        <div className={styles.dismissal} onClick={this.dismissErrorMessage} >
                            <CancelIcon />
                            <span>{translatePhrase('Dismiss')}</span>
                        </div>
                    </div>}

                </div>
            </div>);
    }
}

const Locations = connect(mapStateToProps, mapDispatchToProps)(ConnectedLocations);

export default Locations;