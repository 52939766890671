import React, { Component, ChangeEvent } from 'react';
import styles from './UserCSV.module.scss';

import InputText from '../../../widgets/form/InputText';

import { translatePhrase } from '../../../shared/helpers/translation';

import { ApplicationState } from '../../../shared/store/types';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { ReactComponent as TemplateIcon } from '../../../common/assets/table.svg';
import { ReactComponent as ImportIcon } from '../../../assets/new-custom-icons/common/import.svg';
import { ReactComponent as ExportCSVIcon } from "../../../assets/new-custom-icons/common/export.svg";
import { ReactComponent as CancelIcon } from '../../../common/assets/close.svg';
import { ReactComponent as ExportIcon } from '../../../common/assets/export.svg';
import chevronIcon from '../../../assets/chevron-arrow-down.svg';

import { CSVLink } from 'react-csv';
import Papa from 'papaparse';
import uuid from 'uuid';
import moment from 'moment';
import store from '../../../shared/store/main';

import { IProject } from '../../../shared/store/structure/project/types';
import { IRole } from '../../../shared/store/structure/role/types';
import { ILocation, LocationState } from '../../../shared/store/structure/location/types';
import { IUpdateableUserData } from '../../../shared/store/users/types';
import { addUser, setProjectTypeIdForCSVForm, setRoleTypeIdForCSVForm, updateUser } from '../../../shared/store/users/actions';
import { getCustomFieldValueForInput, getReadableDataForCustomField, exampleValueForCustomField } from '../../../shared/store/custom-fields';
import { getAncestorChainOfLocation, getLocationsAtLevel } from '../../../shared/helpers/locations';
import { isUUID } from '../../../shared/helpers/utilities';
import { setToastMessage, clearToastMessage, freezeBackground, unFreezeBackground } from '../../../shared/store/my-data/actions';
import { filterUsers } from '../../../shared/helpers/filters';
import LoaderModal from '../../../widgets/loader/LoaderModal';
import Button from '../../../widgets/button/CommonButton';
import { FieldType } from '../../../shared/store/custom-fields/types';
import { isWebUri } from 'valid-url';
import { saveAs } from 'file-saver';

type OwnProps = {
    closeCSV: () => void,
    isLimitReached?: boolean
};

const ID_COLUMN_VALUE = 'ID - DO NOT EDIT/DELETE THIS HEADER ROW';
const LEVEL_PREFIX = 'Level: ';
const CUSTOM_FIELD_PREFIX = 'Custom field: ';
const COMPUTED_FIELD_PREFIX = 'Custom field (read only): ';
interface ColumnMapping {
    id: number;
    isOnline?: number;
    project?: number;
    level?: number;
    role?: number;
    phone?: number;
    password?: number;
    language?: number;
    createdTime?: number;
    lastUpdatedTime?: number;

    levels: {
        [key: string]: number;
    };
    customFields: {
        [key: string]: number;
    };
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    return {
        projectsData: state.structure.projects,
        levelsData: state.structure.levels,
        rolesData: state.structure.roles,
        structureData: state.structure,
        userData: state.users,
        languageData: state.internationalization.languages,
        isSuperUser: !isUUID(state.myData.id),

        organizationPlan: state.organization.plan,
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        addUser: (payload: IUpdateableUserData) => dispatch(addUser(payload)),
        updateUser: (payload: IUpdateableUserData) => dispatch(updateUser(payload)),

        setToastMessage: (message: string, persistMessage: boolean) => dispatch(setToastMessage(message, persistMessage)),
        clearToastMessage: () => dispatch(clearToastMessage()),

        freezeBackground: () => dispatch(freezeBackground()),
        unFreezeBackground: () => dispatch(unFreezeBackground()),

        setProjectTypeIdForCSVForm: (id: string | undefined) => dispatch(setProjectTypeIdForCSVForm(id)),
        setRoleTypeIdForCSVForm: (id: string | undefined) => dispatch(setRoleTypeIdForCSVForm(id))
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;


type OwnState = {
    projectId: string | undefined,
    roleId: string | undefined,
    successMessage: string,
    importTemplateData: Array<Array<string>>,
    errorMessage: string,
    exportData: Array<Array<string>>,
    erroneousData: Array<Array<string>>,
    showLoader: boolean,
    loaderText: Array<string>
};

class ConnectedUserModify extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            projectId: props.projectsData.allEntries.length > 0 ? props.projectsData.allEntries[0] : undefined,
            roleId: undefined,
            successMessage: '',
            importTemplateData: [],
            errorMessage: '',
            exportData: [],
            erroneousData: [],
            showLoader: false,
            loaderText: []
        }
    }

    changeProject = (projectId: string | undefined) => {

        this.setState({
            projectId,
            exportData: [],
        });
        this.props.setProjectTypeIdForCSVForm(projectId)
    }

    changeRole = (roleId: string | undefined) => {

        this.setState({
            roleId,
            exportData: [],
        });
        this.props.setRoleTypeIdForCSVForm(roleId)
    }

    importRow = (userData: Array<string>, lastImportId: string | undefined, columnMapping: ColumnMapping) => {
        let userID = userData[columnMapping.id].trim();

        if (userID && !(userID in this.props.userData.byId)) {
            throw new Error('Unknown ID for user');
        }

        const existingUser = userID ? this.props.userData.byId[userID] : undefined;
        let isOnline = false;

        if (typeof columnMapping.isOnline !== 'undefined') {
            isOnline = userData[columnMapping.isOnline].toLowerCase() === 'yes';
        }

        let projectId: string | undefined;
        let levelId: string | undefined;
        let roleId: string | undefined;

        let currentLocation: ILocation | undefined;

        if (typeof columnMapping.project !== 'undefined') {
            const projectName = userData[columnMapping.project].trim();
            projectId = this.props.structureData.projects.allEntries.find(projectId => {
                return this.props.structureData.projects.byId[projectId].name === projectName;
            });
        }

        if (projectId && typeof columnMapping.level !== 'undefined') {
            const levelName = userData[columnMapping.level].trim();
            levelId = this.props.structureData.levels.allEntries.find(levelId => {
                const level = this.props.structureData.levels.byId[levelId];
                return level.name === levelName && level.project === projectId;
            });
        }

        if (projectId && levelId && typeof columnMapping.role !== 'undefined') {
            const roleName = userData[columnMapping.role].trim();
            roleId = this.props.structureData.roles.allEntries.find(roleId => {
                const role = this.props.structureData.roles.byId[roleId];
                return role.name === roleName && role.level === levelId;
            });
        }

        if (projectId && levelId) {
            const project = this.props.projectsData.byId[projectId];
            const level = this.props.levelsData.byId[levelId];

            const levelIndex = project.children.findIndex(levelId => levelId === level.id);

            if (levelIndex === -1) {
                throw new Error('Could not find level in project');
            }

            let rawImportInput = '';
            let parentLocation: ILocation | undefined;
            let locationStateData = store.getState().structure.locations;

            // Given a list of sibling IDs and a location, return the data of the location which has the given name within that list of siblings
            function getLocationFromName(siblingLocationIds: Array<string>, locationName: string, locationStateData: LocationState) {
                const currentLocationId = siblingLocationIds.find(locationId => locationStateData.byId[locationId].name.trim().toLocaleLowerCase() === locationName.trim().toLocaleLowerCase());

                return typeof currentLocationId !== 'undefined' ? locationStateData.byId[currentLocationId] : undefined;
            }

            const childLevelIds = project.children.slice(0, levelIndex + 1);

            // Loop through each level
            for (let i = 0; i < childLevelIds.length; i += 1) {
                const levelId = childLevelIds[i];
                const level = this.props.structureData.levels.byId[levelId];

                if (typeof columnMapping.levels[levelId] !== 'undefined') {
                    rawImportInput = userData[columnMapping.levels[levelId]].trim();
                }

                if (!rawImportInput) {
                    throw new Error('Could not get location data for level: ' + level.name);
                }

                // Get current location data
                if (i === 0) {
                    currentLocation = getLocationFromName(locationStateData.byProject[project.id], rawImportInput, locationStateData);
                } else {

                    if (typeof parentLocation === 'undefined') {
                        throw new Error('The parent location must be defined for lower level locations');
                    }

                    currentLocation = getLocationFromName(parentLocation.children, rawImportInput, locationStateData);
                }

                if (!currentLocation) {
                    throw new Error(`This location does not exist: ${rawImportInput}`);
                }

                parentLocation = currentLocation;
            }

            if (!currentLocation) {
                throw new Error('This user does not have a valid location');
            }
        }


        if (currentLocation && lastImportId) {
            // This ID is only defined when the previous user needs new locations to be added
            const lastUser = this.props.userData.byId[lastImportId];

            const newProjects = lastUser.projects;
            const newLevels = lastUser.levels;
            const newRoles = lastUser.roles;
            const newLocations = lastUser.locations;

            if (projectId && !newProjects.includes(projectId)) {
                newProjects.push(projectId);
            }

            if (levelId && !newLevels.includes(levelId)) {
                newLevels.push(levelId);
            }

            if (roleId && !newRoles.includes(roleId)) {
                newRoles.push(roleId);
            }

            if (!newLocations.includes(currentLocation.id)) {
                newLocations.push(currentLocation.id);
            }

            const updateUserData: IUpdateableUserData = {
                id: lastUser.id,
                projects: newProjects,
                levels: newLevels,
                roles: newRoles,
                locations: newLocations,
                isAppUser: true,
                isOnline,
                isBetaTester: false,
                phone: lastUser.phone,
                password: lastUser.password,
                language: lastUser.language,
                customFields: lastUser.customFields,
            };

            this.props.updateUser(updateUserData);
            return lastImportId;
        }

        const phone = typeof columnMapping.phone !== 'undefined' ? userData[columnMapping.phone].trim() : undefined;
        const password = (!!userID && userID in this.props.userData.byId) || typeof columnMapping.password === 'undefined' ? '' : userData[columnMapping.password].trim();
        const languageName = typeof columnMapping.language !== 'undefined' ? userData[columnMapping.language].trim() : undefined;
        const languageId = this.props.languageData.allEntries.find(languageId => this.props.languageData.byId[languageId].name === languageName);

        if (!existingUser) {
            if (typeof projectId === 'undefined') {
                throw new Error('The project is required');
            }

            if (typeof levelId === 'undefined') {
                throw new Error('The level is required');
            }

            if (typeof roleId === 'undefined') {
                throw new Error('The role is required');
            }

            if (typeof currentLocation === 'undefined') {
                throw new Error('The location is required');
            }

            if (typeof phone === 'undefined') {
                throw new Error('The phone is required');
            }

            if (typeof languageName === 'undefined') {
                throw new Error('The language is required');
            }
        }

        const newUserId = uuid.v4();
        const newUserData: IUpdateableUserData = {
            id: userID || newUserId,
            projects: projectId ? [projectId] : [],
            levels: levelId ? [levelId] : [],
            roles: roleId ? [roleId] : [],
            locations: currentLocation ? [currentLocation.id] : [],
            isAppUser: true,
            isOnline,
            isBetaTester: false,
            phone: {
                countryCode: phone ? phone.split(' ')[0] : '',
                number: phone ? phone.split(' ')[1] : '',
            },
            password,
            language: languageId || '0',
            customFields: {},
        }

        if (existingUser) {
            newUserData.projects = projectId ? newUserData.projects : existingUser.projects;
            newUserData.levels = levelId ? newUserData.levels : existingUser.levels;
            newUserData.roles = roleId ? newUserData.roles : existingUser.roles;
            newUserData.locations = currentLocation ? newUserData.locations : existingUser.locations;

            if (!newUserData.phone.countryCode || !newUserData.phone.number) {
                newUserData.phone = {
                    ...existingUser.phone,
                };
            }

            if (!newUserData.password) {
                newUserData.password = existingUser.password;
            }

            if (languageName?.toLocaleLowerCase() === 'english') {
                newUserData.language = '0';
            } else if (!languageId) {
                newUserData.language = existingUser.language;
            }
        }

        const updatingUserCustomFieldIds = this.props.userData.customFields.allFields.filter(customFieldId => {
            const customField = this.props.userData.customFields.byId[customFieldId];
            return Object.keys(columnMapping.customFields).includes(customFieldId) && !customField.isComputed;
        });

        // Loop through the custom fields, and store the value in the new Location Data
        for (const customFieldId of updatingUserCustomFieldIds) {
            const customField = this.props.userData.customFields.byId[customFieldId];
            let rawImportInput = userData[columnMapping.customFields[customFieldId]].trim();

            // Custom field values of the new location is stored
            const customFieldValue = getCustomFieldValueForInput(customField, rawImportInput, this.props.userData.customFieldOptions);
            newUserData.customFields[customField.id] = customFieldValue;

        }

        if (typeof roleId !== 'undefined') {
            const role = this.props.rolesData.byId[roleId];

            const updatingRoleCustomFieldIds = role.customFields.filter(customFieldId => {
                const customField = this.props.structureData.roles.customFields.byId[customFieldId];
                return Object.keys(columnMapping.customFields).includes(customFieldId) && !customField.isComputed;
            });

            for (const customFieldId of updatingRoleCustomFieldIds) {
                const customField = this.props.structureData.roles.customFields.byId[customFieldId];
                let rawImportInput = userData[columnMapping.customFields[customFieldId]].trim();

                rawImportInput = userData[columnMapping.customFields[customFieldId]].trim();

                // Custom field values of the new location is stored
                const customFieldValue = getCustomFieldValueForInput(customField, rawImportInput, this.props.structureData.roles.customFieldOptions);

                newUserData.customFields[customField.id] = customFieldValue;

            }
        }


        if (userID in this.props.userData.byId) {
            this.props.updateUser(newUserData);
        } else {
            const state = store.getState();

            let MAX_NO_OF_USERS = 10;

            if (this.props.organizationPlan === 'unlimited') {
                MAX_NO_OF_USERS = Infinity;
            }

            if (state.users.allEntries.length >= MAX_NO_OF_USERS) {
                throw new Error('Cannot add any more users - org limit reached');
            }

            for (const userId of state.users.allEntries) {
                const user = state.users.byId[userId];

                if (user.phone.countryCode === newUserData.phone.countryCode &&
                    user.phone.number === newUserData.phone.number) {
                    throw new Error('Cannot add this user - another user with this phone already exists');
                }
            }

            this.props.addUser(newUserData);
        }

        return newUserData.id;

    }

    areRowsMostlyEqual = (rowOne: Array<string>, rowTwo: Array<string>) => {
        let noOfMisMatches = 0;

        rowOne.forEach((value, index) => {
            if (value !== rowTwo[index]) {
                noOfMisMatches += 1;
            }
        });

        return noOfMisMatches < 2;
    }

    areUsersEqual = (rowOne: Array<string>, rowTwo: Array<string>, columnMapping: ColumnMapping) => {
        const idFieldIndex = columnMapping.id;
        const phoneFieldIndex = columnMapping.phone ? columnMapping.phone : rowOne.findIndex(field => field.startsWith('+91'));

        const userOneId = rowOne[idFieldIndex];
        const userTwoId = rowTwo[idFieldIndex];

        if (isUUID(userOneId) && isUUID(userTwoId)) {
            return userOneId === userTwoId;
        } else if (phoneFieldIndex >= 0) {
            return rowOne[phoneFieldIndex].trim() === rowTwo[phoneFieldIndex].trim();
        } else {
            throw Error('Neither the ID nor the phone number is present in the import');
        }

    }

    getLevelIdFromName = (levelName: string) => {
        let project: IProject;
        let role: IRole | undefined = undefined;

        if (typeof this.state.roleId !== 'undefined') {
            role = this.props.structureData.roles.byId[this.state.roleId];
            const level = this.props.structureData.levels.byId[role.level];
            project = this.props.structureData.projects.byId[level.project];
        } else if (typeof this.state.projectId !== 'undefined') {
            project = this.props.structureData.projects.byId[this.state.projectId];
        } else {
            // If both project and role are not defined, see how many projects there are

            if (this.props.structureData.projects.allEntries.length !== 1) {
                // If there is more than one project, or no projects, do nothing
                return;
            }

            project = this.props.structureData.projects.byId[this.props.structureData.projects.allEntries[0]];
        }

        const endLevelIndex = role ? project.children.indexOf(role.level) : project.children.length - 1;

        return project.children.slice(0, endLevelIndex + 1).find(levelId => {
            const level = this.props.structureData.levels.byId[levelId];
            return level.name.toLocaleLowerCase().trim() === levelName.toLocaleLowerCase().trim();
        });
    }

    getCustomFieldIdFromName = (customFieldName: string) => {
        let project: IProject;
        let role: IRole | undefined = undefined;

        if (typeof this.state.roleId !== 'undefined') {
            role = this.props.structureData.roles.byId[this.state.roleId];
            const level = this.props.structureData.levels.byId[role.level];
            project = this.props.structureData.projects.byId[level.project];
        } else if (typeof this.state.projectId !== 'undefined') {
            project = this.props.structureData.projects.byId[this.state.projectId];
        } else {
            // If both project and role are not defined, see how many projects there are

            if (this.props.structureData.projects.allEntries.length !== 1) {
                // If there is more than one project, or no projects, do nothing
                return;
            }

            project = this.props.structureData.projects.byId[this.props.structureData.projects.allEntries[0]];
        }

        return this.props.userData.customFields.allFields.concat(role ? role.customFields : [])
            .find(customFieldId => {
                const customField = customFieldId in this.props.userData.customFields.byId ? this.props.userData.customFields.byId[customFieldId] : this.props.rolesData.customFields.byId[customFieldId];
                return customField.name.toLocaleLowerCase().trim() === customFieldName.toLocaleLowerCase().trim();
            });
    }

    getColumnMappingForImport = (headingRow: Array<string>): ColumnMapping => {
        const columnMapping: ColumnMapping = {
            id: 0,
            levels: {},
            customFields: {},
        };

        for (let i = 0; i < headingRow.length; i += 1) {
            const headingCell = headingRow[i];

            const hardCodedHeadings = [
                ID_COLUMN_VALUE.toLocaleLowerCase(),
                'is online',
                'project',
                'level',
                'role',
                'phone',
                'password',
                'language',
                'created time',
                'last updated time',
            ];

            if (hardCodedHeadings.includes(headingCell.toLocaleLowerCase())) {
                switch (headingCell.toLocaleLowerCase()) {
                    case ID_COLUMN_VALUE.toLocaleLowerCase():
                        columnMapping.id = i;
                        break;
                    case 'is online':
                        columnMapping.isOnline = i;
                        break;
                    case 'project':
                        columnMapping.project = i;
                        break;
                    case 'level':
                        columnMapping.level = i;
                        break;
                    case 'role':
                        columnMapping.role = i;
                        break;
                    case 'phone':
                        columnMapping.phone = i;
                        break;
                    case 'password':
                        columnMapping.password = i;
                        break;
                    case 'language':
                        columnMapping.language = i;
                        break;
                    case 'created time':
                        columnMapping.createdTime = i;
                        break;
                    case 'last updated time':
                        columnMapping.lastUpdatedTime = i;
                        break;
                    default:
                        ;
                }

                continue;
            }

            const levelId = headingCell.startsWith(LEVEL_PREFIX) ? this.getLevelIdFromName(headingCell.substring(LEVEL_PREFIX.length)) : undefined;
            const customFieldId = headingCell.startsWith(CUSTOM_FIELD_PREFIX) ?
                this.getCustomFieldIdFromName(headingCell.substring(CUSTOM_FIELD_PREFIX.length)) :
                headingCell.startsWith(COMPUTED_FIELD_PREFIX) ?
                    this.getCustomFieldIdFromName(headingCell.substring(COMPUTED_FIELD_PREFIX.length)) :
                    undefined;

            if (levelId) {
                columnMapping.levels[levelId] = i;
                continue;
            } else if (customFieldId) {
                const userComputedField = this.props.userData.customFields.byId[customFieldId];

                if (userComputedField && !userComputedField.isComputed) {
                    columnMapping.customFields[customFieldId] = i;
                }

                const roleComputedField = this.props.rolesData.customFields.byId[customFieldId];

                if (roleComputedField && !roleComputedField.isComputed) {
                    columnMapping.customFields[customFieldId] = i;
                }

                continue;
            }

            if (headingCell.toLocaleLowerCase() === 'id' || headingCell.toLocaleLowerCase().startsWith('id ')) {
                columnMapping.id = i;
                continue;
            }

            throw new Error(`The heading ${headingCell} does not map to any known value`);
        }

        return columnMapping;
    }

    importUsers = (importData: Array<Array<string>>) => {

        const erroneousData: Array<Array<string>> = [];

        if (importData.length < 2) {
            return;
        }

        if (!importData[0].some(cellValue => cellValue.toLocaleLowerCase() === 'id' || cellValue.toLocaleLowerCase().startsWith('id '))) {
            this.setState({
                showLoader: true,
                errorMessage: translatePhrase('The ID column is required'),
            });

            return;
        }

        let columnMapping: ColumnMapping | undefined;

        try {
            columnMapping = this.getColumnMappingForImport(importData[0]);
        } catch (e: any) {
            this.setState({
                showLoader: true,
                errorMessage: e.message,
            });

            return;
        }

        const errorFileHeading = this.getImportFileHeader(columnMapping).concat(['Errors']);
        const noOfColumns = errorFileHeading.length - 1;
        erroneousData.push(errorFileHeading);

        const dataWithoutHeader = importData.slice(1);

        let lastRow: Array<string> | undefined;
        let lastImportId: string | undefined;
        let totalUsersImported = 0;

        for (const rowData of dataWithoutHeader) {
            if (rowData.length > 1 || (rowData.length === 1 && rowData[0].trim() !== '')) {
                const isDuplicateRowWithNewLocation = !!lastRow && this.areUsersEqual(rowData, lastRow, columnMapping);
                try {
                    lastImportId = this.importRow(rowData, isDuplicateRowWithNewLocation ? lastImportId : undefined, columnMapping);
                    if (!isDuplicateRowWithNewLocation) {
                        totalUsersImported += 1;
                    }
                } catch (e: any) {
                    const erroneousRow = Array(noOfColumns).fill('').map((entry, index) => {
                        if (index < rowData.length) {
                            return rowData[index];
                        } else {
                            return '';
                        }
                    });
                    erroneousRow.push(e.message);
                    erroneousData.push(erroneousRow);
                }

                lastRow = rowData;
            }
        }

        if (erroneousData.length > 1) {
            this.setState({
                errorMessage: 'There were errors during import',
                successMessage: '',
                erroneousData,
            });

            this.hideMessages();

        } else {
            this.setState({
                errorMessage: '',
                successMessage: `The import completed successfully! ${totalUsersImported} user(s) imported.`,
                erroneousData,
            });

            this.hideMessages();
        }
    }

    hideMessages = () => {
        setTimeout(() => {
            this.dismissSuccessMessage();
        }, 3000);
    }

    handleFileUpload = (e: ChangeEvent<HTMLInputElement>) => {
        const file = !!e.target.files ? e.target.files[0] : undefined;

        if (!!file) {
            this.setState({ showLoader: true });
            this.setState({ loaderText: [translatePhrase('Compressing the file(s)') + '...', translatePhrase('Uploading')] });

            Papa.parse(file, {
                complete: (results) => {
                    const csvData = results.data as Array<Array<string>>;

                    // this.props.setToastMessage('Importing', true);
                    // this.props.freezeBackground();

                    window.setTimeout(() => {
                        this.importUsers(csvData);

                        this.setState({ showLoader: false });
                        this.setState({ loaderText: [] });

                        // this.props.clearToastMessage();
                        // this.props.unFreezeBackground();
                    }, 2000);
                }
            });
        }
    }

    getUserDataToExport = () => {
        let project: IProject;
        let role: IRole | undefined = undefined;

        if (typeof this.state.roleId !== 'undefined') {
            role = this.props.structureData.roles.byId[this.state.roleId];
            const level = this.props.structureData.levels.byId[role.level];
            project = this.props.structureData.projects.byId[level.project];
        } else if (typeof this.state.projectId !== 'undefined') {
            project = this.props.structureData.projects.byId[this.state.projectId];
        } else {
            // If both project and role are not defined, see how many projects there are

            if (this.props.structureData.projects.allEntries.length !== 1) {
                // If there is more than one project, or no projects, do nothing
                return [];
            }

            project = this.props.structureData.projects.byId[this.props.structureData.projects.allEntries[0]];
        }

        const applicationState = store.getState();
        const qualifiedUserIds = filterUsers(applicationState).filter(user => {
            if (user.archived) {
                return false;
            }

            if (!user.projects.includes(project.id)) {
                return false;
            }

            if (!!role) {
                if (!user.roles.includes(role.id)) {
                    return false;
                }
            }

            return true;
        }).map(workflow => workflow.id);

        let exportData: Array<Array<string>> = [];
        qualifiedUserIds.forEach(userId => {
            const user = this.props.userData.byId[userId];
            const rowData: Array<string> = [];

            rowData.push(user.id);

            rowData.push(moment(user.createdTime).format('DD MMM YYYY hh:mm A'));
            rowData.push(moment(user.lastUpdatedTime).format('DD MMM YYYY hh:mm A'));
            rowData.push(user.isOnline ? 'Yes' : 'No');

            rowData.push('');  // Project
            rowData.push('');  // Level
            rowData.push('');  // Role

            const userLocationsIndex = rowData.length;
            // User locations will be input at the end of the logic. This is because there is a separate row in the export data for each location
            rowData.push('');

            // Fill up the location spaces with empty strings. These will be input at the very end. A user may have multiple locations, and each one will be a different row.
            for (let i = 0; i < project.children.length - 1; i += 1) {
                rowData.push('');
            }

            rowData.push(user.phone.countryCode + ' ' + user.phone.number);

            let languageName: string;

            if (user.language in this.props.languageData.byId) {
                languageName = this.props.languageData.byId[user.language].name;
            } else {
                languageName = 'English';
            }

            rowData.push(languageName);

            for (const customFieldId of this.props.userData.customFields.allFields) {
                const customField = this.props.userData.customFields.byId[customFieldId];

                if (customField.type === FieldType.FREE_TEXT) {
                    let dataToPush = getReadableDataForCustomField(user.customFields[customFieldId], customField, user.id, 'user') === '-' ? 'Not Available' : 'Available';
                    rowData.push(dataToPush);
                } else {
                    rowData.push(getReadableDataForCustomField(user.customFields[customFieldId], customField, user.id, 'user'));
                }

            }

            if (typeof role !== 'undefined') {
                for (const customFieldId of role.customFields) {
                    const customField = this.props.structureData.roles.customFields.byId[customFieldId];
                    const readableValue = getReadableDataForCustomField(user.customFields[customFieldId], customField, user.id, 'role');

                    if (customField.type === FieldType.FREE_TEXT) {
                        let dataToPush = readableValue === '-' ? 'Not Available' : 'Available';
                        rowData.push(dataToPush);
                    } else if (customField.type === FieldType.FILE) {
                        let fileValue = readableValue;
                        if (fileValue.startsWith('http') && fileValue.includes('diceflow.in')) {
                            const fileUrlComponents = fileValue.split('/');
                            const orgCode = fileUrlComponents[4];
                            const fileName = fileUrlComponents[5];
                            fileValue = `${window.location.origin}/file-download/${encodeURIComponent(orgCode)}${encodeURIComponent('/')}${encodeURIComponent(fileName)}`;
                        } else if (/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/.test(fileValue)) {
                            fileValue = 'File data not synced';
                        }
                        rowData.push(fileValue);
                    } else {
                        rowData.push(readableValue);
                    }

                }
            }

            user.locations.forEach(locationId => {
                const locationChain = getAncestorChainOfLocation(locationId).reverse();
                locationChain.push(locationId);
                const newRowForLocation = rowData.slice();

                const levelId = user.levels
                    .filter(levelId => levelId in applicationState.structure.levels.byId)
                    .find(levelId => getLocationsAtLevel(levelId).map(location => location.id).includes(locationId));

                if (levelId) {
                    const level = this.props.structureData.levels.byId[levelId];

                    if (level.project === this.state.projectId) {
                        const project = this.props.structureData.projects.byId[level.project];
                        const allowedRoles = level.children.filter(roleId => user.roles.includes(roleId));

                        newRowForLocation[4] = project.name;
                        newRowForLocation[5] = level.name;

                        for (let i = 0; i < locationChain.length; i += 1) {
                            const location = this.props.structureData.locations.byId[locationChain[i]];
                            newRowForLocation[userLocationsIndex + i] = location.name;
                        }

                        allowedRoles.forEach(roleId => {
                            const role = this.props.structureData.roles.byId[roleId];
                            const newRowForRole = newRowForLocation.slice();
                            newRowForRole[6] = role.name;

                            exportData.push(newRowForRole);
                        });
                    }
                }

            });
        });

        return exportData;
    }

    getExportFileHeader = () => {
        let templateMarkup: Array<string> = [];
        let project: IProject;
        let role: IRole | undefined = undefined;

        if (typeof this.state.roleId !== 'undefined') {
            role = this.props.structureData.roles.byId[this.state.roleId];
            const level = this.props.structureData.levels.byId[role.level];
            project = this.props.structureData.projects.byId[level.project];
        } else if (typeof this.state.projectId !== 'undefined') {
            project = this.props.structureData.projects.byId[this.state.projectId];
        } else {
            // If both project and role are not defined, see how many projects there are

            if (this.props.structureData.projects.allEntries.length !== 1) {
                // If there is more than one project, or no projects, do nothing
                return [];
            }

            project = this.props.structureData.projects.byId[this.props.structureData.projects.allEntries[0]];
        }

        templateMarkup.push(ID_COLUMN_VALUE);
        templateMarkup.push('Created time');
        templateMarkup.push('Last updated time');
        templateMarkup.push('Is Online');
        templateMarkup.push('Project');
        templateMarkup.push('Level');
        templateMarkup.push('Role');

        for (const levelId of project.children) {
            const level = this.props.structureData.levels.byId[levelId];

            templateMarkup.push(LEVEL_PREFIX + level.name);
        }

        templateMarkup.push('Phone');
        templateMarkup.push('Language');

        for (const customFieldId of this.props.userData.customFields.allFields) {
            const customField = this.props.userData.customFields.byId[customFieldId];

            if (customField.isComputed) {
                templateMarkup.push(COMPUTED_FIELD_PREFIX + customField.name);
            } else {
                templateMarkup.push(CUSTOM_FIELD_PREFIX + customField.name);
            }

        }

        if (typeof role !== 'undefined') {
            for (const customFieldId of role.customFields) {
                const customField = this.props.structureData.roles.customFields.byId[customFieldId];

                if (customField.isComputed) {
                    templateMarkup.push(COMPUTED_FIELD_PREFIX + customField.name);
                } else {
                    templateMarkup.push(CUSTOM_FIELD_PREFIX + customField.name);
                }

            }
        }

        return templateMarkup;

    }

    getImportFileHeader = (columnMapping?: ColumnMapping) => {
        let templateMarkup: Array<string> = [];
        let project: IProject;
        let role: IRole | undefined = undefined;

        if (typeof this.state.roleId !== 'undefined') {
            role = this.props.structureData.roles.byId[this.state.roleId];
            const level = this.props.structureData.levels.byId[role.level];
            project = this.props.structureData.projects.byId[level.project];
        } else if (typeof this.state.projectId !== 'undefined') {
            project = this.props.structureData.projects.byId[this.state.projectId];
        } else {
            // If both project and role are not defined, see how many projects there are

            if (this.props.structureData.projects.allEntries.length !== 1) {
                // If there is more than one project, or no projects, do nothing
                return [];
            }

            project = this.props.structureData.projects.byId[this.props.structureData.projects.allEntries[0]];
        }

        if (!columnMapping) {
            templateMarkup.push(ID_COLUMN_VALUE);
        } else if (typeof columnMapping.id === 'number') {
            templateMarkup[columnMapping.id] = ID_COLUMN_VALUE;
        }

        if (!columnMapping) {
            templateMarkup.push('Project');
        } else if (typeof columnMapping.project === 'number') {
            templateMarkup[columnMapping.project] = 'Project';
        }

        if (!columnMapping) {
            templateMarkup.push('Level');
        } else if (typeof columnMapping.level === 'number') {
            templateMarkup[columnMapping.level] = 'Level';
        }

        if (!columnMapping) {
            templateMarkup.push('Role');
        } else if (typeof columnMapping.role === 'number') {
            templateMarkup[columnMapping.role] = 'Role';
        }

        const endLevelIndex = role ? project.children.indexOf(role.level) : project.children.length - 1;

        for (const levelId of project.children.slice(0, endLevelIndex + 1)) {
            const level = this.props.structureData.levels.byId[levelId];

            if (!columnMapping) {
                templateMarkup.push(LEVEL_PREFIX + level.name);
            } else if (typeof columnMapping.levels[levelId] === 'number') {
                templateMarkup[columnMapping.levels[levelId]] = LEVEL_PREFIX + level.name;
            }
        }

        if (!columnMapping) {
            templateMarkup.push('Phone');
        } else if (typeof columnMapping.phone === 'number') {
            templateMarkup[columnMapping.phone] = 'Phone';
        }

        if (!columnMapping) {
            templateMarkup.push('Password');
        } else if (typeof columnMapping.password === 'number') {
            templateMarkup[columnMapping.password] = 'Password';
        }

        if (!columnMapping) {
            templateMarkup.push('Language');
        } else if (typeof columnMapping.language === 'number') {
            templateMarkup[columnMapping.language] = 'Language';
        }

        if (!columnMapping) {
            templateMarkup.push('Is Online');
        } else if (typeof columnMapping.isOnline === 'number') {
            templateMarkup[columnMapping.isOnline] = 'Is Online';
        }

        for (const customFieldId of this.props.userData.customFields.allFields) {
            const customField = this.props.userData.customFields.byId[customFieldId];

            if (!customField.isComputed) {
                if (!columnMapping) {
                    templateMarkup.push(CUSTOM_FIELD_PREFIX + customField.name);
                } else if (typeof columnMapping.customFields[customFieldId] === 'number') {
                    templateMarkup[columnMapping.customFields[customFieldId]] = CUSTOM_FIELD_PREFIX + customField.name;
                }
            }

        }

        if (typeof role !== 'undefined') {
            for (const customFieldId of role.customFields) {
                const customField = this.props.structureData.roles.customFields.byId[customFieldId];

                if (!customField.isComputed) {
                    if (!columnMapping) {
                        templateMarkup.push(CUSTOM_FIELD_PREFIX + customField.name);
                    } else if (typeof columnMapping.customFields[customFieldId] === 'number') {
                        templateMarkup[columnMapping.customFields[customFieldId]] = CUSTOM_FIELD_PREFIX + customField.name;
                    }
                }

            }
        }

        return templateMarkup;

    }

    getImportFileSampleRow = () => {
        let templateMarkup: Array<string> = [];
        let project: IProject;
        let role: IRole | undefined = undefined;

        if (typeof this.state.roleId !== 'undefined') {
            role = this.props.structureData.roles.byId[this.state.roleId];
            const level = this.props.structureData.levels.byId[role.level];
            project = this.props.structureData.projects.byId[level.project];
        } else if (typeof this.state.projectId !== 'undefined') {
            project = this.props.structureData.projects.byId[this.state.projectId];
        } else {
            // If both project and role are not defined, see how many projects there are

            if (this.props.structureData.projects.allEntries.length !== 1) {
                // If there is more than one project, or no projects, do nothing
                return [];
            }

            project = this.props.structureData.projects.byId[this.props.structureData.projects.allEntries[0]];
        }

        templateMarkup.push('Copy ID from export file for update; Leave BLANK for completely new values');
        templateMarkup.push(project.name);
        templateMarkup.push('Level name');
        templateMarkup.push('Role name');

        for (const levelId of project.children) {
            const level = this.props.structureData.levels.byId[levelId];

            templateMarkup.push(`Type ${level.name} as in system`);
        }

        templateMarkup.push('+91 9999999999');
        templateMarkup.push('pa$$w0rd');
        templateMarkup.push('English');
        templateMarkup.push('Yes or no');

        const importableUserCustomFields = this.props.userData.customFields.allFields.filter(customFieldId => {
            const field = this.props.userData.customFields.byId[customFieldId];;
            return field.type !== FieldType.FREE_TEXT;
        });

        for (const customFieldId of importableUserCustomFields) {
            const customField = this.props.userData.customFields.byId[customFieldId];

            if (!customField.isComputed) {
                templateMarkup.push(exampleValueForCustomField(customField, this.props.userData.customFieldOptions));
            }

        }

        if (typeof role !== 'undefined') {

            const importableRoleCustomFields = role.customFields.filter(customFieldId => {
                const field = this.props.structureData.roles.customFields.byId[customFieldId];;
                return field.type !== FieldType.FREE_TEXT;
            });

            for (const customFieldId of importableRoleCustomFields) {
                const customField = this.props.structureData.roles.customFields.byId[customFieldId];

                if (!customField.isComputed) {
                    templateMarkup.push(exampleValueForCustomField(customField, this.props.structureData.roles.customFieldOptions));
                }

            }
        }

        return templateMarkup;

    }

    collectDataToExport = () => {

        this.setState({ showLoader: true });

        this.setState({ loaderText: [translatePhrase('Preparing export') + '...'] });

        window.setTimeout(async () => {
            const data = this.getUserDataToExport();

            if (data.length > 0) {
                const projectName = `${this.state.projectId ? this.props.structureData.projects.byId[this.state.projectId].name : ''}`;
                const roleName = `${this.state.roleId ? this.props.structureData.roles.byId[this.state.roleId].name : ''}`;
                const fileName = roleName ? `${projectName.toLocaleLowerCase().split(' ').join('-')}-${roleName.toLocaleLowerCase().split(' ').join('-')}` : `${projectName.toLocaleLowerCase().split(' ').join('-')}-all-roles`;


                window.setTimeout(async () => {
                    const exportDataForUsers: Array<Array<string>> = [];
                    const headings = this.getExportFileHeader();
                    exportDataForUsers.push(headings);

                    for (let i = 0; i < data.length; i += 1) {
                        exportDataForUsers.push(data[i]);
                    }

                    const fileData = Papa.unparse(exportDataForUsers);
                    const fileBlob = new Blob([fileData], { type: 'application/csv' });

                    saveAs(fileBlob, `${fileName}.csv`);

                    this.hideMessages();

                    this.setState({
                        showLoader: false,
                        loaderText: [],
                        successMessage: translatePhrase('Export ready'),
                    });

                    window.setTimeout(() => {
                        this.dismissSuccessMessage();
                    }, 3000);
                }, 2000);
            } else {
                this.setState({
                    errorMessage: 'Oops. No data to export',
                });
            }
        }, 2000);
    }

    collectImportTemplate = () => {
        const headings = this.getImportFileHeader();

        const sampleData = this.getImportFileSampleRow();

        this.setState({
            importTemplateData: [headings, sampleData],
        });
    }

    dismissErrorMessage = () => {
        this.setState({
            showLoader: false,
            successMessage: '',
            errorMessage: '',
            erroneousData: [],
        });
    }

    dismissSuccessMessage = () => {
        this.setState({
            successMessage: '',
        });
    }

    startDataExport = () => {
        this.setState({
            errorMessage: '',
        });


        if (typeof this.state.roleId === 'undefined' && typeof this.state.projectId === 'undefined' && this.props.structureData.projects.allEntries.length !== 1) {
            this.setState({
                errorMessage: 'Select at least one role or project',
            });

            this.hideMessages();
        }

        return true;
    }

    startDataImport = () => {
        this.setState({
            errorMessage: '',
        });


        if (typeof this.state.roleId === 'undefined' && typeof this.state.projectId === 'undefined' && this.props.structureData.projects.allEntries.length !== 1) {
            this.setState({
                errorMessage: 'Select at least one role or project',
            });
            this.hideMessages();
        } else {
            const fileElement = document.getElementById('import-file-input');
            if (!!fileElement) {
                fileElement.click();
            }
        }
    }

    render() {
        let allowedProjects = this.props.structureData.projects.allEntries;

        if (this.props.userData.filters.projects.length > 0) {
            allowedProjects = this.props.userData.filters.projects.slice();
        }

        const projectsList = allowedProjects.map(projectId => {
            return {
                name: translatePhrase(this.props.projectsData.byId[projectId].name),
                value: projectId,
            };
        });

        let allowedRoles = this.props.structureData.roles.allEntries;

        if (this.props.userData.filters.roles.length > 0) {
            allowedRoles = this.props.userData.filters.roles.slice();
        }

        const rolesList = allowedRoles
            .filter(roleId => {

                if (!this.state.projectId) {
                    return true;
                }

                const role = this.props.rolesData.byId[roleId];
                const level = this.props.levelsData.byId[role.level];
                return level.project === this.state.projectId;
            })
            .map(roleId => {

                const role = this.props.rolesData.byId[roleId];
                const level = this.props.levelsData.byId[role.level];
                const project = this.props.projectsData.byId[level.project];

                return {
                    name: `${translatePhrase(role.name)} (${translatePhrase(project.name)})`,
                    value: roleId,
                };
            }).concat([{
                name: translatePhrase('All Roles'),
                value: '',
            }]);

        const projectName = `${this.state.projectId ? this.props.structureData.projects.byId[this.state.projectId].name : ''}`;
        const roleName = `${this.state.roleId ? this.props.structureData.roles.byId[this.state.roleId].name : ''}`;
        const fileName = roleName ? `${projectName.toLocaleLowerCase().split(' ').join('-')}-${roleName.toLocaleLowerCase().split(' ').join('-')}` : `${projectName.toLocaleLowerCase().split(' ').join('-')}-all-roles`;

        let defaultProjectId = projectsList.length > 0 ? translatePhrase(projectsList[0].name) : '';
        let defaultRoleId = translatePhrase('All Roles');

        return (
            <section>
                {this.state.showLoader && <LoaderModal loaderText={this.state.loaderText} isIndeterminate={true} />}

                {this.state.successMessage && <LoaderModal loaderText={[this.state.successMessage]} isSuccess={true} />}

                {this.state.errorMessage && <LoaderModal closeModal={this.dismissErrorMessage} loaderText={[this.state.errorMessage]} link={this.state.erroneousData.length > 1 ? <CSVLink data={this.state.erroneousData} filename="Errors.csv" className={styles.errorFileDownload}>Download erroneous data</CSVLink> : <div></div>} isError={true} />}


                <section className={styles.commonModalHolder + ' ' + styles.csvModal}>
                    <div className={styles.filterCloseButton} onClick={this.props.closeCSV}>
                        <Button title={translatePhrase("Close")} icon={<CancelIcon />} size={'small'} isRounded />
                    </div>

                    <section className={styles.addOrModifyListCard}>
                        <header>
                            <h2> <ExportIcon /> {translatePhrase('Import / Export')} {translatePhrase('Users')} </h2>
                        </header>

                        <div className={styles.container}>
                            <div className={styles.allInputsHolder}>
                                <div className={styles.inputSegment}>
                                    <InputText placeholder="Project" icon={chevronIcon} default={defaultProjectId} options={projectsList} onChange={this.changeProject} />
                                </div>
                                {this.state.projectId && <div className={styles.inputSegment} key={this.state.projectId}>
                                    <InputText placeholder="Role" icon={chevronIcon} default={defaultRoleId} options={rolesList} onChange={this.changeRole} />
                                </div>}

                            </div>
                        </div>

                        <input type="file" accept=".csv, text/csv" id="import-file-input" className={styles.hiddenFile} onChange={this.handleFileUpload} />

                        <div className={styles.buttonsHolder}>
                            <Button icon={<ExportCSVIcon />} isRounded padding={'0px 10px'} text={translatePhrase(`Export Table`)} isBlock onClick={this.collectDataToExport} />
                            <div className={styles.actionButtons}>
                                {this.props.isSuperUser && <Button icon={<ImportIcon />} type={'secondary'} isRounded isBlock padding={'0px 10px'} text={translatePhrase('Import')} onClick={this.startDataImport} isDisabled={this.props.isLimitReached} />}
                                <CSVLink data={this.state.importTemplateData} onClick={this.collectImportTemplate} filename={`${fileName} template.csv`}>
                                    <Button icon={<TemplateIcon />} type={'tertiary'} isRounded text={translatePhrase('Download Template')} onClick={this.startDataExport} />
                                </CSVLink>
                            </div>
                        </div>

                    </section>
                </section>
            </section>
        );
    }
}

const UserModify = connect(mapStateToProps, mapDispatchToProps)(ConnectedUserModify);

export default UserModify;