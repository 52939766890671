import { FC, useState, useEffect } from 'react';
import styles from './WorkflowsTransfer.module.scss';

import { translatePhrase } from '../../../shared/helpers/translation';

import { ReactComponent as CancelIcon } from '../../../common/assets/close.svg';
import { ReactComponent as TransferIcon } from '../../../common/assets/transfer.svg';

import Button from '../../../widgets/button/CommonButton';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../../../shared/store/types';
import { getVisibleUserIds } from '../../../shared/helpers/visible-entities';
import store from '../../../shared/store/main';
import translate from '../../../helpers/translations';
import { getReadableDataForCustomField } from '../../../shared/store/custom-fields';
import InputText from '../../../widgets/form/InputText';
import { bulkTransferWorkflows } from '../../../shared/store/workflows/actions';
import LoaderModal from '../../../widgets/loader/LoaderModal';
import ConfirmModal from '../../../widgets/loader/ConfirmModal';

type OwnProps = {
    workflowIds: Array<string>,
    closeTransferForm: () => void,
};

interface UserToTransferTo {
    id: string;
    name: string;
    noOfWorkflows: number;
}

interface UserOption {
    name: string;
    value: string;
};

const WorkflowsTransfer: FC<OwnProps> = (props) => {
    const userState = useSelector((state: ApplicationState) => state.users);
    const workflowState = useSelector((state: ApplicationState) => state.workflows);
    const [users, setUsers] = useState<Array<UserToTransferTo>>([]);
    const [selectedUserId, setSelectedUserId] = useState<string | undefined>();

    const [isAskingForTransfer, setIsAskingForTransfer] = useState(false);

    const [successMessage, setSuccessMessage] = useState('');

    const dispatch = useDispatch();

    const userNameField = userState.customFields.byId[userState.nameFieldId];

    useEffect(() => {
        const state = store.getState();
        const userIds = getVisibleUserIds(state, true);

        const workflowCounts: {
            [userId: string]: number;
        } = {};

        for (const workflowId of workflowState.allEntries) {
            const workflow = workflowState.byId[workflowId];
            const workflowStatus = workflowState.types.statuses.byId[workflow.status];

            if (workflow && workflowStatus && !workflowStatus.isTerminal) {
                if (workflow.user in workflowCounts) {
                    workflowCounts[workflow.user] += 1;
                } else {
                    workflowCounts[workflow.user] = 1;
                }
            }
        }

        const usersToTransferTo: Array<UserToTransferTo> = userIds.map(userId => {
            const user = userState.byId[userId];

            const userName = getReadableDataForCustomField(user.customFields[userState.nameFieldId], userNameField, userId, 'user');

            return {
                id: userId,
                name: `${translate(userName)} (${userId in workflowCounts ? workflowCounts[userId] : 0})`,
                noOfWorkflows: userId in workflowCounts ? workflowCounts[userId] : 0,
            };
        });

        setUsers(usersToTransferTo);
    }, [userState, workflowState, userNameField]);

    const userOptions: Array<UserOption> = users.map(user => {
        return {
            name: user.name,
            value: user.id,
        }
    });

    const selectedUser = users.find(userOption => userOption.id === selectedUserId);

    const userWorkflowIds = selectedUser ? props.workflowIds.filter(workflowId => {
        const workflow = workflowState.byId[workflowId];

        return workflow.user !== selectedUser.id;
    }) : props.workflowIds;

    const transferringWorkflowIds = userWorkflowIds.filter(workflowId => {
        const workflow = workflowState.byId[workflowId];
        const workflowStatus = workflowState.types.statuses.byId[workflow.status];

        return !workflowStatus.isTerminal;
    });

    const transferWorkflows = () => {
        setIsAskingForTransfer(false);

        if (selectedUser) {

            dispatch(bulkTransferWorkflows(transferringWorkflowIds, selectedUser.id));
            setSuccessMessage(transferringWorkflowIds.length + ' workflow(s) have been transferred to ' + selectedUser.name)

            setTimeout(() => {
                setSuccessMessage('');
                props.closeTransferForm();
            }, 5000);
        }
    }

    function askForTransfer() {
        setIsAskingForTransfer(true);
    }

    function cancelTransfer() {
        setIsAskingForTransfer(false);
    }

    return (
        <section>
            {successMessage && <LoaderModal isSuccess loaderText={[successMessage]} />}

            {isAskingForTransfer && <ConfirmModal
                confirmText={`${translatePhrase('Transfer all entries')}? (${transferringWorkflowIds.length})`}
                confirm={transferWorkflows}
                cancel={cancelTransfer}
            />}

            <section className={styles.commonModalHolder + ' ' + styles.csvModal}>
                <div className={styles.filterCloseButton} onClick={props.closeTransferForm}>
                    <Button title={translatePhrase("Close")} icon={<CancelIcon />} size={'small'} isRounded />
                </div>

                <section className={styles.addOrModifyListCard}>
                    <header>
                        <h2> <TransferIcon /> {translatePhrase('Transfer')} {translatePhrase('Workflows')} </h2>
                    </header>

                    <div className={styles.container}>
                        <div className={styles.allInputsHolder}>
                            <div className={styles.inputSegment}>
                                <InputText placeholder="User" options={userOptions} default={selectedUser?.name} onChange={setSelectedUserId} />
                            </div>
                        </div>
                    </div>

                    {selectedUser && <div className={styles.workflowsCount}>{translate('Note: Number in brackets is the number of active workflows for user')}: {selectedUser.noOfWorkflows}</div>}

                    <div className={styles.buttonsHolder}>
                        <Button isDisabled={transferringWorkflowIds.length === 0} isRounded isBlock padding={'0px 10px'} text={translatePhrase(`Transfer active workflows in table`)} onClick={askForTransfer} />
                    </div>
                </section>
            </section>
        </section>
    );
}

export default WorkflowsTransfer;