import { ChangeEvent, FC } from "react";
import styles from "./DropDownSearchBox.module.scss";
import { Option } from "./ListItem";

export const getFilteredOptionsBySearch = (list: Option[], searchTerm: string) => {
    return list.filter(item => item.name.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()));
}

type OwnProps = {
    handleSearchInputChange: (searchTerm: string) => void;
    placeholder: string;
    searchTerm: string;
 };

type Props = OwnProps;

const DropDownSearchBox: FC<Props> = (props) => {

    const { handleSearchInputChange, placeholder, searchTerm } = props;
    
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        handleSearchInputChange(e.target.value);
    }

    return (
    <div className={styles.nameContainer}>
        <input className={styles.nameInput} onChange={handleInputChange} value={searchTerm} type="text" placeholder={placeholder} />
    </div>
    )

}
export default DropDownSearchBox;