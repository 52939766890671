import { createSelector } from 'reselect';
import { ApplicationState } from '../shared/store/types';
import { isUUID } from '../shared/helpers/utilities';
import { IMember } from '../shared/store/members/types';
import { IGroup } from '../shared/store/groups/types';
import { IWorkflow } from '../shared/store/workflows/types';
import { MemberTypeState } from '../shared/store/members/types/types';
import { GroupTypeState } from '../shared/store/groups/types/types';
import { WorkflowTypeState } from '../shared/store/workflows/types/types';

const getOrganization = (state: ApplicationState) => state.organization;
const getMyData = (state: ApplicationState) => state.myData;
const isOnlineSelector = (state: ApplicationState) => state.myData.isOnline;
const myIdSelector = (state: ApplicationState) => state.myData.id;
const usersByIdSelector = (state: ApplicationState) => state.users.byId;
const rolesByIdSelector = (state: ApplicationState) => state.structure.roles.byId;
const levelsByIdSelector = (state: ApplicationState) => state.structure.levels.byId;

const isValidThresholdValue = (threshold: number | undefined) => (
    threshold !== null &&
    threshold !== Infinity &&
    !isNaN(Number(threshold))
);

const getHasThreshold = createSelector(
    [getOrganization],
    (organization) => {
        const {
            completedMemberThreshold,
            completedGroupThreshold,
            completedWorkflowThreshold,
            completedMemberThresholdForSuperUser,
            completedGroupThresholdForSuperUser,
            completedWorkflowThresholdForSuperUser
        } = organization;

        let hasMemberThreshold = isValidThresholdValue(completedMemberThreshold);
        let hasGroupThreshold = isValidThresholdValue(completedGroupThreshold);
        let hasWorkflowThreshold = isValidThresholdValue(completedWorkflowThreshold);

        let hasThreshold = hasMemberThreshold || hasGroupThreshold || hasWorkflowThreshold;

        if (!hasThreshold) {
            hasMemberThreshold = isValidThresholdValue(completedMemberThresholdForSuperUser);
            hasGroupThreshold = isValidThresholdValue(completedGroupThresholdForSuperUser);
            hasWorkflowThreshold = isValidThresholdValue(completedWorkflowThresholdForSuperUser);

            hasThreshold = hasMemberThreshold || hasGroupThreshold || hasWorkflowThreshold;
        }

        return hasThreshold;
    });

export const getIsPartialFetch = createSelector(
    [getMyData, getHasThreshold],
    (myData, hasThreshold) => {
        return !myData.isOlderDataFetched && hasThreshold;
    })

export const getActionableIdsAndBulkActionsSelector = (
    filteredList: (IMember | IGroup | IWorkflow)[],
    entityTypes: MemberTypeState | GroupTypeState | WorkflowTypeState) =>
    createSelector(
        [isOnlineSelector, myIdSelector, usersByIdSelector, rolesByIdSelector, levelsByIdSelector],
        (isOnline, myId, usersById, rolesById, levelsById) => {
            let bulkActions = false;

            let actionableIds: Array<string> = [];
            if (!isOnline) {
                if (!isUUID(myId)) {
                    bulkActions = true;
                    actionableIds = filteredList.map(item => item.id);
                } else {
                    const users = usersById[myId];
                    const roles = users.roles.map(roleId => rolesById[roleId]);
                    const actionableProjectIds = roles.filter(role => role.bulkActions).map(role => {
                        const level = levelsById[role.level];
                        return level.project;
                    });

                    bulkActions = roles.some(role => role.bulkActions);
                    const canUseBulkActionsCompletely = roles.every(role => role.bulkActions);

                    if (bulkActions) {
                        if (roles.length > 1 && !canUseBulkActionsCompletely) {
                            actionableIds = filteredList
                                .filter(item => {
                                    const type = entityTypes.byId[item.type];
                                    return actionableProjectIds.includes(type.project);
                                })
                                .map(item => item.id);
                        } else {
                            actionableIds = filteredList.map(item => item.id);
                        }
                    }
                }
            }

            return { bulkActions, actionableIds };
        }
    )

export const getBulkActionsSelector = () =>
    createSelector(
        [myIdSelector, usersByIdSelector, rolesByIdSelector],
        (myId, usersById, rolesById) => {
            let bulkActions = false;

            if (!isUUID(myId)) {
                bulkActions = true;
            } else {
                const user = usersById[myId];
                const roles = user.roles.map(roleId => rolesById[roleId]);
                bulkActions = roles.some(role => role.bulkActions);
            }

            return { bulkActions };
        }
    );