import { createSelector } from 'reselect';
import { getVisibleUserIdsSelector } from '../../../helpers/selectors';
import { filterUsers } from '../../../shared/helpers/filters';
import store from '../../../shared/store/main';
import { ApplicationState } from '../../../shared/store/types';;

const getUserState = (state: ApplicationState) => state.users.byId;
const getSearchTerm = (state: ApplicationState) => state.users.searchTerm;
const getCurrentPageNumber = (state: ApplicationState) => state.users.currentPageNumber;
const getPageSize = (state: ApplicationState) => state.users.pageSize;
const getFilters = (state: ApplicationState) => state.users.filters;

export const getFilteredUsers = createSelector(
    [getUserState, getCurrentPageNumber, getPageSize, getSearchTerm, getFilters, getVisibleUserIdsSelector],
    (userState, currentPageNumber, pageSize, searchTerm, filters, visibleUserIds) => {
        const state = store.getState();

        const filteredUsers = filterUsers(state, visibleUserIds);
        return filteredUsers;

    }
)