import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/types';
import { INewDataFragmentData, IUpdateableDataFragmentData } from '../../../shared/store/static-info/data-fragment/types';
import { reOrderDataFragments, addDataFragment, updateDataFragment, deleteDataFragment } from '../../../shared/store/static-info/data-fragment/actions';

import CardTreeVertical, {
    OwnProps as CardTreeOwnProps,
    StateProps as CardTreeStateProps,
    DispatchProps as CardTreeDispatchProps,
    OwnState as CardTreeOwnState
} from './DataFragmentsVertical';
import { translatePhrase } from '../../../shared/helpers/translation';

interface OwnProps extends CardTreeOwnProps {
    parentId: string,
    isReadOnly: boolean,
}

interface StateProps extends CardTreeStateProps {
}

interface DispatchProps extends CardTreeDispatchProps {
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): StateProps => {
    const dataFragmentIds = ownProps.parentId in state.staticInfo.fragments.byStaticDataHolder ? state.staticInfo.fragments.byStaticDataHolder[ownProps.parentId] : state.staticInfo.fragments.byId[ownProps.parentId].children;
    const availableDataFragments = dataFragmentIds.filter(dataFragmentId => dataFragmentId in state.staticInfo.fragments.byId);

    const allDataFragments = availableDataFragments.map(dataFragmentId => {
        const dataFragment = state.staticInfo.fragments.byId[dataFragmentId];

        const subDataFragmentIds = dataFragment.children;

        const subDataFragmentNames = subDataFragmentIds.map(dataIds => {
            const names = state.staticInfo.fragments.byId[dataIds] ? translatePhrase(state.staticInfo.fragments.byId[dataIds].name) : ''
            return names
        });

        return {
            id: dataFragment.id,
            name: translatePhrase(dataFragment.name),
            details: subDataFragmentNames.join(', ')
        }
    });

    return {
        read: true,
        write: true,
        restrictStructureChanges: false,

        cardsList: allDataFragments,
        selectedCard: ownProps.selectedId ? state.staticInfo.fragments.byId[ownProps.selectedId] : undefined,
    };
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
    return {
        reOrderCards: (sourceIndex: number, destinationIndex: number) => dispatch(reOrderDataFragments(sourceIndex, destinationIndex, ownProps.parentId || '')),
        addCard: (payload: INewDataFragmentData) => dispatch(addDataFragment(payload, ownProps.parentId || '')),
        deleteCard: (id: string) => dispatch(deleteDataFragment(id, ownProps.parentId || '')),
        updateCard: (payload: IUpdateableDataFragmentData) => dispatch(updateDataFragment(payload)),
    };
}

type Props = OwnProps & StateProps & DispatchProps;

interface OwnState extends CardTreeOwnState {
};

class ConnectedDataFragmentsList extends CardTreeVertical<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            isShowingAddForm: false,
            isShowingModifyForm: false,
            modifyingCardName: props.selectedCard ? props.selectedCard.name : '',
        };
    }

    static defaultProps = {
        isReadOnly: false,
    }

}

const DataFragmentsList = connect(mapStateToProps, mapDispatchToProps)(ConnectedDataFragmentsList);

export default DataFragmentsList;