import moment, { Moment } from 'moment';
import { IReportSchedule, ReportScheduleFrequency } from '../store/reports/schedules/types';

export function reOrderList<T>(list: Iterable<T>, startIndex: number, endIndex: number) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
}

export function mergeReverseLinkArrays(incomingLinks: Array<string>, existingLinks: Array<string>) {
    const mergedArray: Array<string> = [];

    while (incomingLinks.length > 0 && existingLinks.length > 0) {
        const firstIncomingElement = incomingLinks[0];
        const firstExistingElement = existingLinks[0];

        if (!incomingLinks.includes(firstExistingElement)) {
            mergedArray.push(firstExistingElement);
            existingLinks.shift();
            continue;
        }

        mergedArray.push(firstIncomingElement);

        incomingLinks.shift();
        existingLinks = existingLinks.filter(existingElement => existingElement !== firstIncomingElement);
    }

    for (const remainingLink of incomingLinks.concat(existingLinks)) {
        mergedArray.push(remainingLink);
    }

    return mergedArray;
}

export function getReadableDate(dateString: string) {
    return moment(dateString).format('DD MMM YYYY');
}

export function getCurrentPosition(options?: PositionOptions) {
    return new Promise<GeolocationPosition>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject, options);
    });
}

export function dataURLtoFile(dataUrl: string, filename: string) {

    let arr = dataUrl.split(','),
        mimes = arr[0].match(/:(.*?);/),
        mime = mimes ? mimes[1] : '',
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
}

export function isUUID(string: string) {
    return /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(string);
}

export function isObjectEmpty(object: any) {
    if (object === null || typeof object === 'undefined') {
        return true;
    }

    return Object.entries(object).length === 0 && object.constructor === Object
}

export function flattenArray<T>(twoDimensionalArray: Array<Array<T>>) {
    const flattenedArray: Array<T> = [];
    for (const internalArray of twoDimensionalArray) {
        flattenedArray.push.apply(flattenedArray, internalArray);
    }

    return flattenedArray;
}

export function copyStringToClipboard(value: string) {
    var el = document.createElement('textarea');
    el.value = value;

    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}

export function isMobilePlatform() {
    return typeof window !== 'undefined' && window.location.hostname.startsWith('m.') || ((window.location.hostname === 'localhost' || window.location.hostname.startsWith('192')) && window.location.port === '8100');
}

export function isDesktopPlatform() {
    return typeof window !== 'undefined' && !isMobilePlatform();
}

export function validateEmail(email: string) {
    return email.match(
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const ordinalPluralRules = new Intl.PluralRules('en-US', { type: 'ordinal' });

const ordinalSuffixes = new Map([
    ['one', 'st'],
    ['two', 'nd'],
    ['few', 'rd'],
    ['other', 'th'],
]);

export function formatNumberWithOrdinal(number: number) {
    const rule = ordinalPluralRules.select(number);
    const suffix = ordinalSuffixes.get(rule);
    return `${number}${suffix}`;
};

function resetTimeValuesForDate(date: Moment) {
    return date.hours(0).minutes(0).seconds(0).milliseconds(0);
}

export function getNextGenerationTimeForReportSchedule(schedule: IReportSchedule) {
    const dayOfMonth = schedule.dayOfMonth || 1;

    let reportGenerationDate = moment();
    const today = resetTimeValuesForDate(moment());

    switch (schedule.frequency) {
        case ReportScheduleFrequency.MONTHLY:
            const reportDateThisMonth = resetTimeValuesForDate(moment()).date(dayOfMonth || 1);

            if (reportDateThisMonth >= today) {
                reportGenerationDate = moment(reportDateThisMonth);
            } else {
                reportGenerationDate = moment(reportDateThisMonth).add(1, 'month');
            }
            break;
        case ReportScheduleFrequency.QUARTERLY:
            const reportDateThisQuarter = resetTimeValuesForDate(moment()).date(dayOfMonth || 1).add(3 - moment().month() % 3, 'month').subtract(3, 'months');

            if (reportDateThisQuarter >= today) {
                reportGenerationDate = moment(reportDateThisQuarter);
            } else {
                reportGenerationDate = moment(reportDateThisQuarter).add(3, 'months');
            }
            break;
        default:
            break;
    }

    return reportGenerationDate;
}

export function getTimeoutPromise(timeoutInMs: number) {
    return new Promise<void>((resolve, reject) => {
        window.setTimeout(() => {
            resolve();
        }, timeoutInMs);
    });
};

export function smoothScroll(elem: Element, options: ScrollIntoViewOptions) {
    return new Promise<void>((resolve, reject) => {
        let samePositionCounter = 0;
        let lastXPosition: number | null = null;
        let lastYPosition: number | null = null;
        const scrollOptions: ScrollIntoViewOptions = Object.assign({ behavior: 'smooth' }, options);

        elem.scrollIntoView(scrollOptions);

        // this function will be called every painting frame
        // for the duration of the smooth scroll operation
        function check() {
            // check our current position
            const elementRect = elem.getBoundingClientRect();
            const newXPos = elementRect.left;
            const newYPos = elementRect.top;

            if (newXPos === lastXPosition && newYPos === lastYPosition) {
                samePositionCounter += 1;

                // If it's been in the same position for more than two frames, the scroll is complete
                if (samePositionCounter > 2) {
                    return resolve();
                }
            } else {
                // Reset the counter
                samePositionCounter = 0;

                // Store new positions
                lastXPosition = newXPos;
                lastYPosition = newYPos;
            }

            // check again next painting frame
            requestAnimationFrame(check);
        }

        // Check at most once per frame
        requestAnimationFrame(check);
    });
}

export function formatBytes(bytes: number): string {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return (bytes / Math.pow(k, i)).toFixed(2) + ' ' + sizes[i];
}

export function cleanLogicalOperators(obj: any): any {
    if (Array.isArray(obj)) {
        return obj
            .map(item => cleanLogicalOperators(item))
            .filter(item => item !== undefined && (Array.isArray(item) ? item.length > 0 : true));
    } else if (obj !== null && typeof obj === 'object') {
        const newObj: any = {};
        Object.keys(obj).forEach(key => {
            const value = cleanLogicalOperators(obj[key]);
            if (value !== undefined) {
                // Remove empty arrays only for $and, $or, $nor
                if (['$and', '$or', '$nor'].includes(key) && Array.isArray(value) && value.length === 0) {
                    return;
                }
                newObj[key] = value;
            }
        });
        return Object.keys(newObj).length > 0 ? newObj : undefined;
    }
    return obj;
}
