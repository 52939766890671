import React, { WheelEvent, MouseEvent, useEffect, useRef, useState } from 'react';
import styles from './FlowchartComparison.module.scss';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { ApplicationState } from '../shared/store/types';
import { FlowchartContext, FlowchartInfoForPiece, PieceHighlight, PieceHighlightColour } from '../contexts/flowchart-context';
import { WorkflowTypeContext } from '../contexts/workflow-type-context';
import { getComponentForWorkflowPiece, piecesByCategory } from './flowchart/helpers/workflow';
import { PiecePositionState } from '../shared/helpers/common-types';
import { setNextPiece, setInnerPiece, setConditionForIfPiece, setConditionPiece, setConditionNextPiece, setLoopVariable, setIterableVariable, setOperand, setLeftOperand, setRightOperand, setQuestionData, setMemberVariable, setQuestionRequiredPiece, setQuestionDisabledPiece, setQuestionDefaultPiece, setQuestionImage, setDataStoreValue, setDataSetValue, setDataCopyVariable, setPieceForList, setReturnValue, setLocationPiece, setVariableForShow, setVariableForCustomField, setVariablePiece, setWorkflowAffiliationVariable, setHeading, setMessage, setUpdateDueDateValue, setEntity, setEntityType, setError, setFinsalPremium, setFinsalMemberFirstName, setFinsalMemberLastName, setFinsalMemberEmail, setFinsalMemberPhone, setFinsalMemberPan, setFinsalMemberState, setFinsalMemberCity, setFinsalMemberAddressLine1, setFinsalMemberAddressLine2, setFinsalMemberPinCode, setFinsalUserEmail, setFinsalUserPhone, setFinsalMember, setFinsalMemberDOB, setFinsalMemberGender, setFinsalMemberFatherName, setFinsalMemberMotherName, setFinsalMemberAnnualIncome, setFinsalMemberMaritalStatus, setFinsalApplyForLoan, setDate, setMonth, setYear, unsetWorkflowTypeComparison, togglePieceExpansion, toggleAllPiecesExpansion, resetPieceExpansion, unsetWorkflowTypeComparisonSelectedPiece, setWorkflowTypeComparisonSelectedPiece, setQuestionHiddenPiece, setHiddenPieceForShow } from '../shared/store/flowchart/pieces/actions';
import { AllPieceTypes, FlowchartPieceActions, PieceType, SelectedPieceDetails } from '../shared/store/flowchart/pieces/types';
import { updateWorkflowTypeStartPiece, updateWorkflowTypeBetaStartPiece, registerWorkflowTypeVariable, removeAllIsolatedWorkflowTypePieces, removeIsolatedWorkflowTypePiece, setIsolatedWorkflowTypePiece, removeAllBetaIsolatedWorkflowTypePieces, removeBetaIsolatedWorkflowTypePiece, setBetaIsolatedWorkflowTypePiece } from '../shared/store/workflows/types/actions';
import { getAllPiecesInPiece, getIfPieceId } from '../shared/store/flowchart/helpers/pieces';
import { DuplicationIDMappng } from '../shared/helpers/duplicate';
import { PieceStackStep, getPieceStack } from '../shared/store/flowchart/helpers/piece-stack';

import { ReactComponent as CloseIcon } from '../assets/action-icons/cancel.svg';
import { ReactComponent as ChevronIcon } from '../assets/chevron-arrow-down.svg';
import { smoothScroll } from '../shared/helpers/utilities';
import store from '../shared/store/main';

export type OwnProps = {
    workflowTypeId: string;
};

interface PieceDetails {
    pieceId: string;
    parentSplitPieces: Array<string>;
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    const workflowType = state.workflows.types.byId[ownProps.workflowTypeId];

    return {
        myId: state.myData.id,
        workflowType,

        piecesData: state.flowchart.pieces,
        variablesData: state.flowchart.variables,
        workflowTypesData: state.workflows.types,
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    const flowchartPieceActions: FlowchartPieceActions = {
        setNextPiece: (pieceId, value) => dispatch(setNextPiece(pieceId, value)),
        setInnerPiece: (pieceId, value) => dispatch(setInnerPiece(pieceId, value)),
        setConditionForIfPiece: (pieceId, index, value) => dispatch(setConditionForIfPiece(pieceId, index, value)),
        setConditionPiece: (pieceId, value) => dispatch(setConditionPiece(pieceId, value)),
        setConditionNextPiece: (pieceId, index, value) => dispatch(setConditionNextPiece(pieceId, index, value)),
        setLoopVariable: (pieceId, value) => dispatch(setLoopVariable(pieceId, value)),
        setIterableVariable: (pieceId, value) => dispatch(setIterableVariable(pieceId, value)),
        setOperand: (pieceId, value) => dispatch(setOperand(pieceId, value)),
        setLeftOperand: (pieceId, value) => dispatch(setLeftOperand(pieceId, value)),
        setRightOperand: (pieceId, value) => dispatch(setRightOperand(pieceId, value)),
        setQuestionData: (pieceId, value) => dispatch(setQuestionData(pieceId, value)),
        setMemberVariable: (pieceId, value) => dispatch(setMemberVariable(pieceId, value)),
        setRequiredPiece: (pieceId, value) => dispatch(setQuestionRequiredPiece(pieceId, value)),
        setDisabledPiece: (pieceId, value) => dispatch(setQuestionDisabledPiece(pieceId, value)),
        setHiddenPiece: (pieceId, value) => dispatch(setQuestionHiddenPiece(pieceId, value)),
        setDefaultPiece: (pieceId, value) => dispatch(setQuestionDefaultPiece(pieceId, value)),
        setImage: (pieceId, value) => dispatch(setQuestionImage(pieceId, value)),
        setDataStoreValue: (pieceId, value) => dispatch(setDataStoreValue(pieceId, value)),
        setDataSetValue: (pieceId, value) => dispatch(setDataSetValue(pieceId, value)),
        setDataCopyVariable: (pieceId, value) => dispatch(setDataCopyVariable(pieceId, value)),
        setDataForList: (pieceId, value) => dispatch(setPieceForList(pieceId, value)),
        setReturnVariable: (pieceId, value) => dispatch(setReturnValue(pieceId, value)),
        setLocationPiece: (pieceId, value) => dispatch(setLocationPiece(pieceId, value)),
        setVariableForShow: (pieceId, value) => dispatch(setVariableForShow(pieceId, value)),
        setHiddenPieceForShow: (pieceId, value) => dispatch(setHiddenPieceForShow(pieceId, value)),
        setVariableForCustomField: (pieceId, value) => dispatch(setVariableForCustomField(pieceId, value)),
        setVariablePiece: (pieceId, value) => dispatch(setVariablePiece(pieceId, value)),
        setAffiliationVariablePiece: (pieceId, value) => dispatch(setWorkflowAffiliationVariable(pieceId, value)),
        setHeadingPiece: (pieceId, value) => dispatch(setHeading(pieceId, value)),
        setMessage: (pieceId, value) => dispatch(setMessage(pieceId, value)),

        setDatePiece: (pieceId, value) => dispatch(setDate(pieceId, value)),
        setMonthPiece: (pieceId, value) => dispatch(setMonth(pieceId, value)),
        setYearPiece: (pieceId, value) => dispatch(setYear(pieceId, value)),
        setDueDatePiece: (pieceId, value) => dispatch(setUpdateDueDateValue(pieceId, value)),

        setEntity: (pieceId, value) => dispatch(setEntity(pieceId, value)),
        setEntityType: (pieceId, value) => dispatch(setEntityType(pieceId, value)),

        setErrorValue: (pieceId: string, value: string | undefined) => dispatch(setError(pieceId, value)),

        setStartPieceData: (workflowTypeId: string, payload: PiecePositionState) => dispatch(updateWorkflowTypeStartPiece(payload, workflowTypeId)),

        setFinsalPremium: (pieceId: string, value: string | undefined) => dispatch(setFinsalPremium(pieceId, value)),
        setFinsalMemberFirstName: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberFirstName(pieceId, value)),
        setFinsalMemberLastName: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberLastName(pieceId, value)),
        setFinsalMemberEmail: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberEmail(pieceId, value)),
        setFinsalMemberPhone: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberPhone(pieceId, value)),
        setFinsalMemberPan: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberPan(pieceId, value)),
        setFinsalMemberState: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberState(pieceId, value)),
        setFinsalMemberCity: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberCity(pieceId, value)),
        setFinsalMemberAddressLine1: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberAddressLine1(pieceId, value)),
        setFinsalMemberAddressLine2: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberAddressLine2(pieceId, value)),
        setFinsalMemberPinCode: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberPinCode(pieceId, value)),

        setFinsalUserEmail: (pieceId: string, value: string | undefined) => dispatch(setFinsalUserEmail(pieceId, value)),
        setFinsalUserPhone: (pieceId: string, value: string | undefined) => dispatch(setFinsalUserPhone(pieceId, value)),
        setFinsalMember: (pieceId: string, value: string | undefined) => dispatch(setFinsalMember(pieceId, value)),
        setFinsalMemberDOB: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberDOB(pieceId, value)),
        setFinsalMemberGender: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberGender(pieceId, value)),
        setFinsalMemberFatherName: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberFatherName(pieceId, value)),
        setFinsalMemberMotherName: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberMotherName(pieceId, value)),
        setFinsalMemberAnnualIncome: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberAnnualIncome(pieceId, value)),
        setFinsalMemberMaritalStatus: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberMaritalStatus(pieceId, value)),
        setFinsalApplyForLoan: (pieceId: string, value: string | undefined) => dispatch(setFinsalApplyForLoan(pieceId, value)),
    };

    const betaFlowchartPieceActions: FlowchartPieceActions = {
        ...flowchartPieceActions,
        setStartPieceData: (workflowTypeId: string, payload: PiecePositionState) => dispatch(updateWorkflowTypeBetaStartPiece(payload, workflowTypeId)),
    };

    return {
        flowchartPieceActions,
        betaFlowchartPieceActions,

        setIsolatedWorkflowTypePiece: (workflowTypeId: string, payload: PiecePositionState) => dispatch(setIsolatedWorkflowTypePiece(payload, workflowTypeId)),
        removeIsolatedWorkflowTypePiece: (workflowTypeId: string, pieceId: string) => dispatch(removeIsolatedWorkflowTypePiece(pieceId, workflowTypeId)),
        removeAllIsolatedWorkflowTypePieces: (workflowTypeId: string) => dispatch(removeAllIsolatedWorkflowTypePieces(workflowTypeId)),
        registerWorkflowTypeVariable: (workflowTypeId: string, variableId: string) => dispatch(registerWorkflowTypeVariable(variableId, workflowTypeId)),

        setBetaIsolatedWorkflowTypePiece: (workflowTypeId: string, payload: PiecePositionState) => dispatch(setBetaIsolatedWorkflowTypePiece(payload, workflowTypeId)),
        removeBetaIsolatedWorkflowTypePiece: (workflowTypeId: string, pieceId: string) => dispatch(removeBetaIsolatedWorkflowTypePiece(pieceId, workflowTypeId)),
        removeAllBetaIsolatedWorkflowTypePieces: (workflowTypeId: string) => dispatch(removeAllBetaIsolatedWorkflowTypePieces(workflowTypeId)),

        unsetWorkflowTypeComparison: () => dispatch(unsetWorkflowTypeComparison()),

        toggleAllPiecesExpansion: (pieceIds: Array<string>, isExpanded: boolean) => dispatch(toggleAllPiecesExpansion(pieceIds, isExpanded)),
        resetPieceExpansion: () => dispatch(resetPieceExpansion()),

        setSelectedPiece: (selectedPiece: SelectedPieceDetails) => dispatch(setWorkflowTypeComparisonSelectedPiece(selectedPiece)),
        unsetSelectedPiece: () => dispatch(unsetWorkflowTypeComparisonSelectedPiece()),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;

interface PiecesStack {
    [pieceId: string]: Array<PieceStackStep>
}

const ConnectedFlowchartComparison: React.FC<Props> = (props) => {

    const timeoutValue = useRef<number | undefined>();
    const liveScrollTopStart = useRef<number | undefined>();
    const liveScrollLeftStart = useRef<number | undefined>();

    const betaScrollTopStart = useRef<number | undefined>();
    const betaScrollLeftStart = useRef<number | undefined>();

    const liveFlowchartHolderElement = document.getElementById('live-flowchart-holder');
    const betaFlowchartHolderElement = document.getElementById('beta-flowchart-holder');

    const [liveHighlights, setLiveHighlights] = useState<PieceHighlight>({});
    const [betaHighlights, setBetaHighlights] = useState<PieceHighlight>({});
    const [piecesStack, setPiecesStack] = useState<PiecesStack>({});

    const [isShowingAddModifications, setIsShowingAddModifications] = useState(true);
    const [isShowingUpdateModifications, setIsShowingUpdateModifications] = useState(true);
    const [isShowingDeleteModifications, setIsShowingDeleteModifications] = useState(true);
    const [isShowingMoveModifications, setIsShowingMoveModifications] = useState(true);

    const selectedPieceDetails = props.piecesData.comparison.selectedPiece;

    const [isLiveCollapsed, setIsLiveCollapsed] = useState(false);
    const [isPublishedCollapsed, setIsPublishedCollapsed] = useState(false);

    useEffect(() => {

        if (props.workflowType.publishPieceMapping) {
            const betaStartPiece = props.piecesData.byId[props.workflowType.betaStartPiece.piece];
            const liveStartPiece = props.piecesData.byId[props.workflowType.startPiece.piece];

            const betaPieces = betaStartPiece && betaStartPiece.type === PieceType.START && betaStartPiece.nextPiece ? getAllPiecesInPiece(props.piecesData, betaStartPiece.nextPiece) : [];
            const publishedPieces = liveStartPiece && liveStartPiece.type === PieceType.START && liveStartPiece.nextPiece ? getAllPiecesInPiece(props.piecesData, liveStartPiece.nextPiece) : [];

            const betaPieceIds = betaPieces.map(piece => piece.id);
            const betaPieceIdSet = new Set(betaPieces.map(piece => piece.id));
            const publishedPieceIds = publishedPieces.map(piece => piece.id);

            const liveToBetaMap: DuplicationIDMappng = props.workflowType.publishPieceMapping;
            const betaToLiveMap: DuplicationIDMappng = {};

            for (const pieceId in liveToBetaMap) {
                betaToLiveMap[liveToBetaMap[pieceId]] = pieceId;
            }

            const addedPieceIds: Array<string> = betaPieceIds.filter(pieceId => !(pieceId in betaToLiveMap));
            const deletedPieceIds: Array<string> = publishedPieceIds.filter(pieceId => !betaPieceIdSet.has(liveToBetaMap[pieceId]));

            // const updatedPieceIds: Array<string> = [];
            const updatedPieceIds: Array<string> = publishedPieceIds.filter(pieceId => {
                const piece = props.piecesData.byId[pieceId];
                let betaPiece: AllPieceTypes | undefined = props.piecesData.byId[liveToBetaMap[pieceId]];

                if (!betaPiece) {
                    return false;
                }

                if ((betaPiece as any).archived) {
                    betaPiece = undefined;
                }

                if (!betaPiece) {
                    return false;
                }

                if (piece.type === PieceType.SPLIT) {
                    if (betaPiece.type !== PieceType.SPLIT) {
                        return false;
                    }

                    return piece.ifPieceData?.length !== betaPiece.ifPieceData?.length;
                }

                const excludedKeys = ['id', 'createdTime', 'lastUpdatedTime', 'archived'];

                const keys = Array.from(new Set(Object.keys(piece).concat(Object.keys(betaPiece)).filter(prop => !excludedKeys.includes(prop))));

                for (const key of keys) {
                    let pieceValue: any = (piece as any)[key];
                    let betaPieceValue: any = (betaPiece as any)[key];

                    if (typeof pieceValue === 'object' || typeof betaPieceValue === 'object') {
                        pieceValue = JSON.stringify((piece as any)[key]);
                        betaPieceValue = JSON.stringify((betaPiece as any)[key]);
                    }

                    if (pieceValue in props.piecesData.byId || betaPieceValue in props.piecesData.byId) {
                        continue;
                    }

                    if (pieceValue !== betaPieceValue) {
                        return true;
                    }
                }

                return false;
            });

            const updatedBetaPieceIds = updatedPieceIds.map(pieceId => liveToBetaMap[pieceId]);

            const modifiedPieceIds = new Set(deletedPieceIds.concat(updatedPieceIds));

            const movedPieceIdsSet: Set<string> = new Set();

            publishedPieceIds.forEach(pieceId => {
                const piece = props.piecesData.byId[pieceId];
                let betaPiece: AllPieceTypes | undefined = props.piecesData.byId[liveToBetaMap[pieceId]];

                if (!betaPiece) {
                    return false;
                }

                if ((betaPiece as any).archived) {
                    betaPiece = undefined;
                }

                const excludedKeys = ['id', 'createdTime', 'lastUpdatedTime'];

                const keys = Array.from(new Set(Object.keys(piece).concat(betaPiece ? Object.keys(betaPiece) : []).filter(prop => !excludedKeys.includes(prop))));

                for (const key of keys) {
                    let pieceValue = (piece as any)[key];
                    let betaPieceValue: any = betaPiece ? (betaPiece as any)[key] : undefined;

                    if (pieceValue in props.piecesData.byId) {
                        if (pieceValue !== betaToLiveMap[betaPieceValue]) {
                            if (!modifiedPieceIds.has(pieceValue)) {
                                movedPieceIdsSet.add(pieceValue);
                            }
                        }
                    }
                }
            });

            const movedPieceIds = Array.from(movedPieceIdsSet);
            const movedBetaPieceIds = movedPieceIds.map(pieceId => liveToBetaMap[pieceId]);

            const liveHighlights: PieceHighlight = {};
            const betaHighlights: PieceHighlight = {};

            const piecesStack: PiecesStack = {};

            for (const pieceId of addedPieceIds) {
                betaHighlights[pieceId] = PieceHighlightColour.GREEN;

                const pieceStack = getPieceStack(pieceId, props.piecesData, props.workflowType.betaStartPiece.piece, []);
                piecesStack[pieceId] = pieceStack.finalStack;
            }

            for (const pieceId of deletedPieceIds) {
                liveHighlights[pieceId] = PieceHighlightColour.RED;

                const pieceStack = getPieceStack(pieceId, props.piecesData, props.workflowType.startPiece.piece, []);
                piecesStack[pieceId] = pieceStack.finalStack;
            }

            for (const pieceId of updatedPieceIds) {
                liveHighlights[pieceId] = PieceHighlightColour.YELLOW;

                const pieceStack = getPieceStack(pieceId, props.piecesData, props.workflowType.startPiece.piece, []);
                piecesStack[pieceId] = pieceStack.finalStack;
            }

            for (const pieceId of updatedBetaPieceIds) {
                betaHighlights[pieceId] = PieceHighlightColour.YELLOW;

                const pieceStack = getPieceStack(pieceId, props.piecesData, props.workflowType.betaStartPiece.piece, []);
                piecesStack[pieceId] = pieceStack.finalStack;
            }

            for (const pieceId of movedPieceIds) {
                liveHighlights[pieceId] = PieceHighlightColour.PURPLE;

                const pieceStack = getPieceStack(pieceId, props.piecesData, props.workflowType.startPiece.piece, []);
                piecesStack[pieceId] = pieceStack.finalStack;
            }

            for (const pieceId of movedBetaPieceIds) {
                betaHighlights[pieceId] = PieceHighlightColour.PURPLE;

                const pieceStack = getPieceStack(pieceId, props.piecesData, props.workflowType.betaStartPiece.piece, []);
                piecesStack[pieceId] = pieceStack.finalStack;
            }

            setLiveHighlights(liveHighlights);
            setBetaHighlights(betaHighlights);
            setPiecesStack(piecesStack);
        }

    }, []);

    const closeFlowchartComparison = () => {

        props.unsetWorkflowTypeComparison();

        if (!selectedPieceDetails) {
            return;
        }

        const pieceId = selectedPieceDetails.pieceId;

        const pieceStack = piecesStack[pieceId];
        let pieceIdsToExpand = getPieceIdsToExpandFromStack(pieceStack);

        const betaPieceId = props.workflowType.publishPieceMapping ? props.workflowType.publishPieceMapping[pieceId] : undefined;

        if (betaPieceId) {
            const pieceStack = piecesStack[betaPieceId];
            if (pieceStack) {
                const betaPieceIdsToExpand = getPieceIdsToExpandFromStack(pieceStack);
                pieceIdsToExpand = pieceIdsToExpand.concat(betaPieceIdsToExpand);
            }
        }

        setTimeout(() => {

            props.toggleAllPiecesExpansion(pieceIdsToExpand, true);

            setTimeout(() => {

                const options: ScrollIntoViewOptions = {
                    behavior: 'smooth',
                    block: 'start',
                    inline: 'start',
                };

                document.querySelector(`#flowchart-holder [data-piece-id="${betaPieceId}"]`)?.scrollIntoView(options);
                document.querySelector(`#flowchart-holder [data-piece-id="${pieceId}"]`)?.scrollIntoView(options);
            }, 200);

        }, 500);
    }

    useEffect(() => {

        const closeOnEscape = (e: KeyboardEvent) => {
            if (e.key === 'Escape') {
                closeFlowchartComparison();
            }
        }

        document.addEventListener('keydown', closeOnEscape);

        return () => {
            document.removeEventListener('keydown', closeOnEscape);
        }
    }, [piecesStack, closeFlowchartComparison]);

    useEffect(() => {

        if (selectedPieceDetails && piecesStack[selectedPieceDetails.pieceId]) {
            setTimeout(() => {
                goToPiece(selectedPieceDetails.pieceId, selectedPieceDetails.color);
            }, 1500);
        }
    }, [piecesStack]);

    useEffect(() => {
        const initialExpandedPieces = Object.keys(props.piecesData.isPieceExpanded).filter(pieceId => props.piecesData.isPieceExpanded[pieceId]);
        const initialCollapsedPieces = Object.keys(props.piecesData.isPieceExpanded).filter(pieceId => !props.piecesData.isPieceExpanded[pieceId]);

        props.resetPieceExpansion();

        return () => {
            props.resetPieceExpansion();
            const selectedPieceDetails = store.getState().flowchart.pieces.comparison.selectedPiece;

            if (!selectedPieceDetails) {
                props.toggleAllPiecesExpansion(initialExpandedPieces, true);
                props.toggleAllPiecesExpansion(initialCollapsedPieces, false);
                return;
            }
        }
    }, []);

    if (!props.workflowType) {
        return <div></div>
    }

    let filteredLiveHighlights = liveHighlights;
    let filteredBetaHighlights = betaHighlights;

    const betaPieceId = props.workflowType.publishPieceMapping && selectedPieceDetails?.pieceId
        ?
        props.workflowType.publishPieceMapping[selectedPieceDetails?.pieceId]
        : undefined;

    let selectedColor = selectedPieceDetails?.color;

    if (selectedPieceDetails?.pieceId) {
        if (selectedPieceDetails.pieceId in betaHighlights) {
            selectedColor = betaHighlights[selectedPieceDetails.pieceId];
        }

        if (selectedPieceDetails.pieceId in liveHighlights) {
            selectedColor = liveHighlights[selectedPieceDetails.pieceId];
        }
    }

    switch (selectedColor) {
        case PieceHighlightColour.GREEN:
            filteredLiveHighlights = {};
            if (selectedPieceDetails) {
                filteredBetaHighlights = {
                    [selectedPieceDetails.pieceId]: PieceHighlightColour.GREEN,
                };
            }
            break;

        case PieceHighlightColour.YELLOW:
            if (selectedPieceDetails) {
                filteredLiveHighlights = {
                    [selectedPieceDetails.pieceId]: PieceHighlightColour.YELLOW,
                };
            }
            filteredBetaHighlights = betaPieceId ? {
                [betaPieceId]: PieceHighlightColour.YELLOW,
            } : {};
            break;

        case PieceHighlightColour.RED:

            if (selectedPieceDetails) {
                filteredLiveHighlights = {
                    [selectedPieceDetails.pieceId]: PieceHighlightColour.RED,
                };
            }
            filteredBetaHighlights = {};
            break;

        case PieceHighlightColour.PURPLE:
            if (selectedPieceDetails) {
                filteredLiveHighlights = {
                    [selectedPieceDetails.pieceId]: PieceHighlightColour.PURPLE,
                };
            }
            filteredBetaHighlights = betaPieceId ? {
                [betaPieceId]: PieceHighlightColour.PURPLE,
            } : {};
            break;
    }

    filteredBetaHighlights = {
        ...filteredBetaHighlights,
    };

    filteredLiveHighlights = {
        ...filteredLiveHighlights,
    };

    for (const pieceId in filteredBetaHighlights) {
        const color = filteredBetaHighlights[pieceId];

        const shouldRemoveAddHighlight = !isShowingAddModifications && color === PieceHighlightColour.GREEN,
            shouldRemoveUpdateHighlight = !isShowingUpdateModifications && color === PieceHighlightColour.YELLOW,
            shouldRemoveMoveHighlight = !isShowingMoveModifications && color === PieceHighlightColour.PURPLE;

        if (shouldRemoveAddHighlight ||
            shouldRemoveUpdateHighlight ||
            shouldRemoveMoveHighlight) {
            delete filteredBetaHighlights[pieceId];
        }
    }

    for (const pieceId in filteredLiveHighlights) {
        const color = filteredLiveHighlights[pieceId];

        const shouldRemoveDeleteHighlight = !isShowingDeleteModifications && color === PieceHighlightColour.RED,
            shouldRemoveUpdateHighlight = !isShowingUpdateModifications && color === PieceHighlightColour.YELLOW,
            shouldRemoveMoveHighlight = !isShowingMoveModifications && color === PieceHighlightColour.PURPLE;

        if (shouldRemoveDeleteHighlight ||
            shouldRemoveUpdateHighlight ||
            shouldRemoveMoveHighlight) {
            delete filteredLiveHighlights[pieceId];
        }
    }

    const liveFlowchartInfoForPiece: FlowchartInfoForPiece = {
        flowchartHolderElement: liveFlowchartHolderElement,
        projects: [props.workflowType.project],
        variables: props.workflowType.variables.slice(),
        parentSplitPieceIds: [],
        parentIfPieceIndices: [],
        invalidPieces: [],
        isValidating: false,
        isReadonly: true,
        highlightIncompletePieces: true,
        highlights: filteredLiveHighlights,
        searchTerm: '',
    };

    const betaFlowchartInfoForPiece: FlowchartInfoForPiece = {
        flowchartHolderElement: betaFlowchartHolderElement,
        projects: [props.workflowType.project],
        variables: props.workflowType.variables.slice(),
        parentSplitPieceIds: [],
        parentIfPieceIndices: [],
        invalidPieces: [],
        isValidating: false,
        isReadonly: true,
        highlightIncompletePieces: true,
        highlights: filteredBetaHighlights,
        searchTerm: '',
    };

    const startPiece = props.workflowType.startPiece ? getComponentForWorkflowPiece(props.workflowTypesData, props.piecesData, props.variablesData, props.workflowType, false, props.flowchartPieceActions, props.setIsolatedWorkflowTypePiece.bind(this, props.workflowTypeId), props.removeIsolatedWorkflowTypePiece.bind(this, props.workflowTypeId), props.registerWorkflowTypeVariable.bind(this, props.workflowTypeId), props.workflowType.startPiece.piece, undefined) : undefined;
    const betaStartPiece = props.workflowType.betaStartPiece ? getComponentForWorkflowPiece(props.workflowTypesData, props.piecesData, props.variablesData, props.workflowType, false, props.betaFlowchartPieceActions, props.setBetaIsolatedWorkflowTypePiece.bind(this, props.workflowTypeId), props.removeBetaIsolatedWorkflowTypePiece.bind(this, props.workflowTypeId), props.registerWorkflowTypeVariable.bind(this, props.workflowTypeId), props.workflowType.betaStartPiece.piece, undefined) : undefined;


    const allPieces = Object.keys(piecesByCategory).map(category => (piecesByCategory as any)[category].pieces).flat();
    const pieceNameMapping: {
        [type: string]: string,
    } = {};

    allPieces.forEach(piece => {
        pieceNameMapping[piece.type] = piece.name;
    });

    const getPieceIdsToExpandFromStack = (pieceStack: Array<PieceStackStep>) => {
        const pieceIdsToExpand: Array<string> = [];

        for (const stackEntry of pieceStack) {
            const piece = props.piecesData.byId[stackEntry.pieceId];

            if (piece.type !== PieceType.SPLIT && piece.type !== PieceType.GROUP) {
                continue;
            }

            pieceIdsToExpand.push(piece.id);

            if (piece.type === PieceType.SPLIT && typeof stackEntry.index !== 'undefined') {
                const ifPieceId = getIfPieceId(stackEntry.pieceId, stackEntry.index);
                pieceIdsToExpand.push(ifPieceId);
            }
        }

        return pieceIdsToExpand;
    }

    const goToPiece = (pieceId: string, color: PieceHighlightColour) => {
        props.setSelectedPiece({ pieceId, color });

        const pieceStack = piecesStack[pieceId];
        let pieceIdsToExpand = getPieceIdsToExpandFromStack(pieceStack);

        const betaPieceId = props.workflowType.publishPieceMapping ? props.workflowType.publishPieceMapping[pieceId] : undefined;

        if (betaPieceId) {
            const pieceStack = piecesStack[betaPieceId];
            if (pieceStack) {
                const betaPieceIdsToExpand = getPieceIdsToExpandFromStack(pieceStack);
                pieceIdsToExpand = pieceIdsToExpand.concat(betaPieceIdsToExpand);
            }
        }

        props.toggleAllPiecesExpansion(pieceIdsToExpand, true);

        setTimeout(() => {
            const options: ScrollIntoViewOptions = {
                behavior: 'smooth',
                block: 'start',
                inline: 'start',
            };

            switch (color) {
                case PieceHighlightColour.GREEN:
                    document.querySelector(`#beta-flowchart-holder [data-piece-id="${pieceId}"]`)?.scrollIntoView(options);
                    break;
                case PieceHighlightColour.YELLOW:
                    const updateBetaElement = document.querySelector(`#beta-flowchart-holder [data-piece-id="${betaPieceId}"]`);
                    const updateLiveElement = document.querySelector(`#live-flowchart-holder [data-piece-id="${pieceId}"]`);
                    if (updateBetaElement && updateLiveElement) {
                        smoothScroll(updateBetaElement, options).then(() => {
                            smoothScroll(updateLiveElement, options);
                        });
                    }
                    break;
                case PieceHighlightColour.RED:
                    document.querySelector(`#live-flowchart-holder [data-piece-id="${pieceId}"]`)?.scrollIntoView(options);
                    break;
                case PieceHighlightColour.PURPLE:
                    const moveBetaElement = document.querySelector(`#beta-flowchart-holder [data-piece-id="${betaPieceId}"]`);
                    const moveLiveElement = document.querySelector(`#live-flowchart-holder [data-piece-id="${pieceId}"]`);
                    if (moveBetaElement && moveLiveElement) {
                        smoothScroll(moveBetaElement, options).then(() => {
                            smoothScroll(moveLiveElement, options);
                        });
                    }
                    break;
            }
        }, 200);

    }

    const syncScroll = () => {
        if (!betaFlowchartHolderElement || !liveFlowchartHolderElement) {
            return;
        }

        if (!timeoutValue.current) {
            liveScrollTopStart.current = liveFlowchartHolderElement.scrollTop;
            liveScrollLeftStart.current = liveFlowchartHolderElement.scrollLeft;

            betaScrollTopStart.current = betaFlowchartHolderElement.scrollTop;
            betaScrollLeftStart.current = betaFlowchartHolderElement.scrollLeft;
        }

        const newTimeoutValue = window.setTimeout(() => {
            timeoutValue.current = undefined;

            liveScrollTopStart.current = undefined;
            liveScrollLeftStart.current = undefined;

            betaScrollTopStart.current = undefined;
            betaScrollLeftStart.current = undefined;

            console.log('Stop listening');
        }, 100);

        window.clearTimeout(timeoutValue.current);
        timeoutValue.current = newTimeoutValue;
    }

    const handleLiveScroll = (event: WheelEvent) => {
        if (!betaFlowchartHolderElement || !liveFlowchartHolderElement) {
            return;
        }

        if (event.metaKey || event.ctrlKey) {
            syncScroll();

            if (typeof liveScrollTopStart.current !== 'undefined' && typeof betaScrollTopStart.current !== 'undefined') {
                const scrollTopDelta = liveFlowchartHolderElement.scrollTop - liveScrollTopStart.current;
                const newScrollTop = betaScrollTopStart.current + scrollTopDelta
                betaFlowchartHolderElement.scrollTop = newScrollTop > 0 ? newScrollTop : 0;
            }

            if (typeof liveScrollLeftStart.current !== 'undefined' && typeof betaScrollLeftStart.current !== 'undefined') {
                const scrollLeftDelta = liveFlowchartHolderElement.scrollLeft - liveScrollLeftStart.current;
                const newScrollLeft = betaScrollLeftStart.current + scrollLeftDelta;
                betaFlowchartHolderElement.scrollLeft = newScrollLeft > 0 ? newScrollLeft : 0;
            }
        }
    }

    const handleBetaScroll = (event: WheelEvent) => {
        if (!betaFlowchartHolderElement || !liveFlowchartHolderElement) {
            return;
        }

        if (event.metaKey || event.ctrlKey) {
            syncScroll();

            if (typeof liveScrollTopStart.current !== 'undefined' && typeof betaScrollTopStart.current !== 'undefined') {
                const scrollTopDelta = betaFlowchartHolderElement.scrollTop - betaScrollTopStart.current;
                const newScrollTop = liveScrollTopStart.current + scrollTopDelta
                liveFlowchartHolderElement.scrollTop = newScrollTop > 0 ? newScrollTop : 0;
            }

            if (typeof liveScrollLeftStart.current !== 'undefined' && typeof betaScrollLeftStart.current !== 'undefined') {
                const scrollLeftDelta = betaFlowchartHolderElement.scrollLeft - betaScrollLeftStart.current;
                const newScrollLeft = liveScrollLeftStart.current + scrollLeftDelta;
                liveFlowchartHolderElement.scrollLeft = newScrollLeft > 0 ? newScrollLeft : 0;
            }
        }
    }

    allPieces.forEach(piece => {
        pieceNameMapping[piece.type] = piece.name;
    });

    const unselectPiece = (e: MouseEvent) => {
        props.unsetSelectedPiece();
        e.stopPropagation();
    }

    const additionPieces = Object.keys(betaHighlights)
        .filter(pieceId => {
            const highlight = betaHighlights[pieceId];
            return highlight === PieceHighlightColour.GREEN;
        })
        .map(pieceId => {
            const piece = props.piecesData.byId[pieceId];
            return <section
                className={selectedPieceDetails?.pieceId === pieceId ? styles.selectedModificationEntryForAdd : styles.modificationEntry}
                onClick={() => goToPiece(pieceId, PieceHighlightColour.GREEN)}
            >
                <section className={styles.modificationText}>{pieceNameMapping[piece.type]}</section>
                {selectedPieceDetails?.pieceId === pieceId && <section
                    className={styles.closeIconContainer}
                    onClick={unselectPiece}
                >
                    <CloseIcon />
                </section>}
            </section>
        });

    const updationPieces = Object.keys(liveHighlights)
        .filter(pieceId => {
            const highlight = liveHighlights[pieceId];
            return highlight === PieceHighlightColour.YELLOW;
        })
        .map(pieceId => {
            const piece = props.piecesData.byId[pieceId];
            return <section
                className={selectedPieceDetails?.pieceId === pieceId ? styles.selectedModificationEntryForUpdate : styles.modificationEntry}
                onClick={() => goToPiece(pieceId, PieceHighlightColour.YELLOW)}
            >
                <section className={styles.modificationText}>{pieceNameMapping[piece.type]}</section>
                {selectedPieceDetails?.pieceId === pieceId && <section
                    className={styles.closeIconContainer}
                    onClick={unselectPiece}
                >
                    <CloseIcon />
                </section>}
            </section>
        });

    const deletionPieces = Object.keys(liveHighlights)
        .filter(pieceId => {
            const highlight = liveHighlights[pieceId];
            return highlight === PieceHighlightColour.RED;
        })
        .map(pieceId => {
            const piece = props.piecesData.byId[pieceId];
            return <section
                className={selectedPieceDetails?.pieceId === pieceId ? styles.selectedModificationEntryForDelete : styles.modificationEntry}
                onClick={() => goToPiece(pieceId, PieceHighlightColour.RED)}
            >
                <section className={styles.modificationText}>{pieceNameMapping[piece.type]}</section>
                {selectedPieceDetails?.pieceId === pieceId && <section
                    className={styles.closeIconContainer}
                    onClick={unselectPiece}
                >
                    <CloseIcon />
                </section>}
            </section>
        });

    const movedPieces = Object.keys(liveHighlights)
        .filter(pieceId => {
            const highlight = liveHighlights[pieceId];
            return highlight === PieceHighlightColour.PURPLE;
        })
        .map(pieceId => {
            const piece = props.piecesData.byId[pieceId];
            return <section
                className={selectedPieceDetails?.pieceId === pieceId ? styles.selectedModificationEntryForMove : styles.modificationEntry}
                onClick={() => goToPiece(pieceId, PieceHighlightColour.PURPLE)}
            >
                <section className={styles.modificationText}>{pieceNameMapping[piece.type]}</section>
                {selectedPieceDetails?.pieceId === pieceId && <section
                    className={styles.closeIconContainer}
                    onClick={unselectPiece}
                >
                    <CloseIcon />
                </section>}
            </section>
        });

    const toggleModificationCategory = (category: 'add' | 'update' | 'delete' | 'move') => {
        switch (category) {
            case 'add':
                setIsShowingAddModifications(oldValue => !oldValue);
                break;
            case 'update':
                setIsShowingUpdateModifications(oldValue => !oldValue);
                break;
            case 'delete':
                setIsShowingDeleteModifications(oldValue => !oldValue);
                break;
            case 'move':
                setIsShowingMoveModifications(oldValue => !oldValue);
                break;
        }
    }

    const toggleLiveFlowchartCollapse = () => {
        setIsLiveCollapsed(oldValue => !oldValue);
    }

    const togglePublishedFlowchartCollapse = () => {
        setIsPublishedCollapsed(oldValue => !oldValue);
    }

    return <section className={styles.flowchartComparison}>
        {!isLiveCollapsed && <div id="beta-flowchart-holder"
            className={`${styles.flowchartContainer} ' react-drag-disabled'}`}
            onWheel={handleBetaScroll}
        >
            <h4>Test</h4>
            <WorkflowTypeContext.Provider value={props.workflowType}>
                <FlowchartContext.Provider value={betaFlowchartInfoForPiece}>
                    {betaStartPiece}
                </FlowchartContext.Provider>
            </WorkflowTypeContext.Provider>
        </div>}
        <section className={styles.comparisonInfo}>
            <div className={isLiveCollapsed ? styles.collapsedLive : styles.collapseLive} onClick={toggleLiveFlowchartCollapse}>
                <ChevronIcon />
            </div>
            <div className={isPublishedCollapsed ? styles.collapsedPublished : styles.collapsePublished} onClick={togglePublishedFlowchartCollapse}>
                <ChevronIcon />
            </div>
            <h3>Changed pieces</h3>
            <section className={styles.modifications}>
                <section className={isLiveCollapsed ? styles.disabledModificationHeading : styles.modificationHeading} onClick={() => toggleModificationCategory('add')}>
                    <span className={styles.additionMarker}></span>
                    <span className={styles.modificationHeadingText}>Added ({additionPieces.length})</span>
                    <div className={styles.toggle}>{isShowingAddModifications && !isLiveCollapsed ? 'Hide' : 'Show'}</div>
                </section>
                {isShowingAddModifications && !isLiveCollapsed && additionPieces}
                <section className={styles.modificationHeading} onClick={() => toggleModificationCategory('update')}>
                    <span className={styles.updateMarker}></span>
                    <span className={styles.modificationHeadingText}>Updated ({updationPieces.length})</span>
                    <div className={styles.toggle}>{isShowingUpdateModifications ? 'Hide' : 'Show'}</div>
                </section>
                {isShowingUpdateModifications && updationPieces}
                <section className={isPublishedCollapsed ? styles.disabledModificationHeading : styles.modificationHeading} onClick={() => toggleModificationCategory('delete')}>
                    <span className={styles.deleteMarker}></span>
                    <span className={styles.modificationHeadingText}>Deleted ({deletionPieces.length})</span>
                    <div className={styles.toggle}>{isShowingDeleteModifications && !isPublishedCollapsed ? 'Hide' : 'Show'}</div>
                </section>
                {isShowingDeleteModifications && !isPublishedCollapsed && deletionPieces}
                <section className={styles.modificationHeading} onClick={() => toggleModificationCategory('move')}>
                    <span className={styles.moveMarker}></span>
                    <span className={styles.modificationHeadingText}>Moved ({movedPieces.length})</span>
                    <div className={styles.toggle}>{isShowingMoveModifications ? 'Hide' : 'Show'}</div>
                </section>
                {isShowingMoveModifications && movedPieces}
            </section>
            <section className={styles.scrollInstructions}>Ctrl/Cmd + scroll to scroll both windows</section>
        </section>
        {!isPublishedCollapsed && <div
            id="live-flowchart-holder"
            className={`${styles.flowchartContainer} ' react-drag-disabled'}`}
            onWheel={handleLiveScroll}
        >
            <h4>Published</h4>
            <WorkflowTypeContext.Provider value={props.workflowType}>
                <FlowchartContext.Provider value={liveFlowchartInfoForPiece}>
                    {startPiece}
                </FlowchartContext.Provider>
            </WorkflowTypeContext.Provider>
        </div>}
        <section className={styles.close} onClick={closeFlowchartComparison}>X</section>
    </section>
}

const FlowchartComparison = connect(mapStateToProps, mapDispatchToProps)(ConnectedFlowchartComparison);

export default FlowchartComparison;
