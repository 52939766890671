import React, { Component } from 'react';
import styles from './StartPiece.module.scss';
import FlowchartPiece, { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setNextPiece } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import { nextPieceTarget } from './utilities';
import { translatePhrase } from '../../../shared/helpers/translation';
import { PieceType } from '../../../shared/store/flowchart/pieces/types';
import { setErrorMessage, clearErrorMessage } from '../../../shared/store/my-data/actions';


type StartPieceProps = {
    nextPiece?: JSX.Element,
}

const mapStateToProps = (state: ApplicationState) => {
    return {
        pieceData: state.flowchart.pieces,
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string | undefined) => dispatch(setTargetPiece(pieceId)),
        setNextPiece: (targetPieceId: string, draggedPieceId: string) => dispatch(setNextPiece(targetPieceId, draggedPieceId)),
        setErrorMessage: (message: string) => dispatch(setErrorMessage(message, true)),
        clearErrorMessage: () => dispatch(clearErrorMessage()),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = StartPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type StartPieceState = {
    isHoveringOverNextPiece: boolean,
}

class ConnectedStartPiece extends Component<Props, StartPieceState> {

    state = {
        isHoveringOverNextPiece: false,
    };

    handleHoverOverNextPiece = () => {
        this.setState({
            isHoveringOverNextPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        nextPieceTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfNextPiece = () => {
        this.setState({
            isHoveringOverNextPiece: false,
        });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && this.state.isHoveringOverNextPiece) {

            if (this.props.nextPiece) {
                const currentPiece = this.props.pieceData.byId[this.props.pieceId];

                let followingPiece = this.props.lastDraggedPiece;

                while ('nextPiece' in followingPiece && !!followingPiece.nextPiece) {
                    followingPiece = this.props.pieceData.byId[followingPiece.nextPiece];
                }

                if ('nextPiece' in currentPiece && currentPiece.nextPiece) {
                    if (followingPiece.type === PieceType.END || followingPiece.type === PieceType.RETURN ||
                        followingPiece.type === PieceType.SUCCESS || followingPiece.type === PieceType.ERROR) {
                        this.props.setErrorMessage(translatePhrase('Please remove the last piece and continue'));
                        setTimeout(() => {
                            this.props.clearErrorMessage();
                        }, 4000);

                        this.setState({
                            isHoveringOverNextPiece: false,
                        });
                        return;
                    }

                    this.props.setNextPiece(followingPiece.id, currentPiece.nextPiece);
                }
            }

            this.props.setNextPiece(this.props.pieceId, this.props.lastDraggedPiece.id);
            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverNextPiece: false,
            });
        }
    }

    render() {
        return (
            <FlowchartPiece {...this.props}>
                <div>
                    <section className={styles.startPiece}>
                        <div className={styles.text}>Start</div>
                        <div className={(this.state.isHoveringOverNextPiece && this.props.isDragging && this.props.targetPiece ? styles.nextPieceHovering : styles.nextPieceHoverable) + ' attachment-target'} onMouseOver={this.handleHoverOverNextPiece} onMouseOut={this.handleHoverOutOfNextPiece}></div>
                    </section>
                </div>
                {this.props.nextPiece || undefined}
            </FlowchartPiece>
        )

    }
}

const StartPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedStartPiece);

export default StartPiece;