import { FC, useState } from 'react';
import styles from './TableWidget.module.scss';

import { ApplicationState } from '../../shared/store/types';

import { connect } from 'react-redux';

import { ReactComponent as TableIcon } from '../../common/assets/table.svg';
import { ReactComponent as ExportIcon } from '../../common/assets/export.svg';
import { ReactComponent as MoreIcon } from '../../assets/new-custom-icons/common/more.svg';
import { ReactComponent as CloseIcon } from '../../assets/action-icons/cancel.svg';
import { ReactComponent as RefreshIcon } from '../../assets/new-custom-icons/new-revision/refresh.svg';
import { ReactComponent as RightArrow } from '../../common/assets/right-arrow.svg';
import { ReactComponent as SearchIcon } from '../../common/assets/search.svg';


import { translatePhrase } from '../../shared/helpers/translation';
import { Dispatch } from 'redux';
import { setToastMessage } from '../../shared/store/my-data/actions';
import Table from '../../widgets/table/Table';
import { TableCell, TableRow } from '../../shared/helpers/common-types';
import Button from '../../widgets/button/CommonButton';
import Papa from 'papaparse';
import EnhancedInputText from '../../widgets/form/InputText';
import fi from 'date-fns/esm/locale/fi/index.js';
import { saveAs } from 'file-saver';

interface OwnProps {
    widgetId: string;
    optionsMarkup?: JSX.Element,
    tableData: Array<Array<TableCell>>,
    shouldPromptReload?: boolean,
    updateWidgetCache: () => void,
    isDashboardTableWidget?: boolean,
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    return {
        widget: state.widgets.byId[ownProps.widgetId],
        myId: state.myData.id,
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setToastMessage: (message: string) => dispatch(setToastMessage(message)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;

const ConnectedTableWidget: FC<Props> = (props) => {

    const [showMoreOptions, setShowMoreOptions] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [showSearchBar, setShowSearchBar] = useState(false);
    const [tableSearchTerm, setTableSearchTerm] = useState("");

    const handleTableSearchTerm = (searchTerm: string) => {
        setPageNumber(1);
        setTableSearchTerm(searchTerm);
    }

    const handleSearchOrCloseButtonClick = () => {
        setShowSearchBar(!showSearchBar);
        setTableSearchTerm("");
    }

    const exportData = (tableData: Array<Array<TableCell>>, title?: string) => {
        const rawTableData = tableData.map(row => {
            return row.map(cell => {
                return cell.value;
            });
        });

        const csvString = Papa.unparse(rawTableData);
        const fileBlob = new Blob([csvString], { type: 'text/csv' });

        saveAs(fileBlob, `${title ? title : 'Table'} Export.csv`);
    }

    const header = <header className={styles.showHeader}>
        <h3 title={translatePhrase(props.widget.name)} className={styles.showHeading + ' ' + (showMoreOptions ? styles.hide : '')}>
            <TableIcon />
            <div className={styles.heading}>
                <div title={translatePhrase(props.widget.name)}> {translatePhrase(props.widget.name)} </div>
                <span> {translatePhrase('Formatted Table')} </span>
            </div>
        </h3>

        {
            !showMoreOptions &&
            <div className={styles.actionButtonsContainer}>
                <Button
                    title={showSearchBar ? translatePhrase('Close') : translatePhrase('Search')}
                    icon={showSearchBar ? <CloseIcon /> : <SearchIcon />}
                    onClick={() => handleSearchOrCloseButtonClick()}
                    isRounded={true} type={showSearchBar ? 'primary' : 'secondary'}
                    size={'small'} />
                <Button
                    title={translatePhrase('More')}
                    icon={<MoreIcon />}
                    onClick={() => setShowMoreOptions(true)}
                    isRounded={true}
                    type={'secondary'}
                    size={'small'} />
            </div>
        }

        <div className={styles.moreOptionsHolder + ' ' + (showMoreOptions ? styles.active : '')}>
            <ul className={styles.moreOptions}>
                {props.optionsMarkup}
                <Button isRounded type={'secondary'} size={'small'} icon={<ExportIcon />}
                    title={translatePhrase('Export')} onClick={() => exportData(props.tableData, props.widget.name)} />
                <Button title={translatePhrase('Refresh')}
                    onClick={props.updateWidgetCache}
                    isRounded type={'secondary'} size={'small'} icon={<RefreshIcon />}
                />
                <Button title={translatePhrase('Close')}
                    onClick={() => { setShowMoreOptions(false) }}
                    isRounded type={'primary'} size={'small'} icon={<CloseIcon />} />
            </ul>
        </div>

    </header>;

    const translatedEntries: Array<TableRow> = props.tableData.map((row, index) => {
        return {
            id: String(index),
            entries: row.map(cell => {
                return {
                    ...cell,
                    value: translatePhrase(cell.value),
                }
            }),
        }
    });

    let pages: Array<JSX.Element> = []
    let totalPages = Math.ceil(translatedEntries.length / 5);
    let showingTableRows: Array<TableRow> = [];

    totalPages = Math.ceil(translatedEntries.length / 5);
    let startIndexForPages: number, endIndexForPages: number;

    if (totalPages < 5) {
        startIndexForPages = 1;
        endIndexForPages = totalPages;
    } else if (pageNumber < 5) {
        startIndexForPages = 1;
        endIndexForPages = 5
    } else if (pageNumber > (totalPages - 5)) {
        startIndexForPages = totalPages - 4;
        endIndexForPages = totalPages;
    } else {
        startIndexForPages = pageNumber - 2;
        endIndexForPages = pageNumber + 2;
    }

    for (let i = startIndexForPages; i <= endIndexForPages; i += 1) {
        pages.push(<section key={i} className={pageNumber === i ? styles.activePageLink : styles.pageLink} onClick={e => goToPage(i)} title={i ? i.toString() : ''}>{i}</section>);
    }

    const newTranslatedEntries: TableRow[][] = [];

    for (let i = 0; i < translatedEntries.length; i = i + 5) {
        newTranslatedEntries.push(translatedEntries.slice(i, i + 5));
    }

    const tableEntries = newTranslatedEntries.filter(entries => {
        const doesPageHaveSearchTerm = entries.some(tableRow => {
            const doesRowHaveSearchTerm = tableRow.entries.some(entry => {

                let newTableCell: TableCell;
                if (typeof entry !== 'object') {
                    newTableCell = {
                        value: String(entry),
                    }
                } else {
                    newTableCell = entry;
                }

                return String(newTableCell.value).toLocaleLowerCase().includes(tableSearchTerm.toLocaleLowerCase());
            });

            return doesRowHaveSearchTerm;
        });

        return doesPageHaveSearchTerm;
    });

    if (tableSearchTerm) {
        for (const tablePage of tableEntries) {
            for (const tableRow of tablePage) {
                for (let tableCell of tableRow.entries) {
                    if (typeof tableCell === 'object' && String(tableCell.value).toLocaleLowerCase().includes(tableSearchTerm.toLocaleLowerCase())) {
                        tableCell.backgroundColor = "FDDA0D";
                    }
                }
            }
        }
    }


    const startIndex = (pageNumber - 1) * 5;
    const endIndex = startIndex + 5 > tableEntries.flat().length ? tableEntries.flat().length : startIndex + 5;

    if (tableSearchTerm) {
        totalPages = Math.ceil(tableEntries.flat().length / 5);
        showingTableRows = tableEntries.flat();
    } else {
        showingTableRows = tableEntries.flat();
    }

    showingTableRows = showingTableRows.slice(startIndex, endIndex);

    const goToPage = (pageNumber: number) => {
        setPageNumber(pageNumber);
    }

    return <section className={styles.showDataContainerForTable}>
        {header}
        {
            showSearchBar &&
            <div className={styles.searchBar}>
                <EnhancedInputText
                    type="search"
                    placeholder={translatePhrase('Search within the list below')}
                    onChange={(event) => handleTableSearchTerm(event)} />
            </div>
        }
        {props.shouldPromptReload && <p className={styles.promptReloadMessage}>
            <span> {translatePhrase('Please reload to see data')} </span>
            <Button title={translatePhrase('Refresh')}
                onClick={props.updateWidgetCache}
                isRounded type={'secondary'} size={'small'} icon={<RefreshIcon />} />
        </p>}
        {!props.shouldPromptReload && <div className={styles.dataContainer}>
            <section className={styles.normalTable}>
                <section className={styles.tableHolder}>
                    <Table
                        headings={[]}
                        entries={showingTableRows}
                        isReadOnly
                        isEquidistant
                        areActionsHidden
                        shouldOverrideStyles
                        isDashboardTableWidget={props.isDashboardTableWidget}
                    />

                    <div className={styles.pageActions}>
                        {pages.length > 1 && <div className={styles.pageSize}>
                            <input type="text" onBlur={(event) => goToPage(Number(event.currentTarget.value))} key={pageNumber} defaultValue={pageNumber} />
                            <div> {translatePhrase('of')} {totalPages} </div>
                            {pageNumber > 1 && <button className={styles.prevPage} onClick={() => goToPage(pageNumber - 1)}> <RightArrow /> </button>}
                            {pageNumber < totalPages && <button className={styles.nextPage} onClick={() => goToPage(pageNumber + 1)}> <RightArrow /> </button>}
                        </div>}
                    </div>
                </section>
            </section>
        </div>}
    </section>
}

const TableWidget = connect(mapStateToProps, mapDispatchToProps)(ConnectedTableWidget);

export default TableWidget;