import React, { MouseEvent } from 'react';
import MultiSelectToggle from './MultiSelectToggle';
import styles from './Option.module.scss';


type OwnProps = {
    name: string,
    description?: string,
    value: string,
    search: string,
    isHighlighted: boolean,
    isMultiple?: boolean,
    isSelected?: boolean,

    onClick: (name: string, value: string) => void,
}

const Option: React.FC<OwnProps> = (props) => {

    const startIndex = props.name.toLocaleLowerCase().indexOf(props.search.toLocaleLowerCase());
    const endIndex = startIndex + props.search.length;
    let optionMarkup;

    const handleClick = (e: MouseEvent<HTMLElement>) => {
        props.onClick(props.name, props.value);
    }

    if (props.isMultiple) {
        optionMarkup = <MultiSelectToggle name={props.name} description={props.description ? props.description : props.name} value={props.value} isSelected={!!props.isSelected} search={props.search} />;
    } else if (props.search === '' || startIndex === endIndex) {
        optionMarkup = <span>{props.name}</span>;
    } else {
        optionMarkup = <span>{props.name.substring(0, startIndex)}<em>{props.name.substring(startIndex, endIndex)}</em>{props.name.substring(endIndex)}</span>;
    }

    return <section data-selector={`option-value-${props.name}`} title={props.description ? props.description : props.name} className={props.isHighlighted ? styles.selectedOption : styles.option} onClick={handleClick}>{optionMarkup}</section>
}

export default Option;