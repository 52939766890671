import { Component } from 'react';
import styles from './WorkflowCSV.module.scss';

import MultiSelectInputText from '../../../widgets/form/MultiSelectInput';

import { translatePhrase } from '../../../shared/helpers/translation';

import { ApplicationState } from '../../../shared/store/types';

import { connect } from 'react-redux';
import { Dispatch } from 'redux';

import { ReactComponent as ExportCSVIcon } from "../../../assets/new-custom-icons/common/export.svg";
import { ReactComponent as CancelIcon } from '../../../common/assets/close.svg';
import { ReactComponent as ExportIcon } from '../../../common/assets/export.svg';

import { CSVLink } from 'react-csv';
import moment from 'moment';

import { getReadableDataForCustomField } from '../../../shared/store/custom-fields';
import { CustomFieldValueType, CustomFieldDataForIndividualMembers, FieldType, CustomField } from '../../../shared/store/custom-fields/types';
import { isUUID } from '../../../shared/helpers/utilities';
import { setToastMessage, clearToastMessage, freezeBackground, unFreezeBackground } from '../../../shared/store/my-data/actions';
import store from '../../../shared/store/main';
import LoaderModal from '../../../widgets/loader/LoaderModal';
import { addAppError } from '../../../shared/store/errors/actions';
import Button from '../../../widgets/button/CommonButton';
import { IWorkflowType } from '../../../shared/store/workflows/types/types';
import Papa from 'papaparse';
import { getAncestorChainOfLocation } from '../../../shared/helpers/locations';
import { IWorkflow, WorkflowFilters } from '../../../shared/store/workflows/types';
import { appendGroups, clearGroupEntries } from '../../../shared/store/groups/actions';
import { IGroup } from '../../../shared/store/groups/types';
import { appendMembers, clearMemberEntries } from '../../../shared/store/members/actions';
import { IMember } from '../../../shared/store/members/types';
import { appendWorkflows, filterWorkflowTable, clearWorkflowEntries, setWorkflowTypeIdsForCSV } from '../../../shared/store/workflows/actions';
import axios from 'axios';
import { FilterResponseForOnlineEntities, OnlineWorkflowQueryData, PageDataForOnlineEntities } from '../../../shared/helpers/synchronize/types';
import { BASE_URL } from '../../../shared/store/url';
import { saveAs } from 'file-saver';
import { getFilteredWorkflows } from './selectors';
import { isWebUri } from 'valid-url';

interface AffiliationMapsForWorkflow {
    [memberTypeId: string]: Array<string>,
}

type OwnProps = {
    closeCSV: () => void,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    return {
        structureData: state.structure,
        usersData: state.users,
        membersData: state.members,
        groupsData: state.groups,
        workflowData: state.workflows,
        languageData: state.internationalization.languages,
        myId: state.myData.id,
        orgCode: state.organization.code,
        isSuperUser: !isUUID(state.myData.id),
        applicationState: state,
        isOnline: state.myData.isOnline,
        totalNoOfWorkflows: state.workflows.totalNoOfWorkflows,
    }
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setToastMessage: (message: string, persistMessage: boolean) => dispatch(setToastMessage(message, persistMessage)),
        clearToastMessage: () => dispatch(clearToastMessage()),

        freezeBackground: () => dispatch(freezeBackground()),
        unFreezeBackground: () => dispatch(unFreezeBackground()),

        addError: (message: string, context: string, location: string, userId: string) => dispatch(addAppError(message, context, location, userId)),

        appendMembers: (members: Array<IMember>) => dispatch(appendMembers(members)),
        appendGroups: (members: Array<IGroup>) => dispatch(appendGroups(members)),
        appendWorkflows: (workflows: Array<IWorkflow>) => dispatch(appendWorkflows(workflows)),

        filterWorkflowTable: (dues: Array<string>, projects: Array<string>, types: Array<string>, statuses: Array<string>, users: Array<string>, locations: Array<string>, otherUsers: Array<string>, affiliations: Array<string>, customFields: { [customFieldId: string]: Array<string> }, createdDateRange: Array<string>, lastUpdatedDateRange: Array<string>, lastWorkedOn: { startTime?: string, endTime?: string }, dueDateRange: Array<string>, unsynced: boolean, archived: boolean, isOutdated: boolean) => dispatch(filterWorkflowTable(dues, projects, types, statuses, users, locations, otherUsers, affiliations, customFields, createdDateRange, lastUpdatedDateRange, lastWorkedOn, dueDateRange, unsynced, archived, isOutdated)),

        clearMemberEntries: () => dispatch(clearMemberEntries()),
        clearGroupEntries: () => dispatch(clearGroupEntries()),
        clearWorkflowEntries: () => dispatch(clearWorkflowEntries()),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

interface WorkflowTypeExport {
    name: string;
    data: Array<Array<string>>;
}

type OwnState = {
    successMessage: string,
    errorMessage: string,
    exportData: Array<WorkflowTypeExport>,
    erroneousData: Array<Array<string>>,
    showLoader: boolean,
    loaderText: Array<string>,
};

class ConnectedWorkflowModify extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            successMessage: '',
            errorMessage: '',
            exportData: [],
            erroneousData: [],
            showLoader: false,
            loaderText: []
        }
    }

    changeWorkflowTypes = (workflowTypeIds: Array<string>) => {


        this.props.filterWorkflowTable(
            this.props.workflowData.filters.dues,
            this.props.workflowData.filters.projects,
            workflowTypeIds,
            this.props.workflowData.filters.statuses,
            this.props.workflowData.filters.users,
            this.props.workflowData.filters.locations,
            this.props.workflowData.filters.otherUsers,
            this.props.workflowData.filters.affiliations,
            this.props.workflowData.filters.customFields,
            this.props.workflowData.filters.createdDateRange,
            this.props.workflowData.filters.lastUpdatedDateRange,
            this.props.workflowData.filters.lastWorkedOn,
            this.props.workflowData.filters.dueDateRange,
            this.props.workflowData.filters.unsynced,
            this.props.workflowData.filters.archived,
            this.props.workflowData.filters.isOutdated,
        );
    }

    getLocationData = (locationId: string, projectId: string, lastLevelId?: string) => {
        const locations = getAncestorChainOfLocation(locationId).reverse().concat(locationId);

        const project = this.props.structureData.projects.byId[projectId];
        const rowData: Array<string> = [];

        let locationName = '-'

        for (let i = 0; i < locations.length; i += 1) {
            const locationId = locations[i];
            const location = this.props.structureData.locations.byId[locationId];

            const levelId = project.children[i];
            const level = this.props.structureData.levels.byId[levelId];

            locationName = location && location.name ? location.name : '-'
            rowData.push(locationName);

            for (const customFieldId of level.customFields) {
                const customField = this.props.structureData.levels.customFields.byId[customFieldId];
                const readableValue = getReadableDataForCustomField(location.customFields[customField.id], customField, location.id, 'level');
                rowData.push(readableValue);
            }

            if (levelId === lastLevelId) {
                break;
            }
        }

        return rowData;
    }

    isCustomFieldReadable = (customField: CustomField) => {
        return customField.type === FieldType.TEXT ||
            customField.type === FieldType.DATE ||
            customField.type === FieldType.NUMBER ||
            customField.type === FieldType.PHONE ||
            customField.type === FieldType.BOOLEAN ||
            customField.type === FieldType.SINGLE_SELECT ||
            customField.type === FieldType.FILE ||
            customField.type === FieldType.FREE_TEXT ||
            customField.type === FieldType.MULTI_SELECT ||
            customField.type === FieldType.LOCATION;
    }

    getFileUrlForOfflineExport = (value: string) => {
        if (value.startsWith('http') && value.includes('diceflow.in')) {
            const fileUrlValue = value.split('/');
            const firstUriComponent = fileUrlValue[4] ? encodeURIComponent(fileUrlValue[4]) : '';
            const secondUriComponent = fileUrlValue[5] ? encodeURIComponent('/') + encodeURIComponent(fileUrlValue[5]) : '';
            return window.location.origin + '/file-download/' + firstUriComponent + secondUriComponent;

        } else if (/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/.test(value)) {
            return 'File data not synced';
        } else if (value.startsWith('/file')) {
            const fileName = value.slice(1)
            return window.location.origin + '/file-download/' + encodeURIComponent(fileName);
        };

        return value;
    }

    writeNonAffiliatedWorkflowTypeRows = (exportDataForQualifiedWorkflows: Array<Array<string>>, workflowTypeId: string, qualifiedWorkflows: Array<IWorkflow>) => {
        const workflowType = this.props.workflowData.types.byId[workflowTypeId];

        if (workflowType.affiliation !== 'none') {
            return;
        }

        const qualifiedWorkflowIdsForType = qualifiedWorkflows.filter(workflow => workflow.type === workflowTypeId).map(workflow => workflow.id)

        if (qualifiedWorkflowIdsForType.length === 0) {
            return;
        }

        const exportHeaderForWorkflowType = this.getExportFileHeader(workflowType);
        exportDataForQualifiedWorkflows.push(exportHeaderForWorkflowType);

        const exportingCustomFieldIds = workflowType.customFields
            .filter(customFieldId => {
                const customField = this.props.workflowData.types.customFields.byId[customFieldId];
                return customField.id !== workflowType.subTitleFieldId && this.isCustomFieldReadable(customField);
            });


        for (const workflowId of qualifiedWorkflowIdsForType) {
            try {
                const workflow = this.props.workflowData.byId[workflowId];
                const rowData: Array<string> = [];

                rowData.push(workflow.id);

                let locationName: string = '-';
                let affiliationName: string = '-';

                rowData.push(workflowType.name);

                if (workflowType.subTitleFieldId) {
                    const subtitleField = this.props.workflowData.types.customFields.byId[workflowType.subTitleFieldId];
                    const processState = workflow.history[workflow.historyIndex];

                    const customFieldValue = processState.customFields[subtitleField.id] as CustomFieldValueType;
                    const subtitleValue = getReadableDataForCustomField(customFieldValue, subtitleField, workflow.id, 'workflow');
                    rowData.push(subtitleValue);
                } else {
                    rowData.push(affiliationName);
                }

                rowData.push(moment(workflow.createdTime).format('DD MMM YYYY hh:mm A'));

                const processState = workflow.historyIndex >= workflow.history.length ? workflow.history[workflow.history.length - 1] : workflow.history[workflow.historyIndex];
                rowData.push(moment(processState.executionTime).format('DD MMM YYYY hh:mm A'));

                rowData.push(locationName);

                const status = this.props.workflowData.types.statuses.byId[workflow.status];
                rowData.push(status.name);
                rowData.push(moment(workflow.dueDate).format('DD MMM YYYY'));

                const user = this.props.usersData.byId[workflow.user];
                const userNameField = this.props.usersData.customFields.byId[this.props.usersData.nameFieldId];
                const userNameValue = getReadableDataForCustomField(user.customFields[userNameField.id], userNameField, user.id, 'user');

                const userSubTitleField = this.props.usersData.customFields.byId[this.props.usersData.subTitleFieldId];
                const userSubTitleValue = getReadableDataForCustomField(user.customFields[userSubTitleField.id], userSubTitleField, user.id, 'user');

                const userName = `${userNameValue} (${userSubTitleValue})`;
                rowData.push(userName);

                for (const customFieldId of exportingCustomFieldIds) {
                    const customField = this.props.workflowData.types.customFields.byId[customFieldId];

                    const customFieldValue = processState.customFields[customFieldId] as CustomFieldValueType;
                    let readableValue = getReadableDataForCustomField(customFieldValue, customField, workflow.id, 'workflow');

                    if (customField.type === FieldType.FREE_TEXT) {
                        let dataToPush = readableValue === '-' ? 'Not Available' : 'Available';
                        rowData.push(dataToPush);
                    } else if (customField.type === FieldType.FILE) {
                        const fileValue = this.getFileUrlForOfflineExport(readableValue);
                        rowData.push(fileValue);
                    } else {
                        rowData.push(readableValue);
                    }

                }

                exportDataForQualifiedWorkflows.push(rowData);

            } catch (error) {
                if (error instanceof Error) {
                    this.props.addError(error.message, 'Workflow export for ' + workflowId, window.location.href, this.props.myId);
                }
            }
        }

        exportDataForQualifiedWorkflows.push([]);

    }

    writeMemberAffiliatedWorkflowTypeRows = (exportDataForQualifiedWorkflows: Array<Array<string>>, workflowTypeId: string, qualifiedWorkflows: Array<IWorkflow>) => {
        const workflowType = this.props.workflowData.types.byId[workflowTypeId];

        if (workflowType.affiliation !== 'member') {
            return;
        };

        const workflowMapsForMemberTypes: AffiliationMapsForWorkflow = {};

        if (workflowType.affiliatedEntity && isUUID(workflowType.affiliatedEntity)) {
            workflowMapsForMemberTypes[workflowType.affiliatedEntity] = qualifiedWorkflows
                .filter(workflow => workflow.type === workflowTypeId)
                .map(workflow => workflow.id);
        } else {
            for (const workflow of qualifiedWorkflows) {

                if (workflow.type !== workflowTypeId) {
                    continue;
                }

                const member = this.props.membersData.byId[workflow.affiliatedEntity];

                if (!member) {
                    continue;
                }

                if (!(member.type in workflowMapsForMemberTypes)) {
                    workflowMapsForMemberTypes[member.type] = [];
                };

                workflowMapsForMemberTypes[member.type].push(workflow.id);
            }
        }

        const exportingCustomFieldIds = workflowType.customFields
            .filter(customFieldId => {
                const customField = this.props.workflowData.types.customFields.byId[customFieldId];

                return customField.id !== workflowType.subTitleFieldId && this.isCustomFieldReadable(customField);
            });

        for (const memberTypeId in workflowMapsForMemberTypes) {
            const qualifiedWorkflowIdsForType = workflowMapsForMemberTypes[memberTypeId];

            if (qualifiedWorkflowIdsForType.length === 0) {
                continue;
            };

            const exportHeaderForWorkflowType = this.getExportFileHeader(workflowType, memberTypeId);
            exportDataForQualifiedWorkflows.push(exportHeaderForWorkflowType);

            for (const workflowId of qualifiedWorkflowIdsForType) {
                try {
                    const workflow = this.props.workflowData.byId[workflowId];
                    const rowData: Array<string> = [];

                    rowData.push(workflow.id);

                    let locationName: string = '-';
                    let affiliationName: string = '-';

                    rowData.push(workflowType.name);

                    const member = this.props.membersData.byId[workflow.affiliatedEntity];
                    const currentLocation = this.props.structureData.locations.byId[member.location];

                    const memberType = this.props.membersData.types.byId[member.type];
                    const nameField = this.props.membersData.types.customFields.byId[memberType.nameFieldId];
                    const nameValue = getReadableDataForCustomField(member.customFields[nameField.id], nameField, member.id, 'member');

                    const subTitleField = this.props.membersData.types.customFields.byId[memberType.subTitleFieldId];
                    const subTitleValue = getReadableDataForCustomField(member.customFields[subTitleField.id], subTitleField, member.id, 'member');

                    const otherMemberTypeFieldIds = memberType.customFields
                        .filter(customFieldId => {
                            const customField = this.props.membersData.types.customFields.byId[customFieldId];
                            return customField.id !== memberType.nameFieldId && customField.id !== memberType.subTitleFieldId && this.isCustomFieldReadable(customField);
                        });

                    affiliationName = `${nameValue} (${subTitleValue})`;
                    locationName = currentLocation && currentLocation.name ? currentLocation.name : "-";

                    if (workflowType.subTitleFieldId) {
                        const subtitleField = this.props.workflowData.types.customFields.byId[workflowType.subTitleFieldId];
                        const processState = workflow.history[workflow.historyIndex];

                        const customFieldValue = processState.customFields[subtitleField.id] as CustomFieldValueType;
                        const subtitleValue = getReadableDataForCustomField(customFieldValue, subtitleField, workflow.id, 'workflow');
                        rowData.push(subtitleValue);
                    } else {
                        rowData.push(affiliationName);
                    }

                    rowData.push(moment(workflow.createdTime).format('DD MMM YYYY hh:mm A'));

                    const processState = workflow.historyIndex >= workflow.history.length ? workflow.history[workflow.history.length - 1] : workflow.history[workflow.historyIndex];
                    rowData.push(moment(processState.executionTime).format('DD MMM YYYY hh:mm A'));

                    rowData.push(locationName);

                    const status = this.props.workflowData.types.statuses.byId[workflow.status];
                    rowData.push(status.name);
                    rowData.push(moment(workflow.dueDate).format('DD MMM YYYY'));

                    const user = this.props.usersData.byId[workflow.user];
                    let userName = 'User Not Fetched';

                    try {
                        if (user) {
                            const userNameField = this.props.usersData.customFields.byId[this.props.usersData.nameFieldId];
                            const userCustomField = user.customFields[userNameField.id];
                            const userNameValue = getReadableDataForCustomField(userCustomField, userNameField, user.id, 'user');

                            const userSubTitleField = this.props.usersData.customFields.byId[this.props.usersData.subTitleFieldId];
                            const userSubTitleValue = getReadableDataForCustomField(user.customFields[userSubTitleField.id], userSubTitleField, user.id, 'user');

                            userName = `${userNameValue} (${userSubTitleValue})`;
                        }
                    } catch (error) {
                        console.error('An error occurred while processing user data:', error);
                    };

                    rowData.push(userName);

                    for (const customFieldId of exportingCustomFieldIds) {
                        const customField = this.props.workflowData.types.customFields.byId[customFieldId];

                        const customFieldValue = processState.customFields[customFieldId] as CustomFieldValueType;
                        let readableValue = getReadableDataForCustomField(customFieldValue, customField, workflow.id, 'workflow');

                        if (customField.type === FieldType.FREE_TEXT) {
                            let dataToPush = readableValue === '-' ? 'Not Available' : 'Available';
                            rowData.push(dataToPush);
                        } else if (customField.type === FieldType.FILE) {
                            const fileValue = this.getFileUrlForOfflineExport(readableValue);
                            rowData.push(fileValue);
                        } else {
                            rowData.push(readableValue);
                        };

                    }

                    const isArchivedMemeber = member.archived ? "Yes" : "No"

                    rowData.push(member.id);
                    rowData.push(moment(member.createdTime).format('DD MMM YYYY hh:mm A'));
                    rowData.push(moment(member.lastUpdatedTime).format('DD MMM YYYY hh:mm A'));
                    rowData.push(nameValue);
                    rowData.push(subTitleValue);
                    rowData.push(isArchivedMemeber)

                    if (member) {
                        for (const customFieldId of otherMemberTypeFieldIds) {
                            const customField = this.props.membersData.types.customFields.byId[customFieldId];
                            let readableValue = getReadableDataForCustomField(member.customFields[customField.id], customField, member.id, 'member');

                            if (customField.type === FieldType.FREE_TEXT) {
                                let dataToPush = readableValue === '-' ? 'Not Available' : 'Available';
                                rowData.push(dataToPush);
                            } else if (customField.type === FieldType.FILE) {
                                const fileValue = this.getFileUrlForOfflineExport(readableValue);
                                rowData.push(fileValue);
                            } else {
                                rowData.push(readableValue);
                            }
                        };

                        const locationData = this.getLocationData(member.location, memberType.project);

                        for (const locationDatum of locationData) {
                            rowData.push(this.getFileUrlForOfflineExport(locationDatum));
                        }
                    }

                    exportDataForQualifiedWorkflows.push(rowData);

                } catch (error) {
                    if (error instanceof Error) {
                        this.props.addError(error.message, 'Workflow export for ' + workflowId, window.location.href, this.props.myId);
                    }
                }
            }

            exportDataForQualifiedWorkflows.push([]);
        }
    }

    writeGroupAffiliatedWorkflowTypeRows = (exportDataForQualifiedWorkflows: Array<Array<string>>, workflowTypeId: string, qualifiedWorkflows: Array<IWorkflow>) => {
        const workflowType = this.props.workflowData.types.byId[workflowTypeId];

        if (workflowType.affiliation !== 'group') {
            return;
        }

        const workflowMapsForGroupTypes: AffiliationMapsForWorkflow = {};

        if (workflowType.affiliatedEntity && isUUID(workflowType.affiliatedEntity)) {
            workflowMapsForGroupTypes[workflowType.affiliatedEntity] = qualifiedWorkflows
                .filter(workflow => workflow.type === workflowTypeId)
                .map(workflow => workflow.id);
        } else {
            for (const workflow of qualifiedWorkflows) {

                if (workflow.type !== workflowTypeId) {
                    continue;
                }

                const group = this.props.groupsData.byId[workflow.affiliatedEntity];

                if (!group) {
                    continue;
                }

                if (!(group.type in workflowMapsForGroupTypes)) {
                    workflowMapsForGroupTypes[group.type] = [];
                }

                workflowMapsForGroupTypes[group.type].push(workflow.id);
            }
        }

        const exportingCustomFieldIds = workflowType.customFields
            .filter(customFieldId => {
                const customField = this.props.workflowData.types.customFields.byId[customFieldId];

                return customField.id !== workflowType.subTitleFieldId && this.isCustomFieldReadable(customField);
            });

        const hasIndividualMemberFields = workflowType.affiliation === 'group' && exportingCustomFieldIds.some(customFieldId => {
            const customField = this.props.workflowData.types.customFields.byId[customFieldId];
            return customField.affiliation === 'member';
        });

        for (const groupTypeId in workflowMapsForGroupTypes) {
            const qualifiedWorkflowIdsForType = workflowMapsForGroupTypes[groupTypeId];

            if (qualifiedWorkflowIdsForType.length === 0) {
                continue;
            }

            const exportHeaderForWorkflowType = this.getExportFileHeader(workflowType, groupTypeId);
            exportDataForQualifiedWorkflows.push(exportHeaderForWorkflowType);

            for (const workflowId of qualifiedWorkflowIdsForType) {
                try {
                    const workflow = this.props.workflowData.byId[workflowId];
                    let rowData: Array<string> = [];

                    rowData.push(workflow.id);

                    let locationName: string = '-';
                    let affiliationName: string = '-';

                    rowData.push(workflowType.name);

                    const group = this.props.groupsData.byId[workflow.affiliatedEntity];
                    const currentLocation = this.props.structureData.locations.byId[group.location];

                    const groupType = this.props.groupsData.types.byId[group.type];
                    const nameField = this.props.groupsData.types.customFields.byId[groupType.nameFieldId];
                    const nameValue = getReadableDataForCustomField(group.customFields[nameField.id], nameField, group.id, 'group');

                    const subTitleField = this.props.groupsData.types.customFields.byId[groupType.subTitleFieldId];
                    const subTitleValue = getReadableDataForCustomField(group.customFields[subTitleField.id], subTitleField, group.id, 'group');

                    const otherGroupTypeFieldIds = groupType.customFields
                        .filter(customFieldId => {
                            const customField = this.props.groupsData.types.customFields.byId[customFieldId];
                            return customField.id !== groupType.nameFieldId && customField.id !== groupType.subTitleFieldId && this.isCustomFieldReadable(customField);
                        });

                    affiliationName = `${nameValue} (${subTitleValue})`;
                    locationName = currentLocation && currentLocation.name ? currentLocation.name : '-';

                    if (workflowType.subTitleFieldId) {
                        const subtitleField = this.props.workflowData.types.customFields.byId[workflowType.subTitleFieldId];
                        const processState = workflow.history[workflow.historyIndex];

                        const customFieldValue = processState.customFields[subtitleField.id] as CustomFieldValueType;
                        const subtitleValue = getReadableDataForCustomField(customFieldValue, subtitleField, workflow.id, 'workflow');
                        rowData.push(subtitleValue);
                    } else {
                        rowData.push(affiliationName);
                    }

                    rowData.push(moment(workflow.createdTime).format('DD MMM YYYY hh:mm A'));

                    const processState = workflow.historyIndex >= workflow.history.length ? workflow.history[workflow.history.length - 1] : workflow.history[workflow.historyIndex];
                    rowData.push(moment(processState.executionTime).format('DD MMM YYYY hh:mm A'));

                    rowData.push(locationName);

                    const status = this.props.workflowData.types.statuses.byId[workflow.status];
                    rowData.push(status.name);
                    rowData.push(moment(workflow.dueDate).format('DD MMM YYYY'));

                    const user = this.props.usersData.byId[workflow.user];
                    const userNameField = this.props.usersData.customFields.byId[this.props.usersData.nameFieldId];
                    const userNameValue = getReadableDataForCustomField(user.customFields[userNameField.id], userNameField, user.id, 'user');

                    const userSubTitleField = this.props.usersData.customFields.byId[this.props.usersData.subTitleFieldId];
                    const userSubTitleValue = getReadableDataForCustomField(user.customFields[userSubTitleField.id], userSubTitleField, user.id, 'user');

                    const userName = `${userNameValue} (${userSubTitleValue})`;
                    rowData.push(userName);

                    const customFieldStartIndex = rowData.length;
                    let memberDataStartIndex: number | undefined;
                    const memberIds: Set<string> = new Set();

                    for (const customFieldId of exportingCustomFieldIds) {
                        const customField = this.props.workflowData.types.customFields.byId[customFieldId];

                        if (customField.affiliation === 'member') {
                            const groupCustomFieldValue = processState.customFields[customFieldId] as CustomFieldDataForIndividualMembers;

                            for (const memberId in groupCustomFieldValue) {
                                if (!this.props.membersData.byId[memberId].archived) {
                                    memberIds.add(memberId);
                                }
                            }
                            rowData.push('');
                        } else {
                            const customFieldValue = processState.customFields[customFieldId] as CustomFieldValueType;
                            let readableValue = getReadableDataForCustomField(customFieldValue, customField, workflow.id, 'workflow');

                            if (customField.type === FieldType.FREE_TEXT) {
                                let dataToPush = readableValue === '-' ? 'Not Available' : 'Available';
                                rowData.push(dataToPush);
                            } else if (customField.type === FieldType.FILE) {
                                const fileValue = this.getFileUrlForOfflineExport(readableValue);
                                rowData.push(fileValue);
                            } else {
                                rowData.push(readableValue);
                            }
                        }
                    }

                    const isArchivedGroup = group.archived ? "Yes" : "No"

                    rowData.push(group.id);
                    rowData.push(moment(group.createdTime).format('DD MMM YYYY hh:mm A'));
                    rowData.push(moment(group.lastUpdatedTime).format('DD MMM YYYY hh:mm A'));
                    rowData.push(nameValue);
                    rowData.push(subTitleValue);
                    rowData.push(isArchivedGroup);

                    if (group) {
                        for (const customFieldId of otherGroupTypeFieldIds) {
                            const customField = this.props.groupsData.types.customFields.byId[customFieldId];
                            let readableValue = getReadableDataForCustomField(group.customFields[customField.id], customField, group.id, 'group');

                            if (customField.type === FieldType.FREE_TEXT) {
                                let dataToPush = readableValue === '-' ? 'Not Available' : 'Available';
                                rowData.push(dataToPush);
                            } else if (customField.type === FieldType.FILE) {
                                const fileValue = this.getFileUrlForOfflineExport(readableValue);
                                rowData.push(fileValue);
                            } else {
                                rowData.push(readableValue);
                            }
                        }

                        memberDataStartIndex = rowData.length;

                        if (hasIndividualMemberFields) {
                            rowData.push('');
                            rowData.push('');
                            rowData.push('');
                            rowData.push('');
                        }

                        const locationData = this.getLocationData(group.location, groupType.project);

                        for (const locationDatum of locationData) {
                            rowData.push(this.getFileUrlForOfflineExport(locationDatum));
                        }
                    };

                    if (hasIndividualMemberFields) {
                        if (memberIds.size > 0) {

                            for (const memberId of memberIds) {
                                rowData = rowData.slice();

                                let customFieldCount = 0;
                                for (const customFieldId of exportingCustomFieldIds) {
                                    const customField = this.props.workflowData.types.customFields.byId[customFieldId];

                                    if (customField.affiliation === 'member') {
                                        const groupCustomFieldValue = processState.customFields[customFieldId] as CustomFieldDataForIndividualMembers;
                                        const member = this.props.membersData.byId[memberId];
                                        const memberType = this.props.membersData.types.byId[member.type];
                                        const nameField = this.props.membersData.types.customFields.byId[memberType.nameFieldId];
                                        const nameValue = getReadableDataForCustomField(member.customFields[nameField.id], nameField, member.id, 'member', this.props.applicationState);

                                        const subTitleField = this.props.membersData.types.customFields.byId[memberType.subTitleFieldId];
                                        const subTitleValue = getReadableDataForCustomField(member.customFields[subTitleField.id], subTitleField, member.id, 'member', this.props.applicationState);
                                        const isArchivedMemeber = member.archived ? "Yes" : "No"
                                        const valueForMember = groupCustomFieldValue && memberId in groupCustomFieldValue ? getReadableDataForCustomField(groupCustomFieldValue[memberId], customField, workflow.id, 'workflow') : '-';

                                        rowData[customFieldStartIndex + customFieldCount] = valueForMember;

                                        if (memberDataStartIndex) {
                                            rowData[memberDataStartIndex] = memberId;
                                            rowData[memberDataStartIndex + 1] = nameValue;
                                            rowData[memberDataStartIndex + 2] = subTitleValue;
                                            rowData[memberDataStartIndex + 3] = isArchivedMemeber;
                                        }
                                    }

                                    customFieldCount += 1;
                                };
                                exportDataForQualifiedWorkflows.push(rowData);
                            }
                        } else {

                            let customFieldCount = 0;
                            rowData = rowData.slice();

                            for (const customFieldId of exportingCustomFieldIds) {
                                const customField = this.props.workflowData.types.customFields.byId[customFieldId];


                                if (customField.affiliation === 'member') {
                                    rowData[customFieldStartIndex + customFieldCount] = '-';
                                }

                                customFieldCount += 1;

                            }

                            if (memberDataStartIndex) {
                                rowData[memberDataStartIndex] = '-';
                                rowData[memberDataStartIndex + 1] = '-';
                                rowData[memberDataStartIndex + 2] = '-';
                                rowData[memberDataStartIndex + 3] = '-';
                            }
                            exportDataForQualifiedWorkflows.push(rowData);

                        }
                    } else {
                        exportDataForQualifiedWorkflows.push(rowData);
                    }
                } catch (error) {
                    if (error instanceof Error) {
                        this.props.addError(error.message, 'Workflow export for ' + workflowId, window.location.href, this.props.myId);
                        console.error('Workflow export for ' + workflowId, error);
                    }
                }
            }

            exportDataForQualifiedWorkflows.push([]);
        }
    }

    getOnlineWorkflowFilters = async () => {
        const serverUrl = BASE_URL + '/online-workflow-filters/';

        const responseData = await axios.get<OnlineWorkflowQueryData>(serverUrl, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });

        return responseData.data;
    }

    applyOnlineWorkflowFilters = async (filters: WorkflowFilters, searchTerm: string, pageSize: number) => {
        const serverUrl = new URL('/online-workflow-filters/', BASE_URL);

        const queryData: OnlineWorkflowQueryData = {
            filters,
            searchTerm,
            pageSize,
        }

        return axios.post<FilterResponseForOnlineEntities>(serverUrl.toString(), queryData, {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });
    }

    getPageData = async (pageNumber: number, pageSize: number) => {
        const serverUrl = new URL('/online-workflows/', BASE_URL);

        serverUrl.searchParams.set('pageSize', String(pageSize));
        serverUrl.searchParams.set('currentPageNumber', String(pageNumber));
        serverUrl.searchParams.set('summary', String(true));

        const responseData = await axios.get<PageDataForOnlineEntities>(serverUrl.toString(), {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token')
            }
        });

        return responseData.data;
    }

    getWorkflowDataToExport = async () => {

        let workflowTypeIds: Array<string> = [];

        if (this.props.workflowData.filters.types.length > 0) {
            workflowTypeIds = this.props.workflowData.filters.types;
        } else {
            workflowTypeIds = this.props.workflowData.types.allEntries
        };

        let applicationState: ApplicationState = store.getState();
        applicationState = {
            ...applicationState,
            workflows: {
                ...applicationState.workflows,
                filters: {
                    ...applicationState.workflows.filters,
                },
            }
        };

        const exportDataForQualifiedWorkflows: Array<Array<string>> = [];

        // Override the types filter with the selected types
        applicationState.workflows.filters.types = workflowTypeIds;


        if (this.props.isOnline) {
            const PAGE_SIZE_FOR_EXPORT = 10_000;

            const originalWorkflowQueryData = await this.getOnlineWorkflowFilters();

            for (const workflowTypeId of workflowTypeIds) {
                const workflowType = this.props.workflowData.types.byId[workflowTypeId];

                const filterForType: WorkflowFilters = {
                    ...originalWorkflowQueryData.filters,
                    types: [workflowType.id],
                }

                let applicationState = store.getState();
                applicationState = {
                    ...applicationState,
                    workflows: {
                        ...applicationState.workflows,
                        filters: {
                            ...applicationState.workflows.filters,
                        },
                    }
                };

                const axiosResponse = await this.applyOnlineWorkflowFilters(filterForType, originalWorkflowQueryData.searchTerm, PAGE_SIZE_FOR_EXPORT);
                const noOfPages = Math.ceil(axiosResponse.data.totalNumber / PAGE_SIZE_FOR_EXPORT);

                for (let i = 0; i < noOfPages; i += 1) {
                    const pageNumber = i + 1;
                    const pageData = await this.getPageData(pageNumber, PAGE_SIZE_FOR_EXPORT);

                    this.props.clearMemberEntries();
                    this.props.clearGroupEntries();
                    this.props.clearWorkflowEntries();

                    this.props.appendMembers(pageData.members);
                    this.props.appendGroups(pageData.groups);
                    this.props.appendWorkflows(pageData.workflows);

                    applicationState = store.getState();
                    applicationState = {
                        ...applicationState,
                        workflows: {
                            ...applicationState.workflows,
                            filters: {
                                ...applicationState.workflows.filters,
                            },
                        }
                    };

                    if (workflowType.affiliation === 'none') {
                        this.writeNonAffiliatedWorkflowTypeRows(exportDataForQualifiedWorkflows, workflowTypeId, pageData.workflows);
                    } else if (workflowType.affiliation === 'member') {
                        this.writeMemberAffiliatedWorkflowTypeRows(exportDataForQualifiedWorkflows, workflowTypeId, pageData.workflows);
                    } else if (workflowType.affiliation === 'group') {
                        this.writeGroupAffiliatedWorkflowTypeRows(exportDataForQualifiedWorkflows, workflowTypeId, pageData.workflows);
                    };

                }
            }

            this.props.filterWorkflowTable(
                originalWorkflowQueryData.filters.dues,
                originalWorkflowQueryData.filters.projects,
                originalWorkflowQueryData.filters.types,
                originalWorkflowQueryData.filters.statuses,
                originalWorkflowQueryData.filters.users,
                originalWorkflowQueryData.filters.locations,
                originalWorkflowQueryData.filters.otherUsers,
                originalWorkflowQueryData.filters.affiliations,
                originalWorkflowQueryData.filters.customFields,
                originalWorkflowQueryData.filters.createdDateRange,
                originalWorkflowQueryData.filters.lastUpdatedDateRange,
                originalWorkflowQueryData.filters.lastWorkedOn,
                originalWorkflowQueryData.filters.dueDateRange,
                originalWorkflowQueryData.filters.unsynced,
                originalWorkflowQueryData.filters.archived,
                originalWorkflowQueryData.filters.isOutdated,
            );

        } else {
            const qualifiedWorkflows = getFilteredWorkflows(applicationState);


            for (const workflowTypeId of workflowTypeIds) {
                const workflowType = this.props.workflowData.types.byId[workflowTypeId];

                if (workflowType.affiliation === 'none') {
                    this.writeNonAffiliatedWorkflowTypeRows(exportDataForQualifiedWorkflows, workflowTypeId, qualifiedWorkflows);
                } else if (workflowType.affiliation === 'member') {
                    this.writeMemberAffiliatedWorkflowTypeRows(exportDataForQualifiedWorkflows, workflowTypeId, qualifiedWorkflows);
                } else if (workflowType.affiliation === 'group') {
                    this.writeGroupAffiliatedWorkflowTypeRows(exportDataForQualifiedWorkflows, workflowTypeId, qualifiedWorkflows);
                }
            }
        }

        return exportDataForQualifiedWorkflows;

    }

    getLocationExportHeader = (projectId: string, lastLevelId?: string) => {
        const project = this.props.structureData.projects.byId[projectId];
        const templateMarkup: Array<string> = [];

        for (const levelId of project.children) {
            const level = this.props.structureData.levels.byId[levelId];

            templateMarkup.push(level.name);

            for (const customFieldId of level.customFields) {
                const customField = this.props.structureData.levels.customFields.byId[customFieldId];
                templateMarkup.push(`${level.name} custom field: ${customField.name}`);
            }

            if (levelId === lastLevelId) {
                break;
            }
        }

        return templateMarkup;
    }

    getExportFileHeader = (workflowType: IWorkflowType, affiliationTypeId?: string) => {
        let templateMarkup: Array<string> = [];

        const subtitleFieldName = workflowType.subTitleFieldId && workflowType.subTitleFieldId in this.props.workflowData.types.customFields.byId ? this.props.workflowData.types.customFields.byId[workflowType.subTitleFieldId].name : undefined;

        templateMarkup.push('ID');
        templateMarkup.push('Type');
        templateMarkup.push(subtitleFieldName ? `Subtitle (${subtitleFieldName})` : 'Subtitle');
        templateMarkup.push('Created time');
        templateMarkup.push('Last worked on');
        templateMarkup.push('Location');
        templateMarkup.push('Status');
        templateMarkup.push('Due date');
        templateMarkup.push('User');

        const workflowTypeCustomFieldsToExport = workflowType.customFields
            .filter(customFieldId => {
                const customField = this.props.workflowData.types.customFields.byId[customFieldId];

                return customField.id !== workflowType.subTitleFieldId && this.isCustomFieldReadable(customField);
            });

        for (const customFieldId of workflowTypeCustomFieldsToExport) {
            const customField = this.props.workflowData.types.customFields.byId[customFieldId];

            templateMarkup.push(customField.name);
        }

        const hasIndividualMemberFields = workflowType.affiliation === 'group' && workflowTypeCustomFieldsToExport.some(customFieldId => {
            const customField = this.props.workflowData.types.customFields.byId[customFieldId];
            return customField.affiliation === 'member';
        });

        if (workflowType.affiliation !== 'none') {
            const affiliationType = workflowType.affiliation[0].toUpperCase() + workflowType.affiliation.substring(1);
            templateMarkup.push(affiliationType + ' System ID');
            templateMarkup.push(affiliationType + ' Created timestamp');
            templateMarkup.push(affiliationType + ' Last Updated timestamp');
            templateMarkup.push(affiliationType + ' Name');
            templateMarkup.push(affiliationType + ' Subtitle');
            templateMarkup.push(affiliationType + ' Archived');
        }

        if (workflowType.affiliation === 'member' && affiliationTypeId) {
            const memberType = this.props.membersData.types.byId[affiliationTypeId];

            const exportingMemberTypeCustomFields = memberType.customFields
                .filter(customFieldId => {
                    const customField = this.props.membersData.types.customFields.byId[customFieldId];
                    return customField.id !== memberType.nameFieldId && customField.id !== memberType.subTitleFieldId && this.isCustomFieldReadable(customField);
                });

            for (const customFieldId of exportingMemberTypeCustomFields) {
                const customField = this.props.membersData.types.customFields.byId[customFieldId];

                templateMarkup.push('Member custom field: ' + customField.name);
            }

            const locationHeaders = this.getLocationExportHeader(memberType.project);
            templateMarkup = templateMarkup.concat(locationHeaders);
        } else if (workflowType.affiliation === 'group' && affiliationTypeId) {
            const groupType = this.props.groupsData.types.byId[affiliationTypeId];

            const exportingGroupTypeCustomFields = groupType.customFields
                .filter(customFieldId => {
                    const customField = this.props.groupsData.types.customFields.byId[customFieldId];
                    return customField.id !== groupType.nameFieldId && customField.id !== groupType.subTitleFieldId && this.isCustomFieldReadable(customField);
                });

            for (const customFieldId of exportingGroupTypeCustomFields) {
                const customField = this.props.groupsData.types.customFields.byId[customFieldId];

                templateMarkup.push('Group custom field: ' + customField.name);
            }

            if (hasIndividualMemberFields) {
                templateMarkup.push('Member System ID');
                templateMarkup.push('Member Name');
                templateMarkup.push('Member Subtitle');
                templateMarkup.push('Member Archived');
            }

            const locationHeaders = this.getLocationExportHeader(groupType.project, groupType.level);
            templateMarkup = templateMarkup.concat(locationHeaders);
        }

        return templateMarkup;

    }

    exportData = async () => {

        try {

            this.setState({ showLoader: true });

            this.setState({ loaderText: [translatePhrase('Preparing export') + '...', translatePhrase('This may take some time') + '...', translatePhrase('Capturing all the data') + '...', translatePhrase('Calculating') + '...', translatePhrase('Creating tables') + '...', translatePhrase('Writing data into the file') + '...'] });

        } catch (e) {
            this.hideMessages();

            this.setState({ showLoader: false });

            this.setState({ loaderText: [] });

            return;
        }


        window.setTimeout(async () => {
            const exportDataForQualifiedWorkflows = await this.getWorkflowDataToExport();
            const fileData = Papa.unparse(exportDataForQualifiedWorkflows);
            const fileBlob = new Blob([fileData], { type: 'application/csv' });

            saveAs(fileBlob, `Workflow Export.csv`);


            this.setState({
                showLoader: false,
                loaderText: [],
                successMessage: translatePhrase('Export ready'),
                errorMessage: '',
                erroneousData: [],
            });
        }, 2000);
    }

    dismissErrorMessage = () => {
        this.setState({
            successMessage: '',
            errorMessage: '',
            erroneousData: [],
        });
    }

    dismissSuccessMessage = () => {
        this.setState({
            successMessage: '',
        });
    }

    startDataExport = () => {
        this.setState({
            errorMessage: '',
        });

        return true;
    }

    hideMessages = () => {
        setTimeout(() => {
            this.dismissErrorMessage();
            this.dismissSuccessMessage();
        }, 3000);
    }

    componentDidMount = () => {


    }

    render() {
        let allowedTypes = this.props.workflowData.types.allEntries;

        if (this.props.workflowData.filters.types.length > 0) {
            allowedTypes = this.props.workflowData.filters.types.slice();
        }

        const workflowTypesList = allowedTypes.map(memberTypeId => {
            return {
                name: translatePhrase(this.props.workflowData.types.byId[memberTypeId].name),
                value: memberTypeId,
            };
        });

        let workflowTypeIds: Array<string> = [];

        if (this.props.workflowData.filters.types.length > 0) {
            workflowTypeIds = this.props.workflowData.filters.types;
        } else {
            workflowTypeIds = this.props.workflowData.types.allEntries
        };

        return (
            <section>

                {this.state.showLoader && <LoaderModal loaderText={this.state.loaderText} isIndeterminate={true} />}

                {this.state.successMessage && <LoaderModal
                    loaderText={[this.state.successMessage]}
                    isSuccess
                    closeModal={this.dismissSuccessMessage}
                />}

                {this.state.errorMessage && <LoaderModal loaderText={[this.state.errorMessage]} link={this.state.erroneousData.length > 1 ? <CSVLink data={this.state.erroneousData} filename="Errors.csv" className={styles.errorFileDownload}>Download erroneous data</CSVLink> : <div></div>} isError={true} />}


                <section className={styles.commonModalHolder + ' ' + styles.csvModal}>
                    <div className={styles.filterCloseButton} onClick={this.props.closeCSV}>
                        <Button title={translatePhrase("Close")} icon={<CancelIcon />} size={'small'} isRounded />
                    </div>

                    <section className={styles.addOrModifyListCard}>
                        <header>
                            <h2> <ExportIcon /> {translatePhrase('Import/Export')} {translatePhrase('Workflows')} </h2>
                        </header>

                        <div className={styles.container}>
                            <div className={styles.allInputsHolder}>
                                <div className={styles.inputSegment}>
                                    <MultiSelectInputText
                                        options={workflowTypesList}
                                        default={workflowTypeIds}
                                        onChange={this.changeWorkflowTypes}
                                        placeholder={`${translatePhrase('Workflow Types')} (${translatePhrase('Default')}: ${translatePhrase('All Workflow Types')})`}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={styles.buttonsHolder}>
                            {this.props.workflowData.allEntries.length > 0 && this.state.exportData.length === 0 && <Button icon={<ExportCSVIcon />} isRounded isBlock padding={'0px 10px'} text={translatePhrase(`Export Table`)} onClick={this.exportData} />}
                        </div>
                    </section>
                </section>
            </section>
        );
    }
}

const WorkflowModify = connect(mapStateToProps, mapDispatchToProps)(ConnectedWorkflowModify);

export default WorkflowModify;