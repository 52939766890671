import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/types';
import { INewStaticDataHolderData, IUpdateableStaticDataHolderData } from '../../../shared/store/static-info/types';
import { reOrderStaticDataHolders, addStaticDataHolder, updateStaticDataHolder, deleteStaticDataHolder } from '../../../shared/store/static-info/actions';

import StaticDataHoldersVertical, {
    OwnProps as CardTreeOwnProps,
    StateProps as CardTreeStateProps,
    DispatchProps as CardTreeDispatchProps,
    OwnState as CardTreeOwnState
} from './StaticDataHoldersVertical';
import { CardType } from '../../../widgets/card/Card';
import { Permissions } from '../../../shared/store/permissions/types';
import { translatePhrase } from '../../../shared/helpers/translation';

interface OwnProps extends CardTreeOwnProps {
}

interface StateProps extends CardTreeStateProps {
}

interface DispatchProps extends CardTreeDispatchProps {
    addCard: (payload: INewStaticDataHolderData) => void,
    updateCard: (payload: IUpdateableStaticDataHolderData) => void,
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps): StateProps => {

    const allStaticDataHolders: Array<CardType> = state.staticInfo.allEntries
        .map(staticDataHolderId => {
            const staticDataHolder = state.staticInfo.byId[staticDataHolderId];

            const fragmentIds = staticDataHolder.children;

            const fragmentNames = fragmentIds.map(dataIds => {
                const names = state.staticInfo.fragments.byId[dataIds] ? translatePhrase(state.staticInfo.fragments.byId[dataIds].name) : '' 
                return names
            })

            return {
                id: staticDataHolderId,
                name: staticDataHolder.name,
                isReadOnly: ownProps.isReadOnly,
                details: fragmentNames.join(", ")
            };
        });

    return {
        restrictStructureChanges: false,

        cardsList: allStaticDataHolders,
        selectedCard: ownProps.selectedId ? state.staticInfo.byId[ownProps.selectedId] : undefined,
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        reOrderCards: (sourceIndex: number, destinationIndex: number) => dispatch(reOrderStaticDataHolders(sourceIndex, destinationIndex)),
        addCard: (payload: INewStaticDataHolderData) => dispatch(addStaticDataHolder(payload)),
        deleteCard: (id: string) => dispatch(deleteStaticDataHolder(id)),
        updateCard: (payload: IUpdateableStaticDataHolderData) => dispatch(updateStaticDataHolder(payload)),
    };
}

type Props = OwnProps & StateProps & DispatchProps;

interface OwnState extends CardTreeOwnState {
};

class ConnectedStaticDataHoldersList extends StaticDataHoldersVertical<Props, OwnState> {

    state: OwnState = {
        isShowingAddForm: false,
        isShowingModifyForm: false,
        modifyingCardName: '',
    }

    static defaultProps = {
        isReadOnly: false,
    }

}

const StaticDataHoldersList = connect(mapStateToProps, mapDispatchToProps)(ConnectedStaticDataHoldersList);

export default StaticDataHoldersList;