import React, { Component } from 'react';
import CardsList from '../../../widgets/card/CardsList';
import ModifyForm from '../../../widgets/card/ModifyForm';
import { INewLocationData, IUpdateableLocationData, ILocation } from '../../../shared/store/structure/location/types';
import { CustomFieldDataType, CustomFieldOptionsDataType, CustomFieldValueType, FieldType } from '../../../shared/store/custom-fields/types';
import { CardType } from '../../../widgets/card/Card';
import EnhancedInputText from '../../../widgets/form/InputText';
import CustomFieldInput from '../../../widgets/form/CustomField';
import { CustomFieldHolder } from '../../../shared/store/normalized-model';
import { clearTempFiles, commitTempFiles } from '../../../shared/store/file-operations';
import uuid from 'uuid';

export interface OwnProps {
    selectedId?: string,
    levelId: string,
    heading: string,
    isReadOnly?: boolean,

    onSelectCard: (id: string) => void,
    onUnSelectCard: () => void,
}

export interface StateProps {
    read: boolean,
    write: boolean,
    restrictStructureChanges: boolean,

    customFieldIds: Array<string>,

    customFieldsData: CustomFieldDataType,
    customFieldOptionsData: CustomFieldOptionsDataType,

    cardsList: Array<CardType>,
    selectedCard: ILocation | undefined,

    myToken: string,
    orgCode: string,
}

export interface DispatchProps {
    reOrderCards: (sourceIndex: number, destinationIndex: number) => void,
    addCard: (payload: IUpdateableLocationData) => void,
    deleteCard: (id: string) => void,
    updateCard: (payload: IUpdateableLocationData) => void,
}

type Props = OwnProps & StateProps & DispatchProps;

export interface OwnState {
    isShowingAddForm: boolean,
    isShowingModifyForm: boolean,
    modifyingCardId: string,
    modifyingCardName: string,
    token: string,

    customFields: {
        [customFieldId: string]: CustomFieldValueType,
    },

    newFileName: string
};

type LocationEntity = {
    id: string
} & CustomFieldHolder;

class CardTreeLevel<TProps extends Props, TState extends OwnState> extends Component<TProps, TState> {

    componentDidUpdate(prevProps: Props) {
        if (this.props.selectedCard && prevProps.selectedId !== this.props.selectedId) {
            this.setState({
                customFields: this.props.selectedCard.customFields
            });
        }
    }

    handleSelectCard = (id: string) => {
        this.props.onSelectCard(id);
    }

    cancelLocationEdit = async () => {

        if (!this.props.selectedCard) {
            throw new Error('Cannot cancel edit card since nothing is selected');
        }

        await clearTempFiles();

        this.setState({
            isShowingModifyForm: false,
            isShowingAddForm: false,
            modifyingCardId: this.props.selectedCard.id,
            modifyingCardName: this.props.selectedCard.name,
            customFields: this.props.selectedCard.customFields
        });
    }

    editSelectedCard = () => {

        if (!this.props.selectedCard) {
            throw new Error('Cannot edit card since nothing is selected');
        }

        this.setState({
            isShowingAddForm: false,
            isShowingModifyForm: true,
            modifyingCardId: this.props.selectedCard.id,
            modifyingCardName: this.props.selectedCard.name,
            customFields: this.props.selectedCard.customFields
        });
    }

    updateCardName = (value: string) => {
        this.setState({
            modifyingCardName: value
        });
    }

    addNewCard = () => {
        this.setState({
            modifyingCardId: uuid.v4(),
            modifyingCardName: '',
            isShowingAddForm: true,
            isShowingModifyForm: false,
            customFields: {},
        })
    }

    addCard = async () => {

        await commitTempFiles();

        this.props.addCard({
            id: this.state.modifyingCardId,
            name: this.state.modifyingCardName,
            customFields: this.state.customFields,
        });

        this.setState({
            modifyingCardId: uuid.v4(),
            modifyingCardName: '',
            isShowingAddForm: false,
            customFields: {},
        });
    }

    changeCustomField = (fieldId: string, value: string | string[] | undefined | boolean) => {
        const field = this.props.customFieldsData.byId[fieldId];

        const customFields = {
            ...this.state.customFields,
            [fieldId]: field.type === FieldType.NUMBER ? Number(value) : value,
        }

        this.setState({
            customFields,
        });

    }

    updateCard = async () => {

        if (!this.props.selectedCard) {
            return;
        }

        await commitTempFiles();

        this.props.updateCard({
            id: this.props.selectedCard.id,
            name: this.state.modifyingCardName,
            customFields: this.state.customFields,
        });

        this.setState({
            isShowingModifyForm: false,
            modifyingCardName: '',
            isShowingAddForm: false,
        });
    }

    validateCard = () => {
        if (!this.state.modifyingCardName) {
            return 'Enter a valid name';
        }

        return true;
    };

    render() {

        let locationData: LocationEntity = {
            id: this.state.modifyingCardId,
            customFields: this.state.customFields,
        };

        const customFieldsMarkup = this.props.customFieldIds.map(fieldId => {
            return <CustomFieldInput
                entityType="level"
                entity={locationData}
                fieldId={fieldId}
                customFieldsData={this.props.customFieldsData}
                customFieldOptionsData={this.props.customFieldOptionsData}
                changeField={this.changeCustomField}
            />
        });

        const modifyForm = <ModifyForm hideCancel key={this.props.selectedId} isNew={!this.state.isShowingModifyForm} submitForm={this.state.isShowingModifyForm ? this.updateCard : this.addCard} cancelForm={this.cancelLocationEdit} validateForm={this.validateCard}>
            <EnhancedInputText placeholder="Name" onEnterPress={this.state.isShowingModifyForm ? this.updateCard : this.addCard} onChange={this.updateCardName} default={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.name : ''} key={this.state.isShowingModifyForm && this.props.selectedCard ? this.props.selectedCard.id : 0} />
            {customFieldsMarkup}
        </ModifyForm>;

        return <CardsList
            heading={this.props.heading}
            cards={this.props.cardsList}
            selectedCard={this.props.selectedCard}
            onSelectCard={this.handleSelectCard}
            onUnselectCard={this.props.onUnSelectCard}
            onDeleteCard={this.props.deleteCard}
            onEditCard={this.editSelectedCard}
            onReorderCards={this.props.reOrderCards}
            modifyForm={modifyForm}
            isShowingAddForm={this.state.isShowingAddForm}
            isShowingEditForm={this.state.isShowingModifyForm}
            onAddCard={this.addNewCard}
            isDeleteRestricted={this.props.restrictStructureChanges}
            isReadOnly={this.props.isReadOnly || !this.props.write}
        />
    }
}

export default CardTreeLevel;