import { MemberState, MemberActionTypes, ADD_MEMBER, UPDATE_MEMBER, DELETE_MEMBER, SEARCH_MEMBER_TABLE, GO_TO_PAGE_MEMBER_TABLE, SET_PAGE_SIZE_MEMBER_TABLE, SORT_MEMBER_TABLE, UPDATE_MEMBER_DATA, ADD_WORKFLOW_TO_MEMBER, UPDATE_MEMBER_CUSTOM_FIELD_DATA, ADD_GROUP_TO_MEMBER, REMOVE_GROUP_FROM_MEMBER, FILTER_MEMBER_TABLE, IMember, UPDATE_MEMBERS_DATA, SYNCHRONIZE_MEMBERS_DATA, CLEAR_MEMBERS_DELTA, UN_ARCHIVE_MEMBER, REMOVE_WORKFLOW_FROM_MEMBER, BULK_ADD_MEMBERS, BULK_UPDATE_MEMBERS, SET_IS_FILTERING_FOR_MEMBER_TABLE, APPEND_MEMBERS_DATA, SET_IS_SHOWING_ADD_FORM_FOR_MEMBERS, SET_IS_SHOWING_CSV_FORM_FOR_MEMBERS, SET_IS_SHOWING_FILTER_FORM_FOR_MEMBERS, SET_IS_SHOWING_MODIFY_FORM_FOR_MEMBERS, UPDATE_MEMBERS_LOCATION, UPDATE_MEMBER_COMPUTED_FIELD_DATA, BULK_UPDATE_MEMBER_COMPUTED_FIELD_DATA, CLEAR_MEMBER_ENTRIES, SET_TOTAL_NUMBER_OF_MEMBERS_FROM_SERVER, BULK_ADD_GROUPS_TO_MEMBERS, BULK_REMOVE_GROUPS_FROM_MEMBERS, SET_MEMBER_TYPE_FOR_CSV, BULK_ADD_WORKFLOWS_TO_MEMBERS, BULK_REMOVE_WORKFLOWS_FROM_MEMBERS, BULK_DELETE_MEMBERS } from './types';
import { memberTypesReducer, initialState as memberTypesInitialState } from './types/reducer';
import { addEntity, addEntities, updateEntityWithCustomFields, bulkUpdateEntityWithCustomFields, deleteEntity, updateEntries, synchronizeEntriesForCustomModels, clearDelta, unArchiveEntity, synchronizeReverseLinks, synchronizeEntries, bulkDeleteEntities } from '../normalized-model';
import { IMemberType, MemberTypeState } from './types/types';
import { IWorkflow, SYNCHRONIZE_WORKFLOWS_DATA } from '../workflows/types';
import { areCustomFieldValuesEqual } from '../custom-fields';
import { SET_MEMBERS_TYPE_IN_GROUP } from '../flowchart/pieces/types';

const initialState: MemberState = {
    byId: {},
    allEntries: [],
    filteredEntries: [],

    isFiltering: false,

    noOfMembersReceivedInLastSync: 0,
    totalNoOfMembers: 0,

    createdIds: new Set<string>(),
    updatedIds: new Set<string>(),
    deletedIds: new Set<string>(),

    types: memberTypesInitialState,

    pageSize: 25,
    currentPageNumber: 1,

    markedForIndex: [],

    filters: {
        projects: [],
        types: [],
        locations: [],
        customFields: {},
        createdDateRange: [],
        lastUpdatedDateRange: [],
        archived: false,
        unsynced: false,
    },
    sort: {
        column: undefined,
        order: 'ASC',
    },
    searchTerm: '',

    isShowingAddForm: false,
    isShowingModifyForm: false,
    isShowingFilterForm: false,
    isShowingCSVForm: false,
};

export function membersReducer(state = initialState, action: MemberActionTypes): MemberState {
    state = {
        ...state,
        types: memberTypesReducer(state.types, action),
    };

    let connectedWorkflows: Array<string> = [];
    let connectedGroups: Array<string> = [];

    switch (action.type) {
        case ADD_MEMBER:
            return addEntity<MemberState, IMember>(state, action.payload);

        case BULK_ADD_MEMBERS:
            return addEntities<MemberState, IMember>(state, action.payload);

        case UPDATE_MEMBER:
            return updateEntityWithCustomFields<MemberState, IMember>(state, action.payload, action.currentTime);

        case BULK_UPDATE_MEMBERS:
            return bulkUpdateEntityWithCustomFields<MemberState, IMember>(state, action.payload, action.currentTime);

        case DELETE_MEMBER:
            return deleteEntity<MemberState, IMember>(state, action.id, action.currentTime);

        case BULK_DELETE_MEMBERS:
            return bulkDeleteEntities<MemberState, IMember>(state, action.ids, action.currentTime);

        case UN_ARCHIVE_MEMBER:
            return unArchiveEntity<MemberState, IMember>(state, action.id, action.currentTime);

        case ADD_WORKFLOW_TO_MEMBER:
            connectedWorkflows = state.byId[action.memberId].workflows[action.workflowTypeId] || [];
            connectedWorkflows = Array.from((new Set(connectedWorkflows)).add(action.workflowId));

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberId]: {
                        ...state.byId[action.memberId],
                        workflows: {
                            ...state.byId[action.memberId].workflows,
                            [action.workflowTypeId]: connectedWorkflows,
                        },
                    },
                },
            };

        case REMOVE_WORKFLOW_FROM_MEMBER:
            connectedWorkflows = state.byId[action.memberId].workflows[action.workflowTypeId] || [];
            connectedWorkflows = connectedWorkflows.filter(workflowId => workflowId !== action.workflowId);

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberId]: {
                        ...state.byId[action.memberId],
                        workflows: {
                            ...state.byId[action.memberId].workflows,
                            [action.workflowTypeId]: connectedWorkflows,
                        }
                    }
                },
            }

        case UPDATE_MEMBER_CUSTOM_FIELD_DATA:

            if (areCustomFieldValuesEqual(state.byId[action.memberId].customFields, action.customFieldData)) {
                return state;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberId]: {
                        ...state.byId[action.memberId],
                        lastUpdatedTime: action.currentTime,
                        customFields: {
                            ...state.byId[action.memberId].customFields,
                            ...action.customFieldData,
                        },
                    },
                },
                updatedIds: new Set([...state.updatedIds, action.memberId]),
            }

        case UPDATE_MEMBER_COMPUTED_FIELD_DATA:

            if (areCustomFieldValuesEqual(state.byId[action.memberId].customFields, action.customFieldData)) {
                return state;
            }

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberId]: {
                        ...state.byId[action.memberId],
                        customFields: {
                            ...state.byId[action.memberId].customFields,
                            ...action.customFieldData,
                        },
                    },
                },
                updatedIds: new Set([...state.updatedIds, action.memberId]),
            }

        case BULK_UPDATE_MEMBER_COMPUTED_FIELD_DATA:
            state = {
                ...state,
                byId: {
                    ...state.byId,
                },
                updatedIds: new Set([...state.updatedIds]),
            }

            for (const memberData of action.payload) {

                if (areCustomFieldValuesEqual(state.byId[memberData.memberId].customFields, memberData.customFieldData)) {
                    continue;
                }

                state.byId[memberData.memberId] = {
                    ...state.byId[memberData.memberId],
                    customFields: {
                        ...state.byId[memberData.memberId].customFields,
                        ...memberData.customFieldData,
                    },
                };

                state.updatedIds.add(memberData.memberId);
            }

            return state;

        case UPDATE_MEMBERS_LOCATION:
            state = {
                ...state,
                byId: {
                    ...state.byId,
                },
                updatedIds: new Set([...state.updatedIds, ...action.memberIds]),
            }

            for (const memberId of action.memberIds) {
                state.byId[memberId] = {
                    ...state.byId[memberId],
                    location: action.locationId,
                }
            }

            return state;

        case ADD_GROUP_TO_MEMBER:
            connectedGroups = state.byId[action.memberId].groups[action.groupTypeId] || [];
            connectedGroups = Array.from((new Set(connectedGroups)).add(action.groupId));

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberId]: {
                        ...state.byId[action.memberId],
                        groups: {
                            ...state.byId[action.memberId].groups,
                            [action.groupTypeId]: connectedGroups,
                        },
                    },
                },
            }

        case REMOVE_GROUP_FROM_MEMBER:
            connectedGroups = state.byId[action.memberId].groups[action.groupTypeId] || [];
            connectedGroups = connectedGroups.filter(groupId => groupId !== action.groupId);

            return {
                ...state,
                byId: {
                    ...state.byId,
                    [action.memberId]: {
                        ...state.byId[action.memberId],
                        groups: {
                            ...state.byId[action.memberId].groups,
                            [action.groupTypeId]: connectedGroups,
                        }
                    }
                },
            }

        case BULK_ADD_GROUPS_TO_MEMBERS:
            state = {
                ...state,
                byId: {
                    ...state.byId,
                }
            };

            const updatedMemberIdsForAdd: Set<string> = new Set();

            for (const link of action.links) {
                connectedGroups = state.byId[link.memberId].groups[link.groupTypeId] || [];
                connectedGroups = Array.from((new Set(connectedGroups)).add(link.groupId));

                state.byId[link.memberId] = {
                    ...state.byId[link.groupId],
                    groups: {
                        ...state.byId[link.groupId].groups,
                        [link.groupTypeId]: connectedGroups,
                    },
                };

                updatedMemberIdsForAdd.add(link.groupId);
            }

            state.updatedIds = new Set([...state.updatedIds, ...updatedMemberIdsForAdd]);

            return state;

        case BULK_REMOVE_GROUPS_FROM_MEMBERS:
            state = {
                ...state,
                byId: {
                    ...state.byId,
                }
            };

            const updatedMemberIdsForRemove: Set<string> = new Set();

            for (const link of action.links) {
                connectedGroups = state.byId[link.memberId].groups[link.groupTypeId] || [];
                connectedGroups = connectedGroups.filter(groupId => groupId !== link.groupId);

                state.byId[link.groupId] = {
                    ...state.byId[link.groupId],
                    groups: {
                        ...state.byId[link.groupId].groups,
                        [link.groupTypeId]: connectedGroups,
                    },
                };

                updatedMemberIdsForRemove.add(link.groupId)
            }

            state.updatedIds = new Set([...state.updatedIds, ...updatedMemberIdsForRemove]);

            return state;

        case BULK_ADD_WORKFLOWS_TO_MEMBERS:
            state = {
                ...state,
                byId: {
                    ...state.byId,
                }
            };

            const updatedMemberIdsForWorkflowAdd: Set<string> = new Set();

            for (const link of action.links) {
                connectedWorkflows = state.byId[link.memberId].workflows[link.workflowTypeId] || [];
                connectedWorkflows = Array.from((new Set(connectedWorkflows)).add(link.workflowId));

                state.byId[link.memberId] = {
                    ...state.byId[link.workflowId],
                    workflows: {
                        ...state.byId[link.workflowId].workflows,
                        [link.workflowTypeId]: connectedWorkflows,
                    },
                };

                updatedMemberIdsForWorkflowAdd.add(link.workflowId);
            }

            state.updatedIds = new Set([...state.updatedIds, ...updatedMemberIdsForWorkflowAdd]);

            return state;

        case BULK_REMOVE_WORKFLOWS_FROM_MEMBERS:
            state = {
                ...state,
                byId: {
                    ...state.byId,
                }
            };

            const updatedMemberIdsForWorkflowRemove: Set<string> = new Set();

            for (const link of action.links) {
                connectedWorkflows = state.byId[link.memberId].workflows[link.workflowTypeId] || [];
                connectedWorkflows = connectedWorkflows.filter(workflowId => workflowId !== link.workflowId);

                state.byId[link.workflowId] = {
                    ...state.byId[link.workflowId],
                    workflows: {
                        ...state.byId[link.workflowId].workflows,
                        [link.workflowTypeId]: connectedWorkflows,
                    },
                };

                updatedMemberIdsForWorkflowRemove.add(link.workflowId)
            }

            state.updatedIds = new Set([...state.updatedIds, ...updatedMemberIdsForWorkflowRemove]);

            return state;


        case SEARCH_MEMBER_TABLE:
            return {
                ...state,
                searchTerm: action.searchTerm,
                currentPageNumber: 1,
            }

        case FILTER_MEMBER_TABLE:
            return {
                ...state,
                filters: {
                    projects: action.projects,
                    types: action.types,
                    locations: action.locations,
                    customFields: action.customFields,
                    createdDateRange: action.createdDateRange,
                    lastUpdatedDateRange: action.lastUpdatedDateRange,
                    unsynced: action.unsynced,
                    archived: action.archived,
                },
                currentPageNumber: 1,
            }

        case SET_IS_FILTERING_FOR_MEMBER_TABLE:
            return {
                ...state,
                isFiltering: action.isFiltering,
            };

        case GO_TO_PAGE_MEMBER_TABLE:
            return {
                ...state,
                currentPageNumber: action.pageNumber,
            }

        case SET_PAGE_SIZE_MEMBER_TABLE:
            return {
                ...state,
                pageSize: action.pageSize,
            }

        case SORT_MEMBER_TABLE:
            return {
                ...state,
                sort: {
                    column: action.column,
                    order: action.order
                }
            }

        case UPDATE_MEMBERS_DATA:
            state = updateEntries<MemberState, IMember>(state, action.data);
            state.totalNoOfMembers = action.totalNoOfMembers;
            return state;

        case SET_IS_SHOWING_ADD_FORM_FOR_MEMBERS:
            return {
                ...state,
                isShowingAddForm: action.showValue,
            }

        case SET_IS_SHOWING_MODIFY_FORM_FOR_MEMBERS:
            return {
                ...state,
                isShowingModifyForm: action.showValue,
            }

        case SET_IS_SHOWING_FILTER_FORM_FOR_MEMBERS:
            return {
                ...state,
                isShowingFilterForm: action.showValue,
            }

        case SET_IS_SHOWING_CSV_FORM_FOR_MEMBERS:
            return {
                ...state,
                isShowingCSVForm: action.showValue,
            }

        case SYNCHRONIZE_MEMBERS_DATA:
            if (action.data.length === 0) {
                return {
                    ...state,
                    noOfMembersReceivedInLastSync: 0,
                }
            }

            state = synchronizeEntriesForCustomModels<MemberState, IMember>(state, action.data);
            state.types = synchronizeReverseLinks<MemberTypeState, IMemberType, IMember>(state.types, action.data, 'type', 'members');
            state.noOfMembersReceivedInLastSync = action.data.length;
            return state;

        case SYNCHRONIZE_WORKFLOWS_DATA:
            const workflowsAffiliatedWithMembers = action.data.filter(workflow => workflow.affiliatedEntity in state.byId);
            state = synchronizeReverseLinks<MemberState, IMember, IWorkflow>(state, workflowsAffiliatedWithMembers, 'affiliatedEntity', 'workflows', 'type');
            return state;

        case APPEND_MEMBERS_DATA:
            const unrecordedInactiveMembers = action.data.filter(member => !(member.id in state.byId));
            state = synchronizeEntries(state, unrecordedInactiveMembers);
            return state;

        case SET_TOTAL_NUMBER_OF_MEMBERS_FROM_SERVER:
            return {
                ...state,
                currentPageNumber: 1,
                totalNoOfMembers: action.totalNumberOfMembers,
            }

        case CLEAR_MEMBER_ENTRIES:
            return {
                ...state,
                byId: {},
                allEntries: [],
            };

        case CLEAR_MEMBERS_DELTA:
            return clearDelta<MemberState, IMember>(state);

        case UPDATE_MEMBER_DATA:
            return {
                ...action.data,
                createdIds: state.createdIds,
                updatedIds: state.updatedIds,
                deletedIds: state.deletedIds,
                filters: state.filters,
                types: {
                    ...action.data.types,
                    createdIds: state.types.createdIds,
                    updatedIds: state.types.updatedIds,
                    deletedIds: state.types.deletedIds,
                    createdCustomFieldIds: state.types.createdCustomFieldIds,
                    updatedCustomFieldIds: state.types.updatedCustomFieldIds,
                    deletedCustomFieldIds: state.types.deletedCustomFieldIds,
                    createdCustomFieldOptionIds: state.types.createdCustomFieldOptionIds,
                    updatedCustomFieldOptionIds: state.types.updatedCustomFieldOptionIds,
                    deletedCustomFieldOptionIds: state.types.deletedCustomFieldOptionIds,
                },
            }
        case SET_MEMBER_TYPE_FOR_CSV:
            return {
                ...state,
                selectedMemberTypeForCSV: action.id
            }

        default:
            return state;
    }
}