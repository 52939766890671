import React from 'react';
import styles from './Workflows.module.scss';
import { Switch, Route, Redirect } from "react-router-dom";

import { ReactComponent as CloseIcon } from '../../common/assets/close.svg';
import { ReactComponent as CheckIcon } from '../../assets/new-custom-icons/profile/check-mark.svg';

import { ApplicationState } from '../../shared/store/types';

import { Permissions } from '../../shared/store/permissions/types';

import Configuration from './configuration/Configuration';
import List from './list/List';
import WorkflowTypeFlowchart from './flowchart/WorkflowTypeFlowchart';

import { translatePhrase } from '../../shared/helpers/translation';

import { connect } from 'react-redux';

import { ReactComponent as WorkflowIcon } from '../../common/assets/flow-data.svg';
import { Dispatch } from 'redux';
import { hideRichTextEditor } from '../../shared/store/flowchart/pieces/actions';
import RichTextModal from './flowchart/RichTextModal';
import { updateWorkflowTypeRichText } from '../../shared/store/workflows/types/actions';
import Button from '../../widgets/button/CommonButton';
import Header from '../../widgets/header/Header';

type OwnProps = {};

const mapStateToProps = (state: ApplicationState) => {
    const canViewWorkflowConfiguration = state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.WRITE || state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.READ;
    const isOnline = state.myData.isOnline

    return {
        canGoToConfiguration: canViewWorkflowConfiguration,
        canGoToList: true,
        currentWorkflowsCount: Object.keys(state.workflows.byId).length,
        totalWorkflowsCount: state.myData.totalNoOfWorkflows,
        hasWorkflows: state.workflows.allEntries.length > 0,
        isLoaded: state.myData.isLoaded,
        isOnline,
        isPresentable: state.myData.isLoaded || state.myData.isPartiallyLoaded,
        isShowingRichTextEditor: state.flowchart.pieces.isShowingRichTextEditor,
        isFlowchartExpanded: state.myData.isFlowchartExpanded,
        myId: state.myData.id,

        selectedWorkflowType: state.workflows.types.selected ? state.workflows.types.byId[state.workflows.types.selected] : undefined,
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        hideRichTextEditor: () => dispatch(hideRichTextEditor()),
        updateWorkflowTypeRichText: (richTextValue: string, workflowTypeId: string) => dispatch(updateWorkflowTypeRichText(richTextValue, workflowTypeId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = OwnProps & StateProps & DispatchProps;

const ConnectedWorkflows: React.FC<Props> = (props) => {
    const {
      canGoToConfiguration,
      canGoToList,
      totalWorkflowsCount,
      currentWorkflowsCount,
      selectedWorkflowType,
      updateWorkflowTypeRichText,
      hideRichTextEditor,
      isShowingRichTextEditor,
      isPresentable,
      isFlowchartExpanded,
      hasWorkflows,
      isOnline,
    } = props;

    if (!(canGoToConfiguration || canGoToList)) {
        return <Redirect to="/dashboard" />;
    }

    const isActive = (match: any, location: any) => {
      if (
        location.pathname ===
          `/workflows/flowchart/${selectedWorkflowType?.id}/beta` ||
        location.pathname === "/workflows/configuration" ||
        location.pathname ===
          `/workflows/flowchart/${selectedWorkflowType?.id}/live`
      ) {
        return true;
      } else {
        return false;
      }
    };

    const tabs = [
        {
            tabName: "List",
            link: "/workflows/list",
            hasAccess: canGoToList,
            dataSelector: "workflows-list-tab",
        },
        {
            tabName: "Configuration",
            link: "/workflows/configuration",
            hasAccess: canGoToConfiguration,
            dataSelector: "workflows-configuration-tab",
            isActive: isActive,
        }
    ];

    return (
        <section className={styles.FocusSpace}>
            
            <Header
                pageName={"Workflows"}
                tabs={tabs}
                icon={<WorkflowIcon />}
                currentCount={currentWorkflowsCount}
                totalCount={totalWorkflowsCount}
            />

            {isShowingRichTextEditor && selectedWorkflowType && <div className={styles.modalHolder + ' ignore-react-onclickoutside'}>
                <section className={styles.richTextModal}>
                    <RichTextModal
                        textToShow={selectedWorkflowType.richTextDescription || ''}
                        onContentUpdate={(updatedContent: string) => updateWorkflowTypeRichText(updatedContent, selectedWorkflowType ? selectedWorkflowType.id : '')}
                    />

                    <div className={styles.submitModalButtons}>
                        <Button type={"secondary"} icon={<CheckIcon />} onClick={hideRichTextEditor} text={translatePhrase('Done')} isRounded color={"contrast"} />
                        <Button type={"secondary"} icon={<CloseIcon />} onClick={hideRichTextEditor} text={translatePhrase('Cancel')} isRounded color={"danger"} />
                    </div>

                </section>
            </div>}

            {isPresentable && <div className={`${!isFlowchartExpanded && styles.innerFocus}`}>
                <Switch>
                    <Route path="/workflows/configuration" render={() => <Configuration />} />
                    <Route path="/workflows/flowchart/:id/:version" component={WorkflowTypeFlowchart} />
                    <Route path="/workflows/list" render={() => <List />} />
                    <Route render={() => <Redirect to={canGoToList && (hasWorkflows || isOnline || !canGoToConfiguration) ? '/workflows/list' : '/workflows/configuration'} />} />
                </Switch>
            </div>}
        </section>
    );
};

const Workflows = connect(mapStateToProps, mapDispatchToProps)(ConnectedWorkflows);

export default Workflows;