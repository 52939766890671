import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { ApplicationState } from '../../../shared/store/types';
import { INewGroupTypeActionData, IUpdateableGroupTypeActionData } from '../../../shared/store/groups/types/actions/types';
import { reOrderGroupTypeActions, addGroupTypeAction, updateGroupTypeAction, deleteGroupTypeAction } from '../../../shared/store/groups/types/actions/actions';

import CardTreeVertical, { 
    OwnProps as CardTreeOwnProps, 
    StateProps as CardTreeStateProps, 
    DispatchProps as CardTreeDispatchProps, 
    OwnState as CardTreeOwnState 
} from './GroupTypeActionsVertical';
import { Permissions } from '../../../shared/store/permissions/types';
import { translatePhrase } from '../../../shared/helpers/translation';
import { isUUID } from '../../../shared/helpers/utilities';
import { setToastMessage } from '../../../shared/store/my-data/actions';

interface OwnProps extends CardTreeOwnProps {
}

interface StateProps extends CardTreeStateProps {
}

export interface GroupTypeActionCard {
    id: string;
    name: string;
    details: string;
    icon: string;
    workflowType?: string;
    isDeleteRestricted?: boolean;
}

interface DispatchProps extends CardTreeDispatchProps {
    reOrderCards: (sourceIndex: number, destinationIndex: number) => void,
    addCard: (payload: INewGroupTypeActionData) => void,
    updateCard: (payload: IUpdateableGroupTypeActionData) => void,
    deleteCard: (id: string) => void,
    setToastMessage: (message: string) => void,
}

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) : StateProps => {
    const canEditConfiguration = state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.WRITE;
    const canViewConfiguration = canEditConfiguration || state.permissions.myPermissions.general.WorkflowsConfiguration === Permissions.READ;
    const groupType = state.groups.types.byId[ownProps.parentId];

    const allGroupTypeActions: Array<GroupTypeActionCard> = groupType.actions.map((actionId, index) => {
        const action = state.groups.types.actions.byId[actionId];

        return {
            id: actionId,
            name: translatePhrase(action.name),
            details: action.workflowType ? state.workflows.types.byId[action.workflowType].name : 'Default',
            icon: action.icon,
            workflowType: action.workflowType,
            isDeleteRestricted: index < 3,
        };
    });

    const nonAffiliatedWorkflowTypes = state.workflows.types.allEntries
    .filter(workflowTypeId => {
        const workflowType = state.workflows.types.byId[workflowTypeId];

        if (workflowType.affiliation !== 'none') {
            return false;
        }

        return workflowType.project === groupType.project;
    })
    .map(workflowTypeId => state.workflows.types.byId[workflowTypeId]);

    const eligibleWorkflowTypes = state.workflows.types.allEntries
    .filter(workflowTypeId => {
        const workflowType = state.workflows.types.byId[workflowTypeId];

        if (workflowType.affiliation !== 'group') {
            return false;
        }

        if (isUUID(workflowType.affiliatedEntity) && ownProps.parentId !== workflowType.affiliatedEntity) {
            return false;
        }

        return workflowType.project === groupType.project;
    })
    .map(workflowTypeId => state.workflows.types.byId[workflowTypeId]);

    return {
        read: canViewConfiguration,
        write: canEditConfiguration,
        restrictStructureChanges: false,

        isSelectedCardRestricted: ownProps.selectedId ? groupType.actions.slice(0, 2).includes(ownProps.selectedId) : false,

        addWorkflowTypes: nonAffiliatedWorkflowTypes,
        workflowTypes: eligibleWorkflowTypes,
        cardsList: allGroupTypeActions,
        selectedCard: ownProps.selectedId ? allGroupTypeActions.find(groupTypeAction => groupTypeAction.id === ownProps.selectedId) : undefined,
    };
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: OwnProps) => {
    return {
        reOrderCards: (sourceIndex: number, destinationIndex: number) => dispatch(reOrderGroupTypeActions(sourceIndex, destinationIndex, ownProps.parentId)),
        addCard: (payload: INewGroupTypeActionData) => dispatch(addGroupTypeAction(payload, ownProps.parentId)),
        deleteCard: (id: string) => dispatch(deleteGroupTypeAction(id, ownProps.parentId)),
        updateCard: (payload: IUpdateableGroupTypeActionData) => dispatch(updateGroupTypeAction(payload)),
        setToastMessage: (message: string) => dispatch(setToastMessage(message)),
    };
}

type Props = OwnProps & StateProps & DispatchProps;

interface OwnState extends CardTreeOwnState {
};

class ConnectedStatusesList extends CardTreeVertical<Props, OwnState> {

    state: OwnState = {
        isShowingAddForm: false,
        isShowingModifyForm: false,
        modifyingCardName: '',
        modifyingCardIcon: '',
        modifyingCardWorkflowType: '',
    }

    static defaultProps = {
        isReadOnly: false,
    }

}

const StatusesList = connect(mapStateToProps, mapDispatchToProps)(ConnectedStatusesList);

export default StatusesList;