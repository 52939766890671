import React, { Component } from 'react';
import Input from '../Input';
import StepPiece from './step-piece/StepPiece';
import styles from './step-piece/StepPiece.module.scss';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setError } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import { FlowchartContext } from '../../../contexts/flowchart-context';
import { valuePieceSlotTarget } from './utilities';


type ErrorPieceProps = {
    errorVariableText?: string,
    errorVariablePiece: JSX.Element | undefined
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string | undefined) => dispatch(setTargetPiece(pieceId)),
        setErrorValue: (targetPieceId: string, errorValue: string) => dispatch(setError(targetPieceId, errorValue)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = ErrorPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type ErrorPieceState = {
    isHoveringOverVariablePiece: boolean,
}

class ConnectedErrorPiece extends Component<Props, ErrorPieceState> {

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            isHoveringOverVariablePiece: false,
        };
    }
    handleErrorValueUpdate = (value: string) => {
        this.props.setErrorValue(this.props.pieceId, value);
    };

    handleHoverOverVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: false,
        });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && this.state.isHoveringOverVariablePiece) {

            this.props.setErrorValue(this.props.pieceId, this.props.lastDraggedPiece.id);
            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverVariablePiece: false,
            });
        }
    }


    render() {

        return <FlowchartContext.Consumer>
            {
                flowchartContext => {

                    let isValid = true;

                    if (flowchartContext.highlightIncompletePieces) {
                        const isIncomplete = !this.props.errorVariableText;
                        isValid = isValid && !isIncomplete;
                    }

                    return <StepPiece theme={isValid ? "camo" : "red"} {...this.props} hasLowerLock={false}>
                        <div className={styles.text}>Error</div>
                        {this.props.errorVariablePiece ?
                            this.props.errorVariablePiece
                            :
                            <Input
                                minSize={12}
                                placeholderText="Empty value"
                                canReceiveDrag={this.props.isDragging && this.state.isHoveringOverVariablePiece && !!this.props.targetPiece}
                                onMouseOver={this.handleHoverOverVariablePiece}
                                onMouseOut={this.handleHoverOutOfVariablePiece}
                                defaultText={this.props.errorVariableText}
                                onChange={this.handleErrorValueUpdate} />}

                    </StepPiece>
                }
            }
        </FlowchartContext.Consumer>
    }
}

const ErrorPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedErrorPiece);

export default ErrorPiece;