import { PieceType } from "../../../shared/store/flowchart/pieces/types";

interface PieceCategory {
    name: string;
    type: PieceType;
    tooltip: string | undefined;
}

export const ControlPieces: { [key: string]: PieceCategory } = {
    For: {
        name: 'For',
        type: PieceType.FOR,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.d3z0v1jhx3g1',
    },
    End: {
        name: 'End',
        type: PieceType.END,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.5lea8f45ktrt',
    },
    Return: {
        name: 'Return',
        type: PieceType.RETURN,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.5xi7otg9t4gp'
    },
    ReturnRichText: {
        name: 'Return (Rich Text)',
        type: PieceType.RETURN_RICH_TEXT,
        tooltip: undefined
    },
    Status: {
        name: 'Status',
        type: PieceType.STATUS,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.u137x7yphway',
    },
    UpdateStatus: {
        name: 'Update Status',
        type: PieceType.UPDATE_STATUS,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.8no8wwcbtg84',
    },
    UpdateDueDate: {
        name: 'Update Due Date',
        type: PieceType.UPDATE_DUE_DATE,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.7yqw05s1frxr',
    },
    Split: {
        name: 'Split',
        type: PieceType.SPLIT,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.pmylk5ojr4ny',
    },
    Show: {
        name: 'Show',
        type: PieceType.SHOW,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.a4y9pftaa8on',
    },
    GroupedShow: {
        name: 'Grouped Show',
        type: PieceType.GROUPED_SHOW,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.jvtbzl7a7y5v',
    },
    SwitchWorkflow: {
        name: 'Switch Workflow',
        type: PieceType.SWITCH_WORKFLOW,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.lj61fr8ws6eg',
    },
    Transfer: {
        name: 'Transfer',
        type: PieceType.TRANSFER_WORKFLOW,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.yiwn6udqq8fx',
    },
    Group: {
        name: 'Group',
        type: PieceType.GROUP,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.otjqt9iy4t3w',
    },
    GroupForList: {
        name: 'Group for list',
        type: PieceType.GROUP_FOR_LIST,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.y2gohzx9u2dh',
    },
    Section: {
        name: 'Section',
        type: PieceType.SECTION,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.i6ampmcppjr6',
    },
    AddMember: {
        name: 'Add Member',
        type: PieceType.ADD_MEMBER,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.jhyrl26cmw5a',
    },
    AddGroup: {
        name: 'Add Group',
        type: PieceType.ADD_GROUP,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.8lh6p7axvve8'
    },
    AddWorkflow: {
        name: 'Add Workflow',
        type: PieceType.ADD_WORKFLOW,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.foes80msm7j7'
    },
    AddReport: {
        name: 'Add Report',
        type: PieceType.ADD_REPORT,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.42fpa8i4s4pb'
    },
    Archive: {
        name: 'Archive',
        type: PieceType.ARCHIVE,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.84qnzldhw44b'
    },
    SetMembersInGroup: {
        name: 'Set Members in Group',
        type: PieceType.SET_MEMBERS_IN_GROUP,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.irl6vnvtvyhl'
    },
    SetLocation: {
        name: 'Set Location',
        type: PieceType.SET_LOCATION,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.k5l02o9aw2aa'
    },
    Structure: {
        name: 'Structure',
        type: PieceType.STRUCTURE,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.plb7e9nrt2oj'
    },
    StaticData: {
        name: 'Static Data',
        type: PieceType.STATIC_DATA,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.8cc3e8pir2v7'
    },
    GetAffiliation: {
        name: 'Get Affiliation',
        type: PieceType.GET_AFFILIATION_FROM_WORKFLOW,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.3zmv9863knys'
    },
    RestrictNavigation: {
        name: 'Restrict Navigation',
        type: PieceType.RESTRICT_NAVIGATION,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.qrk41xv8ral2'
    },
    GetCurrentLocation: {
        name: 'Get Current Location',
        type: PieceType.GET_CURRENT_LOCATION,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.vsnv8ddc9onw'
    },
    Continue: {
        name: 'Continue',
        type: PieceType.CONTINUE,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.yson9ik1hvg4'
    },
    Translate: {
        name: 'Translate',
        type: PieceType.TRANSLATE,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.3isv4ky0k4q7'
    },
    Format: {
        name: 'Format',
        type: PieceType.FORMAT,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.p2h7ps4el79a'
    },
    GetEntities: {
        name: 'Get entities',
        type: PieceType.GET_ENTITIES,
        tooltip: 'https://support.diceflow.in/configuration/pieces/control#h.lw3jxmdd3b1u'
    },
};

export const CustomFieldPieces: { [key: string]: PieceCategory } = {
    CustomField: {
        name: 'Custom Field',
        type: PieceType.CUSTOM_FIELD,
        tooltip: 'https://support.diceflow.in/configuration/pieces/custom-fields#h.rhl9u92w40li',
    },
    Get: {
        name: 'Get',
        type: PieceType.GET_VALUE,
        tooltip: 'https://support.diceflow.in/configuration/pieces/custom-fields#h.b4io69h9rfzr',
    },

    Store: {
        name: 'Store',
        type: PieceType.STORE,
        tooltip: 'https://support.diceflow.in/configuration/pieces/custom-fields#h.o2kcjp4xtaxp',
    },
};

export const ConstantPieces: { [key: string]: PieceCategory } = {
    Today: {
        name: 'Today',
        type: PieceType.TODAY,
        tooltip: 'https://support.diceflow.in/configuration/pieces/constants#h.al9cjbqeqv8m'
    },
    Now: {
        name: 'Now',
        type: PieceType.NOW,
        tooltip: 'https://support.diceflow.in/configuration/pieces/constants#h.yvqhsxgin1mw'
    },
    True: {
        name: 'True',
        type: PieceType.TRUE,
        tooltip: 'https://support.diceflow.in/configuration/pieces/constants#h.c8s0zq7nyjeq'
    },
    TrueHexagonal: {
        name: 'True (hexagonal)',
        type: PieceType.HEXAGONAL_TRUE,
        tooltip: 'https://support.diceflow.in/configuration/pieces/constants#h.jmudtemlj9zm'
    },
    False: {
        name: 'False',
        type: PieceType.FALSE,
        tooltip: 'https://support.diceflow.in/configuration/pieces/constants#h.42pferux6cf3'
    },
    FalseHexagonal: {
        name: 'False (hexagonal)',
        type: PieceType.HEXAGONAL_FALSE,
        tooltip: 'https://support.diceflow.in/configuration/pieces/constants#h.uqf39timr'
    },
    LoggedInUser: {
        name: 'Logged in User',
        type: PieceType.LOGGED_IN_USER,
        tooltip: 'https://support.diceflow.in/configuration/pieces/constants#h.t3uqvzimjv54'
    },
    FinancialYearMonths: {
        name: 'Financial Year Months',
        type: PieceType.FINANCIAL_YEAR_MONTHS,
        tooltip: 'https://support.diceflow.in/configuration/pieces/constants#h.lfvz2jq5xwu1'
    },
};

export const ArithmeticOperatorsPieces: { [key: string]: PieceCategory } = {
    Add: {
        name: 'Add',
        type: PieceType.ADD,
        tooltip: 'https://support.diceflow.in/configuration/pieces/arithmetic-operators#h.66o06pid6rc'
    },
    Subtract: {
        name: 'Subtract',
        type: PieceType.SUBTRACT,
        tooltip: 'https://support.diceflow.in/configuration/pieces/arithmetic-operators#h.hatvjp4eq1hc'
    },
    Multiply: {
        name: 'Multiply',
        type: PieceType.MULTIPLY,
        tooltip: 'https://support.diceflow.in/configuration/pieces/arithmetic-operators#h.tnpt8iah2yn8'
    },
    Divide: {
        name: 'Divide',
        type: PieceType.DIVIDE,
        tooltip: 'https://support.diceflow.in/configuration/pieces/arithmetic-operators#h.tnpt8iah2yn8'
    },
    Exponent: {
        name: 'Exponent',
        type: PieceType.EXPONENT,
        tooltip: 'https://support.diceflow.in/configuration/pieces/arithmetic-operators#h.10vogidwjmrn'
    },
    Sequence: {
        name: 'Sequence',
        type: PieceType.SEQUENCE,
        tooltip: undefined
    },
};

export const BooleanOperatorsPieces: { [key: string]: PieceCategory } = {
    LesserThan: {
        name: 'Lesser Than',
        type: PieceType.LESSER_THAN,
        tooltip: 'https://support.diceflow.in/configuration/pieces/boolean-operators#h.jy6o9r63tyfw'
    },
    LesserThanOrEqualTo: {
        name: 'Lesser Than Or Equal To',
        type: PieceType.LESSER_THAN_OR_EQUAL_TO,
        tooltip: 'https://support.diceflow.in/configuration/pieces/boolean-operators#h.jy6o9r63tyfw'
    },
    GreaterThan: {
        name: 'Greater Than',
        type: PieceType.GREATER_THAN,
        tooltip: 'https://support.diceflow.in/configuration/pieces/boolean-operators#h.jy6o9r63tyfw'
    },
    GreaterThanOrEqualTo: {
        name: 'Greater Than Or Equal To',
        type: PieceType.GREATER_THAN_OR_EQUAL_TO,
        tooltip: 'https://support.diceflow.in/configuration/pieces/boolean-operators#h.jy6o9r63tyfw'
    },
    EqualTo: {
        name: 'Equal To',
        type: PieceType.EQUAL_TO,
        tooltip: 'https://support.diceflow.in/configuration/pieces/boolean-operators#h.jy6o9r63tyfw'
    },
    NotEqualTo: {
        name: 'Not Equal To',
        type: PieceType.NOT_EQUAL_TO,
        tooltip: 'https://support.diceflow.in/configuration/pieces/boolean-operators#h.jy6o9r63tyfw'
    },
    In: {
        name: 'In',
        type: PieceType.IN,
        tooltip: 'https://support.diceflow.in/configuration/pieces/boolean-operators#h.1vlzn1r3stmj'
    },
    NotIn: {
        name: 'Not In',
        type: PieceType.NOT_IN,
        tooltip: 'https://support.diceflow.in/configuration/pieces/boolean-operators#h.1vlzn1r3stmj'
    },
    And: {
        name: 'And',
        type: PieceType.AND,
        tooltip: 'https://support.diceflow.in/configuration/pieces/boolean-operators#h.yyrbgx2trmc8'
    },
    Or: {
        name: 'Or',
        type: PieceType.OR,
        tooltip: 'https://support.diceflow.in/configuration/pieces/boolean-operators#h.yyrbgx2trmc8'
    },
    Not: {
        name: 'Not',
        type: PieceType.NOT,
        tooltip: 'https://support.diceflow.in/configuration/pieces/boolean-operators#h.yyrbgx2trmc8'
    },
    VariableToBoolean: {
        name: 'Variable to Boolean',
        type: PieceType.VARIABLE_TO_BOOLEAN,
        tooltip: 'https://support.diceflow.in/configuration/pieces/boolean-operators#h.hb6qblvd18wq'
    },
    BooleanToVariable: {
        name: 'Boolean to Variable',
        type: PieceType.BOOLEAN_TO_VARIABLE,
        tooltip: 'https://support.diceflow.in/configuration/pieces/boolean-operators#h.hb6qblvd18wq'
    },
    IsDefined: {
        name: 'Is Defined',
        type: PieceType.IS_DEFINED,
        tooltip: 'https://support.diceflow.in/configuration/pieces/boolean-operators#h.8bxak3hgq3vd'
    },
    IsNotDefined: {
        name: 'Is Not Defined',
        type: PieceType.IS_NOT_DEFINED,
        tooltip: 'https://support.diceflow.in/configuration/pieces/boolean-operators#h.8bxak3hgq3vd'
    },
};

export const DateOperatorsPieces: { [key: string]: PieceCategory } = {
    AddMonths: {
        name: 'Add Months',
        type: PieceType.ADD_MONTHS,
        tooltip: 'https://support.diceflow.in/configuration/pieces/date-operators#h.ax7rmucvj8bq'
    },
    AddYears: {
        name: 'Add Years',
        type: PieceType.ADD_YEARS,
        tooltip: 'https://support.diceflow.in/configuration/pieces/date-operators#h.njh2xydlgs0d'
    },
    SubtractMonths: {
        name: 'Subtract Months',
        type: PieceType.SUBTRACT_MONTHS,
        tooltip: 'https://support.diceflow.in/configuration/pieces/date-operators#h.jykwvks80v5t'
    },
    SubtractYears: {
        name: 'Subtract Years',
        type: PieceType.SUBTRACT_YEARS,
        tooltip: 'https://support.diceflow.in/configuration/pieces/date-operators#h.9t286xogved2'
    },
    GetDate: {
        name: 'Get Date',
        type: PieceType.GET_DATE,
        tooltip: 'https://support.diceflow.in/configuration/pieces/date-operators#h.tx40pmi5n28w'
    },
    GetDay: {
        name: 'Get Day',
        type: PieceType.GET_DAY,
        tooltip: 'https://support.diceflow.in/configuration/pieces/date-operators#h.tx40pmi5n28w'
    },
    GetMonth: {
        name: 'Get Month',
        type: PieceType.GET_MONTH,
        tooltip: 'https://support.diceflow.in/configuration/pieces/date-operators#h.tx40pmi5n28w'
    },
    GetReadableMonth: {
        name: 'Get Readable Month',
        type: PieceType.GET_READABLE_MONTH,
        tooltip: 'https://support.diceflow.in/configuration/pieces/date-operators#h.tx40pmi5n28w'
    },
    GetYear: {
        name: 'Get Year',
        type: PieceType.GET_YEAR,
        tooltip: 'https://support.diceflow.in/configuration/pieces/date-operators#h.tx40pmi5n28w'
    },
    GetTimeDifference: {
        name: 'Get Time Difference',
        type: PieceType.GET_TIME_DIFFERENCE,
        tooltip: 'https://support.diceflow.in/configuration/pieces/date-operators#h.lmypciukcblr'
    },
}

export const VariablePieces: { [key: string]: PieceCategory } = {
    Variable: {
        name: 'Variable',
        type: PieceType.VARIABLE,
        tooltip: 'https://support.diceflow.in/configuration/pieces/variables#h.hypy27j2i32w',
    },
    SetVariable: {
        name: 'Set Variable',
        type: PieceType.SET_VARIABLE,
        tooltip: 'https://support.diceflow.in/configuration/pieces/variables#h.xdc98ov3wfrd',
    },
    StyleTableVariable: {
        name: 'Style Table Variable',
        type: PieceType.STYLE_TABLE_SECTION,
        tooltip: 'https://support.diceflow.in/configuration/pieces/variables#h.qlxq9x3w0tc8',
    },
};

export const ListOperatorsPieces: { [key: string]: PieceCategory } = {
    PickFirstElement: {
        name: 'Pick first element',
        type: PieceType.PICK_FIRST_ELEMENT,
        tooltip: 'https://support.diceflow.in/configuration/pieces/list-operators#h.g6fb78jnoig8'
    },
    PickFirstNElements: {
        name: 'Pick first N elements',
        type: PieceType.PICK_FIRST_N_ELEMENTS,
        tooltip: 'https://support.diceflow.in/configuration/pieces/list-operators#h.vyrt6dowxthf'
    },
    PickLastElement: {
        name: 'Pick last element',
        type: PieceType.PICK_LAST_ELEMENT,
        tooltip: 'https://support.diceflow.in/configuration/pieces/list-operators#h.vxifkk46qcxd'
    },
    PickLastNElements: {
        name: 'Pick last N elements',
        type: PieceType.PICK_LAST_N_ELEMENTS,
        tooltip: 'https://support.diceflow.in/configuration/pieces/list-operators#h.johgc22wdu4y'
    },
    PickNthElement: {
        name: 'Pick Nth element',
        type: PieceType.PICK_NTH_ELEMENT,
        tooltip: 'https://support.diceflow.in/configuration/pieces/list-operators#h.ox7kcg22u1ni'
    },
    SplitBySeparator: {
        name: 'Split by separator',
        type: PieceType.SPLIT_BY_SEPARATOR,
        tooltip: 'https://support.diceflow.in/configuration/pieces/list-operators#h.egq8wc42he7t'
    },
    AddToList: {
        name: 'Add to list',
        type: PieceType.ADD_TO_LIST,
        tooltip: 'https://support.diceflow.in/configuration/pieces/list-operators#h.838am1a15rpk'
    },
    RemoveFromList: {
        name: 'Remove from list',
        type: PieceType.REMOVE_FROM_LIST,
        tooltip: 'https://support.diceflow.in/configuration/pieces/list-operators#h.dbdiyq3ctmqd'
    },
    AddToTable: {
        name: 'Add to table',
        type: PieceType.ADD_TO_TABLE,
        tooltip: 'https://support.diceflow.in/configuration/pieces/list-operators#h.ozqwi09ihclm'
    },
    Length: {
        name: 'Length',
        type: PieceType.LENGTH,
        tooltip: 'https://support.diceflow.in/configuration/pieces/list-operators#h.q9f77zo0tttv'
    },
};

export const QuestionAnswerPieces: { [key: string]: PieceCategory } = {
    Question: {
        name: 'Question',
        type: PieceType.QUESTION,
        tooltip: 'https://support.diceflow.in/configuration/pieces/questions-and-answers#h.7bi2sn3xd2hj',
    },
    GroupedQuestion: {
        name: 'Grouped Question',
        type: PieceType.GROUPED_QUESTION,
        tooltip: 'https://support.diceflow.in/configuration/pieces/questions-and-answers#h.eruyqeqbi7sd',
    },
    Choose: {
        name: 'Choose',
        type: PieceType.CHOOSE,
        tooltip: 'https://support.diceflow.in/configuration/pieces/questions-and-answers#h.vkso6noqtr9k',
    },
    GroupedChoose: {
        name: 'Grouped Choose',
        type: PieceType.GROUPED_CHOOSE,
        tooltip: 'https://support.diceflow.in/configuration/pieces/questions-and-answers#h.z9oswwrp87hd',
    },
    Answer: {
        name: 'Answer',
        type: PieceType.ANSWER,
        tooltip: 'https://support.diceflow.in/configuration/pieces/questions-and-answers#h.6zj9gnae1zsd',
    },
    GroupedAnswer: {
        name: 'Grouped Answer',
        type: PieceType.GROUPED_ANSWER,
        tooltip: 'https://support.diceflow.in/configuration/pieces/questions-and-answers#h.dezyf49l0mch',
    },
    Success: {
        name: 'Success',
        type: PieceType.SUCCESS,
        tooltip: 'https://support.diceflow.in/configuration/pieces/questions-and-answers#h.dv2ebm471ia7',
    },
    Error: {
        name: 'Error',
        type: PieceType.ERROR,
        tooltip: 'https://support.diceflow.in/configuration/pieces/questions-and-answers#h.9eop3knr8qm0',
    }
};

export const IntegrationsPieces: { [key: string]: PieceCategory } = {
    FinsallLoanProcess: {
        name: 'Finsall Loan Process',
        type: PieceType.FINSAL_LOAN_PROCESS,
        tooltip: 'https://support.diceflow.in/configuration/pieces/integrations-finsall'
    },
}

