import React, { Component } from 'react';
import styles from './step-piece/StepPiece.module.scss';
import continueStyles from './ContinuePiece.module.scss';
import StepPiece from './step-piece/StepPiece'
import Input from '../Input';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setConditionPiece, setMessage } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import { booleanPieceSlotTarget, valuePieceSlotTarget } from './utilities';


type ContinuePieceProps = {
    conditionPiece?: JSX.Element,
    messagePiece?: JSX.Element,
    messageText?: string,
    nextPiece?: JSX.Element,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
        setConditionPiece: (pieceId: string, value: string|undefined) => dispatch(setConditionPiece(pieceId, value)),
        setMessage: (pieceId: string, value: string|undefined) => dispatch(setMessage(pieceId, value)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = ContinuePieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type ContinuePieceState = {
    isHoveringOverCondition: boolean,
    isHoveringOverMessage: boolean,
}

class ConnectedContinuePiece extends Component<Props, ContinuePieceState> {

    state = {
        isHoveringOverCondition: false,
        isHoveringOverMessage: false,
    }

    handleHoverOverCondition = () => {
        this.setState({
            isHoveringOverCondition: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        booleanPieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfCondition = () => {
        this.setState({
            isHoveringOverCondition: false,
        });
    };

    handleHoverOverMessage = () => {
        this.setState({
            isHoveringOverMessage: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfMessage = () => {
        this.setState({
            isHoveringOverMessage: false,
        });
    };

    handleMessageValueUpdate = (value: string) => {
        this.props.setMessage(this.props.pieceId, value);
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && (this.state.isHoveringOverCondition || this.state.isHoveringOverMessage)) {

            if (this.state.isHoveringOverCondition) {
                this.props.setConditionPiece(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverMessage) {
                this.props.setMessage(this.props.pieceId, this.props.lastDraggedPiece.id);
            }

            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverCondition: false,
                isHoveringOverMessage: false,
            });
        }
    }

    render() {
    
        return (
            <StepPiece theme="aqua" {...this.props}>
                <div className={styles.text}>continue if</div>
                {this.props.conditionPiece || <div className={(this.state.isHoveringOverCondition && this.props.isDragging && this.props.targetPiece ? continueStyles.booleanIndicatorHovering : continueStyles.booleanIndicator) + ' attachment-target'} onMouseOver={this.handleHoverOverCondition} onMouseOut={this.handleHoverOutOfCondition}></div>}
                <div className={styles.text}>else show message: </div>
                {this.props.messagePiece || <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverMessage && !!this.props.targetPiece} defaultText={this.props.messageText} onMouseOver={this.handleHoverOverMessage} onMouseOut={this.handleHoverOutOfMessage} onChange={this.handleMessageValueUpdate} />}
            </StepPiece>
        )
        
    }
}

const ContinuePiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedContinuePiece)

export default ContinuePiece;