import React, { ChangeEvent, MouseEvent, Component } from 'react';
import styles from './QuestionPiece.module.scss';
import Input from '../Input';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import { Option } from '../drop-down/ListItem';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setNextPiece, setQuestionData, setMemberVariable, setCustomField } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import { valuePieceSlotTarget } from './utilities';


type QuestionPieceEssentialsProps = {
    questionVariablePiece?: JSX.Element,
    questionText?: string,
    memberVariable?: JSX.Element,
    isForSingleMember?: boolean,

    selectedCustomField?: string,
    customFields: Array<Option>,
    nextPiece?: JSX.Element,

    pieceName: string,

    isValid?: boolean,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,
        workflowCustomFieldsData: state.workflows.types.customFields,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
        setNextPiece: (targetPieceId: string, draggedPieceId: string) => dispatch(setNextPiece(targetPieceId, draggedPieceId)),
        setQuestionData: (targetPieceId: string, draggedPieceId: string) => dispatch(setQuestionData(targetPieceId, draggedPieceId)),
        setMemberVariable: (targetPieceId: string, draggedPieceId: string) => dispatch(setMemberVariable(targetPieceId, draggedPieceId)),
        setCustomField: (pieceId: string, value: string) => dispatch(setCustomField(pieceId, value)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = QuestionPieceEssentialsProps & StateProps & DispatchProps & FlowchartPieceProps;

type QuestionPieceEssentialsState = {
    isHoveringOverMemberPiece: boolean,
    isHoveringOverVariablePiece: boolean,
    searchText: string,
}

class ConnectedQuestionPieceEssentials extends Component<Props, QuestionPieceEssentialsState>  {

    state = {
        isHoveringOverMemberPiece: false,
        isHoveringOverVariablePiece: false,
        searchText: '',
    };

    searchForCustomField = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            searchText: e.target.value,
        });
    }

    handleHoverOverVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: false,
        });
    };

    handleHoverOverMemberPiece = () => {
        this.setState({
            isHoveringOverMemberPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfMemberPiece = () => {
        this.setState({
            isHoveringOverMemberPiece: false,
        });
    };

    handleQuestionValueUpdate = (value: string) => {
        this.props.setQuestionData(this.props.pieceId, value);
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && (this.state.isHoveringOverMemberPiece || this.state.isHoveringOverVariablePiece)) {
            if (this.state.isHoveringOverMemberPiece) {
                this.props.setMemberVariable(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverVariablePiece) {
                this.props.setQuestionData(this.props.pieceId, this.props.lastDraggedPiece.id)
            }

            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverMemberPiece: false,
                isHoveringOverVariablePiece: false,
            });
        }
    }

    render() {

        const customFieldName = this.props.selectedCustomField && this.props.selectedCustomField in this.props.workflowCustomFieldsData.byId ? this.props.workflowCustomFieldsData.byId[this.props.selectedCustomField].name : undefined;

        const variableSelectBox = <SelectBox theme="pink" selectionPromptText={customFieldName}>
            <DropDownList theme="pink" dismissAfterSelection={true}>
                <div className={styles.nameContainer + ' ignore-options-onclickoutside'}>
                    <input className={styles.nameInput} onChange={this.searchForCustomField} value={this.state.searchText} type="text" placeholder="Search custom field" />
                </div>
                {this.props.customFields
                .filter(customField => customField.name.toLocaleLowerCase().includes(this.state.searchText.toLocaleLowerCase()))
                .map((customField, index) => <ListItem name={customField.name} key={index} value={customField.value} theme="pink" onClick={this.props.setCustomField.bind(this, this.props.pieceId)} />)}
            </DropDownList>
        </SelectBox>;

        return (
            <div className={styles.visibleItems}>
                <div className={styles.text}>{this.props.pieceName}</div>
                {this.props.questionVariablePiece ? this.props.questionVariablePiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverVariablePiece && !!this.props.targetPiece} defaultText={this.props.questionText} placeholderText="Enter the question here" minSize={24} onMouseOver={this.handleHoverOverVariablePiece} onMouseOut={this.handleHoverOutOfVariablePiece} onChange={this.handleQuestionValueUpdate} />}
                <div className={styles.text}>stored in</div>
                {variableSelectBox}
                {this.props.isForSingleMember && <div className={styles.text}>for</div>}
                {this.props.isForSingleMember && (this.props.memberVariable ? this.props.memberVariable : <Input placeholderText="Member variable" minSize={15} canReceiveDrag={this.props.isDragging && this.state.isHoveringOverMemberPiece && !!this.props.targetPiece} isDisabled onMouseOver={this.handleHoverOverMemberPiece} onMouseOut={this.handleHoverOutOfMemberPiece} />)}
            </div>
        )

    }
}

const QuestionPieceEssentials = connect(mapStateToProps, mapDispatchToProps)(ConnectedQuestionPieceEssentials);

export default QuestionPieceEssentials;