import React, { Component } from 'react';
import styles from './SequenceOperator.module.scss';

import SelectBox from '../../drop-down/SelectBox';
import DropDownList from '../../drop-down/DropDownList';
import ListItem from '../../drop-down/ListItem';
import FlowchartPiece, { OwnProps as FlowchartPieceProps } from '../FlowchartPiece';
import { Option } from '../../drop-down/ListItem';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setSelectedOptions } from '../../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../../shared/store/types';
import { PieceType } from '../../../../shared/store/flowchart/pieces/types';


export type SequenceOperatorProps = {
    pieceId: string,
    options?: Array<Option>,
}

const mapStateToProps = (state: ApplicationState, ownProps: SequenceOperatorProps) => {
    const pieceData = state.flowchart.pieces.byId[ownProps.pieceId];

    if (pieceData.type !== PieceType.SEQUENCE) {
        throw new Error('This piece ID must only belong to a sequence piece');
    }

    return {
        selectedOptions: pieceData.selectedOptions,
    }
}

const mapDispatchToProps = (dispatch: Dispatch, ownProps: SequenceOperatorProps) => {

    return {
        setSelectedOptions: (selectedOptions: Array<string>) => dispatch(setSelectedOptions(ownProps.pieceId, selectedOptions)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = SequenceOperatorProps & StateProps & DispatchProps & FlowchartPieceProps;

class ConnectedSequenceOperator extends Component<Props> {

    updateSelectedOptions = (option: string) => {
        let selectedOptions = this.props.selectedOptions ? this.props.selectedOptions.slice(0) : [];

        if (selectedOptions.includes(option)) {
            selectedOptions = selectedOptions.filter(selectedOption => option !== selectedOption);
        } else {
            selectedOptions.push(option);
        }

        this.props.setSelectedOptions(selectedOptions);
    }

    getReadableName = (variableName: string) => {
        return variableName.split('_').map(word => word[0].toUpperCase() + word.substring(1)).join(' ');
    }

    render() {

        const selectedFieldsText = this.props.selectedOptions ? this.props.selectedOptions.map(selectedOption => {
            if (!this.props.options) {
                throw new Error('You cannot have selected options without any options');
            }

            const option = this.props.options.find(option => option.value === selectedOption);

            if (typeof option === 'undefined') {
                throw new Error('The selected option is not present in the options');
            }

            return this.getReadableName(option.name);
        }).join(', ') : undefined;

        const fieldsSelectBox = this.props.options ? <SelectBox theme="pink" selectionPromptText={selectedFieldsText}>
            <DropDownList theme="pink">
                {this.props.options.map((option, index) => <ListItem name={option.name} value={option.value} key={index} theme="pink" onClick={this.updateSelectedOptions} />)}
            </DropDownList>
        </SelectBox> : undefined;

        return (
            <FlowchartPiece {...this.props}>
                <div className={styles.sequenceOperator}>
                    <div className={styles.operatorText}>sequence</div>
                    {fieldsSelectBox}
                </div>
            </FlowchartPiece>
        )
    }
}

const SequenceOperator = connect(mapStateToProps, mapDispatchToProps)(ConnectedSequenceOperator);

export default SequenceOperator;