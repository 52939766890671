import { RouteParam, SavedStates, SavedStatesActionTypes, SET_CURRENT_ROUTE, SET_COMPONENTS_WIDGET_SEARCH_TERM } from "./types";


const initialState: SavedStates = {
    previousRoute: {
        dashboard: RouteParam.DASHBOARD,
        structure: RouteParam.STRUCTURE_HIERARCHY,
        users: RouteParam.USERS_LIST,
        groups: RouteParam.GROUPS_LIST,
        members: RouteParam.MEMBERS_LIST,
        workflows: RouteParam.WORKFLOWS_LIST,
        reports: RouteParam.REPORTS_LIST,
        data: RouteParam.DATA,
        languages: RouteParam.LANGUAGES,
    },
    searchTerms: [],
}

export function savedStatesReducer(state = initialState, action: SavedStatesActionTypes): SavedStates {

    switch (action.type) {
        case SET_CURRENT_ROUTE:
            let updatedPrevoiusRoute = { ...state.previousRoute, [action.routeComponent]: action.routeParam }
            return {
                ...state,
                previousRoute: updatedPrevoiusRoute
            };
        case SET_COMPONENTS_WIDGET_SEARCH_TERM:
            let updatedSearchTerm = {...state.searchTerms, [action.widgetName]: action.searchTerm}
            console.log(updatedSearchTerm)
            return {
                ...state,
                searchTerms: updatedSearchTerm
            }
        default:
            return state
    }
}