import React, { Component } from 'react';
import styles from './TriToggle.module.scss';

export type TriToggleOptions = 'positive' | 'middle' | 'negative';

type OwnProps = {
    toggleId: string,
    toggleState: TriToggleOptions,

    onToggle: (id: string, state: TriToggleOptions) => void;
}

type OwnState = {
    leftToRight: boolean,
}

class Toggle extends Component<OwnProps, OwnState> {
    state = {
        leftToRight: true,
    };

    handleToggleClick = (toggleState: TriToggleOptions) => {
        this.props.onToggle(this.props.toggleId, toggleState);
    }

    render() {
        let toggleClass: string;

        switch (this.props.toggleState) {
            case 'positive':
                toggleClass = styles.positive;
                break;
            case 'middle':
                toggleClass = styles.middle;
                break;
            case 'negative':
                toggleClass = styles.negative;
                break;
            default:
                throw new Error('It must be one of the above')
        }

        return (
            <section className={toggleClass} >
                <div className={styles.negativePiece} onClick={() => this.handleToggleClick('negative')}></div>
                <div className={styles.middlePiece} onClick={() => this.handleToggleClick('middle')}></div>
                <div className={styles.positivePiece} onClick={() => this.handleToggleClick('positive')}></div>
                <div className={styles.slider}></div>
            </section>
        );

    }
}

export default Toggle;