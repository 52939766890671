import { reOrderList, getReadableDate } from '../../helpers/utilities';
import { DefaultFlowchartProcessState } from '../flowchart/types';
import { getLevelComputedFieldValue } from '../flowchart/helpers/custom-fields/level';
import { getRoleComputedFieldValue } from '../flowchart/helpers/custom-fields/role';
import { getUserComputedFieldValue } from '../flowchart/helpers/custom-fields/user';
import { getMemberComputedFieldValue } from '../flowchart/helpers/custom-fields/member';
import { getGroupComputedFieldValue } from '../flowchart/helpers/custom-fields/group';
import { getWorkflowComputedFieldValue } from '../flowchart/helpers/custom-fields/workflow';
import store from '../main';
import { ApplicationState } from '../types'
import { translatePhrase } from '../../helpers/translation';
import moment from 'moment';
import { PiecePositionState, VariableValueType } from '../../helpers/common-types';
import { CustomFieldState, IUpdateableCustomFieldData, IUpdateableFieldChoiceData, CustomFieldOptionsDataType, CustomField, CustomFieldValueType, FieldType, FieldChoice, WorkflowTypeCustomField, CustomFieldDataForIndividualMembers } from './types';

export function selectCustomField<T extends CustomFieldState>(state: T, id: string): T {
    return {
        ...state,
        selectedField: id,
    }
}

export function unSelectCustomField<T extends CustomFieldState>(state: T): T {
    return {
        ...state,
        selectedField: undefined,
    }
}

export function addCustomField<T extends CustomFieldState>(state: T, payload: IUpdateableCustomFieldData, parentId: string | undefined, currentTime: string): T {
    return {
        ...state,
        customFields: {
            ...state.customFields,
            byId: {
                ...state.customFields.byId,
                [payload.id]: {
                    ...payload,
                    parentId,
                    choices: [],
                    isolatedPieces: [],
                    createdTime: currentTime,
                    lastUpdatedTime: currentTime,
                },
            },
            allFields: state.customFields.allFields.concat([payload.id]),
        },
        createdCustomFieldIds: new Set(Array.from(state.createdCustomFieldIds).concat([payload.id])),
    }
}

export function deleteCustomField<T extends CustomFieldState>(state: T, id: string, currentTime: string): T {
    return {
        ...state,
        customFields: {
            ...state.customFields,
            byId: {
                ...state.customFields.byId,
                [id]: {
                    ...state.customFields.byId[id],
                    lastUpdatedTime: currentTime,
                    archived: true,
                }
            },
            allFields: state.customFields.allFields.filter(fieldId => fieldId !== id),
        },
        deletedCustomFieldIds: new Set(Array.from(state.deletedCustomFieldIds).concat([id])),
    }
}

export function updateCustomField<T extends CustomFieldState>(state: T, payload: IUpdateableCustomFieldData, currentTime: string): T {
    return {
        ...state,
        customFields: {
            ...state.customFields,
            byId: {
                ...state.customFields.byId,
                [payload.id]: {
                    ...state.customFields.byId[payload.id],
                    ...payload,
                    lastUpdatedTime: currentTime,
                }
            },
        },
        updatedCustomFieldIds: new Set(Array.from(state.updatedCustomFieldIds).concat([payload.id])),
    }
}

export function selectCustomFieldOption<T extends CustomFieldState>(state: T, id: string): T {
    return {
        ...state,
        selectedOption: id,
    }
}

export function unSelectCustomFieldOption<T extends CustomFieldState>(state: T): T {
    return {
        ...state,
        selectedOption: undefined,
    }
}

export function reOrderCustomFieldOptions<T extends CustomFieldState>(state: T, sourceIndex: number, destinationIndex: number, parentId: string): T {
    const reOrderedList = reOrderList(state.customFields.byId[parentId].choices, sourceIndex, destinationIndex);

    return {
        ...state,
        customFields: {
            ...state.customFields,
            byId: {
                ...state.customFields.byId,
                [parentId]: {
                    ...state.customFields.byId[parentId],
                    choices: reOrderedList,
                }
            },
        },
        reOrderedCustomFieldOptions: {
            ...state.reOrderedCustomFieldOptions,
            [parentId]: reOrderedList,
        },
    }
}

export function updateStartPieceForCustomField<T extends CustomFieldState>(state: T, customFieldId: string, pieceState: PiecePositionState, currentTime: string): T {

    return {
        ...state,
        customFields: {
            ...state.customFields,
            byId: {
                ...state.customFields.byId,
                [customFieldId]: {
                    ...state.customFields.byId[customFieldId],
                    startPiece: pieceState,
                    lastUpdatedTime: currentTime,
                }
            }
        },
        updatedCustomFieldIds: new Set(Array.from(state.updatedCustomFieldIds).concat([customFieldId])),
    }
}

export function setIsolatedPieceForCustomField<T extends CustomFieldState>(state: T, customFieldId: string, pieceState: PiecePositionState, currentTime: string): T {

    const newCustomFieldIsolatedPieces = state.customFields.byId[customFieldId].isolatedPieces.slice(0);
    const customFieldIsolatedPieceIndex = newCustomFieldIsolatedPieces.findIndex(isolatedPieceData => isolatedPieceData.piece === pieceState.piece);

    if (customFieldIsolatedPieceIndex < 0) {
        newCustomFieldIsolatedPieces.push(pieceState);
    } else {
        newCustomFieldIsolatedPieces[customFieldIsolatedPieceIndex] = pieceState;
    }

    return {
        ...state,
        customFields: {
            ...state.customFields,
            byId: {
                ...state.customFields.byId,
                [customFieldId]: {
                    ...state.customFields.byId[customFieldId],
                    isolatedPieces: newCustomFieldIsolatedPieces,
                    lastUpdatedTime: currentTime,
                }
            }
        },
        updatedCustomFieldIds: new Set(Array.from(state.updatedCustomFieldIds).concat([customFieldId])),
    }
}

export function removeIsolatedPieceForCustomField<T extends CustomFieldState>(state: T, customFieldId: string, pieceId: string, currentTime: string): T {

    return {
        ...state,
        customFields: {
            ...state.customFields,
            byId: {
                ...state.customFields.byId,
                [customFieldId]: {
                    ...state.customFields.byId[customFieldId],
                    isolatedPieces: state.customFields.byId[customFieldId].isolatedPieces.filter(pieceData => pieceData.piece !== pieceId),
                    lastUpdatedTime: currentTime,
                }
            }
        },
        updatedCustomFieldIds: new Set(Array.from(state.updatedCustomFieldIds).concat([customFieldId])),
    }
}

export function registerVariableForCustomField<T extends CustomFieldState>(state: T, customFieldId: string, variableId: string, currentTime: string): T {

    return {
        ...state,
        customFields: {
            ...state.customFields,
            byId: {
                ...state.customFields.byId,
                [customFieldId]: {
                    ...state.customFields.byId[customFieldId],
                    variables: state.customFields.byId[customFieldId].variables ? state.customFields.byId[customFieldId].variables.concat([variableId]) : [variableId],
                    lastUpdatedTime: currentTime,
                }
            }
        },
        updatedCustomFieldIds: new Set(Array.from(state.updatedCustomFieldIds).concat([customFieldId])),
    }
}

export function addCustomFieldOption<T extends CustomFieldState>(state: T, payload: IUpdateableFieldChoiceData, parentId: string, currentTime: string): T {
    return {
        ...state,
        customFieldOptions: {
            ...state.customFieldOptions,
            byId: {
                ...state.customFieldOptions.byId,
                [payload.id]: {
                    ...payload,
                    parentId,
                    createdTime: currentTime,
                    lastUpdatedTime: currentTime,
                },
            },
            allOptions: state.customFieldOptions.allOptions.concat([payload.id]),
        },
        customFields: {
            ...state.customFields,
            byId: {
                ...state.customFields.byId,
                [parentId]: {
                    ...state.customFields.byId[parentId],
                    lastUpdatedTime: currentTime,
                    choices: state.customFields.byId[parentId].choices.concat([payload.id])
                }
            }
        },
        reOrderedCustomFieldOptions: {
            ...state.reOrderedCustomFieldOptions,
            [parentId]: state.customFields.byId[parentId].choices.concat([payload.id]),
        },
        createdCustomFieldOptionIds: new Set(Array.from(state.createdCustomFieldOptionIds).concat([payload.id])),
    }
}

export function deleteCustomFieldOption<T extends CustomFieldState>(state: T, id: string, parentId: string, currentTime: string): T {
    return {
        ...state,
        customFieldOptions: {
            ...state.customFieldOptions,
            byId: {
                ...state.customFieldOptions.byId,
                [id]: {
                    ...state.customFieldOptions.byId[id],
                    lastUpdatedTime: currentTime,
                    archived: true,
                },
            },
            allOptions: state.customFieldOptions.allOptions.filter(fieldId => fieldId !== id),
        },
        customFields: {
            ...state.customFields,
            byId: {
                ...state.customFields.byId,
                [parentId]: {
                    ...state.customFields.byId[parentId],
                    lastUpdatedTime: currentTime,
                    choices: state.customFields.byId[parentId].choices.filter(optionId => optionId !== id),
                }
            }
        },
        reOrderedCustomFieldOptions: {
            ...state.reOrderedCustomFieldOptions,
            [parentId]: state.customFields.byId[parentId].choices.filter(optionId => optionId !== id),
        },
        deletedCustomFieldOptionIds: new Set(Array.from(state.deletedCustomFieldOptionIds).concat([id])),
    }
}

export function updateCustomFieldOption<T extends CustomFieldState>(state: T, payload: IUpdateableFieldChoiceData, currentTime: string): T {
    return {
        ...state,
        customFieldOptions: {
            ...state.customFieldOptions,
            byId: {
                ...state.customFieldOptions.byId,
                [payload.id]: {
                    ...state.customFieldOptions.byId[payload.id],
                    ...payload,
                    lastUpdatedTime: currentTime,
                },
            },
        },
        updatedCustomFieldOptionIds: new Set(Array.from(state.updatedCustomFieldOptionIds).concat([payload.id])),
    }
}

function getOptionsData(entityType: 'level' | 'role' | 'user' | 'member' | 'group' | 'workflow', state?: ApplicationState) {

    const applicationState = state ? state : store.getState();
    let optionsData: CustomFieldOptionsDataType;

    switch (entityType) {
        case 'level':
            optionsData = applicationState.structure.levels.customFieldOptions;
            break;
        case 'role':
            optionsData = applicationState.structure.roles.customFieldOptions;
            break;
        case 'user':
            optionsData = applicationState.users.customFieldOptions;
            break;
        case 'member':
            optionsData = applicationState.members.types.customFieldOptions;
            break;
        case 'group':
            optionsData = applicationState.groups.types.customFieldOptions;
            break;
        case 'workflow':
            optionsData = applicationState.workflows.types.customFieldOptions;
            break;
        default:
            throw new Error('Unknown entity type');
    }

    return optionsData;

}

export function getValueForComputedField(customField: CustomField, entityId: string, entityType: 'level' | 'role' | 'user' | 'member' | 'group' | 'workflow', state?: ApplicationState): CustomFieldValueType {

    if (!customField.isComputed) {
        return undefined;
    }

    if (typeof customField.startPiece === 'undefined') {
        throw new Error('A computed field must have a start piece');
    }

    let customFieldValue: VariableValueType;
    let processState: DefaultFlowchartProcessState;
    const applicationState = state ? state : store.getState();

    switch (entityType) {
        case 'level':
            const location = applicationState.structure.locations.byId[entityId];
            processState = {
                customFields: { ...location.customFields },
                variables: {
                    [customField.seedEntityVariable]: location.id,
                },
                lastComputedPiece: undefined,
                executionStack: [],
                forIterationCounts: {},
                displayingQuestionPieceId: undefined,
                displayingShowPieceId: undefined,
                displayingGroupPieceId: undefined,
                displayingTransferPieceId: undefined,
                displayingContinuePieceId: undefined,
                displayingAddWorkflowPieceId: undefined,
                createdWorkflowId: undefined,
                displayingFinsalLoanProcessPieceId: undefined,
            };

            try {
                customFieldValue = getLevelComputedFieldValue(applicationState, processState, customField.startPiece.piece, location.id, customField);
            } catch {
                customFieldValue = '-'
            }

            break;
        case 'role':
            const roleUser = applicationState.users.byId[entityId];
            processState = {
                customFields: { ...roleUser.customFields },
                variables: {
                    [customField.seedEntityVariable]: roleUser.id,
                },
                lastComputedPiece: undefined,
                executionStack: [],
                forIterationCounts: {},
                displayingQuestionPieceId: undefined,
                displayingShowPieceId: undefined,
                displayingGroupPieceId: undefined,
                displayingTransferPieceId: undefined,
                displayingContinuePieceId: undefined,
                displayingAddWorkflowPieceId: undefined,
                createdWorkflowId: undefined,
                displayingFinsalLoanProcessPieceId: undefined,
            };

            try {
                customFieldValue = getRoleComputedFieldValue(applicationState, processState, customField.startPiece.piece, roleUser.id, customField);
            } catch {
                customFieldValue = '-'
            }

            break;
        case 'user':
            const normalUser = applicationState.users.byId[entityId];
            processState = {
                customFields: { ...normalUser.customFields },
                variables: {
                    [customField.seedEntityVariable]: normalUser.id,
                },
                lastComputedPiece: undefined,
                executionStack: [],
                forIterationCounts: {},
                displayingQuestionPieceId: undefined,
                displayingShowPieceId: undefined,
                displayingGroupPieceId: undefined,
                displayingTransferPieceId: undefined,
                displayingContinuePieceId: undefined,
                displayingAddWorkflowPieceId: undefined,
                createdWorkflowId: undefined,
                displayingFinsalLoanProcessPieceId: undefined,
            };

            try {
                customFieldValue = getUserComputedFieldValue(applicationState, processState, customField.startPiece.piece, normalUser.id, customField);
            } catch {
                customFieldValue = '-'
            }

            break;
        case 'member':
            const member = applicationState.members.byId[entityId];
            processState = {
                customFields: { ...member.customFields },
                variables: {
                    [customField.seedEntityVariable]: member.id,
                },
                lastComputedPiece: undefined,
                executionStack: [],
                forIterationCounts: {},
                displayingQuestionPieceId: undefined,
                displayingShowPieceId: undefined,
                displayingGroupPieceId: undefined,
                displayingTransferPieceId: undefined,
                displayingContinuePieceId: undefined,
                displayingAddWorkflowPieceId: undefined,
                createdWorkflowId: undefined,
                displayingFinsalLoanProcessPieceId: undefined,
            };

            try {
                customFieldValue = getMemberComputedFieldValue(applicationState, processState, customField.startPiece.piece, member.id, customField);
            } catch {
                customFieldValue = '-'
            }

            break;
        case 'group':
            const group = applicationState.groups.byId[entityId];
            processState = {
                customFields: { ...group.customFields },
                variables: {
                    [customField.seedEntityVariable]: group.id,
                },
                lastComputedPiece: undefined,
                executionStack: [],
                forIterationCounts: {},
                displayingQuestionPieceId: undefined,
                displayingShowPieceId: undefined,
                displayingGroupPieceId: undefined,
                displayingTransferPieceId: undefined,
                displayingContinuePieceId: undefined,
                displayingAddWorkflowPieceId: undefined,
                createdWorkflowId: undefined,
                displayingFinsalLoanProcessPieceId: undefined,
            };

            try {
                customFieldValue = getGroupComputedFieldValue(applicationState, processState, customField.startPiece.piece, group.id, customField);
            } catch {
                customFieldValue = '-'
            }

            break;
        case 'workflow':
            const workflowCustomField = customField as WorkflowTypeCustomField;
            const workflow = applicationState.workflows.byId[entityId];
            const currentProcessState = workflow.historyIndex >= workflow.history.length ? workflow.history[workflow.history.length - 1] : workflow.history[workflow.historyIndex];

            const workflowProcessState = {
                customFields: { ...currentProcessState.customFields },
                variables: {
                    [workflowCustomField.seedEntityVariable]: workflow.id,
                },
                lastComputedPiece: undefined,
                executionStack: [],
                forIterationCounts: {},
                displayingQuestionPieceId: undefined,
                displayingShowPieceId: undefined,
                displayingGroupPieceId: undefined,
                displayingTransferPieceId: undefined,
                displayingContinuePieceId: undefined,
                displayingAddWorkflowPieceId: undefined,
                createdWorkflowId: undefined,
                displayingFinsalLoanProcessPieceId: undefined,
            };

            if (workflowCustomField.seedAffiliationVariable) {
                workflowProcessState.variables[workflowCustomField.seedAffiliationVariable] = workflow.affiliatedEntity;
            }

            try {
                customFieldValue = getWorkflowComputedFieldValue(applicationState, workflowProcessState, customField.startPiece.piece, workflow.id, workflowCustomField);
            } catch {
                customFieldValue = '-'
            }

            break;
        default:
            throw new Error('Unknown entity type');
    }

    if (Array.isArray(customFieldValue)) {

        if (customFieldValue.length > 0 && Array.isArray(customFieldValue[0])) {
            // Cannot be a multidimensional array
            throw new Error('The value cannot be a multi-dimensional array')
        }

        customFieldValue = customFieldValue as Array<string>;
    }

    return customFieldValue;
}

export function getReadableDataForNonComputedCustomField(value: CustomFieldValueType, customField: CustomField, customFieldOptions: Array<FieldChoice>) {
    let cellValue = '-';

    switch (customField.type) {
        case FieldType.SINGLE_SELECT:
            if (Array.isArray(value)) {
                throw new Error('A single select field should not have an array value');
            }

            if (typeof value === 'boolean') {
                throw new Error('A single select field should not have a boolean value.');
            }

            if (value) {
                const field = customFieldOptions.find(option => option.id === value);

                if (field) {
                    cellValue = field.name;
                }
            }

            break;

        case FieldType.MULTI_SELECT:
            if (typeof value === 'undefined') {
                break;
            }

            if (customField.isComputed && typeof value === 'string') {
                cellValue = value;
                break;
            }

            if (!Array.isArray(value)) {
                throw new Error('A multi select field should have an array value');
            }

            if (value) {
                const fields = customFieldOptions.filter(option => value.includes(option.id));
                cellValue = fields.map(singleValue => translatePhrase(singleValue.name)).join(', ');
            }

            break;

        case FieldType.DATE:
            if (Array.isArray(value)) {
                throw new Error('A date field should not have an array value');
            }

            if (typeof value === 'boolean') {
                throw new Error('A date field should not have a boolean value.');
            }

            if (typeof value === 'number') {
                throw new Error('A date field should not have a numeric value.');
            }

            if (value) {
                cellValue = getReadableDate(value);
            }

            break;

        case FieldType.BOOLEAN:
            if (typeof value === 'boolean') {
                cellValue = value ? 'Yes' : 'No';
            }

            break;

        case FieldType.LOCATION:
            if (typeof value === 'string' && value.split(' ').length === 2) {
                cellValue = value;
            }
            break;

        case FieldType.NUMBER:
            if (Array.isArray(value)) {
                throw new Error('A non multi-select field should not have an array value');
            }

            if (typeof value === 'boolean') {
                throw new Error('A default field should not have a boolean value.');
            }

            if (!isNaN(Number(value)) && (value || value === 0)) {
                cellValue = String(value);
            }

            break;

        default:
            if (Array.isArray(value)) {
                throw new Error('A non multi-select field should not have an array value');
            }

            if (typeof value === 'boolean') {
                throw new Error('A default field should not have a boolean value.');
            }

            if (value) {
                cellValue = String(value);
            }
    }

    return cellValue;
}

export function getReadableDataFromValue(value: CustomFieldValueType, customField: CustomField, optionsData: Array<FieldChoice>) {
    let cellValue = '-';

    switch (customField.type) {
        case FieldType.SINGLE_SELECT:
            if (Array.isArray(value)) {
                throw new Error('A single select field should not have an array value');
            }

            if (typeof value === 'boolean') {
                throw new Error('A single select field should not have a boolean value.');
            }

            if (customField.isComputed && value) {
                cellValue = translatePhrase(String(value));
            } else if (value) {
                const option = optionsData.find(option => option.id === value);
                if (option) {
                    cellValue = translatePhrase(option.name);
                }
            }

            break;

        case FieldType.MULTI_SELECT:
            if (typeof value === 'undefined') {
                break;
            }

            if (customField.isComputed && typeof value === 'string') {
                cellValue = value;
                break;
            }

            if (!Array.isArray(value)) {
                throw new Error('A multi select field should have an array value');
            }

            if (customField.isComputed && value) {
                cellValue = value.map(singleValue => translatePhrase(String(singleValue))).join(',');
            } else if (value) {
                cellValue = value.map(singleValue => {
                    const option = optionsData.find(option => option.id === singleValue);
                    if (option) {
                        return translatePhrase(option.name);
                    } else {
                        return '';
                    }
                }).join(', ');
            }

            break;

        case FieldType.DATE:
            if (Array.isArray(value)) {
                throw new Error('A date field should not have an array value');
            }

            if (typeof value === 'boolean') {
                throw new Error('A date field should not have a boolean value.');
            }

            if (typeof value === 'number') {
                throw new Error('A date field should not have a numeric value.');
            }

            if (value) {
                cellValue = getReadableDate(value);
            }

            break;

        case FieldType.BOOLEAN:
            if (typeof value === 'boolean') {
                cellValue = value ? 'Yes' : 'No';
            }

            break;

        case FieldType.LOCATION:
            if (typeof value === 'string' && value.split(' ').length === 2) {
                cellValue = value;
            }
            break;

        case FieldType.NUMBER:
            if (Array.isArray(value)) {
                throw new Error('A non multi-select field should not have an array value');
            }

            if (typeof value === 'boolean') {
                throw new Error('A default field should not have a boolean value.');
            }

            if (!isNaN(Number(value)) && (value || value === 0)) {
                cellValue = String(value);
            }

            break;

        default:
            if (Array.isArray(value)) {
                cellValue = value.join(', ');
            }

            if (typeof value === 'boolean') {
                cellValue = value ? 'Yes' : 'No';
            }

            if (value || value === 0) {
                cellValue = String(value);
            }

            break;
    }

    return cellValue;
}

export function getReadableDataForCustomField(value: CustomFieldValueType, customField: CustomField, entityId: string, entityType: 'level' | 'role' | 'user' | 'member' | 'group' | 'workflow', state?: ApplicationState) {
    const optionsData = getOptionsData(entityType, state);
    const options = optionsData.allOptions.map(optionId => optionsData.byId[optionId]);

    const cellValue = getReadableDataFromValue(value, customField, options);

    return cellValue;
}

export function exampleValueForCustomField(customField: CustomField, customFieldOptions: CustomFieldOptionsDataType) {
    switch (customField.type) {
        case FieldType.SINGLE_SELECT:
            const singleSelectOptions = customField.choices.map(optionId => {
                const option = customFieldOptions.byId[optionId];
                return option.name;
            });

            return 'Type exactly as defined: ' + singleSelectOptions.join(', ');
        case FieldType.MULTI_SELECT:
            const multiSelectOptions = customField.choices.map(optionId => {
                const option = customFieldOptions.byId[optionId];
                return option.name;
            });

            return 'Type exactly as defined in comma separated format: ' + multiSelectOptions.join(', ');
        case FieldType.BOOLEAN:
            return 'Yes or No';
        case FieldType.LOCATION:
            return '13.64432 75.6539';
        case FieldType.PHONE:
            return '+91 9999999999';
        case FieldType.NUMBER:
            return '3';
        case FieldType.DATE:
            return moment().format('DD MMM YYYY');
        case FieldType.TEXT:
            return 'Text value';
        case FieldType.FILE:
            return 'https://link-to-file.com/path-to-file/';
        case FieldType.FREE_TEXT:
            throw new Error('This field is not importable');
        default:
            return 'Value';
    }
}

export function getCustomFieldValueForInput(customField: CustomField, dataInput: string, customFieldOptions: CustomFieldOptionsDataType) {
    let customFieldValue: CustomFieldValueType;

    if (dataInput === '-') {
        return undefined;
    }

    switch (customField.type) {
        case FieldType.SINGLE_SELECT:
            if (dataInput.length > 0) {
                const selectedOption = customField.choices.find(optionId => {
                    const option = customFieldOptions.byId[optionId];

                    return option.name === dataInput;
                });

                if (typeof selectedOption !== 'undefined') {
                    customFieldValue = selectedOption;
                } else {
                    throw new Error(`${customField.name}: Value not recognized`);
                }
            }
            break;

        case FieldType.MULTI_SELECT:
            if (dataInput.length > 0) {
                const listOfValues = dataInput.split(',').map(word => word.trim());

                const options = customField.choices.map(optionId => customFieldOptions.byId[optionId]);
                const selectedOptionIds: Array<string> = [];

                for (const value of listOfValues) {
                    const option = options.find(option => option.name === value);
                    if (typeof option === 'undefined') {
                        throw new Error(`${customField.name}: Value not recognized`);
                    }

                    selectedOptionIds.push(option.id);
                }

                customFieldValue = selectedOptionIds;
            }
            break;

        case FieldType.BOOLEAN:
            if (dataInput.toLocaleLowerCase() === 'yes' || dataInput.toLocaleLowerCase() === 'true') {
                customFieldValue = true;
            } else if (dataInput.toLocaleLowerCase() === 'no' || dataInput.toLocaleLowerCase() === 'false') {
                customFieldValue = false;
            } else if (dataInput.length > 0) {
                throw new Error(`${customField.name}: Unknown value for boolean`)
            }
            break;

        case FieldType.LOCATION:
            if (dataInput.length > 0) {
                const coOrdinates = dataInput.split(' ').map(word => word.trim());

                if (coOrdinates.length !== 2) {
                    throw new Error(`${customField.name}: There must be exactly two values in co-ordinates`);
                }

                if (isNaN(Number(coOrdinates[0])) || isNaN(Number(coOrdinates[1]))) {
                    throw new Error(`${customField.name}: Both co-ordinates must be a number`);
                }

                customFieldValue = `${coOrdinates[0]} ${coOrdinates[1]}`;
            }

            break;

        case FieldType.PHONE:
            if (dataInput.length > 0) {
                const phoneNumber = dataInput.split(' ').map(word => word.trim());

                if (phoneNumber.length !== 2) {
                    throw new Error(`${customField.name}: There must be exactly two values in the phone number`);
                }

                if (isNaN(Number(phoneNumber[1]))) {
                    throw new Error(`${customField.name}: The phone number format is incorrect`);
                }

                const prefix = phoneNumber[0].startsWith('+') ? '' : '+';

                customFieldValue = `${prefix + phoneNumber[0]} ${phoneNumber[1]}`;
            }
            break;

        case FieldType.NUMBER:
            if (isNaN(Number(dataInput))) {
                throw new Error(`Invalid number`);
            }
            customFieldValue = Number(dataInput);
            break;

        case FieldType.DATE:
            if (dataInput.length > 0) {
                if (moment(dataInput, 'YYYY-MM-DD', true).isValid()) {
                    customFieldValue = dataInput;
                } else if (moment(dataInput, 'DD MMM YYYY', true).isValid()) {
                    customFieldValue = moment(dataInput, 'DD MMM YYYY').format('YYYY-MM-DD');
                } else if (moment(dataInput, 'DD-MMM-YYYY', true).isValid()) {
                    customFieldValue = moment(dataInput, 'DD-MMM-YYYY').format('YYYY-MM-DD');
                } else if (moment(dataInput, 'D MMM YYYY', true).isValid()) {
                    customFieldValue = moment(dataInput, 'D MMM YYYY').format('YYYY-MM-DD');
                } else if (moment(dataInput, 'D-MMM-YYYY', true).isValid()) {
                    customFieldValue = moment(dataInput, 'D-MMM-YYYY').format('YYYY-MM-DD');
                } else if (dataInput === 'Invalid date') {
                    customFieldValue = undefined;
                } else {
                    throw new Error(`Invalid date format for ${customField.name}. Accepted formats: YYYY-MM-DD, DD MMM YYYY, DD-MMM-YYYY, D MMM YYYY, D-MMM-YYYY`);
                }
            }
            break;

        default:
            customFieldValue = dataInput;
            break;
    }

    return customFieldValue;
}

interface CustomFieldDataHolder {
    [customFieldId: string]: CustomFieldValueType,
}

interface WorkflowCustomFieldDataHolder {
    [customFieldId: string]: CustomFieldValueType | CustomFieldDataForIndividualMembers,
}

export function areCustomFieldValuesEqual(holderOne: CustomFieldDataHolder, holderTwo: CustomFieldDataHolder) {
    if (holderOne === null) {
        return holderTwo === null;
    }

    if (typeof holderOne === 'undefined') {
        return typeof holderTwo === 'undefined';
    }

    // Return false if they do not have the same custom field IDs
    if (JSON.stringify(Object.keys(holderOne).sort()) !== JSON.stringify(Object.keys(holderTwo).sort())) {
        return false;
    }

    for (const fieldId in holderOne) {
        const valueOne = holderOne[fieldId];
        const valueTwo = holderTwo[fieldId];

        // Return false if the types are not equal
        if (typeof valueOne !== typeof valueTwo) {
            return false;
        }

        if (Array.isArray(valueOne) !== Array.isArray(valueTwo)) {
            return false;
        }



        // Return false if the values are not equal
        if (Array.isArray(valueOne) && Array.isArray(valueTwo)) {
            if (JSON.stringify(valueOne.sort()) !== JSON.stringify(valueTwo.sort())) {
                return false;
            }
        }

        if (valueOne !== valueTwo) {
            return false;
        }
    }

    return true;
}

export function areWorkflowCustomFieldValuesEqual(holderOne: WorkflowCustomFieldDataHolder, holderTwo: WorkflowCustomFieldDataHolder) {
    // Return false if they do not have the same custom field IDs
    if (JSON.stringify(Object.keys(holderOne).sort()) !== JSON.stringify(Object.keys(holderTwo).sort())) {
        return false;
    }

    const otherCustomFieldHolderOne: CustomFieldDataHolder = {};
    const otherCustomFieldHolderTwo: CustomFieldDataHolder = {};

    for (const fieldId in holderOne) {
        const valueOne = holderOne[fieldId];
        const valueTwo = holderTwo[fieldId];

        const isOneMembersDataHolder = typeof valueOne === 'object' && !Array.isArray(valueOne);
        const isTwoMembersDataHolder = typeof valueTwo === 'object' && !Array.isArray(valueTwo);

        // Return false if only one of them is a member data holder
        if (isOneMembersDataHolder !== isTwoMembersDataHolder) {
            return false;
        }

        if (isOneMembersDataHolder && isTwoMembersDataHolder) {
            // Compare members data
            const isMemberDataSame = areCustomFieldValuesEqual(valueOne as CustomFieldDataForIndividualMembers, valueTwo as CustomFieldDataForIndividualMembers);

            if (!isMemberDataSame) {
                return false;
            }
        } else {
            // The field is not a member data holder, and can be computed normally
            otherCustomFieldHolderOne[fieldId] = valueOne as CustomFieldValueType;
            otherCustomFieldHolderTwo[fieldId] = valueTwo as CustomFieldValueType;
        }
    }

    return areCustomFieldValuesEqual(otherCustomFieldHolderOne, otherCustomFieldHolderTwo);
}

export function updateCustomFields<T extends CustomFieldState>(state: T, fields: Array<CustomField>): T {
    const newState = {
        ...state,
        customFields: {
            ...state.customFields,
            byId: {},
            allFields: [],
        }
    };

    for (const customField of fields) {
        if (!(customField.id in newState.customFields.byId)) {
            newState.customFields.allFields.push(customField.id);
        }
        newState.customFields.byId[customField.id] = customField;
    }

    return newState;
}

export function updateCustomFieldOptions<T extends CustomFieldState>(state: T, options: Array<FieldChoice>): T {
    const newState = {
        ...state,
        customFieldOptions: {
            ...state.customFieldOptions,
            byId: {},
            allOptions: [],
        }
    };

    for (const option of options) {
        if (!(option.id in newState.customFieldOptions.byId)) {
            newState.customFieldOptions.allOptions.push(option.id);
        }
        newState.customFieldOptions.byId[option.id] = option;
    }

    return newState;
}

export function synchronizeCustomFields<T extends CustomFieldState>(state: T, fields: Array<CustomField>): T {
    const newState = {
        ...state,
        customFields: {
            ...state.customFields,
            byId: {
                ...state.customFields.byId,
            },
            allFields: state.customFields.allFields.slice(),
        },
    };

    for (const customField of fields) {
        newState.customFields.byId[customField.id] = customField;
        newState.customFields.allFields.push(customField.id);
    }

    return newState;
}

export function synchronizeCustomFieldOptions<T extends CustomFieldState>(state: T, options: Array<FieldChoice>): T {
    const newState = {
        ...state,
        customFieldOptions: {
            ...state.customFieldOptions,
            byId: {
                ...state.customFieldOptions.byId,
            },
            allOptions: state.customFieldOptions.allOptions.slice(),
        },
    };

    for (const option of options) {
        newState.customFieldOptions.byId[option.id] = option;
        newState.customFieldOptions.allOptions.push(option.id);
    }

    return newState;
}

export function clearDeltaForCustomFields<T extends CustomFieldState>(state: T): T {
    return {
        ...state,
        createdCustomFieldIds: new Set(),
        updatedCustomFieldIds: new Set(),
        deletedCustomFieldIds: new Set(),
        createdCustomFieldOptionIds: new Set(),
        updatedCustomFieldOptionIds: new Set(),
        deletedCustomFieldOptionIds: new Set(),

        reOrderedCustomFieldOptions: {},
    }
}