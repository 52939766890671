import { ApplicationState } from '../../store/types';
import { FieldType, WorkflowTypeCustomFieldState, CustomFieldState } from '../../store/custom-fields/types';
import { PermissionSet } from '../../store/permissions/types';
import { AllPieceTypes, PieceState } from '../../store/flowchart/pieces/types';
import store from '../../store/main';
import { IVariable, VariableType } from '../../store/flowchart/variables/types';
import { importWorkflowType, mapConfigIDs, importMemberType, importGroupType, importReportType } from './import';
import { NormalizedModel } from '../../store/normalized-model';
import { PiecePositionState } from '../common-types';

interface ExportedCustomFieldOption {
    id: string;
    name: string;
}

interface ExportedCustomField {
    id: string;
    name: string;
    type: FieldType;
    isComputed: boolean;
    isInTable: boolean;
    isEditable: boolean;
    isDeletable: boolean;
    options: Array<ExportedCustomFieldOption>;

    seedEntityVariable: string,
    startPiece?: PiecePositionState;
    variables: Array<string>,
}

interface ExportedRole {
    id: string;
    name: string;
    customFields: Array<ExportedCustomField>;
}

interface ExportedLevel {
    id: string;
    name: string;
    customFields: Array<ExportedCustomField>;
    roles: Array<ExportedRole>;
}

interface ExportedAction {
    id: string;
    name: string;
    icon: string;
    workflowType?: string;
}

export interface ExportedMemberType {
    id: string;
    name: string;
    customFields: Array<ExportedCustomField>;
    nameFieldId: string;
    subTitleFieldId: string;
    locationFieldId: string;

    actions: Array<ExportedAction>;
}

export interface ExportedGroupType {
    id: string;
    name: string;
    level: string;
    customFields: Array<ExportedCustomField>;
    isRequired: boolean;
    uniqueFieldId: string;
    nameFieldId: string;
    subTitleFieldId: string;

    actions: Array<ExportedAction>;
}

interface ExportedWorkflowTypeCustomField extends ExportedCustomField {
    affiliation: 'member' | 'group';
    seedAffiliationVariable: string | undefined;
}

interface ExportedStatus {
    id: string;
    name: string;
    isTerminal: boolean;
    dueInDays?: number;
}

export interface ExportedStaticData {
    id: string;
    name: string;
    data: Array<Array<string>>;
}

export interface ExportedWorkflowType {
    id: string;
    name: string;
    affiliatedEntity: string;
    affiliation: 'member' | 'group' | 'none';
    isCore: boolean;
    areMultipleInstancesAllowed: boolean;
    isManaged?: boolean;
    startPiece: PiecePositionState;
    variables: Array<string>;

    seedEntityVariable: string;
    seedAffiliationVariable: string;

    levels: Array<ExportedLevel>;

    statuses: Array<ExportedStatus>;
    customFields: Array<ExportedWorkflowTypeCustomField>;
}

export interface ExportedReportType {
    id: string;
    name: string;
    startPiece?: PiecePositionState;

    variables: Array<string>;

    seedUserVariable: string;
    seedUsersVariable: string;
    seedMembersVariable: string;
    seedGroupsVariable: string;
    seedWorkflowsVariable: string;

    seedStartDateVariable: string;
    seedEndDateVariable: string;
}

export interface ExportedVariable {
    id: string;
    name: string;
    type: VariableType,
}

export interface ExportedConfiguration {
    levels: Array<ExportedLevel>;
    permissions: {
        [roleId: string]: PermissionSet;
    };
    memberTypes: Array<ExportedMemberType>;
    groupTypes: Array<ExportedGroupType>;
    workflowTypes: Array<ExportedWorkflowType>;
    reportTypes: Array<ExportedReportType>;

    variables: Array<ExportedVariable>;
    pieceState: PieceState;
}

export interface ExportWorkflowTypeData {
    workflowType: ExportedWorkflowType,
    staticData?: Array<ExportedStaticData>,
    pieceState: NormalizedModel<AllPieceTypes>,
    variableState: NormalizedModel<IVariable>,
    affiliation: ExportedMemberType | ExportedGroupType | undefined,
}

export interface ExportReportTypeData {
    reportType: ExportedReportType,
    pieceState: NormalizedModel<AllPieceTypes>,
    variableState: NormalizedModel<IVariable>,
}

export interface DuplicationIDMappng {
    [oldId: string]: string,
};

export interface StateIDMapping {
    projectIds: DuplicationIDMappng,
    levelIds: DuplicationIDMappng,
    levelCustomFieldIds: DuplicationIDMappng,
    levelCustomFieldOptionIds: DuplicationIDMappng,
    roleIds: DuplicationIDMappng,
    roleCustomFieldIds: DuplicationIDMappng,
    roleCustomFieldOptionIds: DuplicationIDMappng,

    variableIds: DuplicationIDMappng,

    memberTypeIds: DuplicationIDMappng,
    memberTypeCustomFieldIds: DuplicationIDMappng,
    memberTypeCustomFieldOptionIds: DuplicationIDMappng,

    groupTypeIds: DuplicationIDMappng,
    groupTypeCustomFieldIds: DuplicationIDMappng,
    groupTypeCustomFieldOptionIds: DuplicationIDMappng,

    workflowTypeIds: DuplicationIDMappng,
    workflowStatusIds: DuplicationIDMappng,
    workflowTypeCustomFieldIds: DuplicationIDMappng,
    workflowTypeCustomFieldOptionIds: DuplicationIDMappng,

    reportTypeIds: DuplicationIDMappng,
}

export interface ReportImportIdMap {
    variableIds: DuplicationIDMappng,
}

export interface WorkflowImportIdMap {
    variableIds: DuplicationIDMappng,

    affiliationTypeIds: DuplicationIDMappng,
    affiliationTypeCustomFieldIds: DuplicationIDMappng,
    affiliationTypeCustomFieldOptionIds: DuplicationIDMappng,
    affiliationTypeActionIds: DuplicationIDMappng,

    workflowTypeIds: DuplicationIDMappng,
    workflowStatusIds: DuplicationIDMappng,
    workflowTypeCustomFieldIds: DuplicationIDMappng,
    workflowTypeCustomFieldOptionIds: DuplicationIDMappng,
}

export function exportCustomFields(customFieldState: CustomFieldState, customFieldIds: Array<string>): Array<ExportedCustomField> {
    return customFieldIds.map(customFieldId => {
        const customField = customFieldState.customFields.byId[customFieldId];

        return {
            id: customField.id,
            name: customField.name,

            type: customField.type,
            isComputed: customField.isComputed,
            isInTable: customField.isInTable,
            isEditable: customField.isEditable,
            isDeletable: customField.isDeletable,

            seedEntityVariable: customField.seedEntityVariable,

            startPiece: customField.startPiece ? {
                piece: customField.startPiece.piece,
                position: customField.startPiece.position,
            } : undefined,
            variables: customField.variables || [],

            options: customField.choices.map(choiceId => {
                const choice = customFieldState.customFieldOptions.byId[choiceId];

                return {
                    id: choice.id,
                    name: choice.name,
                };
            }),
        }
    });
}

export function exportWorkflowTypeCustomFields(customFieldState: WorkflowTypeCustomFieldState, customFieldIds: Array<string>): Array<ExportedWorkflowTypeCustomField> {
    return customFieldIds.map(customFieldId => {
        const customField = customFieldState.customFields.byId[customFieldId];

        return {
            id: customField.id,
            name: customField.name,

            type: customField.type,
            isComputed: customField.isComputed,
            isInTable: customField.isInTable,
            isEditable: customField.isEditable,
            isDeletable: customField.isDeletable,
            affiliation: customField.affiliation,

            seedEntityVariable: customField.seedEntityVariable,
            seedAffiliationVariable: customField.seedAffiliationVariable,

            startPiece: customField.startPiece ? {
                piece: customField.startPiece.piece,
                position: customField.startPiece.position,
            } : undefined,
            variables: customField.variables || [],

            options: customField.choices.map(choiceId => {
                const choice = customFieldState.customFieldOptions.byId[choiceId];

                return {
                    id: choice.id,
                    name: choice.name,
                };
            }),
        }
    });
}

export function duplicateStaticDataHolder(staticDataHolderId: string) {

}

export function duplicateMemberType(memberTypeId: string) {
    const applicationState: ApplicationState = store.getState();
    const memberType = applicationState.members.types.byId[memberTypeId];
    const configuration = getConfigurationToExport(memberType.project);
    const duplicationMap = mapConfigIDs(configuration);

    const exportedMemberType = configuration.memberTypes.find(memberType => memberType.id === memberTypeId);

    if (!exportedMemberType) {
        throw new Error('Could not find member type');
    }

    importMemberType(exportedMemberType, configuration.pieceState, duplicationMap, memberType.project, configuration.variables, true);
}

export function duplicateGroupType(groupTypeId: string) {
    const applicationState: ApplicationState = store.getState();
    const groupType = applicationState.groups.types.byId[groupTypeId];
    const configuration = getConfigurationToExport(groupType.project);
    const duplicationMap = mapConfigIDs(configuration);

    const exportedGroupType = configuration.groupTypes.find(groupType => groupType.id === groupTypeId);

    if (!exportedGroupType) {
        throw new Error('Could not find group type');
    }

    importGroupType(exportedGroupType, configuration.pieceState, duplicationMap, groupType.project, configuration.variables, true);
}

export interface WorkflowTypeDuplicateOverride {
    name: string;
    project: string;
    affiliatedEntity: string;
    isCore: boolean;
    areMultipleInstancesAllowed: boolean;
}

export function duplicateWorkflowType(workflowTypeId: string, override?: WorkflowTypeDuplicateOverride) {
    const applicationState: ApplicationState = store.getState();
    const workflowType = applicationState.workflows.types.byId[workflowTypeId];
    const configuration = getConfigurationToExport(workflowType.project);
    const duplicationMap = mapConfigIDs(configuration);

    let exportedWorkflowType: ExportedWorkflowType | undefined = configuration.workflowTypes.find(workflowType => workflowType.id === workflowTypeId);

    if (!exportedWorkflowType) {
        throw new Error('Could not find workflow type');
    }

    if (override) {
        exportedWorkflowType = {
            ...exportedWorkflowType,
            name: override.name,
            affiliatedEntity: override.affiliatedEntity,
            isCore: override.isCore,
            areMultipleInstancesAllowed: override.areMultipleInstancesAllowed,
        };
    }

    const isCopiedInSameProject = override && override.project !== workflowType.project ? false : true;

    importWorkflowType(exportedWorkflowType, configuration.pieceState, duplicationMap, override ? override.project : workflowType.project, configuration.variables, isCopiedInSameProject);
}

export function duplicateReportType(reportTypeId: string) {
    const applicationState: ApplicationState = store.getState();
    const reportType = applicationState.reports.types.byId[reportTypeId];
    const configuration = getConfigurationToExport(reportType.project);
    const duplicationMap = mapConfigIDs(configuration);

    const exportedReportType = configuration.reportTypes.find(reportType => reportType.id === reportTypeId);

    if (!exportedReportType) {
        throw new Error('Could not find report type');
    }

    importReportType(exportedReportType, configuration.pieceState, duplicationMap, reportType.project, configuration.variables, true);
}

export function getConfigurationToExport(projectId: string) {
    const applicationState: ApplicationState = store.getState();
    const exportedConfig: ExportedConfiguration = {
        levels: [],
        permissions: {},
        memberTypes: [],
        groupTypes: [],
        workflowTypes: [],
        reportTypes: [],
        variables: [],
        pieceState: applicationState.flowchart.pieces,
    };

    exportedConfig.levels = applicationState.structure.projects.byId[projectId].children.map(levelId => {
        const level = applicationState.structure.levels.byId[levelId];

        return {
            id: level.id,
            name: level.name,
            customFields: exportCustomFields(applicationState.structure.levels, level.customFields),

            roles: level.children.map(roleId => {
                const role = applicationState.structure.roles.byId[roleId];

                return {
                    id: role.id,
                    name: role.name,
                    customFields: exportCustomFields(applicationState.structure.roles, role.customFields),
                };
            }),
        };
    });

    exportedConfig.permissions = applicationState.permissions.rolePermissions;

    exportedConfig.memberTypes = applicationState.members.types.allEntries
        .filter(memberTypeId => {
            const memberType = applicationState.members.types.byId[memberTypeId];

            return memberType.project === projectId;
        })
        .map(memberTypeId => {
            const memberType = applicationState.members.types.byId[memberTypeId];

            const exportedActions = memberType.actions.map(actionId => {
                const action = applicationState.members.types.actions.byId[actionId];

                return {
                    id: action.id,
                    name: action.name,
                    icon: action.icon,
                    workflowType: action.workflowType,
                };
            });

            return {
                id: memberType.id,
                name: memberType.name,
                nameFieldId: memberType.nameFieldId,
                subTitleFieldId: memberType.subTitleFieldId,
                locationFieldId: memberType.locationFieldId,
                customFields: exportCustomFields(applicationState.members.types, memberType.customFields),
                actions: exportedActions,
            };
        });

    exportedConfig.groupTypes = applicationState.groups.types.allEntries
        .filter(groupTypeId => {
            const groupType = applicationState.groups.types.byId[groupTypeId];

            return groupType.project === projectId;
        })
        .map(groupTypeId => {
            const groupType = applicationState.groups.types.byId[groupTypeId];

            const exportedActions = groupType.actions.map(actionId => {
                const action = applicationState.groups.types.actions.byId[actionId];

                return {
                    id: action.id,
                    name: action.name,
                    icon: action.icon,
                    workflowType: action.workflowType,
                };
            });

            return {
                id: groupType.id,
                name: groupType.name,
                level: groupType.level,
                isRequired: groupType.isRequired,
                uniqueFieldId: groupType.uniqueFieldId,
                nameFieldId: groupType.nameFieldId,
                subTitleFieldId: groupType.subTitleFieldId,
                customFields: exportCustomFields(applicationState.groups.types, groupType.customFields),
                actions: exportedActions,
            };
        });

    exportedConfig.workflowTypes = applicationState.workflows.types.allEntries
        .filter(workflowTypeId => {
            const workflowType = applicationState.workflows.types.byId[workflowTypeId];

            return workflowType.project === projectId;
        })
        .map(workflowTypeId => {
            const workflowType = applicationState.workflows.types.byId[workflowTypeId];
            const project = applicationState.structure.projects.byId[workflowType.project];

            return {
                id: workflowType.id,
                name: workflowType.name,
                affiliation: workflowType.affiliation,
                affiliatedEntity: workflowType.affiliatedEntity,
                isCore: workflowType.isCore,

                seedEntityVariable: workflowType.seedEntityVariable,
                seedAffiliationVariable: workflowType.seedAffiliationVariable,
                areMultipleInstancesAllowed: workflowType.areMultipleInstancesAllowed,

                startPiece: workflowType.startPiece,
                variables: workflowType.variables,

                levels: project.children.map(levelId => {
                    const level = applicationState.structure.levels.byId[levelId];

                    return {
                        id: levelId,
                        name: level.name,
                        customFields: exportCustomFields(applicationState.structure.levels, level.customFields),
                        roles: level.children.map(roleId => {
                            const role = applicationState.structure.roles.byId[roleId];

                            return {
                                id: roleId,
                                name: role.name,
                                customFields: exportCustomFields(applicationState.structure.roles, role.customFields),
                            };
                        }),
                    };
                }),

                statuses: workflowType.statuses.map(statusId => {
                    const status = applicationState.workflows.types.statuses.byId[statusId];

                    return {
                        id: status.id,
                        name: status.name,
                        isTerminal: status.isTerminal,
                        dueInDays: status.dueInDays,
                    }
                }),
                customFields: exportWorkflowTypeCustomFields(applicationState.workflows.types, workflowType.customFields),
            };
        });

    exportedConfig.reportTypes = applicationState.reports.types.allEntries
        .filter(reportTypeId => {
            const reportType = applicationState.reports.types.byId[reportTypeId];

            return reportType.project === projectId;
        })
        .map(reportTypeId => {
            const reportType = applicationState.reports.types.byId[reportTypeId];

            return {
                id: reportType.id,
                name: reportType.name,
                startPiece: reportType.startPiece,

                variables: reportType.variables,

                seedUserVariable: reportType.seedUserVariable,
                seedUsersVariable: reportType.seedUsersVariable,
                seedMembersVariable: reportType.seedMembersVariable,
                seedGroupsVariable: reportType.seedGroupsVariable,
                seedWorkflowsVariable: reportType.seedWorkflowsVariable,

                seedStartDateVariable: reportType.seedStartDateVariable,
                seedEndDateVariable: reportType.seedEndDateVariable,
            };
        });

    exportedConfig.variables = applicationState.flowchart.variables.allEntries.map(variableId => {
        const variable = applicationState.flowchart.variables.byId[variableId];
        return {
            id: variable.id,
            name: variable.name,
            type: variable.type,
        };
    });

    return exportedConfig;
}