import ReactJson from 'react-json-view'
import { FC, useEffect, useState } from "react";
import { translatePhrase } from "../../shared/helpers/translation";
import styles from './APIDocs.module.scss';

import { ReactComponent as ClipboardIcon } from '../../assets/new-custom-icons/common/clipboard.svg';
import Table from "../../widgets/table/Table";
import PageHeader from '../page-header/PageHeader';
import Button from '../../widgets/button/CommonButton';
import { BASE_URL } from '../../shared/store/url';
import LoaderModal from '../../widgets/loader/LoaderModal';

interface OwnProps {

}

const REPORT_TYPES_API_JSON_DATA = [
    {
        "id": "d8069a39-60a0-477c-95ca-a529de6664cd",
        "name": "Test report type"
    }
];

const REPORT_API_JSON_DATA = [
    {
        "id": "864b179f-3bce-41da-84d6-b7a04e6d6a47",
        "name": "Test 1",
        "startDate": "2021-11-27",
        "endDate": "2021-12-27",
        "createdTime": "2021-12-27T09:44:13Z",
        "lastUpdatedTime": "2021-12-27T09:44:33Z",
        "type": "Test report type"
    }
];

const WORKFLOW_API_REQUEST_JSON_DATA = {
    "filter": {
        "workflowTypes": ['KYM Version 2'],
        "createdDate": {
            "start": "2022-11-29",
            "end": "2022-11-30"
        },
        "lastWorkedOnDate": {
            "start": "2022-11-29",
            "end": "2022-11-30"
        },
    },
    "pageSize": 20,
    "pageNumber": 1
}

const WORKFLOW_API_RESPONSE_JSON_DATA = [
    {
        "id": "033469f1-57a0-4b89-9e31-5c9dda0dc2d5",
        "type": {
            "id": "c2ad29dd-b447-451e-b923-a17753416a86",
            "name": "KYM Version 2"
        },
        "subtitle": "Rajeshwari Wo Mohan raj - +91 7259120512",
        "status": {
            "id": "5caf48a9-6bb1-4685-b6d8-9a631e0fd524",
            "name": "Not interested"
        },
        "dueDate": "1981-07-10",
        "lastWorkedOnDate": "2023-08-03",
        "user": {
            "id": "6d68b9b3-674d-4989-a5bf-9718680a62d2",
            "name": "T Lakshmi",
            "phoneNo": "+919739688039"
        },
        "affiliation": {
            "id": "209ac135-6fc6-4bb4-8567-f08c20244fdf",
            "affiliationType": "group"
        },
        "customFields": [
            {
                "fieldId": "28863cfd-fc97-48b1-aab8-f226d16aab73",
                "name": "CO Name with full address",
                "value": "SMS Zone 7",
                "affiliation": "group",
                "affiliationId": "209ac135-6fc6-4bb4-8567-f08c20244fdf"
            },
        ],
        "createdDate": "2023-08-03",
        "lastUpdatedDate": "2023-08-09",
        "isArchived": true,
        "previousOwners": [
            "6d68b9b3-674d-4989-a5bf-9718680a62d2"
        ]
    }
]

const MEMBERS_API_REQUEST_JSON_DATA = {
    "filter": {
        "memberTypes": ["Family Member (Urban)"],
        "createdDate": {
            "start": "2022-11-20",
            "end": "2022-12-30"
        },
        "lastUpdatedDate": {
            "start": "2022-11-20",
            "end": "2022-12-30"
        }
    },
    "pageSize": 25,
    "pageNumber": 1
};

const MEMBERS_API_RESPONSE_JSON_DATA = [
    {
        "id": "2153f1a8-d57d-41a9-985e-903eff651381",
        "name": "Komala M Wo Mohan",
        "subtitle": "Baby G Wo Gopi Rao - KAZMALM00134/SHG Swathi Sampige AG 1",
        "lastSeen": "-",
        "type": {
            "id": "bdaf334d-bd01-4e01-8840-ff87706ff7d2",
            "name": "Family Member (MC)"
        },
        "locations": {
            "Country": "India",
            "State": "Karnataka",
            "District": "Bangalore",
            "Zone/Block": "Zone 3 - Rajajinagar",
            "Area": "Malleshwaram"
        },
        "customFields": [
            {
                "fieldId": "265bb172-5557-4260-95c4-eca204c8c48b",
                "name": "Date of Enrollment",
                "value": "03 Apr 2018",
                "affiliation": "member",
                "affiliationId": "2153f1a8-d57d-41a9-985e-903eff651381"
            },
            {
                "fieldId": "35bb05fb-f989-4e4f-abfc-573ac0c92f97",
                "name": "Gender",
                "value": "Female",
                "affiliation": "member",
                "affiliationId": "2153f1a8-d57d-41a9-985e-903eff651381"
            }
        ],
        "groups": [
            {
                "id": "7256f291-b610-4837-9efc-3af7061f3ff4",
                "name": "Swathi Sampige AG 1",
                "type": {
                    "id": "5429f456-cd90-49bd-82cc-d3e66877811d",
                    "name": "SHG (MC)"
                }
            }
        ],
        "createdDate": "2023-01-09",
        "lastUpdatedDate": "2023-12-12",
        "isArchived": false
    }
];

const GROUPS_API_REQUEST_JSON_DATA = {
    "filter": {
        "groupTypes": ["SHG"],
        "createdDate": {
            "start": "2022-11-20",
            "end": "2022-12-30"
        },
        "lastUpdatedDate": {
            "start": "2022-11-20",
            "end": "2022-12-30"
        }
    },
    "pageSize": 25,
    "pageNumber": 1
};

const GROUPS_API_RESPONSE_JSON_DATA = [
    {
        "id": "e21e7986-0b1f-493e-9139-970bde491763",
        "name": "Uma H (Laggere) Group",
        "subtitle": "Female Group  / 1 members",
        "type": {
            "id": "50fcd635-5e64-4fdd-aa58-7295a1809fa3",
            "name": "SHG"
        },
        "locations": {
            "Country": "India",
            "State": "Karnataka",
            "District": "Bangalore Central",
            "Zone": "Rajajinagar",
            "Ward": "Shakti Ganapathi Nagara",
            "WF": "Uma H"
        },
        "customFields": [
            {
                "fieldId": "e6925a7f-00a7-4a4f-98e1-4c3c33b56001",
                "name": "Scheduled Meeting Date",
                "value": "11 Jul 2024",
                "affiliation": "group",
                "affiliationId": "e21e7986-0b1f-493e-9139-970bde491763"
            },
            {
                "fieldId": "56cb0e75-3c72-4d72-bc21-42a6c597af82",
                "name": "Scheduled Meeting Time Slot",
                "value": "5pm to 8pm",
                "affiliation": "group",
                "affiliationId": "e21e7986-0b1f-493e-9139-970bde491763"
            }
        ],
        "members": [
            {
                "id": "3c04e34e-6a25-485a-997c-221e891f0cd3",
                "name": "Tabasum wo Yaseen",
                "type": {
                    "id": "ec95a12a-1591-4d7b-aec2-588a5d43e198",
                    "name": "Family Member (Urban)"
                }
            }
        ],
        "createdDate": "2023-06-26",
        "lastUpdatedDate": "2024-06-11",
        "isArchived": false
    }
];

const APIDocs: FC<OwnProps> = (props) => {
    const [token, setToken] = useState('');

    const [loaderModal, setLoaderModal] = useState<JSX.Element | undefined>(undefined);

    useEffect(() => {
        const token = window.localStorage.getItem('token');

        if (token) {
            setToken(token);
        }
    }, []);

    const copyObjectToClipboard = (dataToCopy: Object) => {
        navigator.clipboard.writeText(JSON.stringify(dataToCopy, null, 2));

        setLoaderModal(<LoaderModal isSuccess loaderText={["Copied to Clipboard"]} />)
        setTimeout(() => setLoaderModal(undefined), 3000);
    }

    return <section className={styles.FocusSpace}>
        {loaderModal}
        <header className={styles.pageHeader}>
            <h2 className={styles.heading}> <ClipboardIcon />  {translatePhrase('API Docs')} </h2>
            <PageHeader />
        </header>

        <section className={styles.innerFocus}>
            <section>
                <section className={styles.tokenContainer}>
                    <div className={styles.tokenText}>Token: {token}</div>
                    <Button
                        icon={<ClipboardIcon />}
                        type='primary'
                        padding={'0 10px'}
                        isRounded
                        size='small'
                        text={'Copy Token to clipboard'}
                        onClick={() => copyObjectToClipboard(token)}
                    />
                </section>
                <div className={styles.apiCallContainer}>
                    <h3 className={styles.apiHeading}>Authentication</h3>

                    <h4 className={styles.apiSubHeading}>URL</h4>
                    <section className={styles.url}>{BASE_URL}/api-auth/</section>

                    <div className={styles.apiHeader}>
                        <h4 className={styles.apiSubHeading}>Request body</h4>
                        <Button
                            icon={<ClipboardIcon />}
                            type='primary'
                            padding={'0 10px'}
                            isRounded
                            size='small'
                            text={translatePhrase("Copy Object to clipboard")}
                            onClick={() => copyObjectToClipboard({
                                username: '+91 9845611111',
                                password: 'p4$sw0rd',
                                org_code: '14WE'
                            })}
                        />
                    </div>
                    <section className={styles.dataHolder}>
                        <ReactJson
                            src={{
                                username: '+91 9845611111',
                                password: 'p4$sw0rd',
                                org_code: '14WE'
                            }}
                            theme="ashes"
                            style={{
                                padding: 5,
                                fontSize: 14,
                            }}
                        />
                    </section>

                    <div className={styles.apiHeader}>
                        <h4 className={styles.apiSubHeading}>Response body</h4>
                        <Button
                            icon={<ClipboardIcon />}
                            type='primary'
                            padding={'0 10px'}
                            isRounded
                            size='small'
                            text={translatePhrase("Copy Object to clipboard")}
                            onClick={() => copyObjectToClipboard({
                                id: '<user-id>',
                                code: 'ORG-CODE',
                                token: '<token-to-use-in-requests>',
                            })}
                        />
                    </div>
                    <section className={styles.dataHolder}>
                        <ReactJson
                            src={{
                                id: '<user-id>',
                                code: 'ORG-CODE',
                                token: '<token-to-use-in-requests>',
                            }}
                            theme="ashes"
                            style={{
                                padding: 5,
                                fontSize: 14,
                            }}
                        />
                    </section>
                </div>

                <div className={styles.apiCallContainer}>
                    <h3 className={styles.apiHeading}>Workflows</h3>

                    <h4 className={styles.apiSubHeading}>URL</h4>
                    <section className={styles.url}>{BASE_URL}/analytics/workflows</section>

                    <h4 className={styles.apiSubHeading}>Headers</h4>
                    <section className={styles.tableHolder}>
                        <Table
                            headings={[{
                                name: 'Sl no.',
                                width: 100,
                                isSortable: false,
                            }, {
                                name: 'Name',
                                width: 100,
                                isSortable: false,
                            }, {
                                name: 'Description',
                                width: 100,
                                isSortable: false,
                            }, {
                                name: 'E.g.',
                                width: 200,
                                isSortable: false,
                            }]}
                            entries={[
                                {
                                    id: '0',
                                    entries: [
                                        1,
                                        'Token',
                                        'The token to pass which identifies the user',
                                        'Bearer eyJhbGciOiJIUzI1...'
                                    ]
                                }
                            ]}
                            isReadOnly
                            areActionsHidden
                        />
                    </section>


                    <h4 className={styles.apiSubHeading}>Parameters</h4>
                    <section className={styles.tableHolder}>
                        <Table
                            headings={[{
                                name: 'Sl no.',
                                width: 100,
                                isSortable: false,
                            }, {
                                name: 'Name',
                                width: 100,
                                isSortable: false,
                            }, {
                                name: 'Description',
                                width: 100,
                                isSortable: false,
                            }, {
                                name: 'E.g.',
                                width: 200,
                                isSortable: false,
                            }]}
                            entries={[
                                {
                                    id: '1',
                                    entries: [
                                        1,
                                        'Page Size',
                                        'Number of items per page in the response.',
                                        '20',
                                    ]
                                },
                                {
                                    id: '2',
                                    entries: [
                                        2,
                                        'Page Number',
                                        'Page number for pagination.',
                                        '1',
                                    ]
                                },
                                {
                                    id: '3',
                                    entries: [
                                        3,
                                        'Workflow Types',
                                        'An array containing the names of Workflow types',
                                        '["Enroll Family", "Apply for Scheme"]',
                                    ]
                                },
                                {
                                    id: '4',
                                    entries: [
                                        4,
                                        'Created Start Date',
                                        'A date string filtering workflows created on or after the given date.',
                                        '2022-10-11 (YYYY-MM-DD)',
                                    ]
                                },
                                {
                                    id: '5',
                                    entries: [
                                        5,
                                        'Created End Date',
                                        'A date string filtering workflows created on or before the given date.',
                                        '2023-04-20 (YYYY-MM-DD)',
                                    ]
                                },
                                {
                                    id: '6',
                                    entries: [
                                        6,
                                        'Last Worked On Start Date',
                                        'A date string filtering workflows last worked on or after the given date.',
                                        '2022-10-11 (YYYY-MM-DD)',
                                    ]
                                },
                                {
                                    id: '7',
                                    entries: [
                                        7,
                                        'Last Worked On End Date',
                                        'A date string filtering workflows last worked on or before the given date.',
                                        '2023-04-20 (YYYY-MM-DD)',
                                    ]
                                }
                            ]}
                            isReadOnly
                            areActionsHidden
                        />
                    </section>

                    <h4 className={styles.apiSubHeading}>URL</h4>
                    <section className={styles.url}>{BASE_URL}/analytics/workflows</section>

                    <div className={styles.apiHeader}>
                        <h4 className={styles.apiSubHeading}>POST Request body</h4>
                        <Button
                            icon={<ClipboardIcon />}
                            type='primary'
                            padding={'0 10px'}
                            isRounded
                            size='small'
                            text={translatePhrase("Copy Object to clipboard")}
                            onClick={() => copyObjectToClipboard(WORKFLOW_API_REQUEST_JSON_DATA)}
                        />
                    </div>

                    <section className={styles.dataHolder}>
                        <ReactJson
                            src={WORKFLOW_API_REQUEST_JSON_DATA}
                            theme="ashes"
                            style={{
                                padding: 5,
                                fontSize: 14,
                            }}
                            collapsed
                            onSelect={e => console.log(e)}
                            displayDataTypes={false}
                            displayObjectSize={false}
                        />
                    </section>
                    <div className={styles.apiHeader}>
                        <h4 className={styles.apiSubHeading}>Response body</h4>
                        <Button
                            icon={<ClipboardIcon />}
                            type='primary'
                            padding={'0 10px'}
                            isRounded
                            size='small'
                            text={translatePhrase("Copy Object to clipboard")}
                            onClick={() => copyObjectToClipboard(WORKFLOW_API_RESPONSE_JSON_DATA)}
                        />
                    </div>
                    <section className={styles.dataHolder}>
                        <ReactJson
                            src={WORKFLOW_API_RESPONSE_JSON_DATA}
                            theme="ashes"
                            style={{
                                padding: 5,
                                fontSize: 14,
                            }}
                            collapsed
                        />
                    </section>
                </div>

                <div className={styles.apiCallContainer}>
                    <h3 className={styles.apiHeading}>Groups</h3>

                    <h4 className={styles.apiSubHeading}>URL</h4>
                    <section className={styles.url}>{BASE_URL}/analytics/groups</section>

                    <h4 className={styles.apiSubHeading}>Headers</h4>
                    <section className={styles.tableHolder}>
                        <Table
                            headings={[{
                                name: 'Sl no.',
                                width: 100,
                                isSortable: false,
                            }, {
                                name: 'Name',
                                width: 100,
                                isSortable: false,
                            }, {
                                name: 'Description',
                                width: 100,
                                isSortable: false,
                            }, {
                                name: 'E.g.',
                                width: 200,
                                isSortable: false,
                            }]}
                            entries={[
                                {
                                    id: '0',
                                    entries: [
                                        1,
                                        'Token',
                                        'The token to pass which identifies the user',
                                        'Bearer eyJhbGciOiJIUzI1...'
                                    ]
                                }
                            ]}
                            isReadOnly
                            areActionsHidden
                        />
                    </section>

                    <h4 className={styles.apiSubHeading}>Parameters</h4>
                    <section className={styles.tableHolder}>
                        <Table
                            headings={[{
                                name: 'Sl no.',
                                width: 100,
                                isSortable: false,
                            }, {
                                name: 'Name',
                                width: 100,
                                isSortable: false,
                            }, {
                                name: 'Description',
                                width: 100,
                                isSortable: false,
                            }, {
                                name: 'E.g.',
                                width: 200,
                                isSortable: false,
                            }]}
                            entries={[
                                {
                                    id: '1',
                                    entries: [
                                        1,
                                        'Page Size',
                                        'Number of items per page in the response.',
                                        '25',
                                    ]
                                },
                                {
                                    id: '2',
                                    entries: [
                                        2,
                                        'Page Number',
                                        'Page number for pagination.',
                                        '1',
                                    ]
                                },
                                {
                                    id: '3',
                                    entries: [
                                        3,
                                        'Group Types',
                                        'An array containing the names of Group types',
                                        '["Family", "SHG"]',
                                    ]
                                },
                                {
                                    id: '4',
                                    entries: [
                                        4,
                                        'Created Start Date',
                                        'A date string filtering groups created on or after the given date.',
                                        '2022-10-11 (YYYY-MM-DD)',
                                    ]
                                },
                                {
                                    id: '5',
                                    entries: [
                                        5,
                                        'Created End Date',
                                        'A date string filtering groups created on or before the given date.',
                                        '2023-04-20 (YYYY-MM-DD)',
                                    ]
                                },
                                {
                                    id: '6',
                                    entries: [
                                        6,
                                        'Last Updated On Start Date',
                                        'A date string filtering groups last updated on or after the given date.',
                                        '2022-10-11 (YYYY-MM-DD)',
                                    ]
                                },
                                {
                                    id: '7',
                                    entries: [
                                        7,
                                        'Last Updated On End Date',
                                        'A date string filtering groups last updated on or before the given date.',
                                        '2023-04-20 (YYYY-MM-DD)',
                                    ]
                                }
                            ]}
                            isReadOnly
                            areActionsHidden
                        />
                    </section>

                    <h4 className={styles.apiSubHeading}>URL</h4>
                    <section className={styles.url}>{BASE_URL}/analytics/groups</section>

                    <div className={styles.apiHeader}>
                        <h4 className={styles.apiSubHeading}>POST Request body</h4>
                        <Button
                            icon={<ClipboardIcon />}
                            type='primary'
                            padding={'0 10px'}
                            isRounded
                            size='small'
                            text={translatePhrase("Copy Object to clipboard")}
                            onClick={() => copyObjectToClipboard(GROUPS_API_REQUEST_JSON_DATA)}
                        />
                    </div>

                    <section className={styles.dataHolder}>
                        <ReactJson
                            src={GROUPS_API_REQUEST_JSON_DATA}
                            theme="ashes"
                            style={{
                                padding: 5,
                                fontSize: 14,
                            }}
                            collapsed
                            onSelect={e => console.log(e)}
                            displayDataTypes={false}
                            displayObjectSize={false}
                        />
                    </section>
                    <div className={styles.apiHeader}>
                        <h4 className={styles.apiSubHeading}>Response body</h4>
                        <Button
                            icon={<ClipboardIcon />}
                            type='primary'
                            padding={'0 10px'}
                            isRounded
                            size='small'
                            text={translatePhrase("Copy Object to clipboard")}
                            onClick={() => copyObjectToClipboard(GROUPS_API_RESPONSE_JSON_DATA)}
                        />
                    </div>
                    <section className={styles.dataHolder}>
                        <ReactJson
                            src={GROUPS_API_RESPONSE_JSON_DATA}
                            theme="ashes"
                            style={{
                                padding: 5,
                                fontSize: 14,
                            }}
                            collapsed
                        />
                    </section>
                </div>

                <div className={styles.apiCallContainer}>
                    <h3 className={styles.apiHeading}>Members</h3>

                    <h4 className={styles.apiSubHeading}>URL</h4>
                    <section className={styles.url}>{BASE_URL}/analytics/members</section>

                    <h4 className={styles.apiSubHeading}>Headers</h4>
                    <section className={styles.tableHolder}>
                        <Table
                            headings={[{
                                name: 'Sl no.',
                                width: 100,
                                isSortable: false,
                            }, {
                                name: 'Name',
                                width: 100,
                                isSortable: false,
                            }, {
                                name: 'Description',
                                width: 100,
                                isSortable: false,
                            }, {
                                name: 'E.g.',
                                width: 200,
                                isSortable: false,
                            }]}
                            entries={[
                                {
                                    id: '0',
                                    entries: [
                                        1,
                                        'Token',
                                        'The token to pass which identifies the user',
                                        'Bearer eyJhbGciOiJIUzI1...'
                                    ]
                                }
                            ]}
                            isReadOnly
                            areActionsHidden
                        />
                    </section>


                    <h4 className={styles.apiSubHeading}>Parameters</h4>
                    <section className={styles.tableHolder}>
                        <Table
                            headings={[{
                                name: 'Sl no.',
                                width: 100,
                                isSortable: false,
                            }, {
                                name: 'Name',
                                width: 100,
                                isSortable: false,
                            }, {
                                name: 'Description',
                                width: 100,
                                isSortable: false,
                            }, {
                                name: 'E.g.',
                                width: 200,
                                isSortable: false,
                            }]}
                            entries={[
                                {
                                    id: '1',
                                    entries: [
                                        1,
                                        'Page Size',
                                        'Number of items per page in the response.',
                                        '25',
                                    ]
                                },
                                {
                                    id: '2',
                                    entries: [
                                        2,
                                        'Page Number',
                                        'Page number for pagination.',
                                        '1',
                                    ]
                                },
                                {
                                    id: '3',
                                    entries: [
                                        3,
                                        'Member Types',
                                        'An array containing the names of Member types',
                                        '["Family Member (Urban)"]',
                                    ]
                                },
                                {
                                    id: '4',
                                    entries: [
                                        4,
                                        'Created Start Date',
                                        'A date string filtering members created on or after the given date.',
                                        '2022-10-11 (YYYY-MM-DD)',
                                    ]
                                },
                                {
                                    id: '5',
                                    entries: [
                                        5,
                                        'Created End Date',
                                        'A date string filtering members created on or before the given date.',
                                        '2023-04-20 (YYYY-MM-DD)',
                                    ]
                                },
                                {
                                    id: '6',
                                    entries: [
                                        6,
                                        'Last Updated On Start Date',
                                        'A date string filtering members last updated on or after the given date.',
                                        '2022-10-11 (YYYY-MM-DD)',
                                    ]
                                },
                                {
                                    id: '7',
                                    entries: [
                                        7,
                                        'Last Updated On End Date',
                                        'A date string filtering members last updated on or before the given date.',
                                        '2023-04-20 (YYYY-MM-DD)',
                                    ]
                                }
                            ]}
                            isReadOnly
                            areActionsHidden
                        />
                    </section>

                    <h4 className={styles.apiSubHeading}>URL</h4>
                    <section className={styles.url}>{BASE_URL}/analytics/members</section>

                    <div className={styles.apiHeader}>
                        <h4 className={styles.apiSubHeading}>POST Request body</h4>
                        <Button
                            icon={<ClipboardIcon />}
                            type='primary'
                            padding={'0 10px'}
                            isRounded
                            size='small'
                            text={translatePhrase("Copy Object to clipboard")}
                            onClick={() => copyObjectToClipboard(MEMBERS_API_REQUEST_JSON_DATA)}
                        />
                    </div>

                    <section className={styles.dataHolder}>
                        <ReactJson
                            src={MEMBERS_API_REQUEST_JSON_DATA}
                            theme="ashes"
                            style={{
                                padding: 5,
                                fontSize: 14,
                            }}
                            collapsed
                            onSelect={e => console.log(e)}
                            displayDataTypes={false}
                            displayObjectSize={false}
                        />
                    </section>
                    <div className={styles.apiHeader}>
                        <h4 className={styles.apiSubHeading}>Response body</h4>
                        <Button
                            icon={<ClipboardIcon />}
                            type='primary'
                            padding={'0 10px'}
                            isRounded
                            size='small'
                            text={translatePhrase("Copy Object to clipboard")}
                            onClick={() => copyObjectToClipboard(MEMBERS_API_RESPONSE_JSON_DATA)}
                        />
                    </div>
                    <section className={styles.dataHolder}>
                        <ReactJson
                            src={MEMBERS_API_RESPONSE_JSON_DATA}
                            theme="ashes"
                            style={{
                                padding: 5,
                                fontSize: 14,
                            }}
                            collapsed
                        />
                    </section>
                </div>

                <div className={styles.apiCallContainer}>
                    <h3 className={styles.apiHeading}>Report types</h3>

                    <h4 className={styles.apiSubHeading}>URL</h4>
                    <section className={styles.url}>{BASE_URL}/api/reports-types/</section>

                    <h4 className={styles.apiSubHeading}>Headers</h4>
                    <section className={styles.tableHolder}>
                        <Table
                            headings={[{
                                name: 'Sl no.',
                                width: 100,
                                isSortable: false,
                            }, {
                                name: 'Name',
                                width: 100,
                                isSortable: false,
                            }, {
                                name: 'Description',
                                width: 100,
                                isSortable: false,
                            }, {
                                name: 'E.g.',
                                width: 200,
                                isSortable: false,
                            }]}
                            entries={[
                                {
                                    id: '0',
                                    entries: [
                                        1,
                                        'Token',
                                        'The token to pass which identifies the user',
                                        'Bearer eyJhbGciOiJIUzI1...'
                                    ]
                                }
                            ]}
                            isReadOnly
                            areActionsHidden
                        />
                    </section>

                    <div className={styles.apiHeader}>
                        <h4 className={styles.apiSubHeading}>Response body</h4>
                        <Button
                            icon={<ClipboardIcon />}
                            type='primary'
                            padding={'0 10px'}
                            isRounded
                            size='small'
                            text={translatePhrase("Copy Object to clipboard")}
                            onClick={() => copyObjectToClipboard(REPORT_TYPES_API_JSON_DATA)}
                        />
                    </div>

                    <section className={styles.dataHolder}>
                        <ReactJson
                            src={REPORT_TYPES_API_JSON_DATA}
                            theme="ashes"
                            style={{
                                padding: 5,
                                fontSize: 14,
                            }}
                            collapsed
                        />
                    </section>
                </div>


                <div className={styles.apiCallContainer}>
                    <h3 className={styles.apiHeading}>Reports</h3>

                    <h4 className={styles.apiSubHeading}>URL</h4>
                    <section className={styles.url}>{BASE_URL}/api/reports/</section>

                    <h4 className={styles.apiSubHeading}>Headers</h4>
                    <section className={styles.tableHolder}>
                        <Table
                            headings={[{
                                name: 'Sl no.',
                                width: 100,
                                isSortable: false,
                            }, {
                                name: 'Name',
                                width: 100,
                                isSortable: false,
                            }, {
                                name: 'Description',
                                width: 100,
                                isSortable: false,
                            }, {
                                name: 'E.g.',
                                width: 200,
                                isSortable: false,
                            }]}
                            entries={[
                                {
                                    id: '0',
                                    entries: [
                                        1,
                                        'Token',
                                        'The token to pass which identifies the user',
                                        'Bearer eyJhbGciOiJIUzI1...'
                                    ]
                                }
                            ]}
                            isReadOnly
                            areActionsHidden
                        />
                    </section>


                    <h4 className={styles.apiSubHeading}>Parameters</h4>
                    <section className={styles.tableHolder}>
                        <Table
                            headings={[{
                                name: 'Sl no.',
                                width: 100,
                                isSortable: false,
                            }, {
                                name: 'Name',
                                width: 100,
                                isSortable: false,
                            }, {
                                name: 'Description',
                                width: 100,
                                isSortable: false,
                            }, {
                                name: 'E.g.',
                                width: 200,
                                isSortable: false,
                            }]}
                            entries={[
                                {
                                    id: '0',
                                    entries: [
                                        1,
                                        'Limit',
                                        'Number of records to return',
                                        '10',
                                    ]
                                },
                                {
                                    id: '1',
                                    entries: [
                                        1,
                                        'Skip',
                                        'Number of records to skip',
                                        '5',
                                    ]
                                },
                                {
                                    id: '2',
                                    entries: [
                                        2,
                                        'Report type',
                                        'The name of the report type',
                                        'Test Report',
                                    ]
                                },
                                {
                                    id: '3',
                                    entries: [
                                        2,
                                        'Start date lesser than',
                                        'A date string that filters out reports with start date that are larger than the given date',
                                        '2021-11-04',
                                    ]
                                },
                                {
                                    id: '4',
                                    entries: [
                                        2,
                                        'Start date greater than',
                                        'A date string that filters out reports with start date that are smaller than the given date',
                                        '2021-10-26',
                                    ]
                                },
                                {
                                    id: '5',
                                    entries: [
                                        2,
                                        'End date lesser than',
                                        'A date string that filters out reports with end date that are larger than the given date',
                                        '2021-12-04',
                                    ]
                                },
                                {
                                    id: '6',
                                    entries: [
                                        2,
                                        'End date greater than',
                                        'A date string that filters out reports with end date that are smaller than the given date',
                                        '2021-11-26',
                                    ]
                                },
                            ]}
                            isReadOnly
                            areActionsHidden
                        />
                    </section>


                    <h4 className={styles.apiSubHeading}>URL example</h4>
                    <section className={styles.url}>{BASE_URL}/api/reports/?startDateGt=2021-11-26&amp;startDateLt=2021-12-01</section>

                    <div className={styles.apiHeader}>
                        <h4 className={styles.apiSubHeading}>Response body</h4>
                        <Button
                            icon={<ClipboardIcon />}
                            type='primary'
                            padding={'0 10px'}
                            isRounded
                            size='small'
                            text={translatePhrase("Copy Object to clipboard")}
                            onClick={() => copyObjectToClipboard(REPORT_API_JSON_DATA)}
                        />
                    </div>

                    <section className={styles.dataHolder}>
                        <ReactJson
                            src={REPORT_API_JSON_DATA}
                            theme="ashes"
                            style={{
                                padding: 5,
                                fontSize: 14,
                            }}
                            collapsed
                        />
                    </section>
                </div>
            </section>
        </section>

    </section>
}

export default APIDocs;