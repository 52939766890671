import { Component } from 'react';
import styles from './step-piece/StepPiece.module.scss';
import Input from '../Input';
import StepPiece from './step-piece/StepPiece'

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setIterableVariable, setMemberTypeToGroup, setVariablePiece } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import { valuePieceSlotTarget } from './utilities';
import DropDownSearchBox from '../drop-down/DropDownSearchBox';


type SetMembersInGroupPieceProps = {
    nextPiece?: JSX.Element,

    variablePiece?: JSX.Element,
    memberType?: 'representatives'|'all_members',
    iterableVariablePiece?: JSX.Element,
}

interface Option {
    name: string;
    value: "representatives" | "all_members",
}

const OPTION_TYPES: Option[] = [
    { name: "Representatives", value: "representatives" },
    { name: "Other Members", value: "all_members" }
  ];

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string|undefined) => dispatch(setTargetPiece(pieceId)),
        setIterableVariable: (targetPieceId: string, draggedPieceId: string) => dispatch(setIterableVariable(targetPieceId, draggedPieceId)),
        setVariablePiece: (targetPieceId: string, draggedPieceId: string) => dispatch(setVariablePiece(targetPieceId, draggedPieceId)),
        setMemberType: (targetPieceId: string, memberType: 'representatives'|'all_members') => dispatch(setMemberTypeToGroup(targetPieceId, memberType)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = SetMembersInGroupPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type SetMembersInGroupPieceState = {
    isHoveringOverVariablePiece: boolean,
    isHoveringOverIterableVariablePiece: boolean,
    optionSearchTerm: string,
}

class ConnectedSetMembersInGroupPiece extends Component<Props, SetMembersInGroupPieceState> {

    constructor(props: Props) {
        super(props);
        
        this.state = {
            isHoveringOverVariablePiece: false,
            isHoveringOverIterableVariablePiece: false,
            optionSearchTerm: "",
        };
    }

    handleHoverOverVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfVariablePiece = () => {
        this.setState({
            isHoveringOverVariablePiece: false,
        });
    };

    handleHoverOverIterableVariablePiece = () => {
        this.setState({
            isHoveringOverIterableVariablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfIterableVariablePiece = () => {
        this.setState({
            isHoveringOverIterableVariablePiece: false,
        });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && (this.state.isHoveringOverVariablePiece || this.state.isHoveringOverIterableVariablePiece)) {

            if (this.state.isHoveringOverVariablePiece) {
                this.props.setVariablePiece(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverIterableVariablePiece) {
                this.props.setIterableVariable(this.props.pieceId, this.props.lastDraggedPiece.id);
            }

            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverVariablePiece: false,
                isHoveringOverIterableVariablePiece: false,
            });
        }
    }
    searchForOption = (searchTerm: string) => {
        this.setState({ optionSearchTerm: searchTerm });
    }
    render() {
        const filteredOptions = OPTION_TYPES.filter(option => option.name.toLocaleLowerCase().includes(this.state.optionSearchTerm.toLocaleLowerCase()));
        const memberTypeSelectBox = <SelectBox theme="aqua" selectionPromptText={this.props.memberType ? this.props.memberType === 'representatives' ? 'Representatives' : 'Other Members' : 'Select a type'}>
            <DropDownList theme="aqua" dismissAfterSelection={false}>
                <DropDownSearchBox
                    handleSearchInputChange={this.searchForOption}
                    placeholder={"Search by name"}
                    searchTerm={this.state.optionSearchTerm}
                />
                {filteredOptions.map(option => {
                    return <ListItem name={option.name} value={option.value} theme="aqua" onClick={this.props.setMemberType.bind(this, this.props.pieceId, option.value)} />;
                })}
            </DropDownList>
        </SelectBox>;
    
        return (
            <StepPiece theme="aqua" {...this.props}>
                <div className={styles.text}>set members</div>
                {this.props.iterableVariablePiece ? this.props.iterableVariablePiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverIterableVariablePiece && !!this.props.targetPiece} onMouseOver={this.handleHoverOverIterableVariablePiece} onMouseOut={this.handleHoverOutOfIterableVariablePiece} />}
                <div className={styles.text}>of type</div>
                {memberTypeSelectBox}
                <div className={styles.text}>in group</div>
                {this.props.variablePiece ? this.props.variablePiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverVariablePiece && !!this.props.targetPiece} onMouseOver={this.handleHoverOverVariablePiece} onMouseOut={this.handleHoverOutOfVariablePiece} />}
            </StepPiece>
        )
        
    }
}

const SetMembersInGroupPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedSetMembersInGroupPiece)

export default SetMembersInGroupPiece;