import React, { Component } from 'react';
import styles from './WorkflowModify.module.scss';
import "react-datepicker/dist/react-datepicker.css";

import InputText from '../../../widgets/form/InputText';
import chevronIcon from '../../../assets/chevron-arrow-down.svg';

import { ReactComponent as PlusIcon } from '../../../assets/new-custom-icons/dashboard/plus.svg';
import { ReactComponent as CheckIcon } from '../../../assets/new-custom-icons/profile/check-mark.svg';
import { ReactComponent as CancelIcon } from '../../../common/assets/close.svg';

import { translatePhrase } from '../../../shared/helpers/translation';

import { ApplicationState } from '../../../shared/store/types';
import { IUpdateableWorkflowData } from '../../../shared/store/workflows/types';

import { connect } from 'react-redux';
import uuid from 'uuid';
import moment from 'moment';
import { getReadableDataForCustomField } from '../../../shared/store/custom-fields';
import { isUUID } from '../../../shared/helpers/utilities';
import { Permissions } from '../../../shared/store/permissions/types';
import { getPermissionsForUser } from '../../../shared/helpers/permissions';
import { getAllLocationsVisibleToUser } from '../../../shared/helpers/locations';
import { getVisibleUserIds, getVisibleMemberIds, getVisibleGroupIds } from '../../../shared/helpers/visible-entities';
import LoaderModal from '../../../widgets/loader/LoaderModal';
import { withRouter, RouteComponentProps } from 'react-router';
import Button from '../../../widgets/button/CommonButton';
import DateInput from '../../../widgets/form/DateInput';
import AlertModal from '../../../widgets/alert/AlertModal';

type OwnProps = {
    workflowId?: string,
    isReadOnly?: boolean,

    submit: (workflowData: IUpdateableWorkflowData) => void,
    cancel: () => void,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {
    const workflowTypes = state.workflows.types.allEntries.map(workflowTypeId => state.workflows.types.byId[workflowTypeId]);

    return {
        workflow: ownProps.workflowId ? state.workflows.byId[ownProps.workflowId] : undefined,
        workflowTypes: workflowTypes,
        permissionsData: state.permissions,

        usersData: state.users,
        myId: state.myData.id,

        memberData: state.members,
        groupsData: state.groups,
        groupTypesData: state.groups.types,

        workflowsData: state.workflows,
        workflowTypesData: state.workflows.types,
        workflowStatusesData: state.workflows.types.statuses,
        customFieldsData: state.workflows.types.customFields,
        customFieldOptionsData: state.workflows.types.customFieldOptions,

        applicationState: state,
    }
};

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps & RouteComponentProps;


type OwnState = {
    workflowData: IUpdateableWorkflowData,
    submitTimer: number | undefined,
    locationKey: number,
    errorMessage: string,

    otherInstanceId: string | undefined,
    affiliationIds: Array<string>,

    loader: React.ReactNode | undefined
};

class ConnectedWorkflowModify extends Component<Props, OwnState> {

    constructor(props: Readonly<Props>) {
        super(props);

        let workflowData: IUpdateableWorkflowData;
        if (!props.workflow) {
            // This is a new workflow
            workflowData = {
                id: uuid.v4(),
                type: '',
                status: '',
                dueDate: moment().add(7, 'days').format('YYYY-MM-DD'),
                user: isUUID(this.props.myId) ? this.props.myId : '',
                affiliatedEntity: '',
            };
        } else {
            workflowData = {
                id: props.workflow.id,
                type: props.workflow.type,
                status: props.workflow.status,
                dueDate: props.workflow.dueDate,
                user: props.workflow.user,
                affiliatedEntity: props.workflow.affiliatedEntity,
            }
        }

        let affiliationIds: Array<string> = [];

        if (props.workflow) {
            const workflowType = this.props.workflowTypesData.byId[props.workflow.type];

            if (workflowType.affiliation === 'member') {
                affiliationIds = getVisibleMemberIds(props.applicationState, true);
            } else if (workflowType.affiliation === 'group') {
                affiliationIds = getVisibleGroupIds(props.applicationState, true);
            }
        }


        this.state = {
            workflowData: workflowData,
            submitTimer: undefined,
            locationKey: 0,
            errorMessage: '',
            otherInstanceId: undefined,
            affiliationIds,
            loader: undefined
        };
    }

    static defaultProps = {
        isReadOnly: false,
    }

    changeType = (type: string) => {

        const statusesForTypes = this.props.workflowTypesData.byId[type].statuses;
        const nonTerminalStatuses = this.props.workflowTypesData.byId[type].statuses.filter(statusId => !this.props.workflowStatusesData.byId[statusId].isTerminal);

        let defaultStatusId = '';
        let defaultDueDate = moment().add(7, 'days').format('YYYY-MM-DD');

        if (nonTerminalStatuses.length > 0) {
            defaultStatusId = nonTerminalStatuses[0];
            const defaultStatus = this.props.workflowStatusesData.byId[defaultStatusId];
            defaultDueDate = moment().add(defaultStatus.dueInDays ? defaultStatus.dueInDays : 7, 'days').format('YYYY-MM-DD');
        } else if (statusesForTypes.length > 0) {
            defaultStatusId = statusesForTypes[0];
        }

        const workflowType = this.props.workflowTypesData.byId[type];

        let affiliationIds: Array<string> = [];

        if (workflowType.affiliation === 'member') {
            affiliationIds = getVisibleMemberIds(this.props.applicationState, true);
        } else if (workflowType.affiliation === 'group') {
            affiliationIds = getVisibleGroupIds(this.props.applicationState, true);
        }

        let updatedIUpdateableWorkflowData: IUpdateableWorkflowData = {
            ...this.state.workflowData,
            type: type,
            user: isUUID(this.props.myId) ? this.props.myId : '',
            status: defaultStatusId,
            dueDate: defaultDueDate,
        };

        this.setState({
            workflowData: updatedIUpdateableWorkflowData,
            affiliationIds,
        });
    }

    changeDueDate = (dueDate: string | undefined) => {
        let updatedIUpdateableWorkflowData: IUpdateableWorkflowData = {
            ...this.state.workflowData,
            dueDate: dueDate
        };

        this.setState({
            workflowData: updatedIUpdateableWorkflowData
        });
    }

    changeStatus = (status: string) => {
        const statusData = this.props.workflowStatusesData.byId[status];
        const defaultDueDate = moment().add(statusData.dueInDays ? statusData.dueInDays : 7, 'days').format('YYYY-MM-DD');

        let updatedIUpdateableWorkflowData: IUpdateableWorkflowData = {
            ...this.state.workflowData,
            status: status,
            dueDate: defaultDueDate,
        };

        this.setState({
            workflowData: updatedIUpdateableWorkflowData,
        });
    }

    changeUser = (user: string) => {
        let updatedIUpdateableWorkflowData: IUpdateableWorkflowData = {
            ...this.state.workflowData,
            user: user
        };

        this.setState({
            workflowData: updatedIUpdateableWorkflowData
        });
    }

    changeAffiliatedEntity = (entity: string) => {
        let updatedIUpdateableWorkflowData: IUpdateableWorkflowData = {
            ...this.state.workflowData,
            affiliatedEntity: entity,
        };

        this.setState({
            workflowData: updatedIUpdateableWorkflowData
        });
    }

    showErrorMessage = (message: string) => {
        let that = this

        this.setState({
            errorMessage: message
        });

        window.setTimeout(() => {
            that.setState({
                errorMessage: ''
            });
        }, 3000);

    }

    preValidateIUpdateableWorkflowData = () => {

        if (!this.state.workflowData.type) {
            return;
        }

        if (!this.state.workflowData.user) {
            return;
        }

        if (!this.state.workflowData.status) {
            return;
        }

        if (this.props.workflowTypesData.byId[this.state.workflowData.type].affiliation !== 'none') {
            if (!this.state.workflowData.affiliatedEntity) {
                return;
            }
        }

        return true
    }

    validateIUpdateableWorkflowData = () => {

        if (!this.state.workflowData.type) {
            this.showErrorMessage('Select a valid type');
            return;
        }

        if (!this.state.workflowData.user) {
            this.showErrorMessage('Select a valid user');
            return;
        }

        if (!this.state.workflowData.status) {
            this.showErrorMessage('Select a valid status');
            return;
        }

        if (this.props.workflowTypesData.byId[this.state.workflowData.type].affiliation !== 'none') {
            if (!this.state.workflowData.affiliatedEntity) {
                this.showErrorMessage('Select a valid affiliated entity');
                return;
            }
        }

        if (!this.props.workflow) {
            const workflowType = this.props.workflowTypesData.byId[this.state.workflowData.type];

            if (!workflowType.areMultipleInstancesAllowed && Array.isArray(workflowType.workflows)) {
                const workflowIdsOfType = this.props.workflowsData.activeWorkflowEntries.filter(workflowId => {
                    const workflow = this.props.workflowsData.byId[workflowId];
                    return workflow.type === workflowType.id;
                });

                if (this.props.workflowTypesData.byId[this.state.workflowData.type].affiliation !== 'none') {

                    const myWorkflows = workflowIdsOfType.filter(workflowId => this.props.workflowsData.byId[workflowId].affiliatedEntity === this.state.workflowData.affiliatedEntity)

                    if (myWorkflows.length === 0) {
                        return true;
                    }

                    for (const workflowOfTypeId of workflowIdsOfType) {
                        const workflowOfType = this.props.workflowsData.byId[workflowOfTypeId];

                        const isExistingFlowExecutable = workflowOfType.user === this.props.myId || !isUUID(this.props.myId);

                        const triggerWorkflow = (trigger: boolean) => {
                            if (trigger) {
                                if (isExistingFlowExecutable) {
                                    this.props.history.push(`/workflow/${this.state.otherInstanceId}/execute`);
                                } else {
                                    this.props.history.push(`/workflow-data/${this.state.otherInstanceId}`);
                                }
                            } else this.setState({ loader: undefined });
                        }

                        if (workflowOfType && !workflowOfType.archived && workflowOfType.affiliatedEntity === this.state.workflowData.affiliatedEntity && !this.props.workflowStatusesData.byId[workflowOfType.status].isTerminal) {
                            const loader = <AlertModal
                                alertSentence={(isExistingFlowExecutable) ?
                                    translatePhrase("Seems like there is already a workflow existing... Would you like to resume it?") :
                                    translatePhrase('Another user already has a workflow of this type open for this member/group. View data?')}
                                confirmApproval={triggerWorkflow} alertHeader={''}
                            />
                            this.setState({
                                otherInstanceId: workflowOfTypeId,
                                loader
                            });
                            return;
                        }
                    }
                } else {
                    const myWorkflows = workflowIdsOfType.filter(workflowId => this.props.applicationState.workflows.byId[workflowId].user === this.state.workflowData.user);

                    if (myWorkflows.length === 0) {
                        return true;
                    };

                    for (const workflowOfTypeId of workflowIdsOfType) {
                        const workflowOfType = this.props.workflowsData.byId[workflowOfTypeId];

                        let isExistingFlowExecutable = workflowOfType.user === this.props.myId || !isUUID(this.props.myId);

                        const triggerWorkflow = (trigger: boolean) => {
                            if (trigger) {
                                if (isExistingFlowExecutable) {
                                    this.props.history.push(`/workflow/${this.state.otherInstanceId}/execute`);
                                } else {
                                    this.props.history.push(`/workflow-data/${this.state.otherInstanceId}`);
                                }
                            } else this.setState({ loader: undefined });
                        }

                        if (workflowOfType.user === this.state.workflowData.user) {
                            const loader = <AlertModal
                                alertSentence={(isExistingFlowExecutable) ?
                                    translatePhrase("Seems like there is already a workflow existing... Would you like to resume it?") :
                                    translatePhrase('Another user already has a workflow of this type open for this user. View data?')}
                                confirmApproval={triggerWorkflow} alertHeader={''}
                            />
                            this.setState({
                                otherInstanceId: workflowOfTypeId,
                                loader
                            });
                            return;
                        }
                    }
                }
            }
        }

        return true
    }

    submitWorkflowForm = () => {
        if (this.validateIUpdateableWorkflowData()) {
            if (this.state.otherInstanceId) {
                this.setState({
                    otherInstanceId: undefined,
                });
            }

            this.markForSubmit();

        }
    }

    markForSubmit = () => {
        let that = this;

        const timeout = window.setTimeout(function () {
            that.props.submit(that.state.workflowData);
        }, 1000);

        this.setState({
            submitTimer: timeout
        });
    }

    render() {

        const typesList = this.props.workflowTypesData.allEntries
            .filter(typeId => !this.props.permissionsData.myPermissions.workflows[typeId] || this.props.permissionsData.myPermissions.workflows[typeId] === Permissions.WRITE)
            .map(typeId => {
                return {
                    name: translatePhrase(this.props.workflowTypesData.byId[typeId].name),
                    value: typeId,
                };
            });

        let usersList: Array<{ name: string, value: string }> = [];

        if (this.state.workflowData.type) {
            const workflowType = this.props.workflowTypesData.byId[this.state.workflowData.type];

            const userIds = getVisibleUserIds(this.props.applicationState);

            if (isUUID(this.props.myId)) {
                userIds.unshift(this.props.myId);
            }

            usersList = userIds
                .filter(userId => {
                    const userData = this.props.usersData.byId[userId];

                    if (!userData.projects.includes(workflowType.project)) {
                        return false;
                    };

                    const permissionsForRole = getPermissionsForUser(userData.id, this.props.applicationState);

                    if (permissionsForRole.workflows[workflowType.id]) {
                        if (permissionsForRole.workflows[workflowType.id] === Permissions.NONE) {
                            return false;
                        }
                    }

                    return true;
                })
                .map(userId => {
                    const user = this.props.usersData.byId[userId];
                    let userName = user.customFields[this.props.usersData.nameFieldId];

                    const nameField = this.props.usersData.customFields.byId[this.props.usersData.nameFieldId];

                    userName = getReadableDataForCustomField(userName, nameField, userId, 'user');

                    let userSubtitle = user.customFields[this.props.usersData.subTitleFieldId];

                    const subTitleField = this.props.usersData.customFields.byId[this.props.usersData.subTitleFieldId];

                    userSubtitle = getReadableDataForCustomField(userSubtitle, subTitleField, userId, 'user');

                    return {
                        name: `${userName} (${userSubtitle})`,
                        value: userId,
                    };
                });
        }

        const currentUser = this.state.workflowData.user ? usersList.find(userChoice => userChoice.value === this.state.workflowData.user) : undefined;
        const currentUserName = typeof currentUser !== 'undefined' ? currentUser.name : undefined;
        const loggedInUser = isUUID(this.props.myId) ? this.props.usersData.byId[this.props.myId] : undefined;
        let loggedInUserName: string | undefined;

        if (loggedInUser) {
            const nameField = this.props.usersData.customFields.byId[this.props.usersData.nameFieldId];
            loggedInUserName = getReadableDataForCustomField(loggedInUser.customFields[nameField.id], nameField, loggedInUser.id, 'user');
        }

        let statusesInput: JSX.Element | undefined = undefined;
        let affiliationsInput: JSX.Element | undefined = undefined;
        let areAllAffiliationssIndexed = true;

        if (this.state.workflowData.type) {
            const workflowType = this.props.workflowTypesData.byId[this.state.workflowData.type];

            const statusesForType = workflowType.statuses.map(statusId => {
                return {
                    name: translatePhrase(this.props.workflowStatusesData.byId[statusId].name),
                    value: statusId,
                }
            });

            statusesInput = <div className={styles.inputSegment} key={this.state.workflowData.type + 's'}>
                <InputText placeholder="Status" icon={chevronIcon} isDisabled default={this.props.workflow ? translatePhrase(this.props.workflowStatusesData.byId[this.props.workflow.status].name) : this.state.workflowData.status ? translatePhrase(this.props.workflowStatusesData.byId[this.state.workflowData.status].name) : ''} options={statusesForType} onChange={this.changeStatus} />
            </div>;

            let affiliatedEntityOptions: Array<{ name: string, value: string }> = [];
            let defaultAffiliation = '';

            const workflowTypeHasStrictAffiliation = isUUID(workflowType.affiliatedEntity);

            if (workflowType.affiliation === 'member') {

                affiliatedEntityOptions = this.state.affiliationIds
                    .filter(memberId => {
                        if (!(memberId in this.props.memberData.byId)) {
                            return false;
                        }

                        const member = this.props.memberData.byId[memberId];

                        if (member.archived) {
                            return false;
                        }

                        if (workflowTypeHasStrictAffiliation && workflowType.affiliatedEntity !== member.type) {
                            return false;
                        }

                        if (member.type in this.props.permissionsData.myPermissions.members) {
                            if (this.props.permissionsData.myPermissions.members[member.type] === Permissions.NONE) {
                                return false;
                            }
                        }

                        return true;
                    })
                    .map(memberId => {
                        const member = this.props.memberData.byId[memberId];
                        const memberType = this.props.memberData.types.byId[member.type];

                        const nameField = this.props.memberData.types.customFields.byId[memberType.nameFieldId];
                        const subTitleField = this.props.memberData.types.customFields.byId[memberType.subTitleFieldId];

                        const memberName = getReadableDataForCustomField(member.customFields[memberType.nameFieldId], nameField, memberId, 'member', this.props.applicationState);
                        const memberSubtitle = getReadableDataForCustomField(member.customFields[memberType.subTitleFieldId], subTitleField, memberId, 'member', this.props.applicationState);

                        if (this.props.workflow && this.props.workflow.affiliatedEntity === memberId) {
                            defaultAffiliation = memberId;
                        }

                        return {
                            name: `${memberName} (${memberSubtitle})`,
                            value: memberId,
                        };
                    });
            } else if (workflowType.affiliation === 'group') {

                affiliatedEntityOptions = this.state.affiliationIds
                    .filter(groupId => {
                        if (!(groupId in this.props.groupsData.byId)) {
                            return false;
                        }

                        const group = this.props.groupsData.byId[groupId];

                        if (group.archived) {
                            return false;
                        }

                        if (workflowTypeHasStrictAffiliation && workflowType.affiliatedEntity !== group.type) {
                            return false;
                        }

                        if (group.type in this.props.permissionsData.myPermissions.groups) {
                            if (this.props.permissionsData.myPermissions.groups[group.type] === Permissions.NONE) {
                                return false;
                            }
                        }

                        return true;
                    })
                    .map(groupId => {
                        const group = this.props.groupsData.byId[groupId];
                        const groupType = this.props.groupsData.types.byId[group.type];

                        const nameField = this.props.groupsData.types.customFields.byId[groupType.nameFieldId];
                        const subTitleField = this.props.groupsData.types.customFields.byId[groupType.subTitleFieldId];

                        const groupName = getReadableDataForCustomField(group.customFields[groupType.nameFieldId], nameField, groupId, 'group', this.props.applicationState);
                        const groupSubtitle = getReadableDataForCustomField(group.customFields[groupType.subTitleFieldId], subTitleField, groupId, 'group', this.props.applicationState);

                        if (this.props.workflow && this.props.workflow.affiliatedEntity === groupId) {
                            defaultAffiliation = groupId;
                        }

                        return {
                            name: `${groupName} (${groupSubtitle})`,
                            value: groupId,
                        };
                    });
            }

            if (workflowType.affiliation !== 'none') {
                affiliationsInput = <div key={currentUser?.value} className={styles.inputSegment}>
                    <InputText placeholder={translatePhrase(workflowType.affiliation[0].toUpperCase() + workflowType.affiliation.substring(1))} icon={chevronIcon} default={defaultAffiliation} options={affiliatedEntityOptions} onChange={this.changeAffiliatedEntity} isAutoFocus={true} />
                </div>;
            }

        }

        let isExistingFlowExecutable = false;

        if (this.state.otherInstanceId) {
            const otherFlow = this.props.workflowsData.byId[this.state.otherInstanceId];
            isExistingFlowExecutable = otherFlow.user === this.props.myId;
        }

        let infoMessage = '';

        if (!areAllAffiliationssIndexed) {
            if (this.state.workflowData.type) {
                const workflowType = this.props.workflowTypesData.byId[this.state.workflowData.type];

                if (workflowType.affiliation === 'group') {
                    infoMessage = 'Groups are still being indexed. The list may be incomplete';
                } else if (workflowType.affiliation === 'member') {
                    infoMessage = 'Members are still being indexed. The list may be incomplete';
                }

            }
        }

        const processState = this.props.workflow ?
            this.props.workflow.historyIndex >= this.props.history.length ?
                this.props.workflow.history[this.props.workflow.history.length - 1]
                :
                this.props.workflow.history[this.props.workflow.historyIndex]
            :
            undefined;

        return (
            <section className={styles.commonModalHolder}>
                <section data-selector="workflow-upsert-form" className={this.props.isReadOnly ? styles.viewOnlyWorkflow : styles.addOrModifyListCard}>

                    {this.state.errorMessage && <LoaderModal loaderText={[translatePhrase('Error') + ' - ' + translatePhrase(this.state.errorMessage)]} isError={true} />}

                    {this.state.loader}
                    <header>
                        <h2 className={styles.formHeading}>{this.props.isReadOnly ? translatePhrase('View Workflow data') : translatePhrase('Add Workflow')}</h2>
                        <Button size={'small'} padding={'0px 10px'} isRounded onClick={this.props.cancel} icon={<CancelIcon />} text={this.props.isReadOnly ? translatePhrase('Close') : translatePhrase('Cancel')} />
                    </header>

                    <div className={styles.container}>
                        <div className={styles.allInputsHolder}>
                            <div className={styles.inputSegment}>
                                <InputText placeholder={translatePhrase('Name')} icon={chevronIcon} default={this.props.workflow ? translatePhrase(this.props.workflowTypesData.byId[this.props.workflow.type].name) : ''} options={typesList} onChange={this.changeType} isAutoFocus={true} />
                            </div>
                            {this.state.workflowData.type && <div className={styles.inputSegment} key={this.state.workflowData.type}>
                                <InputText placeholder={translatePhrase('User')} icon={chevronIcon} default={currentUserName || loggedInUserName || ''} options={usersList} onChange={this.changeUser} isAutoFocus={true} />
                            </div>}

                            {affiliationsInput}

                            {statusesInput}

                            {this.state.workflowData.type && <div className={styles.inputSegment + ' ' + styles.dateInput} key={this.state.workflowData.status}>
                                <DateInput placeholder={translatePhrase('Due Date')} default={this.state.workflowData.dueDate ? new Date(this.state.workflowData.dueDate) : this.props.workflow && this.props.workflow.dueDate ? new Date(this.props.workflow.dueDate) : undefined} onChange={value => this.changeDueDate(value)} />
                            </div>}

                            {this.props.workflow && <div className={styles.inputSegment}>
                                <DateInput isDisabled placeholder={translatePhrase('Created time')} default={new Date(this.props.workflow.createdTime)} onChange={() => { }} />
                            </div>}
                            {this.props.workflow && <div className={styles.inputSegment}>
                                <DateInput isDisabled placeholder={translatePhrase('Last updated time')} default={new Date(this.props.workflow.lastUpdatedTime)} onChange={() => { }} />
                            </div>}
                            {processState && <div className={styles.inputSegment}>
                                <DateInput isDisabled placeholder={translatePhrase('Last updated time')} default={new Date(processState.executionTime)} onChange={() => { }} />
                            </div>}

                        </div>
                    </div>

                    {!this.props.isReadOnly && <div className={styles.buttonsHolder}>
                        {this.state.submitTimer ?
                            <Button color={'contrast'} padding={'0px 10px'} isRounded icon={<CheckIcon />} text={this.props.workflow ? translatePhrase('Updated Workflow') : translatePhrase('Added Workflow')} /> :
                            <Button dataSelector="upsert-workflow-button" isDisabled={!this.preValidateIUpdateableWorkflowData()} color={'contrast'} padding={'0px 10px'} isRounded icon={this.props.workflow ? <CheckIcon /> : <PlusIcon />} onClick={this.submitWorkflowForm} text={this.props.workflow ? translatePhrase('Update Workflow') : translatePhrase('Start')} />}
                    </div>}

                </section>

            </section>
        );
    }
}

const WorkflowModify = withRouter(connect(mapStateToProps)(ConnectedWorkflowModify));

export default WorkflowModify;