import { isUUID } from "../../../helpers/utilities";
import { PieceState, PieceType, IfData } from "../pieces/types";

export interface PieceStackStep {
    pieceId: string,
    index?: number,
}

interface PieceStackInfo {
    finalStack: Array<PieceStackStep>,
    pieceFound: boolean,
}

export function getPieceStack(targetPieceId: string, pieceState: PieceState, pieceId: string, pieceStack: Array<PieceStackStep>) {

    if (pieceId === targetPieceId) {
        return {
            finalStack: pieceStack,
            pieceFound: true,
        }
    }

    const pieceData = pieceState.byId[pieceId];
    let pieceStackInfo: PieceStackInfo;

    const getPieceStackShorthand = getPieceStack.bind({}, targetPieceId, pieceState);

    if ('nextPiece' in pieceData) {
        if (typeof pieceData.nextPiece === 'string') {
            pieceStackInfo = getPieceStackShorthand(pieceData.nextPiece, pieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if ('innerPiece' in pieceData) {
        if (typeof pieceData.innerPiece === 'string') {

            const newPieceStack = pieceStack.concat({
                pieceId,
            });

            pieceStackInfo = getPieceStackShorthand(pieceData.innerPiece, newPieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if (pieceData.type === PieceType.SPLIT) {
        const newIfData: Array<IfData> | undefined = JSON.parse(JSON.stringify(pieceData.ifPieceData));

        if (typeof newIfData !== 'undefined') {
            for (let i = 0; i < newIfData.length; i += 1) {
                const ifDatum = newIfData[i];

                if (typeof ifDatum.conditionPiece === 'string') {
                    const newPieceStack = pieceStack.concat({
                        pieceId,
                    });

                    pieceStackInfo = getPieceStackShorthand(ifDatum.conditionPiece, newPieceStack);

                    if (pieceStackInfo.pieceFound) {
                        return pieceStackInfo;
                    }
                }

                if (typeof ifDatum.nextPiece === 'string') {
                    const newPieceStack = pieceStack.concat({
                        pieceId,
                        index: i,
                    });

                    pieceStackInfo = getPieceStackShorthand(ifDatum.nextPiece, newPieceStack);

                    if (pieceStackInfo.pieceFound) {
                        return pieceStackInfo;
                    }
                }
            }

            pieceData.ifPieceData = newIfData;
        }
    }

    if ('iterableVariable' in pieceData) {
        if (typeof pieceData.iterableVariable === 'string' && isUUID(pieceData.iterableVariable)) {
            pieceStackInfo = getPieceStackShorthand(pieceData.iterableVariable, pieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if ('operand' in pieceData) {
        if (typeof pieceData.operand === 'string' && isUUID(pieceData.operand)) {
            pieceStackInfo = getPieceStackShorthand(pieceData.operand, pieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if ('leftOperand' in pieceData) {
        if (typeof pieceData.leftOperand === 'string' && isUUID(pieceData.leftOperand)) {
            pieceStackInfo = getPieceStackShorthand(pieceData.leftOperand, pieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if ('rightOperand' in pieceData) {
        if (typeof pieceData.rightOperand === 'string' && isUUID(pieceData.rightOperand)) {
            pieceStackInfo = getPieceStackShorthand(pieceData.rightOperand, pieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if ('heading' in pieceData) {
        if (typeof pieceData.heading === 'string' && isUUID(pieceData.heading)) {
            pieceStackInfo = getPieceStackShorthand(pieceData.heading, pieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if ('question' in pieceData) {
        if (typeof pieceData.question === 'string' && isUUID(pieceData.question)) {
            pieceStackInfo = getPieceStackShorthand(pieceData.question, pieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if ('isRequiredPiece' in pieceData) {
        if (typeof pieceData.isRequiredPiece === 'string' && isUUID(pieceData.isRequiredPiece)) {
            pieceStackInfo = getPieceStackShorthand(pieceData.isRequiredPiece, pieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if ('isHiddenPiece' in pieceData) {
        if (typeof pieceData.isHiddenPiece === 'string' && isUUID(pieceData.isHiddenPiece)) {
            pieceStackInfo = getPieceStackShorthand(pieceData.isHiddenPiece, pieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if ('isDisabledPiece' in pieceData) {
        if (typeof pieceData.isDisabledPiece === 'string' && isUUID(pieceData.isDisabledPiece)) {
            pieceStackInfo = getPieceStackShorthand(pieceData.isDisabledPiece, pieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if ('default' in pieceData) {
        if (typeof pieceData.default === 'string' && isUUID(pieceData.default)) {
            pieceStackInfo = getPieceStackShorthand(pieceData.default, pieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if ('yearVariablePiece' in pieceData) {
        if (typeof pieceData.yearVariablePiece === 'string' && isUUID(pieceData.yearVariablePiece)) {
            pieceStackInfo = getPieceStackShorthand(pieceData.yearVariablePiece, pieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if ('monthVariablePiece' in pieceData) {
        if (typeof pieceData.monthVariablePiece === 'string' && isUUID(pieceData.monthVariablePiece)) {
            pieceStackInfo = getPieceStackShorthand(pieceData.monthVariablePiece, pieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if ('dateVariablePiece' in pieceData) {
        if (typeof pieceData.dateVariablePiece === 'string' && isUUID(pieceData.dateVariablePiece)) {
            pieceStackInfo = getPieceStackShorthand(pieceData.dateVariablePiece, pieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if ('memberVariablePiece' in pieceData) {
        if (typeof pieceData.memberVariablePiece === 'string' && isUUID(pieceData.memberVariablePiece)) {
            pieceStackInfo = getPieceStackShorthand(pieceData.memberVariablePiece, pieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if ('dataToStore' in pieceData) {
        if (typeof pieceData.dataToStore === 'string' && isUUID(pieceData.dataToStore)) {
            pieceStackInfo = getPieceStackShorthand(pieceData.dataToStore, pieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if ('dataToSet' in pieceData) {
        if (typeof pieceData.dataToSet === 'string' && isUUID(pieceData.dataToSet)) {
            pieceStackInfo = getPieceStackShorthand(pieceData.dataToSet, pieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if ('returnValue' in pieceData) {
        if (typeof pieceData.returnValue === 'string' && isUUID(pieceData.returnValue)) {
            pieceStackInfo = getPieceStackShorthand(pieceData.returnValue, pieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if ('variableToShow' in pieceData) {
        if (typeof pieceData.variableToShow === 'string' && isUUID(pieceData.variableToShow)) {
            pieceStackInfo = getPieceStackShorthand(pieceData.variableToShow, pieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if (pieceData.type !== PieceType.STYLE_TABLE_SECTION) {
        if ('variablePiece' in pieceData) {
            if (typeof pieceData.variablePiece === 'string' && isUUID(pieceData.variablePiece)) {
                pieceStackInfo = getPieceStackShorthand(pieceData.variablePiece, pieceStack);

                if (pieceStackInfo.pieceFound) {
                    return pieceStackInfo;
                }
            }
        }
    }

    if ('workflowDueDateVariable' in pieceData) {
        if (typeof pieceData.workflowDueDateVariable === 'string' && isUUID(pieceData.workflowDueDateVariable)) {
            pieceStackInfo = getPieceStackShorthand(pieceData.workflowDueDateVariable, pieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if ('affiliationVariable' in pieceData) {
        if (typeof pieceData.affiliationVariable === 'string' && isUUID(pieceData.affiliationVariable)) {
            pieceStackInfo = getPieceStackShorthand(pieceData.affiliationVariable, pieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if ('dueDate' in pieceData) {
        if (typeof pieceData.dueDate === 'string' && isUUID(pieceData.dueDate)) {
            pieceStackInfo = getPieceStackShorthand(pieceData.dueDate, pieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if ('locationPiece' in pieceData) {
        if (typeof pieceData.locationPiece === 'string' && isUUID(pieceData.locationPiece)) {
            pieceStackInfo = getPieceStackShorthand(pieceData.locationPiece, pieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if ('message' in pieceData) {
        if (typeof pieceData.message === 'string' && isUUID(pieceData.message)) {
            pieceStackInfo = getPieceStackShorthand(pieceData.message, pieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if ('name' in pieceData) {
        if (typeof pieceData.name === 'string' && isUUID(pieceData.name)) {
            pieceStackInfo = getPieceStackShorthand(pieceData.name, pieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if ('user' in pieceData) {
        if (typeof pieceData.user === 'string' && isUUID(pieceData.user)) {
            pieceStackInfo = getPieceStackShorthand(pieceData.user, pieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if ('startDate' in pieceData) {
        if (typeof pieceData.startDate === 'string' && isUUID(pieceData.startDate)) {
            pieceStackInfo = getPieceStackShorthand(pieceData.startDate, pieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if ('endDate' in pieceData) {
        if (typeof pieceData.endDate === 'string' && isUUID(pieceData.endDate)) {
            pieceStackInfo = getPieceStackShorthand(pieceData.endDate, pieceStack);

            if (pieceStackInfo.pieceFound) {
                return pieceStackInfo;
            }
        }
    }

    if (pieceData.type === PieceType.FINSAL_LOAN_PROCESS) {
        if ('premium' in pieceData) {
            if (typeof pieceData.premium === 'string' && isUUID(pieceData.premium)) {
                pieceStackInfo = getPieceStackShorthand(pieceData.premium, pieceStack);

                if (pieceStackInfo.pieceFound) {
                    return pieceStackInfo;
                }
            }
        }

        if ('memberFirstName' in pieceData) {
            if (typeof pieceData.memberFirstName === 'string' && isUUID(pieceData.memberFirstName)) {
                pieceStackInfo = getPieceStackShorthand(pieceData.memberFirstName, pieceStack);

                if (pieceStackInfo.pieceFound) {
                    return pieceStackInfo;
                }
            }
        }

        if ('memberLastName' in pieceData) {
            if (typeof pieceData.memberLastName === 'string' && isUUID(pieceData.memberLastName)) {
                pieceStackInfo = getPieceStackShorthand(pieceData.memberLastName, pieceStack);

                if (pieceStackInfo.pieceFound) {
                    return pieceStackInfo;
                }
            }
        }

        if ('memberEmail' in pieceData) {
            if (typeof pieceData.memberEmail === 'string' && isUUID(pieceData.memberEmail)) {
                pieceStackInfo = getPieceStackShorthand(pieceData.memberEmail, pieceStack);

                if (pieceStackInfo.pieceFound) {
                    return pieceStackInfo;
                }
            }
        }

        if ('memberPhone' in pieceData) {
            if (typeof pieceData.memberPhone === 'string' && isUUID(pieceData.memberPhone)) {
                pieceStackInfo = getPieceStackShorthand(pieceData.memberPhone, pieceStack);

                if (pieceStackInfo.pieceFound) {
                    return pieceStackInfo;
                }
            }
        }

        if ('memberPan' in pieceData) {
            if (typeof pieceData.memberPan === 'string' && isUUID(pieceData.memberPan)) {
                pieceStackInfo = getPieceStackShorthand(pieceData.memberPan, pieceStack);

                if (pieceStackInfo.pieceFound) {
                    return pieceStackInfo;
                }
            }
        }

        if ('memberState' in pieceData) {
            if (typeof pieceData.memberState === 'string' && isUUID(pieceData.memberState)) {
                pieceStackInfo = getPieceStackShorthand(pieceData.memberState, pieceStack);

                if (pieceStackInfo.pieceFound) {
                    return pieceStackInfo;
                }
            }
        }

        if ('memberCity' in pieceData) {
            if (typeof pieceData.memberCity === 'string' && isUUID(pieceData.memberCity)) {
                pieceStackInfo = getPieceStackShorthand(pieceData.memberCity, pieceStack);

                if (pieceStackInfo.pieceFound) {
                    return pieceStackInfo;
                }
            }
        }

        if ('memberAddressLine1' in pieceData) {
            if (typeof pieceData.memberAddressLine1 === 'string' && isUUID(pieceData.memberAddressLine1)) {
                pieceStackInfo = getPieceStackShorthand(pieceData.memberAddressLine1, pieceStack);

                if (pieceStackInfo.pieceFound) {
                    return pieceStackInfo;
                }
            }
        }

        if ('memberAddressLine2' in pieceData) {
            if (typeof pieceData.memberAddressLine2 === 'string' && isUUID(pieceData.memberAddressLine2)) {
                pieceStackInfo = getPieceStackShorthand(pieceData.memberAddressLine2, pieceStack);

                if (pieceStackInfo.pieceFound) {
                    return pieceStackInfo;
                }
            }
        }

        if ('memberPinCode' in pieceData) {
            if (typeof pieceData.memberPinCode === 'string' && isUUID(pieceData.memberPinCode)) {
                pieceStackInfo = getPieceStackShorthand(pieceData.memberPinCode, pieceStack);

                if (pieceStackInfo.pieceFound) {
                    return pieceStackInfo;
                }
            }
        }

        if ('memberDob' in pieceData) {
            if (typeof pieceData.memberDob === 'string' && isUUID(pieceData.memberDob)) {
                pieceStackInfo = getPieceStackShorthand(pieceData.memberDob, pieceStack);

                if (pieceStackInfo.pieceFound) {
                    return pieceStackInfo;
                }
            }
        }

        if ('memberGender' in pieceData) {
            if (typeof pieceData.memberGender === 'string' && isUUID(pieceData.memberGender)) {
                pieceStackInfo = getPieceStackShorthand(pieceData.memberGender, pieceStack);

                if (pieceStackInfo.pieceFound) {
                    return pieceStackInfo;
                }
            }
        }

        if ('memberFatherName' in pieceData) {
            if (typeof pieceData.memberFatherName === 'string' && isUUID(pieceData.memberFatherName)) {
                pieceStackInfo = getPieceStackShorthand(pieceData.memberFatherName, pieceStack);

                if (pieceStackInfo.pieceFound) {
                    return pieceStackInfo;
                }
            }
        }

        if ('memberMotherName' in pieceData) {
            if (typeof pieceData.memberMotherName === 'string' && isUUID(pieceData.memberMotherName)) {
                pieceStackInfo = getPieceStackShorthand(pieceData.memberMotherName, pieceStack);

                if (pieceStackInfo.pieceFound) {
                    return pieceStackInfo;
                }
            }
        }

        if ('memberAnnualIncome' in pieceData) {
            if (typeof pieceData.memberAnnualIncome === 'string' && isUUID(pieceData.memberAnnualIncome)) {
                pieceStackInfo = getPieceStackShorthand(pieceData.memberAnnualIncome, pieceStack);

                if (pieceStackInfo.pieceFound) {
                    return pieceStackInfo;
                }
            }
        }

        if ('memberMaritalStatus' in pieceData) {
            if (typeof pieceData.memberMaritalStatus === 'string' && isUUID(pieceData.memberMaritalStatus)) {
                pieceStackInfo = getPieceStackShorthand(pieceData.memberMaritalStatus, pieceStack);

                if (pieceStackInfo.pieceFound) {
                    return pieceStackInfo;
                }
            }
        }

        if ('userEmail' in pieceData) {
            if (typeof pieceData.userEmail === 'string' && isUUID(pieceData.userEmail)) {
                pieceStackInfo = getPieceStackShorthand(pieceData.userEmail, pieceStack);

                if (pieceStackInfo.pieceFound) {
                    return pieceStackInfo;
                }
            }
        }

        if ('userPhone' in pieceData) {
            if (typeof pieceData.userPhone === 'string' && isUUID(pieceData.userPhone)) {
                pieceStackInfo = getPieceStackShorthand(pieceData.userPhone, pieceStack);

                if (pieceStackInfo.pieceFound) {
                    return pieceStackInfo;
                }
            }
        }

        if ('member' in pieceData) {
            if (typeof pieceData.member === 'string' && isUUID(pieceData.member)) {
                pieceStackInfo = getPieceStackShorthand(pieceData.member, pieceStack);

                if (pieceStackInfo.pieceFound) {
                    return pieceStackInfo;
                }
            }
        }

    }

    return {
        finalStack: pieceStack,
        pieceFound: false,
    };
}