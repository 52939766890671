import { takeEvery, put, select } from 'redux-saga/effects'
import { AddDataFragmentAction, DeleteDataFragmentAction, ADD_DATA_FRAGMENT, DELETE_DATA_FRAGMENT, ADD_DATA_FRAGMENTS, AddDataFragmentsAction } from './types';
import { ApplicationState } from '../../types';
import { addDataFragmentsToStaticDataHolder, addDataFragmentToStaticDataHolder, removeDataFragmentFromStaticDataHolder } from '../actions';
import { AddStaticDataHolderAction, ADD_STATIC_DATA_HOLDER } from '../types';
import { setDefaultStaticDataPermission } from '../../permissions/actions';

function* addDefaultPermissions(action: AddStaticDataHolderAction) {
    yield put(setDefaultStaticDataPermission(action.payload.id));
}

function* addReverseLinkForParentStaticDataHolder(action: AddDataFragmentAction) {
    const state: ApplicationState = yield select();
    if (action.parentId in state.staticInfo.byId) {
        yield put(addDataFragmentToStaticDataHolder(action.payload.id, action.parentId));
    }
}

function* addReverseLinksForParentStaticDataHolder(action: AddDataFragmentsAction) {
    const state: ApplicationState = yield select();
    const payload: Array<{ dataFragmentId: string, staticDataHolderId: string }> = [];
    for (const entry of action.payload) {
        if (entry.parentId in state.staticInfo.byId) {
            payload.push({
                dataFragmentId: entry.data.id,
                staticDataHolderId: entry.parentId,
            });
        }
    }

    if (payload.length > 0) {
        yield put(addDataFragmentsToStaticDataHolder(payload));
    }
}

function* removeReverseLinkForParentStaticDataHolder(action: DeleteDataFragmentAction) {
    const state: ApplicationState = yield select();
    if (action.parentId in state.staticInfo.byId) {
        yield put(removeDataFragmentFromStaticDataHolder(action.id, action.parentId));
    }
}

export function* watchStaticDataCreationChanges() {
    yield takeEvery(ADD_STATIC_DATA_HOLDER, addDefaultPermissions);
}

export function* watchDataFragmentCreationChanges() {
    yield takeEvery(ADD_DATA_FRAGMENT, addReverseLinkForParentStaticDataHolder);
}

export function* watchDataFragmentsCreationChanges() {
    yield takeEvery(ADD_DATA_FRAGMENTS, addReverseLinksForParentStaticDataHolder);
}

export function* watchDataFragmentDeletionChanges() {
    yield takeEvery(DELETE_DATA_FRAGMENT, removeReverseLinkForParentStaticDataHolder);
}