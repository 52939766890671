import React, { Component } from 'react';

import { ApplicationState } from '../../shared/store/types';
import CountDown from '../../widgets/card/CountDown';
import ShowTable from '../workflow/process/ShowTable';

import { connect } from 'react-redux';

import { ReactComponent as DeleteIcon } from '../../assets/new-custom-icons/dashboard/trash.svg';
import { ReactComponent as EditIcon } from '../../assets/new-custom-icons/dashboard/edit.svg';
import { ReactComponent as UndoIcon } from '../../assets/undo.svg';


import { translatePhrase } from '../../shared/helpers/translation';
import { Dispatch } from 'redux';
import { setToastMessage } from '../../shared/store/my-data/actions';
import AggregatedWidget from './AggregatedWidget';
import MessageWidget from './MessageWidget';
import { TableCell } from '../../shared/helpers/common-types';
import TableWidget from './TableWidget';
import Button from '../../widgets/button/CommonButton';

type OwnProps = {
    widgetId: string,
    isReadOnly: boolean,
    isDashboardWidget?: boolean,

    setIsPopulatingExport: (isPopulatingExport: boolean) => void,
    updateWidgetCache: () => void,
    deleteWidget?: (widgetId: string) => void,
    modifyWidget: (widgetId: string) => void,
    setToastLoader: (text: string, isSuccess: boolean) => void,
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    const cachedTableData = ownProps.widgetId in state.widgets.cachedWidgetTableData ? state.widgets.cachedWidgetTableData[ownProps.widgetId] : undefined;

    return {
        widget: state.widgets.byId[ownProps.widgetId],
        cachedMessage: state.widgets.cachedWidgetMessages[ownProps.widgetId],
        cachedTableData,
        myId: state.myData.id,
    };
}

const mapDispatchToProps = (dispatch: Dispatch) => {
    return {
        setToastMessage: (message: string) => dispatch(setToastMessage(message)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;
type Props = OwnProps & StateProps & DispatchProps;

type OwnState = {
    dataType: 'table' | 'bar' | 'line' | 'donut' | 'message' | 'formatted-table',
    deleteTimer: number | undefined;
    deletingWidgets: Array<string>,
}

class ConnectedWidget extends Component<Props, OwnState> {

    static defaultProps = {
        isReadOnly: true,
    };

    constructor(props: Readonly<Props>) {
        super(props);

        this.state = {
            dataType: props.widget.displayType || 'table',
            deleteTimer: undefined,
            deletingWidgets: [],
        }
    }

    deleteWidgetFromId = (deletingWidgetId: string) => {
        this.props.deleteWidget && this.props.deleteWidget(deletingWidgetId);
        this.setState(prevState => {
            return {
                deleteTimer: undefined,
                deletingWidgets: prevState.deletingWidgets.filter(widgetId => deletingWidgetId !== widgetId),
            }
        })
    }

    markForDelete = (widgetId: string) => {
        let that = this;
        const timeout = window.setTimeout(() => {
            that.deleteWidgetFromId(widgetId);
        }, 5000);

        this.setState(prevState => {
            return {
                deleteTimer: timeout,
                deletingWidgets: prevState.deletingWidgets.concat([widgetId]),
            };
        });
    }

    cancelDelete = (deletingWidgetId: string) => {
        clearTimeout(this.state.deleteTimer);
        this.setState(prevState => {
            return {
                deleteTimer: undefined,
                deletingWidgets: prevState.deletingWidgets.filter(widgetId => deletingWidgetId !== widgetId),
            }
        });
    }

    render() {
        const canEditWidget = this.props.myId === 'SuperUser' || (!this.props.isReadOnly && this.props.myId === this.props.widget.creatingUser);
        const optionsMarkup = <div>
            {canEditWidget &&
                <Button title={translatePhrase('Edit')} onClick={() => this.props.modifyWidget(this.props.widgetId)}
                    size={'small'} isRounded={true} type={'secondary'} icon={<EditIcon />} />}
            {canEditWidget && this.props.widgetId && (this.state.deletingWidgets.includes(this.props.widgetId) ?
                <Button color={'danger'} padding={"0px 10px"} size={'small'} isRounded={true} type={'primary'} icon={<UndoIcon />}
                    title={translatePhrase('Undo')} onClick={this.cancelDelete.bind(this, this.props.widgetId)} text={<CountDown color={'#fff'} remaining={5} />} /> :
                <Button color={"danger"} size={'small'} isRounded={true} type={'secondary'} icon={<DeleteIcon />} title={translatePhrase('Delete')} onClick={this.markForDelete.bind(this, this.props.widgetId)} />)}
        </div>

        let textToShow: string | undefined;
        let tableData: Array<Array<TableCell>> | undefined;

        if (this.props.widget.displayType === 'message') {
            textToShow = this.props.cachedMessage;

            return <MessageWidget
                widgetId={this.props.widgetId}
                optionsMarkup={optionsMarkup}
                textToShow={textToShow}
                setToastLoaderForCopy={() => this.props.setToastLoader('The share URL has been copied to clipboard', true)}
            />
        } else if (this.props.widget.displayType === 'formatted-table') {
            tableData = this.props.cachedTableData;

            if (Array.isArray(tableData)) {

                return <TableWidget
                    widgetId={this.props.widgetId}
                    optionsMarkup={optionsMarkup}
                    tableData={tableData}
                    updateWidgetCache={this.props.updateWidgetCache}
                    isDashboardTableWidget={this.props.isDashboardWidget}
                />
            } else {

                return <TableWidget
                    widgetId={this.props.widgetId}
                    optionsMarkup={optionsMarkup}
                    shouldPromptReload
                    tableData={[]}
                    updateWidgetCache={this.props.updateWidgetCache}
                    isDashboardTableWidget={this.props.isDashboardWidget}
                />
            }
        }

        let fields = this.props.widget.systemFields ? this.props.widget.systemFields : [];
        fields = fields.concat(this.props.widget.customFields);

        const isGlobalAggregated = this.props.widget.aggregation !== 'none' && fields.length === 1;

        if (isGlobalAggregated) {
            return <AggregatedWidget
                widgetId={this.props.widgetId}
                optionsMarkup={optionsMarkup}
                setToastLoaderForCopy={() => this.props.setToastLoader('The share URL has been copied to clipboard', true)}
                setIsPopulatingExport={this.props.setIsPopulatingExport}
                updateWidgetCache={this.props.updateWidgetCache}
            />
        }

        let yAxisAggregation: 'sum' | 'average' | undefined;

        switch (this.props.widget.aggregation) {
            case 'sum':
                yAxisAggregation = 'sum';
                break;
            case 'average':
                yAxisAggregation = 'average';
                break;
            default:
                yAxisAggregation = undefined;
        }

        return <ShowTable
            key={this.props.widgetId}
            widgetId={this.props.widgetId}
            header={this.props.widget.name}
            type={this.props.widget.type}
            typeId={this.props.widget.typeId}
            systemFields={this.props.widget.systemFields}
            customFields={this.props.widget.customFields}
            exportingSystemFields={this.props.widget.exportingSystemFields}
            exportingCustomFields={this.props.widget.exportingCustomFields}
            aggregation={this.props.widget.aggregation}
            roles={this.props.widget.roles}
            entityIds={[]}
            textToShow={textToShow}
            optionsMarkup={optionsMarkup}
            setToastLoaderForCopy={() => this.props.setToastLoader('The share URL has been copied to clipboard', true)}

            startingDisplayType={this.props.widget.displayType}

            setIsPopulatingExport={this.props.setIsPopulatingExport}
            updateWidgetCache={this.props.updateWidgetCache}
            yAxisAggregation={yAxisAggregation}
        />

    }
}

const Widget = connect(mapStateToProps, mapDispatchToProps)(ConnectedWidget);

export default Widget;
