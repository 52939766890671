import React, { ChangeEvent, Component, ReactNode } from 'react';
import styles from './container-piece/ContainerPiece.module.scss';
import Input from '../Input';

import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';
import { Option } from '../drop-down/ListItem';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setLoopVariable, setIterableVariable } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import ContainerPiece from './container-piece/ContainerPiece';
import { valuePieceSlotTarget, nextPieceTarget } from './utilities';
import { WorkflowTypeContext } from '../../../contexts/workflow-type-context';
import { FlowchartContext, FlowchartInfoForPiece } from '../../../contexts/flowchart-context';
import { VariableType } from '../../../shared/store/flowchart/variables/types';
import { isUUID } from '../../../shared/helpers/utilities';


type ForPieceProps = {
    variables: Array<Option>,
    loopVariableId?: string,

    nextPiece?: JSX.Element,
    iterablePiece?: JSX.Element,
    iterableText?: string,
    iterableVariableType?: VariableType,
    children?: ReactNode,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,
        variablesData: state.flowchart.variables,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string | undefined) => dispatch(setTargetPiece(pieceId)),
        setLoopVariable: (targetPieceId: string, draggedPieceId: string) => dispatch(setLoopVariable(targetPieceId, draggedPieceId)),
        setIterableVariable: (targetPieceId: string, draggedPieceId: string) => dispatch(setIterableVariable(targetPieceId, draggedPieceId)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = ForPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type ForPieceState = {
    isHoveringOverIterablePiece: boolean,
    searchText: string,
}

class ConnectedForPiece extends Component<Props, ForPieceState>  {

    state = {
        isHoveringOverIterablePiece: false,
        searchText: '',
    };

    handleHoverOverInnerPiece = () => {

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        nextPieceTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOverIterablePiece = () => {
        this.setState({
            isHoveringOverIterablePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfIterablePiece = () => {
        this.setState({
            isHoveringOverIterablePiece: false,
        });
    };

    handleIterableValueUpdate = (value: string) => {
        this.props.setIterableVariable(this.props.pieceId, value);
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && (this.state.isHoveringOverIterablePiece)) {
            if (this.state.isHoveringOverIterablePiece) {
                this.props.setIterableVariable(this.props.pieceId, this.props.lastDraggedPiece.id);
            }

            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverIterablePiece: false,
            });
        }
    }

    searchForVariable = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            searchText: e.target.value,
        });
    }

    updateValidity = (isValid: boolean, flowchartContext: FlowchartInfoForPiece) => {
        if (!isValid) {
            const invalidPieceInfo = flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId);

            if (!invalidPieceInfo && flowchartContext.setInvalidPiece) {
                flowchartContext.setInvalidPiece(this.props.pieceId, flowchartContext.parentSplitPieceIds);
            }
        }

        if (isValid && flowchartContext.removeInvalidPiece && flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId)) {
            flowchartContext.removeInvalidPiece(this.props.pieceId);
        }
    }

    areVariableTypesValid = () => {
        if (!this.props.loopVariableId) {
            return true;
        }

        const variable = this.props.variablesData.byId[this.props.loopVariableId];

        if (this.props.iterableText && !isUUID(this.props.iterableText) && !isNaN(Number(this.props.iterableText))) {
            return variable.type === VariableType.NUMBER;
        }

        switch (this.props.iterableVariableType) {
            case VariableType.TEXT_LIST:
                return variable.type === VariableType.TEXT;
            case VariableType.TEXT_LIST:
                return variable.type === VariableType.TEXT;
            case VariableType.USERS_LIST:
                return variable.type === VariableType.USER;
            case VariableType.MEMBERS_LIST:
                return variable.type === VariableType.MEMBER;
            case VariableType.GROUPS_LIST:
                return variable.type === VariableType.GROUP;
            case VariableType.LOCATIONS_LIST:
                return variable.type === VariableType.LOCATION;
            case VariableType.ROLES_LIST:
                return variable.type === VariableType.ROLE;
            case VariableType.LEVELS_LIST:
                return variable.type === VariableType.LEVEL;
            case VariableType.PROJECTS_LIST:
                return variable.type === VariableType.PROJECT;
            case VariableType.WORKFLOWS_LIST:
                return variable.type === VariableType.WORKFLOW;
            case VariableType.REPORTS_LIST:
                return variable.type === VariableType.REPORT;
            case VariableType.DATA_FRAGMENTS_LIST:
                return variable.type === VariableType.DATA_FRAGMENT;

            case VariableType.STYLED_TABLE_ROW:
                return variable.type === VariableType.STYLED_TABLE_CELL;
            case VariableType.STYLED_TABLE_DATA:
                return variable.type === VariableType.STYLED_TABLE_ROW;

            case VariableType.TABLE_DATA:
                return variable.type === VariableType.TEXT_LIST;
            case VariableType.NUMBER:
                return variable.type === VariableType.NUMBER;
            default:
                return false;
        }
    }

    render() {
        return <FlowchartContext.Consumer>
            {
                flowchartContext => {
                    let isValid = !this.props.loopVariableId || flowchartContext.variables.includes(this.props.loopVariableId);

                    isValid = isValid && this.areVariableTypesValid();

                    if (flowchartContext.highlightIncompletePieces) {
                        const isIncomplete = !this.props.loopVariableId || (!this.props.iterablePiece && !this.props.iterableText);
                        isValid = isValid && !isIncomplete;
                    }

                    this.updateValidity(isValid, flowchartContext);
                    const loopVariableName = this.props.loopVariableId && this.props.loopVariableId in this.props.variablesData.byId ? this.props.variablesData.byId[this.props.loopVariableId].name : undefined;

                    const variableSelectBox = <SelectBox theme="indigo" selectionPromptText={loopVariableName}>
                        <DropDownList theme="indigo" dismissAfterSelection={false}>
                            <div className={styles.nameContainer}>
                                <input className={styles.nameInput} onChange={this.searchForVariable} value={this.state.searchText} type="text" placeholder="Search by name" />
                            </div>
                            {this.props.variables.filter(variable => variable.name.toLocaleLowerCase().includes(this.state.searchText.toLocaleLowerCase())).map((variable, index) => <ListItem name={variable.name} value={variable.value} key={index} theme="indigo" onClick={this.props.setLoopVariable.bind(this, this.props.pieceId)} />)}
                        </DropDownList>
                    </SelectBox>;

                    const upperArmContent = (<div className={styles.upperArmContent}>
                        <div className={styles.text}>for</div>
                        {variableSelectBox}
                        <div className={styles.text}>in </div>
                        {this.props.iterablePiece ? this.props.iterablePiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverIterablePiece && !!this.props.targetPiece} onMouseOver={this.handleHoverOverIterablePiece} onMouseOut={this.handleHoverOutOfIterablePiece} defaultText={this.props.iterableText} onChange={this.handleIterableValueUpdate} />}
                    </div>);

                    return (<ContainerPiece
                        {...this.props}
                        theme={isValid ? "aqua" : "red"}
                        handleHoverOverInnerPiece={this.handleHoverOverInnerPiece}

                        upperArmContent={upperArmContent}
                    >{this.props.children}</ContainerPiece>);
                }
            }
        </FlowchartContext.Consumer>

    }
}

const ForPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedForPiece);

export default ForPiece;