import { NudgeData } from "./index";
import { ApplicationState } from "../../../shared/store/types";
import { NudgeType } from "../../../shared/store/my-data/types";

export function getWorkflowsConfigurationNudges(applicationState: ApplicationState): Array<NudgeData> {

    let nudgesData: Array<NudgeData> = [{
        id: NudgeType.WORKFLOWS_CONFIGURATION_IMPORT,
        header: 'Import workflow type',
        content: `Click on the Import button on the top right part of the Workflow Types panel. You will asked to choose a file from your system. This file must have a json file of previously exported Unaffiliated, Member-affiliated and Group-affiliated workflows on your local system. Please note that such export files contain Workflows definition and if affiliated, Member or Group Type definition 


        Import Workflow - Member affiliated workflow: You must use a json file of Member affiliated workflow. Using export of any other workflow type will have unpredictable results. Form shown will have the following fields:

        Name: Unique name of the Workflow type
        Project: Select a project which is configured in your account; You must have at least one project configured
        Link to existing member type?: If enabled, you can choose one of the configured Member types in your account; You must have at least one Member type configured; If this field is disabled, you can type a new Member type to be created in selected project; DICEFlow will create a new Member type exactly as defined in the selected json file
        Member type: If above field is enabled, this will be turn into a dropdown of configured Member types; If disabled, you can type a unique name of new Member type to be created
        Static Data: If the import includes static data, you have an option to import the data from the file into the organization
        Is Core?: Workflow type should be added to Core list of Workflows when shown in Mobile
        Allow Multiple Instances: If enabled, allow user to add new workflows for the same Member even when another workflow of the same type is open/active.  
        
        
        Import Workflow - Group affiliated workflow: You must use a json file of Group affiliated workflow. Using export of any other workflow type will have unpredictable results. Form shown will have the following fields:
        
        Name: Unique name of the Workflow type
        Project: Select a project which is configured in your account; You must have at least one project configured
        Link to existing group type?: If enabled, you can choose one of the configured Group types in your account; You must have at least one Group type configured; If this field is disabled, you can type a new Group type to be created in selected project; DICEFlow will create a new Group type exactly as defined in the selected json file
        Create at level: Group type will be created at the hierarchy level specified
        Group type: If above field is enabled, this will be turn into a dropdown of configured Group types; If disabled, you can type a unique name of new Group type to be created
        Static Data: If the import includes static data, you have an option to import the data from the file into the organization
        Is Core?: Workflow type should be added to Core list of Workflows when shown in Mobile
        Allow Multiple Instances: If enabled, allow user to add new workflows for the same Member even when another workflow of the same type is open/active.
        

        Import Workflow - Unaffiliated workflow: You must use a json file of Unaffiliated workflow. Using export of any other workflow type will have unpredictable results. Form shown will have the following fields:
        
        Name: Unique name of the Workflow type
        Project: Select a project which is configured in your account; You must have at least one project configured
        Static Data: If the import includes static data, you have an option to import the data from the file into the organization
        Is Core?: Workflow type should be added to Core list of Workflows when shown in Mobile
        Allow Multiple Instances: If enabled, allow user to add new workflows for the same Member even when another workflow of the same type is open/active.`
    }];

    return nudgesData;
}