import { AllPieceTypes, PieceType, IfData, NestingData } from '../../store/flowchart/pieces/types';
import uuid from 'uuid';
import { isUUID } from '../utilities';
import { StateIDMapping, DuplicationIDMappng, WorkflowImportIdMap } from './index';
import { NormalizedModel } from '../../store/normalized-model';

export function duplicatePiece(piecesData: NormalizedModel<AllPieceTypes>, duplicateIDMapping: StateIDMapping, projectId: string, isCopiedInSameProject: boolean, addFullPiece: (pieceData: AllPieceTypes) => void, pieceId: string) {
    const pieceToCopy = piecesData.byId[pieceId];
    const newId = uuid.v4();

    const duplicatePieceTempShortHand = duplicatePiece.bind({}, piecesData, duplicateIDMapping, projectId);
    const duplicatePieceShortHand = duplicatePieceTempShortHand.bind({}, isCopiedInSameProject, addFullPiece);

    const newPieceData: AllPieceTypes = {
        ...pieceToCopy,
        id: newId,
    };

    // Replace the types, custom fields, etc.

    if ('loopVariable' in newPieceData) {
        if (typeof newPieceData.loopVariable === 'string' && isUUID(newPieceData.loopVariable)) {
            newPieceData.loopVariable =  duplicateIDMapping.variableIds[newPieceData.loopVariable];
        }
    }

    if ('listVariable' in newPieceData) {
        if (typeof newPieceData.listVariable === 'string' && isUUID(newPieceData.listVariable)) {
            newPieceData.listVariable =  duplicateIDMapping.variableIds[newPieceData.listVariable];
        }
    }

    if ('status' in newPieceData) {
        if (typeof newPieceData.status === 'string' && isUUID(newPieceData.status)) {
            newPieceData.status =  duplicateIDMapping.workflowStatusIds[newPieceData.status];
        }
    }

    if ('workflow' in newPieceData) {
        if (typeof newPieceData.workflow === 'string' && isUUID(newPieceData.workflow)) {
            newPieceData.workflow =  duplicateIDMapping.variableIds[newPieceData.workflow];
        }
    }

    if ('statusId' in newPieceData) {
        if (typeof newPieceData.statusId === 'string' && isUUID(newPieceData.statusId)) {
            newPieceData.statusId =  duplicateIDMapping.workflowStatusIds[newPieceData.statusId];
        }
    }

    if (!isCopiedInSameProject && 'roles' in newPieceData) {
        if (Array.isArray(newPieceData.roles) && newPieceData.roles.length > 0) {
            newPieceData.roles =  newPieceData.roles.filter(roleId => !isUUID(roleId) || roleId in duplicateIDMapping.roleIds).map(roleId => duplicateIDMapping.roleIds[roleId]);
        }
    }

    if ('variable' in newPieceData) {
        if (typeof newPieceData.variable === 'string' && isUUID(newPieceData.variable)) {
            newPieceData.variable =  duplicateIDMapping.variableIds[newPieceData.variable];
        }
    }

    if (!isCopiedInSameProject && 'project' in newPieceData) {
        if (typeof newPieceData.project === 'string' && isUUID(newPieceData.project)) {
            newPieceData.project =  projectId;
        }
    }

    if (!isCopiedInSameProject && 'level' in newPieceData) {
        if (typeof newPieceData.level === 'string' && isUUID(newPieceData.level)) {
            newPieceData.level =  duplicateIDMapping.levelIds[newPieceData.level];
        }
    }

    if (!isCopiedInSameProject && 'role' in newPieceData) {
        if (typeof newPieceData.role === 'string' && isUUID(newPieceData.role)) {
            newPieceData.role =  duplicateIDMapping.roleIds[newPieceData.role];
        }
    }

    if ('variableToSet' in newPieceData) {
        if (typeof newPieceData.variableToSet === 'string' && isUUID(newPieceData.variableToSet)) {
            newPieceData.variableToSet =  duplicateIDMapping.variableIds[newPieceData.variableToSet];
        }
    }

    if ('customField' in newPieceData) {
        if (typeof newPieceData.customField === 'string' && isUUID(newPieceData.customField)) {
            newPieceData.customField =  duplicateIDMapping.workflowTypeCustomFieldIds[newPieceData.customField];
        }
    }

    if ('customFieldOption' in newPieceData) {
        if (typeof newPieceData.customFieldOption === 'string' && isUUID(newPieceData.customFieldOption)) {
            newPieceData.customFieldOption =  duplicateIDMapping.workflowTypeCustomFieldOptionIds[newPieceData.customFieldOption];
        }
    }

    if ('variableToCopy' in newPieceData) {
        if (typeof newPieceData.variableToCopy === 'string' && isUUID(newPieceData.variableToCopy)) {
            newPieceData.variableToCopy =  duplicateIDMapping.variableIds[newPieceData.variableToCopy];
        }
    }

    if ('entityType' in newPieceData && typeof newPieceData.entityType !== 'undefined') {
        if (!isCopiedInSameProject) {
            if (newPieceData.entityType in duplicateIDMapping.levelIds) {
                newPieceData.entityType = duplicateIDMapping.levelIds[newPieceData.entityType];
            } else if (newPieceData.entityType in duplicateIDMapping.roleIds) {
                newPieceData.entityType = duplicateIDMapping.roleIds[newPieceData.entityType];
            } else if (newPieceData.entityType in duplicateIDMapping.memberTypeIds) {
                newPieceData.entityType = duplicateIDMapping.memberTypeIds[newPieceData.entityType];
            } else if (newPieceData.entityType in duplicateIDMapping.groupTypeIds) {
                newPieceData.entityType = duplicateIDMapping.groupTypeIds[newPieceData.entityType];
            } else if (newPieceData.entityType in duplicateIDMapping.workflowTypeIds) {
                newPieceData.entityType = duplicateIDMapping.workflowTypeIds[newPieceData.entityType];
            }
        }
    }

    if ('customFieldId' in newPieceData) {
        if (!isCopiedInSameProject) {
            if (typeof newPieceData.customFieldId === 'string' && isUUID(newPieceData.customFieldId)) {
                if (newPieceData.customFieldId in duplicateIDMapping.workflowTypeCustomFieldIds) {
                    newPieceData.customFieldId = duplicateIDMapping.workflowTypeCustomFieldIds[newPieceData.customFieldId];
                } else if (newPieceData.customFieldId in duplicateIDMapping.roleCustomFieldIds) {
                    newPieceData.customFieldId = duplicateIDMapping.roleCustomFieldIds[newPieceData.customFieldId];
                } else if (newPieceData.customFieldId in duplicateIDMapping.memberTypeCustomFieldIds) {
                    newPieceData.customFieldId = duplicateIDMapping.memberTypeCustomFieldIds[newPieceData.customFieldId];
                } else if (newPieceData.customFieldId in duplicateIDMapping.groupTypeCustomFieldIds) {
                    newPieceData.customFieldId = duplicateIDMapping.groupTypeCustomFieldIds[newPieceData.customFieldId];
                } else if (newPieceData.customFieldId in duplicateIDMapping.levelCustomFieldIds) {
                    newPieceData.customFieldId = duplicateIDMapping.levelCustomFieldIds[newPieceData.customFieldId];
                }
            }
        }
    }

    if ('customFieldIds' in newPieceData) {
        if (!isCopiedInSameProject && newPieceData.customFieldIds) {
            newPieceData.customFieldIds = newPieceData.customFieldIds
            .filter(customFieldId => {
                if (customFieldId in duplicateIDMapping.workflowTypeCustomFieldIds) {
                    return true;
                } else if (customFieldId in duplicateIDMapping.roleCustomFieldIds) {
                    return true;
                } else if (customFieldId in duplicateIDMapping.memberTypeCustomFieldIds) {
                    return true;
                } else if (customFieldId in duplicateIDMapping.groupTypeCustomFieldIds) {
                    return true;
                } else if (customFieldId in duplicateIDMapping.levelCustomFieldIds) {
                    return true;
                } else {
                    return false;
                }
            })
            .map(customFieldId => {
                if (customFieldId in duplicateIDMapping.workflowTypeCustomFieldIds) {
                    return duplicateIDMapping.workflowTypeCustomFieldIds[customFieldId];
                } else if (customFieldId in duplicateIDMapping.roleCustomFieldIds) {
                    return duplicateIDMapping.roleCustomFieldIds[customFieldId];
                } else if (customFieldId in duplicateIDMapping.memberTypeCustomFieldIds) {
                    return duplicateIDMapping.memberTypeCustomFieldIds[customFieldId];
                } else if (customFieldId in duplicateIDMapping.groupTypeCustomFieldIds) {
                    return duplicateIDMapping.groupTypeCustomFieldIds[customFieldId];
                } else if (customFieldId in duplicateIDMapping.levelCustomFieldIds) {
                    return duplicateIDMapping.levelCustomFieldIds[customFieldId];
                } else {
                    throw Error('Unknown custom field ID')
                }
            });
        }
    }

    if ('choiceVariable' in newPieceData) {
        if (typeof newPieceData.choiceVariable === 'string' && isUUID(newPieceData.choiceVariable)) {
            newPieceData.choiceVariable =  duplicateIDMapping.variableIds[newPieceData.choiceVariable];
        }
    }

    if ('workflowType' in newPieceData) {
        if (typeof newPieceData.workflowType === 'string' && isUUID(newPieceData.workflowType)) {
            newPieceData.workflowType =  duplicateIDMapping.workflowTypeIds[newPieceData.workflowType];
        }
    }

    if ('workflowStatus' in newPieceData) {
        if (typeof newPieceData.workflowStatus === 'string' && isUUID(newPieceData.workflowStatus)) {
            newPieceData.workflowStatus =  duplicateIDMapping.workflowStatusIds[newPieceData.workflowStatus];
        }
    }

    if ('nesting' in newPieceData) {
        const newNesting: Array<NestingData> = [];

        if (newPieceData.nesting) {
            for (const nestingEntry of newPieceData.nesting) {
                if (isUUID(nestingEntry.value)) {
                    if (nestingEntry.value in duplicateIDMapping.memberTypeIds) {
                        newNesting.push({
                            ...nestingEntry,
                            value: duplicateIDMapping.memberTypeIds[nestingEntry.value],
                        });
                    } else if (nestingEntry.value in duplicateIDMapping.groupTypeIds) {
                        newNesting.push({
                            ...nestingEntry,
                            value: duplicateIDMapping.groupTypeIds[nestingEntry.value],
                        });
                    } else if (nestingEntry.value in duplicateIDMapping.workflowTypeIds) {
                        newNesting.push({
                            ...nestingEntry,
                            value: duplicateIDMapping.workflowTypeIds[nestingEntry.value],
                        });
                    }
                } else {
                    newNesting.push(nestingEntry);
                }
            }
        }
        
        newPieceData.nesting = newNesting;
    }

    // Duplicate any pieces that are connected to this piece

    if ('nextPiece' in newPieceData) {
        if (typeof newPieceData.nextPiece === 'string') {
            newPieceData.nextPiece =  duplicatePieceShortHand(newPieceData.nextPiece);
        }
    }

    if ('innerPiece' in newPieceData) {
        if (typeof newPieceData.innerPiece === 'string') {
            newPieceData.innerPiece =  duplicatePieceShortHand(newPieceData.innerPiece);
        }
    }

    if (newPieceData.type === PieceType.SPLIT) {
        const newIfData: Array<IfData> | undefined = JSON.parse(JSON.stringify(newPieceData.ifPieceData));

        if (typeof newIfData !== 'undefined') {
            for (let ifDatum of newIfData) {
                if (typeof ifDatum.conditionPiece === 'string') {
                    ifDatum.conditionPiece =  duplicatePieceShortHand(ifDatum.conditionPiece);
                }
                
                if (typeof ifDatum.nextPiece === 'string') {
                    ifDatum.nextPiece =  duplicatePieceShortHand(ifDatum.nextPiece);
                }
            }

            newPieceData.ifPieceData = newIfData;
        }
    }

    if ('iterableVariable' in newPieceData) {
        if (typeof newPieceData.iterableVariable === 'string' && isUUID(newPieceData.iterableVariable)) {
            newPieceData.iterableVariable =  duplicatePieceShortHand(newPieceData.iterableVariable);
        }
    }

    if ('operand' in newPieceData) {
        if (typeof newPieceData.operand === 'string' && isUUID(newPieceData.operand)) {
            newPieceData.operand =  duplicatePieceShortHand(newPieceData.operand);
        }
    }

    if ('leftOperand' in newPieceData) {
        if (typeof newPieceData.leftOperand === 'string' && isUUID(newPieceData.leftOperand)) {
            newPieceData.leftOperand =  duplicatePieceShortHand(newPieceData.leftOperand);
        }
    }

    if ('rightOperand' in newPieceData) {
        if (typeof newPieceData.rightOperand === 'string' && isUUID(newPieceData.rightOperand)) {
            newPieceData.rightOperand =  duplicatePieceShortHand(newPieceData.rightOperand);
        }
    }

    if ('heading' in newPieceData) {
        if (typeof newPieceData.heading === 'string' && isUUID(newPieceData.heading)) {
            newPieceData.heading =  duplicatePieceShortHand(newPieceData.heading);
        }
    }

    if ('question' in newPieceData) {
        if (typeof newPieceData.question === 'string' && isUUID(newPieceData.question)) {
            newPieceData.question =  duplicatePieceShortHand(newPieceData.question);
        }
    }

    if ('isRequiredPiece' in newPieceData) {
        if (typeof newPieceData.isRequiredPiece === 'string' && isUUID(newPieceData.isRequiredPiece)) {
            newPieceData.isRequiredPiece =  duplicatePieceShortHand(newPieceData.isRequiredPiece);
        }
    }

    if ('isHiddenPiece' in newPieceData) {
        if (typeof newPieceData.isHiddenPiece === 'string' && isUUID(newPieceData.isHiddenPiece)) {
            newPieceData.isHiddenPiece =  duplicatePieceShortHand(newPieceData.isHiddenPiece);
        }
    }

    if ('isDisabledPiece' in newPieceData) {
        if (typeof newPieceData.isDisabledPiece === 'string' && isUUID(newPieceData.isDisabledPiece)) {
            newPieceData.isDisabledPiece =  duplicatePieceShortHand(newPieceData.isDisabledPiece);
        }
    }

    if ('default' in newPieceData) {
        if (typeof newPieceData.default === 'string' && isUUID(newPieceData.default)) {
            newPieceData.default =  duplicatePieceShortHand(newPieceData.default);
        }
    }

    if ('yearVariablePiece' in newPieceData) {
        if (typeof newPieceData.yearVariablePiece === 'string' && isUUID(newPieceData.yearVariablePiece)) {
            newPieceData.yearVariablePiece =  duplicatePieceShortHand(newPieceData.yearVariablePiece);
        }
    }

    if ('monthVariablePiece' in newPieceData) {
        if (typeof newPieceData.monthVariablePiece === 'string' && isUUID(newPieceData.monthVariablePiece)) {
            newPieceData.monthVariablePiece =  duplicatePieceShortHand(newPieceData.monthVariablePiece);
        }
    }

    if ('dateVariablePiece' in newPieceData) {
        if (typeof newPieceData.dateVariablePiece === 'string' && isUUID(newPieceData.dateVariablePiece)) {
            newPieceData.dateVariablePiece =  duplicatePieceShortHand(newPieceData.dateVariablePiece);
        }
    }

    if ('memberVariablePiece' in newPieceData) {
        if (typeof newPieceData.memberVariablePiece === 'string' && isUUID(newPieceData.memberVariablePiece)) {
            newPieceData.memberVariablePiece =  duplicatePieceShortHand(newPieceData.memberVariablePiece);
        }
    }

    if ('dataToStore' in newPieceData) {
        if (typeof newPieceData.dataToStore === 'string' && isUUID(newPieceData.dataToStore)) {
            newPieceData.dataToStore =  duplicatePieceShortHand(newPieceData.dataToStore);
        }
    }

    if ('dataToSet' in newPieceData) {
        if (typeof newPieceData.dataToSet === 'string' && isUUID(newPieceData.dataToSet)) {
            newPieceData.dataToSet =  duplicatePieceShortHand(newPieceData.dataToSet);
        }
    }

    if ('returnValue' in newPieceData) {
        if (typeof newPieceData.returnValue === 'string' && isUUID(newPieceData.returnValue)) {
            newPieceData.returnValue =  duplicatePieceShortHand(newPieceData.returnValue);
        }
    }

    if ('variableToShow' in newPieceData) {
        if (typeof newPieceData.variableToShow === 'string' && isUUID(newPieceData.variableToShow)) {
            newPieceData.variableToShow =  duplicatePieceShortHand(newPieceData.variableToShow);
        }
    }

    if ('variablePiece' in newPieceData) {
        if (typeof newPieceData.variablePiece === 'string' && isUUID(newPieceData.variablePiece)) {
            newPieceData.variablePiece =  duplicatePieceShortHand(newPieceData.variablePiece);
        }
    }

    if ('workflowDueDateVariable' in newPieceData) {
        if (typeof newPieceData.workflowDueDateVariable === 'string' && isUUID(newPieceData.workflowDueDateVariable)) {
            newPieceData.workflowDueDateVariable =  duplicatePieceShortHand(newPieceData.workflowDueDateVariable);
        }
    }

    if ('affiliationVariable' in newPieceData) {
        if (typeof newPieceData.affiliationVariable === 'string' && isUUID(newPieceData.affiliationVariable)) {
            newPieceData.affiliationVariable =  duplicatePieceShortHand(newPieceData.affiliationVariable);
        }
    }

    if ('dueDate' in newPieceData) {
        if (typeof newPieceData.dueDate === 'string' && isUUID(newPieceData.dueDate)) {
            newPieceData.dueDate =  duplicatePieceShortHand(newPieceData.dueDate);
        }
    }

    if ('locationPiece' in newPieceData) {
        if (typeof newPieceData.locationPiece === 'string' && isUUID(newPieceData.locationPiece)) {
            newPieceData.locationPiece =  duplicatePieceShortHand(newPieceData.locationPiece);
        }
    }

    if ('message' in newPieceData) {
        if (typeof newPieceData.message === 'string' && isUUID(newPieceData.message)) {
            newPieceData.message =  duplicatePieceShortHand(newPieceData.message);
        }
    }

    if ('name' in newPieceData) {
        if (typeof newPieceData.name === 'string' && isUUID(newPieceData.name)) {
            newPieceData.name =  duplicatePieceShortHand(newPieceData.name);
        }
    }

    if ('user' in newPieceData) {
        if (typeof newPieceData.user === 'string' && isUUID(newPieceData.user)) {
            newPieceData.user =  duplicatePieceShortHand(newPieceData.user);
        }
    }

    if ('startDate' in newPieceData) {
        if (typeof newPieceData.startDate === 'string' && isUUID(newPieceData.startDate)) {
            newPieceData.startDate =  duplicatePieceShortHand(newPieceData.startDate);
        }
    }

    if ('endDate' in newPieceData) {
        if (typeof newPieceData.endDate === 'string' && isUUID(newPieceData.endDate)) {
            newPieceData.endDate =  duplicatePieceShortHand(newPieceData.endDate);
        }
    }

    addFullPiece(newPieceData);

    return newId;
}

export function importPiece(piecesData: NormalizedModel<AllPieceTypes>, importIDMapping: WorkflowImportIdMap, addFullPiece: (pieceData: AllPieceTypes) => void, pieceId: string) {
    const pieceToCopy = piecesData.byId[pieceId];
    const newId = uuid.v4();

    const importPieceShortHand = importPiece.bind({}, piecesData, importIDMapping, addFullPiece);

    const newPieceData: AllPieceTypes = {
        ...pieceToCopy,
        id: newId,
    };

    const getIdFromMappingIfExists = (id: string, mapping: DuplicationIDMappng) => {
        return id in mapping ? mapping[id] : id;
    }

    const getVariableIdFromMapping = (variableId: string) => {
        return getIdFromMappingIfExists(variableId, importIDMapping.variableIds);
    }

    // Replace the types, custom fields, etc.

    if ('loopVariable' in newPieceData) {
        if (typeof newPieceData.loopVariable === 'string' && isUUID(newPieceData.loopVariable)) {
            newPieceData.loopVariable =  getVariableIdFromMapping(newPieceData.loopVariable);
        }
    }

    if ('listVariable' in newPieceData) {
        if (typeof newPieceData.listVariable === 'string' && isUUID(newPieceData.listVariable)) {
            newPieceData.listVariable = getVariableIdFromMapping(newPieceData.listVariable);
        }
    }

    if ('status' in newPieceData) {
        if (typeof newPieceData.status === 'string' && isUUID(newPieceData.status)) {
            newPieceData.status =  getIdFromMappingIfExists(newPieceData.status, importIDMapping.workflowStatusIds);
        }
    }

    if ('workflow' in newPieceData) {
        if (typeof newPieceData.workflow === 'string' && isUUID(newPieceData.workflow)) {
            newPieceData.workflow =  getVariableIdFromMapping(newPieceData.workflow);
        }
    }

    if ('statusId' in newPieceData) {
        if (typeof newPieceData.statusId === 'string' && isUUID(newPieceData.statusId)) {
            newPieceData.statusId =  getIdFromMappingIfExists(newPieceData.statusId, importIDMapping.workflowStatusIds);
        }
    }

    if ('variable' in newPieceData) {
        if (typeof newPieceData.variable === 'string' && isUUID(newPieceData.variable)) {
            newPieceData.variable =  getVariableIdFromMapping(newPieceData.variable);
        }
    }

    if ('variableToSet' in newPieceData) {
        if (typeof newPieceData.variableToSet === 'string' && isUUID(newPieceData.variableToSet)) {
            newPieceData.variableToSet =  getVariableIdFromMapping(newPieceData.variableToSet);
        }
    }

    if ('customField' in newPieceData) {
        if (typeof newPieceData.customField === 'string' && isUUID(newPieceData.customField)) {
            newPieceData.customField =  getIdFromMappingIfExists(newPieceData.customField, importIDMapping.workflowTypeCustomFieldIds);
        }
    }

    if ('customFieldOption' in newPieceData) {
        if (typeof newPieceData.customFieldOption === 'string' && isUUID(newPieceData.customFieldOption)) {
            newPieceData.customFieldOption =  getIdFromMappingIfExists(newPieceData.customFieldOption, importIDMapping.workflowTypeCustomFieldOptionIds);
        }
    }

    if ('variableToCopy' in newPieceData) {
        if (typeof newPieceData.variableToCopy === 'string' && isUUID(newPieceData.variableToCopy)) {
            newPieceData.variableToCopy =  importIDMapping.variableIds[newPieceData.variableToCopy];
        }
    }

    if ('entityType' in newPieceData && typeof newPieceData.entityType !== 'undefined') {
        if (newPieceData.entityType in importIDMapping.affiliationTypeIds) {
            newPieceData.entityType = importIDMapping.affiliationTypeIds[newPieceData.entityType];
        } else if (newPieceData.entityType in importIDMapping.workflowTypeIds) {
            newPieceData.entityType = importIDMapping.workflowTypeIds[newPieceData.entityType];
        }
    }

    if ('customFieldId' in newPieceData) {
        if (typeof newPieceData.customFieldId === 'string' && isUUID(newPieceData.customFieldId)) {
            if (newPieceData.customFieldId in importIDMapping.workflowTypeCustomFieldIds) {
                newPieceData.customFieldId = importIDMapping.workflowTypeCustomFieldIds[newPieceData.customFieldId];
            } else if (newPieceData.customFieldId in importIDMapping.affiliationTypeCustomFieldIds) {
                newPieceData.customFieldId = importIDMapping.affiliationTypeCustomFieldIds[newPieceData.customFieldId];
            }
        }
    }

    if ('customFieldIds' in newPieceData) {
        if (newPieceData.customFieldIds) {
            newPieceData.customFieldIds = newPieceData.customFieldIds
            .filter(customFieldId => {
                if (customFieldId in importIDMapping.workflowTypeCustomFieldIds) {
                    return true;
                } else if (customFieldId in importIDMapping.affiliationTypeCustomFieldIds) {
                    return true;
                } else {
                    return false;
                }
            })
            .map(customFieldId => {
                if (customFieldId in importIDMapping.workflowTypeCustomFieldIds) {
                    return importIDMapping.workflowTypeCustomFieldIds[customFieldId];
                } else if (customFieldId in importIDMapping.affiliationTypeCustomFieldIds) {
                    return importIDMapping.affiliationTypeCustomFieldIds[customFieldId];
                } else {
                    throw Error('Unknown custom field ID')
                }
            });
        }
    }

    if ('choiceVariable' in newPieceData) {
        if (typeof newPieceData.choiceVariable === 'string' && isUUID(newPieceData.choiceVariable)) {
            newPieceData.choiceVariable =  getVariableIdFromMapping(newPieceData.choiceVariable);
        }
    }

    if ('workflowType' in newPieceData) {
        if (typeof newPieceData.workflowType === 'string' && isUUID(newPieceData.workflowType)) {
            newPieceData.workflowType =  getIdFromMappingIfExists(newPieceData.workflowType, importIDMapping.workflowTypeIds);
        }
    }

    if ('workflowStatus' in newPieceData) {
        if (typeof newPieceData.workflowStatus === 'string' && isUUID(newPieceData.workflowStatus)) {
            newPieceData.workflowStatus =  getIdFromMappingIfExists(newPieceData.workflowStatus, importIDMapping.workflowStatusIds);
        }
    }

    if ('nesting' in newPieceData) {
        const newNesting: Array<NestingData> = [];

        if (newPieceData.nesting) {
            for (const nestingEntry of newPieceData.nesting) {
                if (isUUID(nestingEntry.value)) {
                    if (nestingEntry.value in importIDMapping.affiliationTypeIds) {
                        newNesting.push({
                            ...nestingEntry,
                            value: importIDMapping.affiliationTypeIds[nestingEntry.value],
                        });
                    } else if (nestingEntry.value in importIDMapping.workflowTypeIds) {
                        newNesting.push({
                            ...nestingEntry,
                            value: importIDMapping.workflowTypeIds[nestingEntry.value],
                        });
                    } else {
                        newNesting.push({
                            ...nestingEntry,
                        });
                    }
                } else {
                    newNesting.push(nestingEntry);
                }
            }
        }
        
        newPieceData.nesting = newNesting;
    }

    // Duplicate any pieces that are connected to this piece

    if ('nextPiece' in newPieceData) {
        if (typeof newPieceData.nextPiece === 'string') {
            newPieceData.nextPiece =  importPieceShortHand(newPieceData.nextPiece);
        }
    }

    if ('innerPiece' in newPieceData) {
        if (typeof newPieceData.innerPiece === 'string') {
            newPieceData.innerPiece =  importPieceShortHand(newPieceData.innerPiece);
        }
    }

    if (newPieceData.type === PieceType.SPLIT) {
        const newIfData: Array<IfData> | undefined = JSON.parse(JSON.stringify(newPieceData.ifPieceData));

        if (typeof newIfData !== 'undefined') {
            for (let ifDatum of newIfData) {
                if (typeof ifDatum.conditionPiece === 'string') {
                    ifDatum.conditionPiece =  importPieceShortHand(ifDatum.conditionPiece);
                }
                
                if (typeof ifDatum.nextPiece === 'string') {
                    ifDatum.nextPiece =  importPieceShortHand(ifDatum.nextPiece);
                }
            }

            newPieceData.ifPieceData = newIfData;
        }
    }

    if ('iterableVariable' in newPieceData) {
        if (typeof newPieceData.iterableVariable === 'string' && isUUID(newPieceData.iterableVariable)) {
            newPieceData.iterableVariable =  importPieceShortHand(newPieceData.iterableVariable);
        }
    }

    if ('operand' in newPieceData) {
        if (typeof newPieceData.operand === 'string' && isUUID(newPieceData.operand)) {
            newPieceData.operand =  importPieceShortHand(newPieceData.operand);
        }
    }

    if ('leftOperand' in newPieceData) {
        if (typeof newPieceData.leftOperand === 'string' && isUUID(newPieceData.leftOperand)) {
            newPieceData.leftOperand =  importPieceShortHand(newPieceData.leftOperand);
        }
    }

    if ('rightOperand' in newPieceData) {
        if (typeof newPieceData.rightOperand === 'string' && isUUID(newPieceData.rightOperand)) {
            newPieceData.rightOperand =  importPieceShortHand(newPieceData.rightOperand);
        }
    }

    if ('heading' in newPieceData) {
        if (typeof newPieceData.heading === 'string' && isUUID(newPieceData.heading)) {
            newPieceData.heading =  importPieceShortHand(newPieceData.heading);
        }
    }

    if ('question' in newPieceData) {
        if (typeof newPieceData.question === 'string' && isUUID(newPieceData.question)) {
            newPieceData.question =  importPieceShortHand(newPieceData.question);
        }
    }

    if ('isRequiredPiece' in newPieceData) {
        if (typeof newPieceData.isRequiredPiece === 'string' && isUUID(newPieceData.isRequiredPiece)) {
            newPieceData.isRequiredPiece =  importPieceShortHand(newPieceData.isRequiredPiece);
        }
    }

    if ('isHiddenPiece' in newPieceData) {
        if (typeof newPieceData.isHiddenPiece === 'string' && isUUID(newPieceData.isHiddenPiece)) {
            newPieceData.isHiddenPiece =  importPieceShortHand(newPieceData.isHiddenPiece);
        }
    }

    if ('isDisabledPiece' in newPieceData) {
        if (typeof newPieceData.isDisabledPiece === 'string' && isUUID(newPieceData.isDisabledPiece)) {
            newPieceData.isDisabledPiece =  importPieceShortHand(newPieceData.isDisabledPiece);
        }
    }

    if ('default' in newPieceData) {
        if (typeof newPieceData.default === 'string' && isUUID(newPieceData.default)) {
            newPieceData.default =  importPieceShortHand(newPieceData.default);
        }
    }

    if ('yearVariablePiece' in newPieceData) {
        if (typeof newPieceData.yearVariablePiece === 'string' && isUUID(newPieceData.yearVariablePiece)) {
            newPieceData.yearVariablePiece =  importPieceShortHand(newPieceData.yearVariablePiece);
        }
    }

    if ('monthVariablePiece' in newPieceData) {
        if (typeof newPieceData.monthVariablePiece === 'string' && isUUID(newPieceData.monthVariablePiece)) {
            newPieceData.monthVariablePiece =  importPieceShortHand(newPieceData.monthVariablePiece);
        }
    }

    if ('dateVariablePiece' in newPieceData) {
        if (typeof newPieceData.dateVariablePiece === 'string' && isUUID(newPieceData.dateVariablePiece)) {
            newPieceData.dateVariablePiece =  importPieceShortHand(newPieceData.dateVariablePiece);
        }
    }

    if ('memberVariablePiece' in newPieceData) {
        if (typeof newPieceData.memberVariablePiece === 'string' && isUUID(newPieceData.memberVariablePiece)) {
            newPieceData.memberVariablePiece =  importPieceShortHand(newPieceData.memberVariablePiece);
        }
    }

    if ('dataToStore' in newPieceData) {
        if (typeof newPieceData.dataToStore === 'string' && isUUID(newPieceData.dataToStore)) {
            newPieceData.dataToStore =  importPieceShortHand(newPieceData.dataToStore);
        }
    }

    if ('dataToSet' in newPieceData) {
        if (typeof newPieceData.dataToSet === 'string' && isUUID(newPieceData.dataToSet)) {
            newPieceData.dataToSet =  importPieceShortHand(newPieceData.dataToSet);
        }
    }

    if ('returnValue' in newPieceData) {
        if (typeof newPieceData.returnValue === 'string' && isUUID(newPieceData.returnValue)) {
            newPieceData.returnValue =  importPieceShortHand(newPieceData.returnValue);
        }
    }

    if ('variableToShow' in newPieceData) {
        if (typeof newPieceData.variableToShow === 'string' && isUUID(newPieceData.variableToShow)) {
            newPieceData.variableToShow =  importPieceShortHand(newPieceData.variableToShow);
        }
    }

    if ('variablePiece' in newPieceData) {
        if (typeof newPieceData.variablePiece === 'string' && isUUID(newPieceData.variablePiece)) {
            newPieceData.variablePiece =  importPieceShortHand(newPieceData.variablePiece);
        }
    }

    if ('workflowDueDateVariable' in newPieceData) {
        if (typeof newPieceData.workflowDueDateVariable === 'string' && isUUID(newPieceData.workflowDueDateVariable)) {
            newPieceData.workflowDueDateVariable =  importPieceShortHand(newPieceData.workflowDueDateVariable);
        }
    }

    if ('affiliationVariable' in newPieceData) {
        if (typeof newPieceData.affiliationVariable === 'string' && isUUID(newPieceData.affiliationVariable)) {
            newPieceData.affiliationVariable =  importPieceShortHand(newPieceData.affiliationVariable);
        }
    }

    if ('dueDate' in newPieceData) {
        if (typeof newPieceData.dueDate === 'string' && isUUID(newPieceData.dueDate)) {
            newPieceData.dueDate =  importPieceShortHand(newPieceData.dueDate);
        }
    }

    if ('locationPiece' in newPieceData) {
        if (typeof newPieceData.locationPiece === 'string' && isUUID(newPieceData.locationPiece)) {
            newPieceData.locationPiece =  importPieceShortHand(newPieceData.locationPiece);
        }
    }

    if ('message' in newPieceData) {
        if (typeof newPieceData.message === 'string' && isUUID(newPieceData.message)) {
            newPieceData.message =  importPieceShortHand(newPieceData.message);
        }
    }

    if ('name' in newPieceData) {
        if (typeof newPieceData.name === 'string' && isUUID(newPieceData.name)) {
            newPieceData.name =  importPieceShortHand(newPieceData.name);
        }
    }

    if ('user' in newPieceData) {
        if (typeof newPieceData.user === 'string' && isUUID(newPieceData.user)) {
            newPieceData.user =  importPieceShortHand(newPieceData.user);
        }
    }

    if ('startDate' in newPieceData) {
        if (typeof newPieceData.startDate === 'string' && isUUID(newPieceData.startDate)) {
            newPieceData.startDate =  importPieceShortHand(newPieceData.startDate);
        }
    }

    if ('endDate' in newPieceData) {
        if (typeof newPieceData.endDate === 'string' && isUUID(newPieceData.endDate)) {
            newPieceData.endDate =  importPieceShortHand(newPieceData.endDate);
        }
    }

    addFullPiece(newPieceData);

    return newId;
}