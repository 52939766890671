import React from 'react';
import { translatePhrase } from '../../../shared/helpers/translation';
import { completionPercentageOfWorkflow } from '../../../shared/store/flowchart/helpers/progress';
import styles from './Process.module.scss';

type OwnProps = {
    workflowId: string,
    isComplete?: boolean,
};

const ProgressBar: React.FC<OwnProps> = (props) => {

    let completionPercentage = 0;

    try {
        completionPercentage = completionPercentageOfWorkflow(props.workflowId);

        if (!props.isComplete && completionPercentage > 96) {
            completionPercentage = 96;
        }

        completionPercentage = Math.round(completionPercentage / 5) * 5;
    } catch (e) {
        console.error(e);
    }

    return <div className={styles.progressHolder}>
        <div className={styles.progressBar}>
            <span className={styles.progress} style={{ width: completionPercentage + '%' }}> </span>
        </div>
        <div className={styles.text}> {completionPercentage.toPrecision(3)}% {translatePhrase('Finished')} </div>
    </div>
}

export default ProgressBar;