import React, { Component } from 'react';

import { connect } from 'react-redux';

import { PieceType } from '../../../shared/store/flowchart/pieces/types';
import { WorkflowProcessState } from '../../../shared/store/workflows/types';
import { ApplicationState } from '../../../shared/store/types';
import { isUUID } from '../../../shared/helpers/utilities';
import { getWorkflowPieceValue } from '../../../shared/store/flowchart/helpers/workflow';

type OwnProps = {
    workflowId: string;
    continuePieceId: string;
};

const mapStateToProps = (state: ApplicationState, ownProps: OwnProps) => {

    const continuePiece = state.flowchart.pieces.byId[ownProps.continuePieceId];

    if (continuePiece.type !== PieceType.CONTINUE) {
        throw new Error('This must be a continue piece');
    }

    let message = continuePiece.message;

    if (typeof continuePiece.message === 'string' && isUUID(continuePiece.message)) {

        const workflow = state.workflows.byId[ownProps.workflowId];
        const workflowProcessState = workflow.history[workflow.historyIndex];
    
        const processState: WorkflowProcessState = JSON.parse(JSON.stringify({
            customFields: workflowProcessState.customFields,
            lastComputedPiece: workflowProcessState.displayingShowPieceId,
            executionStack: workflowProcessState.executionStack,
            forIterationCounts: workflowProcessState.forIterationCounts,
            variables: workflowProcessState.variables,
            displayingQuestionPieceId: workflowProcessState.displayingQuestionPieceId,
            displayingShowPieceId: workflowProcessState.displayingShowPieceId,
            displayingGroupPieceId: workflowProcessState.displayingGroupPieceId,
            displayingTransferPieceId: workflowProcessState.displayingTransferPieceId,
            createdWorkflowId: workflowProcessState.createdWorkflowId,
        }));

        const messageValue = getWorkflowPieceValue(state, processState, ownProps.workflowId, continuePiece.message);
        message = String(messageValue);
    }


    return {
        messageText: message,
    }
}

type StateProps = ReturnType<typeof mapStateToProps>;

type Props = OwnProps & StateProps;

class ConnectedContinue extends Component<Props> {
    render() {
        return <div>{this.props.messageText}</div>
    }
}

const Continue = connect(mapStateToProps)(ConnectedContinue);

export default Continue;