import React, { Component } from 'react';
import styles from './StepPiece.module.scss';
import FlowchartPiece, { OwnProps as FlowchartPieceProps } from '../FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setNextPiece } from '../../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../../shared/store/types';
import { FlowchartContext, PieceHighlightColour } from '../../../../contexts/flowchart-context';
import { nextPieceTarget } from '../utilities';
import { PieceType } from '../../../../shared/store/flowchart/pieces/types';
import { clearErrorMessage, setErrorMessage } from '../../../../shared/store/my-data/actions';
import { translatePhrase } from '../../../../shared/helpers/translation';


type StepPieceEssentialsProps = {
    hasUpperLock?: boolean,
    hasLowerLock?: boolean,
    nextPiece?: JSX.Element,
    theme?: 'indigo' | 'pink' | 'aqua' | 'camo' | 'red',

    handleHoverOverNextPiece: () => void,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        pieceDate: state.flowchart.pieces,
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string | undefined) => dispatch(setTargetPiece(pieceId)),
        setNextPiece: (targetPieceId: string, draggedPieceId: string) => dispatch(setNextPiece(targetPieceId, draggedPieceId)),
        setErrorMessage: (message: string) => dispatch(setErrorMessage(message, true)),
        clearErrorMessage: () => dispatch(clearErrorMessage()),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = StepPieceEssentialsProps & StateProps & DispatchProps & FlowchartPieceProps;

type StepPieceEssentialsState = {
    isHoveringOverNextPiece: boolean,
}

class ConnectedStepPieceEssentials extends Component<Props, StepPieceEssentialsState> {

    static defaultProps = {
        hasUpperLock: true,
        hasLowerLock: true,
    };

    state = {
        isHoveringOverNextPiece: false,
    };

    handleHoverOverNextPiece = () => {
        this.setState({
            isHoveringOverNextPiece: true,
        });

        this.props.handleHoverOverNextPiece();
    };

    handleHoverOutOfNextPiece = () => {
        this.setState({
            isHoveringOverNextPiece: false,
        });
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && this.state.isHoveringOverNextPiece) {

            if (this.props.nextPiece) {
                const currentPiece = this.props.pieceDate.byId[this.props.pieceId];

                let followingPiece = this.props.lastDraggedPiece;

                while ('nextPiece' in followingPiece && !!followingPiece.nextPiece) {
                    followingPiece = this.props.pieceDate.byId[followingPiece.nextPiece];
                }

                if ('nextPiece' in currentPiece && currentPiece.nextPiece) {
                    if (followingPiece.type === PieceType.END || followingPiece.type === PieceType.RETURN ||
                        followingPiece.type === PieceType.SUCCESS || followingPiece.type === PieceType.ERROR) {
                        this.props.setErrorMessage(translatePhrase('Please remove the last piece and continue'));
                        setTimeout(() => {
                            this.props.clearErrorMessage();
                        }, 4000);

                        this.setState({
                            isHoveringOverNextPiece: false,
                        });
                        return;
                    }
                    this.props.setNextPiece(followingPiece.id, currentPiece.nextPiece);
                }
            }

            this.props.setNextPiece(this.props.pieceId, this.props.lastDraggedPiece.id);

            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverNextPiece: false,
            });
        }
    }

    render() {
        return <FlowchartContext.Consumer>
            {
                (flowchartContext) => {
                    let themeClass: string;

                    switch (this.props.theme) {
                        case 'indigo':
                            themeClass = styles.indigoStepPiece;
                            break;
                        case 'pink':
                            themeClass = styles.pinkStepPiece;
                            break;
                        case 'aqua':
                            themeClass = styles.aquaStepPiece;
                            break;
                        case 'camo':
                            themeClass = styles.camoStepPiece;
                            break;
                        case 'red':
                            themeClass = styles.redStepPiece;
                            break;
                        default:
                            themeClass = styles.indigoStepPiece;
                            break;
                    }

                    const highlightColor = flowchartContext.highlights && flowchartContext.highlights[this.props.pieceId];
                    let highlightClass = styles.noHighlight;

                    switch (highlightColor) {
                        case PieceHighlightColour.GREEN:
                            highlightClass = styles.addedHighlight;
                            break;
                        case PieceHighlightColour.YELLOW:
                            highlightClass = styles.updatedHighlight;
                            break;
                        case PieceHighlightColour.PURPLE:
                            highlightClass = styles.movedHighlight;
                            break;
                        case PieceHighlightColour.RED:
                            highlightClass = styles.deletedHighlight;
                            break;
                    }

                    return <FlowchartPiece {...this.props}>
                        <div className={highlightClass}>
                            <section className={themeClass}>
                                <div className={this.props.hasUpperLock ? styles.upperPieceLock : styles.filledUpperPieceLock}></div>
                                <div className={styles.spaceBetweenLocks}></div>
                                <div className={this.props.hasLowerLock ? styles.lowerPieceLock : styles.filledLowerPieceLock}></div>
                                <div className={styles.extendableUpperArm}></div>
                                <div className={styles.visibleItems}>
                                    {this.props.children}
                                </div>
                                {this.props.hasLowerLock && <div className={this.state.isHoveringOverNextPiece && this.props.isDragging && this.props.targetPiece ? styles.nextPieceHovering : styles.nextPieceHoverable} onMouseOver={this.handleHoverOverNextPiece} onMouseOut={this.handleHoverOutOfNextPiece}></div>}
                            </section>
                        </div>
                        {this.props.nextPiece}
                    </FlowchartPiece>
                }
            }
        </FlowchartContext.Consumer>
    }
}

const StepPieceEssentials = connect(mapStateToProps, mapDispatchToProps)(ConnectedStepPieceEssentials);

export default StepPieceEssentials;