import React, { Component } from 'react';
import styles from './StopPiece.module.scss';
import SelectBox from '../drop-down/SelectBox';
import DropDownList from '../drop-down/DropDownList';
import ListItem from '../drop-down/ListItem';
import FlowchartPiece, { OwnProps as FlowchartPieceProps } from './FlowchartPiece';
import { Option } from '../drop-down/ListItem';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setUpdateStatusValue, setTargetPiece, setMessage, setWorkflow } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import Input from '../Input';
import { valuePieceSlotTarget } from './utilities';
import { FlowchartContext, FlowchartInfoForPiece, PieceHighlightColour } from '../../../contexts/flowchart-context';
import DropDownSearchBox from '../drop-down/DropDownSearchBox';
import { getFilteredOptionsBySearch } from '../drop-down/DropDownSearchBox';


type StopPieceProps = {
    nextPiece?: JSX.Element,
    terminalStates: Array<Option>,
    stopStateId?: string,

    selectedWorkflow: string | undefined,
    workflowVariables: Array<Option>,

    messageText?: string,
    messageVariablePiece?: JSX.Element,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        pieceData: state.flowchart.pieces,
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,
        workflowStatusesData: state.workflows.types.statuses,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string | undefined) => dispatch(setTargetPiece(pieceId)),
        setMessage: (pieceId: string, value: string | undefined) => dispatch(setMessage(pieceId, value)),
        setUpdateStatusValue: (pieceId: string, value: string) => dispatch(setUpdateStatusValue(pieceId, value)),
        setWorkflow: (pieceId: string, value: string) => dispatch(setWorkflow(pieceId, value)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = StopPieceProps & StateProps & DispatchProps & FlowchartPieceProps;

type StopPieceState = {
    isHoveringOverMessagePiece: boolean,
    statusSearchTerm: string,
    workflowSearchTerm: string,
}

class ConnectedStopPiece extends Component<Props, StopPieceState> {

    constructor(props: Props) {
        super(props);
        this.state = {
            isHoveringOverMessagePiece: false,
            statusSearchTerm: "",
            workflowSearchTerm: "",
        };
    }


    handleHoverOverMessagePiece = () => {
        this.setState({
            isHoveringOverMessagePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfMessagePiece = () => {
        this.setState({
            isHoveringOverMessagePiece: false,
        });
    };

    handleMessageValueUpdate = (value: string) => {
        this.props.setMessage(this.props.pieceId, value);
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && (this.state.isHoveringOverMessagePiece)) {

            this.props.setMessage(this.props.pieceId, this.props.lastDraggedPiece.id);
            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverMessagePiece: false,
            });
        }
    }

    updateValidity = (isValid: boolean, flowchartContext: FlowchartInfoForPiece) => {
        if (!isValid) {
            const invalidPieceInfo = flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId);

            if (!invalidPieceInfo && flowchartContext.setInvalidPiece) {
                flowchartContext.setInvalidPiece(this.props.pieceId, flowchartContext.parentSplitPieceIds);
            }
        }

        if (isValid && flowchartContext.removeInvalidPiece && flowchartContext.invalidPieces?.find(piece => this.props.pieceId === piece.pieceId)) {
            flowchartContext.removeInvalidPiece(this.props.pieceId);
        }
    }

    searchForStatus = (searchTerm: string) => {
        this.setState({ statusSearchTerm: searchTerm });
    }

    searchForWorkflows = (searchTerm: string) => {
        this.setState({ workflowSearchTerm: searchTerm });
    }

    render() {
        const { workflowSearchTerm, statusSearchTerm } = this.state;
        const { terminalStates, workflowVariables } = this.props;

        const newWorkflowVariables = workflowVariables.concat([{ name: "No workflow", value: "" }]);

        return <FlowchartContext.Consumer>
            {
                flowchartContext => {
                    const terminalStatesMarkup = getFilteredOptionsBySearch(terminalStates, statusSearchTerm).map(terminalState => <ListItem key={terminalState.name} theme="aqua" name={terminalState.name} value={terminalState.value} onClick={this.props.setUpdateStatusValue.bind(this, this.props.pieceId)} />);
                    const workflowOptions = getFilteredOptionsBySearch(newWorkflowVariables, workflowSearchTerm).map(workflowVariable => <ListItem key={workflowVariable.name} name={workflowVariable.name} value={workflowVariable.value} onClick={this.props.setWorkflow.bind(this, this.props.pieceId)} />);

                    let isValid = true;

                    let selectedWorkflowText = 'No Workflow';

                    if (this.props.selectedWorkflow) {
                        const workflowVariableOption = this.props.workflowVariables.find(option => option.value === this.props.selectedWorkflow);
                        isValid = !!workflowVariableOption;

                        if (workflowVariableOption) {
                            selectedWorkflowText = workflowVariableOption.name;
                        }
                    }

                    if (this.props.stopStateId) {
                        const isValidTerminalStatus = !!this.props.terminalStates.find(option => option.value === this.props.stopStateId);
                        if (!isValidTerminalStatus) {
                            isValid = false;
                        }
                    }

                    this.updateValidity(isValid, flowchartContext);

                    const selectedStatusName = this.props.stopStateId && this.props.stopStateId in this.props.workflowStatusesData.byId ? this.props.workflowStatusesData.byId[this.props.stopStateId].name : undefined;


                    const highlightColor = flowchartContext.highlights && flowchartContext.highlights[this.props.pieceId];
                    let highlightClass = styles.noHighlight;

                    switch (highlightColor) {
                        case PieceHighlightColour.GREEN:
                            highlightClass = styles.addedHighlight;
                            break;
                        case PieceHighlightColour.YELLOW:
                            highlightClass = styles.updatedHighlight;
                            break;
                        case PieceHighlightColour.PURPLE:
                            highlightClass = styles.movedHighlight;
                            break;
                        case PieceHighlightColour.RED:
                            highlightClass = styles.deletedHighlight;
                            break;
                    }

                    return (
                        <FlowchartPiece {...this.props}>
                            <section className={(isValid ? styles.stopPiece : styles.invalidStopPiece) + ' ' + highlightClass}>
                                <div className={styles.text}>End</div>
                                <SelectBox theme="dark-aqua" selectionPromptText={selectedStatusName ? selectedStatusName : "Select a status"}>
                                    <DropDownList theme="aqua" dismissAfterSelection={false}>
                                        <DropDownSearchBox
                                            handleSearchInputChange={this.searchForStatus}
                                            placeholder={"Search by name"}
                                            searchTerm={this.state.statusSearchTerm}
                                        />
                                        {terminalStatesMarkup}
                                    </DropDownList>
                                </SelectBox>
                                <div className={styles.text}>with message</div>
                                {this.props.messageVariablePiece ? this.props.messageVariablePiece : <Input canReceiveDrag={this.props.isDragging && this.state.isHoveringOverMessagePiece && !!this.props.targetPiece} onMouseOver={this.handleHoverOverMessagePiece} onMouseOut={this.handleHoverOutOfMessagePiece} onChange={this.handleMessageValueUpdate} placeholderText="Message" defaultText={this.props.messageText} minSize={10} />}
                                <div className={styles.text}>switch to</div>
                                <SelectBox selectionPromptText={selectedWorkflowText}>
                                    <DropDownList dismissAfterSelection={false}>
                                        <DropDownSearchBox
                                            handleSearchInputChange={this.searchForWorkflows}
                                            placeholder={"Search by name"}
                                            searchTerm={this.state.workflowSearchTerm}
                                        />
                                        {workflowOptions}
                                    </DropDownList>
                                </SelectBox>
                            </section>
                        </FlowchartPiece>
                    )
                }
            }
        </FlowchartContext.Consumer>
    }
}

const StopPiece = connect(mapStateToProps, mapDispatchToProps)(ConnectedStopPiece);

export default StopPiece;