import { PieceType } from "../../../shared/store/flowchart/pieces/types";

export function booleanPieceSlotTarget(pieceType: PieceType, targetCallback: (pieceId: string | undefined) => void, pieceId: string) {
    switch (pieceType) {
        case PieceType.AND:
        case PieceType.OR:
        case PieceType.NOT:
        case PieceType.LESSER_THAN:
        case PieceType.LESSER_THAN_OR_EQUAL_TO:
        case PieceType.GREATER_THAN:
        case PieceType.GREATER_THAN_OR_EQUAL_TO:
        case PieceType.EQUAL_TO:
        case PieceType.NOT_EQUAL_TO:
        case PieceType.IN:
        case PieceType.NOT_IN:
        case PieceType.VARIABLE_TO_BOOLEAN:
        case PieceType.IS_DEFINED:
        case PieceType.IS_NOT_DEFINED:
        case PieceType.HEXAGONAL_TRUE:
        case PieceType.HEXAGONAL_FALSE:
            targetCallback(pieceId);
            break;

        default:
            targetCallback(undefined)
            break;
    }
}

export function valuePieceSlotTarget(pieceType: PieceType, targetCallback: (pieceId: string | undefined) => void, pieceId: string) {
    switch (pieceType) {
        case PieceType.ADD:
        case PieceType.SUBTRACT:
        case PieceType.MULTIPLY:
        case PieceType.DIVIDE:
        case PieceType.EXPONENT:
        case PieceType.SEQUENCE:
        case PieceType.VARIABLE:
        case PieceType.CUSTOM_FIELD:
        case PieceType.ANSWER:
        case PieceType.GROUPED_ANSWER:
        case PieceType.STATUS:
        case PieceType.BOOLEAN_TO_VARIABLE:
        case PieceType.TODAY:
        case PieceType.NOW:
        case PieceType.TRUE:
        case PieceType.FALSE:
        case PieceType.LOGGED_IN_USER:
        case PieceType.MY_GROUPS:
        case PieceType.MY_MEMBERS:
        case PieceType.PICK_FIRST_ELEMENT:
        case PieceType.PICK_FIRST_N_ELEMENTS:
        case PieceType.PICK_LAST_ELEMENT:
        case PieceType.PICK_LAST_N_ELEMENTS:
        case PieceType.PICK_NTH_ELEMENT:
        case PieceType.SPLIT_BY_SEPARATOR:
        case PieceType.STRUCTURE:
        case PieceType.STATIC_DATA:
        case PieceType.LENGTH:
        case PieceType.TRANSLATE:
        case PieceType.ADD_MONTHS:
        case PieceType.ADD_YEARS:
        case PieceType.SUBTRACT_MONTHS:
        case PieceType.SUBTRACT_YEARS:
        case PieceType.GET_VALUE:
        case PieceType.GET_DATE:
        case PieceType.GET_DAY:
        case PieceType.GET_MONTH:
        case PieceType.GET_READABLE_MONTH:
        case PieceType.GET_YEAR:
        case PieceType.FORMAT:
        case PieceType.GET_TIME_DIFFERENCE:
        case PieceType.GET_AFFILIATION_FROM_WORKFLOW:
        case PieceType.GET_ENTITIES:
        case PieceType.FINANCIAL_YEAR_MONTHS:
            targetCallback(pieceId);
            break;

        default:
            targetCallback(undefined);
            break;
    }
}

export function nextPieceTarget(pieceType: PieceType, targetCallback: (pieceId: string | undefined) => void, pieceId: string) {
    switch (pieceType) {
        case PieceType.FOR:
        case PieceType.GET:
        case PieceType.QUESTION:
        case PieceType.CHOOSE:
        case PieceType.ERROR:
        case PieceType.SUCCESS:
        case PieceType.SET_VARIABLE:
        case PieceType.SPLIT:
        case PieceType.STORE:
        case PieceType.END:
        case PieceType.RETURN:
        case PieceType.RETURN_RICH_TEXT:
        case PieceType.SHOW:
        case PieceType.UPDATE_STATUS:
        case PieceType.UPDATE_DUE_DATE:
        case PieceType.START_WORKFLOW:
        case PieceType.SWITCH_WORKFLOW:
        case PieceType.TRANSFER_WORKFLOW:
        case PieceType.GROUP:
        case PieceType.GROUP_FOR_LIST:
        case PieceType.REPEAT:
        case PieceType.ADD_MEMBER:
        case PieceType.ADD_GROUP:
        case PieceType.ADD_WORKFLOW:
        case PieceType.ADD_REPORT:
        case PieceType.SET_MEMBERS_IN_GROUP:
        case PieceType.ADD_TO_LIST:
        case PieceType.REMOVE_FROM_LIST:
        case PieceType.ADD_TO_TABLE:
        case PieceType.NEW_DATE:
        case PieceType.RESTRICT_NAVIGATION:
        case PieceType.CONTINUE:
        case PieceType.GET_CURRENT_LOCATION:
        case PieceType.ARCHIVE:
        case PieceType.SET_LOCATION:
        case PieceType.STYLE_TABLE_SECTION:
        case PieceType.FINSAL_LOAN_PROCESS:
            targetCallback(pieceId);
            break;

        default:
            targetCallback(undefined);
            break;
    }
}

export function groupedNextPieceTarget(pieceType: PieceType, targetCallback: (pieceId: string | undefined) => void, pieceId: string) {
    switch (pieceType) {
        case PieceType.GROUPED_QUESTION:
        case PieceType.GROUPED_CHOOSE:
        case PieceType.GROUPED_SHOW:
            targetCallback(pieceId);
            break;

        default:
            targetCallback(undefined);
            break;
    }
}