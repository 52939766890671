import React, { ChangeEvent, MouseEvent, Component } from 'react';
import styles from './QuestionPiece.module.scss';
import Input from '../Input';
import { OwnProps as FlowchartPieceProps } from './FlowchartPiece';

import { Dispatch } from 'redux';
import { connect } from 'react-redux';

import { setTargetPiece, setNextPiece, setFinsalPremium, setFinsalMemberFirstName, setFinsalMemberLastName, setFinsalMemberEmail, setFinsalMemberPhone, setFinsalMemberState, setFinsalMemberCity, setFinsalMemberPan, setFinsalMemberAddressLine1, setFinsalMemberAddressLine2, setFinsalMemberPinCode } from '../../../shared/store/flowchart/pieces/actions';

import { ApplicationState } from '../../../shared/store/types';
import { valuePieceSlotTarget } from './utilities';


type FinsalLoanProcessUpperArmProps = {
    premiumPiece: JSX.Element | undefined,
    memberFirstNamePiece: JSX.Element | undefined,
    memberLastNamePiece: JSX.Element | undefined,
    memberEmailPiece: JSX.Element | undefined,
    memberPhonePiece: JSX.Element | undefined,
    memberPanPiece: JSX.Element | undefined,
    memberStatePiece: JSX.Element | undefined,
    memberCityPiece: JSX.Element | undefined,

    memberAddressLine1Piece: JSX.Element | undefined,
    memberAddressLine2Piece: JSX.Element | undefined,
    memberPinCodePiece: JSX.Element | undefined,
}

const mapStateToProps = (state: ApplicationState) => {

    return {
        isDragging: state.flowchart.pieces.isDragging,
        lastDraggedPiece: state.flowchart.pieces.lastDraggedPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.lastDraggedPiece] : undefined,
        targetPiece: state.flowchart.pieces.targetPiece ? state.flowchart.pieces.byId[state.flowchart.pieces.targetPiece] : undefined,
    }
}

const mapDispatchToProps = (dispatch: Dispatch) => {

    return {
        setTargetPiece: (pieceId: string | undefined) => dispatch(setTargetPiece(pieceId)),
        setNextPiece: (targetPieceId: string, draggedPieceId: string) => dispatch(setNextPiece(targetPieceId, draggedPieceId)),
        setFinsalPremium: (pieceId: string, value: string | undefined) => dispatch(setFinsalPremium(pieceId, value)),
        setFinsalMemberFirstName: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberFirstName(pieceId, value)),
        setFinsalMemberLastName: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberLastName(pieceId, value)),
        setFinsalMemberEmail: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberEmail(pieceId, value)),
        setFinsalMemberPhone: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberPhone(pieceId, value)),
        setFinsalMemberPan: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberPan(pieceId, value)),
        setFinsalMemberState: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberState(pieceId, value)),
        setFinsalMemberCity: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberCity(pieceId, value)),
        setFinsalMemberAddressLine1: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberAddressLine1(pieceId, value)),
        setFinsalMemberAddressLine2: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberAddressLine2(pieceId, value)),
        setFinsalMemberPinCode: (pieceId: string, value: string | undefined) => dispatch(setFinsalMemberPinCode(pieceId, value)),
    };
}

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = ReturnType<typeof mapDispatchToProps>;

type Props = FinsalLoanProcessUpperArmProps & StateProps & DispatchProps & FlowchartPieceProps;

type FinsalLoanProcessUpperArmState = {
    isHoveringOverPremiumPiece: boolean,
    isHoveringOverFirstNamePiece: boolean,
    isHoveringOverLastNamePiece: boolean,
    isHoveringOverEmailPiece: boolean,
    isHoveringOverPhonePiece: boolean,
    isHoveringOverPanPiece: boolean,
    isHoveringOverStatePiece: boolean,
    isHoveringOverCityPiece: boolean,

    isHoveringOverAddressLine1Piece: boolean,
    isHoveringOverAddressLine2Piece: boolean,
    isHoveringOverPinCodePiece: boolean,
}

class ConnectedFinsalLoanProcessUpperArm extends Component<Props, FinsalLoanProcessUpperArmState>  {

    state = {
        isHoveringOverPremiumPiece: false,
        isHoveringOverFirstNamePiece: false,
        isHoveringOverLastNamePiece: false,
        isHoveringOverEmailPiece: false,
        isHoveringOverPhonePiece: false,
        isHoveringOverPanPiece: false,
        isHoveringOverStatePiece: false,
        isHoveringOverCityPiece: false,

        isHoveringOverAddressLine1Piece: false,
        isHoveringOverAddressLine2Piece: false,
        isHoveringOverPinCodePiece: false,
    };

    componentDidUpdate(prevProps: Props) {
        if (this.props.isDragging === prevProps.isDragging) {
            return;  // The dragging prop did not change. Only set the pieces when the dragging has stopped.
        }

        if (this.props.isDragging) {
            return; // The dragging is still happening
        }

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // Nothing to do if no piece is being dragged
        }

        if (!this.props.targetPiece) {
            return;  // This piece does not qualify as a target
        }

        const oneOfThePiecesAreHovering = this.state.isHoveringOverPremiumPiece ||
            this.state.isHoveringOverFirstNamePiece ||
            this.state.isHoveringOverLastNamePiece ||
            this.state.isHoveringOverEmailPiece ||
            this.state.isHoveringOverPhonePiece ||
            this.state.isHoveringOverPanPiece ||
            this.state.isHoveringOverStatePiece ||
            this.state.isHoveringOverCityPiece ||
            this.state.isHoveringOverAddressLine1Piece ||
            this.state.isHoveringOverAddressLine2Piece ||
            this.state.isHoveringOverPinCodePiece;

        if (!this.props.isDragging && prevProps.isDragging && this.props.pieceId === this.props.targetPiece.id && oneOfThePiecesAreHovering) {
            if (this.state.isHoveringOverPremiumPiece) {
                this.props.setFinsalPremium(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverFirstNamePiece) {
                this.props.setFinsalMemberFirstName(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverLastNamePiece) {
                this.props.setFinsalMemberLastName(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverEmailPiece) {
                this.props.setFinsalMemberEmail(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverPhonePiece) {
                this.props.setFinsalMemberPhone(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverPanPiece) {
                this.props.setFinsalMemberPan(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverStatePiece) {
                this.props.setFinsalMemberState(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverCityPiece) {
                this.props.setFinsalMemberCity(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverAddressLine1Piece) {
                this.props.setFinsalMemberAddressLine1(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverAddressLine2Piece) {
                this.props.setFinsalMemberAddressLine2(this.props.pieceId, this.props.lastDraggedPiece.id);
            } else if (this.state.isHoveringOverPinCodePiece) {
                this.props.setFinsalMemberPinCode(this.props.pieceId, this.props.lastDraggedPiece.id);
            }

            this.props.removeIsolatedPiece && this.props.removeIsolatedPiece(this.props.lastDraggedPiece.id);

            this.setState({
                isHoveringOverPremiumPiece: false,
                isHoveringOverFirstNamePiece: false,
                isHoveringOverLastNamePiece: false,
                isHoveringOverEmailPiece: false,
                isHoveringOverPhonePiece: false,
                isHoveringOverPanPiece: false,
                isHoveringOverStatePiece: false,
                isHoveringOverCityPiece: false,
                isHoveringOverAddressLine1Piece: false,
                isHoveringOverAddressLine2Piece: false,
                isHoveringOverPinCodePiece: false,
            });
        }
    }

    handleHoverOverPremiumPiece = () => {
        this.setState({
            isHoveringOverPremiumPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfPremiumPiece = () => {
        this.setState({
            isHoveringOverPremiumPiece: false,
        });
    };

    handleHoverOverFirstNamePiece = () => {
        this.setState({
            isHoveringOverFirstNamePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfFirstNamePiece = () => {
        this.setState({
            isHoveringOverFirstNamePiece: false,
        });
    };

    handleHoverOverLastNamePiece = () => {
        this.setState({
            isHoveringOverLastNamePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfLastNamePiece = () => {
        this.setState({
            isHoveringOverLastNamePiece: false,
        });
    };

    handleHoverOverEmailPiece = () => {
        this.setState({
            isHoveringOverEmailPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfEmailPiece = () => {
        this.setState({
            isHoveringOverEmailPiece: false,
        });
    };

    handleHoverOverPhonePiece = () => {
        this.setState({
            isHoveringOverPhonePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfPhonePiece = () => {
        this.setState({
            isHoveringOverPhonePiece: false,
        });
    };

    handleHoverOverPanPiece = () => {
        this.setState({
            isHoveringOverPanPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfPanPiece = () => {
        this.setState({
            isHoveringOverPanPiece: false,
        });
    };

    handleHoverOverStatePiece = () => {
        this.setState({
            isHoveringOverStatePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfStatePiece = () => {
        this.setState({
            isHoveringOverStatePiece: false,
        });
    };

    handleHoverOverCityPiece = () => {
        this.setState({
            isHoveringOverCityPiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfCityPiece = () => {
        this.setState({
            isHoveringOverCityPiece: false,
        });
    };

    handleHoverOverAddressLine1Piece = () => {
        this.setState({
            isHoveringOverAddressLine1Piece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfAddressLine1Piece = () => {
        this.setState({
            isHoveringOverAddressLine1Piece: false,
        });
    };

    handleHoverOverAddressLine2Piece = () => {
        this.setState({
            isHoveringOverAddressLine2Piece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfAddressLine2Piece = () => {
        this.setState({
            isHoveringOverAddressLine2Piece: false,
        });
    };

    handleHoverOverPinCodePiece = () => {
        this.setState({
            isHoveringOverPinCodePiece: true,
        });

        if (!this.props.lastDraggedPiece || this.props.lastDraggedPiece.id === this.props.pieceId) {
            return;  // No need to set a target piece if no piece is being dragged
        }

        valuePieceSlotTarget(this.props.lastDraggedPiece.type, this.props.setTargetPiece, this.props.pieceId);
    };

    handleHoverOutOfPinCodePiece = () => {
        this.setState({
            isHoveringOverPinCodePiece: false,
        });
    };

    render() {

        return (
            <div className={styles.visibleItems}>
                <div className={styles.text}>Finsall Loan Process API call</div>

                <div className={styles.text}>Premium:</div>
                {this.props.premiumPiece ? this.props.premiumPiece : <Input placeholderText="E.g. '1200'" minSize={9}
                    canReceiveDrag={this.props.isDragging && this.state.isHoveringOverPremiumPiece && !!this.props.targetPiece}
                    onMouseOver={this.handleHoverOverPremiumPiece}
                    onMouseOut={this.handleHoverOutOfPremiumPiece}
                />}

                <div className={styles.text}>First Name:</div>
                {this.props.memberFirstNamePiece ? this.props.memberFirstNamePiece : <Input placeholderText="E.g. 'Sonal'" minSize={10}
                    canReceiveDrag={this.props.isDragging && this.state.isHoveringOverFirstNamePiece && !!this.props.targetPiece}
                    onMouseOver={this.handleHoverOverFirstNamePiece}
                    onMouseOut={this.handleHoverOutOfFirstNamePiece}
                />}

                <div className={styles.text}>Last Name:</div>
                {this.props.memberLastNamePiece ? this.props.memberLastNamePiece : <Input placeholderText="E.g. 'Kumari'" minSize={12}
                    canReceiveDrag={this.props.isDragging && this.state.isHoveringOverLastNamePiece && !!this.props.targetPiece}
                    onMouseOver={this.handleHoverOverLastNamePiece}
                    onMouseOut={this.handleHoverOutOfLastNamePiece}
                />}

                <div className={styles.text}>Email:</div>
                {this.props.memberEmailPiece ? this.props.memberEmailPiece : <Input placeholderText="E.g. 'sonal.kumari@gmail.com'" minSize={27}
                    canReceiveDrag={this.props.isDragging && this.state.isHoveringOverEmailPiece && !!this.props.targetPiece}
                    onMouseOver={this.handleHoverOverEmailPiece}
                    onMouseOut={this.handleHoverOutOfEmailPiece}
                />}

                <div className={styles.text}>Phone:</div>
                {this.props.memberPhonePiece ? this.props.memberPhonePiece : <Input placeholderText="E.g. '9845611111'" minSize={14}
                    canReceiveDrag={this.props.isDragging && this.state.isHoveringOverPhonePiece && !!this.props.targetPiece}
                    onMouseOver={this.handleHoverOverPhonePiece}
                    onMouseOut={this.handleHoverOutOfPhonePiece}
                />}

                <div className={styles.text}>Pan:</div>
                {this.props.memberPanPiece ? this.props.memberPanPiece : <Input placeholderText="E.g. 'ABCDE1234F'" minSize={14}
                    canReceiveDrag={this.props.isDragging && this.state.isHoveringOverPanPiece && !!this.props.targetPiece}
                    onMouseOver={this.handleHoverOverPanPiece}
                    onMouseOut={this.handleHoverOutOfPanPiece}
                />}

                <div className={styles.text}>State:</div>
                {this.props.memberStatePiece ? this.props.memberStatePiece : <Input placeholderText="E.g. 'Karnataka'" minSize={14}
                    canReceiveDrag={this.props.isDragging && this.state.isHoveringOverStatePiece && !!this.props.targetPiece}
                    onMouseOver={this.handleHoverOverStatePiece}
                    onMouseOut={this.handleHoverOutOfStatePiece}
                />}

                <div className={styles.text}>City:</div>
                {this.props.memberCityPiece ? this.props.memberCityPiece : <Input placeholderText="E.g. 'Bengaluru'" minSize={14}
                    canReceiveDrag={this.props.isDragging && this.state.isHoveringOverCityPiece && !!this.props.targetPiece}
                    onMouseOver={this.handleHoverOverCityPiece}
                    onMouseOut={this.handleHoverOutOfCityPiece}
                />}

                <div className={styles.text}>Address Line 1:</div>
                {this.props.memberAddressLine1Piece ? this.props.memberAddressLine1Piece : <Input placeholderText="E.g. 'Line 1'" minSize={14}
                    canReceiveDrag={this.props.isDragging && this.state.isHoveringOverAddressLine1Piece && !!this.props.targetPiece}
                    onMouseOver={this.handleHoverOverAddressLine1Piece}
                    onMouseOut={this.handleHoverOutOfAddressLine1Piece}
                />}

                <div className={styles.text}>Address Line 2:</div>
                {this.props.memberAddressLine2Piece ? this.props.memberAddressLine2Piece : <Input placeholderText="E.g. 'Line 2'" minSize={14}
                    canReceiveDrag={this.props.isDragging && this.state.isHoveringOverAddressLine2Piece && !!this.props.targetPiece}
                    onMouseOver={this.handleHoverOverAddressLine2Piece}
                    onMouseOut={this.handleHoverOutOfAddressLine2Piece}
                />}

                <div className={styles.text}>Pin Code:</div>
                {this.props.memberPinCodePiece ? this.props.memberPinCodePiece : <Input placeholderText="E.g. '560065'" minSize={14}
                    canReceiveDrag={this.props.isDragging && this.state.isHoveringOverPinCodePiece && !!this.props.targetPiece}
                    onMouseOver={this.handleHoverOverPinCodePiece}
                    onMouseOut={this.handleHoverOutOfPinCodePiece}
                />}
            </div>
        )

    }
}

const FinsalLoanProcessUpperArm = connect(mapStateToProps, mapDispatchToProps)(ConnectedFinsalLoanProcessUpperArm);

export default FinsalLoanProcessUpperArm;