export type TextRuleType = 'bold' | 'italic';

export function decorateText(text: string, ruleType: TextRuleType) {
    const boldRegex = /\*(.*?)\*/g;
    const italicRegex = /\*(.*?)\*/g;

    const elements: Array<{
        text: string;
        isBold: boolean;
    }> = [];

    if (ruleType === 'bold') {
        text.split(boldRegex).map((part, index) => {
            if (index % 2 === 0) {
                return elements.push({ text: part, isBold: false });
            } else {
                return elements.push({ text: part, isBold: true });
            };
        });
    };

    return elements;
};
