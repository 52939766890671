import { ApplicationState } from '../../types';
import { FlowchartProcessState, DefaultFlowchartProcessState } from '../types';

import { getNextPieceId, executePiece, getPieceValue, getReadableProcessState, getEntitiesFunctionaltiy } from './index';
import { PieceType } from '../pieces/types';
import { VariableValueType } from '../../../helpers/common-types';
import { getAllLocationsUnderUser } from '../../../helpers/locations';
import { isUUID } from '../../../helpers/utilities';

export function getReportPieceValue(applicationState: ApplicationState, processState: DefaultFlowchartProcessState, reportId: string, pieceId: string): VariableValueType {
    const piecesData = applicationState.flowchart.pieces;
    const piece = piecesData.byId[pieceId];

    const report = applicationState.reports.byId[reportId];
    const locationsData = applicationState.structure.locations;
    const assignedUser = applicationState.users.byId[report.user];

    const functionShortHand = getReportPieceValue.bind({}, applicationState, processState, reportId);

    switch (piece.type) {
        case PieceType.MY_GROUPS:
            const allMyLocationsForGroups = assignedUser.locations.concat(getAllLocationsUnderUser(assignedUser.id, applicationState));
            return allMyLocationsForGroups.map(locationId => locationsData.byId[locationId].groups).flat().filter(groupId => groupId in applicationState.groups.byId);

        case PieceType.MY_MEMBERS:
            const allMyLocationsForMembers = assignedUser.locations.concat(getAllLocationsUnderUser(assignedUser.id, applicationState));
            return allMyLocationsForMembers.map(locationId => locationsData.byId[locationId].members).flat().filter(memberId => memberId in applicationState.members.byId);

        case PieceType.GET_ENTITIES:
            const locationForGetEntities = piece.variablePiece && isUUID(piece.variablePiece) ? functionShortHand(piece.variablePiece) : undefined;
            let locationIdForGetEntities: string | undefined;

            if (typeof locationForGetEntities === 'string' && isUUID(locationForGetEntities) && locationForGetEntities in applicationState.structure.locations.byId) {
                locationIdForGetEntities = locationForGetEntities;
            }
            return getEntitiesFunctionaltiy(piece, assignedUser.id, applicationState, locationIdForGetEntities);

        default:
            return getPieceValue(applicationState, processState, pieceId, functionShortHand);
    }
}


export function getNextPieceIdForReport(applicationState: ApplicationState, processState: FlowchartProcessState, startPiece: string | undefined, getPieceValueFromAbove?: (pieceId: string) => VariableValueType): string | undefined {

    const getNextPieceIdShortHand = getNextPieceIdForReport.bind({}, applicationState, processState, startPiece, getPieceValueFromAbove);

    return getNextPieceId(applicationState, processState, startPiece, getNextPieceIdShortHand, getPieceValueFromAbove);
}

export type ExecutePieceReturnType = {
    canContinueExecution: boolean,
    returnValue: VariableValueType,
};

// If it returns false, stop execution and display what needs to be displayed. Otherwise, feel free to get the next piece and continue executing
function executePieceForReport(applicationState: ApplicationState, processState: DefaultFlowchartProcessState, reportId: string, pieceId: string): ExecutePieceReturnType {
    const piecesData = applicationState.flowchart.pieces;
    const piece = piecesData.byId[pieceId];

    const pieceValueShorthand = getReportPieceValue.bind({}, applicationState, processState, reportId);

    switch (piece.type) {

        case PieceType.RETURN:

            if (typeof piece.returnValue === 'undefined') {
                throw new Error('The return piece must have a variable')
            }

            const returnVariablePiece = piecesData.byId[piece.returnValue];
            const returnVariableValue = pieceValueShorthand(returnVariablePiece.id);

            return {
                canContinueExecution: false,
                returnValue: returnVariableValue,
            };

        default:
            const canContinueExecution = executePiece(applicationState, processState, pieceId, pieceValueShorthand);

            return {
                canContinueExecution,
                returnValue: undefined,
            };
    }
}

export function getReportValue(applicationState: ApplicationState, processState: DefaultFlowchartProcessState, reportId: string, startPiece: string | undefined) {
    let executionResult: ExecutePieceReturnType;
    const pieceValueShorthand = getReportPieceValue.bind({}, applicationState, processState, reportId);

    do {
        let nextPieceId: string | undefined;

        try {
            nextPieceId = getNextPieceIdForReport(applicationState, processState, startPiece, pieceValueShorthand);
        } catch (e) {
            let errorMessage: string | undefined;
            if (e instanceof Error) {
                errorMessage = e.message;
            }

            const readableProcessState = getReadableProcessState(processState, applicationState);
            const newErrorMessage = JSON.stringify({
                errorMessage,
                ...readableProcessState,
            }, undefined, 4);

            throw new Error('Error when getting next piece in report. Details: ' + newErrorMessage);
        }

        if (nextPieceId === '92f7b628-597d-48ba-91fd-f0abf5a69b48') {
            // For piece after getting list of vaxnow flows

            const vaxNowList = processState.variables['439d8f31-1745-48ba-a8ae-baa5deb644af'];

            if (!Array.isArray(vaxNowList)) {
                console.log('The list of vax now flows should have been an array');
            } else {
                const targetWorkflowId = 'c3bda44f-1c0d-4c09-b367-0e3b5dd2f3e7';

                if ((vaxNowList as Array<string>).includes(targetWorkflowId)) {
                    console.log('Workflow ID for D1 in search found in vax now list');
                } else {
                    console.log('Workflow ID for D1 in search not found in vax now list');
                }
            }
        }

        if (typeof nextPieceId === 'undefined') {
            return true;
        }

        try {
            executionResult = executePieceForReport(applicationState, processState, reportId, nextPieceId);
        } catch (e) {
            let errorMessage: string | undefined;
            let errorStackTrace: string | undefined;
            if (e instanceof Error) {
                errorMessage = e.message;
                errorStackTrace = e.stack;
            }

            const readableProcessState = getReadableProcessState(processState, applicationState);
            const newErrorMessage = JSON.stringify({
                errorMessage,
                errorStackTrace,
                executingPiece: applicationState.flowchart.pieces.byId[nextPieceId],
                ...readableProcessState,
            }, undefined, 4);

            throw new Error('Error when executing piece in report. Details: ' + newErrorMessage);
        }
    } while (executionResult.canContinueExecution);

    return executionResult.returnValue;
}

