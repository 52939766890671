import store from "../../main";
import { ApplicationState } from "../../types";
import { WorkflowProcessState } from "../../workflows/types";
import { AllPieceTypes, PieceType } from "../pieces/types";

function getNextPieceId(pieceId: string, executionStack: Array<string>, state: ApplicationState, backTrack = true) {
    let stepPiece = state.flowchart.pieces.byId[pieceId];

    if (stepPiece.type === PieceType.FOR && stepPiece.innerPiece) {
        if (stepPiece.innerPiece) {
            executionStack.push(stepPiece.id);
            return stepPiece.innerPiece;
        }
    }

    if (stepPiece.type === PieceType.SPLIT && stepPiece.ifPieceData && stepPiece.ifPieceData.length > 0) {
        executionStack.push(stepPiece.id);
        return stepPiece.ifPieceData[0].nextPiece;
    }

    if ('nextPiece' in stepPiece && stepPiece.nextPiece) {
        return stepPiece.nextPiece;
    }

    if (backTrack) {
        while (executionStack.length > 0) {
            const stackPieceId = executionStack.pop();

            if (stackPieceId) {
                const stackPiece = state.flowchart.pieces.byId[stackPieceId];
                if ('nextPiece' in stackPiece) {
                    return stackPiece.nextPiece;
                }
            }
        }
    }

    return undefined;
}

function completionPercentageFromPieceId(
    noOfCompletedSteps: number,
    processState: WorkflowProcessState,
    executionStack: Array<string>,
    state: ApplicationState
) {

    let remainingSteps = 0;

    let currentPieceId = processState.lastComputedPiece;

    if (!currentPieceId) {
        return 0.0;
    }

    let isWorkflowPaused = false;

    if (processState.displayingQuestionPieceId) {
        currentPieceId = processState.displayingQuestionPieceId;
        isWorkflowPaused = true;
    } else if (processState.displayingShowPieceId) {
        currentPieceId = processState.displayingShowPieceId;
        isWorkflowPaused = true;
    } else if (processState.displayingGroupPieceId) {
        currentPieceId = processState.displayingGroupPieceId;
        isWorkflowPaused = true;
    } else if (processState.displayingTransferPieceId) {
        currentPieceId = processState.displayingTransferPieceId;
        isWorkflowPaused = true;
    } else if (processState.displayingContinuePieceId) {
        currentPieceId = processState.displayingContinuePieceId;
        isWorkflowPaused = true;
    } else if (processState.displayingAddWorkflowPieceId) {
        currentPieceId = processState.displayingAddWorkflowPieceId;
        isWorkflowPaused = true;
    } else if (processState.createdWorkflowId) {
        currentPieceId = processState.createdWorkflowId;
        isWorkflowPaused = true;
    }

    let stepPiece: AllPieceTypes | undefined = state.flowchart.pieces.byId[currentPieceId];

    while (stepPiece) {

        if (
            (stepPiece.type === PieceType.ADD_WORKFLOW) ||
            (stepPiece.type === PieceType.CONTINUE) ||
            (stepPiece.type === PieceType.GROUP) ||
            (stepPiece.type === PieceType.QUESTION) ||
            (stepPiece.type === PieceType.CHOOSE) ||
            (stepPiece.type === PieceType.SHOW) ||
            (stepPiece.type === PieceType.TRANSFER_WORKFLOW)
        ) {
            remainingSteps += 1;
        }

        const nextPieceId = getNextPieceId(stepPiece.id, executionStack, state);

        if (nextPieceId) {
            stepPiece = state.flowchart.pieces.byId[nextPieceId];
        } else {
            stepPiece = undefined;
        }
    }

    let totalSteps = noOfCompletedSteps + remainingSteps;

    if (isWorkflowPaused) {
        totalSteps += 1;
    }

    return ((totalSteps - remainingSteps) * 100.0) / totalSteps;
}

export function completionPercentageOfWorkflow(workflowId: string, applicationState?: ApplicationState) {
    const state: ApplicationState = applicationState ? applicationState : store.getState();

    const workflow = state.workflows.byId[workflowId];
    const workflowType = state.workflows.types.byId[workflow.type];
    const workflowStatus = state.workflows.types.statuses.byId[workflow.status];

    if (workflowStatus.isTerminal) {
        return 100.0;
    }

    const historyIndex = workflow.historyIndex >= workflow.history.length ? workflow.history.length - 1 : workflow.historyIndex;
    const processState = workflow.history[historyIndex];
    const executionStack = processState.executionStack.slice();

    let completionPercentage: number;

    completionPercentage = completionPercentageFromPieceId(
        historyIndex - 1,
        processState,
        executionStack,
        state
    );

    if (isNaN(completionPercentage)) {
        completionPercentage = 100;
    }

    return completionPercentage;
}