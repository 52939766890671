import React from 'react';
import { WorkflowTypeState } from '../../../shared/store/workflows/types/types';
import { VariableState } from '../../../shared/store/flowchart/variables/types';
import { PieceType, PieceState, FlowchartPieceActions } from '../../../shared/store/flowchart/pieces/types';
import ReturnPiece from '../../../components/flowchart/pieces/ReturnPiece';
import StartPiece from '../../../components/flowchart/pieces/StartPiece';
import SequenceOperator from '../../../components/flowchart/pieces/operators/SequenceOperator';
import { getComponent } from './index';
import { PiecePositionState, Position } from '../../../shared/helpers/common-types';
import { isUUID } from '../../../shared/helpers/utilities';
import { CustomField } from '../../../shared/store/custom-fields/types';
import { MemberTypeState } from '../../../shared/store/members/types/types';
import { LevelState } from '../../../shared/store/structure/level/types';
import { RoleState } from '../../../shared/store/structure/role/types';
import { UserState } from '../../../shared/store/users/types';
import { GroupTypeState } from '../../../shared/store/groups/types/types';
import CustomFieldPiece from '../pieces/CustomFieldPiece';
import { ArithmeticOperatorsPieces, BooleanOperatorsPieces, ConstantPieces, ControlPieces, CustomFieldPieces, DateOperatorsPieces, ListOperatorsPieces, VariablePieces } from './piece-categories';

export function getComponentForCommonComputedFields(computedField: CustomField, piecesState: PieceState, variablesState: VariableState, variableIds: Array<string>, isEditable = true, getInnerComponent: (pieceId: string, detachPiece?: () => void) => JSX.Element, flowchartPieceActions: FlowchartPieceActions, isolatePiece: (pieceState: PiecePositionState) => void, removeIsolatedPiece: (pieceId: string) => void, registerVariable: (variableId: string) => void, pieceId: string, detachPiece?: () => void, initialPosition?: Position): JSX.Element {
    const piece = piecesState.byId[pieceId];

    switch (piece.type) {

        case PieceType.START:
            const startNextPiece = piece.nextPiece ? getInnerComponent(piece.nextPiece, flowchartPieceActions.setNextPiece.bind({}, pieceId, undefined)) : undefined;
            const startInitialPosition = computedField.startPiece ? computedField.startPiece.position : undefined;
            return <StartPiece pieceId={pieceId} nextPiece={startNextPiece} isDragDisabled={!isEditable} initialPosition={startInitialPosition} removeIsolatedPiece={removeIsolatedPiece} />

        case PieceType.RETURN:
            const returnVariablePiece = piece.returnValue && isUUID(piece.returnValue) ? getInnerComponent(piece.returnValue, flowchartPieceActions.setReturnVariable.bind({}, pieceId, undefined)) : undefined;
            const returnVariableText = piece.returnValue;

            return <ReturnPiece
                pieceId={pieceId}
                returnVariablePiece={returnVariablePiece}
                returnVariableText={returnVariableText}
                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}
            />

        default:
            return getComponent(pieceId, piecesState, variablesState, variableIds, getInnerComponent, isEditable, flowchartPieceActions, isolatePiece, removeIsolatedPiece, registerVariable, detachPiece, initialPosition);
    }
}

export function getComponentForLevelComputedField(computedFieldId: string, levelState: LevelState, piecesState: PieceState, variablesState: VariableState, variableIds: Array<string>, isEditable = true, flowchartPieceActions: FlowchartPieceActions, isolatePiece: (pieceState: PiecePositionState) => void, removeIsolatedPiece: (pieceId: string) => void, registerVariable: (variableId: string) => void, pieceId: string, detachPiece?: () => void, initialPosition?: Position): JSX.Element {
    const piece = piecesState.byId[pieceId];

    const getTempInnerComponentShorthand = getComponentForLevelComputedField.bind({}, computedFieldId, levelState, piecesState, variablesState);
    const getTemp2InnerComponentShorthand = getTempInnerComponentShorthand.bind({}, variableIds, isEditable, flowchartPieceActions);
    const getInnerComponentShorthand = getTemp2InnerComponentShorthand.bind({}, isolatePiece, removeIsolatedPiece, registerVariable);

    const computedField = levelState.customFields.byId[computedFieldId];

    switch (piece.type) {

        case PieceType.CUSTOM_FIELD:
            const levelId = levelState.allEntries.find(levelId => {
                const level = levelState.byId[levelId];
                return level.customFields.includes(computedFieldId);
            });

            if (typeof levelId === 'undefined') {
                throw new Error('This custom field is not tied to a member type');
            }

            const level = levelState.byId[levelId];

            return <CustomFieldPiece
                pieceId={pieceId}

                customFieldIds={level.customFields}
                selectedCustomFieldId={piece.customField}
                selectedCustomFieldOptionId={piece.customFieldOption}
                type="Level"

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}

                isShowingMemberVariablePiece={false}
            />

        default:
            return getComponentForCommonComputedFields(computedField, piecesState, variablesState, variableIds, isEditable, getInnerComponentShorthand, flowchartPieceActions, isolatePiece, removeIsolatedPiece, registerVariable, pieceId, detachPiece, initialPosition);
    }
}

export function getComponentForRoleComputedField(computedFieldId: string, roleState: RoleState, piecesState: PieceState, variablesState: VariableState, variableIds: Array<string>, isEditable = true, flowchartPieceActions: FlowchartPieceActions, isolatePiece: (pieceState: PiecePositionState) => void, removeIsolatedPiece: (pieceId: string) => void, registerVariable: (variableId: string) => void, pieceId: string, detachPiece?: () => void, initialPosition?: Position): JSX.Element {
    const piece = piecesState.byId[pieceId];

    const getTempInnerComponentShorthand = getComponentForRoleComputedField.bind({}, computedFieldId, roleState, piecesState, variablesState);
    const getTemp2InnerComponentShorthand = getTempInnerComponentShorthand.bind({}, variableIds, isEditable, flowchartPieceActions);
    const getInnerComponentShorthand = getTemp2InnerComponentShorthand.bind({}, isolatePiece, removeIsolatedPiece, registerVariable);

    const computedField = roleState.customFields.byId[computedFieldId];

    switch (piece.type) {

        case PieceType.CUSTOM_FIELD:
            const roleId = roleState.allEntries.find(roleId => {
                const role = roleState.byId[roleId];
                return role.customFields.includes(computedFieldId);
            });

            if (typeof roleId === 'undefined') {
                throw new Error('This custom field is not tied to a member type');
            }

            const role = roleState.byId[roleId];

            return <CustomFieldPiece
                pieceId={pieceId}

                customFieldIds={role.customFields}
                selectedCustomFieldId={piece.customField}
                selectedCustomFieldOptionId={piece.customFieldOption}
                type="Role"

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}

                isShowingMemberVariablePiece={false}
            />

        default:
            return getComponentForCommonComputedFields(computedField, piecesState, variablesState, variableIds, isEditable, getInnerComponentShorthand, flowchartPieceActions, isolatePiece, removeIsolatedPiece, registerVariable, pieceId, detachPiece, initialPosition);
    }
}

export function getComponentForUserComputedField(computedFieldId: string, userState: UserState, piecesState: PieceState, variablesState: VariableState, variableIds: Array<string>, isEditable = true, flowchartPieceActions: FlowchartPieceActions, isolatePiece: (pieceState: PiecePositionState) => void, removeIsolatedPiece: (pieceId: string) => void, registerVariable: (variableId: string) => void, pieceId: string, detachPiece?: () => void, initialPosition?: Position): JSX.Element {
    const piece = piecesState.byId[pieceId];

    const getTempInnerComponentShorthand = getComponentForUserComputedField.bind({}, computedFieldId, userState, piecesState, variablesState);
    const getTemp2InnerComponentShorthand = getTempInnerComponentShorthand.bind({}, variableIds, isEditable, flowchartPieceActions);
    const getInnerComponentShorthand = getTemp2InnerComponentShorthand.bind({}, isolatePiece, removeIsolatedPiece, registerVariable);

    const computedField = userState.customFields.byId[computedFieldId];

    switch (piece.type) {

        case PieceType.CUSTOM_FIELD:

            return <CustomFieldPiece
                pieceId={pieceId}

                customFieldIds={userState.customFields.allFields}
                selectedCustomFieldId={piece.customField}
                selectedCustomFieldOptionId={piece.customFieldOption}
                type="User"

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}

                isShowingMemberVariablePiece={false}
            />

        default:
            return getComponentForCommonComputedFields(computedField, piecesState, variablesState, variableIds, isEditable, getInnerComponentShorthand, flowchartPieceActions, isolatePiece, removeIsolatedPiece, registerVariable, pieceId, detachPiece, initialPosition);
    }
}

export function getComponentForMemberComputedField(computedFieldId: string, typeState: MemberTypeState, piecesState: PieceState, variablesState: VariableState, variableIds: Array<string>, isEditable = true, flowchartPieceActions: FlowchartPieceActions, isolatePiece: (pieceState: PiecePositionState) => void, removeIsolatedPiece: (pieceId: string) => void, registerVariable: (variableId: string) => void, pieceId: string, detachPiece?: () => void, initialPosition?: Position): JSX.Element {
    const piece = piecesState.byId[pieceId];

    const getTempInnerComponentShorthand = getComponentForMemberComputedField.bind({}, computedFieldId, typeState, piecesState, variablesState);
    const getTemp2InnerComponentShorthand = getTempInnerComponentShorthand.bind({}, variableIds, isEditable, flowchartPieceActions);
    const getInnerComponentShorthand = getTemp2InnerComponentShorthand.bind({}, isolatePiece, removeIsolatedPiece, registerVariable);

    const computedField = typeState.customFields.byId[computedFieldId];

    switch (piece.type) {

        case PieceType.CUSTOM_FIELD:
            const memberTypeId = typeState.allEntries.find(typeId => {
                const memberType = typeState.byId[typeId];
                return memberType.customFields.includes(computedFieldId);
            });

            if (typeof memberTypeId === 'undefined') {
                throw new Error('This custom field is not tied to a member type');
            }

            const memberType = typeState.byId[memberTypeId];

            return <CustomFieldPiece
                pieceId={pieceId}

                customFieldIds={memberType.customFields}
                selectedCustomFieldId={piece.customField}
                selectedCustomFieldOptionId={piece.customFieldOption}
                type="Member"

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}

                isShowingMemberVariablePiece={false}
            />

        default:
            return getComponentForCommonComputedFields(computedField, piecesState, variablesState, variableIds, isEditable, getInnerComponentShorthand, flowchartPieceActions, isolatePiece, removeIsolatedPiece, registerVariable, pieceId, detachPiece, initialPosition);
    }
}

export function getComponentForGroupComputedField(computedFieldId: string, typeState: GroupTypeState, piecesState: PieceState, variablesState: VariableState, variableIds: Array<string>, isEditable = true, flowchartPieceActions: FlowchartPieceActions, isolatePiece: (pieceState: PiecePositionState) => void, removeIsolatedPiece: (pieceId: string) => void, registerVariable: (variableId: string) => void, pieceId: string, detachPiece?: () => void, initialPosition?: Position): JSX.Element {
    const piece = piecesState.byId[pieceId];

    const getTempInnerComponentShorthand = getComponentForGroupComputedField.bind({}, computedFieldId, typeState, piecesState, variablesState);
    const getTemp2InnerComponentShorthand = getTempInnerComponentShorthand.bind({}, variableIds, isEditable, flowchartPieceActions);
    const getInnerComponentShorthand = getTemp2InnerComponentShorthand.bind({}, isolatePiece, removeIsolatedPiece, registerVariable);

    const computedField = typeState.customFields.byId[computedFieldId];

    switch (piece.type) {

        case PieceType.CUSTOM_FIELD:
            const groupTypeId = typeState.allEntries.find(typeId => {
                const groupType = typeState.byId[typeId];
                return groupType.customFields.includes(computedFieldId);
            });

            if (typeof groupTypeId === 'undefined') {
                throw new Error('This custom field is not tied to a group type');
            }

            const groupType = typeState.byId[groupTypeId];

            return <CustomFieldPiece
                pieceId={pieceId}

                customFieldIds={groupType.customFields}
                selectedCustomFieldId={piece.customField}
                selectedCustomFieldOptionId={piece.customFieldOption}
                type="Group"

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}

                isShowingMemberVariablePiece={false}
            />

        default:
            return getComponentForCommonComputedFields(computedField, piecesState, variablesState, variableIds, isEditable, getInnerComponentShorthand, flowchartPieceActions, isolatePiece, removeIsolatedPiece, registerVariable, pieceId, detachPiece, initialPosition);
    }
}

export function getComponentForWorkflowComputedField(computedFieldId: string, typeState: WorkflowTypeState, piecesState: PieceState, variablesState: VariableState, variableIds: Array<string>, isEditable = true, flowchartPieceActions: FlowchartPieceActions, isolatePiece: (pieceState: PiecePositionState) => void, removeIsolatedPiece: (pieceId: string) => void, registerVariable: (variableId: string) => void, pieceId: string, detachPiece?: () => void, initialPosition?: Position): JSX.Element {
    const piece = piecesState.byId[pieceId];

    const getTempInnerComponentShorthand = getComponentForWorkflowComputedField.bind({}, computedFieldId, typeState, piecesState, variablesState);
    const getTemp2InnerComponentShorthand = getTempInnerComponentShorthand.bind({}, variableIds, isEditable, flowchartPieceActions);
    const getInnerComponentShorthand = getTemp2InnerComponentShorthand.bind({}, isolatePiece, removeIsolatedPiece, registerVariable);

    const computedField = typeState.customFields.byId[computedFieldId];

    switch (piece.type) {

        case PieceType.SEQUENCE:
            const sequenceOptions = [{
                name: 'Type',
                value: 'type',
            }, {
                name: 'Affiliated Entity',
                value: 'affiliatedEntity',
            }]

            return <SequenceOperator pieceId={pieceId} options={sequenceOptions} />;

        case PieceType.CUSTOM_FIELD:
            const customField = piece.customField ? typeState.customFields.byId[piece.customField] : undefined;
            const workflowTypeId = typeState.allEntries.find(typeId => {
                const workflowType = typeState.byId[typeId];
                return workflowType.customFields.includes(computedFieldId);
            });

            if (typeof workflowTypeId === 'undefined') {
                throw new Error('This custom field is not tied to a workflow type');
            }

            const workflowType = typeState.byId[workflowTypeId];

            const isShowingMemberPiece = customField && !piece.customFieldOption ? workflowType.affiliation === 'group' && customField.affiliation === 'member' : false;

            const customFieldMemberVariable = piece.memberVariablePiece ? getInnerComponentShorthand(piece.memberVariablePiece, flowchartPieceActions.setMemberVariable.bind({}, pieceId, undefined)) : undefined;

            return <CustomFieldPiece
                pieceId={pieceId}

                customFieldIds={workflowType.customFields}
                selectedCustomFieldId={piece.customField}
                selectedCustomFieldOptionId={piece.customFieldOption}
                type="Workflow"

                isDragDisabled={!isEditable}
                detachPiece={detachPiece}
                isolatePiece={isolatePiece}
                removeIsolatedPiece={removeIsolatedPiece}
                initialPosition={initialPosition}

                isShowingMemberVariablePiece={isShowingMemberPiece}
                memberVariablePiece={customFieldMemberVariable}
            />

        default:
            return getComponentForCommonComputedFields(computedField, piecesState, variablesState, variableIds, isEditable, getInnerComponentShorthand, flowchartPieceActions, isolatePiece, removeIsolatedPiece, registerVariable, pieceId, detachPiece, initialPosition);
    }
}

export const piecesByCategory = {
    'Control': {
        color: '#14b1ab',
        pieces: [
            ControlPieces.For,
            ControlPieces.Return,
            ControlPieces.Split,
            ControlPieces.Structure,
            ControlPieces.StaticData,
            ControlPieces.GetAffiliation,
            ControlPieces.Status,
            ControlPieces.Translate,
            ControlPieces.Format,
            ControlPieces.GetEntities,
        ],
    },
    'Constants': {
        color: '#efaa4b',
        pieces: [
            ConstantPieces.Today,
            ConstantPieces.Now,
            ConstantPieces.True,
            ConstantPieces.TrueHexagonal,
            ConstantPieces.False,
            ConstantPieces.FalseHexagonal,
            ConstantPieces.LoggedInUser,
            ConstantPieces.FinancialYearMonths,
        ],
    },
    'Arithmetic Operators': {
        color: '#efaa4b',
        pieces: [
            ArithmeticOperatorsPieces.Subtract,
            ArithmeticOperatorsPieces.Add,
            ArithmeticOperatorsPieces.Multiply,
            ArithmeticOperatorsPieces.Divide,
            ArithmeticOperatorsPieces.Exponent,
            ArithmeticOperatorsPieces.Sequence,
        ],
    },
    'Boolean Operators': {
        color: '#efaa4b',
        pieces: [
            BooleanOperatorsPieces.LesserThan,
            BooleanOperatorsPieces.LesserThanOrEqualTo,
            BooleanOperatorsPieces.GreaterThan,
            BooleanOperatorsPieces.GreaterThanOrEqualTo,
            BooleanOperatorsPieces.EqualTo,
            BooleanOperatorsPieces.NotEqualTo,
            BooleanOperatorsPieces.In,
            BooleanOperatorsPieces.NotIn,
            BooleanOperatorsPieces.And,
            BooleanOperatorsPieces.Or,
            BooleanOperatorsPieces.Not,
            BooleanOperatorsPieces.VariableToBoolean,
            BooleanOperatorsPieces.BooleanToVariable,
            BooleanOperatorsPieces.IsDefined,
            BooleanOperatorsPieces.IsNotDefined
        ],
    },
    'List Operators': {
        color: '#efaa4b',
        pieces: [
            ListOperatorsPieces.PickFirstElement,
            ListOperatorsPieces.PickFirstNElements,
            ListOperatorsPieces.PickLastElement,
            ListOperatorsPieces.PickLastNElements,
            ListOperatorsPieces.PickNthElement,
            ListOperatorsPieces.SplitBySeparator,
            ListOperatorsPieces.AddToList,
            ListOperatorsPieces.RemoveFromList,
            ListOperatorsPieces.AddToTable,
            ListOperatorsPieces.Length,
        ],
    },
    'Date Operators': {
        color: '#efaa4b',
        pieces: [
            DateOperatorsPieces.AddMonths,
            DateOperatorsPieces.AddYears,
            DateOperatorsPieces.SubtractMonths,
            DateOperatorsPieces.SubtractYears,
            DateOperatorsPieces.GetDate,
            DateOperatorsPieces.GetDay,
            DateOperatorsPieces.GetMonth,
            DateOperatorsPieces.GetReadableMonth,
            DateOperatorsPieces.GetYear,
            DateOperatorsPieces.GetTimeDifference,
        ],
    },
    'Variables': {
        color: '#8891c8',
        pieces: [
            VariablePieces.Variable,
            VariablePieces.SetVariable,
            VariablePieces.StyleTableVariable,
        ],
    },
    'Custom Fields': {
        color: '#d289c0',
        pieces: [
            CustomFieldPieces.CustomField,
            CustomFieldPieces.Get,
        ],
    },
}